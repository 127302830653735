import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const GET_RAMOSEGURO = `
query get($filtro: InputGetRamoSeguro){
getRamoSeguro(filtro: $filtro)
{
ramoSeguroID
ramoSeguro
fijo
            ejercicio
            mes
            fechaInicio
            fechaFin
}
}
`;


export const GET_ALL_RAMOSEGURO =`
query filtroGetAll($filtro: InputGetRamoSeguro){
getAllRamoSeguro(filtro: $filtro)
{
    ramoSeguroID
    ramoSeguro
    fijo
            ejercicio
            mes
            fechaInicio
            fechaFin
}
}
`;


export const TABLA_RAMOSEGURO = `
query filtro($filtro: InputFilterRamoSeguro){
    filterRamoSeguro(filtro: $filtro){
        listado{
        ramoSeguroID
        ramoSeguro
        fijo
        }
        registros
        paginas
    }
}
`;

export const GET_ALL_TOTAL_RAMOSEGURO =`
query GetTotalRamo($filtro: InputGetRamoSeguro){
    getTotalRamoSeguro(filtro : $filtro)
      {
        ramoSeguroID
        ramoSeguro
        totalSeguro
        color
      }
    }
`;

export const getRamoSeguro = async (filtro, token) => {
    try{
        let {getRamoSeguro} = await FetchAPI.graphql({
            token,
            variables: {filtro},
            url: urlServidor,
            query: GET_RAMOSEGURO
        });

    return getRamoSeguro;
} catch({message}) {throw new Error(message);}
}


export const getAllRamoSeguro = async (variables, token) =>{
    try{
        let {getAllRamoSeguro} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_RAMOSEGURO
        });
        return getAllRamoSeguro;
    } catch({message}) {throw new Error(message);}
}

export const getTotalRamoSeguro = async (variables, token) =>{
    try{
        let {getTotalRamoSeguro} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_TOTAL_RAMOSEGURO
        });
        return getTotalRamoSeguro;
    } catch({message}) {throw new Error(message);}
}


export const seleccionRamoSeguro = async (variables, token) => {
    try{
        let allRamoSeguro = await getAllRamoSeguro(variables, token);
        let auxAllRamoSeguro = [];
        if (!Array.isArray(allRamoSeguro)) throw new Error("No se encontraron registros");
        for (let i = 0; i < allRamoSeguro.length; i++) {
            const { ramoSeguro, ramoSeguroID } = allRamoSeguro[i];
            if (!ramoSeguro.toUpperCase().trim().includes('COBERTURA')) {
                auxAllRamoSeguro.push({ value: ramoSeguroID, label: ramoSeguro.toUpperCase() });
            }
        }

        return auxAllRamoSeguro;
        // return allRamoSeguro.map(({
        //     ramoSeguroID: value, ramoSeguro: label
        // }) => ({ value, label: label.toUpperCase() }));
    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const seleccionRamoSeguroCoberturas = async (variables, token) => {
    try{
        let allRamoSeguro = await getAllRamoSeguro(variables, token);
        if(!Array.isArray(allRamoSeguro)) throw new Error ("No se encontraron registros");

        return allRamoSeguro.map(({
            ramoSeguroID: value, ramoSeguro: label
        }) => ({value, label: label.toUpperCase() }));
    }catch({message: label}){
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}



export const seleccionRamoSeguroNA = async (variables, token) => {
    try{
        let allRamoSeguro = await getAllRamoSeguro(variables, token);
        if(!Array.isArray(allRamoSeguro)) throw new Error ("No se encontraron registros");
/*
        return allRamoSeguro.map(({
            ramoSeguroID: value, ramoSeguro: label
        }) => ({value, label: label.toUpperCase() }));
*/
        let arreglo1 = [];

        arreglo1.push({label: "Todos los ramos", value: "0"});

        allRamoSeguro.forEach( ({ramoSeguroID,ramoSeguro}) => {
                arreglo1.push({label: ramoSeguro, value: ramoSeguroID});
            });

            return arreglo1;
    }catch({message: label}){
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}


export const tablaRamoSeguro = async (variable, token) => {
    try{
        let {ramoSeguroID, ramoSeguro, pagina, limite} = variable.filtro;
        let filtro = {ramoSeguroID: ramoSeguroID === null ? null : Number(ramoSeguroID), ramoSeguro, pagina, limite};
        let variables = {filtro};
        let {filterRamoSeguro} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_RAMOSEGURO
        });
            return filterRamoSeguro;

        } catch({message}) {throw new Error(message);}
    }
