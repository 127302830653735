import React, { useState, useEffect, useRef } from 'react'
import Input from '../../../_layout/genericComponents/Inputs';
import Button from '@material-ui/core/Button';
import './styles.css';
import { fetchRestAPI } from 'helpers';
import { handleAlertGeneric } from '_layout/helpers/handles';
import { handleAlertMutationGeneric } from '_layout/helpers/handles';
import { useSelector } from 'react-redux';
import { success } from '_layout/helpers/colores';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { handelAlertEliminar } from '_layout/helpers/handles';
import { handleEliminar } from '_layout/helpers/handles';

export default function MultiCondicion(props) {
    const { token, usuario } = useSelector(stDatos => stDatos.login);

    let { datosGet, setDataToSave, clearData } = props;
    const [alert, setAlert] = useState(null);
    const [actualizar, setActualizar] = useState(false);

    const cleanPart = {
        condicionID: 0,
        condicion: "",
        hallazgoB64: "",
        hallazgoFile:"",
        seguimientoB64: "",
        seguimientoFile:"",
        estatus: "CORREGIDO",
        comentarios: "",
    }

    const listaEstatus = [
        {
            label: 'CORREGIDO',
            value: 'CORREGIDO'
        },
        {
            label: 'PARCIAL',
            value: 'PARCIAL'
        },
        {
            label: 'PENDIENTE',
            value: 'PENDIENTE'
        },
        {
            label: 'NO REQUIERE ATENCIÓN',
            value: 'NO REQUIERE ATENCIÓN'
        },
    ]

    const [statePartidas, setStatePartidas] = useState([cleanPart])

    useEffect(() => {
        async function clearData() {
            setStatePartidas([cleanPart])
        }
        clearData()
    }, [clearData])

    useEffect(() => {
        async function ftEffect() {
            datosGet?.length > 0 ? setStatePartidas(datosGet) : setStatePartidas([cleanPart])
        }
        ftEffect();

    }, [datosGet]);

    useEffect(() => {
        async function dataUpdate() {
            setDataToSave(statePartidas)
        }
        dataUpdate();
    }, [statePartidas]);

    const agregarPartida = () => {
        setStatePartidas([...statePartidas, cleanPart])
    }

    // const removerPartida = (index, condicionID) => {
    //     if (condicionID > 0) {
    //         eliminarCondicion(index,condicionID);
    //     }
    //     else {
    //         const list = [...statePartidas];
    //         list.splice(index, 1);
    //         setStatePartidas(list);
    //     }

    // }



    function eliminarCondicion(index,condicionID) {
        setAlert({
            descripcion: `¿Desear eliminar el registro?`,
            title: "¡Eliminar Registro!",
            tipo: "warning",
            msjConfirmacion: "Aceptar",
            msjCancelacion: "Cancelar",
            onConfirm: async () => {
                if (condicionID>0){
                    await fetchRestAPI({ data: { condicionID }, token, url: 'recorridosCondicionesDelete' });
                }
                const list = [...statePartidas];
                list.splice(index, 1);
                setStatePartidas(list);
                setAlert(null);
            },
            onCancel: () => setAlert(null)
        });     
    }
        
    const handleChangePartidas = (e, index, campo = "condicion",base64) => {

        const list = [...statePartidas];
        if (campo == 'estatus') {
            const { value } = e.target;
            list[index]['estatus'] = value;
            setStatePartidas([...list]);
        }
        else if (campo =='condicion'){
            list[index]['condicion'] = e.target.value;
            setStatePartidas([...list]);
        }
        else if (campo =='hallazgoB64'){
            list[index]['hallazgoB64'] = base64;
            list[index]['hallazgoFile'] = e;
            setStatePartidas([...list]);
        }
        else if (campo =='seguimientoB64'){
            list[index]['seguimientoB64'] = base64;
            list[index]['seguimientoFile'] = e;
            setStatePartidas([...list]);
        }
        else if (campo =='comentarios'){
            list[index]['comentarios'] = e.target.value;
            setStatePartidas([...list]);
        }
    }

    return (<>
        <div className='contenedoresPartidas'>
            {
                statePartidas.map((partida, index) => (
                    <div key={index} class="contenedorMultiPartidas">
                        <div class="tituloPartidas">
                            {/* Subpartida # {index + 1} */}
                        </div>
                        <div class="agrupadorInputsBotones">
                            <div class="contenedorInputsPartida">
                                <div class="fila">
                                    <Input title="Condición" placeholder="Condición" id="condicion" multiline={false} value={partida.condicion}
                                        onChange={(e) => { handleChangePartidas(e, index,"condicion") }}
                                    />
                                </div>
                                <div class="fila">
                                    <div class="campo">
                                        <Input title="Hallazgo" placeholder="Hallazgo" tipo="imageupload" id="hallazgoB64" removeImage={partida.hallazgoB64 == "" ? true : false} value={partida.hallazgoB64} 
                                            onChange= {(e,base64) =>{
                                                handleChangePartidas(e, index, 'hallazgoB64',base64)
                                            }}
                                        />
                                    </div>
                                    <div class="campo">
                                        <Input title="Seguimiento" placeholder="Seguimiento" tipo="imageupload" id="seguimientoB64" removeImage={partida.seguimientoB64 == "" ? true : false} value={partida.seguimientoB64} 
                                        onChange= {(e,base64) =>{
                                            handleChangePartidas(e, index, 'seguimientoB64',base64)
                                        }}
                                        />
                                    </div>
                                </div>
                                <div class="fila">
                                    <Input title="Seleccionar Estatus" placeholder="Seleccionar Estatus" tipo="select" id="estatus" data={listaEstatus} value={partida.estatus}
                                        onChange={(e) => { handleChangePartidas(e, index, 'estatus') }}
                                    />
                                    </div>
                                    <div>
                                    <Input title="Comentarios" placeholder="Comentarios" id="comentarios" multiline={false} value={partida.comentarios}
                                        onChange={(e) => { handleChangePartidas(e, index, 'comentarios') }}
                                    />
                                </div>
                            </div>
                            <div class="botonesPartidas">
                                {
                                    <Button size="small" variant="outlined" color="primary" style={{ backgroundColor: '#388e3c', color: 'white', marginBottom:'10px' }} onClick={() => agregarPartida()}>Agregar</Button>
                                }
                                {
                                    statePartidas.length > 1 && <Button size="small" variant="outlined" color="primary" style={{ backgroundColor: '#F31818', color: 'white' }} onClick={async() => eliminarCondicion(index,partida.condicionID)}>Remover</Button>
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        {
            alert && <SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            />}
    </>)
}