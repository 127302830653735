import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const ADD_SOLSEGFIANHOGAR = `
mutation addSolSegFianHogar($input: InputAddSolSegFianHogar!){
    addSolSegFianHogar(input: $input){
        hogarID
        solSegFianID
        usuarioBitID
        nombreContratante
        rfc
        direccion
        anioEstimadoCons
        numPisos
        pisoResidencia
        numSotanos
        tipoMuro
        tipoTecho
        tipoEntrepiso
        tipoEstructura 
    }
}
`;




export const UPDATE_SOLSEGFIANHOGAR = `
mutation updateSolSegFianHogar($input: InputUpdateSolSegFianHogar!){
    updateSolSegFianHogar(input: $input){
        hogarID
        solSegFianID
        usuarioBitID
        nombreContratante
        rfc
        direccion
        anioEstimadoCons
        numPisos
        pisoResidencia
        numSotanos
        tipoMuro
        tipoTecho
        tipoEntrepiso
        tipoEstructura 
    }
}
`;




export const DELETE_SOLSEGFIANHOGAR = `
mutation deleteSolSegFianHogar($input: InputDeleteSolSegFianHogar){
    deleteSolSegFianHogar(input: $input)
}
`;

export const addSolSegFianHogar = async (variables, token) =>
{
    try{
        let {addSolSegFianHogar} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SOLSEGFIANHOGAR
        });
        return addSolSegFianHogar;
    }catch({message}) {throw new Error(message);}
}

export const updateSolSegFianHogar = async (variables, token) =>
{
    try{
        let {updateSolSegFianHogar} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SOLSEGFIANHOGAR
        });
        return updateSolSegFianHogar;
    }catch({message}) {throw new Error(message);}
}

export const deleteSolSegFianHogar = async (input, token) => {
    try{
        let {deleteSolSegFianHogar} = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_SOLSEGFIANHOGAR
        });
        return deleteSolSegFianHogar;
    }catch ({message}) {throw new Error(message);}
}
