import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const ADD_ASEGURADORA = `
mutation addAseguradora($input: InputAddAseguradora!) {
    addAseguradora(input: $input) {
        aseguradoraID
        aseguradora
    }
}
`;


export const UPDATE_ASEGURADORA =`
mutation updateAseguradora($input: InputUpdateAseguradora!) {
    updateAseguradora(input: $input) {
        aseguradoraID
        aseguradora
    }
}
`;

export const DELETE_ASEGURADORA = `
mutation deleteAseguradora($input: InputDeleteAseguradora) {
    deleteAseguradora(input: $input)
}
`;


export const addAseguradora = async (variables, token) => {
    try {

        let { addAseguradora } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_ASEGURADORA
        });

        return addAseguradora;

    } catch({message}) { throw new Error(message); }
};

export const updateAseguradora = async (variables, token) => {
    try {

        let { updateAseguradora } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_ASEGURADORA
        });

        return updateAseguradora;

    } catch({message}) { throw new Error(message); }
};


export const deleteAseguradora = async (input, token) => {
    try {

        let { deleteAseguradora } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_ASEGURADORA
        });

        return deleteAseguradora;

    } catch({message}) { throw new Error(message); }
};
