import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const ADD_ACTIVOASEG = `
mutation addActivosAsegurado($input: InputAddActivosAsegurado!){
    addActivosAsegurado(input: $input){
        activoAsegID
        usuarioBitID
        activoaseg
        clasificacion
        tipoActivo
    }
}

`;

export const UPDATE_ACTIVOASEG = `
mutation updateActivosAsegurado($input: InputUpdateActivosAsegurado!){
    updateActivosAsegurado(input: $input){
        activoAsegID
        usuarioBitID
        activoaseg
        clasificacion
        tipoActivo
    }
    
}
`;

export const DELETE_ACTIVOASEG = `
mutation deleteActivosAsegurado($input: InputDeleteActivosAsegurado){
    deleteActivosAsegurado(input: $input)
}
`;


export const addActivosAsegurado = async (variables, token) =>
{
    try
    {let {addActivosAsegurado} =await FetchAPI.graphql({
        token,
        variables,
        url: urlServidor,
        query: ADD_ACTIVOASEG
    });

    return addActivosAsegurado;
    } catch({message}) {throw new Error(message);}
};

export const updateActivosAsegurado = async (variables, token) => {
    try {
        let{updateActivosAsegurado} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_ACTIVOASEG
        });
        return updateActivosAsegurado;
    } catch ({message}) {throw new Error(message);}
};

export const deleteActivosAsegurado = async (input, token) => {
    try {
        let {deleteActivosAsegurado} = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_ACTIVOASEG
        });
        return deleteActivosAsegurado;
    } catch ({message}) {throw new Error(message);}
};