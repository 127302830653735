import { Restaurant } from "@material-ui/icons";
import { FetchAPI } from "@renedelangel/helpers";
import moment from "moment";
import { urlServidor } from '../../../helpers';
import { getArchivo, deleteFileStorage, blobToBase64 } from "../../../helpers/serverStorage";

export const terminarRecorrido = async (variables, token) => {
    let Query = `
    mutation terminarRecorrido($input: InputTerminarRecorrido){
        terminarRecorrido(input: $input)
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const terminarSeguimiento = async (variables, token) => {
    let Query = `
    mutation terminarSeguimiento($input: InputTerminarRecorrido){
        terminarSeguimiento(input: $input)
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const tablaRecorridos = async(variables, token) => {
    let Query = `
    query filterRecorridos($filtro: InputFilterRecorridos){
        filterRecorridos(filtro:$filtro){
        listado{
            recorridoID
            empresaID
            descripcion
            fechaRecorrido
            empresa
            folioInterno
            estatus
            finalizado
            fechaFinalizado
            ubicacionID
            ubicacion

        }
        registros
        paginas
        }
    }
    `;

    const tResult = await Ejecutar(variables, token, Query);
    for (const [key,
            value
        ] of Object.entries(tResult)) {
        if (value === null) {
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getRecorrido = async(variables, token) => {
    let Query = `
    query get($filtro: InputGetRecorridos){
        getRecorrido(filtro:$filtro) {
            recorridoID
            empresaID
            descripcion
            fechaRecorrido
            estatus
            finalizado
            fechaFinalizado
            ubicacionID
            ubicacion
        }
    }
    `;

    let resultado = await Ejecutar({ filtro: variables }, token, Query);
    for (const [key, value] of Object.entries(resultado)) {
        if (value === null) {
            resultado[key] = "";
        }
    }

    return resultado;
}

export const addRecorrido = async(variables, token) => {
    let Query = `
    mutation addRecorrido($input: InputAddRecorrido!) {
        addRecorrido(input: $input) {
            recorridoID
            empresaID
            descripcion
            fechaRecorrido
            estatus
            finalizado
            fechaFinalizado
            ubicacionID
            ubicacion
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const updateRecorrido = async(variables, token) => {
    let Query = `
    mutation updateRecorrido($input: InputUpdateRecorrido!) {
        updateRecorrido(input: $input) {
            recorridoID
            empresaID
            descripcion
            fechaRecorrido
            estatus
            finalizado
            fechaFinalizado
            ubicacionID
            ubicacion
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const deleteRecorrido = async(variables, token) => {
    let Query = `
    mutation deleteRecorrido($input: InputDeleteRecorrido) {
        deleteRecorrido(input: $input)
    }
    `;

    let valor = variables["recorridoID"];
    valor = {
        recorridoID: valor
    };

    let tResult = await Ejecutar({
        input: valor
    }, token, Query);
    /*let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);*/

    return tResult;
};

export const getAllRecorridos = async (variables, token) => {
    let Query = `
    query getAllRecorridos($filtro: InputGetRecorridos){
        getAllRecorridos(filtro:$filtro){
            recorridoID
            empresaID
            descripcion
            fechaRecorrido
            finalizado
            fechaFinalizado
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const tablaRecorridosDetalle = async(variables, token) => {
    let Query = `
    query filterRecorridosDetalle($filtro: InputFilterRecorridosDetalles){
        filterRecorridosDetalle(filtro:$filtro){
        listado{
            recorridoDetalleID
            recorridoID
            descripcion
            descripcion2
            seguimiento
            fotografia1
            fotografia2
            fotografia3
            fotografia4
            ubicacionID
            ubicacion
        }
        registros
        paginas
        }
    }
    `;

    const tResult = await Ejecutar(variables, token, Query);

    for (const [key,
            value
        ] of Object.entries(tResult)) {
        if (value === null) {
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getRecorridosDetalle = async(variables, token) => {
    let Query = `
    query get($filtro: InputGetRecorridosDetalles){
        getRecorridosDetalle(filtro:$filtro) {
            recorridoDetalleID
            recorridoID
            descripcion
            descripcion2
            seguimiento
            fotografia1
            fotografia2
            fotografia3
            fotografia4
            storageID1
            storageID2
            storageID3
            storageID4
            ubicacionID
        }
    }
    `;

    let resultado = await Ejecutar({ filtro: variables }, token, Query);
    for (const [key, value] of Object.entries(resultado)) {
        if (value === null) {
            resultado[key] = "";
        }
    }

    resultado["rutafotografia1"] = resultado["fotografia1"];
    let foto1 = resultado.fotografia1 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia1, token) :
        "";
    resultado["fotografia1"] = foto1;

    resultado["rutafotografia2"] = resultado["fotografia2"];
    let foto2 = resultado.fotografia2 ?
        await getArchivo('/private/segumex' + resultado.fotografia2, token) :
        "";
    resultado["fotografia2"] = foto2;

    resultado["rutafotografia3"] = resultado["fotografia3"];
    let foto3 = resultado.fotografia3 ?
        await getArchivo('/private/segumex' + resultado.fotografia3, token) :
        "";
    resultado["fotografia3"] = foto3;

    resultado["rutafotografia4"] = resultado["fotografia4"];
    let foto4 = resultado.fotografia4 ?
        await getArchivo('/private/segumex' + resultado.fotografia4, token) :
        "";
    resultado["fotografia4"] = foto4;

    return resultado;
}

export const addRecorridosDetalle = async(variables, token) => {
    let Query = `
    mutation addRecorridosDetalle($input: InputAddRecorridosDetalle!) {
        addRecorridosDetalle(input: $input) {
            recorridoDetalleID
            recorridoID
            descripcion 
            descripcion2
            seguimiento
            fotografia1
            fotografia2
            fotografia3
            fotografia4
            ubicacionID
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const updateRecorridosDetalle = async(variables, token) => {
    let Query = `
    mutation updateRecorridosDetalle($input: InputUpdateRecorridosDetalle!) {
        updateRecorridosDetalle(input: $input) {
            recorridoDetalleID
            recorridoID
            descripcion
            descripcion2
            seguimiento
            fotografia1
            fotografia2
            fotografia3
            fotografia4
            ubicacionID
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const deleteRecorridosDetalle = async(variables, token) => {
    let Query = `
    mutation deleteRecorridosDetalle($input: InputDeleteRecorridosDetalle) {
        deleteRecorridosDetalle(input: $input)
    }
    `;

    let valor = variables["recorridoDetalleID"];
    valor = {
        recorridoDetalleID: valor
    };

    let tResult = await Ejecutar({
        input: valor
    }, token, Query);
    /*let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);*/

    return tResult;
};

export const getAllRecorridosDetalle = async (variables, token) => {
    let Query = `
    query getAllRecorridosDetalle($filtro: InputGetRecorridosDetalle){
        getAllRecorridosDetalle(filtro:$filtro){
            recorridoDetalleID
            recorridoID
            descripcion
            descripcion2
            seguimiento
            fotografia1
            fotografia2
            fotografia3
            fotografia4
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}
