import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
//storage
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import { RiFileExcel2Fill } from "react-icons/ri";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import { FaRoute } from "react-icons/fa";

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import moment from 'moment';
import { urlServidor } from '../../../helpers';
import RecorridosDetalle from './RecorridosDetalle';
import Modal from '../../../_layout/genericComponents/Modal';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import * as Permisos from '../Permisos';

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;

function Recorridos() {
    const { token, usuario } = useSelector(stDatos => stDatos.login);
    const { Encrypt } = Encriptar;
    let anioActual = new Date().getFullYear();
    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";

    let Empresas = [];
    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [stEmpresas, setEmpresas] = useState(Empresas)
    let [datosPDF, setdatosPDf] = useState({});
    const [seleccionables, setSeleccionables] = useState({});
    let [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [openDetalle, setOpenDetalle] = useState(false);

    const [recorridoID, setRecorridoID] = useState(0);
    const [folioInterno, setFolioInterno] = useState('');
    const [estatus, setEstatus] = useState('');
    const [stEmpresaID, setEmpresaID] = useState(null);

    const [actualizarDetalle, setActualizarDetalle] = useState(false);

    let titleAccion = stDatos.recorridoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Recorrido`;
    let ArrayRef = [];

    let acciones = [
       {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "recorridoID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros,
                token,
                setState,
                setOpen,
                setAlert,
                asyncGet: Consultas.getRecorrido
            })
        },
        {
            id: "verPartidas",
            icono: PlaylistAddCheckOutlinedIcon,
            color: rose,
            descripcion: "Mostrar Partidas",
            parametros: [{ campo: "folioInterno" }, { campo: "recorridoID" }, { campo: "estatus" },{ campo: "empresaID"}],
            disabled: { multiseleccion: true },
            onClick: ({ recorridoID, folioInterno, estatus, empresaID }) => AbrirPartida(recorridoID, folioInterno, estatus,empresaID)
        },
        {
            accionID: [4],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Terminar Recorrido",
            parametros: [{ campo: "recorridoID" }, { campo: "estatus" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => TerminarRecorrido(parametros)
        },
        {
            accionID: [9],
            icono: AssignmentTurnedInIcon,
            color: primary,
            descripcion: "Terminar Seguimiento",
            parametros: [{ campo: "recorridoID" }, { campo: "estatus" }, { campo: "folioInterno" }, { campo: "finalizado" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => TerminarSeguimiento(parametros)
        },
        // {
        //     accionID: [5],
        //     icono: PictureAsPdfIcon,
        //     color: primary,
        //     descripcion: "Descargar PDF",
        //     parametros: [{ campo: "recorridoID" }, { campo: "folioInterno" }],
        //     disabled: { multiseleccion: true },
        //     onClick: ({ recorridoID, folioInterno }) => AuxDescargar(recorridoID, folioInterno, 'PDF', 'PDF')
        // },
        {
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "recorridoID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ recorridoID, folioInterno }) => AuxDescargar(recorridoID, folioInterno, 'excel', 'excel')
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "recorridoID" },
            { campo: "folioInterno", variable: "folioInterno" }
            ],
            disabled: { multiseleccion: true },
            onClick: ({ recorridoID, folioInterno }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el recorrido ${folioInterno}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token,
                    setAlert,
                    setActualizar,
                    actualizar,
                    parametros: { recorridoID, folioInterno },
                    asyncDelete: Consultas.deleteRecorrido
                })
            })
        },
    ];

    function AuxDescargar(recorridoID, folioInterno, formatoReporte, tipoReporte) {
        setdatosPDf({ "recorridoID": recorridoID, "folioInterno": folioInterno, formatoReporte, tipoReporte });
        datosPDF = { "recorridoID": recorridoID, "folioInterno": folioInterno, formatoReporte, tipoReporte };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar el formato de impresión del recorrido?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato de impresión del recorrido ",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el formato de impresión del recorrido ' + folioInterno,
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("recorridoID", datosPDF.recorridoID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);
        formdata.append("tipoReporte", datosPDF.tipoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'Recorrido' + datosPDF.folioInterno
        await fetch(`${urlServidor}/service/reportes/ReporteRecorridos`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte} ${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    let infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "recorridoID",
        color: primary,
        title: "Recorrido",
        iconTable: < FaRoute />,
        headers: [
            { variable: "recorridoID", descripcion: "ID", hide: true },
            { variable: "folioInterno", descripcion: "Folio interno" },
            { variable: "empresaID", descripcion: "empresaID",hide:true },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "ubicacion", descripcion: "Ubicación" },
            { variable: "fechaRecorrido", descripcion: "Fecha del Recorrido" },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "finalizado", descripcion: "Finalizado" },
            { variable: "fechaFinalizado", descripcion: "Fecha Finalizado" }
        ],
        responsiveTitle: ["empresa", "folioInterno"],
        rangoFechas: {
            ini: "fechaInicio", fin: "fechaFinal",
            fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"),
            fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD")
        },
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }, { columnas: [3], alineacion: "center" }
        ],
        selectFilter: [{
            campo: "empresaID",
            title: "Empresa",
            retorna: "number",
            limpiarFiltro: "Todos",
            data: stEmpresas ? stEmpresas : [],
            grid: { md: 3, lg: 3 }
        }],
        formato: [{ columnas: ["finalizado"], tipo: "estatus" },
        {
            columnas: ["fechaRecorrido", "fechaFinalizado"], tipo: "fecha", onlyDate: true
        }]
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["recorridoID"] = { error: false, helperText: "" };
    cleanErrorState["empresaID"] = { error: false, helperText: "" };
    cleanState["recorridoID"] = 0;
    cleanState["empresaID"] = 0;
    //cleanState["ubicacionID"] = 0;
    InicializarInputs("empresaID", "Empresa*", "", usuario["empresaID"], "", 0);
    InicializarInputs("fechaRecorrido", "Fecha de recorrido *", "", new Date(), "", 0, "center");
    InicializarInputs("estatus", "Estatus", "", "EN PROCESO");
    //InicializarInputs("ubicacionID", "Ubicación / Instalación *");
    InicializarInputs("descripcion", "Descripción *");

    //-----------------------------------------------------
    InputsConID["empresaID"].grid = { md: 6, lg: 6 };
    InputsConID["estatus"].grid = { md: 3, lg: 3 };
    InputsConID["fechaRecorrido"].grid = { md: 3, lg: 3 };
    //InputsConID["ubicacionID"].grid = { md: 12, lg: 12 };
    InputsConID["descripcion"].grid = { md: 12, lg: 12 };

    InputsConID["estatus"].disabled = true;
    InputsConID["empresaID"].disabled = stDatos["estatus"] === "EN PROCESO" ? false : true;
    InputsConID["fechaRecorrido"].disabled = stDatos["estatus"] === "EN PROCESO" ? false : true;
    InputsConID["descripcion"].disabled = stDatos["estatus"] === "EN PROCESO" ? false : true;
    //InputsConID["ubicacionID"].disabled = stDatos["estatus"] === "EN PROCESO" ? false : true;

    //-----------------------------------------------------


    InputsConID["fechaRecorrido"].tipo = "datetimepicker";
    InputsConID["fechaRecorrido"].timeFormat = false;
    InputsConID["fechaRecorrido"].onlyDate = true;
    InputsConID["fechaRecorrido"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaRecorrido");
    };

    InputsConID["empresaID"].tipo = "autocomplete";
    InputsConID["empresaID"].data = stEmpresas;
    InputsConID["empresaID"].disabled = stDatos.recorridoID ? true : false;
    InputsConID["empresaID"].onChange = (e) =>{
        if (e?.value){
            setState({...stDatos,empresaID: e.value})
        }
    };

   /* InputsConID["ubicacionID"].tipo = "autocomplete";
    InputsConID["ubicacionID"].data = seleccionables.Ubicaciones;
    InputsConID["ubicacionID"].onChange = (e) =>{
        if (e?.value){
            setState({...stDatos,ubicacionID: e.value})
        }
    };*/
    // InputsConID["ubicacionID"].disabled = stDatos.recorridoID ? true : false;

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        accionID: stDatos["recorridoID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["fechaRecorrido"],
            mensajeCorrecto: `El recorrido se ${stDatos["recorridoID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState,
            condicion,
            ref,
            keyError,
            mensajeError,
            loader: setLoader,
            disabled: setDisabled,
            errorState: setErrorState
        });
    }

    async function handleValidaciones() {

        let error;
        let validaciones = [
            {
                condicion: !stDatos["empresaID"] || stDatos["empresaID"] === 0,
                ref: ArrayRef["empresaID"],
                keyError: "empresaID",
                mensajeError: "Debe especificar la empresa"
            },
            {
                condicion: !stDatos["fechaRecorrido"] || trim(stDatos["fechaRecorrido"]) === "",
                ref: ArrayRef["fechaRecorrido"],
                keyError: "fechaRecorrido",
                mensajeError: "Debe especificar la fecha de recorrido"
            } 
          ,/*  {
                condicion: !stDatos["ubicacionID"] || ["ubicacionID"] === 0,
                // ref: ArrayRef["ubicacionID"],
                keyError: "ubicacionID",
                mensajeError: "Debe especificar la ubicación"
            }, */
            {
                condicion: !stDatos["descripcion"] || trim(stDatos["descripcion"]) === "",
                ref: ArrayRef["descripcion"],
                keyError: "descripcion",
                mensajeError: "Debe especificar la descripción"
            },
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["recorridoID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            }
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            let error = await handleValidaciones();

            let DatosGuardar = {
                recorridoID: stDatos["recorridoID"],
                empresaID: stDatos["empresaID"],
                descripcion: stDatos["descripcion"],
                fechaRecorrido: stDatos["fechaRecorrido"],
                estatus: stDatos["estatus"],
               // ubicacionID: stDatos["ubicacionID"]
            };

            DatosGuardar["fechaRecorrido"] = formatearFecha(DatosGuardar["fechaRecorrido"]);
            DatosGuardar["recorridoID"] = parseInt(DatosGuardar["recorridoID"]);
            DatosGuardar["empresaID"] = parseInt(DatosGuardar["empresaID"]);
           // DatosGuardar["ubicacionID"] = parseInt(DatosGuardar["ubicacionID"]);

            delete DatosGuardar["ubicacion"];
            delete DatosGuardar["finalizado"];
            delete DatosGuardar["fechaFinalizado"];
            delete DatosGuardar["finalizadoLetra"];

            if (error) return error;

            if (stDatos["recorridoID"]) {
                await Consultas.updateRecorrido(DatosGuardar, token);
            } else {
                delete DatosGuardar["recorridoID"];
                await Consultas.addRecorrido(DatosGuardar, token);
            }
        }

        return ftGuardar();
    }

    function formatearFecha(pFecha) {
        if (moment(pFecha).isValid() === false) {
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));
    }

    async function ObtenerEmpresas() {
        let Respuesta = await ConsultasEmpresas.seleccionEmpresa({ filtro: { activa: 1 } }, token);
        setEmpresas(Respuesta);
    }

     useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();
                // let Ubicaciones = await seleccionUbicaciones({ filtro: {} }, token);
                // setSeleccionables({ Ubicaciones });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, []);

    function AbrirPartida(recorridoID, folioInterno, estatus,empresaID) {
        setRecorridoID(parseInt(recorridoID));
        setFolioInterno(folioInterno);
        setEstatus(estatus);
        setEmpresaID(empresaID);
        setOpenDetalle(true);
        setActualizarDetalle(!actualizarDetalle);
    }

    function TerminarRecorrido(parametros) {
        if (parametros.estatus === 'TERMINADO') {
            setAlert({
                descripcion: 'Este registro ya se encuentra terminado',
                title: "Terminar registro",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
            return;
        }


        setAlert({
            descripcion: 'El recorrido ' + parametros.folioInterno + ' pasará a terminado',
            title: "Terminar registro",
            tipo: "success-two",
            msjConfirmacion: "Aceptar",
            onConfirm: () => ftTerminarRecorrido(parametros.recorridoID),
            msjCancelacion: "Cancelar",
            onCancel: () => setAlert(null)
        });
    }


    function TerminarSeguimiento(parametros) {
        if (parametros.estatus !== 'TERMINADO' || parametros.finalizado) {
            setAlert({
                descripcion: parametros.finalizado ? 'Este registro ya esta finalizado' : 'Este registro aun esta en proceso',
                title: "Terminar seguimiento",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
            return;
        }
        setAlert({
            descripcion: 'El recorrido ' + parametros.folioInterno + ' pasará a terminado',
            title: "Terminar seguimiento",
            tipo: "success-two",
            msjConfirmacion: "Aceptar",
            onConfirm: () => ftTerminarSegumiento(parametros.recorridoID),
            msjCancelacion: "Cancelar",
            onCancel: () => setAlert(null)
        });
    }

    async function ftTerminarRecorrido(recorridoID) {
        try {
            await Consultas.terminarRecorrido({ recorridoID }, token);
            setActualizar(!actualizar);
            setAlert(null);
        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    async function ftTerminarSegumiento(recorridoID) {
        try {
            await Consultas.terminarSeguimiento({ recorridoID }, token);
            setActualizar(!actualizar);
            setAlert(null);
        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla infoTabla={infoTabla}
            asyncData={Consultas.tablaRecorridos}
            token={token} />
        <FormularioModal open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["empresaID"]}
        />
        <Modal
            open={openDetalle}
            onClose={() => setOpenDetalle(false)}
            title={"Partidas " + folioInterno}>
            <RecorridosDetalle
                recorridoID={recorridoID}
                folioInterno={folioInterno}
                estatus={estatus}
                actualizarDetalle={actualizarDetalle}
                setActualizarDetalle={setActualizarDetalle} 
                pEmpresaID={stEmpresaID}
                />
        </Modal>
        {
            alert && < SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            />}
    </>
    );
}

export default Recorridos;
