import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from "react-redux";

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { useErrorToken } from '../../../hooks/errores';
import Loader from '../../../_layout/genericComponents/Loader';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { FaTasks } from "react-icons/fa";


import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { seleccionUsuario } from '../Usuarios/Consultas';

import { getGenBitacora, tablaGenBitacora, tablaGenBitacoraDetalle } from '../../../querys/General/Bitacora/metodos';
import { selectEnumEstatus } from '../../../querys/Enums/metodos'


import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import moment from 'moment';


const { trim } = Validaciones;

function Bitacora( ) {

    const { token } = useSelector(state => state.login)
    const cleanState = { bitacoraID: null  };
    const cleanErrorState = {
        departamento: { error: false, helperText: ""  },
        inventarios: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    let titleAccion = "Visualizar Detalle" ;
    let titleModal = `${titleAccion} Bitacora`;

    let departamentoRef = useRef(null);
    let bitacoraRef = useRef(false);

    const acciones = [{
        icono: RemoveRedEyeIcon,
        color: primary,
        descripcion: "Ver Detalle",
        parametros: [{ campo: "bitacoraID" }
        ],
        disabled: { multiseleccion: true },
        onClick: ({bitacoraID}) => {

        setState( state => (
            {
                ...state, bitacoraID: bitacoraID
            }) );

            setOpen(true)

      }
    }];

    const botones = [ {
        icono: SyncIcon,
        color: primary,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "bitacoraID",
        color: primary,
        title: "Bitácora",
        iconTable: <FaTasks />,
       headers: [
            {variable: "usuarioID", descripcion: "Usuario ID", hide: true},
            {variable: "bitacoraID", descripcion: "ID", hide: true },
            {variable: "fechaHora", descripcion: "Fecha"},
            {variable: "usuario", descripcion: "Usuario" },
            {variable: "modulo", descripcion: "Modulo" },
            {variable: "opcion", descripcion: "Opción" },
            {variable: "evento", descripcion: "Evento" }
        ],
         responsiveTitle: ["fechaHora"],
        filter: [

            { campo: "usuario", placeholder: "Usuario"},
            { campo: "modulo", placeholder: "Módulo"},
            { campo: "opcion", placeholder: "Opción"},
            { campo: "evento", placeholder: "Evento"}

        ],
        alineacion: [{ columnas: [0], alineacion: "left" },{ columnas: [1], alineacion: "center" }],
         formato: [{ columnas: ["fechaHora"], tipo: "fecha" }],
        rangoFechas: { ini: "fechaHoraInicio", fin: "fechaHoraFin", fechaInicial:   moment().startOf('date')
        , onlyDate: false  },
        selectFilter: [
            {
                campo: "usuarioID", placeholder: "Seleccionar Usuario",
                retorna: "number", limpiarFiltro: "Todos los Usuarios",
                data: seleccionables.Usuarios ? seleccionables.Usuarios : []
            }]
    };

    const infoTablaDetalle = {


        actualizar,
        id: "bitacoraID",
        hideHeader:true,
        color: primary,
        title: "Detalle de Bitácora",
        iconTable: <FaTasks />,
       headers: [
            {variable: "bitacoraID", descripcion: "ID", hide: true },
            {variable: "fechaHora", descripcion: "Fecha"},
            {variable: "evento", descripcion: "Evento" },
            {variable: "columnaAfectada", descripcion: "Columna" },
            {variable: "datoAnterior", descripcion: "Dato Anterior" } ,
            {variable: "datoNuevo", descripcion: "Dato Nuevo" }
        ],
         responsiveTitle: ["fechaHora"],
        filter: [
            { campo: "evento", placeholder: "Dato Nuevo"},
            { campo: "columna", placeholder: "Columna"},
            { campo: "datoAnterior", placeholder: "Dato Anterior"},
            { campo: "datoNuevo", placeholder: "Dato Nuevo"}

        ],
        alineacion: [{ columnas: [0], alineacion: "left" },{ columnas: [1], alineacion: "center" }],
        formato: [{ columnas: ["fechaHora"], tipo: "fecha" }]

    } ;



    const accionesFormulario = [ {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleClose
    }];


    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    useEffect(()=> {

        async function ftEffect() {

            try {

             let Usuarios = await seleccionUsuario({} , token);

            setSeleccionables({ Usuarios });
            } catch ({ message }) {
            setAlert({
              descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
              title: "¡Una disculpa!",
              tipo: danger,
              msjConfirmacion: "Aceptar",
              onConfirm: () => setAlert(null)
            });
            setErrorToken(message);
          }

        } ftEffect();
      }, [actualizar]);


    return( !tokenError && seleccionables.Usuarios ?  <>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaGenBitacora}
            //parametrosFijos={{ inventarios }}
            token={token}
        />
       {
           open &&  <Modal
            open= {open}
            title={"Detalle"}
            onClose={handleClose}
            >
            <Tabla
                infoTabla={infoTablaDetalle}
                asyncData={tablaGenBitacoraDetalle}
                parametrosFijos={{ bitacoraID:state.bitacoraID}}
                token={token}
            />
        </Modal>
       }

    </>: <Loader />
     );

}

export default Bitacora;
