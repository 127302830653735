import React, { useState } from 'react';

function SeccionX({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      mercanciaCargaGen: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      maquinariaEquiElect: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      mercanciaRefri: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      graneles: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      minerales: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      productosQuimicos: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      aceitesVegatales: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      chatarra: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      aceroDesprotegido: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      mercanciaPesada: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      animalesVivos: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      mercanciaConten: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      contenedoresVacios: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      vehiculos: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      cargaIMDG: { metodoCarga: '', tonelajeAnual: 0, cargaPropia: 0 },
      B: { respuesta: 0, explicacion: '' },
      B1: { respuesta1: 0,  respuesta2: 0, respuesta3: 0, explicacion: '' },
      C: { respuesta: 0, explicacion: '' },
      D: { promedio: '', maximo: '' },
      E: { respuesta1: 0,  respuesta2: 0, explicacion: '' },
      F: { respuesta: 0, explicacion: '' },
      G: { respuesta: 0, explicacion: '' },
      H: { respuesta: 0, explicacion: '' },
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(ejercicio, columna, value) {
    if (columna === "metodoCarga"|| columna === "respuesta" || columna === "explicacion"){
      value = value
    }else{
      value = ftFormatoNumerico(value);
    }
    
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;

    if (respuestaAux[ejercicio]["respuesta"] === 0) {
      respuestaAux[ejercicio]["explicacion"] = '';
    }

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));

  }

  
  function handleChangeValueIncisos(ejercicio, columna, value) {
    
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;

    if (respuestaAux[ejercicio]["respuesta2"] === 0 && respuestaAux[ejercicio]["respuesta3"] === 0) {
      respuestaAux[ejercicio]["explicacion"] = '';
    }


    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));

  }



  function ftFormatoNumerico(value) {
    value = value.replaceAll(",", "");
    if (!value) {
      return "0";
    }

    if (isNaN(value)) {
      return "0";
    }

    let parts = value.toString().split(".");
    parts[0] = parseInt(parts[0]).toString();
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      return parts.join(".");
    } else {
      return parts[0];
    }
  }

  let anchoColumnasImportes = "110px";
  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          X. Operaciones de Estibaje:
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
          A) Describa las mercaderías manipuladas:
          </div>
        <br />
         <div className="cSeccionTabla" style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px" }}>
          <div className="cContenedorTabla">
            <div className="cTabla">
              <div className="cRowEncabezado">
                <div className="cCelda" style={{ width: "200px" }}>
                  Tipo de Mercancia
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  Metodo de Carga
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Tonelaje Anual
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                 %Carga Propia
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Mercancías/Carga General"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["mercanciaCargaGen"].metodoCarga} onChange={event => handleChangeValue("mercanciaCargaGen", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaCargaGen"].tonelajeAnual} onChange={event => handleChangeValue("mercanciaCargaGen", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaCargaGen"].cargaPropia} onChange={event => handleChangeValue("mercanciaCargaGen", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Maquinaria o Equipos Electrónicos"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["maquinariaEquiElect"].metodoCarga} onChange={event => handleChangeValue("maquinariaEquiElect", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["maquinariaEquiElect"].tonelajeAnual} onChange={event => handleChangeValue("maquinariaEquiElect", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["maquinariaEquiElect"].cargaPropia} onChange={event => handleChangeValue("maquinariaEquiElect", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Mercancía Refrigerada"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["mercanciaRefri"].metodoCarga} onChange={event => handleChangeValue("mercanciaRefri", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaRefri"].tonelajeAnual} onChange={event => handleChangeValue("mercanciaRefri", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaRefri"].cargaPropia} onChange={event => handleChangeValue("mercanciaRefri", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Graneles"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["graneles"].metodoCarga} onChange={event => handleChangeValue("graneles", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["graneles"].tonelajeAnual} onChange={event => handleChangeValue("graneles", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["graneles"].cargaPropia} onChange={event => handleChangeValue("graneles", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Minerales"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["minerales"].metodoCarga} onChange={event => handleChangeValue("minerales", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["minerales"].tonelajeAnual} onChange={event => handleChangeValue("minerales", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["minerales"].cargaPropia} onChange={event => handleChangeValue("minerales", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Productos Químicos"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["productosQuimicos"].metodoCarga} onChange={event => handleChangeValue("productosQuimicos", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["productosQuimicos"].tonelajeAnual} onChange={event => handleChangeValue("productosQuimicos", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["productosQuimicos"].cargaPropia} onChange={event => handleChangeValue("productosQuimicos", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Aceites Vegetales"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["aceitesVegatales"].metodoCarga} onChange={event => handleChangeValue("aceitesVegatales", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["aceitesVegatales"].tonelajeAnual} onChange={event => handleChangeValue("aceitesVegatales", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["aceitesVegatales"].cargaPropia} onChange={event => handleChangeValue("aceitesVegatales", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Chatarra"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["chatarra"].metodoCarga} onChange={event => handleChangeValue("chatarra", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["chatarra"].tonelajeAnual} onChange={event => handleChangeValue("chatarra", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["chatarra"].cargaPropia} onChange={event => handleChangeValue("chatarra", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Acero Desprotegido"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["aceroDesprotegido"].metodoCarga} onChange={event => handleChangeValue("aceroDesprotegido", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["aceroDesprotegido"].tonelajeAnual} onChange={event => handleChangeValue("aceroDesprotegido", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["aceroDesprotegido"].cargaPropia} onChange={event => handleChangeValue("aceroDesprotegido", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Mercancía Pesada*"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["mercanciaPesada"].metodoCarga} onChange={event => handleChangeValue("mercanciaPesada", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaPesada"].tonelajeAnual} onChange={event => handleChangeValue("mercanciaPesada", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaPesada"].cargaPropia} onChange={event => handleChangeValue("mercanciaPesada", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Animales Vivos"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["animalesVivos"].metodoCarga} onChange={event => handleChangeValue("animalesVivos", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["animalesVivos"].tonelajeAnual} onChange={event => handleChangeValue("animalesVivos", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["animalesVivos"].cargaPropia} onChange={event => handleChangeValue("animalesVivos", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Mercancía en Contenedores"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["mercanciaConten"].metodoCarga} onChange={event => handleChangeValue("mercanciaConten", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaConten"].tonelajeAnual} onChange={event => handleChangeValue("mercanciaConten", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["mercanciaConten"].cargaPropia} onChange={event => handleChangeValue("mercanciaConten", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Contenedores Vacíos"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["contenedoresVacios"].metodoCarga} onChange={event => handleChangeValue("contenedoresVacios", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["contenedoresVacios"].tonelajeAnual} onChange={event => handleChangeValue("contenedoresVacios", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["contenedoresVacios"].cargaPropia} onChange={event => handleChangeValue("contenedoresVacios", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Vehículos"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["vehiculos"].metodoCarga} onChange={event => handleChangeValue("vehiculos", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["vehiculos"].tonelajeAnual} onChange={event => handleChangeValue("vehiculos", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["vehiculos"].cargaPropia} onChange={event => handleChangeValue("vehiculos", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "200px" }}>
                  {"Carga “IMDG”"}
                </div>
                <div className="cCelda" style={{ width: "200px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["cargaIMDG"].metodoCarga} onChange={event => handleChangeValue("cargaIMDG", "metodoCarga", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["cargaIMDG"].tonelajeAnual} onChange={event => handleChangeValue("cargaIMDG", "tonelajeAnual", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["cargaIMDG"].cargaPropia} onChange={event => handleChangeValue("cargaIMDG", "cargaPropia", event.target.value)} disabled={disabled} />
                </div>
              </div>
              * por ejemplo grúas, garfios, cintas transportadoras, redes o eslingas, grúas para container conducidos en ro/ro, etc
            </div>
          </div>
        </div> 
        </div> 
        <div className="ElementoPregunta">
          <div className="Pregunta">
            B) ¿Es el solicitante responsable por las operaciones  de estibaje?
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("B", "respuesta", (stRespuestasLocal["B"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("B", "respuesta", (stRespuestasLocal["B"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["B"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor explique en que consiste:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["B"].explicacion} onChange={event => handleChangeValue("B", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
          <div className="Pregunta">
            1) Es el equipo utilizado
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("B1", "respuesta1", (stRespuestasLocal["B1"].respuesta1 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B1"].respuesta1 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B1"].respuesta1 === 1) ? " cSelec" : "")}>PROPIO</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("B1", "respuesta2", (stRespuestasLocal["B1"].respuesta2 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B1"].respuesta2 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B1"].respuesta2 === 1) ? " cSelec" : "")}>EN LEASING</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("B1", "respuesta3", (stRespuestasLocal["B1"].respuesta3 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B1"].respuesta3 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B1"].respuesta3 === 1) ? " cSelec" : "")}>RENTADO</div>
          </div>
          {(stRespuestasLocal["B1"].respuesta2 === 1 || stRespuestasLocal["B1"].respuesta3 === 1) ?
            <><div className="Pregunta">
              ¿De ser En Leasing o Rentado, es el equipo operado por el solicitante o por su dueño?
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["B1"].explicacion} onChange={event => handleChangeValueIncisos("B1", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
          </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            C) ¿Realiza el solicitante almacenamiento de carga en la Terminal?	
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["C"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor indique las responsabilidades asumidas por el solicitante:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["C"].explicacion} onChange={event => handleChangeValue("C", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            D)	Valor de la carga almacenada por mes: 
          </div>
          <br />
          <div className="RespuestasInput">
            Promedio:
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.D.promedio} onChange={event => handleChangeValue("D", "promedio", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
          <div className="RespuestasInput">
            Máximo:
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.D.maximo} onChange={event => handleChangeValue("D", "maximo", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            E) ¿Ofrece el solicitante tanques para almacenamiento liquido?
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("E", "respuesta1", (stRespuestasLocal["E"].respuesta1 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["E"].respuesta1 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["E"].respuesta1 === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("E", "respuesta1", (stRespuestasLocal["E"].respuesta1 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["E"].respuesta1 === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["E"].respuesta1 === 0) ? " cSelec" : "")}>NO</div>
          </div>
          <div className="Pregunta">
            Asimismo, cuenta el solicitante con algún tipo de póliza de riesgo ambiental:
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("E", "respuesta2", (stRespuestasLocal["E"].respuesta2 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["E"].respuesta2 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["E"].respuesta2 === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("E", "respuesta2", (stRespuestasLocal["E"].respuesta2 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["E"].respuesta2 === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["E"].respuesta2 === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["E"].respuesta1 === 1) ?
            <><div className="Pregunta">
              Por favor indique material de construcción, cantidad, edad y capacidad de dichos tanques:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["E"].explicacion} onChange={event => handleChangeValueIncisos("E", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            F) Se realiza algún tipo de operación de manipuleo de carga sobre agua o de buque a buque (“Lighterage”)	
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("F", "respuesta", (stRespuestasLocal["F"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["F"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["F"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("F", "respuesta", (stRespuestasLocal["F"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["F"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["F"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["F"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor indique en que consiste:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["F"].explicacion} onChange={event => handleChangeValue("F", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            G) Realiza el solicitante carga/descarga de camiones en la terminal?	
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("G", "respuesta", (stRespuestasLocal["G"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["G"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["G"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("G", "respuesta", (stRespuestasLocal["G"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["G"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["G"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["G"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor indique que porcentaje de sus operaciones representa:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["G"].explicacion} onChange={event => handleChangeValue("G", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            H) Realiza el solicitante carga/descarga ferroviaria en la terminal?	
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("H", "respuesta", (stRespuestasLocal["H"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["H"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["H"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("H", "respuesta", (stRespuestasLocal["H"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["H"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["H"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["H"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor indique que porcentaje de sus operaciones representa:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["H"].explicacion} onChange={event => handleChangeValue("H", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
      </div>
    </div>
  </>);
}

export default SeccionX;