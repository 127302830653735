import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_GRUPO = `
query get($filtro: InputGetGrupo)
{
    getGrupo(filtro : $filtro)
  {
    grupoID
    usuarioBitID
    grupo
  }
}`;

export const GET_ALL_GRUPOS = `
query getAll($filtro: InputGetGrupo)
{
    getAllGrupos(filtro : $filtro)
  {
    grupoID
    usuarioBitID
    grupo
  }
}`;

export const TABLA_GRUPOS = `
query filter($filtro: InputFiltroGrupo)
{
    filterGrupos(filtro : $filtro)
  {
    listado{
        grupoID
        usuarioBitID
        grupo
    }
    paginas
    registros
  }
}`;

export const getGrupo = async (filtro, token) => {
    try {
        let { getGrupo } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_GRUPO
        });

        return getGrupo;

    } catch({message}) { throw new Error(message); }
}

export const getAllGrupos = async (variables, token) => {
    try {

        let { getAllGrupos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_GRUPOS
        });

        return getAllGrupos;

    } catch({message}) { throw new Error(message); }
}

export const seleccionGrupos = async (variables, token) => {
  try{
      let allGrupos = await getAllGrupos(variables, token);
      if(!Array.isArray(allGrupos)) throw new Error ("No se encontraron registros");

      return allGrupos.map(({
          grupoID: value, grupo: label
      }) => ({value, label: label.toUpperCase() }));
  }catch({message: label}){
      return [{
          label,
          value: null,
          disabled: true
      }];
  }
}

export const tablaGrupos = async (variables, token) => {
    try{
        let {filterGrupos} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_GRUPOS
        });
        return filterGrupos;
    }catch({message}) { throw new Error(message); }
}
