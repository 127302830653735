import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_POLIZAS_SEGURO = `
        query get($filtro: InputGetPolizasSeguro){
            getPolizasSeguro(filtro:$filtro){
                polizasSeguroID
                numero
                fechaSolCot
                empresaID
                nombre
                ramoSeguroID
                ramoSeguro
                aseguradoraID
                aseguradora
                numpolEndoso
                divisaID
                divisa
                sumaAseg
                primaNeta
                fpPromedio
                vigenciaIni
                vigenciaHasta
                exclusiones
                renovacion
                horasInvertidas
                folioInterno
                tipoValorAseg
                tipoPoliza
                polizaOrigenID
                folioInternoControlPago
                fechaModificacion
                controlPagoID
                beneficiarioPreferente
                bienesAmparadosEncabezado
                polizaRenovada
                folioPolizaRenovada
                ejercicio
                mes
                fechaInicio
                fechaFin
                derechoPoliza
                paridad
                inciso
                renovacionObs
                gastosExpedicion
                iva
                total
                aseguradoAdicional
                seleccionTipoPoliza
                subPolizaID
                subRamoSeguroID
                subRamoSeguro
                primaNetaTotal
            }
        }`;

    export const GET_ALL_POLIZAS_SEGURO = `
        query filtroGetAll($filtro: InputGetPolizasSeguro){
            getAllPolizasSeguro(filtro:$filtro){
                polizasSeguroID
                numero
                fechaSolCot
                empresaID
                nombre
                ramoSeguroID
                ramoSeguro
                aseguradoraID
                aseguradora
                numpolEndoso
                divisaID
                divisa
                sumaAseg
                primaNeta
                fpPromedio
                vigenciaIni
                vigenciaHasta
                exclusiones
                renovacion
                horasInvertidas
                folioInterno
                tipoValorAseg
                tipoPoliza
                polizaOrigenID
                folioInternoControlPago
                fechaModificacion
                controlPagoID
                beneficiarioPreferente
                bienesAmparadosEncabezado
                polizaRenovada
                folioPolizaRenovada
                ejercicio
                mes
                fechaInicio
                fechaFin
                derechoPoliza
                paridad
                inciso
                renovacionObs
                gastosExpedicion
                iva
                total
                aseguradoAdicional
                seleccionTipoPoliza
                subPolizaID
                subRamoSeguroID
                subRamoSeguro
                primaNetaTotal
            }
        }`;

    export const TABLA_POLIZAS_SEGURO = `
        query filtro($filtro: InputFiltroPolizasSeguro){
            filterPolizasSeguro(filtro:$filtro){
            listado{
                polizasSeguroID
                numero
                fechaSolCot
                empresaID
                nombre
                ramoSeguroID
                ramoSeguro
                aseguradoraID
                aseguradora
                numpolEndoso
                divisaID
                divisa
                sumaAseg
                primaNeta
                fpPromedio
                vigenciaIni
                vigenciaHasta
                exclusiones
                renovacion
                horasInvertidas
                folioInterno
                tipoValorAseg
                tipoPoliza
                polizaOrigenID
                folioInternoControlPago
                fechaModificacion
                controlPagoID
                beneficiarioPreferente
                bienesAmparadosEncabezado
                polizaRenovada
                folioPolizaRenovada
                derechoPoliza
                paridad
                inciso
                gastosExpedicion
                iva
                total
                aseguradoAdicional
                seleccionTipoPoliza
                subPolizaID
                subRamoSeguroID
                subRamoSeguro
                primaNetaTotal
            }
            registros
            paginas
            }
        }`;

export const getPolizasSeguro = async (filtro, token) => {
    try {
        let { getPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_POLIZAS_SEGURO
        });

        return getPolizasSeguro;

    } catch({message}) { throw new Error(message); }
}

export const getAllPolizasSeguro = async (variables, token) => {
    try {

        let { getAllPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_POLIZAS_SEGURO
        });

        return getAllPolizasSeguro;

    } catch({message}) { throw new Error(message); }
}
/*
export const seleccionPolizasSeguro = async (variables, token) => {
    try {

        let allPolizasSeguro = await getAllPolizasSeguro
        (variables, token);

        if(!Array.isArray(allPolizasSeguro)) throw new Error("No se encontraron registros");

        return allPolizasSeguro.map(({ polizasSeguroID:value, numpolEndoso:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}
*/
export const seleccionPolizasSeguro = async (variables, token) => {
    try {

        let allPolizasSeguro = await getAllPolizasSeguro
        (variables, token);


        if(!Array.isArray(allPolizasSeguro)) throw new Error("No se encontraron registros");

        let arreglo1 = [];

        arreglo1.push({label: "No Aplica", value: "0"});

        allPolizasSeguro.forEach( ({polizasSeguroID,numero}) => {
                arreglo1.push({label: numero, value: polizasSeguroID});
            });

        return arreglo1;

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaPolizasSeguro = async (variables, token) => {
    
    try{
        let {filterPolizasSeguro} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_POLIZAS_SEGURO
        });

        return filterPolizasSeguro;
    }catch({message}) { throw new Error(message); }
}



export const GET_SUBPOLIZAS_SEGURO = `
        query getSubPolizasSeguro($filtro: InputGetSubPolizasSeguro){
            getSubPolizasSeguro(filtro:$filtro){
                subPolizaID
                polizasSeguroID
                ramoSeguroID
                ramoSeguro
                sumaAseg
                primaNeta
                fpPromedio
                bienesAmparadosEncabezado
                folioSubpoliza
                folioSubpolizaExtendida
                beneficiarioPreferente
            }
        }`;

    export const GET_ALL_SUBPOLIZAS_SEGURO = `
        query getAllSubPolizasSeguro($filtro: InputGetSubPolizasSeguro){
            getAllSubPolizasSeguro(filtro:$filtro){
                subPolizaID
                polizasSeguroID
                ramoSeguroID
                ramoSeguro
                sumaAseg
                primaNeta
                fpPromedio
                bienesAmparadosEncabezado
                folioSubpoliza
                folioSubpolizaExtendida
                beneficiarioPreferente
            }
        }`;


export const getSubPolizasSeguro = async (filtro, token) => {
    try {
        let { getSubPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_SUBPOLIZAS_SEGURO
        });

        return getSubPolizasSeguro;

    } catch({message}) { throw new Error(message); }
}

export const getAllSubPolizasSeguro = async (variables, token) => {
    try {

        let { getAllSubPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SUBPOLIZAS_SEGURO
        });

        return getAllSubPolizasSeguro;

    } catch({message}) { throw new Error(message); }
}

export const seleccionableSubPolizasSeguro = async (variables, token) => {
    try {

        let allSubpolizas = await getAllSubPolizasSeguro(variables, token);

        if (!Array.isArray(allSubpolizas)) throw new Error("No se encontraron registros");
        
        return allSubpolizas.map(({ subPolizaID: value, folioSubpolizaExtendida: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}