import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import AccountCircle from '@material-ui/icons/AccountCircle';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { selectEnumEstatus } from '../../../querys/Enums/metodos';

import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import * as ConsultasActivosAsegurado from '../../../querys/ActivosAsegurado';
import { seleccionVehiculosAsegurados } from '../../../querys/ActivosAseguradosPolizas'
import { seleccionEmpresa } from '../../../querys/Empresas';
import { rgxPassword, rgxNumeros, rgxCorreo } from '../../../helpers/regexp';

import { seleccionActivosAsegurado as seleccionActivosAseguradosSolPol, getInfoActAsegPolizas } from '../PolizasSeguro/ConsultasActivos';

import moment from 'moment';
import { urlServidor } from '../../../helpers';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
let ParentSolSegFianID = 1;

function SolSegFianAuto({ datosDetalle, setOpenSolSegAuto, lMostrarFormularioCompleto }) {

    let now = moment();
    let formato = "YYYY-MM-DD";
    let history = useHistory();
    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let URLPolizasSeguroID = null;
    let mostrarTabla = false;
    let nAutosID = null;
    let nEmpresaID = null;
    let nUsuarioSolicita = null;
    let datosDetalleURL = {};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nRamoSeguroID = null;
    let nSubRamoID = null;
    let nSolicitud = null;
    let URLSubPolizaID = null;
    let pSeleccionTipoPoliza = null;
    let cTitulo2 = "ANEXO: Factura de equipo y OC. Si es sustitución de un auto favor de indicar a que vehículo sustituye, si procede su baja, adjuntar LIBRO DE BAJA firmado.";

    let { params } = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLSolSegFianID, pEmpresaID, pUsuarioSolicita, datosDetalle, pfolioFormateado, pURLPolizasSeguroID, pRamoSeguroID, pSolicitud,pSubpolizaID,seleccionTipoPoliza, pSubRamoID } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSubPolizaID = pSubpolizaID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        nSolicitud = pSolicitud;
        pSeleccionTipoPoliza = seleccionTipoPoliza;

    }
    let { onCerrarModal, onOcultarTabla } = datosDetalleURL;
    mostrarTabla = URLSolSegFianID ? true : false;

    if (datosDetalleURL != undefined && datosDetalleURL.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {


        let {pEstatus, pSolSegFianID, pAutosID, pEmpresaID, pUsuarioSolicita, onCerrarModal, mostrarTabla, pfolioFormateado, pURLPolizasSeguroID, pRamoSeguroID, pSolicitud, pSubRamoID } = datosDetalle;

        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSolSegFianID = pSolSegFianID;
        mostrarTabla = mostrarTabla;
        nAutosID = pAutosID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
    }
    mostrarTabla = lMostrarFormularioCompleto;
    ParentSolSegFianID = URLSolSegFianID;
    const { token, usuario } = useSelector(stDatos => stDatos.login);

    let Empresas = [];
    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    let [stEmpresas, setEmpresas] = useState(Empresas)
    let [stActivosAsegurado, setActivosAsegurado] = useState(Empresas)
    let [stVehiculosAsegurados, setVehiculosAsegurados] = useState(Empresas)
    let [datosPDF, setdatosPDf] = useState({});

    let [stDatos, setState] = useState(cleanState);
    const [disabledActivo, setDisabledActivo] = useState(false);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = stDatos.autosID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Solicitud de Seguro de Auto`;
    let ArrayRef = [];

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "autosID" }, { campo: "modelo", variable: "modelo" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: Consultas.getSolSegFianAuto, formatearResultado: [
                { id: "activo", formato: (data) => data === null ? "" : data },
            ]
        })
    }, {
        ccionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "autosID" },
        { campo: "modelo", variable: "modelo" },
        { campo: "serie", variable: "serie" }],
        disabled: { multiseleccion: true },
        onClick: ({ autosID, serie, modelo }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro con serie y modelo: ${serie}-${modelo}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { autosID }, asyncDelete: Consultas.deleteSolSegFianAuto
            })
        })
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "autosID" }],
        disabled: { multiseleccion: true },
        onClick: ({ autosID }) => {
            //setRedireccionarFormulario({ autosID });
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: autosID,
                pTipo: cfolioFormateado,
                pClasificacion: "",
                pTabla: "Solicitud Seguro de Autos",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    },
    {
        accionID: [6],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "autosID" }, { campo: "serie" }],
        disabled: { multiseleccion: true },
        onClick: ({ autosID, serie }) => AuxDescargar(autosID, serie)
    }];

    function AuxDescargar(autosID, serie) {

        setdatosPDf({ "autosID": autosID, "serie": serie });
        datosPDF = { "autosID": autosID, "serie": serie };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de partida de auto?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de reporte de partida de auto ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de partida de auto ' + serie, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
       
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("autosID", datosPDF.autosID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReporteParSolAuto' + datosPDF.serie
        await fetch(`${urlServidor}/service/reportes/ReporteParSolAutos`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}.pdf`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }

    let botones = [
        /*{
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                history.push(`/gen/SolicitudesSeguros/`);
            }
        }, */
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                handleCloseFormulario();
            }
        },{
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

        if (cEstatus != "RECHAZADA"){
            botones = [...botones, {
                accionID: [2],
                icono: Add,
                color: success,
                descripcion: "Agregar",
                onClick: () => {setOpen(true); divisas()},
                disabled: { multiseleccion: true }
            }]
        }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "autosID",
        color: primary,
        title: "Autos",
        title2: cTitulo2,
        iconTable: <AccountCircle />,
        headers: [
            { variable: "autosID", descripcion: "ID", hide: true },
            { variable: "marca", descripcion: "Marca" },
            { variable: "modelo", descripcion: "Modelo" },
            { variable: "serie", descripcion: "Número de Serie", hide: false },
            { variable: "anio", descripcion: "Año", hide: false },
            { variable: "responsable", descripcion: "Responsable" },   
            { variable: "sustituye", descripcion: nSolicitud === "Baja de Seguro" ? "Baja a" : "Sustituye al vehículo" }  ,               
            { variable: "motivo", descripcion: "Motivo" },
        ],
        responsiveTitle: ["responsable", "marca", "modelo"],
        filter: [
            { campo: "responsable", variable: "responsable", placeholder: "Responsable" },
            { campo: "marca", variable: "marca", placeholder: "Marca" },
            { campo: "serie", variable: "serie", placeholder: "Serie" },
            { campo: "modelo", variable: "modelo", placeholder: "Modelo" }
        ],
        alineacion: [{ columnas: [0, 1, 2, 3, 4], alineacion: "left" }]
        // rangoFechas: { ini: "fechaIni", fin: "fechaFin" },
    }
    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["autosID"] = { error: false, helperText: "" };
    cleanState["autosID"] = nAutosID;
    cleanState["solSegFianID"] = ParentSolSegFianID;

    InicializarInputs("empresaID", "Empresa", "Seleccione la empresa", nEmpresaID);
    InicializarInputs("marca", "Marca*", "Capture la marca", "", "", 50);
    InicializarInputs("modelo", "Modelo*", "Capture el modelo", "", "", 50);
    InicializarInputs("serie", "Serie*", "Capture la serie", "", "", 20);
    InicializarInputs("anio", "Año*", "Capture el año");
    InicializarInputs("activoAsegID", "Activo Asegurado *", "Seleccione el activo");
    InicializarInputs("tipoTransmision", "Tipo Transmision", "Seleccione la transmision","STD");
    //InicializarInputs("ubicacionID", "Nombre", "Capture ", "", "", 100);
    InicializarInputs("sustituyeID", nSolicitud === "Baja de Seguro" ? "Activo a dar de Baja *" : "Activo a Sustituir *", "", "", "", 50);
    InicializarInputs("activo", "Activo", "Activo", "ACTIVO");

    InicializarInputs("responsable", "Responsable *", "Capture al responsable", "", "", 100);
    InicializarInputs("motivo", "Motivo", nSolicitud === "Baja de Seguro" ? "Introduza el motivo por el cual se dara de Baja" : "Introduza el motivo por el cual se sustituye", "", "", 30);
    InicializarInputs("divisaID", "Divisa","",datosDetalle.codigoMoneda == 'USD' ? 100 : 69);
    InicializarInputs("paridad", "Paridad", "", "1", "", "", "", "right");

    //Orden de inputs (grid)
    //-------------------------------------------
    InputsConID["empresaID"].grid = { md: 3, lg: 3 };
    InputsConID["marca"].grid = { md: 3, lg: 3 };
    InputsConID["modelo"].grid = { md: 3, lg: 3 };
    InputsConID["serie"].grid = { md: 3, lg: 3 };
    //-------------------------------------------
    InputsConID["anio"].grid = { md: 2, lg: 2 };
    InputsConID["activoAsegID"].grid = { md: 3, lg: 3 };
    InputsConID["sustituyeID"].grid = { md: 5, lg: 5 };
    InputsConID["activo"].grid = { md: 3, lg: 3 };
    InputsConID["tipoTransmision"].grid = { md: 2, lg: 2 };

    //-------------------------------------------
    InputsConID["responsable"].grid = { md: 9, lg: 9 };
    InputsConID["motivo"].grid = { md: 8, lg: 8 };
    InputsConID["divisaID"].grid = { md: 2, lg: 2 };
    InputsConID["paridad"].grid = { md: 2, lg: 2 };

/*     InputsConID["sustituyeID"].tipo = "select";
    InputsConID["sustituyeID"].data = seleccionables.Activos;
    InputsConID["sustituyeID"].disabled = stDatos["autosID"] ? true : false;  */

    InputsConID["empresaID"].tipo = "select";
    InputsConID["empresaID"].data = stEmpresas;
    InputsConID["empresaID"].disabled =  disabledActivo === true ? true : (stDatos["empresaID"] ? true : false);

    InputsConID["activoAsegID"].tipo = "select";
    InputsConID["activoAsegID"].data = stActivosAsegurado;

    InputsConID["tipoTransmision"].tipo = "select";
    InputsConID["tipoTransmision"].data = [
        {
            label: 'NO APLICA',
            value: 'NO APLICA'
        }, {
            label: 'STD',
            value: 'STD'
        },
        {
            label: 'AUT',
            value: 'AUT'
        }];

    InputsConID["sustituyeID"].placeholder = "Seleccione un activo a sustituir";
    InputsConID["sustituyeID"].tipo = "autocomplete";
    InputsConID["sustituyeID"].data = seleccionables.Activos;
    InputsConID["sustituyeID"].onChange = ( target ) => {
        handleChangeSustituyeID(target.value, nSolicitud)
    };

    

    InputsConID["activo"].tipo = "select";
    InputsConID["activo"].data =  [{
        label: 'ACTIVO',
        value: 'ACTIVO'
    },
    {
        label: 'INACTIVO',
        value: 'INACTIVO'
    }];

    InputsConID["divisaID"].tipo = "select";
    InputsConID["divisaID"].data = seleccionables.divisas;

    
    InputsConID["activo"].disabled = disabledActivo === true ? true : false;
    InputsConID["activoAsegID"].disabled = InputsDisabled();
    InputsConID["anio"].disabled = InputsDisabled();
    InputsConID["marca"].disabled = InputsDisabled();
    InputsConID["modelo"].disabled = InputsDisabled();
    InputsConID["motivo"].disabled = disabledActivo === true ? true : false;
    InputsConID["paridad"].disabled = true;
    InputsConID["responsable"].disabled = InputsDisabled();
    InputsConID["serie"].disabled = InputsDisabled();
    InputsConID["tipoTransmision"].disabled = InputsDisabled();
    InputsConID["sustituyeID"].disabled = disabledActivo === true ? true : false;
    InputsConID["divisaID"].disabled = true

    
   
    function InputsDisabled() {
        if (nSolicitud === "Baja de Seguro"){
                return true;
        }else{
            if(disabledActivo === true){
                return true;
            }
                return false;
        }
    }

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0) {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({ target: { value } }) => Identificador === "divisaID" ?  handleChangeDivisa(value) : handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }


    async function handleChangeDivisa (value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
                    
        
         setState({ ...stDatos, ["divisaID"]: value, ["paridad"]: historialParidades[0].paridad });
    }

    
    async function handleChangeSustituyeID (value, key) {
                   
                setDisabledActivo(true);
                setDisabled(true);
                 setState({ ...stDatos, ["sustituyeID"]: value});
                 let res = await getInfoActAsegPolizas({ infoActAsegID: value}, token) 

                 if (nSolicitud === "Baja de Seguro"){
                    setState({ ...stDatos, 
                    ["sustituyeID"]: value, 
                    ["activo"]: res.estado === undefined ? "" : res.estado, 
                    ["marca"]: res.marca === undefined ? "" : res.marca,
                    ["modelo"]: res.modelo === undefined ? "" : res.modelo,
                    ["serie"]: res.serie === undefined ? "" : res.serie, 
                    ["anio"]: res.anio === undefined ? "" : res.anio, 
                    ["activoAsegID"]: res.activoAsegID === undefined ? "" : res.activoAsegID,  
                    ["tipoTransmision"]: res.tipoTransmision === undefined ? "" : res.tipoTransmision,  
                    ["responsable"]: res.responsable === undefined ? "" : res.responsable,  
                    ["motivo"]: res.motivo === undefined ? "" : res.motivo, 
                    ["divisaID"]: res.divisaID === undefined ? "" : res.divisaID, 
                    ["paridad"]: res.paridad === undefined ? "" : res.paridad});
                    

                 }else{
                    setState({ ...stDatos, ["sustituyeID"]: value, ["activo"]: res.estado });

                 }
                 setDisabledActivo(false);
                 setDisabled(false);


    
    }

    let accionesFormulario = [{
        accionID: stDatos["autosID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["marca"],
            mensajeCorrecto: `El auto se ${stDatos["autosID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    /**activoAsegID
marca
serie
modelo
empresaID
motivo
responsable
 */
    function handleValidaciones() {
        let error;
        var validaciones = [
            {
                condicion: !stDatos["empresaID"] || stDatos["empresaID"] === 0,
                ref: ArrayRef["empresaID"], keyError: "empresaID",
                mensajeError: "Debe especificar le empresa"
            },
            {
                condicion: !stDatos["marca"] || trim(stDatos["marca"]) === "",
                ref: ArrayRef["marca"], keyError: "marca",
                mensajeError: "Debe especificar la marca"
            },
            {
                condicion: !stDatos["modelo"] || trim(stDatos["modelo"]) === "",
                ref: ArrayRef["modelo"], keyError: "modelo",
                mensajeError: "Debe especificar el modelo"
            },
            {
                condicion: !stDatos["serie"] || trim(stDatos["serie"]) === "",
                ref: ArrayRef["serie"], keyError: "serie",
                mensajeError: "Debe especificar la serie"
            },
            {
                condicion: !stDatos["activoAsegID"] || stDatos["activoAsegID"] === 0,
                ref: ArrayRef["activoAsegID"], keyError: "activoAsegID",
                mensajeError: "Debe especificar el activo asegurado"
            },
            {
                condicion: !stDatos["responsable"] || trim(stDatos["responsable"]) === "",
                ref: ArrayRef["responsable"], keyError: "responsable",
                mensajeError: "Debe especificar al responsable"
            }/*,
            {
                condicion: !stDatos["motivo"] || trim(stDatos["motivo"]) === "",
                ref: ArrayRef["motivo"], keyError: "motivo",
                mensajeError: "Debe especificar el motivo"
            }*/];

        if(nSolicitud === "Baja de Seguro"){

            validaciones = [];
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["autosID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            }
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {

            let error = await handleValidaciones();

            
            var DatosGuardar = { ...stDatos};


            if(nSolicitud === "Baja de Seguro"){

                DatosGuardar["activo"] = DatosGuardar["activo"];
                DatosGuardar["activoAsegID"] = DatosGuardar["activoAsegID"] === "" ? 0 : parseInt(DatosGuardar["activoAsegID"]);
                DatosGuardar["anio"] = DatosGuardar["anio"] === "" ? 0 : parseInt(DatosGuardar["anio"]);
                DatosGuardar["divisaID"] = DatosGuardar["divisaID"] === "" ? 0 : parseInt(DatosGuardar["divisaID"]);
                DatosGuardar["empresaID"] = DatosGuardar["empresaID"] === "" ? 0 : parseInt(DatosGuardar["empresaID"]);
                DatosGuardar["marca"] = DatosGuardar["marca"] === "" ? "-" : DatosGuardar["marca"];
                DatosGuardar["modelo"] = DatosGuardar["modelo"] === "" ? "-" : DatosGuardar["modelo"];
                DatosGuardar["motivo"] = DatosGuardar["motivo"] === "" ? "-" : DatosGuardar["motivo"];
                DatosGuardar["paridad"] = DatosGuardar["paridad"] === "" ? 0 : parseFloat(DatosGuardar["paridad"]);
                DatosGuardar["responsable"] = DatosGuardar["responsable"] === "" ? "-" : DatosGuardar["responsable"];
                DatosGuardar["serie"] = DatosGuardar["serie"] === "" ? "-" : DatosGuardar["serie"];
                DatosGuardar["responsable"] = DatosGuardar["responsable"] === "" ? "-" : DatosGuardar["responsable"];
                DatosGuardar["solSegFianID"] = DatosGuardar["solSegFianID"] === "" ? 0 : parseInt(DatosGuardar["solSegFianID"]);
                DatosGuardar["sustituyeID"] = DatosGuardar["sustituyeID"] === "" ? 0 : parseInt(DatosGuardar["sustituyeID"]);
                DatosGuardar["tipoTransmision"] = DatosGuardar["tipoTransmision"] === "" ? "-" : DatosGuardar["tipoTransmision"];
            }

            if (error) return error;

            if (stDatos["autosID"]) {
                await Consultas.updateSolSegFianAuto(DatosGuardar, token);
            }
            else {
                delete DatosGuardar["autosID"];
                await Consultas.addSolSegFianAuto(DatosGuardar, token);
            }
        }
        return ftGuardar();
    }

    function handleClose() {

        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });

        if (!mostrarTabla) {
            if (onCerrarModal != undefined) { onCerrarModal(); }

        } else {
            if (onCerrarModal != undefined) { onCerrarModal(); }
            if (onOcultarTabla != undefined) { onOcultarTabla(); }

        }
    }

    function handleCloseFormulario() {

        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });

        setOpenSolSegAuto();


        /*  if (!mostrarTabla) {
              if (onCerrarModal != undefined) { onCerrarModal(); }

          } else {
              if (onCerrarModal != undefined) { onCerrarModal(); }
              if (onOcultarTabla != undefined) { onOcultarTabla(); }

           }*/
    }


    function handleChange(value, key, MaxLength = 0) {
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        if (key === "anio") {
            value = value.toString().trim();

            if (value.length > 4) {
                return;
            }

            if (value.toString() !== "") {
                if (!(value.toString().match(rgxNumeros))) {
                    return;
                }
                value = parseInt(value);
            }
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

    }

    async function ObtenerEmpresas() {
        var Respuesta = await seleccionEmpresa({ filtro: { empresaID: nEmpresaID } }, token);
        setEmpresas(Respuesta);
    }

    async function ObtenerVehiculosAsegurados() {
        var Respuesta = await seleccionVehiculosAsegurados({ filtro: { empresaID: nEmpresaID } }, token);
        setVehiculosAsegurados(Respuesta);
    }

    async function ObtenerActivosAsegurados() {
        var Respuesta = await ConsultasActivosAsegurado.seleccionActivosAsegurado({ filtro: { clasificacion: 'AUTOMOVILES' } }, token);
        setActivosAsegurado(Respuesta);
    }


    async function divisas(){
        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: stDatos.divisaID } }, token);
                
        setState({ ...stDatos, ["paridad"]: historialParidades[0].paridad });
    }


    useEffect(() => {
        async function ftEffect() {
            try {
                let Activos;
                if (pSeleccionTipoPoliza == 2) {
                    if (parseInt(URLSubPolizaID) > 0) {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {subPolizaID: URLSubPolizaID == 0 ? null : parseInt(URLSubPolizaID)} }, token, nSolicitud,"Automóviles residentes");
                    } else {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud,"Automóviles residentes");
                    }
                }
                else {
                    if (parseInt(URLPolizasSeguroID) > 0) {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {polizasSeguroID: URLPolizasSeguroID == 0 ? null : parseInt(URLPolizasSeguroID)} }, token, nSolicitud,"Automóviles residentes");
                    } else {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud,"Automóviles residentes");
                    }
                }
               
                
                const divisas = [
                    {
                        label: 'MXN',
                        value: 69
                    },
                    {
                        label: 'USD',
                        value: 100
                    },
                ]

                setSeleccionables({ Activos,divisas});

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [URLPolizasSeguroID]);

    function setRedireccionarFormulario({ autosID }) {

        let seed = "scrumweb";
        let ctabla = "Solicitud Seguro de Autos";
        const datos = `{ "pURLDoctoOrigenID": "${autosID}", "pTipo": "${cfolioFormateado}", "pTabla": "${ctabla}", "pTitulo2": "${cTitulo2}"  }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();
                ObtenerActivosAsegurados();
                ObtenerVehiculosAsegurados();
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={Consultas.tablaSolSegFianAutos}
            token={token}
            parametrosFijos={{ solSegFianID: ParentSolSegFianID }}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["marca"]}
        />
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default SolSegFianAuto;
