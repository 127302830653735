import {getMinEjercicio}  from '../../../componentes/General/PresupuestoAnual/Consultas';

export function getEdad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
      diferenciaMeses < 0 ||(diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--
    }
    return edad
  }

  export function generarNumero(numero) {
    return (Math.random() * numero).toFixed(0);
}

export function colorRGB() {
    let coolor = "(" + generarNumero(255) + "," + generarNumero(255) + "," + generarNumero(255) + ")";
    return "rgb" + coolor;
}

export const ftObtenerEjercicios = async ( token) =>  {
  try {
      let anioActual = new Date().getFullYear();
      let minEjercicio =  await getMinEjercicio(token);
      let ejerciciosTemp = [];
      let minAnio = parseInt(minEjercicio) - 3;
      let maxAnio = parseInt(anioActual) + 5;

      for (let i = minAnio; i <= maxAnio; i++) {

          ejerciciosTemp.push({ value: i, label: i.toString() });
      }
      return ejerciciosTemp ;
  } catch({message}) { throw new Error(message); }
}

export const ftObtenerEjerciciosFiltrado = async ( token) =>  {
  try {
      let anioActual = new Date().getFullYear();
      let minEjercicio =  await getMinEjercicio(token);
      let ejerciciosTemp = [];
      let minAnio = parseInt(minEjercicio) - 3;
      let maxAnio = parseInt(anioActual) + 1;

      for (let i = minAnio; i <= maxAnio; i++) {
           ejerciciosTemp.push({ value: i.toString(), label: i.toString() });
         // ejerciciosTemp.push(i);
      }
      return ejerciciosTemp ;
  } catch({message}) { throw new Error(message); }
}
