import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";


// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { getTransporteMercancia, tablaTransporteMercancia } from '../../../querys/SolTransMerca';
import { seleccionArea } from '../../../querys/Areas';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { addTransporteMercancia, updateTransporteMercancia, deleteTransporteMercancia } from '../../../mutations/SolTransMerca';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { seleccionActivosAsegurado } from '../../../querys/ActivosAsegurado';
import { rgxLetras, rgxNumeros } from '../../../helpers/regexp';
import modalStyle from '_layout/assets/jss/material-dashboard-pro-react/modalStyle';

import { seleccionActivosAsegurado as seleccionActivosAseguradosSolPol, getInfoActAsegPolizas } from '../PolizasSeguro/ConsultasActivos';

import { rgxdeInt11, rgxInputTexto30, rgxDecimal8, rgxDecimal14 } from '../../../helpers/regexp';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import moment from 'moment';
import {toFixed, redondeo } from '../../../helpers';

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }
function TransporteMercancia({ datosDetalle, setOpenSolSegTranspMerc, lMostrarFormularioCompleto }) {

    let history = useHistory();

    let now = moment();
    const { token } = useSelector(state => state.login);
    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let URLPolizasSeguroID = null;
    let mostrarTabla = false;
    let nActivoAsegID = null;
    let nEmpresaID = null;
    let nRamoSeguroID = null;
    let nSubRamoID = null;
    let nUsuarioSolicita = null;
    let datosDetalleURL = {};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nSolicitud = null;
    let URLSubPolizaID = null;
    let pSeleccionTipoPoliza = null;
    let cTitulo2 = "ANEXOS: Factura de equipo, pieza y/o mercancia. En caso de tener más equipos a asegurar favor de anexarlos en un formato en excel. Si es una pieza de algun equipo de la empresa favor de indicarlo en la descripción del Equipo. Póliza, RFC, Tarjeta de circulación y licencia del Transportista que realizará el traslado";
    let { params } = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLSolSegFianID, pEmpresaID, pUsuarioSolicita, datosDetalle, pfolioFormateado, pURLPolizasSeguroID, pRamoSeguroID, pSolicitud,pSubpolizaID,seleccionTipoPoliza,pSubRamoID } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        nSolicitud = pSolicitud;
        URLSubPolizaID = pSubpolizaID;
        pSeleccionTipoPoliza = seleccionTipoPoliza;
    }

    let { onCerrarModal, onOcultarTabla } = datosDetalleURL;
    mostrarTabla = URLSolSegFianID ? true : false;

    if (datosDetalleURL != undefined && datosDetalleURL.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pEstatus, pSolSegFianID, pActivoAsegID, pEmpresaID, pUsuarioSolicita, onCerrarModal, mostrarTabla, onOcultarTabla, pfolioFormateado, pURLPolizasSeguroID, pRamoSeguroID, pSolicitud,pSubpolizaID,seleccionTipoPoliza,pSubRamoID } = datosDetalle;

        URLSolSegFianID = pSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        mostrarTabla = mostrarTabla;
        nActivoAsegID = pActivoAsegID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;
        nRamoSeguroID = pRamoSeguroID;
        nSubRamoID = pSubRamoID;
        URLSubPolizaID = pSubpolizaID;
        pSeleccionTipoPoliza = seleccionTipoPoliza;
    }
    mostrarTabla = lMostrarFormularioCompleto;

    const formato = "YYYY-MM-DD";
    const cleanState = {
        transpMciasID: null,
        solSegFianID: URLSolSegFianID,
        activoAsegID: nActivoAsegID,
        equipo: "",
        marca: "",
        capacidad: "",
        valorAseg: "",
        cdOrigen: "",
        fechaSalida: "",
        embalaje: "",
        pesoyVol: "",
        empresaID: nEmpresaID,
        usuarioSolicita: nUsuarioSolicita,
        nuevoUsado: "Nuevo",
        serie: "",
        modelo: "",
        noInv: "",
        valorNuevo: "",
        cdDestino: "",
        fechaLlegada: "",
        chofer: "",
        sobreDimension: "",
        activoAsegurar: "",
        sustituyeID: 0,
        sustituye: "",
        omitir: 0,
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1",
        activo: "ACTIVO",
        montoAsegurado:"0"
    };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };

    const cleanErrorState = {
        solSegFianID: { error: false, helperText: "" },
        activoAsegID: { error: false, helperText: "" },
        equipo: { error: false, helperText: "" },
        marca: { error: false, helperText: "" },
        capacidad: { error: false, helperText: "" },
        valorAseg: { error: false, helperText: "" },
        cdOrigen: { error: false, helperText: "" },
        fechaSalida: { error: false, helperText: "" },
        embalaje: { error: false, helperText: "" },
        pesoyVol: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        usuarioSolicita: { error: false, helperText: "" },
        nuevoUsado: { error: false, helperText: "" },
        serie: { error: false, helperText: "" },
        modelo: { error: false, helperText: "" },
        noInv: { error: false, helperText: "" },
        valorNuevo: { error: false, helperText: "" },
        cdDestino: { error: false, helperText: "" },
        fechaLlegada: { error: false, helperText: "" },
        chofer: { error: false, helperText: "" },
        sobreDimension: { error: false, helperText: "" },
        activoAsegurar: { error: false, helperText: "" },
        sustituyeID: { error: false, helperText: "" },
        omitir: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        activo: { error: false, helperText: "" },
        montoAsegurado: { error: false, helperText: "" }

    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [stateMultiple, setStateMultiple] = useState(cleanStateMultiple);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);


    const [disabledActivo, setDisabledActivo] = useState(false);
    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.transpMciasID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Transporte de Mercancía`;

    const estadoEquipo = [
        {
            label: 'Nuevo',
            value: 'Nuevo'
        },
        {
            label: 'Usado',
            value: 'Usado'
        },
    ];

    const infoPendiente = [
        {
            label: 'Listado en desarrollo, una disculpa ♥',
            value: 'Espera'
        },
    ];

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    const activoAsegIDRef = useRef(null);
    const equipoRef = useRef(null);
    const marcaRef = useRef(null);
    const capacidadRef = useRef(null);
    const valorAsegRef = useRef(null);
    const cdOrigenRef = useRef(null);
    const fechaSalidaRef = useRef(null);
    const embalajeRef = useRef(null);
    const pesoyVolRef = useRef(null);
    const nuevoUsadoRef = useRef(null);
    const serieRef = useRef(null);
    const modeloRef = useRef(null);
    const noInvRef = useRef(null);
    const valorNuevoRef = useRef(null);
    const cdDestinoRef = useRef(null);
    const fechaLlegadaRef = useRef(null);
    const choferRef = useRef(null);
    const sobreDimensionRef = useRef(null);
    const activoAsegurarRef = useRef(null);
    const sustituyeIDRef = useRef(null);
    const divisaIDRef = useRef(null);
    const montoAseguradoRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "transpMciasID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getTransporteMercancia, formatearResultado: [
                { id: "activo", formato: (data) => data === null ? "" : data },
            ]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "transpMciasID" },
        { campo: "equipo" }],
        disabled: { multiseleccion: true },
        onClick: ({ transpMciasID, equipo }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro de ${equipo}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { transpMciasID }, asyncDelete: deleteTransporteMercancia
            })
        })
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "transpMciasID" }],
        disabled: { multiseleccion: true },
        onClick: ({ transpMciasID }) => {
            // setRedireccionarFormulario({ transpMciasID });
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: transpMciasID,
                pTipo: cfolioFormateado,
                pClasificacion: "",
                pTabla: "Solicitud Seguro Transporte de Mercancias",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    }/*,
    {
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Descargar PDF",
        parametros: [{ campo: "transpMciasID" }, { campo: "nombres" }],
        disabled: { multiseleccion: true },
        onClick: ({ transpMciasID, nombres }) => AuxDescargar(transpMciasID, nombres)
    }*/
    ];
    /*
    function AuxDescargar(transpMciasID, nombres){
        setdatosPDf({"transpMciasID": transpMciasID, "nombres": nombres});
        datosPDF = {"transpMciasID": transpMciasID, "nombres": nombres};

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion:"¿Deseas descargar el  PDF del seguro de viaje?", tipo:"warning", msjConfirmacion:"Aceptar", msjCancelacion:"Cancelar"},

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros:  {}, asyncMutation: () => DescargarPdf(),
                mensajes:{
                    msjEspera:{
                        title: "Espere...", descripcion: "Generando el PDF del seguro de viaje de " + nombres, tipo : success
                    },
                    msjCorrecto:{
                        title: "Se generó con éxito", descripcion: 'Se descargó el PDF del seguro de viaje de ' + nombres, msjConfirmacion:"Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf(){
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("transpMciasID", datosPDF.transpMciasID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteSolicitudTranspMercancias' + datosPDF.nombres
        await fetch(`${urlServidor}/service/reportes/ReporteSolicitudTranspMercancias`, requestOptions)
            .then(response => {
                if (!response.ok)  return response.json();
                return response.blob()
                            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                    showFile(result, `${nombreReporte}.pdf`);
                setAlert(null)
                            })
            .catch(({message}) => {throw new Error(message)});
    }

    */
    let botones = [/*{
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            history.push(`/gen/SolicitudesSeguros/`);
        }
    },*/

        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                setOpenSolSegTranspMerc();
            }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

        if (cEstatus != "RECHAZADA"){
            botones = [...botones, {
                accionID: [2],
                icono: Add,
                color: success,
                descripcion: "Agregar",
                onClick: () => {setOpen(true); divisas()},
                disabled: { multiseleccion: true }
            }]
        }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "transpMciasID",
        color: primary,
        title: "Transporte de Mercancías",
        title2: cTitulo2,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "transpMciasID", descripcion: "transpMciasID", hide: true },
            { variable: "equipo", descripcion: "Equipo" },
            { variable: "marca", descripcion: "Marca" },
            { variable: "capacidad", descripcion: "Capacidad" },
            { variable: "valorAseg", descripcion: "Valor Asegurdo" },
            { variable: "valorNuevo", descripcion: "Valor Nuevo" },
            { variable: "sustituye", descripcion: nSolicitud === "Baja de Seguro" ? "Baja a" : "Sustituye a" },
            { variable: "fechaSalida", descripcion: "Fecha Salida", hide: true },
            { variable: "fechaLlegada", descripcion: "Fecha Llegada", hide: true },
        ],
        responsiveTitle: ["equipo", "marca"],
        filter: [
            { campo: "equipo", placeholder: "Departamento" },
            { campo: "marca", placeholder: "Área" },
        ],
        formato: [

            {
                columnas: ["fechaSalida", "fechaLlegada"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["valorAseg", "valorNuevo"], tipo: "moneda",
            }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }, { columnas: [3, 4], alineacion: "right" }]
    }

    const inputs = [
        /*  {
             disabled,
             id: "activoAsegID",
             value: state.activoAsegID,
             error: errorState.activoAsegID.error,
             success: state.activoAsegID && !errorState.activoAsegID.error ? true : undefined,
             helperText: errorState.activoAsegID.helperText,
             inputRef: activoAsegIDRef,
             title: "Activo *",
             placeholder: "Seleccione el activo",
             grid: { md: 6, lg: 6 },
             tipo: "autocomplete",
             data: seleccionables.ActivoAseg,
               onChange: (value) => setState({ ...state, activoAsegID: value ? Number(value.value) : null }),

         },*/
        {
            disabled: InputsDisabled(),
            id: "activoAsegurar",
            value: state.activoAsegurar,
            error: errorState.activoAsegurar.error,
            success: state.activoAsegurar && !errorState.activoAsegurar.error ? true : undefined,
            helperText: errorState.activoAsegurar.helperText,
            inputRef: equipoRef,
            title: "Camiones y Chasis *",
            placeholder: "Capture el camion y/o chasis",
            grid: { sm: 12, md: 6, lg: 6 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "activoAsegurar") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, activoAsegurar: { error: state.activoAsegurar.length > 150 && true, helperText: state.activoAsegurar.length > 150 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "equipo",
            value: state.equipo,
            error: errorState.equipo.error,
            success: state.equipo && !errorState.equipo.error ? true : undefined,
            helperText: errorState.equipo.helperText,
            inputRef: equipoRef,
            title: "Equipo *",
            placeholder: "Capture el equipo",
            grid: { sm: 12, md: 6, lg: 6 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "equipo") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, equipo: { error: state.equipo.length > 100 && true, helperText: state.equipo.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "marca",
            value: state.marca,
            error: errorState.marca.error,
            success: state.marca && !errorState.marca.error ? true : undefined,
            helperText: errorState.marca.helperText,
            inputRef: marcaRef,
            title: "Marca *",
            placeholder: "Capture la marca",
            grid: { sm: 12, md:3, lg:3 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "marca") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, marca: { error: state.marca.length > 20 && true, helperText: state.marca.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "capacidad",
            value: state.capacidad,
            error: errorState.capacidad.error,
            success: state.capacidad && !errorState.capacidad.error ? true : undefined,
            helperText: errorState.capacidad.helperText,
            inputRef: capacidadRef,
            title: "Capacidad *",
            placeholder: "Capture la capacidad",
            grid: { sm: 12, md:3, lg:3 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "capacidad") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, capacidad: { error: state.capacidad.length > 20 && true, helperText: state.capacidad.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "valorAseg",
            value: state.valorAseg,
            error: errorState.valorAseg.error,
            success: state.valorAseg && !errorState.valorAseg.error ? true : undefined,
            helperText: errorState.valorAseg.helperText,
            inputRef: valorAsegRef,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            textAlign: "right",
            placeholder: "0.0",
            title: "Valor a asegurar *",
            grid: {  md: 3, lg: 3 },
            onChange: ({ target: { value } }) => {
                // (rgxDecimal8.test(value)) &&
                //     handleChange(value, "valorAseg")
                (!isNaN(Number(value)) && value !=='' ) &&
                setState({...state,
                    valorAseg: value, montoAsegurado: redondeo( parseFloat(toFixed((parseFloat(value)/parseFloat(state.paridad) ),2) ),2 )}) 
            },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, valorAseg: { error: state.valorAseg.length > 10 && true, helperText: state.valorAseg.length > 10 ? "Límite de caracteres excedido" : "" } })
            }
        }, 
        {
            disabled: InputsDisabled(),
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 3, lg: 3 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: state.divisaID === 69,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            title: "Paridad Actual ",
            textAlign: "right",
            placeholder: "Paridad Actual",
            grid: { md: 2, lg: 2 },
            onChange: ({ target: { value } }) => { (!isNaN(Number(value)) && value !=='' )  && setState({...state,
                    paridad: value, montoAsegurado: redondeo( parseFloat(toFixed(parseFloat((state.valorAseg)/parseFloat(value)),2 )),2 )}) 
            }
        },
        {
            disabled,
            id: "montoAsegurado",
            value: state.montoAsegurado,
            error: errorState.montoAsegurado.error,
            success: state.montoAsegurado && !errorState.montoAsegurado.error ? true :
                undefined,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            helperText: errorState.montoAsegurado.helperText,
            inputRef: montoAseguradoRef,
            title: "Monto Asegurado",
            textAlign: "right",
            placeholder: "Especifique el monto",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "montoAsegurado") },
            onKeyDown: () => { return; }
        },
        {
            disabled: InputsDisabled(),
            id: "cdOrigen",
            value: state.cdOrigen,
            error: errorState.cdOrigen.error,
            success: state.cdOrigen && !errorState.cdOrigen.error ? true : undefined,
            helperText: errorState.cdOrigen.helperText,
            inputRef: cdOrigenRef,
            title: "Cd. Origen *",
            placeholder: "Capture la ciudad de origen",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "cdOrigen") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, cdOrigen: { error: state.cdOrigen.length > 50 && true, helperText: state.cdOrigen.length > 50 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "fechaSalida",
            value: state.fechaSalida,
            error: errorState.fechaSalida.error,
            success: state.fechaSalida && !errorState.fechaSalida.error ? true : undefined,
            helperText: errorState.fechaSalida.helperText,
            inputRef: fechaSalidaRef,
            placeholder: "Fecha salida",
            tipo: "datetimepicker",
            onlyDate: true,
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: (value) => { handleChange(value.format(formato), "fechaSalida") },
        },
        {
            disabled: InputsDisabled(),
            id: "embalaje",
            value: state.embalaje,
            error: errorState.embalaje.error,
            success: state.embalaje && !errorState.embalaje.error ? true : undefined,
            helperText: errorState.embalaje.helperText,
            inputRef: embalajeRef,
            title: "Embalaje *",
            placeholder: "Capture el embalaje",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "embalaje") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, embalaje: { error: state.embalaje.length > 20 && true, helperText: state.embalaje.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "pesoyVol",
            value: state.pesoyVol,
            error: errorState.pesoyVol.error,
            success: state.pesoyVol && !errorState.pesoyVol.error ? true : undefined,
            helperText: errorState.pesoyVol.helperText,
            inputRef: pesoyVolRef,
            title: "Peso y volumen *",
            placeholder: "Capture el peso y volumen",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "pesoyVol") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, pesoyVol: { error: state.pesoyVol.length > 20 && true, helperText: state.pesoyVol.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "nuevoUsado",
            value: state.nuevoUsado,
            error: errorState.nuevoUsado.error,
            success: state.nuevoUsado && !errorState.nuevoUsado.error ? true : undefined,
            helperText: errorState.nuevoUsado.helperText,
            inputRef: nuevoUsadoRef,
            title: "Estado *",
            placeholder: "Seleccione el estado",
            grid: { md: 4, lg: 4 },
            tipo: "select",
            data: estadoEquipo,
            onChange: ({ target: { value } }) => setState({ ...state, nuevoUsado: value ? value : null }),
        },
        {
            disabled: InputsDisabled(),
            id: "serie",
            value: state.serie,
            error: errorState.serie.error,
            success: state.serie && !errorState.serie.error ? true : undefined,
            helperText: errorState.serie.helperText,
            inputRef: serieRef,
            title: "Serie*",
            placeholder: "Capture la serie",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "serie") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, serie: { error: state.serie.length > 20 && true, helperText: state.serie.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "modelo",
            value: state.modelo,
            error: errorState.modelo.error,
            success: state.modelo && !errorState.modelo.error ? true : undefined,
            helperText: errorState.modelo.helperText,
            inputRef: modeloRef,
            title: "Modelo*",
            placeholder: "Capture la modelo",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "modelo") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, modelo: { error: state.modelo.length > 20 && true, helperText: state.modelo.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "noInv",
            value: state.noInv,
            error: errorState.noInv.error,
            success: state.noInv && !errorState.noInv.error ? true : undefined,
            helperText: errorState.noInv.helperText,
            inputRef: noInvRef,
            title: "No. Inv*",
            placeholder: "Capture el Número de inventario",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "noInv") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, noInv: { error: state.noInv.length > 20 && true, helperText: state.noInv.length > 20 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "valorNuevo",
            value: state.valorNuevo,
            error: errorState.valorNuevo.error,
            success: state.valorNuevo && !errorState.valorNuevo.error ? true : undefined,
            helperText: errorState.valorNuevo.helperText,
            inputRef: valorNuevoRef,
            title: "Valor nuevo*",
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            textAlign: "right",
            placeholder: "0.0",
            grid: { sm: 12, md: 4, lg: 4 },
            /*onChange: ({ target: { value } }) =>{  !isNaN(Number(value)) && handleChange(value.toUpperCase(), "valorNuevo")},
            */
            onChange: ({ target: { value } }) => {
                (rgxDecimal8.test(value)) &&
                    handleChange(value, "valorNuevo")
            },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, valorNuevo: { error: state.valorNuevo.length > 10 && true, helperText: state.valorNuevo.length > 10 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "cdDestino",
            value: state.cdDestino,
            error: errorState.cdDestino.error,
            success: state.cdDestino && !errorState.cdDestino.error ? true : undefined,
            helperText: errorState.cdDestino.helperText,
            inputRef: cdDestinoRef,
            title: "Cd. Destino*",
            placeholder: "Capture la ciudad destino",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "cdDestino") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, cdDestino: { error: state.cdDestino.length > 50 && true, helperText: state.cdDestino.length > 50 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "fechaLlegada",
            value: state.fechaLlegada,
            error: errorState.fechaLlegada.error,
            success: state.fechaLlegada && !errorState.fechaLlegada.error ? true : undefined,
            helperText: errorState.fechaLlegada.helperText,
            inputRef: fechaLlegadaRef,
            placeholder: 'Fecha llegada',
            tipo: "datetimepicker",
            onlyDate: true,
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: (value) => { handleChange(value.format(formato), "fechaLlegada") },
        },
        {
            disabled: InputsDisabled(),
            id: "chofer",
            value: state.chofer,
            error: errorState.chofer.error,
            success: state.chofer && !errorState.chofer.error ? true : undefined,
            helperText: errorState.chofer.helperText,
            inputRef: choferRef,
            title: "Chofer *",
            placeholder: "Capture el chofer",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "chofer") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, chofer: { error: state.chofer.length > 50 && true, helperText: state.chofer.length > 50 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "sobreDimension",
            value: state.sobreDimension,
            error: errorState.sobreDimension.error,
            success: state.sobreDimension && !errorState.sobreDimension.error ? true : undefined,
            helperText: errorState.sobreDimension.helperText,
            inputRef: sobreDimensionRef,
            title: "Sobredimension *",
            placeholder: "Capture la sobredimension",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "sobreDimension") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, sobreDimension: { error: state.sobreDimension.length > 10 && true, helperText: state.sobreDimension.length > 10 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "sustituyeID",
            value: state.sustituyeID,
            error: errorState.sustituyeID.error,
            success: state.sustituyeID && !errorState.sustituyeID.error ? true : undefined,
            helperText: errorState.sustituyeID.helperText,
            inputRef: sustituyeIDRef,
            title: nSolicitud === "Baja de Seguro" ? "Activo a dar de Baja *" : "Activo a Sustituir *",
            placeholder: "Seleccione el Activo a Sustituir",
            tipo: "autocomplete",
            data: seleccionables.Activos,
            grid: { md: 6, lg: 6 },
            onChange: ({ value, label }) => { handleChangeSustituyeID(value, nSolicitud) }
        },
        {
            disabled: disabledActivo === true ? true : false,
            id: "activo",
            value: state.activo,
            error: errorState.activo.error,
            success: Array.isArray(state.activo) && state.activo.length > 0 && state.activo && !errorState.activo.error ? true : undefined,
            helperText: errorState.activo.helperText,
            title: "Estado",
            placeholder: "Seleccionar el estado",
            grid: { md: 3, lg: 3 },
            tipo: "select",
            data: [{
                label: 'ACTIVO',
                value: 'ACTIVO'
            },
            {
                label: 'INACTIVO',
                value: 'INACTIVO'
            }],
            onChange: ({ target: { value } }) => handleChange(value, "activo"),
        }, /* 
        {
            disabled,
            id: "omitir",
            value: state.omitir,
            checked: state.omitir,
            title: "Omitir monto en pólizas",
            tipo: "switch",
            grid: { md: 3, lg: 3 },
            onChange: (e) => {
                setState({ ...state, omitir: (e.target.checked ? 1 : 0) })
            }
        } */
    ];

    function InputsDisabled() {
        if (nSolicitud === "Baja de Seguro"){
                return true;
        }else{
            if(disabledActivo === true){
                return true;
            }
                return false;
        }
    }
    
    let accionesFormulario = [{
        accionID: state.transpMciasID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: equipoRef,
            mensajeCorrecto: `El registro se ${state.transpMciasID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }


    async function handleChangeDivisa (value, key) {
        if (URLPolizasSeguroID) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad, montoAsegurado: redondeo( parseFloat( toFixed( (parseFloat(state.valorAseg)/parseFloat(datosPolizaSeguro.paridad)),2 ) ),2 )});
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
            setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad, montoAsegurado: redondeo( parseFloat( toFixed( (parseFloat(state.valorAseg)/parseFloat(historialParidades[0].paridad)),2 ) ),2 )});
        }
    }


    async function divisas(){
        if (URLPolizasSeguroID) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token); 
            setState({ ...state, ["paridad"]: historialParidades[0].paridad });
        }
    }



    async function handleValidaciones({ equipo, marca, capacidad, valorAseg, cdOrigen, fechaSalida, embalaje, pesoyVol, nuevoUsado, serie, modelo, noInv, valorNuevo, cdDestino, fechaLlegada, chofer, sobreDimension, activoAsegurar }) {

        let error;
        let validaciones = [

            {
                condicion: !activoAsegurar || trim(activoAsegurar) === "",
                ref: activoAsegurarRef, keyError: "activoAsegurar",
                mensajeError: "Debe especificar el camión y/o chasis"
            },
            {
                condicion: !equipo || trim(equipo) === "",
                ref: equipoRef, keyError: "equipo",
                mensajeError: "Debe especificar el equipo"
            },
            {
                condicion: !marca || trim(marca) === "",
                ref: marcaRef, keyError: "marca",
                mensajeError: "Debe especificar la marca"
            },
            {
                condicion: !capacidad || trim(capacidad) === "",
                ref: capacidadRef, keyError: "capacidad",
                mensajeError: "Debe especificar la capacidad"
            },
            {
                condicion: !valorAseg,
                ref: valorAsegRef, keyError: "valorAseg",
                mensajeError: "Debe especificar el valor"
            },
            {
                condicion: !cdOrigen || trim(cdOrigen) === "",
                ref: cdOrigenRef, keyError: "cdOrigen",
                mensajeError: "Debe especificar la ciudad"
            },
            {
                condicion: !fechaSalida || trim(fechaSalida) === "",
                ref: fechaSalidaRef, keyError: "fechaSalida",
                mensajeError: "Debe especificar la fecha"
            },
            {
                condicion: !embalaje || trim(embalaje) === "",
                ref: embalajeRef, keyError: "embalaje",
                mensajeError: "Debe especificar el embalaje"
            },
            {
                condicion: !pesoyVol || trim(pesoyVol) === "",
                ref: pesoyVolRef, keyError: "pesoyVol",
                mensajeError: "Debe especificar el peso y volumen"
            },
            {
                condicion: !nuevoUsado, keyError: "nuevoUsado",
                mensajeError: "Debe seleccionar un estado"
            },
            {
                condicion: !serie || trim(serie) === "",
                ref: serieRef, keyError: "serie",
                mensajeError: "Debe especificar la serie"
            },
            {
                condicion: !modelo || trim(modelo) === "",
                ref: modeloRef, keyError: "modelo",
                mensajeError: "Debe especificar el modelo"
            },
            {
                condicion: !noInv || trim(noInv) === "",
                ref: noInvRef, keyError: "noInv",
                mensajeError: "Debe especificar el número de inventario"
            },
            {
                condicion: !valorNuevo,
                ref: valorNuevoRef, keyError: "valorNuevo",
                mensajeError: "Debe especificar el valor"
            },
            {
                condicion: !cdDestino || trim(cdDestino) === "",
                ref: cdDestinoRef, keyError: "cdDestino",
                mensajeError: "Debe especificar la ciudad"
            },
            {
                condicion: !fechaLlegada || trim(fechaLlegada) === "",
                ref: fechaLlegadaRef, keyError: "fechaLlegada",
                mensajeError: "Debe especificar la fecha"
            },
            {
                condicion: !chofer || trim(chofer) === "",
                ref: choferRef, keyError: "chofer",
                mensajeError: "Debe especificar el chofer"
            },
            {
                condicion: !sobreDimension || trim(sobreDimension) === "",
                ref: sobreDimensionRef, keyError: "sobreDimension",
                mensajeError: "Debe especificar la sobredimension"
            },
        ];

        
        if(nSolicitud === "Baja de Seguro"){

            validaciones = [];
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { transpMciasID, solSegFianID, activoAsegID, equipo, marca, capacidad, valorAseg, cdOrigen, fechaSalida, embalaje, pesoyVol, empresaID, usuarioSolicita, nuevoUsado, serie, modelo, noInv, valorNuevo, cdDestino, fechaLlegada, chofer, sobreDimension, activoAsegurar, omitir, divisaID, paridad, activo, montoAsegurado } = state;


            if(nSolicitud === "Baja de Seguro"){
                //transpMciasID = transpMciasID === "" ? 0 : parseInt(transpMciasID) 
                solSegFianID = solSegFianID === "" ? 0 : parseInt(solSegFianID) 
                activoAsegID = (activoAsegID === "" || activoAsegID === null) ? 0 : parseInt(activoAsegID)
                equipo = equipo === "" ? "-" : equipo
                marca = marca === "" ? "-" : marca
                capacidad = capacidad === "" ? "-" : capacidad 
                valorAseg = valorAseg === "" ? 0 : parseFloat(valorAseg)
                cdOrigen = cdOrigen === "" ? "-" : cdOrigen 
                fechaSalida = fechaSalida === "" ? null : fechaSalida
                embalaje = embalaje === "" ? "-" : embalaje 
                pesoyVol = pesoyVol === "" ? "-" : pesoyVol 
                empresaID = (empresaID === "" || empresaID === null) ? 0 : parseInt(empresaID) 
                usuarioSolicita = usuarioSolicita === "" ? "-" : usuarioSolicita 
                nuevoUsado = nuevoUsado === "" ? "-" : nuevoUsado 
                serie = serie === "" ? "-" : serie 
                modelo = modelo === "" ? "-" : modelo 
                noInv = noInv === "" ? "-" : noInv 
                valorNuevo = valorNuevo === "" ? 0 : parseFloat(valorNuevo) 
                cdDestino = cdDestino === "" ? "-" : cdDestino 
                fechaLlegada = fechaLlegada === "" ? null : fechaLlegada 
                chofer = chofer === "" ? "-" : chofer 
                sobreDimension = sobreDimension === "" ? "-" : sobreDimension 
                activoAsegurar = activoAsegurar === "" ? "-" : activoAsegurar 
                omitir = omitir === "" ? 0 : parseInt(omitir) 
                divisaID = divisaID === "" ? 0 : parseInt(divisaID)  
                paridad = paridad === "" ? 0 : parseFloat(paridad)
                activo = activo === "" ? "-" : activo
                montoAsegurado = montoAsegurado === "" ? "-" : montoAsegurado
            }

            let error = await handleValidaciones({ equipo, marca, capacidad, valorAseg, cdOrigen, fechaSalida, embalaje, pesoyVol, nuevoUsado, serie, modelo, noInv, valorNuevo, cdDestino, fechaLlegada, chofer, sobreDimension, activoAsegurar });

            if (error) return error;

            if (transpMciasID) await updateTransporteMercancia({
                input: { 
                    transpMciasID, 
                    solSegFianID, 
                    activoAsegID: null, 
                    equipo, 
                    marca, 
                    capacidad, 
                    valorAseg: Number(valorAseg), 
                    cdOrigen, 
                    fechaSalida, 
                    embalaje, 
                    pesoyVol, 
                    empresaID, 
                    usuarioSolicita, 
                    nuevoUsado, 
                    serie, 
                    modelo, 
                    noInv, 
                    valorNuevo: Number(valorNuevo), 
                    cdDestino, 
                    fechaLlegada, 
                    chofer, 
                    sobreDimension, 
                    activoAsegurar, 
                    omitir: parseInt(omitir), 
                    divisaID, 
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad), 
                    activo: trim(activo) ,
                    montoAsegurado: parseFloat(montoAsegurado)
                }
            }, token);
            else await addTransporteMercancia({
                input: { solSegFianID, activoAsegID: null, equipo, marca, capacidad, valorAseg: Number(valorAseg), cdOrigen, fechaSalida, embalaje, pesoyVol, empresaID, usuarioSolicita, nuevoUsado, serie, modelo, noInv, valorNuevo: Number(valorNuevo), cdDestino, fechaLlegada, chofer, sobreDimension, activoAsegurar, omitir: parseInt(omitir), divisaID, paridad: paridad === undefined ? null : (paridad === "" ? null : paridad), activo: trim(activo) ,
                montoAsegurado: parseFloat(montoAsegurado)
            }
            }, token);
        }
        return ftGuardar();
    }

    function handleClose() {

        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });

        if (!mostrarTabla) {
            if (onCerrarModal != undefined) { onCerrarModal(); }

        } else {
            if (onCerrarModal != undefined) { onCerrarModal(); }
            if (onOcultarTabla != undefined) { onOcultarTabla(); }
        }
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function setRedireccionarFormulario({ transpMciasID }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${transpMciasID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Seguro Transporte de Mercancias" , "pTitulo2": "${cTitulo2}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    async function handleChangeSustituyeID (value, nSolicitud) {
                   
        setDisabledActivo(true);
        setDisabled(true);
         setState({ ...state, ["sustituyeID"]: value});
         let res = await getInfoActAsegPolizas({ infoActAsegID: value}, token) 

         if (nSolicitud === "Baja de Seguro"){
            setState({ ...state, 
           ["sustituyeID"]: value === undefined ? "" : res.value, 
           ["activo"]: res.estado === undefined ? "" : res.estado,
           ["equipo"]: res.equipo === undefined ? "" : res.equipo, 
           ["marca"]: res.marca === undefined ? "" : res.marca, 
           ["capacidad"]: res.capacidad === undefined ? "" : res.capacidad,
           ["valorAseg"]: res.valorAseg === undefined ? "" : res.valorAseg,
           ["divisaID"]: res.divisaID === undefined ? "" : res.divisaID, 
           ["paridad"]: res.paridad === undefined ? "" : res.paridad,
           ["cdOrigen"]: res.cdOrigen === undefined ? "" : res.cdOrigen,
           ["fechaSalida"]: res.fechaSalida === undefined ? "" : res.fechaSalida,
           ["embalaje"]: res.embalaje === undefined ? "" : res.embalaje,
           ["pesoyVol"]: res.pesoyVol === undefined ? "" : res.pesoyVol,
           ["nuevoUsado"]: res.nuevoUsado === undefined ? "" : res.nuevoUsado,
           ["serie"]: res.serie === undefined ? "" : res.serie,
           ["modelo"]: res.modelo === undefined ? "" : res.modelo, 
           ["noInv"]: res.noInv === undefined ? "" : res.noInv, 
           ["valorNuevo"]: res.valorNuevo === undefined ? "" : res.valorNuevo, 
           ["cdDestino"]: res.cdDestino === undefined ? "" : res.cdDestino, 
           ["fechaLlegada"]: res.fechaLlegada === undefined ? "" : res.fechaLlegada, 
           ["chofer"]: res.chofer === undefined ? "" : res.chofer, 
           ["sobreDimension"]: res.sobreDimension === undefined ? "" : res.sobreDimension });
         }else{
            setState({ ...state, ["sustituyeID"]: value, ["activo"]: res.estado });
        }
         setDisabledActivo(false);
         setDisabled(false);

}


    
    useEffect(() => {
        async function ftEffect() {
            try {
                let ActivoAseg = await seleccionActivosAsegurado({ filtro: { clasificacion: "Equipo de Transporte" } }, token);
                
                let Activos;
                if (pSeleccionTipoPoliza == 2) {
                    if (parseInt(URLSubPolizaID) > 0) {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {subPolizaID: URLSubPolizaID == 0 ? null : parseInt(URLSubPolizaID)} }, token, nSolicitud);
                    } else {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud);
                    }
                }
                else {
                    if (parseInt(URLPolizasSeguroID) > 0) {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {polizasSeguroID: URLPolizasSeguroID == 0 ? null : parseInt(URLPolizasSeguroID)} }, token, nSolicitud);
                    } else {
                        Activos = await seleccionActivosAseguradosSolPol({ filtro: {empresaID: nEmpresaID !== "" ? parseInt(nEmpresaID) : -1, ramoSeguroID: nSubRamoID !== "" ? parseInt(nSubRamoID) : -1, } }, token, nSolicitud);
                    }
                }

                setSeleccionables({ ActivoAseg, Activos });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, URLPolizasSeguroID]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>

        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaTransporteMercancia}
            token={token}
            parametrosFijos={{ solSegFianID: URLSolSegFianID }}
        />}
        {
            <FormularioModal
                open={open}
                title={titleModal}
                onClose={handleClose}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputs}
                acciones={accionesFormulario}
                focus={equipoRef}
            />}
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}

    </>);

}

export default TransporteMercancia;
