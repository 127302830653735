import React from 'react';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";

import Inputs from '../index';



function Fieldset({ leyenda, inputs = [], SizeLetra = "S", hide= false, widthBorderFieldset = "1px", fontWeight = 300 }) {

    const contenedor = (inputs && Array.isArray(inputs)) &&  <GridContainer>
        { inputs.map(i => (<Inputs key={i.id} { ...i } />)) }
    </GridContainer>;

    const localStyle = {
        fieldSetStyle: {
            border: `solid ${widthBorderFieldset} lightgray`,
            borderRadius: "8px",
            display: hide ? 'none' : 'block'
        }
    };

    return(
        <fieldset  style={localStyle.fieldSetStyle}>
            { leyenda && <legend style={ { fontSize: SizeLetra, fontWeight: parseInt(fontWeight) } }>{leyenda}</legend> }
            { contenedor }
        </fieldset>
    )
}

export default Fieldset;
