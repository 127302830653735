import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../../helpers';

export const tablaSolSegFianAutos = async (variables, token) => {
    var Query = `
    query filterSolSegFianAuto($filtro: InputFiltroSolSegFianAuto){
        filterSolSegFianAuto(filtro:$filtro){
            listado{
            autosID
            solSegFianID
            activoAsegID
            usuarioBitID
            marca
            serie
            modelo
            empresaID
            ubicacionID
            anio
            sustituye
            motivo
            responsable
            sustituyeID
        }
        registros
        paginas
        }
    }
    `;
    var tResult = await Ejecutar(variables, token, Query);
    return (tResult);
}

export const getSolSegFianAuto = async (variables, token) => {
    var Query = `
    query get($filtro: InputGetSolSegFianAuto){
        getSolSegFianAuto(filtro:$filtro) {
            autosID
            solSegFianID
            activoAsegID
            usuarioBitID
            marca
            serie
            modelo
            empresaID
            ubicacionID
            anio
            sustituye
            motivo
            responsable
            sustituyeID
            divisaID
            paridad
            tipoTransmision
            activo
        }
    }
    `;

    var resultado = await Ejecutar({filtro: variables}, token, Query);
    
    return resultado;
}

export const getAllSolSegFianAuto = async (variables, token) => {
    let Query = `
    query getAll($filtro: InputGetSolSegFianAuto){
        getAllSolSegFianAuto(filtro:$filtro){
            autosID
            solSegFianID
            activoAsegID
            usuarioBitID
            marca
            serie
            modelo
            empresaID
            ubicacionID
            anio
            sustituye
            motivo
            responsable
            sustituyeID
            divisaID
            paridad
            tipoTransmision
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const addSolSegFianAuto = async (variables, token) => {
    var Query = `
    mutation addSolSegFianAuto($input: InputAddSolSegFianAuto!) {
        addSolSegFianAuto(input: $input) {
            autosID
            solSegFianID
            activoAsegID
            usuarioBitID
            marca
            serie
            modelo
            empresaID
            ubicacionID
            anio
            sustituye
            motivo
            responsable
            sustituyeID
            divisaID
            paridad
            tipoTransmision
            activo
        }
    }
    `;

    var tResult = await Ejecutar({input: variables}, token, Query);

    return tResult;
};

export const updateSolSegFianAuto = async (variables, token) => {
    var Query = `
    mutation updateSolSegFianAuto($input: InputUpdateSolSegFianAuto!) {
        updateSolSegFianAuto(input: $input) {
            autosID
            solSegFianID
            activoAsegID
            usuarioBitID
            marca
            serie
            modelo
            empresaID
            ubicacionID
            anio
            sustituye
            motivo
            responsable
            sustituyeID
            divisaID
            paridad
            tipoTransmision
            activo
        }
    }
    `;

    var tResult = await Ejecutar({input: variables}, token, Query);

    return tResult;
};

export const deleteSolSegFianAuto = async (variables, token) => {
    var Query = `
    mutation deleteSolSegFianAuto($input: InputDeleteSolSegFianAuto) {
        deleteSolSegFianAuto(input: $input)
    }
    `;

    var tResult = await Ejecutar({input : variables}, token, Query);

    return tResult;
};

export const asignarEmpresasSolSegFianAuto = async (variables, token) => {
    var Query = `
    mutation asignarEmpresasSolSegFianAuto($SolSegFianAutoID: ID!, $empresasIDs: [String]!) {
        asignarEmpresasSolSegFianAuto(id: $SolSegFianAutoID, empresasIDs: $empresasIDs)
    }
    `;

    return await Ejecutar(variables, token, Query);
};

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch(error) { throw new Error(error.message); }
}
