import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HistoryRounded from '@material-ui/icons/HistoryRounded';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getDivisa, tablaDivisas } from '../../../querys/Divisa';
import { selectEnumEstatus } from '../../../querys/Enums/metodos';
import { addDivisa, updateDivisa, deleteDivisa } from '../../../mutations/Divisa';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../../_layout/helpers/colores';

import { seleccionMonedaSAT } from '../../../querys/CodigosMoneda/metodos';
import moment from 'moment';
import { rgxPorcentaje, rgxNumerosMaximo10, rgxNumeroDecimal4 } from '../../../helpers/regexp';
import { useHistory } from 'react-router-dom';
import Encriptar from '@renedelangel/helpers/lib/Encriptar';
import { rgxdeInt11, rgxdeInt4, rgxInputTexto30, rgxInputTexto10, rgxInputTexto20, rgxInputTexto50, rgxNumeros, rgxDecimal14, rgxdeInt1 } from '../../../helpers/regexp';
import * as Permisos from '../Permisos';
import { urlServidor } from '../../../helpers';

const { trim } = Validaciones;
const { Encrypt } = Encriptar;

function Divisas() {
    let history = useHistory();
    const { token } = useSelector(state => state.login)
    const cleanState = {
        divisaID: null,
        divisa: "",
        divisac: "",
        codigoMon: "",
        paridad: 0.00,
        fechaCotizacion: "",
        filtrar: 1,
        abreviacion: "",
        decimales: 0.00,
        porcentajeVariacion: 0
    };
    const valoresFiltros = [
        {
            label: 'FAVORITO',
            value: 1
        },
        {
            label: "NO FAVORITO",
            value: 2
        }
    ];

    const cleanErrorState = {
        divisaID: { error: false, helperText: "" },
        divisa: { error: false, helperText: "" },
        divisac: { error: false, helperText: "" },
        codigoMon: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        fechaCotizacion: { error: false, helperText: "" },
        filtrar: { error: false, helperText: "" },
        abreviacion: { error: false, helperText: "" },
        decimales: { error: false, helperText: "" },
        porcentajeVariacion: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    let [datosPDF, setdatosPDf] = useState({});

    let titleAccion = state.divisaID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} divisa`;

    const divisaRef = useRef(null);
    const divisacRef = useRef(null);
    const codigoMonRef = useRef(null);
    const paridadRef = useRef(null);
    const fechaCotizacionRef = useRef(null);
    const abreviacionRef = useRef(null);
    const decimalesRef = useRef(null);
    const porcentajeVariacionRef = useRef(null);
    const filtrarRef = useRef(null);
    const claveMoneda = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "divisaID" }]
        ,
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getDivisa,
            formatearResultado: [
                { id: "fechaCotizacion", formato: (data) => moment(data) }]
        })
    },
    {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "divisaID" }, { campo: "divisa" }],
        disabled: { multiseleccion: true },
        onClick: ({ divisaID, divisa }) => handelAlertEliminar({
            setAlert,
            mensaje: `a la divisa ${divisa}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { divisaID },
                asyncDelete: deleteDivisa
            })
        })
    },
    {
        icono: HistoryRounded,
        color: success,
        descripcion: "Consultar Historial de Paridades",
        disabled: { multiseleccion: true },
        parametros: [{ campo: "codigoMon" }, { campo: "divisaID" }, { campo: "divisa" }],
        disabled: { multiseleccion: true },
        onClick: ({ codigoMon, divisaID, divisa }) => {
            let seed = "scrumweb";
            const datos = `{ "codigoMon": "${codigoMon}", "divisaID": "${divisaID}", "divisa": "${divisa}"  }`;
            let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
            history.push(`/gen/HistorialParidades/${url}`);
        }

    },
    {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "divisaID" }, { campo: "codigoMon" }],
        disabled: { multiseleccion: true },
        onClick: ({ divisaID, codigoMon }) => AuxDescargar(divisaID, codigoMon)
    }
    ];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    function AuxDescargar(divisaID, codigoMon) {

        setdatosPDf({ "divisaID": divisaID, "codigoMon": codigoMon });
        datosPDF = { "divisaID": divisaID, "codigoMon": codigoMon };


        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de Divisas?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de Divisas ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de Divisas ' + codigoMon, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {

        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("divisaID", datosPDF.divisaID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReporteDivisas' + datosPDF.codigoMon
        await fetch(`${urlServidor}/service/reportes/ReporteDivisas`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}.pdf`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "divisaID",
        color: primary,
        title: "Divisas",
        iconTable: <AttachMoneyIcon />,
        headers: [
            { variable: "divisaID", descripcion: "ID", hide: true },
            /*  {variable:"filtrar",descripcion:"filtrar", hide:true} , */
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "divisac", descripcion: "Nombre Corto" },
            { variable: "abreviacion", descripcion: "Abreviación" },
            { variable: "codigoMon", descripcion: "Código" },
            { variable: "fechaCotizacion", descripcion: "Fecha de Cotización" },
            { variable: "paridad", descripcion: "Paridad" },
            { variable: "decimales", descripcion: "Decimales" },
            { variable: "porcentajeVariacion", descripcion: "Porcentaje de Variación" }
        ],

        responsiveTitle: ["codigoMon", "divisa", "paridad"],

        filter: [{ campo: "codigoMon", placeholder: "Código" },
        { campo: "divisa", placeholder: "Divisa" }]
        ,
        selectFilter: [
            {
                title: "Estado:",
                campo: "filtrar",
                placeholder: "Seleccionar Estado Divisas",
                retorna: "number",
                limpiarFiltro: "Seleccionar Estado Divisas",
                defaultValue: "1",
                data: [

                    { value: '1', label: "Favoritas" },
                    { value: '0', label: "No Favoritos" }

                ]
            }
        ],

        alineacion: [{ columnas: [0, 1, 3], alineacion: "left" }, { columnas: [5, 6, 7, 8], alineacion: "right" }],
        formato: [
            {
                columnas: ["fechaCotizacion"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["paridad"], tipo: "moneda",
            }
        ]
        // formato: [{ columnas: ["fechaCotizacion"], tipo: "fecha" } ],
    }

    const inputs = [{
        disabled,
        id: "divisa",
        value: state.divisa,
        error: errorState.divisa.error,
        success: state.divisa && !errorState.divisa.error ? true : undefined,
        helperText: errorState.divisa.helperText,
        inputRef: divisaRef,
        title: "Divisa *",
        grid: { md: 4, lg: 4 },
        placeholder: "Capture la descripción de la Divisa",
        onChange: ({ target: { value } }) => handleChange(value, "divisa"),
        onKeyDown: (evt) => handleFocus(evt, divisaRef)
    },
    {
        disabled,
        id: "divisac",
        value: state.divisac,
        error: errorState.divisac.error,
        success: state.divisac && !errorState.divisac.error ? true : undefined,
        helperText: errorState.divisac.helperText,
        inputRef: divisacRef,
        title: "Divisa Corto*",
        grid: { md: 4, lg: 4 },
        placeholder: "Capture la descripción corta de la Divisa",
        onChange: ({ target: { value } }) => handleChange(value, "divisac"),
        onKeyDown: (evt) => handleFocus(evt, divisacRef)
    },
    {
        disabled,
        id: "codigoMon",
        value: state.codigoMon,
        tipo: "autocomplete",
        error: errorState.codigoMon.error,
        success: state.codigoMon && !errorState.codigoMon.error ? true : undefined,
        helperText: errorState.codigoMon.helperText,
        inputRef: codigoMonRef,
        grid: { md: 4, lg: 4 },
        title: "Código SAT*",
        placeholder: "Capture el código SAT de la Divisa",
        data: seleccionables.MonedasSAT,

        onChange: ({ value, decimales, porcentajeVariacion }) => {
            handleChange(value.toUpperCase(), "codigoMon");
            handleValorPredeterminadoSAT(value, decimales, porcentajeVariacion)
            //datosInternos
        },
        //onKeyDown: (evt) => handleFocus(evt, divisaRef)
    },
    {
        disabled,
        id: "abreviacion",
        value: state.abreviacion,
        error: errorState.abreviacion.error,
        success: state.abreviacion && !errorState.abreviacion.error ? true : undefined,
        helperText: errorState.abreviacion.helperText,
        inputRef: abreviacionRef,
        title: "Abreviación*",
        grid: { md: 2, lg: 2 },
        placeholder: "Capture la abreviación de la Divisa",
        /* onChange: ({ target: { value } }) => handleChange( value.toUpperCase(), "abreviacion" ),
        onKeyDown: (evt) => handleFocus(evt, abreviacionRef) */
        onChange: ({ target: { value } }) => handleChange(value, "abreviacion"),
        onKeyDown: (evt) => handleFocus(evt, abreviacionRef)
    },
    {
        disabled,
        id: "fechaCotizacion",
        //grid: {xl:3,sm:3},
        grid: { md: 3, lg: 3 },
        value: state.fechaCotizacion === "0000-00-00" ? "0000-00-00" : state.fechaCotizacion,
        tipo: "datetimepicker",
        onlyDate: true,
        error: errorState.fechaCotizacion.error,
        success: state.fechaCotizacion && !errorState.fechaCotizacion.error ? true : undefined,
        helperText: errorState.fechaCotizacion.helperText,
        inputRef: fechaCotizacionRef,
        title: "Fecha Cotización*",
        placeholder: "Fecha Cotización de la Divisa",
        // onChange: ({ value  }) => handleChange( value, "fechaCotizacion" ),
        onChange: (value) => {
            if (typeof value !== "object") return;
            handleChange(value, "fechaCotizacion");
        }
        // onKeyDown: (evt) => handleFocus(evt, divisaRef)
    },
    {
        disabled,
        id: "paridad",
        value: state.paridad,

        //grid: {xl:3,sm:3},
        grid: { md: 2, lg: 2 },
        error: errorState.paridad.error,
        success: state.paridad && !errorState.paridad.error ? true : undefined,
        helperText: errorState.paridad.helperText,
        inputRef: paridadRef,
        textAlign: "right",
        // inputProps: { type:'number' } ,
        //  inputProps: { onBlur : () => { !rgxNumeros.test(state.paridad) }  } ,
        title: "Paridad*",
        placeholder: "Capture la paridad de la Divisa",
        /*onChange: ({ target: { value } }) => handleChange( value, "paridad" ),
        onKeyDown: (evt) => handleFocus(evt, paridadRef)*/
        onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "paridad") },
        onKeyDown: (evt) => handleFocus(evt, paridadRef)
    },
    {
        disabled,
        id: "decimales",
        value: state.decimales,
        // grid: {xl:3,sm:3},
        grid: { md: 3, lg: 3 },
        error: errorState.decimales.error,
        success: state.decimales && !errorState.decimales.error ? true : undefined,
        helperText: errorState.decimales.helperText,
        inputRef: decimalesRef,
        title: "Número de decimales*",
        textAlign: "right",
        placeholder: "Capture el número de decimales permitidos para la Divisa",
        /*onChange: ({ target: { value } }) => handleChange( value, "decimales" ),
        onKeyDown: (evt) => handleFocus(evt, decimalesRef)
        */
        onChange: ({ target: { value } }) => { (rgxdeInt1.test(value)) && handleChange(value, "decimales") },
        onKeyDown: (evt) => handleFocus(evt, decimalesRef)
    },
    {
        disabled,
        id: "porcentajeVariacion",
        value: state.porcentajeVariacion,
        //grid: {xl:3,sm:3},
        grid: { md: 3, lg: 3 },
        error: errorState.porcentajeVariacion.error,
        success: state.porcentajeVariacion && !errorState.porcentajeVariacion.error ? true : undefined,
        helperText: errorState.porcentajeVariacion.helperText,
        inputRef: porcentajeVariacionRef,
        title: "Porcentaje de Variación*",
        textAlign: "right",
        placeholder: "Capture el porcentaje de variación permitido para la Divisa",
        /*onChange: ({ target: { value } }) => handleChange( value, "porcentajeVariacion" ),
        onKeyDown: (evt) => handleFocus(evt, porcentajeVariacionRef)
        */
        onChange: ({ target: { value } }) => { (rgxdeInt1.test(value)) && handleChange(value, "porcentajeVariacion") },
        onKeyDown: (evt) => handleFocus(evt, porcentajeVariacionRef)
    },
    {


        id: "filtrar",
        tipo: "select",
        title: "Filtrar",
        placeholder: "Seleccione el filtro a marcar en la selección",
        data: valoresFiltros,
        value: state.filtrar,
        /*  multiple: false, */
        inputRef: filtrarRef,
        disabled,
        onChange: ({ target: { value } }) => setState({ ...state, filtrar: value ? value : null }),
        error: errorState.filtrar.error,
        success: state.filtrar && !errorState.filtrar.error ? true : undefined,
        helperText: errorState.filtrar.helperText,
        grid: { md: 2, lg: 2 }

        /* disabled,
        id: "filtrar",
        value: state.filtrar,
        checked: state.filtrar,
        title: "Divisa Favorita",
        tipo: "select",
        grid: { md: 3, lg: 3 },
        onChange: () => handleChangeSwitch() */
    }];



    let accionesFormulario = [{
        accionID: state.divisa ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: divisaRef,
            mensajeCorrecto: `La Divisa se ${state.divisa ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ divisa, divisac, codigoMon, abreviacion, fechaCotizacion, paridad, filtrar, decimales, porcentajeVariacion }) {

        let error;
        let validaciones = [{
            condicion: !divisa || trim(divisa) === "",
            ref: divisaRef, keyError: "divisa",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas una descripción a la divisa"
        },
        {
            condicion: divisa.length > 50,
            ref: divisaRef, keyError: "divisa",
            mensajeError: "Esta excediendo el valor permitido de 50 caracteres"
        },
        {
            condicion: !divisac || trim(divisac) === "",
            ref: divisacRef, keyError: "divisac",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas una descripción corta a la divisa"
        },
        {
            condicion: divisac.length > 50,
            ref: divisacRef, keyError: "divisac",
            mensajeError: "Esta excediendo el valor permitido de 50 caracteres"
        },
        {
            condicion: !codigoMon || trim(codigoMon) === "",
            ref: codigoMonRef, keyError: "codigoMon",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas el código del SAT a la divisa"
        },
        {
            condicion: codigoMon.length > 5,
            ref: codigoMonRef, keyError: "codigoMon",
            mensajeError: "Esta excediendo el valor permitido de 5 caracteres"
        },
        {
            condicion: !abreviacion || trim(abreviacion) === "",
            ref: abreviacionRef, keyError: "abreviacion",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas la abreviación a la divisa"
        },
        {
            condicion: abreviacion.length > 5,
            ref: abreviacionRef, keyError: "abreviacion",
            mensajeError: "Esta excediendo el valor permitido de 5 caracteres"
        },

        {
            condicion: !paridad || paridad === "",
            ref: paridadRef, keyError: "paridad",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas la paridad a la divisa"
        },
        {
            condicion: paridad.length > 8,
            ref: paridadRef, keyError: "paridad",
            mensajeError: "Esta excediendo el valor permitido de 8 caracteres"
        },

        /*   {
            condicion: !moment(fechaCotizacion).isValid(),
            ref: fechaCotizacionRef, keyError: "fechaCotizacion",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas la fecha de cotización de la divisa"
          }, */
        {
            condicion: !decimales || decimales === "",
            ref: divisaRef, keyError: "decimales",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas el número de decimales a la divisa"
        },
        {
            condicion: decimales.length > 2,
            ref: divisaRef, keyError: "decimales",
            mensajeError: "Esta excediendo el valor permitido de 2 caracteres"
        },
        {
            condicion: !porcentajeVariacion || porcentajeVariacion === "",
            ref: divisaRef, keyError: "porcentajeVariacion",
            mensajeError: "Para que podamos funcionar bien necesitamos que le definas el porcentaje de variación de la divisa"
        },
        {
            condicion: porcentajeVariacion.length > 8,
            ref: divisaRef, keyError: "porcentajeVariacion",
            mensajeError: "Esta excediendo el valor permitido de 8 caracteres"
        }
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    let formato = "YYYY-MM-DD";

    function ftGuardar() {
        async function ftGuardar() {

            let { divisaID, divisa, divisac, codigoMon, abreviacion, fechaCotizacion, paridad, filtrar, decimales, porcentajeVariacion } = state;

            let error = await handleValidaciones({ divisa, divisac, codigoMon, abreviacion/* ,fechaCotizacion: fechaCotizacion.format(formato) */, paridad, filtrar, decimales, porcentajeVariacion });

            if (error) return error;

            if (divisaID) await updateDivisa({
                input: { divisaID, divisa: trim(divisa), divisac: trim(divisac), codigoMon: trim(codigoMon), abreviacion: trim(abreviacion), fechaCotizacion: fechaCotizacion.format(formato).substr(0, 10), paridad: paridad === null ? null : Number(paridad), filtrar, decimales: decimales === null ? null : Number(decimales), porcentajeVariacion: porcentajeVariacion === null ? null : Number(porcentajeVariacion) > 1 ? Number(porcentajeVariacion) / 100 : Number(porcentajeVariacion) }
            }, token);
            else await addDivisa({
                input: {
                    divisa: trim(divisa), divisac: trim(divisac), codigoMon: trim(codigoMon), abreviacion: trim(abreviacion), fechaCotizacion: fechaCotizacion.format(formato).substr(0, 10), paridad: paridad === null ? null : Number(paridad), filtrar, decimales: decimales === null ? null : Number(decimales), porcentajeVariacion: porcentajeVariacion === null ? null : Number(porcentajeVariacion) > 1 ? Number(porcentajeVariacion) / 100 : Number(porcentajeVariacion)
                }
            }, token);


        }
        return ftGuardar();

    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleChangeSwitch(e) {
        setState(state => (
            {
                ...state, filtrar: e.target.checked
            })
        )
    };

    function handleValorPredeterminadoSAT(value, decimales, porcentajeVariacion) {
        setState(state => (
            {
                ...state, decimales: decimales,
                porcentajeVariacion: porcentajeVariacion,
                /* abreviacion: value */
            })
        )

    };

    useEffect(() => {
        async function ftEffect() {
            try {

                let estatus = await selectEnumEstatus(token);
                setSeleccionables({ estatus });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    useEffect(() => {
        let tipoError = ''
        try {


            if (state.divisa.length > 50) {
                setErrorState({ ...cleanErrorState, divisa: { error: false } })
                tipoError = 'divisa';
                throw new Error("Número de caracteres capturados excedido.");

            } else {
                setErrorState({ ...cleanErrorState, divisa: { error: false } })
            }

            if (state.abreviacion.length > 3) {
                setErrorState({ ...cleanErrorState, abreviacion: { error: false } })
                tipoError = 'abreviacion';
                throw new Error("Número de caracteres capturados excedido.");

            } else {
                setErrorState({ ...cleanErrorState, abreviacion: { error: false } })
            }

            if (state.fechaCotizacion.length === 0 || state.fechaCotizacion === '0000-00-00') {
                setErrorState({ ...cleanErrorState, fechaCotizacion: { error: false } })
                tipoError = 'fechaCotizacion';
                throw new Error("Fecha Cotización vacia.");

            } else {
                setErrorState({ ...cleanErrorState, fechaCotizacion: { error: false } })
            }

            if (!rgxNumeroDecimal4.test(state.paridad) && state.paridad != 0) {
                setErrorState({ ...cleanErrorState, paridad: { error: false } })
                tipoError = 'parida';
                throw new Error("Caracter no permitido.");
            } else {
                setErrorState({ ...cleanErrorState, paridad: { error: false } })
            }

            if (!rgxNumerosMaximo10.test(state.decimales) && state.decimales != 0) {
                setErrorState({ ...cleanErrorState, decimales: { error: false } })
                tipoError = 'decimales';
                throw new Error("Caracter no permitido.");
            } else {
                setErrorState({ ...cleanErrorState, decimales: { error: false } })
            }

            if (!rgxPorcentaje.test(state.porcentajeVariacion) && state.porcentajeVariacion != 0) {
                setErrorState({ ...cleanErrorState, porcentajeVariacion: { error: false } })
                tipoError = 'porcentajeVariacion';
                throw new Error("Caracter no permitido.");
            } else {
                setErrorState({ ...cleanErrorState, porcentajeVariacion: { error: false } })
            }
        } catch ({ message }) {

            switch (tipoError) {
                case "divisa":
                    setErrorState({ ...cleanErrorState, divisa: { error: true, helperText: message } })
                    break;
                case "abreviacion":
                    setErrorState({ ...cleanErrorState, abreviacion: { error: true, helperText: message } })
                    break;
                case "parida":
                    setErrorState({ ...cleanErrorState, paridad: { error: true, helperText: message } })
                    break;
                case "decimales":
                    setErrorState({ ...cleanErrorState, decimales: { error: true, helperText: message } })
                    break;
                case "porcentajeVariacion":
                    setErrorState({ ...cleanErrorState, porcentajeVariacion: { error: true, helperText: message } })
                    break;
            }
        }
    }, [state]);

    useEffect(() => {
        async function ftEffect() {
            try {
                let MonedasSAT = await seleccionMonedaSAT({
                    filtro: {}
                }, token);

                setSeleccionables({ MonedasSAT });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDivisas}

            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={divisaRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default Divisas;
