import { Restaurant } from "@material-ui/icons";
import { FetchAPI } from "@renedelangel/helpers";
import moment from "moment";
import { urlServidor } from '../../../helpers';
import { getArchivo, deleteFileStorage, blobToBase64 } from "../../../helpers/serverStorage";

export const tablaReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    query filterReporteDaniosGerencia($filtro: InputFilterReporteDaniosGerencia){
        filterReporteDaniosGerencia(filtro:$filtro){
        listado{
            reporteDaniosGerenciaID
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            finalizadoNombre
            seguimiento
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            codigo
            clasificacion
            nombreAccidentado
            diasIncapacidad
            cliente
            costosInvolucrados
            observaciones
            fechaModificacion
        }
        registros
        paginas
        }
    }
    `;

    let EsReporteador = false;

    if (document.location.pathname.includes("ReporteadorDeDanios")) {
        EsReporteador = true;
    }

    if (EsReporteador) {
        variables.filtro["finalizado"] = 1;
    } else {
        variables.filtro["finalizado"] = null;
    }

    const tResult = await Ejecutar(variables, token, Query);

    for (const [key,
            value
        ] of Object.entries(tResult)) {
        if (value === null) {
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    query get($filtro: InputGetReporteDaniosGerencia){
        getReporteDaniosGerencia(filtro:$filtro) {
            reporteDaniosGerenciaID
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            rutafotografia1
            storageID1
            nombrefoto1
            rutafotografia2
            storageID2
            nombrefoto2
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            codigo
            clasificacion
            nombreAccidentado
            diasIncapacidad
            cliente
            costosInvolucrados
            observaciones
            fechaModificacion
            ejercicio
            mes
            fechaInicio
            fechaFinReporte
        }
    }
    `;

    var resultado = await Ejecutar({
        filtro: variables
    }, token, Query);
    for (const [key,
            value
        ] of Object.entries(resultado)) {
        if (value === null) {
            resultado[key] = "";
        }
    }
    let foto1 = resultado.rutafotografia1 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia1, token) :
        "";
    resultado["fotografia1"] = foto1;

    let foto2 = resultado.rutafotografia2 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia2, token) :
        "";
    resultado["fotografia2"] = foto2;

    return resultado;
}

function formatearHora(pHora) {
    if (moment(pHora).isValid() === false) {
        let tHora = moment(pHora, 'HH:mm').format('HH:mm');
        if (moment(tHora).isValid() === false) {
            return tHora;
        }
        return "";
    }

    return moment(pHora).format("HH:mm");
}

export const addReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    mutation addReporteDaniosGerencia($input: InputAddReporteDaniosGerencia!) {
        addReporteDaniosGerencia(input: $input) {
            reporteDaniosGerenciaID
            reporteDaniosID
            usuarioBitID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            codigo
            clasificacion
            nombreAccidentado
            diasIncapacidad
            cliente
            costosInvolucrados
            observaciones
            fechaModificacion
        }
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const updateReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    mutation updateReporteDaniosGerencia($input: InputUpdateReporteDaniosGerencia!) {
        updateReporteDaniosGerencia(input: $input) {
            reporteDaniosGerenciaID
            reporteDaniosID
            usuarioBitID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            horasInvertidas
            codigoClasifDanio
            semanaDanio
            montos
            codigo
            clasificacion
            nombreAccidentado
            diasIncapacidad
            cliente
            costosInvolucrados
            observaciones
            fechaModificacion
        }
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const deleteReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    mutation deleteReporteDaniosGerencia($input: InputDeleteReporteDaniosGerencia) {
        deleteReporteDaniosGerencia(input: $input)
    }
    `;

    let valor = variables["reporteDaniosGerenciaID"];
    valor = {
        reporteDaniosGerenciaID: valor
    };

    var tResult = await Ejecutar({
        input: valor
    }, token, Query);
    /*let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);*/

    return tResult;
};

export const FinalizarReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    mutation finalizarReporteDaniosGerencia($input: InputFinalizarReporteDaniosGerencia){
        finalizarReporteDaniosGerencia(input: $input)
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const CerrarReporteDaniosGerencia = async(variables, token) => {
    var Query = `
    mutation cerrarReporteDaniosGerencia($input: InputCerrarReporteDaniosGerencia){
        cerrarReporteDaniosGerencia(input: $input)
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);
    tResult = JSON.parse(tResult)

    return tResult;
};

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}
