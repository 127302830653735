import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

//PARA EL GET:

export const GET_SOLSEGFIANEQUIPOELEC= `
query get($filtro: InputGetSolSegFianEquipoElec){
    getSolSegFianEquipoElec(filtro:$filtro){
    equipoElecID
    solSegFianID
    activoAsegID
    equipo
    noEco
    nocpu
    serieCPU
    noMonitor
    serieMonitor
    serie
    anio
    valorAseg
    empresaID
    ubicacionID
    sustituye
    motivo
    responsable
    sustituyeID
    activoSustituye
    omitir
    divisaID
    divisa
    departamento
    paridad
    activo
    modelo
    montoAsegurado
    marca
}
}
`;


//PARA EL GET ALL:

export const GET_ALL_SOLSEGFIANEQUIPOELEC = `
query filtroGetAll($filtro: InputGetSolSegFianEquipoElec){
    getAllSolSegFianEquipoElec(filtro: $filtro){
        equipoElecID
        solSegFianID
        activoAsegID
        equipo
        noEco
        nocpu
        serieCPU
        noMonitor
        serieMonitor
        serie
        anio
        valorAseg
        empresaID
        ubicacionID
        sustituye
        motivo
        responsable
        sustituyeID
        activoSustituye
        omitir
        divisaID
        divisa
        departamento
        paridad
        modelo
        tipoActivoAsegurado
        montoAsegurado
        marca
}
}
`;

//PARA LA TABLA:

export const TABLA_SOLSEGFIANEQUIPOELEC = `
query filtro($filtro: InputFilterSolSegFianEquipoElec){
    filterSolSegFianEquipoElec(filtro: $filtro){
	listado{
        equipoElecID
        solSegFianID
        activoAsegID
        equipo
        noEco
        nocpu
        serieCPU
        noMonitor
        serieMonitor
        serie
        anio
        valorAseg
        empresaID
        ubicacionID
        sustituye
        motivo
        responsable
        sustituyeID
        activoSustituye
        departamento
        modelo
        tipoActivoAsegurado
        divisaID
        divisa
        montoAsegurado
        marca
	}
	registros
	paginas
	}
}
`;

export const getSolSegFianEquipoElec = async (filtro, token) => {
    try{
        let {getSolSegFianEquipoElec} = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_SOLSEGFIANEQUIPOELEC
        });
        return getSolSegFianEquipoElec;
    }catch ({message}) {throw new Error(message);}
}

export const getAllSolSegFianEquipoElec = async (variables, token) => {
    try{
        let {getAllSolSegFianEquipoElec} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SOLSEGFIANEQUIPOELEC
        });

        return getAllSolSegFianEquipoElec;
    }catch ({message}) {throw new Error(message);}
}

export const seleccionSolSegFianEquipoElec = async (variables, token) => {
    try{
        let allSolSegFianEquipoElec = await getAllSolSegFianEquipoElec(variables, token);
        if (!Array.isArray(allSolSegFianEquipoElec)) throw new Error ("No se encontraron registros");

        return allSolSegFianEquipoElec.map(({equipoElecID: value, equipo: label}) => ({value, label: label.toUpperCase()}));


    }catch({message:label}){
        return[{
            label,
            value: null,
            disabled: true
        }];
    }
}


export const tablaSolSegFianEquipoElec = async (variable, token) => {
    try{
        let { equipoElecID, solSegFianID, activoAsegID, equipo, noEco, nocpu, serieCPU, noMonitor, serieMonitor, serie, anio, valorAseg, empresaID, ubicacionID, sustituye, motivo, responsable, divisa, pagina, limite, marca} = variable.filtro;
        let filtro = {equipoElecID: equipoElecID === null ? null : Number (equipoElecID), solSegFianID, activoAsegID, equipo, noEco, nocpu, serieCPU, noMonitor,
            serieMonitor, serie, anio, valorAseg, empresaID, ubicacionID, sustituye, motivo, responsable, divisa, pagina, limite, marca};
            let variables = {filtro};
            let {filterSolSegFianEquipoElec} = await FetchAPI.graphql({
                token,
                variables,
                url: urlServidor,
                query: TABLA_SOLSEGFIANEQUIPOELEC
            });
            return filterSolSegFianEquipoElec;
    }catch({message}) {throw new Error(message);}
}