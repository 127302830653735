import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_TAREAS_PROG_ANUAL = `
mutation addTareasProgAnual($input: InputAddTareasProgAnual!) {
    addTareasProgAnual(input: $input) {
            tareaID
            programaAnualID
            empresaID
            polizaID
            ramoSeguroID
            usuarioID
            usuarioBitID
            numero
            tipoTarea
            objetivo
            fechaAlta
            fechaModificacion
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            siglas
            numPolEndoso
            ramoSeguro
    }
}
`;


export const UPDATE_TAREAS_PROG_ANUAL = `
mutation updateTareasProgAnual($input: InputUpdateTareasProgAnual!) {
    updateTareasProgAnual(input: $input) {
            tareaID
            programaAnualID
            empresaID
            polizaID
            ramoSeguroID
            usuarioID
            usuarioBitID
            numero
            tipoTarea
            objetivo
            fechaAlta
            fechaModificacion
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            siglas
            numPolEndoso
            ramoSeguro
    }
}
`;

export const DELETE_TAREAS_PROG_ANUAL = `
mutation deleteTareasProgAnual($input: InputDeleteTareasProgAnual) {
    deleteTareasProgAnual(input: $input)
}
`;


export const addTareasProgAnual = async (variables, token) => {
    try {

        let { addTareasProgAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_TAREAS_PROG_ANUAL
        });

        return addTareasProgAnual;

    } catch ({ message }) { throw new Error(message); }
};

export const updateTareasProgAnual = async (variables, token) => {
    try {

        let { updateTareasProgAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_TAREAS_PROG_ANUAL
        });

        return updateTareasProgAnual;

    } catch ({ message }) { throw new Error(message); }
};


export const deleteTareasProgAnual = async (input, token) => {
    try {
            
        let { deleteTareasProgAnual } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: DELETE_TAREAS_PROG_ANUAL
        });

        return deleteTareasProgAnual;

    } catch ({ message }) { throw new Error(message); }
};
