import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';

//Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from 'react-router-dom';

//master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

//componentes genericos
import SweetAlert from '_layout/genericComponents/ModalConfirmacion';

//Queries
import { getActivosAsegurado, getAllActivosAsegurado, seleccionActivosAsegurado, tablaActivosAsegurado } from '../../../querys/ActivosAsegurado';

//Mutations
import { addActivosAsegurado, updateActivosAsegurado, deleteActivosAsegurado } from '../../../mutations/ActivosAsegurado';


//iconos
import SyncIcon from '@material-ui/icons/Sync';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import BuildIcon from '@material-ui/icons/Build';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

//HANDLERS
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';

//COLORES
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { handleChange } from 'helpers/rutinas/metodos/rutinasProductos';
import * as Permisos from '../Permisos';
const { trim } = Validaciones;

function ActivosAsegurado({ cClasif = 'Automoviles', cTitulo }) {

    const { token } = useSelector(state => state.login);
    const cleanState =
    {
        activoAsegID: null,
        activoaseg: "",
        clasificacion: cClasif,
        tipoActivo: "MOVIL"
    };

    const cleanErrorState =
    {
        activoaseg: { error: false, helperText: "" },
        clasificacion: { error: false, helpertText: "" },
        tipoActivo: {error: false, helperText: ""}
    };

    const cleanNotificaciones = { mensajes: "", color: null, open: false };

    //seleccionables
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    let titleAccion = state.activoAsegID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} ${cTitulo}`

    const activoasegRef = useRef(null);
    const clasificacionRef = useRef(null);
    const tipoActivoRef = useRef(null);

    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "activoAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getActivosAsegurado
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "activoAsegID" },
            { campo: "activoaseg", variable: "activoaseg" },
            { campo: "clasificacion", variable: "clasificacion" }],
            disabled: { multiseleccion: true },
            onClick: ({ activoAsegID, activoaseg }) => handelAlertEliminar({
                setAlert,
                mensaje: `El ${cTitulo} ${activoaseg}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { activoAsegID },
                    asyncDelete: deleteActivosAsegurado
                })
            })
        }

    ];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    },
    {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    let mostrarColumna = cClasif === "Equipo Electronico" ? false : true;
    const objetoIconos = {
        "Equipo de Operaciones": < BuildIcon />,
        "Equipo Electronico": < ImportantDevicesIcon/>,
        "Camiones y Chasis": < LocalShippingIcon/>,
        "Automoviles": < DriveEtaIcon/>
    };
    let infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "activoAsegID",
        color: primary,
        title: " Activos para asegurar: " + cClasif,
        iconTable:objetoIconos[cClasif],
        headers: [
            { variable: "activoAsegID", descripcion: "ID", hide: true },
            { variable: "activoaseg", descripcion: "Activo Asegurado" },
            { variable: "clasificacion", descripcion: "Clasificación" },
            { variable: "tipoActivo", descripcion: "Tipo de Activo", hide: mostrarColumna }
        ],
        responsiveTitle: ["activoaseg"],
        filter: [{ campo: "activoaseg", placeholder: "Activo Asegurado" }],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    if(!mostrarColumna)
    {
        infoTabla = {...infoTabla,selectFilter: [
            {
                campo: "tipoActivo",
                placeholder: "Seleccione el tipo de activo",
                data: [
                    {label: 'MÓVIL',value: 'MOVIL'},
                    {label: 'FIJO', value: 'FIJO'}
                ]
            }
        ]}

        infoTabla = {...infoTabla,  formato: [
            {
                columnas: ["tipoActivo"], tipo: "custom", callback: (value) => {
                    return value === "MOVIL" ? "MÓVIL" : value;
                }
            },
        ]}
    }
    
    const inputs = [
        {
            disabled,
            id: "activoaseg",
            value: state.activoaseg,
            error: errorState.activoaseg.error,
            success: state.activoaseg && !errorState.activoaseg.error ? true :
                undefined,
            helperText: errorState.activoaseg.helperText,
            inputRef: activoasegRef,
            title: "Activo Asegurado",
            placeholder: `Capture el nombre del ${cTitulo}`,
            onChange: ({ target: { value } }) => handleChange(value, "activoaseg"),
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "clasificacion",
            value: state.clasificacion,
            error: errorState.clasificacion.error,
            success: state.clasificacion && !errorState.clasificacion.error ? true :
                undefined,
            helperText: errorState.clasificacion.helperText,
            inputRef: clasificacionRef,
            title: "Clasificación",
            placeholder: "Capture la clasificación del Activo Asegurado",
            onChange: ({ target: { value } }) => handleChange(value, "clasificacion"),
            onKeyDown: () => { return; }
        },
        
    ];

    if (!mostrarColumna)
    {
        inputs.push({
            disabled,
            id: "tipoActivo",
            value: state.tipoActivo,
            tipo: "select",
            inputRef: tipoActivoRef,
            data: [
                {label: 'MÓVIL',value: 'MOVIL'},
                {label: 'FIJO', value: 'FIJO'}
            ],
            success: state.tipoActivo && !errorState.tipoActivo.error ? true : undefined,
            title: "Tipo de Activo",
            helperText: errorState.tipoActivo.helperText,
            error: errorState.tipoActivo.error,
            onChange: ({target: {value}}) => handleChange(value, "tipoActivo")
        })
    }

    let accionesFormulario = [{
        accionID: state.activoAsegID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: activoasegRef,
            mensajeCorrecto: `El ${cTitulo} se ${state.activoAsegID ? "actualizo" : "agrego"} correctamente`,
            asyncCallback: ftGuardar
        })
    },
    {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }

    ];

    //FUNCION QUE PERMITE MANEJAR EL CERRADO DEL FORMULARIO MODAL
    //AL FINALIZAR EL REGISTRO DE DATOS
    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    //FUNCION PARA MANEJAR LOS ERRORES AL INTRODUCIR TEXTO
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    //FUNCION PARA REALIZAR LAS VALIDACIONES
    function handleValidaciones({
        activoaseg, clasificacion, tipoActivo }) {

        let error;
        let validaciones = [
            {
                condicion: !activoaseg || trim(activoaseg) === "",
                ref: activoasegRef, keyError: "activoaseg",
                mensajeError: `El nombre del ${cTitulo} es dato obligatorio. Faltan datos.`
            },
            {
                condicion: !clasificacion || trim(clasificacion) === "",
                ref: clasificacionRef, keyError: "clasificacion",
                mensajeError: `La clasificación es dato obligatorio. Faltan datos.`
            }
        ];

        if(!mostrarColumna)
        {
            validaciones.push({
                condicion: !tipoActivo || trim(tipoActivo) === "",
                ref: tipoActivoRef, keyError: "tipoActivo",
                mensajeError: `El tipo de activo del ${cTitulo} es dato obligatorio. Faltan datos.`
            })
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }


    //FUNCION PARA GUARDAR LOS CAMPOS INDICADOS
    function ftGuardar() {
        async function ftGuardar() {

            let { activoAsegID,
                activoaseg, clasificacion, tipoActivo } = state;

            let error = await handleValidaciones({
                activoaseg, clasificacion, tipoActivo
            });

            if (error) return error;

            if (activoAsegID) await updateActivosAsegurado({
                input: { activoAsegID, activoaseg: trim(activoaseg), clasificacion: trim(clasificacion), tipoActivo: !mostrarColumna ? trim(tipoActivo) : null
                }
            }, token);
            else await addActivosAsegurado({
                input: { activoaseg: trim(activoaseg), clasificacion: trim(clasificacion), tipoActivo: !mostrarColumna ? trim(tipoActivo) : null }
            }, token);
        }


        return ftGuardar();
    }

    //FUNCION PARA MANEJAR LOS CAMBIOS
    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    //ALERTA DE QUE FALTAN ALGUNOS ELEMENTOS AL MOMENTO DE INICIALIZAR LA PAGINA
    useEffect(() => {
        async function ftEffect() {
            try {
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    //INFORMACION A MOSTRAR
    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaActivosAsegurado}
            token={token}
            parametrosFijos={{ clasificacion: cClasif }}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotificaciones={() => setNotificaciones({
                ...cleanNotificaciones
            })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={activoasegRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);




}

export default ActivosAsegurado;
