import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getAseguradora, tablaAseguradora } from '../../../querys/Aseguradoras';
import { deleteAseguradora, addAseguradora, updateAseguradora } from '../../../mutations/Aseguradoras';


import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

import { letrasMod } from '../../../helpers/regexp';
import * as Permisos from '../Permisos';

const { trim } = Validaciones;

function Aseguradoras() {
    const { token } = useSelector(state => state.login);
    const cleanState = {
        aseguradoraID: null,
        aseguradora: ""
    };
    const cleanErrorState = {
        aseguradora: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    let titleAccion = state.aseguradoraID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Aseguradora`;

    const aseguradoraRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "aseguradoraID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getAseguradora
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "aseguradoraID" },
        { campo: "aseguradora" }],
        disabled: { multiseleccion: true },
        onClick: ({ aseguradoraID, aseguradora }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la aseguradora ${aseguradora}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { aseguradoraID }, asyncDelete: deleteAseguradora
            })
        })
    }];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "aseguradoraID",
        color: primary,
        title: "Aseguradoras",
        iconTable: <AssignmentIndIcon />,
        headers: [{ variable: "aseguradoraID", descripcion: "ID", hide: true },
        { variable: "aseguradora", descripcion: "Aseguradora" }
        ],
        responsiveTitle: ["aseguradora"],
        filter: [
            { campo: "aseguradora" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "aseguradora",
                    value: state.aseguradora,
                    error: errorState.aseguradora.error,
                    success: state.aseguradora && !errorState.aseguradora.error ? true : undefined,
                    helperText: errorState.aseguradora.helperText,
                    inputRef: aseguradoraRef,
                    title: "Aseguradora *",
                    placeholder: "Capture el nombre de la aseguradora",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "aseguradora")
                    },
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];

    let accionesFormulario = [{
        accionID: state.aseguradoraID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: aseguradoraRef,
            mensajeCorrecto: `La aseguradora se ${state.aseguradoraID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ aseguradora }) {

        let error;
        let validaciones = [
            {
                condicion: !aseguradora || trim(aseguradora) === "",
                ref: aseguradoraRef, keyError: "aseguradora",
                mensajeError: "Debe especificar la aseguradora"
            },
            {
                condicion: aseguradora.length > 100,
                ref: aseguradoraRef, keyError: "aseguradora",
                mensajeError: "Número de caracteres capturados excedido."
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { aseguradoraID,
                aseguradora } = state;

            let error = await handleValidaciones({
                aseguradora
            });

            if (error) return error;

            if (aseguradoraID) await updateAseguradora({
                input: { aseguradoraID, aseguradora: trim(aseguradora) }
            }, token);
            else await addAseguradora({
                input: { aseguradora: trim(aseguradora) }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaAseguradora}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={aseguradoraRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default Aseguradoras;
