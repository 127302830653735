import { ErrorRounded } from '@material-ui/icons';
import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const GET_ACTIVOASEG = `
query get($filtro: InputGetActivosAsegurado)
{
    getActivosAsegurado(filtro: $filtro)
    {
        activoAsegID
        usuarioBitID
        activoaseg
        clasificacion
        tipoActivo
    }
}
`;

export const GET_ALL_ACTIVOASEG = `
query filtroGetAll($filtro: InputGetActivosAsegurado)
{
    getAllActivosAsegurado(filtro: $filtro)
    {
        activoAsegID
        activoaseg
        clasificacion
        tipoActivo
    }
}`;

export const TABLA_ACTIVOASEG = `
query filtro($filtro: InputFilterActivosAsegurado)
{
    filterActivosAsegurado(filtro: $filtro)
    {
        listado
        {
            activoAsegID
            activoaseg
            clasificacion
            tipoActivo
        }
        registros
        paginas
    }
}`;

export const getActivosAsegurado = async (filtro, token) => {
    try{
        let {getActivosAsegurado} = await FetchAPI.graphql({
            token,
            variables: {filtro},
            url: urlServidor,
            query: GET_ACTIVOASEG
        });
        return getActivosAsegurado;

    }catch ({message}) {throw new Error(message);}
}

export const getAllActivosAsegurado = async (variables, token) => {

    try{
        let {getAllActivosAsegurado} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_ACTIVOASEG
        });

        return getAllActivosAsegurado;

    }catch ({message}) {throw new Error(message);}
}

export const seleccionActivosAsegurado = async (variables, token) => {
    try{
        let allActivoAseg = await getAllActivosAsegurado(variables, token);

        if(!Array.isArray(allActivoAseg)) throw new Error ("No se encontraron registros");

        let auxAllActivoAseg = allActivoAseg.map(({activoAsegID: value, activoaseg: label, tipoActivo}) => ({value, label: label.toUpperCase(), tipoActivo}));
        auxAllActivoAseg.unshift({value: "0", label: 'NO APLICA'});
        return auxAllActivoAseg;
    }catch({message: label}){
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}


export const tablaActivosAsegurado = async (variable, token) => { 
    try{
        //let {activoAsegID, activoAseg, clasificacion,tipoActivo, pagina, limite} = variable.filtro;
        //let filtro = {activoAsegID: activoAsegID === null ? null : Number (activoAsegID), activoAseg, clasificacion,tipoActivo, pagina, limite};
        //let variables = {filtro};
        let {filterActivosAsegurado} = await FetchAPI.graphql({
            token,
            //variables,
            variables: variable,
            url: urlServidor,
            query: TABLA_ACTIVOASEG
        });

        return filterActivosAsegurado;
    }catch({message}) {throw new Error(message);}
}
