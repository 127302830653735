import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import {BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getSolSegFianHogar, tablaSolSegFianHogar } from '../../../querys/SolSegFianHogar';
import { deleteSolSegFianHogar, addSolSegFianHogar, updateSolSegFianHogar } from '../../../mutations/SolSegFianHogar';

import { getEdad } from '../../../helpers/rutinas/metodos/rutinasGenerales';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { urlServidor } from '../../../helpers';
import { letrasMod, rgxdeInt4, rgxInputTexto150, rgxRFC, rgxInputTexto100 } from '../../../helpers/regexp';
import moment from 'moment';
import Documentacion from '../Documentacion';

const { trim } = Validaciones;
const {Encrypt,Decrypt}=Encriptar;

function SolSegFianHogar({datosDetalle,setOpenSolSegFianHogar,lMostrarFormularioCompleto}) {

    let history = useHistory();

    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let mostrarTabla = false;
    let nHogarID = null;
    let datosDetalleURL ={};
    let cfolioFormateado = null;
    let cTitulo2="";
    let cEstatus = null;
    let nSolicitud = null;
    let {params} = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let {pURLSolSegFianID,datosDetalle,pfolioFormateado, pSolicitud} = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null ;
        URLSolSegFianID = pURLSolSegFianID;
        datosDetalleURL = datosDetalle;
        cfolioFormateado = pfolioFormateado;
        nSolicitud = pSolicitud;
    }
    let {onCerrarModal,onOcultarTabla } = datosDetalleURL;
        mostrarTabla = URLSolSegFianID ? true : false;

        if (datosDetalleURL!= undefined && datosDetalleURL.length>0){
            lUtilizaProps = false;
       }

     if (lUtilizaProps) {
        let {pEstatus,pSolSegFianID,pHogarID,mostrarTabla,pfolioFormateado, pSolicitud } =  datosDetalle;
        URLSolSegFianID =pSolSegFianID;
        mostrarTabla = mostrarTabla ;
        nHogarID = pHogarID;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;

    }
    mostrarTabla=lMostrarFormularioCompleto;
    //let {pHogarID,pSolSegFianID,mostrarTabla,onCerrarModal,onOcultarTabla} = props.datosDetalle
    const { token } = useSelector(state => state.login);


    let now = moment();

    const cleanState = {
        hogarID: nHogarID,
        solSegFianID: URLSolSegFianID,
        nombreContratante: "", 
        rfc: "",
        direccion: "",
        anioEstimadoCons: 0,
        numPisos: 0,
        pisoResidencia: 0,
        numSotanos: 0,
        tipoMuro: "",
        tipoTecho: "",
        tipoEntrepiso: "",
        tipoEstructura: ""
    };
    const cleanErrorState = {

        solSegFianID: { error: false, helperText: "" },
        nombreContratante: { error: false, helperText: "" },
        rfc: { error: false, helperText: "" },
        direccion: { error: false, helperText: "" },
        anioEstimadoCons: { error: false, helperText: "" },
        numPisos: { error: false, helperText: "" },
        pisoResidencia: { error: false, helperText: "" },
        numSotanos: { error: false, helperText: "" },
        tipoMuro: { error: false, helperText: "" },
        tipoTecho: { error: false, helperText: "" },
        tipoEntrepiso: { error: false, helperText: "" },
        tipoEstructura: { error: false, helperText: "" }
    }


    const cleanNotificaciones = { mensaje: "", color: null, open: false };


    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    let [datosPDF, setdatosPDf] = useState({});

    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);


    let titleAccion = state.afianzadoraID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Seguro de Hogar`;


    const solSegFianIDRef = useRef(null);
    const nombreContratanteRef = useRef(null); 
    const rfcRef = useRef(null);
    const direccionRef = useRef(null);
    const anioEstimadoConsRef = useRef(null);
    const numPisosRef = useRef(null);
    const pisoResidenciaRef = useRef(null);
    const numSotanosRef = useRef(null);
    const tipoMuroRef = useRef(null);
    const tipoTechoRef = useRef(null);
    const tipoEntrepisoRef = useRef(null);
    const tipoEstructuraRef = useRef(null);


    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "hogarID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getSolSegFianHogar
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "hogarID" },
        { campo: "nombreContratante" }],
        disabled: { multiseleccion: true },
        onClick: ({ hogarID, nombreContratante }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el seguro de ${nombreContratante}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { hogarID }, asyncDelete: deleteSolSegFianHogar
            })
        })
    },
    {
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{campo: "hogarID"} ],
        disabled: {multiseleccion: true},
        onClick: ({hogarID}) =>
        {
           // setRedireccionarFormulario({hogarID }) ;
           setDatosDetalleAdjuntos({
            pURLDoctoOrigenID:hogarID ,
             pTipo:cfolioFormateado ,
             pClasificacion: "",
             pTabla: "Solicitud Seguro Viajero",
             pTitulo2: cTitulo2,
             plUtilizaProps: true
        });
        setOpenDocumentacion(true);
        }
    },
    // {
    //     icono: PictureAsPdfIcon,
    //     color: primary,
    //     descripcion: "Descargar PDF",
    //     parametros: [{ campo: "hogarID" }, { campo: "nombres" }],
    //     disabled: { multiseleccion: true },
    //     onClick: ({ hogarID, nombres }) => AuxDescargar(hogarID, nombres)
    // }
];

    function AuxDescargar(hogarID, nombres){
        setdatosPDf({"hogarID": hogarID, "nombres": nombres});
        datosPDF = {"hogarID": hogarID, "nombres": nombres};

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion:"¿Deseas descargar el  PDF del seguro de viaje?", tipo:"warning", msjConfirmacion:"Aceptar", msjCancelacion:"Cancelar"},

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros:  {}, asyncMutation: () => DescargarPdf(),
                mensajes:{
                    msjEspera:{
                        title: "Espere...", descripcion: "Generando el PDF del seguro de viaje de " + nombres, tipo : success
                    },
                    msjCorrecto:{
                        title: "Se generó con éxito", descripcion: 'Se descargó el PDF del seguro de viaje de ' + nombres, msjConfirmacion:"Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf(){
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("hogarID", datosPDF.hogarID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteSolicitudSolSegFianHogar' + datosPDF.nombres
        await fetch(`${urlServidor}/service/reportes/ReporteSolicitudSolSegFianHogar`, requestOptions)
            .then(response => {
                if (!response.ok)  return response.json();
                return response.blob()
                            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                    showFile(result, `${nombreReporte}.pdf`);
                setAlert(null)
                            })
            .catch(({message}) => {throw new Error(message)});
    }

    let botones = [ {
        icono: BackspaceOutlined,
        color: danger,
        descripcion:"Regresar",
        disabled: {multiseleccion: true},
        onClick:() => {
        setOpenSolSegFianHogar();
    }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    if (cEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "hogarID",
        color: primary,
        title: "Hogar",
        title2: cTitulo2,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "hogarID", descripcion: "hogarID", hide: true },
            { variable: "solSegFianID", descripcion: "solSegFianID", hide: true },
            { variable: "nombreContratante", descripcion: "Nombre del Contratante", hide: false },
            { variable: "rfc", descripcion: "RFC", hide: false },
            { variable: "direccion", descripcion: "Direccion", hide: false },
            { variable: "anioEstimadoCons", descripcion: "Año Estimado Cons.", hide: false },
            { variable: "numPisos", descripcion: "No. Pisos", hide: false },
            { variable: "pisoResidencia", descripcion: "Piso de Residencia", hide: false },
            { variable: "numSotanos", descripcion: "No. Sotanos", hide: false },
            { variable: "tipoMuro", descripcion: "Tipo de Muro", hide: false },
            { variable: "tipoTecho", descripcion: "Tipo de Techo", hide: false },
            { variable: "tipoEntrepiso", descripcion: "Tipo de Entrepiso", hide: false },
            { variable: "tipoEstructura", descripcion: "Tipo de Estructura", hide: false }
        ],
        responsiveTitle: ["nombreContratante"],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }



    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: InputsDisabled(),
                    id: "nombreContratante",
                    value: state.nombreContratante,
                    error: errorState.nombreContratante.error,
                    success: state.nombreContratante && !errorState.nombreContratante.error ? true : undefined,
                    helperText: errorState.nombreContratante.helperText,
                    inputRef: nombreContratanteRef,
                    title: "Contratante *",
                    placeholder: "Capture el nombre del contratante",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
         /*            if(!letrasMod.test(value)) return; */
                        rgxInputTexto150.test(value) &&
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "nombreContratante")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, nombreContratante: { error: state.nombreContratante.length > 150 && true, helperText: state.nombreContratante.length > 150 ? "Límite de 150 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, rfcRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "rfc",
                    value: state.rfc,
                    error: errorState.rfc.error,
                    success: state.rfc && !errorState.rfc.error ? true : undefined,
                    helperText: errorState.rfc.helperText,
                    inputRef: rfcRef,
                    title: "RFC *",
                    placeholder: "Capture el RFC",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        /*               if(!rgxRFCMod.test(value)) return; */
                        handleChange(value.toUpperCase(), "rfc")
                    },
                    onKeyDown: (evt) => handleFocus(evt, direccionRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "direccion",
                    value: state.direccion,
                    error: errorState.direccion.error,
                    success: state.direccion && !errorState.direccion.error ? true : undefined,
                    helperText: errorState.direccion.helperText,
                    inputRef: direccionRef,
                    title: "Direccion *",
                    placeholder: "Capture la direccion",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto150.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "direccion")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, direccion: { error: state.direccion.length > 150 && true, helperText: state.direccion.length > 150 ? "Límite de 150 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, anioEstimadoConsRef)
                }]
        },
        
        {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 12 },
            leyenda: "▀",
            inputs: [

                {
                    disabled: InputsDisabled(),
                    id: "anioEstimadoCons",
                    value: state.anioEstimadoCons,
                    error: errorState.anioEstimadoCons.error,
                    success: state.anioEstimadoCons && !errorState.anioEstimadoCons.error ? true : undefined,
                    helperText: errorState.anioEstimadoCons.helperText,
                    inputRef: anioEstimadoConsRef,
                    title: "Año estimado de construccion *",
                    placeholder: "Capture el año estimado de construccion",
                    grid: { md: 3, lg: 3 },
                    textAlign: "right",
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt4.test(value)) &&
                            handleChange(value, "anioEstimadoCons")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, anioEstimadoCons: { error: state.anioEstimadoCons.length > 4 && true, helperText: state.anioEstimadoCons.length > 4 ? "Límite de 4 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, numPisosRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "numPisos",
                    value: state.numPisos,
                    error: errorState.numPisos.error,
                    success: state.numPisos && !errorState.numPisos.error ? true : undefined,
                    helperText: errorState.numPisos.helperText,
                    inputRef: numPisosRef,
                    title: "No. de pisos *",
                    placeholder: "Capture el No. de pisos",
                    grid: { md: 3, lg: 3 },
                    textAlign: "right",
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt4.test(value)) &&
                            handleChange(value, "numPisos")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, numPisos: { error: state.numPisos.length > 4 && true, helperText: state.numPisos.length > 4 ? "Límite de 4 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, numSotanosRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "numSotanos",
                    value: state.numSotanos,
                    error: errorState.numSotanos.error,
                    success: state.numSotanos && !errorState.numSotanos.error ? true : undefined,
                    helperText: errorState.numSotanos.helperText,
                    inputRef: numSotanosRef,
                    title: "No. de Sotanos *",
                    placeholder: "Capture el No. de Sotanos",
                    grid: { md: 3, lg: 3 },
                    textAlign: "right",
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt4.test(value)) &&
                            handleChange(value, "numSotanos")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, numSotanos: { error: state.numSotanos.length > 4 && true, helperText: state.numSotanos.length > 4 ? "Límite de 4 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, pisoResidenciaRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "pisoResidencia",
                    value: state.pisoResidencia,
                    error: errorState.pisoResidencia.error,
                    success: state.pisoResidencia && !errorState.pisoResidencia.error ? true : undefined,
                    helperText: errorState.pisoResidencia.helperText,
                    inputRef: pisoResidenciaRef,
                    title: "Piso de residencia *",
                    placeholder: "Capture el piso de residencia",
                    grid: { md: 3, lg: 3 },
                    textAlign: "right",
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt4.test(value)) &&
                            handleChange(value, "pisoResidencia")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, pisoResidencia: { error: state.pisoResidencia.length > 4 && true, helperText: state.pisoResidencia.length > 4 ? "Límite de 4 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, tipoMuroRef)
                },
            ]}, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: InputsDisabled(),
                    id: "tipoMuro",
                    value: state.tipoMuro,
                    error: errorState.tipoMuro.error,
                    success: state.tipoMuro && !errorState.tipoMuro.error ? true : undefined,
                    helperText: errorState.tipoMuro.helperText,
                    inputRef: tipoMuroRef,
                    title: "Tipo de Muro *",
                    placeholder: "Capture la tipo de muro",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto100.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "tipoMuro")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, tipoMuro: { error: state.tipoMuro.length > 100 && true, helperText: state.tipoMuro.length > 100 ? "Límite de 100 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, tipoTechoRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "tipoTecho",
                    value: state.tipoTecho,
                    error: errorState.tipoTecho.error,
                    success: state.tipoTecho && !errorState.tipoTecho.error ? true : undefined,
                    helperText: errorState.tipoTecho.helperText,
                    inputRef: tipoTechoRef,
                    title: "Tipo de Techo *",
                    placeholder: "Capture el tipo de Techo",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto100.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "tipoTecho")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, tipoTecho: { error: state.tipoTecho.length > 100 && true, helperText: state.tipoTecho.length > 100 ? "Límite de 100 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, tipoEntrepisoRef)
                }, 
                {
                    disabled: InputsDisabled(),
                    id: "tipoEntrepiso",
                    value: state.tipoEntrepiso,
                    error: errorState.tipoEntrepiso.error,
                    success: state.tipoEntrepiso && !errorState.tipoEntrepiso.error ? true : undefined,
                    helperText: errorState.tipoEntrepiso.helperText,
                    inputRef: tipoEntrepisoRef,
                    title: "Tipo de Entre Piso *",
                    placeholder: "Capture la tipo de Entre Piso",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto100.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "tipoEntrepiso")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, tipoEntrepiso: { error: state.tipoEntrepiso.length > 100 && true, helperText: state.tipoEntrepiso.length > 100 ? "Límite de 100 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, tipoEstructuraRef)
                }, 
                {
                    disabled: InputsDisabled(),
                    id: "tipoEstructura",
                    value: state.tipoEstructura,
                    error: errorState.tipoEstructura.error,
                    success: state.tipoEstructura && !errorState.tipoEstructura.error ? true : undefined,
                    helperText: errorState.tipoEstructura.helperText,
                    inputRef: tipoEstructuraRef,
                    title: "Tipo de Estructura *",
                    placeholder: "Capture la tipo de Estructura",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto100.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "tipoEstructura")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, tipoEstructura: { error: state.tipoEstructura.length > 100 && true, helperText: state.tipoEstructura.length > 100 ? "Límite de 100 caracteres excedido" : "" } })
                    },
                }, ]
        }, 
    ];

    function InputsDisabled() {
        return false;
}

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: solSegFianIDRef,
            mensajeCorrecto: `El hogar se ${state.hogarID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }


    function handleValidaciones({ 
        nombreContratante, 
        rfc,
        direccion,
        anioEstimadoCons,
        numPisos,
        pisoResidencia,
        numSotanos,
        tipoMuro,
        tipoTecho,
        tipoEntrepiso,
        tipoEstructura  }) {

        let error;
        let validaciones = [
            {
                condicion: !nombreContratante || trim(nombreContratante) === "",
                ref: nombreContratanteRef, keyError: "nombreContratante",
                mensajeError: "Debe especificar el nombre del contratante"
            },
            {
                condicion: !rgxRFC.test(rfc), ref: rfcRef, keyError: "rfc",
                mensajeError: "El RFC capturado lamentablemente no tiene un formato correcto"
            },
            {
                condicion: !direccion || trim(direccion) === "",
                ref: direccionRef, keyError: "direccion",
                mensajeError: "Debe especificar la direccion"
            },
            {
                condicion: !anioEstimadoCons || anioEstimadoCons === "",
                ref: anioEstimadoConsRef, keyError: "anioEstimadoCons",
                mensajeError: "Debe especificar el año de construccion"
            },
            {
                condicion: numPisos.length > 1,
                ref: numPisosRef, keyError: "numPisos",
                mensajeError: "Debe tener al menos un piso"
            },
            {
                condicion: !pisoResidencia || pisoResidencia === "",
                ref: pisoResidenciaRef, keyError: "pisoResidencia",
                mensajeError: "Debe especificar el piso de residencia"
            },
            {
                condicion: !tipoMuro || trim(tipoMuro) === "",
                ref: tipoMuroRef, keyError: "tipoMuro",
                mensajeError: "Debe especificar el tipo de muro"
            },
            {
                condicion: !tipoTecho || trim(tipoTecho) === "",
                ref: tipoTechoRef, keyError: "tipoTecho",
                mensajeError: "Debe especificar el tipo de techo"
            },
            {
                condicion: !tipoEntrepiso || trim(tipoEntrepiso) === "",
                ref: tipoEntrepisoRef, keyError: "tipoEntrepiso",
                mensajeError: "Debe especificar el tipo de entre piso"
            },
            {
                condicion: !tipoEstructura || trim(tipoEstructura) === "",
                ref: tipoEstructuraRef, keyError: "tipoEstructura",
                mensajeError: "Debe especificar el tipo de estructura"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let {
                hogarID,
                nombreContratante, 
                rfc,
                direccion,
                anioEstimadoCons,
                numPisos,
                pisoResidencia,
                numSotanos,
                tipoMuro,
                tipoTecho,
                tipoEntrepiso,
                tipoEstructura } = state;

                let error = await handleValidaciones({
                    nombreContratante, 
                    rfc,
                    direccion,
                    anioEstimadoCons,
                    numPisos,
                    pisoResidencia,
                    numSotanos,
                    tipoMuro,
                    tipoTecho,
                    tipoEntrepiso,
                    tipoEstructura 
                });
    
                if (error) return error;

            if (hogarID) await updateSolSegFianHogar({
                input: {
                    hogarID,
                    solSegFianID: parseInt(URLSolSegFianID),
                    nombreContratante: trim(nombreContratante),
                    rfc: trim(rfc),
                    direccion: trim(direccion),
                    anioEstimadoCons: parseInt(anioEstimadoCons),
                    numPisos: parseInt(numPisos),
                    pisoResidencia: parseInt(pisoResidencia),
                    numSotanos: parseInt(numSotanos),
                    tipoMuro: trim(tipoMuro),
                    tipoTecho: trim(tipoTecho),
                    tipoEntrepiso: trim(tipoEntrepiso),
                    tipoEstructura: trim(tipoEstructura)
                }
            }, token);
            else await addSolSegFianHogar({
                input: {
                    solSegFianID: parseInt(URLSolSegFianID),
                    nombreContratante: trim(nombreContratante),
                    rfc: trim(rfc),
                    direccion: trim(direccion),
                    anioEstimadoCons: parseInt(anioEstimadoCons),
                    numPisos: parseInt(numPisos),
                    pisoResidencia: parseInt(pisoResidencia),
                    numSotanos: parseInt(numSotanos),
                    tipoMuro: trim(tipoMuro),
                    tipoTecho: trim(tipoTecho),
                    tipoEntrepiso: trim(tipoEntrepiso),
                    tipoEstructura: trim(tipoEstructura)
                }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        if (!mostrarTabla){
            if (onCerrarModal != undefined) {onCerrarModal();}

        }else{
            if (onCerrarModal!= undefined) {onCerrarModal();}
            if (onOcultarTabla!= undefined) {onOcultarTabla();}
        }
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function setRedireccionarFormulario({hogarID}){

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${hogarID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Seguro Viajero", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({data: datos, seed}).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
       }

    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSolSegFianHogar}
            token={token}
            parametrosFijos={{solSegFianID:URLSolSegFianID}}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={solSegFianIDRef}
        />
         {
             openDocumentacion && <Modal maxWidth={true}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos}   setOpenDocumentacion = { () => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default SolSegFianHogar;
