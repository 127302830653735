/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "../../assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {

    const classes = useStyles();
    const { fluid, white, organizacion = {}, opciones = [] } = props;

    var container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.whiteColor]: white
    });

    var anchor =
        classes.a +
        cx({
            [" " + classes.whiteColor]: white
        });

    var block = cx({
        [classes.block]: true,
        [classes.whiteColor]: white
    });

    return (
        <footer className={classes.footer}>
            <div className={container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        {
                            opciones.map(({ url, nombre,  }, key) => (<ListItem key={key} className={classes.inlineBlock}>
                                <a href={url ? url : "#"} className={block}>
                                    {nombre}
                                </a>
                            </ListItem>))
                        }
                    </List>
                </div>
                <p className={classes.right}>
                    &copy; {1900 + new Date().getYear()}{" "}
                    <a
                        href={organizacion.url ? organizacion.url : "#"}
                        className={anchor}
                        target="_blank"
                    >
                        { organizacion.nombre }
                    </a>
                    { organizacion.terminos }
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    organizacion: PropTypes.shape({
        url: PropTypes.string,
        nombre: PropTypes.string.isRequired,
        terminos: PropTypes.string
    }).isRequired,
    opciones: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        nombre: PropTypes.string.isRequired,
    }))
};
