import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor, urlServidorSAT } from '../../../helpers/';
import { GET_CODIGOMONEDA_SAT } from '../index';

export const getMonedaSAT = async (variables, token) => {
    try {
        let { getMoneda } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidorSAT,
            query: GET_CODIGOMONEDA_SAT
        });

        return getMoneda;

    } catch({message}) { throw new Error(message); }
}


export const seleccionMonedaSAT = async (variables, token) => {
    try {
        let AllMonedaSAT = await getMonedaSAT(variables, token);

        if (!Array.isArray(AllMonedaSAT)) throw new Error("No se encontraron registros");

        return AllMonedaSAT.map(({ claveMoneda: value, descripcion: label, decimales, porcentajeVariacion }) => ({ value, label,decimales, porcentajeVariacion }));

    } catch ({ message: label }) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
}
