import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_RECLATERCERO = `
        query get($filtro: InputGetSiniestrosReclaTercero){
            getSiniestrosReclaTercero(filtro:$filtro){
                title
                titleSeguimiento
                color
                siniesReclTercerosID
                usuarioBitID
                numero
                empresaID
                fechaAfectado
                infoIdentifTercero
                asegDelTercero
                ajustadorRespresentante
                noSiniestro
                noPoliza
                bienAfectado
                descripEvento
                ubicacion
                estatus
                montoPagado
                fechaReclamacion
                fechaIndemnizacion
                atendio
                noDiasHabilesTrans
                horasInvertidas
                folioInterno
                folioInternoReporteDanios
                reporteDaniosID
                fechaModificacion
                ejercicio
                mes
                fechaInicio
                fechaFinReporte
                divisaID
                paridad

            }
        }`;

export const GET_ALL_RECLATERCERO = `
        query filtroGetAll($filtro: InputGetSiniestrosReclaTercero){
            getAllSiniestrosReclaTercero(filtro:$filtro){
                title
                titleSeguimiento
                color
                siniesReclTercerosID
                usuarioBitID
                numero
                empresaID
                fechaAfectado
                infoIdentifTercero
                asegDelTercero
                ajustadorRespresentante
                noSiniestro
                noPoliza
                bienAfectado
                descripEvento
                ubicacion
                estatus
                montoPagado
                fechaReclamacion
                fechaIndemnizacion
                atendio
                noDiasHabilesTrans
                horasInvertidas
                folioInterno
                folioInternoReporteDanios
                reporteDaniosID
                fechaModificacion
                ejercicio
                mes
                fechaInicio
                fechaFinReporte
                divisaID
                paridad
            }
        }`;

export const TABLA_RECLATERCERO = `
        query filtro($filtro: InputFiltroSiniestrosReclaTercero){
            filterSiniestrosReclaTercero(filtro:$filtro){
            listado{
                siniesReclTercerosID
                usuarioBitID
                numero
                empresaID
                fechaAfectado
                infoIdentifTercero
                asegDelTercero
                ajustadorRespresentante
                noSiniestro
                noPoliza
                bienAfectado
                descripEvento
                ultimoSeguimiento
                ubicacion
                estatus
                montoPagado
                fechaReclamacion
                fechaIndemnizacion
                atendio
                noDiasHabilesTrans
                horasInvertidas
                folioInterno
                folioInternoReporteDanios
                reporteDaniosID
                fechaModificacion
                ubicacionRep
                divisaID
                paridad
                reclamoPrincipalID
                tipoReclamoPrincipal
            }
            registros
            paginas
            }
        }`;

export const getSiniestrosReclaTercero = async (filtro, token) => {
    try {
     
        let { getSiniestrosReclaTercero } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_RECLATERCERO
        });

        return getSiniestrosReclaTercero;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllSiniestrosReclaTercero = async (variables, token) => {
    try {

        let { getAllSiniestrosReclaTercero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_RECLATERCERO
        });

        let auxData = getAllSiniestrosReclaTercero.map(({
            title,
            titleSeguimiento,
            color,
            siniesReclTercerosID,
            usuarioBitID,
            numero,
            empresaID,
            fechaAfectado,
            infoIdentifTercero,
            asegDelTercero,
            ajustadorRespresentante,
            noSiniestro,
            noPoliza,
            bienAfectado,
            descripEvento,
            ubicacion,
            estatus,
            montoPagado,
            fechaReclamacion,
            fechaIndemnizacion,
            atendio,
            noDiasHabilesTrans,
            horasInvertidas,
            folioInterno,
            folioInternoReporteDanios,
            reporteDaniosID,
            fechaModificacion,
            ejercicio,
            mes,
            fechaInicio,
            fechaFinReporte
        }) => {
            return {
                title,
                titleSeguimiento,
                color,
                start: new Date(fechaAfectado),
                end: new Date(fechaAfectado),
                siniesReclTercerosID,
                usuarioBitID,
                numero,
                empresaID,
                fechaAfectado,
                infoIdentifTercero,
                asegDelTercero,
                ajustadorRespresentante,
                noSiniestro,
                noPoliza,
                bienAfectado,
                descripEvento,
                ubicacion,
                estatus,
                montoPagado,
                fechaReclamacion,
                fechaIndemnizacion,
                atendio,
                noDiasHabilesTrans,
                horasInvertidas,
                folioInterno,
                folioInternoReporteDanios,
                reporteDaniosID,
                fechaModificacion,
                ejercicio,
                mes,
                fechaInicio,
                fechaFinReporte
            }
        })

        return auxData;

    } catch ({ message }) { throw new Error(message); }
}

export const seleccionSiniestrosReclaTercero = async (variables, token) => {
    try {

        let getAllSiniestrosReclaTercero = await getAllSiniestrosReclaTercero(variables, token);


        if (!Array.isArray(getAllSiniestrosReclaTercero)) throw new Error("No se encontraron registros");

        return getAllSiniestrosReclaTercero.map(({ siniesReclTercerosID: value, descripEvento: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaSiniestrosReclaTercero = async (variable, token) => {
    try {
        let { siniesReclTercerosID, numero, empresaID,
            fechaAfectado, infoIdentifTercero, asegDelTercero,
            ajustadorRespresentante, noSiniestro, noPoliza, bienAfectado,
            descripEvento, ubicacion, estatus, montoPagado, fechaReclamacion,
            fechaIndemnizacion, atendio, noDiasHabilesTrans, fechaIni, fechaFin, pagina, limite, 
            reclamoPrincipalID, tipoReclamoPrincipal } = variable.filtro;
        let filtro = {
            siniesReclTercerosID: siniesReclTercerosID === null ? null : Number(siniesReclTercerosID), numero, empresaID,
            fechaAfectado, infoIdentifTercero, asegDelTercero,
            ajustadorRespresentante, noSiniestro, noPoliza, bienAfectado,
            descripEvento, ubicacion, estatus, montoPagado, fechaReclamacion,
            fechaIndemnizacion, atendio, noDiasHabilesTrans, fechaIni, fechaFin, pagina, limite,
            reclamoPrincipalID, tipoReclamoPrincipal 
        };
        let variables = { filtro };
   
        let { filterSiniestrosReclaTercero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_RECLATERCERO
        });

        return filterSiniestrosReclaTercero;
    } catch ({ message }) { throw new Error(message); }
}
