import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import { RiSurveyLine } from "react-icons/ri";
import Mail from '@material-ui/icons/Mail';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

//import Preguntas from './preguntas';
import * as Consultas from './Consultas.js';
import * as ConsultasDominios from '../../../querys/Dominios';
import { seleccionArea } from '../../../querys/Areas';
import * as Permisos from '../Permisos';

import { ftObtenerEjerciciosFiltrado } from '../../../helpers/rutinas/metodos/rutinasGenerales';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';


import moment from 'moment';

import PlantillaEncuesta from '../PlantillaEncuesta';

const { trim } = Validaciones;

function Encuesta() {

    let now = moment().year();


    const { token } = useSelector(state => state.login);

    const cleanState = {
        encuestaID: null,
        encuesta: "",
        areaID: "",
        correo: "",
        dominio: ""
    };
    const cleanErrorState = {
        encuesta: { error: false, helperText: "" },
        areaID: { error: false, helperText: "" },
        correo: { error: false, helperText: "" },
        dominio: { error: false, helperText: "" }
    }


    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [seleccionables, setSeleccionables] = useState(false);
    const [openEnviarCorreo, setOpenEnviarCorreo] = useState(false);
    const [openVisualizarPregunta, setOpenVisualizarPregunta] = useState(false);
    const [stateData, setStateData] = useState({});
    const [openPreguntas, setOpenPreguntas] = useState(false);
    const [stFiltros, setFiltros] = useState({ ejercicio: now });

    let titleAccion = state.encuestaID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Sección`;

    const encuestaRef = useRef(null);
    const areaIDRef = useRef(null);

    let acciones = [
        {
            
            icono: VisibilityIcon,
            id: "pdf",
            color: success,
            descripcion: "Ver Sección",
            parametros: [{ campo: "encuestaID" }, { campo: "encuesta" }, { campo: "ejercicio" }, { campo: "seccionID" }],
            disabled: { multiseleccion: true },
            onClick: ({ encuestaID, encuesta, ejercicio, seccionID }) => { setOpenVisualizarPregunta(true); setStateData({ encuestaID, encuesta, ejercicio, seccionID, accion: "VerSeccion" }) }
        },
        {
            
            icono: Mail,
            id: "EnviarEncuesta",
            color: info,
            descripcion: "Enviar Encuesta",
            parametros: [{ campo: "encuestaID" }, { campo: "encuesta" }, { campo: "ejercicio" }, { campo: "seccionID" }],
            disabled: { multiseleccion: true },
            onClick: ({ encuestaID, encuesta, ejercicio, seccionID }) => { setOpenEnviarCorreo(true); setStateData({ encuestaID, encuesta, ejercicio, seccionID, accion: "EnviarCorreo"}) }
        }];

    let botones = [
        {
           
            icono: Add,
            color: success,
            descripcion: "Generar Encuesta",
            onClick: () => GenerarEncuesta(),
            disabled: { multiseleccion: true }
        },
        // {
        //     accionID: [7],
        //     icono: PictureAsPdfIcon,
        //     color: "success",
        //     descripcion: "Imprimir Encuesta",
        //     onClick: () => ftImprimirEncuesta()
        // },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        },
        {
          
            icono: AssignmentTurnedInIcon,
            color: "primary",
            descripcion: "Revisar / Imprimir Encuesta",
            onClick: () => ftRevisarEncuesta()
        },
        {
           
            icono: Close,
            color: "danger",
            descripcion: "Eliminar Encuesta",
            onClick: () => handelAlertEliminar({
                setAlert,
                mensaje: ` la encuesta del ejercicio ${stFiltros.ejercicio}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: {}, asyncDelete: EliminarEncuesta
                })
            })
        }
    ];

    async function ftImprimirEncuesta(){
        let {listado: datosEncuesta} = await Consultas.tablaEncuesta({filtro:{ejercicio: parseInt(stFiltros.ejercicio), count: null, pagina: 1, limite: 100000}  }, token);
        if(datosEncuesta.length > 0){
            setStateData({ encuestaID: parseInt(datosEncuesta[0].encuestaID), ejercicio: stFiltros.ejercicio, seccionID: null });
            setOpenVisualizarPregunta(true);
        }else{
            setAlert({
                descripcion: 'No existe una encuesta generada para este ejercicio',
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }

    }

    
    async function ftRevisarEncuesta(){
        let {listado: datosEncuesta} = await Consultas.tablaEncuesta({filtro:{ejercicio: parseInt(stFiltros.ejercicio), count: null, pagina: 1, limite: 100000}  }, token);

        if(datosEncuesta.length > 0){
            setStateData({ encuestaID: parseInt(datosEncuesta[0].encuestaID), ejercicio: stFiltros.ejercicio, seccionID: null, estatus: datosEncuesta[0].estatus,accion: "RevisarEncuesta" });
            setOpenPreguntas(true);
        }else{
            setAlert({
                descripcion: 'No existe una encuesta generada para este ejercicio',
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }

    }


    async function GenerarEncuesta() {
        try {
            await Consultas.generarEncuesta({ ejercicio: stFiltros.ejercicio }, token);
            setActualizar(!actualizar);
            setAlert({
                descripcion: "Se generó correctamente la encuesta para el ejercicio " + stFiltros.ejercicio,
                title: "Encuesta",
                tipo: success,
                msjConfirmacion: "Aceptar",
                showCancel: false,
                onConfirm: () => setAlert(null)
            });
        } catch (error) {
            setAlert({
                descripcion: error.message,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    async function EliminarEncuesta() {
        try {
            await Consultas.deleteEncuesta({ ejercicio: stFiltros.ejercicio }, token)
            setActualizar(!actualizar);
        } catch (error) {
            setAlert({
                descripcion: error.message,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "encuestaID",
        color: primary,
        title: "Secciones de Encuesta",
        iconTable: <RiSurveyLine />,
        headers: [
            { variable: "encuestaID", descripcion: "encuestaID", hide: true },
            { variable: "seccionID", descripcion: "seccionID", hide: true },
            { variable: "ejercicio", descripcion: "Ejercicio" },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "enviada", descripcion: "Correo enviado" },
            { variable: "pendienteRespuesta", descripcion: "Pendiente de Respuesta" }
        ],
        alineacion: [{ columnas: [1, 3, 4], alineacion: "center" }],
        responsiveTitle: ["descripcion"],
        selectFilter: [
            {
                campo: "ejercicio", title: "Ejercicio",
                retorna: "number", /* limpiarFiltro: "Todos", */
                defaultValue: now,
                data: seleccionables.Ejercicios ? seleccionables.Ejercicios : [], grid: { md: 3, lg: 3 }
            }

        ],
        formato: [
            { columnas: ["enviada"], tipo: "estatus" }
        ],
        accionesEffect: 
        [
            // { botones: ["pdf"], ocultar: (data) => data["pendienteRespuesta"].value == 'NO' ? false : true },
            // { botones: ["EnviarEncuesta"],  ocultar: (data) => data["pendienteRespuesta"].value !== "NO"  ? false : true  },
            // { botones: ["pdf"], ocultar: (data) => data["descripcion"].value === 'Firmas' ? true : false },
            { botones: ["EnviarEncuesta"],  ocultar: (data) => data["descripcion"].value === "Firmas"  ? true : false  }
        ]
    }

    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "encuesta",
                    value: state.encuesta,
                    error: errorState.encuesta.error,
                    success: state.encuesta && !errorState.encuesta.error ? true : undefined,
                    helperText: errorState.encuesta.helperText,
                    inputRef: encuestaRef,
                    title: "Sección *",
                    placeholder: "Capture la sección",
                    grid: { md: 8, lg: 8 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "encuesta")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "areaID",
                    value: state.areaID,
                    error: errorState.areaID.error,
                    success: state.areaID && !errorState.areaID.error ? true : undefined,
                    helperText: errorState.areaID.helperText,
                    inputRef: areaIDRef,
                    title: "Área *",
                    placeholder: "Seleccione el área",
                    grid: { md: 4, lg: 4 },
                    tipo: "select",
                    data: seleccionables.Areas,
                    onChange: ({ target: { value } }) => {
                        handleChange(value, "areaID")
                    },
                },
            ]
        }
    ];

    let accionesFormulario = [{
        accionID: state.encuestaID,
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: encuestaRef,
            mensajeCorrecto: `La sección se ${state.encuestaID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function handleCloseEnviarCorreo() {
        setOpenEnviarCorreo(false);
        setState({ ...cleanState });
    }

    function handleCloseVisualizarPregunta() {
        setOpenVisualizarPregunta(false);
        setState({ ...cleanState });
    }

    
    function handleCloseVerPreguntas() {
        setOpenPreguntas(false);
        setState({ ...cleanState });
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ encuesta, areaID }) {

        let error;
        let validaciones = [
            {
                condicion: !encuesta || trim(encuesta) === "",
                ref: encuestaRef, keyError: "encuesta",
                mensajeError: "Debe especificar la sección"
            },
            {
                condicion: encuesta.length > 200,
                ref: encuestaRef, keyError: "encuesta",
                mensajeError: "Número de caracteres capturados excedido."
            },
            {
                condicion: !areaID,
                ref: areaIDRef, keyError: "areaID",
                mensajeError: "Debe especificar la area"
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { encuestaID,
                areaID,
                encuesta } = state;

            let error = await handleValidaciones({
                encuesta,
                areaID
            });

            state["areaID"] = parseInt(areaID);
            delete state["correo"];
            delete state["dominio"];
            delete state["ejercicio"];
            if (error) return error;

            if (encuestaID) await Consultas.updateEncuesta(state, token);
            else await Consultas.addEncuesta(state, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    useEffect(() => {
        async function ftEffect() {
            try {
                let Areas = await seleccionArea({ filtro: {} }, token);
                let Ejercicios = await ftObtenerEjerciciosFiltrado(token);
                let Dominios = await ConsultasDominios.seleccionDominios({}, token);
                setSeleccionables({ Areas, Ejercicios, Dominios });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const inputsEnviarCorreo = [
        {
            disabled,
            id: "correo",
            value: state.correo,
            title: "Correo *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el correo",
            onChange: ({ target: { value } }) => setState({ ...state, correo: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "dominio",
            value: state.dominio,
            title: "Dominio *",
            placeholder: "Seleccione el dominio",
            tipo: "select",
            data: seleccionables.Dominios,
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
    ]

    const accionesFormularioEnviarCorreo = [{
        accionID: "EnviarCorreo",
        loader,
        disabled,
        icono: Mail,
        color: success,
        descripcion: "Enviar",
        onClick: () => EnviarCorreo()
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseEnviarCorreo
    }];

    async function EnviarCorreo() {
        try {
            let validacorreo = state.correo.includes('@');
            if (trim(state.correo) === "" || validacorreo) throw new Error("Debe ingresar un correo valido");
            if (trim(state.dominio) === "") throw new Error("Debe ingresar un dominio");
            handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: { encuestaID: stateData.encuestaID, seccionID: stateData.seccionID, correo: state.correo + state.dominio, ejercicio: stateData.ejercicio },
                asyncMutation: Consultas.enviarEncuesta,
                mensajes: {
                    msjEspera: {
                        title: `Espere por favor`,
                        descripcion: `Enviando encuesta...`
                    },
                    msjCorrecto: {
                        title: `Correcto`,
                        descripcion: `La encuesta se ha enviado`
                    }
                }
            })
            handleCloseEnviarCorreo();
        } catch ({ message }) {
            setAlert({
                descripcion: message,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }


    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={Consultas.tablaEncuestaSecciones}
            token={token}
            setFiltrosAplicados={setFiltros}
            requiereFiltros={true}
            limiteRegInicial={25}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            maxWidth={false}
            acciones={accionesFormulario}
            focus={encuestaRef}
        />
        {
            openEnviarCorreo && <FormularioModal
                open={openEnviarCorreo}
                title={"Enviar por correo"}
                onClose={handleCloseEnviarCorreo}
                inputs={inputsEnviarCorreo}
                acciones={accionesFormularioEnviarCorreo}
            />
        }
        {
            openVisualizarPregunta && <Modal
            title = {''}
            onClose = {handleCloseVisualizarPregunta}
            >

                <PlantillaEncuesta
                info={
                    {
                    token,
                    encuestaID: stateData.encuestaID,
                    seccionID: stateData.seccionID,
                    ejercicio: stateData.ejercicio,
                    accion: stateData.accion
                    }
                }
                openFromEncuesta = {true}
                />

            </Modal> 
        }
        {
            openPreguntas && <Modal
            title = {''}
            onClose = {handleCloseVerPreguntas}
            >

                <PlantillaEncuesta
                info={
                    {
                    token,
                    encuestaID: stateData.encuestaID,
                    seccionID: stateData.seccionID,
                    ejercicio: stateData.ejercicio,
                    estatus: stateData.estatus,
                    accion: stateData.accion
                    }
                }
                openFromEncuesta = {true}
                agregarFirmas = {true}
                handleCloseVerPreguntas={handleCloseVerPreguntas}
                />

            </Modal> 
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default Encuesta;
