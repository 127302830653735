import React, { useState, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';
import GroupIcon from '@material-ui/icons/Group';
import './botonAux.css';

// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import { info, danger, primary } from '../../../_layout/helpers/colores';

/* import * as Permisos from '../Permisos'; */
import ConfigPerfil from '../Permisos/ConfigPerfil.js';
import { reiniciarPermisosPerfil } from '../Perfiles/Consultas';
import { InicializarProceso } from '../Permisos/';
function Perfiles() {

    const { token, usuario } = useSelector(stDatos => stDatos.login);

    let remover = false;
    const cleanState = { perfil: "" };
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanNotificacionesConfigPerfil = { mensaje: "", color: null, open: false };

    let [stDatos, setState] = useState({ perfil: "" });
    const [notificacionesConfigPerfil, setNotificacionesConfigPerfil] = useState(cleanNotificacionesConfigPerfil);
    const [openConfigPerfil, setOpenConfigPerfil] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [fotoPerfilUsuario, setFotoPerfil] = useState({ valor: "", file: "", eliminar: remover });

    let ArrayRef = [];
    let acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "perfil" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => AbrirConfigPerfil(parametros)
    }, {
        icono: SyncIcon,
        color: "danger",
        descripcion: "Reiniciar permisos de este perfil",
        parametros: [{ campo: "perfil" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => setAlert({
            descripcion: 'Esta acción va a borrar los permisos personalizados del perfil "' + parametros.perfil + '". (Los permisos personalizados por usuario no cambiarán) ¿Desea continuar?',
            title: "¡Atención!",
            tipo: "warning",
            msjConfirmacion: "Sí",
            onConfirm: () => ftReiniciarPermisosPerfil(parametros),
            onCancel: () => setAlert(null),
            msjCancelacion: "No"
        })
    }];

    let botones = [{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    },
    {
        icono: SyncIcon,
        color: danger,
        descripcion: "Reiniciar permisos por default",
        onClick: () => setAlert({
            descripcion: 'Esta acción va a borrar los permisos personalizados por perfil. (Los permisos personalizados por usuario no cambiarán) ¿Desea continuar?',
            title: "¡Atención!",
            tipo: "warning",
            msjConfirmacion: "Sí",
            onConfirm: () => reiniciarPermisos(),
            onCancel: () => setAlert(null),
            msjCancelacion: "No"
        }),
        disabled: { multiseleccion: true }
    }];

    async function reiniciarPermisos() {
        setAlert({
            descripcion: 'Este proceso puede tardar un poco, por favor espere un momento',
            title: "Disculpe",
            tipo: "warning",
            showCancel: false,
            msjConfirmacion: "Enterado",
            onConfirm: () => { return }
        })
        let resp = await InicializarProceso({ procesoID: 0 }, token);
        setAlert({
            descripcion: resp,
            title: "Listo!",
            tipo: "success",
            msjConfirmacion: "Enterado",
            onConfirm: () => setAlert(null)
        })
    }

    async function ftReiniciarPermisosPerfil({ perfil }) {
        setAlert({
            descripcion: 'Este proceso puede tardar un poco, por favor espere un momento',
            title: "Disculpe",
            tipo: "warning",
            showCancel: false,
            msjConfirmacion: "Enterado",
            onConfirm: () => { return }
        });
        let resp = await reiniciarPermisosPerfil({ perfil }, token);
        setAlert({
            descripcion: resp,
            title: "Listo!",
            tipo: "success",
            msjConfirmacion: "Enterado",
            onConfirm: () => setAlert(null)
        })
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "perfil",
        color: primary,
        title: "Perfiles",
        iconTable: <GroupIcon />,
        headers: [
            { variable: "perfil", descripcion: "Perfil" }
        ],
        responsiveTitle: ["perfil"],
        filter: [
            { campo: "nombreUsuario", variable: "nombreUsuario", placeholder: "Capture el usuario a buscar" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [{ columnas: ["fotoUsuario"], tipo: "imagen", textoAlt: 'fotoPerfil' }],

    }

    cleanErrorState["perfil"] = { error: false, helperText: "" };
    cleanState["perfil"] = 0;

    function AbrirConfigPerfil({ perfil }) {
        setState({ perfil });
        setOpenConfigPerfil(true);
    }

    let accionesFormularioConfigPerfil = [{
        key: "GuardarConfigPerfil",
        accionID: stDatos["perfil"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        /*onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["UsuarioNombre"],
            mensajeCorrecto: `El usuario se ${stDatos["perfil"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),*/
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseConfigPerfil
    }];

    function handleCloseConfigPerfil() {
        setOpenConfigPerfil(false);
        setNotificacionesConfigPerfil({ ...cleanNotificaciones });
    }

    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    async function PerfilesFijos() {
        return {
            paginas: 1, registros: 5, listado: [
                { perfil: "ADMINISTRADOR" },
                { perfil: "COORDINADOR" },
                { perfil: "ASISTENTE" },
                { perfil: "INVITADO" },
                { perfil: "AUXILIAR" }
            ]
        };
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={PerfilesFijos}
            token={token}
        />
        <ConfigPerfil
            open={openConfigPerfil}
            title={'Personalizar permisos ' + stDatos.perfil}
            onClose={handleCloseConfigPerfil}
            notificaciones={notificacionesConfigPerfil}
            closeNotification={() => setNotificacionesConfigPerfil({ ...cleanNotificacionesConfigPerfil })}
            inputs={[]} //inputs={inputs}
            acciones={accionesFormularioConfigPerfil}
            handleCloseConfigPerfil={handleCloseConfigPerfil}
            token={token}
            perfil={stDatos['perfil']}
            focus={ArrayRef["UsuarioNombre"]}
            setAlert={setAlert}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default Perfiles;
