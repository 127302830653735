import { FetchAPI } from '@renedelangel/helpers';
import { urlServidor } from '../../helpers';



export const GET_SOLSEGFIANHOGAR = `
query get($filtro: InputGetSolSegFianHogar){
    getSolSegFianHogar(filtro: $filtro)
    {
        hogarID
        solSegFianID
        usuarioBitID
        nombreContratante
        rfc
        direccion
        anioEstimadoCons
        numPisos
        pisoResidencia
        numSotanos
        tipoMuro
        tipoTecho
        tipoEntrepiso
        tipoEstructura 
}
}`;


export const GET_ALL_SOLSEGFIANHOGAR = `
query filtroGetAll($filtro: InputGetSolSegFianHogar){
    getAllSolSegFianHogar(filtro: $filtro)
    {
        hogarID
        solSegFianID
        usuarioBitID
        nombreContratante
        rfc
        direccion
        anioEstimadoCons
        numPisos
        pisoResidencia
        numSotanos
        tipoMuro
        tipoTecho
        tipoEntrepiso
        tipoEstructura 
    }
}`;

export const TABLA_SOLSEGFIANHOGAR = `
query filtro($filtro: InputFilterSolSegFianHogar){
    filterSolSegFianHogar(filtro: $filtro){
        listado{
            hogarID
            solSegFianID
            usuarioBitID
            nombreContratante
            rfc
            direccion
            anioEstimadoCons
            numPisos
            pisoResidencia
            numSotanos
            tipoMuro
            tipoTecho
            tipoEntrepiso
            tipoEstructura 
        }
        registros
        paginas
    }
}
`;


export const getSolSegFianHogar = async (filtro, token) => {
    try {
        let { getSolSegFianHogar } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_SOLSEGFIANHOGAR
        });
        return getSolSegFianHogar;
    } catch ({ message }) { throw new Error(message); }
}

export const getAllSolSegFianHogar = async (variables, token) => {
    try {
        let { getAllSolSegFianHogar } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SOLSEGFIANHOGAR
        });
        return getAllSolSegFianHogar;
    } catch ({ message }) { throw new Error(message); }
}

export const seleccionSolSegFianHogar = async (variables, token) => {
    try {
        let allSolSegFianHogar = await getAllSolSegFianHogar(variables, token);
        if (!Array.isArray(allSolSegFianHogar)) throw new Error("No se encontraron registros");

        return allSolSegFianHogar.map(({ equipoOperID: value, equipo: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}


export const tablaSolSegFianHogar = async (variables, token) => {
    try {

        let { filterSolSegFianHogar } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SOLSEGFIANHOGAR
        });
        return filterSolSegFianHogar;
    } catch ({ message }) { throw new Error(message); }
}