import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
//storage
import { actualizaArchivo, eliminaArchivo } from "../../../helpers/serverStorage";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { RiFileExcel2Fill } from "react-icons/ri";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { getEdad } from '../../../helpers/rutinas/metodos/rutinasGenerales';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, warning} from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import * as ConsultasDominios from '../../../querys/Dominios';
import { getControlPago, tablaControlPagosReporteDanios } from '../../../querys/ControlPago';
import moment from 'moment';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { urlServidor } from '../../../helpers';
import { seleccionArea } from '../../../querys/Areas';

import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import { seleccionUbicaciones } from 'querys/Ubicaciones';
import TrazabilidadSiniestros from '../TrazabilidadSiniestros';
import SeguimientoActividades from '../SeguimientoActividades';
import DescriptionIcon from '@material-ui/icons/Description';
import ControlPagoReporteDanios from '../ControlPagoReporteDanios';
import UpsertControlPago from '../ControlPagoReporteDanios/Upsert';

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;

function ReporteDanios() {
    const { token, usuario } = useSelector(stDatos => stDatos.login);
    const { Encrypt, Decrypt } = Encriptar;
    const [registroID, setRegistroID] = useState(null);
    const [stReporteDaniosID, setReporteDaniosID] = useState(null);
    const [validarParams, setvalidarParams] = useState(false);
    const [openSeguimientos, setOpenSeguimientos] = useState(false);
    const [openControlPago, setOpenControlPago] = useState(false);
    const [verHistorial, setVerHistorial] = useState(false);

    let auxRegistroIDEffect;
    const { params } = useParams();
    let url;
    if (window.location.pathname.includes('ReporteadorDeDanios')) {
        url = params.replace(/&/g, "/");
        if (url !== ":params") {
            let { registroID: auxRegistroID } = Decrypt({ data: url, seed: "scrumweb" }) !== "" ? JSON.parse(Decrypt({ data: url, seed: "scrumweb" })) : null;
            auxRegistroIDEffect = auxRegistroID;
        }
    }

    let anioActual = new Date().getFullYear();
    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    const listEstatusUnidad = [
        {
            value: 'Se embarco',
            label: 'Se embarcó'
        },
        {
            value: 'No se embarco',
            label: 'No se embarcó'
        },
        {
            value: 'Se desistio',
            label: 'Se desistió'
        },
        {
            value: 'NA',
            label: 'NA'
        }
    ]

    let Empresas = [];
    let remover = false;
    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [stEmpresas, setEmpresas] = useState(Empresas)
    let [datosPDF, setdatosPDf] = useState({});
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [stTituloTipoDanio, setTituloTipoDanio] = useState("")
    let [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [stFotografia1, setFotografia1] = useState({ valor: "", file: "", eliminar: remover });
    const [stFotografia2, setFotografia2] = useState({ valor: "", file: "", eliminar: remover });

    const [abrirSeguimiento, setAbrirSeguimiento] = useState(false);
    const [openFinalizar, setOpenFinalizar] = useState(false);
    const [stateFinalizar, setStateFinalizar] = useState({
        correo: "",
        dominio: "@atpaltamira.com.mx"
    });
    const cleanNotificacionesSeguimiento = { mensaje: "", color: null, open: false };
    const [notificacionesSeguimiento, setnotificacionesSeguimiento] = useState(cleanNotificacionesSeguimiento);
    const [seguimientoSelect, setseguimientoSelect] = useState("SIN RECLAMACIÓN");
    const [stCerrarReporte, setCerrarReporte] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    const [openTrazabilidad, setOpenTrazabilidad] = useState(false);
    const [datosDetalle, setDatosDetalle] = useState({})

    let titleAccion = stDatos.reporteDaniosID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Reporte de Daños`;
    let ArrayRef = [];

    const location = useLocation();
    let EsReporteador = false;

    if (location.pathname.includes("ReporteadorDeDanios")) {
        EsReporteador = true;
    }

    const history = useHistory();
    const IrARuta = (pReclamo, RegistroID) => {
        let path = '/';
        if (pReclamo === 'TERCERO') {
            path = `/gen/ReclamacionATerceros`;
        } else {
            path = `/gen/SiniestrosReclamados`;
        }
        setAlert(null);
        history.push(path, { RegistroID });
    }

    let acciones = [];
    if (EsReporteador === false) {
        acciones = [{
            accionID: [9],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Finalizar Reporte",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "finalizado" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => FinalizarReporteDanios(parametros)
        }, {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros,
                token,
                setState,
                setOpen,
                setAlert,
                asyncGet: Consultas.getReporteDanios,
                formatearResultado: [{ id: "fechaElaboracion", formato: (data) => moment(data) }, { id: "fechaEvento", formato: (data) => moment(data) }, { id: "fechaIngreso", formato: (data) => moment(data) }, { id: "fechaHabilidad", formato: (data) => moment(data) }, { id: "semanaDanio", formato: (data) => moment(data) }]
            })
        }, {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "reporteDaniosID" },
            { campo: "folioInterno", variable: "folioInterno" }
            ],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el reporte de daño ${folioInterno}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token,
                    setAlert,
                    setActualizar,
                    actualizar,
                    parametros: { reporteDaniosID, folioInterno },
                    asyncDelete: Consultas.deleteReporteDanios
                })
            })
        }, {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Descargar PDF",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => AuxDescargar(reporteDaniosID, folioInterno, 'PDF', 'PDF1')
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel (Largo)",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => AuxDescargar(reporteDaniosID, folioInterno, 'excel', 'EXCEL')
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => {
                //  setRedireccionarFormulario({reporteDaniosID,folioInterno}) ;
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: reporteDaniosID,
                    pTipo: folioInterno,
                    pClasificacion: "",
                    pTabla: "Reporte de daños",
                    pTitulo2: `Reporte de daños folio: ${folioInterno}`,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }
        ];
    } else {
        acciones = [{
            accionID: [3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros,
                token,
                setState,
                setOpen,
                setAlert,
                asyncGet: Consultas.getReporteDanios,
                formatearResultado: [{ id: "fechaElaboracion", formato: (data) => moment(data) }, { id: "fechaEvento", formato: (data) => moment(data) }, { id: "fechaIngreso", formato: (data) => moment(data) }, { id: "fechaHabilidad", formato: (data) => moment(data) }, { id: "semanaDanio", formato: (data) => moment(data) }]
            })
        },
        {
            id: "EstatusReporte",
            accionID: [9],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Estatus Reporte",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "estatus" }, { campo: "folioInterno" }, { campo: "seguimiento" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => AbrirFormCerrarReporte(parametros)
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => {
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: reporteDaniosID,
                    pTipo: folioInterno,
                    pClasificacion: "",
                    pTabla: "Reporte de daños",
                    pTitulo2: `Reporte de daños folio: ${folioInterno}`,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }, {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Descargar PDF",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => AuxDescargar(reporteDaniosID, folioInterno, 'PDF', 'PDF2')
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel (Largo)",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno }) => AuxDescargar(reporteDaniosID, folioInterno, 'excel', 'EXCEL')
        }, {
            // accionID: [10],
            id: "verTrazabilidad",
            icono: TrendingUpIcon,
            color: success,
            descripcion: "Ver Trazabilidad",
            parametros: [{ campo: "reporteDaniosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID }) => {
                setDatosDetalle({
                    pReporteDaniosID: reporteDaniosID,
                    pPolizasSeguroID: null,
                    pControlPagoID: null,
                    plUtilizaProps: true,
                    pSiniesReclAsegID: null,
                    pSiniesReclTercerosID: null
                });
                setOpenTrazabilidad(true);
            }
        },
        {
            accionID: [10],
            id: "verSeguimientos",
            icono: DescriptionIcon,
            color: primary,
            descripcion: "Seguimientos",
            parametros: [{ campo: "reporteDaniosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID }) => { setReporteDaniosID(reporteDaniosID); setVerHistorial(false); setOpenSeguimientos(true); }
        },
        {
            accionID: [11],
            id: "verHistorialSeguimientos",
            icono: DescriptionIcon,
            color: success,
            descripcion: "Historial de Seguimientos",
            parametros: [{ campo: "reporteDaniosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID }) => { setReporteDaniosID(reporteDaniosID); setOpenSeguimientos(true); setVerHistorial(true) }
        },
        {
            accionID: [13],
            id: "generarControlPago",
            icono: AccountBalanceWalletIcon,
            color: warning,
            descripcion: "Control de pago",
            parametros: [{ campo: "reporteDaniosID" }, {campo: "folioInterno"}, {campo: "seguimiento"}],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno, seguimiento }) => { setReporteDaniosID(reporteDaniosID);  VerControldePagos(reporteDaniosID, folioInterno, seguimiento); }
        },
        ];
    }

    function setRedireccionarFormulario({ reporteDaniosID, folioInterno }) {
        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${reporteDaniosID}", "pTipo": "${folioInterno}", "pTabla": "Reporte de daños", "pTitulo2": "Reporte de daños folio: ${folioInterno}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    function AuxDescargar(reporteDaniosID, folioInterno, formatoReporte, tipoReporte) {
        setdatosPDf({ "reporteDaniosID": reporteDaniosID, "folioInterno": folioInterno, formatoReporte, tipoReporte });
        datosPDF = { "reporteDaniosID": reporteDaniosID, "folioInterno": folioInterno, formatoReporte, tipoReporte };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de daños?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato de impresión del reporte de daños ",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el formato de impresión del reporte de daños ' + folioInterno,
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("reporteDaniosID", datosPDF.reporteDaniosID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);
        formdata.append("tipoReporte", datosPDF.tipoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteDeDaños' + datosPDF.folioInterno
        await fetch(`${urlServidor}/service/reportes/ReporteDeDanios`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte} ${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    async function EnviarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("reporteDaniosID", stateFinalizar.reporteDaniosID);
        formdata.append("formatoReporte", stateFinalizar.formatoReporte);
        formdata.append("tipoReporte", stateFinalizar.tipoReporte);
        formdata.append("correo", `${stateFinalizar.correo}${stateFinalizar.dominio}`);
        formdata.append("enviarCorreo", 1);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        // let nombreReporte = 'ReporteDeDaños' + datosPDF.folioInterno
        await fetch(`${urlServidor}/service/reportes/ReporteDeDanios`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // // showFile(result, `${nombreReporte}.pdf`);
                // showFile(result, `${nombreReporte} ${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    function FinalizarReporteDanios(parametros) {
        if (parametros.finalizado === 1) {
            setAlert({
                descripcion: 'Este registro ya se encuentra finalizado',
                title: "Finalizar registro",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
            return;
        }

        setOpenFinalizar(true);
        setStateFinalizar({ ...stateFinalizar, reporteDaniosID: parametros.reporteDaniosID, folioInterno: parametros.folioInterno, formatoReporte: "excelCorto", tipoReporte: "EXCELCORTO" })
    }
    async function VerControldePagos(reporteDaniosID, folioInterno, seguimiento){

        let seg = seguimiento.substring(0,9)
        if(seg !== "RECLAMADO"){
            let result = await tablaControlPagosReporteDanios({filtro:{pagina:1, limite: 1000, reporteDaniosID: Number(reporteDaniosID)}},token)

            let {listado } = result;
            let descripcionMensaje = [];
            if (listado.length > 0) {
               listado.map(pago => {
                descripcionMensaje.push(<><>Control pago: {pago.folioInterno}, ${pago.importePago}</> <br/></> )         
               })
            }else{
                descripcionMensaje = '¿Desea agregar un control de pago?'
            }
            setAlert({
                descripcion: descripcionMensaje,
                title: "Control de pagos "+folioInterno,
                tipo: "warning",
                msjConfirmacion: "Agregar control de pago",
                onConfirm: () => {setAlert(null); setOpenControlPago(true);},
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
           

        }else{
            setAlert({
                descripcion: "El seguimiento del reporte es RECLAMADO, no aplica ",
                title: "Control de pagos "+folioInterno,
                tipo: "danger",                
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });            
        }       

    }

    async function ftFinalizarReporte(reporteDaniosID) {
        try {
            let validacorreo = stateFinalizar.correo.includes('@');
            if (trim(stateFinalizar.correo) === "" || validacorreo) throw new Error("Debe ingresar un correo");
            let resul = await Consultas.FinalizarReporteDanios({ reporteDaniosID: stateFinalizar.reporteDaniosID }, token);
            await EnviarPdf()

            setActualizar(!actualizar);
            // setAlert(null);
            handleCloseFinalizar();
            setAlert({
                descripcion: resul,
                title: "¡Reporte Finalizado!",
                tipo: success,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    let botones = [];
    if (!EsReporteador) {
        botones = [{
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => {
                setActualizar(!actualizar);
                setvalidarParams(true);
                setRegistroID(null);
            },
            disabled: { multiseleccion: true }
        }];
    } else {
        botones = [{
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => {
                setActualizar(!actualizar);
                setvalidarParams(true);
                setRegistroID(null);
            },
            disabled: { multiseleccion: true }
        }];
    }

    let OpcionesDanioA = [{ value: "Carga General", label: "Carga General" },
    { value: "Contenedor (es)", label: "Contenedor (es)" },
    { value: "Automóvil (es)", label: "Automóvil (es)" },
    { value: "Maquinaria y/o Equipo Interno", label: "Maquinaria y/o Equipo Interno" },
    { value: "Herramientas de Trabajo", label: "Herramientas de Trabajo" },
    { value: "Instalaciones", label: "Instalaciones" },
    { value: "Propiedad Externa", label: "Propiedad Externa" }
    ];

    let infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "reporteDaniosID",
        color: primary,
        title: EsReporteador ? "Reporteador de Daños" : "Reporte de Daños",
        iconTable: EsReporteador ? < SyncProblemIcon /> : < ReportProblemIcon />,
        headers: [
            { variable: "reporteDaniosID", descripcion: "ID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "fechaElaboracion", descripcion: "Fecha del Elaboración" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "finalizado", descripcion: "Finalizado", hide: true },
            { variable: "finalizadoNombre", descripcion: "Estatus" },
            { variable: "descripBreve", descripcion: "Descripción del Evento" },
            { variable: "numIncidencia", descripcion: "No. de Acción", hide: false },
            { variable: "numNC", descripcion: "No. De NC", hide: false },
            { variable: "fechaEvento", descripcion: "Fecha del Evento" },
            { variable: "lugarEvento", descripcion: "Lugar del Evento" },
            { variable: "supervCargo", descripcion: "Supervisor(es) a Cargo" },
            { variable: "danioOcasioA", descripcion: "Daño ocasionado a"}
        ],
        responsiveTitle: ["folioInterno", "numIncidencia", "lugarEvento"],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        filter: [
            { campo: "numIncidencia", variable: "numIncidencia", placeholder: "Capture el No. de Acción a buscar" },
            { campo: "lugarEvento", variable: "lugarEvento", placeholder: "Capture el lugar del evento a buscar" }
        ],
        alineacion: [{ columnas: [0, 2, 3, 4, 5], alineacion: "left" },
        ],
        formato: [
            {
                columnas: ["fechaElaboracion", "fechaEvento", "fechaModificacion"], tipo: "fecha", onlyDate: true
            }],
        selectFilter: [{
            campo: "empresaID",
            title: "Empresa",
            retorna: "number",
            limpiarFiltro: "Todos",
            data: stEmpresas ? stEmpresas : [],
            grid: { md: 3, lg: 3 }
        },
        {
            campo: "danioOcasioA",
            title: "Daño ocasionado a",
            retorna: "String",
            limpiarFiltro: "Todos",
            data: OpcionesDanioA ? OpcionesDanioA : [],
            grid: { md: 3, lg: 3 }
        }
        ],
        accionesEffect: [{ botones: ["generarControlPago"],  ocultar: (data) => data["finalizadoNombre"].value !== "CERRADO"  ? true : false  }]
    }

    const estatusReporteador = [{ value: "EN PROCESO", label: "EN PROCESO" },
    { value: "CERRADO", label: "CERRADO" }
    ];

    const seguimiento = [{ value: "PENDIENTE", label: "PENDIENTE" },
    { value: "SIN RECLAMACIÓN", label: "SIN RECLAMACIÓN" },
    { value: "REPARADO", label: "REPARADO" },
    { value: "PAGADO", label: "PAGADO" },
    { value: "RECLAMADO A ASEGURADORA (RGR-002)", label: "RECLAMADO A ASEGURADORA (RGR-002)" },
    { value: "RECLAMADO A TERCERO (RGR-007)", label: "RECLAMADO A TERCERO (RGR-007)" },
    { value: "REPOSICIÓN", label: "REPOSICIÓN" },
    { value: "CERRADO", label: "CERRADO" }
    ];

    let infoTablaReporteador = {
        botones,
        acciones,
        actualizar,
        id: "reporteDaniosID",
        color: primary,
        title: "Reporteador de Daños",
        iconTable: < ReportProblemIcon />,
        headers: [
            { variable: "reporteDaniosID", descripcion: "ID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "descripBreve", descripcion: "Bien dañado", hide: false },
            { variable: "seguimiento", descripcion: "Seguimiento" },
            { variable: "ultimoSeguimiento", descripcion: "Último Seguimiento" },
            { variable: "numNC", descripcion: "No. De NC", hide: true },
            { variable: "fechaEvento", descripcion: "Fecha del Evento" },
            { variable: "lugarEvento", descripcion: "Lugar del Evento" },
            { variable: "supervCargo", descripcion: "Supervisor(es) a Cargo" },
            { variable: "costoDanio", descripcion: "Costo de Daño" },
            { variable: "fechaElaboracion", descripcion: "Fecha del Elaboración" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "danioOcasioA", descripcion: "Daño ocasionado a"}
        ],
        responsiveTitle: ["folioInterno", "estatus", "lugarEvento"],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        filter: [
            { campo: "folioInterno", variable: "folioInterno", placeholder: "Capture el folio a buscar" },
            { campo: "lugarEvento", variable: "lugarEvento", placeholder: "Capture el lugar del evento a buscar" }
        ],

        selectFilter: [
            { campo: "estatus", title: "Estatus", placeholder: "Seleccionar el estatus", limpiarFiltro: "Todos", data: estatusReporteador, grid: { md: 4, lg: 4 } },
            { campo: "seguimiento", title: "Seguimiento", placeholder: "Seleccionar el seguimiento", limpiarFiltro: "Todos", data: seguimiento, grid: { md: 4, lg: 4 } },
            {
                campo: "empresaID",
                title: "Empresa",
                retorna: "number",
                limpiarFiltro: "Todos",
                data: stEmpresas ? stEmpresas : [],
                grid: { md: 3, lg: 3 }
            },
            {
                campo: "danioOcasioA",
                title: "Daño ocasionado a",
                retorna: "String",
                limpiarFiltro: "Todos",
                data: OpcionesDanioA ? OpcionesDanioA : [],
                grid: { md: 3, lg: 3 }
            }
        ],
        alineacion: [{ columnas: [0, 1, 2, 4, 5, 6], alineacion: "left" }, { columnas: [3, 7], alineacion: "right" },
        { columnas: [7], alineacion: "center" }
        ],
        formato: [{ columnas: ["costoDanio"], tipo: "moneda" },
        {
            columnas: ["fechaEvento", "fechaModificacion", "fechaElaboracion"], tipo: "fecha", onlyDate: true
        }],
        accionesEffect: [{ botones: ["generarControlPago"],  ocultar: (data) => data["estatus"].value !== "CERRADO"  ? true : false  },
                         { botones: ["EstatusReporte"],  ocultar: (data) => data["seguimiento"].value === "CERRADO"  ? true : false  }]
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["reporteDaniosID"] = { error: false, helperText: "" };
    cleanState["reporteDaniosID"] = 0;
    cleanState["empresaID"] = 0;
    cleanState["estatus"] = 'EN PROCESO';
    cleanState["seguimiento"] = 'PENDIENTE';
    cleanState["finalizado"] = 0;
    cleanState["areaRespDanioID"] = 0;
    cleanState["areaRespRepaID"] = 0;
    /* cleanState["horaNombramiento"] = new Date();
    cleanState["horaAlimentos"] = new Date(); */

    InicializarInputs("fechaElaboracion", "Fecha de Elaboración*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputs("numIncidencia", "No. de Acción", "", "", "Datos Generales", 30);
    InicializarInputs("numNC", "No. De NC", "", "", "Datos Generales", 30);
    //InicializarInputs("codigoClasifDanio", "Codigo Clasificacion Daño", "", "", "Datos Generales", 30);
    InicializarInputs("descripBreve", "Descripción Breve del Evento", "", "", "Datos Generales", 0);
    InicializarInputs("lugarEvento", "Lugar del Evento*", "", "", "Datos Generales", 0);
    // InicializarInputs("semanaDanio", "Semana de Daño", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputs("fechaEvento", "Fecha del Evento*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputs("horaEvento", "Hora del Evento*", "", new Date(), "Datos Generales", 0, "center");

    InicializarInputs("supervCargo", "Supervisor(es) a Cargo Durante el Evento", "", "", "Datos Generales", 0);
    InicializarInputs("areaID", "Área", "", "", "Datos Generales", 150);
    InicializarInputs("empresaID", "Empresa*", "", usuario["empresaID"], "Datos Generales", 0);
    InicializarInputs("danioOcasioTer", "Daño Ocasionado por un Tercero", "", "", "Datos Generales", 150);
    InicializarInputs("danioOcasioNos", "Daño Ocasionado por Nosotros", "", "N/A", "Datos Generales", 150);
    //InicializarInputs("montos", "Montos", "", 0, "Datos Generales", 0, "right");
    InicializarInputs("descripMedia", "Descripción del evento: (Incluir solo lo que pasó, cómo, cuándo, quién, testigos, maquinaria involucrada, etc.)", "", "", "Información del Evento", 0);
    InicializarInputs("danioOcasioA", "Daño Ocasionado a", "", "", "Información del Evento", 150);
    InicializarInputs("tipoDanioOcasio", "Tipo de daño", "", "", "Información del Evento", 150);

    InicializarInputs("buqueViaje", "Buque viaje", "", "", "Información del Evento", 150);

    InicializarInputs("descripDetallada", "Descripción Detallada del Daño:", "Incluir descripción de los daños ocasionados que se presentan, partes o propiedad, ubicaciones, dimensiones, contenido, estatus, cantidad", "", "Información del Evento", 0);
    InicializarInputs("folioServicio", "Folio de servicio", "", "", "Información complementaria (en caso de aplicar)", 0);
    InicializarInputs("tipoServicio", "Tipo de servicio", "", "", "Información complementaria (en caso de aplicar)", 50);
    InicializarInputs("tipoMercancia", "¿Qué tipo de mercancia es?", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputs("propiedadDe", "Propiedad de", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputs("repreLocal", "Representante local", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputs("estatusUnidad", "Estatus Unidad", "", "", "Información complementaria (en caso de aplicar)", 100);
    InicializarInputs("autoriza", "Autoriza", "", "", "Información complementaria (en caso de aplicar)", 100);
    InicializarInputs("horaAutorizacion", "Hora Autorización", "", new Date(), "Información complementaria (en caso de aplicar)", 100);
    InicializarInputs("fotografia1", "Foto (1)", "", "", "Incluir Fotografía(s) Ilustrativa(s) del Evento", 250);
    InicializarInputs("fotografia2", "Foto (2)", "", "", "Incluir Fotografía(s) Ilustrativa(s) del Evento", 250);
    InicializarInputs("nombresPersonal", "Nombres", "", "", "Información del Personal Involucrado", 0);
    InicializarInputs("numControlPersonal", "No. De control", "", "", "Información del Personal Involucrado", 0);
    InicializarInputs("habilidadBase", "Puesto o habilidad base", "", "", "Información del Personal Involucrado", 30);
    InicializarInputs("habilidadActiv", "Puesto o habilidad de la actividad que realizaba", "", "", "Información del Personal Involucrado", 150);
    //  InicializarInputs("fechaIngreso", "Fecha de Ingreso*", "", new Date(), "Información del Personal Involucrado", 0, "center");
    // InicializarInputs("fechaHabilidad", "Fecha de habilidad*", "", new Date(), "Información del Personal Involucrado", 0, "center");
    // InicializarInputs("otrosDaniosHabilidades", "Otros Daños o Habilidades", "", "", "Información del Personal Involucrado", 0);

    InicializarInputs("nombresTestigos", "Nombres", "", "", "Información de Testigo 1", 150);
    InicializarInputs("numControlTestigos", "No. De control", "", "", "Información de Testigo 1", 150);
    InicializarInputs("habilidadTestigos", "Puesto o habilidad", "", "", "Información de Testigo 1", 150);
    InicializarInputs("nombresTestigos2", "Nombres", "", "", "Información de Testigo 2", 150);
    InicializarInputs("numControlTestigos2", "No. De control", "", "", "Información de Testigo 2", 150);
    InicializarInputs("habilidadTestigos2", "Puesto o habilidad", "", "", "Información de Testigo 2", 150);
    InicializarInputs("notifAseg", "¿Fue necesaro notificar a nuestra aseguradora?", "", "", "", 5);
    InicializarInputs("reparaDanio", "¿Se acordó reparación del daño por parte de nosotros?", "", "", "", 5);
    InicializarInputs("inspecTerceros", "¿Se solicitó la inspección de una tercera parte?", "", "", "", 5);
    InicializarInputs("cotizacionDanio", "¿Se cuenta con Cotización del daño?", "", "", "", 5);
    InicializarInputs("anexRep", "¿Se anexan reportes internos / externos?", "", "", "", 5);
    InicializarInputs("reportesAnex", "¿Cuáles reportes se anexan?", "", "", "", 0);
    InicializarInputs("obserGenerales", "Observaciones Generales", "", "", "", 0);
    InicializarInputs("responAnalisis", "Responsable del Análisis de Causa Raíz", "", "", "", 0);
    InicializarInputs("elaboro", "Elaboró", "", "", "", 250);
    InicializarInputs("firma", "Firma", "", "", "", 250);
    InicializarInputs("cargo", "Cargo", "", "", "", 0);




    InicializarInputs("ubicacion", "Ubicación del incidente / accidente", "Ubicación del incidente / accidente", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputs("modeloUnidad", "Modelo de unidad (Marca, año, modelo)", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputs("numVin", "Número de vin completo", "", "", "Servicio al cliente Autos", 50, "right");
    InicializarInputs("numControl", "Número de control", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputs("nomChofer", "Nombre del chofer", "", "", "Servicio al cliente Autos", 250, "left");
    InicializarInputs("genero", "Género", "", "", "Servicio al cliente Autos", 10, "left");
    InicializarInputs("antiguedad", "Antigüedad de la categoría", "", "", "Servicio al cliente Autos", 250, "left");
    InicializarInputs("fechaNacimiento", "Fecha de nacimiento", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputs("edad", "Edad", "", 0, "Servicio al cliente Autos", 0, "left");
    InicializarInputs("horaNombramiento", "Hora de nombramiento (Del día del incidente)", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputs("turnoAnterior", "5 días anteriores de que turno andaba o sí descanso ", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputs("horaAlimentos", "Hora de sus alimentos (Del día del incidente)", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputs("calistenia", "Se brindó calistenia (Del día del incidente)", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputs("ultimaPlatica", "Tema de la última plática de Seguridad", "", "", "Servicio al cliente Autos", 150, "left");
    InicializarInputs("fechaUltimaPlatica", "Fecha de la última plática de seguridad", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputs("fechaRevProc", "Fecha en que se revisaron estos procedimientos con la persona", "", "", "Servicio al cliente Autos", 0, "left");

    //-----------------------------------------------------
    if (EsReporteador === true) {
        infoTabla = infoTablaReporteador;

        InicializarInputs("folioInterno", "Folio", "", "", "Reporteador", 0, "left");
        InicializarInputs("estatus", "Estatus", "", "", "Reporteador", 0, "left");
        InicializarInputs("seguimiento", "Seguimiento", "", "", "Reporteador", 0, "left");
        InicializarInputs("danioOcasioPor", "Daño Ocasionado por", "", "", "Reporteador", 0, "left");
        InicializarInputs("danioOcasioARep", "Daño Ocasionado a", "", "", "Reporteador", 0, "left");
        InicializarInputs("equipoBienPersona", "Equipo / Bien / Persona", "", "", "Reporteador", 0, "left");
        InicializarInputs("areaRespDanioID", "Área Responsable de Daño", "", 0, "Reporteador", 0, "left");
        InicializarInputs("areaRespRepaID", "Área Responsable de Reparación", "", 0, "Reporteador", 0, "left");
        InicializarInputs("divisaID", "Divisa", "", 69, "Reporteador");
        InicializarInputs("paridad", "Paridad", "", 1, "Reporteador", 0, "right");
        InicializarInputs("costoDanio", "Costo de Daño", "", "", "Reporteador", 0, "right");
        InicializarInputs("tiempoInvertido", "Tiempo invertido", "", "", "Reporteador", 0, "left");
        InicializarInputs("atendio", "Atendió", "", "", "Reporteador", 0, "left");
        InicializarInputs("notas", "Notas", "", "", "Reporteador", 0, "left");

        InputsConID["folioInterno"].grid = { md: 4, lg: 4 };
        InputsConID["estatus"].grid = { md: 4, lg: 4 };
        InputsConID["seguimiento"].grid = { md: 4, lg: 4 };
        //-----------------------------------------------------
        InputsConID["danioOcasioPor"].grid = { md: 6, lg: 6 };
        InputsConID["danioOcasioARep"].grid = { md: 6, lg: 6 };
        //-----------------------------------------------------
        InputsConID["equipoBienPersona"].grid = { md: 6, lg: 6 };
        InputsConID["areaRespDanioID"].grid = { md: 6, lg: 6 };
        InputsConID["areaRespRepaID"].grid = { md: 6, lg: 6 };
        //-----------------------------------------------------
        InputsConID["divisaID"].grid = { md: 2, lg: 2 };
/*         InputsConID["divisaID"].onChange = (value) =>{
            handleChangeDivisa(value)
        }; */
        InputsConID["paridad"].grid = { md: 2, lg: 2 };
        InputsConID["costoDanio"].grid = { md: 2, lg: 2 };
        InputsConID["tiempoInvertido"].grid = { md: 3, lg: 3 };
        InputsConID["atendio"].grid = { md: 9, lg: 9 };
        //-----------------------------------------------------
        InputsConID["notas"].grid = { md: 12, lg: 12 };
        InputsConID["notas"].multiline = true;

        InputsConID["fechaElaboracion"].disabled = false;
        InputsConID["numIncidencia"].disabled = true;
        InputsConID["numNC"].disabled = true;
        // InputsConID["codigoClasifDanio"].disabled = true;
        InputsConID["descripBreve"].disabled = true;
        InputsConID["lugarEvento"].disabled = true;
        // InputsConID["semanaDanio"].disabled = true;
        InputsConID["fechaEvento"].disabled = true;
        InputsConID["horaEvento"].disabled = true;
        InputsConID["supervCargo"].disabled = true;
        InputsConID["empresaID"].disabled = true;
        InputsConID["areaID"].disabled = true;
        InputsConID["danioOcasioTer"].disabled = true;
        InputsConID["danioOcasioNos"].disabled = true;
        //InputsConID["montos"].disabled = true;
        InputsConID["descripMedia"].disabled = true;
        InputsConID["danioOcasioA"].disabled = true;
        InputsConID["buqueViaje"].disabled = true;
        InputsConID["descripDetallada"].disabled = true;
        InputsConID["folioServicio"].disabled = true;
        InputsConID["tipoServicio"].disabled = true;
        InputsConID["tipoMercancia"].disabled = true;
        InputsConID["propiedadDe"].disabled = true;
        InputsConID["repreLocal"].disabled = true;
        InputsConID["estatusUnidad"].disabled = true;
        InputsConID["autoriza"].disabled = true;
        InputsConID["horaAutorizacion"].disabled = true;
        InputsConID["fotografia1"].disabled = true;
        InputsConID["fotografia2"].disabled = true;
        InputsConID["nombresPersonal"].disabled = true;
        InputsConID["numControlPersonal"].disabled = true;
        InputsConID["habilidadBase"].disabled = true;
        InputsConID["habilidadActiv"].disabled = true;
        //InputsConID["fechaIngreso"].disabled = true;
        // InputsConID["fechaHabilidad"].disabled = true;
        // InputsConID["otrosDaniosHabilidades"].disabled = true;
        InputsConID["nombresTestigos"].disabled = true;
        InputsConID["numControlTestigos"].disabled = true;
        InputsConID["habilidadTestigos"].disabled = true;
        InputsConID["nombresTestigos2"].disabled = true;
        InputsConID["numControlTestigos2"].disabled = true;
        InputsConID["habilidadTestigos2"].disabled = true;
        InputsConID["notifAseg"].disabled = true;
        InputsConID["reparaDanio"].disabled = true;
        InputsConID["inspecTerceros"].disabled = true;
        InputsConID["cotizacionDanio"].disabled = true;
        InputsConID["anexRep"].disabled = true;
        InputsConID["reportesAnex"].disabled = true;
        InputsConID["obserGenerales"].disabled = true;
        InputsConID["responAnalisis"].disabled = true;
        InputsConID["elaboro"].disabled = true;
        InputsConID["firma"].disabled = true;
        InputsConID["cargo"].disabled = true;
        InputsConID["tipoDanioOcasio"].disabled = true;

        InputsConID["estatus"].disabled = true;
        InputsConID["seguimiento"].disabled = true;
        InputsConID["folioInterno"].disabled = true;
        //InputsConID["folio"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["danioOcasioPor"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["danioOcasioARep"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["equipoBienPersona"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["areaRespDanioID"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["areaRespRepaID"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["costoDanio"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["divisaID"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["paridad"].disabled = (stDatos["estatus"] !== "EN PROCESO");

        InputsConID["tiempoInvertido"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["atendio"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConID["notas"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        // DaniosATerceroEmpresa

        let OpcionesDanioReporteador = [{ value: "Personal", label: "Personal" },
        { value: "Tercero", label: "Tercero" }
        ];

        InputsConID["danioOcasioPor"].tipo = "select";
        InputsConID["danioOcasioPor"].data = OpcionesDanioReporteador;
        InputsConID["danioOcasioPor"].value = stDatos["danioOcasioPor"];

        InputsConID["danioOcasioARep"].tipo = "select";
        InputsConID["danioOcasioARep"].data = seleccionables.DaniosATerceroEmpresa;
        InputsConID["danioOcasioARep"].value = stDatos["danioOcasioARep"];

        InputsConID["areaRespDanioID"].tipo = "select";
        InputsConID["areaRespDanioID"].data = seleccionables.Areas;

        InputsConID["areaRespRepaID"].tipo = "select";
        InputsConID["areaRespRepaID"].data = seleccionables.Areas;

        InputsConID["divisaID"].tipo = "select";
        InputsConID["divisaID"].data = listDivisas;

        InputsConID["paridad"].textAlign = "right";
        InputsConID["paridad"].icono = <IconoSimbolo simbolo="$" />;
        InputsConID["paridad"].posicionIcono = "start";
        InputsConID["paridad"].formatoImportes = true;
        InputsConID["paridad"].onChange = ({target:{value}}) => {
           !isNaN(parseInt(value)) && setState( {...stDatos,["paridad"]: parseInt(value)})
        }

        InputsConID["costoDanio"].textAlign = "right";
        InputsConID["costoDanio"].icono = <IconoSimbolo simbolo="$" />;
        InputsConID["costoDanio"].posicionIcono = "start";
        InputsConID["costoDanio"].formatoImportes = true;
    }
    //-----------------------------------------------------

    //-----------------------------------------------------
    InputsConID["fechaElaboracion"].grid = { md: 3, lg: 3 };
    InputsConID["numIncidencia"].grid = { md: 3, lg: 3 };
    InputsConID["numNC"].grid = { md: 3, lg: 3 };
    // InputsConID["codigoClasifDanio"].grid = { md: 3, lg: 3 };
    InputsConID["descripBreve"].grid = { md: 12, lg: 12 };
    InputsConID["lugarEvento"].grid = { md: 6, lg: 6 };
    // InputsConID["semanaDanio"].grid = { md: 3, lg: 3 };
    InputsConID["fechaEvento"].grid = { md: 3, lg: 3 };
    InputsConID["horaEvento"].grid = { md: 3, lg: 3 };

    InputsConID["supervCargo"].grid = { md: 12, lg: 12 };
    InputsConID["areaID"].grid = { md: 12, lg: 12 };
    InputsConID["empresaID"].grid = { md: 6, lg: 6 };
    InputsConID["danioOcasioTer"].grid = { md: 6, lg: 6 };
    InputsConID["danioOcasioNos"].grid = { md: 7, lg: 7 };
    //InputsConID["montos"].grid = { md: 5, lg: 5 };
    //-----------------------------------------------------
    InputsConID["descripMedia"].grid = { md: 12, lg: 12 };
    InputsConID["danioOcasioA"].grid = { md: 4, lg: 4 };
    InputsConID["tipoDanioOcasio"].grid = { md: 4, lg: 4 };
    InputsConID["buqueViaje"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["descripDetallada"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["folioServicio"].grid = { md: 4, lg: 4 };
    InputsConID["tipoServicio"].grid = { md: 4, lg: 4 };
    InputsConID["tipoMercancia"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["propiedadDe"].grid = { md: 4, lg: 4 };
    InputsConID["repreLocal"].grid = { md: 4, lg: 4 };
    InputsConID["estatusUnidad"].grid = { md: 4, lg: 4 };
    InputsConID["estatusUnidad"].tipo = "select";
    InputsConID["estatusUnidad"].data = listEstatusUnidad;
    InputsConID["autoriza"].grid = { md: 4, lg: 4 };
    InputsConID["horaAutorizacion"].grid = { md: 4, lg: 4 };
    InputsConID["horaAutorizacion"].tipo = "datetimepicker";
    InputsConID["horaAutorizacion"].timeFormat = false;
    InputsConID["horaAutorizacion"].onlyTime = true;
    InputsConID["horaAutorizacion"].onlyDate = false;
    InputsConID["horaAutorizacion"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaAutorizacion");
    };
    //-----------------------------------------------------
    InputsConID["fotografia1"].grid = { md: 6, lg: 6 };
    InputsConID["fotografia2"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["nombresPersonal"].grid = { md: 8, lg: 8 };
    InputsConID["numControlPersonal"].grid = { md: 4, lg: 4 };
    InputsConID["habilidadBase"].grid = { md: 6, lg: 6 };
    InputsConID["habilidadActiv"].grid = { md: 6, lg: 6 };
    //InputsConID["fechaIngreso"].grid = { md: 6, lg: 6 };
    //InputsConID["fechaHabilidad"].grid = { md: 6, lg: 6 };
    //InputsConID["otrosDaniosHabilidades"].grid = { md: 12, lg: 12 };

    //-----------------------------------------------------
    InputsConID["nombresTestigos"].grid = { md: 4, lg: 4 };
    InputsConID["numControlTestigos"].grid = { md: 4, lg: 4 };
    InputsConID["habilidadTestigos"].grid = { md: 4, lg: 4 };
    InputsConID["nombresTestigos2"].grid = { md: 4, lg: 4 };
    InputsConID["numControlTestigos2"].grid = { md: 4, lg: 4 };
    InputsConID["habilidadTestigos2"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["notifAseg"].grid = { md: 6, lg: 6 };
    InputsConID["reparaDanio"].grid = { md: 6, lg: 6 };
    InputsConID["inspecTerceros"].grid = { md: 6, lg: 6 };
    InputsConID["cotizacionDanio"].grid = { md: 6, lg: 6 };
    InputsConID["anexRep"].grid = { md: 6, lg: 6 };
    InputsConID["reportesAnex"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["obserGenerales"].grid = { md: 12, lg: 12 };
    InputsConID["responAnalisis"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["elaboro"].grid = { md: 4, lg: 4 };
    InputsConID["firma"].grid = { md: 4, lg: 4 };
    InputsConID["cargo"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------


    InputsConID["ubicacion"].grid = { md: 4, lg: 4 };
    InputsConID["modeloUnidad"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["numVin"].grid = { md: 4, lg: 4 };
    InputsConID["numControl"].grid = { md: 4, lg: 4 };
    InputsConID["nomChofer"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["genero"].grid = { md: 3, lg: 3 };
    InputsConID["antiguedad"].grid = { md: 3, lg: 3 };
    InputsConID["fechaNacimiento"].grid = { md: 3, lg: 3 };
    InputsConID["edad"].grid = { md: 3, lg: 3 };
    //-----------------------------------------------------
    InputsConID["horaNombramiento"].grid = { md: 4, lg: 4 };
    InputsConID["turnoAnterior"].grid = { md: 4, lg: 4 };
    InputsConID["horaAlimentos"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["calistenia"].grid = { md: 6, lg: 6 };
    InputsConID["ultimaPlatica"].grid = { md: 6, lg: 6 };
    InputsConID["fechaUltimaPlatica"].grid = { md: 5, lg: 5 };
    InputsConID["fechaRevProc"].grid = { md: 5, lg: 5 };
    //-----------------------------------------------------

    InputsConID["empresaID"].tipo = "select";
    InputsConID["empresaID"].data = stEmpresas;

    InputsConID["areaID"].tipo = "select";
    InputsConID["areaID"].data = seleccionables.Areas;

    InputsConID["descripBreve"].multiline = true;
    InputsConID["descripMedia"].multiline = true;
    InputsConID["descripDetallada"].multiline = true;
    InputsConID["obserGenerales"].multiline = true;

    let OpcionesDanios = [{ value: "A propiedad interna", label: "A propiedad interna" },
    { value: "A la carga", label: "A la carga" },
    { value: "A un tercero (Externo)", label: "A un tercero (Externo)" },
    { value: "N/A", label: "N/A" },
    ];

    InputsConID["danioOcasioTer"].tipo = "select";
    InputsConID["danioOcasioTer"].data = OpcionesDanios;

    InputsConID["danioOcasioNos"].tipo = "select";
    InputsConID["danioOcasioNos"].data = OpcionesDanios;

    
    /*
        InputsConID["montos"].textAlign = "right";
        InputsConID["montos"].icono = <IconoSimbolo simbolo="$" />;
        InputsConID["montos"].posicionIcono = "start";
        InputsConID["montos"].formatoImportes = true;
    */
    InputsConID["danioOcasioA"].tipo = "select";
    InputsConID["danioOcasioA"].data = OpcionesDanioA;
    InputsConID["danioOcasioA"].value = stDatos["danioOcasioA"];

    InputsConID["tipoDanioOcasio"].title = stTituloTipoDanio;
    InputsConID["tipoDanioOcasio"].placeholder = stTituloTipoDanio;

    let OpcionesMercancia = [{ value: "Importacion", label: "Importacion" },
    { value: "Exportación", label: "Exportación" },
    { value: "Vacios", label: "Vacios" },
    { value: "Transbordo Lleno", label: "Transbordo Lleno" },
    { value: "Transbordo Vacío", label: "Transbordo Vacío" },
    { value: "NA", label: "NA" }
    ];

    InputsConID["tipoMercancia"].tipo = "select";
    InputsConID["tipoMercancia"].data = OpcionesMercancia;

    let Opciones = [{ value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
    { value: "NA", label: "NA" }
    ];

    InputsConID["notifAseg"].tipo = "select";
    InputsConID["notifAseg"].data = Opciones;

    InputsConID["reparaDanio"].tipo = "select";
    InputsConID["reparaDanio"].data = Opciones;

    InputsConID["inspecTerceros"].tipo = "select";
    InputsConID["inspecTerceros"].data = Opciones;

    InputsConID["cotizacionDanio"].tipo = "select";
    InputsConID["cotizacionDanio"].data = Opciones;

    InputsConID["anexRep"].tipo = "select";
    InputsConID["anexRep"].data = Opciones;

    InputsConID["fotografia1"].tipo = "imageupload";
    InputsConID["fotografia1"]["removeImage"] = stDatos["fotografia1"] === "" ? true : false;
    InputsConID["fotografia1"].onChange = (file, base) => {
        if (file === null) {
            remover = true;
        }
        setState(stDatos => ({
            ...stDatos,
            'fotografia1': base
        }));
        if (open) {
            setFotografia1({ valor: base, file: file, eliminar: remover });
        }
        return;
    };
    InputsConID["fotografia2"].tipo = "imageupload";
    InputsConID["fotografia2"]["removeImage"] = stDatos["fotografia2"] === "" ? true : false;
    InputsConID["fotografia2"].onChange = (file, base) => {
        if (file === null) {
            remover = true;
        }
        setState(stDatos => ({
            ...stDatos,
            'fotografia2': base
        }));
        if (open) {
            setFotografia2({ valor: base, file: file, eliminar: remover });
        }
        return;
    };

    InputsConID["fechaElaboracion"].tipo = "datetimepicker";
    InputsConID["fechaElaboracion"].timeFormat = false;
    InputsConID["fechaElaboracion"].onlyDate = true;
    InputsConID["fechaElaboracion"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaElaboracion");
    };

    /*InputsConID["semanaDanio"].tipo = "datetimepicker";
    InputsConID["semanaDanio"].timeFormat = false;
    InputsConID["semanaDanio"].onlyDate = true;
    InputsConID["semanaDanio"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "semanaDanio");
    };*/

    /* InputsConID["fechaIngreso"].tipo = "datetimepicker";
    InputsConID["fechaIngreso"].timeFormat = false;
    InputsConID["fechaIngreso"].onlyDate = true;
    InputsConID["fechaIngreso"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaIngreso");
    };

    InputsConID["fechaHabilidad"].tipo = "datetimepicker";
    InputsConID["fechaHabilidad"].timeFormat = false;
    InputsConID["fechaHabilidad"].onlyDate = true;
    InputsConID["fechaHabilidad"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaHabilidad");
    };*/

    InputsConID["fechaEvento"].tipo = "datetimepicker";
    InputsConID["fechaEvento"].timeFormat = false;
    InputsConID["fechaEvento"].onlyDate = true;
    InputsConID["fechaEvento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaEvento");
    };

    InputsConID["horaEvento"].tipo = "datetimepicker";
    InputsConID["horaEvento"].timeFormat = false;
    InputsConID["horaEvento"].onlyTime = true;
    InputsConID["horaEvento"].onlyDate = false;
    InputsConID["horaEvento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaEvento");
    };

    InputsConID["fechaNacimiento"].tipo = "datetimepicker";
    InputsConID["fechaNacimiento"].timeFormat = false;
    InputsConID["fechaNacimiento"].onlyDate = true;
    InputsConID["fechaNacimiento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaNacimiento");
        setState(stDatos => ({ ...stDatos, edad: getEdad(value) }))
    };

    InputsConID["fechaUltimaPlatica"].tipo = "datetimepicker";
    InputsConID["fechaUltimaPlatica"].timeFormat = false;
    InputsConID["fechaUltimaPlatica"].onlyDate = true;
    InputsConID["fechaUltimaPlatica"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaUltimaPlatica");
    };

    InputsConID["fechaRevProc"].tipo = "datetimepicker";
    InputsConID["fechaRevProc"].timeFormat = false;
    InputsConID["fechaRevProc"].onlyDate = true;
    InputsConID["fechaRevProc"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaRevProc");
    };

    InputsConID["horaNombramiento"].tipo = "datetimepicker";
    InputsConID["horaNombramiento"].timeFormat = false;
    InputsConID["horaNombramiento"].onlyTime = true;
    InputsConID["horaNombramiento"].onlyDate = false;
    InputsConID["horaNombramiento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaNombramiento");
    };

    InputsConID["horaAlimentos"].tipo = "datetimepicker";
    InputsConID["horaAlimentos"].timeFormat = false;
    InputsConID["horaAlimentos"].onlyTime = true;
    InputsConID["horaAlimentos"].onlyDate = false;
    InputsConID["horaAlimentos"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaAlimentos");
    };

    let OpcionesGenero = [
        { value: "Masculino", label: "Masculino" },
        { value: "Femenino", label: "Femenino" }
    ];

    InputsConID["genero"].tipo = "select";
    InputsConID["genero"].data = OpcionesGenero;

    //InputsConID["montos"].onChange = ({ target: { value } }) => !isNaN(Number(value)) && handleChange(value, "montos");
    Secciones["Servicio al cliente Autos"].hide = (stDatos["danioOcasioA"] !== "Automóvil (es)") || (EsReporteador);

    function DisabledAllInputs() {
        if (!EsReporteador) {
            for (const key in InputsConID) {
                InputsConID[key].disabled = stDatos["finalizado"] === 1 ? true : false;
            }
        }
    }

    /*  if (stDatos['danioOcasioPor'] !== "")
        {
            InputsConID[]
        }
    */

    const Auto = [
        'ubicacion',
        'modeloUnidad',
        'numVin',
        'numControl',
        'nomChofer',
        'genero',
        'antiguedad',
        'fechaNacimiento',
        'horaNombramiento',
        'turnoAnterior',
        'horaAlimentos',
        'calistenia',
        'ultimaPlatica',
        'fechaUltimaPlatica',
        'fechaRevProc'
    ]

    function VerificarAuto() {
        stDatos['danioOcasioA'] == "Automóvil (es)" ?
            Auto.map(key => {
                InputsConID[key].disabled = false;
            }) :
            Auto.map(key => {
                InputsConID[key].value = "";
                InputsConID[key].disabled = true;
            })
    }

    function Verificar()
    {
        if(stDatos['danioOcasioTer'] != "N/A") {
            InputsConID["danioOcasioNos"].disabled = true;
        }
        if(stDatos['danioOcasioNos'] != "N/A") {
            InputsConID["danioOcasioTer"].disabled = true;
        }
            
    }

    DisabledAllInputs();
    VerificarAuto();
    Verificar();

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => Identificador === "divisaID" ?  handleChangeDivisa(value) : handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.5em",
                widthBorderFieldset: Seccion === "Reporteador" ? "10px" : "1px",
                fontWeight: Seccion === "Reporteador" ? 700 : 300
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        accionID: stDatos["reporteDaniosID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["fechaElaboracion"],
            mensajeCorrecto: `El reporte de daños se ${stDatos["reporteDaniosID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState,
            condicion,
            ref,
            keyError,
            mensajeError,
            loader: setLoader,
            disabled: setDisabled,
            errorState: setErrorState
        });
    }

    async function handleValidaciones() {

        let error;
        let validaciones = [
            /*{
            condicion: !stDatos["numIncidencia"] || trim(stDatos["numIncidencia"]) === "",
            ref: ArrayRef["numIncidencia"],
            keyError: "numIncidencia",
            mensajeError: "Debe especificar el No. de Acción"
        },
        {
            condicion: !stDatos["numNC"] || trim(stDatos["numNC"]) === "",
            ref: ArrayRef["numNC"],
            keyError: "numNC",
            mensajeError: "Debe especificar el No. De NC"
        },*/
            {
                condicion: !stDatos["empresaID"] || ["empresaID"] === 0,
                ref: ArrayRef["empresaID"],
                keyError: "empresaID",
                mensajeError: "Debe especificar la empresa"
            },
            {
                condicion: !stDatos["fechaElaboracion"] || trim(stDatos["fechaElaboracion"]) === "",
                ref: ArrayRef["fechaElaboracion"],
                keyError: "fechaElaboracion",
                mensajeError: "Debe especificar la fecha de elaboración"
            },
            {
                condicion: !stDatos["lugarEvento"] || trim(stDatos["lugarEvento"]) === "",
                ref: ArrayRef["lugarEvento"],
                keyError: "lugarEvento",
                mensajeError: "Debe especificar el lugar del evento"
            },
            /*  {
                condicion: !stDatos["semanaDanio"] || trim(stDatos["semanaDanio"]) === "",
                ref: ArrayRef["semanaDanio"],
                keyError: "semanaDanio",
                mensajeError: "Debe especificar la semana del daño"
            }, 
            {
                condicion: !stDatos["fechaIngreso"] || trim(stDatos["fechaIngreso"]) === "",
                ref: ArrayRef["fechaIngreso"],
                keyError: "fechaIngreso",
                mensajeError: "Debe especificar la fecha de ingreso"
            },
            {
                condicion: !stDatos["fechaHabilidad"] || trim(stDatos["fechaHabilidad"]) === "",
                ref: ArrayRef["fechaHabilidad"],
                keyError: "fechaHabilidad",
                mensajeError: "Debe especificar la fecha de habilidad"
            },*/
            {
                condicion: !stDatos["fechaEvento"] || trim(stDatos["fechaEvento"]) === "",
                ref: ArrayRef["fechaEvento"],
                keyError: "fechaEvento",
                mensajeError: "Debe especificar la fecha del evento"
            },
            {
                condicion: !stDatos["horaEvento"] || trim(stDatos["horaEvento"]) === "",
                ref: ArrayRef["horaEvento"],
                keyError: "horaEvento",
                mensajeError: "Debe especificar la hora del evento"
            },
            
            /* ,
        {
                condicion: (!stDatos["horaAlimentos"] || trim(stDatos["horaAlimentos"]) === "") && stDatos['danioOcasioA'] == "Automóvil (es)",
                ref: ArrayRef["horaAlimentos"],
                keyError: "horaAlimentos",
                mensajeError: "Debe especificar la hora de alimentos"
            },
            {
                condicion: (!stDatos["horaNombramiento"] || trim(stDatos["horaNombramiento"]) === "") && stDatos['danioOcasioA'] == "Automóvil (es)",
                ref: ArrayRef["horaNombramiento"],
                keyError: "horaNombramiento",
                mensajeError: "Debe especificar la hora del nombramiento"
            }, */
        ];

        if(EsReporteador){
            validaciones.push(
                {
                    condicion: !stDatos["danioOcasioPor"] || ["danioOcasioPor"] === 0,
                    ref: ArrayRef["danioOcasioPor"],
                    keyError: "danioOcasioPor",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["danioOcasioARep"] || ["danioOcasioARep"] === 0,
                    ref: ArrayRef["danioOcasioARep"],
                    keyError: "danioOcasioARep",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["equipoBienPersona"] || trim(stDatos["equipoBienPersona"]) === "",
                    ref: ArrayRef["equipoBienPersona"],
                    keyError: "equipoBienPersona",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["areaRespDanioID"] || ["areaRespDanioID"] === 0,
                    ref: ArrayRef["areaRespDanioID"],
                    keyError: "areaRespDanioID",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["areaRespRepaID"] || ["areaRespRepaID"] === 0,
                    ref: ArrayRef["areaRespRepaID"],
                    keyError: "areaRespRepaID",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: stDatos["costoDanio"] == null || stDatos["costoDanio"] === "",
                    ref: ArrayRef["costoDanio"],
                    keyError: "costoDanio",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["tiempoInvertido"] || trim(stDatos["tiempoInvertido"]) === "",
                    ref: ArrayRef["tiempoInvertido"],
                    keyError: "tiempoInvertido",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["atendio"] || trim(stDatos["atendio"]) === "",
                    ref: ArrayRef["atendio"],
                    keyError: "atendio",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["notas"] || trim(stDatos["notas"]) === "",
                    ref: ArrayRef["notas"],
                    keyError: "notas",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: !stDatos["paridad"] || stDatos["paridad"] === "",
                    ref: ArrayRef["paridad"],
                    keyError: "paridad",
                    mensajeError: "Dato obligatorio"
                }
            )
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["reporteDaniosID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            }
        });

        if (error) return error;

    }

    async function handleChangeDivisa (value) {
        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
                    
         setState({ ...stDatos, ["divisaID"]: value, ["paridad"]: historialParidades[0].paridad });

    }

    function ftGuardar() {
        async function ftGuardar() {
            let valor;
            let error = await handleValidaciones();
            //try{
            let DatosGuardar = { ...stDatos };

            DatosGuardar["fechaElaboracion"] = formatearFecha(DatosGuardar["fechaElaboracion"]);
            //DatosGuardar["semanaDanio"] = formatearFecha(DatosGuardar["semanaDanio"]);
            // DatosGuardar["fechaIngreso"] = formatearFecha(DatosGuardar["fechaIngreso"]);
            // DatosGuardar["fechaHabilidad"] = formatearFecha(DatosGuardar["fechaHabilidad"]);
            DatosGuardar["fechaEvento"] = formatearFecha(DatosGuardar["fechaEvento"]);
            DatosGuardar["horaEvento"] = formatearHora(DatosGuardar["horaEvento"]);
            DatosGuardar["horaAutorizacion"] = DatosGuardar["horaAutorizacion"] !== null ? formatearHora(DatosGuardar["horaAutorizacion"]) : null;
            //DatosGuardar["montos"] = DatosGuardar["montos"] ? parseFloat(DatosGuardar["montos"]) : 0

            DatosGuardar["costoDanio"] = parseFloat(DatosGuardar["costoDanio"]);
            DatosGuardar["divisaID"] = EsReporteador === true ? stDatos.divisaID : null ;
            DatosGuardar["paridad"] = EsReporteador === true ? parseFloat(stDatos.paridad) : null;
            DatosGuardar["reporteDaniosID"] = parseInt(DatosGuardar["reporteDaniosID"]);
            DatosGuardar["finalizado"] = parseInt(DatosGuardar["finalizado"])

            DatosGuardar["areaID"] = parseInt(DatosGuardar["areaID"]);
            DatosGuardar["areaRespDanioID"] = parseInt(DatosGuardar["areaRespDanioID"]);
            DatosGuardar["areaRespRepaID"] = parseInt(DatosGuardar["areaRespRepaID"]);

            DatosGuardar["ubicacionID"] = null;
            DatosGuardar["fechaNacimiento"] = stDatos['danioOcasioA'] == "Automóvil (es)" && stDatos['fechaNacimiento'] !== (null || "") ? formatearFecha(DatosGuardar["fechaNacimiento"]) : null;
            DatosGuardar["fechaUltimaPlatica"] = stDatos['danioOcasioA'] == "Automóvil (es)" && stDatos['fechaUltimaPlatica'] !== (null || "") ? formatearFecha(DatosGuardar["fechaUltimaPlatica"]) : null;
            DatosGuardar["fechaRevProc"] = stDatos['danioOcasioA'] == "Automóvil (es)" && stDatos['fechaRevProc'] !== (null || "") ? formatearFecha(DatosGuardar["fechaRevProc"]) : null;
            DatosGuardar["horaAlimentos"] = stDatos['danioOcasioA'] == "Automóvil (es)" && stDatos['horaAlimentos'] !== (null || "") ? formatearHora(DatosGuardar["horaAlimentos"]) : null;
            DatosGuardar["horaNombramiento"] = stDatos['danioOcasioA'] == "Automóvil (es)" && stDatos['horaNombramiento'] !== (null || "") ? formatearHora(DatosGuardar["horaNombramiento"]) : null;


            if (error) return error;

            let datos = { serverStorageID: stDatos.storageID1, origenID: Number(DatosGuardar.reporteDaniosID), tabla: "REPORTEDANIOS", tipo: "REPORTEDANIOS", clasificacion: "fotoDanio", nombreArchivo: stFotografia1.eliminar ? "" : stFotografia1.file.name, ruta: stDatos["rutafotografia1"] }
            let datos2 = { serverStorageID: stDatos.storageID2, origenID: Number(DatosGuardar.reporteDaniosID), tabla: "REPORTEDANIOS", tipo: "REPORTEDANIOS", clasificacion: "fotoDanio", nombreArchivo: stFotografia2.eliminar ? "" : stFotografia2.file.name, ruta: stDatos["rutafotografia2"] }

            delete DatosGuardar["fotografia1"];
            delete DatosGuardar["rutafotografia1"];
            delete DatosGuardar["storageID1"];
            delete DatosGuardar["nombrefoto1"];

            delete DatosGuardar["fotografia2"];
            delete DatosGuardar["rutafotografia2"];
            delete DatosGuardar["storageID2"];
            delete DatosGuardar["nombrefoto2"];
            delete DatosGuardar["fechaModificacion"];
            delete DatosGuardar["folioInterno"];
            delete DatosGuardar["areaNombre"];
            delete DatosGuardar["areaRespDanioNombre"];
            delete DatosGuardar["areaRespRepaNombre"];
            delete DatosGuardar["fechaIngreso"];
            delete DatosGuardar["fechaHabilidad"];
            delete DatosGuardar["montos"];
            delete DatosGuardar["ejercicio"];
            delete DatosGuardar["mes"];
            delete DatosGuardar["fechaInicio"];
            delete DatosGuardar["fechaFin"];

            if (stDatos["reporteDaniosID"]) {
                await Consultas.updateReporteDanios(DatosGuardar, token);
            } else {
                delete DatosGuardar["reporteDaniosID"];
                valor = await Consultas.addReporteDanios(DatosGuardar, token);
                datos = { ...datos, serverStorageID: null, origenID: valor.reporteDaniosID };
                datos2 = { ...datos2, serverStorageID: null, origenID: valor.reporteDaniosID };
            }
            try {
                if (stFotografia1.file || stFotografia1.eliminar == true) {
                    await actualizaArchivo('ReporteDanios/fotoPerfil/', stFotografia1.file, 1, datos, token, stFotografia1.eliminar);
                }
                if (stFotografia2.file || stFotografia2.eliminar == true) {
                    await actualizaArchivo('ReporteDanios/fotoPerfil/', stFotografia2.file, 1, datos2, token, stFotografia2.eliminar);
                }
            } catch (error) {
                setNotificaciones({ mensaje: error, color: danger, open: true });
            }
        }

        return ftGuardar();
    }

    function formatearFecha(pFecha) {
        if (moment(pFecha).isValid() === false) {
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function formatearHora(pHora) {
        if (moment(pHora).isValid() === false) {
            let tHora = moment(pHora, 'HH:mm').format('HH:mm');
            if (moment(tHora).isValid() === false) {
                return tHora;
            }
            return null;
        }

        return moment(pHora).format("HH:mm");
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setFotografia1({ valor: "", file: "", remover: false });
        setFotografia2({ valor: "", file: "", remover: false });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (key === "fotoUsuario") {
            return;
        }

        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        if (key === "costoDanio") {
            if (isNaN(Number(value))) {
                return;
            }
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

        if (key === "danioOcasioA") {
            switch (value) {
                case "Carga General":
                    setTituloTipoDanio("Tipo de Carga y/o No. de Serie");
                    break;
                case "Contenedor (es)":
                    setTituloTipoDanio("Tipo y No de Cont.");
                    break;
                case "Automóvil (es)":
                    setTituloTipoDanio("No. Serie");
                    break;
                case "Maquinaria y/o Equipo Interno":
                    setTituloTipoDanio("No. de Serie y/o Económico");
                    break;
                case "Herramientas de Trabajo":
                    setTituloTipoDanio("Tipo de Herramienta");
                    break;
                case "Instalaciones":
                    setTituloTipoDanio("¿Cuál (es)?");
                    break;
                case "Propiedad Externa":
                    setTituloTipoDanio("Tipo y No de Serie o Económico");
                    break;
                default:
                    setTituloTipoDanio("")
                    break;
            }
        }
    }

    async function ObtenerEmpresas() {
        let Respuesta = await ConsultasEmpresas.seleccionEmpresa({ filtro: { activa: 1 } }, token);
        setEmpresas(Respuesta);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();

                let Areas = await seleccionArea({ filtro: {} }, token);
                let Ubicaciones = await seleccionUbicaciones({ filtro: {} }, token);
                let DaniosATerceroEmpresa = await ConsultasEmpresas.seleccionEmpresaDanio({ filtro: {} }, token);
                let dominios = await ConsultasDominios.seleccionDominios({}, token);

                setSeleccionables({ Areas, Ubicaciones, DaniosATerceroEmpresa, dominios });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            try {
                if (registroID === null) {
                    setRegistroID(null);
                }
                if (url !== ":params" && (!validarParams)) {
                    setRegistroID(auxRegistroIDEffect);
                }
                setActualizar(!actualizar)
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [registroID, auxRegistroIDEffect]);

    function handleCloseSeguimiento() {
        setAbrirSeguimiento(false);
        setnotificacionesSeguimiento({ ...cleanNotificaciones });
        setseguimientoSelect("");
    }

    function handleCloseFinalizar() {
        setOpenFinalizar(false);
        setStateFinalizar({ correo: "", dominio: "@atpaltamira.com.mx" })
    }

    ArrayRef["seguimientoSelect"] = useRef(null);
    cleanErrorState["seguimientoSelect"] = { error: false, helperText: "" };

    const inputsSeguimiento = [{
        value: seguimientoSelect,
        id: "seguimientoSelect",
        error: errorState["seguimientoSelect"].error,
        success: seguimientoSelect && !errorState["seguimientoSelect"].error ? true : undefined,
        helperText: errorState["seguimientoSelect"].helperText,
        inputRef: ArrayRef["seguimientoSelect"],
        title: "Seguimiento",
        placeholder: "Seguimiento",
        textAlign: "left",
        onChange: ({ target: { value } }) => setseguimientoSelect(value),
        onKeyDown: () => { return; },
        grid: { md: 12, lg: 12 },
        tipo: "select",
        data: [{ value: "SIN RECLAMACIÓN", label: "SIN RECLAMACIÓN" },
        { value: "REPARADO", label: "REPARADO" },
        { value: "PAGADO", label: "PAGADO" },
        { value: "RECLAMADO A ASEGURADORA (RGR-002)", label: "RECLAMADO A ASEGURADORA (RGR-002)" },
        { value: "RECLAMADO A TERCERO (RGR-007)", label: "RECLAMADO A TERCERO (RGR-007)" }, 
        { value: "REPOSICIÓN", label: "REPOSICIÓN" },
        { value: "CERRADO", label: "CERRADO" }
        ]
    }]

    const inputsFinalizar = [
        {
            disabled,
            id: "correo",
            value: stateFinalizar.correo,
            title: "Correo",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el correo",
            onChange: ({ target: { value } }) => setStateFinalizar({ ...stateFinalizar, correo: value }),
            onKeyDown: () => { return; }
        },
        {
            value: stateFinalizar.dominio,
            id: "seguimientoSelect",
            error: errorState["seguimientoSelect"].error,
            success: seguimientoSelect && !errorState["seguimientoSelect"].error ? true : undefined,
            helperText: errorState["seguimientoSelect"].helperText,
            inputRef: ArrayRef["seguimientoSelect"],
            title: "Dominio",
            placeholder: "Dominio",
            textAlign: "left",
            onChange: ({ target: { value } }) => setStateFinalizar({ ...stateFinalizar, dominio: value }),
            onKeyDown: () => { return; },
            grid: { md: 6, lg: 6 },
            tipo: "select",
            data: seleccionables.dominios
        }
    ]

    const accionesSeguimiento = [{
        loader,
        disabled,
        icono: AssignmentTurnedInIcon,
        color: info,
        descripcion: "Cerrar reporte",
        onClick: () => CerrarReporte()
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseSeguimiento
    }];

    const accionesFinalizar = [{
        loader,
        disabled,
        icono: AssignmentTurnedInIcon,
        color: info,
        descripcion: "Finalizar",
        onClick: () => ftFinalizarReporte()
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseFinalizar
    }];

    function AbrirFormCerrarReporte(parametros) {
        setCerrarReporte(parametros)
        if (parametros.estatus === "CERRADO" && parametros.seguimiento === 'CERRADO') {
            setAlert({
                descripcion: 'El reporte de daños ya se encuentra cerrado',
                title: "Cerrar reporte",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
            return;
        }

        setAbrirSeguimiento(true);
    }

    async function CerrarReporte() {
        try {
            let result = await Consultas.CerrarReporteDanios({ reporteDaniosID: stCerrarReporte['reporteDaniosID'], seguimiento: seguimientoSelect }, token);

            switch (seguimientoSelect) {
                case "RECLAMADO A ASEGURADORA (RGR-002)":
                    setAlert({
                        descripcion: 'El reporte de daños generó la reclamación a aseguradora con No. Siniestro ' + stCerrarReporte.folioInterno,
                        title: "",
                        tipo: "success-two",
                        msjConfirmacion: "Abrir reclamo",
                        onConfirm: () => IrARuta('ASEGURADORA', result["RegistroID"]),
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });
                    break;
                case "RECLAMADO A TERCERO (RGR-007)":
                    setAlert({
                        descripcion: 'El reporte de daños generó la reclamación a tercero con No. Siniestro ' + stCerrarReporte.folioInterno,
                        title: "",
                        tipo: "success-two",
                        msjConfirmacion: "Abrir reclamo",
                        onConfirm: () => IrARuta('TERCERO', result["RegistroID"]),
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });
                    break;
                default:
                    break;
            }

            setActualizar(!actualizar);
            setseguimientoSelect("");
            setCerrarReporte({})
            setAbrirSeguimiento(false);

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);

    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    //<ControlPagoReporteDanios data={{ reporteDaniosID: stReporteDaniosID }}/>

    return (<>
        <Tabla infoTabla={infoTabla}
            asyncData={Consultas.tablaReporteDanios}
            token={token}
            parametrosFijos={{ reporteDaniosID: registroID }}
        />
        <FormularioModal open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["UsuarioNombre"]} /> <FormularioModal open={abrirSeguimiento}
                title={EsReporteador ? "Cerrar Reporteador de Daños" : "Cerrar Reporte de Daños"}
                onClose={handleCloseSeguimiento}
                notificaciones={notificacionesSeguimiento}
                //closeNotification={() => setNotificacionesSeguimiento({ ...cleanNotificacionesSeguimiento })}
                inputs={inputsSeguimiento}
                acciones={accionesSeguimiento}
                focus={ArrayRef["seguimientoSelect"]} />
        {
            openFinalizar && <FormularioModal open={openFinalizar}
                title={"Finalizar Reporte"}
                onClose={handleCloseFinalizar}
                inputs={inputsFinalizar}
                acciones={accionesFinalizar}
            />
        }
        {
            openDocumentacion &&
            <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>
                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />
            </Modal>
        }
        {
            openTrazabilidad &&
            <Modal maxWidth={true} onClose={() => {setOpenTrazabilidad(false)}}>
                <TrazabilidadSiniestros datosDetalle={datosDetalle} setOpenTrazabilidad={() => setOpenTrazabilidad(false)
                } />
            </Modal>
        }
        {
            alert && < SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            />}
        {
            openSeguimientos && <Modal
                open={openSeguimientos}
                onClose={() => setOpenSeguimientos(false)}
            >
                <SeguimientoActividades datos={{ actividadID: null, reporteDaniosID: stReporteDaniosID, verHistorial }} actualizarTablaReporteador={setActualizar} reporteadorDaños={true} />
            </Modal>
        }
        {
            openControlPago && <Modal maxWidth={true}
                open={openControlPago}
                onClose={() => setOpenControlPago(false)}
            >
                <UpsertControlPago 
                data={{ reporteDaniosID: stReporteDaniosID }} 
                cerrar={() => setOpenControlPago(false)}      
                propsAlert={alert} 
                propsSetAlert={setAlert}         
                ></UpsertControlPago>
            </Modal>
        }
    </>
    );
}

export default ReporteDanios;
