import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { getEdificio, tablaEdificios } from '../../../querys/SolSegFianEdificio';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
import { addEdificio, updateEdificio,deleteEdificio } from '../../../mutations/SolSegFianEdificio';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { rgxLetras, rgxNumeros,rgxNumeroDecimal4 } from '../../../helpers/regexp';
import { getEdad } from 'helpers/rutinas/metodos/rutinasGenerales';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import moment from 'moment';
import { toFixed,redondeo } from '../../../helpers/index';
import { seleccionDepartamento } from 'querys/Departamento';
import { seleccionGrupos } from '../../../querys/Grupos'
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;

function SolSegFianEdificio({ datosDetalle,setOpenSolSegFianEdificio }) {
    let now = moment();
    let cTitulo2 = "ANEXOS: Recuerde adjuntar documentos fuente de donde se deriva la obligacion, Factura, OC, fotografias, planos, catalogo de conceptos.";

    let URLPolizasSeguroID = null;

    const { token } = useSelector(state => state.login);
    const formato = "YYYY-MM-DD";
    const cleanState = {
        edificioID:null,
        solsegfianID:datosDetalle.pSolSegFianID,
        empresaID: datosDetalle.pEmpresaID,
        empresa:"",
        grupoID:"",
        grupo:"",
        bienBajoConvenio:"",
        infraestructura:"",
        descripcion:"",
        areaM2:"",
        valorFactura:"",
        valorAsegurado:"",
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1"
    };

    const cleanErrorState = {
        edificioID: { error: false, helperText: "" },
        solsegfianID: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        grupoID: { error: false, helperText: "" },
        bienBajoConvenio: { error: false, helperText: "" },
        infraestructura: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        areaM2: { error: false, helperText: "" },
        valorFactura: { error: false, helperText: "" },
        valorAsegurado: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" }
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.edificioID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Edificio`;

    const edificioIDRef = useRef(null);
    const solsegfianIDRef = useRef(null);
    const empresaIDRef = useRef(null);
    const grupoIDRef = useRef(null);
    const bienBajoConvenioRef = useRef(null);
    const infraestructuraRef = useRef(null);
    const descripcionRef = useRef(null);
    const areaM2Ref = useRef(null);
    const valorFacturaRef = useRef(null);
    const valorAseguradoRef = useRef(null);
    const divisaIDRef = useRef(null);
    const paridadRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]


    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "edificioID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getEdificio,
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "edificioID" }],
        disabled: { multiseleccion: true },
        onClick: ({ edificioID }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { edificioID }, asyncDelete: deleteEdificio
            })
        })
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "edificioID" }],
        disabled: { multiseleccion: true },
        onClick: ({ edificioID }) => {
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: edificioID,
                pTipo: datosDetalle.pfolioFormateado,
                pClasificacion: "",
                pTabla: "Solicitud de Contenidos",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    }];

    let botones = [
        {
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            setOpenSolSegFianEdificio(false);
        }
    },  
    {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];


    if (datosDetalle.pEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setOpen(true); divisas()},
            disabled: { multiseleccion: true }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "edificioID",
        color: primary,
        title: "Edificios",
        title2: "ANEXO: Recuerde adjuntar documentos fuente de donde se deriva la obligacion, Factura, OC, fotografias.",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "edificioID", descripcion: "edificioID", hide: true },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "grupo", descripcion: "Grupo" },
            { variable: "bienBajoConvenio", descripcion: "Bien Bajo Convenio" },
            { variable: "infraestructura", descripcion: "Infraestructura" },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "areaM2", descripcion: "Área M2" },
            { variable: "valorFactura", descripcion: "Valor Factura" },
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "paridad", descripcion: "Paridad" },
            { variable: "valorAsegurado", descripcion: "Valor Asegurado" },
        ],
        formato: [
            {
                columnas: ["costoUnitario"], tipo: "moneda"
            }
        ],
        responsiveTitle: ["departamento"],
        filter: [
            { campo: "departamento", placeholder: "Departamento" },
            { campo: "area", placeholder: "Área" },
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            disabled:true,
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccionar Empresa",
            tipo: "autocomplete",
            data: seleccionables.empresas,
            grid: { md: 3, lg: 3 },
            onChange: (valor) => { setState({ ...state, empresaID: valor ? valor.value : '',  }) }
        },
        {
            disabled,
            id: "grupoID",
            value: state.grupoID,
            error: errorState.grupoID.error,
            success: state.grupoID && !errorState.grupoID.error ? true : undefined,
            helperText: errorState.grupoID.helperText,
            inputRef: grupoIDRef,
            title: "Grupo *",
            placeholder: "Seleccionar grupo",
            tipo: "autocomplete",
            data: seleccionables.grupos,
            grid: { md: 4, lg: 4 },
            onChange: (valor) => { setState({ ...state, grupoID: valor ? valor.value : '',  }) }
        },
        {
            disabled,
            id: "bienBajoConvenio",
            value: state.bienBajoConvenio,
            error: errorState.bienBajoConvenio.error,
            success: state.bienBajoConvenio && !errorState.bienBajoConvenio.error ? true : undefined,
            helperText: errorState.bienBajoConvenio.helperText,
            inputRef: bienBajoConvenioRef,
            title: "Bien Bajo Convenio",
            placeholder: "Capture el bien bajo convenio",
            grid: { sm: 12, md: 5, lg: 5 },
            onChange: ({ target: { value } }) => { handleChange(value, "bienBajoConvenio") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, bienBajoConvenio: { error: state.bienBajoConvenio.length > 100 && true, helperText: state.bienBajoConvenio.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "infraestructura",
            value: state.infraestructura,
            error: errorState.infraestructura.error,
            success: state.infraestructura && !errorState.infraestructura.error ? true : undefined,
            helperText: errorState.infraestructura.helperText,
            inputRef: infraestructuraRef,
            title: "Infraestructura",
            placeholder: "Capture la infraestructura",
            grid: { sm: 12, md: 5, lg: 5 },
            onChange: ({ target: { value } }) => { handleChange(value, "infraestructura") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, infraestructura: { error: state.infraestructura.length > 100 && true, helperText: state.infraestructura.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "descripcion",
            value: state.descripcion,
            error: errorState.descripcion.error,
            success: state.descripcion && !errorState.descripcion.error ? true : undefined,
            helperText: errorState.descripcion.helperText,
            inputRef: descripcionRef,
            title: "Descripcion *",
            placeholder: "Capture la descripcion",
            grid: { sm: 12, md: 5, lg: 5 },
            onChange: ({ target: { value } }) => { handleChange(value, "descripcion") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, descripcion: { error: state.descripcion.length > 250 && true, helperText: state.descripcion.length > 250 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "areaM2",
            value: state.areaM2,
            error: errorState.areaM2.error,
            success: state.areaM2 && !errorState.areaM2.error ? true : undefined,
            helperText: errorState.areaM2.helperText,
            inputRef: areaM2Ref,
            title: "Área M2 *",
            placeholder: "Capture el área M2",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "areaM2") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, areaM2: { error: state.areaM2.length > 100 && true, helperText: state.areaM2.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "valorFactura",
            value: state.valorFactura,
            error: errorState.valorFactura.error,
            success: state.valorFactura && !errorState.valorFactura.error ? true : undefined,
            helperText: errorState.valorFactura.helperText,
            inputRef: valorFacturaRef,
            title: "Costo Factura *",
            icono: <span>{'$'}</span>,
            posicionIcono: "start",
            formatoImportes: true,
            placeholder: "Capture el valor factura",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { 
                (!isNaN(Number(value)) && value.trim() !== '') && 
                setState({...state,
                    valorFactura: value, valorAsegurado: redondeo( parseFloat(toFixed((parseFloat(value)/parseFloat(state.paridad) ),2) ),2 )})
            },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, valorFactura: { error: state.valorFactura.length > 100 && true, helperText: state.valorFactura.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: true,
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 2, lg: 2 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => 
            handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: state.divisaID === 69,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            title: "Paridad Actual",
            textAlign: "right",
            placeholder: "Paridad Actual",
            grid: { md: 2, lg: 2 },
            onChange: ({ target: { value } }) => {(!isNaN(Number(value)) && value.trim() !=='') &&
                setState({
                    ...state,
                    paridad: value, valorAsegurado: redondeo(parseFloat(toFixed(parseFloat((state.valorFactura) / parseFloat(value)), 2)), 2)
                })
            }
        },
        {
            disabled,
            id: "valorAsegurado",
            value: state.valorAsegurado,
            error: errorState.valorAsegurado.error,
            success: state.valorAsegurado && !errorState.valorAsegurado.error ? true : undefined,
            helperText: errorState.valorAsegurado.helperText,
            inputRef: valorAseguradoRef,
            title: "Costo Unitario *",
            icono: <span>{'$'}</span>,
            posicionIcono: "start",
            formatoImportes: true,
            placeholder: "Capture el valor asegurado",
            grid: { sm: 12, md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "valorAsegurado") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, valorAsegurado: { error: state.valorAsegurado.length > 100 && true, helperText: state.valorAsegurado.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
    ];


    let accionesFormulario = [{
        accionID: state.gtosMedMayID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: empresaIDRef,
            mensajeCorrecto: `El registro se ${state.edificioID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    async function handleValidaciones({ grupoID,descripcion,areaM2,valorFactura }) {
        let error;
        let validaciones = [
            {
                condicion: !grupoID,
                keyError: "grupoID",
                mensajeError: "Debe especificar el grupo"
            },
            {
                condicion: !descripcion || trim(descripcion) === "",
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Debe ingresar una descripción"
            },
            {
                condicion: !areaM2 || trim(areaM2) === "",
                ref: areaM2Ref, keyError: "areaM2",
                mensajeError: "Debe ingresar el área"
            },
            {
                condicion:  valorFactura === "",
                ref: valorFacturaRef, keyError: "valorFactura",
                mensajeError: "Debe especificar el valor factura"
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    
    async function handleChangeDivisa (value, key) {
        if (URLPolizasSeguroID) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
            setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
        }
    }
    
    async function divisas(){
        if (URLPolizasSeguroID && Number(URLPolizasSeguroID) !== 0) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
         } else {
             let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);        
             setState({ ...state, ["paridad"]: historialParidades[0].paridad });
         }
    }


    function ftGuardar() {
        async function ftGuardar() {

            let {edificioID,solsegfianID,empresaID,grupoID,bienBajoConvenio,infraestructura,descripcion,areaM2,valorFactura,valorAsegurado,divisaID,paridad} = state;

            let error = await handleValidaciones({ grupoID,descripcion,areaM2,valorFactura});
            if (error) return error;

            if (edificioID) await updateEdificio({
                input: { edificioID,solsegfianID,empresaID,grupoID,bienBajoConvenio,infraestructura,descripcion,areaM2,
                    valorFactura: parseFloat(valorFactura),
                    valorAsegurado: parseFloat(valorAsegurado),
                    divisaID,
                    paridad:parseFloat(paridad) }
            }, token);
            else await addEdificio({
                input: { edificioID,solsegfianID,empresaID,grupoID,bienBajoConvenio,infraestructura,descripcion,areaM2,
                    valorFactura: parseFloat(valorFactura),
                    valorAsegurado: parseFloat(valorAsegurado),
                    divisaID,
                    paridad:parseFloat(paridad) }
            }, token);
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }



    useEffect(() => {
        async function ftEffect() {
            try {
                let empresas = await seleccionEmpresa({ filtro: {} }, token);
                let ubicaciones = await seleccionUbicaciones({ filtro: {empresaID: datosDetalle.pEmpresaID} }, token);
                let departamentos = await seleccionDepartamento({ filtro: {} }, token);
                let grupos = await seleccionGrupos({ filtro: {} }, token);

                setSeleccionables({ empresas,ubicaciones,departamentos,grupos });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }



    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaEdificios}
            token={token}
            parametrosFijos={{ solsegfianID: datosDetalle.pSolSegFianID }}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={empresaIDRef}
        />
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default SolSegFianEdificio;
