import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import SeguimientoActividades from '../SeguimientoActividades';
import DescriptionIcon from '@material-ui/icons/Description';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import TableChartTwoTone from "@material-ui/icons/TableChartTwoTone";

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { seleccionPolizasSeguro } from '../../../querys/PolizasSeguro';
import { seleccionRamoSeguroNA, seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../Empresas/Consultas';
import { seleccionUsuario } from '../Usuarios/Consultas';

import { getActividadesDeTareas, tablaActividadesDeTareas } from '../../../querys/ActividadesDeTareas';
import { deleteActividadesDeTareas, addActividadesDeTareas, updateActividadesDeTareas } from '../../../mutations/ActividadesDeTareas';

import { getTareasProgAnual, tablaTareasProgAnual } from '../../../querys/TareasProgAnual';
import { deleteTareasProgAnual, addTareasProgAnual, updateTareasProgAnual } from '../../../mutations/TareasProgAnual';

import moment from 'moment';
import { DateTime } from "luxon";

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

import { rgxdeInt10, rgxInputTexto30, rgxNumeros, rgxDecimal8, rgxDecimal14 } from '../../../helpers/regexp';
import { toFixed,redondeo } from '../../../helpers/index';

const { trim } = Validaciones;

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }


const { Encrypt, Decrypt } = Encriptar;

function TareasProgAnual() {

    let history = useHistory();
    const { token } = useSelector(state => state.login);

    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";

    let { params } = useParams();
    let url = params.replace(/&/g, "/");
    let seed = "segumex";
    let { programaAnualID,folioInterno,autorizado } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;


    
    const cleanState = {
        tareaID: null,
        empresaID: "",
        polizaID: "",
        ramoSeguroID: "",
        usuarioID: "",
        numero: "",
        tipoTarea: "",
        objetivo: "",
        fechaAlta: now.format(formatoDelmomento),
        fechaModificacion: "",
        estado: "ACTIVA",
        avance: 0,
        fechaInicio: "",
        fechaFinal: "",
        fechaInicioReal: null,
        fechaFinalReal: null,
        extraordinaria: false
    };

const aTiposTarea = [{value:"Renovación", label:"Renovación"},
{value:"Presentación", label:"Presentación"},
{value:"Recorrido", label:"Recorrido"},{value:"Contratación", label:"Contratación"},{value:"Capacitación", label:"Capacitación"},{value:"Otro", label:"Otro"}];

    const cleanErrorState = {

        empresaID: { error: false, helperText: "" },
        polizaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        usuarioID: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        tipoTarea: { error: false, helperText: "" },
        objetivo: { error: false, helperText: "" },
        fechaAlta: { error: false, helperText: "" },
        fechaModificacion: { error: false, helperText: "" },
        estado: { error: false, helperText: "" },
        avance: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" },
        extraordinaria: { error: false, helperText: "" }
    }


    const cleanStateActividades = {
        actividadID: null,
        tareaID: "",
        usuarioID: "",
        descripcion: "",
        responsableID: "",
        porcentaje: 0,
        estado: "ACTIVA",
        avance: 0,
        fechaInicio: "",
        fechaFinal: "",
        fechaInicioReal: null,
        fechaFinalReal: null
    };
    const cleanErrorStateActividades = {

        tareaID: { error: false, helperText: "" },
        usuarioID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        responsableID: { error: false, helperText: "" },
        porcentaje: { error: false, helperText: "" },
        estado: { error: false, helperText: "" },
        avance: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" }

    }


    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [seleccionables, setSeleccionables] = useState({});


    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);



    const [errorStateActividades, setErrorStateActividades] = useState(cleanErrorStateActividades);
    const [stateActividades, setStateActividades] = useState(cleanStateActividades);
    const [openActividades, setOpenActividades] = useState(false);
    const [actualizarActividades, setActualizarActividades] = useState(false);
    const [openVerActividades, setOpenVerActividades] = useState(false);

    const [stateTareaID, setStateTareaID] = useState(-1);
    const [stateUsuarioID, setStateUsuarioID] = useState(-1);
    const [stateDescripcionTarea, setStateDescripcionTarea] = useState("");

    const [stFechaProgInicio, setFechaProgInicio] = useState(now.format(formatoDelmomento));
    const [stFechaProgFin, setFechaProgFin] = useState(now.format(formatoDelmomento));
    const [tareaID,setTareaID] = useState("");
    const [openSeguimientos,setOpenSeguimientos] = useState(false);


   

    let titleAccion = state.tareaID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Tarea ${folioInterno}`;
    let titleAccionActividades = stateActividades.actividadID ? "Modificar" : "Agregar";
    let titleModalActividades = `${titleAccionActividades} Actividad`;

    const empresaIDRef = useRef(null);
    const polizaIDRef = useRef(null);
    const ramoSeguroIDRef = useRef(null);
    const usuarioIDRef = useRef(null);
    const numeroRef = useRef(null);
    const tipoTareaRef = useRef(null);
    const objetivoRef = useRef(null);
    const fechaAltaRef = useRef(null);
    const fechaModificacionRef = useRef(null);
    const estadoRef = useRef(null);
    const avanceRef = useRef(null);
    const fechaInicioRef = useRef(null);
    const fechaFinalRef = useRef(null);
    const fechaInicioRealRef = useRef(null);
    const fechaFinalRealRef = useRef(null);
    const descripcionRef = useRef(null);
    const responsableRef = useRef(null);
    const porcentajeRef = useRef(null);

const listMeses = [
    { label: "Enero", value: 1 },
    { label: "Febrero", value: 2 },
    { label: "Marzo", value: 3 },
    { label: "Abril", value: 4 },
    { label: "Mayo", value: 5 },
    { label: "Junio", value: 6 },
    { label: "Julio", value: 7 },
    { label: "Agosto", value: 8 },
    { label: "Septiembre", value: 9 },
    { label: "Octubre", value: 10 },
    { label: "Noviembre", value: 11 },
    { label: "Diciembre", value: 12 }
]
    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "tareaID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getTareasProgAnual,
            formatearResultado: [
                { id: "fechaAlta", formato: (data) => moment(data) },
                { id: "fechaModificacion", formato: (data) => moment(data) },
                { id: "fechaInicio", formato: (data) => moment(data) },
                { id: "fechaFinal", formato: (data) => moment(data) },
                { id: "avance", formato: (data) => `${redondeo( parseFloat(toFixed((data * 100),2)),2 )}` },
            ]
        })
    }, 
    {
            icono: TableChartTwoTone ,
            color: primary,
            descripcion: "Actividades de Tareas",
            parametros: [{ campo: "tareaID" }, { campo: "usuarioID" }, { campo: "numero" },{ campo: "objetivo" },{ campo: "fechaInicio" },{ campo: "fechaFinal" } ],
            disabled: { multiseleccion: false },

            onClick: ({ tareaID, usuarioID, numero, objetivo,fechaInicio,fechaFinal}) => {
                if (stateTareaID != tareaID && !openVerActividades) {
                    setOpenVerActividades(!openVerActividades);
                    setStateTareaID(tareaID);
                    setActualizarActividades(!actualizarActividades)
                    setStateUsuarioID(usuarioID);
                    let descripcion = ` del No. ${numero} con objetivo de ${objetivo.toLowerCase()}`;
                    setStateDescripcionTarea(descripcion );
                    setFechaProgInicio(fechaInicio);
                    setFechaProgFin(fechaFinal);
                } else if (stateTareaID != tareaID && openVerActividades) {
                    setStateTareaID(tareaID);
                    setActualizarActividades(!actualizarActividades)
                    setStateUsuarioID(usuarioID);
                }
                else if (stateTareaID === tareaID && openVerActividades) {
                    setStateTareaID(tareaID);
                    setOpenVerActividades(!openVerActividades);
                    setStateUsuarioID(usuarioID);
                } else if (stateTareaID === tareaID && !openVerActividades) {
                    setStateTareaID(tareaID);
                    setOpenVerActividades(!openVerActividades);
                    setStateUsuarioID(usuarioID);
                }
            },
            disabled: { multiseleccion: true }
        },
];
if (parseInt(autorizado) === 0) {
    acciones.push(
        {
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "tareaID" },{ campo: "numero" }],
            disabled: { multiseleccion: true },
            onClick: ({ tareaID, numero }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro ${numero}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { tareaID }, asyncDelete: deleteTareasProgAnual
                })
            })
        })
}

    const botones = [{
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            history.push(`/gen/ProgramaAnual/`);
        }
        },
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];


    const accionesActividades = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "actividadID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateActividades, setOpen: setOpenActividades, setAlert,
            asyncGet: getActividadesDeTareas,
            formatearResultado: [
                { id: "fechaInicio", formato: (data) => moment(data) },
                { id: "fechaFinal", formato: (data) => moment(data) },
                { id: "avance", formato: (data) => `${redondeo( parseFloat(toFixed((data * 100),2)),2 )}` },
            ]
        })
    }, 
    {
        icono: DescriptionIcon,
        color: primary,
        descripcion: "Seguimientos",
        parametros: [{ campo: "actividadID" }],
        disabled: { multiseleccion: true },
        onClick: ({ actividadID }) => {setTareaID(actividadID);setOpenSeguimientos(true);}
    },
];

if (parseInt(autorizado) === 0) {
    accionesActividades.push(
        {
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "actividadID" },{ campo: "tareaID" }],
            disabled: { multiseleccion: true },
            onClick: ({ actividadID,tareaID }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro `,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar:setActualizarActividades, actualizar:actualizarActividades,
                    parametros: { actividadID ,tareaID}, asyncDelete: deleteActividadesDeTareas
                })
            })
        })
}
    const botonesActividades = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setStateActividades({ ...stateActividades, fechaInicio: stFechaProgInicio,fechaFinal:stFechaProgFin });
                setOpenActividades(true);},
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarActividades(!actualizarActividades),
            disabled: { multiseleccion: true }
        }];


    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "tareaID",
        color: primary,
        title: `Tareas del Programa Anual ${folioInterno}`,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "tareaID", descripcion: "tareaID", hide: true },
            { variable: "programaAnualID", descripcion: "programaAnualID", hide: true},
            { variable: "polizaID", descripcion: "polizaID", hide: true },
            { variable: "ramoSeguroID", descripcion: "ramoSeguroID", hide: true },
            { variable: "usuarioID", descripcion: "usuarioID", hide: true },
            { variable: "numero", descripcion: "Numero" },
            { variable: "siglas", descripcion: "Empresa" },
            { variable: "estado", descripcion: "Estado" },
            { variable: "tipoTarea", descripcion: "Tipo de Tarea" },
            { variable: "numPolEndoso", descripcion: "Póliza" },
            { variable: "ramoSeguro", descripcion: "Ramo Seguro" },
            { variable: "objetivo", descripcion: "Objetivo" },
            { variable: "fechaAlta", descripcion: "Fecha de Alta" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "avance", descripcion: "Avance" },
            { variable: "fechaInicio", descripcion: "Fecha Inicio" },
            { variable: "fechaFinal", descripcion: "Fecha Final" },
            { variable: "fechaInicioReal", descripcion: "Fecha Inicio Real" },
            { variable: "fechaFinalReal", descripcion: "Fecha Final Real" },
            { variable: "folioPoliza", descripcion: "Folio de Poliza" },
            { variable: "vigenciaIniPoliza", descripcion: "Vigencia Inicio Poliza" },
            { variable: "vigenciaHastaPoliza", descripcion: "Vigencia Hasta Poliza" },
            { variable: "filtroMes", descripcion: "filtroMes", hide: true }
        ],
        responsiveTitle: ["numero"],
        filter: [
            { campo: "numero" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [
            {
                columnas: ["fechaAlta", "fechaModificacion", "fechaInicio","fechaFinal","fechaInicioReal","fechaFinalReal","vigenciaIniPoliza","vigenciaHastaPoliza"], tipo: "fecha2", onlyDate: true

            },
            {
                columnas: ["avance"], tipo: "custom", callback: (valor) =>{
                    return `${redondeo( parseFloat(toFixed((valor * 100),2)),2 )}%`;
                }
            }],
            selectFilter: [
                {
                    campo: "empresaID", title: "Empresa",
                    retorna: "number", limpiarFiltro: "Todos",
                    data: seleccionables.Empresa ? seleccionables.Empresa : [], grid: { md: 3, lg: 3 }
                },
                {
                    title: "Mes",
                    campo: "filtroMes", placeholder: "Filtrar por mes",
                    limpiarFiltro: "Todos",
                    retorna: "number",
                    data: listMeses,
                    grid: { md: 3, lg: 3 },
                },
                {
                    title: "Ramo de Seguro",
                    campo: "ramoSeguroID", placeholder: "Filtrar por Ramo",
                    limpiarFiltro: "Todos",
                    retorna: "number",
                    data: seleccionables.ramosSeguro || [],
                    grid: { md: 3, lg: 3 },
                },
            ]
    }

    const infoTablaActividades = {
        botones: botonesActividades,
        acciones: accionesActividades,
        loader,
        actualizar: actualizarActividades,
        id: "actividadID",
        color: primary,
        title: ` Actividades de Tareas ${stateDescripcionTarea}`,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "actividadID", descripcion: "actividadID", hide: true },
            { variable: "tareaID", descripcion: "tareaID", hide: true },
            { variable: "usuarioID", descripcion: "usuarioID", hide: true },
            { variable: "usuario", descripcion: "Usuario" },
            { variable: "responsable", descripcion: "Responsable" },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "porcentaje", descripcion: "Porcentaje" },
            { variable: "estado", descripcion: "Estado" },
            { variable: "avance", descripcion: "Avance" },
            { variable: "fechaInicio", descripcion: "Fecha Inicio" },
            { variable: "fechaFinal", descripcion: "Fecha Final" },
            { variable: "fechaInicioReal", descripcion: "Fecha Inicio Real" },
            { variable: "fechaFinalReal", descripcion: "Fecha Final Real" },
        ],
        responsiveTitle: ["numero"],
        filter: [
            { campo: "numero" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [
            {
                columnas: ["fechaInicio", "fechaFinal", "fechaInicioReal", "fechaFinalReal"], tipo: "fecha"

            },
            {
                columnas: ["porcentaje"], tipo: "porcentaje", simbolo: "%"

            },
            {
                columnas: ["avance"], tipo: "custom", callback: (valor) =>{
                    return `${redondeo( parseFloat(toFixed((valor * 100),2)),2 )}%`;
                }
            }
        ]
    }




    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 6 },
            inputs: [{
                disabled,
                id: "empresaID",
                value: state.empresaID,
                error: errorState.empresaID.error,
                success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                helperText: errorState.empresaID.helperText,
                inputRef: empresaIDRef,
                title: "Empresa *",
                placeholder: "Selecciona la empresa",
                tipo: "select",
                data: seleccionables.Empresa,
                grid: { md: 6, lg: 6 },
                onChange: ({ target: { value } }) => {
                    setState({ ...state, empresaID: value })
                }
                },{
                    disabled,
                    id: "ramoSeguroID",
                    value: state.ramoSeguroID,
                    error: errorState.ramoSeguroID.error,
                    success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
                    helperText: errorState.ramoSeguroID.helperText,
                    inputRef: ramoSeguroIDRef,
                    title: "Ramo de Seguro *",
                    placeholder: "Selecciona el ramo de seguro",
                    tipo: "select",
                    data: seleccionables.ramosSeguro,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, ramoSeguroID: value })
                    }
                },
                {
                    disabled,
                    id: "polizaID",
                    value: state.polizaID,
                    error: errorState.polizaID.error,
                    success: state.polizaID && !errorState.polizaID.error ? true : undefined,
                    helperText: errorState.polizaID.helperText,
                    inputRef: polizaIDRef,
                    title: "Póliza *",
                    placeholder: "Selecciona la póliza",
                    tipo: "select",
                    data: seleccionables.Poliza,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, polizaID: value })
                    }
                },

                {
                    disabled,
                    id: "usuarioID",
                    value: state.usuarioID,
                    error: errorState.usuarioID.error,
                    success: state.usuarioID && !errorState.usuarioID.error ? true : undefined,
                    helperText: errorState.usuarioID.helperText,
                    inputRef: usuarioIDRef,
                    title: "Usuario *",
                    placeholder: "Selecciona el usuario",
                    tipo: "select",
                    data: seleccionables.Usuarios,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, usuarioID: value })
                    }
                }
            ]
        }, {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 6 },
            inputs: [
                {
                    disabled: true,
                    id: "fechaAlta",
                    value: state.fechaAlta,
                    error: errorState.fechaAlta.error,
                    success: state.fechaAlta && !errorState.fechaAlta.error ? true : undefined,
                    helperText: errorState.fechaAlta.helperText,
                    inputRef: fechaAltaRef,
                    placeholder: "Fecha de Alta ",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaAlta")
                },
                {
                    disabled:true,
                    id: "fechaModificacion",
                    value: state.fechaModificacion,
                    error: errorState.fechaModificacion.error,
                    success: state.fechaModificacion && !errorState.fechaModificacion.error ? true : undefined,
                    helperText: errorState.fechaModificacion.helperText,
                    inputRef: fechaModificacionRef,
                    placeholder: "Fecha Modificación ",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaModificacion")
                },
                {
                    disabled,
                    id: "fechaInicio",
                    value: state.fechaInicio,
                    error: errorState.fechaInicio.error,
                    success: state.fechaInicio && !errorState.fechaInicio.error ? true : undefined,
                    helperText: errorState.fechaInicio.helperText,
                    inputRef: fechaInicioRef,
                    placeholder: "Fecha de Inicio *",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaInicio")
                },
                {
                    disabled,
                    id: "fechaFinal",
                    value: state.fechaFinal,
                    error: errorState.fechaFinal.error,
                    success: state.fechaFinal && !errorState.fechaFinal.error ? true : undefined,
                    helperText: errorState.fechaFinal.helperText,
                    inputRef: fechaFinalRef,
                    placeholder: "Fecha Final *",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaFinal")
                }, ,
                {
                    disabled,
                    id: "fechaInicioReal",
                    hidden: true,
                    value: state.fechaInicioReal,
                    error: errorState.fechaInicioReal.error,
                    success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
                    helperText: errorState.fechaInicioReal.helperText,
                    inputRef: fechaInicioRealRef,
                    placeholder: "Fecha Inicio Real *",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaInicioReal")
                },
                {
                    disabled,
                    id: "fechaFinalReal",
                    hidden: true,
                    value: state.fechaFinalReal,
                    error: errorState.fechaFinalReal.error,
                    success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
                    helperText: errorState.fechaFinalReal.helperText,
                    inputRef: fechaFinalRealRef,
                    placeholder: "Fecha Final Real *",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaFinalReal")
                },
            ]
        }, {
            id: "abaj0",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: state.estado === 'TERMINADA',
                    id: "estado",
                    value: state.estado,
                    error: errorState.estado.error,
                    success: state.estado && !errorState.estado.error ? true : undefined,
                    helperText: errorState.estado.helperText,
                    inputRef: estadoRef,
                    title: "Estado *",
                    tipo: "select",
                    grid: { md: 4, lg: 4 },
                    data: [
                        {
                            label: 'ACTIVA',
                            value: 'ACTIVA'
                        },
                        {
                            label: 'EN PROCESO',
                            value: 'EN PROCESO'
                        },
                        {
                            label: 'TERMINADA',
                            value: 'TERMINADA',
                            disabled: true
                        },
                    ],
                    onChange: ({ target: { value } }) => handleChange(value, "estado"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled:true,
                    id: "numero",
                    value: state.numero,
                    error: errorState.numero.error,
                    success: state.numero && !errorState.numero.error ? true : undefined,
                    helperText: errorState.numero.helperText,
                    inputRef: numeroRef,
                    title: "Numero ",
                    placeholder: "Capture el numero",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt10.test(value)) &&
                            handleChange(value, "numero")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, numero: { error: state.numero.length > 10 && true, helperText: state.numero.length > 10 ? "Límite de 10 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, tipoTareaRef)

                },

                {
                    disabled,
                    id: "tipoTarea",
                    value: state.tipoTarea,
                    error: errorState.tipoTarea.error,
                    success: state.tipoTarea && !errorState.tipoTarea.error ? true : undefined,
                    helperText: errorState.tipoTarea.helperText,
                    inputRef: tipoTareaRef,
                    title: "Tipo de Tarea *",
                    tipo: "select",
                    grid: { md: 4, lg: 4 },
                    data: aTiposTarea,
                    onChange: ({ target: { value } }) => handleChange(value, "tipoTarea"),
                    onKeyDown: () => { return; }
                },
                // {
                //     disabled,
                //     id: "extraordinaria",
                //     value: state.extraordinaria,
                //     checked: state.extraordinaria,
                //     title: "Tarea Extraordinaria",
                //     tipo: "switch",
                //     grid: { md: 3, lg: 3 },
                //     onChange: (e) => {
                //         setState({ ...state, extraordinaria: e.target.checked })
                //     }
                // },
                /*{
                    disabled,
                    id: "tipoTarea",
                    value: state.tipoTarea,
                    error: errorState.tipoTarea.error,
                    success: state.tipoTarea && !errorState.tipoTarea.error ? true : undefined,
                    helperText: errorState.tipoTarea.helperText,
                    inputRef: tipoTareaRef,
                    title: "Tipo de Tarea *",
                    placeholder: "Capture el tipo de tarea",
                    grid: { md: 8, lg: 8 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto30.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "tipoTarea")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, tipoTarea: { error: state.tipoTarea.length > 30 && true, helperText: state.tipoTarea.length > 30 ? "Límite de 30 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, objetivoRef)

                },*/
                {
                    disabled,
                    id: "objetivo",
                    value: state.objetivo,
                    error: errorState.objetivo.error,
                    success: state.objetivo && !errorState.objetivo.error ? true : undefined,
                    helperText: errorState.objetivo.helperText,
                    inputRef: objetivoRef,
                    title: "Objetivo *",
                    placeholder: "Capture el objetivo",
                    grid: { md: 9, lg: 9 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "objetivo")
                    },
                    onKeyDown: () => { return; }

                },
                {
                    disabled:true,
                    id: "avance",
                    value: state.avance,
                    error: errorState.avance.error,
                    success: state.avance && !errorState.avance.error ? true : undefined,
                    helperText: errorState.avance.helperText,
                    inputRef: avanceRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Avance ",
                    grid: { md: 3, lg: 3 },
                    placeholder: "Capture el avance",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChange(value, "avance")
                    },
                    onKeyDown: () => { return; }
                }
            ]}
    ];



    const inputsActividades = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "usuarioID",
                    value: stateActividades.usuarioID,
                    error: errorStateActividades.usuarioID.error,
                    success: stateActividades.usuarioID && !errorStateActividades.usuarioID.error ? true : undefined,
                    helperText: errorStateActividades.usuarioID.helperText,
                    inputRef: usuarioIDRef,
                    title: "Usuario *",
                    placeholder: "Selecciona el usuario",
                    tipo: "select",
                    data: seleccionables.Usuarios,
                    grid: { md: 6, lg: 6},
                    onChange: ({ target: { value } }) => {
                        setStateActividades({ ...stateActividades, usuarioID: value })
                    }
                },

                {
                    disabled,
                    id: "responsable",
                    value: stateActividades.responsableID,
                    error: errorStateActividades.responsableID.error,
                    success: stateActividades.responsableID && !errorStateActividades.responsableID.error ? true : undefined,
                    helperText: errorStateActividades.responsableID.helperText,
                    inputRef: responsableRef,
                    tipo: "select",
                    data: seleccionables.Usuarios,
                    title: "Responsable *",
                    placeholder: "Capture el responsable",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setStateActividades({ ...stateActividades, responsableID: value })
                    },
                    onKeyDown: () => { return; }

                },{
                    disabled,
                    id: "descripcion",
                    value: stateActividades.descripcion,
                    error: errorStateActividades.descripcion.error,
                    success: stateActividades.descripcion && !errorStateActividades.descripcion.error ? true : undefined,
                    helperText: errorStateActividades.descripcion.helperText,
                    inputRef: descripcionRef,
                    multiline:true,
                    title: "Descripcion *",
                    placeholder: "Capture la descripcion",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChangeActividades(value.charAt(0).toUpperCase() + value.slice(1), "descripcion")
                    },
                    onKeyDown: () => { return; }

                },
            ]
        }, {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaInicio",
                    value: stateActividades.fechaInicio ,
                    error: errorStateActividades.fechaInicio.error,
                    success: stateActividades.fechaInicio && !errorStateActividades.fechaInicio.error ? true : undefined,
                    helperText: errorStateActividades.fechaInicio.helperText,
                    inputRef: fechaInicioRef,
                    placeholder: "Fecha de Inicio *",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                  //  onlyDate: true,
                    onChange: (value) => handleChangeActividades(value, "fechaInicio")
                },
                {
                    disabled,
                    id: "fechaFinal",
                    value: stateActividades.fechaFinal,
                    error: errorStateActividades.fechaFinal.error,
                    success: stateActividades.fechaFinal && !errorStateActividades.fechaFinal.error ? true : undefined,
                    helperText: errorStateActividades.fechaFinal.helperText,
                    inputRef: fechaFinalRef,
                    placeholder: "Fecha Final *",
                    tipo: "datetimepicker",
                    grid: { md: 6, lg: 6 },
                   // onlyDate: true,
                    onChange: (value) => handleChangeActividades(value, "fechaFinal")
                }, ,
                {
                    disabled:true,
                    id: "fechaInicioReal",
                    hidden: true,
                    value: stateActividades.fechaInicioReal,
                    error: errorStateActividades.fechaInicioReal.error,
                    success: stateActividades.fechaInicioReal && !errorStateActividades.fechaInicioReal.error ? true : undefined,
                    helperText: errorStateActividades.fechaInicioReal.helperText,
                    inputRef: fechaInicioRealRef,
                    placeholder: "Fecha Inicio Real *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    onlyDate: true,
                    onChange: (value) => handleChangeActividades(value, "fechaInicioReal")
                },
                {
                    disabled:true,
                    id: "fechaFinalReal",
                    hidden: true,
                    value: stateActividades.fechaFinalReal,
                    error: errorStateActividades.fechaFinalReal.error,
                    success: stateActividades.fechaFinalReal && !errorStateActividades.fechaFinalReal.error ? true : undefined,
                    helperText: errorStateActividades.fechaFinalReal.helperText,
                    inputRef: fechaFinalRealRef,
                    placeholder: "Fecha Final Real *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    onlyDate: true,
                    onChange: (value) => handleChangeActividades(value, "fechaFinalReal")
                },
            ]
        }, {
            id: "abaj0",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: stateActividades.estado === 'TERMINADA',
                    id: "estado",
                    value: stateActividades.estado,
                    error: errorStateActividades.estado.error,
                    success: stateActividades.estado && !errorStateActividades.estado.error ? true : undefined,
                    helperText: errorStateActividades.estado.helperText,
                    inputRef: estadoRef,
                    title: "Estado *",
                    tipo: "select",
                    grid: { md: 4, lg: 4 },
                    data: [
                        {
                            label: 'ACTIVA',
                            value: 'ACTIVA'
                        },
                        {
                            label: 'EN PROCESO',
                            value: 'EN PROCESO'
                        },
                        {
                            label: 'TERMINADA',
                            value: 'TERMINADA',
                            disabled: true
                        },
                    ],
                    onChange: ({ target: { value } }) => handleChangeActividades(value, "estado"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled:true,
                    id: "avance",
                    value: stateActividades.avance,
                    error: errorStateActividades.avance.error,
                    success: stateActividades.avance && !errorStateActividades.avance.error ? true : undefined,
                    helperText: errorStateActividades.avance.helperText,
                    inputRef: avanceRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Avance ",
                    grid: { md: 4, lg: 4 },
                    placeholder: "Capture el avance",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChangeActividades(value, "avance")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled:true,
                    id: "porcentaje",
                    value: stateActividades.porcentaje,
                    error: errorStateActividades.porcentaje.error,
                    success: stateActividades.porcentaje && !errorStateActividades.porcentaje.error ? true : undefined,
                    helperText: errorStateActividades.porcentaje.helperText,
                    inputRef: porcentajeRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Porcentaje *",
                    grid: { md: 4, lg: 4 },
                    placeholder: "Capture el porcentaje",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChangeActividades(value, "porcentaje")
                    },
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];




    const accionesFormulario = [{
        loader,
        disabled: parseInt(autorizado) === 1 ? true : false,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: numeroRef,
            mensajeCorrecto: `Se ${state.tareaID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const accionesFormularioActividades = [{
        loader,
        disabled: parseInt(autorizado) === 1 ? true : false,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarActividades,
            actualizar: actualizarActividades,
            notificaciones: setNotificaciones,
            handleClose: handleCloseActividades,
            handleFocus,
            mensajeCorrecto: `La actividad se ${stateActividades.actividadID ? "actualizó" : "agregó"} correctamente.`,
            asyncCallback: ftGuardarActividades,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseActividades
    }];


    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftErrorInputTextActividades({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState: cleanErrorStateActividades, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorStateActividades
        });
    }

    function handleValidaciones({ empresaID, polizaID, ramoSeguroID, usuarioID,fechaAlta,fechaModificacion,fechaInicio,fechaFinal,fechaInicioReal,fechaFinalReal, numero, tipoTarea, objetivo }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(empresaID),
                ref: empresaIDRef, keyError: "empresaID",
                mensajeError: "Seleccione una empresa"
            },
            /*{
                condicion: !rgxNumeros.test(polizaID),
                ref: polizaIDRef, keyError: "polizaID",
                mensajeError: "Seleccione una póliza"
            },*/
           {
                condicion: !rgxNumeros.test(ramoSeguroID),
                ref: ramoSeguroIDRef, keyError: "ramoSeguroID",
                mensajeError: "Seleccione un ramo de seguro"
            },
            {
                condicion: !rgxNumeros.test(usuarioID),
                ref: usuarioIDRef, keyError: "usuarioID",
                mensajeError: "Seleccione un usuario"
            },
            /*{
                condicion: !moment(fechaModificacion).isValid(),
                ref: fechaModificacionRef, keyError: "fechaModificacion",
                mensajeError: "La fecha no tiene un formato válido"
            },*/
            {
                condicion: !moment(fechaInicio).isValid(),
                ref: fechaInicioRef, keyError: "fechaInicio",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(fechaFinal).isValid(),
                ref: fechaFinalRef, keyError: "fechaFinal",
                mensajeError: "La fecha no tiene un formato válido"
            },
            /*{
                condicion: !numero || numero === "",
                ref: numeroRef, keyError: "numero",
                mensajeError: "Debe especificar la observacion"
            },*/
            {
                condicion: !tipoTarea || trim(tipoTarea) === "",
                  keyError: "tipoTarea",
                mensajeError: "Debe especificar el tipo de tarea"
            },
            {
                condicion: !objetivo || trim(objetivo) === "",
                ref: objetivoRef, keyError: "objetivo",
                mensajeError: "Debe especificar el objetivo"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function handleValidacionesActividades({ usuarioID, fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal, descripcion, responsableID }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(usuarioID),
                ref: usuarioIDRef, keyError: "usuarioID",
                mensajeError: "Seleccione un usuario"
            },
            {
                condicion: !descripcion || trim(descripcion) === "",
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Debe especificar la descripcion"
            },
            {
                condicion: !responsableID  ,
                keyError: "responsable",
                mensajeError: "Debe especificar el responsable"
            },
            {
                condicion: !moment(fechaInicio).isValid(),
                ref: fechaInicioRef, keyError: "fechaInicio",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(fechaFinal).isValid(),
                ref: fechaFinalRef, keyError: "fechaFinal",
                mensajeError: "La fecha no tiene un formato válido"
            }/*,
             {
                condicion: !moment(fechaInicioReal).isValid(),
                ref: fechaInicioRealRef, keyError: "fechaInicioReal",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(fechaFinalReal).isValid(),
                ref: fechaFinalRealRef, keyError: "fechaFinalReal",
                mensajeError: "La fecha no tiene un formato válido"
            },*/
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputTextActividades({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let {
                tareaID,
                empresaID,
                polizaID,
                ramoSeguroID,
                usuarioID,
                numero,
                tipoTarea,
                objetivo,
                fechaAlta,
                fechaModificacion,
                estado,
                avance,
                fechaInicio,
                fechaFinal,
                fechaInicioReal,
                fechaFinalReal,
                extraordinaria
             } = state;

            let error = await handleValidaciones({
                empresaID, polizaID, ramoSeguroID, usuarioID, fechaAlta, fechaModificacion, fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal, numero, tipoTarea, objetivo
            });

            if (error) return error;

            if (tareaID) await updateTareasProgAnual({
                input: {
                    tareaID,
                    programaAnualID: Number(programaAnualID),
                    empresaID: Number(empresaID),
                    polizaID: Number(polizaID),
                    ramoSeguroID: Number(ramoSeguroID),
                    usuarioID: Number(usuarioID),
                    numero: Number(numero),
                    tipoTarea: trim(tipoTarea),
                    objetivo: trim(objetivo),
                    fechaAlta: fechaAlta.format(formato),
                    fechaModificacion: null /*fechaModificacion.format(formato)*/,
                    estado: trim(estado),
                    avance: parseFloat(avance/100),
                    fechaInicio/* : moment(fechaInicio['_d']).format('YYYY-MM-DDTHH:mm:ss') */: fechaInicio.format(formato),
                    fechaFinal/* :moment(fechaFinal['_d']).format('YYYY-MM-DDTHH:mm:ss') */: fechaFinal.format(formato),
                    fechaInicioReal,
                    fechaFinalReal,
                    extraordinaria
                }
            }, token);
            else await addTareasProgAnual({
                input: {
                    programaAnualID: Number(programaAnualID),
                    empresaID: Number(empresaID),
                    polizaID: Number(polizaID),
                    ramoSeguroID: Number(ramoSeguroID),
                    usuarioID: Number(usuarioID),
                    numero: Number(numero),
                    tipoTarea: trim(tipoTarea),
                    objetivo: trim(objetivo),
                    fechaAlta: now.format(formato),
                    fechaModificacion: null/*fechaModificacion.format(formato)*/,
                    estado: trim(estado),
                    avance: parseFloat(avance),
                    fechaInicio: fechaInicio.format(formato),
                    fechaFinal: fechaFinal.format(formato),
                    fechaInicioReal,
                    fechaFinalReal,
                    extraordinaria
                }
            }, token);

        }
        return ftGuardar();
    }


    function ftGuardarActividades() {
        async function ftGuardarActividades() {

            let {
                actividadID,

                usuarioID,
                descripcion,
                responsableID,
                porcentaje,
                estado,
                avance,
                fechaInicio,
                fechaFinal,
                fechaInicioReal,
                fechaFinalReal } = stateActividades;
               
             let error = await handleValidacionesActividades({
                usuarioID, fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal, descripcion, responsableID
            });

            if (error) return error;

            if (actividadID) await updateActividadesDeTareas({
                input: {
                    actividadID: Number(actividadID),
                    tareaID: Number(stateTareaID),
                    usuarioID: Number(usuarioID),
                    descripcion: trim(descripcion),
                    responsableID: Number(responsableID),
                    porcentaje: parseFloat(porcentaje),
                    estado: trim(estado),
                    avance: parseFloat(avance/100),
                    fechaInicio:moment(fechaInicio['_d']).format('YYYY-MM-DDTHH:mm:ss')/*: fechaInicio.format(formato)*/,
                    fechaFinal:moment(fechaFinal['_d']).format('YYYY-MM-DDTHH:mm:ss')/*: fechaFinal.format(formato)*/,
                    fechaInicioReal: null/*fechaInicioReal.format(formato)*/,
                    fechaFinalReal: null/*fechaFinalReal.format(formato)*/
                }
            }, token);
            else await addActividadesDeTareas({
                input: {
                    tareaID:Number(stateTareaID),
                    usuarioID: Number(usuarioID),
                    descripcion: trim(descripcion),
                    responsableID: Number(responsableID),
                    porcentaje: parseFloat(porcentaje),
                    estado: trim(estado),
                    avance: avance /*parseFloat(avance)*/,
                    fechaInicio:moment(fechaInicio['_d']).format('YYYY-MM-DDTHH:mm:ss')/*: fechaInicio.format(formato)*/,
                    fechaFinal:moment(fechaFinal['_d']).format('YYYY-MM-DDTHH:mm:ss')/*: fechaFinal.format(formato)*/,
                    fechaInicioReal: null/* fechaInicioReal.format(formato)*/ ,
                    fechaFinalReal: null/* fechaFinalReal.format(formato)*/
                }
            }, token);
            setActualizar(!actualizar);
        }
        return ftGuardarActividades();
    }


    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseActividades() {
        setOpenActividades(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateActividades({ ...cleanStateActividades });
        setErrorStateActividades({ ...cleanErrorStateActividades });
    }

    function handleCloseTablaActividades() {
        setOpenVerActividades(false);
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleChangeActividades(value, key) { setStateActividades({ ...stateActividades, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {
                let Poliza = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== null && state.empresaID !== "" ? parseInt(state.empresaID) : -1, ramoSeguroID: state.ramoSeguroID !== null && state.ramoSeguroID !== "" ? parseInt(state.ramoSeguroID) : -1, tipoPoliza:"PRINCIPAL"}}, token);
                let Ramo = await seleccionRamoSeguroNA({ filtro: {} }, token);
                let Empresa = await seleccionEmpresa({}, token);
                let Usuarios = await seleccionUsuario({}, token);
                let ramosSeguro = await seleccionRamoSeguro({}, token);
                setSeleccionables({ Empresa, Poliza, Ramo, Usuarios,ramosSeguro });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, state.empresaID,state.ramoSeguroID]);

   
    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaTareasProgAnual}
            token={token}
            parametrosFijos={{ programaAnualID: Number(programaAnualID) }}
        />
        {
            openVerActividades &&
            <Modal
                open={openVerActividades}
                onClose={handleCloseTablaActividades}
            >
                <Tabla
                    infoTabla={infoTablaActividades}
                    asyncData={tablaActividadesDeTareas}
                    token={token}
        parametrosFijos={{ tareaID: parseInt(stateTareaID) /*,usuarioID:parseInt(stateUsuarioID) */} }
                />

                <FormularioModal
                    open={openActividades}
                    title={titleModalActividades}
                    onClose={handleCloseActividades}
                    notificaciones={notificaciones}
                    closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                    inputs={inputsActividades}
                    acciones={accionesFormularioActividades}
                    focus={descripcionRef}
                />
            </Modal>
        }
        {
            openSeguimientos && <Modal
                open={openSeguimientos}
                onClose={() => setOpenSeguimientos(false)}
            >
                <SeguimientoActividades datos={{ actividadID: tareaID }} actualizarActividades={setActualizarActividades} programaAnual={true} />
            </Modal>
        }
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={polizaIDRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default TareasProgAnual;
