import React, { useState } from 'react';

function SeccionVIII({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      A: { respuesta: 0, explicacion: '' },
      B: { respuesta: 0, explicacion: '' },
      C: { respuesta: 0, explicacion: '' },
      D: { respuesta: 0, explicacion: '' }
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(inciso, key, value) {
    if (disabled) {
      return;
    }
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[inciso][key] = value;

    if (respuestaAux[inciso]["respuesta"] === 0) {
      respuestaAux[inciso]["explicacion"] = '';
    }
    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
  }

  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          VIII. Operadores de Terminal
        </div>
        <br />
        <div className="ElementoPregunta">
          <div className="Pregunta">
            A) ¿Realiza el solicitante algún tipo de mezcla, envasado o reformulación de productos?
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("A", "respuesta", (stRespuestasLocal["A"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["A"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["A"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("A", "respuesta", (stRespuestasLocal["A"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["A"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["A"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["A"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor explique en que consiste:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["A"].explicacion} onChange={event => handleChangeValue("A", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            B) ¿Realiza el solicitante alguna otra actividad además de la manipulación y el almacenamiento de carga?
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("B", "respuesta", (stRespuestasLocal["B"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("B", "respuesta", (stRespuestasLocal["B"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["B"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor explique en que consiste:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["B"].explicacion} onChange={event => handleChangeValue("B", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            C) ¿Posee el solicitante o es legalmente responsable por camiones, vagones de carga, o algún otro tipo vehículo utilizado en sus operaciones?
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["C"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor aclare en detalle:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["C"].explicacion} onChange={event => handleChangeValue("C", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            D) ¿Realiza el solicitante algún tipo de operaciones fuera del espacio físico de la terminal?
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("D", "respuesta", (stRespuestasLocal["D"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["D"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["D"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => handleChangeValue("D", "respuesta", (stRespuestasLocal["D"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["D"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["D"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["D"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor explique en que consisten:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["D"].explicacion} onChange={event => handleChangeValue("D", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
      </div>
    </div>
  </>);
}

export default SeccionVIII;