import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from "react-redux";
import { NetworkError } from "../../../_layout/genericComponents/Metodos/";
import { useHistory } from "react-router-dom";

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { RiFileExcel2Fill } from "react-icons/ri";
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, warning } from '../../../_layout/helpers/colores';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import moment from 'moment';

import { rgxNumeros, rgxInputTexto20, rgxInputTexto30, rgxInputTexto50 } from '../../../helpers/regexp';

import { seleccionableSubPolizasSeguro } from '../../../querys/PolizasSeguro';
import { seleccionActivosAsegurado } from '../PolizasSeguro/ConsultasActivos';

import { getSiniestrosReclaAseg, tablaSiniestrosReclaAseg } from '../../../querys/SiniestrosReclaAseg';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionPolizasSeguro } from '../../../querys/PolizasSeguro';
import { addSiniestrosReclaAseg, updateSiniestrosReclaAseg, deleteSiniestrosReclaAseg, aplicarPagoReclamoAseguradora } from '../../../mutations/SiniestrosReclaAseg/';
import { getAllHistorialParidades } from '../../../querys/HistorialParidades';
import { seleccionEmpresa } from 'querys/Empresas';
import { urlServidor } from '../../../helpers';
import { seleccionAseguradora } from 'querys/Aseguradoras';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import TrazabilidadSiniestros from '../TrazabilidadSiniestros';
import SeguimientoActividades from '../SeguimientoActividades';
import DescriptionIcon from '@material-ui/icons/Description';
import ReclamacionATerceros from '../Reclamaciones/ReclamacionATerceros'
import { tablaControlPagosReporteDanios } from 'querys/ControlPago';
import UpsertControlPago from '../ControlPagoReporteDanios/Upsert';


const { trim } = Validaciones;

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

function SiniestrosReclaAseg(props) {

    let reclamoPrincipalID = null;
    let tipoReclamo = null;
    let folio = '';

    if (props.datos) {
        reclamoPrincipalID = props.datos.reclamoPrincipalID;
        tipoReclamo = props.datos.tipoReclamo;
        folio = props.datos.folioPrincipal;
    }

    let now = moment();
    let formato = "YYYY-MM-DD";
    let anioActual = new Date().getFullYear();
    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";

    const history = useHistory();
    let [datosPDF, setdatosPDf] = useState({});

    const { token } = useSelector(state => state.login);
    const [openSeguimientos, setOpenSeguimientos] = useState(false);
    const [stReporteDaniosID, setReporteDaniosID] = useState(null);
    const [stSiniesReclAsegID, setSiniesReclAsegID] = useState(null);
    const [verHistorial, setVerHistorial] = useState(false);
    const [folioPrincipal, setFolioPrincipal] = useState('');

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    const cleanState = {
        siniesReclAsegID: null,
        empresaID: "",
        estatus: "ABIERTO",
        fechaAfectado: now,
        siniestro: "",
        aseguradoraID: "",
        numPoliza: "",
        deducible: "",
        equipoBienAfectado: "",
        descripEvento: "",
        maquinaria: "",
        operador: "",
        ajustador: "",
        fechaReclamacion: "",
        indemnizaPagada: "",
        fechaPago: "",
        ramoSeguroID: "0",
        polizaID: "0",
        observaciones: "",
        divisaID: 69,
        paridad: 1,
        subPolizaID: "0",
        activoID: "0",
    };

    const cleanErrorState = {
        empresaID: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        fechaAfectado: { error: false, helperText: "" },
        siniestro: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        numPoliza: { error: false, helperText: "" },
        deducible: { error: false, helperText: "" },
        equipoBienAfectado: { error: false, helperText: "" },
        descripEvento: { error: false, helperText: "" },
        maquinaria: { error: false, helperText: "" },
        operador: { error: false, helperText: "" },
        ajustador: { error: false, helperText: "" },
        fechaReclamacion: { error: false, helperText: "" },
        indemnizaPagada: { error: false, helperText: "" },
        fechaPago: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        polizaID: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        subPolizaID: { error: false, helperText: "" },
        activoID: { error: false, helperText: "" },
    };

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [disabledSubPol, setDisabledSubPol] = useState(false);
    const [disabledActivoID, setDisabledActivoID] = useState(false);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(null);
    const [openEstado, setOpenEstado] = useState(false);
    const [openReclamoAseguradora, setOpenReclamosAseguradora] = useState(false);
    const [openReclamosTerceros, setOpenReclamosTerceros] = useState(false);
    const [openControlPago, setOpenControlPago] = useState(false);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    const [openTrazabilidad, setOpenTrazabilidad] = useState(false);
    const [datosDetalle, setDatosDetalle] = useState({})

    let titleAccion = state.siniesReclAsegID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Reclamos a Aseguradoras`;

    const empresaIDRef = useRef(null);
    const estatusRef = useRef(null);
    const fechaAfectadoRef = useRef(null);
    const siniestroRef = useRef(null);
    const aseguradoraIDRef = useRef(null);
    const deducibleRef = useRef(null);
    const equipoBienAfectadoRef = useRef(null);
    const descripEventoRef = useRef(null);
    const maquinariaRef = useRef(null);
    const operadorRef = useRef(null);
    const ajustadorRef = useRef(null);
    const fechaReclamacionRef = useRef(null);
    const indemnizaPagadaRef = useRef(null);
    const fechaPagoRef = useRef(null);
    const ramoSeguroIDRef = useRef(null);
    const polizaIDRef = useRef(null);
    const subPolizaIDRef = useRef(null);
    const observacionesRef = useRef(null);
    const paridadRef = useRef(null);

    let accionesAdicionales = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSiniestrosReclaAseg,
                formatearResultado: [{ id: "fechaAfectado", formato: (data) => data ? moment(data) : '' }, { id: "fechaReclamacion", formato: (data) => data ? moment(data) : '' }, { id: "fechaPago", formato: (data) => data ? moment(data) : '' }]
            })
        },
        {
            accionID: [8],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Estado",
            parametros: [{ campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen: setOpenEstado, setAlert,
                asyncGet: getSiniestrosReclaAseg,
                formatearResultado: [
                    { id: "fechaAfectado", formato: (data) =>  data ? moment(data) : null}
                    , 
                    { id: "fechaReclamacion", formato: (data) =>  data ? moment(data) : null}
                    , 
                    { id: "fechaPago", formato: (data) =>  data ? moment(data): null 
                }]
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "empresaID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, empresaID, folioInterno }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro de ${folioInterno}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { siniesReclAsegID }, asyncDelete: deleteSiniestrosReclaAseg
                })
            })
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, folioInterno }) => {
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: siniesReclAsegID,
                    pTipo: folioInterno,
                    pClasificacion: "",
                    pTabla: "Reclamaciones Aseguradoras",
                    pTitulo2: `Reclamaciones Aseguradoras folio: ${folioInterno}`,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }, {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Descargar PDF",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, folioInterno }) => AuxDescargar(siniesReclAsegID, folioInterno)
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, folioInterno }) => AuxDescargar(siniesReclAsegID, folioInterno, 'excel')
        },


    ];

    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSiniestrosReclaAseg,
                formatearResultado: [{ id: "fechaAfectado", formato: (data) => data ? moment(data) : '' }, { id: "fechaReclamacion", formato: (data) => data ? moment(data) : '' }, { id: "fechaPago", formato: (data) => data ? moment(data) : '' }]
            })
        },
        {
            accionID: [8],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Estado",
            parametros: [{ campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen: setOpenEstado, setAlert,
                asyncGet: getSiniestrosReclaAseg,
                formatearResultado: [{ id: "fechaAfectado", formato: (data) => moment(data) }, { id: "fechaReclamacion", formato: (data) => moment(data) }, { id: "fechaPago", formato: (data) => moment(data) }]
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "siniesReclAsegID" },
            { campo: "empresaID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, empresaID, folioInterno }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro de ${folioInterno}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { siniesReclAsegID }, asyncDelete: deleteSiniestrosReclaAseg
                })
            })
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, folioInterno }) => {
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: siniesReclAsegID,
                    pTipo: folioInterno,
                    pClasificacion: "",
                    pTabla: "Reclamaciones Aseguradoras",
                    pTitulo2: `Reclamaciones Aseguradoras folio: ${folioInterno}`,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }, {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Descargar PDF",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, folioInterno }) => AuxDescargar(siniesReclAsegID, folioInterno)
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "siniesReclAsegID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclAsegID, folioInterno }) => AuxDescargar(siniesReclAsegID, folioInterno, 'excel')
        },
        /* {
            icono: AccountBalanceWalletIcon,
            color: success,
            descripcion: "Generar control de pagos",
            parametros: [{ campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => AplicarPago(parametros)
        }, */
        {
            accionID: [13],
            id: "verTrazabilidad",
            icono: TrendingUpIcon,
            color: success,
            descripcion: "Ver Trazabilidad",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "siniesReclAsegID" }, { campo: 'controlPagoID' }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclAsegID, controlPagoID }) => {
                setDatosDetalle({
                    pReporteDaniosID: reporteDaniosID,
                    pPolizasSeguroID: null,
                    pControlPagoID: controlPagoID,
                    plUtilizaProps: true,
                    pSiniesReclAsegID: siniesReclAsegID,
                    pSiniesReclTercerosID: null
                });
                setOpenTrazabilidad(true);
            }
        },
        {
            accionID: [10],
            icono: DescriptionIcon,
            color: primary,
            descripcion: "Seguimientos",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclAsegID }) => { setReporteDaniosID(reporteDaniosID); setSiniesReclAsegID(siniesReclAsegID); setVerHistorial(false); setOpenSeguimientos(true); }
        },
        {
            accionID: [11],
            id: "verHistorialSeguimientos",
            icono: DescriptionIcon,
            color: success,
            descripcion: "Historial de Seguimientos",
            parametros: [{ campo: "reporteDaniosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclAsegID }) => { setReporteDaniosID(reporteDaniosID); setSiniesReclAsegID(siniesReclAsegID); setOpenSeguimientos(true); setVerHistorial(true) }
        },
        {
            accionID: [9],
            id: "generarControlPago",
            icono: AccountBalanceWalletIcon,
            color: warning,
            descripcion: "Control de pago",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "folioInterno" }, { campo: "estatus" }, { campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno, estatus, siniesReclAsegID }) => { setReporteDaniosID({ repDaniosID: reporteDaniosID, recAsegID: siniesReclAsegID }); VerControldePagos(reporteDaniosID, folioInterno, estatus); }
        },
        {
            accionID: [12],
            id: "Reclamos",
            icono: AnnouncementIcon,
            color: "tumblr",
            descripcion: "Reclamos adicionales a aseguradoras",
            parametros: [{ campo: "folioInterno" }, { campo: "reporteDaniosID" }, { campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclAsegID, folioInterno }) => {
                setReporteDaniosID(reporteDaniosID);
                setSiniesReclAsegID(siniesReclAsegID);
                setOpenReclamosAseguradora(true);
                setFolioPrincipal(folioInterno);

            }
        },
        {
            accionID: [14],
            id: "Reclamos",
            icono: AnnouncementIcon,
            color: "pinterest",
            descripcion: "Reclamos adicionales a terceros",
            parametros: [{ campo: "folioInterno" }, { campo: "reporteDaniosID" }, { campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclAsegID, folioInterno }) => {
                setReporteDaniosID(reporteDaniosID);
                setSiniesReclAsegID(siniesReclAsegID);
                setOpenReclamosTerceros(true);
                setFolioPrincipal(folioInterno);

            }
        },


    ];


    async function VerControldePagos(reporteDaniosID, folioInterno, estatus) {


        if (estatus !== "CERRADO") {
            let result = await tablaControlPagosReporteDanios({ filtro: { pagina: 1, limite: 1000, reporteDaniosID: Number(reporteDaniosID) } }, token)

            let { listado } = result;
            let descripcionMensaje = [];
            if (listado.length > 0) {
                listado.map(pago => {
                    descripcionMensaje.push(<><>Control pago: {pago.folioInterno}, ${pago.importePago}</> <br /></>)
                })
            } else {
                descripcionMensaje = '¿Desea agregar un control de pago?'
            }
            setAlert({
                descripcion: descripcionMensaje,
                title: "Control de pagos " + folioInterno,
                tipo: "warning",
                msjConfirmacion: "Agregar control de pago",
                onConfirm: () => { setAlert(null); setOpenControlPago(true); },
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });


        } else {
            setAlert({
                descripcion: "El seguimiento del reporte es RECLAMADO, no aplica ",
                title: "Control de pagos " + folioInterno,
                tipo: "danger",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }

    }

    async function AplicarPago(parametros) {
        try {
            let result = await aplicarPagoReclamoAseguradora({ siniesReclAsegID: parametros.siniesReclAsegID }, token);
            setAlert({
                descripcion: result.mensaje,
                title: "Generar control de pago",
                tipo: result.Resultado === 1 ? "success-two" : warning,
                msjConfirmacion: "Abrir control de pago",
                onConfirm: () => RedirigirControlPago(result["controlPagoID"]),
                showConfirm: true,
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    function RedirigirControlPago(controlPagoID) {
        setAlert(null);
        let path = '/';
        path = `/gen/ControlPagos`;
        history.push(path, { controlPagoID });
    }

    function AuxDescargar(siniesReclAsegID, folioInterno, formatoReporte = "PDF") {
        setdatosPDf({ "siniesReclAsegID": siniesReclAsegID, "folioInterno": folioInterno, formatoReporte });
        datosPDF = { "siniesReclAsegID": siniesReclAsegID, "folioInterno": folioInterno, formatoReporte };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de reclamos a aseguradoras?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato de impresión del reporte de reclamos a aseguradoras ",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el formato de impresión del reporte de reclamos a aseguradoras ' + folioInterno,
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("siniesReclAsegID", datosPDF.siniesReclAsegID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteReclamosAseguradoras' + datosPDF.folioInterno
        await fetch(`${urlServidor}/service/reportes/ReporteReclamosAseguradoras`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                showFile(result, `${nombreReporte} ${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones: reclamoPrincipalID ? accionesAdicionales : acciones,
        actualizar,
        id: "siniesReclAsegID",
        color: primary,
        title: "Reclamos a Aseguradora " + folio,
        iconTable: <AnnouncementIcon />,
        headers: [
            { variable: "reclamoPincipalID", descripcion: "reclamoPrincipalID", hide: true },
            { variable: "tipoReclamoPrincipal", descripcion: "tipoReclamoPrincipal", hide: true },
            { variable: "siniesReclAsegID", descripcion: "ID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Reclamo", hide: false },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "ramoSeguro", descripcion: "Ramo de Seguro" },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "ultimoSeguimiento", descripcion: "Último Seguimiento" },
            { variable: "equipoBienAfectado", descripcion: "Bien dañado", hide: false },
            { variable: "fechaAfectado", descripcion: "Fecha del Daño" },
            { variable: "fechaReclamacion", descripcion: "Fecha Reclamación" },
            { variable: "reporteDaniosID", descripcion: "reporteDaniosID", hide: true },
            { variable: "fechaPago", descripcion: "Fecha de Pago" },
            { variable: "siniestro", descripcion: "No. Siniestro" },
            { variable: "folioInternoControlPago", descripcion: "Folio Control Pago", hide: false },
            { variable: "folioInternoReporteDanios", descripcion: "Folio Reporte", hide: false },
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true }
        ],
        filter: [
            { campo: "siniestro", variable: "siniestro", placeholder: "Capture el no. de Siniestro a Buscar" }
        ],
        alineacion: [{ columnas: [0, 1, 2, 4, 5], alineacion: "left" },
        { columnas: [3], alineacion: "center" }],
        rangoFechas: { fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        formato: [
            {
                columnas: ["fechaAfectado", "fechaReclamacion", "fechaPago"], tipo: "fecha", onlyDate: true
            }],
        selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.empresas ? seleccionables.empresas : [], grid: { md: 3, lg: 3 }
            }, {
                campo: "ramoSeguroID", title: "Ramo de Seguro",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.ramoSeguros || [], grid: { md: 3, lg: 3 }
            },
        ],
        accionesEffect: [{ botones: ["generarControlPago"], ocultar: (data) => data["estatus"].value !== "CERRADO" && data['reporteDaniosID'].value != null ? false : true }]
    }

    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "empresaID",
                    value: state.empresaID,
                    error: errorState.empresaID.error,
                    success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                    helperText: errorState.empresaID.helperText,
                    inputRef: empresaIDRef,
                    title: "Empresa *",
                    placeholder: "Seleccione empresa",
                    grid: { md: 3, lg: 3 },
                    tipo: "select",
                    data: seleccionables.empresas,
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, empresaID: value, polizaID: "0", subPolizaID: "0", activoID: "0" })
                    }
                },
                {
                    disabled,
                    id: "aseguradoraID",
                    value: state.aseguradoraID,
                    error: errorState.aseguradoraID.error,
                    success: state.aseguradoraID && !errorState.aseguradoraID.error ? true : undefined,
                    helperText: errorState.aseguradoraID.helperText,
                    inputRef: aseguradoraIDRef,
                    title: "Aseguradora *",
                    grid: { md: 3, lg: 3 },
                    tipo: "select",
                    data: seleccionables.aseguradora,
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, aseguradoraID: value })
                    }
                },
                {
                    disabled,
                    id: "estatus",
                    value: state.estatus,
                    checked: state.estatus,
                    title: "estatus",
                    inputRef: estatusRef,
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => handleChange(value, "estatus"),
                    tipo: "select",
                    data: [
                        {
                            label: "ABIERTO",
                            value: "ABIERTO"
                        },
                        {
                            label: "PRESCRITO",
                            value: "PRESCRITO"
                        },
                        {
                            label: "CERRADO",
                            value: "CERRADO"
                        },
                        {
                            label: "DESISTIDO",
                            value: "DESISTIDO"
                        },
                        {
                            label: "PAGADO",
                            value: "PAGADO"
                        }
                    ],
                    onKeyDown: (evt) => handleFocus(evt, fechaAfectadoRef)
                },
                {
                    disabled,
                    id: "fechaAfectado",
                    value: state.fechaAfectado,
                    error: errorState.fechaAfectado.error,
                    success: state.fechaAfectado && !errorState.fechaAfectado.error ? true : undefined,
                    helperText: errorState.fechaAfectado.helperText,
                    inputRef: fechaAfectadoRef,
                    title: "Fecha Daño *",
                    grid: { md: 3, lg: 3 },
                    placeholder: "Fecha Daño *",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    onChange: (value) => {
                        if (typeof value !== "object") return;
                        else handleChange(value, "fechaAfectado")
                    }
                },
                {
                    disabled,
                    id: "siniestro",
                    value: state.siniestro,
                    error: errorState.siniestro.error,
                    success: state.siniestro && !errorState.siniestro.error ? true : undefined,
                    helperText: errorState.siniestro.helperText,
                    inputRef: siniestroRef,
                    title: "No. Siniestro *",
                    placeholder: "Siniestro",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto20.test(value)) &&
                            handleChange(value, "siniestro")
                    },
                    onKeyDown: (evt) => handleFocus(evt, aseguradoraIDRef)
                },
                {
                    disabled,
                    id: "deducible",
                    value: state.deducible,
                    error: errorState.deducible.error,
                    success: state.deducible && !errorState.deducible.error ? true : undefined,
                    helperText: errorState.deducible.helperText,
                    inputRef: deducibleRef,
                    title: "Deducible",
                    textAlign: "right",
                    posicionIcono: "start",
                    placeholder: "Deducible",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value, "deducible")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "divisaID",
                    value: state.divisaID,
                    title: "Divisa",
                    placeholder: "Seleccionar la divisa",
                    grid: { md: 3, lg: 3 },
                    tipo: "select",
                    data: listDivisas,
                    onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
                },
                {
                    disabled: state.divisaID == 69,
                    id: "paridad",
                    value: state.paridad,
                    error: errorState.paridad.error,
                    success: state.paridad && !errorState.paridad.error ? true : undefined,
                    inputRef: paridadRef,
                    helperText: errorState.paridad.helperText,
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    title: "Paridad *",
                    textAlign: "right",
                    placeholder: "Paridad",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(Number(value), "paridad") }
                },
                {
                    disabled: state.empresaID == 0,
                    id: "ramoSeguroID",
                    value: state.ramoSeguroID,
                    error: errorState.ramoSeguroID.error,
                    success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
                    helperText: errorState.ramoSeguroID.helperText,
                    inputRef: ramoSeguroIDRef,
                    title: "Ramo de Seguro *",
                    placeholder: "Seleccione el Ramo de Seguro",
                    grid: { md: 6, lg: 6 },
                    tipo: "autocomplete",
                    data: seleccionables.ramoSeguros,
                    onChange: (value) => setState({ ...state, ramoSeguroID: value ? value.value : null, polizaID: "0", subPolizaID: "0", activoID: "0" }),

                },
                {
                    disabled: state.empresaID == 0 || state.ramoSeguroID == 0,
                    id: "polizaID",
                    value: state.polizaID,
                    error: errorState.polizaID.error,
                    success: state.polizaID && !errorState.polizaID.error ? true : undefined,
                    helperText: errorState.polizaID.helperText,
                    inputRef: polizaIDRef,
                    title: "Póliza de seguro *",
                    placeholder: "Seleccione la póliza de seguro",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.polizasSeguro,
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, polizaID: value, subPolizaID: "0", activoID: "0" })
                    },
                },
                {
                    disabled: state.polizaID == 0,
                    // hidden: state.estatus === "PAGADO" ? false : true, 
                    id: "subPolizaID",
                    value: state.subPolizaID,
                    error: errorState.subPolizaID.error,
                    success: state.subPolizaID && !errorState.subPolizaID.error ? true : undefined,
                    helperText: errorState.subPolizaID.helperText,
                    inputRef: subPolizaIDRef,
                    title: "Folio de Cobertura",
                    placeholder: "Seleccione Folio de Cobertura",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.subPolizasSeguro,
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, subPolizaID: value, activoID: "0" })
                    },
                },
                {
                    disabled: state.polizaID != 0 && state.estatus === "PAGADO" ? false : true  ,
                    // hidden: state.estatus === "PAGADO" ? false : true,
                    id: "activoID",
                    value: state.activoID,
                    error: errorState.activoID.error,
                    success: state.activoID && !errorState.activoID.error ? true : undefined,
                    helperText: errorState.activoID.helperText,
                    title: "Activo a dar de Baja",
                    placeholder: "Seleccione el Activo a dar de Baja",
                    grid: { md: 6, lg: 6 },
                    tipo: "autocomplete",
                    /* multiple: true, */
                    data: seleccionables.activos,
                    onChange: (value) => {
                        setState({ ...state, activoID: value ? value.value : null })
                    },
                },
            ]
        },
        {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "equipoBienAfectado",
                    multiline: true,
                    value: state.equipoBienAfectado,
                    error: errorState.equipoBienAfectado.error,
                    success: state.equipoBienAfectado && !errorState.equipoBienAfectado.error ? true : undefined,
                    helperText: errorState.equipoBienAfectado.helperText,
                    inputRef: equipoBienAfectadoRef,
                    title: "Equipo o Bien Afectado *",
                    placeholder: "Especifique el bien dañado",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => handleChange(value, "equipoBienAfectado"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "descripEvento",
                    multiline: true,
                    value: state.descripEvento,
                    error: errorState.descripEvento.error,
                    success: state.descripEvento && !errorState.descripEvento.error ? true : undefined,
                    helperText: errorState.descripEvento.helperText,
                    inputRef: descripEventoRef,
                    title: "Descripción del Evento *",
                    placeholder: "Especifique la descripción del evento",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => handleChange(value, "descripEvento"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "maquinaria",
                    value: state.maquinaria,
                    error: errorState.maquinaria.error,
                    success: state.maquinaria && !errorState.maquinaria.error ? true : undefined,
                    helperText: errorState.maquinaria.helperText,
                    inputRef: maquinariaRef,
                    title: "Maquinaria *",
                    placeholder: "Especifique la maquinaria",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto50.test(value)) &&
                            handleChange(value, "maquinaria")
                    },
                    onKeyDown: (evt) => handleFocus(evt, operadorRef)
                },
                {
                    disabled,
                    id: "operador",
                    value: state.operador,
                    error: errorState.operador.error,
                    success: state.operador && !errorState.operador.error ? true : undefined,
                    helperText: errorState.operador.helperText,
                    inputRef: operadorRef,
                    title: "Operador *",
                    placeholder: "Especifique el operador",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => handleChange(value, "operador"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "ajustador",
                    value: state.ajustador,
                    error: errorState.ajustador.error,
                    success: state.ajustador && !errorState.ajustador.error ? true : undefined,
                    helperText: errorState.ajustador.helperText,
                    inputRef: ajustadorRef,
                    title: "Ajustador *",
                    placeholder: "Especifique el ajustador",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => handleChange(value, "ajustador"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "indemnizaPagada",
                    value: state.indemnizaPagada,
                    error: errorState.indemnizaPagada.error,
                    success: state.indemnizaPagada && !errorState.indemnizaPagada.error ? true : undefined,
                    helperText: errorState.indemnizaPagada.helperText,
                    inputRef: indemnizaPagadaRef,
                    title: "Indemniza Pagada ",
                    placeholder: "Especifique el indemnizaPagada",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto30.test(value)) &&
                            handleChange(value, "indemnizaPagada")
                    },
                    onKeyDown: (evt) => handleFocus(evt, deducibleRef)
                },
                {
                    disabled,
                    id: "observaciones",
                    multiline: true,
                    value: state.observaciones,
                    error: errorState.observaciones.error,
                    success: state.observaciones && !errorState.observaciones.error ? true : undefined,
                    helperText: errorState.observaciones.helperText,
                    inputRef: observacionesRef,
                    title: "Observaciones",
                    placeholder: "Especifique las observaciones",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => handleChange(value, "observaciones"),
                    onKeyDown: () => { return; }
                },
            ]
        },
        {
            id: "Abajo",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaReclamacion",
                    value: state.fechaReclamacion,
                    error: errorState.fechaReclamacion.error,
                    success: state.fechaReclamacion && !errorState.fechaReclamacion.error ? true : undefined,
                    helperText: errorState.fechaReclamacion.helperText,
                    inputRef: fechaReclamacionRef,
                    title: "Fecha Reclamación *",
                    grid: { md: 6, lg: 6 },
                    placeholder: "Fecha Reclamación",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    onChange: (value) => {
                        handleChange(value, "fechaReclamacion");
                        if (typeof value !== "object") {
                            setErrorState({ ...errorState, fechaReclamacion: { error: true, helperText: 'Formato no válido. Ej: día/mes/año' } })
                            if (value === "")
                                setErrorState({ ...errorState, fechaReclamacion: { error: false, helperText: '' } })
                        } else {
                            setErrorState({ ...errorState, fechaReclamacion: { error: false, helperText: '' } })
                        }
                    }
                },
                {
                    disabled,
                    id: "fechaPago",
                    value: state.fechaPago,
                    error: errorState.fechaPago.error,
                    success: state.fechaPago && !errorState.fechaPago.error ? true : undefined,
                    helperText: errorState.fechaPago.helperText,
                    inputRef: fechaPagoRef,
                    title: "Fecha de Pago *",
                    grid: { md: 6, lg: 6 },
                    placeholder: "Fecha Pago",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    onChange: (value) => {
                        handleChange(value, "fechaPago");
                        if (typeof value !== "object") {
                            setErrorState({ ...errorState, fechaPago: { error: true, helperText: 'Formato no válido. Ej: día/mes/año' } })
                            if (value === "")
                                setErrorState({ ...errorState, fechaPago: { error: false, helperText: '' } })
                        } else {
                            setErrorState({ ...errorState, fechaPago: { error: false, helperText: '' } })
                        }
                    }
                }
            ]
        }
    ];

    async function handleChangeDivisa(value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: { divisaID: value } }, token);

        setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
    }

    let accionesFormulario = [{
        accionID: state.siniesReclAsegID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: empresaIDRef,
            mensajeCorrecto: `El siniestro se ${state.siniesReclAsegID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        })
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const inputsEstado = [
        {
            disabled,
            id: "estatus",
            value: state.estatus,
            checked: state.estatus,
            title: "estatus",
            inputRef: estatusRef,
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => handleChange(value, "estatus"),
            tipo: "select",
            data: [
                {
                    label: "ABIERTO",
                    value: "ABIERTO"
                },
                {
                    label: "PRESCRITO",
                    value: "PRESCRITO"
                },
                {
                    label: "CERRADO",
                    value: "CERRADO"
                },
                {
                    label: "DESISTIDO",
                    value: "DESISTIDO"
                },
                {
                    label: "PAGADO",
                    value: "PAGADO"
                }
            ],
        }
    ];

    const accionesFormularioCambioEstado = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseEstado,
            handleFocus,
            refFocus: empresaIDRef,
            mensajeCorrecto: `La reclamación ${state.siniesReclAsegID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        })
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseEstado
    }];

    function handleCloseEstado() {
        setOpenEstado(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    useEffect(() => {
        async function ftEffect() {
            try {

                let aseguradora = await seleccionAseguradora({ filtro: {} }, token);
                let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);

                setSeleccionables((seleccionables) => ({ ...seleccionables, empresas, aseguradora }));

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    useEffect(() => {
        async function ftEffect() {
            try {
                let ramoSeguros = await seleccionRamoSeguro({ filtro: { fijo: true } }, token);
                let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== null ? parseInt(state.empresaID) : -1, ramoSeguroID: state.ramoSeguroID !== null ? parseInt(state.ramoSeguroID) : -1, tipoPoliza: "PRINCIPAL" } }, token);
                ramoSeguros.unshift({ label: "NO APLICA", value: "0" })
                let subPolizasSeguro = []
                let activos = []
                subPolizasSeguro = await seleccionableSubPolizasSeguro({ filtro: { polizasSeguroID: parseInt(state.polizaID) } }, token);
                subPolizasSeguro.unshift({ label: "No Aplica", value: "0" });


                activos = await seleccionActivosAsegurado({ filtro: { polizasSeguroID: parseInt(state.polizaID), /*solicitud: 'Alta de Seguro',*/ subPolizaID: state.subPolizaID == 0 ? null : Number(state.subPolizaID), /*estado: "ACTIVO"*/ } }, token, "", "reclamosAseguradora");

                if (activos === undefined) {
                    activos = []
                    activos.push({ label: "No se encontraron registros", value: "0" });
                    setState({ ...state, activoID: "0" })
                    setDisabledActivoID(true)
                } else {
                    setDisabledActivoID(false)
                }

                setSeleccionables((seleccionables) => ({ ...seleccionables, ramoSeguros, polizasSeguro, subPolizasSeguro, activos }));
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [state.empresaID, state.ramoSeguroID, state.polizaID, state.subPolizaID]);

    function handleValidaciones({
        empresaID,
        aseguradoraID,
        ramoSeguroID,
        siniestro,
        equipoBienAfectado,
        descripEvento,
        maquinaria,
        operador,
        ajustador,
        estatus,
        fechaReclamacion,
        fechaPago }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(empresaID),
                ref: empresaIDRef,
                keyError: "empresaID",
                mensajeError: "Debe seleccionar una empresa"
            },
            {
                condicion: !rgxNumeros.test(aseguradoraID),
                ref: aseguradoraIDRef, keyError: "aseguradoraID",
                mensajeError: "Debe seleccionar la aseguradora"
            },
            {
                condicion: !ramoSeguroID,
                keyError: "ramoSeguroID",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: !siniestro || trim(siniestro) === "",
                ref: siniestroRef, keyError: "siniestro",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: !equipoBienAfectado || trim(equipoBienAfectado) === "",
                ref: equipoBienAfectadoRef, keyError: "equipoBienAfectado",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: !descripEvento || trim(descripEvento) === "",
                ref: descripEventoRef, keyError: "descripEvento",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: !maquinaria || trim(maquinaria) === "",
                ref: maquinariaRef, keyError: "maquinaria",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: !operador || trim(operador) === "",
                ref: operadorRef, keyError: "operador",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: !ajustador || trim(ajustador) === "",
                ref: ajustadorRef, keyError: "ajustador",
                mensajeError: "El campo no puede estar vacio"
            },
            {
                condicion: typeof fechaReclamacion == 'string' || !moment(fechaReclamacion).isValid(), keyError: "fechaReclamacion",
                mensajeError: "Formato no válido"
            }
        ];

        if (estatus === 'PAGADO') {
            validaciones.push(
                {
                    condicion: typeof fechaPago == 'string' || !moment(fechaPago).isValid(), keyError: "fechaPago",
                    mensajeError: "Formato no válido"
                }
            )
        }

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function formatearFecha(FechaE) {
        if (moment(FechaE).isValid() === false) {
            return null;
        }
        return moment(FechaE).format(formato);
    }

    function ftGuardar() {
        async function ftGuardar() {
            let {
                siniesReclAsegID,
                empresaID,
                estatus,
                fechaAfectado,
                siniestro,
                aseguradoraID,
                numPoliza,
                deducible,
                equipoBienAfectado,
                descripEvento,
                maquinaria,
                operador,
                ajustador,
                fechaReclamacion,
                indemnizaPagada,
                fechaPago,
                polizaID,
                ramoSeguroID,
                observaciones,
                divisaID,
                paridad,
                subPolizaID,
                activoID
            } = state;

            let error = handleValidaciones({
                empresaID,
                aseguradoraID,
                ramoSeguroID,
                siniestro,
                equipoBienAfectado,
                descripEvento,
                maquinaria,
                operador,
                ajustador,
                indemnizaPagada,
                estatus,
                fechaReclamacion,
                fechaPago
            });
            if (error) return error;

            if (errorState.fechaReclamacion.error) {
                setErrorState({ ...errorState, fechaReclamacion: { error: true, helperText: 'Formato no válido. Ej: día/mes/año' } })
                throw new Error("Fecha de reclamación no tiene formato correcto");
            }
            if (errorState.fechaPago.error) {
                setErrorState({ ...errorState, fechaPago: { error: true, helperText: 'Formato no válido.Ej: día/mes/año' } })
                throw new Error("Fecha de pago no tiene formato correcto");
            }

            if (siniesReclAsegID) await updateSiniestrosReclaAseg({
                input: {
                    siniesReclAsegID,
                    empresaID,
                    estatus: trim(estatus),
                    fechaAfectado: fechaAfectado ? fechaAfectado.format(formato) : null,
                    siniestro: trim(siniestro),
                    aseguradoraID,
                    deducible,
                    equipoBienAfectado: trim(equipoBienAfectado),
                    descripEvento: trim(descripEvento),
                    maquinaria: trim(maquinaria),
                    operador: trim(operador),
                    ajustador: trim(ajustador),
                    fechaReclamacion: fechaReclamacion ? fechaReclamacion.format(formato) : null,
                    indemnizaPagada: indemnizaPagada,
                    fechaPago: fechaPago ? fechaPago.format(formato) : null,
                    polizaID,
                    ramoSeguroID,
                    observaciones,
                    reclamoPrincipalID: null,
                    tipoReclamoPrincipal: null,
                    divisaID,
                    paridad: Number(paridad),
                    subPolizaID: Number(subPolizaID),
                    activoID: Number(activoID)
                }
            }, token);
            else await addSiniestrosReclaAseg({
                input: {
                    empresaID,
                    estatus: trim(estatus),
                    fechaAfectado: fechaAfectado.format(formato),
                    siniestro: trim(siniestro),
                    aseguradoraID,
                    numPoliza: numPoliza ? trim(numPoliza) : null,
                    deducible: deducible,
                    equipoBienAfectado: trim(equipoBienAfectado),
                    descripEvento: trim(descripEvento),
                    maquinaria: trim(maquinaria),
                    operador: trim(operador),
                    ajustador: trim(ajustador),
                    fechaReclamacion: formatearFecha(state.fechaReclamacion),
                    indemnizaPagada: indemnizaPagada,
                    fechaPago: formatearFecha(state.fechaPago),
                    polizaID,
                    ramoSeguroID,
                    observaciones,
                    reclamoPrincipalID: reclamoPrincipalID,
                    tipoReclamoPrincipal: tipoReclamo,
                    divisaID,
                    paridad: Number(paridad),
                    subPolizaID: Number(subPolizaID),
                    activoID
                }
            }, token);
        }
        return ftGuardar();
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (
        <>
            {<Tabla
                infoTabla={infoTabla}
                asyncData={tablaSiniestrosReclaAseg}
                token={token}
                parametrosFijos={{ reclamoPrincipalID: reclamoPrincipalID, tipoReclamoPrincipal: tipoReclamo }}
            />}
            <FormularioModal
                open={open}
                title={titleModal}
                onClose={handleClose}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputs}
                acciones={accionesFormulario}
                focus={siniestroRef}
            />
            <FormularioModal
                open={openEstado}
                title={"Cambio de Estado"}
                onClose={handleCloseEstado}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputsEstado}
                acciones={accionesFormularioCambioEstado}
            />
            {
                openDocumentacion && <Modal maxWidth={true} onClose={() => { setOpenDocumentacion(false) }}>
                    <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                    } />
                </Modal>
            }
            {
                openTrazabilidad && <Modal maxWidth={true} onClose={() => { setOpenTrazabilidad(false) }}>
                    <TrazabilidadSiniestros datosDetalle={datosDetalle} setOpenTrazabilidad={() => setOpenTrazabilidad(false)
                    } />
                </Modal>
            }
            {
                openReclamoAseguradora &&
                <Modal maxWidth={false} onClose={() => { setOpenReclamosAseguradora(false) }}>
                    <SiniestrosReclaAseg
                        datos={{ tipoReclamo: 1, reclamoPrincipalID: stSiniesReclAsegID, folioPrincipal: folioPrincipal }}
                        setOpenReclamosAseguradora={() => setOpenReclamosAseguradora(false)}
                    >
                    </SiniestrosReclaAseg>
                </Modal>
            }
            {
                openReclamosTerceros &&
                <Modal maxWidth={false} onClose={() => { setOpenReclamosTerceros(false) }}>
                    <ReclamacionATerceros
                        datos={{ tipoReclamo: 1, reclamoPrincipalID: stSiniesReclAsegID, folioPrincipal: folioPrincipal }}
                        setOpenReclamosTerceros={() => setOpenReclamosTerceros(false)}
                    >
                    </ReclamacionATerceros>
                </Modal>
            }
            {alert &&
                <SweetAlert
                    title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />}
            {openSeguimientos &&
                <Modal
                    open={openSeguimientos}
                    onClose={() => setOpenSeguimientos(false)}>
                    <SeguimientoActividades datos={{ reporteDaniosID: stReporteDaniosID, siniesReclAsegID: stSiniesReclAsegID, verHistorial }} reclamosAseguradora={true} actualizarTablaReclamosAseguradora={setActualizar} />
                </Modal>
            }
            {
                openControlPago && <Modal maxWidth={true}
                    open={openControlPago}
                    onClose={() => setOpenControlPago(false)}
                >
                    <UpsertControlPago
                        data={{ reporteDaniosID: stReporteDaniosID.repDaniosID }}
                        referencia={
                            {
                                id: stReporteDaniosID.recAsegID,
                                ref: 'recAseg'
                            }
                        }
                        propsAlert={alert}
                        propsSetAlert={setAlert}
                        cerrar={() => setOpenControlPago(false)}
                    ></UpsertControlPago>
                </Modal>
            }
        </>);
}

export default SiniestrosReclaAseg;
