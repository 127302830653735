import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { RiFileExcel2Fill } from "react-icons/ri";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import ExplicitIcon from '@material-ui/icons/Explicit';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ContactsIcon from '@material-ui/icons/Contacts';

import { getControlPago, tablaControlPagos, tablaControlPagosReporteDanios } from '../../../querys/ControlPago';
import { seleccionAseguradora } from '../../../querys/Aseguradoras';
import { seleccionAgentesSeguro } from '../../../querys/AgentesSeguros';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionDepartamento } from 'querys/Departamento';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import { seleccionArea } from '../../../querys/Areas';

import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
import { getSiniestrosReclaTercero, } from '../../../querys/ReclamacionesATerceros';
import { getReporteDanios } from '../ReporteDanios/Consultas.js'

import { getSiniestrosReclaAseg } from '../../../querys/SiniestrosReclaAseg';
import { estadoReclamacionSiniestro } from '../../../helpers/datosFijos/estadoReclamoSiniestro';
import { useLocation } from 'react-router-dom';
import { seleccionPolizasSeguro, getPolizasSeguro,getAllSubPolizasSeguro } from '../../../querys/PolizasSeguro';
import { getFile } from '../../../mutations/ServerStorage'
//storage
import { getArchivo } from "../../../helpers/serverStorage";

import { showFile } from '../../../helpers';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric, handleGetGeneric } from '../../../_layout/helpers/handles';
import { rgxdeInt2 } from '../../../helpers/regexp';
import { tablaTrazabilidadSiniestros, tablaDocumentosAdjuntos } from '../../../querys/TrazabilidadSiniestros';
import { info, danger, success, primary, rose, warning } from '../../../_layout/helpers/colores';
import { getSolicitudSeguro } from '../../../querys/SolicitudesSeguros/';
import { rgxLetras, rgxNumeros } from '../../../helpers/regexp';
import moment from 'moment';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import { tipoSolicitudSeguro } from '../../../helpers/datosFijos/tipoSolicitudSeguro';

import { estadoSolicitudSeguro } from 'helpers/datosFijos/estadoSolicitudSeguro';
import * as Permisos from '../Permisos';
import { urlServidor } from '../../../helpers';
import Modal from '_layout/genericComponents/Modal';
import MultiPartidas from '../../../_layout/NuevosComponentes/MultiPartidas';
function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const { Decrypt } = Encriptar;


function TrazabilidadSiniestros({ datosDetalle, setOpenTrazabilidad }) {

    const { Encrypt, Decrypt } = Encriptar;
    const { token, usuario } = useSelector(state => state.login);
    const formato = "YYYY-MM-DD";
    const location = useLocation();
    let history = useHistory();
    let formatoDelmomento = "DD/MM/YYYY";
    let nReporteDaniosID = null;
    let ncontrolPagoID = null;
    let nPolizasSeguroID = null;
    let nSiniesReclTercerosID = null;
    let nSiniesReclAsegID = null;
    let now = moment();
    let titleAccion = "Consultar";
    let EsReporteador = true;

    let titleModalReclAseg = `${titleAccion} Datos de Reclamo a Aseguradora RGR-002`;
    let titleModalReclTercero = `${titleAccion} Datos de Reclamo a Tercero RGR-007`;
    let titleModalPago = `${titleAccion} Datos de Control de Pago`;
    let titleModalPolizaPrincipal = `${titleAccion} Datos de Póliza Principal`;
    let titleModalReporteDanio = `${titleAccion} Datos de Reporte de Daños`;
    let lUtilizaProps = true;
    let remover = false;
    let ArrayRef = [];



    const listEstatus = [
        {
            label: 'COMPLETADO',
            value: 'COMPLETADO'
        },
        {
            label: 'EN PROCESO',
            value: 'EN PROCESO'
        },
        {
            label: 'PENDIENTE',
            value: 'PENDIENTE'
        },
    ];

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ];
    const cleanaTiposPoliza = [
        {
            label: 'PRINCIPAL',
            value: 'PRINCIPAL'
        },
        {
            label: 'POLIZA',
            value: 'POLIZA'
        },
        {
            label: 'ENDOSO',
            value: 'ENDOSO'
        }
    ];

    let { params } = useParams();
    if (params != undefined && params !== ":params") {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pReporteDaniosID, pControlPagoID, pPolizasSeguroID, pSiniesReclAsegID, pSiniesReclTercerosID } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        nReporteDaniosID = pReporteDaniosID;
        nPolizasSeguroID = pPolizasSeguroID;
        ncontrolPagoID = pControlPagoID;
        nSiniesReclAsegID = pSiniesReclAsegID;
        nSiniesReclTercerosID = pSiniesReclTercerosID;
    }


    if (nReporteDaniosID != undefined && nReporteDaniosID.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {

        let { pReporteDaniosID, pControlPagoID, pPolizasSeguroID, pSiniesReclAsegID, pSiniesReclTercerosID } = datosDetalle;
        nReporteDaniosID = pReporteDaniosID;
        nPolizasSeguroID = pPolizasSeguroID;
        ncontrolPagoID = pControlPagoID;
        nSiniesReclAsegID = pSiniesReclAsegID;
        nSiniesReclTercerosID = pSiniesReclTercerosID;
    }

    const cleanStateReclAseg = {
        siniesReclAsegID: null,
        empresaID: "",
        estatus: "ABIERTO",
        fechaAfectado: now.format(formatoDelmomento),
        siniestro: "",
        aseguradoraID: "",
        numPoliza: "",
        deducible: "",
        equipoBienAfectado: "",
        descripEvento: "",
        maquinaria: "",
        operador: "",
        ajustador: "",
        fechaReclamacion: "",
        indemnizaPagada: "",
        fechaPago: "",
        ramoSeguroID: "",
        polizaID: ""
    };

    const cleanStateReclTerceros = {
        siniesReclTercerosID: null,
        numero: "",
        empresaID: null,
        fechaAfectado: new Date(),
        infoIdentifTercero: "",
        asegDelTercero: "",
        ajustadorRespresentante: "",
        noSiniestro: "",
        noPoliza: "",
        bienAfectado: "",
        descripEvento: "",
        ubicacion: "",
        estatus: "EN PROCESO",
        montoPagado: 0,
        fechaReclamacion: new Date(),
        fechaIndemnizacion: new Date(),
        atendio: "",
        noDiasHabilesTrans: ""
    };

    const cleanErrorStateReclAseg = {
        empresaID: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        fechaAfectado: { error: false, helperText: "" },
        siniestro: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        numPoliza: { error: false, helperText: "" },
        deducible: { error: false, helperText: "" },
        equipoBienAfectado: { error: false, helperText: "" },
        descripEvento: { error: false, helperText: "" },
        maquinaria: { error: false, helperText: "" },
        operador: { error: false, helperText: "" },
        ajustador: { error: false, helperText: "" },
        fechaReclamacion: { error: false, helperText: "" },
        indemnizaPagada: { error: false, helperText: "" },
        fechaPago: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        polizaID: { error: false, helperText: "" }
    }

    const cleanErrorStateReclTerceros = {
        siniesReclTercerosID: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        fechaAfectado: { error: false, helperText: "" },
        infoIdentifTercero: { error: false, helperText: "" },
        asegDelTercero: { error: false, helperText: "" },
        ajustadorRespresentante: { error: false, helperText: "" },
        noSiniestro: { error: false, helperText: "" },
        noPoliza: { error: false, helperText: "" },
        bienAfectado: { error: false, helperText: "" },
        descripEvento: { error: false, helperText: "" },
        ubicacion: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        montoPagado: { error: false, helperText: "" },
        fechaReclamacion: { error: false, helperText: "" },
        fechaIndemnizacion: { error: false, helperText: "" },
        atendio: { error: false, helperText: "" },
        noDiasHabilesTrans: { error: false, helperText: "" }
    }

    const ReclAsegempresaIDRef = useRef(null);
    const ReclAsegestatusRef = useRef(null);
    const ReclAsegfechaAfectadoRef = useRef(null);
    const ReclAsegsiniestroRef = useRef(null);
    const ReclAsegaseguradoraIDRef = useRef(null);
    const ReclAsegnumPolizaRef = useRef(null);
    const ReclAsegdeducibleRef = useRef(null);
    const ReclAsegequipoBienAfectadoRef = useRef(null);
    const ReclAsegdescripEventoRef = useRef(null);
    const ReclAsegmaquinariaRef = useRef(null);
    const ReclAsegoperadorRef = useRef(null);
    const ReclAsegajustadorRef = useRef(null);
    const ReclAsegfechaReclamacionRef = useRef(null);
    const ReclAsegindemnizaPagadaRef = useRef(null);
    const ReclAsegfechaPagoRef = useRef(null);
    const ReclAsegramoSeguroIDRef = useRef(null);
    const ReclAsegpolizaIDRef = useRef(null);


    const ReclTercerossiniesReclTercerosIDRef = useRef(null);
    const ReclTercerosnumeroRef = useRef(null);
    const ReclTercerosempresaIDRef = useRef(null);
    const ReclTercerosfechaAfectadoRef = useRef(null);
    const ReclTercerosinfoIdentifTerceroRef = useRef(null);
    const ReclTercerosasegDelTerceroRef = useRef(null);
    const ReclTercerosajustadorRespresentanteRef = useRef(null);
    const ReclTercerosnoSiniestroRef = useRef(null);
    const ReclTercerosnoPolizaRef = useRef(null);
    const ReclTercerosbienAfectadoRef = useRef(null);
    const ReclTercerosdescripEventoRef = useRef(null);
    const ReclTercerosubicacionRef = useRef(null);
    const ReclTercerosestatusRef = useRef(null);
    const ReclTercerosmontoPagadoRef = useRef(null);
    const ReclTercerosfechaReclamacionRef = useRef(null);
    const ReclTercerosfechaIndemnizacionRef = useRef(null);
    const ReclTercerosatendioRef = useRef(null);
    const ReclTercerosnoDiasHabilesTransRef = useRef(null);

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [datosPartidas, setDatosPartidas] = useState(null);
    const [datosPartidasGet, setDatosPartidasGet] = useState(null);

    const [stateReclAseg, setStateReclAseg] = useState(cleanStateReclAseg);
    const [stateReclTerceros, setStateReclTerceros] = useState(cleanStateReclTerceros);
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [errorstateReclAseg, setErrorStateReclAseg] = useState(cleanErrorStateReclAseg);
    const [errorstateReclTerceros, setErrorStateReclTerceros] = useState(cleanErrorStateReclTerceros);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [openVerReclAseg, setOpenVerReclAseg] = useState(false);
    const [openVerReclTerceros, setOpenVerReclTerceros] = useState(false);

    const cleanState = {};
    const cleanErrorState = {}
    const cleanStateReporteDanios = {};

    const [openVerReporteDanios, setOpenVerReporteDanios] = useState(false);
    const [stDatos, setStateReporteDanios] = useState(cleanStateReporteDanios);
    //  const [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [stFotografia1, setFotografia1] = useState({ valor: "", file: "", eliminar: remover });
    const [stFotografia2, setFotografia2] = useState({ valor: "", file: "", eliminar: remover });
    const [stTituloTipoDanio, setTituloTipoDanio] = useState("")

    const estatusReporteador = [{ value: "EN PROCESO", label: "EN PROCESO" },
    { value: "CERRADO", label: "CERRADO" }
    ];

    const seguimiento = [{ value: "PENDIENTE", label: "PENDIENTE" },
    { value: "SIN RECLAMACIÓN", label: "SIN RECLAMACIÓN" },
    { value: "REPARADO", label: "REPARADO" },
    { value: "PAGADO", label: "PAGADO" },
    { value: "RECLAMADO A ASEGURADORA (RGR-002)", label: "RECLAMADO A ASEGURADORA (RGR-002)" },
    { value: "RECLAMADO A TERCERO (RGR-007)", label: "RECLAMADO A TERCERO (RGR-007)" }, { value: "REPOSICIÓN", label: "REPOSICIÓN" }
    ];

    /* Control de pago */
    const cleanStatePago = {
        controlPagoID: null,
        numero: "",
        fechaTramite: now,
        empresaID: "",
        aseguradoraID: "",
        agenteSegID: "",
        descripcion: "",
        ramoSeguroID: "",
        folioTramite: "",
        poliza: "",
        endoso: "",
        importePago: 0,
        gastosExpedicion: 0,
        porcentajeIVA: 0,
        iva: 0,
        total: 0,
        solicitado: now,
        realizado: now,
        divisaID: 69,
        folioFactura: "",
        pdf: 0,
        xml: 0,
        docOrig: 0,
        estatus: "PENDIENTE",
        observaciones: "",
        horasInvertidas: "",
        polizaID: 0,
        vigenciaIni: "",
        vigenciaHasta: "",
        devolucion: '',
        notaCredito: '',
        paridad: '',
        inciso: ''

    };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };

    const cleanErrorStatePago = {
        controlPagoID: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        fechaTramite: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        agenteSegID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        folioTramite: { error: false, helperText: "" },
        poliza: { error: false, helperText: "" },
        endoso: { error: false, helperText: "" },
        importePago: { error: false, helperText: "" },
        solicitado: { error: false, helperText: "" },
        realizado: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        folioFactura: { error: false, helperText: "" },
        pdf: { error: false, helperText: "" },
        xml: { error: false, helperText: "" },
        docOrig: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        polizaID: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" }

    }

    const [statePago, setStatePago] = useState(cleanStatePago);
    const [errorStatePago, setErrorStatePago] = useState(cleanErrorStatePago);
    const [openVerPago, setOpenVerPago] = useState(false);
    const [openFormularioVerPago, setOpenFormularioVerPago] = useState(false);

    const PagocontrolPagoIDRef = useRef(null);
    const PagonumeroRef = useRef(null);
    const PagofechaTramiteRef = useRef(null);
    const PagoempresaIDRef = useRef(null);
    const PagoaseguradoraIDRef = useRef(null);
    const PagoagenteSegIDRef = useRef(null);
    const PagodescripcionRef = useRef(null);
    const PagoramoSeguroIDRef = useRef(null);
    const PagofolioTramiteRef = useRef(null);
    const PagopolizaRef = useRef(null);
    const PagoendosoRef = useRef(null);
    const PagoimportePagoRef = useRef(null);
    const PagosolicitadoRef = useRef(null);
    const PagorealizadoRef = useRef(null);
    const PagodivisaIDRef = useRef(null);
    const PagofolioFacturaRef = useRef(null);
    const PagoestatusRef = useRef(null);
    const PagoobservacionesRef = useRef(null);
    const PagohorasInvertidasRef = useRef(null);
    const PagopolizaIDRef = useRef(null);
    const PagovigenciaIniRef = useRef(null);
    const PagovigenciaHastaRef = useRef(null);
    const PagoestadoRef = useRef(null);

    /* Poliza Principal */


    const cleanStatePolPrincipal = {
        polizasSeguroID: null,
        numero: "",
        fechaSolCot: "",
        empresaID: "",
        ramoSeguroID: "",
        aseguradoraID: "",
        numpolEndoso: "",
        divisaID: 69,
        sumaAseg: "",
        primaNeta: "",
        fpPromedio: "",
        vigenciaIni: now.format(formatoDelmomento),
        vigenciaHasta: "",
        exclusiones: "",
        renovacion: false,
        horasInvertidas: "",
        tipoValorAseg: "",
        tipoPoliza: "PRINCIPAL",
        polizaOrigenID: null,
        seleccionTipoPoliza:"1",
        derechoPoliza:"",
        ramoSeguro:"",
        paridad:"",
        inciso:"",
        beneficiarioPreferente:"",
        aseguradoAdicional:""
    };
    const cleanErrorStatePolPrincipal = {
        numero: { error: false, helperText: "" },
        fechaSolCot: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        numpolEndoso: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        sumaAseg: { error: false, helperText: "" },
        primaNeta: { error: false, helperText: "" },
        fpPromedio: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        exclusiones: { error: false, helperText: "" },
        renovacion: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        tipoValorAseg: { error: false, helperText: "" },
        tipoPoliza: { error: false, helperText: "" },
        polizaOrigenID: { error: false, helperText: "" },
        seleccionTipoPoliza: { error: false, helperText: "" },
        derechoPoliza: { error: false, helperText: "" },
        ramoSeguro: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        inciso: { error: false, helperText: "" },
        beneficiarioPreferente: { error: false, helperText: "" },
        aseguradoAdicional: { error: false, helperText: "" }
    }
    const PolPrincipalnumeroRef = useRef(null);
    const PolPrincipaltipoValorAsegRef = useRef(null);
    const PolPrincipalfechaSolCotRef = useRef(null);
    const PolPrincipalempresaIDRef = useRef(null);
    const PolPrincipalramoSeguroIDRef = useRef(null);
    const PolPrincipalaseguradoraIDRef = useRef(null);
    const PolPrincipalnumpolEndosoRef = useRef(null);
    const PolPrincipaldivisaIDRef = useRef(null);
    const PolPrincipalsumaAsegRef = useRef(null);
    const PolPrincipalprimaNetaRef = useRef(null);
    const PolPrincipalfpPromedioRef = useRef(null);
    const PolPrincipalvigenciaIniRef = useRef(null);
    const PolPrincipalvigenciaHastaRef = useRef(null);
    const PolPrincipalexclusionesRef = useRef(null);
    const PolPrincipalhorasInvertidasRef = useRef(null);
    const PolPrincipaltipoPolizaRef = useRef(null);
    const PolPrincipalpolizaOrigenIDRef = useRef(null);

    const [statePolPrincipal, setStatePolPrincipal] = useState(cleanStatePolPrincipal);
    const [errorStatePolPrincipal, setErrorStatePolPrincipal] = useState(cleanErrorStatePolPrincipal);
    const [openVerPolPrincipal, setOpenVerPolPrincipal] = useState(false);
    const [aTiposPoliza, setActualizaraTiposPoliza] = useState(cleanaTiposPoliza);

    /* adjuntos */
    let titleModal = `${titleAccion} documento`;
    // let ArrayRef = [];
    var Tipos = [];
    var Clasificaciones = [];
    //  let remover = false;
    const cleanStateAdjuntos = { serverStorageID: null };
    const cleanErrorStateAdjuntos = {};

    const [stDatosAdjuntos, setStateAdjuntos] = useState(cleanStateAdjuntos);
    const [errorStateAdjuntos, setErrorStateAdjuntos] = useState(cleanErrorStateAdjuntos);
    const [documento, setDocumento] = useState({ valor: "", file: "", eliminar: remover });


    const botones = [
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {

                if (lUtilizaProps) {
                    setOpenTrazabilidad();
                } else {
                    history.goBack();
                }

            }
        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => {
                setActualizar(!actualizar);
                //  setActualizarDetalle(!actualizarDetalle);
                // setlConPartidas(true);
            },
            disabled: { multiseleccion: true }
        }];


    let tAcciones = [
        {
            accionID: [6],
            icono: SyncProblemIcon,
            color: info,
            descripcion: "Ver Reporte Daños",
            parametros: [{ campo: "reporteDaniosID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                if (parametros.reporteDaniosID !== null) {
                    actualizarSeleccionables();
                    handleModificar({
                        parametros, token, setState: setStateReporteDanios, setOpen: setOpenVerReporteDanios, setAlert,
                        asyncGet: getReporteDanios,

                    })

                }
            }
        },
        {
            accionID: [2],
            icono: AnnouncementIcon,
            color: rose,
            descripcion: "Ver RGR-002",
            parametros: [{ campo: "siniesReclAsegID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                if (parametros.siniesReclAsegID !== null) {
                    actualizarSeleccionables();
                    handleModificar({
                        parametros, token, setState: setStateReclAseg, setOpen: setOpenVerReclAseg, setAlert,
                        asyncGet: getSiniestrosReclaAseg,
                        formatearResultado: [{ id: "fechaAfectado", formato: (data) => moment(data) }, { id: "fechaReclamacion", formato: (data) => moment(data) }, { id: "fechaPago", formato: (data) => moment(data) }]

                    })
                }
            }

        },
        {
            accionID: [3],
            icono: ContactsIcon,
            color: rose,
            descripcion: "Ver RGR-007",
            parametros: [{ campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                if (parametros.siniesReclTercerosID !== null) {
                    actualizarSeleccionables();
                    handleModificar({
                        parametros, token, setState: setStateReclTerceros, setOpen: setOpenVerReclTerceros, setAlert,
                        asyncGet: getSiniestrosReclaTercero,
                        formatearResultado: [{ id: "fechaAfectado", formato: (data) => moment(data) }, { id: "fechaReclamacion", formato: (data) => moment(data) }, { id: "fechaIndemnizacion", formato: (data) => moment(data) }]

                    })
                }
            }

        }, {
            accionID: [4],
            icono: PaymentIcon,
            color: info,
            descripcion: "Ver Control de Pago",
            parametros: [{ campo: "controlPagoID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                if (nReporteDaniosID !== null) {
                    setOpenVerPago(true)
                }
            }
        }, {
            accionID: [5],
            icono: LocalParkingIcon,
            color: info,
            descripcion: "Ver Póliza Principal",
            parametros: [{ campo: "polizasSeguroID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                if (parametros.polizasSeguroID !== null && parametros.polizasSeguroID !== 0 && parametros.polizasSeguroID !== '') {
                    actualizarSeleccionables();
                    obtenerValoresMultipartidas(parametros);
                    let parametros2 = { polizasSeguroID: parametros.polizasSeguroID }
                    handleModificar({
                        parametros: parametros2, token, setState: setStatePolPrincipal, setOpen: setOpenVerPolPrincipal, setAlert,
                        asyncGet: getPolizasSeguro,

                    })

                }
            }
        }, ];

    const [acciones, setAcciones] = useState(tAcciones);
    const [accionesUsr, setAccionesUsr] = useState();
    useEffect(() => {
        async function ftEffect() {
            try {
                Permisos.PermisosAState(setAccionesUsr, token, 43);
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            try {
                if (!accionesUsr) {
                    return;
                }
                let tAcciones = Permisos.ValidarAcciones(acciones, accionesUsr);
                setAcciones(tAcciones);
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [accionesUsr]);

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "folio",
        color: primary,
        title: "Trazabilidad de Siniestros",
        iconTable: <BusinessIcon />,
        headers: [

            { variable: "reporteDaniosID", descripcion: "reporteDaniosID", hide: true },
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true },
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "siniesReclTercerosID", descripcion: "siniesReclTercerosID", hide: true },
            { variable: "siniesReclAsegID", descripcion: "siniesReclAsegID", hide: true },

            { variable: "siglas", descripcion: "Empresa" },
            { variable: "ramoSeguro", descripcion: "Ramo Seguro", hide: false },
            { variable: "folioInternoReporteDanios", descripcion: "Folio Reporte", hide: false },
            { variable: "descripBreve", descripcion: "Bien Dañado", hide: false },
            { variable: "folioReclamacionTercero", descripcion: "RGR-007", hide: false },
            { variable: "fechaReclamacionTercero", descripcion: "Fecha RGR-007" },
            { variable: "folioReclamacionAseg", descripcion: "RGR-002", hide: false },
            { variable: "fechaReclamacionAseg", descripcion: "Fecha RGR-002" },
            { variable: "folioControlPago", descripcion: "Folio Control Pago", hide: false },
            { variable: "fechaTramitePago", descripcion: "Fecha Tramite Pago" },
            { variable: "folioPolizaPrincipal", descripcion: "Folio Póliza Principal" },

        ],
        responsiveTitle: ["folioInternoReporteDanios", "folioControlPago", "folioPolizaPrincipal"],
        /* filter: [

           /*  { campo: "empresa", placeholder: "Empresa" },
             { campo: "solicitud", placeholder: "Solicitud" },
             { campo: "ramoSeguro", placeholder: "Ramo de Seguro" },*/
        /*   { campo: "fechaSolicitud", placeholder: "Fecha Solicitud", hide: true },
           { campo: "solicitante", placeholder: "Solicitante" },
           { campo: "puesto", placeholder: "Puesto" },
           { campo: "departamento", placeholder: "Departamento" },
           { campo: "observaciones", placeholder: "Observaciones" }



       ],*/
        /* alineacion: [{ columnas: [0, 1, 2, 3, 4, 5, 6], alineacion: "left" }],*/
        formato: [{ columnas: ["fechaReclamacionTercero", "fechaReclamacionAseg", "fechaTramitePago"], tipo: "fecha", onlyDate: true },]
        /*{columnas: ["activosProcesados"], tipo: "estatus"}],*/
        /*
         rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial:moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
         accionesEffect: [{ botones: ["verPartidas"], ocultar: () => true /*esDispositivoMovil */ /*}],*/
        /*  selectFilter: [
              {
                  campo: "empresaID", title: "Empresa",
                  retorna: "number", limpiarFiltro: "Todos",
                  data: seleccionables.empresas ? seleccionables.empresas : [], grid: { md: 3, lg: 3 }
              },
              {
                  campo: "ramoSeguroID", title: "Ramo Seguro",
                  retorna: "number", limpiarFiltro: "Todos",
                  data: seleccionables.ramoSeguros ? seleccionables.ramoSeguros : [], grid: { md: 3, lg: 3 }
              },
              {
                  campo: "departamentoID", title: "Departamento",
                  retorna: "text", limpiarFiltro: "Todos",
                  data: seleccionables.departamentos ? seleccionables.departamentos : [], grid: { md: 3, lg: 3 }
              },
              {
                  campo: "estatus", title: "Estado",
                  retorna: "number", limpiarFiltro: "Todos",
                  data: estadoSolicitudSeguro ? estadoSolicitudSeguro : [], grid: { md: 3, lg: 3 }
              }
              ,
              {
                  campo: "solicitud", title: "Tipo Solicitud",
                  retorna: "text", limpiarFiltro: "Todos",
                  data: tipoSolicitudSeguro ? tipoSolicitudSeguro : [], grid: { md: 3, lg: 3 }
              }
              ]*/
    }


    const accionesFormularioVerReclAseg = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerReclAseg
    }];

    const accionesFormularioVerReclTerceros = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerReclTerceros
    }];

    const accionesFormularioVerPago = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerPago
    }];

    const accionesFormularioVerPolizaPrincipal = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerPolizaPrincipal
    }];

    const accionesFormularioVerReporteDanios = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerReporteDanios
    }];

    const listOpciones = [
        { label: 'NO', value: 0 },
        { label: 'SI', value: 1 }
    ]

    const listporcentajeIVA = [
        { label: '% 0', value: 0 },
        { label: '% 16', value: 16 }
    ]

    const tipoValorSumaAseg = [
        {
            label: 'VALOR CONVENIDO',
            value: 'VALOR CONVENIDO'
        },
        {
            label: 'VALOR COMERCIAL + 10%',
            value: 'VALOR COMERCIAL + 10%'
        },
        {
            label: 'VALOR DE REPOSICIÓN',
            value: 'VALOR DE REPOSICIÓN'
        },
        {
            label: 'AVALUO',
            value: 'AVALUO'
        },
        {
            label: 'VALOR FACTURA',
            value: 'VALOR FACTURA'
        },
    ]

    const listEstatusUnidad = [
        {
            value: 'Se embarco',
            label: 'Se embarcó'
        },
        {
            value: 'No se embarco',
            label: 'No se embarcó'
        },
        {
            value: 'Se desistio',
            label: 'Se desistió'
        },
        {
            value: 'NA',
            label: 'NA'
        }
    ]

    const inputsReclAseg = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "empresaID",
                    value: stateReclAseg.empresaID,
                    error: errorstateReclAseg.empresaID.error,
                    success: stateReclAseg.empresaID && !errorstateReclAseg.empresaID.error ? true : undefined,
                    helperText: errorstateReclAseg.empresaID.helperText,
                    inputRef: ReclAsegempresaIDRef,
                    title: "Empresa *",
                    placeholder: "Seleccione empresa",
                    grid: { md: 3, lg: 3 },
                    tipo: "select",
                    data: seleccionables.empresas
                },
                {
                    disabled: true,
                    id: "aseguradoraID",
                    value: stateReclAseg.aseguradoraID,
                    error: errorstateReclAseg.aseguradoraID.error,
                    success: stateReclAseg.aseguradoraID && !errorstateReclAseg.aseguradoraID.error ? true : undefined,
                    helperText: errorstateReclAseg.aseguradoraID.helperText,
                    inputRef: ReclAsegaseguradoraIDRef,
                    title: "Aseguradora",
                    grid: { md: 3, lg: 3 },
                    tipo: "select",
                    data: seleccionables.aseguradoras
                },
                {
                    disabled: true,
                    id: "estatus",
                    value: stateReclAseg.estatus,
                    checked: stateReclAseg.estatus,
                    title: "estatus",
                    inputRef: ReclAsegestatusRef,
                    grid: { md: 3, lg: 3 },
                    tipo: "select",
                    data: [
                        {
                            label: "ABIERTO",
                            value: "ABIERTO"
                        },
                        {
                            label: "PRESCRITO",
                            value: "PRESCRITO"
                        },
                        {
                            label: "CERRADO",
                            value: "CERRADO"
                        }
                    ]
                },
                {
                    disabled: true,
                    id: "fechaAfectado",
                    value: stateReclAseg.fechaAfectado,
                    error: errorstateReclAseg.fechaAfectado.error,
                    success: stateReclAseg.fechaAfectado && !errorstateReclAseg.fechaAfectado.error ? true : undefined,
                    helperText: errorstateReclAseg.fechaAfectado.helperText,
                    inputRef: ReclAsegfechaAfectadoRef,
                    title: "Fecha Daño *",
                    grid: { md: 3, lg: 3 },
                    placeholder: "Fecha Daño",
                    tipo: "datetimepicker",
                    onlyDate: true
                },
                {
                    disabled: true,
                    id: "siniestro",
                    value: stateReclAseg.siniestro,
                    error: errorstateReclAseg.siniestro.error,
                    success: stateReclAseg.siniestro && !errorstateReclAseg.siniestro.error ? true : undefined,
                    helperText: errorstateReclAseg.siniestro.helperText,
                    inputRef: ReclAsegsiniestroRef,
                    title: "No. Siniestro *",
                    placeholder: "Siniestro",
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled: true,
                    id: "numPoliza",
                    value: stateReclAseg.numPoliza,
                    error: errorstateReclAseg.numPoliza.error,
                    success: stateReclAseg.numPoliza && !errorstateReclAseg.numPoliza.error ? true : undefined,
                    helperText: errorstateReclAseg.numPoliza.helperText,
                    inputRef: ReclAsegnumPolizaRef,
                    title: "No. de Poliza * ",
                    placeholder: "Poliza",
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled: true,
                    id: "deducible",
                    value: stateReclAseg.deducible,
                    error: errorstateReclAseg.deducible.error,
                    success: stateReclAseg.deducible && !errorstateReclAseg.deducible.error ? true : undefined,
                    helperText: errorstateReclAseg.deducible.helperText,
                    inputRef: ReclAsegdeducibleRef,
                    title: "Deducible",
                    textAlign: "right",
                    posicionIcono: "start",
                    placeholder: "Deducible",
                    grid: { md: 4, lg: 4 }
                }, {
                    disabled: true,
                    id: "ramoSeguroID",
                    value: stateReclAseg.ramoSeguroID,
                    error: errorstateReclAseg.ramoSeguroID.error,
                    success: stateReclAseg.ramoSeguroID && !errorstateReclAseg.ramoSeguroID.error ? true : undefined,
                    helperText: errorstateReclAseg.ramoSeguroID.helperText,
                    inputRef: ReclAsegramoSeguroIDRef,
                    title: "Ramo de Seguro",
                    placeholder: "Seleccione el Ramo de Seguro",
                    grid: { md: 6, lg: 6 },
                    tipo: "autocomplete",
                    data: seleccionables.ramoSeguros
                },
                {
                    disabled: true,
                    id: "polizaID",
                    value: stateReclAseg.polizaID,
                    error: errorstateReclAseg.polizaID.error,
                    success: stateReclAseg.polizaID && !errorstateReclAseg.polizaID.error ? true : undefined,
                    helperText: errorstateReclAseg.polizaID.helperText,
                    inputRef: ReclAsegpolizaIDRef,
                    title: "Póliza de seguro",
                    placeholder: "Seleccione la póliza de seguro",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.polizasSeguro
                },
            ]
        },
        {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "equipoBienAfectado",
                    multiline: true,
                    value: stateReclAseg.equipoBienAfectado,
                    error: errorstateReclAseg.equipoBienAfectado.error,
                    success: stateReclAseg.equipoBienAfectado && !errorstateReclAseg.equipoBienAfectado.error ? true : undefined,
                    helperText: errorstateReclAseg.equipoBienAfectado.helperText,
                    inputRef: ReclAsegequipoBienAfectadoRef,
                    title: "Equipo o Bien Afectado *",
                    placeholder: "Especifique el bien dañado",
                    grid: { md: 12, lg: 12 }
                },
                {
                    disabled: true,
                    id: "descripEvento",
                    multiline: true,
                    value: stateReclAseg.descripEvento,
                    error: errorstateReclAseg.descripEvento.error,
                    success: stateReclAseg.descripEvento && !errorstateReclAseg.descripEvento.error ? true : undefined,
                    helperText: errorstateReclAseg.descripEvento.helperText,
                    inputRef: ReclAsegdescripEventoRef,
                    title: "Descripción del Evento *",
                    placeholder: "Especifique la descripción del evento",
                    grid: { md: 12, lg: 12 }
                },
                {
                    disabled: true,
                    id: "maquinaria",
                    value: stateReclAseg.maquinaria,
                    error: errorstateReclAseg.maquinaria.error,
                    success: stateReclAseg.maquinaria && !errorstateReclAseg.maquinaria.error ? true : undefined,
                    helperText: errorstateReclAseg.maquinaria.helperText,
                    inputRef: ReclAsegmaquinariaRef,
                    title: "Maquinaria *",
                    placeholder: "Especifique la maquinaria",
                    grid: { md: 6, lg: 6 }
                },
                {
                    disabled: true,
                    id: "operador",
                    value: stateReclAseg.operador,
                    error: errorstateReclAseg.operador.error,
                    success: stateReclAseg.operador && !errorstateReclAseg.operador.error ? true : undefined,
                    helperText: errorstateReclAseg.operador.helperText,
                    inputRef: ReclAsegoperadorRef,
                    title: "Operador *",
                    placeholder: "Especifique el operador",
                    grid: { md: 6, lg: 6 },
                },
                {
                    disabled: true,
                    id: "ajustador",
                    value: stateReclAseg.ajustador,
                    error: errorstateReclAseg.ajustador.error,
                    success: stateReclAseg.ajustador && !errorstateReclAseg.ajustador.error ? true : undefined,
                    helperText: errorstateReclAseg.ajustador.helperText,
                    inputRef: ReclAsegajustadorRef,
                    title: "Ajustador *",
                    placeholder: "Especifique el ajustador",
                    grid: { md: 6, lg: 6 },
                },
                {
                    disabled: true,
                    id: "indemnizaPagada",
                    value: stateReclAseg.indemnizaPagada,
                    error: errorstateReclAseg.indemnizaPagada.error,
                    success: stateReclAseg.indemnizaPagada && !errorstateReclAseg.indemnizaPagada.error ? true : undefined,
                    helperText: errorstateReclAseg.indemnizaPagada.helperText,
                    inputRef: ReclAsegindemnizaPagadaRef,
                    title: "Indemniza Pagada ",
                    placeholder: "Especifique el indemnizaPagada",
                    grid: { md: 6, lg: 6 }
                },
            ]
        },
        {
            id: "Abajo",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "fechaReclamacion",
                    value: stateReclAseg.fechaReclamacion,
                    error: errorstateReclAseg.fechaReclamacion.error,
                    success: stateReclAseg.fechaReclamacion && !errorstateReclAseg.fechaReclamacion.error ? true : undefined,
                    helperText: errorstateReclAseg.fechaReclamacion.helperText,
                    inputRef: ReclAsegfechaReclamacionRef,
                    title: "Fecha Reclamación *",
                    grid: { md: 6, lg: 6 },
                    placeholder: "Fecha Reclamación",
                    tipo: "datetimepicker",
                    onlyDate: true
                },
                {
                    disabled: true,
                    id: "fechaPago",
                    value: stateReclAseg.fechaPago,
                    error: errorstateReclAseg.fechaPago.error,
                    success: stateReclAseg.fechaPago && !errorstateReclAseg.fechaPago.error ? true : undefined,
                    helperText: errorstateReclAseg.fechaPago.helperText,
                    inputRef: ReclAsegfechaPagoRef,
                    title: "Fecha de Pago *",
                    grid: { md: 6, lg: 6 },
                    placeholder: "Fecha Pago",
                    tipo: "datetimepicker",
                    onlyDate: true
                }
            ]
        }
    ];


    const inputsReclTerceros = [
        {
            disabled: true,
            id: "empresaID",
            value: stateReclTerceros.empresaID,
            error: errorstateReclTerceros.empresaID.error,
            success: stateReclTerceros.empresaID && !errorstateReclTerceros.empresaID.error ? true : undefined,
            helperText: errorstateReclTerceros.empresaID.helperText,
            inputRef: ReclTercerosempresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccione empresa",
            grid: { md: 4, lg: 4 },
            tipo: "autocomplete",
            data: seleccionables.empresas,
        },
        {
            disabled: true,
            id: "fechaAfectado",
            value: stateReclTerceros.fechaAfectado,
            error: errorstateReclTerceros.fechaAfectado.error,
            success: stateReclTerceros.fechaAfectado && !errorstateReclTerceros.fechaAfectado.error ? true : undefined,
            helperText: errorstateReclTerceros.fechaAfectado.helperText,
            inputRef: ReclTercerosfechaAfectadoRef,
            title: "Fecha Daño *",
            grid: { md: 4, lg: 4 },
            placeholder: "Fecha Daño",
            tipo: "datetimepicker",
            onlyDate: true
        },
        {
            disabled: true,
            id: "infoIdentifTercero",
            value: stateReclTerceros.infoIdentifTercero,
            error: errorstateReclTerceros.infoIdentifTercero.error,
            success: stateReclTerceros.infoIdentifTercero && !errorstateReclTerceros.infoIdentifTercero.error ? true :
                undefined,
            helperText: errorstateReclTerceros.infoIdentifTercero.helperText,
            inputRef: ReclTercerosinfoIdentifTerceroRef,
            title: "Información de Identificación del Tercero *",
            placeholder: "Especifique el información de identificación del tercero",
            grid: { md: 4, lg: 4 }
        },
        {
            disabled: true,
            id: "asegDelTercero",
            value: stateReclTerceros.asegDelTercero,
            error: errorstateReclTerceros.asegDelTercero.error,
            success: stateReclTerceros.asegDelTercero && !errorstateReclTerceros.asegDelTercero.error ? true :
                undefined,
            helperText: errorstateReclTerceros.asegDelTercero.helperText,
            inputRef: ReclTercerosasegDelTerceroRef,
            title: "Aseguradora del Tercero",
            placeholder: "Especifique el aseguradora del tercero",
            grid: { md: 6, lg: 6 }
        },
        {
            disabled: true,
            id: "ajustadorRespresentante",
            value: stateReclTerceros.ajustadorRespresentante,
            error: errorstateReclTerceros.ajustadorRespresentante.error,
            success: stateReclTerceros.ajustadorRespresentante && !errorstateReclTerceros.ajustadorRespresentante.error ? true :
                undefined,
            helperText: errorstateReclTerceros.ajustadorRespresentante.helperText,
            inputRef: ReclTercerosajustadorRespresentanteRef,
            title: "Ajustador Responsable del Tercero",
            placeholder: "Especifique el nombre del ajustador responsable del tercero",
            grid: { md: 6, lg: 6 }
        },
        {
            disabled: true,
            id: "noSiniestro",
            value: stateReclTerceros.noSiniestro,
            error: errorstateReclTerceros.noSiniestro.error,
            success: stateReclTerceros.noSiniestro && !errorstateReclTerceros.noSiniestro.error ? true :
                undefined,
            helperText: errorstateReclTerceros.noSiniestro.helperText,
            inputRef: ReclTercerosnoSiniestroRef,
            title: "Número de Siniestro",
            placeholder: "Especifique el número de siniestro",
            grid: { md: 3, lg: 3 }
        },
        {
            disabled: true,
            id: "noPoliza",
            value: stateReclTerceros.noPoliza,
            error: errorstateReclTerceros.noPoliza.error,
            success: stateReclTerceros.noPoliza && !errorstateReclTerceros.noPoliza.error ? true :
                undefined,
            helperText: errorstateReclTerceros.noPoliza.helperText,
            inputRef: ReclTercerosnoPolizaRef,
            title: "Número de Póliza del Tercero",
            placeholder: "Especifique el número de póliza del tercero",
            grid: { md: 3, lg: 3 }
        },
        {
            disabled: true,
            id: "bienAfectado",
            multiline: true,
            value: stateReclTerceros.bienAfectado,
            error: errorstateReclTerceros.bienAfectado.error,
            success: stateReclTerceros.bienAfectado && !errorstateReclTerceros.bienAfectado.error ? true :
                undefined,
            helperText: errorstateReclTerceros.bienAfectado.helperText,
            inputRef: ReclTercerosbienAfectadoRef,
            title: "Bien Dañado *",
            placeholder: "Especifique el bien dañado",
            grid: { md: 6, lg: 6 }
        },
        {
            disabled: true,
            id: "descripEvento",
            multiline: true,
            value: stateReclTerceros.descripEvento,
            error: errorstateReclTerceros.descripEvento.error,
            success: stateReclTerceros.descripEvento && !errorstateReclTerceros.descripEvento.error ? true :
                undefined,
            helperText: errorstateReclTerceros.descripEvento.helperText,
            inputRef: ReclTercerosdescripEventoRef,
            title: "Descripción del Evento *",
            placeholder: "Especifique la descripción del evento",
            grid: { md: 12, lg: 12 }
        },
        {
            disabled: true,
            id: "ubicacion",
            value: stateReclTerceros.ubicacion,
            error: errorstateReclTerceros.ubicacion.error,
            success: stateReclTerceros.ubicacion && !errorstateReclTerceros.ubicacion.error ? true :
                undefined,
            helperText: errorstateReclTerceros.ubicacion.helperText,
            inputRef: ReclTercerosubicacionRef,
            title: "Ubicación",
            placeholder: "Especifique la ubicación",
            grid: { md: 4, lg: 4 }
        },
        {
            disabled: true,
            id: "estatus",
            value: stateReclTerceros.estatus,
            error: errorstateReclTerceros.estatus.error,
            success: stateReclTerceros.estatus && !errorstateReclTerceros.estatus.error ? true :
                undefined,
            helperText: errorstateReclTerceros.estatus.helperText,
            inputRef: ReclTercerosestatusRef,
            title: "Estatus*",
            placeholder: "Especifique el estatus",
            tipo: "select",
            data: estadoReclamacionSiniestro,
            grid: { md: 4, lg: 4 }
        },
        {
            disabled: true,
            id: "montoPagado",
            value: stateReclTerceros.montoPagado,
            error: errorstateReclTerceros.montoPagado.error,
            success: stateReclTerceros.montoPagado && !errorstateReclTerceros.montoPagado.error ? true :
                undefined,
            helperText: errorstateReclTerceros.montoPagado.helperText,
            inputRef: ReclTercerosmontoPagadoRef,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            title: "Monto Pagado*",
            textAlign: "right",
            placeholder: "0",
            grid: { md: 4, lg: 4 }
        },
        {
            disabled: true,
            id: "fechaReclamacion",
            value: stateReclTerceros.fechaReclamacion,
            error: errorstateReclTerceros.fechaReclamacion.error,
            success: stateReclTerceros.fechaReclamacion && !errorstateReclTerceros.fechaReclamacion.error ? true : undefined,
            helperText: errorstateReclTerceros.fechaReclamacion.helperText,
            inputRef: ReclTercerosfechaReclamacionRef,
            title: "Fecha Reclamación *",
            grid: { md: 4, lg: 4 },
            placeholder: "Fecha Reclamación",
            tipo: "datetimepicker",
            onlyDate: true
        },
        {
            disabled: true,
            id: "fechaIndemnizacion",
            value: stateReclTerceros.fechaIndemnizacion,
            error: errorstateReclTerceros.fechaIndemnizacion.error,
            success: stateReclTerceros.fechaIndemnizacion && !errorstateReclTerceros.fechaIndemnizacion.error ? true : undefined,
            helperText: errorstateReclTerceros.fechaIndemnizacion.helperText,
            inputRef: ReclTercerosfechaIndemnizacionRef,
            title: "Fecha Indemnización *",
            grid: { md: 4, lg: 4 },
            placeholder: "Fecha Indemnización",
            tipo: "datetimepicker",
            onlyDate: true
        }, {
            disabled: true,
            id: "noDiasHabilesTrans",
            value: stateReclTerceros.noDiasHabilesTrans,
            error: errorstateReclTerceros.noDiasHabilesTrans.error,
            success: stateReclTerceros.noDiasHabilesTrans && !errorstateReclTerceros.noDiasHabilesTrans.error ? true :
                undefined,
            helperText: errorstateReclTerceros.noDiasHabilesTrans.helperText,
            inputRef: ReclTercerosnoDiasHabilesTransRef,
            title: "Dias Transcurridos",
            placeholder: "Dias transcurridos",
            grid: { md: 4, lg: 4 }
        }, {
            disabled: true,
            id: "atendio",
            value: stateReclTerceros.atendio,
            error: errorstateReclTerceros.atendio.error,
            success: stateReclTerceros.atendio && !errorstateReclTerceros.atendio.error ? true :
                undefined,
            helperText: errorstateReclTerceros.atendio.helperText,
            inputRef: ReclTercerosatendioRef,
            title: "Atendio *",
            placeholder: "Especifique el nombre de quien atendio",
            grid: { md: 6, lg: 6 }
        }
    ];

    const inputsPago = [

        {
            id: "Datos del Pago",
            tipo: "fieldset",
            leyenda: "Datos del Pago",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "fechaTramite",
                    value: statePago.fechaTramite,
                    error: errorStatePago.fechaTramite.error,
                    success: statePago.fechaTramite && !errorStatePago.fechaTramite.error ? true : undefined,
                    helperText: errorStatePago.fechaTramite.helperText,
                    placeholder: "Fecha Tramite",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled: true,
                    id: "empresaID",
                    value: statePago.empresaID,
                    error: errorStatePago.empresaID.error,
                    success: Array.isArray(statePago.empresaID) && statePago.empresaID.length > 0 && statePago.empresaID && !errorStatePago.empresaID.error ? true : undefined,
                    helperText: errorStatePago.empresaID.helperText,
                    title: "Empresa *",
                    placeholder: "Seleccionar empresa",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.empresas
                },
                {
                    disabled: true,
                    id: "aseguradoraID",
                    value: statePago.aseguradoraID,
                    error: errorStatePago.aseguradoraID.error,
                    success: Array.isArray(statePago.aseguradoraID) && statePago.aseguradoraID.length > 0 && statePago.aseguradoraID && !errorStatePago.aseguradoraID.error ? true : undefined,
                    helperText: errorStatePago.aseguradoraID.helperText,
                    title: "Aseguradora ",
                    placeholder: "Seleccionar Aseguradora",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.aseguradoras
                },
                {
                    disabled: true,
                    id: "agenteSegID",
                    value: statePago.agenteSegID,
                    error: errorStatePago.agenteSegID.error,
                    success: Array.isArray(statePago.agenteSegID) && statePago.agenteSegID.length > 0 && statePago.agenteSegID && !errorStatePago.agenteSegID.error ? true : undefined,
                    helperText: errorStatePago.agenteSegID.helperText,
                    title: "Agente de Seguros *",
                    placeholder: "Seleccionar Agente",
                    grid: { md: 3, lg: 3 },
                    tipo: "autocomplete",
                    data: seleccionables.agenteSeguros
                },
                {
                    disabled: true,
                    id: "descripcion",
                    value: statePago.descripcion,
                    error: errorStatePago.descripcion.error,
                    success: statePago.descripcion && !errorStatePago.descripcion.error ? true : undefined,
                    helperText: errorStatePago.descripcion.helperText,
                    title: "Descripción",
                    placeholder: "Capture la Descripción",
                    grid: { md: 6, lg: 6 }
                },
                {
                    disabled: true,
                    id: "folioTramite",
                    value: statePago.folioTramite,
                    error: errorStatePago.folioTramite.error,
                    success: statePago.folioTramite && !errorStatePago.folioTramite.error ? true : undefined,
                    helperText: errorStatePago.folioTramite.helperText,
                    title: "Folio de Tramite ",
                    placeholder: "Capture el Folio de Tramite",
                    grid: { md: 3, lg: 3 }
                }, {
                    disabled: true,
                    id: "divisaID",
                    value: statePago.divisaID,
                    error: errorStatePago.divisaID.error,
                    success: Array.isArray(statePago.divisaID) && statePago.divisaID.length > 0 && statePago.divisaID && !errorStatePago.divisaID.error ? true : undefined,
                    helperText: errorStatePago.divisaID.helperText,
                    title: "Divisa",
                    placeholder: "Seleccionar la Divisa",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listDivisas,
                },
                {
                    disabled: true,
                    id: "paridad",
                    value: statePago.paridad,
                    title: "Paridad Actual ",
                    textAlign: "right",
                    placeholder: "Paridad Actual",
                    grid: { md: 2, lg: 2 },
                },
                {
                    disabled: true,
                    id: "ramoSeguroID",
                    value: statePago.ramoSeguroID,
                    error: errorStatePago.ramoSeguroID.error,
                    success: Array.isArray(statePago.ramoSeguroID) && statePago.ramoSeguroID.length > 0 && statePago.ramoSeguroID && !errorStatePago.ramoSeguroID.error ? true : undefined,
                    helperText: errorStatePago.ramoSeguroID.helperText,
                    title: "Seguro *",
                    placeholder: "Seleccionar Ramo de seguro",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.ramoSeguros,
                },
                {
                    disabled: true,
                    id: "solicitado",
                    value: statePago.solicitado,
                    error: errorStatePago.solicitado.error,
                    success: statePago.solicitado && !errorStatePago.solicitado.error ? true : undefined,
                    helperText: errorStatePago.solicitado.helperText,
                    placeholder: "Fecha Solicitado",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 2, lg: 2 },
                },
                {
                    disabled: true,
                    id: "realizado",
                    value: statePago.realizado,
                    error: errorStatePago.realizado.error,
                    success: statePago.realizado && !errorStatePago.realizado.error ? true : undefined,
                    helperText: errorStatePago.realizado.helperText,
                    placeholder: "Fecha Realizado",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 2, lg: 2 },
                },
                {
                    disabled: true,
                    id: "importePago",
                    value: statePago.importePago,
                    error: errorStatePago.importePago.error,
                    success: statePago.importePago && !errorStatePago.importePago.error ? true : undefined,
                    helperText: errorStatePago.importePago.helperText,
                    title: "Prima Neta *",
                    placeholder: "Capture la Prima Neta",
                    grid: { md: 2, lg: 2 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                },
                {
                    disabled: true,
                    id: "gastosExpedicion",
                    value: statePago.gastosExpedicion,
                    title: "Gastos de expedición *",
                    placeholder: "Capture los Gastos de Expedición",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                },
                {
                    disabled: true,
                    id: "porcentajeIVA",
                    value: statePago.porcentajeIVA,
                    title: "Porcentaje IVA",
                    placeholder: "% IVA",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listporcentajeIVA,
                },
                {
                    disabled: true,
                    id: "iva",
                    value: statePago.iva,
                    title: "IVA *",
                    placeholder: "Capture el IVA",
                    grid: { md: 2, lg: 2 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                },
                {
                    disabled: true,
                    id: "total",
                    value: statePago.total,
                    title: "Total *",
                    placeholder: "Capture el Total",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                },
                {
                    disabled: true,
                    id: "pdf",
                    value: statePago.pdf,
                    checked: statePago.pdf,
                    title: "Factura",
                    tipo: "switch",
                    grid: { md: 4, lg: 4 },
                },
                {
                    disabled: true,
                    id: "xml",
                    value: statePago.xml,
                    checked: statePago.xml,
                    title: "XML",
                    tipo: "switch",
                    grid: { md: 4, lg: 4 },
                },
                {
                    disabled: true,
                    id: "docOrig",
                    value: statePago.docOrig,
                    checked: statePago.docOrig,
                    title: "Doc originales",
                    tipo: "switch",
                    grid: { md: 4, lg: 4 },
                },
                {
                    disabled: true,
                    id: "folioFactura",
                    value: statePago.folioFactura,
                    error: errorStatePago.folioFactura.error,
                    success: statePago.folioFactura && !errorStatePago.folioFactura.error ? true : undefined,
                    helperText: errorStatePago.folioFactura.helperText,
                    title: "No. Factura *",
                    placeholder: "Capture el folio",
                    grid: { md: 6, lg: 6 },
                },
                {
                    disabled: true,
                    id: "estatus",
                    value: statePago.estatus,
                    error: errorStatePago.estatus.error,
                    success: Array.isArray(statePago.estatus) && statePago.estatus.length > 0 && statePago.estatus && !errorStatePago.estatus.error ? true : undefined,
                    helperText: errorStatePago.estatus.helperText,
                    title: "Estatus",
                    placeholder: "Seleccionar el estatus",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listEstatus,
                }, {
                    disabled: true,
                    id: "devolucion",
                    value: statePago.devolucion,
                    title: "¿Es una devolución / Indemnización? *",
                    placeholder: "Seleccione una Opción",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listOpciones,
                }, {
                    disabled: true,
                    id: "notaCredito",
                    value: statePago.notaCredito,
                    title: "¿Tiene la nota de crédito? *",
                    placeholder: "Seleccione una Opción",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listOpciones,
                },
                {
                    disabled: true,
                    id: "observaciones",
                    value: statePago.observaciones,
                    error: errorStatePago.observaciones.error,
                    success: statePago.observaciones && !errorStatePago.observaciones.error ? true : undefined,
                    helperText: errorStatePago.observaciones.helperText,
                    title: "Observaciones ",
                    placeholder: "Capture las Observaciones",
                    grid: { md: 12, lg: 12 },
                    multiline: true,
                },
            ]
        },
        {
            id: "Datos de Póliza",
            tipo: "fieldset",
            leyenda: "Datos de Póliza",
            grid: { lg: 12 },
            inputs: [

                {
                    disabled: true,
                    id: "polizaID",
                    value: statePago.polizaID,
                    error: errorStatePago.polizaID.error,
                    success: statePago.polizaID && !errorStatePago.polizaID.error ? true : undefined,
                    helperText: errorStatePago.polizaID.helperText,
                    title: "Póliza",
                    placeholder: "Seleccionar póliza *",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.polizasSeguro,
                },
                {
                    disabled: true,
                    id: "poliza",
                    value: statePago.poliza,
                    error: errorStatePago.poliza.error,
                    success: statePago.poliza && !errorStatePago.poliza.error ? true : undefined,
                    helperText: errorStatePago.poliza.helperText,
                    title: "Número de Póliza *",
                    placeholder: "Capturar Nuevo Número de Póliza",
                    grid: { md: 6, lg: 6 },
                },
                {
                    disabled: true,
                    id: "endoso",
                    value: statePago.endoso,
                    error: errorStatePago.endoso.error,
                    success: statePago.endoso && !errorStatePago.endoso.error ? true : undefined,
                    helperText: errorStatePago.endoso.helperText,
                    title: "Endoso ",
                    placeholder: "Capturar Nuevo Endoso",
                    grid: { md: 6, lg: 6 },
                },
                {
                    disabled: true,
                    id: "inciso",
                    value: statePago.inciso,
                    title: "Inciso",
                    placeholder: "Capturar el Inciso",
                    grid: { md: 6, lg: 6 },
                },
                {
                    disabled: true,
                    id: "vigenciaIni",
                    value: statePago.vigenciaIni,
                    error: errorStatePago.vigenciaIni.error,
                    success: statePago.vigenciaIni && !errorStatePago.vigenciaIni.error ? true : undefined,
                    helperText: errorStatePago.vigenciaIni.helperText,
                    placeholder: "Fecha Inicio Vigencia *",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 },
                },
                {
                    disabled: true,
                    id: "vigenciaHasta",
                    value: statePago.vigenciaHasta,
                    error: errorStatePago.vigenciaHasta.error,
                    success: statePago.vigenciaHasta && !errorStatePago.vigenciaHasta.error ? true : undefined,
                    helperText: errorStatePago.vigenciaHasta.helperText,
                    placeholder: "Fecha Final de Vigencia *",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 },
                },
                {
                    disabled: true,
                    id: "diasVigencia",
                    value: statePago.diasVigencia,
                    title: "Días vigencia",
                    placeholder: "Capture los días de vigencia",
                    grid: { md: 4, lg: 4 },
                    textAlign: 'right',
                    posicionIcono: "start",
                    formatoImportes: false,
                }
            ]
        }

    ];

    let inputsPolizaPrincipal = [];

    if (statePolPrincipal.seleccionTipoPoliza == 1) {
        inputsPolizaPrincipal.push(
            {
                id: "cTopAPDS",
                tipo: "fieldset",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "seleccionTipoPoliza",
                        value: statePolPrincipal.seleccionTipoPoliza,
                        error: errorStatePolPrincipal.seleccionTipoPoliza.error,
                        success: statePolPrincipal.seleccionTipoPoliza && !errorStatePolPrincipal.seleccionTipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.seleccionTipoPoliza.helperText,
                        title: "Sencilla / Múltiple",
                        placeholder: "Sencilla / Múltiple",
                        tipo: "autocomplete",
                        data: [{ label: 'Sencilla', value: 1 }, { label: 'Multiple', value: 2 }],
                        grid: { md: 12, lg: 12 }
                    }
                ]
            },
            // :
            {
                id: "Arriba izquierdo",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "empresaID",
                        value: statePolPrincipal.empresaID,
                        error: errorStatePolPrincipal.empresaID.error,
                        success: statePolPrincipal.empresaID && !errorStatePolPrincipal.empresaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.empresaID.helperText,
                        inputRef: PolPrincipalempresaIDRef,
                        title: "Empresa *",
                        placeholder: "Seleccione una Empresa",
                        tipo: "autocomplete",
                        data: seleccionables.empresas,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "ramoSeguroID",
                        value: statePolPrincipal.ramoSeguroID,
                        error: errorStatePolPrincipal.ramoSeguroID.error,
                        success: statePolPrincipal.ramoSeguroID && !errorStatePolPrincipal.ramoSeguroID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.ramoSeguroID.helperText,
                        inputRef: PolPrincipalramoSeguroIDRef,
                        title: "Ramo de Seguro *",
                        placeholder: "Seleccione una Ramo",
                        tipo: "autocomplete",
                        data: seleccionables.ramoSeguros,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "aseguradoraID",
                        value: statePolPrincipal.aseguradoraID,
                        error: errorStatePolPrincipal.aseguradoraID.error,
                        success: statePolPrincipal.aseguradoraID && !errorStatePolPrincipal.aseguradoraID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.aseguradoraID.helperText,
                        inputRef: PolPrincipalaseguradoraIDRef,
                        title: "Aseguradora *",
                        placeholder: "Seleccione una Aseguradora",
                        tipo: "autocomplete",
                        data: seleccionables.aseguradoras,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "tipoPoliza",
                        value: statePolPrincipal.tipoPoliza,
                        error: errorStatePolPrincipal.tipoPoliza.error,
                        success: statePolPrincipal.tipoPoliza && !errorStatePolPrincipal.tipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoPoliza.helperText,
                        inputRef: PolPrincipaltipoPolizaRef,
                        title: "Tipo de Póliza *",
                        placeholder: "Seleccione un tipo",
                        tipo: "select",
                        data: aTiposPoliza,
                        grid: { md: 5, lg: 5 }
                    },
                    {
                        hidden: statePolPrincipal.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                        disabled: true,
                        id: "derechoPoliza",
                        value: statePolPrincipal.derechoPoliza,
                        // error: errorStatePolPrincipal.derechoPoliza.error,
                        //success: statePolPrincipal.derechoPoliza && !errorStatePolPrincipal.derechoPoliza.error ? true : undefined,
                        // helperText: errorStatePolPrincipal.derechoPoliza.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        title: "Derecho de Póliza *",
                        placeholder: "Capture el Derecho de Póliza",
                        grid: { md: 7, lg: 7 }
                    },
                    {
                        disabled: true,
                        id: "polizaOrigenID ",
                        value: statePolPrincipal.polizaOrigenID == null ? "0" : statePolPrincipal.polizaOrigenID,
                        error: errorStatePolPrincipal.polizaOrigenID.error,
                        success: statePolPrincipal.polizaOrigenID && !errorStatePolPrincipal.polizaOrigenID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.polizaOrigenID.helperText,
                        inputRef: PolPrincipalpolizaOrigenIDRef,
                        title: "Póliza Principal *",
                        placeholder: "Seleccione la poliza Principal",
                        tipo: "autocomplete",
                        data: seleccionables.polizasSeguro,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "divisaID",
                        value: statePolPrincipal.divisaID,
                        error: errorStatePolPrincipal.divisaID.error,
                        success: statePolPrincipal.divisaID && !errorStatePolPrincipal.divisaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.divisaID.helperText,
                        inputRef: PolPrincipaldivisaIDRef,
                        title: "Divisa",
                        placeholder: "Seleccionar la Divisa",
                        grid: { md: 6, lg: 6 },
                        tipo: "autocomplete",
                        data: listDivisas
                    },
                    {
                        disabled: true,
                        id: "paridad",
                        value: statePolPrincipal.paridad,
                        error: errorStatePolPrincipal.paridad.error,
                        //success: statePolPrincipal.paridad && !errorStatePolPrincipal.paridad.error ? true : undefined,
                        helperText: errorStatePolPrincipal.paridad.helperText,
                        title: "Paridad Actual ",
                        textAlign: "right",
                        placeholder: "Paridad Actual",
                        grid: { md: 6, lg: 6 }
                    }
                ]
            },
            {
                id: "Arriba derecho",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "numero",
                        value: statePolPrincipal.numero,
                        error: errorStatePolPrincipal.numero.error,
                        success: statePolPrincipal.numero && !errorStatePolPrincipal.numero.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numero.helperText,
                        inputRef: PolPrincipalnumeroRef,
                        title: "No. de Póliza *",
                        placeholder: "Capture el No. de Póliza",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "numpolEndoso",
                        value: statePolPrincipal.numpolEndoso,
                        error: errorStatePolPrincipal.numpolEndoso.error,
                        success: statePolPrincipal.numpolEndoso && !errorStatePolPrincipal.numpolEndoso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numpolEndoso.helperText,
                        inputRef: PolPrincipalnumpolEndosoRef,
                        title: "Endoso",
                        placeholder: "Capture el endoso",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "inciso",
                        value: statePolPrincipal.inciso,
                        error: errorStatePolPrincipal.inciso.error,
                        //success: statePolPrincipal.inciso && !errorStatePolPrincipal.inciso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.inciso.helperText,
                        title: "Inciso",
                        placeholder: "Capture el inciso",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "tipoValorAseg",
                        value: statePolPrincipal.tipoValorAseg,
                        error: errorStatePolPrincipal.tipoValorAseg.error,
                        success: statePolPrincipal.tipoValorAseg && !errorStatePolPrincipal.tipoValorAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoValorAseg.helperText,
                        inputRef: PolPrincipaltipoValorAsegRef,
                        title: "Tipo de Valor Suma Asegurada",
                        tipo: "select",
                        data: tipoValorSumaAseg,
                        placeholder: "Capture el tipo de valor asegurado",
                        grid: { md: 8, lg: 8 }
                    },
                    {
                        disabled,
                        id: "sumaAseg",
                        value: statePolPrincipal.sumaAseg,
                        error: errorStatePolPrincipal.sumaAseg.error,
                        success: statePolPrincipal.sumaAseg && !errorStatePolPrincipal.sumaAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.sumaAseg.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        inputRef: PolPrincipalsumaAsegRef,
                        title: "Suma Asegurada",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "primaNeta",
                        value: statePolPrincipal.primaNeta,
                        error: errorStatePolPrincipal.primaNeta.error,
                        success: statePolPrincipal.primaNeta && !errorStatePolPrincipal.primaNeta.error ? true : undefined,
                        helperText: errorStatePolPrincipal.primaNeta.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        inputRef: PolPrincipalprimaNetaRef,
                        title: "Prima Neta",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "fpPromedio",
                        value: statePolPrincipal.fpPromedio,
                        error: errorStatePolPrincipal.fpPromedio.error,
                        success: statePolPrincipal.fpPromedio && !errorStatePolPrincipal.fpPromedio.error ? true : undefined,
                        helperText: errorStatePolPrincipal.fpPromedio.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="%" />,
                        posicionIcono: "end",
                        inputRef: PolPrincipalfpPromedioRef,
                        title: "Promedio",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "horasInvertidas",
                        value: statePolPrincipal.horasInvertidas,
                        error: errorStatePolPrincipal.horasInvertidas.error,
                        success: statePolPrincipal.horasInvertidas && !errorStatePolPrincipal.horasInvertidas.error ? true : undefined,
                        helperText: errorStatePolPrincipal.horasInvertidas.helperText,
                        textAlign: "right",
                        inputRef: PolPrincipalhorasInvertidasRef,
                        title: "Horas Invertidas",
                        placeholder: "Capture las horas invertidas",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "Beneficiario",
                        value: statePolPrincipal.beneficiarioPreferente,
                        error: errorStatePolPrincipal.beneficiarioPreferente.error,
                        //success: statePolPrincipal.beneficiarioPreferente && !errorStatePolPrincipal.beneficiarioPreferente.error ? true : undefined,
                        helperText: errorStatePolPrincipal.beneficiarioPreferente.helperText,
                        title: "Beneficiario Preferente",
                        placeholder: "Capture el nombre del beneficiario",
                        grid: { md: 12, lg: 12 }
                    }
                ]
            },
            {
                id: "fechas",
                tipo: "fieldset",
                leyenda: "▀",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "fechaSolCot",
                        value: statePolPrincipal.fechaSolCot,
                        error: errorStatePolPrincipal.fechaSolCot.error,
                        success: statePolPrincipal.fechaSolCot && !errorStatePolPrincipal.fechaSolCot.error ? true : undefined,
                        helperText: errorStatePolPrincipal.fechaSolCot.helperText,
                        inputRef: PolPrincipalfechaSolCotRef,
                        title: "Fecha Sol. Cotizacion.",
                        placeholder: "Fecha de Solicitud",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaIni",
                        value: statePolPrincipal.vigenciaIni,
                        error: errorStatePolPrincipal.vigenciaIni.error,
                        success: statePolPrincipal.vigenciaIni && !errorStatePolPrincipal.vigenciaIni.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaIni.helperText,
                        inputRef: PolPrincipalvigenciaIniRef,
                        placeholder: "Fecha Vigencia de Inicio",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaHasta",
                        value: statePolPrincipal.vigenciaHasta,
                        error: errorStatePolPrincipal.vigenciaHasta.error,
                        success: statePolPrincipal.vigenciaHasta && !errorStatePolPrincipal.vigenciaHasta.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaHasta.helperText,
                        inputRef: PolPrincipalvigenciaHastaRef,
                        title: "Vigencia hasta",
                        placeholder: "Fecha de Vencimiento",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    }, {
                        disabled: true,
                        id: "renovacion",
                        value: statePolPrincipal.renovacion,
                        checked: statePolPrincipal.renovacion,
                        title: "Renovar al Termino de Vigencia",
                        tipo: "switch",
                        grid: { md: 3, lg: 3 },
                    }]
            }, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "exclusiones",
                    value: statePolPrincipal.exclusiones,
                    error: errorStatePolPrincipal.exclusiones.error,
                    success: statePolPrincipal.exclusiones && !errorStatePolPrincipal.exclusiones.error ? true : undefined,
                    helperText: errorStatePolPrincipal.exclusiones.helperText,
                    inputRef: PolPrincipalexclusionesRef,
                    multiline: true,
                    title: "Exclusiones *",
                    placeholder: "Capture las exclusiones",
                    grid: { md: 12, lg: 12 }
                }]
        }
        )
    }
    else {
        inputsPolizaPrincipal.push(
            {
                id: "cTopAPDS",
                tipo: "fieldset",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "seleccionTipoPoliza",
                        value: statePolPrincipal.seleccionTipoPoliza,
                        error: errorStatePolPrincipal.seleccionTipoPoliza.error,
                        success: statePolPrincipal.seleccionTipoPoliza && !errorStatePolPrincipal.seleccionTipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.seleccionTipoPoliza.helperText,
                        title: "Sencilla / Múltiple",
                        placeholder: "Sencilla / Múltiple",
                        tipo: "autocomplete",
                        data: [{ label: 'Sencilla', value: 1 }, { label: 'Multiple', value: 2 }],
                        grid: { md: 12, lg: 12 }
                    }
                ]
            },
            // :
            {
                id: "Arriba izquierdo",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "empresaID",
                        value: statePolPrincipal.empresaID,
                        error: errorStatePolPrincipal.empresaID.error,
                        success: statePolPrincipal.empresaID && !errorStatePolPrincipal.empresaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.empresaID.helperText,
                        inputRef: PolPrincipalempresaIDRef,
                        title: "Empresa *",
                        placeholder: "Seleccione una Empresa",
                        tipo: "autocomplete",
                        data: seleccionables.empresas,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "ramoSeguroID",
                        value: statePolPrincipal.ramoSeguroID,
                        error: errorStatePolPrincipal.ramoSeguroID.error,
                        success: statePolPrincipal.ramoSeguroID && !errorStatePolPrincipal.ramoSeguroID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.ramoSeguroID.helperText,
                        inputRef: PolPrincipalramoSeguroIDRef,
                        title: "Ramo de Seguro *",
                        placeholder: "Seleccione una Ramo",
                        tipo: "autocomplete",
                        data: seleccionables.ramoSeguros,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "aseguradoraID",
                        value: statePolPrincipal.aseguradoraID,
                        error: errorStatePolPrincipal.aseguradoraID.error,
                        success: statePolPrincipal.aseguradoraID && !errorStatePolPrincipal.aseguradoraID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.aseguradoraID.helperText,
                        inputRef: PolPrincipalaseguradoraIDRef,
                        title: "Aseguradora *",
                        placeholder: "Seleccione una Aseguradora",
                        tipo: "autocomplete",
                        data: seleccionables.aseguradoras,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "tipoPoliza",
                        value: statePolPrincipal.tipoPoliza,
                        error: errorStatePolPrincipal.tipoPoliza.error,
                        success: statePolPrincipal.tipoPoliza && !errorStatePolPrincipal.tipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoPoliza.helperText,
                        inputRef: PolPrincipaltipoPolizaRef,
                        title: "Tipo de Póliza *",
                        placeholder: "Seleccione un tipo",
                        tipo: "select",
                        data: aTiposPoliza,
                        grid: { md: 5, lg: 5 }
                    },
                    {
                        hidden: statePolPrincipal.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                        disabled: true,
                        id: "derechoPoliza",
                        value: statePolPrincipal.derechoPoliza,
                        // error: errorStatePolPrincipal.derechoPoliza.error,
                        //success: statePolPrincipal.derechoPoliza && !errorStatePolPrincipal.derechoPoliza.error ? true : undefined,
                        // helperText: errorStatePolPrincipal.derechoPoliza.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        title: "Derecho de Póliza *",
                        placeholder: "Capture el Derecho de Póliza",
                        grid: { md: 7, lg: 7 }
                    },
                    {
                        disabled: true,
                        id: "polizaOrigenID ",
                        value: statePolPrincipal.polizaOrigenID == null ? "0" : statePolPrincipal.polizaOrigenID,
                        error: errorStatePolPrincipal.polizaOrigenID.error,
                        success: statePolPrincipal.polizaOrigenID && !errorStatePolPrincipal.polizaOrigenID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.polizaOrigenID.helperText,
                        inputRef: PolPrincipalpolizaOrigenIDRef,
                        title: "Póliza Principal *",
                        placeholder: "Seleccione la poliza Principal",
                        tipo: "autocomplete",
                        data: seleccionables.polizasSeguro,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "divisaID",
                        value: statePolPrincipal.divisaID,
                        error: errorStatePolPrincipal.divisaID.error,
                        success: statePolPrincipal.divisaID && !errorStatePolPrincipal.divisaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.divisaID.helperText,
                        inputRef: PolPrincipaldivisaIDRef,
                        title: "Divisa",
                        placeholder: "Seleccionar la Divisa",
                        grid: { md: 6, lg: 6 },
                        tipo: "autocomplete",
                        data: listDivisas
                    },
                    {
                        disabled: true,
                        id: "paridad",
                        value: statePolPrincipal.paridad,
                        error: errorStatePolPrincipal.paridad.error,
                        //success: statePolPrincipal.paridad && !errorStatePolPrincipal.paridad.error ? true : undefined,
                        helperText: errorStatePolPrincipal.paridad.helperText,
                        title: "Paridad Actual ",
                        textAlign: "right",
                        placeholder: "Paridad Actual",
                        grid: { md: 6, lg: 6 }
                    }
                ]
            },
            {
                id: "Arriba derecho",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "numero",
                        value: statePolPrincipal.numero,
                        error: errorStatePolPrincipal.numero.error,
                        success: statePolPrincipal.numero && !errorStatePolPrincipal.numero.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numero.helperText,
                        inputRef: PolPrincipalnumeroRef,
                        title: "No. de Póliza *",
                        placeholder: "Capture el No. de Póliza",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "numpolEndoso",
                        value: statePolPrincipal.numpolEndoso,
                        error: errorStatePolPrincipal.numpolEndoso.error,
                        success: statePolPrincipal.numpolEndoso && !errorStatePolPrincipal.numpolEndoso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numpolEndoso.helperText,
                        inputRef: PolPrincipalnumpolEndosoRef,
                        title: "Endoso",
                        placeholder: "Capture el endoso",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "inciso",
                        value: statePolPrincipal.inciso,
                        error: errorStatePolPrincipal.inciso.error,
                        //success: statePolPrincipal.inciso && !errorStatePolPrincipal.inciso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.inciso.helperText,
                        title: "Inciso",
                        placeholder: "Capture el inciso",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "tipoValorAseg",
                        value: statePolPrincipal.tipoValorAseg,
                        error: errorStatePolPrincipal.tipoValorAseg.error,
                        success: statePolPrincipal.tipoValorAseg && !errorStatePolPrincipal.tipoValorAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoValorAseg.helperText,
                        inputRef: PolPrincipaltipoValorAsegRef,
                        title: "Tipo de Valor Suma Asegurada",
                        tipo: "select",
                        data: tipoValorSumaAseg,
                        placeholder: "Capture el tipo de valor asegurado",
                        grid: { md: 8, lg: 8 }
                    },
                    {
                        disabled: true,
                        id: "Beneficiario",
                        value: statePolPrincipal.beneficiarioPreferente,
                        error: errorStatePolPrincipal.beneficiarioPreferente.error,
                        //success: statePolPrincipal.beneficiarioPreferente && !errorStatePolPrincipal.beneficiarioPreferente.error ? true : undefined,
                        helperText: errorStatePolPrincipal.beneficiarioPreferente.helperText,
                        title: "Beneficiario Preferente",
                        placeholder: "Capture el nombre del beneficiario",
                        grid: { md: 12, lg: 12 }
                    },
                     {
                        disabled,
                        id: "sumaAseg",
                        value: statePolPrincipal.sumaAseg,
                        error: errorStatePolPrincipal.sumaAseg.error,
                        success: statePolPrincipal.sumaAseg && !errorStatePolPrincipal.sumaAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.sumaAseg.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        inputRef: PolPrincipalsumaAsegRef,
                        title: "Suma Asegurada",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    }
                ]
            },
            {
                id: "fechas",
                tipo: "fieldset",
                leyenda: "▀",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "fechaSolCot",
                        value: statePolPrincipal.fechaSolCot,
                        error: errorStatePolPrincipal.fechaSolCot.error,
                        success: statePolPrincipal.fechaSolCot && !errorStatePolPrincipal.fechaSolCot.error ? true : undefined,
                        helperText: errorStatePolPrincipal.fechaSolCot.helperText,
                        inputRef: PolPrincipalfechaSolCotRef,
                        title: "Fecha Sol. Cotizacion.",
                        placeholder: "Fecha de Solicitud",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaIni",
                        value: statePolPrincipal.vigenciaIni,
                        error: errorStatePolPrincipal.vigenciaIni.error,
                        success: statePolPrincipal.vigenciaIni && !errorStatePolPrincipal.vigenciaIni.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaIni.helperText,
                        inputRef: PolPrincipalvigenciaIniRef,
                        placeholder: "Fecha Vigencia de Inicio",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaHasta",
                        value: statePolPrincipal.vigenciaHasta,
                        error: errorStatePolPrincipal.vigenciaHasta.error,
                        success: statePolPrincipal.vigenciaHasta && !errorStatePolPrincipal.vigenciaHasta.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaHasta.helperText,
                        inputRef: PolPrincipalvigenciaHastaRef,
                        title: "Vigencia hasta",
                        placeholder: "Fecha de Vencimiento",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    }, {
                        disabled: true,
                        id: "renovacion",
                        value: statePolPrincipal.renovacion,
                        checked: statePolPrincipal.renovacion,
                        title: "Renovar al Termino de Vigencia",
                        tipo: "switch",
                        grid: { md: 3, lg: 3 },
                    }]
            }, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "exclusiones",
                    value: statePolPrincipal.exclusiones,
                    error: errorStatePolPrincipal.exclusiones.error,
                    success: statePolPrincipal.exclusiones && !errorStatePolPrincipal.exclusiones.error ? true : undefined,
                    helperText: errorStatePolPrincipal.exclusiones.helperText,
                    inputRef: PolPrincipalexclusionesRef,
                    multiline: true,
                    title: "Exclusiones *",
                    placeholder: "Capture las exclusiones",
                    grid: { md: 12, lg: 12 }
                },
                {
                    disabled:true,
                    id: "aseguradoAdicional",
                    value: statePolPrincipal.aseguradoAdicional,
                    error: errorStatePolPrincipal.aseguradoAdicional.error,
                    success: statePolPrincipal.aseguradoAdicional && !errorStatePolPrincipal.aseguradoAdicional.error ? true : undefined,
                    helperText: errorStatePolPrincipal.aseguradoAdicional.helperText,
                    multiline: true,
                    title: "Asegurado Adicional",
                    placeholder: "Capturar asegurado adicional",
                    grid: { md: 12, lg: 12 }
                },
            ]
        },
        {
            id: "cMultipartidas",
            tipo: "custom",
            leyenda: "▀",
            grid: { lg: 12 },
            custom: <MultiPartidas
                datosGet={datosPartidasGet}
                setDataToSave={setDatosPartidas}
                ramoSeguroSelect={seleccionables.ramoSeguros}
                datosEncabezado={statePolPrincipal}
                trazabilidad={1}
            />
        }
        )
    }

    const accionesAdjuntos = [];
    const botonesAdjuntos = [];

    const infoTablaAdjuntos = {
        botones: botonesAdjuntos,
        acciones: accionesAdjuntos,
        actualizar,
        id: "serverStorageID",
        color: primary,
        title: "Documentación Adjunta",
        title2: "Documentación",
        iconTable: <AccountCircle />,
        headers: [
            // { variable: "serverStorageID", descripcion: "ID" , hide: true },
            { variable: "tabla", descripcion: "Categoría" },
            { variable: "tipo", descripcion: "Carpeta" },
            { variable: "clasificacion", descripcion: "Subcarpeta" },
            { variable: "nombreArchivo", descripcion: "Nombre" },
            { variable: "serverStorageID", descripcion: "Archivo" },
            { variable: "ruta", descripcion: "Ruta", hide: true },
        ],
        responsiveTitle: ["tipo", "clasificacion", "nombreArchivo"],
        filter: [
            { campo: "tabla", variable: "tabla", placeholder: "Capture la categoria" },
            { campo: "tipo", variable: "tipo", placeholder: "Capture el tipo" },
            { campo: "clasificacion", variable: "clasificacion", placeholder: "Capture la clasificacion" }
        ],
        alineacion: [{ columnas: [0], alineacion: "left" }, { columnas: [0, 4, 5, 6, 7], alineacion: "center" }],
        formato: [
            {
                columnas: ["serverStorageID"], tipo: "custom", callback: (data) =>
                <div className='cAuxButtonDownload'><Button size="small" variant="outlined" color="primary" onClick={() => descargar(data)}>Descargar</Button></div>

            }
        ],
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorStateAdjuntos["serverStorageID"] = { error: false, helperText: "" };
    cleanStateAdjuntos["serverStorageID"] = 0;


    InicializarInputs("clasificacion", "Clasificación", "Capture la clasificación", "", "", 100);
    InicializarInputs("archivo", "Nombre del archivo", "nombre del archivo", "", "", 100);
    InicializarInputs("nombreDocumento", "Nombre del archivo", "nombre del archivo", "", "", 100);
    InicializarInputs("extension", "Extensión", "Extensión", "", "", 10);


    //-------------------------------------------
    InputsConID["clasificacion"].grid = { md: 12, lg: 12 };
    InputsConID["archivo"].grid = { md: 12, lg: 12 };
    InputsConID["nombreDocumento"].grid = { md: 8, lg: 8 };
    InputsConID["extension"].grid = { md: 4, lg: 4 };
    //-------------------------------------------

    InputsConID["archivo"].inputProps = { type: "file" }
    InputsConID["archivo"].onChange = ({ target: { value, files } }) => handleChangeFiles({ value, files }, "archivo");
    InputsConID["extension"].inputProps = { readOnly: true }

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0) {

        cleanErrorStateAdjuntos[Identificador] = { error: false, helperText: "" };
        cleanStateAdjuntos[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatosAdjuntos[Identificador],
            id: Identificador,
            error: errorStateAdjuntos[Identificador].error,
            success: stDatosAdjuntos[Identificador] && !errorStateAdjuntos[Identificador].error ? true : undefined,
            helperText: errorStateAdjuntos[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({ target: { value } }) => handleChangeAdjuntos(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }


    /***************************reporte de daños**************************** */

    const inputsReporteDanios = [];
    const InputsConIDReporteDanios = [];
    const SeccionesReporteDanios = [];

    cleanErrorState["reporteDaniosID"] = { error: false, helperText: "" };
    cleanState["reporteDaniosID"] = 0;
    cleanState["empresaID"] = 0;
    cleanState["estatus"] = 'EN PROCESO';
    cleanState["seguimiento"] = 'PENDIENTE';
    cleanState["finalizado"] = 0;
    cleanState["areaRespDanioID"] = 0;
    cleanState["areaRespRepaID"] = 0;
    /* cleanState["horaNombramiento"] = new Date();
    cleanState["horaAlimentos"] = new Date(); */

    InicializarInputsReporteDanios("fechaElaboracion", "Fecha de Elaboración*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputsReporteDanios("numIncidencia", "No. de Acción", "", "", "Datos Generales", 30);
    InicializarInputsReporteDanios("numNC", "No. De NC", "", "", "Datos Generales", 30);
    //InicializarInputsReporteDanios("codigoClasifDanio", "Codigo Clasificacion Daño", "", "", "Datos Generales", 30);
    InicializarInputsReporteDanios("descripBreve", "Descripción Breve del Evento", "", "", "Datos Generales", 0);
    InicializarInputsReporteDanios("lugarEvento", "Lugar del Evento*", "", "", "Datos Generales", 0);
    // InicializarInputsReporteDanios("semanaDanio", "Semana de Daño", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputsReporteDanios("fechaEvento", "Fecha del Evento*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputsReporteDanios("horaEvento", "Hora del Evento*", "", new Date(), "Datos Generales", 0, "center");

    InicializarInputsReporteDanios("supervCargo", "Supervisor(es) a Cargo Durante el Evento", "", "", "Datos Generales", 0);
    InicializarInputsReporteDanios("areaID", "Área", "", "", "Datos Generales", 150);
    InicializarInputsReporteDanios("empresaID", "Empresa*", "", usuario["empresaID"], "Datos Generales", 0);
    InicializarInputsReporteDanios("danioOcasioTer", "Daño Ocasionado por un Tercero", "", "", "Datos Generales", 150);
    InicializarInputsReporteDanios("danioOcasioNos", "Daño Ocasionado por Nosotros", "", "", "Datos Generales", 150);
    //InicializarInputsReporteDanios("montos", "Montos", "", 0, "Datos Generales", 0, "right");
    InicializarInputsReporteDanios("descripMedia", "Descripción del evento: (Incluir solo lo que pasó, cómo, cuándo, quién, testigos, maquinaria involucrada, etc.)", "", "", "Información del Evento", 0);
    InicializarInputsReporteDanios("danioOcasioA", "Daño Ocasionado a", "", "", "Información del Evento", 150);
    InicializarInputsReporteDanios("tipoDanioOcasio", "Tipo de daño", "", "", "Información del Evento", 150);

    InicializarInputsReporteDanios("buqueViaje", "Buque viaje", "", "", "Información del Evento", 150);

    InicializarInputsReporteDanios("descripDetallada", "Descripción Detallada del Daño:", "Incluir descripción de los daños ocasionados que se presentan, partes o propiedad, ubicaciones, dimensiones, contenido, estatus, cantidad", "", "Información del Evento", 0);
    InicializarInputsReporteDanios("folioServicio", "Folio de servicio", "", "", "Información complementaria (en caso de aplicar)", 0);
    InicializarInputsReporteDanios("tipoServicio", "Tipo de servicio", "", "", "Información complementaria (en caso de aplicar)", 50);
    InicializarInputsReporteDanios("tipoMercancia", "¿Qué tipo de mercancia es?", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputsReporteDanios("propiedadDe", "Propiedad de", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputsReporteDanios("repreLocal", "Representante local", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputsReporteDanios("estatusUnidad", "Estatus Unidad", "", "", "Información complementaria (en caso de aplicar)", 100);
    InicializarInputsReporteDanios("autoriza", "Autoriza", "", "", "Información complementaria (en caso de aplicar)", 100);
    InicializarInputsReporteDanios("horaAutorizacion", "Hora Autorización", "", new Date(), "Información complementaria (en caso de aplicar)", 100);
    InicializarInputsReporteDanios("fotografia1", "Foto (1)", "", "", "Incluir Fotografía(s) Ilustrativa(s) del Evento", 250);
    InicializarInputsReporteDanios("fotografia2", "Foto (2)", "", "", "Incluir Fotografía(s) Ilustrativa(s) del Evento", 250);
    InicializarInputsReporteDanios("nombresPersonal", "Nombres", "", "", "Información del Personal Involucrado", 0);
    InicializarInputsReporteDanios("numControlPersonal", "No. De control", "", "", "Información del Personal Involucrado", 0);
    InicializarInputsReporteDanios("habilidadBase", "Puesto o habilidad base", "", "", "Información del Personal Involucrado", 30);
    InicializarInputsReporteDanios("habilidadActiv", "Puesto o habilidad de la actividad que realizaba", "", "", "Información del Personal Involucrado", 150);
    //  InicializarInputsReporteDanios("fechaIngreso", "Fecha de Ingreso*", "", new Date(), "Información del Personal Involucrado", 0, "center");
    // InicializarInputsReporteDanios("fechaHabilidad", "Fecha de habilidad*", "", new Date(), "Información del Personal Involucrado", 0, "center");
    // InicializarInputsReporteDanios("otrosDaniosHabilidades", "Otros Daños o Habilidades", "", "", "Información del Personal Involucrado", 0);

    InicializarInputsReporteDanios("nombresTestigos", "Nombres", "", "", "Información de Testigo 1", 150);
    InicializarInputsReporteDanios("numControlTestigos", "No. De control", "", "", "Información de Testigo 1", 150);
    InicializarInputsReporteDanios("habilidadTestigos", "Puesto o habilidad", "", "", "Información de Testigo 1", 150);
    InicializarInputsReporteDanios("nombresTestigos2", "Nombres", "", "", "Información de Testigo 2", 150);
    InicializarInputsReporteDanios("numControlTestigos2", "No. De control", "", "", "Información de Testigo 2", 150);
    InicializarInputsReporteDanios("habilidadTestigos2", "Puesto o habilidad", "", "", "Información de Testigo 2", 150);
    InicializarInputsReporteDanios("notifAseg", "¿Fue necesaro notificar a nuestra aseguradora?", "", "", "", 5);
    InicializarInputsReporteDanios("reparaDanio", "¿Se acordó reparación del daño por parte de nosotros?", "", "", "", 5);
    InicializarInputsReporteDanios("inspecTerceros", "¿Se solicitó la inspección de una tercera parte?", "", "", "", 5);
    InicializarInputsReporteDanios("cotizacionDanio", "¿Se cuenta con Cotización del daño?", "", "", "", 5);
    InicializarInputsReporteDanios("anexRep", "¿Se anexan reportes internos / externos?", "", "", "", 5);
    InicializarInputsReporteDanios("reportesAnex", "¿Cuáles reportes se anexan?", "", "", "", 0);
    InicializarInputsReporteDanios("obserGenerales", "Observaciones Generales", "", "", "", 0);
    InicializarInputsReporteDanios("responAnalisis", "Responsable del Análisis de Causa Raíz", "", "", "", 0);
    InicializarInputsReporteDanios("elaboro", "Elaboró", "", "", "", 250);
    InicializarInputsReporteDanios("firma", "Firma", "", "", "", 250);
    InicializarInputsReporteDanios("cargo", "Cargo", "", "", "", 0);




    InicializarInputsReporteDanios("ubicacionID", "Ubicación del incidente / accidente", "Ubicación del incidente / accidente", 0, "Servicio al cliente Autos", 0, "left");
    InicializarInputsReporteDanios("modeloUnidad", "Modelo de unidad (Marca, año, modelo)", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputsReporteDanios("numVin", "Número de vin completo", "", "", "Servicio al cliente Autos", 50, "right");
    InicializarInputsReporteDanios("numControl", "Número de control", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputsReporteDanios("nomChofer", "Nombre del chofer", "", "", "Servicio al cliente Autos", 250, "left");
    InicializarInputsReporteDanios("genero", "Género", "", "", "Servicio al cliente Autos", 10, "left");
    InicializarInputsReporteDanios("antiguedad", "Antigüedad de la categoría", "", "", "Servicio al cliente Autos", 250, "left");
    InicializarInputsReporteDanios("fechaNacimiento", "Fecha de nacimiento", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputsReporteDanios("edad", "Edad", "", 0, "Servicio al cliente Autos", 0, "left");
    InicializarInputsReporteDanios("horaNombramiento", "Hora de nombramiento (Del día del incidente)", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputsReporteDanios("turnoAnterior", "5 días anteriores de que turno andaba o sí descanso ", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputsReporteDanios("horaAlimentos", "Hora de sus alimentos (Del día del incidente)", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputsReporteDanios("calistenia", "Se brindó calistenia (Del día del incidente)", "", "", "Servicio al cliente Autos", 50, "left");
    InicializarInputsReporteDanios("ultimaPlatica", "Tema de la última plática de Seguridad", "", "", "Servicio al cliente Autos", 150, "left");
    InicializarInputsReporteDanios("fechaUltimaPlatica", "Fecha de la última plática de seguridad", "", "", "Servicio al cliente Autos", 0, "left");
    InicializarInputsReporteDanios("fechaRevProc", "Fecha en que se revisaron estos procedimientos con la persona", "", "", "Servicio al cliente Autos", 0, "left");

    //-----------------------------------------------------
    if (EsReporteador === true) {

        InicializarInputsReporteDanios("folioInterno", "Folio", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("estatus", "Estatus", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("seguimiento", "Seguimiento", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("danioOcasioPor", "Daño Ocasionado por", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("danioOcasioARep", "Daño Ocasionado a", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("equipoBienPersona", "Equipo / Bien / Persona", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("areaRespDanioID", "Área Responsable de Daño", "", 0, "Reporteador", 0, "left");
        InicializarInputsReporteDanios("areaRespRepaID", "Área Responsable de Reparación", "", 0, "Reporteador", 0, "left");
        InicializarInputsReporteDanios("divisaID", "Divisa", "", 69, "Reporteador", 0, "right");
        InicializarInputsReporteDanios("paridad", "Paridad", "", 1, "Reporteador", 0, "right");
        InicializarInputsReporteDanios("costoDanio", "Costo de Daño", "", "", "Reporteador", 0, "right");
        InicializarInputsReporteDanios("tiempoInvertido", "Tiempo invertido", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("atendio", "Atendió", "", "", "Reporteador", 0, "left");
        InicializarInputsReporteDanios("notas", "Notas", "", "", "Reporteador", 0, "left");

        InputsConIDReporteDanios["folioInterno"].grid = { md: 4, lg: 4 };
        InputsConIDReporteDanios["estatus"].grid = { md: 4, lg: 4 };
        InputsConIDReporteDanios["seguimiento"].grid = { md: 4, lg: 4 };
        //-----------------------------------------------------
        InputsConIDReporteDanios["danioOcasioPor"].grid = { md: 6, lg: 6 };
        InputsConIDReporteDanios["danioOcasioARep"].grid = { md: 6, lg: 6 };
        //-----------------------------------------------------
        InputsConIDReporteDanios["equipoBienPersona"].grid = { md: 6, lg: 6 };
        InputsConIDReporteDanios["areaRespDanioID"].grid = { md: 6, lg: 6 };
        InputsConIDReporteDanios["areaRespRepaID"].grid = { md: 6, lg: 6 };
        //-----------------------------------------------------
        InputsConIDReporteDanios["divisaID"].grid = { md: 2, lg: 2 };
        InputsConIDReporteDanios["paridad"].grid = { md: 2, lg: 2 };
        InputsConIDReporteDanios["costoDanio"].grid = { md: 2, lg: 2 };
        InputsConIDReporteDanios["tiempoInvertido"].grid = { md: 3, lg: 3 };
        InputsConIDReporteDanios["atendio"].grid = { md: 9, lg: 9 };
        //-----------------------------------------------------
        InputsConIDReporteDanios["notas"].grid = { md: 12, lg: 12 };
        InputsConIDReporteDanios["notas"].multiline = true;

        InputsConIDReporteDanios["fechaElaboracion"].disabled = false;
        InputsConIDReporteDanios["numIncidencia"].disabled = true;
        InputsConIDReporteDanios["numNC"].disabled = true;
        // InputsConIDReporteDanios["codigoClasifDanio"].disabled = true;
        InputsConIDReporteDanios["descripBreve"].disabled = true;
        InputsConIDReporteDanios["lugarEvento"].disabled = true;
        // InputsConIDReporteDanios["semanaDanio"].disabled = true;
        InputsConIDReporteDanios["fechaEvento"].disabled = true;
        InputsConIDReporteDanios["horaEvento"].disabled = true;
        InputsConIDReporteDanios["supervCargo"].disabled = true;
        InputsConIDReporteDanios["empresaID"].disabled = true;
        InputsConIDReporteDanios["areaID"].disabled = true;
        InputsConIDReporteDanios["danioOcasioTer"].disabled = true;
        InputsConIDReporteDanios["danioOcasioNos"].disabled = true;
        //InputsConIDReporteDanios["montos"].disabled = true;
        InputsConIDReporteDanios["descripMedia"].disabled = true;
        InputsConIDReporteDanios["danioOcasioA"].disabled = true;
        InputsConIDReporteDanios["buqueViaje"].disabled = true;
        InputsConIDReporteDanios["descripDetallada"].disabled = true;
        InputsConIDReporteDanios["folioServicio"].disabled = true;
        InputsConIDReporteDanios["tipoServicio"].disabled = true;
        InputsConIDReporteDanios["tipoMercancia"].disabled = true;
        InputsConIDReporteDanios["propiedadDe"].disabled = true;
        InputsConIDReporteDanios["repreLocal"].disabled = true;
        InputsConIDReporteDanios["estatusUnidad"].disabled = true;
        InputsConIDReporteDanios["autoriza"].disabled = true;
        InputsConIDReporteDanios["horaAutorizacion"].disabled = true;
        InputsConIDReporteDanios["fotografia1"].disabled = true;
        InputsConIDReporteDanios["fotografia2"].disabled = true;
        InputsConIDReporteDanios["nombresPersonal"].disabled = true;
        InputsConIDReporteDanios["numControlPersonal"].disabled = true;
        InputsConIDReporteDanios["habilidadBase"].disabled = true;
        InputsConIDReporteDanios["habilidadActiv"].disabled = true;
        //InputsConIDReporteDanios["fechaIngreso"].disabled = true;
        // InputsConIDReporteDanios["fechaHabilidad"].disabled = true;
        // InputsConIDReporteDanios["otrosDaniosHabilidades"].disabled = true;
        InputsConIDReporteDanios["nombresTestigos"].disabled = true;
        InputsConIDReporteDanios["numControlTestigos"].disabled = true;
        InputsConIDReporteDanios["habilidadTestigos"].disabled = true;
        InputsConIDReporteDanios["nombresTestigos2"].disabled = true;
        InputsConIDReporteDanios["numControlTestigos2"].disabled = true;
        InputsConIDReporteDanios["habilidadTestigos2"].disabled = true;
        InputsConIDReporteDanios["notifAseg"].disabled = true;
        InputsConIDReporteDanios["reparaDanio"].disabled = true;
        InputsConIDReporteDanios["inspecTerceros"].disabled = true;
        InputsConIDReporteDanios["cotizacionDanio"].disabled = true;
        InputsConIDReporteDanios["anexRep"].disabled = true;
        InputsConIDReporteDanios["reportesAnex"].disabled = true;
        InputsConIDReporteDanios["obserGenerales"].disabled = true;
        InputsConIDReporteDanios["responAnalisis"].disabled = true;
        InputsConIDReporteDanios["elaboro"].disabled = true;
        InputsConIDReporteDanios["firma"].disabled = true;
        InputsConIDReporteDanios["cargo"].disabled = true;
        InputsConIDReporteDanios["tipoDanioOcasio"].disabled = true;

        InputsConIDReporteDanios["estatus"].disabled = true;
        InputsConIDReporteDanios["seguimiento"].disabled = true;
        InputsConIDReporteDanios["folioInterno"].disabled = true;
        //InputsConIDReporteDanios["folio"].disabled = (stDatos["estatus"] !== "EN PROCESO");
        InputsConIDReporteDanios["danioOcasioPor"].disabled = true;
        InputsConIDReporteDanios["danioOcasioARep"].disabled = true;
        InputsConIDReporteDanios["equipoBienPersona"].disabled = true;
        InputsConIDReporteDanios["areaRespDanioID"].disabled = true;
        InputsConIDReporteDanios["areaRespRepaID"].disabled = true;
        InputsConIDReporteDanios["costoDanio"].disabled = true;
        InputsConIDReporteDanios["divisaID"].disabled = true;
        InputsConIDReporteDanios["paridad"].disabled = true;

        InputsConIDReporteDanios["tiempoInvertido"].disabled = true;
        InputsConIDReporteDanios["atendio"].disabled = true;
        InputsConIDReporteDanios["notas"].disabled = true;
        // DaniosATerceroEmpresa
        InputsConIDReporteDanios["fechaElaboracion"].disabled = true;

        let OpcionesDanioReporteador = [{ value: "Personal", label: "Personal" },
        { value: "Tercero", label: "Tercero" }
        ];

        InputsConIDReporteDanios["danioOcasioPor"].tipo = "select";
        InputsConIDReporteDanios["danioOcasioPor"].data = OpcionesDanioReporteador;
        InputsConIDReporteDanios["danioOcasioPor"].value = stDatos["danioOcasioPor"];

        InputsConIDReporteDanios["danioOcasioARep"].tipo = "select";
        InputsConIDReporteDanios["danioOcasioARep"].data = seleccionables.DaniosATerceroEmpresa;
        InputsConIDReporteDanios["danioOcasioARep"].value = stDatos["danioOcasioARep"];

        InputsConIDReporteDanios["areaRespDanioID"].tipo = "select";
        InputsConIDReporteDanios["areaRespDanioID"].data = seleccionables.Areas;

        InputsConIDReporteDanios["areaRespRepaID"].tipo = "select";
        InputsConIDReporteDanios["areaRespRepaID"].data = seleccionables.Areas;

        InputsConIDReporteDanios["costoDanio"].textAlign = "right";
        InputsConIDReporteDanios["costoDanio"].icono = <IconoSimbolo simbolo="$" />;
        InputsConIDReporteDanios["costoDanio"].posicionIcono = "start";
        InputsConIDReporteDanios["costoDanio"].formatoImportes = true;
    }
    //-----------------------------------------------------

    //-----------------------------------------------------
    InputsConIDReporteDanios["fechaElaboracion"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["numIncidencia"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["numNC"].grid = { md: 3, lg: 3 };
    // InputsConIDReporteDanios["codigoClasifDanio"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["descripBreve"].grid = { md: 12, lg: 12 };
    InputsConIDReporteDanios["lugarEvento"].grid = { md: 6, lg: 6 };
    // InputsConIDReporteDanios["semanaDanio"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["fechaEvento"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["horaEvento"].grid = { md: 3, lg: 3 };

    InputsConIDReporteDanios["supervCargo"].grid = { md: 12, lg: 12 };
    InputsConIDReporteDanios["areaID"].grid = { md: 12, lg: 12 };
    InputsConIDReporteDanios["empresaID"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["danioOcasioTer"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["danioOcasioNos"].grid = { md: 7, lg: 7 };
    //InputsConIDReporteDanios["montos"].grid = { md: 5, lg: 5 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["descripMedia"].grid = { md: 12, lg: 12 };
    InputsConIDReporteDanios["danioOcasioA"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["tipoDanioOcasio"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["buqueViaje"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["descripDetallada"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["folioServicio"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["tipoServicio"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["tipoMercancia"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["propiedadDe"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["repreLocal"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["estatusUnidad"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["estatusUnidad"].tipo = "select";
    InputsConIDReporteDanios["estatusUnidad"].data = listEstatusUnidad;
    InputsConIDReporteDanios["autoriza"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["horaAutorizacion"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["horaAutorizacion"].tipo = "datetimepicker";
    InputsConIDReporteDanios["horaAutorizacion"].timeFormat = false;
    InputsConIDReporteDanios["horaAutorizacion"].onlyTime = true;
    InputsConIDReporteDanios["horaAutorizacion"].onlyDate = false;
    //-----------------------------------------------------
    InputsConIDReporteDanios["fotografia1"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["fotografia2"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["nombresPersonal"].grid = { md: 8, lg: 8 };
    InputsConIDReporteDanios["numControlPersonal"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["habilidadBase"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["habilidadActiv"].grid = { md: 6, lg: 6 };
    //InputsConIDReporteDanios["fechaIngreso"].grid = { md: 6, lg: 6 };
    //InputsConIDReporteDanios["fechaHabilidad"].grid = { md: 6, lg: 6 };
    //InputsConIDReporteDanios["otrosDaniosHabilidades"].grid = { md: 12, lg: 12 };

    //-----------------------------------------------------
    InputsConIDReporteDanios["nombresTestigos"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["numControlTestigos"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["habilidadTestigos"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["nombresTestigos2"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["numControlTestigos2"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["habilidadTestigos2"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["notifAseg"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["reparaDanio"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["inspecTerceros"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["cotizacionDanio"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["anexRep"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["reportesAnex"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["obserGenerales"].grid = { md: 12, lg: 12 };
    InputsConIDReporteDanios["responAnalisis"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["elaboro"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["firma"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["cargo"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------


    InputsConIDReporteDanios["ubicacionID"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["modeloUnidad"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["numVin"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["numControl"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["nomChofer"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["genero"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["antiguedad"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["fechaNacimiento"].grid = { md: 3, lg: 3 };
    InputsConIDReporteDanios["edad"].grid = { md: 3, lg: 3 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["horaNombramiento"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["turnoAnterior"].grid = { md: 4, lg: 4 };
    InputsConIDReporteDanios["horaAlimentos"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConIDReporteDanios["calistenia"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["ultimaPlatica"].grid = { md: 6, lg: 6 };
    InputsConIDReporteDanios["fechaUltimaPlatica"].grid = { md: 5, lg: 5 };
    InputsConIDReporteDanios["fechaRevProc"].grid = { md: 5, lg: 5 };
    //-----------------------------------------------------

    InputsConIDReporteDanios["empresaID"].tipo = "select";
    InputsConIDReporteDanios["empresaID"].data = seleccionables.empresas;
    InputsConIDReporteDanios["empresaID"].disabled = true;

    InputsConIDReporteDanios["areaID"].tipo = "select";
    InputsConIDReporteDanios["areaID"].data = seleccionables.Areas;

    InputsConIDReporteDanios["descripBreve"].multiline = true;
    InputsConIDReporteDanios["descripMedia"].multiline = true;
    InputsConIDReporteDanios["descripDetallada"].multiline = true;
    InputsConIDReporteDanios["obserGenerales"].multiline = true;

    let OpcionesDanios = [{ value: "A propiedad interna", label: "A propiedad interna" },
    { value: "A la carga", label: "A la carga" },
    { value: "A un tercero (Externo)", label: "A un tercero (Externo)" },
    { value: "N/A", label: "N/A" }
    ];

    InputsConIDReporteDanios["danioOcasioTer"].tipo = "select";
    InputsConIDReporteDanios["danioOcasioTer"].data = OpcionesDanios;

    InputsConIDReporteDanios["danioOcasioNos"].tipo = "select";
    InputsConIDReporteDanios["danioOcasioNos"].data = OpcionesDanios;

    let OpcionesDanioA = [{ value: "Carga General", label: "Carga General" },
    { value: "Contenedor (es)", label: "Contenedor (es)" },
    { value: "Automóvil (es)", label: "Automóvil (es)" },
    { value: "Maquinaria y/o Equipo Interno", label: "Maquinaria y/o Equipo Interno" },
    { value: "Herramientas de Trabajo", label: "Herramientas de Trabajo" },
    { value: "Instalaciones", label: "Instalaciones" },
    { value: "Propiedad Externa", label: "Propiedad Externa" }
    ];
    /*
        InputsConID["montos"].textAlign = "right";
        InputsConID["montos"].icono = <IconoSimbolo simbolo="$" />;
        InputsConID["montos"].posicionIcono = "start";
        InputsConID["montos"].formatoImportes = true;
    */
    InputsConIDReporteDanios["danioOcasioA"].tipo = "select";
    InputsConIDReporteDanios["danioOcasioA"].data = OpcionesDanioA;
    InputsConIDReporteDanios["danioOcasioA"].value = stDatos["danioOcasioA"];

    InputsConIDReporteDanios["tipoDanioOcasio"].title = stTituloTipoDanio;
    InputsConIDReporteDanios["tipoDanioOcasio"].placeholder = stTituloTipoDanio;

    let OpcionesMercancia = [{ value: "Importacion", label: "Importacion" },
    { value: "Exportación", label: "Exportación" },
    { value: "Vacios", label: "Vacios" },
    { value: "Transbordo Lleno", label: "Transbordo Lleno" },
    { value: "Transbordo Vacío", label: "Transbordo Vacío" },
    { value: "NA", label: "NA" }
    ];

    InputsConIDReporteDanios["tipoMercancia"].tipo = "select";
    InputsConIDReporteDanios["tipoMercancia"].data = OpcionesMercancia;

    let Opciones = [{ value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
    { value: "NA", label: "NA" }
    ];

    InputsConIDReporteDanios["notifAseg"].tipo = "select";
    InputsConIDReporteDanios["notifAseg"].data = Opciones;

    InputsConIDReporteDanios["reparaDanio"].tipo = "select";
    InputsConIDReporteDanios["reparaDanio"].data = Opciones;

    InputsConIDReporteDanios["inspecTerceros"].tipo = "select";
    InputsConIDReporteDanios["inspecTerceros"].data = Opciones;

    InputsConIDReporteDanios["cotizacionDanio"].tipo = "select";
    InputsConIDReporteDanios["cotizacionDanio"].data = Opciones;

    InputsConIDReporteDanios["anexRep"].tipo = "select";
    InputsConIDReporteDanios["anexRep"].data = Opciones;

    InputsConIDReporteDanios["fotografia1"].tipo = "imageupload";
    InputsConIDReporteDanios["fotografia1"]["removeImage"] = stDatos["fotografia1"] === "" ? true : false;
    /* InputsConIDReporteDanios["fotografia1"].onChange = (file, base) => {
         if (file === null) {
             remover = true;
         }
         setStateReporteDanios(stDatos => ({
             ...stDatos,
             'fotografia1': base
         }));
         if(open){
             setFotografia1({ valor: base, file: file, eliminar: remover });
         }
         return;
     };*/
    InputsConIDReporteDanios["fotografia2"].tipo = "imageupload";
    InputsConIDReporteDanios["fotografia2"]["removeImage"] = stDatos["fotografia2"] === "" ? true : false;
    /*  InputsConIDReporteDanios["fotografia2"].onChange = (file, base) => {
          if (file === null) {
              remover = true;
          }
          setStateReporteDanios(stDatos => ({
              ...stDatos,
              'fotografia2': base
          }));
          if (open) {
              setFotografia2({ valor: base, file: file, eliminar: remover });
          }
          return;
      };*/

    InputsConIDReporteDanios["fechaElaboracion"].tipo = "datetimepicker";
    InputsConIDReporteDanios["fechaElaboracion"].timeFormat = false;
    InputsConIDReporteDanios["fechaElaboracion"].onlyDate = true;
    /*InputsConIDReporteDanios["fechaElaboracion"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaElaboracion");
    };*/

    InputsConIDReporteDanios["fechaEvento"].tipo = "datetimepicker";
    InputsConIDReporteDanios["fechaEvento"].timeFormat = false;
    InputsConIDReporteDanios["fechaEvento"].onlyDate = true;
    /*InputsConIDReporteDanios["fechaEvento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaEvento");
    };*/

    InputsConIDReporteDanios["horaEvento"].tipo = "datetimepicker";
    InputsConIDReporteDanios["horaEvento"].timeFormat = false;
    InputsConIDReporteDanios["horaEvento"].onlyTime = true;
    InputsConIDReporteDanios["horaEvento"].onlyDate = false;
    /*InputsConIDReporteDanios["horaEvento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaEvento");
    };*/

    InputsConIDReporteDanios["fechaNacimiento"].tipo = "datetimepicker";
    InputsConIDReporteDanios["fechaNacimiento"].timeFormat = false;
    InputsConIDReporteDanios["fechaNacimiento"].onlyDate = true;
    /*InputsConIDReporteDanios["fechaNacimiento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaNacimiento");
    };*/

    InputsConIDReporteDanios["fechaUltimaPlatica"].tipo = "datetimepicker";
    InputsConIDReporteDanios["fechaUltimaPlatica"].timeFormat = false;
    InputsConIDReporteDanios["fechaUltimaPlatica"].onlyDate = true;
    /*InputsConIDReporteDanios["fechaUltimaPlatica"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaUltimaPlatica");
    };*/

    InputsConIDReporteDanios["fechaRevProc"].tipo = "datetimepicker";
    InputsConIDReporteDanios["fechaRevProc"].timeFormat = false;
    InputsConIDReporteDanios["fechaRevProc"].onlyDate = true;
    /*InputsConIDReporteDanios["fechaRevProc"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaRevProc");
    };*/

    InputsConIDReporteDanios["horaNombramiento"].tipo = "datetimepicker";
    InputsConIDReporteDanios["horaNombramiento"].timeFormat = false;
    InputsConIDReporteDanios["horaNombramiento"].onlyTime = true;
    InputsConIDReporteDanios["horaNombramiento"].onlyDate = false;
    /*InputsConIDReporteDanios["horaNombramiento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaNombramiento");
    };*/

    InputsConIDReporteDanios["horaAlimentos"].tipo = "datetimepicker";
    InputsConIDReporteDanios["horaAlimentos"].timeFormat = false;
    InputsConIDReporteDanios["horaAlimentos"].onlyTime = true;
    InputsConIDReporteDanios["horaAlimentos"].onlyDate = false;
    /*InputsConIDReporteDanios["horaAlimentos"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaAlimentos");
    };*/

    InputsConIDReporteDanios["ubicacionID"].tipo = "select";
    InputsConIDReporteDanios["ubicacionID"].data = seleccionables.Ubicaciones;


    let OpcionesGenero = [
        { value: "Masculino", label: "Masculino" },
        { value: "Femenino", label: "Femenino" }
    ];

    InputsConIDReporteDanios["genero"].tipo = "select";
    InputsConIDReporteDanios["genero"].data = OpcionesGenero;

    //InputsConIDReporteDanios["montos"].onChange = ({ target: { value } }) => !isNaN(Number(value)) && handleChange(value, "montos");
    SeccionesReporteDanios["Servicio al cliente Autos"].hide = (stDatos["danioOcasioA"] !== "Automóvil (es)") || (EsReporteador);


    function DisabledAllInputs() {
        if (!EsReporteador) {
            for (const key in InputsConIDReporteDanios) {
                InputsConIDReporteDanios[key].disabled = stDatos["finalizado"] === 1 ? true : false;

            }

        }

    }

    /*  if (stDatos['danioOcasioPor'] !== "")
     {
         InputsConIDReporteDanios[]
     }
  */
    const Auto = [
        'ubicacionID',
        'modeloUnidad',
        'numVin',
        'numControl',
        'nomChofer',
        'genero',
        'antiguedad',
        'fechaNacimiento',
        'horaNombramiento',
        'turnoAnterior',
        'horaAlimentos',
        'calistenia',
        'ultimaPlatica',
        'fechaUltimaPlatica',
        'fechaRevProc'
    ]

    function VerificarAuto() {

        stDatos['danioOcasioA'] == "Automóvil (es)" ?
            Auto.map(key => {
                InputsConIDReporteDanios[key].disabled = false;
            }) :
            Auto.map(key => {
                InputsConIDReporteDanios[key].value = "";
                InputsConIDReporteDanios[key].disabled = true;
            })
    }

    DisabledAllInputs();
    VerificarAuto();


    function InicializarInputsReporteDanios(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            /* onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
             onKeyDown: () => { return; }*/
        };

        InputsConIDReporteDanios[Identificador] = tInput;
        if (Seccion === "") {
            inputsReporteDanios.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (SeccionesReporteDanios[Seccion]) {
            InputFieldSet = SeccionesReporteDanios[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputsReporteDanios.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        SeccionesReporteDanios[Seccion] = InputFieldSet;
    }


    /****************************fin reporte de daños */

    async function obtenerValoresMultipartidas(polizasSeguroID){
        let auxDatos = await getAllSubPolizasSeguro({filtro: {polizasSeguroID: parseInt(polizasSeguroID.polizasSeguroID)}}, token)
        if(auxDatos.length > 0){
        setDatosPartidasGet(auxDatos);
        }
        return ;
    }

    const descargar = async function (idDoc) { //get storagen

        let archivo = await getFile({ serverStorageID: idDoc }, token);
        let documento = await getArchivo(archivo.ruta, token);

        let inicio = documento.length;
        let cadena = documento.indexOf(',')
        let b64 = documento.substring(cadena + 1, inicio);

        let iniciofor = documento.indexOf(':');
        let finfor = documento.indexOf(';');

        let ext = documento.substring(iniciofor + 1, finfor);

        showFile(b64, archivo.nombreArchivo, ext)

    };
    function handleChangeFiles({ files, value }, key) {
        if (files === null) {
            remover = true;
        }
        setDocumento({ valor: value, file: files[0], eliminar: remover });
        let nom = files[0].name;
        let punto = nom.indexOf('.');
        let ext = nom.substring(punto, nom.length);
        let nomDocumento = nom.substring(0, punto);

        setStateAdjuntos({
            ...stDatos,
            [key]: value,
            [`${key}File`]: files[0],
            [`${key}Name`]: files[0].name,
            ["nombreDocumento"]: nomDocumento,
            ["extension"]: ext
        });
        setErrorStateAdjuntos(errorStateAdjuntos => ({
            ...errorStateAdjuntos,
            [key]: { helperText: "", error: false }
        }));
    }
    function handleChangeAdjuntos(value, key, MaxLength = 0) {
        if (key === "archivo") {
            return;
        }
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }
        setStateAdjuntos(stDatosAdjuntos => ({
            ...stDatosAdjuntos,
            [key]: value
        }));

        setErrorState(errorStateAdjuntos => ({
            ...errorStateAdjuntos,
            [key]: { helperText: "", error: false }
        }));

    }

    function handleCloseVerReclAseg() {
        setOpenVerReclAseg(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateReclAseg({ ...cleanStateReclAseg });
        setErrorStateReclAseg({ ...cleanErrorStateReclAseg });

    }

    function handleCloseVerReclTerceros() {
        setOpenVerReclTerceros(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateReclTerceros({ ...cleanStateReclTerceros });
        setErrorStateReclTerceros({ ...cleanErrorStateReclTerceros });

    }

    function handleCloseVerPago() {
        setOpenFormularioVerPago(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStatePago({ ...cleanStatePago });
        setErrorStatePago({ ...cleanErrorStatePago });

    }


    function handleCloseVerPolizaPrincipal() {
        setOpenVerPolPrincipal(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStatePolPrincipal({ ...cleanStatePolPrincipal });
        setErrorStatePolPrincipal({ ...cleanErrorStatePolPrincipal });

    }


    function handleCloseVerReporteDanios() {
        setOpenVerReporteDanios(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateReporteDanios({ ...cleanStateReporteDanios });
        setFotografia1({ valor: "", file: "", remover: false });
        setFotografia2({ valor: "", file: "", remover: false });
        setErrorState({ ...cleanErrorState });

    }
    async function actualizarSeleccionables() {

        let ramoSeguros = await seleccionRamoSeguro({ filtro: { fijo: true } }, token);
        let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);

        let departamentos = await seleccionDepartamento({ filtro: {} }, token);

        let polizasSeguro = await seleccionPolizasSeguro({ filtro: { tipoPoliza: "PRINCIPAL" } }, token);


        let aseguradoras = await seleccionAseguradora({ filtro: {} }, token);
        let agenteSeguros = await seleccionAgentesSeguro({ filtro: {} }, token);

        let Ubicaciones = await seleccionUbicaciones({ filtro: {} }, token);

        let Areas = await seleccionArea({ filtro: {} }, token);
        let DaniosATerceroEmpresa = await ConsultasEmpresas.seleccionEmpresaDanio({ filtro: {} }, token);
        setSeleccionables({ ramoSeguros, empresas, departamentos, polizasSeguro, aseguradoras, agenteSeguros, Ubicaciones, Areas, DaniosATerceroEmpresa });

    }

    let botonesControlPago = [
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                setOpenVerPago(false)
            }
        },
    ];

    let accionesControlPago = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "controlPagoID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => {
            actualizarSeleccionables()
            handleModificar({
            parametros, token, setState: setStatePago, setOpen: setOpenFormularioVerPago, setAlert,
            asyncGet: getControlPago, formatearResultado: [
                { id: "fechaTramite", formato: (data) => moment(data) },
                { id: "solicitado", formato: (data) => moment(data) },
                { id: "realizado", formato: (data) => moment(data) },
                { id: "vigenciaIni", formato: (data) => moment(data) },
                { id: "vigenciaHasta", formato: (data) => moment(data) },
            ]

        })}
    }

    ];

    const infoTablaControlPago = {
        botones: botonesControlPago,
        acciones: accionesControlPago,
        actualizar,
        id: "controlPagoID",
        color: primary,
        title: "Control de Pagos",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true },
            { variable: "polizaID", descripcion: "polizaID", hide: true },
            { variable: "solSegFianID", descripcion: "solSegFianID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "descripcion", descripcion: "Descripción" },
            /* { variable: "numero", descripcion: "No." },*/
            { variable: "fechaTramite", descripcion: "Fecha Tramite" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "agenteSeguro", descripcion: "Agente Seguros" },
            // { variable: "descripcion", descripcion: "Descripción" },
            { variable: "folioTramite", descripcion: "Folio Tramite" },
            { variable: "importePago", descripcion: "Prima neta" },
            { variable: "polizaPrincipal", descripcion: "Póliza Principal" },
            /*  { variable: "poliza", descripcion: "Póliza" },*/
            { variable: "endoso", descripcion: "Endoso" },
            { variable: "folioInternoSolSeguro", descripcion: "Folio Sol. Seguro" },
            { variable: "estatus", descripcion: "Estado" },
            { variable: "folioReclamacion", descripcion: "Folio RGR-002 " },
            { variable: "siniesReclAsegID", descripcion: "siniesReclAsegID", hide: true },
            { variable: "reporteDaniosID", descripcion: "reporteDaniosID", hide: true },
            { variable: "tipoPoliza", descripcion: "tipoPoliza", hide: true },
        ],
        formato: [
            {
                columnas: ["importePago"], tipo: "moneda"
            },
            {
                columnas: ["fechaTramite", "fechaModificacion"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["folioTramite"], tipo: "number"
            },
        ],
        responsiveTitle: ["folioInterno", "fechaTramite", "empresa", "aseguradora", "agenteSeguro"],
        filter: [
            // { campo: "folioTramite",  placeholder: "Folio Tramite" },
            { campo: "poliza", placeholder: "Póliza" },
            // { campo: "endoso", placeholder: "Endoso" },
            { campo: "folioTramite", placeholder: "Folio Tramite" },
            // { campo: "polizaPrincipal", placeholder: "Póliza Principal" },
        ]
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaTrazabilidadSiniestros}
            token={token}
            parametrosFijos={{ reporteDaniosID: nReporteDaniosID !== 0 && nReporteDaniosID !== undefined && nReporteDaniosID !== null ? Number(nReporteDaniosID) : null, polizasSeguroID: nPolizasSeguroID !== 0 && nPolizasSeguroID !== undefined && nPolizasSeguroID !== null ? Number(nPolizasSeguroID) : null, controlPagoID: ncontrolPagoID !== 0 && ncontrolPagoID !== undefined && ncontrolPagoID !== null ? Number(ncontrolPagoID) : null, siniesReclAsegID: nSiniesReclAsegID !== 0 && nSiniesReclAsegID !== undefined && nSiniesReclAsegID !== null ? Number(nSiniesReclAsegID) : null, siniesReclTercerosID: nSiniesReclTercerosID !== 0 && nSiniesReclTercerosID !== undefined && nSiniesReclTercerosID !== null ? Number(nSiniesReclTercerosID) : null }}
        />

        <Tabla
            infoTabla={infoTablaAdjuntos}
            asyncData={tablaDocumentosAdjuntos}
            token={token}
            parametrosFijos={{ reporteDaniosID: nReporteDaniosID !== 0 && nReporteDaniosID !== undefined && nReporteDaniosID !== null ? Number(nReporteDaniosID) : null, polizasSeguroID: nPolizasSeguroID !== 0 && nPolizasSeguroID !== undefined && nPolizasSeguroID !== null ? Number(nPolizasSeguroID) : null, controlPagoID: ncontrolPagoID !== 0 && ncontrolPagoID !== undefined && ncontrolPagoID !== null ? Number(ncontrolPagoID) : null, siniesReclAsegID: nSiniesReclAsegID !== 0 && nSiniesReclAsegID !== undefined && nSiniesReclAsegID !== null ? Number(nSiniesReclAsegID) : null, siniesReclTercerosID: nSiniesReclTercerosID !== 0 && nSiniesReclTercerosID !== undefined && nSiniesReclTercerosID !== null ? Number(nSiniesReclTercerosID) : null }}
        />
        {openVerReclAseg && <FormularioModal
            open={openVerReclAseg}
            title={titleModalReclAseg}
            onClose={handleCloseVerReclAseg}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsReclAseg}
            acciones={accionesFormularioVerReclAseg}
            focus={ReclAsegfechaAfectadoRef}
        />}


        {openVerReclTerceros && <FormularioModal
            open={openVerReclTerceros}
            title={titleModalReclTercero}
            onClose={handleCloseVerReclTerceros}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsReclTerceros}
            acciones={accionesFormularioVerReclTerceros}
            focus={ReclTercerosfechaAfectadoRef}
        />}


        {/* {openVerPago && <FormularioModal
            open={openVerPago}
            title={titleModalPago}
            onClose={handleCloseVerPago}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsPago}
            acciones={accionesFormularioVerPago}
            focus={PagofechaTramiteRef}
        />} */}


        {
        openVerPago && <Modal maxWidth={true} onClose={() => { setOpenVerPago(false) }}>
        <Tabla
        infoTabla={infoTablaControlPago}
        asyncData={tablaControlPagosReporteDanios}
        token={token}
        parametrosFijos={{reporteDaniosID: nReporteDaniosID}}
        />

        <FormularioModal
            open={openFormularioVerPago}
            title={titleModalPago}
            onClose={handleCloseVerPago}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsPago}
            acciones={accionesFormularioVerPago}
            focus={PagofechaTramiteRef}
        />
        
        </Modal>
        }

        

        {openVerPolPrincipal && <FormularioModal
            open={openVerPolPrincipal}
            title={titleModalPolizaPrincipal}
            onClose={handleCloseVerPolizaPrincipal}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsPolizaPrincipal}
            acciones={accionesFormularioVerPolizaPrincipal}
            focus={PolPrincipalnumeroRef}
        />}

        {openVerReporteDanios && <FormularioModal
            open={openVerReporteDanios}
            title={titleModalReporteDanio}
            onClose={handleCloseVerReporteDanios}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsReporteDanios}
            acciones={accionesFormularioVerReporteDanios}
            focus={PolPrincipalnumeroRef}
        />}


        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default TrazabilidadSiniestros;
