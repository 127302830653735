import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import AccountCircle from '@material-ui/icons/AccountCircle';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Formulario from '../../../_layout/genericComponents/Formulario';

import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
//storage
import { actualizaArchivo, eliminaArchivo, getArchivo } from "../../../helpers/serverStorage";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { selectEnumEstatus } from '../../../querys/Enums/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import { upsertFile, deleteFile, tablaServerStorage, getFile } from '../../../mutations/ServerStorage'
import { showFile } from '../../../helpers';
import './styles.css';

const { trim } = Validaciones;
const { Decrypt } = Encriptar;

function Documentacion({ datosDetalleAdjuntos, setOpenDocumentacion }) {

    const { Encrypt, Decrypt } = Encriptar;
    const { token,usuario } = useSelector(state => state.login);
    let history = useHistory();
    let cTipo = null;
    let nDoctoOrigenID = null;
    let cClasificacion = null;
    let cTabla = "";
    let cTitulo2 = "";
    let lUtilizaProps = true;

    let { params } = useParams();
    if (datosDetalleAdjuntos){
        if (datosDetalleAdjuntos.plUtilizaProps === false){
            params = undefined;
        }
    }
    if (params != undefined && params !== ":params") {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLDoctoOrigenID, pTipo, pClasificacion, pTabla, pTitulo2 } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        nDoctoOrigenID = pURLDoctoOrigenID;
        cTipo = pTipo;
        cClasificacion = pClasificacion;
        cTabla = pTabla;
        cTitulo2 = pTitulo2;
    }

    if (nDoctoOrigenID != undefined && nDoctoOrigenID.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pURLDoctoOrigenID, pTipo, pClasificacion, pTabla, pTitulo2 } = datosDetalleAdjuntos;
        nDoctoOrigenID = pURLDoctoOrigenID;
        cTipo = pTipo;
        cClasificacion = pClasificacion;
        cTabla = pTabla;
        cTitulo2 = pTitulo2;
    }


    var Tipos = [];
    var Clasificaciones = [];
    let remover = false;
    const cleanState = { serverStorageID: null };
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    let [stTipos, setTipos] = useState(Tipos)
    let [stClasificaciones, setClasificaciones] = useState(Clasificaciones)
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    let [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [documento, setDocumento] = useState({ valor: "", file: "", eliminar: remover });


    const errorArchivoRef = useRef(null);
    const archivoCleanRef = useRef(null);

    let titleAccion = stDatos.storageID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} documento`;
    let ArrayRef = [];

    stDatos.tipo = cTipo;
    stDatos.origenID = nDoctoOrigenID;
    stDatos.tabla = cTabla;

    const acciones = [
        /* {
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "serverStorageID" }, { campo: "tabla", variable: "tabla" }, { campo: "origenID", variable: "origenID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getFile
            })
        }, */ {
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "serverStorageID", variable: "serverStorageID" },
            { campo: "ruta", variable: "ruta" },
            { campo: "nombreArchivo", variable: "nombreArchivo" },],
            disabled: { multiseleccion: true },
            onClick: ({ serverStorageID, ruta, nombreArchivo }) => handelAlertEliminar({
                setAlert,
                mensaje: ` al documento ${nombreArchivo}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { serverStorageID, ruta, token }, asyncDelete: eliminaArchivo
                })
            })
        }];

    let botones = [
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                // history.goBack();
                if (lUtilizaProps) {
                    setOpenDocumentacion();
                } else {
                    history.goBack();
                }

            }
        }, {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }
    ];

    if (usuario.perfil !=='INVITADO'){
        botones = [...botones, {
            icono: PhotoCamera,
            color: info,
            descripcion: "Adjuntar Fotos",
            disabled: { multiseleccion: true },
            onClick: () => {
                setRedireccionarFormulario();
            }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "serverStorageID",
        color: primary,
        title: "Documentación Adjunta",
        title2: cTitulo2,
        iconTable: <AccountCircle />,
        headers: [
            // { variable: "serverStorageID", descripcion: "ID" , hide: true },
            { variable: "tabla", descripcion: "Categoría" },
            { variable: "tipo", descripcion: "Carpeta" },
            { variable: "clasificacion", descripcion: "Subcarpeta" },
            { variable: "nombreArchivo", descripcion: "Nombre" },
            { variable: "serverStorageID", descripcion: "Archivo" },
            { variable: "ruta", descripcion: "Ruta", hide: true },
        ],
        responsiveTitle: ["tipo", "clasificacion", "nombreArchivo"],
        filter: [
            { campo: "tabla", variable: "tabla", placeholder: "Capture la categoria" },
            { campo: "tipo", variable: "tipo", placeholder: "Capture el tipo" },
            { campo: "clasificacion", variable: "clasificacion", placeholder: "Capture la clasificacion" }
        ],
        alineacion: [{ columnas: [0], alineacion: "left" }, { columnas: [0, 4, 5, 6, 7], alineacion: "center" }],
        formato: [
            {
                columnas: ["serverStorageID"], tipo: "custom", callback: (data) =>
                    <div className='cAuxButtonDownload'><Button size="small" variant="outlined" color="primary" style={{backgroundColor: 'aliceBlue'}} onClick={() => descargar(data)}>Descargar</Button></div>

            }
        ],
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["serverStorageID"] = { error: false, helperText: "" };
    cleanState["serverStorageID"] = 0;

    cleanErrorState["errorArchivo"] = { error: false, helperText: "" };


    InicializarInputs("clasificacion", "Clasificación", "Capture la clasificación", "", "", 100);
    InicializarInputs("archivo", "Nombre del archivo", "nombre del archivo", [], "", 100);
    /* InicializarInputs("nombreDocumento", "Nombre del archivo", "nombre del archivo", "", "", 100);
    InicializarInputs("extension", "Extensión", "Extensión", "", "", 10); */


    //-------------------------------------------
    InputsConID["clasificacion"].grid = { md: 12, lg: 12 };
    InputsConID["archivo"].grid = { md: 12, lg: 12 };
    /* InputsConID["nombreDocumento"].grid = { md: 8, lg: 8 }; */
    /* InputsConID["extension"].grid = { md: 4, lg: 4 }; */
    //-------------------------------------------

    InputsConID["archivo"].tipo = 'custom';
    InputsConID["archivo"].custom = (<div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
    <input ref={archivoCleanRef} type = "file" name='archivo' accept=".doc, .xlsm, .xls, .xml, .pptx, .pdf, .jpg, .png, .jpeg" multiple={true}
    onChange={
        (e) => { handleChange(e, 'archivo')}
    }
    />
    <span name = {'errorArchivo'} style={{color: 'red'}}>
        {errorState.errorArchivo?.helperText}
    </span>
    </div>)
    /* InputsConID["extension"].inputProps = { readOnly: true } */

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0) {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["serverStorageID"],
            mensajeCorrecto: `El documento se ${stDatos["nombreArchivo"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];


    function setRedireccionarFormulario() {
        let seed = "segumex";
        const datos = `{ "origenDocID2": "${stDatos.origenID}", "tablaOrigen2": "${stDatos.tabla}" , "folioOrigen2": "${stDatos.tipo}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/FotosEvidencia/${url}`);
    }



    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }
    function handleChangeFiles({ files, value }, key) {
        if (files === null) {
            remover = true;
        }
        
        setDocumento({ valor: value, file: files[0], eliminar: remover });
        let nom = files[0].name;
        let punto = nom.indexOf('.');
        let ext = nom.substring(punto, nom.length);
        let nomDocumento = nom.substring(0, punto);

        setState({
            ...stDatos,
            [key]: value,
            [`${key}File`]: files[0],
            [`${key}Name`]: files[0].name,
            ["nombreDocumento"]: nomDocumento,
            ["extension"]: ext
        });
        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));
    }

    const descargar = async function (idDoc) { //get storagen

        let archivo = await getFile({ serverStorageID: idDoc }, token);
        let documento = await getArchivo(archivo.ruta, token);

        let inicio = documento.length;
        let cadena = documento.indexOf(',')
        let b64 = documento.substring(cadena + 1, inicio);

        let iniciofor = documento.indexOf(':');
        let finfor = documento.indexOf(';');

        let ext = documento.substring(iniciofor + 1, finfor);

        showFile(b64, archivo.nombreArchivo, ext)

    };
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones() {

        let error;
        let id = stDatos.serverStorageID == 0 ? null : stDatos.serverStorageID;
        let auxErrorArchivo = {
            error: false,
            mensaje: ''
        };
        for(let i = 0; i < stDatos['archivo'].length; i++){
            if(stDatos['archivo'][i].size >= 10000000){
                auxErrorArchivo = {
                    error: true,
                    mensaje: `El archivo " ${stDatos['archivo'][i].name} " sobrepasa los 10 MB permitidos`
                }
                break;
            }
        }
        let validaciones = [
            {
                condicion: !stDatos["clasificacion"] || trim(stDatos["clasificacion"]) === "",
                ref: ArrayRef["clasificacion"], keyError: "clasificacion",
                mensajeError: "Debe ingresar el nombre de clasificacion"
            },
            {
                condicion: stDatos["archivo"].length < 1 ,
                ref: errorArchivoRef, keyError: 'errorArchivo',
                mensajeError: "Debe seleccionar por lo menos un archivo"
            },
            {
                condicion: stDatos["archivo"].length > 10 ,
                ref: errorArchivoRef, keyError: 'errorArchivo',
                mensajeError: "No se pueden subir más de 10 archivos"
            },
            {
                condicion: auxErrorArchivo.error ,
                ref: errorArchivoRef, keyError: 'errorArchivo',
                mensajeError: auxErrorArchivo.mensaje
            }
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({
                condicion, ref, keyError, mensajeError
            })
        });
        if (error) return error;
    }


    function ftGuardar() {
        async function ftGuardar() {
            let error = handleValidaciones();

            if (error) return error;




            let tipo = "EJE-2021-06";
            let rutaArchivo = stDatos.tabla + '/' + stDatos.tipo + '/' + stDatos.clasificacion + '/';

            let datos = {};
            let errorUpdate = [];

            for(let i = 0; i < stDatos.archivo.length; i++){

                datos = {
                    serverStorageID: null,
                    origenID: Number(stDatos.origenID),
                    tipo: stDatos.tipo ? stDatos.tipo : tipo,
                    clasificacion: stDatos.clasificacion,
                    nombreArchivo: `${stDatos.archivo[i].name}`,
                    ruta: rutaArchivo,
                    tabla: stDatos.tabla ? stDatos.tabla : "SINIESTROS"
                }
                try{
                await actualizaArchivo(rutaArchivo, stDatos.archivo[i], 1, datos, token);
                }catch({}){
                    errorUpdate.push(`${stDatos.archivo[i].name}`);
                }
            }

            if(errorUpdate.length > 0){
                handleAlertMutationGeneric({
                    token, setAlert, setActualizar, actualizar,
                    mensajes: {
                        msjCorrecto: {
                            title: "Error al subir archivos", descripcion: `Se subieron los archivos, excepto los siguientes: ${errorUpdate}`, msjConfirmacion: "Aceptar", tipo: danger
                        }
                    }
                })
            }

        }
        return ftGuardar();
    }

    function handleClose() {
        archivoCleanRef.current.value =  null;
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key, MaxLength = 0) {
        
        if(key == 'archivo'){
            let { name, files } = value.target;
            let archivos = Array.from(files);
            setState(stDatos => {
                return {
                    ...stDatos,
                    [key]: archivos
                }
            })
            return;
        }
        else{
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }
        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));
    }
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaServerStorage}
            token={token}
            parametrosFijos={{ origenID: stDatos.origenID ? stDatos.origenID : null, tabla: cTabla }}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["UsuarioNombre"]}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default Documentacion;
