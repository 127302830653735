import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_TRANSPORTE_MERCANCIA = `
mutation add($input: InputAddSolSegFianTranspMcia)
{
  addSolSegFianTranspMcia(input: $input){
    transpMciasID
    solSegFianID
    activoAsegID
    usuarioBitID
    equipo
    marca
    capacidad
    valorAseg
    cdOrigen
    fechaSalida
    embalaje
    pesoyVol
    empresaID
    usuarioSolicita
    nuevoUsado
    serie
    modelo
    noInv
    valorNuevo
    cdDestino
    fechaLlegada
    chofer
    sobreDimension
    activoAsegurar
    sustituyeID
    sustituye
    divisaID
    paridad
    activo
    montoAsegurado
  }
}
`;

export const UPDATE_TRANSPORTE_MERCANCIA =`
mutation update($input: InputUpdateSolSegFianTranspMcia)
{
  updateSolSegFianTranspMcia(input: $input){
    transpMciasID
    solSegFianID
    activoAsegID
    usuarioBitID
    equipo
    marca
    capacidad
    valorAseg
    cdOrigen
    fechaSalida
    embalaje
    pesoyVol
    empresaID
    usuarioSolicita
    nuevoUsado
    serie
    modelo
    noInv
    valorNuevo
    cdDestino
    fechaLlegada
    chofer
    sobreDimension
    activoAsegurar
    sustituyeID
    sustituye
    divisaID
    paridad
    activo
    montoAsegurado
  }
}
`;

export const DELETE_TRANSPORTE_MERCANCIA = `
mutation delete($input : InputDeleteSolSegFianTranspMcia)
{
  deleteSolSegFianTranspMcia(input: $input)
}
`;

export const addTransporteMercancia = async (variables, token) => {
    try {
        let { addSolSegFianTranspMcia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_TRANSPORTE_MERCANCIA
        });
        return addSolSegFianTranspMcia;
    } catch({message}) { throw new Error(message); }
};

export const updateTransporteMercancia = async (variables, token) => {
    try {
        let { updateSolSegFianTranspMcia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_TRANSPORTE_MERCANCIA
        });
        return updateSolSegFianTranspMcia;
    } catch({message}) { throw new Error(message); }
};

export const deleteTransporteMercancia= async (input, token) => {
    try {
        let { deleteSolSegFianTranspMcia } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_TRANSPORTE_MERCANCIA
        });
        return deleteSolSegFianTranspMcia;
    } catch({message}) { throw new Error(message); }
};
