import React, { useState, useRef, useEffect } from 'react';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import Formulario from '../../../_layout/genericComponents/Formulario';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setUsuario, setConfiguracion,setFechasFiltro } from '../../../redux/actions/loginAction';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SendIcon from '@material-ui/icons/Send';
import HelpIcon from '@material-ui/icons/Help';
import ReplayIcon from '@material-ui/icons/Replay';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EmailIcon from '@material-ui/icons/Email';
import FaceIcon from '@material-ui/icons/Face';

import styles from "../../../_layout/assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import loginFondo from '../../../assets/img/puerto.jpg';
import loginFondoSeleccion from '../../../assets/img/puerto.jpg';

import { seedBackend } from '../../../helpers';
import { asyncHandleFocus, handleFocus, handleErrorInputText, focusValidacion } from '../../../_layout/helpers/handles';
import { info, warning, primary, success, danger, black } from '../../../_layout/helpers/colores';
import { rgxNumeros } from '../../../helpers/regexp';
import { Encriptar } from '@renedelangel/helpers';

import * as Consultas from '../../../querys/Login/index.js';

const useStyles = makeStyles(styles);
const { DescriptarToken } = Encriptar;

function Login() {

    const classes = useStyles();
    const fecha = new Date();
    const { usuario } = useSelector(state => state.login);
    const aFechas  = { FechaInicio: fecha, FechaFinal : fecha} ;

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));
    const setUsuarioRedux = (usuario) => dispatch(setUsuario(usuario));
    const setFechasFiltroRedux = (aFechas) => dispatch(setFechasFiltro(aFechas));
    const setConfiguracionRedux = (configuracion) => dispatch(setConfiguracion(configuracion));

    const cleanDisabled = { username: false, password: true, empresaID: true, acceder: true };
    const cleanSeleccionables = { empresas: [] };
    const cleanSesion = { username: "", password: "", empresaID: null, nombreUsuario: "" };
    const cleanLoader = { acceder: false, cambiarPassword: false };
    const cleanErrorState = {
        username: { error: false, helperText: ""  },
        password: { error: false, helperText: ""  },
        empresaID: { error: false, helperText: ""  }
    };

    const [sesion, setSesion] = useState(cleanSesion);
    const [ver, setVer] = useState(false);
    const [seleccion, setSeleccion] = useState(null);
    const [seleccionables, setSeleccionables] = useState(cleanSeleccionables);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [loader, setLoader] = useState(cleanLoader);
    const [disabled, setDisabled] = useState(cleanDisabled);
    const [alert, setAlert] = useState(null);

    const title = "Segumex";
    const grid = { xs:12, sm: 12, md: 5, lg: 4 };

    let usernameRef = useRef(null);
    let passwordRef = useRef(null);
    let accederRef = useRef(null);
    let empresaRef = useRef(null);

    const iconoPassword = {
        onClick: () => !disabled.password && setVer(ver => !ver),
        style: { cursor: "pointer" }
    };

    const inputs = [{
        //disabled: disabled.username,
        id: "username",
        value: sesion.username,
        error: errorState.username.error,
        success: sesion.username && !errorState.username.error ? true : undefined,
        helperText: errorState.username.helperText,
        title: "Nombre de usuario o correo electrónico",
        placeholder: "Capture su nombre de usuario o correo electrónico",
        icono: <EmailIcon />,
        inputRef: usernameRef,
        onChange: ({target:{value}}) => handleSesion(value, "username"),
        onKeyDown: ({key, keyCode}) => (key === "Enter" || keyCode === 13) && ftVerificaUsername(),
        inputProps: { onBlur: ftVerificaUsername }
    }, {
        //disabled: disabled.password,
        id: "password",
        value: sesion.password,
        error: errorState.password.error,
        success: sesion.password && !errorState.password.error ? true : undefined,
        helperText: errorState.password.helperText,
        title: "Contraseña",
        placeholder: "Capture su contraseña",
        icono: disabled.password ? <VpnKeyIcon /> : (ver ? <VisibilityOffIcon { ...iconoPassword } />  : <VisibilityIcon { ...iconoPassword }/>),
        inputRef: passwordRef,
        onChange: ({target:{value}}) => handleSesion(value, "password"),
        onKeyDown: ({ key, keyCode }) => (key === "Enter" || keyCode === 13) && ftIniciarSesion(),
        inputProps: { type: ver ? "text" : "password" }
    }];

    const inputsEleccion = [{
        disabled: disabled.empresaID,
        id: "empresaID",
        inputRef: empresaRef,
        value: sesion.empresaID,
        error: errorState.empresaID.error,
        success: sesion.empresaID && !errorState.empresaID.error ? true : undefined,
        helperText: errorState.empresaID.helperText,
        title: "Empresa *",
        placeholder: "Seleccione la empresa ",
        tipo: "autocomplete",
        data: seleccionables.empresas,
        onChange: (val, {action}) => {
            let value = val ? val.value : null;
            setSesion(sesion => ({ ...sesion, empresaID: value }));
        }
    }];

    const cambiarPassword = (sesion.username.length > 0 && !seleccion) ? [{
        loader: loader.cambiarPassword,
        disabled: disabled.password,
        icono: SendIcon,
        color: primary,
        descripcion: "Recuperar contraseña",
        onClick: ftRecuperarPassword,
        // inputRef: accederRef
    }] : [];

    //Botones
    const acciones = [{
        loader: loader.acceder,
        disabled: loader.acceder,
        icono: ArrowRightIcon,
        color: info,
        descripcion: "Acceder",
        onClick: () => ftIniciarSesion(),
        inputRef: accederRef
    },{
        disabled: seleccion && !loader.acceder ? false : disabled.password,
        icono: ReplayIcon,
        color: warning,
        descripcion: "Cambiar de usuario",
        onClick: () => seleccion ? ftCambiarUsuario() : asyncHandleFocus({key: "Enter", keyCode: 13}, usernameRef, true, async () => ftCambiarUsuario())
    }, ...cambiarPassword];

    function ftVerificaUsername() {
        async function verificaUsername() {

            setLoader({ ...loader, acceder: true });
            try {
                setDisabled(disabled => ({
                    ...disabled,
                    username: true
                }));
                const isValid = await Consultas.Ejecutar({ username: sesion.username }, null, Consultas.IS_USERNAME);
                //const isValid = await isUsername({ username: sesion.username }, );

                if(!isValid) throw new Error("No fue posible válidar al usuario capturado");
                setErrorState(cleanErrorState);
                setDisabled(disabled => ({
                    ...disabled,
                    password: false
                }));
                focusValidacion({ referencia: passwordRef });
            } catch({message:helperText}) {
                setDisabled(cleanDisabled);
                setSesion(sesion => ({
                    ...sesion,
                    password: ''
                }))
                setErrorState(errorState => ({
                    ...errorState,
                    username: { helperText, error: true }
                }));
            }
            setLoader(cleanLoader);
        } verificaUsername();
    }

    function ftIniciarSesion() {
        async function iniciarSesion() {
            setLoader({ ...loader, acceder: true });
            try {
                setDisabled(disabled => ({
                    ...disabled,
                    password: true
                }));

                const token = await Consultas.Ejecutar({ username: sesion.username, password: sesion.password }, sesion.token, Consultas.GET_LOGIN);
                if(!token) throw new Error("No se pudo obtener el token");

                if(/("|')((?:\\\1|(?:(?!\1).))*)\1/.test(token)) {

                    const { nombreUsuario, accesosEmpresas:listadoEmpresas } = JSON.parse(token);

                    const empresas = listadoEmpresas.map(({ empresaID:value, empresa:label }) => ({ value, label }));
                    setSeleccion(listadoEmpresas);

                    if(listadoEmpresas.length === 1) {

                        if(!listadoEmpresas[0].empresas) throw new Error("Lamentablemente la cuenta a la que tiene acceso no cuenta con empresas disponibles");

                        let empresas = listadoEmpresas[0].empresas.map(({ empresaID:value, sucursal:label }) => ({ value, label }));
                        setSesion(sesion => ({ ...sesion, nombreUsuario, empresaID: listadoEmpresas[0].empresaID.toString() }));
                        setSeleccionables(seleccionables => ({ ...seleccionables, empresas }));
                        setDisabled(disabled => ({ ...disabled, empresaID: false, password: true, acceder: false }));
                        setLoader(cleanLoader);
                        return;
                    }

                    setSesion(sesion => ({ ...sesion, nombreUsuario }));
                    setSeleccionables(seleccionables => ({ ...seleccionables, empresas }));
                    setDisabled(disabled => ({ ...disabled, empresaID: false, password: true, acceder: false }));
                    setLoader(cleanLoader);
                    return;
                };

                let { usuario } = await await DescriptarToken({ token, seed: seedBackend });
               
                
                setFechasFiltroRedux(aFechas);
               
                setUsuarioRedux(usuario);
                setTokenRedux(token);
                setErrorState(cleanErrorState);

            } catch({message:helperText}) {
                setDisabled(disabled => ({
                    ...disabled,
                    password: false
                }));
                setErrorState(errorState => ({
                    ...errorState,
                    password: { helperText, error: true }
                }));
                setLoader(cleanLoader);
            }
        } iniciarSesion();
    }

    function ftRecuperarPassword() {
        async function recuperarPassword() {
            setLoader({ ...loader, cambiarPassword: true });
            setDisabled(disabled => ({
                ...disabled,
                password: true
            }));
            try {

                await Consultas.Ejecutar({ username: sesion.username }, null, Consultas.GET_NEWPASSWORD);

                setAlert({
                    descripcion: "Te hemos enviado un correo para que puedas cambiar tu contraseña, este correo solo te permite cambiar la contraseña durante los próximos 5 minutos",
                    title: "¡Revisa tu correo!",
                    tipo: success,
                    msjConfirmacion: "¡Gracias!",
                    onConfirm: () => setAlert(null)
                });

            } catch({message}) {
                setAlert({
                    descripcion: message,
                    title: "¡Te pedimos una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "De acuerdo",
                    onConfirm: () => setAlert(null)
                });
            }
            setDisabled(disabled => ({
                ...disabled,
                password: false
            }));
            setLoader(cleanLoader);
        } recuperarPassword();
    }

    function handleSesion(value, id) {
        setSesion(sesion => ({
            ...sesion,
            [id]: value
        }));
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, errorState: setErrorState, callback: () => setDisabled(disabled => ({ ...disabled, acceder: false, empresaID: seleccion.length === 1 })) });
    }

    function handleValidaciones({ empresaID }) {

        let error;
        let validaciones = [{
            condicion: !rgxNumeros.test(empresaID), keyError: "empresaID",
            mensajeError: "Es necesario seleccionar una empresa para continuar con el proceso."
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;
    }

    function ftAcceder() {
        async function ftAcceder() {
            try {
                setLoader({ ...loader, acceder: true });
                setDisabled(disabled => ({ ...disabled, acceder: true, empresaID: true}));

                let { username, empresaID } = sesion;
                let error = await handleValidaciones({ empresaID });

                if(error) return error;

                let token = await Consultas.Ejecutar({ username, empresaID }, null , Consultas.GET_NEWTOKEN );

                if(!token) throw new Error("No se pudo obtener el token");

                let { usuario } = await await DescriptarToken({ token, seed: seedBackend });
                // setConfiguracionRedux(configuracion);
                setUsuarioRedux(usuario);
                setTokenRedux(token);
                setErrorState(cleanErrorState);

            }  catch({message:helperText}) {
                setDisabled(disabled => ({ ...disabled, acceder: false, empresaID: seleccion.length === 1}));
                setErrorState(errorState => ({
                    ...errorState,
                    empresaID: { helperText, error: true }
                }));
                setLoader(cleanLoader);
            }
        }
        return ftAcceder();
    }

    function ftCambiarUsuario() {
        setUsuarioRedux(null);
        setDisabled(cleanDisabled);
        setErrorState(cleanErrorState);
        setSeleccion(null);
        setSeleccionables(cleanSeleccionables);
        setSesion(cleanSesion);
        setLoader(cleanLoader);
    }

    function ftEffect() {
        if(usuario && usuario.usuario) {
            handleSesion(usuario.usuario, "username");
            setErrorState(cleanErrorState);
            setDisabled(disabled => ({
                ...disabled,
                username: true,
                password: false
            }));
            handleFocus({ key: "Enter", keyCode: 13 }, passwordRef);
        }
    }

    useEffect(ftEffect, [usuario]);

    return (
        <div
        className={classes.fullPage}
        style={{ backgroundImage: "url(" + (seleccion ? loginFondoSeleccion : loginFondo) + ")" }}
            >
        <Formulario
            title={title}
            icono={<LockOpenIcon />}
            grid={grid}
            inputs={seleccion ? inputsEleccion : inputs}
            acciones={acciones}
            focus={seleccion ? empresaRef : usernameRef}
            color={primary}
            info={seleccion ? {
                message: seleccion ? `Hola ${sesion.nombreUsuario} es necesario que indique con que empresa desea continuar` : "Si lo que requiere es recuperar su contraseña solo debe de capturar su nombre de usuario o correo electrónico y presionar el botón de recuperar contraseña (solo es visible al detectar datos en el campo del usuario)",
                icon: HelpIcon,
                color: seleccion ? success : primary
            } : undefined}
            otraInfo={(usuario && usuario.nombreUsuario) && [{
                titulo: usuario.nombreUsuario,
                descripcion: `Parece que tu sesión ha finalizado, si el usuario capturado no es el tuyo elige la opción: "Cambiar de usuario"`,
                icono: FaceIcon,
                colorIcono: primary
            }]}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </div>);

}

export default Login;
