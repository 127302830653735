import React, { useState, useEffect } from 'react'

// Redux
import { useSelector } from 'react-redux';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import Gantt from '../../../_layout/components/Gantt';
import GridContainer from "../../../_layout/components/Grid/GridContainer.js";
import GridItem from '../../../_layout/components/Grid/GridItem.js';
import Select from '../../../_layout/genericComponents/Inputs/components/Select';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import { seleccionProgramaAnual, getProgramaAnual } from "../../../querys/ProgramaAnual";
import { getAllTareasProgAnual } from "../../../querys/TareasProgAnual";
import { getAllActividadesDeTareas } from "../../../querys/ActividadesDeTareas";
import { handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import { urlServidor } from '../../../helpers';
import { RiFileExcel2Fill } from "react-icons/ri";
import MiniBoton from '../../../_layout/genericComponents/Botones/componentes/MiniBoton';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionUsuario } from '../Usuarios/Consultas';
function ProgramaAnualGantt() {

    const { token } = useSelector(state => state.login);
    const [state, setState] = useState({
        programaID: 0,
        tipoCalendario: 1,
        vista: 1,
        ejercicio: "",
        folio: "",
        empresaID: 0,
        mes: 0,
        ramoSeguroID: 0,
        usuarioID: 0
    });
    const [stInfoGantt, setInfoGantt] = useState({ fechaInicial: new Date(), fechaFinal: new Date() });
    const [alert, setAlert] = useState(null);
    const [actualizar, setActualizar] = useState(false);
    const [selectProgramaAnual, setSelectProgramaAnual] = useState([]);
    const [selectEmpresas, setSelectEmpresas] = useState([]);
    const [selectRamoSeguro, setSelectRamoSeguro] = useState([]);
    const [selectUsuarios, setSelectUsuarios] = useState([]);
    const [tareas, setTareas] = useState([]);

    const listMeses = [
        { label: 'TODOS', value: 0 },
        { label: 'Enero', value: 1 },
        { label: 'Febrero', value: 2 },
        { label: 'Marzo', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Mayo', value: 5 },
        { label: 'Junio', value: 6 },
        { label: 'Julio', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Septiembre', value: 9 },
        { label: 'Octubre', value: 10 },
        { label: 'Noviembre', value: 11 },
        { label: 'Diciembre', value: 12 },
    ];

    useEffect(() => {
        async function ftEffect() {
            try {
                let programaAnual = await seleccionProgramaAnual({ filtro: {} }, token);
                setSelectProgramaAnual(programaAnual);

                let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                empresas = [{ label: "TODAS", value: 0 }, ...empresas];
                setSelectEmpresas(empresas);

                let ramoSeguros = await seleccionRamoSeguro({ filtro: {} }, token);
                ramoSeguros = [{ label: "TODOS", value: 0 }, ...ramoSeguros];
                setSelectRamoSeguro(ramoSeguros);

                let usuarios = await seleccionUsuario({}, token);
                usuarios = [{ label: "TODOS", value: 0 }, ...usuarios];
                setSelectUsuarios(usuarios);

                let fecha = new Date();
                for (let i = 0; i < programaAnual.length; i++) {
                    const ejercicio = programaAnual[i];
                    if (ejercicio.label === fecha.getFullYear().toString()) {
                        handleChange(ejercicio.value, "programaID")
                    }
                }

            } catch ({ message }) {
                /*setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });*/
            }
        } ftEffect();
    }, []);

    let selectPrograma = {
        disabled: false,
        id: "programaID",
        value: state.programaID,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Ejercicio",
        placeholder: "Selecciona el Ejercicio",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: selectProgramaAnual,
        onChange: ({ target: { value } }) => handleChange(value, "programaID"),
        onKeyDown: () => { return; }
    };

    let selectCalendario = {
        disabled: false,
        id: "tipoCalendario",
        value: state.tipoCalendario,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Tipo Calendario",
        placeholder: "Selecciona el tipo de calendario",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: [
            {
                label: 'Mensual',
                value: 1
            },
            {
                label: 'Semanal',
                value: 2
            },
        ],
        onChange: ({ target: { value } }) => handleChange(value, "tipoCalendario"),
        onKeyDown: () => { return; }
    };

    let selectVista = {
        disabled: false,
        id: "vista",
        value: state.vista,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Tipo Vista",
        placeholder: "Selecciona la vista",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: [
            {
                label: 'Programado',
                value: 1
            },
            {
                label: 'Programado vs Real',
                value: 2
            },
        ],
        onChange: ({ target: { value } }) => handleChange(value, "vista"),
        onKeyDown: () => { return; }
    };

    let selEmpresa = {
        disabled: false,
        id: "empresaID",
        value: state.empresaID,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Empresa",
        placeholder: "Selecciona la empresa",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: selectEmpresas,
        onChange: ({ target: { value } }) => handleChange(value, "empresaID"),
        onKeyDown: () => { return; }
    };

    let selRamoSeguro = {
        disabled: false,
        id: "ramoSeguroID",
        value: state.ramoSeguroID,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Ramo de Seguro",
        placeholder: "Selecciona el ramo de seguro",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: selectRamoSeguro,
        onChange: ({ target: { value } }) => handleChange(value, "ramoSeguroID"),
        onKeyDown: () => { return; }
    };

    let selUsuario = {
        disabled: false,
        id: "usuarioID",
        value: state.usuarioID,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Usuario",
        placeholder: "Selecciona el usuario",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: selectUsuarios,
        onChange: ({ target: { value } }) => handleChange(value, "usuarioID"),
        onKeyDown: () => { return; }
    };

    let selMes = {
        disabled: false,
        id: "mes",
        value: state.mes,
        error: "",
        success: undefined,
        helperText: "",
        inputRef: undefined,
        title: "Mes",
        placeholder: "Selecciona el mes",
        grid: { xs: 12, sm: 12, md: 2, lg: 2 },
        tipo: "select",
        data: listMeses,
        onChange: ({ target: { value } }) => handleChange(value, "mes"),
        onKeyDown: () => { return; }
    };

    /*let imprimir = {
        index: "imprimir1",
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Descargar PDF",
        parametros: [],
        disabled: { multiseleccion: true },
        onClick: () => AuxDescargar()
    }

    <GridItem xs={2} sm={2} md={2} lg={2}>
        <MiniBoton
            key={imprimir.index}
            Icono={imprimir.icono}
            color={imprimir.color}
            descripcion={imprimir.descripcion}
            disabled={false}
            onClick={imprimir.onClick}
        />
    </GridItem>

    function AuxDescargar(formatoReporte="PDF") {
        setdatosPDf({
            formatoReporte,
            programaID: state.programaID,
            tipoCalendario: state.tipoCalendario,
            vista: state.vista
        });

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar el formato del programa anual?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato del programa anual",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el formato del programa anual',
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        });
    }

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("reporteDaniosCliID", datosPDF.reporteDaniosCliID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteDeDañosClientes' + datosPDF.numIncidencia
        await fetch(`${urlServidor}/service/reportes/ReporteProgramaAnualGantt`, requestOptions)
        .then(response => {
            if (!response.ok) return response.json();
            return response.blob()
        })
        .then(result => {
            let { error, mensaje } = result;
            if (error) throw new Error(mensaje);
            // showFile(result, `${nombreReporte}.pdf`);
            showFile(result, `${nombreReporte}${datosPDF.formatoReporte==='PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
            setAlert(null)
        })
        .catch(({ message }) => { throw new Error(message) });
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };
    */

    function handleChange(value, key) {
        setState(state => ({ ...state, [key]: value }));
        let localSt = { ...state };

        localSt = { ...localSt, [key]: value };
        if (key === "programaID" || key === "mes" || key === "ramoSeguroID" || key === "empresaID" || key === "usuarioID") {
            getTareas(localSt.programaID, localSt.mes, localSt.empresaID, localSt.ramoSeguroID, localSt.usuarioID);
        }
    }

    async function getTareas(programaID, mes, empresaID, ramoSeguroID, usuarioID) {
        programaID = parseInt(programaID);
        let programaAnual = await getProgramaAnual({ programaAnualID: programaID }, token);

        setState({
            ...state,
            ['ejercicio']: programaAnual.ejercicio,
            ['folio']: programaAnual.folioInterno,
            ['programaID']: programaID,
            ['mes']: mes,
            ['empresaID']: empresaID,
            ['ramoSeguroID']: ramoSeguroID,
            ['usuarioID']: usuarioID
        });

        let filtro = { programaAnualID: programaID };
        if (empresaID !== 0) {
            filtro["empresaID"] = parseInt(empresaID);
        }
        if (ramoSeguroID !== 0) {
            filtro["ramoSeguroID"] = parseInt(ramoSeguroID);
        }

        let tareasProg = await getAllTareasProgAnual({ filtro }, token);
        let filtroAct = { programaAnualID: programaID }
        if (usuarioID !== 0) {
            filtroAct["usuarioID"] = parseInt(usuarioID);
        }
        
        let actividadesProgAux = await getAllActividadesDeTareas({ filtro: filtroAct }, token);
        let actAux = {};
        let actividadesProg = [...actividadesProgAux];

        for (let i = 0; i < actividadesProg.length; i++) {
            let actProg = actividadesProg[i];

            if (!actProg.fechaInicio) {
                actProg.fechaInicio = "";
            }
            if (!actProg.fechaFinal) {
                actProg.fechaFinal = "";
            }

            if (!actProg.fechaInicioReal) {
                actProg.fechaInicioReal = "";
            }
            if (!actProg.fechaFinalReal) {
                actProg.fechaFinalReal = "";
            }

            let mesValido = { valido: false };
            actProg.fechaInicio = convertFecha(actProg.fechaInicio, mesValido, mes);
            if (mesValido.valido === false) {
                continue;
            }

            actProg.fechaFinal = convertFecha(actProg.fechaFinal);
            actProg.fechaInicioReal = convertFecha(actProg.fechaInicioReal);
            actProg.fechaFinalReal = convertFecha(actProg.fechaFinalReal);

            actProg.fechaInicio = quitarHoras(actProg.fechaInicio);
            actProg.fechaFinal = quitarHoras(actProg.fechaFinal);
            actProg.fechaInicioReal = quitarHoras(actProg.fechaInicioReal);
            actProg.fechaFinalReal = quitarHoras(actProg.fechaFinalReal);

            let partida = {
                actividadID: actProg.actividadID,
                descripcion: actProg.descripcion,
                siglas: actProg.siglas,
                ramoSeguro: actProg.ramoSeguro,
                fechaInicio: actProg.fechaInicio,
                fechaFinal: actProg.fechaFinal,
                fechaInicioReal: actProg.fechaInicioReal,
                fechaFinalReal: actProg.fechaFinalReal,
                avance: actProg.avance
            }

            if (!actAux.hasOwnProperty(actProg.tareaID)) {
                actAux[actProg.tareaID] = [partida];
            } else {
                actAux[actProg.tareaID].push(partida);
            }
        }

        let tareasAct = [];
        for (let i = 0; i < tareasProg.length; i++) {
            let tareaProg = tareasProg[i];
            if (!tareaProg.fechaInicio) {
                tareaProg.fechaInicio = "";
            }
            if (!tareaProg.fechaFinal) {
                tareaProg.fechaFinal = "";
            }

            if (!tareaProg.fechaInicioReal) {
                tareaProg.fechaInicioReal = "";
            }
            if (!tareaProg.fechaFinalReal) {
                tareaProg.fechaFinalReal = "";
            }

            let mesValido = { valido: false };
            tareaProg.fechaInicio = convertFecha(tareaProg.fechaInicio, mesValido, mes);

            if (actAux.hasOwnProperty(tareaProg.tareaID)) {
                mesValido.valido = true;
            }
            if (mesValido.valido === false) {
                continue;
            }

            tareaProg.fechaFinal = convertFecha(tareaProg.fechaFinal);
            tareaProg.fechaInicioReal = convertFecha(tareaProg.fechaInicioReal);
            tareaProg.fechaFinalReal = convertFecha(tareaProg.fechaFinalReal);

            tareaProg.fechaInicio = quitarHoras(tareaProg.fechaInicio);
            tareaProg.fechaFinal = quitarHoras(tareaProg.fechaFinal);
            tareaProg.fechaInicioReal = quitarHoras(tareaProg.fechaInicioReal);
            tareaProg.fechaFinalReal = quitarHoras(tareaProg.fechaFinalReal);

            let tTarea = {
                tareaID: tareaProg.tareaID,
                descripcion: tareaProg.objetivo,
                siglas: tareaProg.siglas,
                ramoSeguro: tareaProg.ramoSeguro,
                fechaInicio: tareaProg.fechaInicio,
                fechaFinal: tareaProg.fechaFinal,
                fechaInicioReal: tareaProg.fechaInicioReal,
                fechaFinalReal: tareaProg.fechaFinalReal,
                avance: tareaProg.avance,
                actividades: []
            }

            if (actAux.hasOwnProperty(tareaProg.tareaID)) {
                tTarea.actividades = actAux[tareaProg.tareaID];
            }
            tareasAct.push(tTarea);
        }
        setTareas(tareasAct);
    }

    function convertFecha(tFecha, mesValido, mesState = 0) {
        if (tFecha === "") {
            return "";
        }

        let fecha = new Date(tFecha);
        let mes = (fecha.getMonth() + 1).toString();

        if (mesValido) {
            mesValido.valido = true;

            if (mesState === 0) {
                mesValido.valido = true;
            } else {
                if (mes !== mesState.toString()) {
                    mesValido.valido = false;
                }
            }
        }

        if (mes.length === 1) {
            mes = "0" + mes;
        }

        let dia = fecha.getDate().toString();
        if (dia.length === 1) {
            dia = "0" + dia;
        }

        let result = fecha.getFullYear() + "-" + mes + "-" + dia;
        return result;
    }

    function quitarHoras(fecha) {
        if (fecha.includes(":")) {
            let aux = fecha.split(" ");
            fecha = aux[0];
        }
        return fecha;
    }

    function AuxDescargar() {
        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar la gráfica?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarExcel(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato de excel ",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el excel de la gráfica de gantt',
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarExcel() {
        let filtros = {
            ejercicio: state.ejercicio,
            empresa: getLabel(selectEmpresas, state.empresaID).toUpperCase(),
            folio: state.folio.toUpperCase(),
            mes: getLabel(listMeses, state.mes).toUpperCase(),
            programa: getLabel(selectProgramaAnual, state.programaID).toUpperCase(),
            ramoSeguroID: getLabel(selectRamoSeguro, state.ramoSeguroID).toUpperCase(),
            tipoCalendario: (state.tipoCalendario === 1 ? "Mensual" : "Semanal").toUpperCase(),
            usuario: getLabel(selectUsuarios, state.usuarioID).toUpperCase(),
            vista: (state.vista === 1 ? "Programado" : "Programado vs Real").toUpperCase()
        };

        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("filtros", JSON.stringify(filtros));
        formdata.append("filtrosAplicados", JSON.stringify(state));
        formdata.append("infoGantt", JSON.stringify(stInfoGantt));

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = "ProgramaGantt" + "_" + filtros.tipoCalendario + "_" + filtros.vista;
        await fetch(`${urlServidor}/service/reportes/ExcelGantt`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                showFile(result, `${nombreReporte} ${'.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    function getLabel(registros, id) {
        let label = "";
        for (let i = 0; i < registros.length; i++) {
            const registro = registros[i];
            if (registro.value.toString() === id.toString()) {
                label = registro.label;
            }
        }
        return label;
    }

    return (
        <>
            <GridContainer>
                <GridItem xs={selectPrograma.grid.xs} sm={selectPrograma.grid.sm} md={selectPrograma.grid.md} lg={selectPrograma.grid.lg}>
                    <Select
                        id={selectPrograma.id}
                        title={selectPrograma.title}
                        placeholder={selectPrograma.placeholder}
                        data={selectPrograma.data}
                        value={selectPrograma.value}
                        multiple={selectPrograma.multiple}
                        inputRef={selectPrograma.inputRef}
                        disabled={selectPrograma.disabled}
                        onChange={selectPrograma.onChange}
                        error={selectPrograma.error}
                        success={selectPrograma.success}
                        helperText={NetworkError(selectPrograma.helperText)}
                    />
                </GridItem>
                <GridItem xs={selMes.grid.xs} sm={selMes.grid.sm} md={selMes.grid.md} lg={selMes.grid.lg}>
                    <Select
                        id={selMes.id}
                        title={selMes.title}
                        placeholder={selMes.placeholder}
                        data={selMes.data}
                        value={selMes.value}
                        multiple={selMes.multiple}
                        inputRef={selMes.inputRef}
                        disabled={selMes.disabled}
                        onChange={selMes.onChange}
                        error={selMes.error}
                        success={selMes.success}
                        helperText={NetworkError(selMes.helperText)}
                    />
                </GridItem>
                <GridItem xs={selEmpresa.grid.xs} sm={selEmpresa.grid.sm} md={selEmpresa.grid.md} lg={selEmpresa.grid.lg}>
                    <Select
                        id={selEmpresa.id}
                        title={selEmpresa.title}
                        placeholder={selEmpresa.placeholder}
                        data={selEmpresa.data}
                        value={selEmpresa.value}
                        multiple={selEmpresa.multiple}
                        inputRef={selEmpresa.inputRef}
                        disabled={selEmpresa.disabled}
                        onChange={selEmpresa.onChange}
                        error={selEmpresa.error}
                        success={selEmpresa.success}
                        helperText={NetworkError(selEmpresa.helperText)}
                    />
                </GridItem>
                <GridItem xs={selRamoSeguro.grid.xs} sm={selRamoSeguro.grid.sm} md={selRamoSeguro.grid.md} lg={selRamoSeguro.grid.lg}>
                    <Select
                        id={selRamoSeguro.id}
                        title={selRamoSeguro.title}
                        placeholder={selRamoSeguro.placeholder}
                        data={selRamoSeguro.data}
                        value={selRamoSeguro.value}
                        multiple={selRamoSeguro.multiple}
                        inputRef={selRamoSeguro.inputRef}
                        disabled={selRamoSeguro.disabled}
                        onChange={selRamoSeguro.onChange}
                        error={selRamoSeguro.error}
                        success={selRamoSeguro.success}
                        helperText={NetworkError(selRamoSeguro.helperText)}
                    />
                </GridItem>
                <GridItem xs={selUsuario.grid.xs} sm={selUsuario.grid.sm} md={selUsuario.grid.md} lg={selUsuario.grid.lg}>
                    <Select
                        id={selUsuario.id}
                        title={selUsuario.title}
                        placeholder={selUsuario.placeholder}
                        data={selUsuario.data}
                        value={selUsuario.value}
                        multiple={selUsuario.multiple}
                        inputRef={selUsuario.inputRef}
                        disabled={selUsuario.disabled}
                        onChange={selUsuario.onChange}
                        error={selUsuario.error}
                        success={selUsuario.success}
                        helperText={NetworkError(selUsuario.helperText)}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={selectCalendario.grid.xs} sm={selectCalendario.grid.sm} md={selectCalendario.grid.md} lg={selectCalendario.grid.lg}>
                    <Select
                        id={selectCalendario.id}
                        title={selectCalendario.title}
                        placeholder={selectCalendario.placeholder}
                        data={selectCalendario.data}
                        value={selectCalendario.value}
                        multiple={selectCalendario.multiple}
                        inputRef={selectCalendario.inputRef}
                        disabled={selectCalendario.disabled}
                        onChange={selectCalendario.onChange}
                        error={selectCalendario.error}
                        success={selectCalendario.success}
                        helperText={NetworkError(selectCalendario.helperText)}
                    />
                </GridItem>
                <GridItem xs={selectVista.grid.xs} sm={selectVista.grid.sm} md={selectVista.grid.md} lg={selectVista.grid.lg}>
                    <Select
                        id={selectVista.id}
                        title={selectVista.title}
                        placeholder={selectVista.placeholder}
                        data={selectVista.data}
                        value={selectVista.value}
                        multiple={selectVista.multiple}
                        inputRef={selectVista.inputRef}
                        disabled={selectVista.disabled}
                        onChange={selectVista.onChange}
                        error={selectVista.error}
                        success={selectVista.success}
                        helperText={NetworkError(selectVista.helperText)}
                    />
                </GridItem>
                <GridItem xs={selectVista.grid.xs} sm={selectVista.grid.sm} md={selectVista.grid.md} lg={selectVista.grid.lg}>
                    <div style={{ display: "flex", height: "100%", width: "maxContent" }}>
                        <div style={{ display: "flex", margin: "auto" }}>
                            <MiniBoton
                                descripcion='Exportar a excel'
                                Icono={RiFileExcel2Fill}
                                color="success"
                                onClick={() => AuxDescargar()}
                            />
                        </div>
                    </div>

                </GridItem>
            </GridContainer>
            <GridContainer>
                <Gantt tareasPrograma={tareas}
                    vistaSemanal={state.tipoCalendario === 1 ? false : true}
                    ComparativaProgVSReal={state.vista === 1 ? false : true}
                    ejercicio={state.ejercicio}
                    folioInterno={state.folio}
                    mes={state.mes}
                    setInfoGantt={setInfoGantt}
                />
            </GridContainer>
            {
                alert && < SweetAlert title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={
                        () => setAlert(null)
                    }
                />
            }
        </>
    );
}

export default ProgramaAnualGantt;
