import React from 'react';

import { makeStyles } from "@material-ui/core/styles";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';

// material-ui icons
import Check from "@material-ui/icons/Check";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

export default function CheckboxCustom(props) {

    const classes = useStyles();

    let { checked, disabled, onChange, value, title, iconos = {}, multiple, data } = props;
    let { checkedIcon, icon } = iconos, checkedMulti = {};

    if(multiple && (Array.isArray(data) && data.length > 0)) data.forEach(({value:id}) => {
        let chk = [];
        if(checked && Array.isArray(checked) && checked.length > 0) chk = checked.filter(c => c.toString() === id.toString())
        checkedMulti = { ...checkedMulti, [id]: chk.length > 0 };
    });

    function onChangeMulti({ value:v, checked:chk }) {
        let newChk = [v];
        if(checked && Array.isArray(checked) && checked.length > 0) newChk = [...checked, ...newChk].sort();
        onChange(chk ? newChk : newChk.filter(a => a.toString() !== v.toString()));
    }

    return (multiple ? ((Array.isArray(data) && data.length > 0) && data.map(({value, label}, key) => (<FormControlLabel
        key={key}
        control={<Checkbox
            checked={checkedMulti[value] ? checkedMulti[value] : false}
            disabled={disabled}
            onChange={({ target:{value, checked} }) => onChangeMulti({value, checked})}
            value={value}
            checkedIcon={checkedIcon ? checkedIcon : <Check className={classes.checkedIcon} />}
            icon={icon ? icon : <Check className={classes.uncheckedIcon} />}
            classes={{
                checked: classes.checked,
                root: classes.checkRoot
            }}
        />}
        label={label}
    />))) : <FormControlLabel
        control={<Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onChange}
            value={value}
            checkedIcon={checkedIcon ? checkedIcon : <Check className={classes.checkedIcon} />}
            icon={icon ? icon : <Check className={classes.uncheckedIcon} />}
            classes={{
                checked: classes.checked,
                root: classes.checkRoot
            }}
        />}
        label={title}
    />)
}
