import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import { rgxdeInt2 } from '../../../helpers/regexp';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getPolizaSegAuto, tablaPolizaSegAuto } from '../../../querys/PolizaSegAuto';
import { deletePolizaSegAuto, addPolizaSegAuto, updatePolizaSegAuto } from '../../../mutations/PolizaSegAuto';

import moment from 'moment';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionAseguradora } from '../../../querys/Aseguradoras';
import { rgxdeInt11, rgxdeInt4, rgxInputTexto30, rgxInputTexto10, rgxInputTexto20, rgxInputTexto50, rgxNumeros, rgxDecimal14 } from '../../../helpers/regexp';

import { urlServidor } from '../../../helpers';
import * as Permisos from '../Permisos';

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function PolizaSegAuto() {
    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";
    let history = useHistory();
    let cTitulo2 = "ANEXOS: Recuerde adjuntar documento fuente.";
    const { token } = useSelector(state => state.login);

    const cleanState = {
        polizaSegAutosID: null,
        partida: "",
        empresaID: "",
        usario: "",
        aseguradoraID: "",
        flotilla: "",
        polizaInciso: "",
        marca: "",
        tipoModelo: "",
        anio: "",
        numSerie: "",
        transmision: "",
        fechaSolCot: "",
        vigenciaIni: now.format(formatoDelmomento),
        vigenciaHasta: "",
        valorFactura: "",
        coberAdicionales: "",
        sumaAsegurada: "",
        primaNeta: "",
        primaTotal: "",
        renovacion: false,
        horasInvertidas: ""
    };
    const cleanErrorState = {
        partida: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        usario: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        flotilla: { error: false, helperText: "" },
        polizaInciso: { error: false, helperText: "" },
        marca: { error: false, helperText: "" },
        tipoModelo: { error: false, helperText: "" },
        anio: { error: false, helperText: "" },
        numSerie: { error: false, helperText: "" },
        transmision: { error: false, helperText: "" },
        fechaSolCot: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        valorFactura: { error: false, helperText: "" },
        coberAdicionales: { error: false, helperText: "" },
        sumaAsegurada: { error: false, helperText: "" },
        primaNeta: { error: false, helperText: "" },
        primaTotal: { error: false, helperText: "" },
        renovacion: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" }
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    let [datosPDF, setdatosPDf] = useState({});

    const [seleccionables, setSeleccionables] = useState(false);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    let titleAccion = state.polizaSegAutosID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Póliza`;
    const partidaRef = useRef(null);
    const empresaIDRef = useRef(null);
    const usarioRef = useRef(null);
    const aseguradoraIDRef = useRef(null);
    const flotillaRef = useRef(null);
    const polizaIncisoRef = useRef(null);
    const marcaRef = useRef(null);
    const tipoModeloRef = useRef(null);
    const anioRef = useRef(null);
    const numSerieRef = useRef(null);
    const transmisionRef = useRef(null);
    const fechaSolCotRef = useRef(null);
    const vigenciaIniRef = useRef(null);
    const vigenciaHastaRef = useRef(null);
    const valorFacturaRef = useRef(null);
    const coberAdicionalesRef = useRef(null);
    const sumaAseguradaRef = useRef(null);
    const primaNetaRef = useRef(null);
    const primaTotalRef = useRef(null);
    const horasInvertidasRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "polizaSegAutosID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getPolizaSegAuto, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) },
                { id: "vigenciaIni", formato: (data) => moment(data) },
                { id: "vigenciaHasta", formato: (data) => moment(data) }
            ]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "polizaSegAutosID" },
        { campo: "usario" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizaSegAutosID, usario }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la poliza del usuario ${usario}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { polizaSegAutosID }, asyncDelete: deletePolizaSegAuto
            })
        })
    },
    {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "polizaSegAutosID" }, { campo: "partida" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizaSegAutosID, partida }) => AuxDescargar(polizaSegAutosID, partida)
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "polizaSegAutosID" }, { campo: "polizaInciso" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizaSegAutosID, polizaInciso }) => {
            setRedireccionarFormulario({ polizaSegAutosID, polizaInciso });
        }
    }
    ];

    function AuxDescargar(polizaSegAutosID, partida) {

        setdatosPDf({ "polizaSegAutosID": polizaSegAutosID, "partida": partida });
        datosPDF = { "polizaSegAutosID": polizaSegAutosID, "partida": partida };


        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de poliza de auto?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de poliza de auto ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de poliza de auto ' + partida, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {

        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("polizaSegAutosID", datosPDF.polizaSegAutosID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReportePolizaSegAuto' + datosPDF.partida
        await fetch(`${urlServidor}/service/reportes/ReportePolizaSegAuto`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}.pdf`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }

    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "polizaSegAutosID",
        color: primary,
        title: "Pólizas de Seguro de Auto",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "polizaSegAutosID", descripcion: "polizaSegAutosID", hide: true },
            { variable: "partida", descripcion: "No. Partida" },
            { variable: "usario", descripcion: "Usuario" },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "nombre", descripcion: "Empresa" },
            { variable: "aseguradoraID", descripcion: "aseguradoraID", hide: true },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "flotilla", descripcion: "Flotilla" },
            { variable: "polizaInciso", descripcion: "Póliza Inciso" },
            { variable: "marca", descripcion: "Marca" },
            { variable: "tipoModelo", descripcion: "Modelo" },
            { variable: "anio", descripcion: "Año" },
            { variable: "numSerie", descripcion: "No. Serie" },
            { variable: "transmision", descripcion: "Transmisión" },
            { variable: "fechaSolCot", descripcion: "Fecha Sol. Cot." },
            { variable: "vigenciaIni", descripcion: "Vigencia Inicial" },
            { variable: "vigenciaHasta", descripcion: "Vigencia Hasta" },
            { variable: "coberAdicionales", descripcion: "Coberturas Adicionales" },
            { variable: "sumaAsegurada", descripcion: "Suma Asegurada" },
            { variable: "valorFactura", descripcion: "Valor Facturado" },
            { variable: "primaNeta", descripcion: "Prima Neta" },
            { variable: "primaTotal", descripcion: "Prima Total" },
            { variable: "renovacion", descripcion: "Renovar al Termino" }
        ],
        responsiveTitle: ["usario"],
        filter: [
            { campo: "usario" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [

            {
                columnas: ["fechaSolCot", "vigenciaIni", "vigenciaHasta"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["sumaAsegurada", "valorFactura", "primaNeta", "primaTotal"], tipo: "moneda",
            },
            {
                columnas: ["renovacion"], tipo: "estatus"

            }
        ],
        selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Empresa ? seleccionables.Empresa : [], grid: { md: 3, lg: 3 }
            }]
    }
    const listTransmisiones = [
        {
            label: 'Manual',
            value: 'Manual'
        },
        {
            label: 'Automatizada (Secuencial)',
            value: 'Automatizada (Secuencial)'
        },

        {
            label: 'Automatizada (Doble Embrague)',
            value: 'Automatizada (Doble Embrague)'
        },
        {
            label: 'Automática',
            value: 'Automática'
        },
        {
            label: 'CVT',
            value: 'CVT'
        }
    ]

    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "empresaID",
                    value: state.empresaID,
                    error: errorState.empresaID.error,
                    success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                    helperText: errorState.empresaID.helperText,
                    inputRef: empresaIDRef,
                    title: "Empresa *",
                    placeholder: "Seleccione una Empresa",
                    tipo: "select",
                    data: seleccionables.Empresa,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, empresaID: value })
                    },
                },
                {
                    disabled,
                    id: "aseguradoraID",
                    value: state.aseguradoraID,
                    error: errorState.aseguradoraID.error,
                    success: state.aseguradoraID && !errorState.aseguradoraID.error ? true : undefined,
                    helperText: errorState.aseguradoraID.helperText,
                    inputRef: aseguradoraIDRef,
                    title: "Aseguradora *",
                    placeholder: "Seleccione una Aseguradora",
                    tipo: "select",
                    data: seleccionables.Aseguradora,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, aseguradoraID: value })
                    }
                },
                {
                    disabled,
                    id: "usario",
                    value: state.usario,
                    error: errorState.usario.error,
                    success: state.usario && !errorState.usario.error ? true : undefined,
                    helperText: errorState.usario.helperText,
                    inputRef: usarioRef,
                    title: "Usuario *",
                    placeholder: "Capture el Usario",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "usario")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, usario: { error: state.usario.length > 100 && true, helperText: state.usario.length > 100 ? "Límite de 100 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, flotillaRef)
                },
                {
                    disabled,
                    id: "flotilla",
                    value: state.flotilla,
                    error: errorState.flotilla.error,
                    success: state.flotilla && !errorState.flotilla.error ? true : undefined,
                    helperText: errorState.flotilla.helperText,
                    inputRef: flotillaRef,
                    title: "Flotilla *",
                    placeholder: "Capture la Flotilla",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto10.test(value)) &&
                            handleChange(value.toUpperCase(), "flotilla")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, flotilla: { error: state.flotilla.length > 10 && true, helperText: state.flotilla.length > 10 ? "Límite de 10 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, partidaRef)
                },
                {
                    disabled,
                    id: "partida",
                    value: state.partida,
                    error: errorState.partida.error,
                    success: state.partida && !errorState.partida.error ? true : undefined,
                    helperText: errorState.partida.helperText,
                    textAlign: "right",
                    inputRef: partidaRef,
                    title: "No. de Partida *",
                    placeholder: "Capture la Partida",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt11.test(value)) &&
                            handleChange(value, "partida")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, partida: { error: state.partida.length > 11 && true, helperText: state.partida.length > 11 ? "Límite de 11 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, polizaIncisoRef)
                },
                {
                    disabled,
                    id: "polizaInciso",
                    value: state.polizaInciso,
                    error: errorState.polizaInciso.error,
                    success: state.polizaInciso && !errorState.polizaInciso.error ? true : undefined,
                    helperText: errorState.polizaInciso.helperText,
                    textAlign: "right",
                    inputRef: polizaIncisoRef,
                    title: "Poliza Inciso *",
                    placeholder: "Capture la Poliza Inciso",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto30.test(value)) &&
                            handleChange(value.toUpperCase(), "polizaInciso")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, polizaInciso: { error: state.polizaInciso.length > 30 && true, helperText: state.polizaInciso.length > 30 ? "Límite de 30 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, marcaRef)
                },
                {
                    disabled,
                    id: "horasInvertidas",
                    value: state.horasInvertidas,
                    error: errorState.horasInvertidas.error,
                    success: state.horasInvertidas && !errorState.horasInvertidas.error ? true : undefined,
                    helperText: errorState.horasInvertidas.helperText,
                    textAlign: "right",
                    inputRef: horasInvertidasRef,
                    title: "Horas Invertidas",
                    placeholder: "Capture las horas invertidas",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        !isNaN(Number(value)) &&
                        handleChange(value, "horasInvertidas")
                    },
                    onKeyDown: () => { return; }
                },
            ]
        },
        {
            id: "Propiedades",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "marca",
                    value: state.marca,
                    error: errorState.marca.error,
                    success: state.marca && !errorState.marca.error ? true : undefined,
                    helperText: errorState.marca.helperText,
                    inputRef: marcaRef,
                    title: "Marca *",
                    placeholder: "Capture la Marca",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto50.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "marca")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, marca: { error: state.marca.length > 50 && true, helperText: state.marca.length > 50 ? "Límite de 50 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, tipoModeloRef)
                },
                {
                    disabled,
                    id: "tipoModelo",
                    value: state.tipoModelo,
                    error: errorState.tipoModelo.error,
                    success: state.tipoModelo && !errorState.tipoModelo.error ? true : undefined,
                    helperText: errorState.tipoModelo.helperText,
                    inputRef: tipoModeloRef,
                    title: "Tipo de modelo *",
                    placeholder: "Capture el Tipo de Modelo",
                    grid: { md: 8, lg: 8 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto50.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "tipoModelo")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, tipoModelo: { error: state.tipoModelo.length > 50 && true, helperText: state.tipoModelo.length > 50 ? "Límite de 50 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, anioRef)
                },
                {
                    disabled,
                    id: "anio",
                    value: state.anio,
                    error: errorState.anio.error,
                    success: state.anio && !errorState.anio.error ? true : undefined,
                    helperText: errorState.anio.helperText,
                    textAlign: "right",
                    inputRef: anioRef,
                    title: "Año *",
                    placeholder: "Capture el Año",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        (rgxdeInt4.test(value)) &&
                            handleChange(value, "anio")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, anio: { error: state.anio.length > 5 && true, helperText: state.anio.length > 5 ? "Límite de 5 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, numSerieRef)
                },
                {
                    disabled,
                    id: "numSerie",
                    value: state.numSerie,
                    error: errorState.numSerie.error,
                    success: state.numSerie && !errorState.numSerie.error ? true : undefined,
                    helperText: errorState.numSerie.helperText,
                    textAlign: "right",
                    inputRef: numSerieRef,
                    title: "No. de Serie *",
                    placeholder: "Capture el NO. de Serie",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto20.test(value)) &&
                            handleChange(value.toUpperCase(), "numSerie")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, numSerie: { error: state.numSerie.length > 20 && true, helperText: state.numSerie.length > 20 ? "Límite de 20 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, transmisionRef)
                },
                {
                    disabled,
                    id: "transmision",
                    value: state.transmision,
                    error: errorState.transmision.error,
                    success: state.transmision && !errorState.transmision.error ? true : undefined,
                    helperText: errorState.transmision.helperText,
                    inputRef: transmisionRef,
                    title: "Transmisión *",
                    placeholder: "Capture la Transmisión",
                    grid: { md: 4, lg: 4 },
                    tipo: "select",
                    data: listTransmisiones,
                    onChange: ({ target: { value } }) => handleChange(value, "transmision"),
                    onKeyDown: (evt) => handleFocus(evt, fechaSolCotRef)
                }
            ]
        }, {
            id: "fechas",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaSolCot",
                    value: state.fechaSolCot,
                    error: errorState.fechaSolCot.error,
                    success: state.fechaSolCot && !errorState.fechaSolCot.error ? true : undefined,
                    helperText: errorState.fechaSolCot.helperText,
                    inputRef: fechaSolCotRef,
                    title: "Fecha Sol. Cotizacion.",
                    placeholder: "Fecha Solicitud de Cotización",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "fechaSolCot"),
                    onKeyDown: (evt) => handleFocus(evt, vigenciaIniRef)
                },
                {
                    disabled,
                    id: "vigenciaIni",
                    value: state.vigenciaIni,
                    error: errorState.vigenciaIni.error,
                    success: state.vigenciaIni && !errorState.vigenciaIni.error ? true : undefined,
                    helperText: errorState.vigenciaIni.helperText,
                    inputRef: vigenciaIniRef,
                    placeholder: "Fecha Vigencia de Inicio",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "vigenciaIni"),
                    onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                },
                {
                    disabled,
                    id: "vigenciaHasta",
                    value: state.vigenciaHasta,
                    error: errorState.vigenciaHasta.error,
                    success: state.vigenciaHasta && !errorState.vigenciaHasta.error ? true : undefined,
                    helperText: errorState.vigenciaHasta.helperText,
                    inputRef: vigenciaHastaRef,
                    title: "Vigencia hasta",
                    placeholder: "Fecha de Vencimiento",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "vigenciaHasta"),
                    onKeyDown: (evt) => handleFocus(evt, coberAdicionalesRef)
                }, {
                    disabled,
                    id: "renovacion",
                    value: state.renovacion,
                    checked: state.renovacion,
                    title: "Renovar al Termino de Vigencia",
                    tipo: "switch",
                    grid: { md: 3, lg: 3 },
                    onChange: (e) => {
                        setState({ ...state, renovacion: e.target.checked })
                    }
                }]
        }, {
            id: "Abajo izquierdo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 4 },
            inputs: [
                {
                    disabled,
                    id: "coberAdicionales",
                    value: state.coberAdicionales,
                    error: errorState.coberAdicionales.error,
                    success: state.coberAdicionales && !errorState.coberAdicionales.error ? true : undefined,
                    helperText: errorState.coberAdicionales.helperText,
                    inputRef: coberAdicionalesRef,
                    title: "Coberturas adicional *",
                    placeholder: "Capture la Cobertura Adicional",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto20.test(value)) &&
                            handleChange(value.charAt(0).toUpperCase() + value.slice(1), "coberAdicionales")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, coberAdicionales: { error: state.coberAdicionales.length > 20 && true, helperText: state.coberAdicionales.length > 20 ? "Límite de 20 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, sumaAseguradaRef)
                }]
        },
        {
            id: "Abajo derecho",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 8 },
            inputs: [

                {
                    disabled,
                    id: "sumaAsegurada",
                    value: state.sumaAsegurada,
                    error: errorState.sumaAsegurada.error,
                    success: state.sumaAsegurada && !errorState.sumaAsegurada.error ? true : undefined,
                    helperText: errorState.sumaAsegurada.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    inputRef: sumaAseguradaRef,
                    title: "Suma Asegurada *",
                    formatoImportes: true,
                    placeholder: "0.0",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "sumaAsegurada") },
                    onKeyDown: (evt) => handleFocus(evt, valorFacturaRef)
                },
                {
                    disabled,
                    id: "valorFactura",
                    value: state.valorFactura,
                    error: errorState.valorFactura.error,
                    success: state.valorFactura && !errorState.valorFactura.error ? true : undefined,
                    helperText: errorState.valorFactura.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    inputRef: valorFacturaRef,
                    title: "Valor de Factura *",
                    placeholder: "0.0",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal14.test(value)) &&
                        handleChange(value, "valorFactura")
                    },
                    onKeyDown: (evt) => handleFocus(evt, coberAdicionalesRef)
                },
                {
                    disabled,
                    id: "primaNeta",
                    value: state.primaNeta,
                    error: errorState.primaNeta.error,
                    success: state.primaNeta && !errorState.primaNeta.error ? true : undefined,
                    helperText: errorState.primaNeta.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    inputRef: primaNetaRef,
                    title: "Prima Neta *",
                    placeholder: "0.0",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "primaNeta") },
                    onKeyDown: (evt) => handleFocus(evt, primaTotalRef)
                },
                {
                    disabled,
                    id: "primaTotal",
                    value: state.primaTotal,
                    error: errorState.primaTotal.error,
                    success: state.primaTotal && !errorState.primaTotal.error ? true : undefined,
                    helperText: errorState.primaTotal.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    inputRef: primaTotalRef,
                    title: "Prima Total *",
                    placeholder: "0.0",
                    grid: { md: 3, lg: 3 },
                    onChange: ({ target: { value } }) => { (rgxDecimal14.test(value)) && handleChange(value, "primaTotal") },
                    onKeyDown: () => { return; }
                }]
        }
    ];

    let accionesFormulario = [{
        accionID: state.polizaSegAutosID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: usarioRef,
            mensajeCorrecto: `La poliza se ${state.polizaSegAutosID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ empresaID, aseguradoraID, partida, usario, flotilla, polizaInciso, marca, tipoModelo, anio, numSerie, transmision, valorFactura, fechaSolCot, vigenciaIni, vigenciaHasta, coberAdicionales, sumaAsegurada, primaNeta, primaTotal, horasInvertidas }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(empresaID),
                ref: empresaID, keyError: "empresaID",
                mensajeError: "Seleccione una empresa"
            },
            {
                condicion: !rgxNumeros.test(aseguradoraID),
                ref: aseguradoraIDRef, keyError: "aseguradoraID",
                mensajeError: "Seleccione una aseguradora"
            },
            {
                condicion: !usario || trim(usario) === "",
                ref: usarioRef, keyError: "usario",
                mensajeError: "Debe especificar el usario"
            },
            {
                condicion: !flotilla || trim(flotilla) === "",
                ref: flotillaRef, keyError: "flotilla",
                mensajeError: "Debe especificar la flotilla"
            },
            {
                condicion: !partida || partida === "",
                ref: partidaRef, keyError: "partida",
                mensajeError: "Debe especificar la partida"
            },
            {
                condicion: !polizaInciso || trim(polizaInciso) === "",
                ref: polizaIncisoRef, keyError: "polizaInciso",
                mensajeError: "Debe especificar la poliza inciso"
            },
            horasInvertidas !== "" && {
                condicion: !rgxdeInt2.test(horasInvertidas), ref: horasInvertidasRef, keyError: "horasInvertidas",
                mensajeError: "El formato de la hora es inválido"
            },
            {
                condicion: !marca || trim(marca) === "",
                ref: marcaRef, keyError: "marca",
                mensajeError: "Debe especificar la marca"
            },
            {
                condicion: !tipoModelo || trim(tipoModelo) === "",
                ref: tipoModeloRef, keyError: "tipoModelo",
                mensajeError: "Debe especificar el tipo modelo"
            },
            {
                condicion: !anio || anio === "",
                ref: anioRef, keyError: "anio",
                mensajeError: "Debe especificar el año"
            },
            {
                condicion: !numSerie || trim(numSerie) === "",
                ref: numSerieRef, keyError: "numSerie",
                mensajeError: "Debe especificar el numero de serie"
            },
            {
                condicion: !transmision || trim(transmision) === "",
                ref: transmisionRef, keyError: "transmision",
                mensajeError: "Debe especificar la transmision"
            },
            {
                condicion: !moment(fechaSolCot).isValid(),
                ref: fechaSolCotRef, keyError: "fechaSolCot",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(vigenciaHasta).isValid(),
                ref: vigenciaHastaRef, keyError: "vigenciaHasta",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: moment(vigenciaHasta) < moment(vigenciaIni),
                keyError: "vigenciaHasta", ref: vigenciaHastaRef,
                mensajeError: "La fecha vigencia hasta no puede ser menor a la inicial"
            },
            {
                condicion: !coberAdicionales || trim(coberAdicionales) === "",
                ref: coberAdicionalesRef, keyError: "coberAdicionales",
                mensajeError: "Debe especificar el cober adicional"
            },
            {
                condicion: !sumaAsegurada || sumaAsegurada === "",
                ref: sumaAseguradaRef, keyError: "sumaAsegurada",
                mensajeError: "Debe especificar la suma asegurada"
            },
            {
                condicion: !valorFactura || valorFactura === "",
                ref: valorFacturaRef, keyError: "valorFactura",
                mensajeError: "Debe especificar el valor de factura"
            },
            {
                condicion: !primaNeta || primaNeta === "",
                ref: primaNetaRef, keyError: "primaNeta",
                mensajeError: "Debe especificar la prima neta"
            },
            {
                condicion: !primaTotal || primaTotal === "",
                ref: primaTotalRef, keyError: "primaTotal",
                mensajeError: "Debe especificar la prima total"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function setRedireccionarFormulario({ polizaSegAutosID, polizaInciso }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${polizaSegAutosID}", "pTipo": "${polizaInciso}", "pTabla": "Pólizas de Seguro de Auto", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    function ftGuardar() {
        async function ftGuardar() {

            let {
                polizaSegAutosID,
                partida,
                empresaID,
                usario,
                aseguradoraID,
                flotilla,
                polizaInciso,
                marca,
                tipoModelo,
                anio,
                numSerie,
                transmision,
                fechaSolCot,
                vigenciaIni,
                vigenciaHasta,
                valorFactura,
                coberAdicionales,
                sumaAsegurada,
                primaNeta,
                primaTotal,
                renovacion,
                horasInvertidas
            } = state;

            let error = await handleValidaciones({
                partida,
                empresaID,
                usario,
                aseguradoraID,
                flotilla,
                polizaInciso,
                marca,
                tipoModelo,
                anio,
                numSerie,
                transmision,
                fechaSolCot,
                vigenciaIni,
                vigenciaHasta,
                valorFactura,
                coberAdicionales,
                sumaAsegurada,
                primaNeta,
                primaTotal,
                renovacion,
                horasInvertidas
            });

            if (error) return error;

            if (polizaSegAutosID) await updatePolizaSegAuto({
                input: {
                    polizaSegAutosID,
                    partida,
                    empresaID,
                    usario: trim(usario),
                    aseguradoraID,
                    flotilla: trim(flotilla),
                    polizaInciso: trim(polizaInciso),
                    marca: trim(marca),
                    tipoModelo: trim(tipoModelo),
                    anio: Number(anio),
                    numSerie: trim(numSerie),
                    transmision: trim(transmision),
                    fechaSolCot: fechaSolCot.format(formato),
                    vigenciaIni: vigenciaIni.format(formato),
                    vigenciaHasta: vigenciaHasta.format(formato),
                    valorFactura: parseFloat(valorFactura),
                    coberAdicionales: trim(coberAdicionales),
                    sumaAsegurada: parseFloat(sumaAsegurada),
                    primaNeta: parseFloat(primaNeta),
                    primaTotal: parseFloat(primaTotal),
                    renovacion,
                    horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0

                }
            }, token);
            else await addPolizaSegAuto({
                input: {
                    partida,
                    empresaID,
                    usario: trim(usario),
                    aseguradoraID,
                    flotilla: trim(flotilla),
                    polizaInciso: trim(polizaInciso),
                    marca: trim(marca),
                    tipoModelo: trim(tipoModelo),
                    anio: Number(anio),
                    numSerie: trim(numSerie),
                    transmision: trim(transmision),
                    fechaSolCot: fechaSolCot.format(formato),
                    vigenciaIni: now.format(formato),
                    vigenciaHasta: vigenciaHasta.format(formato),
                    valorFactura: parseFloat(valorFactura),
                    coberAdicionales: trim(coberAdicionales),
                    sumaAsegurada: parseFloat(sumaAsegurada),
                    primaNeta: parseFloat(primaNeta),
                    primaTotal: parseFloat(primaTotal),
                    renovacion,
                    horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0
                }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {
                let Empresa = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                let Aseguradora = await seleccionAseguradora({ filtro: {} }, token);

                setSeleccionables({ Empresa, Aseguradora });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);


    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaPolizaSegAuto}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={empresaIDRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default PolizaSegAuto;
