import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const TABLA_TRAZABILIDAD = `
        query filtro($filtro: InputFiltroTrazabilidadSiniestros){
            getTrazabilidadSiniestros(filtro: $filtro){
            listado{
                reporteDaniosID
                fechaElaboracion
                folioInternoReporteDanios
                siglas
                ramoSeguro
                descripBreve
                siniesReclTercerosID 
                fechaReclamacionTercero
                folioReclamacionTercero
                siniesReclAsegID
                fechaReclamacionAseg
                folioReclamacionAseg
                controlPagoID
                polizasSeguroID
                fechaTramitePago
                folioControlPago
                folioPolizaPrincipal
 
            }
            registros
            paginas
            }
        }`;

        export const TABLA_DOCUMENTOS = `
        query filtro($filtro: InputFiltroTrazabilidadSiniestros){
            getDocumentosAdjuntosTrazabilidadSiniestros(filtro: $filtro){
            listado{
                serverStorageID
                origenID,
                tabla,
                tipo,
                ruta,
                clasificacion,
                nombreArchivo
            }
            registros
            paginas
            }
        }`;

export const tablaTrazabilidadSiniestros = async (variables, token) => {
    try {
 
     
        let { getTrazabilidadSiniestros } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_TRAZABILIDAD
        });

        if(getTrazabilidadSiniestros.listado.length > 1){
            getTrazabilidadSiniestros.listado = [getTrazabilidadSiniestros.listado[0]]
        }
        return getTrazabilidadSiniestros;
    } catch ({ message }) { throw new Error(message); }
}

export const tablaDocumentosAdjuntos = async (variables, token) => {
    try {
        let { getDocumentosAdjuntosTrazabilidadSiniestros } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DOCUMENTOS
        });
        return getDocumentosAdjuntosTrazabilidadSiniestros;
    } catch ({ message }) { throw new Error(message); }
}