import React from 'react';

export default function Center ({ children }) {

    return <div style={{
        margin: "0, auto",
        textAlign: "center",
        boxSizing: "content-box",
        width: "100%"
    }}>
        {children}
    </div>;

}
