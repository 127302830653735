import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Search from '@material-ui/icons/Search';

// core components
import CustomInput from '../../../components/CustomInput/CustomInput.js';
import Button from '../../../components/CustomButtons/Button.js';

import styles from '../../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

	const [ searchState, setSearchState] = React.useState("");

	const [ listadoOpen, setListadoOpen ] = React.useState(null);
	const handleClickListado = (event, open, callback) => {
        if(open && (listadoOpen && listadoOpen.contains(event.target))) setListadoOpen(event.currentTarget);
        else setListadoOpen(event.currentTarget);
        if(callback) callback();
    };

	const classes = useStyles();
	const { rtlActive, headerSearch = {}, actionsHeader = [] } = props;
	const searchButton =
		classes.top +
		' ' +
		classes.searchButton +
		' ' +
		classNames({
			[classes.searchRTL]: rtlActive
		});
	const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
		[classes.dropdownItemRTL]: rtlActive
	});
	const wrapper = classNames({
		[classes.wrapperRTL]: rtlActive
	});
	const managerClasses = classNames({
		[classes.managerClasses]: true
    });

    let newActionsHeader = null;
    if(Array.isArray(actionsHeader) && actionsHeader.length > 0) {
        newActionsHeader = actionsHeader.map(({
            tipo, descripcion, onClick = (evt) => console.log(evt), notificaciones,
            open = false, openEvento = () => console.error("La propiedad openEvento no recibe nada para poder abrir las opciones"), listado, ...rest
        }, key) => {
            let props = {}
            if(notificaciones) props = { ...props, "aria-haspopup": "true" };
            switch(tipo){
                case "listado":
                    return <div className={managerClasses} key={key}>
                        <Tooltip title={descripcion} placement="bottom">
                            <Button
                                color="transparent"
                                aria-label={descripcion}
                                justIcon
                                aria-owns={open ? "profile-menu-list" : null}
                                onClick={(evt) => handleClickListado(evt, open, () => openEvento(evt))}
                                className={classes.buttonLink}
                                muiClasses={{ label: '' }}
                                {...props}
                            >
                                { rest.icono ? <rest.icono className={ classes.headerLinksSvg + ' ' + classes.links } /> : <Dashboard className={ classes.headerLinksSvg + ' ' + classes.links } /> }
                                { notificaciones ? <span className={classes.notifications}>{notificaciones}</span> : null }
                                <Hidden mdUp implementation="css">
                                    <span onClick={openEvento} className={classes.linkText}>{descripcion}</span>
                                </Hidden>
                            </Button>
                        </Tooltip>
                        {
                            (Array.isArray(listado) && listado.length > 0) && <Popper
                                open={Boolean(open)}
                                anchorEl={listadoOpen}
                                transition
                                disablePortal
                                placement="bottom"
                                className={classNames({
                                    [classes.popperClose]: !open,
                                    [classes.popperResponsive]: true,
                                    [classes.popperNav]: true
                                })}
                            >
                                {({ TransitionProps }) => (
                                    <Grow {...TransitionProps} id="profile-menu-list" style={{ transformOrigin: '0 0 0' }}>
                                        <Paper className={classes.dropdown}>
                                            <ClickAwayListener onClickAway={(evt) => (listadoOpen && !listadoOpen.contains(evt.target)) && openEvento()}>
                                                <MenuList role="menu">
                                                    {
                                                        listado.map(({descripcion, onClick = (evt) => console.log(evt), division}, index) => {
                                                            if(division) return (<Divider key={index} light />);
                                                            return (<MenuItem key={index} onClick={onClick} className={dropdownItem}>{descripcion}</MenuItem>);
                                                        })
                                                    }
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                        </Popper>
                        }
                    </div>
                default:
                    return <Tooltip title={descripcion} placement="bottom" key={key}>
                        <Button
                            color="transparent"
                            simple
                            aria-label={descripcion}
                            justIcon
                            className={classes.buttonLink}
                            onClick={onClick}
                            muiClasses={{ label: ''}}
                            {...props}
                        >
                            { rest.icono ? <rest.icono className={ classes.headerLinksSvg + ' ' + classes.links } /> : <Dashboard className={ classes.headerLinksSvg + ' ' + classes.links } /> }
                            { notificaciones ? <span className={classes.notifications}>{notificaciones}</span> : null }
                            <Hidden mdUp implementation="css">
                                <span className={classes.linkText}>{descripcion}</span>
                            </Hidden>
                        </Button>
                    </Tooltip>
            }
        })
    }

	return (
		<div className={wrapper}>
            {
                Object.keys(headerSearch).length > 0 && <>
                    <CustomInput
                        rtlActive={rtlActive}
                        value={searchState}
                        formControlProps={{
                            className: classes.top + ' ' + classes.search
                        }}
                        inputProps={{
                            placeholder: headerSearch.placeholder ? headerSearch.placeholder : "Buscar",
                            inputProps: {
                                'aria-label': headerSearch.placeholder ? headerSearch.placeholder : "Buscar",
                                className: classes.searchInput
                            }
                        }}
                        onChange={({target:{value}}) => setSearchState(value)}
                    />
                    <Button color="white" aria-label="edit" justIcon round className={searchButton}
                        onClick={(evt) => headerSearch.onClick ? headerSearch.onClick(searchState, evt) : console.log(evt)}>
                        <Search className={classes.headerLinksSvg + ' ' + classes.searchIcon} />
                    </Button>
                </>
            }
			{ newActionsHeader }
		</div>
	);
}

HeaderLinks.propTypes = {
	rtlActive: PropTypes.bool
};
