import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_ACTIVO = `
mutation add($input: InputAddActivo)
{
    addActivo(input : $input)
  {
    activoID
    usuarioBitID
    activo
  }
}
`;

export const UPDATE_ACTIVO =`
mutation update($input: InputUpdateActivo)
{
    updateActivo(input : $input)
  {
    activoID
    usuarioBitID
    activo
  }
}
`;

export const DELETE_ACTIVO = `
mutation delete ($input : InputDeleteActivo)
{
    deleteActivo(input: $input)
}
`;

export const addActivo = async (variables, token) => {
    try {
        let { addActivo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_ACTIVO
        });
        return addActivo;
    } catch({message}) { throw new Error(message); }
};

export const updateActivo = async (variables, token) => {
    try {
        let { updateActivo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_ACTIVO
        });
        return updateActivo;
    } catch({message}) { throw new Error(message); }
};

export const deleteActivo= async (input, token) => {
    try {
        let { deleteActivo } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_ACTIVO
        });
        return deleteActivo;
    } catch({message}) { throw new Error(message); }
};
