import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getAfianzadora, tablaAfianzadora } from '../../../querys/Afianzadoras';
import { deleteAfianzadora, addAfianzadora, updateAfianzadora } from '../../../mutations/Afianzadoras';
import * as Permisos from '../Permisos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

const { trim } = Validaciones;

function Afianzadoras() {
    const { token } = useSelector(state => state.login);
    const cleanState = {
        afianzadoraID: null,
        afianzadora: ""
    };
    const cleanErrorState = {
        afianzadora: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    let titleAccion = state.afianzadoraID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Afianzadora`;

    const afianzadoraRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "afianzadoraID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getAfianzadora
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "afianzadoraID" },
        { campo: "afianzadora" }],
        disabled: { multiseleccion: true },
        onClick: ({ afianzadoraID, afianzadora }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la afianzadora ${afianzadora}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { afianzadoraID }, asyncDelete: deleteAfianzadora
            })
        })
    }];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "afianzadoraID",
        color: primary,
        title: "Afianzadoras",
        iconTable: <SupervisedUserCircleIcon />,
        headers: [
            { variable: "afianzadoraID", descripcion: "afianzadoraID", hide: true },
            { variable: "afianzadora", descripcion: "Afianzadora" }
        ],
        responsiveTitle: ["afianzadora"],
        filter: [
            { campo: "afianzadora" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "afianzadora",
                    value: state.afianzadora,
                    error: errorState.afianzadora.error,
                    success: state.afianzadora && !errorState.afianzadora.error ? true : undefined,
                    helperText: errorState.afianzadora.helperText,
                    inputRef: afianzadoraRef,
                    title: "Afianzadora *",
                    placeholder: "Capture el nombre de la afianzadora",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "afianzadora")
                    },
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];

    let accionesFormulario = [{
        accionID: state.afianzadoraID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: afianzadoraRef,
            mensajeCorrecto: `La afianzadora se ${state.afianzadoraID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ afianzadora }) {

        let error;
        let validaciones = [
            {
                condicion: !afianzadora || trim(afianzadora) === "",
                ref: afianzadoraRef, keyError: "afianzadora",
                mensajeError: "Debe especificar el nombre de la afianzadora"
            },
            {
                condicion: afianzadora.length > 100,
                ref: afianzadoraRef, keyError: "area",
                mensajeError: "Número de caracteres capturados excedido."
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { afianzadoraID,
                afianzadora } = state;

            let error = await handleValidaciones({
                afianzadora
            });

            if (error) return error;

            if (afianzadoraID) await updateAfianzadora({
                input: { afianzadoraID, afianzadora: trim(afianzadora) }
            }, token);
            else await addAfianzadora({
                input: { afianzadora: trim(afianzadora) }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaAfianzadora}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={afianzadoraRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default Afianzadoras;
