import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';
import {  getArchivo, deleteFileStorage, blobToBase64 } from "../../helpers/serverStorage";

export const GET_REPORTEACCIDENTELABORAL = `
        query get($filtro: InputGetReporteAccidenteLaboral){
            getReporteAccidenteLaboral(filtro:$filtro){

                reporteAccidenteLaboralID
                usuarioBitID
                empresaID
                folioInterno
                fechaElaboracion
                numIncidencia
                numNC
                descripBreve
                lugarEvento
                fechaEvento
                horaEvento
                supervCargo
                area
                empresaPersonalAfectadaID
                puestoHabilidadBase
                puestoHabilidadRealizaba
                nombreTrabajador
                numControlTrabajador
                actividadDesarrollaba
                jornadaDelSuceso
                diaUltimoDescanzo
                portabaEPP
                motivoAusenciaEPP
                iluminacionDeficiente
                condMeteorologicaAdvs
                faltaOrdenLimpieza
                espaciosReducidos
                instalacionMalEstado
                fallasEnProceso
                cortesPorHerramienta
                golpesPorObjetosEnCaida
                caidasAlMismoDifNivel
                concactoSustanciasQuimicas
                atrapamientoExtremidades
                lesionesErgonomicas
                colisionChoque
                quemaduras
                cabeza
                cara
                cuello
                espalda
                pecho
                brazos
                manos
                piernas
                pies
                descripDetallada
                fotografias
                existenTestigos
                nombresPersonalTestigo1
                numControlPersonalTestigo1
                puestoHabilidadTestigo1
                nombresPersonalTestigo2
                numControlPersonalTestigo2
                puestoHabilidadTestigo2
                observGenerales
                responAnalisis
                elaboro
                firma
                cargo
                tipoDanioOcasio
                folio
                danioOcasioPor
                danioOcasioARep
                equipoBienPersona
                areaRespRepa
                costoDanio
                estatus
                tiempoInvertido
                atendio
                notas
                finalizado
                seguimiento
                siglasEmpresa
                nombreEmpresa
                siglasEmpresaAfectada
                empresaAfectada
                fechaModificacion
            ejercicio
            mes
            fechaInicio
            fechaFinReporte

            }
        }`;

    export const GET_ALL_REPORTEACCIDENTELABORAL = `
        query filtroGetAll($filtro: InputGetReporteAccidenteLaboral){
            getAllReporteAccidenteLaboral(filtro:$filtro){

                reporteAccidenteLaboralID
                usuarioBitID
                empresaID
                folioInterno
                fechaElaboracion
                numIncidencia
                numNC
                descripBreve
                lugarEvento
                fechaEvento
                horaEvento
                supervCargo
                area
                empresaPersonalAfectadaID
                puestoHabilidadBase
                puestoHabilidadRealizaba
                nombreTrabajador
                numControlTrabajador
                actividadDesarrollaba
                jornadaDelSuceso
                diaUltimoDescanzo
                portabaEPP
                motivoAusenciaEPP
                iluminacionDeficiente
                condMeteorologicaAdvs
                faltaOrdenLimpieza
                espaciosReducidos
                instalacionMalEstado
                fallasEnProceso
                cortesPorHerramienta
                golpesPorObjetosEnCaida
                caidasAlMismoDifNivel
                concactoSustanciasQuimicas
                atrapamientoExtremidades
                lesionesErgonomicas
                colisionChoque
                quemaduras
                cabeza
                cara
                cuello
                espalda
                pecho
                brazos
                manos
                piernas
                pies
                descripDetallada
                fotografias
                existenTestigos
                nombresPersonalTestigo1
                numControlPersonalTestigo1
                puestoHabilidadTestigo1
                nombresPersonalTestigo2
                numControlPersonalTestigo2
                puestoHabilidadTestigo2
                observGenerales
                responAnalisis
                elaboro
                firma
                cargo
                tipoDanioOcasio
                folio
                danioOcasioPor
                danioOcasioARep
                equipoBienPersona
                areaRespRepa
                costoDanio
                estatus
                tiempoInvertido
                atendio
                notas
                finalizado
                seguimiento
                siglasEmpresa
                nombreEmpresa
                siglasEmpresaAfectada
                empresaAfectada
                fechaModificacion
            ejercicio
            mes
            fechaInicio
            fechaFinReporte
            }
        }`;

    export const TABLA_REPORTEACCIDENTELABORAL = `
        query filtro($filtro: InputFiltroReporteAccidenteLaboral){
            filterReporteAccidenteLaboral(filtro:$filtro){
            listado{

                reporteAccidenteLaboralID
                usuarioBitID
                empresaID
                folioInterno
                fechaElaboracion
                numIncidencia
                numNC
                descripBreve
                lugarEvento
                fechaEvento
                horaEvento
                supervCargo
                area
                empresaPersonalAfectadaID
                puestoHabilidadBase
                puestoHabilidadRealizaba
                nombreTrabajador
                numControlTrabajador
                actividadDesarrollaba
                jornadaDelSuceso
                diaUltimoDescanzo
                portabaEPP
                motivoAusenciaEPP
                iluminacionDeficiente
                condMeteorologicaAdvs
                faltaOrdenLimpieza
                espaciosReducidos
                instalacionMalEstado
                fallasEnProceso
                cortesPorHerramienta
                golpesPorObjetosEnCaida
                caidasAlMismoDifNivel
                concactoSustanciasQuimicas
                atrapamientoExtremidades
                lesionesErgonomicas
                colisionChoque
                quemaduras
                cabeza
                cara
                cuello
                espalda
                pecho
                brazos
                manos
                piernas
                pies
                descripDetallada
                fotografias
                existenTestigos
                nombresPersonalTestigo1
                numControlPersonalTestigo1
                puestoHabilidadTestigo1
                nombresPersonalTestigo2
                numControlPersonalTestigo2
                puestoHabilidadTestigo2
                observGenerales
                responAnalisis
                elaboro
                firma
                cargo
                tipoDanioOcasio
                folio
                danioOcasioPor
                danioOcasioARep
                equipoBienPersona
                areaRespRepa
                costoDanio
                estatus
                tiempoInvertido
                atendio
                notas
                finalizado
                seguimiento
                siglasEmpresa
                nombreEmpresa
                siglasEmpresaAfectada
                empresaAfectada
                fechaModificacion

            }
            registros
            paginas
            }
        }`;

export const getReporteAccidenteLaboral = async (filtro, token) => {
    try {
        let { getReporteAccidenteLaboral } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_REPORTEACCIDENTELABORAL
        });

        let foto1 = getReporteAccidenteLaboral.fotografias ? await getArchivo('/private/segumex' + getReporteAccidenteLaboral.fotografias, token) : "";
        getReporteAccidenteLaboral.fotografia1 = foto1;

        for (const [key, value] of Object.entries(getReporteAccidenteLaboral)) {
            if(value === null){
                getReporteAccidenteLaboral[key] = "";
            }
        }

        return getReporteAccidenteLaboral;

    } catch({message}) { throw new Error(message); }
}

export const getAllReporteAccidenteLaboral = async (variables, token) => {
    try {

        let { getAllReporteAccidenteLaboral } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_REPORTEACCIDENTELABORAL
        });

        return getAllReporteAccidenteLaboral;

    } catch({message}) { throw new Error(message); }
}

export const seleccionReporteAccidenteLaboral = async (variables, token) => {
    try {

        let allReporteAccidenteLaboral = await getAllReporteAccidenteLaboral
        (variables, token);

        if(!Array.isArray(allReporteAccidenteLaboral)) throw new Error("No se encontraron registros");

        return allReporteAccidenteLaboral.map(({ reporteAccidenteLaboralID:value, folioInterno:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaReporteAccidenteLaboral = async (variables, token) => {
    try{

        let {filterReporteAccidenteLaboral} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_REPORTEACCIDENTELABORAL
        });
        return filterReporteAccidenteLaboral;
    }catch({message}) { throw new Error(message); }
}

