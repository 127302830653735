import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_POLIZAS_SEGURO_DETALLE = `
        query get($filtro: InputGetPolizasSeguroDetalle){
            getPolizasSeguroDetalle(filtro:$filtro){
                polSegDetalleID 
                polizaSeguroID
                deducible
                cobertura
                exclusiones
                bienesAmparados
                tipo
                maximoMinimo
                fechaModificacion
                polRenovada
                partComplementaria
                fechaComplementaria
            }
        }`;

    export const GET_ALL_POLIZAS_SEGURO_DETALLE = `
        query filtroGetAll($filtro: InputGetPolizasSeguroDetalle){
            getAllPolizasSeguroDetalle(filtro:$filtro){
                polSegDetalleID
                polizaSeguroID
                deducible
                cobertura
                exclusiones
                bienesAmparados
                tipo
                maximoMinimo
                fechaModificacion
                polRenovada
                partComplementaria
                fechaComplementaria
            }
        }`;

    export const TABLA_POLIZAS_SEGURO_DETALLE = `
        query filtro($filtro: InputFiltroPolizasSeguroDetalle){
            filterPolizasSeguroDetalle(filtro:$filtro){
            listado{
                polSegDetalleID
                polizaSeguroID
                deducible
                cobertura
                exclusiones
                bienesAmparados
                tipo
                maximoMinimo 
                fechaModificacion
                polRenovada
                partComplementaria
                fechaComplementaria
            }
            registros
            paginas
            }
        }`;

export const getPolizasSeguroDetalle = async (filtro, token) => {
    try {
        let { getPolizasSeguroDetalle } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_POLIZAS_SEGURO_DETALLE
        });

        return getPolizasSeguroDetalle;

    } catch({message}) { throw new Error(message); }
}

export const getAllPolizasSeguroDetalle = async (variables, token) => {
    try {

        let { getAllPolizasSeguroDetalle } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_POLIZAS_SEGURO_DETALLE
        });

        return getAllPolizasSeguroDetalle;

    } catch({message}) { throw new Error(message); }
}

export const seleccionPolizasSeguro = async (variables, token) => {
    try {

        let allPolizasSeguroDetalle = await getAllPolizasSeguroDetalle
        (variables, token);

        if(!Array.isArray(allPolizasSeguroDetalle)) throw new Error("No se encontraron registros");

        return allPolizasSeguroDetalle.map(({ polSegDetalleID:value, deducible:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaPolizasSeguroDetalle = async (variable, token) => {
    let {polSegDetalleID,polizaSeguroID,deducible,cobertura,exclusiones,bienesAmparados,tipo, pagina, limite} = variable.filtro;
        let filtro = {/*polSegDetalleID: polSegDetalleID === null ? -1 : Number(polSegDetalleID),*/polizaSeguroID: polizaSeguroID === -1 ? null : Number(polizaSeguroID), deducible,cobertura,exclusiones,bienesAmparados,tipo, pagina: pagina===null ? 1: pagina, limite: limite===null ? 5:limite};
        let variables={filtro}; 
       
    try{
        let {filterPolizasSeguroDetalle} = await FetchAPI.graphql({
            token,
            variables:variables,
            url: urlServidor,
            query: TABLA_POLIZAS_SEGURO_DETALLE
        });
 
        return filterPolizasSeguroDetalle;
    }catch({message}) { throw new Error(message); }
}
