import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_DEPARTAMENTO = `
mutation insert($input : InputAddDepartamento)
{
  addDepartamento(input : $input)
  {
    departamentoID
	areaID
    departamento
    usuarioBitID
  }
}
`;

export const UPDATE_DEPARTAMENTO =`
mutation update($input : InputUpdateDepartamento)
{
  updateDepartamento(input : $input)
  {
    departamentoID
	areaID
    departamento
    usuarioBitID
  }
}
`;

export const DELETE_DEPARTAMENTO = `
mutation delete($input : InputDeleteDepartamento)
{
  deleteDepartamento(input : $input)
}
`;

export const addDepartamento = async (variables, token) => {
    try {
        let { addDepartamento } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_DEPARTAMENTO
        });
        return addDepartamento;
    } catch({message}) { throw new Error(message); }
};

export const updateDepartamento = async (variables, token) => {
    try {
        let { updateDepartamento } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_DEPARTAMENTO
        });
        return updateDepartamento;
    } catch({message}) { throw new Error(message); }
};

export const deleteDepartamento= async (input, token) => {
    try {
        let { deleteDepartamento } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_DEPARTAMENTO
        });
        return deleteDepartamento;
    } catch({message}) { throw new Error(message); }
};
