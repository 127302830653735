import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';



export const ADD_SOLSEGFIANEQUIPOELEC = `
mutation addSolSegFianEquipoElec($input: InputAddSolSegFianEquipoElec!){
    addSolSegFianEquipoElec(input: $input){
              equipoElecID
              solSegFianID
              usuarioBitID
              activoAsegID
              infoActAsegID
              equipo
              noEco
              nocpu
              serieCPU
              noMonitor
              serieMonitor
              serie
              anio
              valorAseg
              empresaID
              ubicacionID
              sustituye
              motivo
              responsable
              sustituyeID
              activoSustituye
              divisaID
              divisa
              paridad
              departamento
              activo
              modelo
              montoAsegurado
              marca
    }
}


`;



export const UPDATE_SOLSEGFIANEQUIPOELEC = `
mutation updateSolSegFianEquipoElec($input: InputUpdateSolSegFianEquipoElec!){
    updateSolSegFianEquipoElec(input: $input){
              equipoElecID
              solSegFianID
              usuarioBitID
              activoAsegID
              infoActAsegID
              equipo
              noEco
              nocpu
              serieCPU
              noMonitor
              serieMonitor
              serie
              anio
              valorAseg
              empresaID
              ubicacionID
              sustituye
              motivo
              responsable
              sustituyeID
              activoSustituye
              divisaID
              divisa
              paridad
              departamento
              activo
              modelo
              montoAsegurado
              marca
    }
}
`;




export const DELETE_SOLSEGFIANEQUIPOELEC = `
mutation deleteSolSegFianEquipoElec($input: InputDeleteSolSegFianEquipoElec){
deleteSolSegFianEquipoElec(input: $input)
}
`;


export const addSolSegFianEquipoElec = async (variables, token) =>
{
    try{
        let {addSolSegFianEquipoElec} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SOLSEGFIANEQUIPOELEC
        });
        return addSolSegFianEquipoElec;

    }catch({message}) {throw new Error(message);}
};


export const updateSolSegFianEquipoElec = async (variables, token) =>{
    try{
        let {updateSolSegFianEquipoElec} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SOLSEGFIANEQUIPOELEC
        });
        return updateSolSegFianEquipoElec;
    }catch ({message}) {throw new Error(message);}
};

export const deleteSolSegFianEquipoElec = async (input, token) => {
  try{
    let {deleteSolSegFianEquipoElec} = await FetchAPI.graphql({
        token,
        variables: {input},
        url: urlServidor,
        query: DELETE_SOLSEGFIANEQUIPOELEC
    });
    return deleteSolSegFianEquipoElec;
    }catch ({message}) {throw new Error(message);}
};
