import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function Label({title}) {

    const classes = useStyles();

    return (<FormLabel className={classes.labelHorizontal}>
        { title }
    </FormLabel>)
}
