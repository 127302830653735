import React, { useState, useEffect, useRef } from 'react'
import './styles.css';
import './dropdown.css';
import Inputs from '_layout/genericComponents/Inputs';
import { rgxDecimal14, rgxPromedio } from 'helpers/regexp';
import { IconoSimbolo } from 'helpers/rutinas/metodos/rutinasProductos';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

import ActivosSubpolizas from './ActivosSubpolizas';
import EndososSubpolizas from './EndososSubpolizas';

import Documentacion from '../../../componentes/General/Documentacion';
import Modal from '_layout/genericComponents/Modal';

export default function MultiPartidas(props) {

    let { datosGet, setDataToSave, ramoSeguroSelect, datosEncabezado,trazabilidad = 0 } = props;

    const cleanPart = {
        subPolizaID: null,
        ramoSeguroID: '',
        ramoSeguro: '',
        sumaAseg: '',
        primaNeta: '',
        fpPromedio: '',
        bienesAmparadosEncabezado: '',
        beneficiarioPreferente: ''
    }

    const cleanErrorPart = {
        subPolizaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        ramoSeguro: { error: false, helperText: "" },
        sumaAseg: { error: false, helperText: "" },
        primaNeta: { error: false, helperText: "" },
        fpPromedio: { error: false, helperText: "" },
        bienesAmparadosEncabezado: { error: false, helperText: "" },
        beneficiarioPreferente: { error: false, helperText: "" },
    }

    const [statePartidas, setStatePartidas] = useState([cleanPart])
    const [errorStatePartidas, setErrorStatePartidas] = useState([cleanErrorPart]);

    const [alert, setAlert] = useState(null);
    const [actualizar, setActualizar] = useState(false);

    const [openVerActivos, setOpenVerActivos] = useState(false);
    const [folioSubpoliza, setFolioSubpoliza] = useState('');
    const [subPolizaID, setSubPolizaID] = useState(null);
    const [infoActivos, setInfoActivos] = useState({
        datosEncabezado,
        datosSubpoliza: {}
    })

    const [openVerEndosos, setOpenVerEndosos] = useState(false);
    const [infoEndosos, setInfoEndosos] = useState({
        datosEncabezado,
        datosSubpoliza: {}
    })

    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})


    useEffect(() => {
        async function ftEffect() {

            if(datosGet){
                let auxDatos = [];
                for(let i = 0; i < datosGet.length; i++){

                    let auxSumaAseg = datosGet[i].sumaAseg;
                    // if(datosGet[i].ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" || datosGet[i].ramoSeguro.toUpperCase() === "GASTOS MÉDICOS MAYORES" || datosGet[i].ramoSeguro.toUpperCase() === "FLOTILLA DE AUTOMÓVILES (CAMIONES CARGA GENERAL)"){
                    //     auxSumaAseg = 1
                    // } 

                    auxDatos.push({...datosGet[i], sumaAseg: auxSumaAseg})
                }
                setStatePartidas(auxDatos)
            }else{
             setStatePartidas([cleanPart])
            }

        }
        ftEffect();

    }, [datosGet]);

    useEffect(() => {
        async function dataUpdate() {
            setDataToSave(statePartidas)
        }
        dataUpdate();
    }, [statePartidas]);

    const agregarPartida = () => {
        setStatePartidas([...statePartidas, cleanPart])
    }

    function eliminarCondicion(index,subPolizaID) {
        setAlert({
            descripcion: `¿Desear eliminar el registro?`,
            title: "¡Eliminar Registro!",
            tipo: "warning",
            msjConfirmacion: "Aceptar",
            msjCancelacion: "Cancelar",
            onConfirm: async () => {
                if (subPolizaID > 0){
                    
                }
                const list = [...statePartidas];
                list.splice(index, 1);
                setStatePartidas(list);
                setAlert(null);
            },
            onCancel: () => setAlert(null)
        });     
    }

    const handleChangePartidas = (e, index, campo, sumaAseg = 0, primaNeta) => {
        
        const list = [...statePartidas];
        if (campo == 'ramoSeguroID') {
            const { value, label } = e;
            list[index]['ramoSeguroID'] = parseInt(value);
            list[index]['ramoSeguro'] = label;

            // if(label.toUpperCase() === "AUTOMÓVILES RESIDENTES" || label.toUpperCase() === "GASTOS MÉDICOS MAYORES" || label.toUpperCase() === "FLOTILLA DE AUTOMÓVILES (CAMIONES CARGA GENERAL)"){
            //     list[index]['sumaAseg'] = 1
            // } 
            setStatePartidas([...list]);
        }
        else if (campo =='sumaAseg'){
            list[index]['sumaAseg'] = e.target.value;   
                let promedio = primaNeta/sumaAseg;
                let decimales = 8;
                let signo = (promedio >= 0 ? 1 : -1);
                promedio = promedio * signo;
                promedio = promedio.toString().split('e');
                promedio = Math.round(+(promedio[0] + 'e' + (promedio[1] ? (+promedio[1] + decimales) : decimales)));
                promedio = promedio.toString().split('e');
                promedio =  signo * (promedio[0] + 'e' + (promedio[1] ? (+promedio[1] - decimales) : -decimales));
                if(promedio === Infinity){
                    list[index]['fpPromedio'] = 0;
                } else if(isNaN(promedio) === true){
                    list[index]['fpPromedio'] = 0;
                } else {
                    list[index]['fpPromedio'] = promedio;
                } 
            setStatePartidas([...list]);
        }
        else if (campo =='primaNeta'){
            if(sumaAseg !== 0){
                if(parseFloat(e.target.value) === 0){
                    list[index]['primaNeta'] = e.target.value;
                }else{
                    let promedio = e.target.value/sumaAseg;
                    let decimales = 8;

                    let signo = (promedio >= 0 ? 1 : -1);
                    promedio = promedio * signo;

                    promedio = promedio.toString().split('e');
                    promedio = Math.round(+(promedio[0] + 'e' + (promedio[1] ? (+promedio[1] + decimales) : decimales)));
                    promedio = promedio.toString().split('e');
                    promedio =  signo * (promedio[0] + 'e' + (promedio[1] ? (+promedio[1] - decimales) : -decimales));

                    
                    if(promedio === Infinity){
                        list[index]['fpPromedio'] = 0;
                    } else if(isNaN(promedio) === true){
                        list[index]['fpPromedio'] = 0;
                    } else {
                        list[index]['fpPromedio'] = promedio;
                    } 
                    list[index]['primaNeta'] = e.target.value;
                }
            }else{
                list[index]['primaNeta'] = e.target.value;
            }
            setStatePartidas([...list]);
        }
        else if (campo =='fpPromedio'){
            list[index]['fpPromedio'] = e.target.value;
            setStatePartidas([...list]);
        }
        else if (campo =='bienesAmparadosEncabezado'){
            list[index]['bienesAmparadosEncabezado'] = e.target.value;
            setStatePartidas([...list]);
        }
        else if (campo =='beneficiarioPreferente'){
            list[index]['beneficiarioPreferente'] = e.target.value;
            setStatePartidas([...list]);
        }

    }


    return (<>
        <div className='contenedoresPartidas'>
            {
                statePartidas.map((partida, index) => {

                    return(<>
                    <div key={index} className="contenedorMultiPartidas">
                        {
                            partida.folioSubpolizaExtendida ? 
                        <div className="tituloPartidas">
                            {partida.folioSubpolizaExtendida}
                        </div>
                        : 
                        <div className="tituloPartidas">
                            Folio de Cobertura #{index + 1}
                        </div>
                        }
                        <div className="agrupadorInputsBotones">
                            <div className="contenedorInputsPartida">
                                <div className="fila">
                                    <div className="campo">
                                        <Inputs
                                        disabled= {false}
                                        id={'ramoSeguroID'}
                                        value=  {partida.ramoSeguroID}
                                        error=  {false}
                                        success=  {false}
                                        helperText=  {''}
                                        title=  {"Cobertura *"}
                                        placeholder=  {"Seleccione una Cobertura "}
                                        tipo=  {"autocomplete"}
                                        data=  {ramoSeguroSelect}
                                        grid=  {{ md: 12, lg: 12 }}
                                        onChange=  {(e) => { handleChangePartidas(e, index,"ramoSeguroID")}}
                                        />
                                    </div>
                                    <div className="campo">
                                        <Inputs
                                        disabled = {
                                           /*  partida.ramoSeguro.toUpperCase().toUpperCase() === "AUTOMÓVILES RESIDENTES" || partida.ramoSeguro.toUpperCase().toUpperCase() === "GASTOS MÉDICOS MAYORES" || partida.ramoSeguro.toUpperCase().toUpperCase() === "FLOTILLA DE AUTOMÓVILES (CAMIONES CARGA GENERAL)" ? true :  */false
                                        }
                                        id= {"sumaAseg"}
                                        value= {partida.sumaAseg}
                                        error=  {false}
                                        success=  {false}
                                        helperText=  {''}
                                        textAlign= {"right"}
                                        icono= {<IconoSimbolo simbolo="$" />}
                                        formatoImportes= {true}
                                        posicionIcono= {"start"}
                                        title= {"Suma Asegurada"}
                                        placeholder= {"0.0"}
                                        grid= {{ md: 12, lg: 12 }}
                                        onChange= {(e) => {
                                            (rgxDecimal14.test(e.target.value)) && handleChangePartidas(e,index, "sumaAseg", e.target.value , partida.primaNeta)}}
                                        />
                                    </div>
                                </div>

                                <div className="fila">
                                <div className="campo">
                                    <Inputs
                                    disabled = {false}
                                    id = { "primaNeta"}
                                    value = { partida.primaNeta}
                                    error=  {false}
                                    success=  {false}
                                    helperText=  {''}
                                    textAlign = {"right"}
                                    icono = {<IconoSimbolo simbolo="$" />}
                                    formatoImportes = {true}
                                    posicionIcono = {"start"}
                                    title = {"Prima Neta"}
                                    placeholder = {"0.0"}
                                    grid = {{ md: 12, lg: 12 }}
                                    onChange = {(e) => {(rgxDecimal14.test(e.target.value)) && handleChangePartidas(e, index, "primaNeta", partida.sumaAseg)}}
                                    />
                                </div>

                                <div className="campo">

                                    <Inputs
                                    disabled = {false}
                                    id = "fpPromedio"
                                    value = {partida.fpPromedio}
                                    error=  {false}
                                    success=  {false}
                                    helperText=  {''}
                                    textAlign = {"right"}
                                    title = {"Factor Promedio"}
                                    placeholder = {"0.0"}
                                    grid = {{ md: 12, lg: 12 }}
                                    onChange = {(e) => {(rgxPromedio.test(e.target.value)) && handleChangePartidas(e, index, "fpPromedio")}}
                                    />
                                </div>

                                </div>

                                <div className="campo">
                                    <Inputs
                                    disabled = {false}
                                    id ={ "bienesAmparadosEncabezado"}
                                    value ={ partida.bienesAmparadosEncabezado}
                                    error=  {false}
                                    success=  {false}
                                    helperText=  {''}
                                    multiline ={ true}
                                    title ={ "Bienes Amparados"}
                                    placeholder ={ "Capture los bienes amparados"}
                                    grid ={ { md: 12, lg: 12 }}
                                    onChange = {(e) => { handleChangePartidas(e, index,"bienesAmparadosEncabezado")}}
                                    />
                                </div>

                                <div className="campo">
                                    <Inputs
                                    disabled = {false}
                                    id ={ "beneficiarioPreferente"}
                                    value ={ partida.beneficiarioPreferente}
                                    error=  {false}
                                    success=  {false}
                                    helperText=  {''}
                                    multiline ={true}
                                    title ={ "Beneficiario Preferente"}
                                    placeholder ={ "Capture el Beneficiario Preferente"}
                                    grid ={ { md: 12, lg: 12 }}
                                    onChange = {(e) => { handleChangePartidas(e, index,"beneficiarioPreferente")}}
                                    />
                                </div>

                                {
                                    partida.subPolizaID && trazabilidad == 0?
                                    <div className='cContenedorAccionesPartidas'>
                                        Acciones

                                        <div className='cContenedorAccionesPartidasHidden'>
                                        <button className='buttonAdjuntar'
                                            onClick={() => {
                                                setDatosDetalleAdjuntos({
                                                    pURLDoctoOrigenID: partida.subPolizaID,
                                                    pTipo: `SubPoliza - ${partida.subPolizaID}`,
                                                    pClasificacion: "",
                                                    pTabla: "SubPoliza de Seguro",
                                                    pTitulo2: 'Anexos: Recuerde adjuntar documento fuente.',
                                                    plUtilizaProps: true
                                                });
                                                setOpenDocumentacion(true);
                                            }}>
                                            Archivos
                                        </button>

                                        {/* <button className='buttonAdjuntar'
                                            onClick={() => {console.log('eliminar ')}}>
                                            Eliminar subpoliza
                                        </button> */}

                                        <button className='buttonAdjuntar'
                                            onClick={() => {
                                                setFolioSubpoliza(partida.folioSubpolizaExtendida);
                                                setSubPolizaID(partida.subPolizaID);
                                                setInfoActivos({...infoActivos, datosSubpoliza: partida});
                                                setOpenVerActivos(true);
                                            }}>
                                            Activos
                                        </button>

                                        <button className='buttonAdjuntar'
                                            onClick={() => {
                                                setSubPolizaID(partida.subPolizaID);
                                                setInfoEndosos({...infoEndosos, datosSubpoliza: partida})
                                                setFolioSubpoliza(partida.folioSubpolizaExtendida);
                                                setOpenVerEndosos(true);
                                            }}>
                                            Endosos
                                        </button>
                                        </div>
                                    </div>
                                        :
                                        <>
                                        </>
                                    
                                }
                                
                            </div>
                            <div className="botonesPartidas">
                                {
                                    (statePartidas.length - 1 === index) && (<button className='buttonAdd'
                                        title='Agregar partida'
                                        onClick={() => { agregarPartida() }}>
                                        +
                                    </button>)
                                }
                                {
                                    (statePartidas.length > 1) && !partida.subPolizaID &&  (
                                        <button className='buttonRemove'
                                            title="Remover partida"
                                            onClick={() => { eliminarCondicion(index, partida.subPolizaID) }}>
                                            -
                                        </button>
                                    )
                                }

                                
                            </div>
                        </div>
                    </div>
                    </>)
                    
                
                
                
                }
                )
            }

        </div>

        {
            openVerActivos ?
            <ActivosSubpolizas
            folioSubpoliza={folioSubpoliza}
            openVerActivos = {openVerActivos}
            setOpenVerActivos = {setOpenVerActivos}
            subPolizaID = {subPolizaID}
            setSubPolizaID = {setSubPolizaID}
            infoActivos = {infoActivos}
            setInfoActivos = {setInfoActivos}
            />
            :
            <></>
        }

        {
            openVerEndosos ?
            <EndososSubpolizas
            openVerEndosos = {openVerEndosos}
            setOpenVerEndosos = {setOpenVerEndosos}
            subPolizaID = {subPolizaID}
            setSubPolizaID = {setSubPolizaID}
            infoEndosos = {infoEndosos}
            setInfoEndosos = {setInfoEndosos}
            folioSubpoliza={folioSubpoliza}
            />
            :
            <>
            </>
        }

        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => { setOpenDocumentacion(false) }}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
            }

        {
            alert && <SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            />}

        
    </>)
}
