/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
//componentes genericos
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
// material-ui icons
import Close from "@material-ui/icons/Close";
import SaveIcon from '@material-ui/icons/Save';
//helpers
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Redux
import { useSelector } from 'react-redux';
import styles from '../../../_layout/assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { useParams, useHistory } from "react-router-dom";

import { getAllSeguimientoActividades } from '../../../querys/SeguimientoActividades';
import { updateSeguimientoActividades, addSeguimientoActividades, deleteSeguimientoActividades } from '../../../mutations/SeguimientoActividades';
const { trim } = Validaciones;

const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);

export default function SeguimientoTareaPorDia() {
    const { token } = useSelector(state => state.login);
    const { Decrypt, Encrypt } = Encriptar;
    let history = useHistory();
    let { params } = useParams();
    let auxProgramaAnualID = null;
    let auxFolioInterno = null;
    let auxTituloActividad = "";
    let auxUsuarioID = null;
    let auxActividadID = null;
    let auxTituloTarea = "";
    let auxRamoSeguro = "";

    if (params != undefined) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { programaAnualID, folioInterno, tituloActividad, actividadID, usuarioID, cTituloTarea, cRamoSeguro } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;

        auxProgramaAnualID = programaAnualID;
        auxFolioInterno = folioInterno;
        auxTituloActividad = tituloActividad;
        auxActividadID = actividadID;
        auxUsuarioID = usuarioID;
        auxTituloTarea = cTituloTarea;
        auxRamoSeguro = cRamoSeguro;
    }
    
    let cTitulo2 = "Datos adjuntos a la actividad " + auxTituloActividad;
    const cleanState = {
        seguimiento: "",
        fechaInicio: "",
        fechaFinal: "",
        fechaInicioReal: "",
        fechaFinalReal: "",
        horaInicial: "",
        horaFinal: ""
    };

    const cleanErrorState = {
        seguimiento: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" },
        horaInicial: { error: false, helperText: "" },
        horaFinal: { error: false, helperText: "" },
    };

    const [stateTituloTarea, setStateTituloTarea] = useState(auxTituloTarea);
    const classes = useStyles();
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    //   const [events, setEvents] = useState(eventsCalendario);
    const [alert, setAlert] = useState(null);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [actualizar, setActualizar] = useState(false);
    const [view, setView] = useState("month");
    let titleAccion = state.seguimientoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Actividad/Evento/Seguimiento`;
    const fechaInicialRealRef = useRef(null);
    const fechaFinalRealRef = useRef(null);
    const seguimientoRef = useRef(null);
    const fechaInicioRef = useRef(null);
    const fechaFinalRef = useRef(null);
    const horaInicialRef = useRef(null);
    const horaFinalRef = useRef(null);
    const botonRef = useRef(null);
    const selectedEvent = evento => {
        setState((state) => ({ ...state, fechaInicioReal: moment(evento.start), fechaFinalReal: moment(evento.end), seguimiento: evento.seguimiento, seguimientoID: evento.seguimientoID, finalizarSeguimiento: evento.finalizarSeguimiento, color: evento.color, folioTarea: evento.folioTarea, horaInicial: moment(evento.start), horaFinal: moment(evento.end) }));

        setOpen(true);
    };

    const eventColors = event => {

        let backgroundColor = "event-";

        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");


        return {

            className: backgroundColor
        }
    };

    const inputs = (view === 'day' || view === 'week') ? [
        {
            disabled,
            id: "seguimiento",
            value: state.seguimiento,
            error: errorState.seguimiento.error,
            success: state.seguimiento && !errorState.seguimiento.error ? true : undefined,
            helperText: errorState.seguimiento.helperText,
            inputRef: seguimientoRef,
            title: "Seguimiento *",
            multiline: true,
            placeholder: "Capture el seguimiento",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "seguimiento")
            },
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "fechaInicioReal",
            value: state.fechaInicioReal,
            error: errorState.fechaInicioReal.error,
            success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
            helperText: errorState.fechaInicioReal.helperText,
            inputRef: fechaInicialRealRef,
            placeholder: "Fecha Inicial",
            tipo: "datetimepicker",
            grid: { md: 3, lg: 3 },
        },
        {
            disabled: true,
            id: "fechaFinalReal",
            value: state.fechaFinalReal,
            error: errorState.fechaFinalReal.error,
            success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
            helperText: errorState.fechaFinalReal.helperText,
            inputRef: fechaFinalRealRef,
            placeholder: "Fecha Final",
            tipo: "datetimepicker",
            grid: { md: 3, lg: 3 },
        },
        {
            disabled,
            id: "finalizarSeguimiento",
            value: state.finalizarSeguimiento,
            checked: state.finalizarSeguimiento,
            title: "¿Finalizar Seguimiento?",
            tipo: "switch",
            grid: { md: 3, lg: 3 },
            onChange: (e) => {
                setState({ ...state, finalizarSeguimiento: e.target.checked })
            }
        }
    ] :

        [
            {
                disabled,
                id: "seguimiento",
                value: state.seguimiento,
                error: errorState.seguimiento.error,
                success: state.seguimiento && !errorState.seguimiento.error ? true : undefined,
                helperText: errorState.seguimiento.helperText,
                inputRef: seguimientoRef,
                title: "Seguimiento *",
                multiline: true,
                placeholder: "Capture el seguimiento",
                grid: { md: 12, lg: 12 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "seguimiento")
                },
                onKeyDown: () => { return; }

            },
            {
                disabled: true,
                id: "fechaInicioReal",
                value: state.fechaInicioReal,
                error: errorState.fechaInicioReal.error,
                success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
                helperText: errorState.fechaInicioReal.helperText,
                inputRef: fechaInicialRealRef,
                placeholder: "Fecha Inicial",
                tipo: "datetimepicker",
                onlyDate: true,
                grid: { md: 3, lg: 3 },
                /*onChange: ( value) =>{ typeof value === "object" && handleChange(value.format(formato), "fechaInicioReal")},*/
            },
            {
                disabled,
                id: "horaInicial",
                value: state.horaInicial,
                error: errorState.horaInicial.error,
                success: state.horaInicial && !errorState.horaInicial.error ? true : undefined,
                helperText: errorState.horaInicial.helperText,
                inputRef: horaInicialRef,
                placeholder: "Hora Inicial",
                tipo: "datetimepicker",
                onlyTime: true,
                grid: { md: 3, lg: 3 },
                onChange: (value) => { typeof value === "object" && handleChange(moment(value), "horaInicial") },
            },
            {
                disabled: true,
                id: "fechaFinalReal",
                value: state.fechaFinalReal,
                error: errorState.fechaFinalReal.error,
                success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
                helperText: errorState.fechaFinalReal.helperText,
                inputRef: fechaFinalRealRef,
                placeholder: "Fecha Final",
                tipo: "datetimepicker",
                onlyDate: true,
                grid: { md: 3, lg: 3 },
                /*   onChange: ( value) =>{ typeof value === "object" && handleChange(value.format(formato), "fechaFinalReal")},*/
            },
            {
                disabled,
                id: "horaFinal",
                value: state.horaFinal,
                error: errorState.horaFinal.error,
                success: state.horaFinal && !errorState.horaFinal.error ? true : undefined,
                helperText: errorState.horaFinal.helperText,
                inputRef: horaFinalRef,
                placeholder: "Hora Final",
                tipo: "datetimepicker",
                onlyTime: true,
                grid: { md: 3, lg: 3 },
                onChange: (value) => { typeof value === "object" && handleChange(moment(value), "horaFinal") },
            },
            {
                disabled,
                id: "finalizarSeguimiento",
                value: state.finalizarSeguimiento,
                checked: state.finalizarSeguimiento,
                title: "¿Finalizar Seguimiento?",
                tipo: "switch",
                grid: { md: 3, lg: 3 },
                onChange: (e) => {
                    setState({ ...state, finalizarSeguimiento: e.target.checked })
                }
            },
        ];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: fechaInicialRealRef,
            mensajeCorrecto: `El seguimiento se ${state.seguimientoID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    },/* {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }, */ /* {
        disabled: state.seguimientoID ? false : true,
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        onClick: () => handelAlertEliminar({
            setAlert,
            mensaje: ` El seguimiento numero ${state.seguimientoID}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncDelete: ftEliminar
            })
        })
    }, */ {
        disabled: state.seguimientoID ? false : true,
        icono: SaveIcon,
        color: success,
        descripcion: "Adjuntar Archivos",
        onClick: ftAdjuntar
    }];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleAgregarEvento(evento) {

        setState((state) => ({ ...state, fechaInicioReal: moment(evento.start), fechaFinalReal: moment(evento.end), seguimiento: evento.seguimiento, seguimientoID: evento.seguimientoID, finalizarSeguimiento: evento.finalizarSeguimiento, folioTarea: evento.folioTarea }));
        setOpen(true);


    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ seguimiento, horaInicial, horaFinal }) {

        let error;
        let validaciones = view === 'day' || view === 'week' ? [
            {
                condicion: !seguimiento || trim(seguimiento) === "",
                ref: seguimientoRef, keyError: "seguimiento",
                mensajeError: "Debe especificar el seguimiento"
            }
        ] :
            [
                {
                    condicion: !seguimiento || trim(seguimiento) === "",
                    ref: seguimientoRef, keyError: "seguimiento",
                    mensajeError: "Debe especificar el seguimiento"
                },
                {
                    condicion: !horaInicial || trim(horaInicial) === "",
                    ref: horaInicialRef, keyError: "horaInicial",
                    mensajeError: "Debe especificar la hora inicial"
                },
                {
                    condicion: !horaFinal || trim(horaFinal) === "",
                    ref: horaFinalRef, keyError: "horaFinal",
                    mensajeError: "Debe especificar la hora final"
                },
            ]
            ;
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { seguimientoID, actividadID, fechaInicioReal, fechaFinalReal, seguimiento, fechaInicio, fechaFinal, estado, colorEstado, finalizarSeguimiento, horaInicial, horaFinal } = state;

            let error = handleValidaciones({
                seguimiento,
                horaInicial,
                horaFinal
            });

            if (error) return error;

            let resultado;
            if (seguimientoID) {
                resultado = await updateSeguimientoActividades({
                    input: {
                        seguimientoID,
                        actividadID: auxActividadID,
                        seguimiento,
                        fechaInicioReal: view === 'day' || view === 'week' ? fechaInicioReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaInicioReal.format("YYYY-MM-DD")}T${horaInicial.format('LT')}`,
                        fechaFinalReal: view === 'day' || view === 'week' ? fechaFinalReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaFinalReal.format("YYYY-MM-DD")}T${horaFinal.format('LT')}`,
                        finalizarSeguimiento
                    }
                }, token);
            }
            else {
                resultado = await addSeguimientoActividades({
                    input: {
                        seguimientoID: null,
                        actividadID: auxActividadID,
                        seguimiento,
                        fechaInicioReal: view === 'day' || view === 'week' ? fechaInicioReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaInicioReal.format("YYYY-MM-DD")}T${horaInicial.format('LT')}`,
                        fechaFinalReal: view === 'day' || view === 'week' ? fechaFinalReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaFinalReal.format("YYYY-MM-DD")}T${horaFinal.format('LT')}`,
                        finalizarSeguimiento
                    }
                }, token);
            }

            let cTitulo = resultado.tituloTarea;
            setStateTituloTarea(cTitulo);

        }
        return ftGuardar();
    }



    function ftEliminar() {
        async function ftEliminar() {

            let { seguimientoID } = state;
            let resultado;

            if (seguimientoID) resultado = await deleteSeguimientoActividades({
                input: {
                    seguimientoID: Number(seguimientoID)
                }
            }, token);
            if (resultado === 'OK') location.reload();
            else setStateTituloTarea(resultado);

            handleClose();
            setActualizar(!actualizar);
        }
        return ftEliminar();
    }


    function ftAdjuntar() {
        async function ftAdjuntar() {

            let { seguimientoID, folioTarea } = state;
            setRedireccionarFormulario({ seguimientoID, folioTarea });
            handleClose();
            setActualizar(!actualizar);
        }
        return ftAdjuntar();
    }

    function setRedireccionarFormulario({ seguimientoID, folioTarea }) {
        let seed = "scrumweb";
        let descripcion = cTitulo2 + ' tarea ' + folioTarea;
        const datos = `{ "pURLDoctoOrigenID": "${seguimientoID}", "pTipo": "${folioTarea}", "pTabla": "Seguimiento Actividades", "pTitulo2": "${descripcion}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }


    useEffect(() => {
        async function ftEffect() {
            try {
                let eventos = await getAllSeguimientoActividades({ filtro: { actividadID: auxActividadID } }, token);
                setSeleccionables({ eventosDB: eventos });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Error: ${message}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    function ftRegresar() {

        history.goBack();

    }

    return (<>

        <div style={{
            color: "#ffffff", cursor: 'pointer', background: "#9c27b0", width: "80px", padding: "8px", border: "none",
            borderRadius: "4px",
        }} onClick={ftRegresar}>
            Regresar
        </div>
        <container>
            <h4>
                <b>Programa Anual: </b> {auxFolioInterno}
            </h4>
            <h4>
                <b>Ramo de Seguro: </b> {auxRamoSeguro}
            </h4>
            <h4 style={{ textAlign: 'justify' }}>
                <strong> Tarea:</strong> {stateTituloTarea}
            </h4>
            <h4 style={{ textAlign: 'justify' }}>
                <b>Actividad: </b> {auxTituloActividad}
            </h4>

            <BigCalendar
                selectable
                //timeslots={2}
                //step={15}
                popup
                localizer={localizer}
                events={seleccionables.eventosDB || []}
                showMultiDayTimes={false}
                defaultView="month"
                scrollToTime={new Date(2000, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={event => selectedEvent(event)}
                onSelectSlot={ () =>{} /* slotInfo => handleAgregarEvento(slotInfo) */}
                eventPropGetter={eventColors}
                views={['month', 'week', 'day'/* ,'agenda' */]}
                messages={{ next: 'Siguiente', previous: 'Anterior', today: 'Hoy', month: 'Mes', week: 'Semana', day: 'Día' /* , agenda:'Ver Agenda' */ }}
                onView={(view) => setView(view)} // para obtener la vista seleccionada ['month', 'week', 'day']
            />

            {open && <FormularioModal
                open={open}
                title={titleModal}
                onClose={handleClose}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputs}
                acciones={accionesFormulario}
                focus={fechaInicialRealRef}
            />
            }
            {alert && <SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            />
            }
        </container>
    </>);
}
