import React from 'react';
import PropTypes from "prop-types";

import { primaryColor, warningColor, dangerColor, successColor,infoColor, roseColor, grayColor } from "../../assets/jss/material-dashboard-pro-react.js";

import './preloader.css';

function Preloader({ color = "primary"}) {

    let newColor;

    switch (color) {
        case "primary":
            newColor = primaryColor[0];
            break;
        case "warning":
            newColor = warningColor[0];
            break;
        case "danger":
            newColor = dangerColor[0];
            break;
        case "success":
            newColor = successColor[0];
            break;
        case "info":
            newColor = infoColor[0];
            break;
        case "rose":
            newColor = roseColor[0];
            break;
        case "gray":
            newColor = grayColor[0];
            break;
        default:
            newColor = color;
            break;
    }

    return (<div id="pre-loading" style={{ backgroundColor: newColor }}>
        <div id="pre-loading-center">
            <div id="pre-loading-center-absolute">
                <div className="pre-object" id="pre-object_four"></div>
                <div className="pre-object" id="pre-object_three"></div>
                <div className="pre-object" id="pre-object_two"></div>
                <div className="pre-object" id="pre-object_one"></div>
            </div>
        </div>
    </div>);
}

Preloader.propTypes = {
    color: PropTypes.oneOfType([PropTypes.oneOf(["primary", "warning", "danger", "success", "info", "rose", "gray"]), PropTypes.string])
}

export default Preloader;
