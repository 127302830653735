import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import './ConfigPerfil.css';

// generic components
import Modal from '../../../_layout/genericComponents/Modal';
import Formulario from '../../../_layout/genericComponents/Formulario';
import Notificaciones from '../../../_layout/genericComponents/Notificaciones';

import * as Consultas from './index';

function ConfigPerfil(props) {
    /**
        allProcesos[1]: { procesoID: 1 -Es el que usaré como key del objeto para hacer más simple el acceso a las acciones
                nombre: "inicio",
                acciones: { 1: "Acceso", 2: "Agregar", 3: "Modificar"}}
        -Con este objeto llenar la tabla

        permisosPerfl[1]: { procesoID: 1 -Es el que usaré como key del objeto para hacer más simple el acceso a las acciones
                permisos: { 1: 1, 2: 0, 3: 1}} - El valor key equivale al accionID del proceso, el value equivale al valor (1) para si y (0) para no
        -Este objeto servirá para marcar en la tabla los valores de los checkbox

        permisosUsuario[1]: { procesoID: 1 -Es el que usaré como key del objeto para hacer más simple el acceso a las acciones
                permisos: { 1: 1, 2: 0, 3: 1}} - El valor key equivale al accionID del proceso
        -Este objeto servirá para marcar en la tabla los valores de los checkbox, sobreescribiendo lo del objeto permisosPerfl

        -Validar en el onclick de los checkbox si el valor indicado en el proceso y en la acción coincide con el de permisosPerfil,
        si es así quitar el registro de permisosUsuario, sino agregarlo

        -Al guardar eliminar los permisos personalizados del usuario y agregar los que queden en esta captura.
     */

    const [allProcesos, setAllProcesos] = useState({}); //procesID, nombre {acciones: accionID, nombre}
    const [permisosPerfil, setPermisosPerfil] = useState({}); // procesoID + '_' accionID : valor
    const [permisosUsuario, setPermisosUsuario] = useState({});// procesoID + '_' accionID : valor
    const [permisosChecks, setPemisosChecks] = useState({});// procesoID + '_' accionID : valor Serán los valores actuales y alguardar se comparan con los fijos (que serían los de arriba);
    const [procesoInicializar, setProcesoInicializar] = useState(0);
    const [inicializaProcesoVisible, setInicializaProcesoVisible] = useState('Oculto');

    let { open = false,
        title = "",
        onClose,
        notificaciones = {},
        closeNotification,
        acciones = [],
        focus,
        usuarioID = null,
        perfil = null,
        handleCloseConfigPerfil,
        setAlert,
        token } = props;

    for (let i = 0; i < acciones.length; i++) {
        const btnAccion = acciones[i];
        if (!btnAccion["key"]) {
            continue;
        }
        if (btnAccion["key"] === 'GuardarConfigPerfil') {
            btnAccion.onClick = () => GuardarConfigPerfil();
            continue;
        }
    }

    async function GuardarConfigPerfil() {
        let PermisosGuardar = [];

        let agregar = false;
        for (const key in permisosChecks) {
            agregar = true;
            if (permisosPerfil.hasOwnProperty(key)) {
                if (permisosPerfil[key] === permisosChecks[key]) {
                    agregar = false;
                }
            }

            if (agregar) {
                let tSplit = key.split('_');

                let permisoGuardar = {
                    procesoID: tSplit[0],
                    accionID: tSplit[1],
                    valor: permisosChecks[key],
                    usuarioID,
                    perfil
                }

                PermisosGuardar.push(permisoGuardar);
            }
        }

        try {
            let result = await Consultas.GuardarPermisosPersonalizadosxUsuario({ permisos: JSON.stringify(PermisosGuardar), usuarioID }, token);
            setAlert({
                descripcion: result,
                title: "Personalización de permisos",
                tipo: 'success',
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        } catch (error) {
            setAlert({
                descripcion: `Ocurrió un error al actualizar los permisos del usuario.`,
                title: "¡Una disculpa!",
                tipo: 'danger',
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }

    }

    useEffect(() => {
        async function ftEffect() {
            if (open && focus && focus.current && focus.current.focus) focus.current.focus();
            setInicializaProcesoVisible('Oculto');
            Refresh();
        } ftEffect();
    }, [open]);

    let PermisosXProceso = (
        <div key='contenedorPermisos' className="contenedorPermisos">
            <div key='InicializaProceso' className={inicializaProcesoVisible}>
                <input key='txtProcesoInicializar' className='txtProcesoInicializar' type='text' value={procesoInicializar} onChange={handleChange} />
                <div key={'Inicializar'} className="btnInicializar" onClick={() => callInicializaProceso()}>
                    Inicializar
                </div>
                <div key={'Refresh'} className="btnRefresh" onClick={() => Refresh()}>
                    Actualizar
                </div>
            </div>


            <div key='permisos' className="permisos">
                <div key='rowEncabezado' className="rowEncabezado">
                    <div key='procesoEncabezado' className="procesoEncabezado">
                        <div key='tituloProcesoEncabezado' className="tituloProcesoEncabezado">
                            Proceso
                        </div>
                    </div>
                    <div key='accionesProcesoEncabezado' className="accionesProcesoEncabezado">
                        <div key='tituloProcesoEncabezado' className="tituloProcesoEncabezado">
                            Acciones del proceso
                        </div>
                    </div>
                </div>
                {AgregarProcesos(allProcesos)}
            </div>
        </div>

    );

    async function Refresh() {
        setAlert(null);

        let Procesos = await Consultas.GetAllProcesos({}, token);
        setAllProcesos(Procesos);

        let permisosXPerfil = await Consultas.GetPermisosXPerfil({ perfil }, token);

        let objPermisosPerfil = {};

        let objPermisosChecks = {};
        for (let i = 0; i < permisosXPerfil.length; i++) {
            const permisoPerfil = permisosXPerfil[i];
            let key = permisoPerfil.procesoID + "_" + permisoPerfil.accionID;
            objPermisosChecks[key] = permisoPerfil.valor;
            objPermisosPerfil[key] = permisoPerfil.valor;
        }
        setPermisosPerfil({ ...objPermisosPerfil });

        if (usuarioID){
            let permisosXUsuario = await Consultas.GetPermisosPersonalizadosxUsuario({ usuarioID }, token);;
            let objPermisosUsuario = {};

            for (let i = 0; i < permisosXUsuario.length; i++) {
                const permisoUsuario = permisosXUsuario[i];
                let key = permisoUsuario.procesoID + "_" + permisoUsuario.accionID;
                objPermisosChecks[key] = permisoUsuario.valor;
                objPermisosPerfil[key] = permisoUsuario.valor;
            }
            setPermisosUsuario({ ...objPermisosUsuario });
        }

        setPemisosChecks({ ...objPermisosChecks });
    }
    async function callInicializaProceso() {
        try {
            let result = await Consultas.InicializarProceso({ procesoID: procesoInicializar }, token);
            ;
            setAlert({
                descripcion: result,
                title: "Personalización de permisos",
                tipo: 'success',
                msjConfirmacion: "Aceptar",
                onConfirm: () => Refresh()
            });

        } catch (error) {
            setAlert({
                descripcion: `Ocurrió un error al inicializar el proceso.`,
                title: "¡Una disculpa!",
                tipo: 'danger',
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    function handleChange(e) {
        setProcesoInicializar(e.target.value);
    }

    function AgregarProcesos(Procesos) {
        let rowsProcesos = [];
        for (const key in Procesos) {
            rowsProcesos.push(AddRowProceso(Procesos[key]));
        }
        return rowsProcesos;
    }

    function AddRowProceso(Proceso) {
        let compAcciones = [];

        for (const accion in Proceso.acciones) {
            let keyAccion = Proceso.procesoID + '_' + accion;
            compAcciones.push(
                <div key={'accion' + keyAccion} className="accion">
                    <div key={'tituloAccion' + keyAccion} className="tituloAccion">
                        {Proceso.acciones[accion]}
                    </div>
                    <div key={'checkboxAccion' + keyAccion} className="checkboxAccion">
                        <input key={'checkbox' + keyAccion} id={'checkbox' + keyAccion} className="checkboxAccion"
                            type="checkbox" checked={permisosChecks[keyAccion] === 1 ? true : false}
                            onClick={() => onClickCheckBox(keyAccion)} />
                    </div>
                </div>);
        }

        return (<div key={'rowProceso' + Proceso.procesoID} className="rowProceso">
            <div key={'proceso' + Proceso.procesoID} className="proceso">
                <div key={'tituloProceso' + Proceso.procesoID} className="tituloProceso">
                    {Proceso.nombre}
                </div>
            </div>
            <div key={'accionesProceso' + Proceso.procesoID} className="accionesProceso">
                {compAcciones}
            </div>
        </div>);
    }

    function onClickCheckBox(keyAccion) {
        if (permisosChecks[keyAccion]) {
            setPemisosChecks({ ...permisosChecks, [keyAccion]: permisosChecks[keyAccion] === 1 ? 0 : 1 });
            return;
        }
        
        setPemisosChecks({ ...permisosChecks, [keyAccion]: 1 });
    }

    // a key map of allowed keys
    let allowedKeys = {
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        65: 'a',
        66: 'b'
    };

    // the 'official' Konami Code sequence
    //let konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a'];
    let konamiCode = ['left', 'right', 'up', 'down'];

    // a variable to remember the 'position' the user has reached so far.
    let konamiCodePosition = 0;

    // add keydown event listener
    document.addEventListener('keydown', function (e) {
        // get the value of the key code from the key map
        let key = allowedKeys[e.keyCode];
        // get the value of the required key from the konami code
        let requiredKey = konamiCode[konamiCodePosition];

        // compare the key with the required key
        if (key == requiredKey) {

            // move to the next key in the konami code sequence
            konamiCodePosition++;

            // if the last key is reached, activate cheats
            if (konamiCodePosition == konamiCode.length) {
                activateCheats();
                konamiCodePosition = 0;
            }
        } else {
            konamiCodePosition = 0;
        }
    });

    function activateCheats() {
        //setInicializaProcesoVisible('InicializaProceso');
    }

    return (<Modal
        key={'modalPerfil'}
        open={open}
        title={title}
        onClose={onClose}
    >
        {
            notificaciones.open && <Notificaciones
                key={'notiPerfil'}
                close={true}
                message={notificaciones.mensaje}
                color={notificaciones.color}
                open={notificaciones.open}
                closeNotification={closeNotification}
            />
        }
        <Formulario
            header={false}
            inputs={[{ tipo: "custom", custom: PermisosXProceso }]}
            acciones={acciones}
        />
    </Modal>);
}

ConfigPerfil.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    notificaciones: PropTypes.object,
    closeNotification: PropTypes.func,
    inputs: PropTypes.arrayOf(PropTypes.object),
    acciones: PropTypes.arrayOf(PropTypes.object),
    focus: PropTypes.object
};

export default ConfigPerfil;
