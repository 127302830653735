import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_SINIESTROS_RECLAMADOS_ASEG = `
    query get($filtro: InputGetSiniestrosReclaAseg){
        getSiniestrosReclaAseg(filtro:$filtro){
            title
            titleSeguimiento
            color
            siniesReclAsegID
            empresaID
            estatus
            fechaAfectado
            siniestro
            aseguradoraID
            numPoliza
            deducible
            equipoBienAfectado
            descripEvento
            maquinaria
            operador
            ajustador
            fechaReclamacion
            indemnizaPagada
            fechaPago
            polizaID
            poliza
            ramoSeguroID
            ramoSeguro
            descripcionBreve
            folioInternoControlPago
            reporteDaniosID
            ejercicio
            mes
            fechaInicio
            fechaFinReporte
            observaciones
            controlPagoID
            divisaID
            paridad
            activoID
        }
    }`;

export const GET_ALL_SINIESTROS_RECLAMADOS_ASEG = `
    query filtroGetAll($filtro: InputGetSiniestrosReclaAseg){
        getAllSiniestrosReclaAseg(filtro:$filtro){
            title
            titleSeguimiento
            color
            siniesReclAsegID
            empresaID
            estatus
            fechaAfectado
            siniestro
            aseguradoraID
            numPoliza
            deducible
            equipoBienAfectado
            descripEvento
            maquinaria
            operador
            ajustador
            fechaReclamacion
            indemnizaPagada
            fechaPago
            polizaID
            poliza
            ramoSeguroID
            ramoSeguro
            descripcionBreve
            folioInternoControlPago
            reporteDaniosID
            ejercicio
            mes
            fechaInicio
            fechaFinReporte
            observaciones
            controlPagoID
            divisaID
            paridad
            activoID
        }
    }`;

export const TABLA_SINIESTROS_RECLAMADOS_ASEG = `
    query filtro($filtro: InputFiltroSiniestrosReclaAseg){
        filterSiniestrosReclaAseg(filtro:$filtro){
        listado{
            siniesReclAsegID
            empresaID
            estatus
            ultimoSeguimiento
            fechaAfectado
            siniestro
            aseguradoraID
            numPoliza
            deducible
            equipoBienAfectado
            descripEvento
            maquinaria
            operador
            ajustador
            fechaReclamacion
            indemnizaPagada
            fechaPago
            folioInterno
            empresa
            aseguradora
            polizaID
            poliza
            ramoSeguroID
            ramoSeguro
            descripcionBreve
            folioInternoControlPago
            reporteDaniosID
            folioInternoReporteDanios
            observaciones
            controlPagoID
            reclamoPrincipalID
            tipoReclamoPrincipal
            divisaID
            paridad
            activoID
        }
        registros
        paginas
        }
    }`;

export const getSiniestrosReclaAseg = async (filtro, token) => {
    try {
        let { getSiniestrosReclaAseg } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_SINIESTROS_RECLAMADOS_ASEG
        });

        return getSiniestrosReclaAseg;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllSiniestrosReclaAseg = async (variables, token) => {
    try {

        let { getAllSiniestrosReclaAseg } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SINIESTROS_RECLAMADOS_ASEG
        });

        let auxData = getAllSiniestrosReclaAseg.map(({
            title,
            titleSeguimiento,
            color,
            siniesReclAsegID,
            empresaID,
            estatus,
            fechaAfectado,
            siniestro,
            aseguradoraID,
            numPoliza,
            deducible,
            equipoBienAfectado,
            descripEvento,
            maquinaria,
            operador,
            ajustador,
            fechaReclamacion,
            indemnizaPagada,
            fechaPago,
            polizaID,
            poliza,
            ramoSeguroID,
            ramoSeguro,
            descripcionBreve,
            folioInternoControlPago,
            reporteDaniosID,
            ejercicio,
            mes,
            fechaInicio,
            fechaFinReporte,
            observaciones
        }) => {
            return {
                title,
                titleSeguimiento,
                color,
                start: new Date(fechaAfectado),
                end: new Date(fechaAfectado),
                siniesReclAsegID,
                empresaID,
                estatus,
                fechaAfectado,
                siniestro,
                aseguradoraID,
                numPoliza,
                deducible,
                equipoBienAfectado,
                descripEvento,
                maquinaria,
                operador,
                ajustador,
                fechaReclamacion,
                indemnizaPagada,
                fechaPago,
                polizaID,
                poliza,
                ramoSeguroID,
                ramoSeguro,
                descripcionBreve,
                folioInternoControlPago,
                reporteDaniosID,
                ejercicio,
                mes,
                fechaInicio,
                fechaFinReporte,
                observaciones
            }
        })

        return auxData;

    } catch ({ message }) { throw new Error(message); }

}

export const seleccionSiniestrosReclaAseg = async (variables, token) => {
    try {

        let getAllSiniestrosReclaAseg = await getAllSiniestrosReclaAseg(variables, token);
        if (!Array.isArray(getAllSiniestrosReclaAseg)) throw new Error("No se encontraron registros");

        return getAllSiniestrosReclaAseg.map(({ siniesReclAsegID: value, descripEvento: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaSiniestrosReclaAseg = async (variables, token) => {
    try {

        let { filterSiniestrosReclaAseg } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SINIESTROS_RECLAMADOS_ASEG
        });

        return filterSiniestrosReclaAseg;
    } catch ({ message }) { throw new Error(message); }
}
