import React from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveIcon from '@material-ui/icons/Remove';

import RowEditable from './componentes/Editable';

import moment from 'moment';

// Helpers
import { Fechas, Utilidades } from "@renedelangel/helpers";

const { DateToSpanishDate } = Fechas;
const { NumberToMoney } = Utilidades;
const NumberToPorcentaje = (valor, simbolo2 = "%") => `${valor}${simbolo2}`;

export default function RowComponentCustom ({ valor, tipo, token, url, textoAlt="", onlyDate = false, simbolo = "$", simbolo2 = "%", callback = () => console.log, id, onEnter, row, parametros, onDobleClic }) {

    let componente = "";

    switch (tipo) {
        case "fecha":
            componente = onlyDate && valor? (valor? moment(valor).format('DD-MM-YYYY') : '') : valor? moment(valor).format('DD-MM-YYYY HH:mm') : '';  /* DateToSpanishDate(valor, false, onlyDate) : "" */;
            break;
        case "fecha2":
            componente = onlyDate && valor? (valor? moment(valor).format('DD/MMMM/YYYY') : '') : valor? moment(valor).format('DD/MMMM/YYYY HH:mm') : '';  /* DateToSpanishDate(valor, false, onlyDate) : "" */;
            break;
        case "imagen":
            componente = valor ? <img src={`${valor}`} alt={textoAlt} title={textoAlt} style={{ maxHeight: "100px", width: "100px" }} /> : "";
            break;
        case "estatus":
            componente = valor ? <CheckCircleIcon /> : <RemoveIcon />;
            break;
        case "moneda":
            componente = valor ? NumberToMoney(valor, simbolo) : "$0";
            break;
        case "porcentaje":
            componente = valor ? NumberToPorcentaje(valor, simbolo2) : "";
            break;
        case "custom":
            componente = callback(valor, row);
            break;
        case "editable":
            componente = <RowEditable id={id} valor={valor} row={row} onEnter={onEnter} onDobleClic={onDobleClic} parametros={parametros} />
            break;
        default:
            componente = `${valor}`;
            break;
    }

    return componente;
}
