// Url's
export const urlServidor = process.env.URL_SERVIDOR || "http://localhost:3500";
export const urlServidorSAT = process.env.URL_SERVIDOR_SAT ||"https://tytsoftwaredemexico.com.mx:4000";
// "https://tytsoftwaredemexico.com.mx:4000"; "http://localhost:4000";

// Semilla
export const seed = process.env.SEED || "Y2FwZXJ1Y2l0YXJvamE=";
export const seedBackend = process.env.SEED_BACKEND || "c29mdHdvbmVAc2VndW1leA==";

// LocalStorage
export const TOKEN = "sesionToken";
export const USUARIO = "datosUsuario";
