export const GET_ALL_BITACORA = `
query getAllGenBitacora($filtro: InputGetBitacora){
    getAllGenBitacora(filtro:$filtro){
        bitacoraID
        empresaID
        empresa
        sucursalID
        sucursal
        usuarioID
        usuario
        fecha
        hora
        modulo
        opcion
        evento
        columnaAfectada
        datoAnterior
        datoNuevo
        montoRecurso
        origenID
    }
  }`;

  export const GET_BITACORA = `
  query getGenBitacora ($filtro: InputGetBitacora!)
{
    getGenBitacora(filtro: $filtro){
        bitacoraID
        empresaID
        empresa
        sucursalID
        sucursal
        usuarioID
        usuario
        fecha
        hora
        modulo
        opcion
        evento
        columnaAfectada
        datoAnterior
        datoNuevo
        montoRecurso
        origenID
        fechaHora
  }
}
`;

export const TABLA_BITACORA = `
query filterGenBitacora ($filtro : InputFilterBitacora){
    filterGenBitacora(filtro:$filtro)
    {
      registros
      paginas
      listado{
        bitacoraID
        usuarioID
        usuario
        fecha
        hora
        modulo
        opcion
        evento
        columnaAfectada
        datoAnterior
        datoNuevo
        origenID
        fechaHora
      }
    }
  }
  `;
  export const TABLA_BITACORADETALLE = `
query filterGenBitacoraDetalle ($filtro : InputFilterBitacora){
    filterGenBitacoraDetalle(filtro:$filtro)
    {
      registros
      paginas
      listado{
        bitacoraID
        usuarioID
        usuario
        fecha
        hora
        modulo
        opcion
        evento
        columnaAfectada
        datoAnterior
        datoNuevo
        origenID
        fechaHora
      }
    }
  }
  `;
