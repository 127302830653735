// Constantes mensajes
export const errorTitle = "¡Lo sentimos!";
export const errorBoton = "Aceptar";

export const modificarSinDatos = "Lamentablemente no pudimos obtener la información requerida.";

export const alertEliminarTitle = "¿Estás seguro?";
export const alertEliminarMsjConfirmacion = "Aceptar";
export const alertEliminarMsjCancelacion = "Cancelar";
export const alertEliminarDescripcion = (mensaje) => `Estás a punto de eliminar ${mensaje}`;
//${/*", aunque si en algún lugar ya se está ocupando es posible que no puedas hacerlo"*/}

export const titleEspera = "Estámos en ello...";
export const genericDescripcionEspera = (mensaje) => `La ${mensaje} del registro este tardando más de lo esperado, espere un momento.`;

export const eliminarTitle = "¡Lo pudimos eliminar!";
export const eliminarDescripcion = "El registro ha sido eliminado.";
export const eliminarBoton = "Aceptar";

export const confirmacionTitle = "¡Lo pudimos procesar!";
export const confirmacionDescripcion = "El registro ha sido procesado.";
export const confirmacionBoton = "Aceptar";
