export const estadoReclamacionSiniestro = [{
    value: "EN PROCESO",
    label: "EN PROCESO"
},{
    value: "CERRADO",
    label: "CERRADO"
},{
    value: "INCOMPLETO",
    label: "INCOMPLETO"
},{
    value: "PAGADO",
    label: "PAGADO"
},{
    value: "REPARACION",
    label: "REPARACION"
},{
    value: "REPOSICION",
    label: "REPOSICION"
}];
