import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { GENERIC_ENUMS } from '../index';

// Genéricos
const genericEnum = async (enumDesc, token) => {
    try {

        let { getEnum } = await FetchAPI.graphql({
            token,
            variables: { enum: enumDesc },
            url: urlServidor,
            query: GENERIC_ENUMS
        });

        return getEnum;

    } catch({message}) { throw new Error(message); }
};

const genericSelectEnum  = async (enumDesc, token) => {
    try {

        let estatus = await genericEnum(enumDesc, token
            );

        if(!Array.isArray(estatus)) throw new Error("No se encontraron registros");

        return estatus.map(({ value, name:label}) => ({value, label}));

    } catch({message:label}) {
        return [{
            label,
            value: "",
            disabled: true
        }];
    }
};

// Estatus
export const enumEstatus = async (token) => {
    try {

        return await genericEnum("EnumEstatus", token);

    } catch({message}) { throw new Error(message); }
};

export const selectEnumEstatus = async (token) => {
    try {

        return await genericSelectEnum("EnumEstatus", token);

    } catch({message}) { throw new Error(message); }
};

// Tipos de usuarios
export const enumTipoUsuarios = async (token) => {
    try {

        return await genericEnum("EnumUsuariosTipos", token);

    } catch({message}) { throw new Error(message); }
};

export const selectEnumTipoUsuarios = async (token) => {
    try {

        return await genericSelectEnum("EnumUsuariosTipos", token);

    } catch({message}) { throw new Error(message); }
};


