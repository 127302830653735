import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_REPORTEACCIDENTELABORAL= `
mutation addReporteAccidenteLaboral($input: InputAddReporteAccidenteLaboral) {
    addReporteAccidenteLaboral(input: $input) {

        reporteAccidenteLaboralID
        usuarioBitID
        empresaID
        folioInterno
        fechaElaboracion
        numIncidencia
        numNC
        descripBreve
        lugarEvento
        fechaEvento
        horaEvento
        supervCargo
        area
        empresaPersonalAfectadaID
        puestoHabilidadBase
        puestoHabilidadRealizaba
        nombreTrabajador
        numControlTrabajador
        actividadDesarrollaba
        jornadaDelSuceso
        diaUltimoDescanzo
        portabaEPP
        motivoAusenciaEPP
        iluminacionDeficiente
        condMeteorologicaAdvs
        faltaOrdenLimpieza
        espaciosReducidos
        instalacionMalEstado
        fallasEnProceso
        cortesPorHerramienta
        golpesPorObjetosEnCaida
        caidasAlMismoDifNivel
        concactoSustanciasQuimicas
        atrapamientoExtremidades
        lesionesErgonomicas
        colisionChoque
        quemaduras
        cabeza
        cara
        cuello
        espalda
        pecho
        brazos
        manos
        piernas
        pies
        descripDetallada
        fotografias
        existenTestigos
        nombresPersonalTestigo1
        numControlPersonalTestigo1
        puestoHabilidadTestigo1
        nombresPersonalTestigo2
        numControlPersonalTestigo2
        puestoHabilidadTestigo2
        observGenerales
        responAnalisis
        elaboro
        firma
        cargo
        tipoDanioOcasio
        folio
        danioOcasioPor
        danioOcasioARep
        equipoBienPersona
        areaRespRepa
        costoDanio
        estatus
        tiempoInvertido
        atendio
        notas
        finalizado
        seguimiento
        siglasEmpresa
        nombreEmpresa
        siglasEmpresaAfectada
        empresaAfectada
        fechaModificacion
    }
}
`;


export const UPDATE_REPORTEACCIDENTELABORAL =`
mutation updateReporteAccidenteLaboral($input: InputUpdateReporteAccidenteLaboral) {
    updateReporteAccidenteLaboral(input: $input) {

        reporteAccidenteLaboralID
        usuarioBitID
        empresaID
        folioInterno
        fechaElaboracion
        numIncidencia
        numNC
        descripBreve
        lugarEvento
        fechaEvento
        horaEvento
        supervCargo
        area
        empresaPersonalAfectadaID
        puestoHabilidadBase
        puestoHabilidadRealizaba
        nombreTrabajador
        numControlTrabajador
        actividadDesarrollaba
        jornadaDelSuceso
        diaUltimoDescanzo
        portabaEPP
        motivoAusenciaEPP
        iluminacionDeficiente
        condMeteorologicaAdvs
        faltaOrdenLimpieza
        espaciosReducidos
        instalacionMalEstado
        fallasEnProceso
        cortesPorHerramienta
        golpesPorObjetosEnCaida
        caidasAlMismoDifNivel
        concactoSustanciasQuimicas
        atrapamientoExtremidades
        lesionesErgonomicas
        colisionChoque
        quemaduras
        cabeza
        cara
        cuello
        espalda
        pecho
        brazos
        manos
        piernas
        pies
        descripDetallada
        fotografias
        existenTestigos
        nombresPersonalTestigo1
        numControlPersonalTestigo1
        puestoHabilidadTestigo1
        nombresPersonalTestigo2
        numControlPersonalTestigo2
        puestoHabilidadTestigo2
        observGenerales
        responAnalisis
        elaboro
        firma
        cargo
        tipoDanioOcasio
        folio
        danioOcasioPor
        danioOcasioARep
        equipoBienPersona
        areaRespRepa
        costoDanio
        estatus
        tiempoInvertido
        atendio
        notas
        finalizado
        seguimiento
        siglasEmpresa
        nombreEmpresa
        siglasEmpresaAfectada
        empresaAfectada
        fechaModificacion
    }
}
`;

export const DELETE_REPORTEACCIDENTELABORAL = `
mutation deleteReporteAccidenteLaboral($input: InputDeleteReporteAccidenteLaboral) {
    deleteReporteAccidenteLaboral(input: $input)
}
`;

export const addReporteAccidenteLaboral = async (variables, token) => {
    
    try {
        let { addReporteAccidenteLaboral } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_REPORTEACCIDENTELABORAL
        });

        return addReporteAccidenteLaboral;

    } catch({message}) { throw new Error(message); }
};

export const updateReporteAccidenteLaboral = async (variables, token) => {

    try {

        let { updateReporteAccidenteLaboral } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_REPORTEACCIDENTELABORAL
        });

        return updateReporteAccidenteLaboral;

    } catch({message}) { throw new Error(message); }
};


export const deleteReporteAccidenteLaboral = async (input, token) => {
    try {

        let { deleteReporteAccidenteLaboral } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_REPORTEACCIDENTELABORAL
        });

        return deleteReporteAccidenteLaboral;

    } catch({message}) { throw new Error(message); }
};

