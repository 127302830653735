import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';
import { getArchivo, deleteFileStorage, blobToBase64 } from "../../helpers/serverStorage";

export const GET_FOTOS_EVIDENCIA = `
        query get($filtro: InputGetFotosEvidencia){
            getFotosEvidencia(filtro: $filtro){
            fotosID,
            usuarioBitID
            fechaAgregada
            descripcion
            folioInterno
            tipoOriginal
            rutaFotoPerfil
            }
        }`;

export const GET_ALL_FOTOS_EVIDENCIA = `
        query filtroGetAll($filtro: InputGetFotosEvidencia){
            getAllFotosEvidencia(filtro: $filtro){
            fotosID,
            usuarioBitID
            fechaAgregada
            descripcion
            folioInterno
            tipoOriginal
            rutaFotoPerfil
            }
        }`;

export const TABLA_FOTOS_EVIDENCIA = `
        query filtro($filtro: InputFiltroFotosEvidencia){
            filterFotosEvidencia(filtro: $filtro){
            listado{
            fotosID,
            usuarioBitID
            fechaAgregada
            descripcion
            folioInterno
            rutaFotoPerfil
            }
            registros
            paginas
            }
        }`;

 export const OBTENER_FOTOS_EVIDENCIA = `
        query filtro($filtro: InputObtenerFotosEvidencia){
            ObtenerFotosEvidencias(filtro: $filtro){
            
            fotosID
            rutaFotoPerfil
            storageID
            nombreFoto

            }
            
        
        }`;

export const ObtenerFotosEvidencias = async (filtro, token) => {
    try {
       
        let { ObtenerFotosEvidencias } = await FetchAPI.graphql({
            token,
            variables:  filtro ,
            url: urlServidor,
            query: OBTENER_FOTOS_EVIDENCIA
        });
     
        return ObtenerFotosEvidencias;

    } catch ({ message }) { throw new Error(message); }
}

export const getFotosEvidencia = async (filtro, token) => {
    try {
        let { getFotosEvidencia } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_FOTOS_EVIDENCIA
        });

        return getFotosEvidencia;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllFotosEvidencia = async (variables, token) => {
    try {

        let { getAllFotosEvidencia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_FOTOS_EVIDENCIA
        });

        return getAllFotosEvidencia;

    } catch ({ message }) { throw new Error(message); }
}

export const seleccionFotosEvidencia = async (variables, token) => {
    try {

        let allFotosEvidencia = await getAllFotosEvidencia
            (variables, token);

        if (!Array.isArray(allFotosEvidencia)) throw new Error("No se encontraron registros");

        return allFotosEvidencia.map(({ fotosID: value, descripcion: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaFotosEvidencia = async (variables, token) => {
    try {
        let { filterFotosEvidencia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_FOTOS_EVIDENCIA
        });
        return filterFotosEvidencia;
    } catch ({ message }) { throw new Error(message);
     }

}

export const getFoto = async (variables, token) => {
    var Query = `
    query get($filtro: InputGetFotosEvidencia){
            getFotosEvidencia(filtro: $filtro){
            fotosID,
            usuarioBitID
            fechaAgregada
            descripcion
            folioInterno
            fotografia
            rutaFotoPerfil
            copia
            }
        }`;

    var resultado = await Ejecutar({ filtro: variables }, token, Query);


    let foto = resultado.rutaFotoPerfil ? await getArchivo('/private/segumex' + resultado.rutaFotoPerfil, token) : "";
    resultado["fotografia"] = foto;
    resultado["rutaFotoPerfil"] = resultado.rutaFotoPerfil;
    resultado["ruta"] = '/private/segumex' + resultado.rutaFotoPerfil;
    return resultado;
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });

        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) { throw new Error(error.message); }
}
