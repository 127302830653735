import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_UBICACION = `
mutation add($input: InputAddUbicacion)
{
  addUbicacion(input : $input)
  {
    ubicacionID
    ubicacion
  }
}
`;

export const UPDATE_UBICACION =`
mutation update($input: InputUpdateUbicacion)
{
  updateUbicacion(input : $input)
  {
    ubicacionID
    ubicacion
  }
}
`;

export const DELETE_UBICACION = `
mutation delete($input : InputDeleteUbicacion)
{
  deleteUbicacion(input: $input)
}
`;

export const addUbicacion = async (variables, token) => {
    try {
        let { addUbicacion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_UBICACION
        });
        return addUbicacion;
    } catch({message}) { throw new Error(message); }
};

export const updateUbicacion = async (variables, token) => {
    try {
        let { updateUbicacion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_UBICACION
        });
        return updateUbicacion;
    } catch({message}) { throw new Error(message); }
};

export const deleteUbicacion= async (input, token) => {
    try {
        let { deleteUbicacion } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_UBICACION
        });
        return deleteUbicacion;
    } catch({message}) { throw new Error(message); }
};
