import { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

import { isToken } from '../querys/Login/metodos';
import { cleanLocalStorage } from '../_layout/helpers/localstorage';

export function useTokenValido() {

    const { token } = useSelector(state => state.login);

    const [hasToken, setHasToken] = useState(null);

    function ftEffect() {
        async function effect() {
            try {

                let hasToken = await isToken(token);
                if(!!hasToken) setHasToken(null);
                setTimeout(() => setHasToken(!!hasToken), 2000);

            } catch({message}) {
                console.error(message);
                setHasToken(false);
                cleanLocalStorage();
            }
        } effect();
    }

    useEffect(ftEffect, [token]);

    return hasToken;

}
