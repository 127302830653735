import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_CONTENIDO = `
mutation add($input: InputAddSolSegFianContenido)
{
    addSolSegFianContenidos(input : $input)
  {
    contenidoID
    solsegfianID
    usuarioBitID
    empresaID
    empresa
    activoID
    activo
    descripcion
    dimensiones
    modelo
    marca
    nSerie
    tonelaje
    voltaje
    capacidad
    anio
    departamentoID
    departamento
    ubicacionID
    ubicacion
    costoUnitario
    divisaID
    paridad
    costoAsegurado
  }
}
`;

export const UPDATE_CONTENIDO =`
mutation update($input: InputUpdateSolSegFianContenido)
{
    updateSolSegFianContenidos(input : $input)
  {
    contenidoID
    solsegfianID
    usuarioBitID
    empresaID
    empresa
    activoID
    activo
    descripcion
    dimensiones
    modelo
    marca
    nSerie
    tonelaje
    voltaje
    capacidad
    anio
    departamentoID
    departamento
    ubicacionID
    ubicacion
    costoUnitario
    divisaID
    paridad
    costoAsegurado
  }
}
`;

export const DELETE_CONTENIDO = `
mutation delete ($input : InputDeleteSolSegFianContenido)
{
    deleteSolSegFianContenidos(input: $input)
}
`;

export const addContenido = async (variables, token) => {
    try {
        let { addSolSegFianContenidos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_CONTENIDO
        });
        return addSolSegFianContenidos;
    } catch({message}) { throw new Error(message); }
};

export const updateContenido = async (variables, token) => {
    try {
        let { updateSolSegFianContenidos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_CONTENIDO
        });
        return updateSolSegFianContenidos;
    } catch({message}) { throw new Error(message); }
};

export const deleteContenido= async (input, token) => {
    try {
        let { deleteSolSegFianContenidos } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_CONTENIDO
        });
        return deleteSolSegFianContenidos;
    } catch({message}) { throw new Error(message); }
};
