import React, { useEffect } from 'react'

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardHeader from "../../components/Card/CardHeader.js";
import InfoArea from "../../components/InfoArea/InfoArea.js";

// generic components
import Inputs from  "../Inputs";
import { Boton } from "../Botones";
import Notificaciones from "../Notificaciones";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function Formulario(props) {

    const classes = useStyles();
    let { color = "info", title, icono, inputs = [], acciones = [], grid = {}, header = true, focus, info = {}, otraInfo = [] } = props;
    let { xs = 12, sm = 12, md = 12, lg = 12 } = grid;

    let { message:infoMensaje = "", icon:infoIcono, color:infoColor } = info;

    let container = { direction: "row", justify: "center", alignItems: "center" };
    const contenedor = (inputs && Array.isArray(inputs)) &&  <GridContainer>
        { inputs.map(i => (<Inputs key={i.id} { ...i } />)) }
    </GridContainer>;

    const pie = <GridContainer
            direction={container.direction}
            justify={container.justify}
            alignItems={container.alignItems}
        >
        { (acciones && Array.isArray(acciones)) && acciones.map((a, index) => (<Boton
            key={index}
            inputRef={a.inputRef}
            Icono={a.icono}
            color={a.color}
            descripcion={a.descripcion}
            disabled={a.disabled}
            loader={a.loader}
            onClick={a.onClick}
        />)) }
    </GridContainer>;

    function ftEffect() { if(focus && focus.current && focus.current.focus) focus.current.focus(); }

    useEffect(ftEffect, [focus]);

    return (<>{ header ? <GridContainer direction={container.direction} justify={container.justify} alignItems={container.alignItems}>
        <GridItem xs={xs} sm={sm} md={md} lg={lg}>
               <Card>
                    <CardHeader color={color} icon>
                        <CardIcon color={color}>
                            { icono ? icono : <Assignment /> }
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>{title}</h4>
                    </CardHeader>
                    <CardBody>
                        {
                            Object.keys(info).length > 0 &&  <>
                                <br />
                                <Notificaciones
                                    message={infoMensaje}
                                    icon={infoIcono}
                                    color={infoColor}
                                />
                            </>
                        }
                        {
                            Array.isArray(otraInfo) && otraInfo.length > 0 && otraInfo.map(({titulo, descripcion, icono, colorIcono}, index) => {
                                if(!titulo || !descripcion) return null;
                                return (<InfoArea
                                    key={index}
                                    title={titulo}
                                    description={descripcion}
                                    icon={icono}
                                    iconColor={colorIcono}
                                />)
                            })
                        }
                        { contenedor }
                    </CardBody>
                    <CardFooter>
                        { pie }
                    </CardFooter>
                </Card>
        </GridItem>
    </GridContainer> : <>{ contenedor }<br />{ pie }</> }</>);
}
