import React, { useEffect, useState } from 'react';
import { NetworkError } from "../Metodos";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import useMediaQuerys from "../MediaQuerys";

import svgCancel from "../../assets/svg/undraw_cancel_u1it.svg";
import svgWarning from "../../assets/svg/undraw_warning_cyit.svg";
import svgSuccess from "../../assets/svg/undraw_High_five_u364.svg";
import Input from "../../genericComponents/Inputs/components/Input.js";
import Select from "../../genericComponents/Inputs/components/Select.js";
import InputsGeneric from "../../genericComponents/Inputs/index";
import GridContainer from "../../components/Grid/GridContainer.js";

const useStyles = makeStyles(styles);

export default function ModalConfirmacionInputs({ title, descripcion, msjConfirmacion, msjCancelacion, onConfirm,
    onCancel, tipo, showConfirm = true, showCancel = true,
    paramsOnConfirm, inputs }) {

    const [stDatos, setState] = useState({});
    let stInputs = [];
    const classes = useStyles();
    let tipoProps = {}, imagenSVG;
    let confirm = typeof showConfirm === "boolean" ? showConfirm : true;

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;
    let cancelar = showCancel && onCancel ? { showCancel: true } : {};

    switch (tipo) {
        case "success":
            tipoProps = { success: true };
            imagenSVG = svgSuccess;
            break;
        case "warning":
            tipoProps = { warning: true, showCancel };
            imagenSVG = svgWarning;
            break;
        case "danger":
            tipoProps = { danger: true };
            imagenSVG = svgCancel;
            break;
        case "success-two":
            tipoProps = { success: true, ...cancelar };
            imagenSVG = svgSuccess;
            break;
        default:
            tipoProps = { ...cancelar };
            break;
    }

    function handleChange(id, value) {
        setState({ ...stDatos, [id]: value });
        if(inputs){
            for (let i = 0; i < inputs.length; i++) {
                let element = inputs[i];
                if (element.id !== id){
                    continue;
                }
                if(element.data){
                    for (let j = 0; j < element.data.length; j++) {
                        const element2 = element.data[j];
                        if (element2.value === value){
                            setState({ ...stDatos, [id]: value, [id + "Aux"]: element2.label });
                        }
                    }
                }
            }
        }
    }

    function ftOnConfirm() {
        paramsOnConfirm = { ...paramsOnConfirm, ...stDatos }
        onConfirm(paramsOnConfirm);
    }

    if (inputs) {
        let inputsJSX = [];
        for (let i = 0; i < inputs.length; i++) {
            const element = inputs[i];
            switch (element.tipo) {
                case 'autocomplete':
                    inputsJSX.push(InputsGeneric({ ...element, key: i + "input", value: stDatos[element.id], onChange: (value) => handleChange(element.id, value.value) }));
                    break;
                default:
                    inputsJSX.push(InputsGeneric({ ...element, key: i + "input", value: stDatos[element.id], onChange: ({ target: { value } }) => handleChange(element.id, value) }));
                    break;
            }
        }
        stInputs = [...inputsJSX];
    } else {
        stInputs = [];
        stInputs.push(<Input
            id={'notasCan'}
            title={'Motivo cancelación'}
            placeholder={'Motivo de cancelación'}
            value={stDatos['notasCan']}
            onChange={({ target: { value } }) => handleChange('notasCan', value)}
            multiline={true}
        />);
    }

    useEffect(() => {
        let auxDatos = {};
        for (let i = 0; i < stInputs.length; i++) {
            const element = stInputs[i];
            auxDatos[element.id] = element.value;
        }
        setState({ ...auxDatos });
    }, []);

    return (
        <SweetAlert
            {...tipoProps}
            style={responsive ? { overflow: "visible" } : { width: "450px", overflow: "visible" }}
            title={title}
            onConfirm={ftOnConfirm}
            onCancel={onCancel}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText={msjConfirmacion}
            cancelBtnText={msjCancelacion}
            showConfirm={confirm}
        >
            {stInputs}
            <br /><br />
            {NetworkError(descripcion)}
        </SweetAlert>
    );
}
