import { Encriptar } from '@renedelangel/helpers';

const { Encrypt, Decrypt } = Encriptar;

export function setLocalStorage(item, campo, seed) {
    if(typeof item !== "string" || typeof campo !== "string") throw new Error("Solo se permiten valores del tipo string");
    if(!campo) throw new Error("No se recibio ningún dato válido");
    let newCampo = Encrypt({ data: campo, seed });
    let newItem = btoa(item);
    localStorage.setItem(newItem, newCampo);
    return true;
}

export function getLocalStorage(item, seed) {
    if(typeof item !== "string") throw new Error("Solo se permiten valores del tipo string");
    let newItem = btoa(item);
    let data = localStorage.getItem(newItem);
    if(!data) throw new Error("No se detecto ningún valor");
    return Decrypt({ data, seed });
}

export function getInitialStorage(item, seed) {
    try { return getLocalStorage(item, seed); }
    catch({message}) {
        console.error(message);
        return null;
    }
}

export function cleanLocalStorage() {
    localStorage.clear();
}

export function cleanLocalStorageItem(item) {
    localStorage.removeItem(item);
}

