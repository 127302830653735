import React, { useState, useRef, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';
import AccountCircle from '@material-ui/icons/AccountCircle';
import './botonAux.css';

// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
//storage
import { actualizaArchivo, eliminaArchivo } from "../../../helpers/serverStorage";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import { selectEnumEstatus } from '../../../querys/Enums/metodos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import { rgxPassword, rgxNumeros, rgxCorreo } from '../../../helpers/regexp';
import defaultImage from "../../../_layout/assets/img/image_placeholder.jpg";
import * as Permisos from '../Permisos';
import ConfigPerfil from '../Permisos/ConfigPerfil.js';
import { reiniciarPermisosXUsuario } from '../Permisos/index.js';

const { trim } = Validaciones;

function Usuarios() {

    const { token, usuario } = useSelector(stDatos => stDatos.login);

    var Empresas = [];
    let remover = false;
    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanNotificacionesConfigPerfil = { mensaje: "", color: null, open: false };
    let [stEmpresas, setEmpresas] = useState(Empresas)
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    let [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [notificacionesConfigPerfil, setNotificacionesConfigPerfil] = useState(cleanNotificacionesConfigPerfil);
    const [open, setOpen] = useState(false);
    const [openConfigPerfil, setOpenConfigPerfil] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [fotoPerfilUsuario, setFotoPerfil] = useState({ valor: "", file: "", eliminar: remover });

    let titleAccion = stDatos.usuarioID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Usuario`;
    let ArrayRef = [];

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "usuarioID" }, { campo: "nombreUsuario", variable: "nombreUsuario" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: Consultas.getUsuario
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "usuarioID" },
        { campo: "nombreUsuario", variable: "nombreUsuario" },
        { campo: "rutaFotoPerfil", variable: "rutaFotoPerfil" }],
        disabled: { multiseleccion: true },
        onClick: ({ usuarioID, nombreUsuario, rutaFotoPerfil }) => handelAlertEliminar({
            setAlert,
            mensaje: ` al usuario ${nombreUsuario}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { usuarioID, rutaFotoPerfil }, asyncDelete: Consultas.deleteUsuario
            })
        })
    }];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "usuarioID",
        color: primary,
        title: "Usuarios",
        iconTable: <AccountCircle />,
        headers: [
            { variable: "usuarioID", descripcion: "ID", hide: true },
            { variable: "nombreUsuario", descripcion: "Nombre" },
            { variable: "perfil", descripcion: "Perfil" },
            { variable: "correoUsuario", descripcion: "Correo" },
            { variable: "fotoUsuario", descripcion: "Foto" },
        ],
        responsiveTitle: ["usuarioID", "nombreUsuario"],
        filter: [
            { campo: "nombreUsuario", variable: "nombreUsuario", placeholder: "Capture el usuario a buscar" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [{ columnas: ["fotoUsuario"], tipo: "imagen", textoAlt: 'fotoPerfil' }],

    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];


    cleanErrorState["usuarioID"] = { error: false, helperText: "" };
    cleanState["usuarioID"] = 0;

    InicializarInputs("nombreUsuario", "Nombre*", "Capture el nombre completo del usuario", "", "", 100);
    InicializarInputs("telefono", "Teléfono", "Capture el teléfono", "", "", 10);
    InicializarInputs("usuario", "Nombre de usuario*", "Capture el nombre de usuario", "", "", 20);
    InicializarInputs("correoUsuario", "Correo*", "Capture el correo electrónico", "", "", 100);

    InicializarInputs("clave", "Contraseña*", "Capture la contraseña", "", "", 100);
    InicializarInputs("RepetirPassword", "Repetir contraseña*", "Capture nuevamente la contraseña", "", "", 100);

    InicializarInputs("perfil", "Perfil*", "Perfil del  usuario", "GENERAL", "");

    if (usuario.perfil === "ADMINISTRADOR") {
        inputs.push({
            tipo: "custom", grid: { md: 3, lg: 3 },
            custom:
                <>
                    <div id="contenedorBotonAux">
                        <div id="botonAux" disabled={disabled} onClick={() => AbrirConfigPerfil()}>
                            Personalizar permisos
                        </div>
                        <div id="botonAux2" disabled={disabled} onClick={() => setAlert({
                            descripcion: 'Esta acción va a borrar los permisos personalizados del usuario ¿Desea continuar?',
                            title: "¡Atención!",
                            tipo: "warning",
                            msjConfirmacion: "Sí",
                            onConfirm: () => ftReiniciarPermisosXUsuario(),
                            onCancel: () => setAlert(null),
                            msjCancelacion: "No"
                        })}>
                            Reiniciar permisos del usuario
                        </div>
                    </div>
                </>
        });
    }
    InicializarInputs("empresas", "Empresas", "", []);
    InicializarInputs("activo", "Activo", "Se encuentra ativo", true, "");
    InicializarInputs("bloqueado", "Bloqueado", "Se encuentra bloqueado", false, "");
    InicializarInputs("notificaciones", "Notificaciones", "Mostrar notificaciones", false, "");
    InicializarInputs("paginaInicio", "Pagina Inicio", "Pagina de inicio", false, "");
    InicializarInputs("fotoUsuario", "Foto", "Foto del usuario", "", "");

    async function ftReiniciarPermisosXUsuario() {
        if (stDatos['perfil'] && stDatos['usuarioID']) {
            setAlert({
                descripcion: 'Este proceso puede tardar un poco, por favor espere un momento',
                title: "Disculpe",
                tipo: "warning",
                showCancel: false,
                msjConfirmacion: "Enterado",
                onConfirm: () => { return }
            });
            let resp = await reiniciarPermisosXUsuario({ usuarioID: stDatos['usuarioID'] }, token);
            setAlert({
                descripcion: resp,
                title: "Listo!",
                tipo: "success",
                msjConfirmacion: "Enterado",
                onConfirm: () => setAlert(null)
            })
        } else {

            let descripcion = '';
            if (!stDatos['usuarioID']) {
                descripcion = 'Para usar esta opción primeo debe guardar el registro';
            }
            if (!stDatos['perfil']) {
                descripcion = 'Para usar esta opción debe seleccionar el perfil';
            }
            setAlert({
                descripcion: `Para personalizar los permisos del usuario debe seleccionar un perfil`,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    function AbrirConfigPerfil() {
        if (stDatos['perfil'] && stDatos['usuarioID']) {
            setOpenConfigPerfil(true)
        } else {

            let descripcion = '';
            if (!stDatos['usuarioID']) {
                descripcion = 'Para usar esta opción primeo debe guardar el registro';
            }
            if (!stDatos['perfil']) {
                descripcion = 'Para usar esta opción debe seleccionar el perfil';
            }
            setAlert({
                descripcion: `Para personalizar los permisos del usuario debe seleccionar un perfil`,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    InputsConID["nombreUsuario"].grid = { md: 6, lg: 6 };
    InputsConID["telefono"].grid = { md: 6, lg: 6 };
    //-------------------------------------------
    InputsConID["usuario"].grid = { md: 6, lg: 6 };
    InputsConID["correoUsuario"].grid = { md: 6, lg: 6 };
    //-------------------------------------------
    InputsConID["clave"].grid = { md: 6, lg: 6 };
    InputsConID["RepetirPassword"].grid = { md: 6, lg: 6 };
    //-------------------------------------------
    InputsConID["perfil"].grid = { md: 6, lg: 6 };
    InputsConID["empresas"].grid = { md: 6, lg: 6 };
    //-------------------------------------------
    InputsConID["activo"].grid = { md: 3, lg: 3 };
    InputsConID["bloqueado"].grid = { md: 3, lg: 3 };
    InputsConID["notificaciones"].grid = { md: 3, lg: 3 };
    InputsConID["paginaInicio"].grid = { md: 3, lg: 3 };
    InputsConID["fotoUsuario"].grid = { md: 6, lg: 6 };
    //-------------------------------------------

    InputsConID["clave"].inputProps = { type: "password" };
    InputsConID["RepetirPassword"].inputProps = { type: "password" };
    InputsConID["clave"].disabled = stDatos["usuarioID"] !== 0 ? true : false;
    InputsConID["RepetirPassword"].disabled = stDatos["usuarioID"] !== 0 ? true : false;

    InputsConID["empresas"].tipo = "select";
    InputsConID["empresas"].multiple = true;
    InputsConID["empresas"].data = stEmpresas;
    InputsConID["empresas"].disabled = (usuario.perfil !== "ADMINISTRADOR");

    InputsConID["activo"].tipo = "switch";
    InputsConID["activo"].value = stDatos["activo"];
    InputsConID["activo"].checked = (stDatos["activo"]);
    InputsConID["activo"].onChange = ({ target }) => setState({ ...stDatos, ["activo"]: target.checked ? 1 : 0 });

    InputsConID["bloqueado"].tipo = "switch";
    InputsConID["bloqueado"].value = stDatos["bloqueado"];
    InputsConID["bloqueado"].checked = (stDatos["bloqueado"]);
    InputsConID["bloqueado"].onChange = ({ target }) => setState({ ...stDatos, ["bloqueado"]: target.checked ? 1 : 0 });

    InputsConID["notificaciones"].tipo = "switch";
    InputsConID["notificaciones"].value = stDatos["notificaciones"];
    InputsConID["notificaciones"].checked = (stDatos["notificaciones"]);
    InputsConID["notificaciones"].onChange = ({ target }) => setState({ ...stDatos, ["notificaciones"]: target.checked ? 1 : 0 });

    InputsConID["paginaInicio"].tipo = "switch";
    InputsConID["paginaInicio"].value = stDatos["paginaInicio"];
    InputsConID["paginaInicio"].checked = (stDatos["paginaInicio"]);
    InputsConID["paginaInicio"].onChange = ({ target }) => setState({ ...stDatos, ["paginaInicio"]: target.checked ? 1 : 0 });

    InputsConID["perfil"].tipo = "select";
    InputsConID["perfil"].data = [{
        value: "ADMINISTRADOR",
        label: "ADMINISTRADOR"
    },
    {
        value: "COORDINADOR",
        label: "COORDINADOR"
    },
    {
        value: "ASISTENTE",
        label: "ASISTENTE"
    },
    {
        value: "INVITADO",
        label: "INVITADO"
    },
    {
        value: "AUXILIAR",
        label: "AUXILIAR"
    }];

    InputsConID["fotoUsuario"].tipo = "imageupload";
    InputsConID["fotoUsuario"].removeImage = stDatos.fotoPerfil;
    InputsConID["fotoUsuario"].onChange = (file, base) => {
        if (file === null) {
            remover = true;
        }
        setState(stDatos => ({
            ...stDatos,
            fotoUsuario: base
        }));
        setFotoPerfil({ valor: base, file: file, eliminar: remover });
        return;
    };

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0) {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        accionID: stDatos["usuarioID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["UsuarioNombre"],
            mensajeCorrecto: `El usuario se ${stDatos["usuarioID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    let accionesFormularioConfigPerfil = [{
        key: "GuardarConfigPerfil",
        accionID: stDatos["usuarioID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        /*onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["UsuarioNombre"],
            mensajeCorrecto: `El usuario se ${stDatos["usuarioID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),*/
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseConfigPerfil
    }];
    /*let accionesFormularioConfigPerfil = [{
        accionID: stDatos["usuarioID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["UsuarioNombre"],
            mensajeCorrecto: `El usuario se ${stDatos["usuarioID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];*/

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones() {
        let error;
        var validaciones = [
            {
                condicion: !stDatos["nombreUsuario"] || trim(stDatos["nombreUsuario"]) === "",
                ref: ArrayRef["nombreUsuario"], keyError: "nombreUsuario",
                mensajeError: "Debe especificar el nombre completo del usuario"
            },
            {
                condicion: !stDatos["usuario"] || trim(stDatos["usuario"]) === "",
                ref: ArrayRef["usuario"], keyError: "usuario",
                mensajeError: "Debe especificar el nombre de usuario"
            }, {
                condicion: !stDatos["clave"] || trim(stDatos["clave"]) === "",
                ref: ArrayRef["clave"], keyError: "clave",
                mensajeError: "Debe especificar el password"
            }, {
                condicion: stDatos["clave"] !== stDatos["RepetirPassword"],
                ref: ArrayRef["RepetirPassword"], keyError: "RepetirPassword",
                mensajeError: "Las contraseñas no coinciden"
            }, {
                condicion: !stDatos["perfil"] || trim(stDatos["perfil"]) === "",
                ref: ArrayRef["perfil"], keyError: "perfil",
                mensajeError: "Debe especificar el perfil"
            }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["usuarioID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (keyError !== "clave" && keyError !== "RepetirPassword") {
                    if (error) return;
                    error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
                }
            }
        });
        if (error) return error;
    }
    function ftGuardar() {
        async function ftGuardar() {
            let error = await handleValidaciones();
            var DatosGuardar = {
                usuarioID: stDatos["usuarioID"],
                usuarioBitID: 0,
                usuario: stDatos["usuario"],
                nombreUsuario: stDatos["nombreUsuario"],
                correoUsuario: stDatos["correoUsuario"],
                clave: stDatos["clave"],
                activo: (stDatos["activo"] == 1),
                bloqueado: (stDatos["bloqueado"] == 1),
                notificaciones: (stDatos["notificaciones"] == 1),
                paginaInicio: (stDatos["paginaInicio"] == 1),
                perfil: stDatos["perfil"],
                empresas: "",
                telefono: stDatos["telefono"]
            };

            for (let i = 0; i < stDatos["empresas"].length; i++) {
                if (DatosGuardar.empresas !== "") {
                    DatosGuardar.empresas = DatosGuardar.empresas + "," + stDatos["empresas"][i];
                    continue;
                }
                DatosGuardar.empresas = stDatos["empresas"][i].toString();
            }

            if (error) return error;
            let datos = { serverStorageID: stDatos.storageID, origenID: Number(DatosGuardar.usuarioID), tabla: "USUARIOS", tipo: "USUARIOS", clasificacion: "fotoPerfil", nombreArchivo: fotoPerfilUsuario.eliminar ? "" : fotoPerfilUsuario.file.name, ruta: stDatos["rutaFotoPerfil"] }
            if (stDatos["usuarioID"]) {
                await Consultas.updateUsuario(DatosGuardar, token);
            }
            else {
                delete DatosGuardar["usuarioID"];
                let valor = await Consultas.addUsuario(DatosGuardar, token);
                datos = { ...datos, serverStorageID: null, origenID: valor.usuarioID };
            }
            try {
                actualizaArchivo('usuarios/fotoPerfil/', fotoPerfilUsuario.file, 1, datos, token, fotoPerfilUsuario.eliminar);
            } catch (error) {
                setNotificaciones({ mensaje: error, color: danger, open: true })
            }
        }
        return ftGuardar();

    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setFotoPerfil({ valor: "", file: "", remover: false });
        setErrorState({ ...cleanErrorState });
        let img = document.getElementById("imgUpload");
        img.src = defaultImage;
    }

    function handleCloseConfigPerfil() {
        setOpenConfigPerfil(false);
        setNotificacionesConfigPerfil({ ...cleanNotificaciones });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (key === "fotoUsuario") {
            return;
        }
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        if (key === "telefono") {
            ValidarTelefono(value);
            return;
        }

        if (key === "correoUsuario") {
            ValidarEmail(value);
            return;
        }

        if (key === "RepetirPassword") {
            ValidarContraseñaRepetir(value)
            return;
        }

        if (key === "clave") {
            ValidarPassword(value)
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

        if (stDatos.usuarioID && key === "perfil"){
            setAlert({
                descripcion: 'El usuario esta cambiando de perfil. ¿Desea quitar los permisos personalizados de este usuario?',
                title: "¡Atención!",
                tipo: "warning",
                msjConfirmacion: "Sí",
                onConfirm: () => ftReiniciarPermisosXUsuario(),
                onCancel: () => setAlert(null),
                msjCancelacion: "No"
            });
        }
    }

    function ValidarTelefono(value) {
        var validRegex = rgxNumeros;

        value = value.trim();
        value = value.replace(/\s/g, "")

        if (value.length > 10) {
            return;
        }

        if (value === "") {
            setState(stDatos => ({
                ...stDatos,
                telefono: value
            }));
            return;
        }

        if (!value.match(validRegex)) {
            return;
        }

        if (value.length === 10) {
            setErrorState(errorState => ({
                ...errorState,
                telefono: { helperText: "", error: false }
            }));
        } else {
            setErrorState(errorState => ({
                ...errorState,
                telefono: { helperText: "El teléfono no tiene un formato válido", error: true }
            }));
        }

        setState(stDatos => ({
            ...stDatos,
            telefono: value
        }));
    }

    function ValidarEmail(value) {

        if (value.length > 100) {
            return;
        }

        var validRegex = rgxCorreo;

        if (value.match(validRegex)) {
            setErrorState(errorState => ({
                ...errorState,
                correoUsuario: { helperText: "", error: false }
            }));
        } else {
            setErrorState(errorState => ({
                ...errorState,
                correoUsuario: { helperText: "El correo electrónico no es válido", error: true }
            }));
        }

        setState(stDatos => ({
            ...stDatos,
            correoUsuario: value
        }));
    }

    function ValidarContraseñaRepetir(value) {

        if (value.length > 30) {
            return;
        }

        setErrorState(errorState => ({
            ...errorState,
            RepetirPassword: { helperText: "", error: false }
        }));

        if (stDatos["clave"] !== value) {
            setErrorState(errorState => ({
                ...errorState,
                RepetirPassword: { helperText: "Las contraseñas no coinciden", error: true }
            }));
        }

        setState(stDatos => ({
            ...stDatos,
            RepetirPassword: value
        }));
    }

    function ValidarPassword(value) {
        if (value.length > 30) {
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            clave: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            clave: { helperText: "", error: false }
        }));

        if (!rgxPassword.test(value) || (value.length < 8)) {
            setErrorState(errorState => ({
                ...errorState,
                clave: { helperText: "La estructura de la contraseña debe contener una letra mayúscula, una letra minúscula, un número, un caracter especial y de 8 a 30 caracteres", error: true }
            }));
            return;
        }
    }

    async function ObtenerEmpresas() {
        var Respuesta = await ConsultasEmpresas.seleccionEmpresa({ filtro: { activa: 1 } }, token);
        setEmpresas(Respuesta);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();
                let estatus = await selectEnumEstatus(token);
                setSeleccionables({ estatus });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={Consultas.tablaUsuarios}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["UsuarioNombre"]}
        />
        <ConfigPerfil
            open={openConfigPerfil}
            title={'Personalizar permisos ' + stDatos['perfil']}
            onClose={handleCloseConfigPerfil}
            notificaciones={notificacionesConfigPerfil}
            closeNotification={() => setNotificaciones({ ...cleanNotificacionesConfigPerfil })}
            inputs={[]} //inputs={inputs}
            acciones={accionesFormularioConfigPerfil}
            handleCloseConfigPerfil={handleCloseConfigPerfil}
            token={token}
            perfil={stDatos['perfil']}
            usuarioID={stDatos['usuarioID']}
            focus={ArrayRef["UsuarioNombre"]}
            setAlert={setAlert}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default Usuarios;
