import { FetchAPI } from '@renedelangel/helpers';
import { urlServidor } from '../../helpers';



export const GET_SOLSEGFIANEQUIPOOPER = `
query get($filtro: InputGetSolSegFianEquipoOper){
    getSolSegFianEquipoOper(filtro: $filtro)
    {
    equipoOperID
    solSegFianID
    activoAsegID
    equipo
    noEco
    marca
    modelo
    anio
    serie
    capacidad
    empresaID
    ubicacionID
    valorAseg
    sustituye
    motivo
    sustituyeID
    activoSustituye
    omitir
    divisaID
    paridad
    activo
    modeloCorrecto
    montoAsegurado
}
}`;


export const GET_ALL_SOLSEGFIANEQUIPOOPER = `
query filtroGetAll($filtro: InputGetSolSegFianEquipoOper){
    getAllSolSegFianEquipoOper(filtro: $filtro)
    {
    equipoOperID
    equipo
    divisaID
    paridad
    }
}`;

export const TABLA_SOLSEGFIANEQUIPOOPER = `
query filtro($filtro: InputFilterSolSegFianEquipoOper){
    filterSolSegFianEquipoOper(filtro: $filtro){
        listado{
            equipoOperID
            solSegFianID
            activoAsegID
            equipo
            noEco
            marca
            modelo
            anio
            serie
            capacidad
            empresaID
            ubicacionID
            valorAseg
            sustituye
            motivo
            sustituyeID
            activoSustituye
            modeloCorrecto
            divisa
            activoSustituye
            montoAsegurado
        }
        registros
        paginas
    }
}
`;


export const getSolSegFianEquipoOper = async (filtro, token) => {
    try {
        let { getSolSegFianEquipoOper } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_SOLSEGFIANEQUIPOOPER
        });
        return getSolSegFianEquipoOper;
    } catch ({ message }) { throw new Error(message); }
}

export const getAllSolSegFianEquipoOper = async (variables, token) => {
    try {
        let { getAllSolSegFianEquipoOper } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SOLSEGFIANEQUIPOOPER
        });
        return getAllSolSegFianEquipoOper;
    } catch ({ message }) { throw new Error(message); }
}

export const seleccionSolSegFianEquipoOper = async (variables, token) => {
    try {
        let allSolSegFianEquipoOper = await getAllSolSegFianEquipoOper(variables, token);
        if (!Array.isArray(allSolSegFianEquipoOper)) throw new Error("No se encontraron registros");

        return allSolSegFianEquipoOper.map(({ equipoOperID: value, equipo: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}


export const tablaSolSegFianEquipoOper = async (variable, token) => {
    try {
        let { equipoOperID, solSegFianID, activoAsegID, equipo, noEco, marca, modelo, anio, serie, capacidad, empresaID, ubicacionID, valorAseg, sustituye, motivo, divisa, activoSustituye, pagina, limite } = variable.filtro;
        let filtro = { equipoOperID: equipoOperID === null ? null : Number(equipoOperID), solSegFianID, activoAsegID, equipo, noEco, marca, modelo, anio, serie, capacidad, empresaID, ubicacionID, valorAseg, sustituye, motivo, divisa, activoSustituye, pagina, limite };
        let variables = { filtro };
        let { filterSolSegFianEquipoOper } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SOLSEGFIANEQUIPOOPER
        });
        return filterSolSegFianEquipoOper;
    } catch ({ message }) { throw new Error(message); }
}