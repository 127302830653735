import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { actualizaArchivoSoloRuta } from "../../../helpers/serverStorage";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import { BsBuilding } from "react-icons/bs";

import { rgxRFC } from '../../../helpers/regexp';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as Permisos from '../Permisos';

const { trim } = Validaciones;

function Empresas() {
    const { token } = useSelector(state => state.login);

    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const fotos = {
        foto1: { valor: "", file: "", eliminar: false }
    }
    const [stFotografias, setFotografias] = useState(fotos);

    let titleAccion = stDatos.empresaID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Empresa`;

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "empresaID" },
        { campo: "nombre", variable: "nombre" },
        { campo: "nombreCorto", variable: "nombreCorto" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: Consultas.getEmpresa
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "empresaID" },
        { campo: "nombre", variable: "nombre" }],
        disabled: { multiseleccion: true },
        onClick: ({ empresaID, nombre }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la empresa ${nombre}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { empresaID }, asyncDelete: Consultas.deleteEmpresa
            })
        })
    }];
    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "empresaID",
        color: primary,
        title: "Empresas",
        iconTable: <BsBuilding />,
        headers: [
            { variable: "empresaID", descripcion: "ID", hide: true },
            { variable: "nombre", descripcion: "Nombre de la Empresa" },
            { variable: "siglas", descripcion: "Siglas" },
            { variable: "activa", descripcion: "Activa" }
        ],
        responsiveTitle: ["empresaID", "nombre"],
        filter: [
            { campo: "nombre", variable: "nombre", placeholder: "Nombre de la empresa a buscar" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [{ columnas: ["activa"], tipo: "estatus" }]
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];
    let ArrayRef = [];

    cleanErrorState["empresaID"] = { error: false, helperText: "" };
    cleanState["empresaID"] = 0;
    cleanState["foto1"] = "";

    InicializarInputs("nombre", "Nombre*", "Capture el nombre de la empresa", "", "", 100);
    InicializarInputs("nombreCorto", "Nombre corto*", "Capture el nombre corto", "", "", 20);
    InicializarInputs("siglas", "Siglas*", "Capture las siglas", "", "", 10);
    InicializarInputs("rfc", "RFC", "Capture el rfc", "", "", 13);
    InicializarInputs("telefono", "Teléfono", "Capture el teléfono", "", "", 60);
    InicializarInputs("calle", "Calle", "Capture la calle", "", "Dirección", 50);
    InicializarInputs("noInt", "Número interior", "Capture el número interior", "", "Dirección", 10);
    InicializarInputs("noExt", "Número exterior", "Capture el número exterior", "", "Dirección", 10);
    InicializarInputs("entreCalles", "Entre calles", "Capture entre calles", "", "Dirección", 100);
    InicializarInputs("colonia", "Colonia", "Capture la colonia", "", "Dirección", 100);
    InicializarInputs("cp", "Código postal", "Capture el código postal", "", "Dirección", 5);
    InicializarInputs("municipio", "Municipio", "Capture el municipio", "", "Dirección", 30);
    InicializarInputs("localidad", "Localidad", "Capture la localidad", "", "Dirección", 30);
    InicializarInputs("estado", "Estado", "Capture el estado", "", "Dirección", 50);
    InicializarInputs("pais", "País", "Capture el país", "", "Dirección", 50);
    InicializarInputs("activa", "Activa", "Se encuentra ativa", 1, "");
    InicializarInputs("logoEmpresa", "Logo", "Logo", "", "");

    //-------------------------------------------
    InputsConID["nombre"].grid = { md: 8, lg: 8 };
    InputsConID["nombreCorto"].grid = { md: 4, lg: 4 };
    //-------------------------------------------
    InputsConID["siglas"].grid = { md: 4, lg: 4 };
    InputsConID["rfc"].grid = { md: 4, lg: 4 };
    InputsConID["telefono"].grid = { md: 4, lg: 4 };
    //-------------------------------------------
    InputsConID["calle"].grid = { md: 6, lg: 6 };
    InputsConID["noInt"].grid = { md: 3, lg: 3 };
    InputsConID["noExt"].grid = { md: 3, lg: 3 };
    //-------------------------------------------
    InputsConID["entreCalles"].grid = { md: 4, lg: 4 };
    InputsConID["colonia"].grid = { md: 4, lg: 4 };
    InputsConID["cp"].grid = { md: 4, lg: 4 };
    //-------------------------------------------
    InputsConID["municipio"].grid = { md: 6, lg: 6 };
    InputsConID["localidad"].grid = { md: 6, lg: 6 };
    InputsConID["estado"].grid = { md: 6, lg: 6 };
    InputsConID["pais"].grid = { md: 6, lg: 6 };
    InputsConID["rfc"].onChange = ({ target: { value } }) => ValidarRFC(value);


    InputsConID["activa"].tipo = "switch";
    InputsConID["activa"].value = stDatos["activa"];
    InputsConID["activa"].checked = (stDatos["activa"]);
    InputsConID["activa"].onChange = ({ target }) => setState({ ...stDatos, ["activa"]: target.checked ? 1 : 0 });

    InputsConID["logoEmpresa"].tipo = "imageupload";
    InputsConID["logoEmpresa"].removeImage = stDatos.foto1 === null || stDatos.foto1 === "" ? true : false;
    InputsConID["logoEmpresa"].onChange = handleSetImagen1;

    function handleSetImagen1(foto1) {
        let remover = false;
        if (foto1 === null) {
            remover = true;
        }
        setFotografias({ ...stFotografias, foto1: { IDimagen: stDatos.IDimagen1, url: stDatos.logoEmpresa, orden: 1, file: foto1, eliminar: remover } });
        setState({ ...stDatos, foto1, logoEmpresa: null });
    }

    function ValidarRFC(Valor) {
        var validRegex = /^[a-zA-Z0-9-]/;
        Valor = Valor.toUpperCase();

        if (Valor.length > 13) {
            return;
        }

        Valor = Valor.trim();

        if (Valor === "") {
            handleChange(Valor, "rfc");
            return;
        }

        if (!Valor.match(validRegex)) {
            return;
        }

        if (Valor.match(rgxRFC)) {
            setErrorState(errorState => ({
                ...errorState,
                rfc: { helperText: "", error: false }
            }));
        } else {
            setErrorState(errorState => ({
                ...errorState,
                rfc: { helperText: "El RFC no tiene un formato válido", error: true }
            }));
        }

        handleChange(Valor, "rfc");
    }

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0) {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        accionID: stDatos["empresaID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef['empresa'],
            mensajeCorrecto: `La empresa se ${stDatos["empresaID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones() {

        let error;
        var validaciones = [
            {
                condicion: !stDatos["nombre"] || trim(stDatos["nombre"]) === "",
                ref: ArrayRef["nombre"], keyError: "nombre",
                mensajeError: "Debe especificar el nombre de la empresa"
            },
            {
                condicion: !stDatos["nombreCorto"] || trim(stDatos["nombreCorto"]) === "",
                ref: ArrayRef["nombreCorto"], keyError: "nombreCorto",
                mensajeError: "Debe especificar el nombre corto de la empresa"
            }, {
                condicion: !stDatos["siglas"] || trim(stDatos["siglas"]) === "",
                ref: ArrayRef["siglas"], keyError: "siglas",
                mensajeError: "Debe especificar las siglas"
            }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {

            let { empresaID, nombre,
                nombreCorto } = stDatos;

            let error = await handleValidaciones({
                nombre,
                nombreCorto
            });

            if (error) return error;

            var DatosGuardar = stDatos;

            DatosGuardar.logoEmpresa = DatosGuardar.logoEmpresaSinConcatenar;

            for (const key in stFotografias) {
                if (stFotografias[key].file || stFotografias[key].eliminar) {
                    let url = stFotografias[key].url;
                    if (url === null) {
                        url = "";
                    }
                    let indice = url.indexOf('?');
                    let urloriginal = url.substring(0, indice);

                    let ruta = 'empresas/logo/';

                    let result = await actualizaArchivoSoloRuta(ruta, stFotografias[key].file, 0, Number(stFotografias[key].IDimagen), token, stFotografias[key].eliminar);
                    if (result) {
                        if (stFotografias[key].eliminar === true) {
                            result = "";
                        }
                        DatosGuardar.logoEmpresa = result;
                    }
                }
            }

            delete DatosGuardar["foto1"];
            delete DatosGuardar["logoEmpresaSinConcatenar"];

            if (stDatos["empresaID"]) {
                await Consultas.updateEmpresa(DatosGuardar, token);
            } else {
                delete DatosGuardar["CuentaID"];
                await Consultas.addEmpresa(DatosGuardar, token);
            }
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

    }

    useEffect(() => {
        async function ftEffect() {
            /*try {

                let estatus = await selectEnumEstatus(token);
                setSeleccionables({ estatus });

            } catch({message}) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }*/
        } ftEffect();
    }, []);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={Consultas.tablaEmpresa}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef['empresa']}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default Empresas;
