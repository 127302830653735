import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_PROGRAMA_ANUAL = `
mutation addProgramaAnual($input: InputAddProgramaAnual!) {
    addProgramaAnual(input: $input) {
            programaAnualID
            ejercicio
            fechaGeneracion
            fechaAutorizada
            observaciones
            estado
            autorizado
    }
}
`;


export const UPDATE_PROGRAMA_ANUAL = `
mutation updateProgramaAnual($input: InputUpdateProgramaAnual!) {
    updateProgramaAnual(input: $input) {
            programaAnualID
            ejercicio
            fechaGeneracion
            fechaAutorizada
            observaciones
            estado
            autorizado
    }
}
`;

export const DELETE_PROGRAMA_ANUAL = `
mutation deleteProgramaAnual($input: InputDeleteProgramaAnual) {
    deleteProgramaAnual(input: $input)
}
`;

export const AUTORIZA_PROGRAMA_ANUAL = `
mutation autorizaProgramaAnual($input: InputAutorizaProgramaAnual) {
    autorizaProgramaAnual(input: $input)
}
`;

export const DUPLICADO_PROGRAMA_ANUAL = `
mutation duplicadoProgramaAnual($input: InputDuplicadoProgramaAnual) {
    duplicadoProgramaAnual(input: $input){
                    programaAnualID
                    ejercicio
                    fechaGeneracion
    }
}
`;


export const CAMBIOESTADO_PROGRAMA_ANUAL = `
mutation cambioEstadoProgramaAnual($input: InputCambioEstadoProgramaAnual) {
    cambioEstadoProgramaAnual(input: $input){
                    programaAnualID
                    estado
                    correoEnviar
                    dominio
                    msjCorreo
                }
}
`;

export const addProgramaAnual = async (variables, token) => {
    try {

        let { addProgramaAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_PROGRAMA_ANUAL
        });

        return addProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
};

export const updateProgramaAnual = async (variables, token) => {
    try {

        let { updateProgramaAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_PROGRAMA_ANUAL
        });

        return updateProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
};


export const deleteProgramaAnual = async (input, token) => {
    try {

        let { deleteProgramaAnual } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: DELETE_PROGRAMA_ANUAL
        });

        return deleteProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
};

export const AutorizarProgramaAnual = async (input, token) => {
    try {

        let { autorizaProgramaAnual } = await FetchAPI.graphql({
            token,
            variables:  input  ,
            url: urlServidor,
            query: AUTORIZA_PROGRAMA_ANUAL
        });

        return autorizaProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
};

export const DuplicadoProgramaAnual = async (input, token) => {
    try {

        let { duplicadoProgramaAnual } = await FetchAPI.graphql({
            token,
            variables: input,
            url: urlServidor,
            query: DUPLICADO_PROGRAMA_ANUAL
        });

        return duplicadoProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
};

export const cambioEstadoProgramaAnual = async (input, token) => {
    try {

        let { cambioEstadoProgramaAnual } = await FetchAPI.graphql({
            token,
            variables: input,
            url: urlServidor,
            query: CAMBIOESTADO_PROGRAMA_ANUAL
        });

        return cambioEstadoProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
};