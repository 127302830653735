import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import { rgxdeInt2, rgxPromedio } from '../../../../helpers/regexp';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../../_layout/genericComponents/ModalConfirmacion';

import { ftObtenerEjerciciosFiltrado } from '../../../../helpers/rutinas/metodos/rutinasGenerales';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import CommuteIcon from '@material-ui/icons/Commute';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ReplayIcon from '@material-ui/icons/Replay';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { RiFileExcel2Fill } from "react-icons/ri";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import { NetworkError } from "../../../../_layout/genericComponents/Metodos";
import { generarControlPagxPoliza } from '../../../../mutations/ControlPago';

import * as ConsultasActivosAsegurado from '../../../../querys/ActivosAsegurado';
import { getPolizasSeguro, getAllPolizasSeguro, tablaPolizasSeguro, seleccionPolizasSeguro, getSubPolizasSeguro, getAllSubPolizasSeguro } from '../../../../querys/PolizasSeguro';
import {
    deletePolizasSeguro, addPolizasSeguro, updatePolizasSeguro, renovarPolizasSeguro, renovarConComplementaria, recalcularImportesPoliza, addSubPolizasSeguro, updateSubPolizasSeguro
} from '../../../../mutations/PolizasSeguro';

import { getPolizasSeguroDetalle, tablaPolizasSeguroDetalle } from '../../../../querys/PolizasSeguroDetalle';
import { deletePolizasSeguroDetalle, addPolizasSeguroDetalle, updatePolizasSeguroDetalle } from '../../../../mutations/PolizasSeguroDetalle';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric, handelAlertConfirmacion, handleEjecutaMetodo } from '../../../../_layout/helpers/handles';
import { info, danger, success, primary, rose, warning } from '../../../../_layout/helpers/colores';

import { getAllHistorialParidades } from '../../../../querys/HistorialParidades';
import { seleccionEmpresa } from '../../../../querys/Empresas';
import { seleccionAseguradora } from '../../../../querys/Aseguradoras';
import { seleccionRamoSeguro, seleccionRamoSeguroCoberturas } from '../../../../querys/RamoSeguro';
import { seleccionUbicaciones } from '../../../../querys/Ubicaciones';

import { rgxdeInt11, rgxInputTexto30, rgxNumeros, rgxDecimal8, rgxDecimal14 } from '../../../../helpers/regexp';

import moment from 'moment';

import { urlServidor } from '../../../../helpers';
import * as ConsultasActivos from '../../../../componentes/General/PolizasSeguro/ConsultasActivos';
import { seleccionActivosAsegurado } from '../../../../componentes/General/PolizasSeguro/ConsultasActivos';
import * as Permisos from '../../../../componentes/General/Permisos';
import { seleccionActivosAsegurado as seleccionActivosAseguradoClasificacion } from '../../../../querys/ActivosAsegurado';


import { getAllControlPagos } from '../../../../querys/ControlPago';
import { seleccionGrupos } from '../../../../querys/Grupos';
import { seleccionActivos } from '../../../../querys/ActivosSolicitudes';
import { seleccionDepartamento } from '../../../../querys/Departamento';

import MultiPartidas from '../../MultiPartidas';


import * as ConsultasActivosSubpolizas from './ConsultasActivosSubpolizas'

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
export default function ActivosSubpolizas(props) {

    let {folioSubpoliza, openVerActivos, setOpenVerActivos, subPolizaID, setSubpolizaID,infoActivos, setInfoActivos, desdeEndosos = false} = props;

    let history = useHistory();
    let now = moment();
    const fecha = new Date();
    const anio = fecha.getFullYear();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";
    let cTitulo2 = "ANEXOS: Recuerde adjuntar documento fuente.";
    const { token } = useSelector(state => state.login);
    const [fPrima, setFprima] = useState(0);
    let anioActual = new Date().getFullYear();

    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";

    let Empresas = [];
    let [stActivosAsegurado, setActivosAsegurado] = useState(Empresas)
    const cleanDisabledInputs = { polizaOrigen: true, tipoPoliza: true, empresa: false, ramoSeguro: false, aseguradora: false, divisa: false, sustituye: false };
    const cleanaTiposPoliza = [
        {
            label: 'PRINCIPAL',
            value: 'PRINCIPAL'
        },
        {
            label: 'POLIZA',
            value: 'POLIZA'
        },
        {
            label: 'ENDOSO',
            value: 'ENDOSO'
        }
    ];
    const tipoValorSumaAseg = [
        {
            label: 'VALOR CONVENIDO',
            value: 'VALOR CONVENIDO'
        },
        {
            label: 'VALOR COMERCIAL + 10%',
            value: 'VALOR COMERCIAL + 10%'
        },
        {
            label: 'VALOR DE REPOSICIÓN',
            value: 'VALOR DE REPOSICIÓN'
        },
        {
            label: 'AVALUO',
            value: 'AVALUO'
        },
        {
            label: 'VALOR FACTURA',
            value: 'VALOR FACTURA'
        },
    ]

    let listAnios = [];
    const cleanState = {
        polizasSeguroID: null,
        seleccionTipoPoliza: 1,
        numero: "",
        fechaSolCot: "",
        empresaID: "",
        ramoSeguroID: "",
        aseguradoraID: "",
        numpolEndoso: "",
        divisaID: 69,
        sumaAseg: "",
        primaNeta: "",
        fpPromedio: "",
        vigenciaIni: moment(),
        vigenciaHasta: "",
        exclusiones: "",
        renovacion: true,
        horasInvertidas: "",
        tipoValorAseg: "",
        tipoPoliza: "PRINCIPAL",
        polizaOrigenID: 0,
        beneficiarioPreferente: "",
        bienesAmparadosEncabezado: "",
        derechoPoliza: 0,
        ramoSeguro: "",
        paridad: '',
        inciso: "",
        renovacionObs: "",
        aseguradoAdicional: ""
    };

    const cleanErrorState = {
        seleccionTipoPoliza: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        fechaSolCot: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        numpolEndoso: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        sumaAseg: { error: false, helperText: "" },
        primaNeta: { error: false, helperText: "" },
        fpPromedio: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        exclusiones: { error: false, helperText: "" },
        renovacion: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        tipoValorAseg: { error: false, helperText: "" },
        tipoPoliza: { error: false, helperText: "" },
        polizaOrigenID: { error: false, helperText: "" },
        beneficiarioPreferente: { error: false, helperText: "" },
        bienesAmparadosEncabezado: { error: false, helperText: "" },
        derechoPoliza: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        inciso: { error: false, helperText: "" },
        renovacionObs: { error: false, helperText: "" },
        aseguradoAdicional: { error: false, helperText: "" }
    }

    const cleanStateDetalle = {
        polizaSeguroID: null,
        polSegDetalleID: null,
        deducible: "",
        cobertura: "",
        exclusiones: "",
        bienesAmparados: "",
        tipo: "Seguro",
        maximoMinimo: ""
    };

    const cleanErrorStateDetalle = {
        deducible: { error: false, helperText: "" },
        cobertura: { error: false, helperText: "" },
        exclusiones: { error: false, helperText: "" },
        bienesAmparados: { error: false, helperText: "" },
        tipo: { error: false, helperText: "" },
        maximoMinimo: { error: false, helperText: "" }
    }

    const cleanErrorStateRenovar = {
        fechaSolCot: { error: false, helperText: "" },
    }

    const cleanStateActivos = {};
    const cleanErrorStateActivos = {};

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanNotificacionesDetalle = { mensaje: "", color: null, open: false };
    const cleanNotificacionesActivos = { mensaje: "", color: null, open: false };

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [disabledInputs, setDisabledInputs] = useState(cleanDisabledInputs);
    const [stateDetalle, setStateDetalle] = useState(cleanStateDetalle);
    const [errorStateDetalle, setErrorStateDetalle] = useState(cleanErrorStateDetalle);
    const [notificacionesDetalle, setNotificacionesDetalle] = useState(cleanNotificacionesDetalle);
    const [stateActivos, setStateActivos] = useState(cleanStateActivos);
    const [errorStateActivos, setErrorStateActivos] = useState(cleanErrorStateActivos);
    const [notificacionesActivos, setNotificacionesActivos] = useState(cleanNotificacionesActivos);
    const [openControlPago, setOpenControlPago] = useState(false);

    let [datosPDF, setdatosPDf] = useState({});

    const [seleccionables, setSeleccionables] = useState(false);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [openSubModal, setOpenSubModal] = useState(false);
    const [actualizarEndosos, setActualizarEndosos] = useState(false);
    //const [notificacionesEndosos, setNotificacionesEndosos] = useState(cleanNotificacionesEndosos);
    const [openRenovar, setOpenRenovar] = useState(false);
    const [openRenovarComp, setOpenRenovarComp] = useState(false);

    const [stateRenovar, setStateRenovar] = useState(cleanState);
    const [errorStateRenovar, setErrorStateRenovar] = useState(cleanErrorStateRenovar);
    const [openDetalle, setOpenDetalle] = useState(false);
    const [actualizarDetalle, setActualizarDetalle] = useState(false);
    const [openVerDetalle, setOpenVerDetalle] = useState(false);
    const [openActivos, setOpenActivos] = useState(false);
    const [actualizarActivos, setActualizarActivos] = useState(false);
    const [loaderActivos, setLoaderActivos] = useState(false);
    //const [openVerActivos, setOpenVerActivos] = useState(false);
    const [openVerEndosos, setOpenVerEndosos] = useState(false);
    const [actualizarTipoPoliza, setActualizarTipoPoliza] = useState(false);
    const [aTiposPoliza, setActualizaraTiposPoliza] = useState(cleanaTiposPoliza);
    const [statePolizaSeguroID, setStatePolizaSeguroID] = useState(-1);

    const [statePolizaSeguroPrincipalID, setStatePolizaSeguroPrincipalID] = useState(-1);
    const [stateEmpresaID, setStateEmpresaID] = useState(-1);
    const [stateRamoSeguroID, setStateRamoSeguroID] = useState(-1);
    const [stateRamoSeguro, setStateRamoSeguro] = useState("");
    const [stateFolioInterno, setStateFolioInterno] = useState("");
    const [stateSubRamoSeguroID, setStateSubRamoSeguroID] = useState(-1);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    const [openTrazabilidad, setOpenTrazabilidad] = useState(false);
    const [datosDetalle, setDatosDetalle] = useState({});
    const [statePromedio, setStatePromedio] = useState(1);
    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [filtrosTexto, setFiltrosTexto] = useState({});

    const [inputsFormulario, setInputsFormulario] = useState(<></>);
    const [datosPartidas, setDatosPartidas] = useState(null);
    const [datosPartidasGet, setDatosPartidasGet] = useState(null);

    const cleanStatePadre = {
        polizasSeguroID: null,
        numero: "",
        fechaSolCot: now.format(formatoDelmomento),
        empresaID: "",
        ramoSeguroID: "",
        aseguradoraID: "",
        numpolEndoso: "",
        divisaID: 69,
        sumaAseg: "",
        primaNeta: "",
        fpPromedio: "",
        vigenciaIni: now.format(formatoDelmomento),
        vigenciaHasta: "",
        exclusiones: "",
        renovacion: true,
        horasInvertidas: "",
        tipoValorAseg: "",
        tipoPoliza: "ENDOSO",
        polizaOrigenID: statePolizaSeguroPrincipalID,
        inciso: ""
    };

    const cleanStateRenovar = {
        polizasSeguroID: null,
        empresaID: "",
        fechaSolCot: now.format(formatoDelmomento)

    }
    const [statePadre, setStatePadre] = useState(cleanStatePadre);

    let titleAccion = state.polizasSeguroID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Póliza de Seguro`;

    let titleModalRenovar = `Renovar Póliza de Seguro `;
    let titleModalRenovarComp = `Renovar Póliza de Seguro Complementaria`;
    let titleAccionDetalle = state.polSegDetalleID ? "Modificar" : "Agregar";
    let titleModalDetalle = `${titleAccionDetalle} Concepto de Póliza`;

    let titleAccionActivos = stateActivos.infoActAsegID ? "Modificar" : "Agregar";
    let titleModalActivos = `${titleAccionActivos} Información de Activos Asegurados de cobertura`;

    const seleccionTipoPolizaRef = useRef(null);
    const numeroRef = useRef(null);
    const tipoValorAsegRef = useRef(null);
    const fechaSolCotRef = useRef(null);
    const empresaIDRef = useRef(null);
    const ramoSeguroIDRef = useRef(null);
    const aseguradoraIDRef = useRef(null);
    const numpolEndosoRef = useRef(null);
    const divisaIDRef = useRef(null);
    const sumaAsegRef = useRef(null);
    const primaNetaRef = useRef(null);
    const fpPromedioRef = useRef(null);
    const vigenciaIniRef = useRef(null);
    const vigenciaHastaRef = useRef(null);
    const exclusionesRef = useRef(null);
    const horasInvertidasRef = useRef(null);
    const tipoPolizaRef = useRef(null);
    const polizaOrigenIDRef = useRef(null);
    const renovacionObsRef = useRef(null);
    const aseguradoAdicionalRef = useRef(null);

    const DetDeducibleRef = useRef(null);
    const DetCoberturaRef = useRef(null);
    const DetExclusionesRef = useRef(null);
    const DetBienesAmparadosRef = useRef(null);
    const DetTipoRef = useRef(null);
    const DetMaximoMinimoRef = useRef(null);

    const renFechaSolCotRef = useRef(null);

    const beneficiarioPreferenteRef = useRef(null);
    const bienesAmparadosEncabezadoRef = useRef(null);
    const derechoPolizaRef = useRef(null);
    const incisoRef = useRef(null);


    useEffect(() => {
        setStatePolizaSeguroID(null);
        setActualizarActivos(!actualizarActivos);
        setStateEmpresaID(infoActivos.datosEncabezado.empresaID);
        setStateFolioInterno(folioSubpoliza);
        setStateRamoSeguroID(infoActivos.datosSubpoliza.ramoSeguroID);
        setStateSubRamoSeguroID(infoActivos.subRamoSeguroID);
        setStateActivos({ ...stateActivos, ramoSeguroID: infoActivos.datosSubpoliza.ramoSeguroID });
        UbicacionesXEmpresa(infoActivos.datosEncabezado.empresaID);
    }, [])

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ];

    async function obtenerValoresMultipartidas(polizasSeguroID){
        let auxDatos = await getAllSubPolizasSeguro({filtro: {polizasSeguroID: parseInt(polizasSeguroID.polizasSeguroID)}}, token)
        if(auxDatos.length > 0){
        setDatosPartidasGet(auxDatos);
        }
        return ;
    }

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => {
            
            obtenerValoresMultipartidas(parametros);
            handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getPolizasSeguro, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) },
                { id: "vigenciaIni", formato: (data) => moment(data) },
                { id: "vigenciaHasta", formato: (data) => moment(data) }
            ],
            
        })
    }
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "polizasSeguroID" },
        { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la poliza del numero ${numero}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { polizasSeguroID }, asyncDelete: deletePolizasSeguro
            })
        })
    },
    {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero)
    },
    {
        accionID: [6],
        icono: RiFileExcel2Fill,
        color: success,
        descripcion: "Generar Excel",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero, 'excel')
    },
    {
        accionID: [8],
        icono: PlaylistAddCheckOutlinedIcon,
        color: primary,
        descripcion: "Ver Conceptos",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: false },

        onClick: ({ polizasSeguroID, folioInterno }) => {
            if (statePolizaSeguroID != polizasSeguroID && !openVerDetalle) {
                setOpenVerDetalle(!openVerDetalle);
                setStatePolizaSeguroID(polizasSeguroID);
                setActualizarDetalle(!actualizarDetalle)
                setStateFolioInterno(folioInterno);
            } else if (statePolizaSeguroID != polizasSeguroID && openVerDetalle) {
                setStatePolizaSeguroID(polizasSeguroID);
                setActualizarDetalle(!actualizarDetalle)
                setStateFolioInterno(folioInterno);
            }
            else if (statePolizaSeguroID === polizasSeguroID && openVerDetalle) {
                setStatePolizaSeguroID(polizasSeguroID);
                setOpenVerDetalle(!openVerDetalle);
                setStateFolioInterno(folioInterno);
            } else if (statePolizaSeguroID === polizasSeguroID && !openVerDetalle) {
                setStatePolizaSeguroID(polizasSeguroID);
                setOpenVerDetalle(!openVerDetalle);
                setStateFolioInterno(folioInterno);
            }
        },
        disabled: { multiseleccion: true }
    },
    {
        accionID: [10],
        icono: CommuteIcon,
        color: info,
        descripcion: "Ver activos",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "empresaID" }, { campo: "folioInterno" }, { campo: "ramoSeguroID" }, { campo: "ramoSeguro" }, { campo: 'fpPromedio' }, {campo: "subPolizaID"}],
        disabled: { multiseleccion: false },

        onClick: ({ polizasSeguroID, empresaID, folioInterno, ramoSeguroID, ramoSeguro, fpPromedio, subPolizaID: subPolizaRegistro }) => {
            setFprima(fpPromedio);
            if (subPolizaID !== subPolizaRegistro && !openVerActivos) {
                setOpenVerActivos(!openVerActivos);
                setStatePolizaSeguroID(null);
                setActualizarActivos(!actualizarActivos);
                setStateEmpresaID(empresaID);
                UbicacionesXEmpresa(empresaID);
                setStateFolioInterno(folioInterno);
                setStateRamoSeguroID(ramoSeguroID);
                setStateRamoSeguro(ramoSeguro);
                setStateActivos({ ...stateActivos, ramoSeguroID });
            } else if (subPolizaID !== subPolizaRegistro && openVerActivos) {
                setStatePolizaSeguroID(null);
                setActualizarActivos(!actualizarActivos);
                setStateEmpresaID(empresaID);
                setStateFolioInterno(folioInterno);
                setStateRamoSeguroID(ramoSeguroID);
                setStateRamoSeguro(ramoSeguro);
                setStateActivos({ ...stateActivos, ramoSeguroID });
                UbicacionesXEmpresa(empresaID);
            }
            else if (subPolizaID === subPolizaRegistro && openVerActivos) {
                setStatePolizaSeguroID(null);
                setOpenVerActivos(!openVerActivos);
                setStateEmpresaID(empresaID);
                setStateFolioInterno(folioInterno);
                setStateRamoSeguroID(ramoSeguroID);
                setStateRamoSeguro(ramoSeguro);
                setStateActivos({ ...stateActivos, ramoSeguroID });
                UbicacionesXEmpresa(empresaID);
            } else if (subPolizaID === subPolizaRegistro && !openVerActivos) {
                setStatePolizaSeguroID(null);
                setOpenVerActivos(!openVerActivos);
                setStateEmpresaID(empresaID);
                setStateFolioInterno(folioInterno);
                setStateRamoSeguroID(ramoSeguroID);
                setStateRamoSeguro(ramoSeguro);
                setStateActivos({ ...stateActivos, ramoSeguroID });
                UbicacionesXEmpresa(empresaID);
            }
        },
        disabled: { multiseleccion: true }
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => {
            // setRedireccionarFormulario({ polizasSeguroID, numero });
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: polizasSeguroID,
                pTipo: numero.toString(),
                pClasificacion: "",
                pTabla: "Pólizas de Seguro",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    },
    {
        //Ver endosos 
        accionID: [9],
        icono: EventNoteIcon,
        color: info,
        descripcion: "Ver Endosos",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "ramoSeguroID" }, { campo: "ramoSeguro" }, { campo: "aseguradoraID" }, { campo: "aseguradora" }, { campo: "empresaID" }, { campo: "divisaID" }, { campo: "divisa" }, { campo: "fpPromedio" }, { campo: "exclusiones" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, ramoSeguroID, ramoSeguro, aseguradora, aseguradoraID, empresaID, divisaID, divisa, fpPromedio, exclusiones, folioInterno }) => {
            setOpenVerEndosos(!openVerEndosos);
            setStatePolizaSeguroPrincipalID(polizasSeguroID);
            setActualizarEndosos(!actualizarEndosos);
            setStatePadre(statePadre => ({ ...statePadre, polizaOrigenID: polizasSeguroID, ramoSeguroID: ramoSeguroID, ramoSeguro: ramoSeguro, aseguradoraID: aseguradoraID, aseguradora: aseguradora, empresaID: empresaID, divisaID: divisaID, divisa: divisa, fpPromedio: fpPromedio, exclusiones: exclusiones }));
            setActualizarTipoPoliza(true);
            setDisabledInputs(disabledInputs => ({ ...disabledInputs, polizaOrigen: true, tipoPoliza: false, empresa: true, ramoSeguro: true, aseguradora: true, divisa: true }));
            setStateFolioInterno(folioInterno);

        }
    },
    {
        accionID: [11],
        icono: ReplayIcon,
        color: info,
        descripcion: "Renovar Póliza",
        parametros: [{ campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateRenovar, setOpen: setOpenRenovar, setAlert,
            asyncGet: getPolizasSeguro, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) }
            ]
        })
    },
    {
        accionID: [11],
        icono: ReplayIcon,
        color: primary,
        descripcion: "Renovar Póliza Complementaria",
        parametros: [{ campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateRenovar, setOpen: setOpenRenovarComp, setAlert,
            asyncGet: getPolizasSeguro, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) }
            ]
        })
    },

    {
        accionID: [12],
        id: "verTrazabilidad",
        icono: TrendingFlatIcon,
        color: rose,
        descripcion: "Ver Trazabilidad",
        parametros: [{ campo: "controlPagoID" }, { campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: ({ controlPagoID, polizasSeguroID }) => {

            setDatosDetalle({

                pSolSegFianID: 0,
                pPolizasSeguroID: polizasSeguroID,
                pControlPagoID: controlPagoID,
                plUtilizaProps: true,
                pOrigen: "Poliza"
            });
            setOpenTrazabilidad(true);
        }
    },
    {
        icono: MonetizationOnIcon,
        color: danger,
        descripcion: "Recalcular Importes",
        parametros: [{ campo: "polizasSeguroID" },
        { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => handelAlertConfirmacion({
            setAlert,
            alertTitle: "Recalcular Importes",
            descripcionMensaje: `¿Deseas recalcular importes de la póliza del numero ${numero}?`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEjecutaMetodo({
                token, setAlert, setActualizar, actualizar,
                parametros: { polizasSeguroID }, asyncDelete: recalcularImportesPoliza
            })
        })
    },
    {
        /*   accionID: [8], */
        icono: AccountBalanceWalletIcon,
        color: success,
        descripcion: "Generar Control de pagos desde poliza",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "empresaID" }, { campo: "aseguradoraID" }, { campo: "ramoSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, empresaID, aseguradoraID, ramoSeguroID }) => {
            VerControldePagos({ polizasSeguroID, empresaID, aseguradoraID, ramoSeguroID });
            // setState({...state,polizasSeguroID,empresaID,aseguradoraID,ramoSeguroID});
        }
    },
    ];

    const accionesEndosos = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen: setOpenSubModal, setAlert,
            asyncGet: getPolizasSeguro, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) },
                { id: "vigenciaIni", formato: (data) => moment(data) },
                { id: "vigenciaHasta", formato: (data) => moment(data) }
            ]
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "polizasSeguroID" },
        { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la poliza del numero ${numero}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizarEndosos, actualizarEndosos,
                parametros: { polizasSeguroID }, asyncDelete: deletePolizasSeguro
            })
        })
    },
    {
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero)
    },
    {
        icono: CommuteIcon,
        color: info,
        descripcion: "Ver activos",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "empresaID" }, { campo: "folioInterno" }, { campo: "ramoSeguroID" }, { campo: 'fpPromedio' }, {campo: "subPolizaID"}],
        disabled: { multiseleccion: false },

        onClick: ({ polizasSeguroID, empresaID, folioInterno, ramoSeguroID, subPolizaID: subPolizaRegistro }) => {
            if (subPolizaID !== subPolizaRegistro && openVerActivos) {
                setStatePolizaSeguroID(null);
                setActualizarActivos(!actualizarActivos)
                setStateEmpresaID(empresaID);
                setStateFolioInterno(folioInterno);
                setStateRamoSeguroID(ramoSeguroID);
                setStateActivos({ ...stateActivos, ramoSeguroID });
                UbicacionesXEmpresa(empresaID);
            }
        },
        disabled: { multiseleccion: true }
    },
    {
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => {
            // setRedireccionarFormulario({ polizasSeguroID, numero });
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: polizasSeguroID,
                pTipo: numero.toString(),
                pClasificacion: "",
                pTabla: "Pólizas de Seguro(Endosos)",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    }
    ];

    const accionesDetalle = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "polSegDetalleID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateDetalle, setOpen: setOpenDetalle, setAlert,
            asyncGet: getPolizasSeguroDetalle
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "polSegDetalleID" }],
        disabled: { multiseleccion: true },
        onClick: ({ polSegDetalleID }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la clausula de la poliza `,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizarDetalle, actualizarDetalle,
                parametros: { polSegDetalleID }, asyncDelete: deletePolizasSeguroDetalle
            })
        })
    },
    {
        icono: RiFileExcel2Fill,
        color: success,
        descripcion: "Generar Excel Endoso",
        parametros: [{ campo: "polizaSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizaSeguroID, numero }) => AuxDescargar(polizaSeguroID, numero, 'excelEndoso')
    }];

    const accionesActivos = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "infoActAsegID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateActivos, setOpen: setOpenActivos, setAlert,
            asyncGet: ConsultasActivos.getInfoActAsegPolizas
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "infoActAsegID" }],
        disabled: { multiseleccion: true },
        onClick: ({ infoActAsegID }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el activo de la poliza `,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar: setActualizarActivos, actualizar: actualizarActivos,
                parametros: { infoActAsegID }, asyncDelete: ConsultasActivos.deleteInfoActAsegPolizas
            })
        })
    },];

    function AuxDescargar(polizasSeguroID, stateFolioInterno, formatoReporte = 'PDF', empresaID, ramoSeguro, ramoSeguroID, subRamoSeguroID ) {

        setdatosPDf({ "polizasSeguroID": polizasSeguroID, "stateFolioInterno": stateFolioInterno, formatoReporte, empresaID, ramoSeguro, ramoSeguroID, subRamoSeguroID });
        datosPDF = { "polizasSeguroID": polizasSeguroID, "stateFolioInterno": stateFolioInterno, formatoReporte, empresaID, ramoSeguro, ramoSeguroID, subRamoSeguroID };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de poliza de seguro?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de poliza de seguro ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de poliza de seguro ' + stateFolioInterno, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("polizasSeguroID", datosPDF.polizasSeguroID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);
        formdata.append("empresaID", datosPDF.empresaID);
        formdata.append("ramoSeguro", datosPDF.ramoSeguro);
        formdata.append('subPolizaID', subPolizaID);
        formdata.append("ramoSeguroID", datosPDF.ramoSeguroID);
        formdata.append("subRamoSeguroID", datosPDF.subRamoSeguroID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReportePolizasSeguro' + datosPDF.stateFolioInterno

        await fetch(`${urlServidor}/service/reportes/ReportePolizasSeguro`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`);// mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => { setOpen(true); divisas() },
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }, {
            icono: MenuBookIcon,
            color: success,
            descripcion: "Polizas y endosos",
            onClick: () => { GenerarPolizasEndosos() },
            disabled: { multiseleccion: true }
        }];


    async function GenerarPolizasEndosos() {

        let filtros = {};
        Object.keys(filtrosAplicados).forEach((key, index) => {
            let aux = Object.values(filtrosAplicados);
            filtros[key] = aux[index] == 'null' ? null : parseInt(aux[index]);
        })
        filtros.tipoPoliza = 'PRINCIPAL';
        filtros.fianza = false;
        filtros.pagina = 1;
        filtros.limite = 100000;
        let encabezadosPolizas = await tablaPolizasSeguro({ filtro: { ...filtros, ...filtrosTexto } }, token);

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Desea generar el reporte de pólizas?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: { encabezadosPolizas }, asyncMutation: () => { GenerarPDFPolizasEndosos(encabezadosPolizas) },
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el reporte de pólizas ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: '', msjConfirmacion: "Aceptar"
                    }
                }
            })
        })

    }

    async function GenerarPDFPolizasEndosos(data) {


        let dataCompleta = [];

        for (let i = 0; i < data.listado.length; i++) {

            let endosos = await getAllPolizasSeguro({ filtro: { polizaOrigenID: data.listado[i].polizasSeguroID } }, token)
            dataCompleta.push({
                polizaPrincipal: data.listado[i],
                endosos
            })

        }

        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append('data', JSON.stringify(dataCompleta));

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/service/reportes/ReportePolizasEndosos`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                showFile(result, `Polizas y endosos ${moment().format('l')}.xlsx`);
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }


    const botonesEndosos = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {
                setState(state => ({ ...state, polizaOrigenID: statePadre.polizasSeguroID, ramoSeguroID: statePadre.ramoSeguroID, ramoSeguro: statePadre.ramoSeguro, aseguradoraID: statePadre.aseguradoraID, aseguradora: statePadre.aseguradora, empresaID: statePadre.empresaID, divisaID: statePadre.divisaID, divisa: statePadre.divisa, fpPromedio: statePadre.fpPromedio }));
                setOpenSubModal(true);
            },
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarEndosos(!actualizarEndosos),
            disabled: { multiseleccion: true }
        }];

    const botonesDetalle = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpenDetalle(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarDetalle(!actualizarDetalle),
            disabled: { multiseleccion: true }
        }];

    const botonesActivos = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => { AgregarActivo(true); setStateActivos((stateActivos) => ({ ...stateActivos, fPrima: fPrima, ramoSeguroID: infoActivos.datosSubpoliza.ramoSeguroID })); },
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarActivos(!actualizarActivos),
            disabled: { multiseleccion: true }
        },
        {
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            disabled: { multiseleccion: true },
            onClick: () => AuxDescargar(statePolizaSeguroID, stateFolioInterno, 'excelActivos', stateEmpresaID, stateRamoSeguro, stateRamoSeguroID, stateSubRamoSeguroID )
        }];

    function AgregarActivo() {
        setOpenActivos(true);
        setStateActivos({ ...stateActivos, ramoSeguroID: infoActivos.datosSubpoliza.ramoSeguroID });
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "polizasSeguroID",
        color: primary,
        title: "Pólizas de Seguro",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "numero", descripcion: "Número" },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "polizaOrigenID", descripcion: "polizaOrigenID", hide: true },
            { variable: "nombre", descripcion: "Empresa" },
            { variable: "ramoSeguroID", descripcion: "ramoSeguroID", hide: true },
            { variable: "ramoSeguro", descripcion: "Ramo seguro" },
            { variable: "aseguradoraID", descripcion: "aseguradoraID", hide: true },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "numpolEndoso", descripcion: "Póliza Endoso" },
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "sumaAseg", descripcion: "Suma Asegurada" },
            { variable: "primaNeta", descripcion: "Prima Neta" },
            { variable: "fpPromedio", descripcion: "F.P. Promedio" },
            { variable: "exclusiones", descripcion: "Exclusiones", hide: true },
            { variable: "fechaSolCot", descripcion: "Fecha Sol. Cot." },
            { variable: "vigenciaIni", descripcion: "Vigencia Inicial" },
            { variable: "vigenciaHasta", descripcion: "Vigencia Hasta" },
            { variable: "renovacion", descripcion: "Renovar al Termino" },
            { variable: "divisaID", descripcion: "divisaID", hide: true },
            { variable: "folioInternoControlPago", descripcion: "Control de Pago" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación", hide: false },
            { variable: "beneficiarioPreferente", descripcion: " Beneficiario Preferente", hide: false },
            { variable: "bienesAmparadosEncabezado", descripcion: "Bienes Amparados", hide: false },
            { variable: "polizaRenovada", descripcion: "Póliza Renovada", hide: false },
            { variable: "folioPolizaRenovada", descripcion: "Folio Póliza Renovada", hide: false },
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true }
        ],
        responsiveTitle: ["folioInterno", "numpolEndoso", 'ramoSeguro'],
        filter: [
            { campo: "numpolEndoso" },
            { campo: "numero" },
            { campo: "folioInterno" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }, { columnas: [0, 4, 6, 7, 8], alineacion: "right" }],
        formato: [

            {
                columnas: ["fechaSolCot", "vigenciaIni", "vigenciaHasta", "fechaModificacion"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["sumaAseg", "primaNeta","valorFactura"], tipo: "moneda",
            },
            {
                columnas: ["fpPromedio"], tipo: "porcentaje", simbolo: "%"

            },
            {
                columnas: ["renovacion", "polizaRenovada"], tipo: "estatus"

            }
        ],
        selectFilter: [

            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Empresa ? seleccionables.Empresa : [], grid: { md: 3, lg: 3 }
            },
            {
                campo: "aseguradoraID", title: "Aseguradora",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Aseguradora ? seleccionables.Aseguradora : [], grid: { md: 3, lg: 3 }
            },
            {
                campo: "ramoSeguroID", title: "Ramo Seguro",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Ramo ? seleccionables.Ramo : [], grid: { md: 3, lg: 3 }
            }
            ,
            {
                campo: "ejercicio", title: "Ejercicio",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Ejercicios ? seleccionables.Ejercicios : [], grid: { md: 3, lg: 3 }
            }

        ],
        /* rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD")},*/

    }

    const infoTablaEndosos = {
        botones: botonesEndosos,
        acciones: accionesEndosos,
        actualizar: actualizarEndosos,
        id: "polizasSeguroID",
        color: primary,
        title: "Pólizas / Endosos " + (stateFolioInterno === "" || stateFolioInterno === null ? "" : "(" + stateFolioInterno + ")"),
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "numero", descripcion: "Número" },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "polizaOrigenID", descripcion: "polizaOrigenID", hide: true },
            { variable: "nombre", descripcion: "Empresa" },
            { variable: "ramoSeguroID", descripcion: "ramoSeguroID", hide: true },
            { variable: "ramoSeguro", descripcion: "Ramo seguro" },
            { variable: "aseguradoraID", descripcion: "aseguradoraID", hide: true },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "numpolEndoso", descripcion: "Póliza Endoso" },
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "sumaAseg", descripcion: "Suma Asegurada" },
            { variable: "primaNeta", descripcion: "Prima Neta" },
            { variable: "fpPromedio", descripcion: "F.P. Promedio" },
            { variable: "exclusiones", descripcion: "Exclusiones", hide: true },
            { variable: "fechaSolCot", descripcion: "Fecha Sol. Cot." },
            { variable: "vigenciaIni", descripcion: "Vigencia Inicial" },
            { variable: "vigenciaHasta", descripcion: "Vigencia Hasta" },
            { variable: "renovacion", descripcion: "Renovar al Termino" },
            { variable: "polizaRenovada", descripcion: "Endoso Aplicado en Renovación" },

        ],
        responsiveTitle: ["folioInterno", "numpolEndoso"],
        filter: [
            { campo: "numpolEndoso" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }, { columnas: [0, 4, 6, 7, 8], alineacion: "right" }],
        formato: [

            {
                columnas: ["fechaSolCot", "vigenciaIni", "vigenciaHasta"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["sumaAseg", "primaNeta"], tipo: "moneda",
            },
            {
                columnas: ["fpPromedio"], tipo: "porcentaje", simbolo: "%"

            },
            {
                columnas: ["renovacion", "polizaRenovada"], tipo: "estatus"

            }
        ],
    }
    const infoTablaDetalle = {
        botones: botonesDetalle,
        acciones: accionesDetalle,
        actualizar: actualizarDetalle,
        id: "polSegDetalleID",
        color: primary,
        title: "Conceptos de la Póliza " + (stateFolioInterno === "" || stateFolioInterno === null ? "" : "(" + stateFolioInterno + ")"),
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "polSegDetalleID", descripcion: "polSegDetalleID", hide: true },
            { variable: "polizaSeguroID", descripcion: "polizaSeguroID", hide: true },
            { variable: "cobertura", descripcion: "Riesgos Cubiertos" },
            { variable: "deducible", descripcion: "% Deducible" },

            { variable: "maximoMinimo", descripcion: "Maximo y Minimo" },
            { variable: "exclusiones", descripcion: "Exclusiones" },
            { variable: "bienesAmparados", descripcion: "Bienes Amparados" },
            { variable: "tipo", descripcion: "tipo", hide: true },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación", hide: false },
            { variable: "polRenovada", descripcion: "Póliza Renovada", hide: false },
            { variable: "partComplementaria", descripcion: "Es Complemento", hide: false },
            { variable: "fechaComplementaria", descripcion: "Fecha Alta Complemento", hide: false },


        ],
        responsiveTitle: ["deducible"],
        filter: [

            { campo: "cobertura", variable: "cobertura", placeholder: "Capture valor a buscar" },
            { campo: "maximoMinimo", variable: "maximoMinimo", placeholder: "Capture valor a buscar" },
            { campo: "bienesAmparados", variable: "bienesAmparados", placeholder: "Capture valor a buscar" },
            { campo: "exclusiones", variable: "exclusiones", placeholder: "Capture valor a buscar" },
        ],
        alineacion: [{ columnas: [0, 1, 2, 3], alineacion: "left" }],
        formato: [{
            columnas: ["fechaModificacion", "fechaComplementaria"], tipo: "fecha", onlyDate: true
        },
        {
            columnas: ["polRenovada", "partComplementaria"], tipo: "estatus"

        }
        ]

    }

    const infoTablaActivos = {
        botones: botonesActivos,
        acciones: accionesActivos,
        actualizar: actualizarActivos,
        id: "infoActAsegID",
        color: primary,
        title: "Activos de la cobertura " + folioSubpoliza,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "infoActAsegID", descripcion: "infoActAsegID", hide: true },
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "incPol", descripcion: "Inc. Pol.", hide: true },
            { variable: "numATP", descripcion: "No ATP", hide: true },
            { variable: "noEco", descripcion: "No Económico",  hide: (stateRamoSeguroID === 44 || stateRamoSeguroID === 48 || stateRamoSeguroID === 50 || stateSubRamoSeguroID === 44 || stateSubRamoSeguroID === 48 || stateSubRamoSeguroID === 50) ? false : true },
            { variable: "modelo", descripcion: "Modelo", hide: (stateRamoSeguroID === 44 || stateRamoSeguroID === 48 || stateRamoSeguroID === 50 || stateSubRamoSeguroID === 44 || stateSubRamoSeguroID === 48 || stateSubRamoSeguroID === 50) ? false : true },
            { variable: "equipo", descripcion: "Equipo", hide: (stateRamoSeguroID === 44 || stateRamoSeguroID === 48 || stateRamoSeguroID === 50 || stateSubRamoSeguroID === 44 || stateSubRamoSeguroID === 48 || stateSubRamoSeguroID === 50) ? false : true },
            { variable: "marca", descripcion: "Marca", hide: (stateRamoSeguroID === 44 || stateRamoSeguroID === 48 || stateRamoSeguroID === 50 || stateSubRamoSeguroID === 44 || stateSubRamoSeguroID === 48 || stateSubRamoSeguroID === 50) ? false : true },
            { variable: "serie", descripcion: "Serie", hide: (stateRamoSeguroID === 44 || stateRamoSeguroID === 48 || stateRamoSeguroID === 50 || stateSubRamoSeguroID === 44 || stateSubRamoSeguroID === 48 || stateSubRamoSeguroID === 50) ? false : true },
            { variable: "infraestructura", descripcion: "Infraestructura", hide: stateRamoSeguroID === 43 || stateSubRamoSeguroID === 43 ? false : true },
            { variable: "serieCPU", descripcion: "Serie CPU", hide: stateRamoSeguroID === 50 || stateSubRamoSeguroID === 50 ? false : true },
            { variable: "serieMonitor", descripcion: "Serie Monitor", hide: stateRamoSeguroID === 50 || stateSubRamoSeguroID === 50 ? false : true },
            { variable: "moneda", descripcion: "Moneda" },
            { variable: "sumaAseg", descripcion: "Suma Asegurada" },
            { variable: "valorFactura", descripcion: "Valor Factura" },
            { variable: "ubicacion", descripcion: "Ubicación" },
            { variable: "departamento", descripcion: "Departamento" },
            { variable: "EsSubPartida", descripcion: "Subpartida" },
            { variable: "inicioVigencia", descripcion: "Fecha Inicio" },
            { variable: "terminoVigencia", descripcion: "Fecha Fin" },
            { variable: "fechaAlta", descripcion: "Fecha Alta" },
            { variable: "fechaBaja", descripcion: "Fecha Baja" },
            { variable: "estado", descripcion: "Estado" },
            { variable: "observaciones", descripcion: "Observaciones" },
            { variable: "solicitud", descripcion: "Solicitud" },
            { variable: "autoAD", descripcion: "Auto Administrado" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación", hide: false },
            { variable: "polRenovada", descripcion: "Póliza Renovada", hide: false },
            { variable: "partComplementaria", descripcion: "Es Complemento", hide: false },
            { variable: "fechaComplementaria", descripcion: "Fecha Alta Complemento", hide: false },

        ],
        responsiveTitle: ["equipo", "noEco" /*'numATP'*/],
        filter: [
            { campo: "incPol", variable: "incPol", placeholder: "Capture valor a buscar" },
            { campo: "noEco", variable: "noEco", placeholder: "Capture valor a buscar" },
            { campo: "numATP", variable: "numATP", placeholder: "Capture valor a buscar" },
            { campo: "modelo", variable: "modelo", placeholder: "Capture valor a buscar" },
            { campo: "equipo", variable: "equipo", placeholder: "Capture valor a buscar" },
            { campo: "marca", variable: "marca", placeholder: "Capture valor a buscar" },
            { campo: "serie", variable: "serie", placeholder: "Capture valor a buscar" },
            { campo: "infraestructura", variable: "infraestructura", placeholder: "Capture valor a buscar" },
            { campo: "serieCPU", variable: "serieCPU", placeholder: "Capture valor a buscar" },
            { campo: "serieMonitor", variable: "serieMonitor", placeholder: "Capture valor a buscar" },
            { campo: "infraestructura", variable: "infraestructura", placeholder: "Capture valor a buscar" },
            { campo: "moneda", variable: "moneda", placeholder: "Capture valor a buscar" },
            { campo: "sumaAseg", variable: "sumaAseg", placeholder: "Capture valor a buscar" },
            { campo: "ubicacion", variable: "ubicacion", placeholder: "Capture valor a buscar" },
            { campo: "departamento", variable: "departamento", placeholder: "Capture valor a buscar" },

        ],
        formato: [
            {
                columnas: ["sumaAseg","valorFactura"], tipo: "moneda",
            },

            {
                columnas: ["inicioVigencia", "terminoVigencia", "fechaAlta", "fechaBaja", "fechaModificacion", "fechaComplementaria"], tipo: "fecha", onlyDate: true
            },
            { columnas: ["autoAD", "polRenovada", "partComplementaria"], tipo: "estatus" }

        ],
        alineacion: [{ columnas: [0, 1, 2, 3, 4, 5, 6, 7], alineacion: "left" }],
    }

    const inputs = [,
        {
            hidden: openSubModal ? true : false,
            id: "cTopAPDS",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: state.polizasSeguroID ? true : false,
                    id: "seleccionTipoPoliza",
                    value: state.seleccionTipoPoliza,
                    error: errorState.seleccionTipoPoliza.error,
                    success: state.seleccionTipoPoliza && !errorState.seleccionTipoPoliza.error ? true : undefined,
                    helperText: errorState.seleccionTipoPoliza.helperText,
                    inputRef: seleccionTipoPolizaRef,
                    title: "Tipo de poliza *",
                    placeholder: "Seleccione el tipo de póliza",
                    tipo: "autocomplete",
                    data: [{ label: 'Sencilla', value: 1 }, { label: 'Multiple', value: 2 }],
                    grid: { md: 12, lg: 12 },
                    onChange: ({ value }) => {
                        setState({ ...state, seleccionTipoPoliza: value })
                    },
                }
            ]
        },
        {
            id: "cInputsFormulario",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: ftSeleccionInputsPDS(state.seleccionTipoPoliza)
        }

    ];

    const inputsDetalle = [

        {
            disabled,
            id: "cobertura",
            multiline: true,
            value: stateDetalle.cobertura,
            error: errorStateDetalle.cobertura.error,
            success: stateDetalle.cobertura && !errorStateDetalle.cobertura.error ? true : undefined,
            helperText: errorStateDetalle.cobertura.helperText,
            inputRef: DetCoberturaRef,
            title: "Cobertura *",
            placeholder: "Capture la cobertura",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "cobertura")
            }
        }, {
            disabled,
            id: "deducible",
            multiline: true,
            value: stateDetalle.deducible,
            error: errorStateDetalle.deducible.error,
            success: stateDetalle.deducible && !errorStateDetalle.deducible.error ? true : undefined,
            helperText: errorStateDetalle.deducible.helperText,
            inputRef: DetDeducibleRef,
            title: "Deducible *",
            placeholder: "Capture el Deducible",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "deducible")
            }
        }
        ,
        {
            disabled,
            id: "maximoMinimo",
            multiline: true,
            value: stateDetalle.maximoMinimo,
            error: errorStateDetalle.maximoMinimo.error,
            success: stateDetalle.maximoMinimo && !errorStateDetalle.maximoMinimo.error ? true : undefined,
            helperText: errorStateDetalle.maximoMinimo.helperText,
            inputRef: DetMaximoMinimoRef,
            title: "Maximo y Minimo *",
            placeholder: "Capture el maximo y minimo",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "maximoMinimo")
            }
        },
        {
            disabled,
            id: "exclusiones",
            multiline: true,
            value: stateDetalle.exclusiones,
            error: errorStateDetalle.exclusiones.error,
            success: stateDetalle.exclusiones && !errorStateDetalle.exclusiones.error ? true : undefined,
            helperText: errorStateDetalle.exclusiones.helperText,
            inputRef: DetExclusionesRef,
            title: "Exclusiones *",
            placeholder: "Capture las exclusiones",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "exclusiones")
            }
        },
        {
            disabled,
            id: "bienesAmparados",
            multiline: true,
            value: stateDetalle.bienesAmparados,
            error: errorStateDetalle.bienesAmparados.error,
            success: stateDetalle.bienesAmparados && !errorStateDetalle.bienesAmparados.error ? true : undefined,
            helperText: errorStateDetalle.bienesAmparados.helperText,
            inputRef: DetBienesAmparadosRef,
            title: "Bienes Amparados *",
            placeholder: "Capture los Bienes Amparados",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "bienesAmparados")
            }
        }
    ];

    const inputsRenovar = [

        {
            disabled,
            id: "fechaSolCot",
            value: stateRenovar.fechaSolCot,
            error: errorStateRenovar.fechaSolCot.error,
            success: stateRenovar.fechaSolCot && !errorStateRenovar.fechaSolCot.error ? true : undefined,
            helperText: errorStateRenovar.fechaSolCot.helperText,
            inputRef: renFechaSolCotRef,
            title: "Fecha Sol. Cotización.",
            placeholder: "Fecha de Solicitud",
            onlyDate: true,
            tipo: "datetimepicker",
            grid: { md: 4, lg: 4 },
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChangeRenovar(value, "fechaSolCot")
            },

        }


    ];

    async function GenerarControlPag(parametros) {
        try {
            let result = await generarControlPagxPoliza({ polizasSeguroID: parametros.polizasSeguroID }, token);
            result = JSON.parse(result);

            setActualizar(!actualizar)

            setAlert({
                descripcion: result.mensaje,
                title: "Generar control de pago",
                tipo: result.Resultado === 1 ? "success-two" : warning,
                msjConfirmacion: "Abrir control de pago",
                onConfirm: () => RedirigirControlPago(result["controlPagoID"]),
                showConfirm: true,
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    function RedirigirControlPago(controlPagoID) {
        setAlert(null);
        let path = '/';
        path = `/gen/ControlPagos`;
        history.push(path, { controlPagoID });
    }

    async function handleChangeDivisa(value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: { divisaID: value } }, token);


        setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
    }


    const inputsActivos = [];
    const InputsConIDActivos = [];
    const SeccionesActivos = [];
    let ArrayRefActivos = [];

    cleanErrorState["reporteDaniosID"] = { error: false, helperText: "" };
    cleanState["infoActAsegID"] = 0;
    cleanState["empresaID"] = 0;
    cleanState["solSegFianID"] = 0;

    InicializarInputsActivos("incPol", "Inc. Pol.", "", "", "", 20, "left");
    InicializarInputsActivos("noEco", "No. Económico", "", "", "", 0, "left");
    //InicializarInputsActivos("numATP", "No. Económico", "", "", "", 0, "left");
    InicializarInputsActivos("equipo", "Equipo", "", "", "", 250, "left");
    InicializarInputsActivos("activoAsegID", "Activo Asegurado", "", 0, "", 0, "left");
    InicializarInputsActivos("modelo", "Modelo", "", "", "", 150, "left");
    InicializarInputsActivos("marca", "Marca", "", "", "", 250, "left");
    InicializarInputsActivos("serie", "Serie", "", "", "", 250, "left");
    InicializarInputsActivos("serieMonitor", "Serie Monitor", "", "", "", 100, "left");
    InicializarInputsActivos("serieCPU", "Serie CPU", "", "", "", 100, "left");
    InicializarInputsActivos("anio", "Año", "", "", "", 4, "left");
    InicializarInputsActivos("capacidad", "Capacidad", "", 0, "", 0, "right");
    InicializarInputsActivos("uniCapacidad", "Unidad de Capacidad", "", "", "", 250, "left");
    InicializarInputsActivos("valorM2", "Valor M2 (oficina/almacén)", "", 0, "", 0, "right");
    InicializarInputsActivos("valorAseg", "Valor Asegurado", "", 0, "", 0, "right");
    InicializarInputsActivos("ramoSeguroID", "Cobertura", "", "", "", 0, "left");
    InicializarInputsActivos("tipoTransmision", "Tipo Transmision", "", "NO APLICA", "", 150, "left");
    InicializarInputsActivos("ubicacionID", "Ubicación", "", "", "", 0, "left");
    InicializarInputsActivos("grupoID", "Grupo", "", "0", "", 250, "left");
    InicializarInputsActivos("bienBajoConvenio", "Bien Bajo Convenio", "", "", "", 250, "left");
    InicializarInputsActivos("descripcion", "Descripción", "", "", "", 250, "left");
    InicializarInputsActivos("infraestructura", "Infraestructura", "", "", "", 250, "left");
    InicializarInputsActivos("activoID", "Activo", "", "0", "", 250, "left");
    InicializarInputsActivos("departamentoID", "Departamento", "", "0", "", 250, "left");
    InicializarInputsActivos("areaM2", "Área M2", "", "", "", 250, "left");
    InicializarInputsActivos("tonelaje", "Tonelaje", "", "", "", 250, "left");
    InicializarInputsActivos("voltaje", "Voltaje", "", "", "", 250, "left");
    InicializarInputsActivos("dimensiones", "Dimensiones", "", "", "", 250, "left");
    InicializarInputsActivos("valorFactura", "Valor Factura", "", 0, "", 250, "left");
    InicializarInputsActivos("moneda", "Moneda", "", "MXN", "", 5, "left");
    InicializarInputsActivos("paridad", "Paridad", "", "1", "", 5, "left");
    InicializarInputsActivos("sumaAseg", "Suma asegurada", "", 0, "", 250, "left");
    InicializarInputsActivos("fPrima", "F. prima", "", stateActivos.fPrima, "", 0, "left");
    InicializarInputsActivos("primaNeta", "Prima neta", "", 0, "", 0, "left");
    InicializarInputsActivos("primaNetaTotal", "Prima Total", "", 0, "", 0, "left");
    InicializarInputsActivos("valorReposicion", "Valor de reposición", "", 0, "", 0, "left");
    InicializarInputsActivos("inicioVigencia", "Inicio de vigencia", "", new Date(), "", 0, "left");
    InicializarInputsActivos("terminoVigencia", "Termino de vigencia", "", new Date(), "", 0, "left");
    InicializarInputsActivos("propietarioID", "Propietario", "", "", "", 0, "left");
    InicializarInputsActivos("clasificacion", "Clasificación", "", "", "", 250, "left");
    InicializarInputsActivos("coberturasAdicionales", "Coberturas adicionales", "", "", "", 250, "left");
    InicializarInputsActivos("ubicacion", "Ubicación Exacta", "", "", "", 100, "left");
    InicializarInputsActivos("usuario", "Usuario", "", "", "", 100, "left");
    InicializarInputsActivos("responsable", "Responsable(*)", "", "", "", 100, "left");
    InicializarInputsActivos("departamento", "Departamento", "", "", "", 100, "left");
    InicializarInputsActivos("subPartida", "Componente", "", 0, "", 250, "left"); // antes Subpartida
    InicializarInputsActivos("fechaAlta", "Fecha Alta", "", new Date(), "", 0, "left");
    InicializarInputsActivos("fechaBaja", "Fecha Baja", "", new Date(), "", 100, "left");
    InicializarInputsActivos("estado", "Estado", "", "ACTIVO", "", 150, "left");
    InicializarInputsActivos("observaciones", "Observaciones", "", "", "", 2000, "left");
    InicializarInputsActivos("sustituyeID", "Sustituye al Activo", "", "0", "", 250, "left");
    InicializarInputsActivos("solicitud", "Solicitud", "", "Alta de Seguro", "", 5, "left");
    InicializarInputsActivos("omitir", "Omitir monto en pólizas", "", 0);

    //------------------------------------------------------------------
    InputsConIDActivos["incPol"].grid = { md: 2, lg: 2 };
    InputsConIDActivos["noEco"].grid = { md: 2, lg: 2 };
    //InputsConIDActivos["numATP"].grid = { md: 2, lg: 2 };
    InputsConIDActivos["activoAsegID"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["equipo"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["modelo"].grid = { md: 4, lg: 4 };
    //------------------------------------------------------------------
    InputsConIDActivos["marca"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["serie"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["serieMonitor"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["serieCPU"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["anio"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["capacidad"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["uniCapacidad"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["valorM2"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["valorAseg"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["grupoID"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["bienBajoConvenio"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["descripcion"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["infraestructura"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["activoID"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["departamentoID"].grid = { md: 4, lg: 4 };    

    InputsConIDActivos["areaM2"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["tonelaje"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["voltaje"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["dimensiones"].grid = { md: 3, lg: 3 };

    InputsConIDActivos["ramoSeguroID"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["moneda"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["paridad"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["sustituyeID"].grid = { md: 6, lg: 6 };
    InputsConIDActivos["solicitud"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["omitir"].grid = { md: 3, lg: 3 };
    //------------------------------------------------------------------
    InputsConIDActivos["tipoTransmision"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["valorFactura"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["sumaAseg"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["fPrima"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["primaNeta"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["primaNetaTotal"].grid = { md: 3, lg: 3 };
    //------------------------------------------------------------------
    InputsConIDActivos["inicioVigencia"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["terminoVigencia"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["propietarioID"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["ubicacionID"].grid = { md: 4, lg: 4 };
    //------------------------------------------------------------------
    InputsConIDActivos["valorReposicion"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["clasificacion"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["coberturasAdicionales"].grid = { md: 4, lg: 4 };
    //------------------------------------------------------------------
    InputsConIDActivos["ubicacion"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["usuario"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["responsable"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["departamento"].grid = { md: 4, lg: 4 };
    InputsConIDActivos["subPartida"].grid = { md: 3, lg: 3 };
    //------------------------------------------------------------------
    InputsConIDActivos["subPartida"].tipo = "switch";
    InputsConIDActivos["subPartida"].value = stateActivos["subPartida"];
    InputsConIDActivos["subPartida"].checked = (stateActivos["subPartida"] === 0 ? false : true);
    InputsConIDActivos["subPartida"].onChange = ({ target }) => setStateActivos({ ...stateActivos, ["subPartida"]: target.checked ? 1 : 0 });
    //---------------------------------------------------------------------
    InputsConIDActivos["fechaAlta"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["fechaBaja"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["estado"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["observaciones"].grid = { md: 12, lg: 12 };
    InputsConIDActivos["observaciones"].multiline = true;

    InputsConIDActivos["ramoSeguroID"].placeholder = "Seleccione un ramo";
    InputsConIDActivos["ramoSeguroID"].tipo = "select";
    InputsConIDActivos["ramoSeguroID"].data = seleccionables.RamosCoberturas;
    InputsConIDActivos["ramoSeguroID"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "ramoSeguroID", 0);
    };
    InputsConIDActivos["ramoSeguroID"].disabled = true;

    InputsConIDActivos["grupoID"].placeholder = "Seleccione un Grupo";
    InputsConIDActivos["grupoID"].tipo = "autocomplete";
    InputsConIDActivos["grupoID"].data = seleccionables.grupos;
    InputsConIDActivos["grupoID"].onChange = (target) => {
       target.value &&  handleChangeActivos(target.value, "grupoID", 10);
    };

    InputsConIDActivos["activoID"].placeholder = "Seleccione un Activo";
    InputsConIDActivos["activoID"].tipo = "autocomplete";
    InputsConIDActivos["activoID"].data = seleccionables.activosSolicitud;
    InputsConIDActivos["activoID"].onChange = (target) => {
       target.value &&  handleChangeActivos(target.value, "activoID", 10);
    };

    InputsConIDActivos["departamentoID"].placeholder = "Seleccione un Departamento";
    InputsConIDActivos["departamentoID"].tipo = "autocomplete";
    InputsConIDActivos["departamentoID"].data = seleccionables.departamentos;
    InputsConIDActivos["departamentoID"].onChange = (target) => {
       target.value &&  handleChangeActivos(target.value, "departamentoID", 10);
    };

    InputsConIDActivos["propietarioID"].placeholder = "Seleccione el propietario";
    InputsConIDActivos["propietarioID"].tipo = "select";
    InputsConIDActivos["propietarioID"].data = seleccionables.Empresa;
    InputsConIDActivos["propietarioID"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "propietarioID", 0);
    };

    InputsConIDActivos["activoAsegID"].disabled = ( (!stateActivos.infoActAsegID ) ? false : true);
    InputsConIDActivos["activoAsegID"].placeholder = "Seleccione el Activo Asegurado";
    InputsConIDActivos["activoAsegID"].tipo = "select";
    InputsConIDActivos["activoAsegID"].data = stActivosAsegurado;
    InputsConIDActivos["activoAsegID"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "activoAsegID", 0);
    };

    InputsConIDActivos["tipoTransmision"].disabled = (stateRamoSeguroID === "1" ? false : true);
    InputsConIDActivos["tipoTransmision"].placeholder = "Seleccione la transmision";
    InputsConIDActivos["tipoTransmision"].tipo = "select";
    InputsConIDActivos["tipoTransmision"].data = [
        {
            label: 'NO APLICA',
            value: 'NO APLICA'
        },
        {
            label: 'STD',
            value: 'STD'
        },
        {
            label: 'AUT',
            value: 'AUT'
        }];

    InputsConIDActivos["tipoTransmision"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "tipoTransmision", 0);
    };

    InputsConIDActivos["moneda"].placeholder = "Seleccione una moneda";
    InputsConIDActivos["moneda"].tipo = "select";
    InputsConIDActivos["moneda"].data = [{
        label: 'MXN',
        value: 'MXN'
    },
    {
        label: 'USD',
        value: 'USD'
    }];
    InputsConIDActivos["moneda"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "moneda", 0);
    };

    InputsConIDActivos["inicioVigencia"].tipo = "datetimepicker";
    InputsConIDActivos["inicioVigencia"].timeFormat = false;
    InputsConIDActivos["inicioVigencia"].onlyDate = true;
    InputsConIDActivos["inicioVigencia"].onChange = (value) => { handleChangeActivos(typeof value !== "object" ? "" : value, "inicioVigencia") };

    InputsConIDActivos["terminoVigencia"].tipo = "datetimepicker";
    InputsConIDActivos["terminoVigencia"].timeFormat = false;
    InputsConIDActivos["terminoVigencia"].onlyDate = true;
    InputsConIDActivos["terminoVigencia"].onChange = (value) => { handleChangeActivos(typeof value !== "object" ? "" : value, "terminoVigencia"); };

    InputsConIDActivos["valorFactura"].textAlign = "right";
    InputsConIDActivos["valorFactura"].icono = <IconoSimbolo simbolo="$" />;
    InputsConIDActivos["valorFactura"].posicionIcono = "start";
    InputsConIDActivos["valorFactura"].formatoImportes = true;

    InputsConIDActivos["sumaAseg"].disabled = stateActivos.estado === "INACTIVO" ? true : false;
    InputsConIDActivos["sumaAseg"].textAlign = "right";
    InputsConIDActivos["sumaAseg"].icono = <IconoSimbolo simbolo="$" />;
    InputsConIDActivos["sumaAseg"].posicionIcono = "start";
    InputsConIDActivos["sumaAseg"].formatoImportes = true;

    InputsConIDActivos["fPrima"].disabled = stateActivos.estado === "INACTIVO" ? true : false;
    InputsConIDActivos["fPrima"].textAlign = "right";
    InputsConIDActivos["fPrima"].icono = <IconoSimbolo simbolo="%" />;
    InputsConIDActivos["fPrima"].posicionIcono = "end";

    InputsConIDActivos["primaNeta"].disabled = stateActivos.estado === "INACTIVO" ? true : false;
    InputsConIDActivos["primaNeta"].textAlign = "right";
    InputsConIDActivos["primaNeta"].icono = <IconoSimbolo simbolo="$" />;
    InputsConIDActivos["primaNeta"].posicionIcono = "start";
    InputsConIDActivos["primaNeta"].formatoImportes = true;

    InputsConIDActivos["valorReposicion"].textAlign = "right";
    InputsConIDActivos["valorReposicion"].icono = <IconoSimbolo simbolo="$" />;
    InputsConIDActivos["valorReposicion"].posicionIcono = "start";
    InputsConIDActivos["valorReposicion"].formatoImportes = true;

    InputsConIDActivos["valorAseg"].textAlign = "right";
    InputsConIDActivos["valorAseg"].icono = <IconoSimbolo simbolo="$" />;
    InputsConIDActivos["valorAseg"].posicionIcono = "start";
    InputsConIDActivos["valorAseg"].formatoImportes = true;

    InputsConIDActivos["ubicacionID"].tipo = "autocomplete";
    InputsConIDActivos["ubicacionID"].data = seleccionables.Ubicaciones;
    InputsConIDActivos["ubicacionID"].onChange = (target) => {
        target ? handleChangeActivos(target.value, "ubicacionID", 0) : handleChangeActivos("", "ubicacionID", 0);
    };
    InputsConIDActivos["fechaAlta"].tipo = "datetimepicker";
    InputsConIDActivos["fechaAlta"].timeFormat = false;
    InputsConIDActivos["fechaAlta"].onlyDate = true;
    InputsConIDActivos["fechaAlta"].onChange = (value) => { handleChangeActivos(typeof value !== "object" ? "" : value, "fechaAlta"); };
    InputsConIDActivos["fechaBaja"].tipo = "datetimepicker";
    InputsConIDActivos["fechaBaja"].timeFormat = false;
    InputsConIDActivos["fechaBaja"].onlyDate = true;
    InputsConIDActivos["fechaBaja"].onChange = (value) => { handleChangeActivos(typeof value !== "object" ? "" : value, "fechaBaja"); };

    InputsConIDActivos["estado"].placeholder = "Seleccione un estado";
    InputsConIDActivos["estado"].tipo = "select";
    InputsConIDActivos["estado"].data = [{
        label: 'ACTIVO',
        value: 'ACTIVO'
    },
    {
        label: 'INACTIVO',
        value: 'INACTIVO'
    }];
    InputsConIDActivos["estado"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "estado", 0);
    };
    InputsConIDActivos["sustituyeID"].placeholder = "Seleccione un activo a sustituir";
    InputsConIDActivos["sustituyeID"].tipo = "select";
    InputsConIDActivos["sustituyeID"].data = seleccionables.Activos;
    InputsConIDActivos["sustituyeID"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "sustituyeID", 0);
    };
    InputsConIDActivos["sustituyeID"].disabled = disabled ? disabled : disabledInputs.sustituye;
    InputsConIDActivos["sustituyeID"].grid = { md: 6, lg: 6 };

    InputsConIDActivos["solicitud"].placeholder = "Solicitud";
    InputsConIDActivos["solicitud"].tipo = "select";
    InputsConIDActivos["solicitud"].tipo = "select";
    InputsConIDActivos["solicitud"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["solicitud"].data = [{
        label: 'Alta de Seguro',
        value: 'Alta de Seguro'
    },
    {
        label: 'Baja de Seguro',
        value: 'Baja de Seguro'
    }];
    InputsConIDActivos["solicitud"].onChange = ({ target: { value } }) => {
        handleChangeActivos(value, "solicitud", 0);
    };

    InputsConIDActivos["omitir"].tipo = "switch";
    InputsConIDActivos["omitir"].grid = { md: 3, lg: 3 };
    InputsConIDActivos["omitir"].placeholder = "Omitir";
    InputsConIDActivos["omitir"].checked = (stateActivos["omitir"] === 1 ? true : false);
    InputsConIDActivos["omitir"].onChange = (e) => {
        setStateActivos({ ...stateActivos, omitir: (e.target.checked ? 1 : 0) })
    };
    InputsConIDActivos["omitir"].disabled = true;


    let accionesFormulario = [{
        accionID: state.polizasSeguroID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: numpolEndosoRef,
            mensajeCorrecto: `La subpoliza se ${state.polizasSeguroID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const accionesFormularioRenovar = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseRenovar,
            handleFocus,
            refFocus: renFechaSolCotRef,
            mensajeCorrecto: `La poliza se renovo correctamente`,
            asyncCallback: ftRenovarPoliza,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseRenovar
    }];

    const accionesFormularioRenovarComp = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseRenovarComp,
            handleFocus,
            refFocus: renFechaSolCotRef,
            mensajeCorrecto: `La poliza se renovo correctamente`,
            asyncCallback: ftRenovarPolizaComplementaria,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseRenovarComp
    }];



    const accionesFormularioEndosos = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarEndosos,
            actualizar: actualizarEndosos,
            setNotificaciones,
            handleClose: handleCloseSubModal,
            handleFocus,
            refFocus: numpolEndosoRef,
            mensajeCorrecto: `La poliza se ${state.polizasSeguroID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardarEndoso,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseSubModal
    }];

    const accionesFormularioDetalle = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarDetalle,
            actualizar: actualizarDetalle,
            notificacionesDetalle: setNotificaciones,
            handleClose: handleCloseDetalle,
            handleFocus,
            refFocus: DetDeducibleRef,
            mensajeCorrecto: `El concepto de la poliza se ${stateDetalle.polSegDetalleID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardarDetalle,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseDetalle
    }];

    const accionesFormularioActivos = [{
        loader: loaderActivos,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader: setLoaderActivos,
            setDisabled,
            setActualizar: setActualizarActivos,
            actualizar: actualizarActivos,
            setNotificaciones: setNotificacionesActivos,
            handleClose: handleCloseActivos,
            handleFocus,
            refFocus: ArrayRefActivos["noEco"],
            //refFocus: ArrayRefActivos["numATP"],
            mensajeCorrecto: `El activo asegurado de la cobertura se ${stateActivos.infoActAsegID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardarActivos,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseActivos
    }];

    async function ObtenerActivosAsegurados() {
        let Respuesta = await seleccionActivosAseguradoClasificacion({ filtro: { clasificacion: null } }, token);
        setActivosAsegurado(Respuesta);
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftErrorInputTextDetalle({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState: cleanErrorStateDetalle, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorStateDetalle
        });
    }

    function ftErrorInputTextActivos({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState: cleanErrorStateActivos, condicion, ref, keyError, mensajeError,
            loader: setLoaderActivos, disabled: setDisabled, errorState: setErrorStateActivos
        });
    }
    function setRedireccionarTrazabilidad({ controlPagoID, polizasSeguroID }) {
        let seed = "scrumweb";
        const datos = `{ "pSolSegFianID": "${0}", "pControlPagoID": "${controlPagoID}","pPolizasSeguroID": "${polizasSeguroID}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");

        history.push(`/gen/TrazabilidadSolicitudesSeguro/${url}`);

    }


    function handleValidaciones({ empresaID, ramoSeguroID, aseguradoraID, divisaID, fechaSolCot, numpolEndoso,
        vigenciaIni, vigenciaHasta, exclusiones, horasInvertidas, bienesAmparadosEncabezado, beneficiarioPreferente, derechoPoliza, renovacionObs, renovacion }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(empresaID) || empresaID == 0,
                keyError: "empresaID",
                mensajeError: "Seleccione una empresa"
            },
            {
                condicion: !rgxNumeros.test(ramoSeguroID),
                keyError: "ramoSeguroID",
                mensajeError: "Seleccione un ramo de seguro"
            },
            {
                condicion: !rgxNumeros.test(aseguradoraID),
                keyError: "aseguradoraID",
                mensajeError: "Seleccione una aseguradora"
            },
            /*             {
                            condicion: !derechoPoliza || derechoPoliza === "",
                            ref: derechoPolizaRef, keyError: "derechoPoliza",
                            mensajeError: "Debe especificar el derecho de póliza"
                        }, */
            {
                condicion: !divisaID || divisaID === "",
                ref: divisaIDRef, keyError: "divisaID",
                mensajeError: "Especifique id"
            },
            {
                condicion: !numpolEndoso || trim(numpolEndoso) === "",
                ref: numpolEndosoRef, keyError: "numpolEndoso",
                mensajeError: "Debe especificar el N. de poliza"
            },
            horasInvertidas !== "" && {
                condicion: !rgxdeInt2.test(horasInvertidas), ref: horasInvertidasRef, keyError: "horasInvertidas",
                mensajeError: "El formato de la hora es inválido"
            },
            {
                condicion: !moment(fechaSolCot).isValid(),
                ref: fechaSolCotRef, keyError: "fechaSolCot",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(vigenciaHasta).isValid(),
                ref: vigenciaHastaRef, keyError: "vigenciaHasta",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: moment(vigenciaHasta).diff(moment(vigenciaIni), 'years', true) < 0 /*moment(vigenciaHasta) < moment(vigenciaIni) */,
                keyError: "vigenciaHasta", ref: vigenciaHastaRef,
                mensajeError: "La fecha vigencia final no puede ser menor a la inicial"
            },
            {
                condicion: !exclusiones || trim(exclusiones) === "",
                ref: exclusionesRef, keyError: "exclusiones",
                mensajeError: "Debe especificar el deducible"
            },
            renovacion === false ? ({
                condicion: !renovacionObs || trim(renovacionObs) === "",
                ref: renovacionObsRef, keyError: "renovacionObs",
                mensajeError: "Debe especificar la observacion"
            }) : {}


            /*,
                 {
                    condicion: !bienesAmparadosEncabezado || bienesAmparadosEncabezado === "",
                    ref: bienesAmparadosEncabezadoRef, keyError: "bienesAmparadosEncabezado",
                    mensajeError: "Debe especificar los bienes amparados"
                },
                {
                    condicion: !beneficiarioPreferente || beneficiarioPreferente ==="",
                    ref: beneficiarioPreferenteRef, keyError: "beneficiarioPreferente",
                    mensajeError: "Debe especificar el nombre del beneficiario preferente"
                }*/
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function handleValidacionesDetalle({ polizaSeguroID,
        deducible,
        cobertura,
        exclusiones,
        bienesAmparados,
        tipo, maximoMinimo }) {
        let error;
        let validaciones = [
            {
                condicion: !polizaSeguroID,
                ref: polizaSeguroID, keyError: "polizaSeguroID",
                mensajeError: "Seleccione una póliza"
            }, {
                condicion: !cobertura || trim(cobertura) === "",
                ref: DetCoberturaRef, keyError: "cobertura",
                mensajeError: "Debe especificar la cobertura"
            },

            {
                condicion: !deducible || deducible === "",
                ref: DetDeducibleRef, keyError: "deducible",
                mensajeError: "Especifique el deducible"
            },

            {
                condicion: !maximoMinimo || maximoMinimo === "",
                ref: DetMaximoMinimoRef, keyError: "maximoMinimo",
                mensajeError: "Especifique el maximo y minimo"
            },
            {
                condicion: !exclusiones || exclusiones === "",
                ref: DetExclusionesRef, keyError: "exclusiones",
                mensajeError: "Debe especificar las exclusiones"
            },
            {
                condicion: !bienesAmparados || bienesAmparados === "",
                ref: DetBienesAmparadosRef, keyError: "bienesAmparados",
                mensajeError: "Debe especificar los bienes amparados"
            },
            {
                condicion: !tipo || tipo === "",
                ref: DetTipoRef, keyError: "tipo",
                mensajeError: "Debe especificar el tipo de póliza"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputTextDetalle({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function handleValidacionesActivos({ incPol,
        polizaSeguroID,
        //numATP,
        noEco,
        equipo,
        marca,
        serie,
        anio,
        ramoSeguroID,
        moneda,
        inicioVigencia,
        terminoVigencia,
        propietarioID,
        ubicacionID,
        clasificacion }) {

        let error;
        let validaciones = [
            /*             {
                            condicion: !incPol,
                            ref: ArrayRefActivos["incPol"], keyError: "incPol",
                            mensajeError: "Debe especificar el inciso de póliza"
                        }, */
            {
                condicion: !polizaSeguroID,
                ref: ArrayRefActivos["polizaSeguroID"], keyError: "polizaSeguroID",
                mensajeError: "Seleccione una póliza"
            }, /*{
                condicion: !numATP || trim(numATP) === "",
                ref: ArrayRefActivos["numATP"], keyError: "numATP",
                mensajeError: "Debe especificar el No. A.T.P"
            }, */{
                condicion: !equipo,
                ref: ArrayRefActivos["equipo"], keyError: "equipo",
                mensajeError: "Debe especificar el equipo"
            }, {
                condicion: !marca,
                ref: ArrayRefActivos["marca"], keyError: "marca",
                mensajeError: "Debe especificar la marca"
            }, {
                condicion: !serie,
                ref: ArrayRefActivos["serie"], keyError: "serie",
                mensajeError: "Debe especificar la serie"
            }, {
                condicion: !anio,
                ref: ArrayRefActivos["anio"], keyError: "anio",
                mensajeError: "Debe especificar el año"
            }, {
                condicion: !ramoSeguroID,
                ref: ArrayRefActivos["ramoSeguroID"], keyError: "ramoSeguroID",
                mensajeError: "Debe especificar el ramo de seguro"
            }, {
                condicion: !moneda,
                ref: ArrayRefActivos["moneda"], keyError: "moneda",
                mensajeError: "Debe especificar la moneda"
            }, {
                condicion: !inicioVigencia,
                ref: ArrayRefActivos["inicioVigencia"], keyError: "inicioVigencia",
                mensajeError: "Debe especificar la fecha de Inicio de vigencia"
            }, {
                condicion: !terminoVigencia,
                ref: ArrayRefActivos["terminoVigencia"], keyError: "terminoVigencia",
                mensajeError: "Debe especificar el termino de vigencia"
            }, {
                condicion: !propietarioID,
                ref: ArrayRefActivos["propietarioID"], keyError: "propietarioID",
                mensajeError: "Debe especificar el propietarioID"
            }, {
                condicion: !ubicacionID,
                ref: ArrayRefActivos["ubicacionID"], keyError: "ubicacionID",
                mensajeError: "Debe especificar la ubicación"
            }, /* {
                condicion: !clasificacion,
                ref: ArrayRefActivos["clasificacion"], keyError: "clasificacion",
                mensajeError: "Debe especificar la clasificación"
            } */
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputTextActivos({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;
    }

    function handleValidacionesRenovar({ polizasSeguroID, empresaID, fechaSolCot }) {
        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(polizasSeguroID),
                ref: polizasSeguroID, keyError: "polizasSeguroID",
                mensajeError: "No se logro obtener la poliza a renovar"
            },
            {
                condicion: !rgxNumeros.test(empresaID),
                ref: empresaID, keyError: "empresaID",
                mensajeError: "No se cuenta con una empresa"
            },
            {
                condicion: !moment(fechaSolCot).isValid(),
                ref: fechaSolCotRef, keyError: "fechaSolCot",
                mensajeError: "La fecha no tiene un formato válido"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function setRedireccionarFormulario({ polizasSeguroID, numero }) {
        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${polizasSeguroID}", "pTipo": "${numero}", "pTabla": "Pólizas de Seguro", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    async function VerControldePagos({ polizasSeguroID, empresaID, aseguradoraID, ramoSeguroID }) {

        let listado = await getAllControlPagos({ filtro: { polizaID: Number(polizasSeguroID), polizaPago: 1 } }, token)
        let totalPrimaNeta = 0.00;
        let descripcionMensaje = [];
        if (listado.length > 0) {
            listado.map(pago => {
                descripcionMensaje.push(<><>Control pago: {pago.folioInterno}, ${pago.importePago}</> <br /></>)
                totalPrimaNeta += pago.primaNeta;
                descripcionMensaje.push(<><>Total: ${totalPrimaNeta}</> <br /></>)                
            })
        } else {
            descripcionMensaje = '¿Desea agregar un control de pago?'
        }
        setAlert({
            descripcion: descripcionMensaje,
            title: "Control de Pagos",
            tipo: "warning",
            msjConfirmacion: "Agregar control de pago",
            onConfirm: () => { setAlert(null); setOpenControlPago(true); setState({ ...state, polizasSeguroID, empresaID, aseguradoraID, ramoSeguroID }) },
            msjCancelacion: "Cancelar",
            onCancel: () => setAlert(null)
        });


    }

    function ftGuardar() {
        async function ftGuardar() {
            let {
                polizasSeguroID,
                seleccionTipoPoliza,
                numero,
                fechaSolCot,
                empresaID,
                ramoSeguroID,
                aseguradoraID,
                numpolEndoso,
                sumaAseg,
                primaNeta,
                fpPromedio,
                divisaID,
                vigenciaIni,
                vigenciaHasta,
                exclusiones,
                renovacion,
                horasInvertidas,
                tipoValorAseg,
                tipoPoliza,
                polizaOrigenID,
                beneficiarioPreferente,
                bienesAmparadosEncabezado,
                derechoPoliza,
                paridad,
                inciso,
                renovacionObs,
                aseguradoAdicional
            } = state;

            let error = false;
            let erroSubpartidas = false;

            error = handleValidaciones({
                empresaID,
                derechoPoliza,
                fechaSolCot,
                ramoSeguroID,
                aseguradoraID,
                numpolEndoso,
                divisaID,
                vigenciaIni,
                vigenciaHasta,
                exclusiones,
                horasInvertidas,
                tipoPoliza,
                polizaOrigenID,
                beneficiarioPreferente,
                bienesAmparadosEncabezado,
                renovacionObs,
                renovacion
            });

            if (error) return error;


            if (seleccionTipoPoliza == 1) {

                if (polizasSeguroID) await updatePolizasSeguro({
                    input: {
                        polizasSeguroID,
                        seleccionTipoPoliza,
                        empresaID,
                        ramoSeguroID,
                        aseguradoraID,
                        divisaID,
                        numero,
                        numpolEndoso: trim(numpolEndoso),
                        sumaAseg: parseFloat(sumaAseg),
                        primaNeta: parseFloat(primaNeta),
                        fpPromedio: parseFloat(fpPromedio),
                        fechaSolCot: fechaSolCot.format(formato),
                        vigenciaIni: vigenciaIni.format(formato),
                        vigenciaHasta: vigenciaHasta.format(formato),
                        exclusiones: trim(exclusiones),
                        renovacion,
                        horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                        tipoValorAseg, tipoPoliza: "PRINCIPAL", polizaOrigenID: null, fianza: false,
                        beneficiarioPreferente,
                        bienesAmparadosEncabezado,
                        derechoPoliza: parseFloat(derechoPoliza),
                        paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                        inciso,
                        renovacionObs,
                        aseguradoAdicional
                    }

                }, token);
                else await addPolizasSeguro({
                    input: {
                        seleccionTipoPoliza,
                        empresaID,
                        ramoSeguroID,
                        aseguradoraID,
                        divisaID,
                        numero,
                        numpolEndoso: trim(numpolEndoso),
                        sumaAseg: parseFloat(sumaAseg),
                        primaNeta: parseFloat(primaNeta),
                        fpPromedio: parseFloat(fpPromedio),
                        fechaSolCot: fechaSolCot.format(formato),
                        vigenciaIni: now.format(formato),
                        vigenciaHasta: vigenciaHasta.format(formato),
                        exclusiones: trim(exclusiones),
                        renovacion,
                        horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                        tipoValorAseg, tipoPoliza: "PRINCIPAL", polizaOrigenID: null, fianza: false,
                        beneficiarioPreferente,
                        bienesAmparadosEncabezado,
                        derechoPoliza: parseFloat(derechoPoliza),
                        paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                        inciso,
                        renovacionObs,
                        aseguradoAdicional
                    }
                }, token);
            } else {


                let auxEncabezado;


                let auxMensaje = '';

                for (let i = 0; i < datosPartidas.length; i++) {

                    let auxFolio = datosPartidas[i].folio ? datosPartidas[i].folio : i + 1

                    if (!datosPartidas[i].ramoSeguroID) {
                        auxMensaje = `Debe seleccionar una cobertura #${auxFolio}`
                        erroSubpartidas = true;
                        break;
                    }
                    // else if (!datosPartidas[i].sumaAseg) {
                    //     auxMensaje = `La suma asegurada de la cobertura #${auxFolio} no es correcta`
                    //     erroSubpartidas = true;
                    //     break;
                    // }
                    // else if (!datosPartidas[i].primaNeta) {
                    //     auxMensaje = `La prima neta de la cobertura #${auxFolio} no es correcta`
                    //     erroSubpartidas = true;
                    //     break;
                    // }
                    // else if (!datosPartidas[i].fpPromedio) {
                    //     auxMensaje = `El factor promedio de la cobertura #${auxFolio} no es correcta`
                    //     erroSubpartidas = true;
                    //     break;
                    // }

                }

                if (auxMensaje !== '') {
                    setAlert({
                        descripcion: auxMensaje,
                        title: "¡Error!",
                        tipo: danger,
                        msjConfirmacion: "Aceptar",
                        onConfirm: () => setAlert(null)
                    });
                }


                if (erroSubpartidas) return erroSubpartidas;

                if (polizasSeguroID) {
                    auxEncabezado = await updatePolizasSeguro({
                        input: {
                            polizasSeguroID,
                            seleccionTipoPoliza,
                            empresaID,
                            ramoSeguroID,
                            aseguradoraID,
                            divisaID,
                            numero,
                            numpolEndoso: trim(numpolEndoso),
                            sumaAseg: parseFloat(sumaAseg),
                            primaNeta: parseFloat(primaNeta),
                            fpPromedio: parseFloat(fpPromedio),
                            fechaSolCot: fechaSolCot.format(formato),
                            vigenciaIni: vigenciaIni.format(formato),
                            vigenciaHasta: vigenciaHasta.format(formato),
                            exclusiones: trim(exclusiones),
                            renovacion,
                            horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                            tipoValorAseg, tipoPoliza: "PRINCIPAL", polizaOrigenID: null, fianza: false,
                            beneficiarioPreferente,
                            bienesAmparadosEncabezado,
                            derechoPoliza: parseFloat(derechoPoliza),
                            paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                            inciso,
                            renovacionObs,
                            aseguradoAdicional
                        }
                    }, token);

                    for (let i = 0; i < datosPartidas.length; i++) {
                        if (datosPartidas[i].subPolizaID) {
                            await updateSubPolizasSeguro({
                                input: {
                                    subPolizaID: datosPartidas[i].subPolizaID,
                                    polizasSeguroID: parseInt(auxEncabezado.polizasSeguroID),
                                    ramoSeguroID: datosPartidas[i].ramoSeguroID,
                                    sumaAseg: parseFloat(datosPartidas[i].sumaAseg),
                                    primaNeta: parseFloat(datosPartidas[i].primaNeta),
                                    fpPromedio:parseFloat( datosPartidas[i].fpPromedio),
                                    bienesAmparadosEncabezado: datosPartidas[i].bienesAmparadosEncabezado
                                }
                            }, token)
                        } else {
                            await addSubPolizasSeguro({
                                input: {
                                    subPolizaID: null,
                                    polizasSeguroID: parseInt(auxEncabezado.polizasSeguroID),
                                    ramoSeguroID: datosPartidas[i].ramoSeguroID,
                                    sumaAseg: parseFloat(datosPartidas[i].sumaAseg),
                                    primaNeta: parseFloat(datosPartidas[i].primaNeta),
                                    fpPromedio: parseFloat(datosPartidas[i].fpPromedio),
                                    bienesAmparadosEncabezado: datosPartidas[i].bienesAmparadosEncabezado
                                }
                            }, token)
                        }
                    }

                }
                else {
                    auxEncabezado = await addPolizasSeguro({
                        input: {
                            seleccionTipoPoliza,
                            empresaID,
                            ramoSeguroID,
                            aseguradoraID,
                            divisaID,
                            numero,
                            numpolEndoso: trim(numpolEndoso),
                            sumaAseg: parseFloat(sumaAseg),
                            primaNeta: parseFloat(primaNeta),
                            fpPromedio: parseFloat(fpPromedio),
                            fechaSolCot: fechaSolCot.format(formato),
                            vigenciaIni: now.format(formato),
                            vigenciaHasta: vigenciaHasta.format(formato),
                            exclusiones: trim(exclusiones),
                            renovacion,
                            horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                            tipoValorAseg, tipoPoliza: "PRINCIPAL", polizaOrigenID: null, fianza: false,
                            beneficiarioPreferente,
                            bienesAmparadosEncabezado,
                            derechoPoliza: parseFloat(derechoPoliza),
                            paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                            inciso,
                            renovacionObs,
                            aseguradoAdicional
                        }
                    }, token);

                    for (let i = 0; i < datosPartidas.length; i++) {
                        if (datosPartidas[i].subPolizaID) {
                            await updateSubPolizasSeguro({
                                input: {
                                    subPolizaID: datosPartidas[i].subPolizaID,
                                    polizasSeguroID: parseInt(auxEncabezado.polizasSeguroID),
                                    ramoSeguroID: datosPartidas[i].ramoSeguroID,
                                    sumaAseg: parseFloat(datosPartidas[i].sumaAseg),
                                    primaNeta: parseFloat(datosPartidas[i].primaNeta),
                                    fpPromedio:parseFloat( datosPartidas[i].fpPromedio),
                                    bienesAmparadosEncabezado: datosPartidas[i].bienesAmparadosEncabezado
                                }
                            }, token)
                        } else {
                            await addSubPolizasSeguro({
                                input: {
                                    subPolizaID: null,
                                    polizasSeguroID: parseInt(auxEncabezado.polizasSeguroID),
                                    ramoSeguroID: datosPartidas[i].ramoSeguroID,
                                    sumaAseg: parseFloat(datosPartidas[i].sumaAseg),
                                    primaNeta: parseFloat(datosPartidas[i].primaNeta),
                                    fpPromedio: parseFloat(datosPartidas[i].fpPromedio),
                                    bienesAmparadosEncabezado: datosPartidas[i].bienesAmparadosEncabezado
                                }
                            }, token)
                        }
                    }

                }

            }
        }
        return ftGuardar();
    }


    function ftRenovarPoliza() {
        async function ftRenovarPoliza() {

            let {
                polizasSeguroID,
                empresaID,
                fechaSolCot
            } = stateRenovar;


            let error = await handleValidacionesRenovar({
                polizasSeguroID,
                empresaID,
                fechaSolCot
            });

            if (error) return error;

            let resultado = await renovarPolizasSeguro({
                input: {
                    polizasSeguroID: Number(polizasSeguroID),
                    empresaID: Number(empresaID),
                    fechaSolCot: fechaSolCot.format(formato)

                }
            }, token);

        }
        return ftRenovarPoliza();
    }

    function ftRenovarPolizaComplementaria() {
        async function ftRenovarPolizaComplementaria() {

            let {
                polizasSeguroID,
                empresaID,
                fechaSolCot
            } = stateRenovar;


            let error = await handleValidacionesRenovar({
                polizasSeguroID,
                empresaID,
                fechaSolCot
            });

            if (error) return error;

            let resultado = await renovarConComplementaria({
                input: {
                    polizasSeguroID: Number(polizasSeguroID),
                    empresaID: Number(empresaID),
                    fechaSolCot: fechaSolCot.format(formato)

                }
            }, token);

        }
        return ftRenovarPolizaComplementaria();
    }

    function ftGuardarEndoso() {
        async function ftGuardarEndoso() {
            let {
                polizasSeguroID,
                numero,
                fechaSolCot,
                empresaID,
                ramoSeguroID,
                aseguradoraID,
                numpolEndoso,
                sumaAseg,
                primaNeta,
                fpPromedio,
                divisaID,
                vigenciaIni,
                vigenciaHasta,
                exclusiones,
                renovacion,
                horasInvertidas,
                tipoValorAseg,
                tipoPoliza,
                polizaOrigenID,
                renovacionObs
            } = state;


            let error = await handleValidaciones({
                fechaSolCot,
                empresaID,
                ramoSeguroID,
                aseguradoraID,
                numpolEndoso,
                divisaID,
                vigenciaIni,
                vigenciaHasta,
                exclusiones,
                renovacion,
                horasInvertidas,
                tipoPoliza,
                polizaOrigenID,
                renovacionObs
            });

            if (error) return error;

            if (polizasSeguroID) await updatePolizasSeguro({
                input: {
                    polizasSeguroID,
                    empresaID,
                    ramoSeguroID,
                    aseguradoraID,
                    divisaID,
                    numero,
                    numpolEndoso: trim(numpolEndoso),
                    sumaAseg: parseFloat(sumaAseg),
                    primaNeta: parseFloat(primaNeta),
                    fpPromedio: parseFloat(fpPromedio),
                    fechaSolCot: fechaSolCot.format(formato),
                    vigenciaIni: vigenciaIni.format(formato),
                    vigenciaHasta: vigenciaHasta.format(formato),
                    exclusiones: trim(exclusiones),
                    renovacion,
                    horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                    tipoValorAseg, tipoPoliza, polizaOrigenID: statePolizaSeguroPrincipalID,
                    renovacionObs
                }
            }, token);
            else await addPolizasSeguro({
                input: {
                    empresaID,
                    ramoSeguroID,
                    aseguradoraID,
                    divisaID,
                    numero,
                    numpolEndoso: trim(numpolEndoso),
                    sumaAseg: parseFloat(sumaAseg),
                    primaNeta: parseFloat(primaNeta),
                    fpPromedio: parseFloat(fpPromedio),
                    fechaSolCot: fechaSolCot.format(formato),
                    vigenciaIni: now.format(formato),
                    vigenciaHasta: vigenciaHasta.format(formato),
                    exclusiones: trim(exclusiones),
                    renovacion,
                    horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                    tipoValorAseg, tipoPoliza, polizaOrigenID: statePolizaSeguroPrincipalID,
                    renovacionObs
                }
            }, token);

        }
        return ftGuardarEndoso();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setDatosPartidas(null);
        setDatosPartidasGet(null);
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseSubModal() {
        setOpenSubModal(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }
    function handleCloseRenovar() {
        setOpenRenovar(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateRenovar({ ...cleanStateRenovar });
        setErrorStateRenovar({ ...cleanErrorStateRenovar });
    }

    function handleCloseRenovarComp() {
        setOpenRenovarComp(false);
        setOpenRenovar(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateRenovar({ ...cleanStateRenovar });
        setErrorStateRenovar({ ...cleanErrorStateRenovar });
    }



    function handleCloseEndosos() {
        setOpen(false);
        setOpenVerEndosos(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        setActualizarTipoPoliza(false);

        setDisabledInputs(disabledInputs => ({ ...disabledInputs, polizaOrigen: true, tipoPoliza: true, empresa: false, ramoSeguro: false, aseguradora: false, divisa: false }));

    }
    function handleCloseDetalle() {
        setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificacionesDetalle({ ...cleanNotificacionesDetalle });
        setStateDetalle({ ...cleanStateDetalle });
        setErrorStateDetalle({ ...cleanErrorStateDetalle });
    }

    function handleCloseActivos() {
        setOpenActivos(false);
        setDisabled(false);
        setLoaderActivos(false);
        setNotificacionesActivos({ ...cleanNotificacionesActivos });
        setStateActivos({ ...cleanStateActivos });
        setErrorStateActivos({ ...cleanErrorStateActivos });
    }
    function handleCloseTablaActivos() {
        setOpenVerActivos(false);
        setOpen(false);
        setDisabled(false);
        setLoaderActivos(false);
        setNotificacionesActivos({ ...cleanNotificacionesActivos });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }
    function handleCloseTablaConceptos() {
        setOpenVerDetalle(false);
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }


    function handleChangePromedio(value, key) {
        if (state.sumaAseg !== 0) {
            if (parseInt(value) === 0) {
                setState({ ...state, [key]: value });
            } else {

                let promedio = value / state.sumaAseg;
                let decimales = 8;

                let signo = (promedio >= 0 ? 1 : -1);
                promedio = promedio * signo;

                promedio = promedio.toString().split('e');
                promedio = Math.round(+(promedio[0] + 'e' + (promedio[1] ? (+promedio[1] + decimales) : decimales)));
                promedio = promedio.toString().split('e');
                promedio = signo * (promedio[0] + 'e' + (promedio[1] ? (+promedio[1] - decimales) : -decimales));


                setStatePromedio(promedio)

                setState({ ...state, primaNeta: value })
            }
        } else {
            setState({ ...state, [key]: value });
        }
    }



    function handleChange(value, key) { setState({ ...state, [key]: value }); }
    function handleChangeRenovar(value, key) { setStateRenovar({ ...stateRenovar, [key]: value }); }
    function handleChangeDetalle(value, key) { setStateDetalle({ ...stateDetalle, [key]: value }); }
    function handleChangeActivos(value, key, MaxLength) {
        if (MaxLength !== 0) {
            if (value.length > MaxLength) {
                return;
            }
        }


        let tprimaNeta = 0;
        let decimales = 8;
        let signo = 0;
        switch (key) {
            case "anio":
            case "primaNeta":
            case "valorReposicion":
                if (isNaN(value)) {
                    return;
                }
                break;
            case "fPrima":
                if (isNaN(value)) {
                    return;
                }
                if (value >= 1) {
                    value = value / 100;
                }
                tprimaNeta = stateActivos["sumaAseg"] * value;

                signo = (tprimaNeta >= 0 ? 1 : -1);
                tprimaNeta = tprimaNeta * signo;

                tprimaNeta = tprimaNeta.toString().split('e');
                tprimaNeta = Math.round(+(tprimaNeta[0] + 'e' + (tprimaNeta[1] ? (+tprimaNeta[1] + decimales) : decimales)));
                tprimaNeta = tprimaNeta.toString().split('e');
                tprimaNeta = signo * (tprimaNeta[0] + 'e' + (tprimaNeta[1] ? (+tprimaNeta[1] - decimales) : -decimales));

                setStateActivos({ ...stateActivos, ["primaNeta"]: tprimaNeta, [key]: value });

                setErrorStateActivos(errorStateActivos => ({
                    ...errorStateActivos,
                    [key]: { helperText: "", error: false }
                }));

                return;
            case "sumaAseg":
                if (isNaN(value)) {
                    return;
                }
                tprimaNeta = stateActivos["fPrima"] * value;

                signo = (tprimaNeta >= 0 ? 1 : -1);
                tprimaNeta = tprimaNeta * signo;

                tprimaNeta = tprimaNeta.toString().split('e');
                tprimaNeta = Math.round(+(tprimaNeta[0] + 'e' + (tprimaNeta[1] ? (+tprimaNeta[1] + decimales) : decimales)));
                tprimaNeta = tprimaNeta.toString().split('e');
                tprimaNeta = signo * (tprimaNeta[0] + 'e' + (tprimaNeta[1] ? (+tprimaNeta[1] - decimales) : -decimales));


                setStateActivos({ ...stateActivos, ["primaNeta"]: tprimaNeta, [key]: value });

                setErrorStateActivos(errorStateActivos => ({
                    ...errorStateActivos,
                    [key]: { helperText: "", error: false }
                }));

                return;
            case "estado":

                if (value === "INACTIVO") {
                    setStateActivos({ ...stateActivos, [key]: value, ["primaNeta"]: 0, ["fPrima"]: 0, ["sumaAseg"]: 0 });

                } else {
                    setStateActivos({ ...stateActivos, [key]: value });
                }

                setErrorStateActivos(errorStateActivos => ({
                    ...errorStateActivos,
                    [key]: { helperText: "", error: false }
                }));

                return;
            default:
                break;
        }

        setStateActivos({ ...stateActivos, [key]: value });

        setErrorStateActivos(errorStateActivos => ({
            ...errorStateActivos,
            [key]: { helperText: "", error: false }
        }));
    }

    async function UbicacionesXEmpresa(pEmpresaID) {
        if (!pEmpresaID) {
            pEmpresaID = null;
        }
        if (pEmpresaID === 0) {
            pEmpresaID = null;
        }
        let Ubicaciones = await seleccionUbicaciones({ filtro: { empresaID: pEmpresaID } }, token);
        setSeleccionables({ ...seleccionables, Ubicaciones });
    }

    function ftGuardarDetalle() {
        async function ftGuardarDetalle() {
            let {
                polSegDetalleID,
                deducible,
                cobertura,
                exclusiones,
                bienesAmparados,
                tipo, 
                maximoMinimo
            } = stateDetalle;

            let polizaSeguroID = statePolizaSeguroID;

            let error = await handleValidacionesDetalle({
                polizaSeguroID,
                deducible,
                cobertura,
                exclusiones,
                bienesAmparados,
                tipo, 
                maximoMinimo
            });

            if (error) return error;

            if (polSegDetalleID) await updatePolizasSeguroDetalle({
                input: {
                    polSegDetalleID,
                    polizaSeguroID,
                    deducible,
                    cobertura,
                    exclusiones,
                    bienesAmparados,
                    tipo, maximoMinimo
                }
            }, token);
            else await addPolizasSeguroDetalle({
                input: {
                    polSegDetalleID,
                    polizaSeguroID,
                    deducible,
                    cobertura,
                    exclusiones,
                    bienesAmparados,
                    tipo, maximoMinimo
                }
            }, token);

        }
        return ftGuardarDetalle();
    }

    function ftGuardarActivos() {
        async function ftGuardarActivos() {
            let {
                incPol,
                noEco,
                //numATP,
                equipo,
                modelo,
                marca,
                serie,
                anio,
                ramoSeguroID,
                moneda,
                inicioVigencia,
                terminoVigencia,
                propietarioID,
                ubicacionID,
                clasificacion,
                fechaAlta,
                fechaBaja,
                estado,
                valorFactura,
                observaciones,
                responsable
            } = stateActivos;
            let polizaSeguroID = statePolizaSeguroID;

            var DatosGuardar = { ...stateActivos };

            DatosGuardar["inicioVigencia"] = formatearFecha(DatosGuardar["inicioVigencia"]);
            DatosGuardar["terminoVigencia"] = formatearFecha(DatosGuardar["terminoVigencia"]);
            DatosGuardar["fechaAlta"] = formatearFecha(DatosGuardar["fechaAlta"]);
            DatosGuardar["fechaBaja"] = formatearFecha(DatosGuardar["fechaBaja"]);
            DatosGuardar["fPrima"] = DatosGuardar["fPrima"] === "" ? 0 : parseFloat(DatosGuardar["fPrima"]);
            DatosGuardar["primaNeta"] = DatosGuardar["primaNeta"] === "" ? 0 : parseFloat(DatosGuardar["primaNeta"]);
            DatosGuardar["valorReposicion"] = parseFloat(DatosGuardar["valorReposicion"]);
            DatosGuardar["anio"] = parseInt(DatosGuardar["anio"]);
            DatosGuardar["ramoSeguroID"] = parseInt(DatosGuardar["ramoSeguroID"]);
            DatosGuardar["empresaID"] = parseInt(stateEmpresaID);
            DatosGuardar["polizasSeguroID"] = infoActivos.datosEncabezado.polizasSeguroID ? infoActivos.datosEncabezado.polizasSeguroID : infoActivos.polizaID;
            //DatosGuardar["polizasSeguroID"] = desdeEndosos ? infoActivos.polizaID : null;
            DatosGuardar["propietarioID"] = parseInt(DatosGuardar["propietarioID"]);
            DatosGuardar["ubicacionID"] = parseInt(DatosGuardar["ubicacionID"]);
            DatosGuardar["sumaAseg"] = DatosGuardar["sumaAseg"] === "" ? 0 : parseFloat(DatosGuardar["sumaAseg"]);
            DatosGuardar["valorFactura"] = parseFloat(DatosGuardar["valorFactura"]);
            DatosGuardar["sustituyeID"] = parseFloat(DatosGuardar["sustituyeID"]);
            DatosGuardar["omitir"] = parseInt(DatosGuardar["omitir"]);
            DatosGuardar["activoAsegID"] = parseInt(DatosGuardar["activoAsegID"]);
            DatosGuardar["subPolizaID"] = desdeEndosos ? null : subPolizaID;
            DatosGuardar["capacidad"] = parseFloat(DatosGuardar["capacidad"]);
            DatosGuardar["uniCapacidad"] = DatosGuardar["uniCapacidad"];
            DatosGuardar["grupoID"] = DatosGuardar["grupoID"];
            DatosGuardar["bienBajoConvenio"] = DatosGuardar["bienBajoConvenio"];
            DatosGuardar["descripcion"] = DatosGuardar["descripcion"];
            DatosGuardar["areaM2"] = DatosGuardar["areaM2"];
            DatosGuardar["activoID"] = DatosGuardar["activoID"];
            DatosGuardar["dimensiones"] = DatosGuardar["dimensiones"];
            DatosGuardar["tonelaje"] = DatosGuardar["tonelaje"];
            DatosGuardar["voltaje"] = DatosGuardar["voltaje"];
            DatosGuardar["departamentoID"] = DatosGuardar["departamentoID"];
            DatosGuardar["responsable"] = DatosGuardar["responsable"];
            DatosGuardar["modelo"] = DatosGuardar["modelo"];            
            DatosGuardar["noEco"] = DatosGuardar["noEco"];

            // delete DatosGuardar["solicitud"];
            delete DatosGuardar["activoSustituido"];
            delete DatosGuardar["activoEquipoSerie"];
            delete DatosGuardar["autoAD"];
            delete DatosGuardar["fechaModificacion"];
            delete DatosGuardar["polRenovada"];
            delete DatosGuardar["partComplementaria"];
            delete DatosGuardar["fechaComplementaria"];
            delete DatosGuardar["beneficiarioPreferente"];
            delete DatosGuardar["mes"];
            delete DatosGuardar["fechaInicio"];
            delete DatosGuardar["fechaFin"];
            delete DatosGuardar["ejercicio"];
            delete DatosGuardar["polizaOrigenID"];
            delete DatosGuardar["motivo"];
            delete DatosGuardar["responsable2"];
            delete DatosGuardar["divisaID"];
            delete DatosGuardar["paridad"];
            delete DatosGuardar["sustituye"];
            //delete DatosGuardar["noEco"];
            delete DatosGuardar["nocpu"];
            delete DatosGuardar["noMonitor"];
            delete DatosGuardar["valorAseg"];
            delete DatosGuardar["cdOrigen"];
            delete DatosGuardar["fechaSalida"];
            delete DatosGuardar["embalaje"];
            delete DatosGuardar["pesoyVol"];
            delete DatosGuardar["usuarioSolicita"];
            delete DatosGuardar["nuevoUsado"];
            delete DatosGuardar["noInv"];
            delete DatosGuardar["valorNuevo"];
            delete DatosGuardar["cdDestino"];
            delete DatosGuardar["fechaLlegada"];
            delete DatosGuardar["chofer"];
            delete DatosGuardar["sobreDimension"];
            delete DatosGuardar["activoAsegurar"];
            //delete DatosGuardar["modelo"];
            delete DatosGuardar["valorM2"];
            delete DatosGuardar["primaNetaTotal"];

            //  delete DatosGuardar["departamento"];

            if (DatosGuardar["solSegFianID"] === '') {
                DatosGuardar["solSegFianID"] = null;
            }

            if (stateActivos["infoActAsegID"]) {
                let respuesta = await ConsultasActivos.updateInfoActAsegPolizas(DatosGuardar, token);
            }
            else {
                let respuesta = await ConsultasActivos.addInfoActAsegPolizas(DatosGuardar, token);
            }

        }
        return ftGuardarActivos();
    }

    function formatearFecha(pFecha) {
        if (moment(pFecha).isValid() === false) {
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function handleHabilitaControles(value) {

        if (value === 'PRINCIPAL') {
            setDisabledInputs(disabledInputs => ({ ...disabledInputs, polizaOrigen: true, sustituye: true }));
            setState(state => ({ ...state, polizaOrigenID: null }));
        }
        if (value === 'POLIZA' || value === 'ENDOSO') {
            setDisabledInputs(disabledInputs => ({ ...disabledInputs, polizaOrigen: false, sustituye: false }));

        }
    }
    function InicializarInputsActivos(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {
        cleanErrorStateActivos[Identificador] = { error: false, helperText: "" };
        cleanStateActivos[Identificador] = ValorInicial;
        ArrayRefActivos[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        let tInput = {
            disabled,
            value: stateActivos[Identificador],
            id: Identificador,
            error: errorStateActivos[Identificador].error,
            success: stateActivos[Identificador] && !errorStateActivos[Identificador].error ? true : undefined,
            helperText: errorStateActivos[Identificador].helperText,
            inputRef: ArrayRefActivos[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => handleChangeActivos(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConIDActivos[Identificador] = tInput;
        if (Seccion === "") {
            inputsActivos.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (SeccionesActivos[Seccion]) {
            InputFieldSet = SeccionesActivos[Seccion];
            tInputsDeSeccion = InputFieldSet.inputsActivos;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputsActivos.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        SeccionesActivos[Seccion] = InputFieldSet;
    }




    async function divisas() {
        let historialParidades = await getAllHistorialParidades({ filtro: { divisaID: state.divisaID } }, token);

        setState({ ...state, ["paridad"]: historialParidades[0].paridad });
    }

    useEffect(() => {
        async function ftEffect() {
            try {

                if (!actualizarTipoPoliza) {

                    let aTiposPolizaAux = [
                        {
                            label: 'PRINCIPAL',
                            value: 'PRINCIPAL'
                        },
                        {
                            label: 'POLIZA',
                            value: 'POLIZA'
                        },
                        {
                            label: 'ENDOSO',
                            value: 'ENDOSO'
                        }
                    ];
                    setActualizaraTiposPoliza(aTiposPoliza => (aTiposPoliza = aTiposPolizaAux));
                    if (!state.polizasSeguroID) {
                        setState(state => ({ ...state, tipoPoliza: 'PRINCIPAL' }));
                    }

                }
                else {

                    let aTiposPolizaAux = [
                        {
                            label: 'POLIZA',
                            value: 'POLIZA'
                        },
                        {
                            label: 'ENDOSO',
                            value: 'ENDOSO'
                        }
                    ];

                    setActualizaraTiposPoliza(aTiposPoliza => (aTiposPoliza = aTiposPolizaAux));
                    if (!state.polizasSeguroID) {
                        setState(state => ({ ...state, tipoPoliza: 'POLIZA', polizaOrigenID: statePolizaSeguroID }));
                    }
                }

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizarTipoPoliza]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }




    //Función para regresar los inputs necesarios en base al tipo de póliza seleccionado
    function ftSeleccionInputsPDS(tipo) {
        if (tipo == 1) {
            return ([
                {
                    id: "Arriba izquierdo",
                    tipo: "fieldset",
                    grid: { lg: 6 },
                    inputs: [
                        {
                            disabled: disabled ? disabled : disabledInputs.empresa,
                            id: "empresaID",
                            value: state.empresaID,
                            error: errorState.empresaID.error,
                            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                            helperText: errorState.empresaID.helperText,
                            inputRef: empresaIDRef,
                            title: "Empresa *",
                            placeholder: "Seleccione una Empresa",
                            tipo: "autocomplete",
                            data: seleccionables.Empresa,
                            grid: { md: 12, lg: 12 },
                            onChange: ({ value }) => {
                                setState({ ...state, empresaID: value })
                            },
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.ramoSeguro,
                            id: "ramoSeguroID",
                            value: state.ramoSeguroID,
                            error: errorState.ramoSeguroID.error,
                            success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
                            helperText: errorState.ramoSeguroID.helperText,
                            inputRef: ramoSeguroIDRef,
                            title: "Ramo de Seguro *",
                            placeholder: "Seleccione un Ramo",
                            tipo: "autocomplete",
                            data: seleccionables.Ramo,
                            grid: { md: 12, lg: 12 },
                            onChange: ({ value, label }) => {
                                setState({ ...state, ramoSeguroID: value, ramoSeguro: label })
                            },

                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.aseguradora,
                            id: "aseguradoraID",
                            value: state.aseguradoraID,
                            error: errorState.aseguradoraID.error,
                            success: state.aseguradoraID && !errorState.aseguradoraID.error ? true : undefined,
                            helperText: errorState.aseguradoraID.helperText,
                            inputRef: aseguradoraIDRef,
                            title: "Aseguradora *",
                            placeholder: "Seleccione una Aseguradora",
                            tipo: "autocomplete",
                            data: seleccionables.Aseguradora,
                            grid: { md: 12, lg: 12 },
                            onChange: ({ value }) => {
                                setState({ ...state, aseguradoraID: value })
                            }
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.tipoPoliza,
                            id: "tipoPoliza",
                            value: state.tipoPoliza,
                            error: errorState.tipoPoliza.error,
                            success: state.tipoPoliza && !errorState.tipoPoliza.error ? true : undefined,
                            helperText: errorState.tipoPoliza.helperText,
                            inputRef: tipoPolizaRef,
                            title: "Tipo de Póliza *",
                            placeholder: "Seleccione un tipo",
                            tipo: "select",
                            data: aTiposPoliza,
                            grid: { md: 5, lg: 5 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, tipoPoliza: value });
                                handleHabilitaControles(value);
                            },
                        },
                        {
                            hidden: state.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                            disabled,
                            id: "derechoPoliza",
                            value: state.derechoPoliza,
                            error: errorState.derechoPoliza.error,
                            success: state.derechoPoliza && !errorState.derechoPoliza.error ? true : undefined,
                            helperText: errorState.derechoPoliza.helperText,
                            inputRef: derechoPolizaRef,
                            textAlign: "right",
                            icono: <IconoSimbolo simbolo="$" />,
                            formatoImportes: true,
                            posicionIcono: "start",
                            title: "Derecho de Póliza *",
                            placeholder: "Capture el Derecho de Póliza",
                            grid: { md: 7, lg: 7 },
                            onChange: ({ target: { value } }) => {
                                (rgxDecimal14.test(value)) &&
                                    handleChange(value, "derechoPoliza")
                            }
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.polizaOrigen,
                            id: "polizaOrigenID ",
                            value: state.polizaOrigenID,
                            error: errorState.polizaOrigenID.error,
                            success: state.polizaOrigenID && !errorState.polizaOrigenID.error ? true : undefined,
                            helperText: errorState.polizaOrigenID.helperText,
                            inputRef: polizaOrigenIDRef,
                            placeholder: "Seleccione la póliza Principal",
                            tipo: "select",
                            title: "Póliza Principal *",
                            data: seleccionables.PolizasPrincipales,

                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, polizaOrigenID: value })
                            },
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.divisa,
                            id: "divisaID",
                            value: state.divisaID,
                            error: errorState.divisaID.error,
                            success: state.divisaID && !errorState.divisaID.error ? true : undefined,
                            helperText: errorState.divisaID.helperText,
                            inputRef: divisaIDRef,
                            title: "Divisa",
                            placeholder: "Seleccionar la Divisa",
                            grid: { md: 6, lg: 6 },
                            tipo: "select",
                            data: listDivisas,
                            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
                            onKeyDown: (evt) => handleFocus(evt, numpolEndosoRef)
                        },
                        {
                            disabled: true,
                            id: "paridad",
                            value: state.paridad,
                            error: errorState.paridad.error,
                            success: state.paridad && !errorState.paridad.error ? true : undefined,
                            helperText: errorState.paridad.helperText,
                            title: "Paridad Actual ",
                            textAlign: "right",
                            placeholder: "Paridad Actual",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => { handleChange(value, "paridad") }
                        }
                    ]
                },
                {
                    id: "Arriba derecho",
                    tipo: "fieldset",
                    grid: { lg: 6 },
                    inputs: [
                        {
                            disabled,
                            id: "numero",
                            value: state.numero,
                            error: errorState.numero.error,
                            success: state.numero && !errorState.numero.error ? true : undefined,
                            helperText: errorState.numero.helperText,
                            inputRef: numeroRef,
                            title: "No. de Póliza *",
                            placeholder: "Capture el No. de Póliza",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value, "numero")
                            },
                            inputProps: {
                                onBlur: () => setErrorState({ ...cleanErrorState, numero: { error: state.numero.length > 50 && true, helperText: state.numero.length > 50 ? "Límite de 50 caracteres excedido" : "" } })
                            },
                            onKeyDown: (evt) => handleFocus(evt, numpolEndosoRef)
                        },
                        {
                            disabled,
                            id: "numpolEndoso",
                            value: state.numpolEndoso,
                            error: errorState.numpolEndoso.error,
                            success: state.numpolEndoso && !errorState.numpolEndoso.error ? true : undefined,
                            helperText: errorState.numpolEndoso.helperText,
                            inputRef: numpolEndosoRef,
                            title: "Endoso",
                            placeholder: "Capture el endoso",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                (rgxInputTexto30.test(value)) &&
                                    handleChange(value.toUpperCase(), "numpolEndoso")
                            },
                            inputProps: {
                                onBlur: () => setErrorState({ ...cleanErrorState, numpolEndoso: { error: state.numpolEndoso.length > 30 && true, helperText: state.numpolEndoso.length > 30 ? "Límite de 30 caracteres excedido" : "" } })
                            },
                            onKeyDown: (evt) => handleFocus(evt, aseguradoraIDRef)
                        },
                        {
                            disabled,
                            id: "inciso",
                            value: state.inciso,
                            error: errorState.inciso.error,
                            success: state.inciso && !errorState.inciso.error ? true : undefined,
                            helperText: errorState.inciso.helperText,
                            inputRef: incisoRef,
                            title: "Inciso",
                            placeholder: "Capture el inciso",
                            grid: { md: 4, lg: 4 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value, "inciso")
                            },
                        },
                        {
                            disabled,
                            id: "tipoValorAseg",
                            value: state.tipoValorAseg,
                            error: errorState.tipoValorAseg.error,
                            success: state.tipoValorAseg && !errorState.tipoValorAseg.error ? true : undefined,
                            helperText: errorState.tipoValorAseg.helperText,
                            inputRef: tipoValorAsegRef,
                            title: "Tipo de Valor Suma Asegurada",
                            placeholder: "Capture el tipo de valor asegurado",
                            tipo: "select",
                            data: tipoValorSumaAseg,
                            grid: { md: 8, lg: 8 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value, "tipoValorAseg")
                            },
                        },
                        {
                            disabled,
                            id: "sumaAseg",
                            value: state.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" || state.ramoSeguro.toUpperCase() === "GASTOS MÉDICOS MAYORES" || state.ramoSeguro.toUpperCase() === "FLOTILLA DE AUTOMÓVILES (CAMIONES CARGA GENERAL)" ? 1 : state.sumaAseg,
                            error: errorState.sumaAseg.error,
                            success: state.sumaAseg && !errorState.sumaAseg.error ? true : undefined,
                            helperText: errorState.sumaAseg.helperText,
                            textAlign: "right",
                            icono: <IconoSimbolo simbolo="$" />,
                            formatoImportes: state.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                            posicionIcono: "start",
                            inputRef: sumaAsegRef,
                            title: "Suma Asegurada",
                            placeholder: "0.0",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                (rgxDecimal14.test(value)) &&
                                    handleChange(value, "sumaAseg")
                            },
                            onKeyDown: () => { return; }
                        },
                        {
                            disabled,
                            id: "primaNeta",
                            value: state.primaNeta,
                            error: errorState.primaNeta.error,
                            success: state.primaNeta && !errorState.primaNeta.error ? true : undefined,
                            helperText: errorState.primaNeta.helperText,
                            textAlign: "right",
                            icono: <IconoSimbolo simbolo="$" />,
                            formatoImportes: true,
                            posicionIcono: "start",
                            inputRef: primaNetaRef,
                            title: "Prima Neta",
                            placeholder: "0.0",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                (rgxDecimal14.test(value)) &&
                                    handleChangePromedio(value, "primaNeta")
                            },
                            onKeyDown: () => { return; }
                        },
                        {
                            disabled,
                            id: "fpPromedio",
                            value: state.fpPromedio,
                            error: errorState.fpPromedio.error,
                            success: state.fpPromedio && !errorState.fpPromedio.error ? true : undefined,
                            helperText: errorState.fpPromedio.helperText,
                            textAlign: "right",
                            inputRef: fpPromedioRef,
                            title: "Factor Promedio",
                            placeholder: "0.0",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                (rgxPromedio.test(value)) &&
                                    handleChange(value, "fpPromedio")
                            },
                        },
                        {
                            disabled,
                            id: "Beneficiario",
                            value: state.beneficiarioPreferente,
                            error: errorState.beneficiarioPreferente.error,
                            success: state.beneficiarioPreferente && !errorState.beneficiarioPreferente.error ? true : undefined,
                            helperText: errorState.beneficiarioPreferente.helperText,
                            inputRef: beneficiarioPreferenteRef,
                            title: "Beneficiario Preferente",
                            placeholder: "Capture el nombre del beneficiario",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, beneficiarioPreferente: value })
                            }
                        }
                    ]
                },
                {
                    id: "fechas",
                    tipo: "fieldset",
                    leyenda: "▀",
                    grid: { lg: 12 },
                    inputs: [
                        {
                            disabled,
                            id: "fechaSolCot",
                            value: state.fechaSolCot,
                            error: errorState.fechaSolCot.error,
                            success: state.fechaSolCot && !errorState.fechaSolCot.error ? true : undefined,
                            helperText: errorState.fechaSolCot.helperText,
                            inputRef: fechaSolCotRef,
                            title: "Fecha Sol. Cotizacion.",
                            placeholder: "Fecha de Solicitud",
                            onlyDate: true,
                            tipo: "datetimepicker",
                            grid: { md: 4, lg: 4 },
                            onChange: (value) =>
                                handleChange(value, "fechaSolCot"),
                            onKeyDown: (evt) => handleFocus(evt, vigenciaIniRef)
                        },
                        {
                            disabled,
                            id: "vigenciaIni",
                            value: state.vigenciaIni,
                            error: errorState.vigenciaIni.error,
                            success: state.vigenciaIni && !errorState.vigenciaIni.error ? true : undefined,
                            helperText: errorState.vigenciaIni.helperText,
                            inputRef: vigenciaIniRef,
                            placeholder: "Fecha Vigencia de Inicio",
                            onlyDate: true,
                            tipo: "datetimepicker",
                            grid: { md: 4, lg: 4 },
                            onChange: (value) =>
                                handleChange(value, "vigenciaIni"),
                            onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                        },
                        {
                            disabled,
                            id: "vigenciaHasta",
                            value: state.vigenciaHasta,
                            error: errorState.vigenciaHasta.error,
                            success: state.vigenciaHasta && !errorState.vigenciaHasta.error ? true : undefined,
                            helperText: errorState.vigenciaHasta.helperText,
                            inputRef: vigenciaHastaRef,
                            title: "Vigencia hasta",
                            placeholder: "Fecha de Vencimiento",
                            onlyDate: true,
                            tipo: "datetimepicker",
                            grid: { md: 4, lg: 4 },
                            onChange: (value) =>
                                handleChange(value, "vigenciaHasta"),
                            onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                        }, {
                            disabled,
                            id: "renovacion",
                            value: state.renovacion,
                            checked: state.renovacion,
                            title: "Renovar al Termino de Vigencia",
                            tipo: "switch",
                            grid: { md: 3, lg: 3 },
                            onChange: (e) => {
                                setState({ ...state, renovacion: e.target.checked, renovacionObs: "" })
                            }
                        },
                        {
                            hidden: state.renovacion === true ? true : false,
                            id: "renovacionObs",
                            value: state.renovacionObs,
                            error: errorState.renovacionObs.error,
                            success: state.renovacionObs && !errorState.renovacionObs.error ? true : undefined,
                            helperText: errorState.renovacionObs.helperText,
                            inputRef: renovacionObsRef,
                            multiline: true,
                            title: state.renovacion === true ? "Observaciones" : "Observaciones (¿Por qué no se renovara?) *",
                            placeholder: "Capture la observación",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "renovacionObs")
                            }
                        },]
                }, {
                    id: "Abajo",
                    tipo: "fieldset",
                    leyenda: "▀",
                    grid: { lg: 12 },
                    inputs: [
                        {
                            disabled,
                            id: "exclusiones",
                            value: state.exclusiones,
                            error: errorState.exclusiones.error,
                            success: state.exclusiones && !errorState.exclusiones.error ? true : undefined,
                            helperText: errorState.exclusiones.helperText,
                            inputRef: exclusionesRef,
                            multiline: true,
                            title: "Exclusiones *",
                            placeholder: "Capture las exclusiones",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "exclusiones")
                            }
                        },
                        {
                            disabled,
                            id: "bienesAmparadosEncabezado",
                            value: state.bienesAmparadosEncabezado,
                            error: errorState.bienesAmparadosEncabezado.error,
                            success: state.bienesAmparadosEncabezado && !errorState.bienesAmparadosEncabezado.error ? true : undefined,
                            helperText: errorState.bienesAmparadosEncabezado.helperText,
                            inputRef: bienesAmparadosEncabezadoRef,
                            multiline: true,
                            title: "Bienes Amparados",
                            placeholder: "Capture los bienesAmparados",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, bienesAmparadosEncabezado: value })
                            }
                        },
                        {
                            disabled,
                            id: "aseguradoAdicional",
                            value: state.aseguradoAdicional,
                            error: errorState.aseguradoAdicional.error,
                            success: state.aseguradoAdicional && !errorState.aseguradoAdicional.error ? true : undefined,
                            helperText: errorState.aseguradoAdicional.helperText,
                            inputRef: aseguradoAdicionalRef,
                            multiline: true,
                            title: "Asegurado Adicional",
                            placeholder: "Capturar asegurado adicional",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, aseguradoAdicional: value })
                            }
                        },
                    ]
                }
            ])
        }
        else {
            return ([
                {
                    id: "Arriba izquierdo",
                    tipo: "fieldset",
                    grid: { lg: 6 },
                    inputs: [
                        {
                            disabled: disabled ? disabled : disabledInputs.empresa,
                            id: "empresaID",
                            value: state.empresaID,
                            error: errorState.empresaID.error,
                            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                            helperText: errorState.empresaID.helperText,
                            inputRef: empresaIDRef,
                            title: "Empresa *",
                            placeholder: "Seleccione una Empresa",
                            tipo: "autocomplete",
                            data: seleccionables.Empresa,
                            grid: { md: 12, lg: 12 },
                            onChange: ({ value }) => {
                                setState({ ...state, empresaID: value })
                            },
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.ramoSeguro,
                            id: "ramoSeguroID",
                            value: state.ramoSeguroID,
                            error: errorState.ramoSeguroID.error,
                            success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
                            helperText: errorState.ramoSeguroID.helperText,
                            inputRef: ramoSeguroIDRef,
                            title: "Ramo de Seguro *",
                            placeholder: "Seleccione un Ramo",
                            tipo: "autocomplete",
                            data: seleccionables.Ramo,
                            grid: { md: 12, lg: 12 },
                            onChange: ({ value, label }) => {
                                setState({ ...state, ramoSeguroID: value, ramoSeguro: label })
                            },

                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.aseguradora,
                            id: "aseguradoraID",
                            value: state.aseguradoraID,
                            error: errorState.aseguradoraID.error,
                            success: state.aseguradoraID && !errorState.aseguradoraID.error ? true : undefined,
                            helperText: errorState.aseguradoraID.helperText,
                            inputRef: aseguradoraIDRef,
                            title: "Aseguradora *",
                            placeholder: "Seleccione una Aseguradora",
                            tipo: "autocomplete",
                            data: seleccionables.Aseguradora,
                            grid: { md: 12, lg: 12 },
                            onChange: ({ value }) => {
                                setState({ ...state, aseguradoraID: value })
                            }
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.tipoPoliza,
                            id: "tipoPoliza",
                            value: state.tipoPoliza,
                            error: errorState.tipoPoliza.error,
                            success: state.tipoPoliza && !errorState.tipoPoliza.error ? true : undefined,
                            helperText: errorState.tipoPoliza.helperText,
                            inputRef: tipoPolizaRef,
                            title: "Tipo de Póliza *",
                            placeholder: "Seleccione un tipo",
                            tipo: "select",
                            data: aTiposPoliza,
                            grid: { md: 5, lg: 5 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, tipoPoliza: value });
                                handleHabilitaControles(value);
                            },
                        },
                        {
                            hidden: state.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                            disabled,
                            id: "derechoPoliza",
                            value: state.derechoPoliza,
                            error: errorState.derechoPoliza.error,
                            success: state.derechoPoliza && !errorState.derechoPoliza.error ? true : undefined,
                            helperText: errorState.derechoPoliza.helperText,
                            inputRef: derechoPolizaRef,
                            textAlign: "right",
                            icono: <IconoSimbolo simbolo="$" />,
                            formatoImportes: true,
                            posicionIcono: "start",
                            title: "Derecho de Póliza *",
                            placeholder: "Capture el Derecho de Póliza",
                            grid: { md: 7, lg: 7 },
                            onChange: ({ target: { value } }) => {
                                (rgxDecimal14.test(value)) &&
                                    handleChange(value, "derechoPoliza")
                            }
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.polizaOrigen,
                            id: "polizaOrigenID ",
                            value: state.polizaOrigenID,
                            error: errorState.polizaOrigenID.error,
                            success: state.polizaOrigenID && !errorState.polizaOrigenID.error ? true : undefined,
                            helperText: errorState.polizaOrigenID.helperText,
                            inputRef: polizaOrigenIDRef,
                            placeholder: "Seleccione la póliza Principal",
                            tipo: "select",
                            title: "Póliza Principal *",
                            data: seleccionables.PolizasPrincipales,

                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, polizaOrigenID: value })
                            },
                        },
                        {
                            disabled: disabled ? disabled : disabledInputs.divisa,
                            id: "divisaID",
                            value: state.divisaID,
                            error: errorState.divisaID.error,
                            success: state.divisaID && !errorState.divisaID.error ? true : undefined,
                            helperText: errorState.divisaID.helperText,
                            inputRef: divisaIDRef,
                            title: "Divisa",
                            placeholder: "Seleccionar la Divisa",
                            grid: { md: 6, lg: 6 },
                            tipo: "select",
                            data: listDivisas,
                            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
                            onKeyDown: (evt) => handleFocus(evt, numpolEndosoRef)
                        },
                        {
                            disabled: true,
                            id: "paridad",
                            value: state.paridad,
                            error: errorState.paridad.error,
                            success: state.paridad && !errorState.paridad.error ? true : undefined,
                            helperText: errorState.paridad.helperText,
                            title: "Paridad Actual ",
                            textAlign: "right",
                            placeholder: "Paridad Actual",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => { handleChange(value, "paridad") }
                        }
                    ]
                },
                {
                    id: "Arriba derecho",
                    tipo: "fieldset",
                    grid: { lg: 6 },
                    inputs: [
                        {
                            disabled,
                            id: "numero",
                            value: state.numero,
                            error: errorState.numero.error,
                            success: state.numero && !errorState.numero.error ? true : undefined,
                            helperText: errorState.numero.helperText,
                            inputRef: numeroRef,
                            title: "No. de Póliza *",
                            placeholder: "Capture el No. de Póliza",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value, "numero")
                            },
                            inputProps: {
                                onBlur: () => setErrorState({ ...cleanErrorState, numero: { error: state.numero.length > 50 && true, helperText: state.numero.length > 50 ? "Límite de 50 caracteres excedido" : "" } })
                            },
                            onKeyDown: (evt) => handleFocus(evt, numpolEndosoRef)
                        },
                        {
                            disabled,
                            id: "numpolEndoso",
                            value: state.numpolEndoso,
                            error: errorState.numpolEndoso.error,
                            success: state.numpolEndoso && !errorState.numpolEndoso.error ? true : undefined,
                            helperText: errorState.numpolEndoso.helperText,
                            inputRef: numpolEndosoRef,
                            title: "Endoso",
                            placeholder: "Capture el endoso",
                            grid: { md: 6, lg: 6 },
                            onChange: ({ target: { value } }) => {
                                (rgxInputTexto30.test(value)) &&
                                    handleChange(value.toUpperCase(), "numpolEndoso")
                            },
                            inputProps: {
                                onBlur: () => setErrorState({ ...cleanErrorState, numpolEndoso: { error: state.numpolEndoso.length > 30 && true, helperText: state.numpolEndoso.length > 30 ? "Límite de 30 caracteres excedido" : "" } })
                            },
                            onKeyDown: (evt) => handleFocus(evt, aseguradoraIDRef)
                        },
                        {
                            disabled,
                            id: "inciso",
                            value: state.inciso,
                            error: errorState.inciso.error,
                            success: state.inciso && !errorState.inciso.error ? true : undefined,
                            helperText: errorState.inciso.helperText,
                            inputRef: incisoRef,
                            title: "Inciso",
                            placeholder: "Capture el inciso",
                            grid: { md: 4, lg: 4 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value, "inciso")
                            },
                        },
                        {
                            disabled,
                            id: "tipoValorAseg",
                            value: state.tipoValorAseg,
                            error: errorState.tipoValorAseg.error,
                            success: state.tipoValorAseg && !errorState.tipoValorAseg.error ? true : undefined,
                            helperText: errorState.tipoValorAseg.helperText,
                            inputRef: tipoValorAsegRef,
                            title: "Tipo de Valor Suma Asegurada",
                            placeholder: "Capture el tipo de valor asegurado",
                            tipo: "select",
                            data: tipoValorSumaAseg,
                            grid: { md: 8, lg: 8 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value, "tipoValorAseg")
                            },
                        },
                        {
                            disabled,
                            id: "Beneficiario",
                            value: state.beneficiarioPreferente,
                            error: errorState.beneficiarioPreferente.error,
                            success: state.beneficiarioPreferente && !errorState.beneficiarioPreferente.error ? true : undefined,
                            helperText: errorState.beneficiarioPreferente.helperText,
                            inputRef: beneficiarioPreferenteRef,
                            title: "Beneficiario Preferente",
                            placeholder: "Capture el nombre del beneficiario",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, beneficiarioPreferente: value })
                            }
                        }
                    ]
                },
                {
                    id: "fechas",
                    tipo: "fieldset",
                    leyenda: "▀",
                    grid: { lg: 12 },
                    inputs: [
                        {
                            disabled,
                            id: "fechaSolCot",
                            value: state.fechaSolCot,
                            error: errorState.fechaSolCot.error,
                            success: state.fechaSolCot && !errorState.fechaSolCot.error ? true : undefined,
                            helperText: errorState.fechaSolCot.helperText,
                            inputRef: fechaSolCotRef,
                            title: "Fecha Sol. Cotizacion.",
                            placeholder: "Fecha de Solicitud",
                            onlyDate: true,
                            tipo: "datetimepicker",
                            grid: { md: 4, lg: 4 },
                            onChange: (value) =>
                                handleChange(value, "fechaSolCot"),
                            onKeyDown: (evt) => handleFocus(evt, vigenciaIniRef)
                        },
                        {
                            disabled,
                            id: "vigenciaIni",
                            value: state.vigenciaIni,
                            error: errorState.vigenciaIni.error,
                            success: state.vigenciaIni && !errorState.vigenciaIni.error ? true : undefined,
                            helperText: errorState.vigenciaIni.helperText,
                            inputRef: vigenciaIniRef,
                            placeholder: "Fecha Vigencia de Inicio",
                            onlyDate: true,
                            tipo: "datetimepicker",
                            grid: { md: 4, lg: 4 },
                            onChange: (value) =>
                                handleChange(value, "vigenciaIni"),
                            onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                        },
                        {
                            disabled,
                            id: "vigenciaHasta",
                            value: state.vigenciaHasta,
                            error: errorState.vigenciaHasta.error,
                            success: state.vigenciaHasta && !errorState.vigenciaHasta.error ? true : undefined,
                            helperText: errorState.vigenciaHasta.helperText,
                            inputRef: vigenciaHastaRef,
                            title: "Vigencia hasta",
                            placeholder: "Fecha de Vencimiento",
                            onlyDate: true,
                            tipo: "datetimepicker",
                            grid: { md: 4, lg: 4 },
                            onChange: (value) =>
                                handleChange(value, "vigenciaHasta"),
                            onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                        }, {
                            disabled,
                            id: "renovacion",
                            value: state.renovacion,
                            checked: state.renovacion,
                            title: "Renovar al Termino de Vigencia",
                            tipo: "switch",
                            grid: { md: 3, lg: 3 },
                            onChange: (e) => {
                                setState({ ...state, renovacion: e.target.checked, renovacionObs: "" })
                            }
                        },
                        {
                            hidden: state.renovacion === true ? true : false,
                            id: "renovacionObs",
                            value: state.renovacionObs,
                            error: errorState.renovacionObs.error,
                            success: state.renovacionObs && !errorState.renovacionObs.error ? true : undefined,
                            helperText: errorState.renovacionObs.helperText,
                            inputRef: renovacionObsRef,
                            multiline: true,
                            title: state.renovacion === true ? "Observaciones" : "Observaciones (¿Por qué no se renovara?) *",
                            placeholder: "Capture la observación",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "renovacionObs")
                            }
                        },]
                }, {
                    id: "Abajo",
                    tipo: "fieldset",
                    leyenda: "▀",
                    grid: { lg: 12 },
                    inputs: [
                        {
                            disabled,
                            id: "exclusiones",
                            value: state.exclusiones,
                            error: errorState.exclusiones.error,
                            success: state.exclusiones && !errorState.exclusiones.error ? true : undefined,
                            helperText: errorState.exclusiones.helperText,
                            inputRef: exclusionesRef,
                            multiline: true,
                            title: "Exclusiones *",
                            placeholder: "Capture las exclusiones",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "exclusiones")
                            }
                        },

                        {
                            disabled,
                            id: "aseguradoAdicional",
                            value: state.aseguradoAdicional,
                            error: errorState.aseguradoAdicional.error,
                            success: state.aseguradoAdicional && !errorState.aseguradoAdicional.error ? true : undefined,
                            helperText: errorState.aseguradoAdicional.helperText,
                            inputRef: aseguradoAdicionalRef,
                            multiline: true,
                            title: "Asegurado Adicional",
                            placeholder: "Capturar asegurado adicional",
                            grid: { md: 12, lg: 12 },
                            onChange: ({ target: { value } }) => {
                                setState({ ...state, aseguradoAdicional: value })
                            }
                        },
                    ]
                },
                {
                    id: "cMultipartidas",
                    tipo: "custom",
                    leyenda: "▀",
                    grid: { lg: 12 },
                    custom: <MultiPartidas
                        datosGet={datosPartidasGet}
                        setDataToSave={setDatosPartidas}
                        ramoSeguroSelect={seleccionables.RamosCoberturas}
                    />
                }
            ])
        }
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerActivosAsegurados()
                let Empresa = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                let Aseguradora = await seleccionAseguradora({ filtro: {} }, token);
                let Ramo = await seleccionRamoSeguro({ filtro: {} }, token);
                let RamosCoberturas = await seleccionRamoSeguroCoberturas({ filtro: {} }, token);
                let Ubicaciones = await seleccionUbicaciones({ filtro: {} }, token);

                let PolizasPrincipales = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== "" ? parseInt(state.empresaID) : null, ramoSeguroID: state.ramoSeguroID !== "" ? parseInt(state.ramoSeguroID) : null, tipoPoliza: "PRINCIPAL" } }, token);
                let Ejercicios = await ftObtenerEjerciciosFiltrado(token);


                let Activos = await seleccionActivosAsegurado({ filtro: { polizasSeguroID: statePolizaSeguroID !== "" ? parseInt(statePolizaSeguroPrincipalID) : null } }, token);
                Activos !== undefined && Activos.unshift({ label: 'NO APLICA', value: '0'});
                let grupos = await seleccionGrupos({ filtro: {} }, token);
                grupos !== undefined && grupos.unshift({ label: 'NO APLICA', value: '0'});
                let activosSolicitud = await seleccionActivos({ filtro: {} }, token);
                activosSolicitud !== undefined && activosSolicitud.unshift({ label: 'NO APLICA', value: '0'});
                let departamentos = await seleccionDepartamento({ filtro: {} }, token);
                departamentos !== undefined && departamentos.unshift({ label: 'NO APLICA', value: '0'});


                // let Activos = await seleccionActivosAsegurado({ filtro: { polizasSeguroID: statePolizaSeguroID !== "" ? ( openVerEndosos ? parseInt(statePolizaSeguroPrincipalID) : parseInt(statePolizaSeguroID) ): null } }, token);


                setSeleccionables({ Empresa, Aseguradora, Ramo,RamosCoberturas, Ubicaciones, PolizasPrincipales, Ejercicios, Activos, grupos,activosSolicitud,departamentos });

                if (PolizasPrincipales.length > 0 && Ramo.length > 0 && state.polizaOrigenID == null && state.ramoSeguroID !== null) {

                    setState(state => (
                        {
                            ...state, polizaOrigenID: PolizasPrincipales[0].value
                        }));
                }


            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, state.empresaID, state.ramoSeguroID, statePolizaSeguroID]);

    useEffect(() => {
        async function ftEffect() {
            try {

                if (statePromedio === Infinity) {

                    setState({ ...state, fpPromedio: 0 });
                } else if (isNaN(statePromedio) === true) {

                    setState({ ...state, fpPromedio: 0 });
                } else {

                    setState({ ...state, fpPromedio: statePromedio });
                }

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [statePromedio]);

    useEffect(() => {
        if (stateActivos.grupoID === "") setStateActivos({...stateActivos, ["grupoID"]: "0"});
        if (stateActivos.activoID === "") setStateActivos({...stateActivos, ["activoID"]: "0"});
        if (stateActivos.departamentoID === "") setStateActivos({...stateActivos, ["departamentoID"]: "0"});
    }, [stateActivos])

    return (<>
            <Modal
                maxWidth={true}
                open={openVerActivos}
                // title={`Activos Asegurados del Folio de Cobertura ${folioSubpoliza}`}
                onClose={handleCloseTablaActivos}
            >

                <Tabla
                    infoTabla={infoTablaActivos}
                    asyncData={ConsultasActivos.tablaInfoActAsegPolizas}
                    token={token}
                    parametrosFijos={desdeEndosos? infoActivos.datosPoliza : {subPolizaID}}
                />

                <FormularioModal
                    open={openActivos}
                    title={`${titleModalActivos} ${desdeEndosos? infoActivos.folio : folioSubpoliza}`}
                    onClose={handleCloseActivos}
                    notificaciones={notificacionesActivos}
                    closeNotification={() => notificacionesActivos({ ...cleanNotificacionesActivos })}
                    inputs={inputsActivos}
                    acciones={accionesFormularioActivos}
                    focus={ArrayRefActivos["incPol"]}

                />

        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
            </Modal>
        
        
    </>);
}