export const GET_CODIGOMONEDA_SAT= `query getMoneda
{
  getMoneda{
    claveMoneda
    descripcion
    decimales
    porcentajeVariacion
    fechaInicioVigencia
    fechaFinVigencia 
  }
}
`;