import React from 'react';
import PropTypes from "prop-types";

function Imagen(props) {

    let { url, value, token, title, style = { maxHeight: "100px", width: "auto" } } = props;

    let altTitle = title ? title : (value ? value : "imagen");

    let urlImagen = `${url}${value}`;

    return (<img src={`${urlImagen}?token=${token}`} alt={altTitle} title={altTitle} style={style} />);

}

Imagen.propTypes = {
    url: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    token: PropTypes.string
};

export default Imagen;
