import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_CONTENIDO = `
query get($filtro: InputGetSolSegFianContenido)
{
    getSolSegFianContenidos(filtro : $filtro)
  {
    contenidoID
    solsegfianID
    usuarioBitID
    empresaID
    empresa
    activoID
    activo
    descripcion
    dimensiones
    modelo
    marca
    nSerie
    tonelaje
    voltaje
    capacidad
    unidadCapacidad
    anio
    departamentoID
    departamento
    ubicacionID
    ubicacion
    costoUnitario
    divisaID
    paridad
    costoAsegurado
  }
}`;

export const GET_ALL_CONTENIDOS = `
query getAll($filtro: InputGetSolSegFianContenido)
{
    getAllSolSegFianContenidos(filtro : $filtro)
  {
    contenidoID
    solsegfianID
    usuarioBitID
    empresaID
    empresa
    activoID
    activo
    descripcion
    dimensiones
    modelo
    marca
    nSerie
    tonelaje
    voltaje
    capacidad
    unidadCapacidad
    anio
    departamentoID
    departamento
    ubicacionID
    ubicacion
    costoUnitario
    divisaID
    paridad
    costoAsegurado
  }
}`;

export const TABLA_CONTENIDOS = `
query filter($filtro: InputFiltroSolSegFianContenido)
{
    filterSolSegFianContenidos(filtro : $filtro)
  {
    listado{
        contenidoID
        solsegfianID
        usuarioBitID
        empresaID
        empresa
        activoID
        activo
        descripcion
        dimensiones
        modelo
        marca
        nSerie
        tonelaje
        voltaje
        capacidad
        unidadCapacidad
        anio
        departamentoID
        departamento
        ubicacionID
        ubicacion
        costoUnitario
        divisaID
        paridad
        costoAsegurado
    }
    paginas
    registros
  }
}`;

export const getContenido = async (filtro, token) => {
    try {
        let { getSolSegFianContenidos } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_CONTENIDO
        });

        return getSolSegFianContenidos;

    } catch({message}) { throw new Error(message); }
}

export const getAllContenidos = async (variables, token) => {
    try {

        let { getAllSolSegFianContenidos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_CONTENIDOS
        });

        return getAllSolSegFianContenidos;

    } catch({message}) { throw new Error(message); }
}

export const tablaContenidos = async (variables, token) => {
    try{
        let {filterSolSegFianContenidos} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_CONTENIDOS
        });
        return filterSolSegFianContenidos;
    }catch({message}) { throw new Error(message); }
}
