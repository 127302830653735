import React, { useState, useEffect, useRef } from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core/styles";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import Grid from "@material-ui/core/Grid";
import { Boton } from '../../../_layout/genericComponents/Botones';
import PrintIcon from '@material-ui/icons/Print';
import ImageIcon from '@material-ui/icons/Image';
import { dowloadFile, convertToBase64, urlServidor } from '../../../helpers';
import { getAllSolicitudSeguro } from '../../../querys/SolicitudesSeguros';
import { getTotalRamoSeguro } from '../../../querys/RamoSeguro';
import { getReporteSolSegFian, getReporteSolSegFianTiempo } from '../../../querys/SolicitudesSeguros';
import { seleccionEmpresa, getReporteEmpresaSolSegFian, getReporteSolSegFianEmpresa } from '../../../querys/Empresas';
import Input from '../../../_layout/genericComponents/Inputs';
import Notificaciones from '../../../_layout/genericComponents/Notificaciones';
import ProgramaAnualGantt from '../ProgramaAnualGantt';
import AgendaProgramaAnual from '../AgendaProgramaAnual';
// Redux
import { useSelector } from 'react-redux';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
    }
}));
function Inicio() {


    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1;
    const { token, usuario } = useSelector(state => state.login);
    const classes = useStyles();
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [alert, setAlert] = useState(null);
    const [seleccionables, setSeleccionables] = useState({});
    const [empresaID, setEmpresaID] = useState("-1");
    const [stateDescargar, setDescargar] = useState("PDF");
    const [stateLoader, setLoader] = useState(false);
    const [img, setImg] = useState("");
    const [auxImg, setAuxImg] = useState("");
    const [base64, setBase64] = useState(false);


    let listAnios = [];

    for (let index = anio + 1; index > 2000; index--) {
        listAnios.push({ label: index, value: index });
    };
    const listMeses = [
        { label: 'Enero', value: 1 },
        { label: 'Febrero', value: 2 },
        { label: 'Marzo', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Mayo', value: 5 },
        { label: 'Junio', value: 6 },
        { label: 'Julio', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Septiembre', value: 9 },
        { label: 'Octubre', value: 10 },
        { label: 'Noviembre', value: 11 },
        { label: 'Diciembre', value: 12 },
    ]




    const [filGrafSol, setFilGrafSol] = useState({
        label: 'Tipo de Solicitud',
        value: 'tipoSolicitud'
    });

    const [periodo, setPeriodo] = useState({
        ejercicio: anio,
        mes
    })
    const [data, setData] = useState({
        labels: [filGrafSol.label],
        datasets: []
    });


    let dataRows = [], labels = [], datas = [], colores = [];

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    const ref = useRef();


    let options = {
        scales: {
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                },
            ],
        },
        animation: {
            onComplete: () => {
                if (ref.current) {
                    setImg(ref.current.ctx.canvas.toDataURL());
                }
            }
        }
    };







    let formatoDescarga = [
        {
            label: 'PDF',
            value: 'PDF'
        },
        {
            label: 'IMAGEN',
            value: 'IMG'
        }];

    const [opcioneGrafica, setOpcionesGrafica] = useState(options);

    const filtroSolicitud = [
        {
            label: 'Tipo de Solicitud',
            value: 'tipoSolicitud'
        },
        {
            label: 'Tipo de Seguro',
            value: 'tipoSeguro'
        },
        {
            label: 'Empresa',
            value: 'porEmpresa'
        },
        {
            label: 'Tiempo',
            value: 'porTiempo'
        },
    ];



    async function Descargar() {

        let nombreReporte = 'reporteGraficas'
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");
        let formdata = new FormData();
        formdata.append("nombreReporte", nombreReporte);
        formdata.append("tituloReporte", filGrafSol.label);
        formdata.append("imgGrafica", img);


        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        if (stateDescargar === "IMG") {
            fetch(img)
                .then(response => {
                    if (!response.ok) return response.json();
                    return response.blob()
                })
                .then(result => {
                    showFile(result, `probando.jpeg`);
                })
        } else {
            await fetch(`${urlServidor}/service/reportes/reporteGraficas`, requestOptions)
                .then(response => {
                    if (!response.ok) return response.json();
                    return response.blob()
                })
                .then(result => {
                    let { error, mensaje } = result;
                    if (error) throw new Error(mensaje);
                    showFile(result, `${nombreReporte}${stateDescargar === 'PDF' ? '.pdf' : '.xlsx'}`);  // mandar como variable dependiendo del if/case
                    setAlert(null)
                })
                .catch(({ message }) => { throw new Error(message) });

        }
    }


    useEffect(() => {
        async function ftEffect() {
            try {

                let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                empresas.unshift({ label: 'Todas las empresas', value: "-1" }) //Se añade la opción de todas las empresas al listado de empresas
                if (filGrafSol.value === "tipoSolicitud") {
                    let solicitudes = await getReporteSolSegFian({ filtro: { ejercicio: periodo.ejercicio, mes: periodo.mes } }, token);
                    let enProceso = [], revisadas = [], autorizadas = [];
                    if (Array.isArray(solicitudes) && solicitudes.length > 0) {
                        solicitudes.forEach(valor => {
                            labels.push(valor.tipo)
                            enProceso.push(valor.enProcesoCantidad);
                            revisadas.push(valor.revisadaCantidad);
                            autorizadas.push(valor.autorizadaCantidad);
                            dataRows.push({ label: [valor.tipo] })
                        })
                        setOpcionesGrafica(options);
                        setData({
                            labels, datasets: [
                                { label: 'En Proceso', data: enProceso, backgroundColor: 'rgb(54, 162, 235)' },
                                { label: 'Revisada', data: revisadas, backgroundColor: 'rgb(255, 99, 132)' },
                                { label: 'Autorizada', data: autorizadas, backgroundColor: 'rgb(75, 192, 192)' },
                                { label: 'Total', data: [(enProceso[0] + revisadas[0] + autorizadas[0]), (enProceso[1] + revisadas[1] + autorizadas[1])], backgroundColor: 'rgb(254,202,155)' },
                            ]
                        });
                        setNotificaciones({ ...cleanNotificaciones });
                    }
                    else {
                        setData({ labels: '', data: [] });
                        setNotificaciones({ mensaje: 'No hay registros para generar la gráfica', color: danger, open: true });
                    }
                }
                else if (filGrafSol.value === "tipoSeguro") {
                    let ramoSeguro = await getTotalRamoSeguro({ filtro: { empresaID: empresaID === "-1" ? null : empresaID, ejercicio: periodo.ejercicio, mes: periodo.mes } }, token);
                    if (Array.isArray(ramoSeguro) && ramoSeguro.length > 0) {
                        ramoSeguro.forEach(valor => {
                            labels.push(valor.ramoSeguro);
                            datas.push(valor.totalSeguro);
                            dataRows.push({ label: [valor.ramoSeguro] });
                            colores.push(valor.color);
                        })
                        setOpcionesGrafica(options);
                        setData({ labels, datasets: [{ label: 'Tipo de Seguro', data: datas, backgroundColor: colores }] });
                        setNotificaciones({ ...cleanNotificaciones });
                    }
                    else {
                        setData({ labels: '', data: [] });
                        setNotificaciones({ mensaje: 'No hay registros para generar la gráfica', color: danger, open: true });
                    }
                }
                else if (filGrafSol.value === "porEmpresa") {
                    let solSegEmpresa = await getReporteSolSegFianEmpresa({ filtro: { empresaID: empresaID === "-1" ? null : empresaID, ejercicio: periodo.ejercicio, mes: periodo.mes } }, token);
                    if (Array.isArray(solSegEmpresa) && solSegEmpresa.length > 0) {
                        solSegEmpresa.forEach(valor => {
                            labels.push(valor.nombre);
                            datas.push(valor.total);
                            dataRows.push({ label: [valor.nombre] });
                            colores.push(valor.color);
                        })
                        setOpcionesGrafica(options);
                        setData({ labels, datasets: [{ label: 'Solicitudes', data: datas, backgroundColor: colores }] });
                        setNotificaciones({ ...cleanNotificaciones });
                    }
                    else {
                        setData({ labels: '', data: [] });
                        setNotificaciones({ mensaje: 'No hay registros para generar la gráfica', color: danger, open: true });
                    }
                }
                else if (filGrafSol.value === "porTiempo") {
                    let solSegFianTiempo = await getReporteSolSegFianTiempo({ filtro: { ejercicio: periodo.ejercicio, mes: periodo.mes } }, token);
                    if (Array.isArray(solSegFianTiempo) && solSegFianTiempo.length > 0) {
                        solSegFianTiempo.forEach(valor => {
                            labels.push(valor.ramoSeguro);
                            datas.push(valor.diasInvertidos);
                            dataRows.push({ label: [valor.ramoSeguro] })
                            colores.push(valor.color);
                        })
                        setData({ labels, datasets: [{ label: 'Total en días', data: datas, backgroundColor: colores }] });
                        setNotificaciones({ ...cleanNotificaciones });
                        // setOpcionesGrafica(options = {
                        //     indexAxis: 'y',
                        //     ticks: {
                        //         autoSkip: false
                        //     },
                        //     // Elements options apply to all of the options unless overridden in a dataset
                        //     // In this case, we are setting the border of each horizontal bar to be 2px wide
                        //     elements: {
                        //       bar: {
                        //         borderWidth: 2,
                        //       },
                        //     },
                        //     responsive: true,
                        //     plugins: {
                        //       legend: {
                        //         position: 'right',
                        //       },
                        //       title: {
                        //         display: true,
                        //         text: 'Promedio de tiempo resolución de tramites en días',
                        //       },
                        //     },
                        //   });
                    }
                    else {
                        setData({ labels: '', data: [] });
                        setNotificaciones({ mensaje: 'No hay registros para generar la gráfica', color: danger, open: true });
                    }
                }
                setSeleccionables({ empresas });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [filGrafSol.value, empresaID, periodo]);

    return (<>
        {
            usuario.paginaInicio === 1 && <div className={classes.root}>
                <Grid container spacing={3}>
                    {<Grid item xs={12} sm={12}>
                        <AgendaProgramaAnual />
                    </Grid>}
                    
                    {<Grid item xs={12} sm={12}>
                        <ProgramaAnualGantt />
                    </Grid>}

                    <Grid item xs={12} sm={6}>
                        {notificaciones.open && <Notificaciones
                            close={true}
                            message={notificaciones.mensaje}
                            color={notificaciones.color}
                            open={notificaciones.open}
                            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                        />}
                        <h5 style={{ textAlign: "left" }}><b>{`Total de Solicitudes Atendidas por ${filGrafSol.label}`}</b></h5>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Input title="Seleccionar Ejercicio" tipo="autocomplete" id="anio" value={periodo.ejercicio} data={listAnios} onChange={(target) => {
                                    if (target) {
                                        setPeriodo({ ...periodo, ejercicio: target.value })
                                    }
                                }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input title="Seleccionar Mes" tipo="autocomplete" id="mes" value={periodo.mes} data={listMeses} onChange={(target) => {
                                    if (target) {
                                        setPeriodo({ ...periodo, mes: target.value })
                                    }
                                }} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Input title="Seleccionar filtro" tipo="autocomplete" id="solicitud" value={filGrafSol.value} data={filtroSolicitud} onChange={(target) => {
                                    if (target) {
                                        setFilGrafSol({ label: target.label, value: target.value });
                                        setEmpresaID("-1")
                                    }
                                }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {
                                    (filGrafSol.value === "tipoSeguro" || filGrafSol.value === "porEmpresa") && <Input title="Seleccionar Empresa" tipo="autocomplete" id="empresa" value={empresaID} data={seleccionables.empresas} onChange={(target) => { target && setEmpresaID(target.value) }} />
                                }
                            </Grid>
                        </Grid>
                        <br />
                        <Bar ref={ref} data={data} options={options} />
                        <br />
                        <br />
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Input title="Seleccionar Formato a Descargar" tipo="autocomplete" id="anio" value={stateDescargar} data={formatoDescarga} onChange={(target) => { target && setDescargar(target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Boton key={'btnPDF'} Icono={PrintIcon} color={'primary'} descripcion={'Generar'} onClick={Descargar} />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        }
    </>
    );
}
export default Inicio;
