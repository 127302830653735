import React, { useState } from 'react';

function SeccionXIV({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      A: { respuesta: ''},
      B: { respuesta: ''},
      C: { respuesta: ''},
      D: { respuesta: ''},
      seccionXVA: { respuesta: 0, explicacion: '' },
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(ejercicio, columna, value) {
    
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;


    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
    
  }



  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
         XIV. Otros Detalles asegurativos
        </div>
        <br />
        <div className="ElementoPregunta">
          <div className="Pregunta">
            A) Límite de Responsabilidad Solicitado:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.A.respuesta} onChange={event => handleChangeValue("A", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            B) Deducible actual:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.B.respuesta} onChange={event => handleChangeValue("B", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            C) Asegurador actual:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.C.respuesta} onChange={event => handleChangeValue("C", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            D) Fecha de vigencia de la Póliza:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.D.respuesta} onChange={event => handleChangeValue("D", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
         XV. ¿Se realiza algún otro pedido de cotización a AIG?
        </div>
        <br />
        <div className="ElementoPregunta">
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("seccionXVA", "respuesta", (stRespuestasLocal["seccionXVA"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["seccionXVA"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["seccionXVA"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("seccionXVA", "respuesta", (stRespuestasLocal["seccionXVA"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["seccionXVA"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["seccionXVA"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["seccionXVA"].respuesta === 1) ?
            <><div className="Pregunta">
              De haberse realizado, por favor indique tipo de cobertura solicitada.
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["seccionXVA"].explicacion} onChange={event => handleChangeValue("seccionXVA", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
      </div>
    </div>
  </>);
}

export default SeccionXIV;