import React from 'react';
import { NetworkError } from "../Metodos";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import useMediaQuerys from "../MediaQuerys";

import svgCancel from "../../assets/svg/undraw_cancel_u1it.svg";
import svgWarning from "../../assets/svg/undraw_warning_cyit.svg";
import svgSuccess from "../../assets/svg/undraw_High_five_u364.svg";

import './styles.css'

const useStyles = makeStyles(styles);

export default function ModalConfirmacion({ title, descripcion, msjConfirmacion, msjCancelacion, onConfirm, onCancel, tipo, showConfirm = true, showCancel = true }) {

    const classes = useStyles();

    let tipoProps = {}, imagenSVG;
    let confirm = typeof showConfirm === "boolean" ? showConfirm : true;

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;
    let cancelar = showCancel && onCancel ? { showCancel: true } : {};

    switch (tipo) {
        case "success":
            tipoProps = { success: true };
            imagenSVG = svgSuccess;
            break;
        case "warning":
            tipoProps = { warning: true, showCancel };
            imagenSVG = svgWarning;
            break;
        case "danger":
            tipoProps = { danger: true };
            imagenSVG = svgCancel;
            break;
        case "success-two":
            tipoProps = { success: true, ...cancelar };
            imagenSVG = svgSuccess;
            break;
        default:
            tipoProps = { ...cancelar };
            break;
    }

    return (
    <div className='cFixAlert'><SweetAlert
        { ...tipoProps }
        style={responsive ? {} : { width: "450px" }}
        title={title}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={msjConfirmacion}
        cancelBtnText={msjCancelacion}
        showConfirm={confirm}
    >
        {imagenSVG && <img src={imagenSVG} alt="imagen" style={{ maxWidth: "100px", height: "auto" }} />}
        <br /><br />

        <div style={{maxHeight: '100px', height: 'auto', overflowY: 'auto'}}>{
            NetworkError(descripcion)
        }</div>
        
    </SweetAlert>
    </div>
    );
}
