import { useState, useEffect } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/actions/loginAction';

export function useErrorToken(error) {

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));

    const tokenMsj = 'token no es válido';
    const rgxToken = new RegExp(tokenMsj);
    const [isValido, setIsValido] = useState(false);

    function ftEffect() {
        async function effect() {
            if(rgxToken.test(error)) {
                setTokenRedux(null);
                setIsValido(true);
            }
        } effect();
    }

    useEffect(ftEffect, [error]);

    return isValido;

}
