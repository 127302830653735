import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_ALL_DOMINIOS = `
query{
    getAllDominios{
      ID
      dominio
    }
}`;

export const getAllDominios = async (variables, token) => {
    try {

        let { getAllDominios } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_DOMINIOS
        });

        return getAllDominios;

    } catch({message}) { throw new Error(message); }
}

export const seleccionDominios = async (variables, token) => {
    try {

        let allDominios = await getAllDominios(variables, token);

        if(!Array.isArray(allDominios)) throw new Error("No se encontraron registros");

        return allDominios.map(({ ID:value, dominio:label }) => ({ value: label, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}
