import React from "react";

// core components
import NavPills from "../../components/NavPills/NavPills.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

import Formulario from "../Formulario";

import { primary } from "../../helpers/colores";

function Nav(props) {

    let { color = primary, leyenda, navs = [], orientation = "horizontal" } = props;
    let tabs = [], horizontal = {};

    if(Array.isArray(navs) && navs.length > 0) tabs = navs.map(({ title, inputs, icon }, key) => ({
        tabButton: title,
        tabIcon: icon,
        tabContent: <Formulario key={key} inputs={inputs} header={false} />
    }))

    if(orientation === "vertical") horizontal = {
        horizontal: {
            tabsGrid: { xs: 12, sm: 12, md: 3 },
            contentGrid: { xs: 12, sm: 12, md: 9 }
        }
    };

    return (<Card>
        {leyenda && <CardHeader><small>{leyenda}</small></CardHeader>}
        <CardBody>
            <NavPills
                { ...horizontal }
                color={color}
                tabs={tabs}
            />
        </CardBody>
    </Card>);

}

export default Nav;
