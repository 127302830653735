import React from 'react';

//Redux
import store from './redux';
import { Provider } from 'react-redux';

import Inicio from './componentes/Inicio';

function App() {
    return (
        <Provider store={store}>
            <Inicio />
        </Provider>
    );
}

export default App;
