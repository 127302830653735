import React, { useState, useEffect } from "react";
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import Grid from "@material-ui/core/Grid";
import Input from '../../../_layout/genericComponents/Inputs';
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";

// dependency plugin for react-big-calendar
import moment from "moment";

//helpers
import { danger } from '../../../_layout/helpers/colores';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
// Redux
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

//Querys
import { getAllSiniestrosReclaAseg } from '../../../querys/SiniestrosReclaAseg';
import { getAllSiniestrosReclaTercero } from "../../../querys/ReclamacionesATerceros";

const localizer = momentLocalizer(moment);
const { trim } = Validaciones;
export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

export default function Agenda() {
    const { token } = useSelector(state => state.login);
    const { Encrypt } = Encriptar;
    const fecha = new Date();
    let history = useHistory();
    let ejercicioActual = fecha.getFullYear();

    const [alert, setAlert] = useState(null)
    const [seleccionables, setSeleccionables] = useState({});
    const [stateReclamo, setReclamo] = useState(1);

    const eventColors = event => {
        let backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    };

    const selectedEvent = evento => {
        let cTitleSeguimiento = trim(evento.titleSeguimiento);
        let cDescripEvento = trim(evento.descripEvento);
        let cSiniesReclAsegID = evento.siniesReclAsegID === undefined ? null : evento.siniesReclAsegID;
        let cSiniesReclTercerosID = evento.siniesReclTercerosID === undefined ? null : evento.siniesReclTercerosID;

        setRedireccionarFormulario({ cSiniesReclAsegID, cSiniesReclTercerosID, cTitleSeguimiento, cDescripEvento, stateReclamo });
    };

    function setRedireccionarFormulario({ cSiniesReclAsegID, cSiniesReclTercerosID, cTitleSeguimiento, cDescripEvento, stateReclamo }) {
        let seed = "scrumweb";
        const datos = `{"cSiniesReclAsegID": "${cSiniesReclAsegID}","cSiniesReclTercerosID": "${cSiniesReclTercerosID}","cTitleSeguimiento": "${cTitleSeguimiento}","cDescripEvento": "${cDescripEvento.replaceAll('\"','\'').replaceAll('\n',' ')}","stateReclamo": "${stateReclamo}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/SeguimientoReclamos/${url}`);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                if (stateReclamo === 1) {
                    let eventos = await getAllSiniestrosReclaTercero({ filtro: {} }, token);
                    setSeleccionables((seleccionables) => ({ ...seleccionables, eventosDB: eventos }));
                } else {
                    let eventos = await getAllSiniestrosReclaAseg({ filtro: {} }, token);
                    setSeleccionables((seleccionables) => ({ ...seleccionables, eventosDB: eventos }));
                }
            } catch ({ message }) {
                setAlert({
                    descripcion: `Error: ${message}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [ejercicioActual, stateReclamo]);

    const ListReclamos = [
        { label: 'Reclamos a Terceros', value: 1 },
        { label: 'Reclamos a Aseguradoras', value: 2 }
    ];

    return (
        <>
            <container>
                <Grid item xs={6} sm={3}>
                    <Input title="Seleccionar Tipo Reclamo" tipo="autocomplete" id="reclamo" value={stateReclamo} data={ListReclamos} onChange={(target) => { target && setReclamo(target.value) }} />
                </Grid>
                <BigCalendar
                    selectable
                    popup
                    localizer={localizer}
                    events={seleccionables.eventosDB || []}
                    defaultView="month"
                    scrollToTime={new Date(2000, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => selectedEvent(event)}
                    eventPropGetter={eventColors}
                    views={['month', 'week', 'day']}
                    messages={{ next: 'Siguiente', previous: 'Anterior', today: 'Hoy', month: 'Mes', week: 'Semana', day: 'Día'/* , agenda:'Ver Agenda' */ }}
                    onNavigate={(event) => { ejercicioActual = event.getFullYear(); }}
                />
            </container>
            {alert &&
                <SweetAlert
                    title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />
            }
        </>);
}
