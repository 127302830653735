import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const TABLA_TRAZABILIDAD = `
        query filtro($filtro: InputFiltroTrazabilidadSolicitudesSeguro){
            getTrazabilidadSolicitudesSeguros(filtro: $filtro){
            listado{
                solSegFianID
                controlPagoID
                polizasSeguroID
                siglas
                ramoSeguro
                folioSolicitud
                fechaSolicitud
                folioControlPago
                fechaTramite
                fechaSolCot
                folioPoliza
                numPolEndoso
                numero
                fechaSolCotPrincipal
                folioPolizaPrincipal
                numPolEndosoPrincipal
                numeroPrincipal
                polizaPrincipalID
            }
            registros
            paginas
            }
        }`;

        export const TABLA_DOCUMENTOS = `
        query filtro($filtro: InputFiltroTrazabilidadSolicitudesSeguro){
            getDocumentosAdjuntosTrazabilidad(filtro: $filtro){
            listado{
                serverStorageID
                origenID,
                tabla,
                tipo,
                ruta,
                clasificacion,
                nombreArchivo
            }
            registros
            paginas
            }
        }`;

export const tablaTrazabilidadSolicitudesSeguro = async (variables, token) => {
    try {
        let { getTrazabilidadSolicitudesSeguros } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_TRAZABILIDAD
        });
        return getTrazabilidadSolicitudesSeguros;
    } catch ({ message }) { throw new Error(message); }
}

export const tablaDocumentosAdjuntos = async (variables, token) => {
    try {
        let { getDocumentosAdjuntosTrazabilidad } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DOCUMENTOS
        });
        return getDocumentosAdjuntosTrazabilidad;
    } catch ({ message }) { throw new Error(message); }
}