import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_ALL_NOTIFICACIONES = `
query getAllNotificaciones{
    getAllNotificaciones{
      registroID
      siglas
      folio
      fecha
      estado
      tipo
      seguimiento
      fechaModificacion
    }
  }
`;

export const getAllNotificaciones = async (variables, token) => {
    try {
        let { getAllNotificaciones } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_NOTIFICACIONES
        });
        return getAllNotificaciones;
    } catch({message}) { throw new Error(message); }
}
