import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_CONTROL_PAGO = `
mutation add($input : InputAddControlPago)
{
  addControlPago(input : $input)
  {
    controlPagoID
    numero
    fechaTramite
    empresaID
    empresaFacturaID
    aseguradoraID
    agenteSegID
    descripcion
    ramoSeguroID
    folioTramite
    poliza
    endoso
    importePago
    solicitado
    realizado
    divisaID
    folioFactura
    pdf
    xml
    docOrig
    estatus
    observaciones
    horasInvertidas
    folioInterno
    polizaID
    polizaPrincipal
    vigenciaIni
    vigenciaHasta
    folioInternoSolSeguro
    devolucion
    notaCredito
    paridad
    inciso
    gastosExpedicion
    iva
    total
    diasVigencia
    reporteDaniosID
    polizaPago
    subRamoID
    subPolizaID
  }
}
`;

export const UPDATE_CONTROL_PAGO =`
mutation update($input : InputUpdateControlPago)
{
  updateControlPago(input : $input)
  {
    controlPagoID
    numero
    fechaTramite
    empresaID
    empresaFacturaID
    aseguradoraID
    agenteSegID
    descripcion
    ramoSeguroID
    folioTramite
    poliza
    endoso
    importePago
    solicitado
    realizado
    divisaID
    folioFactura
    pdf
    xml
    docOrig
    estatus
    observaciones
    horasInvertidas
    folioInterno
    polizaID
    polizaPrincipal
    vigenciaIni
    vigenciaHasta
    folioInternoSolSeguro
    devolucion
    notaCredito
    paridad
    inciso
    gastosExpedicion
    iva
    total
    diasVigencia
    reporteDaniosID
    polizaPago
    subRamoID
    subPolizaID
  }
}
`;

export const DELETE_CONTROL_PAGO = `
mutation delete($input : InputDeleteControlPago)
{
  deleteControlPago(input : $input)
}
`;

export const UPDATE_REFERENCIA_CONTROL_PAGO = `
mutation controlPagoUpdateReferencia($input: InputControlPagoUpdateReferencia){
  controlPagoUpdateReferencia(input: $input)
}`

export const GENERAR_POLIZA_DEL_PAGO = `
mutation generarPolizaDesdePago($input: InputGenerarPolizaDesdePago){
  generarPolizaDesdePago(input: $input)
}
`;

export const GENERAR_CONTROL_PAGO_DE_POLIZA = `
mutation generarControlPagxPoliza($input: InputGenerarControlPagxPoliza){
  generarControlPagxPoliza(input: $input)
}
`;

export const addControlPago = async (variables, token) => {
    try {
        let { addControlPago } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_CONTROL_PAGO
        });
        return addControlPago;
    } catch({message}) { throw new Error(message); }
};

export const updateControlPago = async (variables, token) => {
    try {
        let { updateControlPago } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_CONTROL_PAGO
        });
        return updateControlPago;
    } catch({message}) { throw new Error(message); }
};

export const deleteControlPago = async (input, token) => {
    try {
        let { deleteControlPago } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_CONTROL_PAGO
        });
        return deleteControlPago;
    } catch({message}) { throw new Error(message); }
};

export const controlPagoUpdateReferencia = async (input, token) => {
  try {
      let { controlPagoUpdateReferencia } = await FetchAPI.graphql({
          token,
          variables:{input},
          url: urlServidor,
          query: UPDATE_REFERENCIA_CONTROL_PAGO
      });
      return controlPagoUpdateReferencia;
  } catch({message}) { throw new Error(message); }
};

export const generarPolizaDesdePago = async (input, token) => {
  try {

      let { generarPolizaDesdePago } = await FetchAPI.graphql({
          token,
          variables: { input },
          url: urlServidor,
          query: GENERAR_POLIZA_DEL_PAGO
      });

      return generarPolizaDesdePago;

  } catch ({ message }) { throw new Error(message); }
};


export const generarControlPagxPoliza = async (input, token) => {
  try {

      let { generarControlPagxPoliza } = await FetchAPI.graphql({
          token,
          variables: { input },
          url: urlServidor,
          query: GENERAR_CONTROL_PAGO_DE_POLIZA
      });

      return generarControlPagxPoliza;

  } catch ({ message }) { throw new Error(message); }
};