import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

import { tipoSolicitudSeguro } from '../../../helpers/datosFijos/tipoSolicitudSeguro';
import { rgxdeInt2 } from '../../../helpers/regexp';

// Redux
import { useSelector } from "react-redux";


// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PrintIcon from '@material-ui/icons/Print';
import PlaylistAddIcon from '@material-ui/icons/Print';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import { RiFileExcel2Fill } from "react-icons/ri";
import { NetworkError } from "../../../_layout/genericComponents/Metodos/";
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { VscRequestChanges } from "react-icons/vsc";

import { getAllSolOtrosSegurosyFianzas} from '../../../querys/SolOtrosSegurosyFianzas/';
import { getAllTransporteMercancia} from '../../../querys/SolTransMerca';
import { getAllSegViajero } from '../../../querys/SeguroViajero';
import { getAllSolSegFianEquipoElec } from '../../../querys/Solsegfian_equipoelec';
import { getAllSolSegFianEquipoOper} from '../../../querys/Solsegfian_equipooper';
import { getAllGastosMedicos } from '../../../querys/SolGastosMedicos';
import { getAllSolSegFianAuto } from '../SolSegFianAuto/Consultas';
import { getAllSolSegFianHogar} from '../../../querys/SolSegFianHogar';


import * as ConsultasDominios from '../../../querys/Dominios'
import { selectEnumEstatus } from '../../../querys/Enums/metodos'
import { getSolicitudSeguro, getAllSolicitudSeguro, tablaSolicitudSeguro, tablaSolicitudSeguroDetalle } from '../../../querys/SolicitudesSeguros/';
import { addSolicitudSeguro, updateSolSegFian, deleteSolicitudSeguro, aplicarPagoSolicitudSeguro, asegurarActivosEnPoliza, estatusInvitado   } from '../../../mutations/SolicitudesSeguros/';
import { getSolOtrosSegurosyFianzas } from '../../../querys/SolOtrosSegurosyFianzas/';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric, handleGetGeneric } from '../../../_layout/helpers/handles';
/*
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
*/
import { info, danger, success, primary, rose, warning } from '../../../_layout/helpers/colores';

import { urlServidor } from '../../../helpers';
import { seleccionRamoSeguro,seleccionRamoSeguroCoberturas } from 'querys/RamoSeguro';
import { seleccionDepartamento } from 'querys/Departamento';
import { seleccionPolizasSeguro,seleccionableSubPolizasSeguro } from 'querys/PolizasSeguro';
import moment from 'moment';
import { seleccionEmpresa } from 'querys/Empresas';

import OtrosSegurosyFianzas from './OtrosSegurosyFianzas';
import TransporteMercancia from './TransporteMercancia';
import SolSegFianAuto from '../SolSegFianAuto/SolSegFianAuto';
import SeguroViajero from './SolSegViajero';
import SolSegFianEquipoElec from './SolSegEquipoElectrico';
import SolSegFianEquipoOper from './SolSegEquipoOperacion';
import GastosMedicos from './SolSegGastosMedicos';
import SolSegHogar from './SolSegHogar';
import useMediaQuerys from "../../../_layout/genericComponents/MediaQuerys";
import * as Permisos from '../Permisos';
import TrazabilidadSolicitudesSeguro from '../TrazabilidadSolicitudesSeguro';
import MultiPartidas from './MultiPartidas';
import Documentacion from '../Documentacion';
import { tablaServerStorage } from '../../../mutations/ServerStorage';
import SolSegFianContenidos from './SolSegFianContenidos';
import SolSegFianEdificio from './SolSegFianEdificio';
import { getAllEdificios } from '../../../querys/SolSegFianEdificio';
import { getAllContenidos } from '../../../querys/SolSegFianContenidos';
const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;

function SolicitudesSeguros() {

    const { fechasFiltro } = useSelector(state => state.login);



    const [registroID, setRegistroID] = useState(null);
    const [validarParams, setvalidarParams] = useState(false);
    let auxRegistroIDEffect = null;
    const { params } = useParams();
    let url = params.replace(/&/g, "/");
    if (url !== ":params") {
        let { registroID: auxRegistroID } = Decrypt({ data: url, seed: "scrumweb" }) !== "" ? JSON.parse(Decrypt({ data: url, seed: "scrumweb" })) : null;
        auxRegistroIDEffect = auxRegistroID;
    }

    let history = useHistory();
    const { token, usuario } = useSelector(state => state.login)
    let anioActual = new Date().getFullYear();
    let perfil = usuario.perfil;



    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";

    let Arreglo = [];

    // cFechaFiltroInicial = fechasFiltro.FechaInicio;

    const cleanState = {
        solSegFianID: null, departamentoID: null, empresaID: null, ramoSeguroID: null, subRamoID: null,solicitud: "Alta de Seguro", solicitante: "", puesto: "",
        observaciones: "", nomAutoriza: "", fechaSolicitud: new Date(), ramoSeguro: "", subRamo:"", estatus: "EN REDACCION", folioInterno: "", horasInvertidas: "",
        polizasSeguroID: "0", subPolizaID: "0", correoEnviar: "", dominio: "@atpaltamira.com.mx", msjCorreo: "",
    };
    const cleanErrorState = {
        solicitud: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        nomAutoriza: { error: false, helperText: "" },
        solicitante: { error: false, helperText: "" },
        puesto: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        subRamoID: { error: false, helperText: "" },
        fechaSolicitud: { error: false, helperText: "" },
        departamentoID: { error: false, helperText: "" },
        ramoSeguro: { error: false, helperText: "" },
        departamento: { error: false, helperText: "" },
        empresa: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        folioInterno: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        polizasSeguroID: { error: false, helperText: "" },
        subPolizaID: { error: false, helperText: "" },
        msjCorreo: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    let [datosPDF, setdatosPDf] = useState({});

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [dataToSave, setDataToSave] = useState();
    let [clearData, setClearData] = useState(false);
    let [datosGet, setDatosGet] = useState();
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [openDetalle, setOpenDetalle] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [actualizarDetalle, setActualizarDetalle] = useState(false);
    const [openEstado, setOpenEstado] = useState(false);
    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})    
    const [openEstadoInvitado, setOpenEstadoInvitado] = useState(false);
    const [openTrazabilidad, setOpenTrazabilidad] = useState(false);
    const [random, setRandom] = useState(0);
    const [openSolSegFianzas, setOpenSolSegFianzas] = useState(false);
    const [openSolSegTranspMerc, setOpenSolSegTranspMerc] = useState(false);
    const [openSolSegAuto, setOpenSolSegAuto] = useState(false);
    const [openSolSegViajero, setOpenSolSegViajero] = useState(false);
    const [openSolSegGastosMedicos, setOpenSolSegGastosMedicos] = useState(false);
    const [openSolSegEquipoElectrico, setOpenSolSegEquipoElectrico] = useState(false);
    const [openSolSegEquipoOperacion, setOpenSolSegEquipoOperacion] = useState(false);
    const [openSolSegFianHogar, setOpenSolSegFianHogar] = useState(false);
    const [openSolSegFianContenidos, setOpenSolSegFianContenidos] = useState(false);
    const [openSolSegFianEdificio, setOpenSolSegFianEdificio] = useState(false);
    const [datosDetalle, setDatosDetalle] = useState({})

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let esDispositivoMovil = isTabletOrMobile || isTabletOrMobileDevice;
    const [lMostrarFormularioCompleto, setlMostrarFormularioCompleto] = useState(!esDispositivoMovil);
    const [lConPartidas, setlConPartidas] = useState(false);

    const [lModificar, setlModificarEncabezado] = useState(false);

    let titleAccion = state.solSegFianID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Datos de Solicitud`;

    const empresaIDRef = useRef(null);
    const solicitudRef = useRef(null);
    const ramoSeguroIDRef = useRef(null);
    const subRamoSeguroIDRef = useRef(null);
    const fechaSolicitudRef = useRef(null);
    const solicitanteRef = useRef(null);
    const puestoRef = useRef(null);
    const departamentoIDRef = useRef(null);
    const observacionesRef = useRef(null);
    const nomAutorizaRef = useRef(null);
    const estatusRef = useRef(null);
    const solSegFianIDRef = useRef(null);
    const folioFormateadoRef = useRef(null);
    const horasInvertidasRef = useRef(null);
    const polizasSeguroIDRef = useRef(null);
    const subpolizasSeguroIDRef = useRef(null);
    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: rose,
            descripcion: "Modificar",
            parametros: [{ campo: "solSegFianID" }, { campo: "estatus" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                switch(parametros.estatus){
                    case "EN PROCESO":
                        setlModificarEncabezado(false);
                    break;
                    case "EN REDACCION":
                        setlModificarEncabezado(false);
                    break;
                    default:
                        setlModificarEncabezado(true);
                    break;
                    }

                handleModificar({
                    parametros, token, setState, setOpen, setAlert,
                    asyncGet: getSolicitudSeguro,
                    formatearResultado: [{ id: "fechaSolicitud", formato: (data) => moment(data) }]

                })
            }
        },
        {
            accionID: [2],
            id: "agregarPartidas",
            icono: PlaylistAddOutlinedIcon,
            color: success,
            descripcion: "Agregar Partida",
            parametros: [{ campo: "solSegFianID" }, { campo: "ramoSeguroID" }, { campo: "ramoSeguro" }, { campo: "empresaID" }, { campo: "solicitante" }, { campo: "folioInterno" }, { campo: "polizasSeguroID" }, { campo: "solicitud" },{ campo: "subRamoID" }, { campo: "subPolizaID" },{ campo: "subRamo" },{ campo: "seleccionTipoPoliza" },{campo: "codigoMoneda" },{campo: "departamentoID" }],
            disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, ramoSeguroID, ramoSeguro, empresaID, solicitante, folioInterno, polizasSeguroID, solicitud,subRamoID,subPolizaID,subRamo,seleccionTipoPoliza,codigoMoneda,departamentoID }) => {
                setDatosDetalleFormulario({ solSegFianID, ramoSeguroID, ramoSeguro, empresaID, solicitante, nID: null, lMostrarTabla: true, lAbrir: true, folioInterno, polizasSeguroID, solicitud,subRamoID,subPolizaID,subRamo,seleccionTipoPoliza,codigoMoneda,departamentoID });
                /*  setRedireccionarFormulario({ solSegFianID, ramoSeguro, empresaID, solicitante, folioInterno, polizasSeguroID });*/
            }
        }, 
        // {
        //     accionID: [7],
        //     icono: UnarchiveIcon,
        //     color: info,
        //     descripcion: "Adjuntar",
        //     parametros: [{ campo: "solSegFianID" },{ campo: "folioInterno" }],
        //     disabled: { multiseleccion: true },
        //     onClick: ({ solSegFianID, folioInterno}) => {
        //         setDatosDetalleAdjuntos({
        //             pURLDoctoOrigenID: solSegFianID,
        //             pTipo: folioInterno,
        //             pClasificacion: "Solicitudes de Seguro",
        //             pTabla: "Solicitudes Encabezado",
        //             pTitulo2: "Adjuntar documentos",
        //             plUtilizaProps: true
        //         });
        //         setOpenDocumentacion(true);
        //     }
        // },
        {
            accionID: [11],
            id: "EstadoInvitado",
            icono: EqualizerIcon,
            color: success,
            descripcion: "Estado",
            parametros: [{ campo: "solSegFianID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen: setOpenEstadoInvitado, setAlert,
                asyncGet: getSolicitudSeguro

            })
        },{
            accionID: [8],
            id: "Estado",
            icono: EqualizerIcon,
            color: info,
            descripcion: "Actualizar Estado",
            parametros: [{ campo: "solSegFianID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen: setOpenEstado, setAlert,
                asyncGet: getSolicitudSeguro

            })
        },
        {
            id: "verPartidas",
            icono: PlaylistAddCheckOutlinedIcon,
            color: rose,
            hide: esDispositivoMovil ? true : false,
            descripcion: "Mostrar Partidas",
            parametros: [{ campo: "solSegFianID" }, { campo: "ramoSeguro" }, { campo: "empresaID" }, { campo: "solicitante" }, { campo: "folioInterno" }, { campo: "polizasSeguroID" },{ campo: "subRamoID" }, { campo: "subPolizaID" }],
            disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, ramoSeguro, empresaID, solicitante, folioInterno, polizasSeguroID,subRamoID,subPolizaID }) => {
                setDatosDetalleFormulario({ solSegFianID, ramoSeguro, empresaID, solicitante, nID: null, lMostrarTabla: true, lAbrir: false, folioInterno, polizasSeguroID,subRamoID,subPolizaID });
                /*setRedireccionarFormulario({ solSegFianID, ramoSeguro, empresaID, solicitante, folioInterno, polizasSeguroID });*/
            }
        },
        {
            accionID: [9],
            icono: AccountBalanceWalletIcon,
            id: "GenerarCP",
            color: success,
            descripcion: "Generar control de pagos",
            parametros: [{ campo: "solSegFianID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => AplicarSolicitud(parametros)
        },
        {
            accionID: [10],
            icono: SpellcheckIcon,
            color: success,
            id: "AsegurarActivos",
            descripcion: "Asegurar Activos",
            parametros: [{ campo: "solSegFianID" }, { campo: "empresaID" }, { campo: "polizasSeguroID" },{ campo: "ramoSeguro" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => AsegurarActivosElectronicos(parametros)
        },
        {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Generar PDF",
            parametros: [{ campo: "solSegFianID" }, { campo: "ramoSeguro" }, {campo: "estatus"}, {campo: "estatusInvitado"},{campo: "subRamo"}],
            disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, ramoSeguro, estatus, estatusInvitado,subRamo }) => AuxDescargar(solSegFianID, ramoSeguro, 'PDF',estatus, estatusInvitado,subRamo)
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "solSegFianID" }, { campo: "ramoSeguro" }, {campo: "estatus"}, {campo: "estatusInvitado"},{campo: "subRamo"} ],
            disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, ramoSeguro, estatus, estatusInvitado,subRamo }) => AuxDescargar(solSegFianID, ramoSeguro, 'excel',estatus, estatusInvitado,subRamo)
        },
        {
            id: "verTrazabilidad",
            accionID: [12],
            icono: TrendingFlatIcon,
            color: rose,
            descripcion: "Ver Trazabilidad",
            parametros: [{ campo: "solSegFianID" }, { campo: "polizasSeguroID" }, { campo: "controlPagoID" }],
            disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, polizasSeguroID, controlPagoID }) => {

                // setRedireccionarTrazabilidad({ solSegFianID,
                setDatosDetalle({

                    pSolSegFianID: solSegFianID,
                    pPolizasSeguroID: polizasSeguroID,
                    pControlPagoID: controlPagoID,
                    plUtilizaProps: true,
                    pOrigen: "Solicitud"

                });
                setOpenTrazabilidad(true);
            }
        },
        {
            accionID: [4],
            id: "EliminarSolicitud",
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "solSegFianID" }, { campo: "ramoSeguro" }],
            disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, ramoSeguro }) => {
                handelAlertEliminar({
                    setAlert,
                    mensaje: ` la solicitud ${ramoSeguro}`,
                    onCancel: () => setAlert(null),
                    onConfirm: () => handleEliminar({
                        token, setAlert, setActualizar, actualizar,
                        parametros: { solSegFianID }, asyncDelete: deleteSolicitudSeguro
                    })
                });
            }
        },
    ];

    async function AplicarSolicitud(parametros) {
        try {

            let  equipoElectronico = await getAllSolSegFianEquipoElec({filtro: {solSegFianID: parseInt(parametros.solSegFianID)}},token);
            let tipoActivoFijo =0;
            equipoElectronico.forEach(({tipoActivoAsegurado,valorAseg}) => {
                if (tipoActivoAsegurado === 'FIJO') tipoActivoFijo = 1;
            });
            let result = await aplicarPagoSolicitudSeguro({ solSegFianID: parametros.solSegFianID, tipoActivoFijo }, token);
            result = JSON.parse(result);

            setAlert({
                descripcion: result.mensaje,
                title: "Generar control de pago",
                tipo: result.Resultado === 1 ? "success-two" : warning,
                msjConfirmacion: "Abrir control de pago",
                onConfirm: () => RedirigirControlPago(result["controlPagoID"]),
                showConfirm: true,
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    function AsegurarActivosElectronicos(parametros) {

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: `Aplicar Activos Asegurar`, descripcion: `¿ Deseas aplicar activos fijos?`, tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: parametros, asyncMutation: () => AplicarAsegurarActivos(parametros),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando activos asegurados.. ", tipo: success
                    },
                    msjCorrecto: {
                        title: `Se genero con éxito`, descripcion: `La solicitud se aplico con éxito `, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }


    async function AplicarAsegurarActivos(parametros) {

        let result = await asegurarActivosEnPoliza({ solSegFianID: Number(parametros.solSegFianID), empresaID: Number(parametros.empresaID), polizasSeguroID: Number(parametros.polizasSeguroID), ramoSeguro: parametros.ramoSeguro }, token)
    }

    function RedirigirControlPago(controlPagoID) {
        setAlert(null);
        let path = '/';
        path = `/gen/ControlPagos`;
        history.push(path, { controlPagoID });
    }
    /*
    {
                icono: Add,
                color: success,
                descripcion: "Agregar Solicitud",
                onClick: () =>
                //  setOpen(true)
                handleModificar({
                    token, setState, setOpen, setAlert,
                    asyncGet: InicializaParametros

                })
                ,
                disabled: { multiseleccion: true }
    },

    */
    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {
                setlModificarEncabezado(false);
                setOpen(true);
            },
            disabled: { multiseleccion: true }
        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => {
                setActualizar(!actualizar);
                setActualizarDetalle(!actualizarDetalle);
                setvalidarParams(true);
                setRegistroID(null);
                // setlConPartidas(true);
            },
            disabled: { multiseleccion: true }
        }];

    let botonesDetalle = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            parametros: [
                { campo: "solSegFianID" },
                { campo: "partidaID" }
            ], disabled: { multiseleccion: true },
            onClick: ({ solSegFianID, partidaID }) => {
                setlModificarEncabezado(true);
                handleGetGeneric({
                    parametros: { solSegFianID, partidaID }, token,
                    // asyncGet: InicializaParametros
                });
                //  setOpenSolSegFianzas(true);
            }


        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarDetalle(!actualizarDetalle),
            disabled: { multiseleccion: true }
        }];

        const estadoSolicitudSeguro = [
        {
            value: "EN REDACCION",
            label: "EN REDACCION"
        },{
            value: "EN PROCESO",
            label: "EN PROCESO"
        },{
            value: "REVISADA",
            label: "REVISADA"
        },{
            value: "AUTORIZADA",
            label: "AUTORIZADA"
        },{
            value: "RECHAZADA",
            label: "RECHAZADA"
        }   ,
        {
            value: "COMPLETADA",
            label: "COMPLETADA"
        } 
     ];

        let estadoSolicitudSeguroInputs = [
            {
                value: "EN REDACCION",
                label: "EN REDACCION",
                disabled:true
            },
            {
                value: "EN PROCESO",
                label: "EN PROCESO"
            },{
                value: "REVISADA",
                label: "REVISADA"
            },{
                value: "AUTORIZADA",
                label: "AUTORIZADA"
            },{
                value: "RECHAZADA",
                label: "RECHAZADA"
            }   ];

            let estadoAsistente = [{
                value: "EN PROCESO",
                label: "EN PROCESO"
            },{
                value: "REVISADA",
                label: "REVISADA"
            },{
                value: "AUTORIZADA",
                label: "AUTORIZADA"
            }
         ];

        const estadoInvitado = [
            {
                value: "EN REDACCION",
                label: "EN REDACCION"
            },{
                value: "EN PROCESO",
                label: "EN PROCESO"
            } ];

if (state.ramoSeguro==='Gastos Médicos mayores' || state.ramoSeguro === 'Equipo de Contratistas y Maq. Pesada' || state.ramoSeguro === 'Fianza'){
    estadoSolicitudSeguroInputs.push({ value: "COMPLETADA",label: "COMPLETADA"})
    estadoAsistente.push( {value: "COMPLETADA",label: "COMPLETADA"},)
}

if (state.ramoSeguro==='Montaje'){
    estadoSolicitudSeguroInputs.push({ value: "COMPLETADA",label: "COMPLETADA"})
    estadoAsistente.push( {value: "COMPLETADA",label: "COMPLETADA"},)
}


    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "folio",
        color: primary,
        title: "Solicitudes de Seguro",
        iconTable: <VscRequestChanges />,
        headers: [
            { variable: "empresaID", descripcion: "Empresa ID", hide: true },
            { variable: "solSegFianID", descripcion: "ID", hide: true },
            { variable: "departamentoID", descripcion: "departamentoID", hide: true }, { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true }, { variable: "controlPagoID", descripcion: "controlPagoID", hide: true },
            { variable: "ramoSeguroID", descripcion: "ramoSeguroID", hide: true },
            { variable: "folioInterno", descripcion: "Folio", hide: false },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "fechaSolicitud", descripcion: "Fecha Solicitud", hide: false },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación", hide: false },
            { variable: "solicitud", descripcion: "Solicitud" },
            { variable: "ramoSeguro", descripcion: "Ramo de Seguro" },
            { variable: "subRamo", descripcion: "Cobertura" },
            { variable: "solicitante", descripcion: "Solicitante" },
            { variable: "puesto", descripcion: "Puesto" },
            { variable: "departamento", descripcion: "Departamento" },
            { variable: "observaciones", descripcion: "Observaciones" },
            { variable: "estatusInvitado", descripcion: "Estado", hide: perfil === "INVITADO" ? false : true },
            { variable: "estatus", descripcion: "Estado", hide: perfil === "INVITADO" ? true : false  },
            { variable: "activosProcesados", descripcion: "Activos Procesados" },
            { variable: "folioControlPago", descripcion: "Folio Control de Pago" },
            { variable: "estatusControlPago", descripcion: "Estatus Control de Pago" },
            { variable: "numeroPoliza", descripcion: "Póliza Principal" },
            { variable: "subRamoID", descripcion: "subRamoID",hide: true },
            { variable: "subPolizaID", descripcion: "subPolizaID",hide: true },
            { variable: "seleccionTipoPoliza", descripcion: "seleccionTipoPoliza",hide: true },
            { variable: "codigoMoneda", descripcion: "codigoMoneda",hide: true },
        ],
        responsiveTitle: ["folioInterno", "solicitud", "ramoSeguro"],
        filter: [
            { campo: "fechaSolicitud", placeholder: "Fecha Solicitud", hide: true },
            { campo: "solicitante", placeholder: "Solicitante" },
            { campo: "puesto", placeholder: "Puesto" },
            { campo: "departamento", placeholder: "Departamento" },
            { campo: "observaciones", placeholder: "Observaciones" }
        ],
        alineacion: [{ columnas: [0, 1, 2, 3, 4, 5, 6], alineacion: "left" }],
        formato: [{ columnas: ["fechaSolicitud", "fechaModificacion"], tipo: "fecha", onlyDate: true },
        { columnas: ["activosProcesados"], tipo: "estatus" }],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        accionesEffect: [
            { botones: ["verPartidas"], ocultar: () => true },
            // { botones: ["agregarPartidas"], ocultar: () => perfil === 'INVITADO'  },
            { botones: ["EstadoInvitado"], ocultar: (data) => data["estatusInvitado"].value != "EN REDACCION" ? true : false },
            { botones: ["EliminarSolicitud"], ocultar: (data) => data["estatus"].value === "RECHAZADA" ? true : false },
            { botones: ["Estado"], ocultar: (data) => data["estatus"].value === "RECHAZADA" ? true : false },
            // { botones: ["GenerarCP"], ocultar: (data) => data["estatus"].value === "RECHAZADA" ? true : false },
            // { botones: ["AsegurarActivos"], ocultar: (data) => data["estatus"].value === "RECHAZADA" ? true : false },
            { botones: ["AsegurarActivos"], ocultar: (data) => data["ramoSeguro"].value === "Fianza" ?  (data["estatus"].value === "COMPLETADA" || data["estatus"].value === "RECHAZADA"? true : false) : false },
            { botones: ["GenerarCP"], ocultar: (data) => data["ramoSeguro"].value === "Fianza" ?  (data["estatus"].value === "COMPLETADA" || data["estatus"].value === "RECHAZADA"? true : false) : false }
        ],
        selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.empresas ? seleccionables.empresas : [], grid: { md: 3, lg: 3 },
            },
            {
                campo: "ramoSeguroID", title: "Ramo Seguro",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.ramoSeguros ? seleccionables.ramoSeguros : [], grid: { md: 3, lg: 3 },
            },
            {
                campo: "subRamoID", title: "Cobertura",
                retorna: "number", limpiarFiltro: "Todas",
                data: seleccionables.ramoSegurosCoberturas ? seleccionables.ramoSegurosCoberturas : [], grid: { md: 3, lg: 3 },
            },
            {
                campo: "departamentoID", title: "Departamento",
                retorna: "text", limpiarFiltro: "Todos",
                data: seleccionables.departamentos ? seleccionables.departamentos : [], grid: { md: 3, lg: 3 },
            },
            {
                campo: perfil === "INVITADO" ? "estatusInvitado" : "estatus", title: "Estado",
                retorna: "text", limpiarFiltro: "Todos",
                data:  perfil === "INVITADO" ? (estadoInvitado ? estadoInvitado : []) : (estadoSolicitudSeguro ? estadoSolicitudSeguro : []), grid: { md: 3, lg: 3 },
               defaultValue: perfil === "INVITADO" ? (auxRegistroIDEffect !== null ? null : estadoInvitado[0]['value']) :(auxRegistroIDEffect !== null ? null : estadoSolicitudSeguro[0]['value'])
            },
            {
                campo: "solicitud", title: "Tipo Solicitud",
                retorna: "text", limpiarFiltro: "Todos",
                data: tipoSolicitudSeguro ? tipoSolicitudSeguro : [], grid: { md: 3, lg: 3 },
            }
        ]
    }



    const inputs = [

        {
            disabled,
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccione empresa",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.empresas,
            onChange: (value) => setState({ ...state, empresaID: value ? Number(value.value) : null, polizasSeguroID: "0" }),
        },
        {
            disabled,
            id: "solicitud",
            value: state.solicitud,
            error: errorState.solicitud.error,
            success: state.solicitud && !errorState.solicitud.error ? true : undefined,
            helperText: errorState.solicitud.helperText,
            inputRef: solicitudRef,
            title: "Tipo Solicitud",
            placeholder: "Seleccione el tipo de solicitud",
            tipo: "select",
            data: tipoSolicitudSeguro,
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        }, {
            disabled,
            id: "fechaSolicitud",
            value: state.fechaSolicitud,
            error: errorState.fechaSolicitud.error,
            success: state.fechaSolicitud && !errorState.fechaSolicitud.error ? true : undefined,
            helperText: errorState.fechaSolicitud.helperText,
            inputRef: fechaSolicitudRef,
            title: "Fecha Solicitud *",
            grid: { md: 3, lg: 3 },
            placeholder: "Fecha Solicitud",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChange(value, "fechaSolicitud");
            }
        },
        {
            disabled: !state.solSegFianID ? false : true,
            id: "ramoSeguroID",
            value: state.ramoSeguroID,
            error: errorState.ramoSeguroID.error,
            success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
            helperText: errorState.ramoSeguroID.helperText,
            inputRef: ramoSeguroIDRef,
            title: "Ramo de Seguro *",
            placeholder: "Seleccione el Ramo de Seguro",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.ramoSeguros,
            onChange: (value) => setState({ ...state, ramoSeguroID: value ? Number(value.value) : null, polizasSeguroID: "0",subPolizaID: "0", subRamoID: value &&  value.value !=7? Number(value.value) : ""}),
        },
        {
            disabled:  (state.estatus =='EN PROCESO' || state.estatus =="REVISADA"  /* || !state.solSegFianID */) ||  state.ramoSeguroID ==7? false : true,
            id: "subRamoID",
            value: state.subRamoID,
            error: errorState.subRamoID.error,
            success: state.subRamoID && !errorState.subRamoID.error ? true : undefined,
            helperText: errorState.subRamoID.helperText,
            inputRef: subRamoSeguroIDRef,
            title: "Cobertura *",
            placeholder: "Seleccione la Cobertura",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.ramoSegurosCoberturas,
            onChange: (value) => setState({ ...state, subRamoID: value ? Number(value.value) : null, subRamo: value.label }),
        },
        {
            disabled,
            id: "solicitante",
            value: state.solicitante,
            error: errorState.solicitante.error,
            success: state.solicitante && !errorState.solicitante.error ? true :
                undefined,
            helperText: errorState.solicitante.helperText,
            inputRef: solicitanteRef,
            title: "Solicitante *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el solicitante",
            onChange: ({ target: { value } }) => handleChange(value, "solicitante"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "puesto",
            value: state.puesto,
            error: errorState.puesto.error,
            success: state.puesto && !errorState.puesto.error ? true :
                undefined,
            helperText: errorState.puesto.helperText,
            inputRef: puestoRef,
            title: "Puesto *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el puesto",
            onChange: ({ target: { value } }) => handleChange(value, "puesto"),
            onKeyDown: () => { return; }
        }
        ,
        {
            disabled,
            id: "departamentoID",
            value: state.departamentoID,
            error: errorState.departamentoID.error,
            success: state.departamentoID && !errorState.departamentoID.error ? true : undefined,
            helperText: errorState.departamentoID.helperText,
            inputRef: departamentoIDRef,
            title: "Departamento *",
            placeholder: "Seleccione el departamento",
            grid: { md: 4, lg: 4 },
            tipo: "autocomplete",
            data: seleccionables.departamentos,
            onChange: (value) => setState({ ...state, departamentoID: value ? Number(value.value) : null }),
        },
        {
            disabled,
            id: "observaciones",
            value: state.observaciones,
            error: errorState.observaciones.error,
            success: state.observaciones && !errorState.observaciones.error ? true :
                undefined,
            helperText: errorState.observaciones.helperText,
            inputRef: observacionesRef,
            title: "Observaciones *",
            grid: { md: 8, lg: 8 },
            placeholder: "Capture las observaciones",
            onChange: ({ target: { value } }) => handleChange(value, "observaciones"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "nomAutoriza",
            value: state.nomAutoriza,
            error: errorState.nomAutoriza.error,
            success: state.nomAutoriza && !errorState.nomAutoriza.error ? true :
                undefined,
            helperText: errorState.nomAutoriza.helperText,
            inputRef: nomAutorizaRef,
            title: "Nombre de Autorizador *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el nombre del autorizador",
            onChange: ({ target: { value } }) => handleChange(value, "nomAutoriza"),
            onKeyDown: () => { return; }
        },  {
            disabled: true,
            id: "estatus",
            value: state.estatus,
            error: errorState.estatus.error,
            success: state.estatus && !errorState.estatus.error ? true :
                undefined,
            helperText: errorState.estatus.helperText,
            inputRef: estatusRef,
            title: "Estatus*",
            placeholder: "Especifique el estatus",
            tipo: "select",
            data: estadoSolicitudSeguro,
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled:perfil==='INVITADO' ? true:false,
            id: "horasInvertidas",
            value: state.horasInvertidas,
            error: errorState.horasInvertidas.error,
            success: state.horasInvertidas && !errorState.horasInvertidas.error ? true :
                undefined,
            helperText: errorState.horasInvertidas.helperText,
            inputRef: horasInvertidasRef,
            title: "Horas Invertidas",
            grid: { md: 3, lg: 3 },
            placeholder: "Capture las horas invertidas",
            onChange: ({ target: { value } }) => !isNaN(Number(value)) && handleChange(value, "horasInvertidas"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "polizasSeguroID",
            value: state.polizasSeguroID,
            error: errorState.polizasSeguroID.error,
            success: state.polizasSeguroID && !errorState.polizasSeguroID.error ? true : undefined,
            helperText: errorState.polizasSeguroID.helperText,
            inputRef: polizasSeguroIDRef,
            title: "Póliza de seguro",
            placeholder: "Seleccione la póliza de seguro",
            grid: { md: 6, lg: 6 },
            tipo: "select",
            data: seleccionables.polizasSeguro,
            onChange: ({ target: { value } }) => {
                setState({ ...state, polizasSeguroID: value,subPolizaID: "0" })
            },
        },
        {
            disabled,
            id: "subPolizaID",
            value: state.subPolizaID,
            error: errorState.subPolizaID.error,
            success: state.subPolizaID && !errorState.subPolizaID.error ? true : undefined,
            helperText: errorState.subPolizaID.helperText,
            inputRef: subpolizasSeguroIDRef,
            title: "Folio de Cobertura",
            placeholder: "Seleccione el Folio de Cobertura",
            grid: { md: 6, lg: 6 },
            tipo: "select",
            data: seleccionables.subPolizasSeguro,
            onChange: ({ target: { value } }) => {
                setState({ ...state, subPolizaID: value })
            },
        }
    ];

    let accionesFormulario = [{
        accionID: state.solSegFianID ? [3] : [2],
        loader,
        disabled: lModificar,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: solicitudRef,
            mensajeCorrecto: `La solicitud de seguro ${state.solSegFianID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const inputsEstado = [
        {
            disabled: !state.solSegFianID ? true : false,
            id: "estatus",
            value: state.estatus,
            hidden: perfil === "INVITADO" ? true : false,
            error: errorState.estatus.error,
            success: state.estatus && !errorState.estatus.error ? true : undefined,
            helperText: errorState.estatus.helperText,
            inputRef: estatusRef,
            title: "Estatus*",
            placeholder: "Especifique el estatus",
            tipo: "select",
            data: perfil === "ASISTENTE" ? estadoAsistente : estadoSolicitudSeguroInputs,
            grid: { md: 4, lg: 4 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "agrupadorGeneralPartidas",
            tipo: "custom",
            leyenda: 'Destinatarios',
            sizeLetra: "1.05em",
            grid: { md: 12, lg: 12 },
            custom: (<><MultiPartidas listaDominios={seleccionables.dominios} setDataToSave={setDataToSave}
                clearData={clearData} datosGet={datosGet} notificaciones ={setNotificaciones} /> </>)
        },
        // {
        //     disabled,
        //     id: "correoEnviar",
        //     value: state.correoEnviar,
        //     title: "Correo *",
        //     grid:  perfil === "INVITADO" ? { md: 7, lg: 7 } : { md: 4, lg: 4 },
        //     placeholder: "Capture el correo",
        //     onChange: ({ target: { value } }) => setState({ ...state, correoEnviar: value }),
        //     onKeyDown: () => { return; }
        // },
        // {
        //     disabled,
        //     id: "dominio",
        //     value: state.dominio,
        //     title: "Dominio *",
        //     placeholder: "Seleccione el Dominio",
        //     tipo: "select",
        //     data: seleccionables.dominios,
        //     grid:  perfil === "INVITADO" ? { md: 5, lg: 5 } : { md: 4, lg: 4 },
        //     onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
        //     onKeyDown: () => { return; }
        // },
        // {
        //     disabled,
        //     id: "msjCorreo",
        //     value: state.msjCorreo,
        //     title: "Mensaje",
        //     hidden: perfil === "INVITADO" ? true : false,
        //     grid: { md: 12, lg: 12 },
        //     multiline: true,
        //     placeholder: "Ingrese aquí el mensaje",
        //     onChange: ({ target: { value } }) => setState({ ...state, msjCorreo: value }),
        //     onKeyDown: () => { return; }
        // },
    ];

    const inputsEstadoInvitado = [
        {
            disabled,
            id: "correoEnviar",
            value: state.correoEnviar,
            title: "Correo *",
            grid: { md: 7, lg: 7 },
            placeholder: "Capture el correo",
            onChange: ({ target: { value } }) => setState({ ...state, correoEnviar: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "dominio",
            value: state.dominio,
            title: "Dominio *",
            placeholder: "Seleccione el Dominio",
            tipo: "select",
            data: seleccionables.dominios,
            grid: { md: 5, lg: 5 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
    ];


    const accionesFormularioCambioEstado = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseEstado,
            handleFocus,
            refFocus: solSegFianIDRef,
            mensajeCorrecto: `La solicitud ${state.solSegFianID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardarEstado
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseEstado
    }];

    function handleCloseEstado() {
        setOpenEstado(false);
        setOpenEstadoInvitado(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        setClearData(!clearData);
    }

    function handleCloseEstadoInvitado() {
        setOpenEstadoInvitado(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleClose() {
        setOpen(false);
        setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        /*setDatosDetalleFormulario({solSegFianID,ramoSeguro,empresaID,solicitante, nID:null , lMostrarTabla:false, labrir:true,folioInterno : 'default'}) ;
        */

    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleCloseSolSegFianzas() {
        setOpenSolSegFianzas(false);
    }
    function handleCloseSolSegTranspMerc() {
        setOpenSolSegTranspMerc(false);
    }

    function handleCloseSolSegAuto() {

        setOpenSolSegAuto(false);
    }

    function handleCloseSolSegViajero() {
        setOpenSolSegViajero(false);
    }

    function handleCloseSolSegGastosMedicos() {
        setOpenSolSegGastosMedicos(false);
    }

    function handleCloseSolSegEquipoElectrico() {
        setOpenSolSegEquipoElectrico(false);
    }

    function handleCloseSolSegEquipoOperacion() {
        setOpenSolSegEquipoOperacion(false);
    }

    function fOcultarTabla() {
        setlMostrarFormularioCompleto(false);
    }

    function handleCloseSolSegFianHogar() {
        setOpenSolSegFianHogar(false);
    }

    async function setDatosDetalleFormulario({ solSegFianID, ramoSeguroID, ramoSeguro, empresaID, solicitante, nID, lMostrarTabla, labrir, folioInterno, polizasSeguroID, solicitud,subRamoID,subPolizaID,subRamo,seleccionTipoPoliza, codigoMoneda, departamentoID }) {

        // lMostrarFormularioCompleto = lMostrarTabla;
        if (labrir) {
            setlMostrarFormularioCompleto(true);

        } else {
            setlMostrarFormularioCompleto(lMostrarTabla);
        }
            let resultado = await getSolicitudSeguro( {solSegFianID: solSegFianID}, token)

        switch (subRamo.trim()) {
            case 'Múltiple Empresarial':
            case 'Obra Civil':
            case 'Responsabilidad Civil, Arrendamiento':
            case 'Montaje':
            case 'Riesgos Múltiples para Puertos y Terminales (RC Marinas)':
            case 'Riesgos Múltiples para Puertos y Terminales (Ro-Ro)':
            case 'Robo a bienes empresariales':
            case 'Transporte de contenedores':
            case 'Vida':
            case 'Fianza':
            case 'COBERTURA - REMOCION DE ESCOMBRO':
            case 'COBERTURA - BARDAS O MALLAS PERIMETRALES':
            case 'COBERTURA - BIENES A LA INTEMPERIE':
            case 'COBERTURA - INVENTARIO (MERCANCIAS ALMACENADAS)':
            case 'COBERTURA - RESPONSABILIDAD CIVIL':
            case 'COBERTURA - ROBO CON VIOLENCIA':
            case 'COBERTURA - CRISTALES':
                setDatosDetalle({
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    ramoSeguro: ramoSeguro,
                    pSolSegFianID: solSegFianID,
                    pEmpresaID: empresaID,
                    pUsuarioSolicita: solicitante,
                    pFianzaID: nID,
                    mostrarTabla: lMostrarTabla,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegFianzas,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pTitulo: ramoSeguro,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda
                });
                setOpenSolSegFianzas(true);
                break;
            case 'Transportes Mercancía':
                setDatosDetalle({
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    plUtilizaProps: true,
                    mostrarTabla: lMostrarTabla,
                    ramoSeguro: ramoSeguro,
                    pSolSegFianID: solSegFianID,
                    pActivoAsegID: nID,
                    pEmpresaID: empresaID,
                    pUsuarioSolicita: solicitante,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegTranspMerc,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda
                });
                setOpenSolSegTranspMerc(true);
                break;
            case 'Automóviles Residentes':
                setDatosDetalle({
                    ramoSeguro: ramoSeguro,
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    mostrarTabla: lMostrarTabla,
                    pSolSegFianID: solSegFianID,
                    pAutosID: nID,
                    pEmpresaID: empresaID,
                    pResponsable: solicitante,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegAuto,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda
                });
                setOpenSolSegAuto(true);
                break;
            case 'Equipo de Operación': //creo que este ramo ya no existe
            case 'Equipo de Contratistas y Maq. Pesada':
            case 'Flotilla de automóviles (Camiones Carga General)':
            case 'Múltiple Empresarial- Rotura':
            case 'COBERTURA - ROTURA DE MAQUINARIA':
                setDatosDetalle({
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    mostrarTabla: lMostrarTabla,
                    ramoSeguro: ramoSeguro,
                    pSolSegFianID: solSegFianID,
                    pEquipoOperID: nID,
                    pEmpresaID: empresaID,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegEquipoOperacion,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda,
                });
                setOpenSolSegEquipoOperacion(true);
                break;
            case 'Equipo Electrónico':
            case 'Equipo Electronico':
            case 'COBERTURA - EQUIPO ELECTRÓNICO':
                setDatosDetalle({
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    mostrarTabla: lMostrarTabla,
                    ramoSeguro: ramoSeguro,
                    pSolSegFianID: solSegFianID,
                    pEquipoElecID: nID,
                    pEmpresaID: empresaID,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegEquipoElectrico,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda
                });

                setOpenSolSegEquipoElectrico(true);
                break;
            case 'Gastos Médicos mayores':
                setDatosDetalle({
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    mostrarTabla: lMostrarTabla,
                    ramoSeguro: ramoSeguro,
                    pSolSegFianID: solSegFianID,
                    pGtosMedMayID: nID,
                    pEmpresaID: empresaID,
                    pUsuarioSolicita: solicitante,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegGastosMedicos,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda
                });
                setOpenSolSegGastosMedicos(true);
                break;
            case 'Seguro de Viajero':
                setDatosDetalle({
                    pSolicitud: solicitud,
                    pRamoSeguroID: ramoSeguroID,
                    mostrarTabla: lMostrarTabla,
                    ramoSeguro: ramoSeguro,
                    pSolSegFianID: solSegFianID,
                    pSegViajeroID: nID,
                    pEmpresaID: empresaID,
                    pUsuarioSolicita: solicitante,
                    pfolioFormateado: folioInterno,
                    pURLPolizasSeguroID: polizasSeguroID,
                    onCerrarModal: handleCloseSolSegViajero,
                    onOcultarTabla: fOcultarTabla,
                    pEstatus: resultado.estatus,
                    pSubRamoID:subRamoID,
                    pSubpolizaID:subPolizaID,
                    seleccionTipoPoliza,
                    codigoMoneda
                });
                setOpenSolSegViajero(true);
                break;
                case 'Hogar':
                    setDatosDetalle({
                        pSolicitud: solicitud,
                        pRamoSeguroID: ramoSeguroID,
                        plUtilizaProps: true,
                        mostrarTabla: lMostrarTabla,
                        ramoSeguro: ramoSeguro,
                        pSolSegFianID: solSegFianID,
                        pActivoAsegID: nID,
                        pEmpresaID: empresaID,
                        pUsuarioSolicita: solicitante,
                        pfolioFormateado: folioInterno,
                        pURLPolizasSeguroID: polizasSeguroID,
                        onCerrarModal: handleCloseSolSegFianHogar,
                        onOcultarTabla: fOcultarTabla,
                        pEstatus: resultado.estatus,
                        pSubRamoID:subRamoID,
                        pSubpolizaID:subPolizaID,
                        seleccionTipoPoliza,
                        codigoMoneda
                    });
                    setOpenSolSegFianHogar(true);
                    break;
                case 'COBERTURA - CONTENIDOS':
                    setDatosDetalle({
                        pSolicitud: solicitud,
                        pRamoSeguroID: ramoSeguroID,
                        plUtilizaProps: true,
                        mostrarTabla: lMostrarTabla,
                        ramoSeguro: ramoSeguro,
                        pSolSegFianID: solSegFianID,
                        pActivoAsegID: nID,
                        pEmpresaID: empresaID,
                        pUsuarioSolicita: solicitante,
                        pfolioFormateado: folioInterno,
                        pEstatus: resultado.estatus,
                        pSubRamoID:subRamoID,
                        pSubpolizaID:subPolizaID,
                        seleccionTipoPoliza,
                        codigoMoneda,
                        departamentoID
                    });
                    setOpenSolSegFianContenidos(true);
                    break;
                case 'COBERTURA - EDIFICIO':
                    setDatosDetalle({
                        pSolicitud: solicitud,
                        pRamoSeguroID: ramoSeguroID,
                        plUtilizaProps: true,
                        mostrarTabla: lMostrarTabla,
                        ramoSeguro: ramoSeguro,
                        pSolSegFianID: solSegFianID,
                        pActivoAsegID: nID,
                        pEmpresaID: empresaID,
                        pUsuarioSolicita: solicitante,
                        pfolioFormateado: folioInterno,
                        pEstatus: resultado.estatus,
                        pSubRamoID:subRamoID,
                        pSubpolizaID:subPolizaID,
                        seleccionTipoPoliza,
                        codigoMoneda,
                        departamentoID
                    });
                    setOpenSolSegFianEdificio(true);
                    break;
            default:
                break;
        }

        if (datosDetalle.length === 0) {
            //setlMostrarFormularioCompleto(false);
            setOpenSolSegFianzas(false);
            setOpenSolSegTranspMerc(false);
            setOpenSolSegAuto(false);
            setOpenSolSegViajero(false);
            setOpenSolSegGastosMedicos(false);
            setOpenSolSegEquipoElectrico(false);
            setOpenSolSegEquipoOperacion(false);
            setOpenSolSegFianHogar(false);
        }
    }

    function setRedireccionarFormulario({ solSegFianID, ramoSeguro, empresaID, solicitante, folioInterno, polizasSeguroID }) {
        let seed = "scrumweb";
        const datos = `{ "pURLSolSegFianID": "${solSegFianID}", "pEmpresaID": "${empresaID}","pUsuarioSolicita": "${solicitante}","datosDetalle": "${datosDetalle}","pfolioFormateado": "${folioInterno}","pURLPolizasSeguroID": "${polizasSeguroID}"  }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        // history.push(`/gen/transporteMercancia/${url}`);
        switch (ramoSeguro.trim()) {
            case 'Múltiple Empresarial':
            case 'Obra Civil':
            case 'Responsabilidad Civil, Arrendamiento':
            case 'Montaje':
            case 'Riesgos Múltiples para Puertos y Terminales (RC Marinas)':
            case 'Riesgos Múltiples para Puertos y Terminales (Ro-Ro)':
            case 'Robo a bienes empresariales':
            case 'Transporte de contenedores':
            case 'Vida':
            case 'Fianza':
                history.push(`/gen/OtrosSegurosyFianzas/${url}`);
                break;
            case 'Transportes Mercancía':
                history.push(`/gen/transporteMercancia/${url}`);
                break;
            case 'Automóviles Residentes':
                history.push(`/gen/SolSegFianAuto/${url}`);
                break;
            case 'Equipo de Operación':
            case 'Equipo de Contratistas y Maq. Pesada':
            case 'Flotilla de automóviles (Camiones Carga General)':
            case 'Múltiple Empresarial- Rotura':
                history.push(`/gen/SolSegFianEquipoOper/${url}`);
                break;
            case 'Equipo Electrónico':
                history.push(`/gen/SolSegFianEquipoElec/${url}`);
                break;
            case 'Gastos Médicos mayores':
                history.push(`/gen/gastosmedicos/${url}`);
                break;
            case 'Seguro de viajero':
                history.push(`/gen/SeguroViajero/${url}`);
                break;
            case 'Hogar':
                    history.push(`/gen/Hogar/${url}`);
                break;
            default:
                break;
        }
    }

    function setRedireccionarTrazabilidad({ solSegFianID, polizasSeguroID, controlPagoID }) {
        let seed = "scrumweb";
        const datos = `{ "pSolSegFianID": "${solSegFianID}", "pControlPagoID": "${controlPagoID}","pPolizasSeguroID": "${polizasSeguroID}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");

        history.push(`/gen/TrazabilidadSolicitudesSeguro/${url}`);

    }

    async function InicializaParametros() {
        let res = {};
        if (Array.isArray(seleccionables.ramoSeguros) && seleccionables.ramoSeguros.length === 1) {
            res = { ...res, ramoSeguro: seleccionables.ramoSeguros[0].label, ramoSeguroID: seleccionables.ramoSeguros[0].value };
        }

        if (Array.isArray(seleccionables.empresas) && seleccionables.empresas.length === 1) {
            res = { ...res, empresaID: seleccionables.empresas[0].value };
        }

        if (Array.isArray(seleccionables.departamentos) && seleccionables.departamentos.length === 1) {
            res = { ...res, departamentoID: seleccionables.departamentos[0].value };
        }

        return res;
    };


    useEffect(() => {
        async function ftEffect() {
            try {
                let ramoSeguros = await seleccionRamoSeguro({ filtro: { fijo: true } }, token);
                let ramoSegurosCoberturas = await seleccionRamoSeguroCoberturas({ filtro: { fijo: true } }, token);
                let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                let departamentos = await seleccionDepartamento({ filtro: {} }, token);

                let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== null ? parseInt(state.empresaID) : -1, ramoSeguroID: state.ramoSeguroID !== null ? parseInt(state.ramoSeguroID) : -1, tipoPoliza: "PRINCIPAL" } }, token);
                let subPolizasSeguro = await seleccionableSubPolizasSeguro({ filtro: { polizasSeguroID: state.polizasSeguroID === null ? null : Number(state.polizasSeguroID) } }, token);
                subPolizasSeguro.unshift({label:"No Aplica", value:"0"})

                let dominios = await ConsultasDominios.seleccionDominios({}, token);


                if (ramoSeguros.length === 1) {

                    setState(state => (
                        {
                            ...state, ramoSeguroID: ramoSeguros[0].value
                        })
                    )
                }

                if (empresas.length === 1) {

                    setState(state => (
                        {
                            ...state, empresaID: empresas[0].value
                        })
                    )
                }

                if (departamentos.length === 1) {

                    setState(state => (
                        {
                            ...state, departamentoID: departamentos[0].value
                        })
                    )
                }

                if (polizasSeguro.length === 1) {

                    setState(state => (
                        {
                            ...state, polizasSeguroID: polizasSeguro[0].value
                        })
                    )
                }

                setSeleccionables({ ramoSeguros,ramoSegurosCoberturas, empresas, departamentos, polizasSeguro, dominios,subPolizasSeguro });


            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, state.empresaID, state.ramoSeguroID, state.polizasSeguroID]);

    useEffect(() => {
        async function ftEffect() {
            try {
                if (registroID === null) {
                    setRegistroID(null);
                }
                if (url !== ":params" && (!validarParams)) {
                    setRegistroID(auxRegistroIDEffect);
                }
                setActualizar(!actualizar)
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [registroID, auxRegistroIDEffect]);

    function AuxDescargar(solSegFianID, ramoSeguro, formatoReporte = 'PDF', estatus, estatusInvitado, subRamo) {
        setdatosPDf({ "solSegFianID": solSegFianID, "ramoSeguro": ramoSeguro,"estatus": estatus,"estatusInvitado": estatusInvitado, formatoReporte,subRamo });
        datosPDF = { "solSegFianID": solSegFianID, "ramoSeguro": ramoSeguro,"estatus": estatus,"estatusInvitado": estatusInvitado, formatoReporte,subRamo };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: `¿Deseas generar y descargar el formato de impresión de la solicitud de seguro para ${ramoSeguro}?`, tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión de solicitud de seguro ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: `Se descargó el formato de impresión de la solicitud de seguro`, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };


    async function DescargarPdf(enviarCorreo = false, correo) {

        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        if (enviarCorreo) {

            formdata.append("solSegFianID", correo.solSegFianID);
            formdata.append("tipoSolicitud", correo.tipoSolicitud);
            formdata.append("formatoReporte", correo.formatoReporte);
            formdata.append("empresaID", correo.empresaID);
            formdata.append("nombreUsuario", correo.solicitante);
            formdata.append("folioInterno", correo.folioInterno);
            formdata.append("estatus", correo.estatus);
            formdata.append("perfil", perfil);
            formdata.append("IDsPartidas", JSON.stringify(correo.Arreglo));
            if (perfil === "INVITADO"){
                let ramosValidarCorreoAuditoria = ["Equipo Electrónico","Equipo de Contratistas y Maq. Pesada", "Múltiple Empresarial- Rotura","Automóviles Residentes","Flotilla de automóviles (Camiones Carga General)"]
                if (ramosValidarCorreoAuditoria.includes(correo.tipoSolicitud)) {
                    if (correo.estatus === "AUTORIZADA" && correo.tipoSolicitud !='Flotilla de automóviles (Camiones Carga General)') {
                        formdata.append("correo", `${state.correoEnviar}${state.dominio};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                    }
                    else if (correo.estatus === "RECHAZADA" && ramosValidarCorreoAuditoria.includes(correo.tipoSolicitud)) {
                        formdata.append("correo", `${state.correoEnviar}${state.dominio};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                    }
                    else {
                        //formdata.append("correo", `${state.correoEnviar}${state.dominio};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                        formdata.append("correo", `${state.correoEnviar}${state.dominio};Seguros@atpaltamira.com.mx`);
                        formdata.append("correo", `${state.correoEnviar}${state.dominio};`);
                    }
                }
                else {
                    //formdata.append("correo", `${state.correoEnviar}${state.dominio};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                    formdata.append("correo", `${state.correoEnviar}${state.dominio};Seguros@atpaltamira.com.mx;`);
                    formdata.append("correo", `${state.correoEnviar}${state.dominio};`);
                }
            }

        }else {
            formdata.append("solSegFianID", datosPDF.solSegFianID);
            formdata.append("tipoSolicitud", datosPDF.subRamo);
            formdata.append("formatoReporte", datosPDF.formatoReporte);
            formdata.append("estatus", perfil === "Invitado" ? datosPDF.estatusInvitado : datosPDF.estatus);
        }
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = `Solicitud ${datosPDF.ramoSeguro}`;
        if (!enviarCorreo) //Si es falso
        {
            await fetch(`${urlServidor}/service/reportes/solicitudSeguro`, requestOptions)
                .then(response => {
                    if (!response.ok) return response.json();
                    return response.blob()
                })
                .then(result => {
                    let { error, mensaje } = result;
                    if (error) throw new Error(mensaje);
                    showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                    setAlert(null)
                })
                .catch(({ message }) => { throw new Error(message) });
        } else {
            for (let i = 0; i < dataToSave.length; i++) {
                const elemento = dataToSave[i];
                let ramosValidarCorreoAuditoria = ["Equipo Electrónico", "Equipo de Contratistas y Maq. Pesada", "Múltiple Empresarial- Rotura", "Automóviles Residentes", "Flotilla de automóviles (Camiones Carga General)"]
                if (ramosValidarCorreoAuditoria.includes(correo.tipoSolicitud)) {
                    if (correo.estatus === "AUTORIZADA" && correo.tipoSolicitud != 'Flotilla de automóviles (Camiones Carga General)') {
                        formdata.append("correo", `${elemento.correo}${elemento.dominioID};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                    }
                    else if (correo.estatus === "RECHAZADA" && ramosValidarCorreoAuditoria.includes(correo.tipoSolicitud)) {
                        formdata.append("correo", `${elemento.correo}${elemento.dominioID};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                    }
                    else {
                        //formdata.append("correo", `${elemento.correo}${elemento.dominioID};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                        formdata.append("correo", `${elemento.correo}${elemento.dominioID};Seguros@atpaltamira.com.mx;`);
                        formdata.append("correo", `${elemento.correo}${elemento.dominioID};`);
                    }
                }
                else {
                    //formdata.append("correo", `${elemento.correo}${elemento.dominioID};Seguros@atpaltamira.com.mx;AuditoriaOpva@atpaltamira.com.mx`);
                    formdata.append("correo", `${elemento.correo}${elemento.dominioID};Seguros@atpaltamira.com.mx`);
                    formdata.append("correo", `${elemento.correo}${elemento.dominioID};`);
                }
                formdata.append("msjCorreo", elemento.mensaje);
                requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata
                };
                await fetch(`${urlServidor}/service/reportes/solicitudSeguro`, requestOptions)
                    .then(response => {
                        return response.json();
                    })
                    .then(resp => {
                        let { error, mensaje } = resp;
                        if (error) throw new Error(mensaje);
                        return resp;
                    })
                    .catch(({ message }) => { throw new Error(message) });
                formdata.delete("correo");
                formdata.delete("msjCorreo");
            }
        }

    }

    async function handleValidaciones({ empresaID,
        solicitud, ramoSeguroID, subRamoID,
        fechaSolicitud, solicitante, puesto,
        departamentoID, observaciones, nomAutoriza, horasInvertidas }) {

        let error;
        let validaciones = [
            {
                condicion: empresaID === null || empresaID === undefined,
                keyError: "empresaID",
                mensajeError: "La empresa es un dato obligatorio. Debe seleccionar una empresa"
            },
            {
                condicion: !solicitud || trim(solicitud) == "",
                ref: solicitudRef, keyError: "solicitud",
                mensajeError: "El tipo de solicitud es dato obligatorio. Debe seleccionar un tipo"
            },
            {
                condicion: solicitud && trim(solicitud).length > 15,
                ref: solicitudRef, keyError: "solicitud",
                mensajeError: "El dato de solictud excede el tamaño"
            },
            {
                condicion: !ramoSeguroID,
                keyError: "ramoSeguroID",
                mensajeError: "El ramo de seguro es dato obligatorio. Debe seleccionar un ramo de seguro"
            },
            {
                condicion: !subRamoID,
                keyError: "subRamoID",
                mensajeError: "La cobertura es dato obligatorio. Debe seleccionar una cobertura"
            },
            {
                condicion: !solicitante || trim(solicitante) === "", ref: solicitanteRef, keyError: "solicitante",
                mensajeError: "El nombre del solicitante es dato obligatorio. Debe especificar el nombre"
            },
            {
                condicion: !puesto || trim(puesto) === "", ref: puestoRef, keyError: "puesto",
                mensajeError: "El puesto es dato obligatorio. Debe especificar el puesto."
            },
            {
                condicion: departamentoID === null || departamentoID === undefined,
                keyError: "departamentoID",
                mensajeError: "El departamento es dato obligatorio. Debe seleccionar un departamento"
            },
            {
                condicion: !observaciones || trim(observaciones) === "", ref: observacionesRef, keyError: "observaciones",
                mensajeError: "Las observaciones es dato obligatorio. Debe especificar las observaciones."
            },
            {
                condicion: !nomAutoriza || trim(nomAutoriza) === "", ref: nomAutorizaRef, keyError: "nomAutoriza",
                mensajeError: "El nombre de quien autoriza es dato obligatorio. Debe especificar quien autoriza."
            },
            {
                condicion: !fechaSolicitud, ref: fechaSolicitudRef, keyError: "fechaSolicitud",
                mensajeError: "La fecha de solicitud es dato obligatorio. Debe especificar la fecha."
            },
            horasInvertidas !== "" && {
                condicion: !rgxdeInt2.test(horasInvertidas), ref: horasInvertidasRef, keyError: "horasInvertidas",
                mensajeError: "El formato de la hora es inválido"
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftGuardar() {
        async function ftGuardar() {

            let { solSegFianID, empresaID,
                solicitud, ramoSeguroID, subRamoID,
                fechaSolicitud, solicitante, puesto,
                departamentoID, observaciones, nomAutoriza,
                ramoSeguro, subRamo,estatus, horasInvertidas, polizasSeguroID, subPolizaID, correo, nombreUsuario, folioInterno, correoEnviar,msjCorreo } = state;


            let error = await handleValidaciones({
                empresaID,
                solicitud, ramoSeguroID,subRamoID,
                fechaSolicitud, solicitante, puesto,
                departamentoID, observaciones, nomAutoriza, horasInvertidas/* : horasInvertidas ? parseFloat(horasInvertidas) : 0 */
            });
            let lAbrir = solSegFianID ? false : true;
            if (error) return error;

            if (!solSegFianID) {
                lAbrir = true;
            }


            if (solSegFianID) {
                let valor = await updateSolSegFian({
                    input: {
                        solSegFianID, empresaID, ramoSeguroID, departamentoID, fechaSolicitud, solicitud: trim(solicitud), solicitante: trim(solicitante),
                        puesto: trim(puesto), observaciones: trim(observaciones),
                        nomAutoriza: trim(nomAutoriza), estatus, horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                        polizasSeguroID: parseInt(polizasSeguroID),
                        mensajeCorreo:msjCorreo, subRamoID: subRamoID,subPolizaID: subPolizaID
                    }
                }, token);
            }
            else {
                let valor = await addSolicitudSeguro({
                    input: {
                        solSegFianID: null, empresaID, ramoSeguroID, departamentoID, fechaSolicitud, solicitud: trim(solicitud), solicitante: trim(solicitante),
                        puesto: trim(puesto), observaciones: trim(observaciones),
                        nomAutoriza: trim(nomAutoriza), estatus, horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                        polizasSeguroID: parseInt(polizasSeguroID),
                        mensajeCorreo:msjCorreo,subRamoID: subRamoID,subPolizaID: subPolizaID
                    }
                }, token);


                setDatosDetalleFormulario({ solSegFianID: valor.solSegFianID, ramoSeguroID: valor.ramoSeguroID, ramoSeguro: valor.ramoSeguro, empresaID: valor.empresaID, solicitante: valor.solicitante, nID: null, lMostrarTabla: false, labrir: true, folioInterno: valor.folioInterno, polizasSeguroID: valor.polizasSeguroID, solicitud: valor.solicitud, subRamoID: subRamoID,subPolizaID: subPolizaID, subRamo: valor.subRamo, codigoMoneda: valor.codigoMoneda, departamentoID: valor.departamentoID});

            }

        }

        return ftGuardar();
    }



    function ftGuardarEstado() {
        async function ftGuardarEstado() {
            

                
            let {   solSegFianID, empresaID,
                solicitud, ramoSeguroID,
                fechaSolicitud, solicitante, puesto,
                departamentoID, observaciones, nomAutoriza,
                ramoSeguro, estatus, horasInvertidas, polizasSeguroID, correo, nombreUsuario, folioInterno, correoEnviar,msjCorreo, subRamoID,subPolizaID,subRamo
             } = state;


            let  a = await getAllSolOtrosSegurosyFianzas({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  b = await getAllTransporteMercancia({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  c = await getAllSegViajero({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  d = await getAllSolSegFianEquipoElec({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  e = await getAllSolSegFianEquipoOper({filtro: {solSegFianID: parseInt(solSegFianID)}},token);                
            let  f = await getAllGastosMedicos({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  g = await getAllSolSegFianAuto({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  h = await getAllSolSegFianHogar({filtro: {solSegFianID: parseInt(solSegFianID)}},token);
            let  edificios = await getAllEdificios({filtro: {solsegfianID: parseInt(solSegFianID)}},token);
            let  contenidos = await getAllContenidos({filtro: {solsegfianID: parseInt(solSegFianID)}},token);
           
            a = a.map(({fianzaID: value}) => ({value}));
            b = b.map(({transpMciasID: value}) => ({value}));
            c = c.map(({segViajeroID: value}) => ({value}));
            d = d.map(({equipoElecID: value}) => ({value}));
            e = e.map(({equipoOperID: value}) => ({value}));
            f = f.map(({gtosMedMayID: value}) => ({value}));
            g = g.map(({autosID: value}) => ({value}));
            h = h.map(({hogarID: value}) => ({value}));
            edificios = edificios.map(({edificionID: value}) => ({value}));
            contenidos = contenidos.map(({contenidoID: value}) => ({value}));
 
            for (let i = 0; i < a.length; i++) {
                Arreglo.push(a[i])
            }                 
            for (let i = 0; i < b.length; i++) {
                Arreglo.push(b[i])
            }                 
            for (let i = 0; i < c.length; i++) {
                Arreglo.push(c[i])
            }                 
            for (let i = 0; i < d.length; i++) {
                Arreglo.push(d[i])
            }           
            for (let i = 0; i < e.length; i++) {
                Arreglo.push(e[i])
            }         
            for (let i = 0; i < f.length; i++) {
                Arreglo.push(f[i])
            }           
            for (let i = 0; i < g.length; i++) {
                Arreglo.push(g[i])
            }
            for (let i = 0; i < h.length; i++) {
                Arreglo.push(h[i])
            }
            for (let i = 0; i < edificios.length; i++) {
                Arreglo.push(edificios[i])
            }
            for (let i = 0; i < contenidos.length; i++) {
                Arreglo.push(contenidos[i])
            }

            if (Arreglo.length === 0) throw new Error("No tiene informacion registrada en las partidas de su solicitud")
 
            // if (perfil !== "INVITADO") {
            //    if ((a.length + b.length + c.length + d.length + e.length + f.length + g.length + h.length + edificios.length + contenidos.length) === 0) throw new Error("No tiene informacion registrada en las partidas de su solicitud")
            // }

            let errorEnviarCorreo = false;
            if (perfil === "INVITADO") {
                let validacorreo = correoEnviar.includes('@');
                if (trim(correoEnviar) == "" || validacorreo) throw new Error("Debe especificar un correo")
                // let {listado: listadoAdjuntos} = await tablaServerStorage({filtro:{origenID: state.solSegFianID, tabla: "Solicitudes Encabezado", limite: 100, pagina:1}},token)
                // if (listadoAdjuntos.length === 0) throw new Error("No hay datos adjuntos");
            }
            else{
                for (let j = 0; j < dataToSave.length; j++) {
                    const element = dataToSave[j];
                    if (trim(element.correo) === "" || element.correo === null) errorEnviarCorreo = true;
                    if (trim(element.dominioID) === "" || element.correo === null) errorEnviarCorreo = true;
                }
                if (errorEnviarCorreo) throw new Error("Debe especificar un correo y dominio en cada destinatario");
            }

        if(perfil === "INVITADO"){
            let estatusInvitado2 = "EN PROCESO";
            await estatusInvitado({
                input: {
                    solSegFianID: parseInt(solSegFianID),
                    estatusInvitado: estatusInvitado2,
                    estatus: estatusInvitado2
                }
            }, token);
        
            await DescargarPdf(true, { solSegFianID, tipoSolicitud: subRamo, empresaID, formatoReporte: 'ambos', correo, solicitante, folioInterno,msjCorreo, estatus: estatusInvitado2, Arreglo });


        }else{

            await DescargarPdf(true, { solSegFianID, tipoSolicitud: subRamo, empresaID, formatoReporte: 'ambos', correo, solicitante, folioInterno,msjCorreo, Arreglo,estatus });

            let valor = await updateSolSegFian({
                input: {
                    solSegFianID, empresaID, ramoSeguroID, departamentoID, fechaSolicitud, solicitud: trim(solicitud), solicitante: trim(solicitante),
                    puesto: trim(puesto), observaciones: trim(observaciones),
                    nomAutoriza: trim(nomAutoriza), estatus, horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                    polizasSeguroID: parseInt(polizasSeguroID),
                    mensajeCorreo:msjCorreo,
                    subRamoID: subRamoID,subPolizaID: subPolizaID
                }
            }, token);
        }
        }

        return ftGuardarEstado();
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSolicitudSeguro}
            token={token}
            aFechas={fechasFiltro}
            parametrosFijos={{ solSegFianID: registroID, usuarioID: perfil === 'INVITADO' ? usuario.usuarioID : null }}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={solicitudRef}
        />


        <FormularioModal
            open={openEstadoInvitado}
            title={"Cambio de Estado"}
            onClose={handleCloseEstadoInvitado}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsEstadoInvitado}
            acciones={accionesFormularioCambioEstado}
            focus={solSegFianIDRef}
        />

        <FormularioModal
            open={openEstado}
            title={"Cambio de Estado"}
            onClose={handleCloseEstado}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsEstado}
            acciones={accionesFormularioCambioEstado}
            focus={solSegFianIDRef}
        />


        {
            
            openTrazabilidad && <Modal maxWidth={true} onClose={() => {setOpenTrazabilidad(false)}}>

                <TrazabilidadSolicitudesSeguro datosDetalle={datosDetalle} setOpenTrazabilidad={() => setOpenTrazabilidad(false)
                } />

            </Modal>
        }
        {
            openSolSegFianzas && <Modal maxWidth={true} onClose={() => {setOpenSolSegFianzas(false)}}>

                <OtrosSegurosyFianzas datosDetalle={datosDetalle} setOpenSolSegFianzas={() => setOpenSolSegFianzas(false)
                } lMostrarFormularioCompleto />

            </Modal>
        }

        {
            !lMostrarFormularioCompleto && openSolSegFianzas &&

            <OtrosSegurosyFianzas datosDetalle={datosDetalle} setOpenSolSegFianzas={() => setOpenSolSegFianzas(false)
            } lMostrarFormularioCompleto />

        }

        {
            lMostrarFormularioCompleto && openSolSegTranspMerc && <Modal maxWidth={true} onClose={() => {setOpenSolSegTranspMerc(false)}}>

                <TransporteMercancia datosDetalle={datosDetalle} setOpenSolSegTranspMerc={() => setOpenSolSegTranspMerc(false)
                } lMostrarFormularioCompleto />
            </Modal>
        }
        {
            !lMostrarFormularioCompleto && openSolSegTranspMerc &&

            <TransporteMercancia datosDetalle={datosDetalle} setOpenSolSegTranspMerc={() => setOpenSolSegTranspMerc(false)
            } lMostrarFormularioCompleto />

        }
        {
            lMostrarFormularioCompleto && openSolSegAuto && <Modal maxWidth={true} onClose={() => {setOpenSolSegAuto(false)}}>

                <SolSegFianAuto datosDetalle={datosDetalle} setOpenSolSegAuto={() => setOpenSolSegAuto(false)
                } lMostrarFormularioCompleto />
            </Modal>
        }

        {
            !lMostrarFormularioCompleto && openSolSegAuto && <Modal maxWidth={true} onClose={() => {setOpenSolSegAuto(false)}}>

                <SolSegFianAuto datosDetalle={datosDetalle} setOpenSolSegAuto={() => setOpenSolSegAuto(false)
                } lMostrarFormularioCompleto />
            </Modal>
        }

        {
            lMostrarFormularioCompleto && openSolSegEquipoElectrico && <Modal maxWidth={true} onClose={() => {setOpenSolSegEquipoElectrico(false)}}>

                <SolSegFianEquipoElec datosDetalle={datosDetalle} setOpenSolSegEquipoElectrico={() => setOpenSolSegEquipoElectrico(false)} lMostrarFormularioCompleto />
            </Modal>
        }
        {
            !lMostrarFormularioCompleto && openSolSegEquipoElectrico &&

            <SolSegFianEquipoElec datosDetalle={datosDetalle} setOpenSolSegEquipoElectrico={() => setOpenSolSegEquipoElectrico(false)} lMostrarFormularioCompleto />

        }
        {
            lMostrarFormularioCompleto && openSolSegEquipoOperacion && <Modal maxWidth={true} onClose={() => {setOpenSolSegEquipoOperacion(false)}}>

                <SolSegFianEquipoOper datosDetalle={datosDetalle} setOpenSolSegEquipoOperacion={() => setOpenSolSegEquipoOperacion(false)} lMostrarFormularioCompleto />
            </Modal>
        }
        {
            !lMostrarFormularioCompleto && openSolSegEquipoOperacion &&

            <SolSegFianEquipoOper datosDetalle={datosDetalle} setOpenSolSegEquipoOperacion={() => setOpenSolSegEquipoOperacion(false)} lMostrarFormularioCompleto />

        }
        {
            lMostrarFormularioCompleto && openSolSegGastosMedicos && <Modal maxWidth={true} onClose={() => {setOpenSolSegGastosMedicos(false)}}>

                <GastosMedicos datosDetalle={datosDetalle} setOpenSolSegGastosMedicos={() => setOpenSolSegGastosMedicos(false)
                } lMostrarFormularioCompleto />
            </Modal>
        }
        {
            !lMostrarFormularioCompleto && openSolSegGastosMedicos &&

            <GastosMedicos datosDetalle={datosDetalle} setOpenSolSegGastosMedicos={() => setOpenSolSegGastosMedicos(false)
            } lMostrarFormularioCompleto />

        }
        {
            lMostrarFormularioCompleto && openSolSegViajero && <Modal maxWidth={true} onClose={() => {setOpenSolSegViajero(false)}}>
                
                <SeguroViajero datosDetalle={datosDetalle} setOpenSolSegViajero={() => setOpenSolSegViajero(false)
                } lMostrarFormularioCompleto />
            </Modal>
        }
        {
            !lMostrarFormularioCompleto && openSolSegViajero &&

            <SeguroViajero datosDetalle={datosDetalle} setOpenSolSegViajero={() => setOpenSolSegViajero(false)
            } lMostrarFormularioCompleto />

        }
        {
            lMostrarFormularioCompleto && openSolSegFianHogar && <Modal maxWidth={true} onClose={() => {setOpenSolSegFianHogar(false)}}>
                
                <SolSegHogar datosDetalle={datosDetalle} setOpenSolSegFianHogar={() => setOpenSolSegFianHogar(false)
                } lMostrarFormularioCompleto />
            </Modal>
        }
        {
            !lMostrarFormularioCompleto && openSolSegFianHogar &&

            <SolSegHogar datosDetalle={datosDetalle} setOpenSolSegFianHogar={() => setOpenSolSegFianHogar(false)
            } lMostrarFormularioCompleto />

        }
        {
            openSolSegFianContenidos &&
            <Modal maxWidth={true} onClose={() => { setOpenSolSegFianContenidos(false) }}>
                <SolSegFianContenidos datosDetalle={datosDetalle} setOpenSolSegFianContenidos= {setOpenSolSegFianContenidos}/>
            </Modal>
        }
        {
            openSolSegFianEdificio &&
            <Modal maxWidth={true} onClose={() => { setOpenSolSegFianEdificio(false) }}>
                <SolSegFianEdificio datosDetalle={datosDetalle} setOpenSolSegFianEdificio= {setOpenSolSegFianEdificio}/>
            </Modal>
        }
        {
            openDocumentacion &&
            <Modal maxWidth={true} onClose={() => { setOpenDocumentacion(false) }}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)} />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default SolicitudesSeguros;
