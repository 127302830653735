import {
    primaryColor,
    // warningColor,
    dangerColor,
    successColor,
    // infoColor,
    // roseColor,
    // grayColor,
    // blackColor,
    whiteColor
  } from "../../../../assets/jss/material-dashboard-pro-react.js";

export const colourStyles = ({ error, success }) => {

    return {
        control: (styles, { isDisabled, isFocused, isSelected }) => {
            let newStyles = {};

            newStyles = {
                ...newStyles,
                borderColor: (isSelected || isFocused || isDisabled) ? styles.borderColor : (error ? dangerColor[3] : (success ? successColor[3] : styles.borderColor)),
                borderWidth: (isSelected || isFocused || isDisabled) ? styles.borderWidth : (error || success ? "thin" : styles.borderWidth)
            }

            return { ...styles, ...newStyles };
        },
        option: (styles, { isFocused, isSelected }) => {
            let newStyles = {};

            newStyles = {
                ...newStyles,
                backgroundColor: isFocused ? primaryColor[1] : (isSelected ? primaryColor[4] : styles.backgroundColor),
                color: isFocused ? whiteColor : (isSelected ? whiteColor : styles.color)
            }

            return { ...styles, ...newStyles };
        },
        multiValue: (styles) => {
            let newStyles = {};

            newStyles = {
                ...newStyles,
            }

            return { ...styles, ...newStyles };
        },
        multiValueLabel: (styles) => {
            let newStyles = {};

            newStyles = {
                ...newStyles,
                backgroundColor: whiteColor,
                borderColor: primaryColor[1]
            }

            return { ...styles, ...newStyles };
        },
        multiValueRemove: (styles) => {
            let newStyles = {};

            newStyles = {
                ...newStyles,
                backgroundColor: whiteColor,
            }

            return { ...styles, ...newStyles };
        },
    }
}

export const themeStyles = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary: primaryColor[4],
        primary25: primaryColor[1],
    },
})
