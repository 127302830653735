import React, { useState, useRef, useEffect } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// master components
import Tabla from '../../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../../_layout/masterComponents/FormularioModal';

// Redux
import { useSelector } from "react-redux";

// generic components
import SweetAlert from '../../../../_layout/genericComponents/ModalConfirmacion';

import { NetworkError } from '../../../../_layout/genericComponents/Metodos';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import HistoryRounded from '@material-ui/icons/HistoryRounded';


import { getHistorialParidades, getAllHistorialParidades, tablaHistorialParidades } from "../../../../querys/HistorialParidades";

import { addHistorialParidades, updateHistorialParidades, deleteHistorialParidades } from '../../../../mutations/HistorialParidades';

import { seleccionParidadDivisa, seleccionDivisa } from "../../../../querys/Divisa";

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../../_layout/helpers/handles';
import { info, danger, success, primary, rose } from '../../../../_layout/helpers/colores';
import { Keyboard } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import Encriptar from '@renedelangel/helpers/lib/Encriptar';
import moment from 'moment';
import * as Permisos from '../../Permisos';

const { Encrypt, Decrypt } = Encriptar;

const { trim } = Validaciones;

function HistorialParidades() {

    let history = useHistory();
    let { params } = useParams();
    let url = params.replace(/&/g, "/");
    let seed = "scrumweb";
    let { codigoMon, divisaID, divisa } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;

    const { token } = useSelector(state => state.login);

    const cleanState = {
        paridadID: null, divisaID: divisaID, fechaCotizacion: "", paridad: "", codigoMon: codigoMon
    };

    const cleanErrorState = {
        paridadID: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        fechaCotizacion: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        codigoMon: { error: false, helpertText: "" }
    };


    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const divisaIDRef = useRef(null);
    const paridadIDRef = useRef(null);
    const fechaCotizacionRef = useRef(null);
    const paridadRef = useRef(null);
    const codigoMonRef = useRef(null);

    let titleAccion = state.paridadID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Paridad`;

    let botones = [
        {
            icono: KeyboardBackspaceIcon,
            color: alert,
            descripcion: "Regresar a Divisas",
            onClick: () => { history.push(`/gen/Divisas`) },
            disabled: { multiseleccion: true }
        }, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }
    ];

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "paridadID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getHistorialParidades,
            formatearResultado: [
                { id: "fechaCotizacion", formato: (data) => moment(data) }]

        })
    },
    {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "paridadID" }],
        disabled: { multiseleccion: true },
        onClick: ({ paridadID }) => handelAlertEliminar({
            setAlert,
            mensaje: `La paridad seleccionada`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { paridadID },
                asyncDelete: deleteHistorialParidades
            })
        })
    }
    ]

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "paridadID",
        color: primary,
        title: `Historial de paridades de ${divisa}`,
        iconTable: <HistoryRounded />,
        headers: [
            { variable: "paridadID", descripcion: "pID", hide: true },
            { variable: "divisaID", descripcion: "dID", hide: true },
            { variable: "fechaCotizacion", descripcion: "Fecha de Cotización" },
            { variable: "paridad", descripcion: "Paridad" },
            { variable: "codigoMon", descripcion: "Código" }
        ],
        alineacion: [{ columnas: [2], alineacion: "center" }, { columnas: [1], alineacion: "right" }],
        formato: [
            {
                columnas: ["fechaCotizacion"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["paridad"], tipo: "moneda",
            }
        ]

        /* ,
        responsiveTitle: [ "paridad", "fechaCotizacion"],
        selectFilter: [
                    {
                    campo: "divisaID", placeholder: "Seleccionar Divisa",
                    retorna: "number", limpiarFiltro: "Mostrar todas las Divisas",
                    data: seleccionables.divisaID ? seleccionables.divisaID : ["No se encontraron registros"]
                     }] */
    }

    const inputs = [
        {
            disabled,
            id: "fechaCotizacion",
            //grid: {xl:3,sm:3},
            grid: { md: 3, lg: 3 },
            value: state.fechaCotizacion === "0000-00-00" ? "0000-00-00" : state.fechaCotizacion,
            tipo: "datetimepicker",
            onlyDate: true,
            error: errorState.fechaCotizacion.error,
            success: state.fechaCotizacion && !errorState.fechaCotizacion.error ? true : undefined,
            helperText: errorState.fechaCotizacion.helperText,
            inputRef: fechaCotizacionRef,
            title: "Fecha Cotización*",
            placeholder: "Fecha Cotización de la Divisa",
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChange(value, "fechaCotizacion");
            }
            // onKeyDown: (evt) => handleFocus(evt, divisaRef)
        },
        {
            disabled,
            id: "paridad",
            value: state.paridad,

            //grid: {xl:3,sm:3},
            grid: { md: 2, lg: 2 },
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            inputRef: paridadRef,
            textAlign: "right",
            // inputProps: { type:'number' } ,
            //  inputProps: { onBlur : () => { !rgxNumeros.test(state.paridad) }  } ,
            title: "Paridad*",
            placeholder: "Capture la paridad",
            onChange: ({ target: { value } }) => handleChange(value, "paridad"),
            onKeyDown: (evt) => handleFocus(evt, paridadRef)
        }
    ]

    let accionesFormulario = [{
        accionID: state.paridad ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: paridadRef,
            mensajeCorrecto: `La Paridad se ${state.paridad ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    let formato = "YYYY-MM-DD"
    function ftGuardar() {
        async function ftGuardar() {
            let { paridadID, divisaID, fechaCotizacion, paridad } = state;


            if (paridadID) {
                await updateHistorialParidades({
                    input: { paridadID, divisaID, fechaCotizacion: fechaCotizacion.format(formato).substr(0, 10), paridad: paridad === null ? null : Number(paridad) }
                }, token);
            } else {
                await addHistorialParidades({
                    input: { paridadID: null, divisaID, fechaCotizacion: fechaCotizacion.format(formato).substr(0, 10), paridad: paridad === null ? null : Number(paridad) }
                }, token);
            }
        }
        return ftGuardar();
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleChangeSwitch(e) {
        setState(state => (
            {
                ...state, filtrar: e.target.checked
            })
        )
    };

    useEffect(() => {
        async function ftEffect() {
            try {
                let divisaID = await seleccionDivisa({ filtro: {} }, token);
                setSeleccionables({ divisaID });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaHistorialParidades}
            parametrosFijos={{ codigoMon: codigoMon }}
            token={token} />

        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={paridadRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default HistorialParidades;
