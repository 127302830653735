import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_POLIZAS_SEGURO = `
mutation addPolizasSeguro($input: InputAddPolizasSeguro!) {
    addPolizasSeguro(input: $input) {
        polizasSeguroID
        numero
        fechaSolCot
        empresaID
        ramoSeguroID
        aseguradoraID
        numpolEndoso
        divisaID
        sumaAseg
        primaNeta
        fpPromedio
        vigenciaIni
        vigenciaHasta
        exclusiones
        renovacion
        horasInvertidas
        folioInterno
        tipoValorAseg
        tipoPoliza
        polizaOrigenID
        folioInternoControlPago
        fechaModificacion
        polizaRenovada
        derechoPoliza
        paridad
        inciso
        renovacionObs
        aseguradoAdicional
        seleccionTipoPoliza
        subPolizaID
        subRamoSeguroID
        subRamoSeguro
        bienesAmparadosEncabezado
        primaNetaTotal
    }
}
`;


export const UPDATE_POLIZAS_SEGURO =`
mutation updatePolizasSeguro($input: InputUpdatePolizasSeguro!) {
    updatePolizasSeguro(input: $input) {
        polizasSeguroID
        numero
        fechaSolCot
        empresaID
        ramoSeguroID
        aseguradoraID
        numpolEndoso
        divisaID
        sumaAseg
        primaNeta
        fpPromedio
        vigenciaIni
        vigenciaHasta
        exclusiones
        renovacion
        horasInvertidas
        folioInterno
        tipoValorAseg
        tipoPoliza
        polizaOrigenID
        folioInternoControlPago
        fechaModificacion
        polizaRenovada
        derechoPoliza
        paridad
        inciso
        renovacionObs
        aseguradoAdicional
        seleccionTipoPoliza
        subPolizaID
        subRamoSeguroID
        subRamoSeguro
        bienesAmparadosEncabezado
        primaNetaTotal
    }
}
`;

export const DELETE_POLIZAS_SEGURO = `
mutation deletePolizasSeguro($input: InputDeletePolizasSeguro) {
    deletePolizasSeguro(input: $input)
}
`;

export const RENOVAR_POLIZAS_SEGURO = `
mutation renovarPolizasSeguro($input: InputRenovarPolizasSeguro) {
    renovarPolizasSeguro(input: $input)
}
`;

export const RENOVAR_POLIZAS_SEGURO_COMP = `
mutation renovarConComplementaria($input: InputRenovarPolizasSeguro) {
    renovarConComplementaria(input: $input)
}
`;


export const RECALCULAR_IMPORTES_POLIZA = `
mutation recalcularImportesPoliza($input: InputRecalcularImportePolizasSeguro) {
    recalcularImportesPoliza(input: $input)
}
`;

export const addPolizasSeguro = async (variables, token) => {
    try {

        let { addPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_POLIZAS_SEGURO
        });

        return addPolizasSeguro;

    } catch({message}) { throw new Error(message); }
};

export const updatePolizasSeguro = async (variables, token) => {
    try {

        let { updatePolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_POLIZAS_SEGURO
        });

        return updatePolizasSeguro;

    } catch({message}) { throw new Error(message); }
};


export const deletePolizasSeguro = async (input, token) => {
    try {
      
        let { deletePolizasSeguro } = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_POLIZAS_SEGURO
        });

        return deletePolizasSeguro;

    } catch({message}) { throw new Error(message); }
};

export const renovarPolizasSeguro = async (input, token) => {
    try {

        let { renovarPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables:input,
            url: urlServidor,
            query: RENOVAR_POLIZAS_SEGURO
        });

        return renovarPolizasSeguro;

    } catch({message}) { throw new Error(message); }
};

export const renovarConComplementaria = async (input, token) => {
    try {

        let { renovarConComplementaria } = await FetchAPI.graphql({
            token,
            variables:input,
            url: urlServidor,
            query: RENOVAR_POLIZAS_SEGURO_COMP
        });

        return renovarConComplementaria;

    } catch({message}) { throw new Error(message); }
};


export const recalcularImportesPoliza = async (input, token) => {
    try {
        let { recalcularImportesPoliza } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: RECALCULAR_IMPORTES_POLIZA
        });

        return recalcularImportesPoliza;

    } catch({message}) { throw new Error(message); }
};


export const ADD_SUBPOLIZAS_SEGURO = `
mutation addSubPolizasSeguro($input: InputAddSubPolizasSeguro!) {
    addSubPolizasSeguro(input: $input) {
        subPolizaID
        polizasSeguroID
        ramoSeguroID
        sumaAseg
        primaNeta
        fpPromedio
        bienesAmparadosEncabezado
        folioSubpoliza
    }
}
`;


export const UPDATE_SUBPOLIZAS_SEGURO =`
mutation updateSubPolizasSeguro($input: InputUpdateSubPolizasSeguro!) {
    updateSubPolizasSeguro(input: $input) {
        subPolizaID
        polizasSeguroID
        ramoSeguroID
        sumaAseg
        primaNeta
        fpPromedio
        bienesAmparadosEncabezado
        folioSubpoliza
    }
}
`;

export const DELETE_SUBPOLIZAS_SEGURO = `
mutation deletePolizasSeguro($input: InputDeletePolizasSeguro) {
    deletePolizasSeguro(input: $input)
}
`;


export const addSubPolizasSeguro = async (variables, token) => {
    try {

        let { addSubPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SUBPOLIZAS_SEGURO
        });

        return addSubPolizasSeguro;

    } catch({message}) { throw new Error(message); }
};

export const updateSubPolizasSeguro = async (variables, token) => {
    try {

        let { updateSubPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SUBPOLIZAS_SEGURO
        });

        return updateSubPolizasSeguro;

    } catch({message}) { throw new Error(message); }
};


export const deleteSubPolizasSeguro = async (input, token) => {
    try {
      
        let { deletePolizasSeguro } = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_SUBPOLIZAS_SEGURO
        });

        return deletePolizasSeguro;

    } catch({message}) { throw new Error(message); }
};