import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import {tablaCodifDocumentos,getCodifDocumento, updateCodifDocumento} from './consultas';
import { handleFocus, handleErrorInputText, handleModificar, handleGuardar } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import * as Permisos from '../Permisos';

const { trim } = Validaciones;

function CodifDocumentos() {

    const { token } = useSelector(state => state.login);

    const cleanState = {
        codifDocID: null,
        proceso: "",
        descripcion: ""
    };
    const cleanErrorState = {
        proceso: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    let titleAccion = state.codifDocID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Codificación de documento`;

    const procesoRef = useRef(null);
    const descripcionRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "codifDocID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getCodifDocumento
        })
    }];

    let botones = [
       {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "codifDocID",
        color: primary,
        title: "Codificación de Documentos",
        iconTable: <FileCopyIcon />,
        headers: [
            { variable: "codifDocID", descripcion: "codifDocID", hide: true },
            { variable: "proceso", descripcion: "Proceso" },
            { variable: "descripcion", descripcion: "Descripción" },
        ],
        responsiveTitle: ["proceso","descripcion"],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }
    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled:true,
                    id: "proceso",
                    value: state.proceso,
                    error: errorState.proceso.error,
                    success: state.proceso && !errorState.proceso.error ? true : undefined,
                    helperText: errorState.proceso.helperText,
                    inputRef: procesoRef,
                    title: "Proceso *",
                    placeholder: "Capture el proceso",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "proceso")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "descripcion",
                    value: state.descripcion,
                    error: errorState.descripcion.error,
                    success: state.descripcion && !errorState.descripcion.error ? true : undefined,
                    helperText: errorState.descripcion.helperText,
                    inputRef: descripcionRef,
                    title: "Descripcion *",
                    placeholder: "Capture la descripción",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "descripcion")
                    },
                    onKeyDown: () => { return; }
                },
            ]
        }
    ];

    let accionesFormulario = [{
        accionID: state.codifDocID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: descripcionRef,
            mensajeCorrecto: `La codificación se ${state.codifDocID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ proceso, descripcion }) {

        let error;
        let validaciones = [
            {
                condicion: !proceso || trim(proceso) === "",
                ref: procesoRef, keyError: "proceso",
                mensajeError: "Debe especificar el proceso"
            },
            {
                condicion: proceso.length > 200,
                ref: procesoRef, keyError: "proceso",
                mensajeError: "Número de caracteres capturados excedido."
            },
            {
                condicion: !descripcion || trim(descripcion) === "",
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Debe especificar la descripcion"
            },
            {
                condicion: descripcion.length > 200,
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Número de caracteres capturados excedido."
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { codifDocID,proceso, descripcion } = state;

            let error = handleValidaciones({proceso, descripcion});

            if (error) return error;

            if (codifDocID) await updateCodifDocumento({
                input: { codifDocID, proceso: trim(proceso), descripcion: trim(descripcion) }
            }, token);
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);


    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
         <Tabla
            infoTabla={infoTabla}
            asyncData={tablaCodifDocumentos}
            token={token}
        />
        
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            maxWidth={false}
            acciones={accionesFormulario}
            focus={procesoRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default CodifDocumentos;
