import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_SOLOTROSSEGUROSFIANZAS = `
mutation add($input: InputAddSolSegFianFianza)
{
    addSolSegFianFianza(input: $input){
        fianzaID
        solSegFianID
        usuarioBitID
        tipo
        monto
        empresaID
        docFuente
        intGarantizar
        beneficiario
        divisaID
        divisa
        paridad
        montoAsegurado
  }
}
`;

export const UPDATE_SOLOTROSSEGUROSFIANZAS=`
mutation update($input: InputUpdateSolSegFianFianza)
{
    updateSolSegFianFianza(input: $input){
        fianzaID
        solSegFianID
        usuarioBitID
        tipo
        monto
        empresaID
        docFuente
        intGarantizar
        beneficiario
        divisaID
        divisa
        paridad
        montoAsegurado
  }
}
`;

export const DELETE_SOLOTROSSEGUROSFIANZAS = `
mutation delete($input : InputDeleteSolSegFianFianza)
{
    deleteSolSegFianFianza(input: $input)
}
`;

export const addSolOtrosSegFianFianzas = async (variables, token) => {
    try {
        let { addSolSegFianFianza } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SOLOTROSSEGUROSFIANZAS
        });
        return addSolSegFianFianza;
    } catch({message}) { throw new Error(message); }
};

export const updateSolOtrosSegFianFianzas = async (variables, token) => {
    try {
        let { updateSolSegFianFianza } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SOLOTROSSEGUROSFIANZAS
        });
        return updateSolSegFianFianza;
    } catch({message}) { throw new Error(message); }
};

export const deleteSolOtrosSegFianFianzas= async (input, token) => {
    try {
        let { deleteSolSegFianFianza } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_SOLOTROSSEGUROSFIANZAS
        });
        return deleteSolSegFianFianza;
    } catch({message}) { throw new Error(message); }
};
