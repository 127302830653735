import React from 'react';
import PropTypes from "prop-types";
import { NetworkError } from "../Metodos";

// core components
import GridItem from '../../components/Grid/GridItem.js';

// Componentes
import Select from './components/Select';
import Autocomplete from './components/Autocomplete';
import Checkbox from './components/Checkbox';
import Input from './components/Input';
import Switch from './components/Switch';
import DateTimePicker from './components/DateTimePicker';
import ImageUpload from './components/ImageUpload';
import Label from './components/Label';
import Fieldset from './components/Fieldset';
import Imagen from './components/Imagen';
import WYSIWYG from './components/wysiwyg';

// Componentes externos
import NavPills from '../NavPills';

export default function Inputs(props) {

    let { tipo, id, title, data, value, multiple = false, placeholder, disabled = false, checked = false, avatar, removeImage, textAlign = "left", closeOnSelect,
        error = false, success = false, helperText, fullWidth, icono, posicionIcono = "end", onlyTime = false, onlyDate = false, inputProps = {}, inputRef, asyncGetOptions,
        grid = {}, onChange = (evt) => (evt), onKeyDown = ({key, ctrlKey, keyCode}) => (key, ctrlKey, keyCode),
        leyenda, inputs = [], onClickIcono, hidden = false, custom, sizeLetra, widthBorderFieldset, fontWeight, ...rest
    } = props;

    let { xs = 12, sm = 12, md = 12, lg = 12 } = grid;

    let componente = <></>;

    switch(tipo) {
        case "custom":
            componente = custom ? custom : <div style={{ border: "2px solid red", paddingTop: "5px", color: "red", textAlign: "center" }}>
                <p>¡Aquí debería de ir tu input custom!</p>
                <p>Si vas a utilizar el input custom, necesitas enviar tu componente en la propiedad custom de tu input.</p>
            </div>;
            break;
        case "fieldset":
            componente = <Fieldset leyenda={leyenda} inputs={inputs} SizeLetra={sizeLetra} hide={props.hide ? props.hide : false} widthBorderFieldset={widthBorderFieldset} fontWeight={fontWeight}/>;
            break;
        case "nav":
            componente = <NavPills { ...rest } />;
            break;
        case "image":
            componente = <Imagen
                id={id}
                title={title}
                value={value}
                { ...rest }
            />;
            break;
        case "select":
            componente = <Select
                id={id}
                title={title}
                placeholder={placeholder}
                data={data}
                value={value}
                multiple={multiple}
                inputRef={inputRef}
                disabled={disabled}
                onChange={onChange}
                error={error}
                success={success}
                helperText={NetworkError(helperText)}
            />;
            break;
        case "autocomplete":
            componente = <Autocomplete
                id={id}
                title={title}
                placeholder={placeholder}
                data={data}
                value={value}
                multiple={multiple}
                disabled={disabled}
                onChange={onChange}
                asyncGetOptions={asyncGetOptions}
                error={error}
                success={success}
                helperText={NetworkError(helperText)}
                inputRef={inputRef}
                { ...rest }
            />;
            break;
        case "switch":
            componente = <Switch
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                value={value}
                title={title}
                inputRef={inputRef}
                { ...rest }
            />;
            break;
        case "checkbox":
            componente = <Checkbox
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                value={value}
                title={title}
                data={data}
                multiple={multiple}
                inputRef={inputRef}
                { ...rest }
            />;
            break;
        case "imageupload":
            componente = <ImageUpload
                id={id}
                title={title}
                value={value}
                disabled={disabled}
                avatar={avatar}
                onChange={onChange}
                removeImage={removeImage}
                error={error}
                success={success}
                helperText={NetworkError(helperText)}
            />;
            break;
        case "datetimepicker":
            componente = <DateTimePicker
                id={id}
                onlyTime={onlyTime}
                onlyDate={onlyDate}
                onChange={onChange}
                value={value}
                error={error}
                success={success}
                closeOnSelect={closeOnSelect}
                helperText={NetworkError(helperText)}
                inputProps={{
                    placeholder,
                    disabled
                }}
            />;
            break;
        case "label":
            componente = <Label title={title} />;
            break;
        case "wysiwyg":
            componente = <WYSIWYG
                id={id}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                inputRef={inputRef}
                disabled={disabled}
                { ...rest }
            />;
            break;
        default:
            componente = <Input
                id={id}
                textAlign={textAlign}
                title={title}
                value={value}
                error={error}
                success={success}
                icono={icono}
                posicionIcono={posicionIcono}
                placeholder={placeholder}
                helperText={NetworkError(helperText)}
                fullWidth={fullWidth}
                onChange={onChange}
                onKeyDown={onKeyDown}
                disabled={disabled}
                inputProps={inputProps}
                inputRef={inputRef}
                onClickIcono={onClickIcono}
                { ...rest }
            />;
            break;
    }

    return (!hidden && <GridItem xs={xs} sm={sm} md={md} lg={lg}>{componente}</GridItem>);

}

Inputs.propTypes = {
    tipo: PropTypes.string,
    id: PropTypes.string,
    textAlign: PropTypes.oneOf([
        "left",
        "right",
        "center"
    ]),
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.any,
    multiple: PropTypes.bool,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    error: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    icono: PropTypes.node,
    posicionIcono: PropTypes.string,
    grid: PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number
    }),
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    leyenda: PropTypes.string,
    inputs: PropTypes.arrayOf(PropTypes.object),
    onClickIcono: PropTypes.func
};
