import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

import { estadoReclamacionSiniestro } from '../../../helpers/datosFijos/estadoReclamoSiniestro';

// Redux
import { useSelector } from "react-redux";
import { NetworkError } from "../../../_layout/genericComponents/Metodos/";

import { useHistory } from "react-router-dom";

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import ModalConfirmacionInputs from '../../../_layout/genericComponents/ModalConfirmacionInputs';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { RiFileExcel2Fill } from "react-icons/ri";
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ContactsIcon from '@material-ui/icons/Contacts';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, warning } from '../../../_layout/helpers/colores';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import moment from 'moment';

import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
import { getSiniestrosReclaTercero, tablaSiniestrosReclaTercero } from '../../../querys/ReclamacionesATerceros';
import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { addSiniestrosReclaTercero, updateSiniestrosReclaTercero, deleteSiniestrosReclaTercero } from '../../../mutations/ReclamacionesATerceros/';
import { seleccionEmpresa } from 'querys/Empresas';
import { useLocation } from 'react-router-dom';
import { urlServidor } from '../../../helpers';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import TrazabilidadSiniestros from '../TrazabilidadSiniestros';
import SeguimientoActividades from '../SeguimientoActividades';
import DescriptionIcon from '@material-ui/icons/Description';
import SiniestrosReclaAseg from '../SiniestrosReclamados/index'
import UpsertControlPago from '../ControlPagoReporteDanios/Upsert';
import { tablaControlPagosReporteDanios } from 'querys/ControlPago';


const { trim } = Validaciones;
//const {Decrypt}=Encriptar;
function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

function ReclamacionATerceros(props) {

   let reclamoPrincipalID = null;
   let tipoReclamo = null;
   let folio = '';

   if(props.datos){
        reclamoPrincipalID = props.datos.reclamoPrincipalID;
        tipoReclamo = props.datos.tipoReclamo;
        folio = props.datos.folioPrincipal;
   }     

    let anioActual = new Date().getFullYear();

    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";
    const { Encrypt } = Encriptar;
    const history = useHistory();
    let [datosPDF, setdatosPDf] = useState({});
    const [openSeguimientos, setOpenSeguimientos] = useState(false);
    const [stReporteDaniosID, setReporteDaniosID] = useState(null);
    const [stSiniesReclTercerosID, setSiniesReclTercerosID] = useState(null);
    const [openControlPago, setOpenControlPago] = useState(false);
    const { token } = useSelector(state => state.login)
    const [verHistorial, setVerHistorial] = useState(false);

    const cleanState = {
        siniesReclTercerosID: null,
        numero: "",
        empresaID: null,
        fechaAfectado: new Date(),
        infoIdentifTercero: "",
        asegDelTercero: "",
        ajustadorRespresentante: "",
        noSiniestro: "",
        noPoliza: "",
        bienAfectado: "",
        descripEvento: "",
        ubicacion: "",
        estatus: "EN PROCESO",
        montoPagado: "",
        fechaReclamacion: new Date(),
        fechaIndemnizacion: new Date(),
        atendio: "",
        noDiasHabilesTrans: "",
        divisaID: 69,
        paridad: 1,
        reporteDaniosID: ""
    };

    const cleanErrorState = {
        siniesReclTercerosID: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        fechaAfectado: { error: false, helperText: "" },
        infoIdentifTercero: { error: false, helperText: "" },
        asegDelTercero: { error: false, helperText: "" },
        ajustadorRespresentante: { error: false, helperText: "" },
        noSiniestro: { error: false, helperText: "" },
        noPoliza: { error: false, helperText: "" },
        bienAfectado: { error: false, helperText: "" },
        descripEvento: { error: false, helperText: "" },
        ubicacion: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        montoPagado: { error: false, helperText: "" },
        fechaReclamacion: { error: false, helperText: "" },
        fechaIndemnizacion: { error: false, helperText: "" },
        atendio: { error: false, helperText: "" },
        noDiasHabilesTrans: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" }
    };

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [disabled, setDisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(null);
    const [openEstado, setOpenEstado] = useState(false);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    const [openTrazabilidad, setOpenTrazabilidad] = useState(false);
    const [datosDetalle, setDatosDetalle] = useState({})
    const [openReclamoAseguradora, setOpenReclamosAseguradora] = useState(false);
    const [openReclamosTerceros, setOpenReclamosTerceros] = useState(false);
    const [detalleReclamo, setDetalleReclamo] = useState({tipo:""});
    const [folioPrincipal, setFolioPrincipal] = useState('');

    let titleAccion = state.siniesReclTercerosID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Reclamos a Terceros`;

    const siniesReclTercerosIDRef = useRef(null);
    const empresaIDRef = useRef(null);
    const fechaAfectadoRef = useRef(null);
    const infoIdentifTerceroRef = useRef(null);
    const asegDelTerceroRef = useRef(null);
    const ajustadorRespresentanteRef = useRef(null);
    const noSiniestroRef = useRef(null);
    const noPolizaRef = useRef(null);
    const bienAfectadoRef = useRef(null);
    const descripEventoRef = useRef(null);
    const ubicacionRef = useRef(null);
    const estatusRef = useRef(null);
    const montoPagadoRef = useRef(null);
    const fechaReclamacionRef = useRef(null);
    const fechaIndemnizacionRef = useRef(null);
    const atendioRef = useRef(null);
    const noDiasHabilesTransRef = useRef(null);
    const paridadRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    const accionesAdicionales = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSiniestrosReclaTercero,
                formatearResultado: [{ id: "fechaAfectado", formato: (data) => moment(data) }, { id: "fechaReclamacion", formato: (data) => moment(data) }, { id: "fechaIndemnizacion", formato: (data) => moment(data) }]
            })
        },
        {
            accionID: [8],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Estado",
            parametros: [{ campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen: setOpenEstado, setAlert,
                asyncGet: getSiniestrosReclaTercero
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "siniesReclTercerosID" },
            { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro de ${folioInterno}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { siniesReclTercerosID }, asyncDelete: deleteSiniestrosReclaTercero
                })
            })
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "siniesReclTercerosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => {
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: siniesReclTercerosID,
                    pTipo: folioInterno,
                    pClasificacion: "",
                    pTabla: "Reclamaciones a Terceros",
                    pTitulo2: `Reclamaciones a Terceros folio: ${folioInterno}`,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }, {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Descargar PDF",
            parametros: [{ campo: "siniesReclTercerosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => AuxDescargar(siniesReclTercerosID, folioInterno)
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "siniesReclTercerosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => AuxDescargar(siniesReclTercerosID, folioInterno, 'excel')
        },
           

    ]
   

    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSiniestrosReclaTercero,
                formatearResultado: [{ id: "fechaAfectado", formato: (data) => moment(data) }, { id: "fechaReclamacion", formato: (data) => moment(data) }, { id: "fechaIndemnizacion", formato: (data) => moment(data) }]
            })
        },
        {
            accionID: [8],
            icono: AssignmentTurnedInIcon,
            color: success,
            descripcion: "Estado",
            parametros: [{ campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen: setOpenEstado, setAlert,
                asyncGet: getSiniestrosReclaTercero
            })
        },
        {
            accionID: [4],
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "siniesReclTercerosID" },{ campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro de ${folioInterno}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { siniesReclTercerosID }, asyncDelete: deleteSiniestrosReclaTercero
                })
            })
        },
        {
            accionID: [7],
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "siniesReclTercerosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => {
                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: siniesReclTercerosID,
                    pTipo: folioInterno,
                    pClasificacion: "",
                    pTabla: "Reclamaciones a Terceros",
                    pTitulo2: `Reclamaciones a Terceros folio: ${folioInterno}`,
                    plUtilizaProps: true
                });
                setOpenDocumentacion(true);
            }
        }, {
            accionID: [5],
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Descargar PDF",
            parametros: [{ campo: "siniesReclTercerosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => AuxDescargar(siniesReclTercerosID, folioInterno)
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "siniesReclTercerosID" }, { campo: "folioInterno" }],
            disabled: { multiseleccion: true },
            onClick: ({ siniesReclTercerosID, folioInterno }) => AuxDescargar(siniesReclTercerosID, folioInterno, 'excel')
        },
        {
            accionID: [13],
            id: "verTrazabilidad",
            icono: TrendingUpIcon,
            color: success,
            descripcion: "Ver Trazabilidad",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclTercerosID }) => {
                setDatosDetalle({
                    pReporteDaniosID: reporteDaniosID,
                    pPolizasSeguroID: null,
                    pControlPagoID: null,
                    plUtilizaProps: true,
                    pSiniesReclAsegID: null,
                    pSiniesReclTercerosID: siniesReclTercerosID
                });
                setOpenTrazabilidad(true);
            }
        },
        {
            accionID: [10],
            icono: DescriptionIcon,
            color: primary,
            descripcion: "Seguimientos",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclTercerosID }) => { 
                setReporteDaniosID(reporteDaniosID); setSiniesReclTercerosID(siniesReclTercerosID); setOpenSeguimientos(true); setVerHistorial(false) }
        },
        {
            accionID: [11],
            id: "verHistorialSeguimientos",
            icono: DescriptionIcon,
            color: success,
            descripcion: "Historial de Seguimientos",
            parametros: [{ campo: "reporteDaniosID" }, { campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclTercerosID }) => { setReporteDaniosID(reporteDaniosID); setSiniesReclTercerosID(siniesReclTercerosID); setOpenSeguimientos(true); setVerHistorial(true) }
        },
        {
            accionID: [9],
            id: "generarControlPago",
            icono: AccountBalanceWalletIcon,
            color: warning,
            descripcion: "Control de pago",
            parametros: [{ campo: "reporteDaniosID" }, {campo: "folioInterno"}, {campo: "estatus"}, {campo: "siniesReclTercerosID"}],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, folioInterno, estatus, siniesReclTercerosID }) => { 
                
                setReporteDaniosID({repDaniosID: reporteDaniosID, recTerID: siniesReclTercerosID});  
                VerControldePagos(reporteDaniosID, folioInterno, estatus); }
        },       
        {
            accionID: [12],
            id: "Reclamos",
            icono: AnnouncementIcon,
            color: "tumblr",
            descripcion: "Reclamos adicionales a aseguradoras",
            parametros: [{ campo: "folioInterno" }, { campo: "reporteDaniosID" }, { campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclTercerosID, folioInterno }) => { 
                setReporteDaniosID(reporteDaniosID); 
                setSiniesReclTercerosID(siniesReclTercerosID);
                setOpenReclamosAseguradora(true);
                setFolioPrincipal(folioInterno);
                
            }
        },
        {
            accionID: [14],
            id: "Reclamos",
            icono: AnnouncementIcon,
            color: "pinterest",
            descripcion: "Reclamos adicionales a terceros",
            parametros: [{ campo: "folioInterno" }, { campo: "reporteDaniosID" }, { campo: "siniesReclTercerosID" }],
            disabled: { multiseleccion: true },
            onClick: ({ reporteDaniosID, siniesReclTercerosID, folioInterno }) => { 
                setReporteDaniosID(reporteDaniosID); 
                setSiniesReclTercerosID(siniesReclTercerosID);
                setOpenReclamosTerceros(true);
                setFolioPrincipal(folioInterno);
                
            }
        }
    ];   

          
  

    async function VerControldePagos(reporteDaniosID, folioInterno, estatus){

        
        if(estatus !== "CERRADO"){
            let result = await tablaControlPagosReporteDanios({filtro:{pagina:1, limite: 1000, reporteDaniosID: Number(reporteDaniosID)}},token)

            let {listado } = result;
            let descripcionMensaje = [];
            if (listado.length > 0) {
               listado.map(pago => {
                  descripcionMensaje.push(<><>Control pago: {pago.folioInterno}, ${pago.importePago}</> <br/></> )
               })
            }else{
                descripcionMensaje = '¿Desea agregar un control de pago?'
            }

            setAlert({
                descripcion: descripcionMensaje,
                title: "Control de pagos "+folioInterno,
                tipo: "warning",
                msjConfirmacion: "Agregar control de pago",
                onConfirm: () => {setAlert(null); setOpenControlPago(true);},
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
           

        }else{
            setAlert({
                descripcion: "El seguimiento del reporte es RECLAMADO, no aplica ",
                title: "Control de pagos "+folioInterno,
                tipo: "danger",                
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });            
        }       

    }

    function AuxDescargar(siniesReclTercerosID, folioInterno, formatoReporte = "PDF") {
        setdatosPDf({ "siniesReclTercerosID": siniesReclTercerosID, "folioInterno": folioInterno, formatoReporte });
        datosPDF = { "siniesReclTercerosID": siniesReclTercerosID, "folioInterno": folioInterno, formatoReporte };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de reclamos a terceros?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato de impresión del reporte de reclamos a terceros ",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el formato de impresión del reporte de reclamos a terceros ' + folioInterno,
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("siniesReclTercerosID", datosPDF.siniesReclTercerosID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteReclamosTerceros' + datosPDF.folioInterno
        await fetch(`${urlServidor}/service/reportes/ReporteReclamosTerceros`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                showFile(result, `${nombreReporte} ${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }

    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }
    ];

    const infoTabla = {
        botones,
        acciones: reclamoPrincipalID ? accionesAdicionales : acciones,
        actualizar,
        id: "siniesReclTercerosID",
        color: primary,
        title:  "Reclamos a Terceros "+ folio,
        iconTable: <ContactsIcon />,
        headers: [
            { variable: "reclamoPrincipalID", descripcion: "reclamoPrinipalID", hide: true },
            { variable: "tipoReclamoPrincipal", descripcion: "tipoReclamoPrincipal", hide: true },
            { variable: "siniesReclTercerosID", descripcion: "siniesReclTercerosID", hide: true },
            { variable: "reporteDaniosID", descripcion: "reporteDaniosID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "estatus", descripcion: "Estatus" },
            { variable: "fechaAfectado", descripcion: "Fecha Daño" },
            { variable: "folioInterno", descripcion: "Folio Reclamo" },
            { variable: "infoIdentifTercero", descripcion: "Identificación del Tercero" },
            { variable: "asegDelTercero", descripcion: "Aseguradora Del Tercero", hide: true },
            { variable: "ajustadorRespresentante", descripcion: "Ajustador Respresentante", hide: true },
            { variable: "noSiniestro", descripcion: "No. Siniestro" },
            { variable: "noPoliza", descripcion: "No. Poliza" },
            { variable: "bienAfectado", descripcion: "Bien Dañado" },
            { variable: "descripEvento", descripcion: "Descripción del Evento" },
            { variable: "ultimoSeguimiento", descripcion: "Último Seguimiento" },
            { variable: "ubicacion", descripcion: "Ubicación" },
            { variable: "montoPagado", descripcion: "Monto Pagado" },
            { variable: "fechaReclamacion", descripcion: "Fecha Reclamación" },
            { variable: "fechaIndemnizacion", descripcion: "Fecha Indemnización" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "atendio", descripcion: "Atendio" },
            { variable: "noDiasHabilesTrans", descripcion: "Dias Habiles Transcurridos" },
        ],
        responsiveTitle: ["folioInterno", "fechaAfectado", "noSiniestro"],
        filter: [
            { campo: "noSiniestro", placeholder: "No. Siniestro" },
            { campo: "infoIdentifTercero", placeholder: "Identificación del Tercero" },
            { campo: "noPoliza", placeholder: "No. Poliza" },
            { campo: "bienAfectado", placeholder: "Bien Dañado" },
            { campo: "ubicacion", placeholder: "Ubicación" },
            // { campo: "estatus", placeholder: "Estatus" },
            { campo: "montoPagado", placeholder: "Monto Pagado" },
            { campo: "atendio", placeholder: "Atendio" },
        ],
        formato: [{ columnas: ["fechaAfectado"], tipo: "fecha", onlyDate: true }, { columnas: ["fechaReclamacion"], tipo: "fecha", onlyDate: true }, { columnas: ["fechaIndemnizacion"], tipo: "fecha", onlyDate: true }, { columnas: ["fechaModificacion"], tipo: "fecha", onlyDate: true }, { columnas: ["montoPagado"], tipo: "moneda" }],
        alineacion: [{ columnas: [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 14, 15], alineacion: "left" }, { columnas: [0, 9, 13], alineacion: "right" }], rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todas",
                data: seleccionables.empresas || [], grid: { md: 3, lg: 3 }
            },
            {
                campo: "estatus", title: "Estatus",
                retorna: "string", limpiarFiltro: "Todos",
                data: estadoReclamacionSiniestro, grid: { md: 3, lg: 3 }
            },
        ],
        accionesEffect: [{ botones: ["generarControlPago"],  ocultar: (data) => data["estatus"].value !== "CERRADO" &&  data['reporteDaniosID'].value != null ? false : true  }]
    }

    const inputs = [
        {
            disabled,
            id: "empresaID",
            value: state.empresaID,
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            inputRef: empresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccione empresa",
            grid: { md: 4, lg: 4 },
            tipo: "autocomplete",
            data: seleccionables.empresas,
            onChange: (value) => handleChange(value ? Number(value.value) : null, "empresaID"),
        },
        {
            disabled,
            id: "fechaAfectado",
            value: state.fechaAfectado,
            error: errorState.fechaAfectado.error,
            success: state.fechaAfectado && !errorState.fechaAfectado.error ? true : undefined,
            helperText: errorState.fechaAfectado.helperText,
            inputRef: fechaAfectadoRef,
            title: "Fecha Daño *",
            grid: { md: 4, lg: 4 },
            placeholder: "Fecha Daño",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChange(value, "fechaAfectado");
            }
        },
        {
            disabled,
            id: "infoIdentifTercero",
            value: state.infoIdentifTercero,
            error: errorState.infoIdentifTercero.error,
            success: state.infoIdentifTercero && !errorState.infoIdentifTercero.error ? true :
                undefined,
            helperText: errorState.infoIdentifTercero.helperText,
            inputRef: infoIdentifTerceroRef,
            title: "Información de Identificación del Tercero *",
            placeholder: "Especifique el información de identificación del tercero",
            grid: { md: 4, lg: 4 },
            onChange: ({ target: { value } }) => handleChange(value, "infoIdentifTercero"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "asegDelTercero",
            value: state.asegDelTercero,
            error: errorState.asegDelTercero.error,
            success: state.asegDelTercero && !errorState.asegDelTercero.error ? true :
                undefined,
            helperText: errorState.asegDelTercero.helperText,
            inputRef: asegDelTerceroRef,
            title: "Aseguradora del Tercero",
            placeholder: "Especifique el aseguradora del tercero",
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value } }) => handleChange(value, "asegDelTercero"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "ajustadorRespresentante",
            value: state.ajustadorRespresentante,
            error: errorState.ajustadorRespresentante.error,
            success: state.ajustadorRespresentante && !errorState.ajustadorRespresentante.error ? true :
                undefined,
            helperText: errorState.ajustadorRespresentante.helperText,
            inputRef: ajustadorRespresentanteRef,
            title: "Ajustador Responsable del Tercero",
            placeholder: "Especifique el nombre del ajustador responsable del tercero",
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value } }) => handleChange(value, "ajustadorRespresentante"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "noSiniestro",
            value: state.noSiniestro,
            error: errorState.noSiniestro.error,
            success: state.noSiniestro && !errorState.noSiniestro.error ? true :
                undefined,
            helperText: errorState.noSiniestro.helperText,
            inputRef: noSiniestroRef,
            title: "Número de Siniestro",
            placeholder: "Especifique el número de siniestro",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => handleChange(value, "noSiniestro"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "noPoliza",
            value: state.noPoliza,
            error: errorState.noPoliza.error,
            success: state.noPoliza && !errorState.noPoliza.error ? true :
                undefined,
            helperText: errorState.noPoliza.helperText,
            inputRef: noPolizaRef,
            title: "Número de Póliza del Tercero",
            placeholder: "Especifique el número de póliza del tercero",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => handleChange(value, "noPoliza"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "bienAfectado",
            multiline: true,
            value: state.bienAfectado,
            error: errorState.bienAfectado.error,
            success: state.bienAfectado && !errorState.bienAfectado.error ? true :
                undefined,
            helperText: errorState.bienAfectado.helperText,
            inputRef: bienAfectadoRef,
            title: "Bien Dañado *",
            placeholder: "Especifique el bien dañado",
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value } }) => handleChange(value, "bienAfectado"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "descripEvento",
            multiline: true,
            value: state.descripEvento,
            error: errorState.descripEvento.error,
            success: state.descripEvento && !errorState.descripEvento.error ? true :
                undefined,
            helperText: errorState.descripEvento.helperText,
            inputRef: descripEventoRef,
            title: "Descripción del Evento *",
            placeholder: "Especifique la descripción del evento",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => handleChange(value, "descripEvento"),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "ubicacion",
            value: state.ubicacion,
            error: errorState.ubicacion.error,
            success: state.ubicacion && !errorState.ubicacion.error ? true :
                undefined,
            helperText: errorState.ubicacion.helperText,
            inputRef: ubicacionRef,
            title: "Ubicación",
            placeholder: "Especifique la ubicación",
            grid: { md:3, lg:3 },
            onChange: ({ target: { value } }) => handleChange(value, "ubicacion"),
            onKeyDown: () => { return; }
        },
        {
            disabled: !state.siniesReclTercerosID ? true : false,
            id: "estatus",
            value: state.estatus,
            error: errorState.estatus.error,
            success: state.estatus && !errorState.estatus.error ? true :
                undefined,
            helperText: errorState.estatus.helperText,
            inputRef: estatusRef,
            title: "Estatus *",
            placeholder: "Especifique el estatus",
            tipo: "select",
            data: estadoReclamacionSiniestro,
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "divisaID",
            value: state.divisaID,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 3, lg: 3 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            inputRef: paridadRef,
            helperText: errorState.paridad.helperText,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            title: "Paridad *",
            textAlign: "right",
            placeholder: "Paridad",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(Number(value), "paridad") }
        },
        {
            disabled,
            id: "montoPagado",
            value: state.montoPagado,
            error: errorState.montoPagado.error,
            success: state.montoPagado && !errorState.montoPagado.error ? true :
                undefined,
            helperText: errorState.montoPagado.helperText,
            inputRef: montoPagadoRef,
            icono: <IconoSimbolo simbolo="$" />,
            posicionIcono: "start",
            formatoImportes: true,
            title: "Monto Pagado*",
            textAlign: "right",
            placeholder: "Monto pagado",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value.toUpperCase(), "montoPagado") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, montoPagado: { error: state.montoPagado.length > 10 && true, helperText: state.montoPagado.length > 10 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "fechaReclamacion",
            value: state.fechaReclamacion,
            error: errorState.fechaReclamacion.error,
            success: state.fechaReclamacion && !errorState.fechaReclamacion.error ? true : undefined,
            helperText: errorState.fechaReclamacion.helperText,
            inputRef: fechaReclamacionRef,
            title: "Fecha Reclamación *",
            grid: { md: 3, lg: 3 },
            placeholder: "Fecha Reclamación",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChange(value, "fechaReclamacion");
                ftCalculaDiasTranscurridos({ fechaIndemnizacion: state.fechaIndemnizacion, fechaReclamacion: value });
            }
        },
        {
            disabled,
            id: "fechaIndemnizacion",
            value: state.fechaIndemnizacion,
            error: errorState.fechaIndemnizacion.error,
            success: state.fechaIndemnizacion && !errorState.fechaIndemnizacion.error ? true : undefined,
            helperText: errorState.fechaIndemnizacion.helperText,
            inputRef: fechaIndemnizacionRef,
            title: "Fecha Indemnización *",
            grid: { md: 3, lg: 3 },
            placeholder: "Fecha Indemnización",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => {
                if (typeof value !== "object") return;
                handleChange(value, "fechaIndemnizacion");
                ftCalculaDiasTranscurridos({ fechaIndemnizacion: value, fechaReclamacion: state.fechaReclamacion });
            }
        }, {
            disabled,
            id: "noDiasHabilesTrans",
            value: state.noDiasHabilesTrans,
            error: errorState.noDiasHabilesTrans.error,
            success: state.noDiasHabilesTrans && !errorState.noDiasHabilesTrans.error ? true :
                undefined,
            helperText: errorState.noDiasHabilesTrans.helperText,
            inputRef: noDiasHabilesTransRef,
            title: "Dias Transcurridos",
            placeholder: "Dias transcurridos",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => handleChange(value, "noDiasHabilesTrans"),
            onKeyDown: () => { return; }
        }, {
            disabled,
            id: "atendio",
            value: state.atendio,
            error: errorState.atendio.error,
            success: state.atendio && !errorState.atendio.error ? true :
                undefined,
            helperText: errorState.atendio.helperText,
            inputRef: atendioRef,
            title: "Atendio *",
            placeholder: "Especifique el nombre de quien atendio",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => handleChange(value, "atendio"),
            onKeyDown: () => { return; }
        }
    ];

    let accionesFormulario = [{
        accionID: state.siniesReclTercerosID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: siniesReclTercerosIDRef,
            mensajeCorrecto: `El reclamo se ${state.siniesReclTercerosID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        })
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const inputsEstado = [
        {
            disabled: !state.siniesReclTercerosID ? true : false,
            id: "estatus",
            value: state.estatus,
            error: errorState.estatus.error,
            success: state.estatus && !errorState.estatus.error ? true :
                undefined,
            helperText: errorState.estatus.helperText,
            inputRef: estatusRef,
            title: "Estatus*",
            placeholder: "Especifique el estatus",
            tipo: "select",
            data: estadoReclamacionSiniestro,
            grid: { md: 3, lg: 9 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        }
    ];

    const accionesFormularioCambioEstado = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseEstado,
            handleFocus,
            refFocus: siniesReclTercerosIDRef,
            mensajeCorrecto: `La reclamación ${state.siniesReclTercerosID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        })
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseEstado
    }];

    function handleCloseEstado() {
        setOpenEstado(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) {
        setState({ ...state, [key]: value });
    }

    async function handleChangeDivisa (value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);        
        
         setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
    }

    const location = useLocation();
    function AbrirRegistroDesdeParam() {
        if (location.state) {
            if (location.state.RegistroID) {
                let RegistroID = location.state.RegistroID;
                window.history.replaceState({}, document.title);

                let parametros = { siniesReclTercerosID: RegistroID };

                handleModificar({
                    parametros, token, setState, setOpen, setAlert,
                    asyncGet: getSiniestrosReclaTercero,
                    formatearResultado: [{ id: "fechaAfectado", formato: (data) => moment(data) }, { id: "fechaReclamacion", formato: (data) => moment(data) }, { id: "fechaIndemnizacion", formato: (data) => moment(data) }]
                })
            }
        }
        window.history.replaceState(document.title,{})
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let empresas = await seleccionEmpresa({ filtro: { empresaID: state.EmpresaID, activa: 1 } }, token);
                if (empresas.length >= 1) {
                    setState(state => (
                        {
                            ...state, empresaID: empresas[0].value
                        })
                    )
                }

                let Ubicaciones = await seleccionUbicaciones({ filtro: {} }, token);
                setSeleccionables({ empresas, Ubicaciones });
                AbrirRegistroDesdeParam();
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);


    async function handleValidaciones({ empresaID, infoIdentifTercero, bienAfectado, descripEvento, ubicacion, estatus, atendio,montoPagado,paridad,divisaID }) {

        let error;
        let validaciones = [
            {
                condicion: !empresaID,
                keyError: "empresaID",
                mensajeError: "La empresa es un dato obligatorio. Debe seleccionar una empresa"
            },
            {
                condicion: !infoIdentifTercero || trim(infoIdentifTercero) == "",
                ref: infoIdentifTerceroRef, keyError: "infoIdentifTercero",
                mensajeError: "La información de identificación del tercero es dato obligatorio"
            }, {
                condicion: !bienAfectado || trim(bienAfectado) === "",
                ref: bienAfectadoRef, keyError: "bienAfectado",
                mensajeError: "El bien dañado es dato obligatorio. Debe especificar el bien dañado."
            }, {
                condicion: !descripEvento || trim(descripEvento) === "",
                ref: descripEventoRef, keyError: "descripEvento",
                mensajeError: "La descripción del evento es obligatorio. Debe especificar el evento."
            }, {
                condicion: !ubicacion || trim(ubicacion) === "",
                ref: ubicacionRef, keyError: "ubicacion",
                mensajeError: "La ubicación es dato obligatorio. Debe especificar la ubicación."
            },
            {
                condicion: !montoPagado || montoPagado === "",
                ref: montoPagadoRef, keyError: "montoPagado",
                mensajeError: "Dato obligatorio"
            },
            {
                condicion: !divisaID,
                keyError: "divisaID",
                mensajeError: "Dato obligatorio"
            },
            {
                condicion: !paridad || paridad === "",
                ref: paridadRef, keyError: "paridad",
                mensajeError: "Dato obligatorio"
            },
            {
                condicion: !atendio || trim(atendio) === "",
                ref: atendioRef, keyError: "atendio",
                mensajeError: "El nombre de quien atendio es dato obligatorio. Debe especificar quien atendio."
            },
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });
        if (estatus !=="EN PROCESO" && error){
            setAlert({
                descripcion: "Falta información obligatoria para cambiar el estatus",
                title: "Revisa la información",
                tipo: "danger",                
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });   
        }

        if (error) return error;
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftCalculaDiasTranscurridos({ fechaIndemnizacion, fechaReclamacion }) {
        let from = moment(fechaReclamacion, 'DD/MM/YYY'),
            to = moment(fechaIndemnizacion, 'DD/MM/YYY'),
            days = 0;
        if (from.isValid() && to.isValid()) {
            while (!from.isAfter(to)) {
                // Si no es sabado ni domingo
                if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7) {
                    days++;
                }
                from.add(1, 'days');
            }
            setState(state => (
                {
                    ...state, noDiasHabilesTrans: days
                })
            )
        }
    }

    function ftGuardar() {
        async function ftGuardar() {

            let { siniesReclTercerosID, numero, empresaID, fechaAfectado, infoIdentifTercero, asegDelTercero, ajustadorRespresentante, noSiniestro, noPoliza, bienAfectado, descripEvento, ubicacion, estatus, montoPagado, fechaReclamacion, fechaIndemnizacion, atendio, noDiasHabilesTrans,divisaID,paridad,reporteDaniosID } = state;
            let error = await handleValidaciones({empresaID, infoIdentifTercero, bienAfectado, descripEvento, ubicacion, estatus, atendio,montoPagado,paridad,divisaID});
            
            if (error) return error;

            if (siniesReclTercerosID) await updateSiniestrosReclaTercero({
                input: { siniesReclTercerosID, numero: Number(numero), empresaID, 
                    fechaAfectado, infoIdentifTercero, 
                    asegDelTercero, ajustadorRespresentante, 
                    noSiniestro, noPoliza, bienAfectado, 
                    descripEvento, ubicacion, estatus, 
                    montoPagado: Number(montoPagado), 
                    fechaReclamacion, fechaIndemnizacion, 
                    atendio, 
                    noDiasHabilesTrans: Number(noDiasHabilesTrans),
                    divisaID, paridad: Number(paridad),
                    reclamoPrincipalID: null,
                    tipoReclamoPrincipal: null,
                    reporteDaniosID
                 }
            }, token);
            else await addSiniestrosReclaTercero({
                input: { siniesReclTercerosID: null, numero: Number(numero),
                     empresaID, fechaAfectado, infoIdentifTercero, asegDelTercero, 
                     ajustadorRespresentante, noSiniestro, noPoliza, bienAfectado, 
                     descripEvento, ubicacion, estatus, montoPagado: Number(montoPagado), 
                     fechaReclamacion, fechaIndemnizacion, atendio, 
                     noDiasHabilesTrans: Number(noDiasHabilesTrans),
                     divisaID, paridad: Number(paridad),
                     reclamoPrincipalID:  reclamoPrincipalID ,
                     tipoReclamoPrincipal:  tipoReclamo,
                     reporteDaniosID
                    }
            }, token);
            setSiniesReclTercerosID(null);
        }
        return ftGuardar();
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {        
       acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (
        <>
            {<Tabla
                infoTabla={infoTabla}
                asyncData={tablaSiniestrosReclaTercero}
                token={token}
                parametrosFijos={{reclamoPrincipalID: reclamoPrincipalID, tipoReclamoPrincipal: tipoReclamo}}
            />}
            <FormularioModal
                open={open}
                title={titleModal}
                onClose={handleClose}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputs}
                acciones={accionesFormulario}
                focus={siniesReclTercerosIDRef}
            />
            <FormularioModal
                open={openEstado}
                title={"Cambio de Estado"}
                onClose={handleCloseEstado}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputsEstado}
                acciones={accionesFormularioCambioEstado}
                focus={siniesReclTercerosIDRef}
            />
            {alert &&
                <SweetAlert
                    title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />
            }           
            {
                openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>
                    <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                    } />
                </Modal>
            }
            {
                openTrazabilidad && <Modal maxWidth={true} onClose={() => {setOpenTrazabilidad(false)}}>
                    <TrazabilidadSiniestros datosDetalle={datosDetalle} setOpenTrazabilidad={() => setOpenTrazabilidad(false)
                    } />
                </Modal>
            }
            {
                openReclamoAseguradora &&
                 <Modal maxWidth={false} onClose={() => {setOpenReclamosAseguradora(false)}}>
                  <SiniestrosReclaAseg 
                  datos={{tipoReclamo:2, reclamoPrincipalID:stSiniesReclTercerosID, folioPrincipal: folioPrincipal}} 
                  setOpenReclamosAseguradora={() => setOpenReclamosAseguradora(false)}
                  >
                  </SiniestrosReclaAseg>
                </Modal>                
            }
            {
                openReclamosTerceros &&
                 <Modal maxWidth={false} onClose={() => {setOpenReclamosTerceros(false)}}>
                  <ReclamacionATerceros 
                  datos={{tipoReclamo:2, reclamoPrincipalID:stSiniesReclTercerosID, folioPrincipal: folioPrincipal}} 
                  setOpenReclamosTerceros={() => setOpenReclamosTerceros(false)}
                  >
                  </ReclamacionATerceros>
                </Modal>                
            }
            {
                openSeguimientos && 
                <Modal
                    open={openSeguimientos}
                    onClose={() => setOpenSeguimientos(false)}>
                    <SeguimientoActividades datos={{ reporteDaniosID: stReporteDaniosID, siniesReclTercerosID: stSiniesReclTercerosID, verHistorial }} reclamosTerceros={true} actualizarTablaReclamosTerceros={setActualizar} />
                </Modal>
            }

        {
            openControlPago && <Modal maxWidth={true}
                open={openControlPago}
                onClose={() => setOpenControlPago(false)}
            >
                <UpsertControlPago 
                data={{ reporteDaniosID: stReporteDaniosID.repDaniosID }} 
                referencia={
                   { id: stReporteDaniosID.recTerID,
                    ref: 'recTerID'}
                }
                cerrar={() => setOpenControlPago(false)}
                propsAlert={alert} 
                propsSetAlert={setAlert}
                ></UpsertControlPago>
            </Modal>
        }
        </>);
}

export default ReclamacionATerceros;
