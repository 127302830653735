import { Restaurant } from "@material-ui/icons";
import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor, strUrlSArchivos } from '../../../helpers';
import {  getArchivo, deleteFileStorage, blobToBase64 } from "../../../helpers/serverStorage";

export const tablaUsuarios = async (variables, token) => {
    var Query = `
    query filterUsuario($filtro: InputFilterUsuario){
        filterUsuario(filtro:$filtro){
        listado{
            usuarioID
            nombreUsuario
            perfil
            correoUsuario
            rutaFotoPerfil
            fotoUsuario
            nombreFoto
            notificaciones
            paginaInicio
        }
        registros
        paginas
        }
    }
    `;
    const tResult = await Ejecutar(variables, token, Query);
    return tResult;

}

export const getUsuario = async (variables, token) => {
    var Query = `
    query get($filtro: InputGetUsuario){
        getUsuario(filtro:$filtro) {
            usuarioID
            usuarioBitID
            usuario
            nombreUsuario
            correoUsuario
            clave
            activo
            bloqueado
            fechaAlta
            fotoUsuario
            perfil
            empresas
            telefono
            rutaFotoPerfil
            storageID
            notificaciones
            paginaInicio
        }
    }
    `;

    var resultado = await Ejecutar({filtro: variables}, token, Query);

    resultado["empresas"] = resultado["empresas"].split(",");
    resultado["clave"] = "";
    resultado["RepetirPassWord"] = "";

    resultado["fotoUsuario"] = "";

    if(resultado["activo"]){
        resultado["activo"] = 1;
    }else{
        resultado["activo"] = 0;
    }

    if(resultado["bloqueado"]){
        resultado["bloqueado"] = 1;
    }else{
        resultado["bloqueado"] = 0;
    }

    for (const [key, value] of Object.entries(resultado)) {
        if(value === null){
            resultado[key] = "";
        }
    }
    let foto = resultado.rutaFotoPerfil ? await getArchivo('/private/segumex'+resultado.rutaFotoPerfil, token) : "";
    resultado["fotoUsuario"] = foto;
    resultado["rutaFotoPerfil"] = resultado.rutaFotoPerfil;

    return resultado;
}


export const getAllUsuario = async (variables, token) => {
    var Query = `
    query getAllUsuario($filtro: InputGetUsuario){
        getAllUsuario(filtro:$filtro){
            usuarioID
            usuarioBitID
            usuario
            nombreUsuario
            correoUsuario
            clave
            activo
            bloqueado
            fechaAlta
            fotoUsuario
            perfil
            empresas
            telefono
            rutaFotoPerfil
            storageID
            notificaciones
            paginaInicio
        }
    }
    `;

    var tResult = await Ejecutar({filtro : variables}, token, Query);

    return tResult;
}

export const addUsuario = async (variables, token) => {
    var Query = `
    mutation addUsuario($input: InputAddUsuario!) {
        addUsuario(input: $input) {
            usuarioID
            usuarioBitID
            usuario
            nombreUsuario
            correoUsuario
            clave
            activo
            bloqueado
            fechaAlta
            fotoUsuario
            perfil
            empresas
            telefono
            notificaciones
            paginaInicio
        }
    }
    `;

    var tResult = await Ejecutar({input: variables}, token, Query);

    return tResult;
};

export const updateUsuario = async (variables, token) => {
    var Query = `
    mutation updateUsuario($input: InputUpdateUsuario!) {
        updateUsuario(input: $input) {
            usuarioID
            usuarioBitID
            usuario
            nombreUsuario
            correoUsuario
            clave
            activo
            bloqueado
            fechaAlta
            fotoUsuario
            perfil
            empresas
            telefono
            notificaciones
            paginaInicio
        }
    }
    `;
    var tResult = await Ejecutar({input: variables}, token, Query);

    return tResult;
};

export const deleteUsuario = async (variables, token) => {
    var Query = `
    mutation deleteUsuario($input: InputDeleteUsuario) {
        deleteUsuario(input: $input)
    }
    `;

    let valor = variables["usuarioID"] ;
    valor = {usuarioID:valor};

    var tResult = await Ejecutar({input : valor}, token, Query);
    let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);

    return tResult;
};

export const asignarEmpresasUsuario = async (variables, token) => {
    var Query = `
    mutation asignarEmpresasUsuario($usuarioID: ID!, $empresasIDs: [String]!) {
        asignarEmpresasUsuario(id: $usuarioID, empresasIDs: $empresasIDs)
    }
    `;

    return await Ejecutar(variables, token, Query);
};

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });

        return Respuesta[Object.keys(Respuesta)[0]];

    } catch(error) { throw new Error(error.message); }
}


export const seleccionUsuario = async (variables, token) => {
    try {

        let allUsuario = await getAllUsuario(variables, token);

        if(!Array.isArray(allUsuario)) throw new Error("No se encontraron registros");

        return allUsuario.map(({ usuarioID:value, usuario: label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}
