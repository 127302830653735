import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const ADD_SOLSEGFIANEQUIPOOPER = `
mutation addSolSegFianEquipoOper($input: InputAddSolSegFianEquipoOper!){
    addSolSegFianEquipoOper(input: $input){
        equipoOperID
        solSegFianID
        activoAsegID
        equipo
        noEco
        marca
        modelo
        anio
        serie
        capacidad
        empresaID
        ubicacionID
        valorAseg
        sustituye
        motivo
        sustituyeID
        activoSustituye
        divisaID
        paridad
        activo
        modeloCorrecto
        montoAsegurado
    }
}
`;




export const UPDATE_SOLSEGFIANEQUIPOOPER = `
mutation updateSolSegFianEquipoOper($input: InputUpdateSolSegFianEquipoOper!){
    updateSolSegFianEquipoOper(input: $input){
        equipoOperID
        solSegFianID
        activoAsegID
        equipo
        noEco
        marca
        modelo
        anio
        serie
        capacidad
        empresaID
        ubicacionID
        valorAseg
        sustituye
        motivo
        sustituyeID
        activoSustituye
        divisaID
        paridad
        activo
        modeloCorrecto
        montoAsegurado
    }
}
`;




export const DELETE_SOLSEGFIANEQUIPOOPER = `
mutation deleteSolSegFianEquipoOper($input: InputDeleteSolSegFianEquipoOper){
    deleteSolSegFianEquipoOper(input: $input)
}
`;

export const addSolSegFianEquipoOper = async (variables, token) =>
{
    try{
        let {addSolSegFianEquipoOper} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SOLSEGFIANEQUIPOOPER
        });
        return addSolSegFianEquipoOper;
    }catch({message}) {throw new Error(message);}
}

export const updateSolSegFianEquipoOper = async (variables, token) =>
{
    try{
        let {updateSolSegFianEquipoOper} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SOLSEGFIANEQUIPOOPER
        });
        return updateSolSegFianEquipoOper;
    }catch({message}) {throw new Error(message);}
}

export const deleteSolSegFianEquipoOper = async (input, token) => {
    try{
        let {deleteSolSegFianEquipoOper} = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_SOLSEGFIANEQUIPOOPER
        });
        return deleteSolSegFianEquipoOper;
    }catch ({message}) {throw new Error(message);}
}
