import { Restaurant } from "@material-ui/icons";
import { FetchAPI } from "@renedelangel/helpers";
import moment from "moment";
import { urlServidor } from '../../../helpers';
import { getArchivo, deleteFileStorage, blobToBase64 } from "../../../helpers/serverStorage";

export const tablaPresupuestoAnual = async(variables, token) => {

    // En este formulario si no se aplica ningún filtro no se debe mostrar nada
    if (variables.filtro.ejercicio === null || variables.filtro.empresaID === null ){
        return [];
    }

    let Query = `
    query filterPresupuestoAnual($filtro: InputFilterPresupuestoAnual){
        filterPresupuestoAnual(filtro:$filtro){
            listado{
                presupuestoID
                usuarioBitID
                ramosseguroID
                ramosseguro
                polizaID
                poliza
                divisaID
                divisa
                sumaAseguradaPoliza
                sumaAseguradaPresupuesto
                factorPrimaPromedio
                vigenciaInicio
                vigenciaFinal
                resultado
                porcentajeIncremento
                montoIncremento
                primaNetaEstimada
                primaNetaPresupuesto
                tipoCambioPromedio
                primaNetaPresupuestoMXN
                empresaID
                empresa
                ejercicio
                autorizado
                fechaAutorizado
                sumaAseguradaPolizaNueva
                diferenciaSumasAseguradas
                diferenciaPrimaNeta
                fechaModificacion
                ejercicioPresupuesto
                folio
                folioInterno
                reporte
                definitivo
                fechaDefinicion
            }
        registros
        paginas
        }
    }
    `;

    const tResult = await Ejecutar(variables, token, Query);

    for (const [key,
            value
        ] of Object.entries(tResult)) {
        if (value === null) {
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getPresupuestoAnual = async(variables, token) => {
    let Query = `
    query get($filtro: InputGetPresupuestoAnual){
        getPresupuestoAnual(filtro:$filtro) {
            presupuestoID
            usuarioBitID
            ramosseguroID
            ramosseguro
            polizaID
            poliza
            divisaID
            sumaAseguradaPoliza
            sumaAseguradaPresupuesto
            factorPrimaPromedio
            vigenciaInicio
            vigenciaFinal
            resultado
            porcentajeIncremento
            montoIncremento
            primaNetaEstimada
            primaNetaPresupuesto
            tipoCambioPromedio
            primaNetaPresupuestoMXN
            empresaID
            empresa
            ejercicio
            autorizado
            fechaAutorizado
            sumaAseguradaPolizaNueva
            diferenciaSumasAseguradas
            diferenciaPrimaNeta
            fechaModificacion
            paridad
            folio
        }
    }
    `;

    let resultado = await Ejecutar({ filtro: variables }, token, Query);
    for (const [key, value] of Object.entries(resultado)) {
        if (value === null) {
            resultado[key] = "";
        }
    }

    return resultado;
}

function formatearHora(pHora) {
    if (moment(pHora).isValid() === false) {
        let tHora = moment(pHora, 'HH:mm').format('HH:mm');
        if (moment(tHora).isValid() === false) {
            return tHora;
        }
        return "";
    }

    return moment(pHora).format("HH:mm");
}

export const addPresupuestoAnual = async(variables, token) => {
    let Query = `
    mutation addPresupuestoAnual($input: InputAddPresupuestoAnual!) {
        addPresupuestoAnual(input: $input) {
            presupuestoID
            usuarioBitID
            ramosseguroID
            polizaID
            divisaID
            sumaAseguradaPoliza
            sumaAseguradaPresupuesto
            factorPrimaPromedio
            vigenciaInicio
            vigenciaFinal
            resultado
            porcentajeIncremento
            montoIncremento
            primaNetaEstimada
            primaNetaPresupuesto
            tipoCambioPromedio
            primaNetaPresupuestoMXN
            empresaID
            ejercicio
            autorizado
            fechaAutorizado
            sumaAseguradaPolizaNueva
            diferenciaSumasAseguradas
            diferenciaPrimaNeta
            fechaModificacion
            paridad
            folio
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const updatePresupuestoAnual = async(variables, token) => {
    let Query = `
    mutation updatePresupuestoAnual($input: InputUpdatePresupuestoAnual!) {
        updatePresupuestoAnual(input: $input) {
            presupuestoID
            usuarioBitID
            ramosseguroID
            polizaID
            divisaID
            sumaAseguradaPoliza
            sumaAseguradaPresupuesto
            factorPrimaPromedio
            vigenciaInicio
            vigenciaFinal
            resultado
            porcentajeIncremento
            montoIncremento
            primaNetaEstimada
            primaNetaPresupuesto
            tipoCambioPromedio
            primaNetaPresupuestoMXN
            empresaID
            ejercicio
            autorizado
            fechaAutorizado
            sumaAseguradaPolizaNueva
            diferenciaSumasAseguradas
            diferenciaPrimaNeta
            fechaModificacion
            paridad
            folio
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const deletePresupuestoAnual = async(variables, token) => {
    let Query = `
    mutation deletePresupuestoAnual($input: InputDeletePresupuestoAnual) {
        deletePresupuestoAnual(input: $input)
    }
    `;

    let valor = variables["presupuestoID"];
    valor = {
        presupuestoID: valor
    };

    let tResult = await Ejecutar({
        input: valor
    }, token, Query);

    return tResult;
};

export const GenerarPresupuestoAnual = async(variables, token) => {
    let Query = `
    mutation generarPresupuestoAnual($input: InputGenerarPresupuestoAnual){
        generarPresupuestoAnual(input: $input)
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};



export const autorizarPresupuestoAnual = async(variables, token) => {
    let Query = `
    mutation autorizarPresupuestoAnual($input: InputAutorizarPresupuestoAnual){
        autorizarPresupuestoAnual(input: $input)
    }
    `;
   
    let tResult = await Ejecutar({
        input: variables  
    }, token, Query);

    return tResult;
};

export const autorizarDefinitivamentePresupuestoAnual = async(variables, token) => {
    let Query = `
    mutation autorizarDefinitivamentePresupuestoAnual($input: InputAutorizarDefinitivamentePresupuestoAnual){
        autorizarDefinitivamentePresupuestoAnual(input: $input)
    }
    `;
   
    let tResult = await Ejecutar({
        input: variables  
    }, token, Query);

    return tResult;
};

export const seleccionPresupuestoAnual = async (variables, token) => {
    try {

        let allPresupuestoAnual = await getAllPresupuestoAnual(variables, token);

        if (!Array.isArray(allPresupuestoAnual)) throw new Error("No se encontraron registros");

        return allPresupuestoAnual.map(({ PresupuestoAnualID: value, folioInterno: label }) => ({ value: parseInt(value), label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const getAllPresupuestoAnual = async (variables, token) => {
    let Query = `
    query getAllPresupuestoAnual($filtro: InputGetPresupuestoAnual){
        getAllPresupuestoAnual(filtro:$filtro){
            presupuestoID
            usuarioBitID
            ramosseguroID
            ramosseguro
            polizaID
            poliza
            divisaID
            sumaAseguradaPoliza
            sumaAseguradaPresupuesto
            factorPrimaPromedio
            vigenciaInicio
            vigenciaFinal
            resultado
            porcentajeIncremento
            montoIncremeno
            primaNetaEstimada
            primaNetaPresupuesto
            tipoCambioPromedio
            primaNetaPresupuestoMXN
            empresaID
            empresa
            ejercicio
            autorizado
            fechaAutorizado
            sumaAseguradaPolizaNueva
            diferenciaSumasAseguradas
            diferenciaPrimaNeta
            fechaModificacion
            folio
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const getAllFolioPresupuesto = async (variables, token) => {
    let Query = `
    query getAllFolios($filtro: InputGetFoliosPresupuesto){
        getAllFolios(filtro:$filtro){
            folio
            folioInterno
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const seleccionFoliosPresupuesto = async (variables, token) => {
    try {

        let allFoliosPresupuesto = await getAllFolioPresupuesto(variables, token);

        if (!Array.isArray(allFoliosPresupuesto)) throw new Error("No se encontraron registros");

        return allFoliosPresupuesto.map(({ folio, folioInterno }) => ({ value: folio, label: folioInterno.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const getMinEjercicio = async (token) => {
    let Query = `
    query getMinEjercicio{
        getMinEjercicio
    }
    `;
    let tResult = await Ejecutar({}, token, Query);
    return tResult;
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) { throw new Error(error.message); }
}


export const getAllFolioPresupuestoReporte = async (variables, token) => {
    let Query = `
    query getAllFoliosReporte($filtro: InputGetFoliosPresupuestoReporte){
        getAllFoliosReporte(filtro:$filtro){
            folio
            reporte
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const seleccionFoliosPresupuestoReporte = async (variables, token) => {
    try {

        let allFoliosPresupuestoReporte = await getAllFolioPresupuestoReporte(variables, token);

        if (!Array.isArray(allFoliosPresupuestoReporte)) throw new Error("No se encontraron registros");

        return allFoliosPresupuestoReporte.map(({ folio, reporte }) => ({ value: folio, label: reporte }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}