import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_EDIFICIO = `
mutation add($input: InputAddSolSegFianEdificio)
{
    addSolSegFianEdificio(input : $input)
  {
    edificioID
    usuarioBitID
    solsegfiaID
    empresaID
    empresa
    grupoID
    grupo
    bienBajoConvenio
    infraestructura
    descripcion
    areaM2
    valorFactura
    valorAsegurado
    divisaID
    divisa
    paridad
  }
}
`;

export const UPDATE_EDIFICIO =`
mutation update($input: InputUpdateSolSegFianEdificio)
{
    updateSolSegFianEdificio(input : $input)
  {
    edificioID
    usuarioBitID
    solsegfiaID
    empresaID
    empresa
    grupoID
    grupo
    bienBajoConvenio
    infraestructura
    descripcion
    areaM2
    valorFactura
    valorAsegurado
    divisaID
    divisa
    paridad
  }
}
`;

export const DELETE_EDIFICIO = `
mutation delete ($input : InputDeleteSolSegFianEdificio)
{
    deleteSolSegFianEdificio(input: $input)
}
`;

export const addEdificio = async (variables, token) => {
    try {
        let { addSolSegFianEdificio } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_EDIFICIO
        });
        return addSolSegFianEdificio;
    } catch({message}) { throw new Error(message); }
};

export const updateEdificio = async (variables, token) => {
    try {
        let { updateSolSegFianEdificio } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_EDIFICIO
        });
        return updateSolSegFianEdificio;
    } catch({message}) { throw new Error(message); }
};

export const deleteEdificio= async (input, token) => {
    try {
        let { deleteSolSegFianEdificio } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_EDIFICIO
        });
        return deleteSolSegFianEdificio;
    } catch({message}) { throw new Error(message); }
};
