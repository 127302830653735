import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_AGENTES_SEGURO = `
mutation addAgentesSeguro($input: InputAddAgentesSeguro!) {
    addAgentesSeguro(input: $input) {
        agenteSegID
        agenteSeg
    }
}
`;


export const UPDATE_AGENTES_SEGURO =`
mutation updateAgentesSeguro($input: InputUpdateAgentesSeguro!) {
    updateAgentesSeguro(input: $input) {
        agenteSegID
        agenteSeg
    }
}
`;

export const DELETE_AGENTES_SEGURO = `
mutation deleteAgentesSeguro($input: InputDeleteAgentesSeguro) {
    deleteAgentesSeguro(input: $input)
}
`;


export const addAgentesSeguro = async (variables, token) => {
    try {

        let { addAgentesSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_AGENTES_SEGURO
        });

        return addAgentesSeguro;

    } catch({message}) { throw new Error(message); }
};

export const updateAgentesSeguro = async (variables, token) => {
    try {

        let { updateAgentesSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_AGENTES_SEGURO
        });

        return updateAgentesSeguro;

    } catch({message}) { throw new Error(message); }
};


export const deleteAgentesSeguro = async (input, token) => {
    try {

        let { deleteAgentesSeguro } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_AGENTES_SEGURO
        });

        return deleteAgentesSeguro;

    } catch({message}) { throw new Error(message); }
};
