import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_AREA = `
mutation addArea($input: InputAddArea!) {
    addArea(input: $input) {
        areaID
        area
    }
}
`;


export const UPDATE_AREA =`
mutation updateArea($input: InputUpdateArea!) {
    updateArea(input: $input) {
        areaID
        area
    }
}
`;

export const DELETE_AREA = `
mutation deleteArea($input: InputDeleteArea) {
    deleteArea(input: $input)
}
`;


export const addArea = async (variables, token) => {
    try {

        let { addArea } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_AREA
        });

        return addArea;

    } catch({message}) { throw new Error(message); }
};

export const updateArea = async (variables, token) => {
    try {

        let { updateArea } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_AREA
        });

        return updateArea;

    } catch({message}) { throw new Error(message); }
};


export const deleteArea = async (input, token) => {
    try {

        let { deleteArea } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_AREA
        });

        return deleteArea;

    } catch({message}) { throw new Error(message); }
};
