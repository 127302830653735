import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_UBICACION = `
query getUbi($filtro: InputGetUbicacion)
{
  getUbicacion(filtro: $filtro)
  {
    ubicacionID
    ubicacion
    empresasID
    auxEmpresasID
  }
}`;

export const GET_ALL_UBICACIONES = `
query getAllEmpresasUbicacion($filtro: InputGetEmpresasUbicacion)
{
  getAllEmpresasUbicacion(filtro: $filtro)
  {
    ubicacionID
    ubicacion
  }
}`;



export const TABLA_UBICACIONES = `
query filterUbi($filtro: InputFiltroUbicacion)
{
  filterUbicacion(filtro: $filtro)
  {
    listado{
      ubicacionID
      ubicacion
      empresasID
      auxEmpresasID
    }
   paginas
    registros
  }
}`;

export const getUbicacion = async (filtro, token) => {
    try {
        let { getUbicacion } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_UBICACION
        });

        return getUbicacion;

    } catch({message}) { throw new Error(message); }
}

export const getAllUbicaciones = async (variables, token) => {
    try {

        let { getAllEmpresasUbicacion } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_UBICACIONES
        });
        
        return getAllEmpresasUbicacion;

    } catch({message}) { throw new Error(message); }
}

export const seleccionUbicaciones = async (variables, token) => {
    try {

        let allUbicaciones = await getAllUbicaciones
        (variables, token);

        if(!Array.isArray(allUbicaciones)) throw new Error("No se encontraron registros");

        return allUbicaciones.map(({ ubicacionID:value, ubicacion:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaUbicacion = async (variables, token) => {
    try{
        let {filterUbicacion} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_UBICACIONES
        });
        return filterUbicacion;
    }catch({message}) { throw new Error(message); }
}
