import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';
import moment from "moment";

export const GET_SEGUIMIENTO_ACTIVIDADES = `
        query get($filtro: InputGetSeguimientoActividades){
            getSeguimientoActividades(filtro: $filtro){
                seguimientoID
                actividadID
                usuarioBitID
                seguimiento
                fechaInicio
                fechaFinal
                fechaInicioReal
                fechaFinalReal
                estado
                colorEstado
                finalizarSeguimiento
                titulo
                folioTarea
                tituloTarea
                nombreUsuario
                nombreUsuarioModifico
                siniesReclAsegID
                siniesReclTercerosID
                tipo
                reporteDaniosID
                siniesReclAsegFolio
                siniesReclTercerosFolio
                tipoSeguimiento
            }
        }`;

export const GET_ALL_SEGUIMIENTO_ACTIVIDADES = `
        query filtroGetAll($filtro: InputGetSeguimientoActividades){
            getAllSeguimientoActividades(filtro: $filtro){
                seguimientoID
                actividadID
                usuarioBitID
                seguimiento
                fechaInicio
                fechaFinal
                fechaInicioReal
                fechaFinalReal
                estado
                colorEstado
                finalizarSeguimiento
                titulo
                folioTarea
                tituloTarea
                nombreUsuario
                nombreUsuarioModifico
                siniesReclAsegID
                siniesReclTercerosID
                tipo
                reporteDaniosID
                tipoSeguimiento
            }
        }`;

export const TABLA_SEGUIMIENTO_ACTIVIDADES = `
        query filtro($filtro: InputFiltroSeguimientoActividades){
            filterSeguimientoActividades(filtro: $filtro){
            listado{
                seguimientoID
                actividadID
                usuarioBitID
                seguimiento
                fechaInicio
                fechaFinal
                fechaInicioReal
                fechaFinalReal
                estado
                colorEstado
                finalizarSeguimiento
                titulo
                folioTarea
                tituloTarea
                nombreUsuario
                nombreUsuarioModifico
                siniesReclAsegID
                siniesReclTercerosID
                reporteDaniosID
                folioInternoReporteDanios
                siniesReclAsegFolio
                siniesReclTercerosFolio
                tipoSeguimiento
            }
            registros
            paginas
            }
        }`;

export const getSeguimientoActividades = async (filtro, token) => {
    try {
        let { getSeguimientoActividades } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_SEGUIMIENTO_ACTIVIDADES
        });

        getSeguimientoActividades = {
            ...getSeguimientoActividades,
            fechaInicioReal: new Date(getSeguimientoActividades.fechaInicioReal),
            fechaFinalReal: new Date(getSeguimientoActividades.fechaFinalReal),
            horaInicial: moment(getSeguimientoActividades.fechaInicioReal),
            horaFinal: moment(getSeguimientoActividades.fechaFinalReal),
        };
        
        return getSeguimientoActividades;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllSeguimientoActividades = async (variables, token) => {
    try {
        let { getAllSeguimientoActividades } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SEGUIMIENTO_ACTIVIDADES
        });

        let auxData = getAllSeguimientoActividades.map(({ seguimientoID, actividadID, usuarioBitID, seguimiento, estado, colorEstado, fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal, finalizarSeguimiento, titulo, folioTarea, nombreUsuario, nombreUsuarioModifico }) => {
            return { seguimientoID, title: titulo, start: new Date(fechaInicioReal), end: new Date(fechaFinalReal), actividadID, usuarioBitID, colorEstado, fechaInicio, fechaFinal, estado, color: colorEstado, finalizarSeguimiento, seguimiento, folioTarea, nombreUsuario, nombreUsuarioModifico }
        })

        return auxData;

    } catch ({ message }) { throw new Error(message); }
}

export const seleccionSeguimientoActividades = async (variables, token) => {
    try {
        let allSeguimientoActividades = await getAllSeguimientoActividades
            (variables, token);

        if (!Array.isArray(allSeguimientoActividades)) throw new Error("No se encontraron registros");

        return allSeguimientoActividades.map(({ seguimientoID: value, seguimiento: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaSeguimientoActividades = async (variables, token) => {
    try {
        let { filterSeguimientoActividades } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SEGUIMIENTO_ACTIVIDADES
        });

        return filterSeguimientoActividades;
    } catch ({ message }) { throw new Error(message); }
}
