import { FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';


export const GET_HISTORIALPARIDADES = `
query get($filtro: InputGetHistorialParidades){
    getHistorialParidades(filtro: $filtro){

        paridadID
        divisaID
        fechaCotizacion
        paridad
        codigoMon
        fechaAlta
        fechaFin
    }
}
`;


export const GET_ALL_HISTORIALPARIDADES = `
query getAll ($filtro: InputGetHistorialParidades){
    getAllHistorialParidades(filtro: $filtro){
        paridadID
        divisaID
        fechaCotizacion
        paridad
        codigoMon
        fechaAlta
        fechaFin
    }
}
`;



export const TABLA_HISTORIALPARIDADES = 
`query filtro ($filtro: InputFilterHistorialParidades){
    filterHistorialParidades(filtro: $filtro){
        listado
        {
            paridadID
            divisaID
            fechaCotizacion
            paridad
            codigoMon
        }
        registros
        paginas
    }
}
`;


export const getHistorialParidades = async (filtro, token) =>{
    try{
        let {getHistorialParidades} = await FetchAPI.graphql({
            token,
            variables: {filtro},
            url: urlServidor,
            query: GET_HISTORIALPARIDADES
        });
        let  x = getHistorialParidades === null ? "" : getHistorialParidades
        return x;
    }catch ({message}) {throw new Error(message);}
}


export const getAllHistorialParidades = async (variables, token) => {
    try{
        let {getAllHistorialParidades} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_HISTORIALPARIDADES
        });
        return getAllHistorialParidades;
    }catch({message}) {throw new Error(message);}
}


export const tablaHistorialParidades = async (variable, token) =>{
    try{
        let {paridadID, divisaID, fechaCotizacion, paridad, codigoMon, pagina, limite} = variable.filtro;
        let filtro = {paridadID: paridadID === null ? null : Number (paridadID), divisaID, fechaCotizacion,  paridad, codigoMon, pagina, limite};
        let variables = {filtro};
        let {filterHistorialParidades} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_HISTORIALPARIDADES
        });
        return filterHistorialParidades;
    } catch({message}) {throw new Error(message);}
}
