import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_AREA = `
        query get($filtro: InputGetArea){
            getArea(filtro: $filtro){
                areaID
                area
            }
        }`;

    export const GET_ALL_AREA = `
        query filtroGetAll($filtro: InputGetArea){
            getAllArea(filtro: $filtro){
                areaID
                area
            }
        }`;

    export const TABLA_AREA = `
        query filtro($filtro: InputFiltroArea){
            filterArea(filtro: $filtro){
            listado{
                areaID
                area

            }
            registros
            paginas
            }
        }`;

export const getArea = async (filtro, token) => {
    try {
        let { getArea } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_AREA
        });

        return getArea;

    } catch({message}) { throw new Error(message); }
}

export const getAllArea = async (variables, token) => {
    try {

        let { getAllArea } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_AREA
        });

        return getAllArea;

    } catch({message}) { throw new Error(message); }
}

export const seleccionArea = async (variables, token) => {
    try {

        let allArea = await getAllArea
        (variables, token);

        if(!Array.isArray(allArea)) throw new Error("No se encontraron registros");

        return allArea.map(({ areaID:value, area:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaArea = async (variables, token) => {
    try{
        let {filterArea} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_AREA
        });
        return filterArea;
    }catch({message}) { throw new Error(message); }
}
