import { Restaurant } from "@material-ui/icons";
import { FetchAPI } from "@renedelangel/helpers";
import moment from "moment";
import { urlServidor } from '../../../helpers';
import { getArchivo, deleteFileStorage, blobToBase64 } from "../../../helpers/serverStorage";

export const tablaReporteDanios = async(variables, token) => {
    let Query = `
    query filterReporteDanios($filtro: InputFilterReporteDanios){
        filterReporteDanios(filtro:$filtro){
        listado{
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            finalizadoNombre
            seguimiento
            ultimoSeguimiento
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            fechaModificacion
            areaNombre
            areaRespDanioNombre
            areaRespRepaNombre
            ubicacionID
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            fechaModificacion
            estatusUnidad
            autoriza
            horaAutorizacion
            ubicacion,
            edad
            divisaID
            paridad
        }
        registros
        paginas
        }
    }
    `;

    let EsReporteador = false;

    if (document.location.pathname.includes("ReporteadorDeDanios")) {
        EsReporteador = true;
    }

    if (EsReporteador) {
        variables.filtro["finalizado"] = 1;
    } else {
        variables.filtro["finalizado"] = null;
    }

    const tResult = await Ejecutar(variables, token, Query);

    for (const [key,
            value
        ] of Object.entries(tResult)) {
        if (value === null) {
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getReporteDanios = async(variables, token) => {
    let Query = `
    query get($filtro: InputGetReporteDanios){
        getReporteDanios(filtro:$filtro) {
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            rutafotografia1
            storageID1
            nombrefoto1
            rutafotografia2
            storageID2
            nombrefoto2
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            fechaModificacion
            areaNombre
            areaRespDanioNombre
            areaRespRepaNombre
            ubicacionID
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            fechaModificacion
            ejercicio
            mes
            fechaInicio
            fechaFin
            estatusUnidad
            autoriza
            horaAutorizacion
            ubicacion,
            edad
            divisaID
            paridad
        }
    }
    `;

    let resultado = await Ejecutar({ filtro: variables }, token, Query);
    for (const [key, value] of Object.entries(resultado)) {
        if (value === null) {
            resultado[key] = "";
        }
    }

    let foto1 = resultado.rutafotografia1 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia1, token) :
        "";
    resultado["fotografia1"] = foto1;

    let foto2 = resultado.rutafotografia2 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia2, token) :
        "";
    resultado["fotografia2"] = foto2;

    if (resultado.edad === null || resultado.edad ==="") resultado["edad"] = 0;
    if (resultado.horaAutorizacion == "") resultado["horaAutorizacion"] = null;
    return resultado;
}

function formatearHora(pHora) {
    if (moment(pHora).isValid() === false) {
        let tHora = moment(pHora, 'HH:mm').format('HH:mm');
        if (moment(tHora).isValid() === false) {
            return tHora;
        }
        return "";
    }

    return moment(pHora).format("HH:mm");
}

export const addReporteDanios = async(variables, token) => {
    let Query = `
    mutation addReporteDanios($input: InputAddReporteDanios!) {
        addReporteDanios(input: $input) {
            reporteDaniosID
            usuarioBitID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            fechaModificacion
            areaNombre
            areaRespDanioNombre
            areaRespRepaNombre
            ubicacionID
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            estatusUnidad
            autoriza
            horaAutorizacion
            ubicacion
            edad
            divisaID
            paridad
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const updateReporteDanios = async(variables, token) => {
    let Query = `
    mutation updateReporteDanios($input: InputUpdateReporteDanios!) {
        updateReporteDanios(input: $input) {
            reporteDaniosID
            usuarioBitID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            horasInvertidas
            codigoClasifDanio
            semanaDanio
            montos
            fechaModificacion
            areaNombre
            areaRespDanioNombre
            areaRespRepaNombre
            ubicacionID
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            estatusUnidad
            autoriza
            horaAutorizacion
            ubicacion
            edad
            divisaID
            paridad
        }
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const deleteReporteDanios = async(variables, token) => {
    let Query = `
    mutation deleteReporteDanios($input: InputDeleteReporteDanios) {
        deleteReporteDanios(input: $input)
    }
    `;

    let valor = variables["reporteDaniosID"];
    valor = {
        reporteDaniosID: valor
    };

    let tResult = await Ejecutar({
        input: valor
    }, token, Query);
    /*let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);*/

    return tResult;
};

export const FinalizarReporteDanios = async(variables, token) => {
    let Query = `
    mutation finalizarReporteDanios($input: InputFinalizarReporteDanios){
        finalizarReporteDanios(input: $input)
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const CerrarReporteDanios = async(variables, token) => {
    let Query = `
    mutation cerrarReporteDanios($input: InputCerrarReporteDanios){
        cerrarReporteDanios(input: $input)
    }
    `;

    let tResult = await Ejecutar({
        input: variables
    }, token, Query);
    tResult = JSON.parse(tResult)

    return tResult;
};

export const seleccionReporteDanios = async (variables, token) => {
    try {

        let allReporteDanios = await getAllReporteDanios(variables, token);

        if (!Array.isArray(allReporteDanios)) throw new Error("No se encontraron registros");

        return allReporteDanios.map(({ reporteDaniosID: value, folioInterno: label }) => ({ value: parseInt(value), label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const getAllReporteDanios = async (variables, token) => {
    let Query = `
    query getAllReporteDanios($filtro: InputGetReporteDanios){
        getAllReporteDanios(filtro:$filtro){
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            rutafotografia1
            storageID1
            nombrefoto1
            rutafotografia2
            storageID2
            nombrefoto2
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            fechaModificacion
            areaNombre
            areaRespDanioNombre
            areaRespRepaNombre
            ubicacionID
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            ejercicio
            mes
            fechaInicio
            fechaFin
            estatusUnidad
            autoriza
            horaAutorizacion
            ubicacion
            edad
            divisaID
            paridad
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}
