import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_GASTO_MEDICO = `
query get($filtro: InputGetSolSegFianGtosMedMay)
{
  getSolSegFianGtosMedMay(filtro : $filtro)
  {
    gtosMedMayID
    solSegFianID
    materno
    paterno
    nombres
    fechaNacimiento
    edad
    parentesco
    motivo
    divisaID
    paridad
  }
}`;

export const GET_ALL_GASTOS_MEDICOS = `
query getAll($filtro: InputGetSolSegFianGtosMedMay)
{
  getAllSolSegFianGtosMedMay(filtro : $filtro)
  {
    gtosMedMayID
    solSegFianID
    materno
    paterno
    nombres
    fechaNacimiento
    edad
    parentesco
    motivo
    divisaID
    paridad
  }
}`;

export const TABLA_GASTOS_MEDICOS = `
query filter($filtro: InputFiltroSolSegFianGtosMedMay)
{
  filterSolSegFianGtosMedMay(filtro : $filtro)
  {
    listado{
      gtosMedMayID
      solSegFianID
      materno
      paterno
      nombres
      fechaNacimiento
      edad
      parentesco
      motivo
    }
    paginas
    registros
  }
}`;

export const getGastoMedico = async (filtro, token) => {
    try {
        let { getSolSegFianGtosMedMay } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_GASTO_MEDICO
        });

        return getSolSegFianGtosMedMay;

    } catch({message}) { throw new Error(message); }
}

export const getAllGastosMedicos = async (variables, token) => {
    try {

        let { getAllSolSegFianGtosMedMay } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_GASTOS_MEDICOS
        });

        return getAllSolSegFianGtosMedMay;

    } catch({message}) { throw new Error(message); }
}

export const tablaGastosMedicos = async (variables, token) => {
    try{
        let {filterSolSegFianGtosMedMay} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_GASTOS_MEDICOS
        });
        return filterSolSegFianGtosMedMay;
    }catch({message}) { throw new Error(message); }
}
