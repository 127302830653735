import React from 'react';
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import draftToMarkdown from 'draftjs-to-markdown';

import { grayColor } from "../../../assets/jss/material-dashboard-pro-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function WYSIWYG({ placeholder, disabled, value, onChange, inputRef, formatReturn }) {

    let ftConvert;

    switch(formatReturn){
        case "html":
            ftConvert = (val) => draftToHtml(convertToRaw(val.getCurrentContent()));
            break;
        case "markdown":
            ftConvert = (val) => draftToMarkdown(convertToRaw(val.getCurrentContent()));
            break;
        default:
            ftConvert = null;
            break;
    }

    return (<div style={{ borderBottom: `1px solid ${grayColor[17]}`, padding: "5px" }}>
        <Editor
            editorState={value}
            ref={inputRef}
            readOnly={disabled}
            toolbarHidden={disabled}
            placeholder={placeholder}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(val) => onChange(val, val ? (ftConvert ? ftConvert(val) : val) : {})}
        />
    </div>);

}

export default WYSIWYG;
