import React, { useState } from 'react'
import PropTypes from "prop-types";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import CustomInput from '../../../components/CustomInput/CustomInput.js';

// style for this view
import styles from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function Input(props) {

    const classes = useStyles();

    let { title, value, id, success = false, error = false, posicionIcono = "end", icono, helperText, textAlign = "left",
        fullWidth = true, disabled = false, onChange, onKeyDown, placeholder = "", inputProps = {}, inputRef, onClickIcono,
        multiline = false, formatoImportes = false } = props

    let componenteIcono = (icono ? icono : (error ? <HighlightOffIcon className={classes.danger} /> : ( success ? <CheckCircleOutlineIcon className={classes.success} /> : null )));

    let styleAdornment = onClickIcono ? { cursor: "pointer" } : {};
    let iconAction = onClickIcono ? (e) => onClickIcono(e) : () => { return; };
    let localValue = value;

    if (formatoImportes === true){
        localValue = ftFormatoImportes(value);
    }else{
        formatoImportes = false;
    }

    function ftFormatoImportes(num) {
        if (num === null || num === undefined){
            return "";
        }

        if(isNaN(num)){
            return "";
        }
        let tSplit = num.toString().split(".");
        let valorNum = "";

        if (tSplit.length === 0){
            valorNum = "";
            return valorNum;
        }

        let aux = tSplit[0];
        let newVal = "";
        let countAux = 0;

        for (let i = aux.length-1; i >= 0; i--) {
            if (countAux === 3) {
                newVal = aux[i] + "," + newVal;
                countAux = 1;
                continue;
            }
            newVal = aux[i] + newVal;
            countAux++;
        }

        valorNum = newVal;
        if (tSplit.length > 1) {
            valorNum = valorNum + "." + tSplit[1];
        }

        return valorNum;
    }

    function LocalChange({target}){
        let val = target.value.toString().replace(",", "");
        val = val.replace(",", "");
        props.onChange({ target: { value: val }});
    }

    return (<CustomInput
        style={{ margin: "0px" }}
        labelText={title}
        id={id}
        value={formatoImportes === false ? value : localValue}
        success={success}
        error={error}
        formControlProps={{ fullWidth }}
        helperText={helperText}
        textAlign={textAlign}
        inputProps={{
            startAdornment: (posicionIcono !== "end" && (icono || (success || error))) && (<InputAdornment style={styleAdornment} onClick={iconAction} position="start">{componenteIcono}</InputAdornment>),
            endAdornment: (posicionIcono === "end" && (icono || (success || error))) && (<InputAdornment style={styleAdornment} onClick={iconAction} position="end">{componenteIcono}</InputAdornment>),
            placeholder,
            disabled,
            onKeyDown,
            multiline,
            ...inputProps
        }}
        inputRef={inputRef}
        onChange={formatoImportes === false ? onChange : LocalChange}
    />);

}

Input.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.any,
    posicionIcono: PropTypes.string,
    textAlign: PropTypes.oneOf([
        "left",
        "right",
        "center"
    ]),
    placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    error: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    fullWidth: PropTypes.bool,
    icono: PropTypes.node,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onClickIcono: PropTypes.func
};
