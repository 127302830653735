import React, { useState, useRef, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
//storage
import { actualizaArchivo, eliminaArchivo, getArchivo } from "../../../helpers/serverStorage";
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';

import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import { seleccionUbicaciones } from '../../../querys/Ubicaciones';
import ExploreIcon from '@material-ui/icons/Explore';
import MultiCondicion from './MultiCondicion';
import { urlServidor } from 'helpers';

const { trim } = Validaciones;

function RecorridosDetalle({ recorridoID, folioInterno, estatus, actualizarDetalle, setActualizarDetalle,pEmpresaID = null }) {

    const { token, usuario } = useSelector(stDatos => stDatos.login);
    const cleanState = {};
    const cleanErrorState = {};
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    let Empresas = [];
    const [seleccionables, setSeleccionables] = useState({});
    let [stDatos, setState] = useState(cleanState);
    const [stEmpresas, setEmpresas] = useState(Empresas)
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [dataToSave, setDataToSave] = useState();
    const [clearData, setClearData] = useState(false);
    const [datosGet, setDatosGet] = useState();
    //const [actualizar, setActualizar] = useState(false);
    let actualizar = actualizarDetalle;
    let setActualizar = setActualizarDetalle;

    const [stFotografia1, setFotografia1] = useState({ valor: "", file: "", eliminar: false });
    const [stFotografia2, setFotografia2] = useState({ valor: "", file: "", eliminar: false });
    const [stFotografia3, setFotografia3] = useState({ valor: "", file: "", eliminar: false });
    const [stFotografia4, setFotografia4] = useState({ valor: "", file: "", eliminar: false });

    let titleAccion = stDatos.recorridoDetalleID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Partida`;
    let ArrayRef = [];
    let acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "recorridoDetalleID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros,
            token,
            setState,
            setOpen,
            setAlert,
            asyncGet: Consultas.getRecorridosDetalle
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "recorridoDetalleID" }, {campo: "descripcion"}],
        disabled: { multiseleccion: true },
        onClick: ({ recorridoDetalleID, descripcion }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el detalle del área de ${descripcion}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: { recorridoDetalleID },
                asyncDelete: Consultas.deleteRecorridosDetalle
            })
        })
    }];

    let botones = [{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    if (estatus === 'EN PROCESO') {
        botones = [{
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, ...botones]
    }

    let infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "recorridoDetalleID",
        color: primary,
        title: "Partidas " + folioInterno,
        iconTable: < ExploreIcon />,
        headers: [
            { variable: "recorridoDetalleID", descripcion: "ID", hide: true },

            { variable: "ubicacion", descripcion: "Ubicacion" },
            { variable: "descripcion", descripcion: "Área" },
            // { variable: "descripcion2", descripcion: "Segunda Descripción" },
        ],
        responsiveTitle: ["descripcion", /* "descripcion2" */],
        alineacion: [{ columnas: [0, 1, 2, 3, 4, 5], alineacion: "left" }]
    };

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["recorridoDetalleID"] = { error: false, helperText: "" };
    cleanState["recorridoDetalleID"] = 0;
    cleanState["ubicacionID"] = 0;



    InicializarInputs("ubicacionID", "Ubicación / Instalación *");
    InicializarInputs("descripcion", "Área *");
    // InicializarInputs("condicion", "Condición *");
    // InicializarInputs("descripcion2", "Segunda Desripción");
    // InicializarInputs("fotografia1", "Evidencia", "", "", "");
    // InicializarInputs("fotografia2", "Seguimiento", "", "", "");
    InicializarInputs("condiciones", "Condiciones", "Condiciones", [], "", 100);
    // InicializarInputs("fotografia3", "Fotografia 3", "", "", "Fotografías de Seguimiento");
    // InicializarInputs("fotografia4", "Fotografia 4", "", "", "Fotografías de Seguimiento");
    //-------------------------------------------------------
    
    InputsConID["ubicacionID"].tipo = "autocomplete";
    InputsConID["ubicacionID"].data = seleccionables.Ubicaciones;
    InputsConID["ubicacionID"].onChange = (e) =>{
        if (e?.value){
            setState({...stDatos,ubicacionID: e.value})
        }
    };

    //-----------------------------------------------------
    InputsConID["condiciones"].tipo = 'custom';
    InputsConID["condiciones"].custom = (<>
        <MultiCondicion setDataToSave={setDataToSave} clearData={clearData} datosGet={datosGet} />
    </>);
    InputsConID["descripcion"].grid = { md: 12, lg: 12 };
    // InputsConID["descripcion2"].grid = { md: 12, lg: 12 };
    // InputsConID["fotografia1"].grid = { md: 6, lg: 6 };
    // InputsConID["fotografia2"].grid = { md: 6, lg: 6 };

    // InputsConID["fotografia3"].grid = { md: 6, lg: 6 };
    // InputsConID["fotografia4"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["descripcion"].multiline = false;
    // InputsConID["descripcion2"].multiline = true;

    // InputsConID["fotografia1"].tipo = "imageupload";
    // InputsConID["fotografia1"]["removeImage"] = stDatos["fotografia1"] === "" ? true : false;
    // InputsConID["fotografia1"].onChange = (file, base) => {
    //     let remover = false;
    //     if (file === null) {
    //         remover = true;
    //     }
    //     setState(stDatos => ({
    //         ...stDatos,
    //         fotografia1: base
    //     }));

    //     if(open){
    //         setFotografia1({ valor: base, file: file, eliminar: remover });
    //     }
    // };

    // InputsConID["fotografia2"].tipo = "imageupload";
    // InputsConID["fotografia2"]["removeImage"] = stDatos["fotografia2"] === "" ? true : false;
    // InputsConID["fotografia2"].onChange = (file, base) => {
    //     let remover = false;
    //     if (file === null) {
    //         remover = true;
    //     }
    //     setState(stDatos => ({
    //         ...stDatos,
    //         'fotografia2': base
    //     }));

    //     if(open){
    //         setFotografia2({ valor: base, file: file, eliminar: remover });
    //     }
    // };

    // InputsConID["fotografia3"].tipo = "imageupload";
    // InputsConID["fotografia3"]["removeImage"] = stDatos["fotografia3"] === "" ? true : false;
    // InputsConID["fotografia3"].onChange = (file, base) => {
    //     let remover = false;
    //     if (file === null) {
    //         remover = true;
    //     }
    //     setState(stDatos => ({
    //         ...stDatos,
    //         'fotografia3': base
    //     }));
    //     if (open) {
    //         setFotografia3({ valor: base, file: file, eliminar: remover });
    //     }
    // };

    // InputsConID["fotografia4"].tipo = "imageupload";
    // InputsConID["fotografia4"]["removeImage"] = stDatos["fotografia4"] === "" ? true : false;
    // InputsConID["fotografia4"].onChange = (file, base) => {
    //     let remover = false;
    //     if (file === null) {
    //         remover = true;
    //     }
    //     setState(stDatos => ({
    //         ...stDatos,
    //         'fotografia4': base
    //     }));
    //     if (open) {
    //         setFotografia4({ valor: base, file: file, eliminar: remover });
    //     }
    // };

    InputsConID["ubicacionID"].disabled = estatus === "EN PROCESO" ? false : true;
    /* InputsConID["descripcion"].disabled = (estatus === "EN PROCESO") ? false : true; */
    // InputsConID["descripcion2"].disabled = (estatus === "EN PROCESO") ? false : true;
    // InputsConID["fotografia1"].disabled = (estatus === "EN PROCESO") ? false : true;
    // InputsConID["fotografia2"].disabled = (estatus === "EN PROCESO") ? false : true;
    // InputsConID["fotografia3"].disabled = (estatus === "EN PROCESO") ? false : true;
    // InputsConID["fotografia4"].disabled = (estatus === "EN PROCESO") ? false : true;

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    if (estatus === 'EN PROCESO') {
        accionesFormulario = [{
            loader,
            disabled,
            icono: SaveIcon,
            color: info,
            descripcion: "Guardar",
            onClick: () => handleGuardar({
                setLoader,
                setDisabled,
                setActualizar,
                actualizar,
                setNotificaciones,
                handleClose,
                handleFocus,
                refFocus: ArrayRef["descripcion"],
                mensajeCorrecto: `La partida se ${stDatos["recorridoDetalleID"] ? "actualizó" : "agregó"} correctamente`,
                asyncCallback: ftGuardar,
            }),
        }, ...accionesFormulario]
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState,
            condicion,
            ref,
            keyError,
            mensajeError,
            loader: setLoader,
            disabled: setDisabled,
            errorState: setErrorState
        });
    }

    async function handleValidaciones() {

        let error;
        let validaciones = [
            {
                condicion: !stDatos["ubicacionID"] || ["ubicacionID"] === 0,
                // ref: ArrayRef["ubicacionID"],
                keyError: "ubicacionID",
                mensajeError: "Debe especificar la ubicación"
            },
            {
                condicion: !stDatos["descripcion"] || trim(stDatos["descripcion"]) === "",
                ref: ArrayRef["descripcion"],
                keyError: "descripcion",
                mensajeError: "Debe especificar la descripción"
            }
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["recorridoDetalleID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            }
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            let valor;
            let error = await handleValidaciones();

            let DatosGuardar = { ...stDatos };
            DatosGuardar["recorridoDetalleID"] = parseInt(DatosGuardar["recorridoDetalleID"]);
            DatosGuardar["ubicacionID"] = parseInt(DatosGuardar["ubicacionID"]);
            /* DatosGuardar["descripcion"] = DatosGuardar["descripcion"].toString(); */

            DatosGuardar["recorridoID"] = recorridoID;

            if (error) return error;

            delete DatosGuardar["condiciones"];


            // let datos = { serverStorageID: stDatos.storageID1,
            //             origenID: Number(DatosGuardar.recorridoDetalleID),
            //             tabla: "RECORRIDOS",
            //             tipo: "RECORRIDOS",
            //             clasificacion: "fotografia1",
            //             nombreArchivo: stFotografia1.eliminar ? "" : stFotografia1.file.name,
            //             ruta: stDatos["rutafotografia1"] }

            // let datos2 = { serverStorageID: stDatos.storageID2,
            //             origenID: Number(DatosGuardar.recorridoDetalleID),
            //             tabla: "RECORRIDOS",
            //             tipo: "RECORRIDOS",
            //             clasificacion: "fotografia2",
            //             nombreArchivo: stFotografia2.eliminar ? "" : stFotografia2.file.name,
            //             ruta: stDatos["rutafotografia2"] }

            // let datos3 = {
            //     serverStorageID: stDatos.storageID3,
            //     origenID: Number(DatosGuardar.recorridoDetalleID),
            //     tabla: "RECORRIDOS",
            //     tipo: "RECORRIDOS",
            //     clasificacion: "fotografia3",
            //     nombreArchivo: stFotografia3.eliminar ? "" : stFotografia3.file.name,
            //     ruta: stDatos["rutafotografia3"]
            // }

            // let datos4 = {
            //     serverStorageID: stDatos.storageID4,
            //     origenID: Number(DatosGuardar.recorridoDetalleID),
            //     tabla: "RECORRIDOS",
            //     tipo: "RECORRIDOS",
            //     clasificacion: "fotografia4",
            //     nombreArchivo: stFotografia4.eliminar ? "" : stFotografia4.file.name,
            //     ruta: stDatos["rutafotografia4"]
            // }

            delete DatosGuardar["fotografia1"];
            delete DatosGuardar["rutafotografia1"];
            delete DatosGuardar["storageID1"];
            delete DatosGuardar["nombrefoto1"];

            delete DatosGuardar["fotografia2"];
            delete DatosGuardar["rutafotografia2"];
            delete DatosGuardar["storageID2"];
            delete DatosGuardar["nombrefoto2"];

            delete DatosGuardar["fotografia3"];
            delete DatosGuardar["rutafotografia3"];
            delete DatosGuardar["storageID3"];
            delete DatosGuardar["nombrefoto3"];

            delete DatosGuardar["fotografia4"];
            delete DatosGuardar["rutafotografia4"];
            delete DatosGuardar["storageID4"];
            delete DatosGuardar["nombrefoto4"];

            delete DatosGuardar["area"];
            delete DatosGuardar["ubicacion"];

            if (stDatos["recorridoDetalleID"]) {
                valor = await Consultas.updateRecorridosDetalle(DatosGuardar, token);
            } else {
                delete DatosGuardar["recorridoDetalleID"];
                valor = await Consultas.addRecorridosDetalle(DatosGuardar, token);
                // datos = { ...datos, serverStorageID: null, origenID: valor.recorridoDetalleID };
                // datos2 = { ...datos2, serverStorageID: null, origenID: valor.recorridoDetalleID };
                // datos3 = { ...datos3, serverStorageID: null, origenID: valor.recorridoDetalleID };
                // datos4 = { ...datos4, serverStorageID: null, origenID: valor.recorridoDetalleID };
            }

            try {
                for (let i = 0; i < dataToSave.length; i++) {
                    const element = dataToSave[i];
                    let hallazgoFileID = element.hallazgoB64 === undefined ? null : element.evidencia, seguimientoFileID = element.seguimientoB64 === undefined ? null : element.seguimiento;
                    
                    if (element.hallazgoFile) {
                        let datosHallazgo = {
                            serverStorageID: null,
                            origenID: Number(DatosGuardar.recorridoDetalleID),
                            tabla: "RECORRIDOS CONDICIONES",
                            tipo: "RECORRIDOS",
                            clasificacion: "CONDICIONES",
                            nombreArchivo: element.hallazgoFile.name,
                            ruta: null
                        }
                        let {resultado:{serverStorageID}} = await actualizaArchivo('Recorridos/', element.hallazgoFile, 1, datosHallazgo, token, false);
                        hallazgoFileID = serverStorageID;
                    }

                    if (element.seguimientoFile) {
                        let datosSeguimiento = {
                            serverStorageID: null,
                            origenID: Number(DatosGuardar.recorridoDetalleID),
                            tabla: "RECORRIDOS CONDICIONES",
                            tipo: "RECORRIDOS",
                            clasificacion: "CONDICIONES",
                            nombreArchivo: element.seguimientoFile.name,
                            ruta: null
                        }
                        let {resultado:{serverStorageID}} = seguimientoFileID = await actualizaArchivo('Recorridos/', element.seguimientoFile, 1, datosSeguimiento, token, false);
                        seguimientoFileID = serverStorageID;
                    }



                    await agregarRecorridoCondicion({recorridoDetalleID: valor.recorridoDetalleID,condicionID: element.condicionID,condicion: element.condicion,evidencia:hallazgoFileID,seguimiento:seguimientoFileID,estatus: element.estatus,comentarios: element.comentarios})
                }
            } catch (error) {
                // console.error(error)
                setNotificaciones({ mensaje: error, color: danger, open: true });
            }
            // try {
            //     if (stFotografia1.file || stFotografia1.eliminar == true) {
            //         await actualizaArchivo('Recorridos/', stFotografia1.file, 1, datos, token, stFotografia1.eliminar);
            //     }
            //     if (stFotografia2.file || stFotografia2.eliminar == true) {
            //         await actualizaArchivo('Recorridos/', stFotografia2.file, 1, datos2, token, stFotografia2.eliminar);
            //     }
            //     // if (stFotografia3.file || stFotografia3.eliminar == true) {
            //     //     await actualizaArchivo('Recorridos/', stFotografia3.file, 1, datos3, token, stFotografia3.eliminar);
            //     // }
            //     // if (stFotografia4.file || stFotografia4.eliminar == true) {
            //     //     await actualizaArchivo('Recorridos/', stFotografia4.file, 1, datos4, token, stFotografia4.eliminar);
            //     // }
            // } catch (error) {
            //     setNotificaciones({ mensaje: error, color: danger, open: true });
            // }
        }

        return ftGuardar();
    }

    async function agregarRecorridoCondicion({recorridoDetalleID,condicionID,condicion,evidencia,seguimiento,estatus,comentarios}) {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("recorridoDetalleID", recorridoDetalleID);
        formdata.append("condicionID", condicionID);
        formdata.append("condicion", condicion);
        formdata.append("evidencia", evidencia);
        formdata.append("seguimiento", seguimiento);
        formdata.append("estatus", estatus);
        formdata.append("comentarios", comentarios);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/recorridosCondicionesUpsert`, requestOptions)
            .then(response => {
                return response.json();
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        // setFotografia1({ valor: "", file: "", remover: false });
        // setFotografia2({ valor: "", file: "", remover: false });
        // setFotografia3({ valor: "", file: "", remover: false });
        // setFotografia4({ valor: "", file: "", remover: false });
        setErrorState({ ...cleanErrorState });
        setClearData(!clearData);
    }

    function handleChange(value, key, MaxLength = 0) {
        if (key === "fotografia1"
            || key === "fotografia2"
            /*       || key === "fotografia3"
                  || key === "fotografia4" */
        ) {
            return;
        }

        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));
    }

    
    useEffect(() => {
        async function ftEffect() {
            try {
                let myHeaders = new Headers();
                myHeaders.append("token", token);
                myHeaders.append("mimeType", "multipart/form-data");
        
                let formdata = new FormData();
                formdata.append("recorridoDetalleID", stDatos.recorridoDetalleID);
        
                let requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata
                };
                let {resultado} = await fetch(`${urlServidor}/recorridosCondicionesGet`, requestOptions)
                .then(response => {
                    return response.json();
                })
                .catch(({ message }) => { throw new Error(message) });

                let auxResultado = [];

                for (let i = 0; i < resultado.length; i++) {
                    const element = resultado[i];
                    let hallazgoB64 = element.rutaEvidencia ? await getArchivo('/private/segumex' + element.rutaEvidencia, token) : "";
                    let seguimientoB64 = element.rutaSeguimiento ? await getArchivo('/private/segumex' + element.rutaSeguimiento, token) : "";
                    auxResultado.push(
                        {...element,hallazgoB64,seguimientoB64}
                    )
                }
                setDatosGet(auxResultado);

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, [stDatos.recorridoDetalleID]);


    async function ObtenerEmpresas() {
        let Respuesta = await ConsultasEmpresas.seleccionEmpresa({ filtro: { activa: 1 } }, token);
        setEmpresas(Respuesta);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();
                let Ubicaciones = await seleccionUbicaciones({ filtro: {empresaID: pEmpresaID} }, token);
                setSeleccionables({ Ubicaciones });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, [actualizar]);


    return (
        <>
            <Tabla infoTabla={infoTabla}
                asyncData={Consultas.tablaRecorridosDetalle}
                token={token}
                parametrosFijos={{ recorridoID }} />

            <FormularioModal open={open}
                title={titleModal}
                onClose={handleClose}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputs}
                acciones={accionesFormulario}
                focus={ArrayRef["ubicacionesID"]} />
            {
                alert && < SweetAlert title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />
            }
        </>
    );
}

export default RecorridosDetalle;
