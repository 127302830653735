import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_DEPARTAMENTO = `
query get($filtro: InputGetDepartamento){
    getDepartamento(filtro: $filtro){
        departamentoID
        areaID
        departamento
        empresasID
        auxEmpresasID
    }
}`;

export const GET_ALL_DEPARTAMENTOS = `
query getAll($filtro: InputGetDepartamento){
    getAllDepartamento(filtro: $filtro){
        departamentoID
        areaID
        departamento
         
    }
}`;

export const TABLA_DEPARTAMENTOS = `
query filter($filtro: InputFiltroDepartamento){
    filterDepartamento(filtro: $filtro){
    listado{
        departamentoID
        areaID
        departamento
        area
        empresasID
        auxEmpresasID
    }
    registros
    paginas
    }
}`;

export const getDepartamento = async (filtro, token) => {
    try {
        let { getDepartamento } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_DEPARTAMENTO
        });

        return getDepartamento;

    } catch({message}) { throw new Error(message); }
}

export const getAllDepartamento = async (variables, token) => {
    try {

        let { getAllDepartamento } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_DEPARTAMENTOS
        });

        return getAllDepartamento;

    } catch({message}) { throw new Error(message); }
}

export const seleccionDepartamento = async (variables, token) => {
    try {

        let allDepartamento = await getAllDepartamento
        (variables, token);

        if(!Array.isArray(allDepartamento)) throw new Error("No se encontraron registros");

        return allDepartamento.map(({ departamentoID:value, departamento:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaDepartamento = async (variables, token) => {
    try{
        let {filterDepartamento} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_DEPARTAMENTOS
        });
        return filterDepartamento;
    }catch({message}) { throw new Error(message); }
}
