import { Restaurant } from "@material-ui/icons";
import { FetchAPI } from "@renedelangel/helpers";
import moment from "moment";
import { urlServidor } from '../../../helpers';
import { getArchivo, deleteFileStorage, blobToBase64 } from "../../../helpers/serverStorage";

export const tablaReporteDaniosClientes = async(variables, token) => {
    var Query = `
    query filterReporteDaniosClientes($filtro: InputFilterReporteDaniosClientes){
        filterReporteDaniosClientes(filtro:$filtro){
        listado{
            reporteDaniosCliID
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            finalizadoNombre
            seguimiento
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            agencia
            cliente
            formaRecibido
            estatusCarga
            datosCarga
            categoria
            estatusAccion
            valorMercancia
            montoReclamado
            montoPagar
            procede
            recibio
            estatusReclamacion
            numSiniestro
            retroalimentacion
            pagoRealizado
            folioManiobras
            montoManiobras
            areaImputableID
            armadora
            quejaReclamacion
            nombrePoliza
            realizoPago

        }
        registros
        paginas
        }
    }
    `;

    let EsReporteador = false;

    if (document.location.pathname.includes("ReporteadorDeDanios")) {
        EsReporteador = true;
    }

    if (EsReporteador) {
        variables.filtro["finalizado"] = 1;
    } else {
        variables.filtro["finalizado"] = null;
    }

    const tResult = await Ejecutar(variables, token, Query);

    for (const [key,
            value
        ] of Object.entries(tResult)) {
        if (value === null) {
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getReporteDaniosClientes = async(variables, token) => {
    var Query = `
    query get($filtro: InputGetReporteDaniosClientes){
        getReporteDaniosClientes(filtro:$filtro) {
            reporteDaniosCliID
            reporteDaniosID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            rutafotografia1
            storageID1
            nombrefoto1
            rutafotografia2
            storageID2
            nombrefoto2
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            agencia
            cliente
            formaRecibido
            estatusCarga
            datosCarga
            categoria
            estatusAccion
            valorMercancia
            montoReclamado
            montoPagar
            procede
            recibio
            estatusReclamacion
            numSiniestro
            retroalimentacion
            pagoRealizado
            folioManiobras
            montoManiobras
            areaImputableID
            ubicacion
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            fechaModificacion
            ejercicio
            mes
            fechaInicio
            fechaFinReporte
            armadora
            quejaReclamacion
            nombrePoliza
            realizoPago
        }
    }
    `;

    let resultado = await Ejecutar({ filtro: variables }, token, Query);
    for (const [key, value] of Object.entries(resultado)) {
        if (value === null) {
            resultado[key] = "";
        }
    }

    let foto1 = resultado.rutafotografia1 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia1, token) :
        "";
    resultado["fotografia1"] = foto1;

    let foto2 = resultado.rutafotografia2 ?
        await getArchivo('/private/segumex' + resultado.rutafotografia2, token) :
        "";
    resultado["fotografia2"] = foto2;

    return resultado;
}

function formatearHora(pHora) {
    if (moment(pHora).isValid() === false) {
        let tHora = moment(pHora, 'HH:mm').format('HH:mm');
        if (moment(tHora).isValid() === false) {
            return tHora;
        }
        return "";
    }

    return moment(pHora).format("HH:mm");
}

export const addReporteDaniosClientes = async(variables, token) => {
    var Query = `
    mutation addReporteDaniosClientes($input: InputAddReporteDaniosClientes!) {
        addReporteDaniosClientes(input: $input) {
            reporteDaniosCliID
            reporteDaniosID
            usuarioBitID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            horasInvertidas
            folioInterno
            codigoClasifDanio
            semanaDanio
            montos
            agencia
            cliente
            formaRecibido
            estatusCarga
            datosCarga
            categoria
            estatusAccion
            valorMercancia
            montoReclamado
            montoPagar
            procede
            recibio
            estatusReclamacion
            numSiniestro
            retroalimentacion
            pagoRealizado
            folioManiobras
            montoManiobras
            areaImputableID
            ubicacion
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            fechaModificacion
        armadora
        quejaReclamacion
        nombrePoliza
        realizoPago
        }
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const updateReporteDaniosClientes = async(variables, token) => {
    var Query = `
    mutation updateReporteDaniosClientes($input: InputUpdateReporteDaniosClientes!) {
        updateReporteDaniosClientes(input: $input) {
            reporteDaniosCliID
            reporteDaniosID
            usuarioBitID
            empresaID
            fechaElaboracion
            numIncidencia
            numNC
            descripBreve
            lugarEvento
            fechaEvento
            horaEvento
            supervCargo
            areaID
            danioOcasioTer
            danioOcasioNos
            descripMedia
            danioOcasioA
            tipoDanioOcasio
            buqueViaje
            descripDetallada
            folioServicio
            tipoServicio
            tipoMercancia
            propiedadDe
            repreLocal
            fotografias
            nombresPersonal
            numControlPersonal
            habilidadBase
            habilidadActiv
            fechaIngreso
            fechaHabilidad
            otrosDaniosHabilidades
            nombresTestigos
            numControlTestigos
            habilidadTestigos
            nombresTestigos2
            numControlTestigos2
            habilidadTestigos2
            notifAseg
            reparaDanio
            inspecTerceros
            cotizacionDanio
            anexRep
            reportesAnex
            obserGenerales
            responAnalisis
            elaboro
            firma
            cargo
            tipoDanioOcasio
            folio
            danioOcasioPor
            danioOcasioARep
            equipoBienPersona
            areaRespDanioID
            areaRespRepaID
            costoDanio
            estatus
            tiempoInvertido
            atendio
            notas
            finalizado
            seguimiento
            horasInvertidas
            codigoClasifDanio
            semanaDanio
            montos
            agencia
            cliente
            formaRecibido
            estatusCarga
            datosCarga
            categoria
            estatusAccion
            valorMercancia
            montoReclamado
            montoPagar
            procede
            recibio
            estatusReclamacion
            numSiniestro
            retroalimentacion
            pagoRealizado
            folioManiobras
            montoManiobras
            areaImputableID
            ubicacion
            modeloUnidad
            numVin
            numControl
            nomChofer
            genero
            antiguedad
            fechaNacimiento
            horaNombramiento
            turnoAnterior
            horaAlimentos
            calistenia
            ultimaPlatica
            fechaUltimaPlatica
            fechaRevProc
            fechaModificacion
            armadora
            quejaReclamacion
            nombrePoliza
            realizoPago
        }
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const deleteReporteDaniosClientes = async(variables, token) => {
    var Query = `
    mutation deleteReporteDaniosClientes($input: InputDeleteReporteDaniosClientes) {
        deleteReporteDaniosClientes(input: $input)
    }
    `;

    let valor = variables["reporteDaniosCliID"];
    valor = {
        reporteDaniosCliID: valor
    };

    var tResult = await Ejecutar({
        input: valor
    }, token, Query);
    /*let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);*/

    return tResult;
};

export const FinalizarReporteDaniosClientes = async(variables, token) => {
    var Query = `
    mutation finalizarReporteDaniosClientes($input: InputFinalizarReporteDaniosClientes){
        finalizarReporteDaniosClientes(input: $input)
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);

    return tResult;
};

export const CerrarReporteDaniosClientes = async(variables, token) => {
    var Query = `
    mutation cerrarReporteDaniosClientes($input: InputCerrarReporteDaniosClientes){
        cerrarReporteDaniosClientes(input: $input)
    }
    `;

    var tResult = await Ejecutar({
        input: variables
    }, token, Query);
    tResult = JSON.parse(tResult)

    return tResult;
};

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}
