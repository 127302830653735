import { FetchAPI } from "@renedelangel/helpers";
import moment from "moment";
import { urlServidor } from '../../../helpers';
export const tablaInfoActAsegPolizas = async (variables, token) => {
    let Query = `
    query filterInfoActAsegPolizas($filtro: InputFilterInfoActivoAsegPolizas){
        filterInfoActAsegPolizas(filtro:$filtro){
        listado{
            infoActAsegID
            numATP
            noEco
            empresaID
            activoAsegID
            nombreActivoAseg
            equipo
            marca
            modelo
            serie
            serieCPU
            anio
            capacidad
            ramoSeguroID
            moneda
            sumaAseg
            fPrima
            primaNeta
            inicioVigencia
            terminoVigencia
            propietarioID
            ubicacionID
            valorReposicion
            clasificacion
            coberturasAdicionales
            polizasSeguroID
            polizaOrigenID
            solSegFianID
            incPol
            ubicacion
            departamento
            subPartida
            EsSubPartida
            fechaBaja
            fechaAlta
            estado
            valorFactura
            observaciones
            sustituyeID
            activoSustituido
            activoEquipoSerie
            solicitud
            autoAD
            fechaModificacion
            polRenovada
            partComplementaria
            fechaComplementaria
            serieMonitor
            serieCPU
            subPolizaID
            valorM2
            valorAseg
            uniCapacidad
            grupoID
            bienBajoConvenio
            descripcion
            areaM2
            activoID
            dimensiones
            tonelaje
            voltaje
            departamentoID
            responsable
            responsable2
            activoAsegID
            infraestructura
        }
        registros
        paginas
        }
    }
    `;

    const tResult = await Ejecutar(variables, token, Query);
    for (const [key, value] of Object.entries(tResult)) {
        if(value === null){
            tResult[key] = "";
        }
    }

    return tResult;
}

export const getInfoActAsegPolizas = async (variables, token) => {
    let Query = `
    query getInfoActAsegPolizas($filtro: InputGetInfoActivoAsegPolizas){
        getInfoActAsegPolizas(filtro:$filtro) {
            infoActAsegID
            numATP
            empresaID
            equipo
            marca
            modelo
            serie
            serieCPU
            anio
            capacidad
            ramoSeguroID
            moneda
            sumaAseg
            fPrima
            primaNeta
            inicioVigencia
            terminoVigencia
            propietarioID
            ubicacionID
            valorReposicion
            clasificacion
            coberturasAdicionales
            polizasSeguroID
            polizaOrigenID
            solSegFianID
            incPol
            ubicacion
            departamento
            subPartida
            fechaBaja
            fechaAlta
            estado
            valorFactura
            observaciones
            sustituyeID
            activoSustituido
            activoEquipoSerie
            solicitud
            autoAD
            fechaModificacion
            polRenovada
            partComplementaria
            fechaComplementaria
            ejercicio
            mes
            fechaInicio
            fechaFin
            omitir
            usuario
            activoAsegID
            tipoTransmision
            serieMonitor
            serieCPU
            motivo
            responsable
            responsable2
            divisaID
            paridad
            sustituye
            noEco
            nocpu
            noMonitor
            cdOrigen
            fechaSalida
            embalaje
            pesoyVol
            usuarioSolicita
            nuevoUsado
            noInv
            valorNuevo
            cdDestino
            fechaLlegada
            chofer
            sobreDimension
            activoAsegurar
            subPolizaID
            valorM2
            valorAseg
            uniCapacidad
            grupoID
            bienBajoConvenio
            descripcion
            areaM2
            activoID
            dimensiones
            tonelaje
            voltaje
            departamentoID
            infraestructura
        }
    }
    `;

    let resultado = await Ejecutar({filtro: variables}, token, Query);
    resultado = resultado === null ? {} : resultado
    for (const [key, value] of Object.entries(resultado)) {
        if(value === null){
            resultado[key] = "";
        }
    }
    
    return resultado;
}

export const getAllInfoActAsegPolizas = async (variables, token) => {
    let Query = `
    query getAllInfoActAsegPolizas($filtro: InputGetInfoActivoAsegPolizas){
        getAllInfoActAsegPolizas(filtro:$filtro) {
            infoActAsegID
            numATP
            empresaID
            equipo
            marca
            serie
            anio
            capacidad
            ramoSeguroID
            moneda
            sumaAseg
            fPrima
            primaNeta
            inicioVigencia
            terminoVigencia
            propietarioID
            ubicacionID
            valorReposicion
            clasificacion
            coberturasAdicionales
            polizasSeguroID
            polizaOrigenID
            solSegFianID
            incPol
            ubicacion
            departamento
            subPartida
            fechaBaja
            fechaAlta
            estado
            valorFactura
            observaciones
            sustituyeID
            activoSustituido
            activoEquipoSerie
            solicitud
            autoAD
            fechaModificacion
            polRenovada
            partComplementaria
            fechaComplementaria
            ejercicio
            mes
            fechaInicio
            fechaFin
            usuario
            activoAsegID
            modelo
            tipoTransmision
            responsable
            serieMonitor
            serieCPU
            noMonitor
            noEco
            nocpu
            activoAseg
            valorM2
            valorAseg
            uniCapacidad
            subPolizaID
            grupoID
            bienBajoConvenio
            descripcion
            areaM2
            activoID
            dimensiones
            tonelaje
            voltaje
            departamentoID
            infraestructura
        }
    }
    `;

    let resultado = await Ejecutar(variables, token, Query);
    for (const [key, value] of Object.entries(resultado)) {
        if(value === null){
            resultado[key] = "";
        }
    }

    return resultado;
}

function formatearHora(pHora){
    if(moment(pHora).isValid() === false){
        let tHora = moment(pHora,'HH:mm').format('HH:mm');
        if(moment(tHora).isValid() === false){
            return tHora;
        }
        return "";
    }

    return moment(pHora).format("HH:mm");
}

export const seleccionActivosAsegurado = async (variables, token, nSolicitud= "", valor = "") => {
    try {
        let arreglo1 = [];
        
        let getInfoActAsegPolizas = await getAllInfoActAsegPolizas
            (variables, token);

        if (Array.isArray(getInfoActAsegPolizas) && getInfoActAsegPolizas.length === 0) {
            arreglo1.unshift({ label: "No Aplica", value: "0" });

        } else {
            if (valor === "Aplica") {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    numATP,
                    noEco,
                    serie,
                    activoAseg 
                }) => {
                    arreglo1.push({ label:
                         `${polizasSeguroID === null ? "" : polizasSeguroID} -
                          Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) -
                          ${numATP === null ? "" : numATP} -
                          ${noEco === null ? "" : noEco} -
                          ${serie === null ? "" : serie} -
                          ${activoAseg === null ? "" : activoAseg}`, value: infoActAsegID });
                });

            } else if (valor === "Equipo Electrónico" || valor === "Equipo Electronico" || valor === "COBERTURA - EQUIPO ELECTRÓNICO" ) {

                const currentYear = new Date().getFullYear();

                const filteredPolizas = getInfoActAsegPolizas.filter(({ inicioVigencia }) => {
                    const vigenciaYear = new Date(inicioVigencia).getFullYear();
                    return vigenciaYear >= currentYear - 2;
                });

                getInfoActAsegPolizas = filteredPolizas;

                getInfoActAsegPolizas
                .sort((a, b) => {
                    const dateA = new Date(a.terminoVigencia);
                    const dateB = new Date(b.terminoVigencia);
                    return dateB - dateA;
                })
                .forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    valorFactura,
                    marca,
                    //nocpu,
                    //modelo,
                    serieCPU,
                    //noMonitor,
                    serieMonitor,
                    serie,
                    anio,
                    //departamento,
                    //responsable,
                    noEco 
                }) => {
                    arreglo1.push({
                        /*
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) - VA$
                             ${valorAseg === null ? "" : valorAseg} - 
                             ${serie === null ? "" : serie} - 
                             ${marca === null ? "" : marca} -
                             ${anio === null ? "" : anio} -
                             ${noEco === null ? "" : noEco} -  
                             ${nocpu === null ? "" : nocpu} - 
                             ${serieCPU === null ? "" : serieCPU} - 
                             ${modelo === null ? "" : modelo} - 
                             ${noMonitor === null ? "" : noMonitor} - 
                             ${serieMonitor === null ? "" : serieMonitor} - 
                             ${departamento === null ? "" : departamento} - 
                             ${responsable === null ? "" : responsable}`,
                        */
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                            Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) - VFac$
                            ${valorFactura === null ? "" : valorFactura} - 
                            ${serie === null ? "" : serie} - 
                            ${serieMonitor === null ? "" : serieMonitor} - 
                            ${serieCPU === null ? "" : serieCPU} - 
                            ${marca === null ? "" : marca} -
                            ${anio === null ? "" : anio} -
                            ${noEco === null ? "" : noEco}`,
                            //${departamento === null ? "" : departamento} - 
                            //${responsable === null ? "" : responsable}`,
                         value: infoActAsegID
                        
                    });
                });

            }
            else if (valor === "Automóviles residentes") {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    marca,
                    modelo,
                    anio,
                    serie,
                    responsable }) => {
                    arreglo1.push({
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) -
                             ${marca === null ? "" : marca} -
                             ${modelo === null ? "" : modelo} - 
                             ${anio === null ? "" : anio} - 
                             ${serie === null ? "" : serie} - 
                             ${responsable === null ? "" : responsable}`,
                        value: infoActAsegID
                    });
                });

            }
            else if (valor === "Equipo de Contratistas y Maq. Pesada") {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    valorFactura,
                    equipo,
                    noEco,
                    modelo,
                    marca,
                    anio,
                    serie,
                    capacidad }) => {
                    arreglo1.push({
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) - VFac$
                             ${valorFactura === null ? "" : valorFactura} -  
                             ${equipo === null ? "" : equipo} - 
                             ${modelo === null ? "" : modelo} - 
                             ${noEco === null ? "" : noEco} - 
                             ${marca === null ? "" : marca} -
                             ${anio === null ? "" : anio} - 
                             ${serie === null ? "" : serie} - 
                             ${capacidad === null ? "" : capacidad}`,
                        value: infoActAsegID
                    });
                });


            }
            else if (valor === "Flotilla de automóviles (Camiones Carga General)") {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    equipo,
                    numATP,
                    modelo,
                    marca,
                    anio,
                    serie,
                    capacidad }) => {
                    arreglo1.push({
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) -  
                             ${equipo === null ? "" : equipo} - 
                             ${modelo === null ? "" : modelo} - 
                             ${numATP === null ? "" : numATP} - 
                             ${marca === null ? "" : marca} -
                             ${anio === null ? "" : anio} - 
                             ${serie === null ? "" : serie} - 
                             ${capacidad === null ? "" : capacidad}`,
                        value: infoActAsegID
                    });
                });

            }
            else if (valor === "Múltiple Empresarial- Rotura") {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    equipo,
                    noEco,
                    modelo,
                    marca,
                    anio,
                    serie,
                    capacidad }) => {
                    arreglo1.push({
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) -  
                             ${equipo === null ? "" : equipo} - 
                             ${modelo === null ? "" : modelo} - 
                             ${noEco === null ? "" : noEco} - 
                             ${marca === null ? "" : marca} -
                             ${anio === null ? "" : anio} - 
                             ${serie === null ? "" : serie} - 
                             ${capacidad === null ? "" : capacidad}`,
                        value: infoActAsegID
                    });
                });


            }
            else if (valor === "reclamosAseguradora") {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    numATP,
                    equipo,
                    marca,
                    modelo,
                    serie }) => {
                    arreglo1.push({
                        label:
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) -  
                             ${numATP === null ? "" : numATP} - 
                             ${equipo === null ? "" : equipo} - 
                             ${marca === null ? "" : marca} -
                             ${modelo === null ? "" : modelo} - 
                             ${serie === null ? "" : serie}`,
                        value: infoActAsegID
                    });
                });
            }
            else {
                getInfoActAsegPolizas.forEach(({ 
                    infoActAsegID,
                    polizasSeguroID,
                    terminoVigencia,
                    marca,
                    modelo,
                    anio,
                    responsable,
                    usuario }) => {
                    arreglo1.push({ 
                        label: 
                            `${polizasSeguroID === null ? "" : polizasSeguroID} -
                             Term Vigencia(${terminoVigencia === null ? "" : terminoVigencia}) -  
                             ${marca === null ? "" : marca} -
                             ${modelo === null ? "" : modelo} - 
                             ${anio === null ? "" : anio} - 
                             ${responsable === null ? "" : responsable} - 
                             ${usuario === null ? "" : usuario}`, 
                        value: infoActAsegID 
                    });
                });
            }
        arreglo1.unshift({label: "No Aplica", value: "0"});
 
       

    }

    return arreglo1;

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const addInfoActAsegPolizas = async (variables, token) => {
    let Query = `
    mutation addInfoActAsegPolizas($input: InputAddInfoActivoAsegPolizas!) {
        addInfoActAsegPolizas(input: $input) {
            infoActAsegID
            numATP
            empresaID
            equipo
            marca
            serie
            anio
            capacidad
            ramoSeguroID
            moneda
            sumaAseg
            fPrima
            primaNeta
            inicioVigencia
            terminoVigencia
            propietarioID
            ubicacionID
            valorReposicion
            clasificacion
            coberturasAdicionales
            polizasSeguroID
            polizaOrigenID
            solSegFianID
            incPol
            ubicacion
            departamento
            subPartida
            fechaBaja
            fechaAlta
            estado
            valorFactura
            observaciones
            sustituyeID
            activoSustituido
            activoEquipoSerie
            solicitud
            autoAD
            fechaModificacion
            polRenovada
            partComplementaria
            fechaComplementaria
            usuario
            activoAsegID
            modelo
            tipoTransmision
            valorM2
            valorAseg
            uniCapacidad
            subPolizaID
            responsable
            infraestructura
            noEco
            responsable
        }
    }
    `;

    let tResult = await Ejecutar({input: variables}, token, Query);

    return tResult;
};

export const updateInfoActAsegPolizas = async (variables, token) => {
    let Query = `
    mutation updateInfoActAsegPolizas($input: InputUpdateInfoActivoAsegPolizas!) {
        updateInfoActAsegPolizas(input: $input) {
            infoActAsegID
            numATP
            empresaID
            equipo
            marca
            serie
            anio
            capacidad
            ramoSeguroID
            moneda
            sumaAseg
            fPrima
            primaNeta
            inicioVigencia
            terminoVigencia 
            propietarioID
            ubicacionID
            valorReposicion
            clasificacion
            coberturasAdicionales
            polizasSeguroID
            polizaOrigenID
            solSegFianID
            incPol
            ubicacion
            departamento
            subPartida
            fechaBaja
            fechaAlta
            estado
            valorFactura
            observaciones
            sustituyeID
            activoSustituido
            activoEquipoSerie
            solicitud
            autoAD
            fechaModificacion
            polRenovada
            partComplementaria
            fechaComplementaria
            usuario
            activoAsegID
            modelo
            tipoTransmision
            valorM2
            valorAseg
            uniCapacidad
            subPolizaID
            responsable
            infraestructura
            noEco
        }
    }
    `;

    let tResult = await Ejecutar({input: variables}, token, Query);

    return tResult;
};

export const deleteInfoActAsegPolizas = async (variables, token) => {
    let Query = `
    mutation deleteInfoActAsegPolizas($input: InputDeleteInfoActivoAsegPolizas) {
        deleteInfoActAsegPolizas(input: $input)
    }
    `;

    let valor = variables["infoActAsegID"] ;
    valor = { infoActAsegID:valor};

    let tResult = await Ejecutar({input : valor}, token, Query);
    /*let rs = await deleteFileStorage(variables.rutaFotoPerfil, token);*/

    return tResult;
};



export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}
