export const estadoSolicitudSeguro = [
    {
        value: "EN REDACCION",
        label: "EN REDACCION"
    }, {
        value: "EN PROCESO",
        label: "EN PROCESO"
    }, {
        value: "REVISADA",
        label: "REVISADA"
    }, {
        value: "AUTORIZADA",
        label: "AUTORIZADA"
    }, {
        value: "RECHAZADA",
        label: "RECHAZADA"
    },
    {
        value: "COMPLETADA",
        label: "COMPLETADA"
    }
];