import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_POLIZA_SEG_AUTO = `
        query get($filtro: InputGetPolizaSegAuto){
            getPolizaSegAuto(filtro:$filtro){
                polizaSegAutosID
                partida
                empresaID
                nombre
                usario
                aseguradoraID
                aseguradora
                flotilla
                polizaInciso
                marca
                tipoModelo
                anio
                numSerie
                transmision
                fechaSolCot
                vigenciaIni
                vigenciaHasta
                valorFactura
                coberAdicionales
                sumaAsegurada
                primaNeta
                primaTotal
                renovacion
                horasInvertidas
            }
        }`;

    export const GET_ALL_POLIZA_SEG_AUTO = `
        query filtroGetAll($filtro: InputGetPolizaSegAuto){
            getAllPolizaSegAuto(filtro:$filtro){
                polizaSegAutosID
                partida
                empresaID
                nombre
                usario
                aseguradoraID
                aseguradora
                flotilla
                polizaInciso
                marca
                tipoModelo
                anio
                numSerie
                transmision
                fechaSolCot
                vigenciaIni
                vigenciaHasta
                valorFactura
                coberAdicionales
                sumaAsegurada
                primaNeta
                primaTotal
                renovacion
                horasInvertidas
            }
        }`;

    export const TABLA_POLIZA_SEG_AUTO = `
        query filtro($filtro: InputFiltroPolizaSegAuto){
            filterPolizaSegAuto(filtro:$filtro){
            listado{
                polizaSegAutosID
                partida
                empresaID
                nombre
                usario
                aseguradoraID
                aseguradora
                flotilla
                polizaInciso
                marca
                tipoModelo
                anio
                numSerie
                transmision
                fechaSolCot
                vigenciaIni
                vigenciaHasta
                valorFactura
                coberAdicionales
                sumaAsegurada
                primaNeta
                primaTotal
                renovacion
                horasInvertidas
            }
            registros
            paginas
            }
        }`;

export const getPolizaSegAuto = async (filtro, token) => {
    try {
        let { getPolizaSegAuto } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_POLIZA_SEG_AUTO
        });

        return getPolizaSegAuto;

    } catch({message}) { throw new Error(message); }
}

export const getAllPolizaSegAuto = async (variables, token) => {
    try {

        let { getAllPolizaSegAuto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_POLIZA_SEG_AUTO
        });

        return getAllPolizaSegAuto;

    } catch({message}) { throw new Error(message); }
}

export const seleccionPolizaSegAuto = async (variables, token) => {
    try {

        let allPolizaSegAuto = await getAllPolizaSegAuto
        (variables, token);

        if(!Array.isArray(allPolizaSegAuto)) throw new Error("No se encontraron registros");

        return allPolizaSegAuto.map(({ polizaSegAutosID:value, marca:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaPolizaSegAuto = async (variables, token) => {
    try{
        let {filterPolizaSegAuto} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_POLIZA_SEG_AUTO
        });
        return filterPolizaSegAuto;
    }catch({message}) { throw new Error(message); }
}
