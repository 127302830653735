import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../../helpers';

export const tablaEncuesta = async (variables, token) => {
    var Query = `
    query filterEncuesta($filtro: InputFilterEncuesta){
        filterEncuesta(filtro:$filtro){
            listado{
                encuestaID
                empresaID
                usuarioBitID
                encuesta
                areaID
                area
                ejercicio
                estatus
        }
        registros
        paginas
        }
    }
    `;
    var tResult = await Ejecutar(variables, token, Query);
    return (tResult);
}

export const tablaEncuestaSecciones = async (variables, token) => {
    var Query = `
    query filterEncuestaSecciones($filtro: InputFilterEncuestaSecciones){
        filterEncuestaSecciones(filtro:$filtro){
            listado{
                seccionID
                encuestaID
                empresaID
                seccion
                descripcion
                enviada
                pendienteRespuesta
                respuesta
                ejercicio
        }
        registros
        paginas
        }
    }
    `;
    var tResult = await Ejecutar(variables, token, Query);
    return (tResult);
}

export const getEncuesta = async (variables, token) => {
    var Query = `
    query getEncuesta($filtro: InputGetEncuesta){
        getEncuesta(filtro:$filtro) {
            encuestaID
            empresaID
            usuarioBitID
            encuesta
            areaID
            ejercicio
            estatus
        }
    }
    `;

    var resultado = await Ejecutar({ filtro: variables }, token, Query);

    return resultado;
}

export const getEncuestaSecciones = async (variables, token) => {
    var Query = `
    query getEncuestaSecciones($filtro: InputGetEncuestaSecciones){
        getEncuestaSecciones(filtro:$filtro) {
            seccionID
            encuestaID
            empresaID
            seccion
            descripcion
            enviada
            pendienteRespuesta
            respuesta
            ejercicio
        }
    }
    `;

    var resultado = await Ejecutar({ filtro: variables }, token, Query);
    return resultado;
}

export const getAllEncuestaSecciones = async (variables, token) => {
    let Query = `
    query getAllEncuestaSecciones($filtro: InputGetEncuestaSecciones){
        getAllEncuestaSecciones(filtro:$filtro){
            seccionID
            encuestaID
            empresaID
            seccion
            descripcion
            enviada
            pendienteRespuesta
            respuesta
            ejercicio
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const getAllEncuesta = async (variables, token) => {
    let Query = `
    query getAll($filtro: InputGetEncuesta){
        getAllEncuesta(filtro:$filtro){
            encuestaID
            empresaID
            usuarioBitID
            encuesta
            areaID
            ejercicio
            estatus
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const addEncuesta = async (variables, token) => {
    var Query = `
    mutation addEncuesta($input: InputAddEncuesta!) {
        addEncuesta(input: $input) {
            encuestaID
            empresaID
            usuarioBitID
            encuesta
            areaID
        }
    }
    `;

    var tResult = await Ejecutar({ input: variables }, token, Query);

    return tResult;
};

export const generarEncuesta = async (variables, token) => {
    var Query = `
    mutation generarEncuesta($input: InputGenerarEncuesta!) {
        generarEncuesta(input: $input){
            encuestaID
            empresaID
            usuarioBitID
            encuesta
            areaID
        }
    }
    `;

    var tResult = await Ejecutar({ input: variables }, token, Query);

    return tResult;
};

export const updateEncuesta = async (variables, token) => {
    var Query = `
    mutation updateEncuesta($input: InputUpdateEncuesta!) {
        updateEncuesta(input: $input) 
    }
    `;

    var tResult = await Ejecutar({ input: variables }, token, Query);

    return tResult;
};

export const deleteEncuesta = async (variables, token) => {
    var Query = `
    mutation deleteEncuesta($input: InputDeleteEncuesta) {
        deleteEncuesta(input: $input)
    }
    `;

    var tResult = await Ejecutar({ input: variables }, token, Query);

    return tResult;
};

export const seleccionEncuesta = async (variables, token) => {
    try {

        let allEncuesta = await getAllEncuesta(variables, token);

        if (!Array.isArray(allEncuesta)) throw new Error("No se encontraron registros");

        return allEncuesta.map(({ encuestaID: value, encuesta: label }) => ({ value: parseInt(value), label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) { throw new Error(error.message); }
}

export const enviarEncuesta = async (variables, token) => {
    let query = `mutation enviarEncuesta( $encuestaID: ID, $seccionID: ID, $correo: String, $ejercicio: ID ) {
        enviarEncuesta(encuestaID: $encuestaID, seccionID: $seccionID, correo: $correo, ejercicio: $ejercicio)
    }`;

    try {
        let res = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query
        });

        return res;

    } catch ({ message }) { throw new Error(message); }
};

export const actualizarRespuestas = async (variables, token) => {
    let query = `mutation actualizarRespuestas( $seccionID: ID, $respuesta: String, $agregarFirmas: Boolean ) {
        actualizarRespuestas(seccionID: $seccionID, respuesta: $respuesta, agregarFirmas: $agregarFirmas)
    }`;

    try {
        let res = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query
        });

        return res;

    } catch ({ message }) { throw new Error(message); }
};