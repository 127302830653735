import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { seleccionPolizasSeguro } from '../../../querys/PolizasSeguro';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../Empresas/Consultas';
import { seleccionUsuario } from '../Usuarios/Consultas';

import { getActividadesDeTareas, tablaActividadesDeTareas } from '../../../querys/ActividadesDeTareas';
import { deleteActividadesDeTareas, addActividadesDeTareas, updateActividadesDeTareas } from '../../../mutations/ActividadesDeTareas';

import moment from 'moment';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

import { rgxdeInt10, rgxInputTexto30, rgxNumeros, rgxDecimal8, rgxDecimal14 } from '../../../helpers/regexp';
import { toFixed,redondeo } from '../../../helpers/index';

const { trim } = Validaciones;

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }


const { Encrypt, Decrypt } = Encriptar;

function ActividadesDeTareas() {

    let history = useHistory();
    const { token } = useSelector(state => state.login);

    const fecha = new Date();
    const anio = fecha.getFullYear();

    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";



    const cleanState = {
        actividadID: null,
        tareaID: "",
        usuarioID: "",
        descripcion: "",
        responsable: "",
        porcentaje: "",
        estado: "",
        avance: "",
        fechaInicio: new Date(),
        fechaFinal: new Date(),
        fechaInicioReal: "",
        fechaFinalReal: "",
        horaInicioReal: "",
        horaFinalReal: ""
    };
    const cleanErrorState = {

        tareaID: { error: false, helperText: "" },
        usuarioID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        responsable: { error: false, helperText: "" },
        porcentaje: { error: false, helperText: "" },
        estado: { error: false, helperText: "" },
        avance: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" },
        horaInicioReal: { error: false, helperText: "" },
        horaFinalReal: { error: false, helperText: "" }
    }

    let listAnios = [];

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [seleccionables, setSeleccionables] = useState({});


    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);


    let titleAccion = state.actividadID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Actividad`;

    const usuarioIDRef = useRef(null);
    const descripcionRef = useRef(null);
    const responsableRef = useRef(null);
    const porcentajeRef = useRef(null);
    const estadoRef = useRef(null);
    const avanceRef = useRef(null);
    const fechaInicioRef = useRef(null);
    const fechaFinalRef = useRef(null);
    const fechaInicioRealRef = useRef(null);
    const fechaFinalRealRef = useRef(null);
    const horaInicioRef = useRef(null);
    const horaFinalRef = useRef(null);

    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "actividadID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getActividadesDeTareas,
            formatearResultado: [
                { id: "fechaInicio", formato: (data) => moment(data) },
                { id: "fechaFinal", formato: (data) => moment(data) },
                { id: "fechaInicioReal", formato: (data) => moment(data) },
                { id: "fechaFinalReal", formato: (data) => moment(data) }
            ]
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "actividadID" },
        { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ actividadID, numero }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro ${numero}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { actividadID }, asyncDelete: deleteActividadesDeTareas
            })
        })
    }];

    const botones = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "actividadID",
        color: primary,
        title: "Actividades de Tareas",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "actividadID", descripcion: "actividadID", hide: true },
            { variable: "tareaID", descripcion: "tareaID", hide: true },
            { variable: "usuarioID", descripcion: "usuarioID", hide: true },
            { variable: "responsable", descripcion: "Responsable" },
            { variable: "porcentaje", descripcion: "Porcentaje" },
            { variable: "estado", descripcion: "Estado" },
            { variable: "avance", descripcion: "Avance" },
            { variable: "fechaInicio", descripcion: "Fecha Inicio" },
            { variable: "fechaFinal", descripcion: "Fecha Final" },
            { variable: "fechaInicioReal", descripcion: "Fecha Inicio Real" },
            { variable: "fechaFinalReal", descripcion: "Fecha Final Real" },

        ],
        responsiveTitle: ["numero"],
        filter: [
            { campo: "numero" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [
            {
                columnas: ["fechaInicio", "fechaFinal", "fechaInicioReal", "fechaFinalReal"], tipo: "fecha"

            },
            {
                columnas: ["avance"], tipo: "custom", callback: (valor) =>{
                    return `${redondeo( parseFloat(toFixed((valor * 100),2)),2 )}%`;
                }
            },]
    }



    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "usuarioID",
                    value: state.usuarioID,
                    error: errorState.usuarioID.error,
                    success: state.usuarioID && !errorState.usuarioID.error ? true : undefined,
                    helperText: errorState.usuarioID.helperText,
                    inputRef: usuarioIDRef,
                    title: "Usuario *",
                    placeholder: "Selecciona el usuario",
                    tipo: "select",
                    data: seleccionables.Usuarios,
                    grid: { md: 2, lg: 2 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, usuarioID: value })
                    }
                },
                {
                    disabled,
                    id: "descripcion",
                    value: state.descripcion,
                    error: errorState.descripcion.error,
                    success: state.descripcion && !errorState.descripcion.error ? true : undefined,
                    helperText: errorState.descripcion.helperText,
                    inputRef: descripcionRef,
                    title: "descripcion *",
                    placeholder: "Capture la descripcion",
                    grid: { md: 5, lg: 5 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "descripcion")
                    },
                    onKeyDown: () => { return; }

                },
                {
                    disabled,
                    id: "responsable",
                    value: state.responsable,
                    error: errorState.responsable.error,
                    success: state.responsable && !errorState.responsable.error ? true : undefined,
                    helperText: errorState.responsable.helperText,
                    inputRef: responsableRef,
                    title: "responsable *",
                    placeholder: "Capture el responsable",
                    grid: { md: 5, lg: 5 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "responsable")
                    },
                    onKeyDown: () => { return; }

                }
            ]
         }, {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaInicio",
                    value: state.fechaInicio,
                    error: errorState.fechaInicio.error,
                    success: state.fechaInicio && !errorState.fechaInicio.error ? true : undefined,
                    helperText: errorState.fechaInicio.helperText,
                    inputRef: fechaInicioRef,
                    placeholder: "Fecha de Inicio *** *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                     onlyDate: true,
                    /*onChange: (value) => handleChange(value, "fechaInicio")*/
                    onChange: (value) => {
                        if (typeof value !== "object") return;
                        handleChange(value, "fechaInicio");
                    }
                },
               /* {
                    disabled,
                    id: "horaInicio",
                    value: state.horaInicio,
                    error: errorState.horaInicio.error,
                    success: state.horaInicio && !errorState.horaInicio.error ? true : undefined,
                    helperText: errorState.horaInicio.helperText,
                    inputRef: horaInicioRef,
                    placeholder: "Hora Inicio *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                   // onlyTime: true,
                    onChange: (value) => handleChange(value, "horaInicio")
                }, */
                {
                    disabled,
                    id: "fechaFinal",
                    value: state.fechaFinal,
                    error: errorState.fechaFinal.error,
                    success: state.fechaFinal && !errorState.fechaFinal.error ? true : undefined,
                    helperText: errorState.fechaFinal.helperText,
                    inputRef: fechaFinalRef,
                    placeholder: "Fecha Final *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                   // onlyDate: false,
                  /*  onChange: (value) => handleChange(value, "fechaFinal")
                    */
                  onChange: (value) => {
                    if (typeof value !== "object") return;
                    handleChange(value, "fechaFinal");
                }
                }, 
              /*  {
                    disabled,
                    id: "horaFinal",
                    value: state.horaFinal,
                    error: errorState.horaFinal.error,
                    success: state.horaFinal && !errorState.horaFinal.error ? true : undefined,
                    helperText: errorState.horaFinal.helperText,
                    inputRef: horaFinalRef,
                    placeholder: "Hora Final *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                   // onlyTime: true,
                    onChange: (value) => handleChange(value, "horaFinal")
                }/*, 
                {
                    disabled,
                    hidden: true,
                    id: "fechaInicioReal",
                    value: state.fechaInicioReal,
                    error: errorState.fechaInicioReal.error,
                    success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
                    helperText: errorState.fechaInicioReal.helperText,
                    inputRef: fechaInicioRealRef,
                    placeholder: "Fecha Inicio Real *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaInicioReal")
                },
                {
                    disabled,
                    id: "fechaFinalReal",
                    hidden: true,
                    value: state.fechaFinalReal,
                    error: errorState.fechaFinalReal.error,
                    success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
                    helperText: errorState.fechaFinalReal.helperText,
                    inputRef: fechaFinalRealRef,
                    placeholder: "Fecha Final Real *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaFinalReal")
                },*/
            ]
        }, {
            id: "abaj0",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "estado",
                    value: state.estado,
                    error: errorState.estado.error,
                    success: state.estado && !errorState.estado.error ? true : undefined,
                    helperText: errorState.estado.helperText,
                    inputRef: estadoRef,
                    title: "Estado *",
                    tipo: "select",
                    grid: { md: 4, lg: 4 },
                    data: [
                        {
                            label: 'Activo',
                            value: 'Activo'
                        },
                        {
                            label: 'Cerrado',
                            value: 'Cerrado'
                        },
                    ],
                    onChange: ({ target: { value } }) => handleChange(value, "estado"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "avance",
                    value: state.avance,
                    error: errorState.avance.error,
                    success: state.avance && !errorState.avance.error ? true : undefined,
                    helperText: errorState.avance.helperText,
                    inputRef: avanceRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Avance *",
                    grid: { md: 4, lg: 4 },
                    placeholder: "Capture el avance",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChange(value, "avance")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "porcentaje",
                    value: state.porcentaje,
                    error: errorState.porcentaje.error,
                    success: state.porcentaje && !errorState.porcentaje.error ? true : undefined,
                    helperText: errorState.porcentaje.helperText,
                    inputRef: porcentajeRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Porcentaje *",
                    grid: { md: 4, lg: 4 },
                    placeholder: "Capture el porcentaje",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChange(value, "porcentaje")
                    },
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];


    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: usuarioIDRef,
            mensajeCorrecto: `Se ${state.actividadID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({  usuarioID,  fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal, numero, descripcion,responsable }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(usuarioID),
                ref: usuarioIDRef, keyError: "usuarioID",
                mensajeError: "Seleccione un usuario"
            },
            {
                condicion: !moment(fechaInicio).isValid(),
                ref: fechaInicioRef, keyError: "fechaInicio",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(fechaFinal).isValid(),
                ref: fechaFinalRef, keyError: "fechaFinal",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(fechaInicioReal).isValid(),
                ref: fechaInicioRealRef, keyError: "fechaInicioReal",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(fechaFinalReal).isValid(),
                ref: fechaFinalRealRef, keyError: "fechaFinalReal",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !descripcion || trim(descripcion) === "",
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Debe especificar la descripcion"
            },
            {
                condicion: !responsable || trim(responsable) === "",
                ref: responsableRef, keyError: "responsable",
                mensajeError: "Debe especificar el responsable"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let {
                actividadID,
                tareaID,
                usuarioID,
                descripcion,
                responsable,
                porcentaje,
                estado,
                avance,
                fechaInicio,
                fechaFinal,
                fechaInicioReal,
                fechaFinalReal } = state;

            let error = await handleValidaciones({
                usuarioID, fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal,descripcion, responsable
            });

            if (error) return error;

            if (actividadID) await updateActividadesDeTareas({
                input: {
                    actividadID,
                    tareaID,
                    usuarioID: Number(usuarioID),
                    descripcion: trim(descripcion),
                    responsable: trim(responsable),
                    porcentaje: parseFloat(porcentaje),
                    estado: trim(estado),
                    avance: parseFloat(avance),
                    fechaInicio: fechaInicio.format("YYYY-MM-DDTHH:mm:ss"),
                    fechaFinal: fechaFinal.format("YYYY-MM-DDTHH:mm:ss"),
                    fechaInicioReal: fechaInicioReal.format("YYYY-MM-DDTHH:mm:ss"),
                    fechaFinalReal: fechaFinalReal.format("YYYY-MM-DDTHH:mm:ss")
                }
            }, token);
            else await addActividadesDeTareas({
                input: {
                    tareaID,
                    usuarioID: Number(usuarioID),
                    descripcion: trim(descripcion),
                    responsable: trim(responsable),
                    porcentaje: parseFloat(porcentaje),
                    estado: trim(estado),
                    avance: parseFloat(avance),
                    fechaInicio: fechaInicio.format("YYYY-MM-DDTHH:mm:ss"),
                    fechaFinal: fechaFinal.format("YYYY-MM-DDTHH:mm:ss"),
                    fechaInicioReal: fechaInicioReal.format("YYYY-MM-DDTHH:mm:ss"),
                    fechaFinalReal: fechaFinalReal.format("YYYY-MM-DDTHH:mm:ss")
                }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {
                let Usuarios = await seleccionUsuario({}, token);
                setSeleccionables({ Usuarios });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);


    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaActividadesDeTareas}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={usuarioIDRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default ActividadesDeTareas;
