import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getDepartamento, tablaDepartamento } from '../../../querys/Departamento';
import { seleccionArea } from '../../../querys/Areas';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { addDepartamento, updateDepartamento, deleteDepartamento } from '../../../mutations/Departamentos';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

import { rgxLetras, rgxNumeros } from '../../../helpers/regexp';
import * as Permisos from '../Permisos';

const { trim } = Validaciones;

function Departamentos() {
    const { token } = useSelector(state => state.login);

    const cleanState = {
        departamentoID: null,
        areaID: null,
        departamento: "",
        empresasID: [],
        auxEmpresasID: []
    };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };

    const cleanErrorState = {
        area: { error: false, helperText: "" },
        areaID: { error: false, helperText: "" },
        departamento: { error: false, helperText: "" },
        empresasID: { error: false, helperText: "" },
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [stateMultiple, setStateMultiple] = useState(cleanStateMultiple);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    let titleAccion = state.departamentoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Departamento`;

    const areaIDRef = useRef(null);
    const departamentoRef = useRef(null);
    const empresasIDRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "departamentoID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getDepartamento,
            // formatearResultado: [
            //     {}
            // ]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "departamentoID" },
        { campo: "departamento" }],
        disabled: { multiseleccion: true },
        onClick: ({ departamentoID, departamento }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el departamento ${departamento}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { departamentoID }, asyncDelete: deleteDepartamento
            })
        })
    }];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "departamentoID",
        color: primary,
        title: "Departamentos",
        iconTable: <LocationCityIcon />,
        headers: [
            { variable: "departamentoID", descripcion: "DepartamentoID", hide: true },
            { variable: "area", descripcion: "Área" },
            { variable: "departamento", descripcion: "Departamento" }
        ],
        formato: [
            {
                columnas: ["area"], tipo: "custom", callback: (value) => {
                    return value.toUpperCase();
                }
            },
        ],
        responsiveTitle: ["departamento"],
        filter: [
            { campo: "departamento", placeholder: "Departamento" },
            { campo: "area", placeholder: "Área" },
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            disabled,
            id: "areaID",
            value: state.areaID,
            error: errorState.areaID.error,
            success: state.areaID && !errorState.areaID.error ? true : undefined,
            helperText: errorState.areaID.helperText,
            inputRef: areaIDRef,
            title: "Área *",
            placeholder: "Seleccione el área",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.areas,
            onChange: (value) => setState({ ...state, areaID: value ? Number(value.value) : null }),
        },
        {
            disabled,
            id: "departamento",
            value: state.departamento,
            error: errorState.departamento.error,
            success: state.departamento && !errorState.departamento.error ? true : undefined,
            helperText: errorState.departamento.helperText,
            inputRef: departamentoRef,
            title: "Departamento *",
            placeholder: "Capture el departamento",
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "departamento") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, departamento: { error: state.departamento.length > 100 && true, helperText: state.departamento.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            id: "empresasfield",
            tipo: "fieldset",
            grid: { md: 12, lg: 12 },
            leyenda: 'Asignar Empresas',
            inputs: [
                {
                    disabled,
                    id: "empresasID",
                    value: state.empresasID,
                    error: errorState.empresasID.error,
                    success: Array.isArray(state.empresasID) && state.empresasID.length > 0 && state.empresasID && !errorState.empresasID.error ? true : undefined,
                    helperText: errorState.empresasID.helperText,
                    inputRef: empresasIDRef,
                    title: "Empresas",
                    placeholder: "Seleccionar empresas",
                    // grid: { md: , lg: 6 },
                    tipo: "autocomplete",
                    multiple: true,
                    data: seleccionables.empresas,
                    onChange: (data) => {
                        const valor = Array.isArray(data) && data.length > 0 ? data.map(({ value }) => value) : [];
                        setState({ ...state, empresasID: valor })
                    }
                },
            ]
        },
    ];

    let accionesFormulario = [{
        accionID: state.departamentoID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: departamentoRef,
            mensajeCorrecto: `El departamento se ${state.departamentoID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    async function handleValidaciones({ departamento, areaID, empresasID }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(areaID), keyError: "areaID",
                mensajeError: "Debe seleccionar un área"
            },
            {
                condicion: !departamento || trim(departamento) === "",
                ref: departamentoRef, keyError: "departamento",
                mensajeError: "Debe especificar el departamento"
            },
            // {
            //     condicion: !(Array.isArray(empresasID) && empresasID.length > 0 ), keyError: "empresasID",
            //     mensajeError: "Debe seleccionar al menos una empresa"
            // },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {
            let { departamentoID, departamento, areaID, empresasID, auxEmpresasID } = state;
            let agregar = [], actualizar = [], eliminados = [];
            let error = await handleValidaciones({ departamento, areaID, empresasID });

            if (error) return error;

            if (!auxEmpresasID) {
                agregar = empresasID;
            }
            else {
                for (let i in empresasID) {
                    if (auxEmpresasID.includes(empresasID[i]))
                        actualizar.push(empresasID[i]);
                    else
                        agregar.push(empresasID[i]);
                }
                for (let i in auxEmpresasID) {
                    if (!actualizar.includes(auxEmpresasID[i]))
                        eliminados.push(auxEmpresasID[i]);
                }
            }

            if (departamentoID) await updateDepartamento({
                input: { departamentoID, departamento: trim(departamento), areaID, empresasID, agregar, eliminados }
            }, token);
            else await addDepartamento({
                input: { departamento: trim(departamento), areaID, empresasID }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {
                let areas = await seleccionArea({ filtro: {} }, token);
                let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                setSeleccionables({ areas, empresas });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaDepartamento}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={departamentoRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default Departamentos;
