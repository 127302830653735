import React from 'react';
import { Validaciones } from '@renedelangel/helpers';

import { rgxPorcentaje, rgxMargenMax } from '../../regexp';

const { trim } = Validaciones;

export function handleChangeFocus({ val, id, setState, newState}, ref, action, clear = false) {
    
    if(clear && action === "clear" && ref.current) ref.current.onMenuClose();
    handleChangeAutocomplete(val, id, setState, newState);
    focusAutocomplete(action, ref);
}

export function handleChange(value, key, setState, newState = {}, ref) {
    setState(state => ({ ...state, [key]: value, ...newState }));
    if(ref && ref.current && ref.current.select && typeof ref.current.select === "function" && value === 0) setTimeout(() => ref.current.select(), 100);
}

export function handleChangeAutocomplete(val, key, setState, newData = {}) {
    let value = val ? val : null;
    setState(state => ({ ...state, [key]: value, ...newData }));
}

export function focus(ref) {
    if(ref && ref.current && ref.current.focus) ref.current.focus();
    if(ref && ref.current && ref.current.select && typeof ref.current.select === "function") ref.current.select();
}

export function focusAutocomplete(action, ref) { if(action === "select-option") focus(ref); }

export function validaArray(arr) { return (Array.isArray(arr) && arr.length === 1); }

export function ftPrecarga(preCarga) {
    let newState = {};
    for (const key in preCarga) { if(validaArray(preCarga[key])) newState = { ...newState, [key]: preCarga[key][0] }; }
    return newState;
}

function hasPuntoInicial(value) { return /^\./.test(value); }

function withCeroInicial(value) { return hasPuntoInicial(value) ? `0${value}` : value; }

export function onlyNumber(value) {
    let newValue = addCeroInicial(value);
    if(!isNaN(Number(newValue))) return true;
}

export function onlyEntero(value) { return /^[0-9]+$/.test(value); }

export function addCeroInicial(value) {
    let newValue = trim(value) === "" ? 0 : value;
    return withCeroInicial(newValue);
}

export function firstUpperLetter(string = "") {
    let first = string.charAt(0).toUpperCase();
    let rest = string.substr(1, string.length - 1).toLowerCase();
    return `${first}${rest}`;
}

export function onlyMaximoDecimal(value, maximo) {
    let newValue = addCeroInicial(value);
    let regexp = new RegExp(`^[0-9]+(.?[0-9]{0,${maximo}})?$`);
    return regexp.test(newValue);
}

export function isValidPorcentaje(value) {
    let newValue = addCeroInicial(value);
    return rgxPorcentaje.test(newValue);
}

export function isValidMargen(value) {
    let newValue = addCeroInicial(value);
    return rgxMargenMax.test(newValue);
}

export function mathRound(valor, decimales) {
    let isNumber = !isNaN(Number(valor));
    if (!isNumber) return valor;
    let exp = Math.pow(10, decimales);
    return Math.round(valor * exp) / exp;
}

export function calIVA(iva) { return (1 + (iva/100)); }

export function calPorcentaje(porc) { return (1 - (porc/100)); }

export function calRedondeo(precio, redondeo) {
    let precioRedondeo = redondeo ? mathRound(precio, redondeo) : precio;
    return isFinite(precioRedondeo) ? precioRedondeo : 0;
}

export function calcularPrecioConIVA(valor, iva, redondeo) {
    let precioConIVA = valor * calIVA(iva);
    return calRedondeo(precioConIVA, redondeo);
}

export function calcularPrecioSinIVA(valor, iva, redondeo) {
    let precioSinIVA = valor / calIVA(iva);
    return calRedondeo(precioSinIVA, redondeo);
}

export function calcularPrecioConDescuento(valor, descuento, redondeo) {
    let precioConDescuento = valor * calPorcentaje(descuento);
    return calRedondeo(precioConDescuento, redondeo);
}

export function calcularPrecioFactor(valor, factor, redondeo) {
    let precioFactor = valor / ((factor === 0 || !factor) ? 1 : factor);
    return calRedondeo(precioFactor, redondeo);
}

export function calcularPrecioMargen(valor, margen, redondeo) {
    let newMargen = /*Number(margen) >= 100 ? 99.99 :*/ margen;
    let precioMargen = valor / ((100 - newMargen) / 100);
    return calRedondeo(precioMargen, redondeo);
}

export function calcularPrecioFactorMargen(valor, factor, margen, redondeo) {
    let precioFactor = calcularPrecioFactor(valor, factor);
    return calcularPrecioMargen(precioFactor, margen, redondeo);
}

export function calcularMargenPrecio(precio, costo, redondeo) {
    let margenPrecio = ((precio - costo) / precio) * 100;
    return calRedondeo(margenPrecio, redondeo);
}

export function calcularPorcentajeDescuentoVenta(precio, precioNormal, redondeo) {
    let porcentajeDescuento = (1 - (precio/precioNormal)) * 100;
    return calRedondeo(porcentajeDescuento, redondeo);
}

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }
