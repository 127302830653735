import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';


export const IS_USERNAME = `
    query isUsername($username: String!) {
        isUsername(username: $username)
    }
`;

export const GET_LOGIN = `
    query getLogin($username: String!, $password: String!) {
        getLogin(username: $username, password: $password)
    }
`;

export const IS_TOKEN = `
    query isToken {
        isToken
    }
`;

export const GET_NEWPASSWORD = `
    query getNewPassword($username: String!) {
        getNewPassword(username: $username)
    }
`;

export const GET_NEWTOKEN = `
    query GetNewTokenEmpresa($username: String!, $empresaID: ID!) {
        GetNewTokenEmpresa(username: $username, empresaID: $empresaID)
    }
`;

export const GET_ALLSUCURSALESUSUARIO = `
    query getAllSucursalesUsuario {
        getAllSucursalesUsuario {
            empresaID
            nombreEmpresa
            sucursales {
                sucursalID
                sucursal
            }
        }
    }
`;

/**Esta función recibe en el parámetro tQuery la Query que se va a ejecutar en graphql,
 * anteriormente en las funciones de la carpeta metodos, existía una función por query de este archivo,
 * con esta función eso ya no es necesario*/
export const Ejecutar = async (variables, token, tQuery) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: tQuery
        });
        //Se devuelve el primer elemento del objeto respuesta, para que todo lo demás se quede igual
        return Respuesta[Object.keys(Respuesta)[0]];;

    } catch({message}) { throw new Error(message); }
}
