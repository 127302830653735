// Paginación
export function ftPaginacionGetComponentData(paginacion, paginaActiva) {

    const { paginas = 1, listadoPaginas = 5, onClick = (data) => console.log(data) } = paginacion;

    const valPaginaActiva = Number(paginas) === Number(paginaActiva);
    const valPaginaUno = Number(paginaActiva) === 1;
    const valMayorUno = Number(paginaActiva) > 1;

    const inicio = { text: "<<", value: 1, disabled: valPaginaUno, tooltip: true };
    const anterior = { text: "<", value: valMayorUno ? Number(paginaActiva) - 1 : 1, disabled: valPaginaUno, tooltip: true };
    const siguiente = { text: ">", value: valPaginaActiva ? Number(paginaActiva) : Number(paginaActiva) + 1, disabled: valPaginaActiva, tooltip: true };
    const fin = { text: ">>", value: paginas, disabled: valPaginaActiva, tooltip: true };

    let arrayPages = [],
        valPaginaFinal = (Number(paginaActiva) + Number(listadoPaginas)) - 1,
        paginasVisiblesFinal = valPaginaFinal < paginas ? valPaginaFinal : paginas,
        visInicio = paginasVisiblesFinal - Number(paginaActiva) <= Number(listadoPaginas) ? paginasVisiblesFinal - (Number(listadoPaginas) - 1) : Number(paginaActiva),
        paginasVisilesInicio = visInicio < 0 ? 1 : visInicio;

    for(let i = paginasVisilesInicio; i <= paginasVisiblesFinal; i++) { arrayPages = [...arrayPages, { text: i, value: i, active: i === Number(paginaActiva) }]; }

    let pages = [inicio, anterior, ...arrayPages, siguiente, fin];

    return pages.map(page => ({ ...page, onClick }));

}
