import { ErrorRounded } from '@material-ui/icons';
import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const GET_VEHICULO = `
query get($filtro: InputGetVehiculoAsegurado)
{
    getVehiculoAsegurado(filtro: $filtro)
    {
        polizaSegAutosID
        empresaID
        numSerie
        tipoModelo
        anio
        marca
        descripcion
        polizaInciso
    }
}
`;

export const GET_ALL_VEHICULO = `
query filtroGetAll($filtro: InputGetVehiculoAsegurado)
{
    getAllVehiculoAsegurado(filtro: $filtro)
    {
        polizaSegAutosID
        empresaID
        numSerie
        tipoModelo
        anio
        marca
        descripcion
        polizaInciso
    }
}`;

export const TABLA_VEHICULOS  = `
query filtro($filtro: InputFilterVehiculoAsegurado)
{
    filterVehiculoAsegurado(filtro: $filtro)
    {
        listado
        {
            polizaSegAutosID
            empresaID
            numSerie
            tipoModelo
            anio
            marca
            descripcion
            polizaInciso
        }
        registros
        paginas
    }
}`;

export const getVehiculoAsegurado = async (filtro, token) => {
    try{
        let {getVehiculoAsegurado} = await FetchAPI.graphql({
            token,
            variables: {filtro},
            url: urlServidor,
            query: GET_VEHICULO
        });
        return getVehiculoAsegurado;

    }catch ({message}) {throw new Error(message);}
}

export const getAllVehiculosAsegurado = async (variables, token) => {

    try{
        let {getAllVehiculoAsegurado} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_VEHICULO
        });

        return getAllVehiculoAsegurado;

    }catch ({message}) {throw new Error(message);}
}

export const seleccionVehiculosAsegurados = async (variables, token) => {
    try{
        let getAllVehiculoAsegurado = await getAllVehiculosAsegurado(variables, token);

        if(!Array.isArray(getAllVehiculoAsegurado)) throw new Error ("No se encontraron registros");

        return getAllVehiculoAsegurado.map(({polizaSegAutosID: value, descripcion: label}) => ({value, label: label.toUpperCase()}));

    }catch({message: label}){
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}


export const tablaVehiculosAsegurado = async (variable, token) => {
    try{
        let {polizaSegAutosID, empresaID, numSerie,tipoModelo,anio,marca, pagina, limite} = variable.filtro;
        let filtro = {polizaSegAutosID: polizaSegAutosID === null ? null : Number (polizaSegAutosID), empresaID, numSerie,tipoModelo,anio,marca, pagina, limite};
        let variables = {filtro};
        let {filterVehiculoAsegurado} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_VEHICULOS
        });

        return filterVehiculoAsegurado;
    }catch({message}) {throw new Error(message);}
}
