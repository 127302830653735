import { SET_TOKEN, SET_USUARIO, SET_CONFIGURACION,SET_FECHASFILTRO } from '../types';

export const setToken = (token) => ({
    type: SET_TOKEN,
    payload: token
});

export const setUsuario = (token) => ({
    type: SET_USUARIO,
    payload: token
});

export const setConfiguracion = (token) => ({
    type: SET_CONFIGURACION,
    payload: token
});

export const setFechasFiltro = (token) => ({
   
    type: SET_FECHASFILTRO,
    payload: token
});

