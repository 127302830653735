import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../../helpers';
import {  GET_BITACORA, TABLA_BITACORA, GET_ALL_BITACORA,TABLA_BITACORADETALLE  } from '../index';


export const getGenBitacora = async (filtro, token) => {
    try {

        let { getGenBitacora } = await FetchAPI.graphql({
            token,
            variables: {filtro},
            url: urlServidor,
            query: GET_BITACORA
        });

        return getGenBitacora;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllGenBitacora = async (variables, token) => {
    try {

        let { getAllGenBitacora } = await FetchAPI.graphql({
            token,
            variables ,
            url: urlServidor,
            query: GET_ALL_BITACORA
        });

        return getAllGenBitacora;

    } catch({message}) { throw new Error(message); }
}

export const tablaGenBitacora = async (variables, token) => {
    try {

        let { filterGenBitacora } = await FetchAPI.graphql({
            token,
            variables ,
            url: urlServidor,
            query: TABLA_BITACORA
        });

        return filterGenBitacora;

    } catch ({ message }) { throw new Error(message); }
}


export const tablaGenBitacoraDetalle = async (variables, token) => {
    try {


        let { filterGenBitacoraDetalle } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_BITACORADETALLE
        });

        return filterGenBitacoraDetalle;

    } catch ({ message }) { throw new Error(message); }
}
