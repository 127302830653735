import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_SOLOTROSSEGUROSFIANZAS = `
query get($filtro: InputGetSolSegFianFianza)
{
    getSolSegFianFianza(filtro : $filtro)
  {
    fianzaID
    solSegFianID
    usuarioBitID
    tipo
    monto
    empresaID
    docFuente
    intGarantizar
    beneficiario
    divisaID
    divisa
    paridad
    montoAsegurado
  }
}`;

export const GET_ALL_SOLOTROSSEGUROSFIANZAS = `
query getAll($filtro: InputGetSolSegFianFianza)
{
    getAllSolSegFianFianza(filtro : $filtro)
  {
    fianzaID
    solSegFianID
    usuarioBitID
    tipo
    monto
    empresaID
    docFuente
    intGarantizar
    beneficiario
    divisaID
    divisa
    paridad
    montoAsegurado
  }
}`;

export const TABLA_SOLOTROSSEGUROSFIANZAS = `
query filtro($filtro: InputFiltroSolSegFianFianza)
{
  filterSolSegFianFianza(filtro : $filtro)
  {
    listado{
        fianzaID
        solSegFianID
        usuarioBitID
        tipo
        monto
        empresaID
        docFuente
        intGarantizar
        beneficiario
        empresa
        divisa
        paridad
        montoAsegurado
    }
    paginas
    registros
  }
}`;

export const getSolOtrosSegurosyFianzas = async (filtro, token) => {
    try {
        let { getSolSegFianFianza } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_SOLOTROSSEGUROSFIANZAS
        });

        return getSolSegFianFianza;

    } catch({message}) { throw new Error(message); }
}

export const getAllSolOtrosSegurosyFianzas = async (variables, token) => {
    try {

        let { getAllSolSegFianFianza } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SOLOTROSSEGUROSFIANZAS
        });

        return getAllSolSegFianFianza;

    } catch({message}) { throw new Error(message); }
}

export const tablaSolOtrosSegurosyFianzas = async (variables, token) => {
 
    try{
        let {filterSolSegFianFianza} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SOLOTROSSEGUROSFIANZAS
        });
        
        return filterSolSegFianFianza;
    }catch({message}) { throw new Error(message); }
}
