import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_SEGURO_VIAJERO = `
mutation addSolSegFianSegViajero($input: InputAddSolSegFianSegViajero!) {
    addSolSegFianSegViajero(input: $input) {
        segViajeroID
        solSegFianID
        paterno
        materno
        nombres
        fechaNacimiento
        edad
        rfc
        destino
        periodo
        divisaID
        paridad
    }
}
`;


export const UPDATE_SEGURO_VIAJERO =`
mutation updateEmpresa($input: InputUpdateSolSegFianSegViajero!) {
    updateSolSegFianSegViajero(input: $input) {
        segViajeroID
                solSegFianID
                paterno
                materno
                nombres
                fechaNacimiento
                edad
                rfc
                destino
                periodo
                divisaID
                paridad
    }
}
`;

export const DELETE_SEGURO_VIAJERO = `
mutation deleteaSolSegFianSegViajero($input: InputDeleteSolSegFianSegViajero) {
    deleteSolSegFianSegViajero(input: $input)
}
`;


export const addSegViajero = async (variables, token) => {
    try {

        let { addSolSegFianSegViajero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SEGURO_VIAJERO
        });

        return addSolSegFianSegViajero;

    } catch({message}) { throw new Error(message); }
};

export const updateSegViajero = async (variables, token) => {
    try {

        let { updateSolSegFianSegViajero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SEGURO_VIAJERO
        });

        return updateSolSegFianSegViajero;

    } catch({message}) { throw new Error(message); }
};


export const deleteSegViajero = async (input, token) => {
    try {

        let { deleteSolSegFianSegViajero } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_SEGURO_VIAJERO
        });

        return deleteSolSegFianSegViajero;

    } catch({message}) { throw new Error(message); }
};
