/*eslint-disable*/
import React from "react";
import { NetworkError } from "../Metodos";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import SnackbarContent from "../../components/Snackbar/SnackbarContent.js";

export default function Notifications(prop) {

    let { message, close = false, closeNotification = () => console.log("Clic en cerrar"), open = true, color = "info", icon = AddAlert } = prop;
    let props = close ? { close: true } : {};

    return (
        <SnackbarContent
            message={NetworkError(message)}
            icon={icon}
            color={color}
            open={open}
            closeNotification={closeNotification}
            { ...props }
        />
    );

}
