import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_CONTROL_PAGO = `
query get($filtro: InputGetControlPago)
{
  getControlPago(filtro : $filtro)
  {
    controlPagoID
    numero
    fechaTramite
    empresaID
    empresaFacturaID
    empresaFactura
    empresa
    aseguradoraID
    aseguradora
    agenteSegID
    agenteSeguro
    descripcion
    ramoSeguroID
    folioTramite
    poliza
    endoso
    importePago
    solicitado
    realizado
    divisaID
    divisa
    folioFactura
    pdf
    xml
    docOrig
    estatus
    observaciones
    docOrigRep
    pdfRep
    xmlRep
    horasInvertidas
    folioInterno
    polizaID
    polizaPrincipal
    vigenciaIni
    vigenciaHasta
    folioInternoSolSeguro
    fechaModificacion
    folioReclamacion
    solSegFianID
    siniesReclAsegID
    reporteDaniosID
    devolucion
    notaCredito
    paridad
    inciso
    gastosExpedicion
    porcentajeIVA
    iva
    total
    tipoPoliza
    diasVigencia
    reporteDaniosID
    subRamoID
    subPolizaID
    subRamo
    polizaPago
    polizaLigada
  }
}`;

export const GET_ALL_CONTROL_PAGOS = `
query getAll($filtro: InputGetControlPago)
{
  getAllControlPago(filtro : $filtro)
  {
    controlPagoID
    numero
    fechaTramite
    empresaID
    empresaFacturaID
    empresaFactura
    empresa
    aseguradoraID
    aseguradora
    agenteSegID
    agenteSeguro
    descripcion
    ramoSeguroID
    folioTramite
    poliza
    endoso
    importePago
    solicitado
    realizado
    divisaID
    divisa
    folioFactura
    pdf
    xml
    docOrig
    estatus
    observaciones
    docOrigRep
    pdfRep
    xmlRep
    horasInvertidas
    folioInterno
    polizaID
    polizaPrincipal
    vigenciaIni
    vigenciaHasta
    folioInternoSolSeguro
    fechaModificacion
    folioReclamacion
    solSegFianID
    siniesReclAsegID
    reporteDaniosID
    devolucion
    notaCredito
    paridad
    inciso
    gastosExpedicion
    porcentajeIVA
    iva
    total
    tipoPoliza
    diasVigencia
    subRamoID
    subPolizaID
    subRamo
    polizaPago
  }
}`;
export const GET_ALL_POLIZAS = `
query getAllPolizasSeguro($filtro: InputGetPolizasSeguro)
{
  getAllPolizasSeguro(filtro: $filtro){
    polizasSeguroID
    numpolEndoso
    tipoPoliza
  }
}`;

export const TABLA_CONTROL_PAGOS = `
query filtro($filtro: InputFiltroControlPago)
{
  filterControlPago(filtro : $filtro)
  {
    listado{
      controlPagoID
      numero
      fechaTramite
      empresaID
      empresaFacturaID
      empresaFactura
      empresa
      aseguradoraID
      aseguradora
      agenteSegID
      agenteSeguro
      descripcion
      ramoSeguroID
      folioTramite
      poliza
      endoso
      importePago
      solicitado
      realizado
      divisaID
      divisa
      folioFactura
      pdf
      xml
      docOrig
      estatus
      observaciones
      docOrigRep
      pdfRep
      xmlRep
      horasInvertidas
      folioInterno
      polizaID
      polizaPrincipal
      vigenciaIni
      vigenciaHasta
      folioInternoSolSeguro
      fechaModificacion
      folioReclamacion
      solSegFianID
      siniesReclAsegID
      reporteDaniosID
      devolucion
      notaCredito
      paridad
      inciso
      tipoPoliza
      diasVigencia
      subRamoID
      subPolizaID
      subRamo
      polizaLigada
      polizaPago
    }
    paginas
    registros
  }
}`;

export const TABLA_CONTROL_PAGOS_REPORTE_DANIOS = `
query filtro($filtro: InputFiltroControlPago)
{
  filterControlPagoReporteDanios(filtro : $filtro)
  {
    listado{
      controlPagoID
      numero
      fechaTramite
      empresaID
      empresa
      aseguradoraID
      aseguradora
      agenteSegID
      agenteSeguro
      descripcion
      ramoSeguroID
      ramoSeguro
      folioTramite
      poliza
      endoso
      importePago
      solicitado
      realizado
      divisaID
      divisa
      folioFactura
      pdf
      xml
      docOrig
      estatus
      observaciones
      docOrigRep
      pdfRep
      xmlRep
      horasInvertidas
      folioInterno
      polizaID
      polizaPrincipal
      vigenciaIni
      vigenciaHasta
      folioInternoSolSeguro
      fechaModificacion
      folioReclamacion
      solSegFianID
      siniesReclAsegID
      reporteDaniosID
      devolucion
      notaCredito
      paridad
      inciso
      tipoPoliza
      diasVigencia
      reporteDaniosID
      siniestroReclamadoID
      tipo
      origen
    }
    paginas
    registros
  }
}`;

export const getControlPago = async (filtro, token) => {
    try {
 
        let { getControlPago } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_CONTROL_PAGO
        });

        for (const [key, value] of Object.entries(getControlPago)) {
            if (value === null) {
                getControlPago[key] = "";
            }
        }

        return getControlPago;

    } catch({message}) { throw new Error(message); }
}

 

export const getAllControlPagos = async (variables, token) => {
    try {

        let { getAllControlPago } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_CONTROL_PAGOS
        });

        return getAllControlPago;

    } catch({message}) { throw new Error(message); }
}

export const seleccionPolizasSeguros = async (variables, token) => {
    try {

        let { getAllPolizasSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_POLIZAS
        });

        if(!Array.isArray(getAllPolizasSeguro)) throw new Error("No se encontraron registros");
/*
        return getAllPolizasSeguro.map(({ polizasSeguroID:value, numpolEndoso:label, tipoPoliza }) => ({ value: `${value}@${tipoPoliza}`, label: label.toUpperCase()}));*/

        let arreglo1 = [];

        arreglo1.push({label: "No Aplica", value: "0"});
  
        getAllPolizasSeguro.forEach( ({polizasSeguroID,numpolEndoso}) => {
                arreglo1.push({label: numpolEndoso, value: polizasSeguroID});  
            });
  
        return arreglo1;

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaControlPagos = async (variables, token) => {
    try{
        let {filterControlPago} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_CONTROL_PAGOS
        });
        return filterControlPago;
    }catch({message}) { throw new Error(message); }
}

export const tablaControlPagosReporteDanios = async (variables, token) => {
    try{
   
        let {filterControlPagoReporteDanios} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_CONTROL_PAGOS_REPORTE_DANIOS
        });
       
      
        return filterControlPagoReporteDanios;
    }catch({message}) { throw new Error(message); }
}
