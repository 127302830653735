import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_TRANSPORTE_MERCANCIA = `
query get($filtro: InputGetSolSegFianTranspMcia)
{
  getSolSegFianTranspMcia(filtro : $filtro)
  {
    transpMciasID
    solSegFianID
    activoAsegID
    usuarioBitID
    equipo
    marca
    capacidad
    valorAseg
    cdOrigen
    fechaSalida
    embalaje
    pesoyVol
    empresaID
    usuarioSolicita
    nuevoUsado
    serie
    modelo
    noInv
    valorNuevo
    cdDestino
    fechaLlegada
    chofer
    sobreDimension
    activoAsegurar
    sustituyeID
    omitir
    divisaID
    paridad
    activo
    montoAsegurado
  }
}`;

export const GET_ALL_TRANSPORTE_MERCANCIA = `
query getAll($filtro: InputGetSolSegFianTranspMcia)
{
  getAllSolSegFianTranspMcia(filtro : $filtro)
  {
    transpMciasID
    solSegFianID
    activoAsegID
    usuarioBitID
    equipo
    marca
    capacidad
    valorAseg
    cdOrigen
    fechaSalida
    embalaje
    pesoyVol
    empresaID
    usuarioSolicita
    nuevoUsado
    serie
    modelo
    noInv
    valorNuevo
    cdDestino
    fechaLlegada
    chofer
    sobreDimension
    activoAsegurar
    sustituyeID
    divisaID
    paridad
    montoAsegurado
  }
}`;

export const TABLA_TRANSPORTE_MERCANCIA = `
query filtro($filtro: InputFiltroSolSegFianTranspMcia)
{
  filterSolSegFianTranspMcia(filtro : $filtro)
  {
    listado{
      transpMciasID
      solSegFianID
      activoAsegID
      usuarioBitID
      equipo
      marca
      capacidad
      valorAseg
      cdOrigen
      fechaSalida
      embalaje
      pesoyVol
      empresaID
      usuarioSolicita
      nuevoUsado
      serie
      modelo
      noInv
      valorNuevo
      cdDestino
      fechaLlegada
      chofer
      sobreDimension
      activoAsegurar
      sustituyeID
      sustituye
      montoAsegurado
    }
    paginas
    registros
  }
}`;

export const getTransporteMercancia = async (filtro, token) => {
    try {
        let { getSolSegFianTranspMcia } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_TRANSPORTE_MERCANCIA
        });

        return getSolSegFianTranspMcia;

    } catch({message}) { throw new Error(message); }
}

export const getAllTransporteMercancia = async (variables, token) => {
    try {

        let { getAllSolSegFianTranspMcia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_TRANSPORTE_MERCANCIA
        });

        return getAllSolSegFianTranspMcia;

    } catch({message}) { throw new Error(message); }
}

export const tablaTransporteMercancia = async (variables, token) => {
    try{
        let {filterSolSegFianTranspMcia} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_TRANSPORTE_MERCANCIA
        });
        return filterSolSegFianTranspMcia;
    }catch({message}) { throw new Error(message); }
}
