import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../../helpers';
import { IS_USERNAME, GET_LOGIN, IS_TOKEN, GET_NEWPASSWORD, GET_NEWTOKEN, GET_ALLSUCURSALESUSUARIO } from '../index';

//Este archivo es el que tiene la comunicación con los resolvers
//Todas las funciones de aquí hacen lo mismo, revisar si se puede dejar solo una función :::Ricardo
//Este archivo ya no se utiliza en el login, se agregó la función general al archivo (Ejecutar) de querys/login/index.js
//Solamente se sigue usando isToken en el index de la carpeta inicio

export const isUsername = async (variables, token) => {
    try {
        let { isUsername } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: IS_USERNAME
        });

        return isUsername;

    } catch({message}) { throw new Error(message); }
}

export const getLogin = async (variables, token) => {
    try {
        let { getLogin } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_LOGIN
        });

        return getLogin;

    } catch({message}) { throw new Error(message); }
}

export const getNewToken = async (variables, token) => {
    try {

        let { GetNewTokenEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_NEWTOKEN
        });

        return GetNewTokenEmpresa;

    } catch({message}) { throw new Error(message); }
}

export const getAllSucursalesUsuario = async (variables, token) => {
    try {

        let { getAllSucursalesUsuario } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALLSUCURSALESUSUARIO
        });

        return getAllSucursalesUsuario;

    } catch({message}) { throw new Error(message); }
}

export const isToken = async (token) => {
    try {

        let { isToken } = await FetchAPI.graphql({
            token,
            url: urlServidor,
            query: IS_TOKEN
        });

        return isToken;

    } catch({message}) { throw new Error(message); }
}

export const getNewPassword = async (variables, token) => {
    try {

        let { getNewPassword } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_NEWPASSWORD
        });

        return getNewPassword;

    } catch({message}) { throw new Error(message); }
}
