
export const GENERIC_ENUMS = `
    query genericEnums($enum: String!) {
        getEnum(enum: $enum) {
            value
            name
            description
        }
    }
`;
