import React, { useState, useEffect, useRef } from 'react'
// import Input from '../Inputs/components/Input';
import Input from '../../../_layout/genericComponents/Inputs';

import './styles.css';

export default function MultiPartidas(props) {

    let { listaDominios, datosGet, setDataToSave, clearData, notificaciones } = props;
    const cleanPart = {
        correo: "",
        dominioID: "",
        mensaje: ""
    }
    
    const [statePartidas, setStatePartidas] = useState([cleanPart])

    useEffect(() => {
        async function clearData() {
            setStatePartidas([cleanPart])
        }
        clearData()
    }, [clearData])

    useEffect(() => {
        async function ftEffect() {
            datosGet?.length > 0 ? setStatePartidas(datosGet) : setStatePartidas([cleanPart])
        }
        ftEffect();

    }, [datosGet]);

    useEffect(() => {
        async function dataUpdate() {
            setDataToSave(statePartidas)
        }
        dataUpdate();
    }, [statePartidas]);

    const agregarPartida = () => {
        setStatePartidas([...statePartidas, cleanPart])
    }

    const removerPartida = (index) => {
        const list = [...statePartidas];
        list.splice(index, 1);
        setStatePartidas(list);
    }

    const handleChangePartidas = (e, index, campo) => {

        if (campo == "dominioID") {
            const list = [...statePartidas];
            list[index]['dominioID'] = e.value;
            setStatePartidas([...list]);
        }
        else {
            if(campo == 'correo' && e.target.value.includes('@')){
                notificaciones({mensaje: `No debe incluir @, ni el dominio en campo correo, destinatario #${index + 1}`, color: 'danger', open: true});
            }
            else{
                notificaciones({mensaje: "", color: null, open: false});
                const list = [...statePartidas];
                list[index][campo] = e.target.value;
                setStatePartidas([...list]);
            }
        }
    }

    return (<>
        <div className='contenedoresPartidas'>
            {
                statePartidas.map((partida, index) => (
                    <div key={index} class="contenedorMultiPartidas">
                        <div class="tituloPartidas">
                            Destinatario # {index + 1}
                        </div>
                        <div class="agrupadorInputsBotones">
                            <div class="contenedorInputsPartida">
                                {/* {
                                agregarInactivos({empleadoIDPartida: partida.empleadoID,estatusPartida: partida.estatus})
                            } */}
                                <div class="fila">
                                    <div class="campo">
                                        <Input title="Correo" placeholder="Correo" id="correo" value={partida.correo}
                                            onChange={(e) => { handleChangePartidas(e, index, 'correo') }}
                                        />
                                    </div>
                                    <div class="campo">
                                        <Input title="Seleccionar Dominio" placeholder="Seleccionar Dominio" tipo="autocomplete" id="dominioID" data={listaDominios} value={partida.dominioID}
                                            onChange={(e) => { handleChangePartidas(e, index, 'dominioID') }}
                                        />
                                    </div>
                                </div>
                                <div class="fila">
                                    <div class="campo">
                                        <Input title="Mensaje" placeholder="Mensaje" id="mensaje" value={partida.mensaje} multiline={true}
                                            onChange={(e) => { handleChangePartidas(e, index, 'mensaje') }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="botonesPartidas">
                                {
                                    <button className='buttonAdd'
                                        title='Agregar Destinatario'
                                        // disabled={!proveedorID}
                                        onClick={() => { agregarPartida() }}>
                                        +
                                    </button>
                                }
                                {
                                    statePartidas.length > 1 && (
                                        <button className='buttonRemove'
                                            title="Remover Destinatario"
                                            // disabled={!proveedorID}
                                            onClick={() => { removerPartida(index) }}>
                                            -
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    </>)
}