import { ContactSupportOutlined } from '@material-ui/icons';
import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';


export const GET_DIVISA = `
query get ($filtro: InputGetDivisa)
{
  getDivisa(filtro: $filtro){
      divisaID
      divisa
      divisac
      codigoMon
      paridad
      fechaCotizacion
      filtrar
      filtrarRep
      abreviacion
      decimales
      porcentajeVariacion
}
}
`;

export const GET_ALL_DIVISAS = `
query filtroGetAll($filtro: InputGetDivisa){
    getAllDivisa(filtro:$filtro){
      divisaID
      divisa
      divisac
      codigoMon
      paridad
      fechaCotizacion
      filtrar
      abreviacion
      decimales
      porcentajeVariacion
    }
  }`;



export const TABLA_DIVISA = `
query filtro ($filtro: InputFilterDivisa){
    filterDivisa(filtro: $filtro)
    {
    listado
    {
        divisaID
        divisa
        divisac
        codigoMon
        paridad
        fechaCotizacion
        filtrar
        abreviacion
        decimales
        porcentajeVariacion
      }
      registros
      paginas
    }
  }
  `;



  export const getDivisa = async (filtro, token) => {
    try {

        let { getDivisa } = await FetchAPI.graphql({
            token,
            variables: {filtro},
            url: urlServidor,
            query: GET_DIVISA
        });

        return getDivisa;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllDivisa = async (variables, token) => {
    try {

        let { getAllDivisa } = await FetchAPI.graphql({
            token,
            variables ,
            url: urlServidor,
            query: GET_ALL_DIVISAS
        });

        return getAllDivisa;

    } catch({message}) { throw new Error(message); }
}


export const seleccionDivisa = async (variables, token) => {
    try {

        let allGenDivisa = await getAllDivisa(variables, token);

        if(!Array.isArray(allGenDivisa)) throw new Error("No se encontraron registros");

        let divisas = allGenDivisa.map(({ divisaID:value, codigoMon, divisac, paridad }) => {
            let label = `${divisac} - (${codigoMon})`;
            let valores = { value, label: label.toUpperCase(), paridad } ;
            return valores;
        });
        return divisas;
    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const seleccionParidadDivisa = async (variables, token) => {
    try {

        let AllInvDivisas = await getAllDivisa(variables, token);

        if (!Array.isArray(AllInvDivisas)) throw new Error("No se encontraron registros");

        return AllInvDivisas.map(({ paridad: value, divisa: label }) => ({ value, label }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaDivisas = async (variable, token) => {
    try {


        let {filterDivisa}  = await FetchAPI.graphql({
            token,
            variables: variable,
            url: urlServidor,
            query: TABLA_DIVISA
        });

        return filterDivisa;

    } catch ({ message }) { throw new Error(message); }
}
