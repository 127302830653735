import React, { useState, useRef } from 'react'
import { Validaciones } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getSeguimientoActividades, tablaSeguimientoActividades } from '../../../querys/SeguimientoActividades';
import { deleteSeguimientoActividades, addSeguimientoActividades, updateSeguimientoActividades } from '../../../mutations/SeguimientoActividades';

import moment from 'moment';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Documentacion from '../Documentacion';

const { trim } = Validaciones;
export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

function SeguimientoActividades({ datos,actualizarActividades,programaAnual = false,actualizarTablaReporteador, reporteadorDaños = false, actualizarTablaReclamosTerceros, reclamosTerceros = false, actualizarTablaReclamosAseguradora, reclamosAseguradora = false}) {
    const { actividadID = null, reporteDaniosID = null, siniesReclAsegID = null, siniesReclTercerosID = null, verHistorial = false } = datos;
    const { token } = useSelector(state => state.login);

    let tipoSeguimiento = null;
    let tituloForm = "Seguimiento de Actividades";
    if (reporteDaniosID) {
        tituloForm = "Seguimiento de Reporteador de Daños";
        tipoSeguimiento = 3;
    }
    if (actividadID) {
        tituloForm = "Seguimiento de Actividades";
        tipoSeguimiento = null;
    }
    if (siniesReclAsegID) {
        tituloForm = "Seguimiento de Reclamación a Aseguradora";
        tipoSeguimiento = 2;
    }
    if (siniesReclTercerosID) {
        tituloForm = "Seguimiento de Reclamación a Tercero";
        tipoSeguimiento = 1;
    }

    let formato = "YYYY-MM-DD";
    const cleanState = {
        seguimiento: "",
        fechaInicio: "",
        fechaFinal: "",
        fechaInicioReal: "",
        fechaFinalReal: "",
        horaInicial: "",
        horaFinal: "",
        finalizarSeguimiento: false
    };

    const cleanErrorState = {
        seguimiento: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" },
        horaInicial: { error: false, helperText: "" },
        horaFinal: { error: false, helperText: "" },
    };

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.seguimientoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Actividad/Evento/Seguimiento`;

    const seguimientoRef = useRef(null);
    const fechaFinalRealRef = useRef(null);
    const fechaInicioRealRef = useRef(null);
    const horaInicialRef = useRef(null);
    const horaFinalRef = useRef(null);

    const acciones = !verHistorial ?
        [{
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "seguimientoID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSeguimientoActividades,
                formatearResultado: [
                    { id: "fechaInicio", formato: (data) => moment(data) },
                    { id: "fechaFinal", formato: (data) => moment(data) },
                    { id: "fechaInicioReal", formato: (data) => moment(data) },
                    { id: "fechaFinalreal", formato: (data) => moment(data) },
                    { id: "horaInicial", formato: (data) => moment(data) },
                    { id: "horaFinal", formato: (data) => moment(data) }
                ]
            })
        }, {
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "seguimientoID" }],
            disabled: { multiseleccion: true },
            onClick: ({ seguimientoID }) => handelAlertEliminar({
                setAlert,
                mensaje: ` el registro `,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { seguimientoID }, asyncDelete: ftEliminar
                })
            })
        },
        {
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "seguimientoID" },
            { campo: "folioInternoReporteDanios" },
            { campo: "folioTarea" },
            { campo: "siniesReclAsegFolio" },
            { campo: "siniesReclTercerosFolio" }],
            disabled: { multiseleccion: true },
            onClick: ({ seguimientoID, folioTarea, folioInternoReporteDanios, siniesReclAsegFolio, siniesReclTercerosFolio }) => {
                let pTitulo2 = "";
                if (reporteDaniosID) {
                    pTitulo2 = "Datos adjuntos al reporte de daños " + folioInternoReporteDanios;
                }
                if (actividadID) {
                    pTitulo2 = "Datos adjuntos a la actividad " + folioTarea;
                }
                if (siniesReclAsegID) {
                    pTitulo2 = "Datos adjuntos al reclamo " + siniesReclAsegFolio;
                }
                if (siniesReclTercerosID) {
                    pTitulo2 = "Datos adjuntos al reclamo " + siniesReclTercerosFolio;
                }

                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: seguimientoID,
                    pTipo: folioTarea,
                    pClasificacion: "",
                    pTabla: "Seguimiento Actividades",
                    pTitulo2,
                    plUtilizaProps: false
                });
                setOpenDocumentacion(true);
            }
        }] : [{
            icono: VisibilityIcon,
            color: info,
            descripcion: "Ver",
            parametros: [{ campo: "seguimientoID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getSeguimientoActividades,
                formatearResultado: [
                    { id: "fechaInicio", formato: (data) => moment(data) },
                    { id: "fechaFinal", formato: (data) => moment(data) },
                    { id: "fechaInicioReal", formato: (data) => moment(data) },
                    { id: "fechaFinalreal", formato: (data) => moment(data) },
                    { id: "horaInicial", formato: (data) => moment(data) },
                    { id: "horaFinal", formato: (data) => moment(data) }
                ]
            })
        },
        {
            icono: UnarchiveIcon,
            color: info,
            descripcion: "Adjuntar",
            parametros: [{ campo: "seguimientoID" },
            { campo: "folioInternoReporteDanios" },
            { campo: "folioTarea" },
            { campo: "siniesReclAsegFolio" },
            { campo: "siniesReclTercerosFolio" }],
            disabled: { multiseleccion: true },
            onClick: ({ seguimientoID, folioTarea, folioInternoReporteDanios, siniesReclAsegFolio, siniesReclTercerosFolio }) => {
                let pTitulo2 = "";
                if (reporteDaniosID) {
                    pTitulo2 = "Datos adjuntos al reporte de daños " + folioInternoReporteDanios;
                }
                if (actividadID) {
                    pTitulo2 = "Datos adjuntos a la actividad " + folioTarea;
                }
                if (siniesReclAsegID) {
                    pTitulo2 = "Datos adjuntos al reclamo " + siniesReclAsegFolio;
                }
                if (siniesReclTercerosID) {
                    pTitulo2 = "Datos adjuntos al reclamo " + siniesReclTercerosFolio;
                }

                setDatosDetalleAdjuntos({
                    pURLDoctoOrigenID: seguimientoID,
                    pTipo: folioTarea,
                    pClasificacion: "",
                    pTabla: "Seguimiento Actividades",
                    pTitulo2,
                    plUtilizaProps: false
                });
                setOpenDocumentacion(true);
            }
        }];

    async function ftEliminar({ seguimientoID }) {
        let resultado;

        if (seguimientoID) resultado = await deleteSeguimientoActividades({
            input: {
                seguimientoID: Number(seguimientoID),tipoSeguimiento
            }
        }, token);

        handleClose();
        setActualizar(!actualizar);
    }

    const botones = verHistorial ? [{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }] : [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }
    ];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: reporteDaniosID ? "reporteDaniosID" : "actividadID",
        color: primary,
        title: tituloForm,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "reporteDaniosID", descripcion: "reporteDaniosID", hide: true },
            { variable: "actividadID", descripcion: "actividadID", hide: true },
            { variable: "seguimientoID", descripcion: "seguimientoID", hide: true },
            { variable: "tipoSeguimiento", descripcion: "Tipo de seguimiento", hide: (verHistorial ? false : true) },
            { variable: "seguimiento", descripcion: "Seguimiento" },
            { variable: "folioTarea", descripcion: "Folio tarea", hide: true },
            { variable: "tituloTarea", descripcion: "tituloTarea", hide: true },
            { variable: "folioInternoReporteDanios", descripcion: "folioInternoReporteDanios", hide: true },
            { variable: "fechaInicioReal", descripcion: "Fecha Inicio" },
            { variable: "fechaFinalReal", descripcion: "Fecha Final" },
            { variable: "estado", descripcion: "Estado" },
        ],
        responsiveTitle: ["numero"],
        filter: [
            { campo: "numero" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [
            {
                columnas: ["colorEstado"], tipo: "porcentaje", simbolo: "%"
            },
            {
                columnas: ["fechaInicioReal", "fechaFinalReal"], tipo: "fecha", onlyDate: false
            }
        ]
    }

    const inputs = [
        {
            disabled: disabled || verHistorial,
            id: "seguimiento",
            value: state.seguimiento,
            error: errorState.seguimiento.error,
            success: state.seguimiento && !errorState.seguimiento.error ? true : undefined,
            helperText: errorState.seguimiento.helperText,
            inputRef: seguimientoRef,
            title: "Seguimiento *",
            multiline: true,
            placeholder: "Capture el seguimiento",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "seguimiento")
            },
            onKeyDown: () => { return; }
        },
        {
            disabled: disabled || verHistorial,
            id: "fechaInicioReal",
            value: state.fechaInicioReal,
            error: errorState.fechaInicioReal.error,
            success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
            helperText: errorState.fechaInicioReal.helperText,
            inputRef: fechaInicioRealRef,
            placeholder: "Fecha Inicial *",
            tipo: "datetimepicker",
            onlyDate: true,
            grid: { md: 3, lg: 3 },
            onChange: (value) => { typeof value === "object" && handleChange(value.format(formato), "fechaInicioReal") }
        },
        {
            disabled: disabled || verHistorial,
            id: "horaInicial",
            value: state.horaInicial,
            error: errorState.horaInicial.error,
            success: state.horaInicial && !errorState.horaInicial.error ? true : undefined,
            helperText: errorState.horaInicial.helperText,
            inputRef: horaInicialRef,
            placeholder: "Hora Inicial *",
            tipo: "datetimepicker",
            onlyTime: true,
            grid: { md: 3, lg: 3 },
            onChange: (value) => { typeof value === "object" && handleChange(moment(value), "horaInicial") },
        },
        {
            disabled: disabled || verHistorial,
            id: "fechaFinalReal",
            value: state.fechaFinalReal,
            error: errorState.fechaFinalReal.error,
            success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
            helperText: errorState.fechaFinalReal.helperText,
            inputRef: fechaFinalRealRef,
            placeholder: "Fecha Final *",
            tipo: "datetimepicker",
            onlyDate: true,
            grid: { md: 3, lg: 3 },
            onChange: (value) => { typeof value === "object" && handleChange(value.format(formato), "fechaFinalReal") }
        },
        {
            disabled: disabled || verHistorial,
            id: "horaFinal",
            value: state.horaFinal,
            error: errorState.horaFinal.error,
            success: state.horaFinal && !errorState.horaFinal.error ? true : undefined,
            helperText: errorState.horaFinal.helperText,
            inputRef: horaFinalRef,
            placeholder: "Hora Final *",
            tipo: "datetimepicker",
            onlyTime: true,
            grid: { md: 3, lg: 3 },
            onChange: (value) => { typeof value === "object" && handleChange(moment(value), "horaFinal") },
        },
        {
            disabled: disabled || verHistorial,
            id: "finalizarSeguimiento",
            value: state.finalizarSeguimiento,
            checked: state.finalizarSeguimiento,
            title: "¿Finalizar Seguimiento?",
            tipo: "switch",
            grid: { md: 3, lg: 3 },
            onChange: (e) => {
                setState({ ...state, finalizarSeguimiento: e.target.checked })
            }
        },
    ];

    const accionesFormulario = verHistorial ? [] : [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: seguimientoRef,
            mensajeCorrecto: `Se ${state.actividadID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ seguimiento, fechaInicioReal, fechaFinalReal, horaInicial, horaFinal }) {
        let error;
        let validaciones = [
            {
                condicion: !seguimiento || trim(seguimiento) === "",
                ref: seguimientoRef, keyError: "seguimiento",
                mensajeError: "Debe especificar el seguimiento"
            },
            {
                condicion: !fechaInicioReal || trim(fechaInicioReal) === "",
                ref: fechaInicioRealRef, keyError: "fechaInicioReal",
                mensajeError: "Debe especificar la fecha inicial"
            },
            {
                condicion: !fechaFinalReal || trim(fechaFinalReal) === "",
                ref: fechaFinalRealRef, keyError: "fechaFinalReal",
                mensajeError: "Debe especificar la fecha final"
            },
            {
                condicion: !horaInicial || trim(horaInicial) === "",
                ref: horaInicialRef, keyError: "horaInicial",
                mensajeError: "Debe especificar la hora inicial"
            },
            {
                condicion: !horaFinal || trim(horaFinal) === "",
                ref: horaFinalRef, keyError: "horaFinal",
                mensajeError: "Debe especificar la hora final"
            },
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            let { seguimientoID, fechaInicioReal, fechaFinalReal, seguimiento, finalizarSeguimiento, horaInicial, horaFinal } = state;
            let error = handleValidaciones({
                seguimiento, 
                fechaInicioReal, 
                fechaFinalReal,
                horaInicial,
                horaFinal
            });

            if (error) return error;
            fechaInicioReal = moment(fechaInicioReal);
            fechaFinalReal = moment(fechaFinalReal);

            let resultado;
            if (seguimientoID) {
                resultado = await updateSeguimientoActividades({
                    input: {
                        seguimientoID,
                        actividadID,
                        reporteDaniosID,
                        siniesReclAsegID,
                        siniesReclTercerosID,
                        seguimiento,
                        fechaInicioReal: `${fechaInicioReal.format("YYYY-MM-DD")}T${horaInicial.format('LT')}`,
                        fechaFinalReal: `${fechaFinalReal.format("YYYY-MM-DD")}T${horaFinal.format('LT')}`,
                        finalizarSeguimiento,
                        tipo: tipoSeguimiento
                    }
                }, token);
            }
            else {
                resultado = await addSeguimientoActividades({
                    input: {
                        seguimientoID: null,
                        actividadID,
                        reporteDaniosID,
                        siniesReclAsegID,
                        siniesReclTercerosID,
                        seguimiento,
                        fechaInicioReal: `${fechaInicioReal.format("YYYY-MM-DD")}T${horaInicial.format('LT')}`,
                        fechaFinalReal: `${fechaFinalReal.format("YYYY-MM-DD")}T${horaFinal.format('LT')}`,
                        finalizarSeguimiento,
                        tipo: tipoSeguimiento
                    }
                }, token);
            }
            programaAnual && actualizarActividades(actualizarActividades =>(!actualizarActividades) );
            reporteadorDaños && actualizarTablaReporteador(actualizar =>(!actualizar));
            reclamosTerceros && actualizarTablaReclamosTerceros(actualizar =>(!actualizar));
            reclamosAseguradora && actualizarTablaReclamosAseguradora(actualizar =>(!actualizar));
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    return (
        <>
            <Tabla
                infoTabla={infoTabla}
                asyncData={tablaSeguimientoActividades}
                token={token}
                parametrosFijos={{
                    actividadID,
                    reporteDaniosID,
                    siniesReclAsegID,
                    siniesReclTercerosID,
                    tipo: (verHistorial && reporteDaniosID) ? null : tipoSeguimiento
                }}
            />
            <FormularioModal
                open={open}
                title={titleModal}
                onClose={handleClose}
                notificaciones={notificaciones}
                closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                inputs={inputs}
                acciones={accionesFormulario}
                focus={fechaInicioRealRef}
            />
            {
                alert &&
                <SweetAlert
                    title={alert.title}
                    descripcion={alert.descripcion}
                    tipo={alert.tipo}
                    msjConfirmacion={alert.msjConfirmacion}
                    msjCancelacion={alert.msjCancelacion}
                    onConfirm={alert.onConfirm}
                    showConfirm={alert.showConfirm}
                    showCancel={alert.showCancel}
                    onCancel={() => setAlert(null)}
                />
            }
            {
                openDocumentacion &&
                <Modal maxWidth={true}>
                    <Documentacion
                        datosDetalleAdjuntos={datosDetalleAdjuntos}
                        setOpenDocumentacion={() => setOpenDocumentacion(false)} />
                </Modal>
            }
        </>);
}

export default SeguimientoActividades;