import React from 'react';
import { mensajeInicial } from '..';
import { strUrlSArchivos, empresa } from '../index';

import { upsertFile, deleteFile } from '../../mutations/ServerStorage'
import { DatosObligatorios, DatosObligatoriosFromObject } from '@renedelangel/helpers/lib/Validaciones';
import { ArchiveRounded } from '@material-ui/icons';
import rutas from 'componentes/Menu/rutas';
let tokenFijo = 'c29mdHdvbmUyMDIwQCM='

export async function getArchivo(rutaimagen) {

    let resultado = '';
    if (rutaimagen !== "") {
        let ruta = strUrlSArchivos + rutaimagen;
        try {
            const response = await fetch(
                `${ruta}`,
                {
                    method: "GET",
                    headers: {
                        token: tokenFijo
                    }
                }
            )
                .then(res => res.blob())
                .then(blob => {
                    let result = blobToBase64(blob).then(res => {
                        return res;
                    });
                    return result;
                });
            resultado = response;
        } catch (error) {
            console.log(`Ha ocurrido algo inesperado en metodo GET de server storage, inténtalo  más tarde `);
        }
    }
    return resultado;

};
export async function postArchivo(rutaimagen, archivo, privado) {

    let rutaArchivo = '';
    let rutaGral = empresa + '/' + rutaimagen;

    try {
        const formData = new FormData();
        formData.append("archivo", archivo);
        formData.append("aplicacion", "segumex");
        formData.append("ruta", rutaGral);
        privado === 1 ? formData.append("privado", "true") : formData.append("publico", "true");

        let response = await fetch(
            `${strUrlSArchivos}/upload`,
            {
                method: "POST",
                headers: {
                    token: tokenFijo,//props.token
                    maxFileSize: '50mb',
                },
                body: formData,
            }
        )
            .then(res => res.json())
            .then(res => {
                if (res.ok === true) {
                    let url = res.resultado;
                    let base = strUrlSArchivos + '/privado/segumex';
                    let tam = base.length;
                    if (privado == 0) {
                        tam = tam - 1;
                    }
                    return url = url.substring(tam);
                } else {
                    console.log(res.mensaje);
                }
            });
        rutaArchivo = response;
    } catch (error) {
        console.log(`Ha ocurrido algo inesperado, en metodo post, inténtalo  más tarde `, error);
        return '';
    }
    return rutaArchivo;

};
export async function putArchivo(rutaimagen, archivo) {
    if (rutaimagen === "" || rutaimagen === null || rutaimagen === undefined) return;
    if (rutaimagen.substring(0, 5) === 'https') {
        let lenServer = strUrlSArchivos.length;
        rutaimagen = rutaimagen.substring(lenServer + 1, rutaimagen.length);
    }
    let respuesta = true;
    try {
        const formData = new FormData();
        formData.append(
            "archivo", archivo
        );
        formData.append(
            "ruta", rutaimagen
        );
        let response = await fetch(
            `${strUrlSArchivos}/upload`,
            {
                method: "PUT",
                headers: {
                    token: tokenFijo
                },
                body: formData
            }
        )
            .then(res => res.json())
            .then(res => {
                return {ok: res.ok, ruta: res.resultado};
            });
        respuesta = response;
    } catch (error) {
        console.log(`Ha ocurrido algo inesperado, en metodo put, inténtalo  más tarde `);
    }
    return respuesta;

};
export async function deleteFileStorage(rutaimagen) {

    if (rutaimagen === "" || rutaimagen === null || rutaimagen === undefined) return;

    if (rutaimagen.substring(0, 5) === 'https') {
        let lenServer = strUrlSArchivos.length;
        rutaimagen = rutaimagen.substring(lenServer + 1, rutaimagen.length);
    }
    if (rutaimagen.substring(0, 1) === '/') {
        rutaimagen = rutaimagen.substring(1, rutaimagen.length);
    }
    let respuesta = true;
    try {
        const formData = new FormData();
        formData.append(
            "ruta", rutaimagen//"imagenes/fotoPerfil"
        );
        let response = await fetch(
            `${strUrlSArchivos}/upload`,
            {
                method: "DELETE",
                headers: {
                    token: tokenFijo//props.token
                },
                body: formData
            }
        )
            .then(res => res.json())
            .then(res => {
                return res.ok;
            });
        respuesta = response;
    } catch (error) {
        console.log(`Ha ocurrido algo inesperado, en método delete, inténtalo  más tarde `, error);
    }
    return respuesta;

};
export async function actualizaArchivo(rutaimagen, archivo, privado, datos, token, remover) {
    let result;
    let resultado = { error: false, resultado: null };
    let id = !datos.serverStorageID ? null : datos.serverStorageID;

    if (remover == true) {
        let rutaEliminar = privado == 1 ? 'private/segumex' + datos.ruta : 'public/segumex'
        result = eliminaArchivo({ serverStorageID: Number(id), ruta: rutaEliminar, token });
    }
    if (!archivo) {
        return resultado;
    }
    if (id !== null) {
        let ruta = privado == 1 ? 'private/segumex' + datos.ruta : 'public/segumex'
        result = await putArchivo(ruta, archivo);
        result = result?.ok ? result.ruta : "";
    } else {
        result = await postArchivo(rutaimagen, archivo, privado);
        rutaimagen = result;
    }

    if (result !== "") {
        let rutaBd = datos.serverStorageID ?  result/*datos.ruta*/ : rutaimagen;
        let rs = await upsertFile({ input: { serverStorageID: id, origenID: datos.origenID, tabla: datos.tabla, tipo: datos.tipo, ruta: rutaBd, clasificacion: datos.clasificacion, nombreArchivo: datos.nombreArchivo } }, token);
        resultado = { error: false, resultado: rs };
    } else { resultado = { error: true, resultado: null }; }

    return resultado;

}

export async function actualizaArchivoSoloRuta(rutaimagen, archivo, privado, id, token, remover) {

    let result;
    let resultado;

    if (remover === true) {
        let res = deleteFileStorage(rutaimagen, tokenFijo);
        if (res) {
            result = rutaimagen;
        } else result = false;
        return result;
    }
    if (id > 0) {
        result = await putArchivo(rutaimagen, archivo, tokenFijo);
        resultado = resultado ? rutaimagen : ""
    } else {
        result = await postArchivo(rutaimagen, archivo, privado, tokenFijo);
        resultado = result;
    }
    return resultado;
}

export async function eliminaArchivo( {serverStorageID, ruta, token} ) {

    try {
        let resultado = { error: false, resultado: null };
        if (serverStorageID == null || ruta == "")//valida que se tengan datos necesarios
        {
            return resultado;
        }
        let res = await deleteFileStorage(ruta);
        if (res == true) {
            let resBd = await deleteFile({ input: { serverStorageID } }, token);
            resultado = { error: false, resultado: resBd };
        }else{
          resultado = { error: true, resultado: null };
        }
        return resultado;
    } catch (error) {
        console.log(error);
    }
}


export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(',')[1];
            resolve(dataUrl);
        };
    });
};







