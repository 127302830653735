import { ErrorRounded } from '@material-ui/icons';
import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';


export const ADD_DIVISA = `
    mutation addDivisa ($input: InputAddDivisa!){
      addDivisa(input: $input){
            divisa
            divisac
            codigoMon
            paridad
            fechaCotizacion
            filtrar
            abreviacion
            decimales
            porcentajeVariacion
        }
   }
`;

export const UPDATE_DIVISA = `
mutation updateDivisa ($input: InputUpdateDivisa!){
  updateDivisa(input: $input)
    {
        divisaID
        divisa
        divisac
        codigoMon
        paridad
        fechaCotizacion
        filtrar
        abreviacion
        decimales
        porcentajeVariacion
        usuarioBitID
    }
  }
`;

export const DELETE_DIVISA = `
mutation deleteDivisa ($input : InputDeleteDivisa){
  deleteDivisa(input: $input)
  }
`;


export const addDivisa = async (variables, token) => {
    try {

        let { addDivisa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_DIVISA
        });
 
        return addDivisa;
    } catch({message}) { throw new Error(message); }
};

export const updateDivisa = async (variables, token) => {
    try {

        let { updateDivisa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_DIVISA
        });
        return updateDivisa;
    } catch({message}) { throw new Error(message); }
};

export const deleteDivisa = async (input, token) => {
    try {
        let { deleteDivisa } = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_DIVISA
        });

        return deleteDivisa;

    } catch({message}) { throw new Error(message); }
};
