import React, { useState, useEffect,useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Encriptar } from '@renedelangel/helpers';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import useMediaQuerys from "../../../_layout/genericComponents/MediaQuerys";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Boton } from '../../../_layout/genericComponents/Botones'; 
// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReportIcon from '@material-ui/icons/Report';
// core components
import AdminNavbarLinks from './componentes/Links';
import Button from '../../components/CustomButtons/Button.js';
import styles from '../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js';
//metodos
import { getAllNotificaciones } from '../../../querys/Notificaciones';
const useStyles = makeStyles(styles);
const useStylesPopOver = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));
export default function AdminNavbar(props) {
    const btnNotifSol = useRef();
    const btnNotifRep = useRef();
    const [btnTipo, setBtnTipo] = useState("Solicitud Seguro");
    const { token, usuario } = useSelector(state => state.login);
    const history = useHistory();
    const { Encrypt, Decrypt } = Encriptar;
    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    const classes = useStyles();
    const classesPopOver = useStylesPopOver();
    const { color, rtlActive, brandText, logoMarca, infoHeader, logout, headerSearch, actionsHeader } = props;
    const appBarClasses = cx({
        [' ' + classes[color]]: color
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        ' ' +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        });

    const [listNotificaciones, setListNotificaciones] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
    }

    function redireccionar({ registroID, tipo }) {
        let seed = "scrumweb";
        const datos = `{"registroID": "${registroID}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        if (tipo === "Solicitud Seguro"){
            history.push(`/gen/SolicitudesSeguros/${url}`);
        }
        else{
            history.push(`/gen/ReporteadorDeDanios/${url}`);
        }
        //condicionar con el tipo
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    function generarNotificaciones(data) {
        let cont=0,cont2 = 0;

        let componente = data.map(({ registroID, folio, fecha, estado, tipo, seguimiento }, index) => {
            if (btnTipo === 'Solicitud Seguro')
            {
                if (tipo === 'Solicitud Seguro')
                {
                    cont++;
                    return <div key={`${index}`} style={{ cursor: 'pointer' }} onClick={() => { redireccionar({ registroID,tipo });}}>
                    <p key={`${index}`} style={{ textAlign: 'justify', maxWidth: !isTabletOrMobile ? '500px' : '300px' }}><b>{cont}. {tipo.toUpperCase()}</b> con folio <b>{folio}</b>, fecha de alta <b>{fecha}</b> con estatus <b>{estado}</b>, última modificación <b>{seguimiento.toLowerCase()}.</b></p> <hr style={{ marginTop: '0px', marginBottom: '0px', height: '2px' }} />
                    </div>
                }
            }
            else {
                if (tipo === 'Reporte')
                {
                    cont2++;
                    return <div key={`${index}`} style={{ cursor: 'pointer' }} onClick={() => { redireccionar({ registroID,tipo });}}>
                    <p key={`${index}`} style={{ textAlign: 'justify', maxWidth: !isTabletOrMobile ? '500px' : '300px' }}><b>{cont2}. {tipo}</b> con folio <b>{folio}</b> y fecha <b>{fecha}</b> se encuentra en estado <b>{estado}</b> con seguimiento <b>{seguimiento.toLowerCase()}.</b></p> <hr style={{ marginTop: '0px', marginBottom: '0px', height: '2px' }} />
                    </div>
                }
            }
            
        })
        return componente;
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let notificaciones;
                if (usuario.notificaciones === 1) {
                    notificaciones = await getAllNotificaciones({}, token);
                    setListNotificaciones(notificaciones);
                }
            } catch ({ message }) {
                console.error("Error cargar notificaciones:", message)
            }
        } ftEffect();
    }, []);
    //Tiene dos useEffect para obtener la lista de notificaciones

    useEffect(() => {
        async function ftEffect() {
            try {
                if (usuario.notificaciones === 1) {
                    const interval = setInterval(async () => {
                        try {
                            let notificaciones;
                            notificaciones = await getAllNotificaciones({}, token);
                            setListNotificaciones(notificaciones);
                        } catch (error) {
                            console.error("Error_notificaciones:", error)
                        }
                    }, 60000);
                    interval();
                }
            } catch ({ message }) {
                console.error("Error cargar notificaciones:", message)
            }
        } ftEffect();
    }, []);



    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                            <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                                <MoreVert className={classes.sidebarMiniIcon} />
                            </Button>
                        )}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button href="#" className={classes.title} color="transparent">
                        {logoMarca ? logoMarca : brandText}
                    </Button>
                </div>
                {
                    usuario.notificaciones === 1 && <div style={{ paddingRight: '10px', cursor:'pointer' }} onBlur={handleClose} >
                        {
                            listNotificaciones.length > 0 ? <Badge badgeContent={listNotificaciones.length} color="primary" onClick={handleClick}>
                                <NotificationsNoneIcon />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    {
                                        (Array.isArray(listNotificaciones) && listNotificaciones.length > 0) && <div style={{ width: '500px'}} >
                                            <Boton key={'btnSol'} inputRef={btnNotifSol} Icono={AssignmentIcon} color={'primary'} descripcion={'Solicitudes'} disabled={false} loader={false} onClick={() => setBtnTipo("Solicitud Seguro")} />
                                            <Boton key={'btnRep'} inputRef={btnNotifRep} Icono={ReportIcon} color={'primary'} descripcion={'SINIESTROS'} disabled={false} loader={false} onClick={() => setBtnTipo("Reporte Daños")} />
                                            <hr />
                                            {generarNotificaciones(listNotificaciones)}
                                        </div>
                                    }
                                </Popover>
                            </Badge> :
                            listNotificaciones.length === 0 ? <Badge badgeContent={'0'} color="primary" onClick={handleClick}>
                                <NotificationsNoneIcon />
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >SIN NOTIFICACIONES</Popover>
                            </Badge>
                            : <div>
                                <CircularProgress color="secondary" />
                            </div>
                        }
                    </div>
                }
                <Hidden smDown implementation="css">
                    {infoHeader}
                </Hidden>
                <Hidden smDown implementation="css">
                    <AdminNavbarLinks rtlActive={rtlActive} logout={logout} headerSearch={headerSearch} actionsHeader={actionsHeader} />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

AdminNavbar.propTypes = {
    color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
    rtlActive: PropTypes.bool,
    brandText: PropTypes.string,
    miniActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func
};
