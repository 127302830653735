import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_EMPRESA = `
        query get($filtro: InputGetEmpresa){
            getEmpresa(filtro:$filtro){
                empresaID
                nombre
                siglas
            }
        }`;

    export const GET_ALL_EMPRESA = `
        query filtroGetAll($filtro: InputGetEmpresa){
            getAllEmpresa(filtro:$filtro){
                empresaID
                nombre
                siglas
            }
        }`;
export const GET_REPORTE_EMPRESA_SOLICITUDES = `
    query getReporteEmpresaSolicitudes($filtro: InputReporteEmpresaSolSegFian)
    {
      getReporteEmpresaSolicitudes(filtro: $filtro)
      {
        empresaID
        tipo
        total
      }
    }`;
export const GET_REPORTE_SOLSEGFIAN_EMPRESA = `
query getReporteSolSegFianEmpresa ($filtro : InputReporteEmpresaSolSegFian)
{
  getReporteSolSegFianEmpresa(filtro: $filtro)
  {
    empresaID
    nombre
    total
    color
  }
}`;

    export const TABLA_EMPRESA= `
        query filtro($filtro: InputFiltroEmpresa){
            filterEmpresa(filtro:$filtro){
            listado{
                empresaID,
                cuentaCteID,
                empresa,
                empresaCorto
                siglas
            }
            registros
            paginas
            }
        }`;


export const getEmpresa = async (filtro, token) => {
    try {
        let { getEmpresa } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_EMPRESA
        });

        return getEmpresa;

    } catch({message}) { throw new Error(message); }
}

export const getAllEmpresa = async (variables, token) => {
    try {

        let { getAllEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_EMPRESA
        });

        return getAllEmpresa;

    } catch({message}) { throw new Error(message); }
}

export const getReporteEmpresaSolSegFian= async (variables, token) => {
    try {

        let { getReporteEmpresaSolicitudes } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_REPORTE_EMPRESA_SOLICITUDES
        });

        return getReporteEmpresaSolicitudes;

    } catch({message}) { throw new Error(message); }
}
export const getReporteSolSegFianEmpresa= async (variables, token) => {   
    try {

        let { getReporteSolSegFianEmpresa } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_REPORTE_SOLSEGFIAN_EMPRESA
        });
           
        return getReporteSolSegFianEmpresa;

    }
    
    catch({message}) { throw new Error(message); }
}

export const seleccionEmpresa = async (variables, token) => {
    try {

        let allEmpresa = await getAllEmpresa(variables, token);

        if(!Array.isArray(allEmpresa)) throw new Error("No se encontraron registros");

        return allEmpresa.map(({ empresaID:value, siglas:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaEmpresa = async (variable, token) => {
    try{
        let {empresaID,cuentaCteID,empresa,empresaCorto, pagina, limite} = variable.filtro;
        let filtro = {empresaID: empresaID === null ? null : Number(empresaID), cuentaCteID,empresa,empresaCorto, pagina, limite};
        let variables={filtro};
        let {filterEmpresa} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_EMPRESA
        });
        return filterEmpresa;
    }catch({message}) { throw new Error(message); }
}
