import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_SINIESTROS_RECLAMADOS_ASEG = `
mutation addSiniestrosReclaAseg($input: InputAddSiniestrosReclaAseg!) {
    addSiniestrosReclaAseg(input: $input) {
              siniesReclAsegID
              empresaID
              estatus
              fechaAfectado
              siniestro
              aseguradoraID
              numPoliza
              deducible
              equipoBienAfectado
              descripEvento
              maquinaria
              operador
              ajustador
              fechaReclamacion
              indemnizaPagada
              fechaPago
              polizaID
              poliza
              ramoSeguroID
              ramoSeguro
              observaciones
              divisaID
              paridad
              subPolizaID
              activoID
    }
}
`;


export const UPDATE_SINIESTROS_RECLAMADOS_ASEG = `
mutation updateSiniestrosReclaAseg($input: InputUpdateSiniestrosReclaAseg!) {
    updateSiniestrosReclaAseg(input: $input) {
              siniesReclAsegID
              empresaID
              estatus
              fechaAfectado
              siniestro
              aseguradoraID
              numPoliza
              deducible
              equipoBienAfectado
              descripEvento
              maquinaria
              operador
              ajustador
              fechaReclamacion
              indemnizaPagada
              fechaPago
              polizaID
              poliza
              ramoSeguroID
              ramoSeguro
              observaciones
              subPolizaID
              activoID
    }
}
`;

export const DELETE_SINIESTROS_RECLAMADOS_ASEG = `
mutation deleteaSiniestrosReclaAseg($input: InputDeleteSiniestrosReclaAseg) {
    deleteSiniestrosReclaAseg(input: $input)
}
`;

export const APLICAR_PAGO_RECLAMADO_ASEGURADORA = `
mutation aplicarPagoReclamoAseguradora($input: InputAplicarPagoReclamoAseguradora) {
    aplicarPagoReclamoAseguradora(input: $input)
}
`;


export const addSiniestrosReclaAseg = async (variables, token) => {
    try {

        let { addSiniestrosReclaAseg } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SINIESTROS_RECLAMADOS_ASEG
        });

        return addSiniestrosReclaAseg;

    } catch ({ message }) { throw new Error(message); }
};

export const updateSiniestrosReclaAseg = async (variables, token) => {
    try {

        let { updateSiniestrosReclaAseg } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SINIESTROS_RECLAMADOS_ASEG
        });

        return updateSiniestrosReclaAseg;

    } catch ({ message }) { throw new Error(message); }
};


export const deleteSiniestrosReclaAseg = async (input, token) => {
    try {

        let { deleteSiniestrosReclaAseg } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: DELETE_SINIESTROS_RECLAMADOS_ASEG
        });

        return deleteSiniestrosReclaAseg;

    } catch ({ message }) { throw new Error(message); }
};


export const aplicarPagoReclamoAseguradora = async (input, token) => {
    try {

        let { aplicarPagoReclamoAseguradora } = await FetchAPI.graphql({
            token,
            variables: { input },
            url: urlServidor,
            query: APLICAR_PAGO_RECLAMADO_ASEGURADORA
        });

        return aplicarPagoReclamoAseguradora;

    } catch ({ message }) { throw new Error(message); }
};
