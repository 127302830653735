import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_EDIFICIO = `
query get($filtro: InputGetSolSegFianEdificio)
{
    getSolSegFianEdificio(filtro : $filtro)
  {
    edificioID
    usuarioBitID
    solsegfiaID
    empresaID
    empresa
    grupoID
    grupo
    bienBajoConvenio
    infraestructura
    descripcion
    areaM2
    valorFactura
    valorAsegurado
    divisaID
    divisa
    paridad
  }
}`;

export const GET_ALL_EDIFICIOS = `
query getAll($filtro: InputGetSolSegFianEdificio)
{
    getAllSolSegFianEdificio(filtro : $filtro)
  {
    edificioID
    usuarioBitID
    solsegfiaID
    empresaID
    empresa
    grupoID
    grupo
    bienBajoConvenio
    infraestructura
    descripcion
    areaM2
    valorFactura
    valorAsegurado
    divisaID
    divisa
    paridad
  }
}`;

export const TABLA_EDIFICIOS = `
query filter($filtro: InputFiltroSolSegFianEdificio)
{
    filterSolSegFianEdificio(filtro : $filtro)
  {
    listado{
        edificioID
        usuarioBitID
        solsegfiaID
        empresaID
        empresa
        grupoID
        grupo
        bienBajoConvenio
        infraestructura
        descripcion
        areaM2
        valorFactura
        valorAsegurado
        divisaID
        divisa
        paridad
    }
    paginas
    registros
  }
}`;

export const getEdificio = async (filtro, token) => {
    try {
        let { getSolSegFianEdificio } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_EDIFICIO
        });

        return getSolSegFianEdificio;

    } catch({message}) { throw new Error(message); }
}

export const getAllEdificios = async (variables, token) => {
    try {

        let { getAllSolSegFianEdificio } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_EDIFICIOS
        });

        return getAllSolSegFianEdificio;

    } catch({message}) { throw new Error(message); }
}

export const tablaEdificios = async (variables, token) => {
    try{
        let {filterSolSegFianEdificio} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_EDIFICIOS
        });
        return filterSolSegFianEdificio;
    }catch({message}) { throw new Error(message); }
}
