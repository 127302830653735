import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_ACTIVIDADES_TAREAS = `
mutation addActividadesDeTareas($input: InputAddActividadesDeTareas!) {
    addActividadesDeTareas(input: $input) {
            actividadID
            tareaID
            usuarioID
            usuarioBitID
            descripcion
            responsable
            porcentaje
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            responsableID
            usuario
            ramoSeguro
           
    }
}
`;


export const UPDATE_ACTIVIDADES_TAREAS = `
mutation updateActividadesDeTareas($input: InputUpdateActividadesDeTareas) {
    updateActividadesDeTareas(input: $input) {
            actividadID
            tareaID
            usuarioID
            usuarioBitID
            descripcion
            responsable
            porcentaje
            estado
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            responsableID
            usuario
            ramoSeguro
       
    }
}
`;

export const DELETE_ACTIVIDADES_TAREAS = `
mutation deleteActividadesDeTareas($input: InputDeleteActividadesDeTareas) {
    deleteActividadesDeTareas(input: $input)
}
`;


export const addActividadesDeTareas = async (variables, token) => {
    try {
       
        let { addActividadesDeTareas } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_ACTIVIDADES_TAREAS
        });

        return addActividadesDeTareas;

    } catch ({ message }) { throw new Error(message); }
};

export const updateActividadesDeTareas = async (variables, token) => {
    try {
      
        let { updateActividadesDeTareas } = await FetchAPI.graphql({
            token,
            variables:variables,
            url: urlServidor,
            query: UPDATE_ACTIVIDADES_TAREAS
        });

        return updateActividadesDeTareas;

    } catch ({ message }) { throw new Error(message); }
};


export const deleteActividadesDeTareas = async (input, token) => {
    try {

        let { deleteActividadesDeTareas } = await FetchAPI.graphql({
            token,
            variables: {input },
            url: urlServidor,
            query: DELETE_ACTIVIDADES_TAREAS
        });

        return deleteActividadesDeTareas;

    } catch ({ message }) { throw new Error(message); }
};
