import React from 'react'

import CambiarPassword from '../../../../_layout/masterComponents/Login/CambiarPassword';

import loginFondo from '../../../../_layout/assets/img/lock.jpeg';

import { rgxPassword } from '../../../../helpers/regexp';

import { isToken } from '../../../../querys/Login/metodos';
import { updatePassword } from '../../../../mutations/Login/metodos';
import store from '../../../../redux';
import { Provider } from 'react-redux';
function ActualizarPassword() {

    return (
        <Provider store={store}>

        <CambiarPassword
            isToken={isToken}
            loginFondo={loginFondo}
            rgxPassword={rgxPassword}
            updatePassword={updatePassword}
        />
        </Provider>
    )

}

export default ActualizarPassword;
