import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getAgentesSeguro, tablaAgentesSeguro } from '../../../querys/AgentesSeguros';
import { deleteAgentesSeguro, addAgentesSeguro, updateAgentesSeguro } from '../../../mutations/AgentesSeguros';


import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import * as Permisos from '../Permisos';

import { letrasMod } from '../../../helpers/regexp';

const { trim } = Validaciones;

function AgentesSeguros() {
    const { token } = useSelector(state => state.login);
    const cleanState = {
        agenteSegID: null,
        agenteSeg: ""
    };
    const cleanErrorState = {
        agenteSeg: { error: false, helperText: "" }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    let titleAccion = state.agenteSegID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Agente de Seguro`;

    const proyectoIDRef = useRef(null);
    const agenteSegRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "agenteSegID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getAgentesSeguro
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "agenteSegID" },
        { campo: "agenteSeg" }],
        disabled: { multiseleccion: true },
        onClick: ({ agenteSegID, agenteSeg }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el agente de seguro ${agenteSeg}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { agenteSegID }, asyncDelete: deleteAgentesSeguro
            })
        })
    }];

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "agenteSegID",
        color: primary,
        title: "Agentes de Seguro",
        iconTable: <AccountBoxOutlinedIcon />,
        headers: [{ variable: "agenteSegID", descripcion: "ID", hide: true },
        { variable: "agenteSeg", descripcion: "Agentes de Seguro" }
        ],
        responsiveTitle: ["agenteSeg"],
        filter: [
            { campo: "agenteSeg" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [{
        id: "Arriba",
        tipo: "fieldset",
        grid: { lg: 12 },
        inputs: [
            {
                disabled,
                id: "agenteSeg",
                value: state.agenteSeg,
                error: errorState.agenteSeg.error,
                success: state.agenteSeg && !errorState.agenteSeg.error ? true : undefined,
                helperText: errorState.agenteSeg.helperText,
                inputRef: agenteSegRef,
                title: "Agente de Seguro *",
                placeholder: "Capture el agente de seguro",
                grid: { md: 12, lg: 12 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "agenteSeg")
                },
                onKeyDown: () => { return; }
            }
        ]
    }
    ];


    let accionesFormulario = [{
        accionID: state.agenteSegID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: agenteSegRef,
            mensajeCorrecto: `El agente se ${state.agenteSegID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ agenteSeg }) {

        let error;
        let validaciones = [
            {
                condicion: !agenteSeg || trim(agenteSeg) === "",
                ref: agenteSegRef, keyError: "agenteSeg",
                mensajeError: "Debe especificar el agente"
            },
            {
                condicion: agenteSeg.length > 50,
                ref: agenteSegRef, keyError: "agenteSeg",
                mensajeError: "Número de caracteres capturados excedido."
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { agenteSegID,
                agenteSeg } = state;

            let error = await handleValidaciones({
                agenteSeg
            });

            if (error) return error;

            if (agenteSegID) await updateAgentesSeguro({
                input: { agenteSegID, agenteSeg: trim(agenteSeg) }
            }, token);
            else await addAgentesSeguro({
                input: { agenteSeg: trim(agenteSeg) }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaAgentesSeguro}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={agenteSegRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default AgentesSeguros;
