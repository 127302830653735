import React, { useEffect } from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from "@material-ui/core/FormHelperText";

import classNames from "classnames";

// core components
import Button from "../../components/CustomButtons/Button.js";

import defaultImage from "../../assets/img/image_placeholder.jpg";
import defaultAvatar from "../../assets/img/placeholder.jpg";
import styles from "../../assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const useStyles = makeStyles(styles);

export default function ImageUpload(props) {
    const classes = useStyles();
    const { id, error, success, helperText } = props;
    const [file, setFile] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
        props.avatar ? defaultAvatar : defaultImage
    );
    let fileInput = React.createRef();


    const handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            setFile(file);
            if (props.onChange) props.onChange(file, reader.result);
            setImagePreviewUrl(reader.result);
        };
        if(file){
            reader.readAsDataURL(file);
        }
    };


    // eslint-disable-next-line
    let helpTextClasses = classNames({
        [classes.labelRootError]: error,
        [classes.labelRootSuccess]: success && !error
    });

    // const handleSubmit = e => {
    //     e.preventDefault();
    //     // file is the file/image uploaded
    //     // in this function you can save the image (file) on form submit
    //     // you have to call it yourself
    // };
    const handleClick = () => {
        fileInput.current.click();
    };
    const handleRemove = () => {
        setFile(null);
        if (props.onChange) props.onChange(null);
        setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
        fileInput.current.value = null;
    };
    let {
        avatar,
        addButtonProps,
        changeButtonProps,
        removeButtonProps
    } = props;

    function ftEffect() {
        //if (!props.removeImage && file) handleRemove();
        if (props.removeImage) handleRemove();
    }

    useEffect(ftEffect, [props.removeImage]);

    return (
        <div className="fileinput text-center">
            <input type="file" onChange={handleImageChange} ref={fileInput} accept="image/*" />
            <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
                <img id='imgUpload'
                    src={props.value ? props.value : imagePreviewUrl}
                    alt="..."
                />
            </div>
            <div>
                {file === null && !props.value ? (
                    <Button
                        {...addButtonProps}
                        disabled={props.disabled}
                        onClick={() => handleClick()}
                    >
                        {avatar ? "Agregar Foto" : "Seleccionar imagen"}
                    </Button>
                ) : (
                    <span>
                        <Button
                            {...changeButtonProps}
                            disabled={props.disabled}
                            onClick={() => handleClick()}
                        >
                            Cambiar
                        </Button>
                        {avatar ? <br /> : null}
                        <Button
                            {...removeButtonProps}
                            disabled={props.disabled}
                            onClick={() => handleRemove()}
                        >
                            <i className="fas fa-times" /> Remover
                        </Button>
                    </span>
                )}
            </div>
            {error && helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                    {helperText}
                </FormHelperText>
            ) : null}
        </div>
    );
}

ImageUpload.propTypes = {
    avatar: PropTypes.bool,
    addButtonProps: PropTypes.object,
    changeButtonProps: PropTypes.object,
    removeButtonProps: PropTypes.object
};
