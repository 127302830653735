/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import { Validaciones, Encriptar } from '@renedelangel/helpers';

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
//componentes genericos
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import { handleFocus, handleErrorInputText, handleGuardar } from '../../../_layout/helpers/handles';
import SaveIcon from '@material-ui/icons/Save';
import { info, danger } from '../../../_layout/helpers/colores';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { getAllSeguimientoActividades } from '../../../querys/SeguimientoActividades';
import { updateSeguimientoActividades, addSeguimientoActividades } from '../../../mutations/SeguimientoActividades';


export default function SeguimientoReclamos() {
    const { token } = useSelector(state => state.login);
    const { trim } = Validaciones;
    const localizer = momentLocalizer(moment);
    const { Decrypt } = Encriptar;
    let history = useHistory();
    let { params } = useParams();
    let auxTitleSeguimiento = "";
    let auxDescripEvento = "";
    let auxSiniesReclAsegID;
    let auxSiniesReclTercerosID;
    let auxTipo;

    if (params != undefined) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";

        let { cSiniesReclAsegID, cSiniesReclTercerosID, cTitleSeguimiento, cDescripEvento, stateReclamo } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;

        auxTitleSeguimiento = cTitleSeguimiento;
        auxDescripEvento = cDescripEvento;
        auxSiniesReclAsegID = cSiniesReclAsegID;
        auxSiniesReclTercerosID = cSiniesReclTercerosID;
        auxTipo = stateReclamo;
    }

    const cleanState = {
        seguimiento: "",
        fechaInicioReal: "",
        fechaFinalReal: "",
        horaInicial: "",
        horaFinal: ""
    };

    const cleanErrorState = {
        seguimiento: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" },
        horaInicial: { error: false, helperText: "" },
        horaFinal: { error: false, helperText: "" },
    };

    const [stateTitulo, setStateTitulo] = useState(auxTitleSeguimiento);
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);

    const [alert, setAlert] = useState(null);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [actualizar, setActualizar] = useState(false);
    const [view, setView] = useState("month");
    let titleAccion = state.seguimientoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Actividad/Evento/Seguimiento`;
    const fechaInicialRealRef = useRef(null);
    const fechaFinalRealRef = useRef(null);
    const seguimientoRef = useRef(null);
    const horaInicialRef = useRef(null);
    const horaFinalRef = useRef(null);
    const selectedEvent = evento => {
        setState((state) => ({ ...state, fechaInicioReal: moment(evento.start), fechaFinalReal: moment(evento.end), seguimiento: evento.seguimiento, seguimientoID: evento.seguimientoID, finalizarSeguimiento: evento.finalizarSeguimiento, color: evento.color, folioTarea: evento.folioTarea, horaInicial: moment(evento.start), horaFinal: moment(evento.end) }));
        setOpen(true);
    };

    const eventColors = event => {
        let backgroundColor = "event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        }
    };

    const inputs = (view === 'day' || view === 'week') ? [
        {
            disabled: true,
            id: "seguimiento",
            value: state.seguimiento,
            error: errorState.seguimiento.error,
            success: state.seguimiento && !errorState.seguimiento.error ? true : undefined,
            helperText: errorState.seguimiento.helperText,
            inputRef: seguimientoRef,
            title: "Seguimiento *",
            multiline: true,
            placeholder: "Capture el seguimiento",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "seguimiento")
            },
            onKeyDown: () => { return; }
        },
        {
            disabled: true,
            id: "fechaInicioReal",
            value: state.fechaInicioReal,
            error: errorState.fechaInicioReal.error,
            success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
            helperText: errorState.fechaInicioReal.helperText,
            inputRef: fechaInicialRealRef,
            placeholder: "Fecha Inicial",
            tipo: "datetimepicker",
            grid: { md: 3, lg: 3 },
        },
        {
            disabled: true,
            id: "fechaFinalReal",
            value: state.fechaFinalReal,
            error: errorState.fechaFinalReal.error,
            success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
            helperText: errorState.fechaFinalReal.helperText,
            inputRef: fechaFinalRealRef,
            placeholder: "Fecha Final",
            tipo: "datetimepicker",
            grid: { md: 3, lg: 3 },
        },
        {
            disabled: true,
            id: "finalizarSeguimiento",
            value: state.finalizarSeguimiento,
            checked: state.finalizarSeguimiento,
            title: "¿Finalizar Seguimiento?",
            tipo: "switch",
            grid: { md: 3, lg: 3 },
            onChange: (e) => {
                setState({ ...state, finalizarSeguimiento: e.target.checked })
            }
        }
    ] :

        [
            {
                disabled: true,
                id: "seguimiento",
                value: state.seguimiento,
                error: errorState.seguimiento.error,
                success: state.seguimiento && !errorState.seguimiento.error ? true : undefined,
                helperText: errorState.seguimiento.helperText,
                inputRef: seguimientoRef,
                title: "Seguimiento *",
                multiline: true,
                placeholder: "Capture el seguimiento",
                grid: { md: 12, lg: 12 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "seguimiento")
                },
                onKeyDown: () => { return; }
            },
            {
                disabled: true,
                id: "fechaInicioReal",
                value: state.fechaInicioReal,
                error: errorState.fechaInicioReal.error,
                success: state.fechaInicioReal && !errorState.fechaInicioReal.error ? true : undefined,
                helperText: errorState.fechaInicioReal.helperText,
                inputRef: fechaInicialRealRef,
                placeholder: "Fecha Inicial",
                tipo: "datetimepicker",
                onlyDate: true,
                grid: { md: 3, lg: 3 }
            },
            {
                disabled: true,
                id: "horaInicial",
                value: state.horaInicial,
                error: errorState.horaInicial.error,
                success: state.horaInicial && !errorState.horaInicial.error ? true : undefined,
                helperText: errorState.horaInicial.helperText,
                inputRef: horaInicialRef,
                placeholder: "Hora Inicial",
                tipo: "datetimepicker",
                onlyTime: true,
                grid: { md: 3, lg: 3 },
                onChange: (value) => { typeof value === "object" && handleChange(moment(value), "horaInicial") }
            },
            {
                disabled: true,
                id: "fechaFinalReal",
                value: state.fechaFinalReal,
                error: errorState.fechaFinalReal.error,
                success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
                helperText: errorState.fechaFinalReal.helperText,
                inputRef: fechaFinalRealRef,
                placeholder: "Fecha Final",
                tipo: "datetimepicker",
                onlyDate: true,
                grid: { md: 3, lg: 3 }
            },
            {
                disabled: true,
                id: "horaFinal",
                value: state.horaFinal,
                error: errorState.horaFinal.error,
                success: state.horaFinal && !errorState.horaFinal.error ? true : undefined,
                helperText: errorState.horaFinal.helperText,
                inputRef: horaFinalRef,
                placeholder: "Hora Final",
                tipo: "datetimepicker",
                onlyTime: true,
                grid: { md: 3, lg: 3 },
                onChange: (value) => { typeof value === "object" && handleChange(moment(value), "horaFinal") }
            },
            {
                disabled: true,
                id: "finalizarSeguimiento",
                value: state.finalizarSeguimiento,
                checked: state.finalizarSeguimiento,
                title: "¿Finalizar Seguimiento?",
                tipo: "switch",
                grid: { md: 3, lg: 3 },
                onChange: (e) => {
                    setState({ ...state, finalizarSeguimiento: e.target.checked })
                }
            },
        ];

    const accionesFormulario = [];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ seguimiento, horaInicial, horaFinal }) {
        let error;
        let validaciones = view === 'day' || view === 'week' ? [
            {
                condicion: !seguimiento || trim(seguimiento) === "",
                ref: seguimientoRef, keyError: "seguimiento",
                mensajeError: "Debe especificar el seguimiento"
            }
        ] :
            [
                {
                    condicion: !seguimiento || trim(seguimiento) === "",
                    ref: seguimientoRef, keyError: "seguimiento",
                    mensajeError: "Debe especificar el seguimiento"
                },
                {
                    condicion: !horaInicial || trim(horaInicial) === "",
                    ref: horaInicialRef, keyError: "horaInicial",
                    mensajeError: "Debe especificar la hora inicial"
                },
                {
                    condicion: !horaFinal || trim(horaFinal) === "",
                    ref: horaFinalRef, keyError: "horaFinal",
                    mensajeError: "Debe especificar la hora final"
                },
            ]
            ;
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });
        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            let { seguimientoID, fechaInicioReal, fechaFinalReal, seguimiento, finalizarSeguimiento, horaInicial, horaFinal } = state;
            let error = handleValidaciones({
                seguimiento,
                horaInicial,
                horaFinal
            });

            if (error) return error;

            let resultado;
            if (seguimientoID) {
                resultado = await updateSeguimientoActividades({
                    input: {
                        seguimientoID,
                        seguimiento,
                        fechaInicioReal: view === 'day' || view === 'week' ? fechaInicioReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaInicioReal.format("YYYY-MM-DD")}T${horaInicial.format('LT')}`,
                        fechaFinalReal: view === 'day' || view === 'week' ? fechaFinalReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaFinalReal.format("YYYY-MM-DD")}T${horaFinal.format('LT')}`,
                        finalizarSeguimiento,
                        siniesReclAsegID: parseInt(auxSiniesReclAsegID),
                        siniesReclTercerosID: parseInt(auxSiniesReclTercerosID),
                        tipo: parseInt(auxTipo)
                    }
                }, token);
            }
            else {
                resultado = await addSeguimientoActividades({
                    input: {
                        seguimiento,
                        fechaInicioReal: view === 'day' || view === 'week' ? fechaInicioReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaInicioReal.format("YYYY-MM-DD")}T${horaInicial.format('LT')}`,
                        fechaFinalReal: view === 'day' || view === 'week' ? fechaFinalReal.format("YYYY-MM-DDTHH:mm:ss") : `${fechaFinalReal.format("YYYY-MM-DD")}T${horaFinal.format('LT')}`,
                        finalizarSeguimiento,
                        siniesReclAsegID: parseInt(auxSiniesReclAsegID),
                        siniesReclTercerosID: parseInt(auxSiniesReclTercerosID),
                        tipo: parseInt(auxTipo)
                    }
                }, token);
            }
        }
        return ftGuardar();
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let eventos = await getAllSeguimientoActividades({ filtro: { siniesReclAsegID: parseInt(auxSiniesReclAsegID), siniesReclTercerosID: parseInt(auxSiniesReclTercerosID) } }, token);
                setSeleccionables({ eventosDB: eventos });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Error: ${message}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    function ftRegresar() {
        history.goBack();
    }

    return (
        <>
            <div style={{
                color: "#ffffff", background: "#9c27b0", width: "80px", padding: "8px", border: "none",
                borderRadius: "4px", cursor:'pointer'
            }} onClick={ftRegresar}>
                Regresar
            </div>
            <container>
                <h4>
                    <strong> Titulo de Siniestro:</strong> {stateTitulo}
                </h4>
                <h4>
                    <b>Descripción del evento: </b> {auxDescripEvento}
                </h4>
                <BigCalendar
                    selectable
                    popup
                    localizer={localizer}
                    events={seleccionables.eventosDB || []}
                    showMultiDayTimes={false}
                    defaultView="month"
                    scrollToTime={new Date(2000, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={event => selectedEvent(event)}
                    eventPropGetter={eventColors}
                    views={['month', 'week', 'day']}
                    messages={{ next: 'Siguiente', previous: 'Anterior', today: 'Hoy', month: 'Mes', week: 'Semana', day: 'Día' }}
                    onView={(view) => setView(view)} // para obtener la vista seleccionada ['month', 'week', 'day']
                />
                {open &&
                    <FormularioModal
                        open={open}
                        title={titleModal}
                        onClose={handleClose}
                        notificaciones={notificaciones}
                        closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                        inputs={inputs}
                        acciones={accionesFormulario}
                        focus={fechaInicialRealRef}
                    />
                }
                {alert &&
                    <SweetAlert
                        title={alert.title}
                        descripcion={alert.descripcion}
                        tipo={alert.tipo}
                        msjConfirmacion={alert.msjConfirmacion}
                        msjCancelacion={alert.msjCancelacion}
                        onConfirm={alert.onConfirm}
                        showConfirm={alert.showConfirm}
                        showCancel={alert.showCancel}
                        onCancel={() => setAlert(null)}
                    />
                }
            </container>
        </>);
}
