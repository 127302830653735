import ExtendedForms from "_layout/views/Forms/ExtendedForms";
import { FileOpener } from '@ionic-native/file-opener';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

// Url's
export const urlServidor = process.env.REACT_APP_URL_SERVER || "http://localhost:3500";
export const urlServidorSAT = process.env.REACT_APP_URL_SAT ||"http://localhost:4000";
export const strUrlSArchivos = process.env.REACT_APP_URL_MEDIA || "http://localhost:4100";
export const empresa = "empresa";
// "https://tytsoftwaredemexico.com.mx:4000";

// Semilla
export const seed = process.env.SEED || "Y2FwZXJ1Y2l0YXJvamE=";
export const seedBackend = process.env.SEED_BACKEND || "c29mdHdvbmVAc2VndW1leA==";

// LocalStorage
export const TOKEN = "sesionToken";
export const USUARIO = "datosUsuario";
export const FECHASFILTRO = "fechasFiltro";

export const showFile = (archivo, name = 'file.pdf',extension) => {

    let blob = b64toBlob(archivo, extension);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.setAttribute("download", name);
    a.download = name;
    a.click();
   // a.remove();


};
export async function b64tofile(b64, nombre, extension){

    let resultado ='';

    let blob = b64toBlob(b64, extension);
    resultado = new File([blob], nombre, { type: extension });

    return resultado;

 }
export function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
}

export async function convertToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
  };
  
  
  export function verifyIfExistsFile(item, list) {
    let cont = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i].includes(item)) {
        cont++;
      }
    }
    return cont;
  }

export async function dowloadFile(data,nombreArchivo,tipo){

    const auxNombreArchivo = nombreArchivo.replace(/^(.+)(\.)([a-zA-Z]{1,5})$/g, "$1$2").slice(0,-1);
    const ext = nombreArchivo.replace(/^(.+)(\.)([a-zA-Z]{1,5})$/g, "$3");
  
    let archivos = await Filesystem.readdir({
      path: '',
      directory: Directory.Documents
    });
  
    let resFile = '';
  
    let numArchivos = verifyIfExistsFile(`${auxNombreArchivo}`, archivos.files);
  
    resFile = await Filesystem.writeFile({
      path: numArchivos === 0 ? nombreArchivo : `${auxNombreArchivo}(${numArchivos}).${ext}`,
      data,
      directory: Directory.Documents
    });

    
    return await FileOpener.open(resFile.uri, tipo)
  }

  export function ftFormatoImportes(num) {
    if (num === null || num === undefined){
        return "";
    }

    if(isNaN(num)){
        return "";
    }
    let tSplit = num.toString().split(".");
    let valorNum = "";

    if (tSplit.length === 0){
        valorNum = "";
        return valorNum;
    }

    let aux = tSplit[0];
    let newVal = "";
    let countAux = 0;

    for (let i = aux.length-1; i >= 0; i--) {
        if (countAux === 3) {
            newVal = aux[i] + "," + newVal;
            countAux = 1;
            continue;
        }
        newVal = aux[i] + newVal;
        countAux++;
    }

    valorNum = newVal;
    if (tSplit.length > 1) {
        valorNum = valorNum + "." + tSplit[1];
    }

    return valorNum;
}

export function toFixed(num, fixed) {
  if(num == null)
      {return '0'}
      
  let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

export const redondeo = (valor, decimales) => {
  let resultado = valor.toFixed(decimales);
  return resultado;
}

export async function fetchRestAPI ({ data, token, method = "POST", url }) {
  try {
    let formData = new FormData();
    let keys = Object.keys(data);
    keys.forEach(key => { formData.append(key, data[key]); });
    let rh = await fetch( `${urlServidor}/${url}`, {
        method,
        body: formData,
        headers: { token }
    }).then(res => res.json());

      if(!rh.ok) throw new Error(rh.mensaje);

      return rh;

  } catch({message}) { throw new Error(message); }
};