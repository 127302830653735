import React, { useState } from 'react';

function SeccionI({ setRespuesta, disabled, respuesta }) {

  if (respuesta) {
    // disabled = true;
    respuesta = JSON.parse(respuesta);
  }else{
    respuesta ={
      seccionI: "",
      seccionII: "",
      seccionIII:""
    }
  }
  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);
  function handleChangeValue(value,name){
    let respuestaAux = {...stRespuestasLocal};
    respuestaAux[name] = value;
    setRespuestasLocal(respuestaAux)
    setRespuesta(JSON.stringify(respuestaAux))
  }
  return (<>
      <div className="cContenedorSeccion">
        <div className="ElementoPregunta">
          <div className="Pregunta">
            I. Razón social y dirección completa del principal solicitante y compañías afiliadas:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.seccionI} onChange={event => handleChangeValue(event.target.value,"seccionI")} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cContenedorSeccion">
        <div className="ElementoPregunta">
          <div className="Pregunta">
            II. Por favor indique el tipo de construcción, tamaño, edad, cantidad de pisos y valor de sustitución de cada estructura – edificio de oficinas, almacenes, recintos, etc.- utilizada por el solicitante en sus operaciones:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.seccionII} onChange={event => handleChangeValue(event.target.value,"seccionII")} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cContenedorSeccion">
        <div className="ElementoPregunta">
          <div className="Pregunta">
            III. Incluir la última memoria anual, así como folletos o documentación que describan el tipo de operación que realiza el solicitante:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.seccionIII} onChange={event => handleChangeValue(event.target.value,"seccionIII")} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
  </>);

}
export default SeccionI;