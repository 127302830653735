import React, { useEffect, useState } from "react";
import './gantt.css';

function Gantt({ ejercicio, folioInterno, tareasPrograma, vistaSemanal = false, ComparativaProgVSReal = false, mes = 0, setInfoGantt }) {

    const [stInfoGantt, setInfoGanttLocal] = useState({ fechaInicial: new Date(), fechaFinal: new Date() });
    const [stVista, setVista] = useState("");
    let anchoTareas = 300;
    let anchoFechas = 80;
    let anchoRamoSegumo = 200;
    let anchoEmpresa = 80;

    let anchoRowColumnas = anchoTareas + anchoRamoSegumo + anchoEmpresa + (anchoFechas * (ComparativaProgVSReal ? 4 : 2));
    let anchoCeldaDias = 20;
    let anchoCeldaSemana = 14;
    let anchoCeldaHoras = 22;

    let altoCelda = 45;
    let fontSize = 12;

    let diaSemanaInhabil = [];
    let diasInhabiles = ['2021-01-01', '2021-02-12'];

    let root = document.documentElement;
    root.style.setProperty('--altoCelda', altoCelda + "px");
    root.style.setProperty('--anchoTareas', anchoTareas + "px");
    root.style.setProperty('--anchoFechas', anchoFechas + "px");
    root.style.setProperty('--fontSize', fontSize + "px");

    /*-----------------------------------------------*/
    let Actividad1 = {
        actividadID: 1,
        descripcion: 'Actividad 1',
        fechaInicio: '2021-01-10',
        fechaFinal: '2021-01-12',
        fechaInicioReal: '2021-01-10',
        fechaFinalReal: '2021-01-14',
        avance: 30
    }

    let Actividad2 = {
        actividadID: 1,
        descripcion: 'Actividad 2',
        fechaInicio: '2021-01-10',
        fechaFinal: '2021-01-12',
        fechaInicioReal: '2021-01-10',
        fechaFinalReal: '2021-01-14',
        avance: 50
    }

    let Actividad3 = {
        actividadID: 3,
        descripcion: 'Actividad 3',
        fechaInicio: '2021-02-01',
        fechaFinal: '2021-02-12',
        fechaInicioReal: '2021-02-02',
        fechaFinalReal: '2021-02-14',
        avance: 90
    }

    let Actividad4 = {
        actividadID: 4,
        descripcion: 'Actividad 4',
        fechaInicio: '2021-03-01',
        fechaFinal: '2021-03-07',
        fechaInicioReal: '2021-03-08',
        fechaFinalReal: '2021-03-15',
        avance: 95
    }

    let tTarea1 = {
        tareaID: 1,
        descripcion: 'Actividades de gestión y control de auxiliares',
        fechaInicio: '2021-01-01',
        fechaFinal: '2021-01-12',
        fechaInicioReal: '2021-01-01',
        fechaFinalReal: '2021-01-14',
        avance: 45,
        actividades: [Actividad1, Actividad2]
    }

    let tTarea2 = {
        tareaID: 2,
        descripcion: 'Actualización y capacitación de personal del area de mantenimiento',
        fechaInicio: '2021-02-01',
        fechaFinal: '2021-02-15',
        fechaInicioReal: '2021-03-01',
        fechaFinalReal: '2021-03-20',
        avance: 80,
        actividades: [Actividad3, Actividad4]
    }

    //let tareas = [tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea2, tTarea1, tTarea2, tTarea1, tTarea2, tTarea1];
    /*-----------------------------------------------*/
    let tareas = tareasPrograma ? tareasPrograma : [];
    let Ejercicio = ejercicio;
    let Folio = folioInterno;
    let fechaInicial, fechaFinal;
    function CargarVista() {
        let localMes = mes;
        if (mes === 0) {
            localMes = 2;
        }
        let fechaHoy = new Date();

        //fechaInicial = new Date(fechaHoy.getFullYear() + "-" + localMes + "-01");

        //Estaba causando error con una fecha no definida
        /*  fechaInicial = new Date(fechaHoy.getFullYear() + " - " + localMes + " - 01"); */


        for (let i = 0; i < tareas.length; i++) {
            let tarea = tareas[i];
            /*if (!fechaInicial) {
                fechaInicial = toDate(tarea.fechaInicio);
            }
            if (!fechaFinal) {
                fechaFinal = toDate(tarea.fechaFinal);
            }

            let fechaInicioAux = toDate(tarea.fechaInicio);
            let fechaFinalAux = toDate(tarea.fechaFinal);

            if (fechaInicial.getTime() > fechaInicioAux.getTime()) {
                fechaInicial = new Date(fechaInicioAux);
            }
            if (fechaFinal.getTime() < fechaFinalAux.getTime()) {
                fechaFinal = new Date(fechaFinalAux);
            }

            fechaInicioAux = toDate(tarea.fechaInicioReal);
            fechaFinalAux = toDate(tarea.fechaFinalReal);

            if (fechaInicial.getTime() > fechaInicioAux.getTime()) {
                fechaInicial = new Date(fechaInicioAux);
            }
            if (fechaFinal.getTime() < fechaFinalAux.getTime()) {
                fechaFinal = new Date(fechaFinalAux);
            }*/

            let fechaInicioAux;
            let fechaFinalAux;
            let actividades = tarea["actividades"];
            for (let j = 0; j < actividades.length; j++) {
                const actividad = actividades[j];

                if (!fechaInicial) {
                    fechaInicial = toDate(actividad.fechaInicio);
                }
                if (!fechaFinal) {
                    fechaFinal = toDate(actividad.fechaFinal);
                }

                fechaInicioAux = toDate(actividad.fechaInicio);
                fechaFinalAux = toDate(actividad.fechaFinal);

                if (actividad.fechaInicio) {
                    fechaInicioAux = toDate(actividad.fechaInicio);
                }
                if (actividad.fechaFinal) {
                    fechaFinalAux = toDate(actividad.fechaFinal);
                }

                if (fechaInicial.getTime() > fechaInicioAux.getTime()) {
                    fechaInicial = new Date(fechaInicioAux);
                }

                if (fechaFinal.getTime() < fechaFinalAux.getTime()) {
                    fechaFinal = new Date(fechaFinalAux);
                }
                fechaInicioAux = fechaInicial;
                fechaFinalAux = fechaFinal;

                if (actividad.fechaInicioReal) {
                    fechaInicioAux = toDate(actividad.fechaInicioReal);
                }

                if (actividad.fechaFinalReal) {
                    fechaFinalAux = toDate(actividad.fechaFinalReal);
                }

                if (fechaInicial.getTime() > fechaInicioAux.getTime()) {
                    fechaInicial = fechaInicioAux;
                }

                if (fechaFinal.getTime() < fechaFinalAux.getTime()) {
                    fechaFinal = fechaFinalAux;
                }
            }
        }

        if (!fechaInicial) {
            //fechaInicial = toDate(fechaHoy);
            fechaInicial = new Date(fechaHoy.getFullYear() + "-" + localMes + "-01");
        }

        if (!fechaFinal) {
            fechaFinal = fechaInicial;
        }

        fechaInicial = new Date(fechaInicial.getFullYear(), fechaInicial.getMonth(), 1);
        fechaFinal = getEndMonth(fechaFinal);
        setInfoGanttLocal({ fechaInicial, fechaFinal });
        /*
        weekday[0] = "Domingo";
        weekday[1] = "Lunes";
        weekday[2] = "Martes";
        weekday[3] = "Miércoles";
        weekday[4] = "Jueves";
        weekday[5] = "Viernes";
        weekday[6] = "Sábado";
        */

        diaSemanaInhabil = [];

        let contenedor = `<div class="contenedor">`;
        let contenedorGrafica = `<div class="contenedorGrafica">`;
        let rowTitulos = `<div class="row">`;
        let celdatitulosColumnassticky = `<div class="celda titulos Columnas sticky sTop" style="width: ${anchoRowColumnas}px; top: 0; z-index: 2;"> ${Ejercicio} - ${Folio}\n</div> \n`;

        let rowTitulos2 = `<div class="row">`;
        let celdaSticky = `<div class="celda sticky sTop" style="top: ${altoCelda}px; z-index: 2;">
                            <div class="row Columnas">
                                <div class="celda titulos tarea" style="width: ${anchoTareas}px">
                                    Tareas
                                </div>
                                <div class="celda titulos" style="width: ${anchoEmpresa}px">
                                    Empresa
                                </div>
                                <div class="celda titulos" style="width: ${anchoRamoSegumo}px">
                                    Ramo de seguro
                                </div>
                                <div class="celda titulos inicio" style="width: ${anchoFechas}px">
                                    Inicio Programado
                                </div>
                                <div class="celda titulos fin" style="width: ${anchoFechas}px">
                                    Fin Programado
                                </div>
                                <div class="celda titulos inicio ${ComparativaProgVSReal ? '' : 'Oculto'}" style="width: ${anchoFechas}px">
                                    Inicio Real
                                </div>
                                <div class="celda titulos fin ${ComparativaProgVSReal ? '' : 'Oculto'}"  style="width: ${anchoFechas}px">
                                    Fin Real
                                </div>
                            </div>
                        </div>`;

        let fechaInicialAux = new Date(fechaInicial.getTime());
        let mesActual = -1;
        let celdaRow = "";
        let celdasDiasVacios = [];
        let conteoDias = 1;

        while (fechaInicialAux.getTime() <= fechaFinal.getTime()) {
            if (vistaSemanal) {
                if (conteoDias === 1) {
                    mesActual = fechaInicialAux.getMonth();

                    let diasMes = getEndMonth(fechaInicialAux).getDate();
                    let anchoTitulo = diasMes * anchoCeldaDias;
                    if (vistaSemanal) {
                        anchoTitulo = 7 * anchoCeldaSemana;
                    }

                    let tDia = fechaInicialAux.getDate().toString();
                    if (tDia.length === 1) {
                        tDia = '0' + tDia;
                    }
                    let tituloSemana = getMes(mesActual, true) + " " + tDia + ", " + fechaInicialAux.getFullYear();
                    celdatitulosColumnassticky = celdatitulosColumnassticky + `<div class="celda titulos sticky sTop" style="width: ${anchoTitulo}px; top: 0; z-index: 1; left: auto">\n ${tituloSemana}\n </div>\n`;

                    let celdasInternas = '';
                    let celdasInternasVacias = '';

                    celdasInternas = celdasSemanas(fechaInicialAux, true);
                    celdasInternasVacias = celdasSemanas(fechaInicialAux, false);

                    celdasDiasVacios.push(celdasInternasVacias);
                    celdaRow = `<div class="celda sticky sTop" style="top: ${altoCelda}px; z-index: 1; left: auto">
                                    <div class="row">
                                        ${celdasInternas}
                                    </div>
                                </div>`;
                    celdaSticky = celdaSticky + celdaRow;
                }
                conteoDias++;
                if (conteoDias > 7) {
                    conteoDias = 1;
                }
            } else {
                if (mesActual !== fechaInicialAux.getMonth()) {
                    mesActual = fechaInicialAux.getMonth();
                    let diasMes = getEndMonth(fechaInicialAux).getDate();
                    let anchoTitulo = diasMes * anchoCeldaDias;
                    if (vistaSemanal) {
                        anchoTitulo = 7 * anchoCeldaSemana;
                    }

                    celdatitulosColumnassticky = celdatitulosColumnassticky + `<div class="celda titulos sticky sTop" style="width: ${anchoTitulo}px; top: 0; z-index: 1; left: auto">\n ${getMes(mesActual)}\n </div>\n`;

                    let celdasInternas = '';
                    let celdasInternasVacias = '';

                    celdasInternas = celdasDias(diasMes, mesActual, fechaInicialAux.getFullYear(), true);
                    celdasInternasVacias = celdasDias(diasMes, mesActual, fechaInicialAux.getFullYear(), false);

                    celdasDiasVacios.push(celdasInternasVacias);
                    celdaRow = `<div class="celda sticky sTop" style="top: ${altoCelda}px; z-index: 1; left: auto">
                                    <div class="row">
                                        ${celdasInternas}
                                    </div>
                                </div>`;
                    celdaSticky = celdaSticky + celdaRow;
                }
            }
            fechaInicialAux.setDate(fechaInicialAux.getDate() + 1);
        }
        
        rowTitulos2 = rowTitulos2 + celdaSticky + `</div>`;
        let rowTareas = AgregarTareas(celdasDiasVacios);

        rowTitulos = rowTitulos + celdatitulosColumnassticky + `</div>`;
        contenedorGrafica = contenedorGrafica + rowTitulos + rowTitulos2 + rowTareas + `</div>`;
        contenedor = contenedor + contenedorGrafica + `</div>`;

        return contenedorGrafica;
    }

    function AgregarTareas(celdasDiasVacios) {
        let claseRow = "par";
        let rows = "";
        for (let i = 0; i < tareas.length; i++) {
            const tarea = tareas[i];
            if (!tarea.siglas) {
                tarea.siglas = "";
            }
            if (!tarea.ramoSeguro){
                tarea.ramoSeguro = "";
            }
            claseRow = claseRow === "impar" ? "par" : "impar";

            let row = `<div class="row ${claseRow}">`;
            let celdaSticky = `<div class="celda sticky">
                                    <div class="row">
                                        <div class="celda tarea" style="width: ${anchoTareas}px">
                                            <!--<div class="btnExpand">
                                                -
                                            </div>-->
                                            <div class="descripcionTarea actividad" title = "${tarea.descripcion}">
                                                ${tarea.descripcion.length > 95 ? tarea.descripcion.substring(0, 95) + '...' : tarea.descripcion}
                                            </div>
                                        </div>
                                        <div class="celda actividad" style="width: ${anchoEmpresa}px">
                                            ${tarea.siglas}
                                        </div>
                                        <div class="celda actividad" style="width: ${anchoRamoSegumo}px">
                                            ${tarea.ramoSeguro.length > 95 ? tarea.ramoSeguro.substring(0, 95) + '...' : tarea.ramoSeguro}
                                        </div>
                                        <div class="celda actividad" style="width: ${anchoFechas}px">
                                            ${tarea.fechaInicio}
                                        </div>
                                        <div class="celda fin actividad" style="width: ${anchoFechas}px">
                                            ${tarea.fechaFinal}
                                        </div>
                                        <div class="celda inicio actividad ${ComparativaProgVSReal ? "" : "Oculto"}" style="width: ${anchoFechas}px">
                                            ${tarea.fechaInicioReal}
                                        </div>
                                        <div class="celda fin actividad ${ComparativaProgVSReal ? "" : "Oculto"}" style="width: ${anchoFechas}px">
                                            ${tarea.fechaFinalReal}
                                        </div>
                                    </div>
                                </div>`;

            for (let j = 0; j < celdasDiasVacios.length; j++) {
                const celdasXDia = celdasDiasVacios[j];
                celdaSticky = celdaSticky + `<div class="celda">
                                                <div class="row">
                                                    ${celdasXDia}
                                                </div>
                                            </div>`;
            }

            row = row + celdaSticky + "</div>";
            rows = rows + row;

            let actividades = tarea.actividades;
            for (let k = 0; k < actividades.length; k++) {
                const actividad = actividades[k];
                claseRow = claseRow === "impar" ? "par" : "impar";
                row = `<div class="row ${claseRow}">`;
                celdaSticky = `<div class="celda sticky">
                                    <div class="row">
                                        <div class="celda tarea" style="width: ${anchoTareas}px">
                                            <!--<div class="btnExpand">
                                                -
                                            </div>-->
                                            <div class="descripcionTarea" title = "${actividad.descripcion}">
                                                ${actividad.descripcion.length > 100 ? actividad.descripcion.substring(0, 100) + '...' : actividad.descripcion}
                                            </div>
                                        </div>
                                        <div class="celda inicio" style="width: ${anchoEmpresa}px">
                                            ${actividad.siglas}
                                        </div>
                                        <div class="celda inicio" style="width: ${anchoRamoSegumo}px">
                                            ${actividad.ramoSeguro}
                                        </div>
                                        <div class="celda inicio" style="width: ${anchoFechas}px">
                                            ${actividad.fechaInicio}
                                        </div>
                                        <div class="celda fin" style="width: ${anchoFechas}px">
                                            ${actividad.fechaFinal}
                                        </div>
                                        <div class="celda inicio ${ComparativaProgVSReal ? "" : "Oculto"}" style="width: ${anchoFechas}px">
                                            ${actividad.fechaInicioReal}
                                        </div>
                                        <div class="celda fin ${ComparativaProgVSReal ? "" : "Oculto"}" style="width: ${anchoFechas}px">
                                            ${actividad.fechaFinalReal}
                                        </div>
                                    </div>
                                </div>`;

                for (let j = 0; j < celdasDiasVacios.length; j++) {
                    const celdasXDia = celdasDiasVacios[j];
                    celdaSticky = celdaSticky + `<div class="celda">
                                                    <div class="row">
                                                        ${celdasXDia}
                                                    </div>
                                                </div>`;
                }

                let diasHastaInicio = diasEntreFechas(fechaInicial, actividad.fechaInicioReal) - 1;
                let leftTareaReal = (anchoCeldaDias * diasHastaInicio) + anchoRowColumnas;

                let diasAnchoReal = diasEntreFechas(actividad.fechaInicioReal, actividad.fechaFinalReal);
                let anchoTareaReal = (anchoCeldaDias * diasAnchoReal);

                let diasHastaProg = diasEntreFechas(fechaInicial, actividad.fechaInicio) - 1;
                let leftTareaProg = (anchoCeldaDias * diasHastaProg) + anchoRowColumnas;
                
                let diasAnchoProg = diasEntreFechas(actividad.fechaInicio, actividad.fechaFinal);
                let anchoTareaProg = (anchoCeldaDias * diasAnchoProg);
                if (actividad.avance > 1) {
                    actividad.avance = 1;
                }
                let anchoAvance = ((actividad.avance) * anchoTareaProg);

                if (vistaSemanal) {
                    leftTareaReal = (anchoCeldaSemana * diasHastaInicio) + anchoRowColumnas;
                    anchoTareaReal = (anchoCeldaSemana * diasAnchoReal);
                    anchoAvance = ((actividad.avance) * anchoTareaReal);

                    leftTareaProg = (anchoCeldaSemana * diasHastaProg) + anchoRowColumnas;
                    anchoTareaProg = (anchoCeldaSemana * diasAnchoProg);
                    anchoAvance = ((actividad.avance) * anchoTareaProg);
                }


                //${ComparativaProgVSReal ? "Oculto" : ""} ${anchoTareaReal !== 0 ? "" : " Oculto"}
                let diagrama = `<div class="rectTarea ${ComparativaProgVSReal ? "Oculto" : ""}" style="left: ${leftTareaProg}px; width: ${anchoTareaProg}px" title = "${actividad.descripcion}">

                                </div>
                                <div class="rectAvance ${ComparativaProgVSReal ? "Oculto" : ""}" style="left: ${leftTareaProg}px; width: ${anchoAvance}px" title="Avance: ${actividad.avance !== 0 ? (actividad.avance * 100) + '%' : ''}">

                                </div>
                                <div class="rectTareaProg ${ComparativaProgVSReal ? "" : "Oculto"} ${anchoTareaProg !== 0 ? "" : " Oculto"}" style="left: ${leftTareaProg}px; width: ${anchoTareaProg}px" title = "Programado">

                                </div>
                                <div class="rectTareaReal ${ComparativaProgVSReal ? "" : "Oculto"} ${anchoTareaReal !== 0 ? "" : " Oculto"}" style="left: ${leftTareaReal}px; width: ${anchoTareaReal}px">
                                    Real
                                </div>`;

                row = row + celdaSticky + diagrama + "</div>";
                rows = rows + row;
            }
        }

        return rows;
    }

    function diasEntreFechas(fecha1, fecha2) {
        if (!fecha1 || !fecha2) {
            return 0;
        }
        let fechaInicialAux = toDate(fecha1);
        fecha2 = toDate(fecha2);
        let dias = 0;

        if (fechaInicialAux.getTime() > fecha2.getTime()) {
            fechaInicialAux = new Date(fecha2.getTime());
        }

        while (fechaInicialAux.getTime() <= fecha2.getTime()) {
            dias++;
            if (dias > 1825) {
                break;
            }
            fechaInicialAux.setDate(fechaInicialAux.getDate() + 1);
        }

        return dias;
    }

    function celdasDias(numDias, mes, anio, titulos = false) {
        let cadenaCeldas = ``;

        for (let i = 1; i <= numDias; i++) {
            let clase = "celda";
            if (titulos) {
                clase = clase + " titulos";
            }

            let fecha = new Date(anio, mes, i);
            let inhabil = false;
            /*diaSemanaInhabil
            diasInhabiles*/
            if (diaSemanaInhabil.includes(fecha.getDay())) {
                inhabil = true;
            }
            if (inhabil) {
                clase = clase + " inhabil";
            }
            let celda = `<div class="${clase}" style="width: ${(anchoCeldaDias)}px">`;
            if (titulos) {
                celda = celda + i;
            }
            celda = celda + "</div>";
            cadenaCeldas = cadenaCeldas + celda;
        }

        return cadenaCeldas;
    }

    function celdasSemanas(fechaActual, titulos = false) {
        let cadenaCeldas = ``;

        let ContadorSemana = 1;

        fechaActual = new Date(fechaActual.getTime());

        for (let i = 1; i <= 7; i++) {
            let clase = "celda";
            if (titulos) {
                clase = clase + " titulos";
            }

            let inhabil = false;
            /*diaSemanaInhabil
            diasInhabiles*/
            if (diaSemanaInhabil.includes(fechaActual.getDay())) {
                inhabil = true;
            }
            if (inhabil) {
                clase = clase + " inhabil";
            }

            let celda = `<div class="${clase}" style="width: ${(anchoCeldaSemana)}px; font-size: 10px">`;
            if (titulos) {
                let tDia = fechaActual.getDate().toString();
                if (tDia.length === 1) {
                    tDia = '0' + tDia;
                }
                celda = celda + tDia + "<br>" + getDia(fechaActual.getDay());
            }
            celda = celda + "</div>";
            cadenaCeldas = cadenaCeldas + celda;
            ContadorSemana++;
            fechaActual.setDate(fechaActual.getDate() + 1);
        }

        return cadenaCeldas;
    }

    function getDia(numDia) {
        let weekday = ["D",
            "L",
            "M",
            "M",
            "J",
            "V",
            "S"];
        return weekday[numDia];
    }

    function getMes(mes, corto) {
        let meses = [{ mes: "ENERO", corto: "ENE" },
        { mes: "FEBRERO", corto: "FEB" },
        { mes: "MARZO", corto: "MAR" },
        { mes: "ABRIL", corto: "ABR" },
        { mes: "MAYO", corto: "MAY" },
        { mes: "JUNIO", corto: "JUN" },
        { mes: "JULIO", corto: "JUL" },
        { mes: "AGOSTO", corto: "AGO" },
        { mes: "SEPTIEMBRE", corto: "SEP" },
        { mes: "OCTUBRE", corto: "OCT" },
        { mes: "NOVIEMBRE", corto: "NOV" },
        { mes: "DICIEMBRE", corto: "DIC" }];
        if (corto) {
            return meses[mes].corto;
        }

        return meses[mes].mes;
    }

    function toDate(dateStr) {
        if (!dateStr) {
            return new Date();
        }

        if (dateStr instanceof Date && !isNaN(dateStr.valueOf())) {
            return new Date(dateStr.getTime());;
        }

        if (dateStr.includes("-")) {
            let parts = dateStr.split("-");
            if (parts[2].length === 4) {
                return new Date(parts[2], parts[1] - 1, parts[0]);
            }
            if (parts[0].length === 4) {
                return new Date(parts[0], parts[1] - 1, parts[2]);
            }
        }

        if (dateStr.includes("/")) {
            let parts = dateStr.split("/");
            if (parts[2].length === 4) {
                return new Date(parts[2], parts[1] - 1, parts[0]);
            }
            if (parts[0].length === 4) {
                return new Date(parts[0], parts[1] - 1, parts[2]);
            }
        }
    }

    function getEndMonth(fecha) {
        return new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
    }

    useEffect(() => {
        let vista = CargarVista();
        setVista(vista);
    }, [ejercicio,
        folioInterno,
        tareasPrograma,
        vistaSemanal,
        ComparativaProgVSReal,
        mes]);

    useEffect(() => {
        async function ftEffect() {
            try {
                setInfoGantt({ ...stInfoGantt });
            } catch ({ message }) {
                /*setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });*/
            }
        } ftEffect();
    }, [stInfoGantt]);

    return (<div className={"contenedor"} dangerouslySetInnerHTML={{ __html: (stVista) }} />)
}

export default Gantt;
