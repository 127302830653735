/*eslint-disable*/
import React, { useState, useRef, useEffect } from "react";
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
// dependency plugin for react-big-calendar
import moment from "moment";
//componentes genericos
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { rgxNumeros, rgxDecimal8 } from '../../../helpers/regexp';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
//helpers
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Redux
import { useSelector } from 'react-redux';
// core components
import Heading from "../../../_layout/components/Heading/Heading.js";
import GridContainer from "../../../_layout/components/Grid/GridContainer.js";
import GridItem from "../../../_layout/components/Grid/GridItem.js";
import Card from "../../../_layout/components/Card/Card.js";
import CardBody from "../../../_layout/components/Card/CardBody.js";

import styles from '../../../_layout/assets/jss/material-dashboard-pro-react/components/buttonStyle.js'
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import { events as calendarEvents } from "../../../_layout/variables/general.js";
//Querys
import { getAllActividadesDeTareas } from '../../../querys/ActividadesDeTareas';
import { seleccionPolizasSeguro } from '../../../querys/PolizasSeguro';
import { seleccionRamoSeguroNA } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../Empresas/Consultas';
import { seleccionUsuario } from '../Usuarios/Consultas';
import { seleccionTareasProgAnual } from '../../../querys/TareasProgAnual';
//Mutations
import { deleteActividadesDeTareas, addActividadesDeTareas, updateActividadesDeTareas } from '../../../mutations/ActividadesDeTareas';
import { setLocalStorage,getInitialStorage } from "../../../_layout/helpers/localstorage";
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(styles);
const { trim } = Validaciones;

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

export default function Agenda() {
    const { token } = useSelector(state => state.login);
    const { Decrypt, Encrypt } = Encriptar;
    let history = useHistory();
    const fecha = new Date();
    let ejercicioActual = fecha.getFullYear();
    let { params } = useParams();
    let auxProgramaAnualID = null;
    let auxFolioInterno = null;
    if (params != undefined) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { programaAnualID, folioInterno } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        auxProgramaAnualID = programaAnualID;
        auxFolioInterno = folioInterno;
    }

    const cleanState = {
        seguimiento: "",
        fechaInicio: "",
        fechaFinal: "",
        fechaInicialReal: "",
        fechaFinalReal: ""
    };

    const cleanErrorState = {
        seguimiento: { error: false, helperText: "" },
        fechaInicialReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" }
    };

    const cleanStateActividades = {
        actividadID: null,
        tareaID: "",
        usuarioID: "",
        descripcion: "",
        responsableID: "",
        porcentaje: 0,
        estado: "ACTIVA",
        avance: 0,
        fechaInicio: "",
        fechaFinal: "",
        fechaInicioReal: null,
        fechaFinalReal: null
    };

    const cleanErrorStateActividades = {

        tareaID: { error: false, helperText: "" },
        usuarioID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        responsableID: { error: false, helperText: "" },
        porcentaje: { error: false, helperText: "" },
        estado: { error: false, helperText: "" },
        avance: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFinal: { error: false, helperText: "" },
        fechaInicioReal: { error: false, helperText: "" },
        fechaFinalReal: { error: false, helperText: "" }
    }

    const classes = useStyles();
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    //   const [events, setEvents] = useState(eventsCalendario);
    const [alert, setAlert] = useState(null);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [errorStateActividades, setErrorStateActividades] = useState(cleanErrorStateActividades);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [loader, setLoader] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [open, setOpen] = useState(false);
    const [openActividades, setOpenActividades] = useState(false);
    const [stateActividades, setStateActividades] = useState(cleanStateActividades);
    const [actualizarActividades, setActualizarActividades] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [actualizar, setActualizar] = useState(false);
    let titleAccion = state.ubicacionID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Actividad/Evento/Seguimiento`;
    let titleAccionActividades = stateActividades.actividadID ? "Modificar" : "Agregar";
    let titleModalActividades = `${titleAccionActividades} Actividad`;
    const fechaInicialRealRef = useRef(null);
    const seguimientoRef = useRef(null);
    const fechaInicioRef = useRef(null);
    const fechaFinalRef = useRef(null);
    const usuarioIDRef = useRef(null);
    const responsableRef = useRef(null);
    const descripcionRef = useRef(null);
    const fechaInicioRealRef = useRef(null);
    const fechaFinalRealRef = useRef(null);
    const estadoRef = useRef(null);
    const avanceRef = useRef(null);
    const porcentajeRef = useRef(null);
    const tareaIDRef = useRef(null);

    const selectedEvent = evento => {
        setState((state) => ({ ...state, fechaInicialReal: moment(evento.start), fechaFinalReal: moment(evento.end), seguimiento: evento.title, programaAnualID: evento.programaAnualID, folioInternoProgama: evento.folioInternoProgama }));
        let tituloActividad = evento.actividad;
        let actividadID = evento.actividadID;

        let usuarioID = evento.usuarioID;
        let nProgramaAnualID = evento.programaAnualID;
        let cfolioInternoProgama = evento.folioInternoProgama;
        let cTituloTarea = evento.titulo;
        let cRamoSeguro = evento.ramoSeguro;
        setRedireccionarFormulario({ nProgramaAnualID, cfolioInternoProgama, tituloActividad, actividadID, usuarioID, cTituloTarea, cRamoSeguro });
    };

    const eventColors = event => {
        let backgroundColor = "event-";

        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");

        return {
            className: backgroundColor
        };
    };

    const inputs = [
        {
            disabled,
            id: "seguimiento",
            value: state.seguimiento,
            error: errorState.seguimiento.error,
            success: state.seguimiento && !errorState.seguimiento.error ? true : undefined,
            helperText: errorState.seguimiento.helperText,
            inputRef: seguimientoRef,
            title: "Seguimiento *",
            placeholder: "Capture el seguimiento",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChange(value.charAt(0).toUpperCase() + value.slice(1), "seguimiento")
            },
            onKeyDown: () => { return; }

        },
        {
            disabled: true,
            id: "fechaInicialReal",
            value: state.fechaInicialReal,
            error: errorState.fechaInicialReal.error,
            success: state.fechaInicialReal && !errorState.fechaInicialReal.error ? true : undefined,
            helperText: errorState.fechaInicialReal.helperText,
            inputRef: fechaInicialRealRef,
            placeholder: "Fecha Inicial",
            tipo: "datetimepicker",
            // onlyDate: true,
            grid: { md: 6, lg: 6 },
            // onChange: ( value) =>{ typeof value === "object" && handleChange(value.format(formato), "fechaInicialReal")},
        },
        {
            disabled: true,
            id: "fechaFinalReal",
            value: state.fechaFinalReal,
            error: errorState.fechaFinalReal.error,
            success: state.fechaFinalReal && !errorState.fechaFinalReal.error ? true : undefined,
            helperText: errorState.fechaFinalReal.helperText,
            inputRef: fechaFinalRealRef,
            placeholder: "Fecha Final",
            tipo: "datetimepicker",
            // onlyDate: true,
            grid: { md: 6, lg: 6 },
            // onChange: ( value) =>{ typeof value === "object" && handleChange(value.format(formato), "fechaFinalReal")},
        },
    ];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: fechaInicialRealRef,
            mensajeCorrecto: `La ubicación se ${state.ubicacionID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const inputsActividades = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "tareaID",
                    value: stateActividades.tareaID,
                    error: errorStateActividades.tareaID.error,
                    success: stateActividades.tareaID && !errorStateActividades.tareaID.error ? true : undefined,
                    helperText: errorStateActividades.tareaID.helperText,
                    inputRef: usuarioIDRef,
                    title: "Tarea Extraordinaria *",
                    placeholder: "Selecciona la tarea",
                    tipo: "select",
                    data: seleccionables.tareasProgAnual,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setStateActividades({ ...stateActividades, tareaID: value })
                    }
                },
                {
                    disabled,
                    id: "usuarioID",
                    value: stateActividades.usuarioID,
                    error: errorStateActividades.usuarioID.error,
                    success: stateActividades.usuarioID && !errorStateActividades.usuarioID.error ? true : undefined,
                    helperText: errorStateActividades.usuarioID.helperText,
                    inputRef: usuarioIDRef,
                    title: "Usuario *",
                    placeholder: "Selecciona el usuario",
                    tipo: "select",
                    data: seleccionables.Usuarios,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setStateActividades({ ...stateActividades, usuarioID: value })
                    }
                },

                {
                    disabled,
                    id: "responsable",
                    value: stateActividades.responsableID,
                    error: errorStateActividades.responsableID.error,
                    success: stateActividades.responsableID && !errorStateActividades.responsableID.error ? true : undefined,
                    helperText: errorStateActividades.responsableID.helperText,
                    inputRef: responsableRef,
                    tipo: "select",
                    data: seleccionables.Usuarios,
                    title: "Responsable *",
                    placeholder: "Capture el responsable",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        setStateActividades({ ...stateActividades, responsableID: value })
                    },
                    onKeyDown: () => { return; }

                }, {
                    disabled,
                    id: "descripcion",
                    value: stateActividades.descripcion,
                    error: errorStateActividades.descripcion.error,
                    success: stateActividades.descripcion && !errorStateActividades.descripcion.error ? true : undefined,
                    helperText: errorStateActividades.descripcion.helperText,
                    inputRef: descripcionRef,
                    multiline: true,
                    title: "Descripcion *",
                    placeholder: "Capture la descripcion",
                    grid: { md: 6, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChangeActividades(value.charAt(0).toUpperCase() + value.slice(1), "descripcion")
                    },
                    onKeyDown: () => { return; }

                },
            ]
        }, {
            id: "Medio",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "fechaInicio",
                    value: stateActividades.fechaInicio,
                    // error: errorStateActividades.fechaInicio.error,
                    // success: stateActividades.fechaInicio && !errorStateActividades.fechaInicio.error ? true : undefined,
                    // helperText: errorStateActividades.fechaInicio.helperText,
                    inputRef: fechaInicioRef,
                    placeholder: "Fecha de Inicio *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    // onChange: (value) => handleChangeActividades(value, "fechaInicio")
                },
                {
                    disabled: true,
                    id: "fechaFinal",
                    value: stateActividades.fechaFinal,
                    // error: errorStateActividades.fechaFinal.error,
                    // success: stateActividades.fechaFinal && !errorStateActividades.fechaFinal.error ? true : undefined,
                    // helperText: errorStateActividades.fechaFinal.helperText,
                    inputRef: fechaFinalRef,
                    placeholder: "Fecha Final *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    // onlyDate: true,
                    // onChange: (value) => handleChangeActividades(value, "fechaFinal")
                }, ,
                {
                    disabled: true,
                    id: "fechaInicioReal",
                    hidden: true,
                    value: stateActividades.fechaInicioReal,
                    error: errorStateActividades.fechaInicioReal.error,
                    success: stateActividades.fechaInicioReal && !errorStateActividades.fechaInicioReal.error ? true : undefined,
                    helperText: errorStateActividades.fechaInicioReal.helperText,
                    inputRef: fechaInicioRealRef,
                    placeholder: "Fecha Inicio Real *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    onlyDate: true,
                    onChange: (value) => handleChangeActividades(value, "fechaInicioReal")
                },
                {
                    disabled: true,
                    id: "fechaFinalReal",
                    hidden: true,
                    value: stateActividades.fechaFinalReal,
                    error: errorStateActividades.fechaFinalReal.error,
                    success: stateActividades.fechaFinalReal && !errorStateActividades.fechaFinalReal.error ? true : undefined,
                    helperText: errorStateActividades.fechaFinalReal.helperText,
                    inputRef: fechaFinalRealRef,
                    placeholder: "Fecha Final Real *",
                    tipo: "datetimepicker",
                    grid: { md: 3, lg: 3 },
                    onlyDate: true,
                    onChange: (value) => handleChangeActividades(value, "fechaFinalReal")
                },
            ]
        }, {
            id: "abaj0",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "estado",
                    value: stateActividades.estado,
                    error: errorStateActividades.estado.error,
                    success: stateActividades.estado && !errorStateActividades.estado.error ? true : undefined,
                    helperText: errorStateActividades.estado.helperText,
                    inputRef: estadoRef,
                    title: "Estado *",
                    tipo: "select",
                    grid: { md: 4, lg: 4 },
                    data: [
                        {
                            label: 'ACTIVA',
                            value: 'ACTIVA'
                        },
                        {
                            label: 'EN PROCESO',
                            value: 'EN PROCESO'
                        },
                        {
                            label: 'TERMINADA',
                            value: 'TERMINADA'
                        },
                    ],
                    onChange: ({ target: { value } }) => handleChangeActividades(value, "estado"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled: true,
                    id: "avance",
                    value: stateActividades.avance,
                    error: errorStateActividades.avance.error,
                    success: stateActividades.avance && !errorStateActividades.avance.error ? true : undefined,
                    helperText: errorStateActividades.avance.helperText,
                    inputRef: avanceRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Avance ",
                    grid: { md: 4, lg: 4 },
                    placeholder: "Capture el avance",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChangeActividades(value, "avance")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled: true,
                    id: "porcentaje",
                    value: stateActividades.porcentaje,
                    error: errorStateActividades.porcentaje.error,
                    success: stateActividades.porcentaje && !errorStateActividades.porcentaje.error ? true : undefined,
                    helperText: errorStateActividades.porcentaje.helperText,
                    inputRef: porcentajeRef,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="%" />,
                    title: "Porcentaje *",
                    grid: { md: 4, lg: 4 },
                    placeholder: "Capture el porcentaje",
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                            handleChangeActividades(value, "porcentaje")
                    },
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];

    const accionesFormularioActividades = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarActividades,
            actualizar: actualizarActividades,
            notificaciones,//: setNotificaciones,
            handleClose: handleCloseActividades,
            handleFocus,
            mensajeCorrecto: `La actividad se ${stateActividades.actividadID ? "actualizó" : "agregó"} correctamente.`,
            asyncCallback: ftGuardarActividades,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseActividades
    }];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }
    function handleChangeActividades(value, key) { setStateActividades({ ...stateActividades, [key]: value }); }

    function handleAgregarEvento(evento) {

        let nProgramaAnualID = evento.programaAnualID;
        let cfolioInternoProgama = evento.folioInternoProgama;
        setStateActividades((actividades) => ({ ...actividades, fechaInicio: moment(evento.start), fechaFinal: moment(evento.end) }))
        setOpenActividades(true);
        if (nProgramaAnualID) {
            setState((state) => ({ ...state, fechaInicialReal: moment(evento.start), fechaFinalReal: moment(evento.end), seguimiento: evento.title, programaAnualID: evento.programaAnualID, folioInternoProgama: evento.folioInternoProgama }));
            setRedireccionarFormulario({ nProgramaAnualID, cfolioInternoProgama });
        }
    }

    function handleCloseActividades() {
        setOpenActividades(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateActividades({ ...cleanStateActividades });
        setErrorStateActividades({ ...cleanErrorStateActividades });
    }
    function ftErrorInputTextActividades({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState: cleanErrorStateActividades, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorStateActividades
        });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    async function handleValidacionesActividades({ tareaID, usuarioID, responsableID, descripcion }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(tareaID),
                ref: tareaIDRef, keyError: "tareaID",
                mensajeError: "Seleccione una tarea"
            },
            {
                condicion: !rgxNumeros.test(usuarioID),
                ref: usuarioIDRef, keyError: "usuarioID",
                mensajeError: "Seleccione un usuario"
            },
            {
                condicion: !responsableID,
                ref: responsableRef, keyError: "responsableID",
                mensajeError: "Debe especificar el responsable"
            },
            {
                condicion: !descripcion || trim(descripcion) === "",
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Debe especificar la descripcion"
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputTextActividades({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;
    }

    function ftGuardarActividades() {
        async function ftGuardarActividades() {
            try {
                let {
                    actividadID,
                    tareaID,
                    usuarioID,
                    descripcion,
                    responsableID,
                    porcentaje,
                    estado,
                    avance,
                    fechaInicio,
                    fechaFinal,
                    fechaInicioReal,
                    fechaFinalReal } = stateActividades;

                let error = await handleValidacionesActividades({ tareaID, usuarioID, responsableID, descripcion });

                if (error) return error;

                if (actividadID) await updateActividadesDeTareas({
                    input: {
                        actividadID: Number(actividadID),
                        tareaID: Number(tareaID),
                        usuarioID: Number(usuarioID),
                        descripcion: trim(descripcion),
                        responsableID: Number(responsableID),
                        porcentaje: parseFloat(porcentaje),
                        estado: trim(estado),
                        avance: avance /*parseFloat(avance)*/,
                        fechaInicio /*: fechaInicio.format(formato)*/,
                        fechaFinal/*: fechaFinal.format(formato)*/,
                        fechaInicioReal: null/*fechaInicioReal.format(formato)*/,
                        fechaFinalReal: null/*fechaFinalReal.format(formato)*/
                    }
                }, token);
                else await addActividadesDeTareas({
                    input: {
                        tareaID: Number(tareaID),
                        usuarioID: Number(usuarioID),
                        descripcion: trim(descripcion),
                        responsableID: Number(responsableID),
                        porcentaje: parseFloat(porcentaje),
                        estado: trim(estado),
                        avance: avance /*parseFloat(avance)*/,
                        fechaInicio/*: fechaInicio.format(formato)*/,
                        fechaFinal/*: fechaFinal.format(formato)*/,
                        fechaInicioReal: null/* fechaInicioReal.format(formato)*/,
                        fechaFinalReal: null/* fechaFinalReal.format(formato)*/
                    }
                }, token);
            } catch ({ message: mensaje }) {
                setNotificaciones({ mensaje, color: danger, open: true });
            }

        }
        return ftGuardarActividades();
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let eventos = await getAllActividadesDeTareas({ filtro: { ejercicio: ejercicioActual } }, token);
                setSeleccionables((seleccionables) => ({ ...seleccionables, eventosDB: eventos }));
            } catch ({ message }) {
                setAlert({
                    descripcion: `Error: ${message}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, actualizarActividades, ejercicioActual]);

    function setRedireccionarFormulario({ nProgramaAnualID, cfolioInternoProgama, tituloActividad, actividadID, usuarioID, cTituloTarea, cRamoSeguro }) {
        let seed = "scrumweb";
        const datos = `{ "programaAnualID": "${nProgramaAnualID}", "folioInterno": "${cfolioInternoProgama}", "tituloActividad": "${tituloActividad.replace(/\n/g, " ")}", "actividadID": "${actividadID}", "usuarioID": "${usuarioID}","cTituloTarea": "${cTituloTarea.replace(/\n/g, " ")}","cRamoSeguro": "${cRamoSeguro}"}`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/SeguimientoTareaPorDia/${url}`);
    }


    useEffect(() => {
        async function ftEffect() {
            try {
                let Poliza = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== null && state.empresaID !== "" ? parseInt(state.empresaID) : -1, ramoSeguroID: state.ramoSeguroID !== null && state.ramoSeguroID !== "" ? parseInt(state.ramoSeguroID) : -1, tipoPoliza: "PRINCIPAL" } }, token);
                let Ramo = await seleccionRamoSeguroNA({ filtro: {} }, token);
                let Empresa = await seleccionEmpresa({}, token);
                let Usuarios = await seleccionUsuario({}, token);
                let tareasProgAnual = await seleccionTareasProgAnual({ filtro: { extraordinaria: 1 } }, token);
                setSeleccionables((seleccionables) => ({ ...seleccionables, Empresa, Poliza, Ramo, Usuarios, tareasProgAnual }));
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, state.empresaID, state.ramoSeguroID]);
    return (<>

        <container>
        <BigCalendar
            selectable
            //timeslots={2}
            //step={15}
            popup
            localizer={localizer}
            events={seleccionables.eventosDB || []}
            // showMultiDayTimes={true}
            defaultView="month"
            scrollToTime={new Date(2000, 1, 1, 6)}
            defaultDate={ getInitialStorage('añoAgenda','pwdAgenda') == null ? new Date() : new Date(`${getInitialStorage('añoAgenda','pwdAgenda')}`)}
            onSelectEvent={event => selectedEvent(event)}
            onSelectSlot={()=>{}/* slotInfo => handleAgregarEvento(slotInfo) */}
            //   onSelecting={slot => !slot}
            eventPropGetter={eventColors}
            //onDoubleClickEvent={ftAgregarActividades()}
            views={['month', 'week', 'day' ]} // views={['month', 'week', 'day','agenda']}
            messages={{ next: 'Siguiente', previous: 'Anterior', today: 'Hoy', month: 'Mes', week: 'Semana', day: 'Día'/* , agenda:'Ver Agenda' */ }}
            onNavigate={(event) => {
                    ejercicioActual = event.getFullYear();
                    setLocalStorage('añoAgenda',moment(event).format('YYYY-MM-DD'),'pwdAgenda');
                }} // para obtener las fechas cuando se cambia en Hoy/Anterior/Siguiente
            />

            {
                openActividades && <FormularioModal
                    open={openActividades}
                    title={titleModalActividades}
                    onClose={handleCloseActividades}
                    notificaciones={notificaciones}
                    closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                    inputs={inputsActividades}
                    acciones={accionesFormularioActividades}
                    focus={descripcionRef}
                />
            }
            {alert && <SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={() => setAlert(null)}
            />
            }
        </container>
    </>);
}
