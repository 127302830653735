// EJSA
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Validaciones } from '@renedelangel/helpers';

// Generic Components
import GenericTabla from "../../genericComponents/Tabla";
import { Boton } from "../../genericComponents/Botones";
import { NetworkError } from "../../genericComponents/Metodos";

import moment from 'moment';
//import {setFechasFiltro} from '../../../redux/actions/loginAction.js';
// Redux
import { useDispatch, useSelector } from 'react-redux';

const { trim } = Validaciones;
// Redux


export default function TablaMaster({
    asyncData,
    infoTabla = {},
    parametrosFijos = {},
    token,
    url,
    setErrorToken,
    defaultSearch,
    showHelp = false,
    usarFiltroFechas = false,
    setFiltrosAplicados,
    requiereFiltros = false,
    setFiltrosTexto,
    asyncSumaActivos,
    limiteRegInicial = 10
}) {
    // ,aFechas   
    let { title, title2,title3, hideHeader = false, headers, iconTable, responsiveTitle, filter = [], selectFilter = [], alineacion, formato = [], color, rowColor, accionesEffect = [],
        acciones = [], multiseleccion = [], id, botones = [], rangoFechas, actualizar = false, all, ...rest } = infoTabla,
        hoy = moment(), inicio = moment().startOf('month');

    let rangoFechasInputs = [], seleccionFiltroInputs = [], ini = "", fin = "", defaultRangoFechas = {},
        defaultSeleccionFiltro = {}, retornaSeleccionFiltro = {}, formatRangoFechas, formatIni, formatFin, defaultSeleccionFiltroTexto = {};

    if (rangoFechas && typeof rangoFechas === "object") {
        let { ini: iniRango = "fechaIni", fin: finRango = "fechaFin", formato = "YYYY-MM-DDTHH:mm:ss", replaceIni = {}, replaceFin = {}, fechaInicial, fechaFinal } = rangoFechas;
        let { regexp: rIni = /T([0][0-9]|1[0-2]):([0-5][0-9]):([0-5][0-9])$/g, value: vIni = 'T00:00:00' } = replaceIni;
        let { regexp: rFin = /T([0][0-9]|1[0-2]):([0-5][0-9]):([0-5][0-9])$/g, value: vFin = 'T23:59:59' } = replaceFin;
        ini = iniRango; fin = finRango; defaultRangoFechas = { [ini]: fechaInicial ? fechaInicial : inicio, [fin]: fechaFinal ? fechaFinal : hoy }; formatRangoFechas = formato;
        formatIni = { regexp: rIni, value: vIni }; formatFin = { regexp: rFin, value: vFin };
    }



    if (Object.keys(defaultSeleccionFiltro).length === 0 && (Array.isArray(selectFilter) && selectFilter.length > 0)) {
        selectFilter.forEach(({ variable, campo, retorna = "string", texto, defaultValue }) => {
            let id = variable ? variable : campo;
            defaultSeleccionFiltro = { ...defaultSeleccionFiltro, [id]: defaultValue ? defaultValue : "null" };
            if (texto) defaultSeleccionFiltroTexto = { ...defaultSeleccionFiltroTexto, [id]: defaultValue ? defaultValue : "" };
            retornaSeleccionFiltro = { ...retornaSeleccionFiltro, [id]: retorna };
        });
        
    }

    useEffect(() => {
        if(requiereFiltros){
        setFiltrosAplicados(defaultSeleccionFiltro);}
    }, [])

    const [paginaActiva, setPaginaActiva] = useState(1);
    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);
    const [multiCheck, setMultiCheck] = useState({});
    const [multiCheckValue, setMultiCheckValue] = useState({});
    const [disabledMultiseleccion, setDisabledMultiseleccion] = useState(false);
    const [disabledNormal, setDisabledNormal] = useState(false);
    const [disabledLoader, setDisabledLoader] = useState(false);
    const [valueCheck, setValueCkeck] = useState({ value: {}, id: -1 });
    const [fechasFiltro, setFechasFiltro1] = useState(defaultRangoFechas);
    const [seleccionFiltro, setSeleccionFiltro] = useState(defaultSeleccionFiltro);
    const [seleccionFiltroTexto, setSeleccionFiltroTexto] = useState(defaultSeleccionFiltroTexto);
    const dispatch = useDispatch();
    // const setFechasFiltroRedux = (aFechas) => dispatch(setFechasFiltro(aFechas));
    const [paginacion, setPaginacion] = useState({
        paginas: 1,
        listadoPaginas: 5,
        registros: 0,
        limite: limiteRegInicial,
        busqueda: {},
        onClick: (activa) => setPaginaActiva(activa)
    });
    const [error, setError] = useState({ si: false, mensaje: "", onClick: () => setPaginacion({ ...paginacion, busqueda: {} }) });

    function handleRegistros({ target: { value } }) {
        setPaginaActiva(1);
        setPaginacion({
            ...paginacion,
            limite: Number(value)
        });
    }

    function handleSearch(busqueda) {
        setPaginaActiva(1);
        setPaginacion({
            ...paginacion,
            busqueda
        });
    }

    function handleActualizaFiltrosBusqueda(busqueda) {
        //Asignar solo el valor de búsqueda si se hace lo que en la función handleSearch, el ejecuta el refresh, pero en este evento solo se necesitan actualizar los filtros de búsqueda
        paginacion.busqueda = busqueda;
    }

    function handleRangoFechas(fecha = {}, id) {
        if (typeof fecha !== "object") return;
        setPaginaActiva(1);
        setFechasFiltro1(fechasFiltro => ({
            ...fechasFiltro,
            [id]: fecha
        }));
        /*  
          let aFechas  = { FechaInicio: fecha, FechaFinal : fecha} ;
          setFechasFiltroRedux(aFechas);*/

    }

    function handleSeleccionFiltro(val, id, { cleanStateValues, funcionC }) {
        let value = val ? val.value : null;
        setPaginaActiva(1);
        setSeleccionFiltro(seleccionFiltro => ({
            ...seleccionFiltro,
            [id]: value
        }));
        if (cleanStateValues) cleanStateValues(value, { setSeleccionFiltroTexto, setSeleccionFiltro });
        if (funcionC) funcionC(value,setSeleccionFiltro);

        if (requiereFiltros) {
            setFiltrosAplicados(seleccionFiltro => ({
                ...seleccionFiltro,
                [id]: value
            }));
        }

        if (infoTabla.handleFiltros) {
            infoTabla.handleFiltros(id, value);
        }
    }


    useEffect(()=>{
        async function filterHandler(){
            if(requiereFiltros && usarFiltroFechas){
                setFiltrosAplicados({...seleccionFiltro, ...fechasFiltro})
            }
        }
        filterHandler();
    }, [usarFiltroFechas, fechasFiltro]);


    function handleSeleccionTexto({ target: { value } }, id, { cleanStateValues, validaTexto }) {
        let seguir = true;
        if (validaTexto) seguir = validaTexto(value);
        if (!seguir) return;
        setSeleccionFiltroTexto(seleccionFiltroTexto => ({
            ...seleccionFiltroTexto,
            [id]: value
        }));
        if (cleanStateValues) cleanStateValues(value, { setSeleccionFiltroTexto, setSeleccionFiltro });
    }

    function syncStates(syncState, stateData) {

        let newState = {};

        Object.keys(syncState).forEach(key => {
            let newValue = !stateData[key] ? syncState[key] : (
                (stateData[key] !== syncState[key]) ? stateData[key] : syncState[key]
            );
            newState = { ...newState, [key]: newValue };
        });

        return newState;

    }

    function handleSeleccionTextoEnter({ key, keyCode }) {
        if (key === "Enter" || keyCode === 13) {

            let dataFormat = {};
            Object.keys(seleccionFiltroTexto).forEach(key => {
                let value = seleccionFiltroTexto[key];
                dataFormat = { ...dataFormat, [key]: !value || trim(value) === "" ? null : trim(value) }
            });

            setPaginaActiva(1);
            setSeleccionFiltro(seleccionFiltro => ({
                ...seleccionFiltro,
                ...dataFormat
            }));

        }
    }

    function handleRetornaTipoSeleccionFiltro(value, tipo) {
        if (value === null) return null;
        switch (tipo) {
            case "number": return !isNaN(Number(value)) ? Number(value) : value;
            case "boolean": return (!value || value === "false" || value === "0" || value === 0 || value === "null" || value === "undefined") ? false : true;
            default: return value;
        }
    }

    function onChangeSwitch(id, value) { setValueCkeck({ id, value }); }

    const botonesFormat = (Array.isArray(botones) && botones.length > 0) ? botones.map(({ icono, color, descripcion, onClick, disabled = {} }, index) => {
        let { multiseleccion = false, normal = false } = disabled;
        return (<Boton
            key={index}
            Icono={icono}
            color={color}
            descripcion={descripcion}
            disabled={multiseleccion ? disabledMultiseleccion : (normal ? disabledNormal : disabledLoader)}
            onClick={(e) => {
                let newMultiCheckValue = [], keys = Object.keys(multiCheckValue);
                newMultiCheckValue = keys.map(key => ({ ...multiCheckValue[key] }));
                onClick(newMultiCheckValue, e);
            }}
        />);
    }) : [];

    if (rangoFechas && typeof rangoFechas === "object") {
        let onlyDate = !rangoFechas.onlyDate && rangoFechas.onlyDate !== false;
        let defaultVal = { tipo: "datetimepicker", onlyDate, onChange: handleRangoFechas, grid: { xs: 6, sm: 4, md: 3, lg: 2 } };
        rangoFechasInputs = [
            { ...defaultVal, id: ini, placeholder: "Fecha inicio", value: fechasFiltro[ini] },
            { ...defaultVal, id: fin, placeholder: "Fecha final", value: fechasFiltro[fin] }
        ];
    }

    if (Array.isArray(selectFilter) && selectFilter.length > 0) {
        let defaultVal = { tipo: "autocomplete", onChange: handleSeleccionFiltro, grid: { xs: 2, sm: 2, md: 2, lg: 4 } };
        seleccionFiltroInputs = selectFilter.map(({ variable, campo, placeholder, limpiarFiltro, data, texto, validaTexto, onChangeDisabled, grid, ...rest }) => {
            let id = variable ? variable : campo;
            let limpiar = limpiarFiltro ? [{ value: "null", label: limpiarFiltro }] : [];
            let setTipoTexto = texto ? { tipo: undefined, onChange: handleSeleccionTexto, onKeyDown: handleSeleccionTextoEnter, validaTexto } : {};
            let disabled = onChangeDisabled ? onChangeDisabled(seleccionFiltro) : false;
            let setGrid = grid ? { grid: { ...grid } } : {};
            return {
                ...defaultVal, ...setTipoTexto, ...setGrid, ...rest, disabled, value: texto ? seleccionFiltroTexto[id] : (seleccionFiltro[id] ? seleccionFiltro[id] : "null"), id,
                placeholder, data: (data && Array.isArray(data)) ? [...limpiar, ...data] : [{ value: "null", label: "No se esta recibiendo la información correcta" }]
            };
        });
    }

    function effectListado() {
        async function effect() {

            setLoader(true);
            setDisabledLoader(true);
            setDisabledMultiseleccion(true);
            setDisabledNormal(true);
            let checked = {};

            try {

                let filtrado = { ...paginacion.busqueda, pagina: paginaActiva, limite: paginacion.limite, ...parametrosFijos };

                if (rangoFechas && typeof rangoFechas === "object") {
                    let onlyDate = !rangoFechas.onlyDate && rangoFechas.onlyDate !== false;
                    let fechaIni = onlyDate ? fechasFiltro[ini].format(formatRangoFechas).replace(formatIni.regexp, formatIni.value) : fechasFiltro[ini].format(formatRangoFechas);
                    let fechaFinal = onlyDate ? fechasFiltro[fin].format(formatRangoFechas).replace(formatFin.regexp, formatFin.value) : fechasFiltro[fin].format(formatRangoFechas);
                    filtrado = { ...filtrado, [ini]: fechaIni, [fin]: fechaFinal };
                }

                if (Array.isArray(selectFilter) && selectFilter.length > 0) {
                    let filtroKeys = Object.keys(seleccionFiltro);
                    let syncState = syncStates(seleccionFiltro, seleccionFiltroTexto);
                    filtroKeys.forEach(key => {
                        filtrado = {
                            ...filtrado,
                            [key]: syncState[key] === "null" ? null : handleRetornaTipoSeleccionFiltro(syncState[key], retornaSeleccionFiltro[key])
                        };
                    });
                }

                const { paginas, registros, listado } = asyncData ? await asyncData({ filtro: filtrado }, token, ) : { paginas: 1, registros: 0, listado: [] };
                const partidasSolicitudes = asyncSumaActivos ? await asyncSumaActivos({ filtro:  {solSegFianID: filtrado.solSegFianID} }, token, ) : { listado: [] };
           
                if (all) all(asyncSumaActivos ? partidasSolicitudes : listado);
                if (!paginas || !registros || !listado) throw new Error("No se encontraron registros");
               

                let newData = listado.map(data => {
                    let newObject = {};
                    if (Array.isArray(headers) && headers.length > 0) {
                        headers.forEach(({ variable, descripcion, hide = false, valueEditado, idEditable }) => {
                            let label = descripcion ? descripcion : variable, newValue;
                            if (valueEditado && typeof valueEditado === "object" && idEditable) {
                                let keys = Object.keys(valueEditado);
                                keys.forEach(key => {
                                    if (key === data[idEditable].toString()) newValue = valueEditado[key];
                                });
                            }
                            newObject = {
                                ...newObject,
                                [variable]: { value: newValue ? newValue : data[variable], label, hide }
                            };
                        });
                    }
                    return newObject;
                });

                if (newData.length === 0) throw new Error("No se encontraron registros");

                newData.forEach(data => { checked = { ...checked, [`${id}-${data[id]}`]: false }; });

                setData([...newData]);
                setPaginacion({ ...paginacion, paginas, registros });
                setError({ ...error, si: false });

            } catch ({ message }) {

                let [primero] = data, encabezado = {}, newData = [];
                if (primero) {
                    let keys = Object.keys(primero);
                    keys.forEach(key => {
                        encabezado = {
                            ...encabezado,
                            [key]: {
                                hide: primero[key].hide,
                                label: primero[key].label,
                                value: undefined
                            }
                        }
                    });
                    newData = [encabezado];
                }
                setData([...newData]);
                setPaginacion(paginacion => ({ ...paginacion, paginas: 0, registros: 0 }));
                setError({ ...error, si: true, message: NetworkError(message) });
                if (setErrorToken) setErrorToken(message);
                if (Array.isArray(data) && data.length === 1 && Object.keys(paginacion.busqueda).length > 0) setPaginacion(paginacion => ({ ...paginacion, busqueda: {} }));

            }

            setMultiCheck({ ...checked });
            setMultiCheckValue({});

            setDisabledMultiseleccion(false);
            setDisabledLoader(false);
            setLoader(false);

        } effect();
    }

    function multiCheckAllSelected(checked, newData) {
        let newValues = {}, newCheck = {};
        newData.forEach(data => {
            let value = {}, ident;
            multiseleccion.forEach(({ campo, variable }) => {
                let idData = variable ? variable : campo;
                if (id) ident = `${id}-${data[id].value}`;
                value = { ...value, [idData]: data[campo].value };
            });
            newValues = checked ? { ...newValues, [ident]: value } : {};
            newCheck = { ...newCheck, [ident]: checked };
        });
        setMultiCheck(multiCheck => ({ ...multiCheck, ...newCheck }));
        setDisabledMultiseleccion(Object.keys(newValues).length > 0);
        setDisabledNormal(!Object.keys(newValues).length > 0);
        setMultiCheckValue({ ...newValues });
    }

    function effectMultiSelect() {
        let { id, value } = valueCheck,
            newMultiCheckValue = { ...multiCheckValue },
            eliminar = multiCheck[id];
        if (eliminar) delete newMultiCheckValue[id];
        else newMultiCheckValue = { ...newMultiCheckValue, ...value };
        setMultiCheck(multiCheck => ({ ...multiCheck, [id]: !multiCheck[id] }));
        setDisabledMultiseleccion(Object.keys(newMultiCheckValue).length > 0);
        setDisabledNormal(!Object.keys(newMultiCheckValue).length > 0);
        setMultiCheckValue({ ...newMultiCheckValue });
    }

    function effectDefaultSearch() {
        if (defaultSearch && typeof defaultSearch === "object" && Object.keys(defaultSearch).length > 0) {
            setPaginaActiva(1);
            setPaginacion(paginacion => ({ ...paginacion, busqueda: { ...defaultSearch } }));
        }
    }

    useEffect(effectListado, [paginaActiva, paginacion.limite, paginacion.busqueda, fechasFiltro, seleccionFiltro, actualizar]);
    useEffect(effectDefaultSearch, [defaultSearch])
    useEffect(effectMultiSelect, [valueCheck]);

    return (<>
        {
            Array.isArray(headers) && headers.length > 0 ? <GenericTabla
                title={title}
                title2={title2}
                title3={title3}
                hideHeader={hideHeader}
                responsiveTitle={responsiveTitle}
                alineacion={alineacion}
                formato={formato}
                data={data}
                filter={filter}
                iconTable={iconTable}
                paginacion={paginacion}
                paginaActiva={paginaActiva}
                busqueda={paginacion.busqueda}
                handleRegistros={handleRegistros}
                color={color}
                rowColor={rowColor}
                loader={loader}
                error={error}
                id={id}
                showHelp={showHelp}
                multiCheck={multiCheck}
                multiCheckAllSelected={multiCheckAllSelected}
                acciones={acciones}
                accionesEffect={accionesEffect}
                onChangeSwitch={onChangeSwitch}
                multiseleccion={multiseleccion}
                disabledMultiseleccion={disabledMultiseleccion}
                disabledNormal={disabledNormal}
                disabledLoader={disabledLoader}
                handleSearch={handleSearch}
                botones={botonesFormat}
                rangoFechas={rangoFechasInputs}
                seleccionFiltro={seleccionFiltroInputs}
                defaultSearch={defaultSearch}
                token={token}
                url={url}
                handleActualizaFiltrosBusqueda={handleActualizaFiltrosBusqueda}
                usarFiltroFechas= {usarFiltroFechas}
                requiereFiltros = {requiereFiltros}
                setFiltrosTexto = {setFiltrosTexto}
                {...rest}
            /> : <div style={{ textAlign: "center" }}><h3>Sin los headers es imposible mostrar la tabla maestra correctamente</h3></div>
        }
    </>);

}

TablaMaster.propTypes = {
    token: PropTypes.string.isRequired,
    url: PropTypes.string,
    asyncData: PropTypes.func.isRequired,
    parametrosFijos: PropTypes.object,
    showHelp: PropTypes.bool,
    hideHeader: PropTypes.bool,
    rowColor: PropTypes.func,
    infoTabla: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        headers: PropTypes.arrayOf(PropTypes.shape({
            variable: PropTypes.string.isRequired,
            descripcion: PropTypes.string,
            hide: PropTypes.bool
        })).isRequired,
        responsiveTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
        filter: PropTypes.arrayOf(PropTypes.shape({
            campo: PropTypes.string,
            variable: PropTypes.string,
            placeholder: PropTypes.string
        })),
        selectFilter: PropTypes.arrayOf(PropTypes.shape({
            campo: PropTypes.string.isRequired,
            variable: PropTypes.string,
            placeholder: PropTypes.string,
            limpiarFiltro: PropTypes.string,
            retorna: PropTypes.oneOf(["string", "number", "boolean"]),
            data: PropTypes.arrayOf(PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                label: PropTypes.string
            })),
            texto: PropTypes.bool,
            validaTexto: PropTypes.func,
            defaultValue: PropTypes.any,
            onChangeDisabled: PropTypes.func,
            grid: PropTypes.shape({
                xs: PropTypes.number,
                sm: PropTypes.number,
                md: PropTypes.number,
                lg: PropTypes.number
            }),
            textAlign: PropTypes.string
        })),
        alineacion: PropTypes.arrayOf(PropTypes.shape({
            columnas: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
            alineacion: PropTypes.string
        })),
        formato: PropTypes.arrayOf(PropTypes.shape({
            columnas: PropTypes.arrayOf(PropTypes.string),
            tipo: PropTypes.string
        })),
        color: PropTypes.string,
        multiseleccion: PropTypes.arrayOf(PropTypes.shape({
            campo: PropTypes.string,
            variable: PropTypes.string
        })),
        acciones: PropTypes.arrayOf(PropTypes.shape({
            icono: PropTypes.elementType,
            color: PropTypes.string,
            descripcion: PropTypes.string,
            parametros: PropTypes.arrayOf(PropTypes.shape({
                campo: PropTypes.string,
                variable: PropTypes.string
            })),
            onClick: PropTypes.func
        }))
    })
};
