import React, { useState } from 'react';

function SeccionV({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      unidad: "MILLONES DE PESOS",
      ejercicio4: { anio: ejercicio - 4, terminal: 0, estibaje: 0, muelles: 0, totalIngresos: 0 },
      ejercicio3: { anio: ejercicio - 3, terminal: 0, estibaje: 0, muelles: 0, totalIngresos: 0 },
      ejercicio2: { anio: ejercicio - 2, terminal: 0, estibaje: 0, muelles: 0, totalIngresos: 0 },
      ejercicio1: { anio: ejercicio - 1, terminal: 0, estibaje: 0, muelles: 0, totalIngresos: 0 },
      ejercicio: { anio: ejercicio, terminal: 0, estibaje: 0, muelles: 0, totalIngresos: 0 },
      proyeccion: { anio: ejercicio + 1, terminal: 0, estibaje: 0, muelles: 0, totalIngresos: 0 },
      plantaPermanente: 0,
      temporarios: 0
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function respuestaTexto(value) {
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux.unidad = value;

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
  }

  function handleChangeValue(ejercicio, columna, value) {
    if (isNaN(value)) {
      return;
    }


    let respuestaAux = { ...stRespuestasLocal };
    if (ejercicio === "seccionVI") {
      respuestaAux[columna] = value;
    } else {

      respuestaAux[ejercicio][columna] = value;
      respuestaAux[ejercicio].totalIngresos = parseFloat(respuestaAux[ejercicio].terminal) + parseFloat(respuestaAux[ejercicio].estibaje) + parseFloat(respuestaAux[ejercicio].muelles);
    }

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
  }

  let anchoColumnasImportes = "110px";
  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          V. Salarios Anuales
        </div>
        <br />
        <div className="RespuestasInput">
          <div className="InputText">
            <div className="txt">
              <input className="txt" value={stRespuestasLocal.unidad} onChange={event => respuestaTexto(event.target.value)} disabled={disabled} />
            </div>
          </div>
        </div>
        <div className="cSeccionTabla" style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px", overflow: "hidden" }}>
          <div className="cContenedorTabla">
            <div className="cTabla">
              <div className="cRowEncabezado">
                <div className="cCelda" style={{ width: "150px" }}>
                  Año
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Operadores de Terminal
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Operaciones de Estibaje
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Adm. de Muelles
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Total Ingresos
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"4 años atras " + stRespuestasLocal["ejercicio4"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].terminal} onChange={event => handleChangeValue("ejercicio4", "terminal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].estibaje} onChange={event => handleChangeValue("ejercicio4", "estibaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].muelles} onChange={event => handleChangeValue("ejercicio4", "muelles", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <div className="cInputNumerico">{stRespuestasLocal["ejercicio4"].totalIngresos}</div>
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"3 años atras " + stRespuestasLocal["ejercicio3"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].terminal} onChange={event => handleChangeValue("ejercicio3", "terminal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].estibaje} onChange={event => handleChangeValue("ejercicio3", "estibaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].muelles} onChange={event => handleChangeValue("ejercicio3", "muelles", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <div className="cInputNumerico">{stRespuestasLocal["ejercicio3"].totalIngresos}</div>
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"2 años atras " + stRespuestasLocal["ejercicio2"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].terminal} onChange={event => handleChangeValue("ejercicio2", "terminal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].estibaje} onChange={event => handleChangeValue("ejercicio2", "estibaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].muelles} onChange={event => handleChangeValue("ejercicio2", "muelles", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <div className="cInputNumerico">{stRespuestasLocal["ejercicio2"].totalIngresos}</div>
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"1 años atras " + stRespuestasLocal["ejercicio1"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].terminal} onChange={event => handleChangeValue("ejercicio1", "terminal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].estibaje} onChange={event => handleChangeValue("ejercicio1", "estibaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].muelles} onChange={event => handleChangeValue("ejercicio1", "muelles", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <div className="cInputNumerico">{stRespuestasLocal["ejercicio1"].totalIngresos}</div>
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"Est. cierre año actual " + stRespuestasLocal["ejercicio"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].terminal} onChange={event => handleChangeValue("ejercicio", "terminal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].estibaje} onChange={event => handleChangeValue("ejercicio", "estibaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].muelles} onChange={event => handleChangeValue("ejercicio", "muelles", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <div className="cInputNumerico">{stRespuestasLocal["ejercicio"].totalIngresos}</div>
                </div>
              </div>
              {/* <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"Proyección " + stRespuestasLocal["proyeccion"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].terminal} onChange={event => handleChangeValue("proyeccion", "terminal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].estibaje} onChange={event => handleChangeValue("proyeccion", "estibaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].muelles} onChange={event => handleChangeValue("proyeccion", "muelles", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <div className="cInputNumerico">{stRespuestasLocal["proyeccion"].totalIngresos}</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          VI. Número de Empleados
        </div>
        <br />
        <div className="cPreguntaDoble">
          <div className="Pregunta">
            Planta Permanente
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <input className="cInputImporte" value={stRespuestasLocal.plantaPermanente} onChange={event => handleChangeValue("seccionVI", "plantaPermanente", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
          <div className="Pregunta">
            Temporarios
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <input className="cInputImporte" value={stRespuestasLocal.temporarios} onChange={event => handleChangeValue("seccionVI", "temporarios", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default SeccionV;