import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { getGastoMedico, tablaGastosMedicos } from '../../../querys/SolGastosMedicos';
import { seleccionArea } from '../../../querys/Areas';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { addGastosMedicos, updateGastosMedicos, deleteGastosMedicos } from '../../../mutations/SolGastosMedicos';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

import { rgxLetras, rgxNumeros } from '../../../helpers/regexp';
import { getEdad } from 'helpers/rutinas/metodos/rutinasGenerales';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';

import moment from 'moment';
const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;

function GastosMedicos({ datosDetalle, setOpenSolSegGastosMedicos, lMostrarFormularioCompleto }) {
    let now = moment();
    let history = useHistory();
    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let URLPolizasSeguroID = null;
    let mostrarTabla = false;
    let nGtosMedMayID = null;
    let nEmpresaID = null;
    let nUsuarioSolicita = null;
    let datosDetalleURL = {};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nSolicitud = null;
    let cTitulo2 = "ANEXOS: Acta de Matrimonio. Acta de Nacimiento de cada persona que se dara de alta";
    let { params } = useParams();

    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pURLSolSegFianID, pEmpresaID, pUsuarioSolicita, datosDetalle, pfolioFormateado, pURLPolizasSeguroID, pSolicitud } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nSolicitud = pSolicitud;

    }
    let { onCerrarModal, onOcultarTabla } = datosDetalleURL;
    mostrarTabla = URLSolSegFianID ? true : false;

    if (datosDetalleURL != undefined && datosDetalleURL.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pEstatus, pSolSegFianID, pGtosMedMayID, pEmpresaID, pUsuarioSolicita, onCerrarModal, mostrarTabla, onOcultarTabla, pfolioFormateado, pURLPolizasSeguroID, pSolicitud } = datosDetalle;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSolSegFianID = pSolSegFianID;
        mostrarTabla = mostrarTabla;
        nGtosMedMayID = pGtosMedMayID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita = pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;

    }
    mostrarTabla = lMostrarFormularioCompleto;
    //let {pGtosMedMayID,pSolSegFianID,onCerrarModal,mostrarTabla,onOcultarTabla} = props.datosDetalle;
    const { token } = useSelector(state => state.login);
    const formato = "YYYY-MM-DD";
    const cleanState = {
        gtosMedMayID: nGtosMedMayID,
        solSegFianID: URLSolSegFianID,
        materno: "",
        paterno: "",
        nombres: "",
        fechaNacimiento: null,
        edad: "",
        parentesco: "",
        motivo: "",
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1"
    };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };

    const cleanErrorState = {
        gtosMedMayID: { error: false, helperText: "" },
        solSegFianID: { error: false, helperText: "" },
        materno: { error: false, helperText: "" },
        paterno: { error: false, helperText: "" },
        nombres: { error: false, helperText: "" },
        fechaNacimiento: { error: false, helperText: "" },
        edad: { error: false, helperText: "" },
        parentesco: { error: false, helperText: "" },
        motivo: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [stateMultiple, setStateMultiple] = useState(cleanStateMultiple);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [predeterminados, setPredeterminados] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.gtosMedMayID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Gastos Médicos`;

    const gtosMedMayIDRef = useRef(null);
    const solSegFianIDRef = useRef(null);
    const maternoRef = useRef(null);
    const paternoRef = useRef(null);
    const nombresRef = useRef(null);
    const fechaNacimientoRef = useRef(null);
    const edadRef = useRef(null);
    const parentescoRef = useRef(null);
    const motivoRef = useRef(null);
    const divisaIDRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "gtosMedMayID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getGastoMedico,
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "gtosMedMayID" },
        { campo: "paterno" }, { campo: "materno" }, { campo: "nombres" }],
        disabled: { multiseleccion: true },
        onClick: ({ gtosMedMayID, paterno, materno, nombres }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro de ${paterno} ${materno} ${nombres}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { gtosMedMayID }, asyncDelete: deleteGastosMedicos
            })
        })
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "gtosMedMayID" }],
        disabled: { multiseleccion: true },
        onClick: ({ gtosMedMayID }) => {
            //setRedireccionarFormulario({ gtosMedMayID });
            setDatosDetalleAdjuntos({
                pURLDoctoOrigenID: gtosMedMayID,
                pTipo: cfolioFormateado,
                pClasificacion: "",
                pTabla: "Solicitud Seguro Gastos Medicos",
                pTitulo2: cTitulo2,
                plUtilizaProps: true
            });
            setOpenDocumentacion(true);
        }
    }];

    let botones = [{
        icono: BackspaceOutlined,
        color: danger,
        descripcion: "Regresar",
        disabled: { multiseleccion: true },
        onClick: () => {
            //history.push(`/gen/SolicitudesSeguros/`);
            setOpenSolSegGastosMedicos();
        }
    },  {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];


    if (cEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setOpen(true); divisas()},
            disabled: { multiseleccion: true }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "gtosMedMayID",
        color: primary,
        title: "Gastos Médicos",
        title2: cTitulo2,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "gtosMedMayID", descripcion: "gtosMedMayID", hide: true },
            { variable: "paterno", descripcion: "Paterno" },
            { variable: "materno", descripcion: "Materno" },
            { variable: "nombres", descripcion: "Nombre(s)" },
            { variable: "fechaNacimiento", descripcion: "Fecha de Nacimiento" },
            { variable: "edad", descripcion: "Edad" },
            { variable: "parentesco", descripcion: "Parentesco" },
            { variable: "motivo", descripcion: "Motivo Alta / Baja" },
        ],
        // formato: [
        //     {
        //         columnas: ["area"], tipo: "custom", callback: (value) => {
        //             return value.toUpperCase();
        //         }
        //     },
        // ],
        responsiveTitle: ["departamento"],
        filter: [
            { campo: "departamento", placeholder: "Departamento" },
            { campo: "area", placeholder: "Área" },
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [
        {
            disabled: InputsDisabled(),
            id: "paterno",
            value: state.paterno,
            error: errorState.paterno.error,
            success: state.paterno && !errorState.paterno.error ? true : undefined,
            helperText: errorState.paterno.helperText,
            inputRef: paternoRef,
            title: "Paterno *",
            placeholder: "Capture el apellido paterno",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "paterno") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, paterno: { error: state.paterno.length > 100 && true, helperText: state.paterno.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "materno",
            value: state.materno,
            error: errorState.materno.error,
            success: state.materno && !errorState.materno.error ? true : undefined,
            helperText: errorState.materno.helperText,
            inputRef: maternoRef,
            title: "Materno *",
            placeholder: "Capture el apellido materno",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "materno") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, materno: { error: state.materno.length > 100 && true, helperText: state.materno.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "nombres",
            value: state.nombres,
            error: errorState.nombres.error,
            success: state.nombres && !errorState.nombres.error ? true : undefined,
            helperText: errorState.nombres.helperText,
            inputRef: nombresRef,
            title: "Nombres *",
            placeholder: "Capture los nombres",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "nombres") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, nombres: { error: state.nombres.length > 100 && true, helperText: state.nombres.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "fechaNacimiento",
            value: state.fechaNacimiento,
            error: errorState.fechaNacimiento.error,
            success: state.fechaNacimiento && !errorState.fechaNacimiento.error ? true : undefined,
            helperText: errorState.fechaNacimiento.helperText,
            inputRef: fechaNacimientoRef,
            // title: "Fecha Nacimiento *",
            placeholder: "Fecha Nacimiento",
            tipo: "datetimepicker",
            onlyDate: true,
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: (value) => {
                // handleChange(value.format(formato), "fechaNacimiento")
                typeof value === "object" && setState({ ...state, fechaNacimiento: value.format(formato), edad: getEdad(value) })
            },
        },
        {
            disabled: InputsDisabled(),
            id: "edad",
            value: state.edad,
            error: errorState.edad.error,
            success: state.edad && !errorState.edad.error ? true : undefined,
            helperText: errorState.edad.helperText,
            inputRef: edadRef,
            title: "Edad *",
            placeholder: "Capture la edad",
            grid: { sm: 12, md: 4, lg: 4 },
            // onChange: ({ target: { value } }) =>{ handleChange(value, "edad")},
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, edad: { error: state.edad.length > 100 && true, helperText: state.edad.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: InputsDisabled(),
            id: "parentesco",
            value: state.parentesco,
            error: errorState.parentesco.error,
            success: state.parentesco && !errorState.parentesco.error ? true : undefined,
            helperText: errorState.parentesco.helperText,
            inputRef: parentescoRef,
            title: "Parentesco *",
            placeholder: "Capture el parentesco",
            grid: { sm: 12, md: 4, lg: 4 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "parentesco") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, parentesco: { error: state.parentesco.length > 100 && true, helperText: state.parentesco.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled,
            id: "motivo",
            value: state.motivo,
            error: errorState.motivo.error,
            success: state.motivo && !errorState.motivo.error ? true : undefined,
            helperText: errorState.motivo.helperText,
            inputRef: motivoRef,
            title: "Motivo Alta/Baja *",
            placeholder: "Capture el motivo",
            grid: { sm: 6, md: 6, lg: 6 },
            onChange: ({ target: { value } }) => { handleChange(value.toUpperCase(), "motivo") },
            onKeyDown: () => { return; },
            inputProps: {
                onBlur: () => setErrorState({ ...cleanErrorState, motivo: { error: state.motivo.length > 100 && true, helperText: state.motivo.length > 100 ? "Límite de caracteres excedido" : "" } })
            }
        },
        {
            disabled: true,
            id: "divisaID",
            value: state.divisaID,
            error: errorState.divisaID.error,
            success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
            helperText: errorState.divisaID.helperText,
            inputRef: divisaIDRef,
            title: "Divisa",
            placeholder: "Seleccionar la divisa",
            grid: { md: 3, lg: 3 },
            tipo: "select",
            data: listDivisas,
            onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
        },
        {
            disabled: true,
            id: "paridad",
            value: state.paridad,
            error: errorState.paridad.error,
            success: state.paridad && !errorState.paridad.error ? true : undefined,
            helperText: errorState.paridad.helperText,
            title: "Paridad Actual ",
            textAlign: "right",
            placeholder: "Paridad Actual",
            grid: { md: 3, lg: 3 },
            onChange: ({ target: { value } }) => { handleChange(value, "paridad") }
        },
    ];

    function InputsDisabled() {
        return false;
}

    let accionesFormulario = [{
        accionID: state.gtosMedMayID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: paternoRef,
            mensajeCorrecto: `El registro se ${state.gtosMedMayID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    async function handleValidaciones({ paterno, materno, nombres, fechaNacimiento, edad, parentesco, motivo }) {

        let error;
        let validaciones = [
            {
                condicion: !paterno || trim(paterno) === "",
                ref: paternoRef, keyError: "paterno",
                mensajeError: "Debe especificar el apellido paterno"
            },
            {
                condicion: !materno || trim(materno) === "",
                ref: maternoRef, keyError: "materno",
                mensajeError: "Debe especificar el apellido materno"
            },
            {
                condicion: !nombres || trim(nombres) === "",
                ref: nombresRef, keyError: "nombres",
                mensajeError: "Debe especificar los nombres"
            },
            {
                condicion: !fechaNacimiento || trim(nombres) === "",
                ref: fechaNacimientoRef, keyError: "fechaNacimiento",
                mensajeError: "Debe especificar una fecha válida"
            },
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    
    async function handleChangeDivisa (value, key) {
        if (URLPolizasSeguroID) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
            setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
        }
    }
    
    async function divisas(){
        if (URLPolizasSeguroID && Number(URLPolizasSeguroID) !== 0) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
         } else {
             let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);        
             setState({ ...state, ["paridad"]: historialParidades[0].paridad });
         }
    }


    function ftGuardar() {
        async function ftGuardar() {

            let { solSegFianID, gtosMedMayID, paterno, materno, nombres, fechaNacimiento, edad, parentesco, motivo, divisaID,paridad } = state;

            let error = await handleValidaciones({ paterno, materno, nombres, fechaNacimiento, edad, parentesco, motivo });
            if (edad < 0) throw new Error("La fecha de nacimiento no es válida")

            if (error) return error;

            if (gtosMedMayID) await updateGastosMedicos({
                input: { solSegFianID, gtosMedMayID, paterno, materno, nombres, fechaNacimiento, edad: Number(edad), parentesco, motivo, divisaID, paridad: paridad === undefined ? null : (paridad === "" ? null : paridad) }
            }, token);
            else await addGastosMedicos({
                input: { solSegFianID, paterno, materno, nombres, fechaNacimiento, edad: Number(edad), parentesco, motivo, divisaID, paridad: paridad === undefined ? null : (paridad === "" ? null : paridad) }
            }, token);
        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        if (!mostrarTabla) {
            if (onCerrarModal != undefined) { onCerrarModal(); }

        } else {
            if (onCerrarModal != undefined) { onCerrarModal(); }
            if (onOcultarTabla != undefined) { onOcultarTabla(); }
        }
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function setRedireccionarFormulario({ gtosMedMayID }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${gtosMedMayID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Seguro Gastos Medicos", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let areas = await seleccionArea({ filtro: {} }, token);
                let empresas = await seleccionEmpresa({ filtro: {} }, token);
                setSeleccionables({ areas, empresas });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaGastosMedicos}
            token={token}
            parametrosFijos={{ solSegFianID: URLSolSegFianID }}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={paternoRef}
        />
        {
            openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos} setOpenDocumentacion={() => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default GastosMedicos;
