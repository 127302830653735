
export const info = "info";
export const danger = "danger";
export const success = "success";
export const warning = "warning";
export const primary = "primary";
export const rose = "rose";
export const white = "white";
export const black = "black";
export const it = "default";
