import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor, strUrlSArchivos } from '../../../helpers';
export const tablaEmpresa = async (variables, token) => {
    let Query = `
    query filterEmpresa($filtro: InputFilterEmpresa){
        filterEmpresa(filtro:$filtro){
        listado{
            empresaID,
            nombre,
            nombreCorto,
            siglas,
            activa
        }
        registros
        paginas
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);


    let newArray = tResult.listado.filter((a) => a.nombre !== "EXTERNO"); 

       tResult = {
        listado: newArray,
        paginas: tResult.paginas,
        registros: tResult.registros
    }
    return tResult;
}

export const getEmpresa = async (variables, token) => {
    let Query = `
    query get($filtro: InputGetEmpresa){
        getEmpresa(filtro:$filtro) {
            empresaID
            nombre,
            nombreCorto,
            siglas,
            rfc,
            cp,
            calle,
            noInt,
            noExt,
            colonia,
            entreCalles,
            telefono,
            municipio,
            localidad,
            estado,
            pais,
            activa,
            logoEmpresa
        }
    }
    `;

    let resultado = await Ejecutar({filtro : variables}, token, Query);

    if (resultado === null) {
        return {};
    }

    for (const [key, value] of Object.entries(resultado)) {
        if (value == null) {
            resultado[key] = "";
        }
    }

    resultado["logoEmpresaSinConcatenar"] = resultado["logoEmpresa"];
    let foto1 = resultado.logoEmpresa ? `${strUrlSArchivos}${'/public/segumex'}${resultado.logoEmpresa}${'?'}${Math.random()}` : "";
    resultado["logoEmpresa"] = foto1;

    return resultado;
}

export const seleccionEmpresa = async (variables, token) => {
    try {

        let allEmpresa = await getAllEmpresa(variables, token);

        if(!Array.isArray(allEmpresa)) throw new Error("No se encontraron registros");

        return allEmpresa.map(({ empresaID:value, nombreCorto:label }) => ({ value: parseInt(value), label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const seleccionEmpresaDanio = async (variables, token) => {
    try {

        let allEmpresa = await getAllEmpresa
        (variables, token);


        if(!Array.isArray(allEmpresa)) throw new Error("No se encontraron registros");

        let arreglo1 = [];

        arreglo1.push({label: "Tercero", value: "Tercero"});
        arreglo1.push({label: "Mercancia", value: "Mercancia"});
        allEmpresa.forEach( ({siglas}) => {
                arreglo1.push({label: siglas, value: siglas});
            });

        return arreglo1;

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const getAllEmpresa = async (variables, token) => {
    let Query = `
    query getAllEmpresa($filtro: InputGetEmpresa){
        getAllEmpresa(filtro:$filtro){
            empresaID
            nombre
            nombreCorto
            siglas
        }
    }
    `;
    let tResult = await Ejecutar(variables, token, Query);
    return tResult;
}

export const addEmpresa = async (variables, token) => {
    let Query = `
    mutation addEmpresa($input: InputAddEmpresa!) {
        addEmpresa(input: $input) {
            empresaID
            nombre,
            nombreCorto,
            siglas,
            rfc,
            cp,
            calle,
            noInt,
            noExt,
            colonia,
            entreCalles,
            telefono,
            municipio,
            localidad,
            estado,
            pais,
            activa
        }
    }
    `;

    let tResult = await Ejecutar({input : variables}, token, Query);

    return tResult;
};

export const updateEmpresa = async (variables, token) => {
    let Query = `
    mutation updateEmpresa($input: InputUpdateEmpresa!) {
        updateEmpresa(input: $input) {
            empresaID
            nombre,
            nombreCorto,
            siglas,
            rfc,
            cp,
            calle,
            noInt,
            noExt,
            colonia,
            entreCalles,
            telefono,
            municipio,
            localidad,
            estado,
            pais,
            activa
        }
    }
    `;

    let tResult = await Ejecutar({input : variables}, token, Query);

    return tResult;
};

export const deleteEmpresa = async (variables, token) => {
    let Query = `
    mutation deleteEmpresa($input: InputDeleteEmpresa) {
        deleteEmpresa(input: $input)
    }
    `;

    let tResult = await Ejecutar({input : variables}, token, Query);

    return tResult;
};

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch(error) { throw new Error(error.message); }
}
