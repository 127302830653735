// Redux
/*
import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
*/
import Empresas from '../General/Empresas';
import Usuarios from '../General/Usuarios';
import ActivosAsegurado from '../General/ActivosAsegurado';
import Divisas from '../General/Divisa';
import RamoSeguro from '../General/RamoSeguro';
import Automoviles from '../General/ActivosAsegurado/Automoviles';
import EquipoOperacion from '../General/ActivosAsegurado/EquipoOperacion';
import EquipoElectronico from '../General/ActivosAsegurado/EquipoElectronico';
import EquipoTransporte from '../General/ActivosAsegurado/EquipoTransporte';
import TransporteMercancia from 'componentes/General/SolicitudesSeguros/TransporteMercancia.js';
import SolSegFianAuto from 'componentes/General/SolSegFianAuto/SolSegFianAuto.js';
import SolSegFianEquipoElec from 'componentes/General/SolicitudesSeguros/SolSegEquipoElectrico.js';
import SolSegFianEquipoOper from 'componentes/General/SolicitudesSeguros/SolSegEquipoOperacion.js';
import OtrosSegurosyFianzas from 'componentes/General/SolicitudesSeguros/OtrosSegurosyFianzas.js';
import GastosMedicos from 'componentes/General/SolicitudesSeguros/SolSegGastosMedicos.js';
import SeguroViajero from 'componentes/General/SolicitudesSeguros/SolSegViajero.js';
import FotosEvidencia from '../General/FotosEvidencia';
import ProgramaAnual from '../General/ProgramaAnual';
import ProgramaAnualGantt from 'componentes/General/ProgramaAnualGantt';
import TareasProgAnual from 'componentes/General/TareasProgAnual';
import ActividadesDeTareas from 'componentes/General/ActividadesDeTareas';
import SeguimientoActividades from 'componentes/General/SeguimientoActividades';
import Recorridos from '../General/Recorridos';
import RecorridosDetalle from 'componentes/General/Recorridos/RecorridosDetalle.js';
import Areas from '../General/Areas';
import Aseguradoras from '../General/Aseguradoras';
import AgentesSeguros from '../General/AgentesSeguros';
import Departamentos from '../General/Departamento';
import Ubicaciones from '../General/Ubicaciones';
import Afianzadoras from '../General/Afianzadoras';
import SolicitudesSeguros from '../General/SolicitudesSeguros';
import Documentacion from '../General/Documentacion';
import PolizaSegAuto from 'componentes/General/PolizaSegAuto';
import PolizasSegFian from '../General/PolizasSegFian';
import ReportesInventarios from '../General/Reportes';
import ReporteDanios from '../General/ReporteDanios';
import ReporteDaniosClientes from '../General/ReporteDaniosClientes';
import ReporteDaniosGerencia from '../General/ReporteDaniosGerencia';
import SiniestrosReclamados from '../General/SiniestrosReclamados';
import PresupuestoAnual from '../General/PresupuestoAnual';
import CalendarioReclamos from '../General/CalendarioReclamos';
import SeguimientoReclamos from '../General/SeguimientosReclamos';
import ReclamacionATerceros from '../General/Reclamaciones/ReclamacionATerceros.js';
import HistorialParidades from '../General/Divisa/HistorialParidades';
import Bitacora from '../General/Bitacora';
import ControlPagos from '../General/ControlPago'
import ControlPagoReporteDanios from '../General/ControlPagoReporteDanios';
import Inicio from 'componentes/General/Inicio'
import PolizasSeguro from '../General/PolizasSeguro';
import ReporteAccidenteLaboral from '../General/ReporteAccidenteLaboral';
import AgendaPrograma from '../General/AgendaProgramaAnual/';
import SeguimientoTareaPorDia from '../General/SeguimientoTareaPorDia';
import TrazabilidadSolicitudesSeguro from '../General/TrazabilidadSolicitudesSeguro';
import CodifDocumentos from '../General/CodifDocumentos';
import Encuesta from '../General/Encuesta';
import EncuestaPlantilla from '../General/PlantillaEncuesta';
import Grupos from '../../componentes/General/Grupos'
import ActivosSolicitudes from '../../componentes/General/ActivosSolicitudes';
import Perfiles from '../General/Perfiles';
//--------------- Iconos ---------------//
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import GroupIcon from '@material-ui/icons/Group';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import BuildIcon from '@material-ui/icons/Build';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RoomIcon from '@material-ui/icons/Room';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SpellcheckOutlinedIcon from '@material-ui/icons/SpellcheckOutlined';
import FlightTakeoffOutlinedIcon from '@material-ui/icons/FlightTakeoffOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ContactsIcon from '@material-ui/icons/Contacts';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DescriptionIcon from '@material-ui/icons/Description';
import Security from '@material-ui/icons/Security';
import Policy from '@material-ui/icons/Policy';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import HomeIcon from '@material-ui/icons/Home';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import CameraAlt from '@material-ui/icons/CameraAlt';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SiGroupon } from "react-icons/si";
import { RiArchiveFill } from "react-icons/ri";
import { FaTasks } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { AiFillFolder } from "react-icons/ai";
import { FaRoute } from "react-icons/fa";
import { RiSurveyLine } from "react-icons/ri";
import { ImWarning } from "react-icons/im";
import { BsBuilding } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";
import { VscRequestChanges } from "react-icons/vsc";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

export let ProcesosLayout = {};

let Menus = {};
AgregarMenu(-1, "Perfiles", GroupIcon, "/Perfiles", Perfiles);
AgregarMenu(1, "Inicio", HomeIcon, "/Inicio", Inicio);
AgregarMenu(null, "Catálogos", AiFillFolder);
AgregarMenu(2, "Empresas", BsBuilding, "/Empresas", Empresas, "Catálogos");
AgregarMenu(3, "Usuarios", AccountCircle, "/usuarios", Usuarios, "Catálogos");
AgregarMenu(4, "Ubicaciones", RoomIcon, "/ubicaciones", Ubicaciones, "Catálogos");
AgregarMenu(5, "Areas", FontDownloadIcon, "/Areas", Areas, "Catálogos");
AgregarMenu(6, "Departamentos", LocationCityIcon, "/departamentos", Departamentos, "Catálogos");
AgregarMenu(7, "Agentes de Seguro", AccountBoxOutlinedIcon, "/AgentesSeguros", AgentesSeguros, "Catálogos");
AgregarMenu(8, "Aseguradoras", AssignmentIndIcon, "/Aseguradoras", Aseguradoras, "Catálogos");
AgregarMenu(9, "Afianzadoras", SupervisedUserCircleIcon, "/Afianzadoras", Afianzadoras, "Catálogos");
AgregarMenu(10, "Ramos de Seguros", LibraryAddCheckIcon, "/RamoSeguros", RamoSeguro, "Catálogos");
AgregarMenu(11, "Divisas", AttachMoneyIcon, "/Divisas", Divisas, "Catálogos");
AgregarMenu(44, "Codificación de Documentos", FileCopyIcon, "/Documentacion", CodifDocumentos, "Catálogos");
AgregarMenu(12, "Historial de Paridades", AttachMoneyIcon, "/HistorialParidades/:params", HistorialParidades, "", true);
AgregarMenu(null, "Activos Asegurar", MdOutlineSecurity, "", null, "Catálogos");
AgregarMenu(13, "Equipos de Operaciones", BuildIcon, "/Equipos_de_Operaciones", EquipoOperacion, "Activos Asegurar", false, "/Activos_Asegurados");
AgregarMenu(14, "Equipo Electrónico", ImportantDevicesIcon, "/Equipo_Electronico", EquipoElectronico, "Activos Asegurar", false, "/Activos_Asegurados");
AgregarMenu(15, "Camiones y Chasis", LocalShippingIcon, "/Equipo_de_Transporte", EquipoTransporte, "Activos Asegurar", false, "/Activos_Asegurados");
AgregarMenu(16, "Automóviles", DriveEtaIcon, "/Automoviles", Automoviles, "Activos Asegurar", false, "/Activos_Asegurados");
AgregarMenu(null, "Procesos de Seguros", SpellcheckOutlinedIcon);
AgregarMenu(17, "Solicitudes de Seguro", VscRequestChanges, "/SolicitudesSeguros/:params", SolicitudesSeguros, "Procesos de Seguros");
AgregarMenu(18, "Transporte Mercancia", LocalShippingIcon, "/transporteMercancia/:params", TransporteMercancia, "Procesos de Seguros", true);
AgregarMenu(19, "Gastos Médicos", LocalHospitalIcon, "/gastosmedicos/:params", GastosMedicos, "Procesos de Seguros", true);
AgregarMenu(20, "Solicitud seguros de auto", DesktopMacIcon, "/SolSegFianAuto/:params", SolSegFianAuto, "Procesos de Seguros", true);
AgregarMenu(21, "Solicitud seguros de Equipo Electrico", DesktopMacIcon, "/SolSegFianEquipoElec/:params", SolSegFianEquipoElec, "Procesos de Seguros", true);
AgregarMenu(22, "Solicitud seguros de Equipo de Operaciones", DesktopMacIcon, "/SolSegFianEquipoOper/:params", SolSegFianEquipoOper, "Procesos de Seguros", true);
AgregarMenu(23, "Seguro del Viajero", FlightTakeoffOutlinedIcon, "/SeguroViajero/:params", SeguroViajero, "Procesos de Seguros", true);
AgregarMenu(24, "Otros Seguros y Fianzas", FlightTakeoffOutlinedIcon, "/OtrosSegurosyFianzas/:params", OtrosSegurosyFianzas, "Procesos de Seguros", true);
AgregarMenu(null, "Pólizas de Seguros y Fianzas", AssignmentIcon, "", "", "Procesos de Seguros");
AgregarMenu(25, "Pólizas de Seguros", Policy, "/PolizasSeguro", PolizasSeguro, "Pólizas de Seguros y Fianzas");
AgregarMenu(26, "Pólizas de Autos", Security, "/PolizaSegAuto", PolizaSegAuto, "Pólizas de Seguros y Fianzas", true);
AgregarMenu(27, "Pólizas de Fianzas", Policy, "/PolizasSegFianzas", PolizasSegFian, "Pólizas de Seguros y Fianzas", false);
AgregarMenu(28, "Control de Pagos", AccountBalanceWalletIcon, "/ControlPagos", ControlPagos, "Procesos de Seguros");
AgregarMenu(null, "Procesos de Siniestros", ImWarning);
AgregarMenu(29, "Reporte de daños", ReportProblemIcon, "/ReporteDanios", ReporteDanios, "Procesos de Siniestros");
AgregarMenu(30, "Reporteador de daños", SyncProblemIcon, "/ReporteadorDeDanios/:params", ReporteDanios, "Procesos de Siniestros");
AgregarMenu(31, "Reporte de daños (Servicio a Clientes)", RecordVoiceOverIcon, "/RepDaniosDeClientes", ReporteDaniosClientes, "Procesos de Siniestros");
AgregarMenu(32, "Reporte de daños (Gerencia)", SupervisorAccountIcon, "/RepDaniosGerencia", ReporteDaniosGerencia, "Procesos de Siniestros");
AgregarMenu(33, "Reporte de Accidente Laboral", ReportProblemIcon, "/ReporteAccidenteLaboral", ReporteAccidenteLaboral, "Procesos de Siniestros");
AgregarMenu(null, "Fotos de Evidencia", CameraAlt, "/FotosEvidencia/:params", FotosEvidencia, "Procesos de Siniestros", true);
AgregarMenu(34, "Fotos de Evidencia", CameraAlt, "/FotosEvidencia", FotosEvidencia, "Procesos de Siniestros");
AgregarMenu(45, "Control de Pagos (Siniestros)", AccountBalanceWalletIcon, "/ControlPagoReporteDanios", ControlPagoReporteDanios, "Procesos de Siniestros");
AgregarMenu(null, "Reclamaciones", AssignmentIcon, "", "", "Procesos de Siniestros");
AgregarMenu(35, "Reclamos a Terceros", ContactsIcon, "/ReclamacionATerceros", ReclamacionATerceros, "Reclamaciones");
AgregarMenu(36, "Reclamos a Aseguradoras", AnnouncementIcon, "/SiniestrosReclamados", SiniestrosReclamados, "Reclamaciones");
AgregarMenu(null, "Adjuntar documentos", DescriptionIcon, "/Documentos/:params", Documentacion, "Procesos", true);
AgregarMenu(37, "Reportes", MdPictureAsPdf, "/Reportes", ReportesInventarios);
AgregarMenu(38, "Presupuesto anual", FaRegMoneyBillAlt, "/PresupuestoAnual", PresupuestoAnual);
AgregarMenu(39, "Programa Anual", IoCalendarNumberOutline, "/ProgramaAnual", ProgramaAnual);
// AgregarMenu(null, "Gráfica de programa anual ", EqualizerIcon, "/_ProgramaAnualGantt", ProgramaAnualGantt, "", "", true);
AgregarMenu(null, "Agenda Actividades", IoCalendarNumberOutline, "/AgendaActividades/:params", AgendaPrograma, "", true);
AgregarMenu(null, "Seguimiento Por Dia", IoCalendarNumberOutline, "/SeguimientoTareaPorDia/:params", SeguimientoTareaPorDia, "", true);
AgregarMenu(null, "Tareas Programa anual", AccountBalanceIcon, "/TareasProgAnual/:params", TareasProgAnual, "", true);
AgregarMenu(null, "Seguimiento de Actividades", IoCalendarNumberOutline, "/SeguimientoActividades/", SeguimientoActividades, "", true);
AgregarMenu(40, "Recorridos de instalaciones", FaRoute, "/Recorridos/", Recorridos);
AgregarMenu(41, "Bitácora", FaTasks, "/Bitacora", Bitacora);
AgregarMenu(null, "Trazabilidad", AccountBalanceIcon, "/TrazabilidadSolicitudesSeguro/:params", TrazabilidadSolicitudesSeguro, "", true);
AgregarMenu(45, "Calendario de Reclamos", IoCalendarNumberOutline, "/CalendarioReclamos", CalendarioReclamos,"Reclamaciones");
AgregarMenu(null, "Seguimiento Reclamos", IoCalendarNumberOutline, "/SeguimientoReclamos/:params", SeguimientoReclamos, "", true);
//AgregarMenu(46, "Encuesta", RiSurveyLine, "/Encuesta", Encuesta);
AgregarMenu(null, "Encuesta", RiSurveyLine, "/Encuesta", Encuesta);
AgregarMenu(47, "Encuesta-Plantilla", RiSurveyLine, "/EncuestaPlantilla", EncuestaPlantilla, "", true);
AgregarMenu(48, "Grupos", SiGroupon, "/Grupos", Grupos, "Catálogos", false);
AgregarMenu(49, "Activos", RiArchiveFill, "/ActivosSolicitudes", ActivosSolicitudes, "Catálogos", false);

function AgregarMenu(id, titulo, icono, ruta = "", componente, agrupador = "", hide = false, layout = "/gen") {

    if (id) {
        ProcesosLayout[layout + ruta] = id;
    }

    Menus[titulo] = {
        id,
        colapsar: false,
        titulo,
        icono,
        vistas: [],
        ruta,
        componente,
        layout: layout,
        hide,
        agrupador: "",
        estaAgrupado: false,
        nivel: 1
    }

    if (Menus[agrupador]) {
        let vistas = Menus[agrupador]["vistas"];
        Menus[agrupador].colapsar = true;
        Menus[agrupador].agrupador = agrupador;

        Menus[titulo].estaAgrupado = true;
        Menus[titulo].nivel = (Menus[agrupador].nivel + 1);
        vistas = vistas.push(Menus[titulo]);

        delete Menus[agrupador]["ruta"];
        delete Menus[agrupador]["componente"];
        delete Menus[agrupador]["layout"];
        delete Menus[agrupador]["hide"];
    }
}

function Menu() {
    let ListaMenus = [];
    for (let tMenu in Menus) {
        if (Menus[tMenu]["estaAgrupado"] === false) {
            
            ListaMenus.push(Menus[tMenu]);
        }
    }

    return ListaMenus;
}

export default { Menu, ProcesosLayout };
