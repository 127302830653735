import React, { useState, useEffect } from 'react';
import { Encriptar, Validaciones } from '@renedelangel/helpers';
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import './Style.css';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Boton from './Boton'
import Close from "@material-ui/icons/Close";
import Mail from '@material-ui/icons/Mail';
import SaveIcon from '@material-ui/icons/Save';
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import * as Consultas from '../Encuesta/Consultas.js'
import SeccionI from './SeccionI';
import SeccionII from './SeccionII';
import SeccionIII from './SeccionIII';
import SeccionIV from './SeccionIV';
import SeccionV from './SeccionV';
import SeccionVI from './SeccionVI';
import SeccionVII from './SeccionVII';
import SeccionVIII from './SeccionVIII';
import SeccionIX from './SeccionIX';
import SeccionX from './SeccionX';
import SeccionXI from './SeccionXI';
import SeccionXII from './SeccionXII';
import SeccionXIII from './SeccionXIII';
import SeccionXIV from './SeccionXIV';
import SeccionXV from './SeccionXV';
import SeccionFirmas from './SeccionFirmas';

import { actualizaArchivo } from "../../../helpers/serverStorage";
import * as ConsultasDominios from '../../../querys/Dominios';

import { danger, success } from '../../../_layout/helpers/colores';

import { handleAlertGeneric, handleAlertMutationGeneric } from '_layout/helpers/handles';
import { urlServidor } from 'helpers';

const { Decrypt } = Encriptar;
function PlantillaEncuesta({info = null, objProps = {}, openFromEncuesta = false, agregarFirmas = false,handleCloseVerPreguntas}) {
  let { token } = useParams();
  let tokenFijo = 'c29mdHdvbmUyMDIwQCM='
  let url = "";

  const { trim } = Validaciones;

  let seed = "segumex";
  if (token && !openFromEncuesta) {
    url = token.replace(/&/g, "/");
    objProps = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
    info = { token: token }
  } else if(openFromEncuesta){
    objProps = info;
    token = info.token;
  }

  if(agregarFirmas){
    openFromEncuesta = false
  }
  const cleanState = {
    correo: "",
    dominio: "",
    accion:""
};
  const [alert, setAlert] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const [stSeccionesVisibles, setSeccionesVisibles] = useState([]);
  const [stSecciones, setSecciones] = useState([]);
  const [stDisabled, setDisabled] = useState(false);
  const [stateContestada, setContestada] = useState(false);
  const [actualizar, setActualizar] = useState(false);
  const [openEnviarCorreo, setOpenEnviarCorreo] = useState(false);
  const [seleccionables, setSeleccionables] = useState(false);
  const [state, setState] = useState(cleanState);


  let { encuestaID, seccionID, ejercicio, estatus = 'PENDIENTE' } = objProps;

  useEffect(() => {
    async function ftEffect() {
      try {
        
        let Dominios = await ConsultasDominios.seleccionDominios({}, token);
        setSeleccionables({ Dominios });

        if (seccionID) {
          let regSeccion = await Consultas.getEncuestaSecciones({ encuestaID, seccionID }, token);
          if (!regSeccion) {
            setErrorState(true);
            return;
          }
          let seccionesAux = {};
          seccionesAux[regSeccion.seccion] = regSeccion;
          setSecciones(seccionesAux);
          setSeccionesVisibles([regSeccion.seccion])
        } else {
          setDisabled(true);
          let regSecciones = await Consultas.getAllEncuestaSecciones({filtro: { encuestaID }}, token);
          let seccionesAux = {};
          let seccionesCve = [];

          for (let i = 0; i < regSecciones.length; i++) {
            let regSeccion = regSecciones[i];
            seccionesCve.push(regSeccion.seccion);
            seccionesAux[regSeccion.seccion] = regSeccion;
          }

          setSecciones(seccionesAux);
          setSeccionesVisibles(seccionesCve);
        }
      } catch ({ message }) {
        setAlert({
          descripcion: message,
          title: "¡Una disculpa!",
          tipo: "danger",
          msjConfirmacion: "Aceptar",
          onConfirm: () => setAlert(null)
        });
        setErrorState(true);
      }
    } ftEffect();
  }, []);

  function handleRespuestaSeccion(seccion, respuesta) {
    let seccionesAux = stSecciones;
    seccionesAux[seccion].respuesta = respuesta;
    setSecciones(seccionesAux);
  }
  
  function handleAdjunto(seccion, adjunto) {
    let seccionesAux = stSecciones;
    seccionesAux[seccion].adjunto = adjunto;
    setSecciones(seccionesAux);
  }

  async function ftGuardarRespuestas(){

    if(agregarFirmas){
       handleAlertGeneric({
        setAlert,
        onCancel: () => setAlert(null),
        mensaje: {
            title: "Revision de Encuesta", descripcion: "¿Desea marcar como revisada la encuesta?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
        },

        onConfirm: () =>  handleAlertMutationGeneric({
        token, setAlert, setActualizar, actualizar,
        parametros: {}, asyncMutation: () => ftGuardar(),
        mensajes: {
            msjEspera: {
                title: "Espere...", descripcion: "Se estan guardando las respuestas", tipo: success
            },
            msjCorrecto: {
                title: "Encuesta Contestada", descripcion: 'Se guardaron sus respuestas correctamente.', msjConfirmacion: "Aceptar"
                  }
              }
        })
      })
    }else{
      handleAlertMutationGeneric({
        token, setAlert, setActualizar, actualizar,
        parametros: {}, asyncMutation: () => ftGuardar(),
        mensajes: {
            msjEspera: {
                title: "Espere...", descripcion: "Se estan guardando las respuestas", tipo: success
            },
            msjCorrecto: {
                title: "Encuesta Contestada", descripcion: 'Se guardaron sus respuestas correctamente.', msjConfirmacion: "Aceptar"
                  }
              }
        })
    }
  }

  async function ftGuardar() {
    try {
        if(agregarFirmas){
          let {listado: datosEncuestaSecciones} = await Consultas.tablaEncuestaSecciones({filtro:{ejercicio: parseInt(ejercicio), count: null, pagina: 1, limite: 20}  }, token);

          for (let i = 0; i < datosEncuestaSecciones.length; i++) {
              if(datosEncuestaSecciones[i].seccion === "FIRMAS"){
                seccionID = parseInt(datosEncuestaSecciones[i].seccionID)
              }
            }
        }


      if (seccionID) {
        for (let i = 0; i < stSeccionesVisibles.length; i++) {
          
          let seccionAux = stSeccionesVisibles[i];

          if(stSecciones[seccionAux].adjunto != undefined) {

          let adjunto = stSecciones[seccionAux].adjunto[0];

          let name = adjunto.name.split(".")
          name = name[0]
          
          let datos = {
            serverStorageID: null,
            origenID: Number(stSecciones[seccionAux].seccionID),
            tipo: "ADJUNTO",
            clasificacion: "ADJUNTOSENCUESTA",
            nombreArchivo: name,
            ruta: `ENCUESTA/SECCION${seccionAux}/`,
            tabla: "ENCUESTA"
        }
          await actualizaArchivo(`ENCUESTA/SECCION${seccionAux}/`, adjunto, 1, datos, 'vacio');
      }

          await Consultas.actualizarRespuestas({ seccionID: stSecciones[seccionAux].seccionID, respuesta: stSecciones[seccionAux].respuesta, agregarFirmas });
          
        }
      }
      setContestada(true)
    } catch ({ message }) {
      setAlert({
        descripcion: message,
        title: "¡Una disculpa!",
        tipo: "danger",
        msjConfirmacion: "Aceptar",
        onConfirm: () => setAlert(null)
      });
    }
  }

  function AuxDescargar() {

    handleAlertGeneric({
        setAlert,
        onCancel: () => setAlert(null),
        mensaje: {
            title: "Generar PDF de encuesta", descripcion: "¿Deseas generar y descargar la encuesta?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
        },

        onConfirm: () => handleAlertMutationGeneric({
            token, setAlert, setActualizar, actualizar,
            parametros: {}, asyncMutation: () => DescargarPdf(),
            mensajes: {
                msjEspera: {
                    title: "Espere...", descripcion: "Generando el formato de impresión de la seccion ", tipo: success
                },
                msjCorrecto: {
                    title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión de la seccion', msjConfirmacion: "Aceptar"
                }
            }
        })
    })
}
const showFile = (archivo, name = 'file.pdf',) => {
    let url = window.URL.createObjectURL(archivo);
    let a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
    a.remove();
};

async function DescargarPdf() {
    let myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("mimeType", "multipart/form-data");
    
    let correo = "";
    correo = state.correo+state.dominio


    let formdata = new FormData();
    formdata.append("estatus", estatus);
    formdata.append("correo", correo);
    formdata.append("ejercicio",info.ejercicio);
    formdata.append("encuestaID", info.encuestaID);
    formdata.append("accion", state.accion);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    };

    await fetch(`${urlServidor}/service/reportes/ReporteEncuesta`, requestOptions)
        .then(response => {
            if (!response.ok) return response.json();
            return response.blob()
        })
        .then(result => {
            let { error, mensaje } = result;
            if (error) throw new Error(mensaje);
            if(state.accion !=="EnviarCorreo"){
              showFile(result, `Encuesta.pdf`);
            }
            setAlert(null)
        })
        .catch(({ message }) => { throw new Error(message) });
}

async function EnviarCorreo() {
  try {
      let validacorreo = state.correo.includes('@');
      if (trim(state.correo) === "" || validacorreo) throw new Error("Debe ingresar un correo valido");
      if (trim(state.dominio) === "") throw new Error("Debe ingresar un dominio");
      handleAlertMutationGeneric({
          token, setAlert, setActualizar, actualizar,
          parametros: {},
          asyncMutation: () => DescargarPdf(),
          mensajes: {
              msjEspera: {
                  title: `Espere por favor`,
                  descripcion: `Enviando encuesta...`
              },
              msjCorrecto: {
                  title: `Correcto`,
                  descripcion: `La encuesta se ha enviado`
              }
          }
      })
      setOpenEnviarCorreo(false)
  } catch ({ message }) {
      window.alert(message)
  }
}

const inputsEnviarCorreo = [
  {
      id: "correo",
      title: "Correo *",
      grid: { md: 6, lg: 6 },
      placeholder: "Capture el correo",
      onChange: ({ target: { value } }) => setState({ ...state, correo: value }),
  },
  {
      id: "dominio",
      title: "Dominio *",
      placeholder: "Seleccione el dominio",
      tipo: "select",
      data: seleccionables.Dominios,
      grid: { md: 6, lg: 6 },
      onChange: ({ target: { value } }) => setState({ ...state, dominio: value }),
  },
]

const accionesFormularioEnviarCorreo = [{
  accionID: "EnviarCorreo",
  icono: Mail,
  color: success,
  descripcion: "Enviar",
  onClick: () => EnviarCorreo()
}, {
  icono: Close,
  color: danger,
  descripcion: "Cancelar",
  onClick: () => setOpenEnviarCorreo(false)
}];

  return (<>
    <div className="cContenedorEncuesta">
    {
      stateContestada ?
      <div className="cErrorEncuesta"> Encuesta contestada
        <br />Se guardaron sus respuestas correctamente.
      </div> : <>
      <div class="ListadoPreguntas" id='listadoEncuestasID'>
        <div class="Contenedor-Titulo">
          <div class="ContenedorLogoTitulo">
            <div class="Logo">
            </div>
            <div class="Titulo">
              {"Cuestionario de Responsabilidad Civil para Operadores de Terminales Portuarias (" + ejercicio + ")"}
            </div>
          </div>
        </div>
        {errorState ? <></> :
          <div className="cContenedorSeccion">
            <div className="cTituloInstruccioes">
             {agregarFirmas ? "Por favor responda las preguntas que sean pertinentes al apartado de firmas." : "Por favor responda las preguntas que sean pertinentes a la operación del solicitante. Si el mismo opera en más de una terminal, rogamos completar un cuestionario para cada una de ellas."}
            </div>
          </div>}
        {
          stSeccionesVisibles.includes("I") &&
          <SeccionI
            respuesta={stSecciones["I"].respuesta}
            setRespuesta={(respuesta) => handleRespuestaSeccion("I", respuesta) }
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("IV") &&
          <SeccionIV
            respuesta={stSecciones["IV"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("IV", respuesta)}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("V") &&
          <SeccionV
            respuesta={stSecciones["V"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("V", respuesta)}
            disabled={stDisabled} />
        }
        {/* {
          stSeccionesVisibles.includes("VI") &&
          <SeccionVI
            respuesta={stSecciones["VI"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("VI", respuesta)}
            disabled={stDisabled} />
        } */}
        {
          stSeccionesVisibles.includes("VII") &&
          <SeccionVII
            respuesta={stSecciones["VII"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("VII", respuesta)}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("VIII") &&
          <SeccionVIII
            respuesta={stSecciones["VIII"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("VIII", respuesta)}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("IX") &&
          <SeccionIX
            respuesta={stSecciones["IX"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("IX", respuesta)}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("X") &&
          <SeccionX
            respuesta={stSecciones["X"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("X", respuesta)}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("XI") &&
          <SeccionXI
            respuesta={stSecciones["XI"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("XI", respuesta)}
            setAdjunto={(adjunto) => handleAdjunto("XI", adjunto)}
            seccionID={seccionID}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("XII") &&
          <SeccionXII
            respuesta={stSecciones["XII"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("XII", respuesta)}
            setAdjunto={(adjunto) => handleAdjunto("XII", adjunto)}
            seccionID={seccionID}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("XIII") &&
          <SeccionXIII
            respuesta={stSecciones["XIII"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("XIII", respuesta)}
            disabled={stDisabled} />
        }
        {
          stSeccionesVisibles.includes("XIV") &&
          <SeccionXIV
            respuesta={stSecciones["XIV"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("XIV", respuesta)}
            disabled={stDisabled} />
        }
        {/* {
          stSeccionesVisibles.includes("XV") &&
          <SeccionXV
            respuesta={stSecciones["XV"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("XV", respuesta)}
            disabled={stDisabled} />
        } */}
        {
          stSeccionesVisibles.includes("FIRMAS") &&
          <SeccionFirmas
            respuesta={stSecciones["FIRMAS"].respuesta}
            ejercicio={ejercicio}
            setRespuesta={(respuesta) => handleRespuestaSeccion("FIRMAS", respuesta)}
            disabled={estatus === 'PENDIENTE' ? false : stDisabled} />
        }
      </div>
      {
        errorState ?
          <div className="cErrorEncuesta">Ocurrió un problema al cargar la encuesta.
            <br />Esto puede deberse a que el link a caducado o la encuesta ya no está disponible.
            <br />Te pedimos amablemente una disculpa y de ser posible nos informes de este error
          </div> : <></>
      }
      {
        // !openFromEncuesta ?
        (errorState?
        <>
        </>:        
                <div className='cContenedorBotonesEncuesta'>
                  <div className='cContenedorBotonesEncuesta' style={{ display: 'flex', flexDirection: 'row' }}>
                    {
                      info.accion === 'RevisarEncuesta' &&
                      <Boton titulo={"Generar PDF"}
                        colorFondo="#388e3c"
                        colorletra="white"
                        onClick={() => { setState({ ...state, accion:"GenerarPDF" }); AuxDescargar()}} />
                    }
                    {
                      <Boton titulo={"Guardar respuesta"}
                        colorFondo="var(--colorInfo)"
                        colorletra="white"
                        onClick={() => ftGuardarRespuestas()} />
                    }
                    {
                      info.accion === "RevisarEncuesta" && <Boton titulo={"Enviar por correo"}
                        colorFondo="#0D3C61"
                        colorletra="white"
                        onClick={() => { setOpenEnviarCorreo(true); setState({ ...state, accion:"EnviarCorreo" }) }}
                      />
                    }
                     {
                      info.accion === 'RevisarEncuesta' &&
                      <Boton titulo={"Cancelar"}
                        colorFondo="#F31818"
                        colorletra="white"
                        onClick={() => { handleCloseVerPreguntas()}} />
                    }
                  </div>
                </div>
        )
      } 
      </>
      }
      {openEnviarCorreo && <FormularioModal
                open={openEnviarCorreo}
                title={"Enviar por correo"}
                onClose={() => setOpenEnviarCorreo(false)}
                inputs={inputsEnviarCorreo}
                acciones={accionesFormularioEnviarCorreo}
            />
        } 
      {alert &&
        <SweetAlert
          title={alert.title}
          descripcion={alert.descripcion}
          tipo={alert.tipo}
          msjConfirmacion={alert.msjConfirmacion}
          msjCancelacion={alert.msjCancelacion}
          onConfirm={alert.onConfirm}
          showConfirm={alert.showConfirm}
          showCancel={alert.showCancel}
          onCancel={() => setAlert(null)}
        />
      }
    </div>
  </>);
}
export default PlantillaEncuesta;