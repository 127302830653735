import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { AiOutlineMail } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

//storage
import { actualizaArchivo, eliminaArchivo } from "../../../helpers/serverStorage";

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import * as ConsultasDominios from '../../../querys/Dominios';
import { getReporteAccidenteLaboral, tablaReporteAccidenteLaboral } from '../../../querys/ReporteAccidenteLaboral';
import { deleteReporteAccidenteLaboral, addReporteAccidenteLaboral, updateReporteAccidenteLaboral } from '../../../mutations/ReporteAccidenteLaboral';

import moment from 'moment';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionAseguradora } from '../../../querys/Aseguradoras';
import { rgxInputTexto3000} from '../../../helpers/regexp';

import Documentacion from '../Documentacion';

import {urlServidor} from '../../../helpers';

import { actualizaArchivoSoloRuta } from "../../../helpers/serverStorage";
import * as Permisos from '../Permisos';

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const {Encrypt,Decrypt}=Encriptar;
function ReporteAccidenteLaboral() {
    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";
    let history = useHistory();
    let cTitulo2 = "ANEXOS: Recuerde adjuntar documento fuente.";
    let remover = false;
    const { token } = useSelector(state => state.login);
    let anioActual = new Date().getFullYear();
    let cFechaFiltroInicial =  anioActual +"0101"  ;
    let cFechaFiltroFinal = anioActual +"1231" ;

    const cleanState = {

        reporteAccidenteLaboralID: null,
        usuarioBitID: 0,
        empresaID: 0,
        folioInterno: 0,
        fechaElaboracion: new Date(),
        numIncidencia: "",
        numNC: "",
        descripBreve: "",
        lugarEvento: "",
        fechaEvento: "",
        horaEvento: "",
        supervCargo: "",
        area: "",
        empresaPersonalAfectadaID: 0,
        puestoHabilidadBase: "",
        puestoHabilidadRealizaba: "",
        nombreTrabajador: "",
        numControlTrabajador: "",
        actividadDesarrollaba: "",
        jornadaDelSuceso: "",
        diaUltimoDescanzo: null,
        portabaEPP: false,
        motivoAusenciaEPP: "",
        iluminacionDeficiente: false,
        condMeteorologicaAdvs: false,
        faltaOrdenLimpieza: false,
        espaciosReducidos: false,
        instalacionMalEstado: false,
        fallasEnProceso: false,
        cortesPorHerramienta: false,
        golpesPorObjetosEnCaida: false,
        caidasAlMismoDifNivel: false,
        concactoSustanciasQuimicas: false,
        atrapamientoExtremidades: false,
        lesionesErgonomicas: false,
        colisionChoque: false,
        quemaduras: false,
        cabeza: false,
        cara: false,
        cuello: false,
        espalda: false,
        pecho: false,
        brazos: "No Aplica",
        manos: "No Aplica",
        piernas: "No Aplica",
        pies: "No Aplica",
        descripDetallada: "",
        fotografias: "",
        existenTestigos: false,
        nombresPersonalTestigo1: "",
        numControlPersonalTestigo1: "",
        puestoHabilidadTestigo1: "",
        nombresPersonalTestigo2: "",
        numControlPersonalTestigo2: "",
        puestoHabilidadTestigo2: "",
        observGenerales: "",
        responAnalisis: "",
        elaboro: "",
        firma: "",
        cargo: "",
        tipoDanioOcasio: "",
        folio: "",
        danioOcasioPor: "",
        danioOcasioARep: "",
        equipoBienPersona: "",
        areaRespRepa: "",
        costoDanio: 0,
        estatus: "",
        tiempoInvertido: "",
        atendio: "",
        notas: "",
        finalizado: 0,
        seguimiento: "",
        fotoEvidencia1: null,
        foto1: null,
        IDimagen1:  null,
        storageID1: null,
        rutafotografia1:"",
        fotografia1:"",
        correoEnviar: "",
        dominio: "@atpaltamira.com.mx",
        nombreEmpresa:""
    };

    const cleanErrorState = {
        reporteAccidenteLaboralID: { error: false, helperText: "" },
        usuarioBitID: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        folioInterno: { error: false, helperText: "" },
        fechaElaboracion: { error: false, helperText: "" },
        numIncidencia: { error: false, helperText: "" },
        numNC: { error: false, helperText: "" },
        descripBreve: { error: false, helperText: "" },
        lugarEvento: { error: false, helperText: "" },
        fechaEvento: { error: false, helperText: "" },
        horaEvento: { error: false, helperText: "" },
        supervCargo: { error: false, helperText: "" },
        area: { error: false, helperText: "" },
        empresaPersonalAfectadaID: { error: false, helperText: "" },
        puestoHabilidadBase: { error: false, helperText: "" },
        puestoHabilidadRealizaba: { error: false, helperText: "" },
        nombreTrabajador: { error: false, helperText: "" },
        numControlTrabajador: { error: false, helperText: "" },
        actividadDesarrollaba: { error: false, helperText: "" },
        jornadaDelSuceso: { error: false, helperText: "" },
        diaUltimoDescanzo: { error: false, helperText: "" },
        portabaEPP: { error: false, helperText: "" },
        motivoAusenciaEPP: { error: false, helperText: "" },
        iluminacionDeficiente: { error: false, helperText: "" },
        condMeteorologicaAdvs: { error: false, helperText: "" },
        faltaOrdenLimpieza: { error: false, helperText: "" },
        espaciosReducidos: { error: false, helperText: "" },
        instalacionMalEstado: { error: false, helperText: "" },
        fallasEnProceso: { error: false, helperText: "" },
        cortesPorHerramienta: { error: false, helperText: "" },
        golpesPorObjetosEnCaida: { error: false, helperText: "" },
        caidasAlMismoDifNivel: { error: false, helperText: "" },
        concactoSustanciasQuimicas: { error: false, helperText: "" },
        atrapamientoExtremidades: { error: false, helperText: "" },
        lesionesErgonomicas: { error: false, helperText: "" },
        colisionChoque: { error: false, helperText: "" },
        quemaduras: { error: false, helperText: "" },
        cabeza: { error: false, helperText: "" },
        cara: { error: false, helperText: "" },
        cuello: { error: false, helperText: "" },
        espalda: { error: false, helperText: "" },
        pecho: { error: false, helperText: "" },
        brazos: { error: false, helperText: "" },
        manos: { error: false, helperText: "" },
        piernas: { error: false, helperText: "" },
        pies: { error: false, helperText: "" },
        descripDetallada: { error: false, helperText: "" },
        fotografias: { error: false, helperText: "" },
        existenTestigos: { error: false, helperText: "" },
        nombresPersonalTestigo1: { error: false, helperText: "" },
        numControlPersonalTestigo1: { error: false, helperText: "" },
        puestoHabilidadTestigo1: { error: false, helperText: "" },
        nombresPersonalTestigo2: { error: false, helperText: "" },
        numControlPersonalTestigo2: { error: false, helperText: "" },
        puestoHabilidadTestigo2: { error: false, helperText: "" },
        observGenerales: { error: false, helperText: "" },
        responAnalisis: { error: false, helperText: "" },
        elaboro: { error: false, helperText: "" },
        firma: { error: false, helperText: "" },
        cargo: { error: false, helperText: "" },
        tipoDanioOcasio: { error: false, helperText: "" },
        folio: { error: false, helperText: "" },
        danioOcasioPor: { error: false, helperText: "" },
        danioOcasioARep: { error: false, helperText: "" },
        equipoBienPersona: { error: false, helperText: "" },
        areaRespRepa: { error: false, helperText: "" },
        costoDanio: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        tiempoInvertido: { error: false, helperText: "" },
        atendio: { error: false, helperText: "" },
        notas: { error: false, helperText: "" },
        finalizado: { error: false, helperText: "" },
        seguimiento: { error: false, helperText: "" },
        fotografia1: { error: false, helperText: "" },
    };


    const cleanNotificaciones = { mensaje: "", color: null, open: false };




    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    let [datosPDF, setdatosPDf] = useState({});
    const [openEnviarCorreo, setOpenEnviarCorreo] = useState(false);

    const [seleccionables,setSeleccionables] = useState(false);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const fotos = {
        foto1: { valor: "", file: "", eliminar: false }
    }
   // let [stDatos, setState] = useState(cleanState);
   const [stFotografias, setFotografias] = useState(fotos);
   const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = state.reporteAccidenteLaboralID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Reporte Accidente Laboral`;

    const reporteAccidenteLaboralIDRef = useRef(null);
    const usuarioBitIDRef = useRef(null);
    const empresaIDRef = useRef(null);
    const folioInternoRef = useRef(null);
    const fechaElaboracionRef = useRef(null);
    const numIncidenciaRef = useRef(null);
    const numNCRef = useRef(null);
    const descripBreveRef = useRef(null);
    const lugarEventoRef = useRef(null);
    const fechaEventoRef = useRef(null);
    const horaEventoRef = useRef(null);
    const supervCargoRef = useRef(null);
    const areaRef = useRef(null);
    const empresaPersonalAfectadaIDRef = useRef(null);
    const puestoHabilidadBaseRef = useRef(null);
    const puestoHabilidadRealizabaRef = useRef(null);
    const nombreTrabajadorRef = useRef(null);
    const numControlTrabajadorRef = useRef(null);
    const actividadDesarrollabaRef = useRef(null);
    const jornadaDelSucesoRef = useRef(null);
    const diaUltimoDescanzoRef = useRef(null);
    const portabaEPPRef = useRef(null);
    const motivoAusenciaEPPRef = useRef(null);
    const iluminacionDeficienteRef = useRef(null);
    const condMeteorologicaAdvsRef = useRef(null);
    const faltaOrdenLimpiezaRef = useRef(null);
    const espaciosReducidosRef = useRef(null);
    const instalacionMalEstadoRef = useRef(null);
    const fallasEnProcesoRef = useRef(null);
    const cortesPorHerramientaRef = useRef(null);
    const golpesPorObjetosEnCaidaRef = useRef(null);
    const caidasAlMismoDifNivelRef = useRef(null);
    const concactoSustanciasQuimicasRef = useRef(null);
    const atrapamientoExtremidadesRef = useRef(null);
    const lesionesErgonomicasRef = useRef(null);
    const colisionChoqueRef = useRef(null);
    const quemadurasRef = useRef(null);
    const cabezaRef = useRef(null);
    const caraRef = useRef(null);
    const cuelloRef = useRef(null);
    const espaldaRef = useRef(null);
    const pechoRef = useRef(null);
    const brazosRef = useRef(null);
    const manosRef = useRef(null);
    const piernasRef = useRef(null);
    const piesRef = useRef(null);
    const descripDetalladaRef = useRef(null);
    const fotografiasRef = useRef(null);
    const existenTestigosRef = useRef(null);
    const nombresPersonalTestigo1Ref = useRef(null);
    const numControlPersonalTestigo1Ref = useRef(null);
    const puestoHabilidadTestigo1Ref = useRef(null);
    const nombresPersonalTestigo2Ref = useRef(null);
    const numControlPersonalTestigo2Ref = useRef(null);
    const puestoHabilidadTestigo2Ref = useRef(null);
    const observGeneralesRef = useRef(null);
    const responAnalisisRef = useRef(null);
    const elaboroRef = useRef(null);
    const firmaRef = useRef(null);
    const cargoRef = useRef(null);
    const tipoDanioOcasioRef = useRef(null);
    const folioRef = useRef(null);
    const danioOcasioPorRef = useRef(null);
    const danioOcasioARepRef = useRef(null);
    const equipoBienPersonaRef = useRef(null);
    const areaRespRepaRef = useRef(null);
    const costoDanioRef = useRef(null);
    const estatusRef = useRef(null);
    const tiempoInvertidoRef = useRef(null);
    const atendioRef = useRef(null);
    const notasRef = useRef(null);
    const finalizadoRef = useRef(null);
    const seguimientoRef = useRef(null);
    const fotografia1Ref = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "reporteAccidenteLaboralID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getReporteAccidenteLaboral, formatearResultado: [
                { id: "fechaElaboracion", formato: (data) => moment(data) },
                { id: "fechaEvento", formato: (data) => moment(data) } ,
                { id: "diaUltimoDescanzo", formato: (data) => moment(data) }

            ]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "reporteAccidenteLaboralID" },
        { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteAccidenteLaboralID, folioInterno }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el reporte laboral  ${folioInterno}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { reporteAccidenteLaboralID }, asyncDelete: deleteReporteAccidenteLaboral
            })
        })
    },
    {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "reporteAccidenteLaboralID"}, {campo: "partida" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteAccidenteLaboralID, partida }) =>  AuxDescargar(reporteAccidenteLaboralID, partida)
    },
    {
        accionID: [6],
        icono: RiFileExcel2Fill,
        color: success,
        descripcion: "Generar Excel",
        parametros: [{ campo: "reporteAccidenteLaboralID" }, { campo: "partida" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteAccidenteLaboralID, partida }) => AuxDescargar(reporteAccidenteLaboralID, partida,'excel')
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{campo: "reporteAccidenteLaboralID"},{campo: "folioInterno"} ],
        disabled: {multiseleccion: true},
        onClick: ({reporteAccidenteLaboralID,folioInterno}) =>
        {
          //  setRedireccionarFormulario({reporteAccidenteLaboralID,folioInterno}) ;
          setDatosDetalleAdjuntos({
            pURLDoctoOrigenID:reporteAccidenteLaboralID ,
            pTipo:folioInterno ,
            pClasificacion: "",
            pTabla: "Reporte Accidente Laboral",
            pTitulo2: cTitulo2,
            plUtilizaProps: true
        });
        setOpenDocumentacion(true);
        }
    },
    {
        icono: AssignmentTurnedInIcon,
        color: success,
        descripcion: "Finalizar Reporte",
        parametros: [{campo: "reporteAccidenteLaboralID"},{campo: "folioInterno"} ],
        disabled: {multiseleccion: true},
        onClick: ({reporteAccidenteLaboralID}) => {setOpenEnviarCorreo(true); setState({...state,reporteAccidenteLaboralID})}
    }
];

async function EnviarCorreo(){
    try {
        
      let validacorreo = state.correoEnviar.includes('@');

        if (trim(state.correoEnviar) ==="" || validacorreo ) throw new Error("Debe ingresar un correo valido");
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "application/json");

        let formdata = new FormData();
        formdata.append("reporteAccidenteLaboralID", state.reporteAccidenteLaboralID);
        formdata.append("enviarCorreo", '1');
        formdata.append("destinatario", `${state.correoEnviar}${state.dominio}`);
        formdata.append("asunto", `Reporte de accidente laboral ${state.folioInterno}`);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/service/reportes/accidenteLaboral`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(resp =>{
                let {error,mensaje} = resp;
                if (error) throw new Error(mensaje);
                handleCloseEnviarCorreo()
                setAlert({
                    descripcion: "El correo ha sido enviado",
                    title: "¡Exito!",
                    tipo: success,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
                return resp;
            })
            .catch(({message}) => {throw new Error(message)});
    } catch ({message}) {
        setAlert({
            descripcion: message,
            title: "¡Una disculpa!",
            tipo: danger,
            msjConfirmacion: "Aceptar",
            onConfirm: () => setAlert(null)
        });
    }

}

function AuxDescargar(reporteAccidenteLaboralID, partida,formatoReporte = 'PDF'){

    setdatosPDf({"reporteAccidenteLaboralID": reporteAccidenteLaboralID, "partida": partida,formatoReporte});
    datosPDF = {"reporteAccidenteLaboralID": reporteAccidenteLaboralID, "partida": partida,formatoReporte};

    handleAlertGeneric({
        setAlert,
        onCancel: () => setAlert(null),
        mensaje: {
            title: "Generar Reporte", descripcion:"¿Deseas generar y descargar el formato de impresión del reporte de accidente laboral?", tipo:"warning", msjConfirmacion:"Aceptar", msjCancelacion:"Cancelar"},

        onConfirm: () => handleAlertMutationGeneric({
            token, setAlert, setActualizar, actualizar,
            parametros:  {}, asyncMutation: () => DescargarPdf(),
            mensajes:{
                msjEspera:{
                    title:"Espere...", descripcion: "Generando el formato de impresión del reporte de accidente laboral ", tipo : success
                },
                msjCorrecto:{
                    title:"Se generó con éxito",descripcion: 'Se descargó el formato de impresión del reporte de accidente laboral', msjConfirmacion:"Aceptar"
                }
            }
        })
    })
}
const showFile = (archivo, name = 'file.pdf',) => {
    let url = window.URL.createObjectURL(archivo);
    let a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
    a.remove();
};

async function DescargarPdf(){

    let myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("mimeType", "multipart/form-data");

    let formdata = new FormData();
    formdata.append("reporteAccidenteLaboralID", datosPDF.reporteAccidenteLaboralID);
    formdata.append("formatoReporte", datosPDF.formatoReporte);

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    };
    let nombreReporte = 'Accidente_Laboral';
    await fetch(`${urlServidor}/service/reportes/accidenteLaboral`, requestOptions)
        .then(response => {
            if (!response.ok)  return response.json();
            return response.blob()
                        })
        .then(result => {
            let { error, mensaje } = result;
            if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte} ${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
            setAlert(null)
                        })
        .catch(({message}) => {throw new Error(message)});

}






    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "reporteAccidenteLaboralID",
        color: primary,
        title: "Reporte de Accidente Laboral",
        iconTable: <ReportProblemIcon />,
        headers: [

            { variable: "reporteAccidenteLaboralID", descripcion: "reporteAccidenteLaboralID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "siglasEmpresa", descripcion: "Empresa", hide: false },
            { variable: "fechaElaboracion", descripcion: "Fecha Elaboracion", hide: false },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación", hide: false },
            { variable: "numIncidencia", descripcion: "No. de Acción", hide: false },
            { variable: "numNC", descripcion: "No. NC", hide: false },
            { variable: "descripBreve", descripcion: "Descripción Breve", hide: true },
            { variable: "lugarEvento", descripcion: "Lugar del Evento", hide: false },
            { variable: "fechaEvento", descripcion: "Fecha del Evento", hide: false },
            { variable: "horaEvento", descripcion: "Hora del Evento", hide: false },
            { variable: "supervCargo", descripcion: "Supervisor a Cargo", hide: false },
            { variable: "area", descripcion: "Área", hide: false },
            { variable: "empresaPersonalAfectadaID", descripcion: "empresaPersonalAfectadaID", hide: true },
            { variable: "puestoHabilidadBase", descripcion: "Puesto Habilidad Base", hide: true },
            { variable: "puestoHabilidadRealizaba", descripcion: "Puesto Habilidad Realizaba", hide: true },
            { variable: "nombreTrabajador", descripcion: "Nombre Trabajador", hide: false },
            { variable: "numControlTrabajador", descripcion: "No. Control Trabajador", hide: false },
            { variable: "siglasEmpresaAfectada", descripcion: "Accidente de Personal", hide: false },
            { variable: "actividadDesarrollaba", descripcion: "actividadDesarrollaba", hide: true },
            { variable: "jornadaDelSuceso", descripcion: "jornadaDelSuceso", hide: true },
            { variable: "diaUltimoDescanzo", descripcion: "diaUltimoDescanzo", hide: true },
            { variable: "portabaEPP", descripcion: "portabaEPP", hide: true },
            { variable: "motivoAusenciaEPP", descripcion: "motivoAusenciaEPP", hide: true },
            { variable: "iluminacionDeficiente", descripcion: "iluminacionDeficiente", hide: true },
            { variable: "condMeteorologicaAdvs", descripcion: "condMeteorologicaAdvs", hide: true },
            { variable: "faltaOrdenLimpieza", descripcion: "faltaOrdenLimpieza", hide: true },
            { variable: "espaciosReducidos", descripcion: "espaciosReducidos", hide: true },
            { variable: "instalacionMalEstado", descripcion: "instalacionMalEstado", hide: true },
            { variable: "fallasEnProceso", descripcion: "fallasEnProceso", hide: true },
            { variable: "cortesPorHerramienta", descripcion: "cortesPorHerramienta", hide: true },
            { variable: "golpesPorObjetosEnCaida", descripcion: "golpesPorObjetosEnCaida", hide: true },
            { variable: "caidasAlMismoDifNivel", descripcion: "caidasAlMismoDifNivel", hide: true },
            { variable: "concactoSustanciasQuimicas", descripcion: "concactoSustanciasQuimicas", hide: true },
            { variable: "atrapamientoExtremidades", descripcion: "atrapamientoExtremidades", hide: true },
            { variable: "lesionesErgonomicas", descripcion: "lesionesErgonomicas", hide: true },
            { variable: "colisionChoque", descripcion: "colisionChoque", hide: true },
            { variable: "quemaduras", descripcion: "quemaduras", hide: true },
            { variable: "cabeza", descripcion: "cabeza", hide: true },
            { variable: "cara", descripcion: "cara", hide: true },
            { variable: "cuello", descripcion: "cuello", hide: true },
            { variable: "espalda", descripcion: "espalda", hide: true },
            { variable: "pecho", descripcion: "pecho", hide: true },
            { variable: "brazos", descripcion: "brazos", hide: true },
            { variable: "manos", descripcion: "manos", hide: true },
            { variable: "piernas", descripcion: "piernas", hide: true },
            { variable: "pies", descripcion: "pies", hide: true },
            { variable: "descripDetallada", descripcion: "descripDetallada", hide: true },
            { variable: "fotografias", descripcion: "fotografias", hide: true },
            { variable: "existenTestigos", descripcion: "existenTestigos", hide: true },
            { variable: "nombresPersonalTestigo1", descripcion: "Personal Testigo 1", hide: false },
            { variable: "numControlPersonalTestigo1", descripcion: "numControlPersonalTestigo1", hide: true },
            { variable: "puestoHabilidadTestigo1", descripcion: "puestoHabilidadTestigo1", hide: true },
            { variable: "nombresPersonalTestigo2", descripcion: "Personal Testigo 2", hide: false },
            { variable: "numControlPersonalTestigo2", descripcion: "numControlPersonalTestigo2", hide: true },
            { variable: "puestoHabilidadTestigo2", descripcion: "puestoHabilidadTestigo2", hide: true },
            { variable: "observGenerales", descripcion: "observGenerales", hide: true },
            { variable: "responAnalisis", descripcion: "responAnalisis", hide: true },
            { variable: "elaboro", descripcion: "elaboro", hide: true },
            { variable: "firma", descripcion: "firma", hide: true },
            { variable: "cargo", descripcion: "cargo", hide: true },
            { variable: "tipoDanioOcasio", descripcion: "tipoDanioOcasio", hide: true },
            { variable: "folio", descripcion: "folio", hide: true },
            { variable: "danioOcasioPor", descripcion: "danioOcasioPor", hide: true },
            { variable: "danioOcasioARep", descripcion: "danioOcasioARep", hide: true },
            { variable: "equipoBienPersona", descripcion: "equipoBienPersona", hide: true },
            { variable: "areaRespRepa", descripcion: "areaRespRepa", hide: true },
            { variable: "costoDanio", descripcion: "costoDanio", hide: true },
            { variable: "estatus", descripcion: "estatus", hide: true },
            { variable: "tiempoInvertido", descripcion: "tiempoInvertido", hide: true },
            { variable: "atendio", descripcion: "atendio", hide: true },
            { variable: "notas", descripcion: "notas", hide: true },
            { variable: "finalizado", descripcion: "finalizado", hide: true },
            { variable: "seguimiento", descripcion: "seguimiento", hide: true }
        ],
        responsiveTitle: ["folioInterno","folio","nombreTrabajador"],
        filter: [
            { campo: "folioInterno" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [

            {
                columnas: ["fechaElaboracion", "fechaEvento","fechaModificacion" ], tipo: "fecha", onlyDate: true
            }/*,

             {
                columnas: ["renovacion"],tipo: "estatus"

            }*/
        ],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Empresa ? seleccionables.Empresa : [], grid : { md: 3, lg: 3 }
            }]
    }

    const listTransmisiones = [
        {
            label: 'Manual',
            value: 'Manual'
        },
        {
            label: 'Automatizada (Secuencial)',
            value:  'Automatizada (Secuencial)'
        },

        {
            label: 'Automatizada (Doble Embrague)',
            value: 'Automatizada (Doble Embrague)'
        },
        {
            label: 'Automática',
            value:  'Automática'
        },
        {
            label: 'CVT',
            value: 'CVT'
        }
    ]

    const listaAfectaExtremidad = [
        {
            label: 'No Aplica',
            value: 'No Aplica'
        },
        {
            label: 'Ambos',
            value:  'Ambos'
        },

        {
            label: 'Derecho',
            value: 'Derecho'
        },
        {
            label: 'Izquierdo',
            value:  'Izquierdo'
        }
    ]


    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaElaboracion",
                    value: state.fechaElaboracion,
                    error: errorState.fechaElaboracion.error,
                    success: state.fechaElaboracion && !errorState.fechaElaboracion.error ? true : undefined,
                    helperText: errorState.fechaElaboracion.helperText,
                    inputRef: fechaElaboracionRef,
                    title: "Fecha Elaboración.",
                    placeholder: "Fecha de Elaboración",
                   // onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4 , lg: 4 },
                    /*onChange: (value) =>
                        handleChange(value, "fechaElaboracion"),
                        onKeyDown: (evt) => handleFocus(evt, fechaElaboracionRef)
                        */
                        onChange: (value) => {
                            if (typeof value !== "object") return;
                            handleChange(value, "fechaElaboracion");
                        }
                },
               /* {
                    disabled,
                    id: "numIncidencia",
                    value: state.numIncidencia,
                    error: errorState.numIncidencia.error,
                    success: state.numIncidencia && !errorState.numIncidencia.error ? true : undefined,
                    helperText: errorState.numIncidencia.helperText,
                    textAlign: "right",
                    inputRef: numIncidenciaRef,
                    title: "No. de Accidente o Incidente: ",
                    placeholder: "Capture el número de incidente",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) =>{
                        (rgxdeInt11.test(value)) &&
                        handleChange(value, "numIncidencia")},
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, numIncidencia: { error: state.numIncidencia.length >30 && true, helperText: state.numIncidencia.length >30 ? "Límite de 30 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, numNCRef)
                },
*/
               /* {
                    disabled,
                    id: "numNC",
                    value: state.numNC,
                    error: errorState.numNC.error,
                    success: state.numNC && !errorState.numNC.error ? true : undefined,
                    helperText: errorState.numNC.helperText,
                    textAlign: "right",
                    inputRef: numNCRef,
                    title: "No. de NC: ",
                    placeholder: "Capture el número de NC",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) =>{
                        (rgxdeInt11.test(value)) &&
                        handleChange(value, "numNC")},
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, numNC: { error: state.numNC.length >30 && true, helperText: state.numNC.length >30 ? "Límite de 30 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, fechaEventoRef)
                },*/
                {
                    disabled,
                    id: "fechaEvento",
                    value: state.fechaEvento,
                    error: errorState.fechaEvento.error,
                    success: state.fechaEvento && !errorState.fechaEvento.error ? true : undefined,
                    helperText: errorState.fechaEvento.helperText,
                    inputRef: fechaEventoRef,
                    placeholder: "Fecha Incidente: *",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4 , lg: 4 },
                    /*onChange: (value) =>
                        handleChange(value, "fechaEvento"),
                        onKeyDown: (evt) => handleFocus(evt, horaEventoRef)
                        */
                        onChange: (value) => {
                            if (typeof value !== "object") return;
                            handleChange(value, "fechaEvento");
                        }
                },
                {
                    disabled,
                    id: "horaEvento",
                    value: state.horaEvento,
                    error: errorState.horaEvento.error,
                    success: state.horaEvento && !errorState.horaEvento.error ? true : undefined,
                    helperText: errorState.horaEvento.helperText,
                    inputRef: horaEventoRef,
                    placeholder: "Hora del incidente: *",
                    onlyDate: false,
                    onlyTime:true,
                    tipo: "datetimepicker",
                    timeFormat:false,
                    grid: { md: 4 , lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "horaEvento"),
                        onKeyDown: (evt) => handleFocus(evt, lugarEventoRef)
                },
                {
                    disabled,
                    id: "lugarEvento",
                    value: state.lugarEvento,
                    error: errorState.lugarEvento.error,
                    success: state.lugarEvento && !errorState.lugarEvento.error ? true : undefined,
                    helperText: errorState.lugarEvento.helperText,
                    inputRef: lugarEventoRef,
                    title: "Lugar del incidente: *",
                    placeholder: "Capture el lugar del incidente",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "lugarEvento")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, lugarEvento: { error: state.lugarEvento.length >100 && true, helperText: state.lugarEvento.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, supervCargoRef)
                },
                {
                    disabled,
                    id: "supervCargo",
                    value: state.supervCargo,
                    error: errorState.supervCargo.error,
                    success: state.supervCargo && !errorState.supervCargo.error ? true : undefined,
                    helperText: errorState.supervCargo.helperText,
                    inputRef: supervCargoRef,
                    title: "Supervisor(es) a cargo: *",
                    placeholder: "Capture el supervisor(es) a cargo",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "supervCargo")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, supervCargo: { error: state.supervCargo.length >100 && true, helperText: state.supervCargo.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, areaRef)
                },
                {
                    disabled,
                    id: "area",
                    value: state.area,
                    error: errorState.area.error,
                    success: state.area && !errorState.area.error ? true : undefined,
                    helperText: errorState.area.helperText,
                    inputRef: areaRef,
                    title: "Área: *",
                    placeholder: "Capture el área",
                    multiline:true,
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "area")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, area: { error: state.area.length >150 && true, helperText: state.area.length >150 ? "Límite de 150 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                },
                {
                    disabled,
                    id: "empresaID",
                    value: state.empresaID,
                    error: errorState.empresaID.error,
                    success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                    helperText: errorState.empresaID.helperText,
                    inputRef: empresaIDRef,
                    title: "Empresa: *",
                    placeholder: "Seleccione una Empresa",
                    tipo: "select",
                    data: seleccionables.Empresa,
                    grid: { md: 4 , lg: 4 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, empresaID: value })
                    },
                },
                {
                    disabled,
                    id: "empresaPersonalAfectadaID",
                    value: state.empresaPersonalAfectadaID,
                    error: errorState.empresaPersonalAfectadaID.error,
                    success: state.empresaPersonalAfectadaID && !errorState.empresaPersonalAfectadaID.error ? true : undefined,
                    helperText: errorState.empresaPersonalAfectadaID.helperText,
                    inputRef: empresaPersonalAfectadaIDRef,
                    title: "Accidente de Personal: *",
                    placeholder: "Seleccione una Empresa",
                    tipo: "select",
                    data: seleccionables.Empresa,
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }, {props:{children}}) => {
                        setState({ ...state, empresaPersonalAfectadaID: value,nombreEmpresa: children })
                    },
                },
                {
                    disabled,
                    id: "puestoHabilidadBase",
                    value: state.puestoHabilidadBase,
                    error: errorState.puestoHabilidadBase.error,
                    success: state.puestoHabilidadBase && !errorState.puestoHabilidadBase.error ? true : undefined,
                    helperText: errorState.puestoHabilidadBase.helperText,
                    inputRef: puestoHabilidadBaseRef,
                    title: "Puesto o habilidad base: *",
                    placeholder: "Capture el puesto o habilidad base",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "puestoHabilidadBase")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, puestoHabilidadBase: { error: state.puestoHabilidadBase.length >100 && true, helperText: state.puestoHabilidadBase.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, puestoHabilidadRealizabaRef)
                },
                {
                    disabled,
                    id: "puestoHabilidadRealizaba",
                    value: state.puestoHabilidadRealizaba,
                    error: errorState.puestoHabilidadRealizaba.error,
                    success: state.puestoHabilidadRealizaba && !errorState.puestoHabilidadRealizaba.error ? true : undefined,
                    helperText: errorState.puestoHabilidadRealizaba.helperText,
                    inputRef: puestoHabilidadRealizabaRef,
                    title: "Puesto o habilidad de la actividad que realizaba: *",
                    placeholder: "Capture el puesto o habilidad de la actividad que realizaba: ",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "puestoHabilidadRealizaba")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, puestoHabilidadRealizaba: { error: state.puestoHabilidadRealizaba.length >100 && true, helperText: state.puestoHabilidadRealizaba.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, nombreTrabajadorRef)
                },
                {
                    disabled,
                    id: "nombreTrabajador",
                    value: state.nombreTrabajador,
                    error: errorState.nombreTrabajador.error,
                    success: state.nombreTrabajador && !errorState.nombreTrabajador.error ? true : undefined,
                    helperText: errorState.nombreTrabajador.helperText,
                    inputRef: nombreTrabajadorRef,
                    title: "Nombre (s) del trabajdor: *",
                    placeholder: "Capture el nombre (s) del trabajdor",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "nombreTrabajador")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, nombreTrabajador: { error: state.nombreTrabajador.length >100 && true, helperText: state.nombreTrabajador.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, numControlTrabajadorRef)
                },
                {
                    disabled,
                    id: "numControlTrabajador",
                    value: state.numControlTrabajador,
                    error: errorState.numControlTrabajador.error,
                    success: state.numControlTrabajador && !errorState.numControlTrabajador.error ? true : undefined,
                    helperText: errorState.numControlTrabajador.helperText,
                    inputRef: numControlTrabajadorRef,
                    title: "No. De Control: *",
                    placeholder: "Capture el número de control",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "numControlTrabajador")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, numControlTrabajador: { error: state.numControlTrabajador.length >30 && true, helperText: state.numControlTrabajador.length >30 ? "Límite de 30 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, actividadDesarrollabaRef)
                },
                {
                    disabled,
                    id: "actividadDesarrollaba",
                    value: state.actividadDesarrollaba,
                    error: errorState.actividadDesarrollaba.error,
                    success: state.actividadDesarrollaba && !errorState.actividadDesarrollaba.error ? true : undefined,
                    helperText: errorState.actividadDesarrollaba.helperText,
                    inputRef: actividadDesarrollabaRef,
                    title: "Actividad que desarrollaba:*",
                    placeholder: "Capture la actividad que desarrollaba",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "actividadDesarrollaba")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, actividadDesarrollaba: { error: state.actividadDesarrollaba.length >200 && true, helperText: state.actividadDesarrollaba.length >200 ? "Límite de 200 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, jornadaDelSucesoRef)
                },
                {
                    disabled,
                    id: "jornadaDelSuceso",
                    value: state.jornadaDelSuceso,
                    error: errorState.jornadaDelSuceso.error,
                    success: state.jornadaDelSuceso && !errorState.jornadaDelSuceso.error ? true : undefined,
                    helperText: errorState.jornadaDelSuceso.helperText,
                    inputRef: jornadaDelSucesoRef,
                    title: "Jornada en la que sucede:*",
                    placeholder: "Capture la jornada laboral",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "jornadaDelSuceso")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, jornadaDelSuceso: { error: state.jornadaDelSuceso.length >50 && true, helperText: state.jornadaDelSuceso.length >50 ? "Límite de 50 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                },
                {
                    disabled,
                    id: "diaUltimoDescanzo",
                    value: state.diaUltimoDescanzo,
                    error: errorState.diaUltimoDescanzo.error,
                    success: state.diaUltimoDescanzo && !errorState.diaUltimoDescanzo.error ? true : undefined,
                    helperText: errorState.diaUltimoDescanzo.helperText,
                    inputRef: diaUltimoDescanzoRef,
                    placeholder: "Día de su último descanso: *",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 3 , lg: 3 },
                   /* onChange: (value) =>
                        handleChange(value, "diaUltimoDescanzo"),
                        onKeyDown: (evt) => handleFocus(evt, motivoAusenciaEPPRef)*/
                        onChange: (value) => {
                            if (typeof value !== "object") return;
                            handleChange(value, "diaUltimoDescanzo");
                        }
                },
                {
                    disabled,
                    id: "portabaEPP",
                    value: state.portabaEPP,
                    checked: state.portabaEPP,
                    title: "¿Si portaba su EPP completo?",
                    tipo: "switch",
                    grid: { md: 3, lg: 3 },
                    onChange: (e) => {
                        setState({ ...state, portabaEPP: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "motivoAusenciaEPP",
                    value: state.motivoAusenciaEPP,
                    error: errorState.motivoAusenciaEPP.error,
                    success: state.motivoAusenciaEPP && !errorState.motivoAusenciaEPP.error ? true : undefined,
                    helperText: errorState.motivoAusenciaEPP.helperText,
                    inputRef: motivoAusenciaEPPRef,
                    title: "Describa la Ausencia del EPP: *",
                    placeholder: "Describa la Ausencia del EPP",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "motivoAusenciaEPP")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, motivoAusenciaEPP: { error: state.motivoAusenciaEPP.length >150 && true, helperText: state.motivoAusenciaEPP.length >150 ? "Límite de 150 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                } ]},
                {
                    id: "FactoresTrabajo",
                    tipo: "fieldset",
                    leyenda: "1.- Factores de Trabajo",
                    grid: { lg: 4 },
                    inputs: [
                        {
                            disabled,
                            id: "iluminacionDeficiente",
                            value: state.iluminacionDeficiente,
                            checked: state.iluminacionDeficiente,
                            title: "Iluminación Deficiente ",
                            tipo: "switch",
                            grid: { md: 12, lg: 12 },
                            onChange: (e) => {
                                setState({ ...state, iluminacionDeficiente: e.target.checked })
                            }
                        },
                        {
                            disabled,
                            id: "condMeteorologicaAdvs",
                            value: state.condMeteorologicaAdvs,
                            checked: state.condMeteorologicaAdvs,
                            title: "Condición meteorológica adversas",
                            tipo: "switch",
                            grid: { md: 12, lg: 12 },
                            onChange: (e) => {
                                setState({ ...state, condMeteorologicaAdvs: e.target.checked })
                            }
                        },
                        {
                            disabled,
                            id: "faltaOrdenLimpieza",
                            value: state.faltaOrdenLimpieza,
                            checked: state.faltaOrdenLimpieza,
                            title: "Falta de orden y limpieza",
                            tipo: "switch",
                            grid: { md: 12, lg: 12 },
                            onChange: (e) => {
                                setState({ ...state, faltaOrdenLimpieza: e.target.checked })
                            }
                        },
                        {
                            disabled,
                            id: "espaciosReducidos",
                            value: state.espaciosReducidos,
                            checked: state.espaciosReducidos,
                            title: "Espacios Reducidos ",
                            tipo: "switch",
                            grid: { md: 12, lg: 12 },
                            onChange: (e) => {
                                setState({ ...state, espaciosReducidos: e.target.checked })
                            }
                        },
                        {
                            disabled,
                            id: "instalacionMalEstado",
                            value: state.instalacionMalEstado,
                            checked: state.instalacionMalEstado,
                            title: "Instalación en mal estado ",
                            tipo: "switch",
                            grid: { md: 12, lg: 12 },
                            onChange: (e) => {
                                setState({ ...state, instalacionMalEstado: e.target.checked })
                            }
                        },{
                            disabled,
                            id: "fallasEnProceso",
                            value: state.fallasEnProceso,
                            checked: state.fallasEnProceso,
                            title: "Fallas en la instrucción o Proceso ",
                            tipo: "switch",
                            grid: { md: 12, lg: 12 },
                            onChange: (e) => {
                                setState({ ...state, fallasEnProceso: e.target.checked })
                            }
                        }
            ]
        },
        {
            id: "AgentesRiesgo",
            tipo: "fieldset",
            leyenda: "2.- Agentes de Riesgo",
            grid: { lg: 4 },
            inputs: [

                {
                    disabled,
                    id: "cortesPorHerramienta",
                    value: state.cortesPorHerramienta,
                    checked: state.cortesPorHerramienta,
                    title: "Cortes por  Herramientas",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, cortesPorHerramienta: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "golpesPorObjetosEnCaida",
                    value: state.golpesPorObjetosEnCaida,
                    checked: state.golpesPorObjetosEnCaida,
                    title: "Golpes por objetos en caída",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, golpesPorObjetosEnCaida: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "caidasAlMismoDifNivel",
                    value: state.caidasAlMismoDifNivel,
                    checked: state.caidasAlMismoDifNivel,
                    title: "Caídas al mismo y diferente nivel",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, caidasAlMismoDifNivel: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "concactoSustanciasQuimicas",
                    value: state.concactoSustanciasQuimicas,
                    checked: state.concactoSustanciasQuimicas,
                    title: "Contacto con sustancias químicas",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, concactoSustanciasQuimicas: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "atrapamientoExtremidades",
                    value: state.atrapamientoExtremidades,
                    checked: state.atrapamientoExtremidades,
                    title: "Atrapamiento de Extremidades (superior e Inferior)",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, atrapamientoExtremidades: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "lesionesErgonomicas",
                    value: state.lesionesErgonomicas,
                    checked: state.lesionesErgonomicas,
                    title: "Lesiones Ergonómicas (espalda, cuello, hombros )",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, lesionesErgonomicas: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "colisionChoque",
                    value: state.colisionChoque,
                    checked: state.colisionChoque,
                    title: "Colisión /choque o volcadura",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, colisionChoque: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "quemaduras",
                    value: state.quemaduras,
                    checked: state.quemaduras,
                    title: "Quemaduras",
                    tipo: "switch",
                    grid: { md: 12, lg: 12 },
                    onChange: (e) => {
                        setState({ ...state, quemaduras: e.target.checked })
                    }
                }
             ]
            },
            {
                id: "PartesAfectadas",
                tipo: "fieldset",
                leyenda: "3.- Partes Afectadas",
                grid: { lg: 4 },
                inputs: [

                    {
                        disabled,
                        id: "cabeza",
                        value: state.cabeza,
                        checked: state.cabeza,
                        title: "Cabeza",
                        tipo: "switch",
                        grid: { md: 6, lg: 6 },
                        onChange: (e) => {
                            setState({ ...state, cabeza: e.target.checked })
                        }
                    },
                    {
                        disabled,
                        id: "cara",
                        value: state.cara,
                        checked: state.cara,
                        title: "Cara",
                        tipo: "switch",
                        grid: { md: 6, lg: 6 },
                        onChange: (e) => {
                            setState({ ...state, cara: e.target.checked })
                        }
                    },
                    {
                        disabled,
                        id: "cuello",
                        value: state.cuello,
                        checked: state.cuello,
                        title: "Cuello",
                        tipo: "switch",
                        grid: { md: 6, lg: 6 },
                        onChange: (e) => {
                            setState({ ...state, cuello: e.target.checked })
                        }
                    },
                    {
                        disabled,
                        id: "espalda",
                        value: state.espalda,
                        checked: state.espalda,
                        title: "Espalda",
                        tipo: "switch",
                        grid: { md: 6, lg: 6 },
                        onChange: (e) => {
                            setState({ ...state, espalda: e.target.checked })
                        }
                    },
                    {
                        disabled,
                        id: "pecho",
                        value: state.pecho,
                        checked: state.pecho,
                        title: "Pecho",
                        tipo: "switch",
                        grid: { md: 6, lg: 6 },
                        onChange: (e) => {
                            setState({ ...state, pecho: e.target.checked })
                        }
                    },
                    {
                        disabled,
                        id: "brazos",
                        value: state.brazos,
                        error: errorState.brazos.error,
                        success: state.brazos && !errorState.brazos.error ? true : undefined,
                        helperText: errorState.brazos.helperText,
                        inputRef: brazosRef,
                        title: "Brazos: *",
                        placeholder: "Seleccione opción",
                        tipo: "select",
                        data: listaAfectaExtremidad,
                        grid: { md: 6 , lg: 6},
                        onChange: ({ target: { value } }) => {
                            setState({ ...state, brazos: value })
                        },
                    },
                    {
                        disabled,
                        id: "manos",
                        value: state.manos,
                        error: errorState.manos.error,
                        success: state.manos && !errorState.manos.error ? true : undefined,
                        helperText: errorState.manos.helperText,
                        inputRef: manosRef,
                        title: "Manos: *",
                        placeholder: "Seleccione opción",
                        tipo: "select",
                        data: listaAfectaExtremidad,
                        grid: { md: 6 , lg: 6 },
                        onChange: ({ target: { value } }) => {
                            setState({ ...state, manos: value })
                        },
                    },
                    {
                        disabled,
                        id: "piernas",
                        value: state.piernas,
                        error: errorState.piernas.error,
                        success: state.piernas && !errorState.piernas.error ? true : undefined,
                        helperText: errorState.piernas.helperText,
                        inputRef: piernasRef,
                        title: "Piernas: *",
                        placeholder: "Seleccione opción",
                        tipo: "select",
                        data: listaAfectaExtremidad,
                        grid: { md: 6 , lg: 6 },
                        onChange: ({ target: { value } }) => {
                            setState({ ...state, piernas: value })
                        },
                    },
                    {
                        disabled,
                        id: "pies",
                        value: state.pies,
                        error: errorState.pies.error,
                        success: state.pies && !errorState.pies.error ? true : undefined,
                        helperText: errorState.pies.helperText,
                        inputRef: piesRef,
                        title: "Pies: *",
                        placeholder: "Seleccione opción",
                        tipo: "select",
                        data: listaAfectaExtremidad,
                        grid: { md: 6 , lg: 6 },
                        onChange: ({ target: { value } }) => {
                            setState({ ...state, pies: value })
                        },
                    }
                  ]
          },

          {
              id: "Descripcion",
              tipo: "fieldset",
              leyenda: "Describa Detalladamente el Accidente (Responda a las preguntas qué paso, cuándo paso, dónde paso, cómo y por qué paso)",
              grid: { lg: 12 },
              inputs: [

                {
                    disabled,
                    id: "descripDetallada",
                    value: state.descripDetallada,
                    error: errorState.descripDetallada.error,
                    success: state.descripDetallada && !errorState.descripDetallada.error ? true : undefined,
                    helperText: errorState.descripDetallada.helperText,
                    inputRef: descripDetalladaRef,
                    title: "Describa Detalladamente el Accidente:*",
                    placeholder: "Describa Detalladamente el Accidente (Responda a las preguntas qué paso, cuándo paso, dónde paso, cómo y por qué paso)",
                    multiline:true,
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto3000.test(value)) &&
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "descripDetallada")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, descripDetallada: { error: state.descripDetallada.length >3000 && true, helperText: state.descripDetallada.length >3000 ? "Límite de 3000 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                },

                {
                    disabled,
                    id: "fotografia1",
                    value: state.fotografia1,
                    error: errorState.fotografia1.error,
                    success: state.fotografia1 && !errorState.fotografia1.error ? true : undefined,
                    helperText: errorState.fotografia1.helperText,
                    removeImage: state.fotografia1 === "" ? true : false,
                    inputRef: fotografia1Ref,
                    tipo: "imageupload",
                    grid: { md: 6, lg: 6 },
                    onChange:  (file, base) => {
                        if(file === null){
                            remover = true;
                        }
                        setState(state => ({
                            ...state,
                            'fotografia1': base
                        }));
                        setFotografias( { valor: base, file: file, eliminar:remover});
                    return;}
                },


            ]
        },
        {
           id: "testigos",
           tipo: "fieldset",
           leyenda: "Testigos",
           grid: { lg: 12 },
           inputs: [
                 {
                   disabled,
                   id: "existenTestigos",
                   value: state.existenTestigos,
                   checked: state.existenTestigos,
                   title: "¿Hubo personas que presenciaron el accidente?",
                   tipo: "switch",
                   grid: { md: 4, lg: 4 },
                   onChange: (e) => {
                       setState({ ...state, existenTestigos: e.target.checked })
                   }
               },
               {
                disabled,
                id: "nombresPersonalTestigo1",
                value: state.nombresPersonalTestigo1,
                error: errorState.nombresPersonalTestigo1.error,
                success: state.nombresPersonalTestigo1 && !errorState.nombresPersonalTestigo1.error ? true : undefined,
                helperText: errorState.nombresPersonalTestigo1.helperText,
                inputRef: nombresPersonalTestigo1Ref,
                title: "Nombre (s) de Testigo (s):*",
                placeholder: "Capture los nombre (s) de testigo (s)",
                multiline:true,
                grid: { md: 8, lg: 8 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "nombresPersonalTestigo1")
                },
                inputProps: {
                    onBlur: () => setErrorState({...cleanErrorState, nombresPersonalTestigo1: { error: state.nombresPersonalTestigo1.length >250 && true, helperText: state.nombresPersonalTestigo1.length >250 ? "Límite de 250 caracteres excedido" : ""} })
                },
                onKeyDown: (evt) => handleFocus(evt, numControlPersonalTestigo1Ref)
            },
            {
                disabled,
                id: "numControlPersonalTestigo1",
                value: state.numControlPersonalTestigo1,
                error: errorState.numControlPersonalTestigo1.error,
                success: state.numControlPersonalTestigo1 && !errorState.numControlPersonalTestigo1.error ? true : undefined,
                helperText: errorState.numControlPersonalTestigo1.helperText,
                inputRef: numControlPersonalTestigo1Ref,
                title: "No. De Control: *",
                placeholder: "Capture los numeros de control",
                multiline:true,
                grid: { md: 6, lg: 6 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "numControlPersonalTestigo1")
                },
                inputProps: {
                    onBlur: () => setErrorState({...cleanErrorState, numControlPersonalTestigo1: { error: state.numControlPersonalTestigo1.length >50 && true, helperText: state.numControlPersonalTestigo1.length >50 ? "Límite de 50 caracteres excedido" : ""} })
                },
                onKeyDown: (evt) => handleFocus(evt, puestoHabilidadTestigo1Ref)
            },
            {
                disabled,
                id: "puestoHabilidadTestigo1",
                value: state.puestoHabilidadTestigo1,
                error: errorState.puestoHabilidadTestigo1.error,
                success: state.puestoHabilidadTestigo1 && !errorState.puestoHabilidadTestigo1.error ? true : undefined,
                helperText: errorState.puestoHabilidadTestigo1.helperText,
                inputRef: puestoHabilidadTestigo1Ref,
                title: "Puesto o Habilidad: *",
                placeholder: "Capture el puesto o habilidad",
                multiline:true,
                grid: { md: 6, lg: 6 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "puestoHabilidadTestigo1")
                },
                inputProps: {
                    onBlur: () => setErrorState({...cleanErrorState, puestoHabilidadTestigo1: { error: state.puestoHabilidadTestigo1.length >100 && true, helperText: state.puestoHabilidadTestigo1.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                },
                onKeyDown: (evt) => handleFocus(evt, nombresPersonalTestigo2Ref)
            },
            {
                disabled,
                id: "nombresPersonalTestigo2",
                value: state.nombresPersonalTestigo2,
                error: errorState.nombresPersonalTestigo2.error,
                success: state.nombresPersonalTestigo2 && !errorState.nombresPersonalTestigo2.error ? true : undefined,
                helperText: errorState.nombresPersonalTestigo2.helperText,
                inputRef: nombresPersonalTestigo2Ref,
                title: "Nombre (s) de Testigo (s):*",
                placeholder: "Capture los nombre (s) de testigo (s)",
                multiline:true,
                grid: { md: 8, lg: 8 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "nombresPersonalTestigo2")
                },
                inputProps: {
                    onBlur: () => setErrorState({...cleanErrorState, nombresPersonalTestigo2: { error: state.nombresPersonalTestigo2.length >250 && true, helperText: state.nombresPersonalTestigo2.length >250 ? "Límite de 250 caracteres excedido" : ""} })
                },
                onKeyDown: (evt) => handleFocus(evt, numControlPersonalTestigo2Ref)
            },
            {
                disabled,
                id: "numControlPersonalTestigo2",
                value: state.numControlPersonalTestigo2,
                error: errorState.numControlPersonalTestigo2.error,
                success: state.numControlPersonalTestigo2 && !errorState.numControlPersonalTestigo2.error ? true : undefined,
                helperText: errorState.numControlPersonalTestigo2.helperText,
                inputRef: numControlPersonalTestigo2Ref,
                title: "No. De Control: *",
                placeholder: "Capture los numeros de control",
                multiline:true,
                grid: { md: 6, lg: 6 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "numControlPersonalTestigo2")
                },
                inputProps: {
                    onBlur: () => setErrorState({...cleanErrorState, numControlPersonalTestigo2: { error: state.numControlPersonalTestigo2.length >50 && true, helperText: state.numControlPersonalTestigo2.length >50 ? "Límite de 50 caracteres excedido" : ""} })
                },
                onKeyDown: (evt) => handleFocus(evt, puestoHabilidadTestigo2Ref)
            },
            {
                disabled,
                id: "puestoHabilidadTestigo2",
                value: state.puestoHabilidadTestigo2,
                error: errorState.puestoHabilidadTestigo2.error,
                success: state.puestoHabilidadTestigo2 && !errorState.puestoHabilidadTestigo2.error ? true : undefined,
                helperText: errorState.puestoHabilidadTestigo2.helperText,
                inputRef: puestoHabilidadTestigo2Ref,
                title: "Puesto o Habilidad: *",
                placeholder: "Capture el puesto o habilidad",
                multiline:true,
                grid: { md: 6, lg: 6 },
                onChange: ({ target: { value } }) => {
                    handleChange(value.charAt(0).toUpperCase() + value.slice(1), "puestoHabilidadTestigo2")
                },
                inputProps: {
                    onBlur: () => setErrorState({...cleanErrorState, puestoHabilidadTestigo2: { error: state.puestoHabilidadTestigo2.length >100 && true, helperText: state.puestoHabilidadTestigo2.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                },
                onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
            },

            ]
       },
         {
            id: "firmas",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [

                {
                    disabled,
                    id: "responAnalisis",
                    value: state.responAnalisis,
                    error: errorState.responAnalisis.error,
                    success: state.responAnalisis && !errorState.responAnalisis.error ? true : undefined,
                    helperText: errorState.responAnalisis.helperText,
                    inputRef: responAnalisisRef,
                    title: "Responsable del análisis de causa raíz: *",
                    placeholder: "Capture el responsable del análisis de causa raíz",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "responAnalisis")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, responAnalisis: { error: state.responAnalisis.length >100 && true, helperText: state.responAnalisis.length >100 ? "Límite de 100 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, elaboroRef)
                },

                {
                    disabled,
                    id: "elaboro",
                    value: state.elaboro,
                    error: errorState.elaboro.error,
                    success: state.elaboro && !errorState.elaboro.error ? true : undefined,
                    helperText: errorState.elaboro.helperText,
                    inputRef: elaboroRef,
                    title: "Elaboró: *",
                    placeholder: "Capture quien elaboró",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "elaboro")
                    },
                    inputProps: {
                        onBlur: () => state.elaboro && setErrorState({...cleanErrorState, elaboro: { error: state.elaboro.length >150 && true, helperText: state.elaboro.length >150 ? "Límite de 150 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                },
                {
                    disabled,
                    id: "firma",
                    value: state.firma,
                    error: errorState.firma.error,
                    success: state.firma && !errorState.firma.error ? true : undefined,
                    helperText: errorState.firma.helperText,
                    inputRef: firmaRef,
                    title: "Firma: ",
                    placeholder: "Capture la firma",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "firma")
                    },
                    inputProps: {
                        onBlur: () => state.firma && setErrorState({...cleanErrorState, firma: { error: state.firma.length >150 && true, helperText: state.firma.length >150 ? "Límite de 150 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                },
                {
                    disabled,
                    id: "cargo",
                    value: state.cargo,
                    error: errorState.cargo.error,
                    success: state.cargo && !errorState.cargo.error ? true : undefined,
                    helperText: errorState.cargo.helperText,
                    inputRef: cargoRef,
                    title: "Cargo: *",
                    placeholder: "Capture el cargo",
                    multiline:true,
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "cargo")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({...cleanErrorState, cargo: { error: state.cargo.length >150 && true, helperText: state.cargo.length >150 ? "Límite de 150 caracteres excedido" : ""} })
                    },
                    onKeyDown: (evt) => handleFocus(evt, empresaIDRef)
                },

            ]
        }
    ];

    const inputsEnviarCorreo = [
        {
            disabled,
            id: "correoEnviar",
            value: state.correoEnviar,
            title: "Correo *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el correo",
            onChange: ({ target: { value } }) =>  setState({ ...state, correoEnviar: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "dominio",
            value: state.dominio,
            title: "dominio*",
            placeholder: "Seleccione el dominio",
            tipo: "select",
            data: seleccionables.dominios,
            grid: { md: 6, lg: 6 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
    ]


    function handleSetImagen1(foto1) {
        let remover = false;
        if (foto1 === null) {
            remover = true;
        }
        setFotografias({ ...stFotografias, foto1: { IDimagen: state.IDimagen1, url: state.fotoEvidencia1, orden: 1, file: foto1, eliminar: remover } });
        setState({ ...state, foto1, fotoEvidencia1: null });
    }

    let accionesFormulario = [{
        accionID: state.reporteAccidenteLaboralID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: numIncidenciaRef,
            mensajeCorrecto: `El reporte de accidente laboral se ${state.reporteAccidenteLaboralID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const accionesFormularioEnviarCorreo = [{
        accionID: "EnviarCorreo",
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => EnviarCorreo()
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseEnviarCorreo
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({
                reporteAccidenteLaboralID,
                usuarioBitID,
                empresaID,
                folioInterno,
                fechaElaboracion,
                numIncidencia,
                numNC,
                descripBreve,
                lugarEvento,
                fechaEvento,
                horaEvento,
                supervCargo,
                area,
                empresaPersonalAfectadaID,
                puestoHabilidadBase,
                puestoHabilidadRealizaba,
                nombreTrabajador,
                numControlTrabajador,
                actividadDesarrollaba,
                jornadaDelSuceso,
                diaUltimoDescanzo,
                portabaEPP,
                motivoAusenciaEPP,
                iluminacionDeficiente,
                condMeteorologicaAdvs,
                faltaOrdenLimpieza,
                espaciosReducidos,
                instalacionMalEstado,
                fallasEnProceso,
                cortesPorHerramienta,
                golpesPorObjetosEnCaida,
                caidasAlMismoDifNivel,
                concactoSustanciasQuimicas,
                atrapamientoExtremidades,
                lesionesErgonomicas,
                colisionChoque,
                quemaduras,
                cabeza,
                cara,
                cuello,
                espalda,
                pecho,
                brazos,
                manos,
                piernas,
                pies,
                descripDetallada,
                fotografias,
                existenTestigos,
                nombresPersonalTestigo,
                numControlPersonalTestigo,
                puestoHabilidadTestigo,
                observGenerales,
                responAnalisis,
                elaboro,
                firma,
                cargo,
                tipoDanioOcasio,
                folio,
                danioOcasioPor,
                danioOcasioARep,
                equipoBienPersona,
                areaRespRepa,
                costoDanio,
                estatus,
                tiempoInvertido,
                atendio,
                notas,
                finalizado,
                seguimiento }) {

        let error;
        let validaciones = [



            {
                condicion: !fechaElaboracion, // || trim(fechaElaboracion) === "",
                ref: fechaElaboracionRef, keyError: "fechaElaboracion",
                mensajeError: "Debe especificar la fecha de elaboración"
            },
            /*{
                condicion: !numIncidencia || trim(numIncidencia) === "",
                ref: numIncidenciaRef, keyError: "numIncidencia",
                mensajeError: "Debe especificar el número de incidencia"
            },

            {
                condicion: !numNC || trim(numNC) === "",
                ref: numNCRef, keyError: "numNC",
                mensajeError: "Debe especificar el número NC"
            },*/
            {
                condicion: !fechaEvento , // || trim(fechaEvento) === "",
                ref: fechaEventoRef, keyError: "fechaEvento",
                mensajeError: "Debe especificar la fecha del evento"
            },
            {
                condicion: !horaEvento /* || trim(horaEvento) === ""*/,
                ref: horaEventoRef, keyError: "horaEvento",
                mensajeError: "Debe especificar la hora del evento"
            },

            {
                condicion: !lugarEvento || trim(lugarEvento) === "",
                ref: lugarEventoRef, keyError: "lugarEvento",
                mensajeError: "Debe especificar el lugar del evento"
            },

            {
                condicion: !supervCargo || trim(supervCargo) === "",
                ref: supervCargoRef, keyError: "supervCargo",
                mensajeError: "Debe especificar el supervisor a cargo"
            },
            {
                condicion: !area || trim(area) === "",
                ref: areaRef, keyError: "area",
                mensajeError: "Debe especificar el área"
            },
            {
                condicion: !empresaID  /* !rgxNumeros.test(empresaID)*/,
                  keyError: "empresaID",
                mensajeError: "Debe especificar la empresa"
            },
            {
                condicion: !empresaPersonalAfectadaID /* !rgxNumeros.test(empresaPersonalAfectadaID)*/,
                  keyError: "empresaPersonalAfectadaID",
                mensajeError: "Debe especificar la empresa del personal"
            },
            {
                condicion: !puestoHabilidadBase || trim(puestoHabilidadBase) === "",
                ref: puestoHabilidadBaseRef, keyError: "puestoHabilidadBase",
                mensajeError: "Debe especificar el puesto o habilidad base"
            },
            {
                condicion: !puestoHabilidadRealizaba || trim(puestoHabilidadRealizaba) === "",
                ref: puestoHabilidadRealizabaRef, keyError: "puestoHabilidadRealizaba",
                mensajeError: "Debe especificar el puesto o habilidad que realizaba"
            },
            {
                condicion: !nombreTrabajador || trim(nombreTrabajador) === "",
                ref: nombreTrabajadorRef, keyError: "nombreTrabajador",
                mensajeError: "Debe especificar el nombre del trabajador"
            },
            {
                condicion: !numControlTrabajador || trim(numControlTrabajador) === "",
                ref: numControlTrabajadorRef, keyError: "numControlTrabajador",
                mensajeError: "Debe especificar el n'umero de control del trabajador"
            },
            {
                condicion: !actividadDesarrollaba || trim(actividadDesarrollaba) === "",
                ref: actividadDesarrollabaRef, keyError: "actividadDesarrollaba",
                mensajeError: "Debe especificar el actividad que desarrollaba"
            },
            {
                condicion: !jornadaDelSuceso || trim(jornadaDelSuceso) === "",
                ref: jornadaDelSucesoRef, keyError: "jornadaDelSuceso",
                mensajeError: "Debe especificar la jornada del suceso"
            },
            {
                condicion: !diaUltimoDescanzo /*|| trim(diaUltimoDescanzo) === ""*/,
                ref: diaUltimoDescanzoRef, keyError: "diaUltimoDescanzo",
                mensajeError: "Debe especificar el dia ultimo de descanzo"
            },
            {
                condicion: !descripDetallada || trim(descripDetallada) === "",
                ref: descripDetalladaRef, keyError: "descripDetallada",
                mensajeError: "Debe especificar la descripción detallada"
            },
           /* {
                condicion: !observGenerales || trim(observGenerales) === "",
                ref: observGeneralesRef, keyError: "observGenerales",
                mensajeError: "Debe especificar el observaciones generales"
            },*/
            {
                condicion: !responAnalisis || trim(responAnalisis) === "",
                ref: responAnalisisRef, keyError: "responAnalisis",
                mensajeError: "Debe especificar el responsable de analisis"
            },
            {
                condicion: !elaboro || trim(elaboro) === "",
                ref: elaboroRef, keyError: "elaboro",
                mensajeError: "Debe especificar el elaboro"
            },
            {
                condicion: !cargo || trim(cargo) === "",
                ref: cargoRef, keyError: "cargo",
                mensajeError: "Debe especificar el cargo"
            },
            {
                condicion: !portabaEPP && !motivoAusenciaEPP && trim(motivoAusenciaEPP) === "",
                ref: motivoAusenciaEPPRef, keyError: "motivoAusenciaEPP",
                mensajeError: "Debe especificar el motivo de ausencia de EPP"
            }
        ];


        /*,

            {
                condicion: !portabaEPP || trim(portabaEPP) === "",
                ref: portabaEPPRef, keyError: "portabaEPP",
                mensajeError: "Debe especificar el portabaEPP"
            },
            {
                condicion: !motivoAusenciaEPP || trim(motivoAusenciaEPP) === "",
                ref: motivoAusenciaEPPRef, keyError: "motivoAusenciaEPP",
                mensajeError: "Debe especificar el motivoAusenciaEPP"
            },
            {
                condicion: !iluminacionDeficiente || trim(iluminacionDeficiente) === "",
                ref: iluminacionDeficienteRef, keyError: "iluminacionDeficiente",
                mensajeError: "Debe especificar el iluminacionDeficiente"
            },
            {
                condicion: !condMeteorologicaAdvs || trim(condMeteorologicaAdvs) === "",
                ref: condMeteorologicaAdvsRef, keyError: "condMeteorologicaAdvs",
                mensajeError: "Debe especificar el condMeteorologicaAdvs"
            },
            {
                condicion: !faltaOrdenLimpieza || trim(faltaOrdenLimpieza) === "",
                ref: faltaOrdenLimpiezaRef, keyError: "faltaOrdenLimpieza",
                mensajeError: "Debe especificar el faltaOrdenLimpieza"
            },
            {
                condicion: !espaciosReducidos || trim(espaciosReducidos) === "",
                ref: espaciosReducidosRef, keyError: "espaciosReducidos",
                mensajeError: "Debe especificar el espaciosReducidos"
            },
            {
                condicion: !instalacionMalEstado || trim(instalacionMalEstado) === "",
                ref: instalacionMalEstadoRef, keyError: "instalacionMalEstado",
                mensajeError: "Debe especificar el instalacionMalEstado"
            },
            {
                condicion: !fallasEnProceso || trim(fallasEnProceso) === "",
                ref: fallasEnProcesoRef, keyError: "fallasEnProceso",
                mensajeError: "Debe especificar el fallasEnProceso"
            },
            {
                condicion: !cortesPorHerramienta || trim(cortesPorHerramienta) === "",
                ref: cortesPorHerramientaRef, keyError: "cortesPorHerramienta",
                mensajeError: "Debe especificar el cortesPorHerramienta"
            },
            {
                condicion: !golpesPorObjetosEnCaida || trim(golpesPorObjetosEnCaida) === "",
                ref: golpesPorObjetosEnCaidaRef, keyError: "golpesPorObjetosEnCaida",
                mensajeError: "Debe especificar el golpesPorObjetosEnCaida"
            },
            {
                condicion: !caidasAlMismoDifNivel || trim(caidasAlMismoDifNivel) === "",
                ref: caidasAlMismoDifNivelRef, keyError: "caidasAlMismoDifNivel",
                mensajeError: "Debe especificar el caidasAlMismoDifNivel"
            },
            {
                condicion: !concactoSustanciasQuimicas || trim(concactoSustanciasQuimicas) === "",
                ref: concactoSustanciasQuimicasRef, keyError: "concactoSustanciasQuimicas",
                mensajeError: "Debe especificar el concactoSustanciasQuimicas"
            },
            {
                condicion: !atrapamientoExtremidades || trim(atrapamientoExtremidades) === "",
                ref: atrapamientoExtremidadesRef, keyError: "atrapamientoExtremidades",
                mensajeError: "Debe especificar el atrapamientoExtremidades"
            },
            {
                condicion: !lesionesErgonomicas || trim(lesionesErgonomicas) === "",
                ref: lesionesErgonomicasRef, keyError: "lesionesErgonomicas",
                mensajeError: "Debe especificar el lesionesErgonomicas"
            },
            {
                condicion: !colisionChoque || trim(colisionChoque) === "",
                ref: colisionChoqueRef, keyError: "colisionChoque",
                mensajeError: "Debe especificar el colisionChoque"
            },
            {
                condicion: !quemaduras || trim(quemaduras) === "",
                ref: quemadurasRef, keyError: "quemaduras",
                mensajeError: "Debe especificar el quemaduras"
            },
            {
                condicion: !cabeza || trim(cabeza) === "",
                ref: cabezaRef, keyError: "cabeza",
                mensajeError: "Debe especificar el cabeza"
            },
            {
                condicion: !cara || trim(cara) === "",
                ref: caraRef, keyError: "cara",
                mensajeError: "Debe especificar el cara"
            },
            {
                condicion: !cuello || trim(cuello) === "",
                ref: cuelloRef, keyError: "cuello",
                mensajeError: "Debe especificar el cuello"
            },
            {
                condicion: !espalda || trim(espalda) === "",
                ref: espaldaRef, keyError: "espalda",
                mensajeError: "Debe especificar el espalda"
            },
            {
                condicion: !pecho || trim(pecho) === "",
                ref: pechoRef, keyError: "pecho",
                mensajeError: "Debe especificar el pecho"
            },
            {
                condicion: !brazos || trim(brazos) === "",
                ref: brazosRef, keyError: "brazos",
                mensajeError: "Debe especificar el brazos"
            },
            {
                condicion: !manos || trim(manos) === "",
                ref: manosRef, keyError: "manos",
                mensajeError: "Debe especificar el manos"
            },
            {
                condicion: !piernas || trim(piernas) === "",
                ref: piernasRef, keyError: "piernas",
                mensajeError: "Debe especificar el piernas"
            },
            {
                condicion: !pies || trim(pies) === "",
                ref: piesRef, keyError: "pies",
                mensajeError: "Debe especificar el pies"
            },
            {
                condicion: !descripDetallada || trim(descripDetallada) === "",
                ref: descripDetalladaRef, keyError: "descripDetallada",
                mensajeError: "Debe especificar el descripDetallada"
            },
            {
                condicion: !fotografias || trim(fotografias) === "",
                ref: fotografiasRef, keyError: "fotografias",
                mensajeError: "Debe especificar el fotografias"
            },
            {
                condicion: !existenTestigos || trim(existenTestigos) === "",
                ref: existenTestigosRef, keyError: "existenTestigos",
                mensajeError: "Debe especificar el existenTestigos"
            },
            {
                condicion: !nombresPersonalTestigo || trim(nombresPersonalTestigo) === "",
                ref: nombresPersonalTestigoRef, keyError: "nombresPersonalTestigo",
                mensajeError: "Debe especificar el nombresPersonalTestigo"
            },
            {
                condicion: !numControlPersonalTestigo || trim(numControlPersonalTestigo) === "",
                ref: numControlPersonalTestigoRef, keyError: "numControlPersonalTestigo",
                mensajeError: "Debe especificar el numControlPersonalTestigo"
            },
            {
                condicion: !puestoHabilidadTestigo || trim(puestoHabilidadTestigo) === "",
                ref: puestoHabilidadTestigoRef, keyError: "puestoHabilidadTestigo",
                mensajeError: "Debe especificar el puestoHabilidadTestigo"
            },
            {
                condicion: !observGenerales || trim(observGenerales) === "",
                ref: observGeneralesRef, keyError: "observGenerales",
                mensajeError: "Debe especificar el observGenerales"
            },
            {
                condicion: !responAnalisis || trim(responAnalisis) === "",
                ref: responAnalisisRef, keyError: "responAnalisis",
                mensajeError: "Debe especificar el responAnalisis"
            },
            {
                condicion: !elaboro || trim(elaboro) === "",
                ref: elaboroRef, keyError: "elaboro",
                mensajeError: "Debe especificar el elaboro"
            },
            {
                condicion: !firma || trim(firma) === "",
                ref: firmaRef, keyError: "firma",
                mensajeError: "Debe especificar el firma"
            },
            {
                condicion: !cargo || trim(cargo) === "",
                ref: cargoRef, keyError: "cargo",
                mensajeError: "Debe especificar el cargo"
            },
            {
                condicion: !tipoDanioOcasio || trim(tipoDanioOcasio) === "",
                ref: tipoDanioOcasioRef, keyError: "tipoDanioOcasio",
                mensajeError: "Debe especificar el tipoDanioOcasio"
            },
            {
                condicion: !folio || trim(folio) === "",
                ref: folioRef, keyError: "folio",
                mensajeError: "Debe especificar el folio"
            },
            {
                condicion: !danioOcasioPor || trim(danioOcasioPor) === "",
                ref: danioOcasioPorRef, keyError: "danioOcasioPor",
                mensajeError: "Debe especificar el danioOcasioPor"
            },
            {
                condicion: !danioOcasioARep || trim(danioOcasioARep) === "",
                ref: danioOcasioARepRef, keyError: "danioOcasioARep",
                mensajeError: "Debe especificar el danioOcasioARep"
            },
            {
                condicion: !equipoBienPersona || trim(equipoBienPersona) === "",
                ref: equipoBienPersonaRef, keyError: "equipoBienPersona",
                mensajeError: "Debe especificar el equipoBienPersona"
            },
            {
                condicion: !areaRespRepa || trim(areaRespRepa) === "",
                ref: areaRespRepaRef, keyError: "areaRespRepa",
                mensajeError: "Debe especificar el areaRespRepa"
            },
            {
                condicion:  !rgxNumeros.test(costoDanio),
                ref: costoDanioRef, keyError: "costoDanio",
                mensajeError: "Debe especificar el costoDanio"
            },
            {
                condicion: !estatus || trim(estatus) === "",
                ref: estatusRef, keyError: "estatus",
                mensajeError: "Debe especificar el estatus"
            },
            {
                condicion: !tiempoInvertido || trim(tiempoInvertido) === "",
                ref: tiempoInvertidoRef, keyError: "tiempoInvertido",
                mensajeError: "Debe especificar el tiempoInvertido"
            },
            {
                condicion: !atendio || trim(atendio) === "",
                ref: atendioRef, keyError: "atendio",
                mensajeError: "Debe especificar el atendio"
            },
            {
                condicion: !notas || trim(notas) === "",
                ref: notasRef, keyError: "notas",
                mensajeError: "Debe especificar el notas"
            },
            {
                condicion:  !rgxNumeros.test(finalizado),
                ref: finalizadoRef, keyError: "finalizado",
                mensajeError: "Debe especificar el finalizado"
            },
            {
                condicion: !seguimiento || trim(seguimiento) === "",
                ref: seguimientoRef, keyError: "seguimiento",
                mensajeError: "Debe especificar el seguimiento"
            }*/

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function handleValidacionesPrueba({
        empresaID,
        fechaElaboracion,
        lugarEvento,
        fechaEvento,
        horaEvento,
        supervCargo,
        area,
        empresaPersonalAfectadaID,
        nombreTrabajador,
        actividadDesarrollaba,
        jornadaDelSuceso,
        descripDetallada }) {

let error;
let validaciones = [



    {
        condicion: !fechaElaboracion, // || trim(fechaElaboracion) === "",
        ref: fechaElaboracionRef, keyError: "fechaElaboracion",
        mensajeError: "Debe especificar la fecha de elaboración"
    },
    {
        condicion: !fechaEvento , // || trim(fechaEvento) === "",
        ref: fechaEventoRef, keyError: "fechaEvento",
        mensajeError: "Debe especificar la fecha del evento"
    },
    {
        condicion: !horaEvento /* || trim(horaEvento) === ""*/,
        ref: horaEventoRef, keyError: "horaEvento",
        mensajeError: "Debe especificar la hora del evento"
    },

    {
        condicion: !lugarEvento || trim(lugarEvento) === "",
        ref: lugarEventoRef, keyError: "lugarEvento",
        mensajeError: "Debe especificar el lugar del evento"
    },

    {
        condicion: !supervCargo || trim(supervCargo) === "",
        ref: supervCargoRef, keyError: "supervCargo",
        mensajeError: "Debe especificar el supervisor a cargo"
    },
    {
        condicion: !area || trim(area) === "",
        ref: areaRef, keyError: "area",
        mensajeError: "Debe especificar el área"
    },
    {
        condicion: !empresaID  /* !rgxNumeros.test(empresaID)*/,
          keyError: "empresaID",
        mensajeError: "Debe especificar la empresa"
    },
    {
        condicion: !empresaPersonalAfectadaID /* !rgxNumeros.test(empresaPersonalAfectadaID)*/,
          keyError: "empresaPersonalAfectadaID",
        mensajeError: "Debe especificar la empresa del personal"
    },
    {
        condicion: !nombreTrabajador || trim(nombreTrabajador) === "",
        ref: nombreTrabajadorRef, keyError: "nombreTrabajador",
        mensajeError: "Debe especificar el nombre del trabajador"
    },
    {
        condicion: !actividadDesarrollaba || trim(actividadDesarrollaba) === "",
        ref: actividadDesarrollabaRef, keyError: "actividadDesarrollaba",
        mensajeError: "Debe especificar el actividad que desarrollaba"
    },
    {
        condicion: !jornadaDelSuceso || trim(jornadaDelSuceso) === "",
        ref: jornadaDelSucesoRef, keyError: "jornadaDelSuceso",
        mensajeError: "Debe especificar la jornada del suceso"
    },
    {
        condicion: !descripDetallada || trim(descripDetallada) === "",
        ref: descripDetalladaRef, keyError: "descripDetallada",
        mensajeError: "Debe especificar la descripción detallada"
    },
]
validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
    if (error) return;
    error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
});

if (error) return error;

}

    function setRedireccionarFormulario({reporteAccidenteLaboralID,folioInterno}){

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${reporteAccidenteLaboralID}", "pTipo": "${folioInterno}", "pTabla": "Reporte Accidente Laboral", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({data: datos, seed}).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    function ftGuardar() {
        async function ftGuardar() {

            let {
                reporteAccidenteLaboralID,
                empresaID,
                folioInterno,
                fechaElaboracion,
                numIncidencia,
                numNC,
                descripBreve,
                lugarEvento,
                fechaEvento,
                horaEvento,
                supervCargo,
                area,
                empresaPersonalAfectadaID,
                puestoHabilidadBase,
                puestoHabilidadRealizaba,
                nombreTrabajador,
                numControlTrabajador,
                actividadDesarrollaba,
                jornadaDelSuceso,
                diaUltimoDescanzo,
                portabaEPP,
                motivoAusenciaEPP,
                iluminacionDeficiente,
                condMeteorologicaAdvs,
                faltaOrdenLimpieza,
                espaciosReducidos,
                instalacionMalEstado,
                fallasEnProceso,
                cortesPorHerramienta,
                golpesPorObjetosEnCaida,
                caidasAlMismoDifNivel,
                concactoSustanciasQuimicas,
                atrapamientoExtremidades,
                lesionesErgonomicas,
                colisionChoque,
                quemaduras,
                cabeza,
                cara,
                cuello,
                espalda,
                pecho,
                brazos,
                manos,
                piernas,
                pies,
                descripDetallada,
                fotografias,
                existenTestigos,
                nombresPersonalTestigo1,
                numControlPersonalTestigo1,
                puestoHabilidadTestigo1,
                nombresPersonalTestigo2,
                numControlPersonalTestigo2,
                puestoHabilidadTestigo2,
                observGenerales,
                responAnalisis,
                elaboro,
                firma,
                cargo,
                tipoDanioOcasio,
                folio,
                danioOcasioPor,
                danioOcasioARep,
                equipoBienPersona,
                areaRespRepa,
                costoDanio,
                estatus,
                tiempoInvertido,
                atendio,
                notas,
                finalizado,
                seguimiento,
                storageID1,
                rutafotografia1,
                nombreEmpresa
             } = state;


             if( nombreEmpresa === "EXTERNO" ){

                let error = await handleValidacionesPrueba({
                    empresaID,
                    fechaElaboracion,
                    lugarEvento,
                    fechaEvento,
                    horaEvento,
                    supervCargo,
                    area,
                    empresaPersonalAfectadaID,
                    nombreTrabajador,
                    actividadDesarrollaba,
                    jornadaDelSuceso,
                    descripDetallada
                });
    
    
    
                if (error) return error;

             }else{

                let error = await handleValidaciones({
    
                    empresaID,
                    folioInterno,
                    fechaElaboracion,
                    numIncidencia,
                    numNC,
                    descripBreve,
                    lugarEvento,
                    fechaEvento,
                    horaEvento,
                    supervCargo,
                    area,
                    empresaPersonalAfectadaID,
                    puestoHabilidadBase,
                    puestoHabilidadRealizaba,
                    nombreTrabajador,
                    numControlTrabajador,
                    actividadDesarrollaba,
                    jornadaDelSuceso,
                    diaUltimoDescanzo,
                    portabaEPP,
                    motivoAusenciaEPP,
                    iluminacionDeficiente,
                    condMeteorologicaAdvs,
                    faltaOrdenLimpieza,
                    espaciosReducidos,
                    instalacionMalEstado,
                    fallasEnProceso,
                    cortesPorHerramienta,
                    golpesPorObjetosEnCaida,
                    caidasAlMismoDifNivel,
                    concactoSustanciasQuimicas,
                    atrapamientoExtremidades,
                    lesionesErgonomicas,
                    colisionChoque,
                    quemaduras,
                    cabeza,
                    cara,
                    cuello,
                    espalda,
                    pecho,
                    brazos,
                    manos,
                    piernas,
                    pies,
                    descripDetallada,
                    fotografias,
                    existenTestigos,
                    nombresPersonalTestigo1,
                    numControlPersonalTestigo1,
                    puestoHabilidadTestigo1,
                    nombresPersonalTestigo2,
                    numControlPersonalTestigo2,
                    puestoHabilidadTestigo2,
                    observGenerales,
                    responAnalisis,
                    elaboro,
                    firma,
                    cargo,
                    tipoDanioOcasio,
                    folio,
                    danioOcasioPor,
                    danioOcasioARep,
                    equipoBienPersona,
                    areaRespRepa,
                    costoDanio,
                    estatus,
                    tiempoInvertido,
                    atendio,
                    notas,
                    finalizado,
                    seguimiento
                });
    
    
    
                if (error) return error;

             }

           /* let datos = {serverStorageID:stDatos.storageID1, origenID: Number(DatosGuardar.reporteDaniosID),tabla: "REPORTEDANIOS", tipo: "REPORTEDANIOS", clasificacion: "fotoDanio", nombreArchivo: stFotografia1.eliminar ? "" : stFotografia1.file.name, ruta: stDatos["rutafotografia1"] }

*/
          /*  let fotoEvidencia = null;

            for (const key in stFotografias) {
                if(stFotografias[key].file || stFotografias[key].eliminar){
                    let url = stFotografias[key].url;
                    let indice = url.indexOf('?');
                    let urloriginal = url.substring(0, indice);

                    let ruta = 'reportesAccidentes/Evidencia';

                    let result = await actualizaArchivoSoloRuta(ruta, stFotografias[key].file, 0, Number(stFotografias[key].IDimagen ), token, stFotografias[key].eliminar);
                    if(result){
                        if (stFotografias[key].eliminar === true) {
                            result = "";
                        }
                        fotoEvidencia = result;
                    }
                }
            }*/

            let datos = {serverStorageID:storageID1, origenID: Number(reporteAccidenteLaboralID),tabla: "REPORTEACCIDENTELABORAL", tipo: "REPORTEACCIDENTELABORAL", clasificacion: "fotoEvidencia", nombreArchivo: stFotografias.eliminar ? "" : stFotografias.file.name, ruta: rutafotografia1}


            let valor ;
            if (reporteAccidenteLaboralID) {
                 valor = await updateReporteAccidenteLaboral({
                input: {

                    reporteAccidenteLaboralID,
                    empresaID: empresaID ? Number(empresaID): null ,
                    folioInterno: folioInterno ? Number(folioInterno): null ,
                    fechaElaboracion : formatearFecha(fechaElaboracion),
                    numIncidencia:  numIncidencia ? trim(numIncidencia ): null  ,
                    numNC:  numNC ? trim(numNC ): null  ,
                    descripBreve:  descripBreve ? trim(descripBreve ): null  ,
                    lugarEvento:  lugarEvento ? trim(lugarEvento ): null  ,
                    fechaEvento :formatearFecha(fechaEvento),
                    horaEvento: formatearHora(horaEvento)  ,
                    supervCargo:  supervCargo ? trim(supervCargo ): null  ,
                    area:  area ? trim(area ): null  ,
                    empresaPersonalAfectadaID: empresaPersonalAfectadaID ? Number(empresaPersonalAfectadaID): null ,
                    puestoHabilidadBase:  puestoHabilidadBase ? trim(puestoHabilidadBase ): null  ,
                    puestoHabilidadRealizaba:  puestoHabilidadRealizaba ? trim(puestoHabilidadRealizaba ): null  ,
                    nombreTrabajador:  nombreTrabajador ? trim(nombreTrabajador ): null  ,
                    numControlTrabajador:  numControlTrabajador ? trim(numControlTrabajador ): null  ,
                    actividadDesarrollaba:  actividadDesarrollaba ? trim(actividadDesarrollaba ): null  ,
                    jornadaDelSuceso:  jornadaDelSuceso ? trim(jornadaDelSuceso ): null  ,
                    diaUltimoDescanzo :formatearFecha(diaUltimoDescanzo),
                    portabaEPP,
                    motivoAusenciaEPP:  motivoAusenciaEPP ? trim(motivoAusenciaEPP ): null  ,
                    iluminacionDeficiente,
                    condMeteorologicaAdvs,
                    faltaOrdenLimpieza,
                    espaciosReducidos,
                    instalacionMalEstado,
                    fallasEnProceso,
                    cortesPorHerramienta,
                    golpesPorObjetosEnCaida,
                    caidasAlMismoDifNivel,
                    concactoSustanciasQuimicas,
                    atrapamientoExtremidades,
                    lesionesErgonomicas,
                    colisionChoque,
                    quemaduras,
                    cabeza,
                    cara,
                    cuello,
                    espalda,
                    pecho,
                    brazos:  brazos ? trim(brazos ): null  ,
                    manos:  manos ? trim(manos ): null  ,
                    piernas:  piernas ? trim(piernas ): null  ,
                    pies:  pies ? trim(pies ): null  ,
                    descripDetallada:  descripDetallada ? trim(descripDetallada ): null  ,
                    fotografias:  fotografias ? trim(fotografias ): null  ,
                    existenTestigos,
                    nombresPersonalTestigo1:  nombresPersonalTestigo1 ? trim(nombresPersonalTestigo1 ): null  ,
                    numControlPersonalTestigo1:  numControlPersonalTestigo1 ? trim(numControlPersonalTestigo1 ): null  ,
                    puestoHabilidadTestigo1:  puestoHabilidadTestigo1 ? trim(puestoHabilidadTestigo1 ): null  ,
                    nombresPersonalTestigo2:  nombresPersonalTestigo2 ? trim(nombresPersonalTestigo2 ): null  ,
                    numControlPersonalTestigo2:  numControlPersonalTestigo2 ? trim(numControlPersonalTestigo2 ): null  ,
                    puestoHabilidadTestigo2:  puestoHabilidadTestigo2 ? trim(puestoHabilidadTestigo2 ): null  ,
                    observGenerales:  observGenerales ? trim(observGenerales ): null  ,
                    responAnalisis:  responAnalisis ? trim(responAnalisis ): null  ,
                    elaboro:  elaboro ? trim(elaboro ): null  ,
                    firma:  firma ? trim(firma ): null  ,
                    cargo:  cargo ? trim(cargo ): null  ,
                    tipoDanioOcasio:  tipoDanioOcasio ? trim(tipoDanioOcasio ): null  ,
                    folio:  folio ? trim(folio ): null  ,
                    danioOcasioPor:  danioOcasioPor ? trim(danioOcasioPor ): null  ,
                    danioOcasioARep:  danioOcasioARep ? trim(danioOcasioARep ): null  ,
                    equipoBienPersona:  equipoBienPersona ? trim(equipoBienPersona ): null  ,
                    areaRespRepa:  areaRespRepa ? trim(areaRespRepa ): null  ,
                    costoDanio: costoDanio ? Number(costoDanio): null ,
                    estatus:  estatus ? trim(estatus ): null  ,
                    tiempoInvertido:  tiempoInvertido ? trim(tiempoInvertido ): null  ,
                    atendio:  atendio ? trim(atendio ): null  ,
                    notas:  notas ? trim(notas ): null  ,
                    finalizado: finalizado ? Number(finalizado): null ,
                    seguimiento:  seguimiento ? trim(seguimiento ): null

                }
            }, token);
            }
            else {
                valor = await addReporteAccidenteLaboral({
                input: {
                    reporteAccidenteLaboralID:null,
                    empresaID: empresaID ? Number(empresaID): null ,
                    folioInterno: folioInterno ? Number(folioInterno): null ,
                    fechaElaboracion:formatearFecha(fechaElaboracion),
                    numIncidencia:  numIncidencia ? trim(numIncidencia ): null  ,
                    numNC:  numNC ? trim(numNC ): null  ,
                    descripBreve:  descripBreve ? trim(descripBreve ): null  ,
                    lugarEvento:  lugarEvento ? trim(lugarEvento ): null  ,
                    fechaEvento: formatearFecha(fechaEvento) ,
                    horaEvento:  formatearHora( horaEvento ) ,
                    supervCargo:  supervCargo ? trim(supervCargo ): null  ,
                    area:  area ? trim(area ): null  ,
                    empresaPersonalAfectadaID: empresaPersonalAfectadaID ? Number(empresaPersonalAfectadaID): null ,
                    puestoHabilidadBase:  puestoHabilidadBase ? trim(puestoHabilidadBase ): null  ,
                    puestoHabilidadRealizaba:  puestoHabilidadRealizaba ? trim(puestoHabilidadRealizaba ): null  ,
                    nombreTrabajador:  nombreTrabajador ? trim(nombreTrabajador ): null  ,
                    numControlTrabajador:  numControlTrabajador ? trim(numControlTrabajador ): null  ,
                    actividadDesarrollaba:  actividadDesarrollaba ? trim(actividadDesarrollaba ): null  ,
                    jornadaDelSuceso:  jornadaDelSuceso ? trim(jornadaDelSuceso ): null  ,
                    diaUltimoDescanzo : formatearFecha(diaUltimoDescanzo) ,
                    portabaEPP,
                    motivoAusenciaEPP:  motivoAusenciaEPP ? trim(motivoAusenciaEPP ): null  ,
                    iluminacionDeficiente,
                    condMeteorologicaAdvs,
                    faltaOrdenLimpieza,
                    espaciosReducidos,
                    instalacionMalEstado,
                    fallasEnProceso,
                    cortesPorHerramienta,
                    golpesPorObjetosEnCaida,
                    caidasAlMismoDifNivel,
                    concactoSustanciasQuimicas,
                    atrapamientoExtremidades,
                    lesionesErgonomicas,
                    colisionChoque,
                    quemaduras,
                    cabeza,
                    cara,
                    cuello,
                    espalda,
                    pecho,
                    brazos:  brazos ? trim(brazos ): null  ,
                    manos:  manos ? trim(manos ): null  ,
                    piernas:  piernas ? trim(piernas ): null  ,
                    pies:  pies ? trim(pies ): null  ,
                    descripDetallada:  descripDetallada ? trim(descripDetallada ): null  ,
                    fotografias:  fotografias ? trim(fotografias ): null  ,
                    existenTestigos,
                    nombresPersonalTestigo1:  nombresPersonalTestigo1 ? trim(nombresPersonalTestigo1 ): null  ,
                    numControlPersonalTestigo1:  numControlPersonalTestigo1 ? trim(numControlPersonalTestigo1 ): null  ,
                    puestoHabilidadTestigo1:  puestoHabilidadTestigo1 ? trim(puestoHabilidadTestigo1 ): null  ,
                    nombresPersonalTestigo2:  nombresPersonalTestigo2 ? trim(nombresPersonalTestigo2 ): null  ,
                    numControlPersonalTestigo2:  numControlPersonalTestigo2 ? trim(numControlPersonalTestigo2 ): null  ,
                    puestoHabilidadTestigo2:  puestoHabilidadTestigo2 ? trim(puestoHabilidadTestigo2 ): null  ,
                    observGenerales:  observGenerales ? trim(observGenerales ): null  ,
                    responAnalisis:  responAnalisis ? trim(responAnalisis ): null  ,
                    elaboro:  elaboro ? trim(elaboro ): null  ,
                    firma:  firma ? trim(firma ): null  ,
                    cargo:  cargo ? trim(cargo ): null  ,
                    tipoDanioOcasio:  tipoDanioOcasio ? trim(tipoDanioOcasio ): null  ,
                    folio:  folio ? trim(folio ): null  ,
                    danioOcasioPor:  danioOcasioPor ? trim(danioOcasioPor ): null  ,
                    danioOcasioARep:  danioOcasioARep ? trim(danioOcasioARep ): null  ,
                    equipoBienPersona:  equipoBienPersona ? trim(equipoBienPersona ): null  ,
                    areaRespRepa:  areaRespRepa ? trim(areaRespRepa ): null  ,
                    costoDanio: costoDanio ? Number(costoDanio): null ,
                    estatus:  estatus ? trim(estatus ): null  ,
                    tiempoInvertido:  tiempoInvertido ? trim(tiempoInvertido ): null  ,
                    atendio:  atendio ? trim(atendio ): null  ,
                    notas:  notas ? trim(notas ): null  ,
                    finalizado: finalizado ? Number(finalizado): null ,
                    seguimiento:  seguimiento ? trim(seguimiento ): null
                }
            }, token);

            datos = { ...datos, serverStorageID: null, origenID: valor.reporteAccidenteLaboralID };
        }

        try{
            if(stFotografias.file || stFotografias.eliminar == true){
                let res = await actualizaArchivo('reportesAccidentes/Evidencia/', stFotografias.file, 1, datos, token, stFotografias.eliminar);

            }
        } catch(error)
        {
            setNotificaciones( { mensaje: error, color: danger, open: true });
        }
        }
        return ftGuardar();
    }

    function formatearHora(pHora){
        if(moment(pHora).isValid() === false){
            let tHora = moment(pHora,'HH:mm').format('HH:mm');
            if(moment(tHora).isValid() === false){
                return tHora;
            }
            return null;
        }

        return moment(pHora).format("HH:mm");
    }

    function formatearFecha(pFecha){
        if(moment(pFecha).isValid() === false){
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setFotografias({ valor: "", file: "", remover: false });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseEnviarCorreo() {
        setOpenEnviarCorreo(false);
        setState({ ...cleanState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    useEffect(() => {
        async function ftEffect() {
            try {
                let Empresa = await seleccionEmpresa({ filtro: {activa:1} }, token);
                let Aseguradora = await seleccionAseguradora({ filtro: {} }, token);
                let dominios = await ConsultasDominios.seleccionDominios({ }, token);


                setSeleccionables({ Empresa,Aseguradora,dominios });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaReporteAccidenteLaboral}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={empresaIDRef}
        />
        {
         openEnviarCorreo && <FormularioModal
             open={openEnviarCorreo}
             title={"Enviar por correo"}
             onClose={handleCloseEnviarCorreo}
            //  notificaciones={notificaciones}
            //  closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
             inputs={inputsEnviarCorreo}
             acciones={accionesFormularioEnviarCorreo}
         />
        }
        {
             openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos}   setOpenDocumentacion = { () => setOpenDocumentacion(false)
                } />

            </Modal>
          }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default ReporteAccidenteLaboral;
