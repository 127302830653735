import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_POLIZAS_SEGURO_DETALLE = `
mutation addPolizasSeguroDetalle($input: InputAddPolizasSeguroDetalle!) {
    addPolizasSeguroDetalle(input: $input) {
        polSegDetalleID
        polizaSeguroID
        deducible
        cobertura
        exclusiones
        bienesAmparados
        tipo
        maximoMinimo 
    }
}
`;


export const UPDATE_POLIZAS_SEGURO_DETALLE  =`
mutation updatePolizasSeguroDetalle($input: InputUpdatePolizasSeguroDetalle!) {
    updatePolizasSeguroDetalle(input: $input) {
        polSegDetalleID
        polizaSeguroID
        deducible
        cobertura
        exclusiones
        bienesAmparados
        tipo
        maximoMinimo 
    }
}
`;

export const DELETE_POLIZAS_SEGURO_DETALLE  = `
mutation deletePolizasSeguroDetalle($input: InputDeletePolizasSeguroDetalle) {
    deletePolizasSeguroDetalle(input: $input)
}
`;


export const addPolizasSeguroDetalle = async (variables, token) => {
    try {

        let { addPolizasSeguroDetalle } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_POLIZAS_SEGURO_DETALLE 
        });

        return addPolizasSeguroDetalle;

    } catch({message}) { throw new Error(message); }
};

export const updatePolizasSeguroDetalle = async (variables, token) => {
    try {

        let { updatePolizasSeguroDetalle } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_POLIZAS_SEGURO_DETALLE 
        });

        return updatePolizasSeguroDetalle;

    } catch({message}) { throw new Error(message); }
};


export const deletePolizasSeguroDetalle = async (input, token) => {
    try {

        let { deletePolizasSeguroDetalle } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_POLIZAS_SEGURO_DETALLE 
        });

        return deletePolizasSeguroDetalle;

    } catch({message}) { throw new Error(message); }
};
