import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_FOTOS_EVIDENCIA = `
mutation addFotosEvidencia($input: InputAddFotosEvidencia!) {
    addFotosEvidencia(input: $input) {
    fotosID,
    usuarioBitID
    fechaAgregada
    descripcion
    }
}
`;


export const UPDATE_FOTOS_EVIDENCIA =`
mutation updateFotosEvidencia($input: InputUpdateFotosEvidencia!) {
    updateFotosEvidencia(input: $input) {
    fotosID,
    usuarioBitID
    fechaAgregada
    descripcion
    }
}
`;

export const DELETE_FOTOS_EVIDENCIA = `
mutation deleteFotosEvidencia($input: InputDeleteFotosEvidencia) {
    deleteFotosEvidencia(input: $input)
}
`;


export const addFotosEvidencia = async (variables, token) => {
    try {

        let { addFotosEvidencia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_FOTOS_EVIDENCIA
        });

        return addFotosEvidencia;

    } catch({message}) { throw new Error(message); }
};

export const updateFotosEvidencia = async (variables, token) => {
    try {

        let { updateFotosEvidencia } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_FOTOS_EVIDENCIA
        });

        return updateFotosEvidencia;

    } catch({message}) { throw new Error(message); }
};


export const deleteFotosEvidencia = async (input, token) => {
    try {

        let { deleteFotosEvidencia } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_FOTOS_EVIDENCIA
        });

        return deleteFotosEvidencia;

    } catch({message}) { throw new Error(message); }
};
