import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

//Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from 'react-router-dom';

//master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

//componentes genericos
import SweetAlert from '_layout/genericComponents/ModalConfirmacion';

//QUERIES
import { getGrupo, tablaGrupos } from '../../../querys/Grupos';


//MUTATIONS
import { addGrupo, updateGrupo, deleteGrupo } from '../../../mutations/Grupos';

//iconos
import SyncIcon from '@material-ui/icons/Sync';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { SiGroupon } from "react-icons/si";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

//HANDLERS
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar } from '../../../_layout/helpers/handles';

//COLORES
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import * as Permisos from '../Permisos';
const { trim } = Validaciones;

function Grupos() {
    const { token } = useSelector(state => state.login);
    const cleanState = {
        grupoID: null,
        grupo: ""

    };

    const cleanErrorState = {
        grupo: { error: false, helperText: "" }
    };

    const cleanNotificaciones = { mensajes: "", color: null, open: false };

    //seleccionables
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    let titleAccion = state.grupoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Grupo`;

    const grupoRef = useRef(null);

    let acciones = [
        {
            accionID: [1, 3],
            icono: Edit,
            color: info,
            descripcion: "Modificar",
            parametros: [{ campo: "grupoID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => handleModificar({
                parametros, token, setState, setOpen, setAlert,
                asyncGet: getGrupo
            })
        }, 
        {
            accionID: [4],
            id: "Eliminar",
            icono: Close,
            color: danger,
            descripcion: "Eliminar",
            parametros: [{ campo: "grupoID" },
            { campo: "grupo"}],
            disabled: { multiseleccion: true },
            onClick: ({ grupoID, grupo }) => handelAlertEliminar({
                setAlert,
                mensaje: `El grupo ${grupo}`,
                onCancel: () => setAlert(null),
                onConfirm: () => handleEliminar({
                    token, setAlert, setActualizar, actualizar,
                    parametros: { grupoID },
                    asyncDelete: deleteGrupo
                })
            })
        }
    ];

    let botones = [
        {
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    },
    {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }

    ];

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "grupoID",
        color: primary,
        title: "Grupos",
        iconTable: <SiGroupon />,
        headers: [
            { variable: "grupoID", descripcion: "ID", hide: true },
            { variable: "grupo", descripcion: "Grupo", hide: false }
        ],
        responsiveTitle: ["grupo"],
        filter: [
            { campo: "grupo" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }]
    }

    const inputs = [{
        disabled: state.fijo,
        id: "grupo",
        value: state.grupo,
        error: errorState.grupo.error,
        success: state.grupo && !errorState.grupo.error ? true :
            undefined,
        helperText: errorState.grupo.helperText,
        inputRef: grupoRef,
        title: "Grupo",
        placeholder: "Capture el grupo",
        onChange: ({ target: { value } }) => handleChange(value, "grupo"),
        onKeyDown: () => { return; }

    }

    ];

    let accionesFormulario = [{
        accionID: state.grupoID ? [3] : [2],
        loader,
        disabled: state.fijo,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: grupoRef,
            mensajeCorrecto: `El grupo se ${state.grupoID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar
        })
    },
    {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }
    ];

    //FUNCION QUE PERMITE MANEJAR EL CERRADO DEL FORMULARIO MODAL
    //AL FINALIZAR EL REGISTRO DE DATOS
    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    //FUNCION PARA MANEJAR LOS ERRORES AL INTRODUCIR TEXTO
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    //FUNCION PARA REALIZAR LAS VALIDACIONES
    async function handleValidaciones({ grupo }) {

        let error;
        let validaciones = [
            {
                condicion: !grupo || trim(grupo) === "",
                ref: grupoRef, keyError: "grupo",
                mensajeError: "Debe especificar el nombre del grupo"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    //FUNCION PARA GUARDAR LOS CAMPOS INDICADOS
    function ftGuardar() {
        async function ftGuardar() {

            let { grupoID,grupo } = state;

            let error = await handleValidaciones({ grupo });

            if (error) return error;

            if (grupoID) await updateGrupo({
                input: { grupoID, grupo: trim(grupo) }}, token);
            else await addGrupo({input: { grupo: trim(grupo) }}, token);

        }

        return ftGuardar();
    }

    //FUNCION PARA MANEJAR LOS CAMBIOS
    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    //ALERTA DE QUE FALTAN ALGUNOS ELEMENTOS AL MOMENTO DE INICIALIZAR LA PAGINA
    useEffect(() => {
        async function ftEffect() {
            try {
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    //INFORMACION A MOSTRAR
    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaGrupos}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotificaciones={() => setNotificaciones({
                ...cleanNotificaciones
            })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={grupoRef}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default Grupos;
