import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import { actualizaArchivo, eliminaArchivo, getArchivo, postArchivo, putArchivo } from "../../../helpers/serverStorage";

import { tablaServerStorage, getFile, upsertFile } from '../../../mutations/ServerStorage';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import fs from 'fs';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import CameraAlt from '@material-ui/icons/CameraAlt';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import Check from "@material-ui/icons/Check";

import moment from 'moment';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { getFotosEvidencia, tablaFotosEvidencia, getFoto, ObtenerFotosEvidencias } from '../../../querys/FotosEvidencia';
import { deleteFotosEvidencia, addFotosEvidencia, updateFotosEvidencia } from '../../../mutations/FotosEvidencia';


import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga } from '../../../_layout/helpers/handles';

import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import { PhotoSizeSelectActual, Photo } from '@material-ui/icons';
import * as Permisos from '../Permisos';

const { trim } = Validaciones;

function FotosEvidencia() {

    const { Encrypt, Decrypt } = Encriptar;
    const { token } = useSelector(state => state.login);
    let history = useHistory();

    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";
    let fileCopia = null;
    let remover = false;

    let origenDocID = null;
    let tablaOrigen = null;
    let folioOrigen = null;

    let { params } = useParams();
    if (params != undefined) {
        let url = params.replace(/&/g, "/");
        let seed = "segumex";
        let { origenDocID2, tablaOrigen2, folioOrigen2 } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        origenDocID = origenDocID2;
        tablaOrigen = tablaOrigen2;
        folioOrigen = folioOrigen2;
    }

    const cleanState = {
        fotosID: null,
        fechaAgregada: now.format(formatoDelmomento),
        descripcion: "",
        storageID: null,
        fotografia: "",
        foto: null,
        folioInterno: "",
        ruta: "",
        clasificacion: "",
        nombreArchivo: "",
        tipo: "",
        copia: ""
    };
    const cleanErrorState = {

        fechaAgregada: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        fotografia: { error: false, helperText: "" }

    }



    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const fotos = {
        foto: { valor: "", file: "", eliminar: false }
    }
    const fotosCopia = {
        foto: { valor: "Fotografia", file: fileCopia, eliminar: false }
    }
    const [stFotografias, setFotografias] = useState(fotos);

    const [stFotografiasCopia, setFotografiasCopia] = useState(fotosCopia);
    const [openVerFotos, setOpenVerFotos] = useState(false);
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);


    const [open, setOpen] = useState(false);
    const [openFotos, setOpenFotos] = useState(false);
    const [openFotosAdjuntas, setOpenFotosAdjuntas] = useState(false);
    const [stateFotosID, setStateFotosID] = useState(-1);
    const [actualizarFotos, setActualizarFotos] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);


    let titleAccion = state.fotosID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Folio de Evidencia`;

    const fechaAgregadaRef = useRef(null);
    const descripcionRef = useRef(null);
    const fotografiaRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar Descripcion",
        parametros: [{ campo: "fotosID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getFotosEvidencia, formatearResultado: [
                { id: "fechaAgregada", formato: (data) => moment(data) }
            ]
        })
    }, {
        accionID: [2],
        icono: PhotoCamera,
        color: black,
        descripcion: "Agregar Fotos",
        parametros: [{ campo: "fotosID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen: setOpenFotos, setAlert,
            asyncGet: getFotosEvidencia
        })
    },
    {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "fotosID" }],
        disabled: { multiseleccion: true },
        onClick: ({ fotosID }) => handelAlertEliminar({
            setAlert,
            mensaje: "las fotos",
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { fotosID }, asyncDelete: deleteFotosEvidencia
            })
        })
    },
    {
        icono: Photo,
        color: primary,
        descripcion: "Ver Fotos",
        parametros: [{ campo: "fotosID" }],
        disabled: { multiseleccion: false },
        onClick: ({ fotosID }) => {
            if (stateFotosID != fotosID && !openVerFotos) {
                setOpenVerFotos(!openVerFotos);
                setStateFotosID(fotosID);
                setActualizarFotos(!actualizarFotos)
            } else if (stateFotosID != fotosID && openVerFotos) {
                setStateFotosID(fotosID);
                setActualizarFotos(!actualizarFotos)
            }
            else if (stateFotosID === fotosID && openVerFotos) {
                setStateFotosID(fotosID);
                setOpenVerFotos(!openVerFotos);
            } else if (stateFotosID === fotosID && !openVerFotos) {
                setStateFotosID(fotosID);
                setOpenVerFotos(!openVerFotos);
            }
        },
        disabled: { multiseleccion: true }
    }, {
        icono: Check,
        color: success,
        id: "Seleccionar",
        descripcion: "Seleccionar Fotos",
        parametros: [{ campo: "fotosID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => {
            setOpenFotosAdjuntas(true);// openFotosAdjuntas
            setStateFotosID(parametros.fotosID);
        } /* handleModificar({
            parametros, token, setState, setOpen: setOpenFotosAdjuntas, setAlert,
            asyncGet: getFoto
        })
*/

    },
    ];

    const accionesFotos = [{
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "serverStorageID", variable: "serverStorageID" }, { campo: "ruta", variable: "ruta" }],
        disabled: { multiseleccion: true },
        onClick: ({ serverStorageID, ruta }) => handelAlertEliminar({
            setAlert,
            mensaje: "la foto.",
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar: setActualizarFotos, actualizar: actualizarFotos,
                parametros: { serverStorageID, ruta, token }, asyncDelete: eliminaArchivo

            })
        })
    }/*, {
        icono: Check,
        color: success,
        id: "Seleccionar",
        descripcion: "Seleccionar Fotos",
        parametros: [{ campo: "fotosID" }, { campo: "ruta" }, { campo: "documento" }, { campo: "nombreArchivo" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => {

            setFotografiasCopia(stFotografiasCopia => ({
                ...stFotografiasCopia,
                ruta: parametros.ruta, documento: parametros.documento, nombreArchivo: parametros.nombreArchivo
            }));
            handleModificar({
                parametros: parametros.fotosID, token, setState, setOpen: setOpenFotosAdjuntas, setAlert,
                asyncGet: getFoto
            })
        }
    },*/]

    let botones = [
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                history.goBack();
            }
        },
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const botonesFotos = [
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarFotos(!actualizarFotos),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "fotosID",
        color: primary,
        title: "Fotos de Evidencia",
        iconTable: <CameraAlt />,
        headers: [
            { variable: "fotosID", descripcion: "fotosID", hide: true },

            { variable: "folioInterno", descripcion: "Folio Interno" },
            { variable: "fechaAgregada", descripcion: "Fecha de Alta" },
            { variable: "descripcion", descripcion: "Descripcion" }
        ],
        responsiveTitle: ["folioInterno"],
        formato: [
            {
                columnas: ["fechaAgregada"], tipo: "fecha", onlyDate: true
            }
        ],
        filter: [
            { campo: "folioInterno", placeholder: "Buscar por Folio Interno" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        accionesEffect:
            [
                { botones: ["Adjuntar"], ocultar: () => origenDocID == null ? true : false }
            ]
    }


    const infoTablaFotos = {
        botones: botonesFotos,
        acciones: accionesFotos,
        loader,
        actualizar: actualizarFotos,
        id: "fotosID",
        color: primary,
        title: `Fotos`,
        iconTable: <PhotoSizeSelectActual />,
        headers: [
            { variable: "origenID", descripcion: "origenID", hide: true },
            { variable: "tabla", descripcion: "tabla", hide: true },
            { variable: "ruta", descripcion: "ruta", hide: true },
            { variable: "serverStorageID", descripcion: "serverStorageID", hide: true },
            { variable: "nombreArchivo", descripcion: "Nombre Archivo", hide: false },
            { variable: "documento", descripcion: "Fotos" }
        ],
        responsiveTitle: ["nombreArchivo"],
        formato: [{ columnas: ["documento"], tipo: "imagen", textoAlt: 'Foto Evidencia' }],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "right" }]
    }


    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: state.fotosID ? false : true,
                    id: "fechaAgregada",
                    value: state.fechaAgregada,
                    error: errorState.fechaAgregada.error,
                    success: state.fechaAgregada && !errorState.fechaAgregada.error ? true : undefined,
                    helperText: errorState.fechaAgregada.helperText,
                    inputRef: fechaAgregadaRef,
                    placeholder: "Fecha de Alta",
                    tipo: "datetimepicker",
                    grid: { md: 2, lg: 2 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaAgregada"),
                    onKeyDown: (evt) => handleFocus(evt, descripcionRef)
                },
                {
                    disabled,
                    id: "descripcion",
                    value: state.descripcion,
                    error: errorState.descripcion.error,
                    success: state.descripcion && !errorState.descripcion.error ? true : undefined,
                    helperText: errorState.descripcion.helperText,
                    inputRef: descripcionRef,
                    title: "Descripcion *",
                    placeholder: "Capture la Descripcion",
                    grid: { md: 10, lg: 10 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "descripcion")
                    },
                    onKeyDown: () => { return; }
                },

            ]
        }
    ];

    const inputsFotos2 = [

    ];

    const inputsFotos = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fotografia",
                    value: state.fotografia,
                    error: errorState.fotografia.error,
                    success: state.fotografia && !errorState.fotografia.error ? true : undefined,
                    helperText: errorState.fotografia.helperText,
                    removeImage: state.fotografia === "" ? true : false,
                    inputRef: fotografiaRef,
                    tipo: "imageupload",
                    grid: { md: 12, lg: 12 },
                    onChange: (file, base) => {
                        if (file === null) {
                            remover = true;
                        }
                        setState(state => ({
                            ...state,
                            'fotografia': base
                        }));
                        setFotografias({ valor: base, file: file, eliminar: remover });

                    }
                }
            ]
        }
    ];

    let accionesFormulario = [{
        accionID: state.fotosID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: descripcionRef,
            mensajeCorrecto: `La registro ${state.fotosID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const accionesFormularioFotos = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarFotos,
            actualizar: actualizarFotos,
            setNotificaciones,
            handleClose: handleCloseFotos,
            handleFocus,
            mensajeCorrecto: `La foto se agregó correctamente`,
            asyncCallback: ftGuardarFotos,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseFotos
    }];

    const accionesFormularioFotosAdjuntas = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarFotos,
            actualizar: actualizarFotos,
            setNotificaciones,
            handleClose: handleCloseFotosAdjuntas,
            handleFocus,
            mensajeCorrecto: `La foto se agregó correctamente`,
            asyncCallback: ftGuardarFotosAdjuntas,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseFotosAdjuntas
    }];



    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ descripcion }) {

        let error;
        let validaciones = [
            {
                condicion: !descripcion || trim(descripcion) === "",
                ref: descripcionRef, keyError: "descripcion",
                mensajeError: "Debe especificar la descripcion"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }


    function handleValidacionesFotos({ fotografia }) {

        let error;
        let validaciones = [
            {
                condicion: !fotografia || trim(fotografia) === "",
                ref: fotografiaRef, keyError: "fotografia",
                mensajeError: "Debe seleccionar una fotografia"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { fotosID,
                fechaAgregada,
                descripcion, } = state;

            let error = await handleValidaciones({
                descripcion
            });


            if (error) return error;

            let valor;
            if (fotosID) {
                valor = await updateFotosEvidencia({
                    input: {
                        fotosID,
                        fechaAgregada: fechaAgregada.format(formato),
                        descripcion: trim(descripcion)
                    }


                }, token);
            }
            else {
                valor = await addFotosEvidencia({
                    input: {
                        fechaAgregada: now.format(formato),
                        descripcion: trim(descripcion)
                    }
                }, token);
            }

        }
        return ftGuardar();
    }

    function ftGuardarFotos() {
        async function ftGuardarFotos() {

            let { fotosID,
                storageID,
                fotografia } = state;

            let error = await handleValidacionesFotos({
                fotografia
            });


            if (error) return error;


            let datos = { serverStorageID: storageID, origenID: Number(fotosID), tabla: "Fotos de Evidencia", tipo: "Fotos de Evidencia", clasificacion: "Foto Evidencia", nombreArchivo: stFotografias.eliminar ? "" : stFotografias.file.name };

            try {
                if (stFotografias.file || stFotografias.eliminar == true) {
                    let res = await actualizaArchivo(`/FotosEvidencia/${state.folioInterno}/`, stFotografias.file, 1, datos, token, stFotografias.eliminar);
                }
            } catch (error) {
                setNotificaciones({ mensaje: error, color: danger, open: true });
            }

        }
        return ftGuardarFotos();
    }


    function ftGuardarFotosAdjuntas() {
        async function ftGuardarFotosAdjuntas() {

            let aFotos = await ObtenerFotosEvidencias({filtro:{ fotosID:Number(stateFotosID)}} ,token );

            let error;
            let rutaDestino = `${tablaOrigen}/${folioOrigen}/Foto Evidencia`;


            for (let index = 0; index < aFotos.length; index++) {
                let  rutaFotoPerfil = aFotos[index].rutaFotoPerfil ;
                let  nombreFoto = aFotos[index].nombreFoto ;
                let  fotosID = aFotos[index].fotosID ;
                let ruta =  '/private/segumex' + rutaFotoPerfil; //
                let datos = { serverStorageID: null, origenID: Number(origenDocID), tabla: tablaOrigen, tipo: folioOrigen, clasificacion: "Foto Evidencia", nombreArchivo: nombreFoto, ruta: rutaFotoPerfil };

                    let documento = await getArchivo(ruta, token);

                    let archivo1 = convertBase64ToFile(documento);
                    archivo1 = blobToFile(archivo1, nombreFoto);

                    try {
                        if (documento) {

                            await actualizaArchivo(rutaDestino, archivo1, 1, datos, token, false);
                        }
                    } catch (error) {
                        setNotificaciones({ mensaje: error, color: danger, open: true });
                    }
            }
            history.goBack();
        }
        return ftGuardarFotosAdjuntas();
    }

    function ftGuardarFotosAdjuntas2() {
        async function ftGuardarFotosAdjuntas2() {
            let { storageID, fotografia, nombreArchivo } = state;

            let nombre = stFotografiasCopia.nombreArchivo;
            let datos = { serverStorageID: storageID, origenID: Number(origenDocID), tabla: tablaOrigen, tipo: folioOrigen, clasificacion: "Foto Evidencia", nombreArchivo: stFotografiasCopia.nombreArchivo }

            let rutaDestino = `${tablaOrigen}/${folioOrigen}/Foto Evidencia`;


            let documento = await getArchivo(stFotografiasCopia.ruta, token);

            let archivo1 = convertBase64ToFile(documento);
            archivo1 = blobToFile(archivo1, nombre);

            try {
                if (documento) {

                    await actualizaArchivo(rutaDestino, archivo1, 1, datos, token, false);
                }
            } catch (error) {
                setNotificaciones({ mensaje: error, color: danger, open: true });
            }
        }
        return ftGuardarFotosAdjuntas2();
    }

    const blobToFile = (blob, filename) => {
        let file = new File([blob], filename);
        return file;
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseFotos() {
        setOpenFotos(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setFotografias({ valor: "", file: "", remover: false });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });

    }

    function handleCloseFotosAdjuntas() {
        setOpenFotosAdjuntas(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setFotografias({ valor: "", file: "", remover: false });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });

    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const convertBase64ToFile = function (image) {
        const byteString = atob(image.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab)
            ;
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }
        const newBlob = new Blob([ab], {
            type: 'image/jpeg',
        });
        return newBlob;
    };

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
        accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    } else {
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaFotosEvidencia}
            token={token}
        />
        {openVerFotos && <Tabla
            infoTabla={infoTablaFotos}
            asyncData={tablaServerStorage}
            token={token}
            parametrosFijos={{ origenID: stateFotosID, tipo: "Fotos de Evidencia" }}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={descripcionRef}
        />
        <FormularioModal
            open={openFotos}
            title={"Fotos"}
            onClose={handleCloseFotos}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsFotos}
            acciones={accionesFormularioFotos}
        />
        <FormularioModal
            open={openFotosAdjuntas}
            title={"Fotos Adjuntas"}
            onClose={handleCloseFotosAdjuntas}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsFotos2}
            acciones={accionesFormularioFotosAdjuntas}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default FotosEvidencia;
