import React, { useState } from 'react';

import Inputs from '../../../Inputs/index';

function RowEditable({ id, valor, row, onEnter = (e) => console.log(e), onDobleClic, parametros }) {

    const [state, setState] = useState(valor);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState(null);

    function ftEnter() {
        async function ftEnter() {
            try {

                setError(null);
                setDisabled(true);

                let newParametros = await ftManejarParametros();
                let otros = ftOtros();

                await onEnter({ ...newParametros }, { ...otros });

            } catch({ message }) { setError(message) }
        } ftEnter();
    }

    function ftManejarParametros() {
        return new Promise(res => {

            let newParametros = {};

            if(Array.isArray(parametros) && parametros.length >0 ) parametros.forEach(key => {
                if(row[key] && row[key].value) newParametros = { ...newParametros, [key]: row[key].value }
            });

            res({ [id]: state, ...newParametros });

        });
    }

    function ftOtros() {
        return {
            old: row[id].value,
            cleanState: setState
        }
    }

    function ftDoubleClick() {
        async function ftDoubleClick() {
            try {

                if(onDobleClic) {
                    let newParametros = await ftManejarParametros();
                    let otros = ftOtros();
                    await onDobleClic({ ...newParametros }, { ...otros, disabled: !disabled });
                }
                setDisabled(disabled => !disabled)

            } catch({ message }) { setError(message) }
        } ftDoubleClick();
    }

    let i = {
        id,
        value: state,
        error: !!error,
        helperText: error ? error : "",
        inputProps: { onDoubleClick: ftDoubleClick },
        onChange: ({ target: { value }}) => !disabled && setState(value),
        onKeyDown: ({ key, keyCode }) => ((key === "Enter" || keyCode === 13) && !disabled ) && ftEnter()
     }

    return(<Inputs { ...i } />)

}

export default RowEditable;
