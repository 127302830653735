import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_ASEGURADORA = `
        query get($filtro: InputGetAseguradora){
            getAseguradora(filtro:$filtro){
                aseguradoraID
                aseguradora
            }
        }`;

    export const GET_ALL_ASEGURADORA = `
        query filtroGetAll($filtro: InputGetAseguradora){
            getAllAseguradora(filtro:$filtro){
                aseguradoraID
                aseguradora
            }
        }`;

    export const TABLA_ASEGURADORA = `
        query filtro($filtro: InputFiltroAseguradora){
            filterAseguradora(filtro:$filtro){
            listado{
                aseguradoraID
                aseguradora

            }
            registros
            paginas
            }
        }`;

export const getAseguradora = async (filtro, token) => {
    try {
        let { getAseguradora } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_ASEGURADORA
        });

        return getAseguradora;

    } catch({message}) { throw new Error(message); }
}

export const getAllAseguradora = async (variables, token) => {
    try {

        let { getAllAseguradora } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_ASEGURADORA
        });

        return getAllAseguradora;

    } catch({message}) { throw new Error(message); }
}

export const seleccionAseguradora = async (variables, token) => {
    try {

        let allAseguradora = await getAllAseguradora
        (variables, token);

        if(!Array.isArray(allAseguradora)) throw new Error("No se encontraron registros");

        return allAseguradora.map(({ aseguradoraID:value, aseguradora:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaAseguradora = async (variables, token) => {
    try{
        let {filterAseguradora} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_ASEGURADORA
        });
        return filterAseguradora;
    }catch({message}) { throw new Error(message); }
}
