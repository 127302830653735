import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor, strUrlSArchivos } from '../../../helpers';

export const getCodifDocumento = async (variables, token) => {
    let Query = `
    query getCodifDocumento($codifDocID: ID, $proceso: String, $descripcion: String){
        getCodifDocumento(codifDocID: $codifDocID, proceso: $proceso, descripcion: $descripcion){
          codifDocID
          proceso
          descripcion
        }
      }
    `;
    let resultado = await Ejecutar(variables, token, Query);

    return resultado;
}

export const tablaCodifDocumentos = async (variables, token) => {
    let Query = `
    query filterCodifDocumentos($count: Int, $pagina: Int!, $limite: Int!, $codfDocID: ID, $proceso: String, $descripcion: String){
        filterCodifDocumentos(count: $count, pagina: $pagina, limite:$limite,codifDocID: $codfDocID, proceso: $proceso, descripcion: $descripcion){
          listado{
            codifDocID
            proceso
            descripcion
          }
          registros
          paginas
        }
      }
    `;

    let resultado = await Ejecutar(variables.filtro, token, Query);

    return resultado;
}

export const updateCodifDocumento = async (variables, token) => {
    let Query = `
    mutation updateCodifDocumentos($codifDocID: ID, $proceso: String, $descripcion: String){
        updateCodifDocumentos(codifDocID: $codifDocID, proceso: $proceso, descripcion: $descripcion){
          codifDocID
          proceso
          descripcion
        }
      }
    `;

    let result = await Ejecutar(variables.input, token, Query);

    return result;
};

export const Ejecutar = async (variables, token, query) => {
    try {

        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });

        return Respuesta[Object.keys(Respuesta)[0]];

    } catch(error) { throw new Error(error.message); }
}