import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_AGENTES_SEGURO = `
        query get($filtro: InputGetAgentesSeguro){
            getAgentesSeguro(filtro:$filtro){
                agenteSegID
                agenteSeg
            }
        }`;

    export const GET_ALL_AGENTES_SEGURO = `
        query filtroGetAll($filtro: InputGetAgentesSeguro){
            getAllAgentesSeguro(filtro:$filtro){
                agenteSegID
                agenteSeg
            }
        }`;

    export const TABLA_AGENTES_SEGURO = `
        query filtro($filtro: InputFiltroAgentesSeguro){
            filterAgentesSeguro(filtro:$filtro){
            listado{
                agenteSegID
                agenteSeg

            }
            registros
            paginas
            }
        }`;

export const getAgentesSeguro = async (filtro, token) => {
    try {
        let { getAgentesSeguro } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_AGENTES_SEGURO
        });
        return getAgentesSeguro;

    } catch({message}) { throw new Error(message); }
}

export const getAllAgentesSeguro = async (variables, token) => {
    try {

        let { getAllAgentesSeguro } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_AGENTES_SEGURO
        });

        return getAllAgentesSeguro;

    } catch({message}) { throw new Error(message); }
}

export const seleccionAgentesSeguro = async (variables, token) => {
    try {

        let allAgentesSeguro = await getAllAgentesSeguro
        (variables, token);

        if(!Array.isArray(allAgentesSeguro)) throw new Error("No se encontraron registros");

        return allAgentesSeguro.map(({ agenteSegID:value, agenteSeg:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaAgentesSeguro = async (variables, token) => {
    try{
        let {filterAgentesSeguro} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_AGENTES_SEGURO
        });
        return filterAgentesSeguro;
    }catch({message}) { throw new Error(message); }
}
