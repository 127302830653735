import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_POLIZA_SEG_AUTO = `
mutation addPolizaSegAuto($input: InputAddPolizaSegAuto!) {
    addPolizaSegAuto(input: $input) {
        polizaSegAutosID
        partida
        empresaID
        usario
        aseguradoraID
        flotilla
        polizaInciso
        marca
        tipoModelo
        anio
        numSerie
        transmision
        fechaSolCot
        vigenciaIni
        vigenciaHasta
        valorFactura
        coberAdicionales
        sumaAsegurada
        primaNeta
        primaTotal
        renovacion
        horasInvertidas
    }
}
`;


export const UPDATE_POLIZA_SEG_AUTO =`
mutation updatePolizaSegAuto($input: InputUpdatePolizaSegAuto!) {
    updatePolizaSegAuto(input: $input) {
        polizaSegAutosID
        partida
        empresaID
        usario
        aseguradoraID
        flotilla
        polizaInciso
        marca
        tipoModelo
        anio
        numSerie
        transmision
        fechaSolCot
        vigenciaIni
        vigenciaHasta
        valorFactura
        coberAdicionales
        sumaAsegurada
        primaNeta
        primaTotal
        renovacion
        horasInvertidas
    }
}
`;

export const DELETE_POLIZA_SEG_AUTO = `
mutation deletePolizaSegAuto($input: InputDeletePolizaSegAuto) {
    deletePolizaSegAuto(input: $input)
}
`;


export const addPolizaSegAuto = async (variables, token) => {
    try {

        let { addPolizaSegAuto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_POLIZA_SEG_AUTO
        });

        return addPolizaSegAuto;

    } catch({message}) { throw new Error(message); }
};

export const updatePolizaSegAuto = async (variables, token) => {
    try {

        let { updatePolizaSegAuto } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_POLIZA_SEG_AUTO
        });

        return updatePolizaSegAuto;

    } catch({message}) { throw new Error(message); }
};


export const deletePolizaSegAuto = async (input, token) => {
    try {

        let { deletePolizaSegAuto } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_POLIZA_SEG_AUTO
        });

        return deletePolizaSegAuto;

    } catch({message}) { throw new Error(message); }
};
