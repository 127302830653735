import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_RECLATERCERO = `
mutation addSiniestrosReclaTercero($input: InputAddSiniestrosReclaTercero) {
    addSiniestrosReclaTercero(input: $input) {
        siniesReclTercerosID
        usuarioBitID
        numero
        empresaID
        fechaAfectado
        infoIdentifTercero
        asegDelTercero
        ajustadorRespresentante
        noSiniestro
        noPoliza
        bienAfectado
        descripEvento
        ubicacion
        estatus
        montoPagado
        fechaReclamacion
        fechaIndemnizacion
        atendio
        noDiasHabilesTrans
        horasInvertidas
        folioInterno
        folioInternoReporteDanios
        reporteDaniosID
    }
}
`;


export const UPDATE_RECLATERCERO =`
mutation updateSiniestrosReclaTercero($input: InputUpdateSiniestrosReclaTercero) {
    updateSiniestrosReclaTercero(input: $input) {
        siniesReclTercerosID
        usuarioBitID
        numero
        empresaID
        fechaAfectado
        infoIdentifTercero
        asegDelTercero
        ajustadorRespresentante
        noSiniestro
        noPoliza
        bienAfectado
        descripEvento
        ubicacion
        estatus
        montoPagado
        fechaReclamacion
        fechaIndemnizacion
        atendio
        noDiasHabilesTrans
        horasInvertidas
        folioInterno
        folioInternoReporteDanios
        reporteDaniosID
    }
}
`;

export const DELETE_RECLATERCERO = `
mutation deleteSiniestrosReclaTercero($input: InputDeleteSiniestrosReclaTercero) {
    deleteSiniestrosReclaTercero(input: $input)
}
`;


export const addSiniestrosReclaTercero = async (variables, token) => {

    try {

        let { addSiniestrosReclaTercero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_RECLATERCERO
        });

        return addSiniestrosReclaTercero;

    } catch({message}) { throw new Error(message); }
};

export const updateSiniestrosReclaTercero = async (variables, token) => {

    try {

        let { updateSiniestrosReclaTercero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_RECLATERCERO
        });

        return updateSiniestrosReclaTercero;

    } catch({message}) { throw new Error(message); }
};


export const deleteSiniestrosReclaTercero = async (input, token) => {
    try {

        let { deleteSiniestrosReclaTercero } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_RECLATERCERO
        });

        return deleteSiniestrosReclaTercero;

    } catch({message}) { throw new Error(message); }
};
