import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import { rgxdeInt2 } from '../../../helpers/regexp';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ReplayIcon from '@material-ui/icons/Replay';
import ErrorIcon from '@material-ui/icons/Error';
import { RiFileExcel2Fill } from "react-icons/ri";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import Policy from '@material-ui/icons/Policy';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { deletePolizasSeguro, addPolizasSeguro, updatePolizasSeguro, renovarPolizasSeguro,renovarConComplementaria } from '../../../mutations/PolizasSeguro';

import { getPolizasSeguro, getAllPolizasSeguro, tablaPolizasSeguro } from '../../../querys/PolizasSeguro';

import {getAllHistorialParidades} from '../../../querys/HistorialParidades';

import { getPolizasSeguroDetalle, tablaPolizasSeguroDetalle } from '../../../querys/PolizasSeguroDetalle';
import { deletePolizasSeguroDetalle, addPolizasSeguroDetalle, updatePolizasSeguroDetalle } from '../../../mutations/PolizasSeguroDetalle';


import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, warning, rose } from '../../../_layout/helpers/colores';

import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionAseguradora } from '../../../querys/Aseguradoras';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';

import { generarControlPagxPoliza } from '../../../mutations/ControlPago';

import TrazabilidadSolicitudesSeguro from '../TrazabilidadSolicitudesSeguro';

import { rgxdeInt11, rgxInputTexto30, rgxNumeros, rgxDecimal8, rgxDecimal14 } from '../../../helpers/regexp';

import moment from 'moment';

import { urlServidor } from '../../../helpers';
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';
import UpsertControlPago from '../ControlPago/Upsert';
import {getAllControlPagos } from '../../../querys/ControlPago';

export function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function PolizasSegFian() {
    let history = useHistory();
    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";
    let cTitulo2 = "ANEXOS: Recuerde adjuntar documento fuente.";
    let anioActual = new Date().getFullYear();
    let titleModalRenovar = `Renovar Póliza de Seguro `;
    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";
    const { usuario:{perfil},token } = useSelector(state => state.login);
    const tipoValorSumaAseg = [
        {
            label: 'VALOR CONVENIDO',
            value: 'VALOR CONVENIDO'
        },
        {
            label: 'VALOR COMERCIAL',
            value: 'VALOR COMERCIAL'
        },
        {
            label: 'VALOR DE REPOSICIÓN',
            value: 'VALOR DE REPOSICIÓN'
        },
        {
            label: 'AVALUO',
            value: 'AVALUO'
        },
        {
            label: 'VALOR FACTURA',
            value: 'VALOR FACTURA'
        },
    ]

    const cleanState = {
        polizasSeguroID: null,
        numero: "",
        fechaSolCot: "",
        empresaID: "",
        ramoSeguroID: "",
        aseguradoraID: "",
        numpolEndoso: "",
        divisaID: 69,
        sumaAseg: "",
        primaNeta: "",
        primaNetaTotal: "",
        fpPromedio: "",
        vigenciaIni: now.format(formatoDelmomento),
        vigenciaHasta: "",
        exclusiones: "",
        renovacion: true,
        horasInvertidas: "",
        tipoValorAseg: "VALOR CONVENIDO",
        beneficiarioPreferente: "",
        bienesAmparadosEncabezado: "",
        paridad: '',
        inciso: '',
        renovacionObs: "",
        aseguradoAdicional: ""
    };
    const cleanErrorState = {
        numero: { error: false, helperText: "" },
        fechaSolCot: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        numpolEndoso: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        sumaAseg: { error: false, helperText: "" },
        primaNeta: { error: false, helperText: "" },
        primaNetaTotal: { error: false, helperText: "" },
        fpPromedio: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        exclusiones: { error: false, helperText: "" },
        renovacion: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        tipoValorAseg: { error: false, helperText: "" },
        beneficiarioPreferente: {error: false, helperText: ""},
        bienesAmparadosEncabezado: {error: false, helperText: ""},
        paridad: {error: false, helperText: ""},
        inciso: { error: false, helperText: "" },
        renovacionObs: { error: false, helperText: "" },
        aseguradoAdicional: { error: false, helperText: "" }
    }

    const cleanStateDetalle = {
        polizaSeguroID: null,
        polSegDetalleID: null,
        deducible: "NA",
        cobertura: "",
        exclusiones: "NA",
        bienesAmparados: "NA",
        tipo: "Fianza",
        maximoMinimo: "NA"
    };
    const cleanErrorStateDetalle = {
        deducible: { error: false, helperText: "" },
        cobertura: { error: false, helperText: "" },
        exclusiones: { error: false, helperText: "" },
        bienesAmparados: { error: false, helperText: "" },
        tipo: { error: false, helperText: "" },
        maximoMinimo: { error: false, helperText: "" }

    }
    const cleanStateRenovar = {
        polizasSeguroID: null,
        empresaID: "",
        fechaSolCot: now.format(formatoDelmomento)

    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanNotificacionesDetalle = { mensaje: "", color: null, open: false };


    const cleanErrorStateRenovar = {
        fechaSolCot: { error: false, helperText: "" },
    }

    const [stateDetalle, setStateDetalle] = useState(cleanStateDetalle);
    const [errorStateDetalle, setErrorStateDetalle] = useState(cleanErrorStateDetalle);
    const [notificacionesDetelle, setNotificacionesDetalle] = useState(cleanNotificacionesDetalle);

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    
    const [datosDetalle, setDatosDetalle] = useState({});
    const [openTrazabilidad, setOpenTrazabilidad] = useState(false);

    let [datosPDF, setdatosPDf] = useState({});

    const [seleccionables, setSeleccionables] = useState(false);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [usarFechas, setUsarFechas] = useState(true);

    const [openDetalle, setOpenDetalle] = useState(false);
    const [actualizarDetalle, setActualizarDetalle] = useState(false);

    const [openVerDetalle, setOpenVerDetalle] = useState(false);
    const [statePolizaSeguroID, setStatePolizaSeguroID] = useState(-1);
    const [stNumPoliza, setNumPoliza] = useState('');

    const [openRenovar, setOpenRenovar] = useState(false);
    const [stateRenovar, setStateRenovar] = useState(cleanState);
    const [errorStateRenovar, setErrorStateRenovar] = useState(cleanErrorStateRenovar);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);
    
    const [statePromedio, setStatePromedio] = useState(1);

    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [filtrosTexto, setFiltrosTexto] = useState({});
    const [openControlPago,setOpenControlPago] = useState(false);

    const [totalPremiums, setTotalPremiums] = useState(0);

    let titleAccion = state.polizasSeguroID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Póliza`;

    let titleAccionDetalle = state.polSegDetalleID ? "Modificar" : "Agregar";
    let titleModalDetalle = `${titleAccionDetalle} Concepto de Póliza`;

    const numeroRef = useRef(null);
    const fechaSolCotRef = useRef(null);
    const empresaIDRef = useRef(null);
    const ramoSeguroIDRef = useRef(null);
    const paridadRef = useRef(null);
    const aseguradoraIDRef = useRef(null);
    const numpolEndosoRef = useRef(null);
    const divisaIDRef = useRef(null);
    const sumaAsegRef = useRef(null);
    const primaNetaRef = useRef(null);
    const primaNetaTotalRef = useRef(null);
    const fpPromedioRef = useRef(null);
    const vigenciaIniRef = useRef(null);
    const vigenciaHastaRef = useRef(null);
    const exclusionesRef = useRef(null);
    const horasInvertidasRef = useRef(null);
    const tipoValorAsegRef = useRef(null);
    const aseguradoAdicionalRef = useRef(null);

    const beneficiarioPreferenteRef = useRef(null);
    const bienesAmparadosEncabezadoRef = useRef(null);

    const DetDeducibleRef = useRef(null);
    const DetCoberturaRef = useRef(null);
    const DetExclusionesRef = useRef(null);
    const DetBienesAmparadosRef = useRef(null);
    const DetTipoRef = useRef(null);
    const DetMaximoMinimoRef = useRef(null);
    const renFechaSolCotRef = useRef(null);
    const incisoRef = useRef(null);
    const renovacionObsRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getPolizasSeguro, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) },
                { id: "vigenciaIni", formato: (data) => moment(data) },
                { id: "vigenciaHasta", formato: (data) => moment(data) }
            ]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "polizasSeguroID" },
        { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la poliza del numero ${numero}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { polizasSeguroID }, asyncDelete: deletePolizasSeguro
            })
        })
    },
    {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero)
        },
        {
            accionID: [6],
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
            disabled: { multiseleccion: true },
            onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero, 'excel')
        },
        {
        accionID: [8],
        icono: PlaylistAddCheckOutlinedIcon,
        color: primary,
        descripcion: "Ver Conceptos",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: false },

        onClick: ({ polizasSeguroID, folioInterno }) => {
            if (statePolizaSeguroID != polizasSeguroID && !openVerDetalle) {
                setOpenVerDetalle(!openVerDetalle);
                setStatePolizaSeguroID(polizasSeguroID);
                setActualizarDetalle(!actualizarDetalle);
                setNumPoliza(folioInterno);
            } else if (statePolizaSeguroID != polizasSeguroID && openVerDetalle) {
                setStatePolizaSeguroID(polizasSeguroID);
                setActualizarDetalle(!actualizarDetalle);
                setNumPoliza(folioInterno);
            }
            else if (statePolizaSeguroID === polizasSeguroID && openVerDetalle) {
                setStatePolizaSeguroID(polizasSeguroID);
                setOpenVerDetalle(!openVerDetalle);
                setNumPoliza(folioInterno);
            } else if (statePolizaSeguroID === polizasSeguroID && !openVerDetalle) {
                setStatePolizaSeguroID(polizasSeguroID);
                setOpenVerDetalle(!openVerDetalle);
                setNumPoliza(folioInterno);
            }

        },
        disabled: { multiseleccion: true }
    },
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
        disabled: { multiseleccion: true },
        onClick: ({ polizasSeguroID, numero }) => {
           // setRedireccionarFormulario({ polizasSeguroID, numero });
           setDatosDetalleAdjuntos({
            pURLDoctoOrigenID:polizasSeguroID ,
             pTipo:numero.toString() ,
             pClasificacion: "",
             pTabla: "Pólizas de Seguro",
             pTitulo2: cTitulo2,
             plUtilizaProps: true
         });
         setOpenDocumentacion(true);
        }
    },
    {
        accionID: [11],
        icono: ReplayIcon,
        color: info,
        descripcion: "Renovar Póliza",
        parametros: [{ campo: "polizasSeguroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateRenovar, setOpen: setOpenRenovar, setAlert,
            asyncGet: getPolizasSeguro, formatearResultado: [
                { id: "fechaSolCot", formato: (data) => moment(data) }
            ]
        })
    },
    {
        /* accionID: [8], */
        icono: AccountBalanceWalletIcon,
        color: success,
        descripcion: "Generar Control de pagos desde poliza",
        parametros: [{ campo: "polizasSeguroID" },{ campo: "empresaID" },{ campo: "aseguradoraID" },{ campo: "ramoSeguroID" },{ campo: "primaNetaTotal" }],
        disabled: { multiseleccion: true },
        onClick: ({polizasSeguroID,empresaID,aseguradoraID,ramoSeguroID,primaNetaTotal}) => {
            VerControldePagos({polizasSeguroID,empresaID,aseguradoraID,ramoSeguroID,primaNetaTotal});
            // setState({...state,polizasSeguroID,empresaID,aseguradoraID,ramoSeguroID});
    }
    },
    {   accionID: [12],
        id: "verTrazabilidad",
        icono: TrendingFlatIcon,
        color: rose,
        descripcion: "Ver Trazabilidad",
        parametros: [{ campo: "controlPagoID" },{ campo: "polizasSeguroID" } ],
        disabled: { multiseleccion: true },
        onClick: ({ controlPagoID, polizasSeguroID}) => {

           setDatosDetalle({

                pSolSegFianID: 0,
                pPolizasSeguroID: polizasSeguroID,
                pControlPagoID: controlPagoID,
                plUtilizaProps: true,
                pOrigen: "Poliza"
            });
            setOpenTrazabilidad(true);
        }
    },];

    async function VerControldePagos({polizasSeguroID,empresaID,aseguradoraID,ramoSeguroID,primaNetaTotal}){
        
        let listado = await getAllControlPagos({filtro:{ polizaID: Number(polizasSeguroID), polizaPago: 1}},token)
        let descripcionMensaje = [],sumaPrimaNetaTotal = 0;
        if (listado.length > 0) {
            setTotalPremiums(0);
           listado.map(pago => {
            descripcionMensaje.push(<><>Control pago: {pago.folioInterno}, ${pago.total}</> <br/></> );
            sumaPrimaNetaTotal += pago.total;
            setTotalPremiums(sumaPrimaNetaTotal);
           })
        }else{
            descripcionMensaje = '¿Desea agregar un control de pago?'
        }
        setAlert({
            descripcion: descripcionMensaje,
            title: "Control de Pagos",
            tipo: "warning",
            msjConfirmacion: "Agregar control de pago",
            onConfirm: () => {setAlert(null); setOpenControlPago(true); setState({...state,polizasSeguroID,empresaID,aseguradoraID,ramoSeguroID,primaNetaTotal:primaNetaTotal, sumaPrimaNetaTotal})},
            msjCancelacion: "Cancelar",
            onCancel: () => setAlert(null)
        });
            

}

    async function GenerarControlPag(parametros) {
        try {
            let result = await generarControlPagxPoliza({ polizasSeguroID: parametros.polizasSeguroID }, token);
            result = JSON.parse(result);
            
            setActualizar(!actualizar)

            setAlert({
                descripcion: result.mensaje,
                title: "Generar control de pago",
                tipo: result.Resultado === 1 ? "success-two" : warning,
                msjConfirmacion: "Abrir control de pago",
                onConfirm: () => RedirigirControlPago(result["controlPagoID"]),
                showConfirm: true,
                showCancel: true,
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    function RedirigirControlPago(controlPagoID) {
        setAlert(null);
        let path = '/';
        path = `/gen/ControlPagos`;
        history.push(path, { controlPagoID });
    }
    

    function AuxDescargar(polizasSeguroID, numero, formatoReporte = 'PDF') {

        setdatosPDf({ "polizasSeguroID": polizasSeguroID, "numero": numero, formatoReporte });
        datosPDF = { "polizasSeguroID": polizasSeguroID, "numero": numero, formatoReporte };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de poliza de fianzas?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de poliza de fianzas ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de poliza de fianzas ' + numero, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("polizasSeguroID", datosPDF.polizasSeguroID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReportePolizasSegFian' + datosPDF.numero
        await fetch(`${urlServidor}/service/reportes/ReportePolizasSegFian`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`);  // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }

    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setOpen(true); divisas()},
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        },{
            icono: ErrorIcon,
            color: success,
            descripcion: `${!usarFechas ? '' : "No"} Usar Filtro de Fechas`,
            onClick: () => {setActualizar(!actualizar); setUsarFechas(!usarFechas)},
            disabled: { multiseleccion: true }
        },
        {
            icono: MenuBookIcon,
            color: success,
            descripcion: "Polizas y endosos",
            onClick: () => {GenerarPolizasEndosos()},
            disabled: {multiseleccion: true}
        }
    ];

    const botonesDetalle = [
        {
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpenDetalle(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizarDetalle(!actualizarDetalle),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones: acciones,
        actualizar,
        id: "polizasSeguroID",
        color: primary,
        title: "Pólizas de Seguro de Fianzas",
        iconTable: <Policy />,
        headers: [
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "numero", descripcion: "Póliza" },  
            { variable: "numpolEndoso", descripcion: "Endoso" },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "nombre", descripcion: "Empresa" },
            { variable: "ramoSeguroID", descripcion: "ramoSeguroID", hide: true },
            { variable: "ramoSeguro", descripcion: "Ramo seguro" },
            { variable: "aseguradoraID", descripcion: "aseguradoraID", hide: true },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "sumaAseg", descripcion: "Suma Asegurada" },
            { variable: "primaNeta", descripcion: "Prima Neta" },
            { variable: "primaNetaTotal", descripcion: "Prima Total" },
            { variable: "fpPromedio", descripcion: "F.P. Promedio" },
            { variable: "exclusiones", descripcion: "Exclusiones", hide: true },
            { variable: "fechaSolCot", descripcion: "Fecha Sol. Cot." },
            { variable: "vigenciaIni", descripcion: "Vigencia Inicial" },
            { variable: "vigenciaHasta", descripcion: "Vigencia Hasta" },
            { variable: "renovacion", descripcion: "Renovar al Termino" },
            { variable: "folioInternoControlPago", descripcion: "Control de Pago" },
            { variable: "beneficiarioPreferente", descripcion: " Beneficiario Preferente", hide: false },
             { variable: "bienesAmparadosEncabezado", descripcion: "Bienes Amparados", hide: false },
             { variable: "polizaRenovada", descripcion: "Póliza Renovada", hide: false } ,
             { variable: "folioPolizaRenovada", descripcion: "Folio Póliza Renovada", hide: false },
        ],
        responsiveTitle: ["folioInterno", "numpolEndoso"],
        filter: [
            { campo: "numpolEndoso" },
            { campo: "numero" },
        ],
        alineacion: [{ columnas: [1, 2], alineacion: "left" }, { columnas: [0, 4, 6, 7, 8], alineacion: "right" }],
        formato: [

            {
                columnas: ["fechaSolCot", "vigenciaIni", "vigenciaHasta"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["sumaAseg", "primaNeta","primaNetaTotal"], tipo: "moneda",
            },
            {
                columnas: ["fpPromedio"], tipo: "porcentaje"

            },
            {
                columnas: ["renovacion", "polizaRenovada"], tipo: "estatus"

            }


        ], selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Empresa ? seleccionables.Empresa : [], grid: { md: 3, lg: 3 }
            },
            {
                campo: "aseguradoraID", title: "Aseguradora",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Aseguradora ? seleccionables.Aseguradora : [], grid: { md: 3, lg: 3 }
            }/* ,
            {
                campo: "ramoSeguroID", title: "Ramo Seguro",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.Ramo ? seleccionables.Ramo : [], grid: { md: 3, lg: 3 }
            } */],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },

    }

    const accionesDetalle = [{

        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "polSegDetalleID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState: setStateDetalle, setOpen: setOpenDetalle, setAlert,
            asyncGet: getPolizasSeguroDetalle
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "polSegDetalleID" }],
        disabled: { multiseleccion: true },
        onClick: ({ polSegDetalleID }) => handelAlertEliminar({
            setAlert,
            mensaje: ` la clausula de la poliza del numero ${polSegDetalleID}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizarDetalle, actualizarDetalle,
                parametros: { polSegDetalleID }, asyncDelete: deletePolizasSeguroDetalle
            })
        })
        },
        {
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "polizaSeguroID" }, { campo: "numero" }],
            disabled: { multiseleccion: true },
            onClick: ({ polizaSeguroID, numero }) => AuxDescargar(polizaSeguroID, numero, 'excelConceptos')
        }];

    const infoTablaDetalle = {
        botones: botonesDetalle,
        acciones: accionesDetalle,
        actualizar: actualizarDetalle,
        id: "polSegDetalleID",
        color: primary,
        title: "Conceptos de la Póliza",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "polSegDetalleID", descripcion: "polSegDetalleID", hide: true },
            { variable: "polizaSeguroID", descripcion: "polizaSeguroID", hide: true },
            { variable: "cobertura", descripcion: "Riesgos Cubiertos" },
            { variable: "deducible", descripcion: "% Deducible" },
            { variable: "maximoMinimo", descripcion: "Maximo y Minimo" },
            { variable: "exclusiones", descripcion: "Exclusiones" },
            { variable: "bienesAmparados", descripcion: "Bienes Amparados" },

            { variable: "tipo", descripcion: "tipo", hide: true },


        ],
        responsiveTitle: ["cobertura"],
        filter: [
            { campo: "cobertura" }
        ],
        alineacion: [{ columnas: [0, 1, 2, 3], alineacion: "left" }],

    }

    const inputs = [
        {
            id: "Arriba izquierdp",
            tipo: "fieldset",
            grid: { lg: 6 },
            inputs: [
                {
                    disabled,
                    id: "empresaID",
                    value: state.empresaID,
                    error: errorState.empresaID.error,
                    success: state.empresaID && !errorState.empresaID.error ? true : undefined,
                    helperText: errorState.empresaID.helperText,
                    inputRef: empresaIDRef,
                    title: "Empresa *",
                    placeholder: "Seleccione una Empresa",
                    tipo: "select",
                    data: seleccionables.Empresa,
                    grid: { md: 6, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, empresaID: value })
                    },
                },
                {
                    disabled,
                    id: "ramoSeguroID",
                    value: state.ramoSeguroID,
                    error: errorState.ramoSeguroID.error,
                    success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
                    helperText: errorState.ramoSeguroID.helperText,
                    inputRef: ramoSeguroIDRef,
                    title: "Ramo de Seguro *",
                    placeholder: "Seleccione una Ramo",
                    tipo: "select",
                    data: seleccionables.Ramo,
                    grid: { md: 6, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, ramoSeguroID: value })
                    },
                },
                {
                    disabled,
                    id: "aseguradoraID",
                    value: state.aseguradoraID,
                    error: errorState.aseguradoraID.error,
                    success: state.aseguradoraID && !errorState.aseguradoraID.error ? true : undefined,
                    helperText: errorState.aseguradoraID.helperText,
                    inputRef: aseguradoraIDRef,
                    title: "Aseguradora *",
                    placeholder: "Seleccione una Aseguradora",
                    tipo: "select",
                    data: seleccionables.Aseguradora,
                    grid: { md:6, lg:12 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, aseguradoraID: value })
                    }
                },
                {
                    disabled: perfil !=='ADMINISTRADOR',
                    id: "divisaID",
                    value: state.divisaID,
                    error: errorState.divisaID.error,
                    success: state.divisaID && !errorState.divisaID.error ? true : undefined,
                    helperText: errorState.divisaID.helperText,
                    inputRef: divisaIDRef,
                    title: "Divisa",
                    placeholder: "Seleccionar la Divisa",
                    grid: { md: 3, lg: 6 },
                    tipo: "select",
                    data: listDivisas,
                    onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
                    onKeyDown: (evt) => handleFocus(evt, numpolEndosoRef)
                },
                {
                    disabled: perfil !== 'ADMINISTRADOR' || state.divisaID ===69,
                    id: "paridad",
                    value: state.paridad,
                    error: errorState.paridad.error,
                    success: state.paridad && !errorState.paridad.error ? true : undefined,
                    helperText: errorState.paridad.helperText,
                    title: "Paridad Actual",
                    textAlign: "right",
                    placeholder: "Paridad Actual",
                    grid: { md: 3, lg: 6 },
                    onChange: ({ target: { value } }) => { handleChange(value, "paridad") }
                },
            ]
        },
        {
            id: "Arriba derecho",
            tipo: "fieldset",
            grid: { lg: 6 },
            inputs: [
                {
                    disabled,
                    id: "numero",
                    value: state.numero,
                    error: errorState.numero.error,
                    success: state.numero && !errorState.numero.error ? true : undefined,
                    helperText: errorState.numero.helperText,
                    inputRef: numeroRef,
                    title: "Póliza", 
                    placeholder: "Capture la póliza",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                            handleChange(value, "numero")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, numero: { error: state.numero.length > 11 && true, helperText: state.numero.length > 11 ? "Límite de 11 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, numpolEndosoRef)
                }, 
                {
                    disabled,
                    id: "numpolEndoso",
                    value: state.numpolEndoso,
                    error: errorState.numpolEndoso.error,
                    success: state.numpolEndoso && !errorState.numpolEndoso.error ? true : undefined,
                    helperText: errorState.numpolEndoso.helperText,
                    inputRef: numpolEndosoRef,
                    title: "Endoso", 
                    placeholder: "Capture el endoso",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxInputTexto30.test(value)) &&
                            handleChange(value.toUpperCase(), "numpolEndoso")
                    },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, numpolEndoso: { error: state.numpolEndoso.length > 30 && true, helperText: state.numpolEndoso.length > 30 ? "Límite de 30 caracteres excedido" : "" } })
                    },
                    onKeyDown: (evt) => handleFocus(evt, aseguradoraIDRef)
                },
                {
                    disabled,
                    id: "inciso",
                    value: state.inciso,
                    error: errorState.inciso.error,
                    success: state.inciso && !errorState.inciso.error ? true : undefined,
                    helperText: errorState.inciso.helperText,
                    inputRef: incisoRef,
                    title: "Inciso",
                    placeholder: "Capture el inciso",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => { handleChange(value, "inciso")
                    },
                },
                {
                    disabled,
                    id: "tipoValorAseg",
                    value: state.tipoValorAseg,
                    error: errorState.tipoValorAseg.error,
                    success: state.tipoValorAseg && !errorState.tipoValorAseg.error ? true : undefined,
                    helperText: errorState.tipoValorAseg.helperText,
                    inputRef: tipoValorAsegRef,
                    title: "Tipo Valor Suma Asegurada",
                    placeholder: "Capture el tipo de valor asegurado",
                    tipo: "select",
                    data: tipoValorSumaAseg,
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value, "tipoValorAseg")
                    }},
                {
                    disabled,
                    id: "sumaAseg",
                    value: state.sumaAseg,
                    error: errorState.sumaAseg.error,
                    success: state.sumaAseg && !errorState.sumaAseg.error ? true : undefined,
                    helperText: errorState.sumaAseg.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    inputRef: sumaAsegRef,
                    title: "Suma Asegurada *",
                    placeholder: "0.0",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal14.test(value)) &&
                        handleChange(value, "sumaAseg")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "primaNeta",
                    value: state.primaNeta,
                    error: errorState.primaNeta.error,
                    success: state.primaNeta && !errorState.primaNeta.error ? true : undefined,
                    helperText: errorState.primaNeta.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    inputRef: primaNetaRef,
                    title: "Prima Neta *",
                    placeholder: "0.0",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal14.test(value)) &&
                        handleChangePromedio(value, "primaNeta")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "primaNetaTotal",
                    value: state.primaNetaTotal,
                    error: errorState.primaNetaTotal.error,
                    success: state.primaNetaTotal && !errorState.primaNetaTotal.error ? true : undefined,
                    helperText: errorState.primaNetaTotal.helperText,
                    textAlign: "right",
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    inputRef: primaNetaTotalRef,
                    title: "Prima Total *",
                    placeholder: "0.0",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal14.test(value)) && handleChange(value, "primaNetaTotal")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "fpPromedio",
                    value: state.fpPromedio,
                    error: errorState.fpPromedio.error,
                    success: state.fpPromedio && !errorState.fpPromedio.error ? true : undefined,
                    helperText: errorState.fpPromedio.helperText,
                    textAlign: "right",
                    inputRef: fpPromedioRef,
                    title: "Factor Promedio *",
                    placeholder: "0.0",
                    grid: { md: 4, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        (rgxDecimal8.test(value)) &&
                        handleChange(value, "fpPromedio")
                    },
                    onKeyDown: () => { return; }
                },
            ]
        },
        {
            id: "beneficiario",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "Beneficiario",
                    value: state.beneficiarioPreferente,
                    error: errorState.beneficiarioPreferente.error,
                    success: state.beneficiarioPreferente && !errorState.beneficiarioPreferente.error ? true : undefined,
                    helperText: errorState.beneficiarioPreferente.helperText,
                    inputRef: beneficiarioPreferenteRef,
                    title: "Beneficiario Preferente",
                    placeholder: "Capture el nombre del beneficiario",
                    grid:{md:12, lg:12},
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, beneficiarioPreferente: value })
                    }
                    }
            ]},
        {
            id: "fechas",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaSolCot",
                    value: state.fechaSolCot,
                    error: errorState.fechaSolCot.error,
                    success: state.fechaSolCot && !errorState.fechaSolCot.error ? true : undefined,
                    helperText: errorState.fechaSolCot.helperText,
                    inputRef: fechaSolCotRef,
                    title: "Fecha Sol. Cotizacion.",
                    placeholder: "Fecha de Solicitud",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "fechaSolCot"),
                    onKeyDown: (evt) => handleFocus(evt, vigenciaIniRef)
                },
                {
                    disabled,
                    id: "vigenciaIni",
                    value: state.vigenciaIni,
                    error: errorState.vigenciaIni.error,
                    success: state.vigenciaIni && !errorState.vigenciaIni.error ? true : undefined,
                    helperText: errorState.vigenciaIni.helperText,
                    inputRef: vigenciaIniRef,
                    placeholder: "Fecha Vigencia de Inicio",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "vigenciaIni"),
                    onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                },
                {
                    disabled,
                    id: "vigenciaHasta",
                    value: state.vigenciaHasta,
                    error: errorState.vigenciaHasta.error,
                    success: state.vigenciaHasta && !errorState.vigenciaHasta.error ? true : undefined,
                    helperText: errorState.vigenciaHasta.helperText,
                    inputRef: vigenciaHastaRef,
                    title: "Vigencia hasta",
                    placeholder: "Fecha de Vencimiento",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onChange: (value) =>
                        handleChange(value, "vigenciaHasta"),
                    onKeyDown: (evt) => handleFocus(evt, vigenciaHastaRef)
                }, {
                    disabled,
                    id: "renovacion",
                    value: state.renovacion,
                    checked: state.renovacion,
                    title: "Renovar al Termino de Vigencia",
                    tipo: "switch",
                    grid: { md: 3, lg: 3 },
                    onChange: (e) => {
                        setState({ ...state, renovacion: e.target.checked,renovacionObs: "" })
                    }
                },
                {
                    hidden:  state.renovacion === true ? true : false,
                    id: "renovacionObs",
                    value: state.renovacionObs,
                    error: errorState.renovacionObs.error,
                    success: state.renovacionObs && !errorState.renovacionObs.error ? true : undefined,
                    helperText: errorState.renovacionObs.helperText,
                    inputRef: renovacionObsRef,
                    multiline: true,
                    title: state.renovacion === true ? "Observaciones" : "Observaciones (¿Por qué no se renovara?) *",
                    placeholder: "Capture la observación",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "renovacionObs")
                    }
                }]
        }, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "exclusiones",
                    value: state.exclusiones,
                    error: errorState.exclusiones.error,
                    success: state.exclusiones && !errorState.exclusiones.error ? true : undefined,
                    helperText: errorState.exclusiones.helperText,
                    inputRef: exclusionesRef,
                    multiline: true,
                    title: "Exclusiones *",
                    placeholder: "Capture las exclusiones",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "exclusiones")
                    }
                },
                {
                    disabled,
                    id: "bienesAmparadosEncabezado",
                    value: state.bienesAmparadosEncabezado,
                    error: errorState.bienesAmparadosEncabezado.error,
                    success: state.bienesAmparadosEncabezado && !errorState.bienesAmparadosEncabezado.error ? true : undefined,
                    helperText: errorState.bienesAmparadosEncabezado.helperText,
                    inputRef: bienesAmparadosEncabezadoRef,
                    multiline: true,
                    title: "Bienes Amparados",
                    placeholder: "Capture los bienesAmparados",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, bienesAmparadosEncabezado: value })
                    }
                },
                {
                    disabled,
                    id: "aseguradoAdicional",
                    value: state.aseguradoAdicional,
                    error: errorState.aseguradoAdicional.error,
                    success: state.aseguradoAdicional && !errorState.aseguradoAdicional.error ? true : undefined,
                    helperText: errorState.aseguradoAdicional.helperText,
                    inputRef: aseguradoAdicionalRef,
                    multiline: true,
                    title: "Asegurado Adicional",
                    placeholder: "Capturar asegurado adicional",
                    grid: { md: 12, lg: 12 },
                    onChange: ({ target: { value } }) => {
                        setState({ ...state, aseguradoAdicional: value })
                    }
                },
            ]
        }
    ];

    const inputsDetalle = [


        {
            disabled,
            id: "cobertura",
            multiline: true,
            value: stateDetalle.cobertura,
            error: errorStateDetalle.cobertura.error,
            success: stateDetalle.cobertura && !errorStateDetalle.cobertura.error ? true : undefined,
            helperText: errorStateDetalle.cobertura.helperText,
            inputRef: DetCoberturaRef,
            title: "Riesgos Cubiertos *",
            placeholder: "Capture los riesgos cubiertos",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "cobertura")
            }
        }
        , {
            disabled,
            id: "deducible",
            multiline: true,
            value: stateDetalle.deducible,
            error: errorStateDetalle.deducible.error,
            success: stateDetalle.deducible && !errorStateDetalle.deducible.error ? true : undefined,
            helperText: errorStateDetalle.deducible.helperText,
            inputRef: DetDeducibleRef,
            title: "Deducible *",
            placeholder: "Capture el Deducible",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "deducible")
            }
        }, {
            disabled,
            id: "maximoMinimo",
            multiline: true,
            value: stateDetalle.maximoMinimo,
            error: errorStateDetalle.maximoMinimo.error,
            success: stateDetalle.maximoMinimo && !errorStateDetalle.maximoMinimo.error ? true : undefined,
            helperText: errorStateDetalle.maximoMinimo.helperText,
            inputRef: DetMaximoMinimoRef,
            title: "Maximo y Minimo *",
            placeholder: "Capture el maximo y minimo",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "maximoMinimo")
            }
        }
        ,
        {
            disabled,
            id: "exclusiones",
            multiline: true,
            value: stateDetalle.exclusiones,
            error: errorStateDetalle.exclusiones.error,
            success: stateDetalle.exclusiones && !errorStateDetalle.exclusiones.error ? true : undefined,
            helperText: errorStateDetalle.exclusiones.helperText,
            inputRef: DetExclusionesRef,
            title: "Exclusiones *",
            placeholder: "Capture las exclusiones",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "exclusiones")
            }
        },
        {
            disabled,
            id: "bienesAmparados",
            multiline: true,
            value: stateDetalle.bienesAmparados,
            error: errorStateDetalle.bienesAmparados.error,
            success: stateDetalle.bienesAmparados && !errorStateDetalle.bienesAmparados.error ? true : undefined,
            helperText: errorStateDetalle.bienesAmparados.helperText,
            inputRef: DetBienesAmparadosRef,
            title: "Bienes Amparados *",
            placeholder: "Capture los Bienes Amparados",
            grid: { md: 12, lg: 12 },
            onChange: ({ target: { value } }) => {
                handleChangeDetalle(value.charAt(0).toUpperCase() + value.slice(1), "bienesAmparados")
            }
        }
    ];

    async function handleChangeDivisa (value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
                    
        
         setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
    }

    function handleChangePromedio(value, key) { 
        if (state.sumaAseg !== 0){
            if (parseInt(value) === 0){
            setState({ ...state, [key]: value }); 
            }else{

                let promedio = value/state.sumaAseg;
                let decimales = 8;

                    let signo = (promedio >= 0 ? 1 : -1);
                    promedio = promedio * signo;

                    promedio = promedio.toString().split('e');
                    promedio = Math.round(+(promedio[0] + 'e' + (promedio[1] ? (+promedio[1] + decimales) : decimales)));
                    promedio = promedio.toString().split('e');
                    promedio =  signo * (promedio[0] + 'e' + (promedio[1] ? (+promedio[1] - decimales) : -decimales));


                setStatePromedio(promedio)

                setState({ ...state, primaNeta: value })
            }
        }else{
            setState({ ...state, [key]: value });
        } 
    }



    let accionesFormulario = [{
        accionID: state.polizasSeguroID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: numpolEndosoRef,
            mensajeCorrecto: `La poliza se ${state.polizasSeguroID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const accionesFormularioDetalle = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar: setActualizarDetalle,
            actualizar: actualizarDetalle,
            notificacionesDetelle: setNotificaciones,
            handleClose: handleCloseDetalle,
            handleFocus,
            refFocus: DetDeducibleRef,
            mensajeCorrecto: `El concepto de la poliza se ${stateDetalle.polSegDetalleID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardarDetalle,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseDetalle
    }];


    const inputsRenovar = [

        {
            disabled,
            id: "fechaSolCot",
            value: stateRenovar.fechaSolCot,
            error: errorStateRenovar.fechaSolCot.error,
            success: stateRenovar.fechaSolCot && !errorStateRenovar.fechaSolCot.error ? true : undefined,
            helperText: errorStateRenovar.fechaSolCot.helperText,
            inputRef: renFechaSolCotRef,
            title: "Fecha Sol. Cotización.",
            placeholder: "Fecha de Solicitud",
            onlyDate: true,
            tipo: "datetimepicker",
            grid: { md: 4, lg: 4 },
            onChange: (value) =>{
                handleChangeRenovar(value, "fechaSolCot")
            },
            onKeyDown: (evt) => handleFocus(evt, vigenciaIniRef)
        }
    ];

    function ftRenovarPoliza() {
        async function ftRenovarPoliza() {

            let {
                polizasSeguroID,
                empresaID,
                fechaSolCot
            } = stateRenovar;


            let error = await handleValidacionesRenovar({
                polizasSeguroID,
                empresaID,
                fechaSolCot
            });

            if (error) return error;

            let resultado = await renovarPolizasSeguro({
                input: {
                    polizasSeguroID: Number(polizasSeguroID),
                    empresaID: Number(empresaID),
                    fechaSolCot: fechaSolCot.format(formato)

                }
            }, token);

        }
        return ftRenovarPoliza();
    }


    const accionesFormularioRenovar = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseRenovar,
            handleFocus,
            refFocus: renFechaSolCotRef,
            mensajeCorrecto: `La poliza se renovo correctamente`,
            asyncCallback: ftRenovarPoliza,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseRenovar
    }];


    function handleChangeRenovar(value, key) { setStateRenovar({ ...stateRenovar, [key]: value }); }

    function handleValidacionesRenovar({ polizasSeguroID, empresaID, fechaSolCot }) {
        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(polizasSeguroID),
                ref: polizasSeguroID, keyError: "polizasSeguroID",
                mensajeError: "No se logro obtener la poliza a renovar"
            },
            {
                condicion: !rgxNumeros.test(empresaID),
                ref: empresaID, keyError: "empresaID",
                mensajeError: "No se cuenta con una empresa"
            },
            {
                condicion: !moment(fechaSolCot).isValid(),
                ref: fechaSolCotRef, keyError: "fechaSolCot",
                mensajeError: "La fecha no tiene un formato válido"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }


    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftErrorInputTextDetalle({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState: cleanErrorStateDetalle, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorStateDetalle
        });
    }

    async function handleValidaciones({ empresaID, ramoSeguroID, aseguradoraID, fechaSolCot, sumaAseg, primaNeta, vigenciaIni,vigenciaHasta, exclusiones, renovacionObs,renovacion,numpolEndoso, paridad, primaNetaTotal }) {
        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(empresaID),
                ref: empresaID, keyError: "empresaID",
                mensajeError: "Seleccione una empresa"
            },
            {
                condicion: !rgxNumeros.test(ramoSeguroID),
                ref: ramoSeguroIDRef, keyError: "ramoSeguroID",
                mensajeError: "Seleccione un ramo de seguro"
            },
            {
                condicion: !rgxNumeros.test(aseguradoraID),
                ref: aseguradoraIDRef, keyError: "aseguradoraID",
                mensajeError: "Seleccione una aseguradora"
            },
            {
                condicion: !paridad || Number(paridad) <= 0 ,
                ref: paridadRef, keyError: "paridad",
                mensajeError: "Debe especificar la paridad"
            },
            {
                condicion: !numpolEndoso || numpolEndoso === "",
                ref: numpolEndosoRef, keyError: "numpolEndoso",
                mensajeError: "Debe especificar el numero de póliza"
            },
            {
                condicion: !sumaAseg || sumaAseg === "",
                ref: sumaAsegRef, keyError: "sumaAseg",
                mensajeError: "Debe especificar la suma asegurada"
            },
            {
                condicion: !primaNeta || primaNeta === "",
                ref: primaNetaRef, keyError: "primaNeta",
                mensajeError: "Debe especificar la prima neta"
            },
            {
                condicion: !primaNetaTotal || primaNetaTotal === "",
                ref: primaNetaTotalRef, keyError: "primaNetaTotal",
                mensajeError: "Debe especificar la prima total"
            },
            {
                condicion: !moment(fechaSolCot).isValid(),
                ref: fechaSolCotRef, keyError: "fechaSolCot",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: !moment(vigenciaHasta).isValid(),
                ref: vigenciaHastaRef, keyError: "vigenciaHasta",
                mensajeError: "La fecha no tiene un formato válido"
            },
            {
                condicion: moment(vigenciaHasta) < moment(vigenciaIni),
                keyError: "vigenciaHasta", ref: vigenciaHastaRef,
                mensajeError: "La fecha vigencia hasta no puede ser menor a la inicial"
            },
            {
                condicion: !exclusiones || trim(exclusiones) === "",
                ref: exclusionesRef, keyError: "exclusiones",
                mensajeError: "Debe especificar el deducible"
            },
            renovacion === false ? ({
                condicion: !renovacionObs || trim(renovacionObs) === "",
                ref: renovacionObsRef, keyError: "renovacionObs",
                mensajeError: "Debe especificar la observacion"
                }) : {}
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    async function handleValidacionesDetalle({ polizaSeguroID,
        deducible,
        cobertura,
        exclusiones,
        bienesAmparados,
        tipo, maximoMinimo }) {
        let error;
        let validaciones = [
            {
                condicion: !polizaSeguroID,
                ref: polizaSeguroID, keyError: "polizaSeguroID",
                mensajeError: "Seleccione una póliza"
            },


            {
                condicion: !cobertura || trim(cobertura) === "",
                ref: DetCoberturaRef, keyError: "cobertura",
                mensajeError: "Debe especificar la cobertura"
            },
            {
                condicion: !deducible || deducible === "",
                ref: DetDeducibleRef, keyError: "deducible",
                mensajeError: "Especifique el deducible"
            },
            {
                condicion: !maximoMinimo || maximoMinimo === "",
                ref: DetMaximoMinimoRef, keyError: "maximoMinimo",
                mensajeError: "Especifique el maximo y minimo"
            },
            {
                condicion: !exclusiones || exclusiones === "",
                ref: DetExclusionesRef, keyError: "exclusiones",
                mensajeError: "Debe especificar las exclusiones"
            },
            {
                condicion: !bienesAmparados || bienesAmparados === "",
                ref: DetBienesAmparadosRef, keyError: "bienesAmparados",
                mensajeError: "Debe especificar los bienes amparados"
            },
            {
                condicion: !tipo || tipo === "",
                ref: DetTipoRef, keyError: "tipo",
                mensajeError: "Debe especificar el tipo de póliza"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputTextDetalle({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function setRedireccionarFormulario({ polizasSeguroID, numero }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${polizasSeguroID}", "pTipo": "${numero}", "pTabla": "Pólizas de Fianza", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    function ftGuardar() {
        async function ftGuardar() {

            let {
                polizasSeguroID,
                numero,
                fechaSolCot,
                empresaID,
                ramoSeguroID,
                aseguradoraID,
                numpolEndoso,
                divisaID,
                sumaAseg,
                primaNeta,
                fpPromedio,
                vigenciaIni,
                vigenciaHasta,
                exclusiones,
                renovacion,
                horasInvertidas,
                tipoValorAseg,
                beneficiarioPreferente, 
                bienesAmparadosEncabezado,
                paridad,
                inciso,
                renovacionObs,
                aseguradoAdicional,
                primaNetaTotal
            } = state;

            let error = await handleValidaciones({
                numero,
                fechaSolCot,
                empresaID,
                ramoSeguroID,
                aseguradoraID,
                numpolEndoso,
                paridad,
                sumaAseg,
                primaNeta,
                fpPromedio,
                vigenciaIni,
                vigenciaHasta,
                exclusiones,
                horasInvertidas,
                renovacionObs,
                renovacion,
                numpolEndoso,
                primaNetaTotal
            });

            if (error) return error;

            if (polizasSeguroID) await updatePolizasSeguro({
                input: {
                    polizasSeguroID,
                    empresaID,
                    ramoSeguroID,
                    aseguradoraID,
                    divisaID,
                    numero,
                    numpolEndoso: trim(numpolEndoso),
                    sumaAseg: parseFloat(sumaAseg),
                    primaNeta: parseFloat(primaNeta),
                    fpPromedio: parseFloat(fpPromedio),
                    fechaSolCot: fechaSolCot.format(formato),
                    vigenciaIni: vigenciaIni.format(formato),
                    vigenciaHasta: vigenciaHasta.format(formato),
                    exclusiones: trim(exclusiones),
                    renovacion,
                    horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0, tipoPoliza: "PRINCIPAL", polizaOrigenID: null, tipoValorAseg, fianza: true,
                    beneficiarioPreferente,
                    bienesAmparadosEncabezado,
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                    inciso,
                    renovacionObs,
                    aseguradoAdicional,
                    primaNetaTotal: parseFloat(primaNetaTotal)
                }
            }, token);
            else await addPolizasSeguro({
                input: {
                    empresaID,
                    ramoSeguroID,
                    aseguradoraID,
                    divisaID,
                    numero,
                    numpolEndoso: trim(numpolEndoso),
                    sumaAseg: parseFloat(sumaAseg),
                    primaNeta: parseFloat(primaNeta),
                    fpPromedio: parseFloat(fpPromedio),
                    fechaSolCot: fechaSolCot.format(formato),
                    vigenciaIni: now.format(formato),
                    vigenciaHasta: vigenciaHasta.format(formato),
                    exclusiones: trim(exclusiones),
                    renovacion,
                    horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0, tipoPoliza: "PRINCIPAL", polizaOrigenID: null, tipoValorAseg, fianza: true,
                    beneficiarioPreferente,
                    bienesAmparadosEncabezado,
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad),
                    inciso,
                    renovacionObs,
                    aseguradoAdicional,
                    primaNetaTotal: parseFloat(primaNetaTotal)
                }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseDetalle() {
        setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificacionesDetalle({ ...cleanNotificacionesDetalle });
        setStateDetalle({ ...cleanStateDetalle });
        setErrorStateDetalle({ ...cleanErrorStateDetalle });
    }

    function handleCloseRenovar() {
        setOpenRenovar(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateRenovar({ ...cleanStateRenovar });
        setErrorStateRenovar({ ...cleanErrorStateRenovar });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleChangeDetalle(value, key) { setStateDetalle({ ...stateDetalle, [key]: value }); }

    function ftGuardarDetalle() {
        async function ftGuardarDetalle() {
            let {

                polSegDetalleID,
                deducible,
                cobertura,
                exclusiones,
                bienesAmparados,
                tipo, maximoMinimo
            } = stateDetalle;
            let polizaSeguroID = statePolizaSeguroID;
            let error = await handleValidacionesDetalle({
                polizaSeguroID,
                deducible,
                cobertura,
                exclusiones,
                bienesAmparados,
                tipo, maximoMinimo
            });

            if (error) return error;

            if (polSegDetalleID) await updatePolizasSeguroDetalle({
                input: {
                    polSegDetalleID,
                    polizaSeguroID,
                    deducible,
                    cobertura,
                    exclusiones,
                    bienesAmparados,
                    tipo, maximoMinimo
                }
            }, token);
            else await addPolizasSeguroDetalle({
                input: {
                    polSegDetalleID,
                    polizaSeguroID,
                    deducible,
                    cobertura,
                    exclusiones,
                    bienesAmparados,
                    tipo, maximoMinimo
                }
            }, token);

        }
        return ftGuardarDetalle();
    }

    async function divisas(){
        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);
                
        setState({ ...state, ["paridad"]: historialParidades[0].paridad });
    }

    useEffect(() => {
        async function ftEffect() {
            try {

            if(statePromedio === Infinity){

                setState({ ...state, fpPromedio: 0});
            } else if(isNaN(statePromedio) === true){
 
                setState({ ...state, fpPromedio: 0});
            } else {

                setState({ ...state, fpPromedio: statePromedio});
            }               

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [statePromedio]);


    useEffect(() => {
        async function ftEffect() {
            try {
                let Empresa = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                let Aseguradora = await seleccionAseguradora({ filtro: {} }, token);
                let Ramo = await seleccionRamoSeguro({ filtro: {} }, token);



                setSeleccionables({ Empresa, Aseguradora, Ramo });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    function handleCloseTablaConceptos() {
        setOpenVerDetalle(false);
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
	    accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    }else{
        Permisos.PermisosAState(setAccionesUsr, token);
    }


    async function GenerarPolizasEndosos(){
            


        let filtros = {};
        Object.keys(filtrosAplicados).forEach((key, index) =>{
            let aux = Object.values(filtrosAplicados);
            filtros[key] = aux[index] == 'null' ? null : aux[index];
        })
        filtros.tipoPoliza = 'PRINCIPAL';
        filtros.fianza = true;

        if(usarFechas){
        filtros.fechaIni/* cio */ = moment(filtros.fechaIni).format('YYYY-MM-DD').concat('T00:00:00');
        filtros.fechaFin/* Reporte */ = moment(filtros.fechaFin).format('YYYY-MM-DD').concat('T23:59:59');
        filtros.usarFechas = true;
        /* delete filtros.fechaIni;
        delete filtros.fechaFin; */
    }
        else{
            filtros.fechaIni/* al */ = null;
            filtros.fechaFin/* Reporte */ = null;
            filtros.usarFechas = false;
            /* delete filtros.fechaIni;
            delete filtros.fechaFin; */
        }

        /* let encabezadosPolizas = await getAllPolizasSeguro({filtro: filtros}, token); */

        filtros.pagina = 1;
        filtros.limite = 100000;
        let encabezadosPolizas = await tablaPolizasSeguro({filtro: {...filtros, ...filtrosTexto}}, token);


        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Desea generar el reporte de pólizas?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {encabezadosPolizas}, asyncMutation: () => {GenerarPDFPolizasEndosos(encabezadosPolizas)},
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el reporte de pólizas ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: '', msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
                    
    }

    async function GenerarPDFPolizasEndosos(data){


        let dataCompleta = [];

        for(let i = 0; i < data.listado.length; i ++){

            let endosos = await getAllPolizasSeguro({filtro: {polizaOrigenID: data.listado[i].polizasSeguroID}}, token)
            dataCompleta.push({
                polizaPrincipal : data.listado[i],
                endosos
            })

        }

        let myHeaders = new Headers();
    myHeaders.append("token", token);
    myHeaders.append("mimeType", "multipart/form-data");

    let formdata = new FormData();
    formdata.append('data', JSON.stringify(dataCompleta));

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    };

    await fetch(`${urlServidor}/service/reportes/ReportePolizasEndosos`, requestOptions)
        .then(response => {
            if (!response.ok) return response.json();
            return response.blob()
        })
        .then(result => {
            let { error, mensaje } = result;
            if (error) throw new Error(mensaje);
            showFile(result, `Polizas y endosos ${moment().format('l')}.xlsx`);
            setAlert(null)
        })
        .catch(({ message }) => { throw new Error(message) });
    }







    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaPolizasSeguro}
            token={token}
            parametrosFijos={{ tipoPoliza: 'PRINCIPAL', fianza: true, usarFechas }}
            usarFiltroFechas={usarFechas}
            setFiltrosAplicados = {setFiltrosAplicados}
            setFiltrosTexto = {setFiltrosTexto}
            requiereFiltros = {true}
        />

        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={numpolEndosoRef}
        />
        {openVerDetalle &&
            <Modal
                open={openVerDetalle}
                title={`Conceptos de Póliza ${stNumPoliza}`}
                onClose={handleCloseTablaConceptos}
            >
                <Tabla
                    infoTabla={infoTablaDetalle}
                    asyncData={tablaPolizasSeguroDetalle}
                    token={token}
                    parametrosFijos={{ polizaSeguroID: statePolizaSeguroID, tipo: 'Fianza' }}

                />
                <FormularioModal
                    open={openDetalle}
                    title={` ${titleModalDetalle} ${stNumPoliza}`}
                    onClose={handleCloseDetalle}
                    notificaciones={notificaciones}
                    closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
                    inputs={inputsDetalle}
                    acciones={accionesFormularioDetalle}
                    focus={DetDeducibleRef}
                />
            </Modal>
        }
        {openRenovar && <FormularioModal
            open={openRenovar}
            title={titleModalRenovar}
            onClose={handleCloseRenovar}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsRenovar}
            acciones={accionesFormularioRenovar}
            focus={renFechaSolCotRef}
        />

        }
        {
             openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos}   setOpenDocumentacion = { () => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {
            openControlPago && <Modal maxWidth={true}
                open={openControlPago}
                onClose={() => setOpenControlPago(false)}
            >
                <UpsertControlPago
                    data={{ 
                        polizaSeguroID: state.polizasSeguroID,
                        empresaID: state.empresaID, 
                        ramoSeguroID: state.ramoSeguroID, 
                        aseguradoraID: state.aseguradoraID,
                        primaNetaTotal:state.primaNetaTotal, 
                        sumaPrimaNetaTotal: state.sumaPrimaNetaTotal,
                        totalPremiums: totalPremiums
                    }}
                    propsAlert={alert}
                    propsSetAlert={setAlert}
                    cerrar={() => setOpenControlPago(false)}
                />
            </Modal>
        }
        {
            openTrazabilidad && <Modal maxWidth={true} onClose={() => {setOpenTrazabilidad(false)}}>

               <TrazabilidadSolicitudesSeguro datosDetalle={datosDetalle}   setOpenTrazabilidad = { () => setOpenTrazabilidad(false)
               } />

           </Modal>
       }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default PolizasSegFian;
