import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_SEGURO_VIAJERO = `
        query get($filtro: InputGetSolSegFianSegViajero){
            getSolSegFianSegViajero(filtro:$filtro){
                segViajeroID
                solSegFianID
                paterno
                materno
                nombres
                fechaNacimiento
                edad
                rfc
                destino
                periodo
                divisaID
                paridad
            }
        }`;

    export const GET_ALL_SEGURO_VIAJERO = `
        query filtroGetAll($filtro: InputGetSolSegFianSegViajero){
            getAllSolSegFianSegViajero(filtro:$filtro){
              segViajeroID
              solSegFianID
              paterno
              materno
              nombres
              fechaNacimiento
              edad
              rfc
              destino
              periodo
              divisaID
              paridad
            }
        }`;

    export const TABLA_SEGURO_VIAJERO = `
        query filtro($filtro: InputFiltroSolSegFianSegViajero){
            filterSolSegFianSegViajero(filtro:$filtro){
            listado{
                segViajeroID
                solSegFianID
                paterno
                materno
                nombres
                fechaNacimiento
                edad
                rfc
                destino
                periodo

            }
            registros
            paginas
            }
        }`;

export const getSegViajero = async (filtro, token) => {
    try {
        let { getSolSegFianSegViajero } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_SEGURO_VIAJERO
        });

        return getSolSegFianSegViajero;

    } catch({message}) { throw new Error(message); }
}

export const getAllSegViajero = async (variables, token) => {
    try {

        let { getAllSolSegFianSegViajero } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SEGURO_VIAJERO
        });

        return getAllSolSegFianSegViajero;

    } catch({message}) { throw new Error(message); }
}

export const seleccionSegViajero = async (variables, token) => {
    try {

        let getAllSegViajero = await getAllSegViajero
        (variables, token);

        if(!Array.isArray(getAllSegViajero)) throw new Error("No se encontraron registros");

        return getAllSegViajero.map(({ segViajeroID:value, nombres:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaSegViajero = async (variable, token) => {
    try{
        let { segViajeroID, solSegFianID, paterno, materno, nombres, fechaNacimiento, edad, rfc, destino, periodo,pagina, limite} = variable.filtro;
        let filtro = {segViajeroID : segViajeroID === null ? null : Number(segViajeroID), solSegFianID,paterno, materno, nombres, fechaNacimiento, edad, rfc, destino, periodo, pagina, limite };
        let variables={filtro};
        let {filterSolSegFianSegViajero} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SEGURO_VIAJERO
        });
        return filterSolSegFianSegViajero;
    }catch({message}) { throw new Error(message); }
}
