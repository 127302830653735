import React, { useState } from 'react';
import { getFile } from '../../../mutations/ServerStorage'
import { getArchivo } from "../../../helpers/serverStorage";
import { showFile } from '../../../helpers';

function SeccionXI({ setRespuesta, disabled, respuesta, ejercicio, setAdjunto, seccionID }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      A: { respuesta: ''},
      B: { respuesta: 0, explicacion: '' },
      Archivo: { archivo: ''}
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);
  

  function handleChangeValue(ejercicio, columna, value) {
    
    let respuestaAux = { ...stRespuestasLocal };

    respuestaAux[ejercicio][columna] = value;

    if (respuestaAux[ejercicio]["respuesta"] === 0) {
      respuestaAux[ejercicio]["explicacion"] = '';
    }
    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));

  }


  function handleAdjunto(ejercicio, columna, value) {
    
    let respuestaAux = { ...stRespuestasLocal };
    let { files } = value.target;
    let archivos = Array.from(files);

    respuestaAux[ejercicio][columna] = files[0].name;

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
    setAdjunto(archivos);

  }

  
  const descargar = async function (seccionID) { //get storagen

    let archivo = await getFile({ origenID: seccionID, tipo: 'ADJUNTO', clasificacion: 'ADJUNTOSENCUESTA' });
    let documento = await getArchivo(archivo.ruta);

    let inicio = documento.length;
    let cadena = documento.indexOf(',')
    let b64 = documento.substring(cadena + 1, inicio);

    let iniciofor = documento.indexOf(':');
    let finfor = documento.indexOf(';');

    let ext = documento.substring(iniciofor + 1, finfor);

    showFile(b64, archivo.nombreArchivo, ext)

};


  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          XI. Responsabilidad Contractual
        </div>
        <br />

        <div className="ElementoPregunta">
          <div className="Pregunta">
            A) Adjunte copia de los contratos/condiciones generales que el asegurado tenga con sus clientes. En caso de tener contratos que no se ajusten a las condiciones generales, por favor adjuntar copia de los mismos.
          </div>
          <br />
          <div className="RespuestasInput">
          <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
          { (respuesta.Archivo.archivo === '') ?
            <>
            <input type = "file" name='archivo' accept=".doc, .xlsm, .xls, .xml, .pptx, .pdf, .jpg, .png, .jpeg" multiple={true} onChange={event => handleAdjunto("Archivo", "archivo", event)}/>
            </>
            :
            <>
            <input type = "button" value="Descargar Adjunto" onClick={() => descargar(seccionID)}/>
            </>
          }
            </div>
          </div>
          </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            B) ¿Existe alguna limitación legal o estatutaria que el asegurado pueda aplicar respecto a su negligencia para daños o lesiones?
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("B", "respuesta", (stRespuestasLocal["B"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("B", "respuesta", (stRespuestasLocal["B"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["B"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor indique cual y con qué frecuencia la solicita:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["B"].explicacion} onChange={event => handleChangeValue("B", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
          </div>
      </div>
    </div>
  </>);
}

export default SeccionXI;