import React from 'react';

import ImageUpload from "../../../components/CustomUpload/ImageUpload.js";

export default function Imagen({ title = "", onChange = (evt) => console.log(evt), avatar, removeImage, value, disabled, error, success, helperText, id }) {

    let newProps = {};
    if(avatar) newProps = { avatar: true };

    return (<div style={{ textAlign: "center" }}>
        <legend>{title}</legend>
        <ImageUpload
            { ...newProps }
            id={id}
            removeImage={removeImage}
            onChange={onChange}
            value={value}
            disabled={disabled}
            addButtonProps={{
                color: "rose",
                round: true
            }}
            changeButtonProps={{
                color: "rose",
                round: true
            }}
            removeButtonProps={{
                color: "danger",
                round: true
            }}
            error={error}
            success={success}
            helperText={helperText}
        />
    </div>);

}
