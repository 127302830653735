
// Amabilidad
const MensajeAmable = "eres tan amable de comunicarnos este detalle; estaremos muy agradecidos.";
const MensajeAmableMay = `Si ${MensajeAmable}`;
// Mensajes
// Conexión [CX]
const MensajeErrorNetwork = `[CX] Ocurrio un problema al conectarse con el servidor.`;
const MensajeFetch = `[CX] Algo impide que logremos realizar el proceso de forma correcta. ${MensajeAmableMay}`;
// GraphQL [GQ]
const MensajeGraphQLArguments = `[GQ] Lamentablemente la solicitud que intentas realizar esta recibiendo argumentos que no se han definido. ${MensajeAmableMay}`;
const MensajeGraphQLQuery = `[GQ] Lamentablemente el método que se intenta utilizar no existe en nuestros servidores. ${MensajeAmableMay}`;
const MensajeNoEntero = `[GQ] Por alguna razón un valor numérico esta recibiendo un tipo de dato no numérico. ${MensajeAmableMay}`;
// Base de datos [DB]
const MensajeIncorrectArgumentsSP = `[DB] Lamentablemente falta sincronizar la base de datos. ${MensajeAmableMay}`;
const MensajeFechaInvalida = `[DB] Lamentablemente tenemos un problema con la fecha capturada; no tiene un formato válido. ${MensajeAmableMay}`;
const MensajeDataLong = `[DB] Uno de los datos que estamos recibiendo sobrepasa el permitido. ${MensajeAmableMay}`;
const MensajeConexion = `[DB] Lamentablemente la conexión ha tardado más de la cuenta, si este problema persiste y ${MensajeAmable}`;
// Método
export function NetworkError(mensaje) {
    // if(mensaje) console.error(mensaje);
    if(/^NetworkError/g.test(mensaje)) return MensajeErrorNetwork;
    if(/Incorrect number of arguments for PROCEDURE/g.test(mensaje)) return MensajeIncorrectArgumentsSP;
    if(/^Failed to fetch/g.test(mensaje)) return MensajeFetch;
    if(/is not defined by type/g.test(mensaje)) return MensajeGraphQLArguments;
    if(/^Cannot query field/g.test(mensaje)) return MensajeGraphQLQuery;
    if(/Incorrect date value/g.test(mensaje)) return MensajeFechaInvalida;
    if(/Int cannot represent non-integer value/g.test(mensaje)) return MensajeNoEntero;
    if(/Data too long for column/g.test(mensaje)) return MensajeDataLong;
    if(/Connection timeout/g.test(mensaje)) return MensajeConexion;

    if (mensaje){
        if (mensaje.includes("El token no es válido")) {
            window.location.reload();
        }
    }

    return mensaje;
}
