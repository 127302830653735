import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const GET_ACTIVO = `
query get($filtro: InputGetActivo)
{
    getActivo(filtro : $filtro)
  {
    activoID
    usuarioBitID
    activo
  }
}`;

export const GET_ALL_ACTIVOS = `
query getAll($filtro: InputGetActivo)
{
    getAllActivos(filtro : $filtro)
  {
    activoID
    usuarioBitID
    activo
  }
}`;

export const TABLA_ACTIVOS = `
query filter($filtro: InputFiltroActivo)
{
    filterActivos(filtro : $filtro)
  {
    listado{
        activoID
        usuarioBitID
        activo
    }
    paginas
    registros
  }
}`;

export const getActivo = async (filtro, token) => {
    try {
        let { getActivo } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_ACTIVO
        });

        return getActivo;

    } catch({message}) { throw new Error(message); }
}

export const getAllActivos = async (variables, token) => {
    try {

        let { getAllActivos } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_ACTIVOS
        });

        return getAllActivos;

    } catch({message}) { throw new Error(message); }
}

export const seleccionActivos = async (variables, token) => {
  try{
      let allActivos = await getAllActivos(variables, token);
      if(!Array.isArray(allActivos)) throw new Error ("No se encontraron registros");

      return allActivos.map(({
          activoID: value, activo: label
      }) => ({value, label: label.toUpperCase() }));
  }catch({message: label}){
      return [{
          label,
          value: null,
          disabled: true
      }];
  }
}

export const tablaActivos = async (variables, token) => {
    try{
        let {filterActivos} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_ACTIVOS
        });
        return filterActivos;
    }catch({message}) { throw new Error(message); }
}
