import React, { useState, useRef, useEffect } from 'react';
import { Validaciones } from '@renedelangel/helpers';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { RiFileExcel2Fill } from "react-icons/ri";
//import { RiNodeTree } from 'react-icons/RiNodeTree';
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { urlServidor } from '../../../helpers';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import moment from 'moment';
import { rgxNumeros } from 'helpers/regexp';
import { rgxNumeroDecimal4 } from 'helpers/regexp';
import { useLocation } from 'react-router-dom';
import { seleccionRamoSeguro } from 'querys/RamoSeguro';
import { seleccionPolizasSeguro } from '../../../querys/PolizasSeguro';
import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { seleccionDivisa } from 'querys/Divisa';
import * as Permisos from '../Permisos';

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }
const { trim } = Validaciones;

function PresupuestoAnual() {

    const { token, usuario } = useSelector(stDatos => stDatos.login);
    let anioActual = new Date().getFullYear();

    let now = moment();
    let formato = "YYYY-MM-DD";
    var Empresas = [];
    let remover = false;
    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [stEmpresas, setEmpresas] = useState(Empresas)
    // Seleccionables
    let [datosPDF, setdatosPDf] = useState({});
    let [datosAutorizar, setDatosAutorizar] = useState({});
    const [seleccionables, setSeleccionables] = useState({});
    const [stTituloTipoDanio, setTituloTipoDanio] = useState("");
    let [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [fotoPerfilUsuario, setFotoPerfil] = useState({ valor: "", file: "", eliminar: remover });
    const location = useLocation();
    const [ejercicios, setEjercicios] = useState([]);
    const [stateFiltros , setStateFiltros] = useState({
        empresaID: 1,
        ejercicio: anioActual
    })
    const [stFolios, setFolios] = useState([]);

    let titleAccion = stDatos.presupuestoID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Presupuesto Anual`;
    let ArrayRef = [];
    const [filtros, setfiltros] = useState({ "ejercicio": anioActual, empresaID: 1, folio:null });

    const [reporteExcel, setReporteExcel] = useState(null); // 1 - Generar  2-Generando  3 - Descargar
    const [folioGenerarExcelTodos, setFolioGenerarExcelTodos] = useState(null);
    const [consulta, setConsulta] = useState(null);
    const [reporteFinalizado, setReporteFinalizado] = useState('');
    const [startWaiting, setStartWaiting] = useState(false);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "presupuestoID" }, { campo: "polizaID", variable: "polizaID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: Consultas.getPresupuestoAnual
        })
    },
    {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Generar PDF",
        parametros: [{ campo: "presupuestoID" }, { campo: "poliza" }],
        disabled: { multiseleccion: true },
        onClick: ({ presupuestoID, poliza }) => AuxDescargar(presupuestoID, poliza)
    },
    {
        accionID: [6],
        icono: RiFileExcel2Fill,
        color: success,
        descripcion: "Generar Excel",
        parametros: [{ campo: "presupuestoID" }, { campo: "poliza" }],
        disabled: { multiseleccion: true },
        onClick: ({ presupuestoID, poliza }) => AuxDescargar(presupuestoID, poliza, 'excel')
    },
    {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "presupuestoID" },
        { campo: "poliza", variable: "poliza" },{ campo: "autorizado" }],
        disabled: { multiseleccion: true },
        onClick: ({ presupuestoID, poliza, autorizado }) => { 
            if (!autorizado) {
                handelAlertEliminar({
                    setAlert,
                    mensaje: ` el registro del presupuesto ${poliza}`,
                    onCancel: () => setAlert(null),
                    onConfirm: () => handleEliminar({
                        token, setAlert, setActualizar, actualizar,
                        parametros: { presupuestoID }, asyncDelete: Consultas.deletePresupuestoAnual
                    })
                });
            } else {
                setAlert({
                    descripcion: "No se puede eliminar presupuesto en proceso de autorización o autorizado",
                    title: "¡Una disculpa!",
                    tipo: "warning",
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null),
                    showCancel: false
                });    
            }
        },
    },
    {
        accionID: [8],
        icono: CheckCircleOutlineIcon,
        color: primary,
        descripcion: "solicitar preautorización",
        parametros: [{ campo: "presupuestoID" }, { campo: "poliza" }, { campo: "autorizado" }, { campo: "definitivo" }],
        disabled: { multiseleccion: true },
        onClick: ({ presupuestoID, poliza, autorizado, definitivo }) => ftAutorizarPresupuestoAnual(presupuestoID, poliza, autorizado, definitivo, token)
    }


];
    function AuxDescargar(presupuestoID, poliza, formatoReporte = 'PDF') {

        setdatosPDf({ "presupuestoID": presupuestoID, "poliza": poliza, formatoReporte });
        datosPDF = { "presupuestoID": presupuestoID, "poliza": poliza, formatoReporte };

        
        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de presupuesto actual?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de presupuesto actual ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de presupuesto actual ' + poliza, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    const showURL = (url) => {
        let a = document.createElement('a');
        a.href = url;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("presupuestoID", datosPDF.presupuestoID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReportePresupuestoAnual' + datosPDF.poliza
        await fetch(`${urlServidor}/service/reportes/ReportePresupuestoAnual`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => {setOpen(true); divisas()},
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }, {
        accionID: [9],
        icono: Add,
        color: success,
        descripcion: "Generar",
        onClick: () => GenerarPresupuestoAnual(),
        disabled: { multiseleccion: true }
    },{
        accionID: [6],
        icono: RiFileExcel2Fill,
        color: reporteExcel === 1 ? info : reporteExcel === 2 ? danger : success,
        descripcion: reporteExcel === 1 ? "Generando Excel" : reporteExcel === 2 ? "Obtener Excel Generado" : "Generar Excel",
        disabled: { multiseleccion: true },
        onClick: () => { 
            if (reporteExcel === null) {
                setReporteExcel(1);
                GenerarExcelTodos();
                setStartWaiting(true);
            } else if (reporteExcel === 2) {
                    showURL(reporteFinalizado);
                    setReporteExcel(null);
                    setFolioGenerarExcelTodos(null);
                    setConsulta(null);
                    setReporteFinalizado('');
                    setStartWaiting(false);
            }
        }
    },{
        accionID: [10],
        icono: DoneAllIcon,
        color: info,
        descripcion: "Autorizar Presupuesto",
        disabled: { multiseleccion: true },
        onClick: ({ presupuestoID }) => { ftBloquearPresupuestoAnual(presupuestoID, token); }
    }];

    async function Agregar() {
        if (!filtros.empresaID) {
            filtros.empresaID = 0;
        }
        if (!filtros.ejercicio) {
            filtros.ejercicio = 0;
        }

        if (filtros.empresaID === 0 || filtros.ejercicio === 0) {
            setAlert({
                descripcion: "Debe seleccionar la empresa y el ejercicio para agregar un registro",
                title: "¡Una disculpa!",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                showCancel: false
            });
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            ["empresaID"]: filtros.empresaID,
            ["ejercicio"]: filtros.ejercicio,
        }));

        let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: filtros.empresaID , tipoPoliza:"PRINCIPAL"} }, token);

        setSeleccionables(seleccionables => ({
            ...seleccionables,
            polizasSeguro
        }));
        setOpen(true);
    }

    async function GenerarPresupuestoAnual() {
        if (!filtros.empresaID) {
            filtros.empresaID = -1;
        }
        if (!filtros.ejercicio) {
            filtros.ejercicio = -1;
        }

        filtros.empresaID = parseInt(filtros.empresaID);
        filtros.ejercicio = parseInt(filtros.ejercicio);

        try {
            if (filtros.empresaID === -1 || filtros.empresaID === null) throw new Error("Seleccionar empresa");
            if (filtros.ejercicio === -1 || filtros.ejercicio === null) throw new Error("Seleccionar ejercicio");
            setLoader(true);
            let Resultado = await Consultas.GenerarPresupuestoAnual(filtros, token);
            setLoader(false);
            setAlert({
                descripcion: Resultado,
                title: "Generar presupuesto",
                tipo: success,
                msjConfirmacion: "Aceptar",
                onConfirm: () => CerrarYActualizar()
            });
        } catch (error) {
            let descripcion = "Ocurrió un error al generar el presupuesto.";
            if (error.message) {
                descripcion = error.message;
            }

            setAlert({
                descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }


    async function GenerarExcelTodos() {
        if (!filtros.empresaID) {
            filtros.empresaID = -1;
        }
        if (!filtros.ejercicio) {
            filtros.ejercicio = -1;
        }

        filtros.empresaID = parseInt(filtros.empresaID);
        filtros.ejercicio = parseInt(filtros.ejercicio);

        setdatosPDf({ "empresaID":filtros.empresaID, "ejercicio":filtros.ejercicio,"presupuestoID": null, "poliza": filtros.ejercicio, "formatoReporte":'excel', folio: filtros.folio  });
        datosPDF = {"empresaID":filtros.empresaID, "ejercicio":filtros.ejercicio, "presupuestoID": null, "poliza": filtros.ejercicio, "formatoReporte":'excel', folio: filtros.folio };
        
        setFolioGenerarExcelTodos(filtros.folio);

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de presupuesto actual?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, 
                setActualizar, actualizar,
                parametros: {}, 
                asyncMutation: () => DescargarExcelTodos(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de presupuesto actual ", tipo: success
                    },
                    msjCorrecto: {
                        //title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de presupuesto actual ' + filtros.ejercicio, msjConfirmacion: "Aceptar"
                        title: "Se envío con éxito la solicitud", descripcion: 'Se podra descargar en breve el formato de impresión del reporte de presupuesto actual ' + filtros.ejercicio, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    async function DescargarExcelTodos() {
        if (datosPDF.empresaID == null || datosPDF.empresaID === -1) throw new Error("Seleccionar empresa");
        if (datosPDF.folio == null) throw new Error("Seleccionar folio");
        if (datosPDF.ejercicio == -1 || datosPDF.ejercicio == null) throw new Error("Seleccionar ejercicio");
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("presupuestoID", datosPDF.presupuestoID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);
        formdata.append("empresaID", datosPDF.empresaID);
        formdata.append("ejercicio", datosPDF.ejercicio);
        formdata.append("folio", datosPDF.folio);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReportePresupuestoAnual' + datosPDF.poliza
        //await fetch(`${urlServidor}/service/reportes/ReportePresupuestoAnual`, requestOptions)
        fetch(`${urlServidor}/service/reportes/ReportePresupuestoAnual`, requestOptions)
            /*
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
            */
    }

    function CerrarYActualizar() {
        setAlert(null);
        setActualizar(!actualizar);
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "presupuestoID",
        color: primary,
        title: "Presupuesto Anual",
        iconTable: <FaRegMoneyBillAlt />,
        headers: [
            { variable: "presupuestoID", descripcion: "ID", hide: true },
            { variable: "polizaID", descripcion: "polizaID", hide: true },
            { variable: "folioInterno", descripcion: "Folio" },
            { variable: "ejercicioPresupuesto", descripcion: "Presupuesto" },
            { variable: "ejercicio", descripcion: "Ejercicio" },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "ramosseguro", descripcion: "Ramo de Seguro" },
            { variable: "poliza", descripcion: "No. Póliza" },
            { variable: "sumaAseguradaPoliza", descripcion: "Suma asegurada" },
            { variable: "sumaAseguradaPresupuesto", descripcion: "Suma asegurada presupuestada" },
            { variable: "factorPrimaPromedio", descripcion: "Factor prima promedio" },
            { variable: "vigenciaInicio", descripcion: "Inicio vigencia" },
            { variable: "vigenciaFinal", descripcion: "Termino de vigencia" },
            { variable: "resultado", descripcion: "Resultado" },
            { variable: "porcentajeIncremento", descripcion: "%Incremento" },
            { variable: "montoIncremento", descripcion: "Monto Incremento" },
            { variable: "primaNetaEstimada", descripcion: "Prima neta estimada" },
            { variable: "primaNetaPresupuesto", descripcion: "Prima neta presupuestada" },
            { variable: "divisa", descripcion: "Moneda" },
            { variable: "tipoCambioPromedio", descripcion: "Tipo de cambio promedio" },
            { variable: "primaNetaPresupuestoMXN", descripcion: "Prima presupuestada en MN" },
            { variable: "autorizado", descripcion: "Autorizado" },
            { variable: "fechaAutorizado", descripcion: "Fecha Autorizado" },
            { variable: "definitivo", descripcion: "Definitivo" },
            { variable: "fechaDefinicion", descripcion: "Fecha Definición" },
            { variable: "sumaAseguradaPolizaNueva", descripcion: "Suma Póliza Nueva" },
            { variable: "diferenciaSumasAseguradas", descripcion: "Diferencia Sumas Aseguradas" },
            { variable: "diferenciaPrimaNeta", descripcion: "Diferencia Prima Neta" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "reporte", descripcion: "Reporte", hide: true }
        ],
        responsiveTitle: ["poliza"],
        /*filter: [
            { campo: "ramosseguro", variable: "ramosseguro", placeholder: "Capture el ramo de seguro" },
            { campo: "poliza", variable: "poliza", placeholder: "Capture la póliza" }
        ],*/
        alineacion: [{ columnas: [0, 1, 2, 3, 4, 5], alineacion: "left" },
        { columnas: [6, 7, 8, 11, 12, 13, 14, 15, 16], alineacion: "right" },
        { columnas: [9, 10], alineacion: "center" }],
        selectFilter: [
            {
                campo: "empresaID",
                title: "Empresa",
                retorna: "number",
                data: stEmpresas ? stEmpresas : [],
                grid: { md: 3, lg: 3 },
                funcionC: (valor, setSeleccionFiltro) => { setStateFiltros({ ...stateFiltros, empresaID: valor }); setfiltros(filtros => ({...filtros, folio: null})); setSeleccionFiltro(seleccionFiltro => ({ ...seleccionFiltro, folio: null })) },
                placeholder: "Seleccionar Empresa",
                defaultValue: 1
            },
            {
                campo: "folio",
                title: "Folio",
                data:stFolios || [],
                grid: { md: 3, lg: 3 },
                placeholder: "Seleccionar folio",
                limpiarFiltro: "Todos los folios"
            },
            {
                campo: "ejercicio",
                title: "Ejercicio",
                retorna: "number",
                data: ejercicios,
                grid: { md: 3, lg: 3 },
                defaultValue: anioActual,
                funcionC: (data,setSeleccionFiltro) => {setStateFiltros({...stateFiltros, ejercicio: data}); setfiltros(filtros => ({...filtros, folio: null}));  setSeleccionFiltro(seleccionFiltro => ({ ...seleccionFiltro, folio: null })) },
            }],
        formato: [
            { columnas: ["sumaAseguradaPoliza", "resultado"], tipo: "moneda" },
            { columnas: ["sumaAseguradaPresupuesto", "resultado"], tipo: "moneda" },
            { columnas: ["factorPrimaPromedio"], tipo: "porcentaje" },
            { columnas: ["resultado"], tipo: "moneda" },
            { columnas: ["porcentajeIncremento"], tipo: "porcentaje" },
            { columnas: ["montoIncremento"], tipo: "moneda" },
            { columnas: ["primaNetaEstimada"], tipo: "moneda" },
            { columnas: ["primaNetaPresupuesto"], tipo: "moneda" },
            //{ columnas: ["divisa"], tipo: "moneda" },
            { columnas: ["tipoCambioPromedio"], tipo: "moneda" },
            { columnas: ["primaNetaPresupuestoMXN"], tipo: "moneda" },
            { columnas: ["vigenciaInicio","vigenciaFinal","fechaAutorizado","fechaModificacion","fechaDefinicion"], tipo: "fecha" , onlyDate: true},
            { columnas: ["autorizado"], tipo: "estatus" },
            { columnas: ["definitivo"], tipo: "estatus" },
            { columnas: ["sumaAseguradaPolizaNueva"], tipo: "moneda" },
            { columnas: ["diferenciaSumasAseguradas"], tipo: "moneda" },
            { columnas: ["diferenciaPrimaNeta"], tipo: "moneda" }
        ],
        handleFiltros: FiltrosActuales
    };

    

    function FiltrosActuales(id, value) {
        setfiltros(filtros => ({
            ...filtros,
            [id]: value
        }));
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["presupuestoID"] = { error: false, helperText: "" };
    cleanState["presupuestoID"] = 0;
    cleanState["empresaID"] = filtros.empresaID;
    cleanState["ejercicio"] = filtros.ejercicio;
    cleanState["ramosseguroID"] = 0;

    //----------------------------------------------------------------------------
    InicializarInputs("empresaID", "Empresa", "", filtros.empresaID);
    InicializarInputs("ejercicio", "Ejercicio", "", filtros.ejercicio);
    InicializarInputs("polizaID", "Póliza");
    InicializarInputs("ramosseguroID", "Ramo de Seguro", '', 0);
    InicializarInputs("vigenciaInicio", "Inicio vigencia", "", new Date(), "", 0, "center");
    InicializarInputs("vigenciaFinal", "Término de vigencia", "", new Date(), "", 0, "center");
    InicializarInputs("sumaAseguradaPoliza", "Suma asegurada", "", "0.00", "", 0, "right");
    InicializarInputs("sumaAseguradaPresupuesto", "Suma asegurada presupuestada", "", "0.00", "", 0, "right");
    InicializarInputs("factorPrimaPromedio", "Factor prima promedio", "", "0.00", "", 0, "right");
    InicializarInputs("resultado", "Resultado", "", "0.00", "", 0, "right");
    InicializarInputs("porcentajeIncremento", "%Incremento", "", "0.00", "", 0, "right");
    InicializarInputs("montoIncremento", "Monto Incremento", "", "0.00", "", 0, "right");
    InicializarInputs("primaNetaEstimada", "Prima neta estimada", "", "0.00", "", 0, "right");
    InicializarInputs("primaNetaPresupuesto", "Prima neta presupuestada", "", "0.00", "", 0, "right");
    InicializarInputs("divisaID", "Divisa","",69);
    InicializarInputs("paridad", "Paridad", "", "", "", "", "", "right");
    InicializarInputs("tipoCambioPromedio", "Tipo de cambio promedio", "", "0.00", "", 0, "right");
    InicializarInputs("primaNetaPresupuestoMXN", "Prima neta presupustada conv. a MN", "", "0.00", "", 0, "right");

    //-----------------------------------------------------
    InputsConID["empresaID"].grid = { md: 4, lg: 4 };
    InputsConID["ejercicio"].grid = { md: 4, lg: 4 };
    InputsConID["ramosseguroID"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["polizaID"].grid = { md: 4, lg: 4 };
    InputsConID["divisaID"].grid = { md: 2, lg: 2 };
    InputsConID["paridad"].grid = { md: 2, lg: 2 };
    InputsConID["sumaAseguradaPoliza"].grid = { md: 3, lg: 3 };
    InputsConID["sumaAseguradaPresupuesto"].grid = { md: 3, lg: 3 };
    //-----------------------------------------------------
    InputsConID["factorPrimaPromedio"].grid = { md: 3, lg: 3 };
    InputsConID["vigenciaInicio"].grid = { md: 4, lg: 4 };
    InputsConID["vigenciaFinal"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["resultado"].grid = { md: 3, lg: 3 };
    InputsConID["porcentajeIncremento"].grid = { md: 3, lg: 3 };
    InputsConID["montoIncremento"].grid = { md: 3, lg: 3 };
    InputsConID["primaNetaEstimada"].grid = { md: 3, lg: 3 };
    //-----------------------------------------------------
    InputsConID["primaNetaPresupuesto"].grid = { md: 3, lg: 3 };
    InputsConID["tipoCambioPromedio"].grid = { md: 4, lg: 4 };
    InputsConID["primaNetaPresupuestoMXN"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------

    InputsConID["ejercicio"].disabled = true;
    InputsConID["empresaID"].disabled = true;
    InputsConID["empresaID"].tipo = "select";
    InputsConID["empresaID"].data = stEmpresas;

    InputsConID["ramosseguroID"].tipo = "select";
    InputsConID["ramosseguroID"].data = seleccionables.ramoSeguros;

    InputsConID["polizaID"].tipo = "select";
    InputsConID["polizaID"].data = seleccionables.polizasSeguro;
    InputsConID["polizaID"].disabled = stDatos.ramosseguroID !== 0 && stDatos.ramosseguroID !== '' ? false : true;

    InputsConID["divisaID"].tipo = "select";
    InputsConID["divisaID"].data = seleccionables.divisas;

    InputsConID["vigenciaInicio"].tipo = "datetimepicker";
    InputsConID["vigenciaInicio"].timeFormat = false;
    InputsConID["vigenciaInicio"].onlyDate = true;
    InputsConID["vigenciaInicio"].onChange = (value) => { if (typeof value !== "object") return; handleChange(value, "vigenciaInicio"); };

    InputsConID["vigenciaFinal"].tipo = "datetimepicker";
    InputsConID["vigenciaFinal"].timeFormat = false;
    InputsConID["vigenciaFinal"].onlyDate = true;
    InputsConID["vigenciaFinal"].onChange = (value) => { if (typeof value !== "object") return; handleChange(value, "vigenciaFinal"); };

    InputsConID["sumaAseguradaPoliza"].textAlign = "right";
    InputsConID["sumaAseguradaPoliza"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["sumaAseguradaPoliza"].posicionIcono = "start";
    InputsConID["sumaAseguradaPoliza"].formatoImportes = true;
    InputsConID["sumaAseguradaPoliza"].onChange = ({ target: { value } }) => handleChange(value, "sumaAseguradaPoliza");

    InputsConID["sumaAseguradaPresupuesto"].textAlign = "right";
    InputsConID["sumaAseguradaPresupuesto"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["sumaAseguradaPresupuesto"].posicionIcono = "start";
    InputsConID["sumaAseguradaPresupuesto"].formatoImportes = true;
    InputsConID["sumaAseguradaPresupuesto"].onChange = ({ target: { value } }) => handleChange(value, "sumaAseguradaPresupuesto");

    InputsConID["factorPrimaPromedio"].textAlign = "right";
    InputsConID["factorPrimaPromedio"].icono = <IconoSimbolo simbolo="%" />;
    InputsConID["factorPrimaPromedio"].posicionIcono = "end";
    InputsConID["factorPrimaPromedio"].formatoImportes = true;
    InputsConID["factorPrimaPromedio"].onChange = ({ target: { value } }) => handleChange(value, "factorPrimaPromedio");

    InputsConID["resultado"].textAlign = "right";
    InputsConID["resultado"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["resultado"].posicionIcono = "start";
    InputsConID["resultado"].formatoImportes = true;
    InputsConID["resultado"].onChange = ({ target: { value } }) => handleChange(value, "resultado");

    InputsConID["porcentajeIncremento"].textAlign = "right";
    //InputsConID["porcentajeIncremento"].icono = <IconoSimbolo simbolo="%" />;
    InputsConID["porcentajeIncremento"].icono = <IconoSimbolo simbolo="% " />;
    InputsConID["porcentajeIncremento"].posicionIcono = "end";
    InputsConID["porcentajeIncremento"].formatoImportes = true;
    InputsConID["porcentajeIncremento"].onChange = ({ target: { value } }) => handleChange(value, "porcentajeIncremento");

    InputsConID["montoIncremento"].textAlign = "right";
    InputsConID["montoIncremento"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["montoIncremento"].posicionIcono = "start";
    InputsConID["montoIncremento"].formatoImportes = true;
    InputsConID["montoIncremento"].onChange = ({ target: { value } }) => handleChange(value, "montoIncremento");

    InputsConID["primaNetaEstimada"].textAlign = "right";
    InputsConID["primaNetaEstimada"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["primaNetaEstimada"].posicionIcono = "start";
    InputsConID["primaNetaEstimada"].formatoImportes = true;
    InputsConID["primaNetaEstimada"].onChange = ({ target: { value } }) => handleChange(value, "primaNetaEstimada");

    InputsConID["primaNetaPresupuesto"].textAlign = "right";
    InputsConID["primaNetaPresupuesto"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["primaNetaPresupuesto"].posicionIcono = "start";
    InputsConID["primaNetaPresupuesto"].formatoImportes = true;
    InputsConID["primaNetaPresupuesto"].onChange = ({ target: { value } }) => handleChange(value, "primaNetaPresupuesto");

    InputsConID["tipoCambioPromedio"].textAlign = "right";
    InputsConID["tipoCambioPromedio"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["tipoCambioPromedio"].posicionIcono = "start";
    InputsConID["tipoCambioPromedio"].formatoImportes = true;
    InputsConID["tipoCambioPromedio"].onChange = ({ target: { value } }) => handleChange(value, "tipoCambioPromedio");

    InputsConID["primaNetaPresupuestoMXN"].textAlign = "right";
    InputsConID["primaNetaPresupuestoMXN"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["primaNetaPresupuestoMXN"].posicionIcono = "start";
    InputsConID["primaNetaPresupuestoMXN"].formatoImportes = true;
    InputsConID["primaNetaPresupuestoMXN"].onChange = ({ target: { value } }) => handleChange(value, "primaNetaPresupuestoMXN");

    
    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => Identificador === "divisaID" ?  handleChangeDivisa(value) : handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        accionID: stDatos["presupuestoID"] ? [3] : [2],
        loader,
        disabled: stDatos.autorizado ? true:false,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => { 
            handleGuardar({
                setLoader,
                setDisabled,
                setActualizar,
                actualizar,
                setNotificaciones,
                handleClose,
                handleFocus,
                refFocus: ArrayRef["empresaID"],
                mensajeCorrecto: `El registro del presupuesto se ${stDatos["presupuestoID"] ? "actualizó" : "agregó"} correctamente`,
                asyncCallback: ftGuardar,
            });
        },
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftBloquearPresupuestoAnual(presupuestoID, token) {

        setDatosAutorizar({ "empresaID":filtros.empresaID, "ejercicio":filtros.ejercicio, folio: filtros.folio  });
        datosAutorizar = {"empresaID":filtros.empresaID, "ejercicio":filtros.ejercicio, folio: filtros.folio };

        if (datosAutorizar.folio) {
            handleAlertGeneric({
                setAlert,
                onCancel: () => setAlert(null),
                mensaje: {
                    title: `Autorización Presupuesto Anual`, descripcion: `¿Deseas autorizar definitivamente el presupuesto anual?`, tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
                },

                onConfirm: () => handleAlertMutationGeneric({
                    token, setAlert, setActualizar, actualizar,
                    parametros: {}, asyncMutation: () => ftAutorizarDefinitivamente(datosAutorizar.empresaID, datosAutorizar.ejercicio, datosAutorizar.folio),
                    mensajes: {
                        msjEspera: {
                            title: "Espere...", descripcion: "Autorizando definitivamente el presupuesto anual.. ", tipo: success
                        },
                        msjCorrecto: {
                            title: `Se autorizó con éxito`, descripcion: `Se autorizó con éxito el presupuesto anual`, msjConfirmacion: "Aceptar"
                        }
                    }
                })
            })
        } else {
            setAlert({
                descripcion: "Favor de seleccionar un folio",
                title: "¡Una disculpa!",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                showCancel: false
            });
        }
    }

    function ftAutorizarPresupuestoAnual(presupuestoID, poliza, autorizado, definitivo, token) {

        if (!definitivo) {

            let aux = autorizado ? "revertir la preautorización" : "preautorizar";
            let aux2 = autorizado ? "revirtió la preautorización" : "preautorizó";
            let aux3 = autorizado ? "Revertir preautorización" : "Preautorizar";
            
            handleAlertGeneric({
                setAlert,
                onCancel: () => setAlert(null),
                mensaje: {
                    title: `${aux3} Presupuesto Anual`, descripcion: `¿Deseas ${aux} el presupuesto anual?`, tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
                },

                onConfirm: () => handleAlertMutationGeneric({
                    token, setAlert, setActualizar, actualizar,
                    parametros: {presupuestoID,poliza}, asyncMutation: () => ftAutorizar(presupuestoID),
                    mensajes: {
                        msjEspera: {
                            title: "Espere...", descripcion: "Autorizando presupuesto anual.. ", tipo: success
                        },
                        msjCorrecto: {
                            title: `Se ${aux2} con éxito`, descripcion: `Se ${aux2} con éxito el presupuesto anual de la póliza ` + poliza, msjConfirmacion: "Aceptar"
                        }
                    }
                })
            })
        } else {
            setAlert({
                descripcion: "Póliza autorizada de forma definitiva, no se le podra efectuar cambio alguno.",
                title: "¡Una disculpa!",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                showCancel: false
            });
        }
    }

    async function handleChangeDivisa (value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
                    
        
         setState({ ...stDatos, ["divisaID"]: value, ["paridad"]: historialParidades[0].paridad });
    }

    function ftAutorizar(presupuestoID) {
        async function ftAutorizar() {

            let resultado =  await Consultas.autorizarPresupuestoAnual({

                    presupuestoID: Number(presupuestoID)

            }, token) ;
            setActualizar(!actualizar);
        }
        return ftAutorizar();
    }

    function ftAutorizarDefinitivamente(empresaID, ejercicio, folio) {
        async function ftAutorizarDefinitivamente() {

            let resultado =  await Consultas.autorizarDefinitivamentePresupuestoAnual({

                    empresaID: Number(empresaID),
                    ejercicio: Number(ejercicio),
                    folio: Number(folio)

            }, token) ;
            setActualizar(!actualizar);
        }
        return ftAutorizarDefinitivamente();
    }

    function handleValidaciones() {
        let error;
        var validaciones = [
            {
                condicion: !stDatos["empresaID"] || stDatos["empresaID"] === 0,
                ref: ArrayRef["empresaID"], keyError: "empresaID",
                mensajeError: "Debe especificar la empresa"
            },
            {
                condicion: !stDatos["ejercicio"] || stDatos["ejercicio"] === 0,
                ref: ArrayRef["ejercicio"], keyError: "ejercicio",
                mensajeError: "Debe especificar el ejercicio"
            },
            {
                condicion: !stDatos["ramosseguroID"] || stDatos["ramosseguroID"] === 0,
                ref: ArrayRef["ramosseguroID"], keyError: "ramosseguroID",
                mensajeError: "Debe especificar el ramo de seguro"
            },
            {
                condicion: !stDatos["polizaID"] || stDatos["polizaID"] === 0,
                ref: ArrayRef["polizaID"], keyError: "polizaID",
                mensajeError: "Debe especificar la póliza"
            },
            {
                condicion: !stDatos["divisaID"] || stDatos["divisaID"] === 0,
                ref: ArrayRef["divisaID"], keyError: "divisaID",
                mensajeError: "Debe especificar la divisa"
            }
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["presupuestoID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            }
        });

        if (error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {
            let valor;
            let error = await handleValidaciones();

            var DatosGuardar = { ...stDatos };

            DatosGuardar["empresaID"] = parseInt(DatosGuardar["empresaID"]);
            DatosGuardar["ejercicio"] = parseInt(DatosGuardar["ejercicio"]);
            DatosGuardar["ramosseguroID"] = parseInt(DatosGuardar["ramosseguroID"]);
            DatosGuardar["polizaID"] = parseInt(DatosGuardar["polizaID"]);
            DatosGuardar["divisaID"] = parseInt(DatosGuardar["divisaID"]);
            DatosGuardar["paridad"] = parseInt(DatosGuardar["paridad"]);
            DatosGuardar["vigenciaInicio"] = formatearFecha(DatosGuardar["vigenciaInicio"]);
            DatosGuardar["vigenciaFinal"] = formatearFecha(DatosGuardar["vigenciaFinal"]);
            DatosGuardar["sumaAseguradaPoliza"] = parseFloat(DatosGuardar["sumaAseguradaPoliza"]);
            DatosGuardar["sumaAseguradaPresupuesto"] = parseFloat(DatosGuardar["sumaAseguradaPresupuesto"]);
            DatosGuardar["factorPrimaPromedio"] = parseFloat(DatosGuardar["factorPrimaPromedio"]);
            DatosGuardar["resultado"] = parseFloat(DatosGuardar["resultado"]);
            DatosGuardar["porcentajeIncremento"] = parseFloat(DatosGuardar["porcentajeIncremento"]);
            DatosGuardar["montoIncremento"] = parseFloat(DatosGuardar["montoIncremento"]);
            DatosGuardar["primaNetaEstimada"] = parseFloat(DatosGuardar["primaNetaEstimada"]);
            DatosGuardar["primaNetaPresupuesto"] = parseFloat(DatosGuardar["primaNetaPresupuesto"]);
            DatosGuardar["tipoCambioPromedio"] = parseFloat(DatosGuardar["tipoCambioPromedio"]);
            DatosGuardar["primaNetaPresupuestoMXN"] = parseFloat(DatosGuardar["primaNetaPresupuestoMXN"]);

            if (error) return error;

            delete DatosGuardar["empresa"];
            delete DatosGuardar["ramosseguro"];
            delete DatosGuardar["poliza"];
            delete DatosGuardar["divisa"];
            delete DatosGuardar["autorizado"];
            delete DatosGuardar["fechaAutorizado"];
            delete DatosGuardar["sumaAseguradaPolizaNueva"];
            delete DatosGuardar["diferenciaSumasAseguradas"];
            delete DatosGuardar["diferenciaPrimaNeta"];
            delete DatosGuardar["fechaModificacion"];
            delete DatosGuardar["folio"];
            delete DatosGuardar["definitivo"];
            delete DatosGuardar["fechaDefinicion"];
            
            if (stDatos["presupuestoID"]) {
                await Consultas.updatePresupuestoAnual(DatosGuardar, token);
            }
            else {
                delete DatosGuardar["presupuestoID"];
                valor = await Consultas.addPresupuestoAnual(DatosGuardar, token);
            }
        }
        return ftGuardar();
    }

    function formatearFecha(pFecha) {
        if (moment(pFecha).isValid() === false) {
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setFotoPerfil({ valor: "", file: "", remover: false });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        if (key === "sumaAseguradaPoliza"
            || key === "factorPrimaPresupuesto"
            || key === "factorPrimaPromedio"
            || key === "resultado"
            || key === "porcentajeIncremento"
            || key === "montoIncremento"
            || key === "primaNetaEstimada"
            || key === "primaNetaPresupuesto"
            || key === "tipoCambioPromedio"
            || key === "primaNetaPresupuestoMXN") {
            if (value === "") {
                value = 0;
            }
            if (isNaN(Number(value))) {
                return;
            }
        }

        if(key === "ramosseguroID"){
            setState(stDatos => ({
                ...stDatos,
                [key]: value,
                polizaID: 0
            }));
            return;
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

        let recalcular = false;
        let tempDatos = {
            sumaAseguradaPoliza: stDatos["sumaAseguradaPoliza"],
            sumaAseguradaPresupuesto: stDatos["sumaAseguradaPresupuesto"],
            factorPrimaPromedio: stDatos["factorPrimaPromedio"],
            resultado: stDatos["resultado"],
            porcentajeIncremento: stDatos["porcentajeIncremento"],
            montoIncremento: stDatos["montoIncremento"],
            primaNetaEstimada: stDatos["primaNetaEstimada"],
            primaNetaPresupuesto: stDatos["primaNetaPresupuesto"],
            tipoCambioPromedio: stDatos["tipoCambioPromedio"],
            primaNetaPresupuestoMXN: stDatos["primaNetaPresupuestoMXN"]
        }

        if (tempDatos[key]) {
            tempDatos[key] = value;
        }

        if (key === "sumaAseguradaPoliza" || key === "sumaAseguradaPresupuesto" || key === "factorPrimaPromedio" || key === "porcentajeIncremento") {
            if (tempDatos.sumaAseguradaPresupuesto > 0) {
                tempDatos.resultado = Math.round(parseFloat(tempDatos.sumaAseguradaPresupuesto) * parseFloat(tempDatos.factorPrimaPromedio) * 100) / 100;
            }

            tempDatos.primaNetaEstimada = Math.round(parseFloat(tempDatos.resultado) * (1 + (parseFloat(tempDatos.porcentajeIncremento)/100)) * 100) / 100;
            tempDatos.montoIncremento = Math.round(parseFloat(tempDatos.resultado) * (parseFloat(tempDatos.porcentajeIncremento)/100) * 100) / 100;

            //tempDatos.primaNetaEstimada = Math.round(parseFloat(tempDatos.resultado) * ( 1 + (parseFloat(tempDatos.porcentajeIncremento)/100) ), 2);
            //tempDatos.montoIncremento = Math.round(parseFloat(tempDatos.resultado) * (parseFloat(tempDatos.porcentajeIncremento)/100), 2);

            setState(stDatos => ({
                ...stDatos,
                ...tempDatos,
                [key]: value
            }));
        }

        if (key === "primaNetaPresupuesto" || key === "tipoCambioPromedio") {
            tempDatos.primaNetaPresupuestoMXN = Math.round(parseFloat(tempDatos.primaNetaPresupuesto) * parseFloat(tempDatos.tipoCambioPromedio), 2);
            setState(stDatos => ({
                ...stDatos,
                ...tempDatos,
                [key]: value
            }));
        }
    }

    async function ObtenerEmpresas() {
        var Respuesta = await ConsultasEmpresas.seleccionEmpresa({}, token);
        setEmpresas(Respuesta);
    }

  useEffect(() => {
        async function ftEffect() {
            try {
                const folios = await Consultas.seleccionFoliosPresupuesto({filtro:{empresaID: stateFiltros.empresaID, ejercicio: stateFiltros.ejercicio}}, token);
                setFolios(folios);
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null),
                    showCancel: false
                });
            }
        } ftEffect();
    }, [stateFiltros, actualizar]);
    
    useEffect(() => {
        async function ftClearValores(){
            if(!open){
            setState({...stDatos, presupuestoID: 0, empresaID: filtros.empresaID, ejercicio: filtros.ejercicio, ramosseguroID: 0, vigenciaInicio: new Date(), vigenciaFinal: new Date(), sumaAseguradaPoliza: "0.00", sumaAseguradaPresupuesto: "0.00", factorPrimaPromedio: "0.00", resultado: "0.00", porcentajeIncremento: "0.00", montoIncremento: "0.00", primaNetaEstimada: "0.00", primaNetaPresupuesto: "0.00", divisaID: 69, paridad:  "", tipoCambioPromedio: 0, primaNetaPresupuestoMXN:  0})
            }
        }
        ftClearValores()
    }, [open])
    

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();
                let minEjercicio = await Consultas.getMinEjercicio(token);
                let ejerciciosTemp = [];
                let minAnio = parseInt(minEjercicio) - 3;
                let maxAnio = parseInt(anioActual) + 5;

                for (let i = minAnio; i <= maxAnio; i++) {
                    ejerciciosTemp.push({ value: i, label: i.toString() });
                }

                setEjercicios(ejerciciosTemp);

                let ramoSeguros = await seleccionRamoSeguro({ filtro: { fijo: true } }, token);
                /* let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: filtros.empresaID, tipoPoliza:"PRINCIPAL" } }, token); */
                const divisas = [
                    {
                        label: 'MXN',
                        value: 69
                    },
                    {
                        label: 'USD',
                        value: 100
                    },
                ]
                setSeleccionables({ ramoSeguros,/*  polizasSeguro, */ divisas });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null),
                    showCancel: false
                });
            }
        } ftEffect();
    }, [stateFiltros,actualizar]);

    useEffect(() => {
        async function ftActualizarListadoPolizas(){
            if(stDatos.ramosseguroID !== 0){
            let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: filtros.empresaID, tipoPoliza:"PRINCIPAL", ramoSeguroID: stDatos.ramosseguroID} }, token);
            setSeleccionables({...seleccionables, polizasSeguro })
        }
        }
        ftActualizarListadoPolizas()
    }, [stDatos.ramosseguroID])

    useEffect(() => {
        let timer;
        if (startWaiting) {
            timer = setTimeout(() => {
                setActualizar(prev => !prev);
            }, 20000);
        }
        return () => clearTimeout(timer);
    }, [startWaiting,actualizar]);
    
    useEffect(() => {
        async function ftActualizaDescargarReporte(){
            try {
                const auxData = await Consultas.seleccionFoliosPresupuestoReporte({filtro:{empresaID: stateFiltros.empresaID, folio: folioGenerarExcelTodos, ejercicio: stateFiltros.ejercicio}}, token);
                if (auxData.length >= 1) {
                    setConsulta(auxData);
                }
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null),
                    showCancel: false
                });
            }
        }
        ftActualizaDescargarReporte()
    }, [folioGenerarExcelTodos,actualizar])

    useEffect(() => {
        if (folioGenerarExcelTodos !== null) {
            const auxConsulta = consulta[0];
            const direccion = auxConsulta.label;
            if (direccion !== '') {
                if (reporteFinalizado !== '' && reporteFinalizado !== direccion ) {
                    setReporteExcel(2);
                    setStartWaiting(false);
                    setDisabled(false);
                };
                setReporteFinalizado(direccion);
            }
        }
    }, [consulta])

    async function divisas(){
        let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: stDatos.divisaID } }, token);
         setState({ ...stDatos, ["paridad"]: historialParidades[0].paridad });
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
	    accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    }else{
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={Consultas.tablaPresupuestoAnual}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["UsuarioNombre"]}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default PresupuestoAnual;
