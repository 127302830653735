import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import {BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import {getAllHistorialParidades} from '../../../querys/HistorialParidades';
import { getSegViajero, tablaSegViajero } from '../../../querys/SeguroViajero';
import { deleteSegViajero, addSegViajero, updateSegViajero } from '../../../mutations/SeguroViajero';
import { getPolizasSeguro } from '../../../querys/PolizasSeguro';

import { getEdad } from '../../../helpers/rutinas/metodos/rutinasGenerales';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';
import { urlServidor } from '../../../helpers';
import { letrasMod, rgxRFC, rgxRFCMod } from '../../../helpers/regexp';
import moment from 'moment';
import Documentacion from '../Documentacion';

const { trim } = Validaciones;
const {Encrypt,Decrypt}=Encriptar;

function SeguroViajero({datosDetalle,setOpenSolSegViajero,lMostrarFormularioCompleto}) {

    let history = useHistory();

    let lUtilizaProps = true;
    let URLSolSegFianID = null;
    let URLPolizasSeguroID = null;
    let mostrarTabla = false;
    let nSegViajeroID = null;
    let nEmpresaID = null;
    let nUsuarioSolicita=null;
    let datosDetalleURL ={};
    let cfolioFormateado = null;
    let cEstatus = null;
    let nSolicitud = null;
    let cTitulo2="ANEXOS: El período de seguro debe coincidir con las fechas de los boletos de avión para salir y entrar al país.";
    let {params} = useParams();
    if (params.length === 0) {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let {pURLSolSegFianID,pEmpresaID,pUsuarioSolicita,datosDetalle,pfolioFormateado,pURLPolizasSeguroID, pSolicitud } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null ;
        URLSolSegFianID = pURLSolSegFianID;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        datosDetalleURL = datosDetalle;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita =pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        nSolicitud = pSolicitud;
    }
    let {onCerrarModal,onOcultarTabla } = datosDetalleURL;
        mostrarTabla = URLSolSegFianID ? true : false;

        if (datosDetalleURL!= undefined && datosDetalleURL.length>0){
            lUtilizaProps = false;
       }

     if (lUtilizaProps) {
        let {pEstatus,pSolSegFianID,pSegViajeroID,pEmpresaID,pUsuarioSolicita,onCerrarModal,mostrarTabla,onOcultarTabla,pfolioFormateado,pURLPolizasSeguroID, pSolicitud } =  datosDetalle;
        URLPolizasSeguroID = pURLPolizasSeguroID;
        URLSolSegFianID =pSolSegFianID;
        mostrarTabla = mostrarTabla ;
        nSegViajeroID = pSegViajeroID;
        nEmpresaID = pEmpresaID;
        nUsuarioSolicita =pUsuarioSolicita;
        cfolioFormateado = pfolioFormateado;
        cEstatus = pEstatus;
        nSolicitud = pSolicitud;

    }
    mostrarTabla=lMostrarFormularioCompleto;
    //let {pSegViajeroID,pSolSegFianID,mostrarTabla,onCerrarModal,onOcultarTabla} = props.datosDetalle
    const { token } = useSelector(state => state.login);


    const formato = "YYYY-MM-DD";
    let now = moment();

    const cleanState = {
        segViajeroID: nSegViajeroID,
        solSegFianID: URLSolSegFianID,
        paterno: "",
        materno: "",
        nombres: "",
        fechaNacimiento: now,
        edad: "",
        rfc: "",
        destino: "",
        periodo: "",
        divisaID: datosDetalle.codigoMoneda == 'USD' ? 100 : 69,
        paridad: "1"
    };
    const cleanErrorState = {

        solSegFianID: { error: false, helperText: "" },
        paterno: { error: false, helperText: "" },
        materno: { error: false, helperText: "" },
        nombres: { error: false, helperText: "" },
        fechaNacimiento: { error: false, helperText: "" },
        edad: { error: false, helperText: "" },
        rfc: { error: false, helperText: "" },
        destino: { error: false, helperText: "" },
        periodo: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        paridad: {error: false, helperText: ""}
    }


    const cleanNotificaciones = { mensaje: "", color: null, open: false };


    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    let [datosPDF, setdatosPDf] = useState({});

    const [open, setOpen] = useState(!mostrarTabla);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);


    let titleAccion = state.afianzadoraID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} seguro de viaje`;


    const solSegFianIDRef = useRef(null);
    const paternoRef = useRef(null);
    const maternoRef = useRef(null);
    const nombresRef = useRef(null);
    const fechaNacimientoRef = useRef(null);
    const edadRef = useRef(null);
    const rfcRef = useRef(null);
    const destinoRef = useRef(null);
    const periodoRef = useRef(null);
    const divisaIDRef = useRef(null);

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]


    const acciones = [{
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "segViajeroID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getSegViajero,formatearResultado: [
                { id: "fechaNacimiento", formato: (data) => moment(data) }]
        })
    }, {
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "segViajeroID" },
        { campo: "nombres" }],
        disabled: { multiseleccion: true },
        onClick: ({ segViajeroID, nombres }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el seguro de ${nombres}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { segViajeroID }, asyncDelete: deleteSegViajero
            })
        })
    },
    {
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{campo: "segViajeroID"} ],
        disabled: {multiseleccion: true},
        onClick: ({segViajeroID}) =>
        {
           // setRedireccionarFormulario({segViajeroID }) ;
           setDatosDetalleAdjuntos({
            pURLDoctoOrigenID:segViajeroID ,
             pTipo:cfolioFormateado ,
             pClasificacion: "",
             pTabla: "Solicitud Seguro Viajero",
             pTitulo2: cTitulo2,
             plUtilizaProps: true
        });
        setOpenDocumentacion(true);
        }
    },
    // {
    //     icono: PictureAsPdfIcon,
    //     color: primary,
    //     descripcion: "Descargar PDF",
    //     parametros: [{ campo: "segViajeroID" }, { campo: "nombres" }],
    //     disabled: { multiseleccion: true },
    //     onClick: ({ segViajeroID, nombres }) => AuxDescargar(segViajeroID, nombres)
    // }
];

    function AuxDescargar(segViajeroID, nombres){
        setdatosPDf({"segViajeroID": segViajeroID, "nombres": nombres});
        datosPDF = {"segViajeroID": segViajeroID, "nombres": nombres};

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion:"¿Deseas descargar el  PDF del seguro de viaje?", tipo:"warning", msjConfirmacion:"Aceptar", msjCancelacion:"Cancelar"},

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros:  {}, asyncMutation: () => DescargarPdf(),
                mensajes:{
                    msjEspera:{
                        title: "Espere...", descripcion: "Generando el PDF del seguro de viaje de " + nombres, tipo : success
                    },
                    msjCorrecto:{
                        title: "Se generó con éxito", descripcion: 'Se descargó el PDF del seguro de viaje de ' + nombres, msjConfirmacion:"Aceptar"
                    }
                }
            })
        })
    }

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf(){
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("segViajeroID", datosPDF.segViajeroID);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteSolicitudSegViajero' + datosPDF.nombres
        await fetch(`${urlServidor}/service/reportes/ReporteSolicitudSegViajero`, requestOptions)
            .then(response => {
                if (!response.ok)  return response.json();
                return response.blob()
                            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                    showFile(result, `${nombreReporte}.pdf`);
                setAlert(null)
                            })
            .catch(({message}) => {throw new Error(message)});
    }

    let botones = [ {
        icono: BackspaceOutlined,
        color: danger,
        descripcion:"Regresar",
        disabled: {multiseleccion: true},
        onClick:() => {
        setOpenSolSegViajero();
    }
    },{
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    if (cEstatus != "RECHAZADA"){
        botones = [...botones, {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => {setOpen(true); divisas()},
            disabled: { multiseleccion: true }
        }]
    }

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "segViajeroID",
        color: primary,
        title: "Seguro del viajero",
        title2:cTitulo2,
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "segViajeroID", descripcion: "segViajeroID", hide: true },
            { variable: "solSegFianID", descripcion: "solSegFianID", hide: true },
            { variable: "paterno", descripcion: "Paterno" },
            { variable: "materno", descripcion: "Materno" },
            { variable: "nombres", descripcion: "Nombre" },
            { variable: "fechaNacimiento", descripcion: "Fecha de nacimiento" },
            { variable: "edad", descripcion: "Edad" },
            { variable: "rfc", descripcion: "RFC" },
            { variable: "destino", descripcion: "Destino" },
            { variable: "periodo", descripcion: "Periodo" }
        ],
        responsiveTitle: ["paterno", "materno", "nombres", "fechaNacimiento", "edad", "rfc", "destino", "periodo"],
        filter: [
            { campo: "paterno" }
        ],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [

            {
                columnas: ["fechaNacimiento"], tipo: "fecha", onlyDate: true
            }]
    }



    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: InputsDisabled(),
                    id: "paterno",
                    value: state.paterno,
                    error: errorState.paterno.error,
                    success: state.paterno && !errorState.paterno.error ? true : undefined,
                    helperText: errorState.paterno.helperText,
                    inputRef: paternoRef,
                    title: "Paterno *",
                    placeholder: "Capture la paterno",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        if (!letrasMod.test(value)) return
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "paterno")
                    },
                    onKeyDown: (evt) => handleFocus(evt, maternoRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "materno",
                    value: state.materno,
                    error: errorState.materno.error,
                    success: state.materno && !errorState.materno.error ? true : undefined,
                    helperText: errorState.materno.helperText,
                    inputRef: maternoRef,
                    title: "Materno *",
                    placeholder: "Capture la materno",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        if (!letrasMod.test(value)) return;
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "materno")
                    },
                    onKeyDown: (evt) => handleFocus(evt, nombresRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "nombres",
                    value: state.nombres,
                    error: errorState.nombres.error,
                    success: state.nombres && !errorState.nombres.error ? true : undefined,
                    helperText: errorState.nombres.helperText,
                    inputRef: nombresRef,
                    title: "Nombre *",
                    placeholder: "Capture el nombre",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        if (!letrasMod.test(value)) return;
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "nombres")
                    },
                    onKeyDown: (evt) => handleFocus(evt, fechaNacimientoRef)
                }]
        }, {
            id: "fechas",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: InputsDisabled(),
                    id: "fechaNacimiento",
                    value: state.fechaNacimiento,
                    error: errorState.fechaNacimiento.error,
                    success: state.fechaNacimiento && !errorState.fechaNacimiento.error ? true : undefined,
                    helperText: errorState.fechaNacimiento.helperText,
                    inputRef: fechaNacimientoRef,
                    title: "Fecha de nacimiento *",
                    placeholder: "Fecha de nacimiento",
                    onlyDate: true,
                    tipo: "datetimepicker",
                    grid: { md: 2, lg: 2 },
                    onChange: (value) => {
                        setState({ ...state, edad: getEdad(value), fechaNacimiento: value })
                    },
                    onKeyDown: (evt) => handleFocus(evt, edadRef)
                },
                {
                    disabled: true,
                    id: "edad",
                    value: state.edad,
                    error: errorState.edad.error,
                    success: state.edad && !errorState.edad.error ? true : undefined,
                    helperText: errorState.edad.helperText,
                    inputRef: edadRef,
                    title: "Edad *",
                    placeholder: "Capture la edad",
                    grid: { md: 2, lg: 2 },

                    onKeyDown: (evt) => handleFocus(evt, rfcRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "rfc",
                    value: state.rfc,
                    error: errorState.rfc.error,
                    success: state.rfc && !errorState.rfc.error ? true : undefined,
                    helperText: errorState.rfc.helperText,
                    inputRef: rfcRef,
                    title: "RFC *",
                    placeholder: "Capture el RFC",
                    grid: { md: 4, lg: 4 },
                    onChange: ({ target: { value } }) => {
                        /*               if(!rgxRFCMod.test(value)) return; */
                        handleChange(value.toUpperCase(), "rfc")
                    },
                    onKeyDown: (evt) => handleFocus(evt, destinoRef)
                }, {
                    disabled: true,
                    id: "divisaID",
                    value: state.divisaID,
                    error: errorState.divisaID.error,
                    success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
                    helperText: errorState.divisaID.helperText,
                    inputRef: divisaIDRef,
                    title: "Divisa",
                    placeholder: "Seleccionar la divisa",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listDivisas,
                    onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
                },
                {
                    disabled: true,
                    id: "paridad",
                    value: state.paridad,
                    error: errorState.paridad.error,
                    success: state.paridad && !errorState.paridad.error ? true : undefined,
                    helperText: errorState.paridad.helperText,
                    title: "Paridad Actual ",
                    textAlign: "right",
                    placeholder: "Paridad Actual",
                    grid: { md: 2, lg: 2 },
                    onChange: ({ target: { value } }) => { handleChange(value, "paridad") }
                },]
        }, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: InputsDisabled(),
                    id: "destino",
                    value: state.destino,
                    error: errorState.destino.error,
                    success: state.destino && !errorState.destino.error ? true : undefined,
                    helperText: errorState.destino.helperText,
                    inputRef: destinoRef,
                    title: "Destino *",
                    placeholder: "Capture el destino",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "destino")
                    },
                    onKeyDown: (evt) => handleFocus(evt, periodoRef)
                },
                {
                    disabled: InputsDisabled(),
                    id: "periodo",
                    value: state.periodo,
                    error: errorState.periodo.error,
                    success: state.periodo && !errorState.periodo.error ? true : undefined,
                    helperText: errorState.periodo.helperText,
                    inputRef: periodoRef,
                    title: "Periodo *",
                    placeholder: "Capture el periodo",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "periodo")
                    },
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];


    function InputsDisabled() {
                return false;
    }

    const accionesFormulario = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: solSegFianIDRef,
            mensajeCorrecto: `El cliente se ${state.segViajeroID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ paterno, materno, nombres, fechaNacimiento, edad, rfc, destino, periodo }) {

        let error;
        let validaciones = [
            {
                condicion: !paterno || trim(paterno) === "",
                ref: paternoRef, keyError: "paterno",
                mensajeError: "Debe especificar el nombre paterno"
            },
            {
                condicion: paterno.length > 50,
                ref: paternoRef, keyError: "paterno",
                mensajeError: "Número de caracteres capturados excedido."
            },
            {
                condicion: !materno || trim(materno) === "",
                ref: maternoRef, keyError: "materno",
                mensajeError: "Debe especificar el nombre materno"
            },
            {
                condicion: materno.length > 50,
                ref: maternoRef, keyError: "materno",
                mensajeError: "Número de caracteres capturados excedido."
            },
            {
                condicion: !nombres || trim(nombres) === "",
                ref: nombresRef, keyError: "nombres",
                mensajeError: "Debe especificar el nombre"
            },
            {
                condicion: nombres.length > 50,
                ref: nombresRef, keyError: "nombres",
                mensajeError: "Número de caracteres capturados excedido."
            },
            {
                condicion: !rgxRFC.test(rfc), ref: rfcRef, keyError: "rfc",
                mensajeError: "El RFC capturado lamentablemente no tiene un formato correcto"
            },
            {
                condicion: !destino || trim(destino) === "",
                ref: destinoRef, keyError: "destino",
                mensajeError: "Debe especificar el destino"
            },
            {
                condicion: destino.length > 50,
                ref: destinoRef, keyError: "destino",
                mensajeError: "Número de caracteres capturados excedido."
            },
            {
                condicion: !periodo || trim(periodo) === "",
                ref: periodoRef, keyError: "periodo",
                mensajeError: "Debe especificar el periodo"
            },
            {
                condicion: periodo.length > 20,
                ref: periodoRef, keyError: "periodo",
                mensajeError: "Número de caracteres capturados excedido."
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    async function handleChangeDivisa (value, key) {
        if (URLPolizasSeguroID) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
        } else {
            let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: value } }, token);
            setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
        }
    }


    async function divisas(){
        if (URLPolizasSeguroID && Number(URLPolizasSeguroID) !== 0) {
            let datosPolizaSeguro = await getPolizasSeguro({polizasSeguroID: parseInt(URLPolizasSeguroID)}, token);
            setState({ ...state, ["paridad"]: datosPolizaSeguro.paridad });
         } else {
             let historialParidades = await getAllHistorialParidades({ filtro: {divisaID: state.divisaID } }, token);        
             setState({ ...state, ["paridad"]: historialParidades[0].paridad });
         }
    }


    function ftGuardar() {
        async function ftGuardar() {

            let {
                segViajeroID,

                paterno,
                materno,
                nombres,
                fechaNacimiento,
                edad,
                rfc,
                destino,
                periodo,
                divisaID,
                paridad } = state;

            let error = await handleValidaciones({
                paterno,
                materno,
                nombres,
                fechaNacimiento,
                edad,
                rfc,
                destino,
                periodo,
                divisaID
            });

            let formato = "YYYY-MM-DD";

            if (error) return error;

            if (segViajeroID) await updateSegViajero({
                input: {
                    segViajeroID,
                    solSegFianID: URLSolSegFianID,
                    paterno: trim(paterno),
                    materno: trim(materno),
                    nombres: trim(nombres),
                     fechaNacimiento: fechaNacimiento.format(formato),
                    edad: Number(edad),
                    rfc: trim(rfc),
                    destino: trim(destino),
                    periodo: trim(periodo),
                    divisaID,
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad)
                }
            }, token);
            else await addSegViajero({
                input: {
                    solSegFianID:URLSolSegFianID,
                    paterno: trim(paterno),
                    materno: trim(materno),
                    nombres: trim(nombres),
                    fechaNacimiento: fechaNacimiento.format(formato),
                    edad: Number(edad),
                    rfc: trim(rfc),
                    destino: trim(destino),
                    periodo: trim(periodo),
                    divisaID,
                    paridad: paridad === undefined ? null : (paridad === "" ? null : paridad)
                }
            }, token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        if (!mostrarTabla){
            if (onCerrarModal != undefined) {onCerrarModal();}

        }else{
            if (onCerrarModal!= undefined) {onCerrarModal();}
            if (onOcultarTabla!= undefined) {onOcultarTabla();}
        }
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function setRedireccionarFormulario({segViajeroID}){

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${segViajeroID}", "pTipo": "${cfolioFormateado}", "pTabla": "Solicitud Seguro Viajero", "pTitulo2": "${cTitulo2}" }`;
        let url = Encrypt({data: datos, seed}).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
       }

    useEffect(() => {
        async function ftEffect() {
            try {

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    return (<>
        {mostrarTabla && <Tabla
            infoTabla={infoTabla}
            asyncData={tablaSegViajero}
            token={token}
            parametrosFijos={{solSegFianID:URLSolSegFianID}}
        />}
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={solSegFianIDRef}
        />
         {
             openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos}   setOpenDocumentacion = { () => setOpenDocumentacion(false)
                } />

            </Modal>
        }
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default SeguroViajero;
