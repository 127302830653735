import React, { useState } from 'react';

function SeccionFirmas({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    disabled = false;
  } else {
    respuesta = {
      nombreEjecutivo: '',
      nombreSolicitante: '',
      corredorSeguros: '',
      firmaSolicitante: '',
      compania: '',
      cargo: '',
      fecha1: '',
      fecha2: '',
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(key, value) {

    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[key] = value;
    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
  }

  return (<>
    <div className="cContenedorSeccion">
      {/* <div className="ElementoPregunta"> */}
      <div className="Pregunta">
        Firmas
      </div>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            Nombre del Ejecutivo (Agente/Broker)
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.nombreEjecutivo} onChange={event => handleChangeValue('nombreEjecutivo', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            Nombre del Solicitante
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.nombreSolicitante} onChange={event => handleChangeValue('nombreSolicitante', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>

        <div className="ElementoPregunta">
          <div className="Pregunta">
            Broker / Corredor de Seguros
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.corredorSeguros} onChange={event => handleChangeValue('corredorSeguros', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            Firma del Solicitante
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.firmaSolicitante} onChange={event => handleChangeValue('firmaSolicitante', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>

        <div className="ElementoPregunta">
          <div className="Pregunta">
            Compañía
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.compania} onChange={event => handleChangeValue('compania', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            Cargo
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.cargo} onChange={event => handleChangeValue('cargo', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            Fecha
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.fecha1} onChange={event => handleChangeValue('fecha1', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            Fecha
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.fecha2} onChange={event => handleChangeValue('fecha2', event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  </>);
}

export default SeccionFirmas;