import React from 'react';

// material-ui components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// estilos
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);

export default function SwitchCustom(props) {


    let { checked, onChange, value, title, disabled, inputRef } = props;

    const classes = useStyles();

    return (<FormControlLabel
        control={
            <Switch
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                value={value}
                inputRef={inputRef}
                classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                }}
            />
        }
        classes={{ label: classes.label }}
        label={title}
    />);
}
