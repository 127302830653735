import React, { useState } from 'react';

function SeccionVII({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      ejercicio4: { anio: ejercicio - 4, tonelaje: 0, caudal: 0, teus: 0, reefers: 0 },
      ejercicio3: { anio: ejercicio - 3, tonelaje: 0, caudal: 0, teus: 0, reefers: 0 },
      ejercicio2: { anio: ejercicio - 2, tonelaje: 0, caudal: 0, teus: 0, reefers: 0 },
      ejercicio1: { anio: ejercicio - 1, tonelaje: 0, caudal: 0, teus: 0, reefers: 0 },
      ejercicio: { anio: ejercicio, tonelaje: 0, caudal: 0, teus: 0, reefers: 0 },
      proyeccion: { anio: ejercicio + 1, tonelaje: 0, caudal: 0, teus: 0, reefers: 0 },
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(ejercicio, columna, value) {
    value = ftFormatoNumerico(value);
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
  }

  function ftFormatoNumerico(value) {
    value = value.replaceAll(",", "");
    if (!value) {
      return "0";
    }

    if (isNaN(value)) {
      return "0";
    }

    let parts = value.toString().split(".");
    parts[0] = parseInt(parts[0]).toString();
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      return parts.join(".");
    } else {
      return parts[0];
    }
  }

  let anchoColumnasImportes = "110px";
  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          VII. Tonelaje Anual/Caudal/Teus./Reefers:
        </div>
        <br />
        <div className="cSeccionTabla" style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px", overflow: "hidden" }}>
          <div className="cContenedorTabla">
            <div className="cTabla">
              <div className="cRowEncabezado">
                <div className="cCelda" style={{ width: "150px" }}>
                  Año
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Tonelaje Anual
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Caudal Anual (En barriles)
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Teus Manipulados
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Contenedores "Reefers" Manipulados
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"4 años atras " + stRespuestasLocal["ejercicio4"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].tonelaje} onChange={event => handleChangeValue("ejercicio4", "tonelaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].caudal} onChange={event => handleChangeValue("ejercicio4", "caudal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].teus} onChange={event => handleChangeValue("ejercicio4", "teus", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio4"].reefers} onChange={event => handleChangeValue("ejercicio4", "reefers", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"3 años atras " + stRespuestasLocal["ejercicio3"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].tonelaje} onChange={event => handleChangeValue("ejercicio3", "tonelaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].caudal} onChange={event => handleChangeValue("ejercicio3", "caudal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].teus} onChange={event => handleChangeValue("ejercicio3", "teus", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio3"].reefers} onChange={event => handleChangeValue("ejercicio3", "reefers", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"2 años atras " + stRespuestasLocal["ejercicio2"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].tonelaje} onChange={event => handleChangeValue("ejercicio2", "tonelaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].caudal} onChange={event => handleChangeValue("ejercicio2", "caudal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].teus} onChange={event => handleChangeValue("ejercicio2", "teus", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio2"].reefers} onChange={event => handleChangeValue("ejercicio2", "reefers", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"1 años atras " + stRespuestasLocal["ejercicio1"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].tonelaje} onChange={event => handleChangeValue("ejercicio1", "tonelaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].caudal} onChange={event => handleChangeValue("ejercicio1", "caudal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].teus} onChange={event => handleChangeValue("ejercicio1", "teus", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio1"].reefers} onChange={event => handleChangeValue("ejercicio1", "reefers", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"Est. cierre año actual " + stRespuestasLocal["ejercicio"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].tonelaje} onChange={event => handleChangeValue("ejercicio", "tonelaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].caudal} onChange={event => handleChangeValue("ejercicio", "caudal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].teus} onChange={event => handleChangeValue("ejercicio", "teus", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["ejercicio"].reefers} onChange={event => handleChangeValue("ejercicio", "reefers", event.target.value)} disabled={disabled} />
                </div>
              </div>
              {/* <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  {"Proyección " + stRespuestasLocal["proyeccion"].anio}
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].tonelaje} onChange={event => handleChangeValue("proyeccion", "tonelaje", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].caudal} onChange={event => handleChangeValue("proyeccion", "caudal", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].teus} onChange={event => handleChangeValue("proyeccion", "teus", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["proyeccion"].reefers} onChange={event => handleChangeValue("proyeccion", "reefers", event.target.value)} disabled={disabled} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default SeccionVII;