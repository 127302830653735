import {FetchAPI} from '@renedelangel/helpers';
import { urlServidor } from 'helpers';
import { updateSolSegFianEquipoOper } from 'mutations/Solsegfian_equipooper';

const ADD_HISTORIALPARIDADES = `
mutation addHistorialParidades($input: InputAddHistorialParidades){
    addHistorialParidades(input: $input){
        paridadID
        divisaID
        fechaCotizacion
        paridad 
    }
}`;

const UPDATE_HISTORIALPARIDADES= `
mutation updateHistorialParidades($input: InputUpdateHistorialParidades){
    updateHistorialParidades(input: $input){
        paridadID
        divisaID
        fechaCotizacion
        paridad  
    }
}`;

const DELETE_HISTORIALPARIDADES = `
mutation deleteHistorialParidades ($input: InputDeleteHistorialParidades){
    deleteHistorialParidades(input: $input)
}`;

export const addHistorialParidades = async (variables, token) => {
    try{
        let {addHistorialParidades} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_HISTORIALPARIDADES
        });
        return addHistorialParidades;
    }catch({message}) {throw new Error(message);}
};

export const updateHistorialParidades = async (variables, token) =>{
    try{
       
        let {updateHistorialParidades} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_HISTORIALPARIDADES
        });
        
        return updateHistorialParidades;
    }catch({message}) {throw new Error(message);}
};


export const deleteHistorialParidades = async (input, token) => {
    try{
        let {deleteHistorialParidades} = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_HISTORIALPARIDADES
        });
        return deleteHistorialParidades;
    }catch ({message}) {throw new Error(message);}
};
