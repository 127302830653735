import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_AFIANZADORA = `
        query get($filtro: InputGetAfianzadora){
            getAfianzadora(filtro:$filtro){
                afianzadoraID
                afianzadora
            }
        }`;

    export const GET_ALL_AFIANZADORA = `
        query filtroGetAll($filtro: InputGetAfianzadora){
            getAllAfianzadora(filtro:$filtro){
                afianzadoraID
                afianzadora
            }
        }`;

    export const TABLA_AFIANZADORA = `
        query filtro($filtro: InputFiltroAfianzadora){
            filterAfianzadora(filtro:$filtro){
            listado{
                afianzadoraID
                afianzadora

            }
            registros
            paginas
            }
        }`;

export const getAfianzadora = async (filtro, token) => {
    try {
        let { getAfianzadora } = await FetchAPI.graphql({
            token,
            variables:{filtro},
            url: urlServidor,
            query: GET_AFIANZADORA
        });

        return getAfianzadora;

    } catch({message}) { throw new Error(message); }
}

export const getAllAfianzadora = async (variables, token) => {
    try {

        let { getAllAfianzadora } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_AFIANZADORA
        });

        return getAllAfianzadora;

    } catch({message}) { throw new Error(message); }
}

export const seleccionAfianzadora = async (variables, token) => {
    try {

        let allAfianzadora = await getAllAfianzadora
        (variables, token);

        if(!Array.isArray(allAfianzadora)) throw new Error("No se encontraron registros");

        return allAfianzadora.map(({ afianzadoraID:value, afianzadora:label }) => ({ value, label: label.toUpperCase() }));

    } catch({message:label}) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaAfianzadora = async (variables, token) => {
    try{
        let {filterAfianzadora} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_AFIANZADORA
        });
        return filterAfianzadora;
    }catch({message}) { throw new Error(message); }
}
