import React, { useState } from 'react';

function SeccionX({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      fila1: { fecha: '', reclamante: '', sumasPagadas: 0, reservasPendientes: '', causaPerdida: '' },
      fila2: { fecha: '', reclamante: '', sumasPagadas: 0, reservasPendientes: '', causaPerdida: '' },
      fila3: { fecha: '', reclamante: '', sumasPagadas: 0, reservasPendientes: '', causaPerdida: '' },
      fila4: { fecha: '', reclamante: '', sumasPagadas: 0, reservasPendientes: '', causaPerdida: '' },
      fila5: { fecha: '', reclamante: '', sumasPagadas: 0, reservasPendientes: '', causaPerdida: '' },
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(ejercicio, columna, value) {
    if (columna === "sumasPagadas"){
        value = ftFormatoNumerico(value);
    }
    
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;

    if (respuestaAux[ejercicio]["respuesta"] === 0) {
      respuestaAux[ejercicio]["explicacion"] = '';
    }

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));

  }



  function ftFormatoNumerico(value) {
    value = value.replaceAll(",", "");
    if (!value) {
      return "0";
    }

    if (isNaN(value)) {
      return "0";
    }

    let parts = value.toString().split(".");
    parts[0] = parseInt(parts[0]).toString();
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (parts.length > 1) {
      return parts.join(".");
    } else {
      return parts[0];
    }
  }

  let anchoColumnasImportes = "110px";
  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          XIII. Historial Siniestral:
        </div>
        Por favor facilite con detalle todas las perdidas, aseguradas o no, durante los últimos 5 años:
        <br />
         <div className="cSeccionTabla" style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px", overflow: "hidden" }}>
          <div className="cContenedorTabla">
            <div className="cTabla">
              <div className="cRowEncabezado">
                <div className="cCelda" style={{ width: "150px" }}>
                  Fecha
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  Reclamante
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Sumas Pagadas
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  Reservas Pendientes
                </div>
                <div className="cCelda" style={{ width: "300px" }}>
                  Causa de Perdida (Descripcion)
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila1"].fecha} onChange={event => handleChangeValue("fila1", "fecha", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila1"].reclamante} onChange={event => handleChangeValue("fila1", "reclamante", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["fila1"].sumasPagadas} onChange={event => handleChangeValue("fila1", "sumasPagadas", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila1"].reservasPendientes} onChange={event => handleChangeValue("fila1", "reservasPendientes", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "300px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["fila1"].causaPerdida} onChange={event => handleChangeValue("fila1", "causaPerdida", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila2"].fecha} onChange={event => handleChangeValue("fila2", "fecha", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila2"].reclamante} onChange={event => handleChangeValue("fila2", "reclamante", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["fila2"].sumasPagadas} onChange={event => handleChangeValue("fila2", "sumasPagadas", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila2"].reservasPendientes} onChange={event => handleChangeValue("fila2", "reservasPendientes", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "300px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["fila2"].causaPerdida} onChange={event => handleChangeValue("fila2", "causaPerdida", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila3"].fecha} onChange={event => handleChangeValue("fila3", "fecha", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila3"].reclamante} onChange={event => handleChangeValue("fila3", "reclamante", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["fila3"].sumasPagadas} onChange={event => handleChangeValue("fila3", "sumasPagadas", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila3"].reservasPendientes} onChange={event => handleChangeValue("fila3", "reservasPendientes", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "300px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["fila3"].causaPerdida} onChange={event => handleChangeValue("fila3", "causaPerdida", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila4"].fecha} onChange={event => handleChangeValue("fila4", "fecha", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila4"].reclamante} onChange={event => handleChangeValue("fila4", "reclamante", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["fila4"].sumasPagadas} onChange={event => handleChangeValue("fila4", "sumasPagadas", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila4"].reservasPendientes} onChange={event => handleChangeValue("fila4", "reservasPendientes", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "300px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["fila4"].causaPerdida} onChange={event => handleChangeValue("fila4", "causaPerdida", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila5"].fecha} onChange={event => handleChangeValue("fila5", "fecha", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila5"].reclamante} onChange={event => handleChangeValue("fila5", "reclamante", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputNumerico" value={stRespuestasLocal["fila5"].sumasPagadas} onChange={event => handleChangeValue("fila5", "sumasPagadas", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "250px" }}>
                  <input className="cInputNumerico" style={{ textAlign: "center" }} value={stRespuestasLocal["fila5"].reservasPendientes} onChange={event => handleChangeValue("fila5", "reservasPendientes", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda" style={{ width: "300px" }}>
                  <textarea className="cInputText" value={stRespuestasLocal["fila5"].causaPerdida} onChange={event => handleChangeValue("fila5", "causaPerdida", event.target.value)} disabled={disabled} />
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </>);
}

export default SeccionX;