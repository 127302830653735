
export const rgxCorreo = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const rgxCorreoConcatenado = /^(([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}(\.[a-z]{2,3})+(;)?)+$/;

export const rgxPassword = /^(?=(.*[\d]){1,})(?=(.*[a-zñ]){1,})(?=(.*[A-ZÑ]){1,})(?=(.*[@#$%¡!*=<>()|¿?&-]){1,})(?:[\da-zA-ZÑñ=@#$%¡!*<>()|¿?&-]){8,30}$/;

export const rgxNumeros = /^[0-9]+$/;

export const rgxRFC = /^([A-Z&Ñ]{3,4}[0-9]{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])[A-Z0-9]{2}[0-9A])$/;

export const rgxCURP =/^([A-Z&]|[a-z&]{1})([AEIOU]|[aeiou]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([0-9]{2})$/;

export const rgxNumerosMaximo10 = /^[0-9]{1,10}$/;

export const rgxNumerosMaximo15 = /^[0-9]{1,15}$/;

export const rgxDecimal = /^[0-5]{1,1}(?:.[0-9]{1,4})?$/;

export const rgxLetras = /^[a-z.A-Z]{0,100}$/;

export const rgxNumeroDecimal = /^[0-9]+(\.[0-9]{1,2})$/;

export const rgxAntesDeGuionBajo = /^[^_]*_([Aa-zZ-]+)#?$/;

export const rgxPorcentaje = /^(100|([0-9]{1,2}(\.[0-9]{0,2})?))$/;

export const rgxMargenMax = /^([0-9]{1,2}(\.[0-9]{0,2})?)$/;

export const rgxAnyDe1a100 = /^[\S].{0,99}$/;

export const rgxAnyDe1a255 = /^[\S].{0,244}$/;

export const rgxNumeroDecimal4 = /^[0-9]+(\.[0-9]{1,4})?$/;

export const rgxsolodeprueba = /^[0-7]{0,7}$/;


//Acepta solo letras,espacios y acentos, admite copiado y pegado pero no puntos, numeros y caracteres no.
export const letrasMod = /^[a-z.A-Z .ÁÉÍÓÚáéíóúñÑ]{0,50}$/;

//RFC modificado
export const rgxRFCMod = /^[A-ZÑ&]{3,4}\d{6}[A-V1-9][A-Z1-9][0-9A]{0,13}$/;

//Para hacer validaciones desde input y no recibir strings y no exceder el numero permitido de ancho.

export const rgxdeInt10 = /^[0-9]{0,11}$/;
export const rgxdeInt11 = /^[0-9]{0,12}$/;
export const rgxdeInt5 = /^[0-9]{0,6}$/;
export const rgxdeInt4 = /^[0-9]{0,5}$/;
export const rgxdeInt1 = /^[0-9]{0,1}$/;
export const rgxdeInt2 = /^[0-9]{0,2}$/;

//Input delimitado a recibir los caracteres correspondientes desde backend
export const rgxInputTexto10 = /^.{0,11}$/;
export const rgxInputTexto8 = /^.{0,9}$/;
export const rgxInputTexto20 = /^.{0,21}$/;
export const rgxInputTexto30 = /^.{0,31}$/;
export const rgxInputTexto50 = /^.{0,51}$/;
export const rgxInputTexto100 = /^.{0,101}$/;
export const rgxInputTexto150 = /^.{0,151}$/;
export const rgxInputTexto3000 = /^.{0,3001}$/;


//Decimales 14,2 u 8,6


export const rgxDecimal16 = /^[0-9]{0,16}(\.\d{0,2})?$/;

export const rgxDecimal14 = /^[0-9]{0,14}(\.\d{0,2})?$/;

export const rgxDecimal8 = /^[0-9]{0,8}(\.\d{0,6})?$/;

export const rgxPromedio = /^[0-9]{0,16}(\.\d{0,8})?$/;
