import React, { useState } from 'react';
import { getFile } from '../../../mutations/ServerStorage'
import { getArchivo } from "../../../helpers/serverStorage";
import { showFile } from '../../../helpers';

function SeccionXII({ setRespuesta, disabled, respuesta, ejercicio, setAdjunto, seccionID }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      A: { respuesta: '' },
      B: { respuesta1: 0, respuesta2: 0, respuesta3: 0, explicacion: '' },
      C: { respuesta: 0 },
      D: { respuesta: 0, explicacion: '' },
      Archivo: { archivo: '' }
    }
  }

  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);

  function handleChangeValue(ejercicio, columna, value) {

    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;

    if (respuestaAux[ejercicio]["respuesta"] === 0) {
      respuestaAux[ejercicio]["explicacion"] = '';
    }

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));

  }


  function handleChangeValueIncisos(ejercicio, columna, value) {

    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[ejercicio][columna] = value;

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));

  }


  function handleAdjunto(ejercicio, columna, value) {

    let respuestaAux = { ...stRespuestasLocal };
    let { files } = value.target;
    let archivos = Array.from(files);

    respuestaAux[ejercicio][columna] = files[0].name;

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
    setAdjunto(archivos);

  }

  const descargar = async function (seccionID) { //get storagen

    let archivo = await getFile({ origenID: seccionID, tipo: 'ADJUNTO', clasificacion: 'ADJUNTOSENCUESTA' });
    let documento = await getArchivo(archivo.ruta);

    let inicio = documento.length;
    let cadena = documento.indexOf(',')
    let b64 = documento.substring(cadena + 1, inicio);

    let iniciofor = documento.indexOf(':');
    let finfor = documento.indexOf(';');

    let ext = documento.substring(iniciofor + 1, finfor);

    showFile(b64, archivo.nombreArchivo, ext)

  };

  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          XII. Procedimientos de Seguridad y Prevencion de Siniestros
        </div>
        <br />

        <div className="ElementoPregunta">
          <div className="Pregunta">
            A) Describa naturaleza y proximidad de las medidas de protección públicas y privadas:
          </div>
          <br />
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal.A.respuesta} onChange={event => handleChangeValue("A", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            B) Cuenta la terminal con:
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("B", "respuesta1", (stRespuestasLocal["B"].respuesta1 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta1 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta1 === 1) ? " cSelec" : "")}>Vigilancia las 24 horas</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("B", "respuesta2", (stRespuestasLocal["B"].respuesta2 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta2 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta2 === 1) ? " cSelec" : "")}>Cercamiento Total</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValueIncisos("B", "respuesta3", (stRespuestasLocal["B"].respuesta3 === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["B"].respuesta3 === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["B"].respuesta3 === 1) ? " cSelec" : "")}>Sistema de Iluminación Nocturna</div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            C)  Describa cualquier otra medida de seguridad aplicada:
          </div>
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal["B"].explicacion} onChange={event => handleChangeValueIncisos("B", "explicacion", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            D) ¿Cuenta el solicitante con un Plan Formal para la Prevención de Siniestros?
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["C"].respuesta === 1) ?
            <><div className="Pregunta">
              De contar con el mismo, por favor adjuntar copia:
            </div>
              <div className="RespuestasInput">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  {(respuesta.Archivo.archivo === '') ?
                    <>
                      <input type="file" name='archivo' accept=".doc, .xlsm, .xls, .xml, .pptx, .pdf, .jpg, .png, .jpeg" multiple={true} onChange={event => handleAdjunto("Archivo", "archivo", event)} />
                    </>
                    :
                    <>
                      <input type="button" value="Descargar Adjunto" onClick={() => descargar(seccionID)} />
                    </>
                  }
                </div>
              </div> </>
            : <></>}
        </div>
        {/* <div className="ElementoPregunta">
          <div className="Pregunta">
            D) ¿Existe alguna limitación legal o estatutaria que el asegurado pueda aplicar respecto a su negligencia para daños o lesiones?	
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("D", "respuesta", (stRespuestasLocal["D"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["D"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["D"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("D", "respuesta", (stRespuestasLocal["D"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["D"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["D"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["D"].respuesta === 1) ?
            <><div className="Pregunta">
              Por favor indique cual y con qué frecuencia la solicita:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["D"].explicacion} onChange={event => handleChangeValue("D", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div> */}
      </div>
    </div>
  </>);
}

export default SeccionXII;