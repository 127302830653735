import React, { useState } from 'react';

function SeccionVIII({ setRespuesta, disabled, respuesta, ejercicio }) {
  ejercicio = parseInt(ejercicio);

  if (respuesta) {
    respuesta = JSON.parse(respuesta);
    // disabled = true;
  } else {
    respuesta = {
      A: { respuesta: '' },
      B: { respuesta: '' },
      C: { respuesta: 0, explicacion: '' },
      D: { respuesta: 0 },
      remolcadoresPromedio: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      remolcadoresMaximo: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      barcazasPromedio: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      barcazasMaximo: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      buquesTanquePromedio: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      buquesTanqueMaximo: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      buquesGranelerosPromedio: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      buquesGranelerosMaximo: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      dePasajerosPromedio: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      dePasajerosMaximo: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      roRoPromedio: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      roRoMaximo: { numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      otrosPromedio: { otros: '', numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' },
      otrosMaximo: { otros: '', numeroBuques: '', estadiaMuelle: '', estadiaPuerto: '' }
    }
  }


  const [stRespuestasLocal, setRespuestasLocal] = useState(respuesta);
  function handleChangeValue(inciso, key, value) {
    if (disabled) {
      return;
    }
    let respuestaAux = { ...stRespuestasLocal };
    respuestaAux[inciso][key] = value;

    if (inciso === "C" || inciso === "D") {
      if (respuestaAux[inciso]["respuesta"] === 0) {
        respuestaAux[inciso]["explicacion"] = '';
      }
    }

    setRespuestasLocal(respuestaAux);
    setRespuesta(JSON.stringify(respuestaAux));
  }

  let anchoColumnasImportes = "110px";
  return (<>
    <div className="cContenedorSeccion">
      <div className="ElementoPregunta">
        <div className="Pregunta">
          IX. Operaciones de Amarre y Administración de Muelles
        </div>
        <br />
        <div className="ElementoPregunta">
          <div className="Pregunta">
            A) Clases de buques recibidos (Vg. Remolcador, barcaza, multipropósito, porta contenedor, de transporte de pasajeros (Ferry, cruceros, etc.), indicando asimismo País de Registro y Bandera utilizada:
          </div>
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal["A"].respuesta} onChange={event => handleChangeValue("A", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            B) ¿Quién es responsable y que métodos utiliza para asegurar el amarre de los buques al muelle?
          </div>
          <div className="RespuestasInput">
            <div className="InputText">
              <div className="txt">
                <textarea value={stRespuestasLocal["B"].respuesta} onChange={event => handleChangeValue("B", "respuesta", event.target.value)} disabled={disabled} />
              </div>
            </div>
          </div>
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            C) Solicita el asegurado evidencia de seguro de medio-ambiente por derrame a los armadores/responsables de los buques que amarran en Puerto?
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("C", "respuesta", (stRespuestasLocal["C"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["C"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["C"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
          {(stRespuestasLocal["C"].respuesta === 1) ?
            <><div className="Pregunta">
              ¿Cuáles son los límites del seguro por contaminación?:
            </div>
              <div className="RespuestasInput">
                <div className="InputText">
                  <div className="txt">
                    <textarea value={stRespuestasLocal["C"].explicacion} onChange={event => handleChangeValue("C", "explicacion", event.target.value)} disabled={disabled} />
                  </div>
                </div>
              </div> </>
            : <></>}
        </div>
        <div className="ElementoPregunta">
          <div className="Pregunta">
            D) ¿Es el solicitante responsable por el dragado y mantenimiento de límites mínimos de profundidad?
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("D", "respuesta", (stRespuestasLocal["D"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["D"].respuesta === 1)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["D"].respuesta === 1) ? " cSelec" : "")}>SI</div>
          </div>
          <div class="RadioButton" onClick={() => disabled === true ? "" : handleChangeValue("D", "respuesta", (stRespuestasLocal["D"].respuesta === 1 ? 0 : 1))}>
            <div class="rbt">
              <input disabled={disabled} type="radio" checked={(stRespuestasLocal["D"].respuesta === 0)} />
            </div>
            <div class={"Etiqueta " + ((stRespuestasLocal["D"].respuesta === 0) ? " cSelec" : "")}>NO</div>
          </div>
        </div>
        <div className="cSeccionTabla" style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px", overflow: "hidden" }}>
          <div className="cContenedorTabla">
            <div className="cTabla">
              <div className="cRowEncabezado">
                <div className="cCelda" style={{ width: "150px" }}>
                  Número de amarres por año
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>

                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Número de buques en puerto al mismo tiempo
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Duración de la estadía en el muelle por buque
                </div>
                <div className="cCelda" style={{ width: anchoColumnasImportes }}>
                  Duración de la estadía en el puerto por buque
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  Remolcadores/Buques de Abastecimiento
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["remolcadoresPromedio"].numeroBuques} onChange={event => handleChangeValue("remolcadoresPromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["remolcadoresMaximo"].numeroBuques} onChange={event => handleChangeValue("remolcadoresMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["remolcadoresPromedio"].estadiaMuelle} onChange={event => handleChangeValue("remolcadoresPromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["remolcadoresMaximo"].estadiaMuelle} onChange={event => handleChangeValue("remolcadoresMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["remolcadoresPromedio"].estadiaPuerto} onChange={event => handleChangeValue("remolcadoresPromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["remolcadoresMaximo"].estadiaPuerto} onChange={event => handleChangeValue("remolcadoresMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  Barcazas
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["barcazasPromedio"].numeroBuques} onChange={event => handleChangeValue("barcazasPromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["barcazasMaximo"].numeroBuques} onChange={event => handleChangeValue("barcazasMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["barcazasPromedio"].estadiaMuelle} onChange={event => handleChangeValue("barcazasPromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["barcazasMaximo"].estadiaMuelle} onChange={event => handleChangeValue("barcazasMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["barcazasPromedio"].estadiaPuerto} onChange={event => handleChangeValue("barcazasPromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["barcazasMaximo"].estadiaPuerto} onChange={event => handleChangeValue("barcazasMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  Buques Tanque/Quimiqueros
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["buquesTanquePromedio"].numeroBuques} onChange={event => handleChangeValue("buquesTanquePromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["buquesTanqueMaximo"].numeroBuques} onChange={event => handleChangeValue("buquesTanqueMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["buquesTanquePromedio"].estadiaMuelle} onChange={event => handleChangeValue("buquesTanquePromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["buquesTanqueMaximo"].estadiaMuelle} onChange={event => handleChangeValue("buquesTanqueMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["buquesTanquePromedio"].estadiaPuerto} onChange={event => handleChangeValue("buquesTanquePromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["buquesTanqueMaximo"].estadiaPuerto} onChange={event => handleChangeValue("buquesTanqueMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  Buques Graneleros
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["buquesGranelerosPromedio"].numeroBuques} onChange={event => handleChangeValue("buquesGranelerosPromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["buquesGranelerosMaximo"].numeroBuques} onChange={event => handleChangeValue("buquesGranelerosMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["buquesGranelerosPromedio"].estadiaMuelle} onChange={event => handleChangeValue("buquesGranelerosPromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["buquesGranelerosMaximo"].estadiaMuelle} onChange={event => handleChangeValue("buquesGranelerosMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["buquesGranelerosPromedio"].estadiaPuerto} onChange={event => handleChangeValue("buquesGranelerosPromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["buquesGranelerosMaximo"].estadiaPuerto} onChange={event => handleChangeValue("buquesGranelerosMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  De Pasajeros
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["dePasajerosPromedio"].numeroBuques} onChange={event => handleChangeValue("dePasajerosPromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["dePasajerosMaximo"].numeroBuques} onChange={event => handleChangeValue("dePasajerosMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["dePasajerosPromedio"].estadiaMuelle} onChange={event => handleChangeValue("dePasajerosPromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["dePasajerosMaximo"].estadiaMuelle} onChange={event => handleChangeValue("dePasajerosMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["dePasajerosPromedio"].estadiaPuerto} onChange={event => handleChangeValue("dePasajerosPromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["dePasajerosMaximo"].estadiaPuerto} onChange={event => handleChangeValue("dePasajerosMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda" style={{ width: "150px" }}>
                  Ro/Ro
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["roRoPromedio"].numeroBuques} onChange={event => handleChangeValue("roRoPromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["roRoMaximo"].numeroBuques} onChange={event => handleChangeValue("roRoMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["roRoPromedio"].estadiaMuelle} onChange={event => handleChangeValue("roRoPromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["roRoMaximo"].estadiaMuelle} onChange={event => handleChangeValue("roRoMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["roRoPromedio"].estadiaPuerto} onChange={event => handleChangeValue("roRoPromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["roRoMaximo"].estadiaPuerto} onChange={event => handleChangeValue("roRoMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
              <div className="cRow">
                <div className="cCelda cCeldaDoble" style={{ width: "150px" }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Otros (Especificar)
                  </div>
                  <input className="cInputText" value={stRespuestasLocal["otrosPromedio"].otros}
                    onChange={event => {
                      handleChangeValue("otrosPromedio", "otros", event.target.value);
                      handleChangeValue("otrosMaximo", "otros", event.target.value);
                    }} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <div style={{ width: anchoColumnasImportes }}>
                    Promedio
                  </div>
                  <div style={{ width: anchoColumnasImportes }}>
                    Máximo
                  </div>
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["otrosPromedio"].numeroBuques} onChange={event => handleChangeValue("otrosPromedio", "numeroBuques", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["otrosMaximo"].numeroBuques} onChange={event => handleChangeValue("otrosMaximo", "numeroBuques", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["otrosPromedio"].estadiaMuelle} onChange={event => handleChangeValue("otrosPromedio", "estadiaMuelle", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["otrosMaximo"].estadiaMuelle} onChange={event => handleChangeValue("otrosMaximo", "estadiaMuelle", event.target.value)} disabled={disabled} />
                </div>
                <div className="cCelda cCeldaDoble" style={{ width: anchoColumnasImportes }}>
                  <input className="cInputText" value={stRespuestasLocal["otrosPromedio"].estadiaPuerto} onChange={event => handleChangeValue("otrosPromedio", "estadiaPuerto", event.target.value)} disabled={disabled} />
                  <input className="cInputText" value={stRespuestasLocal["otrosMaximo"].estadiaPuerto} onChange={event => handleChangeValue("otrosMaximo", "estadiaPuerto", event.target.value)} disabled={disabled} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default SeccionVIII;