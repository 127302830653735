import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_SEGUIMIENTO_ACTIVIDADES = `
mutation addSeguimientoActividades($input: InputAddSeguimientoActividades!) {
    addSeguimientoActividades(input: $input) {
            seguimientoID
            actividadID
            usuarioBitID
            seguimiento
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            estado
            colorEstado
            finalizarSeguimiento
            tituloTarea
            nombreUsuario
            nombreUsuarioModifico
            siniesReclAsegID
            siniesReclTercerosID
            tipo
    }
}
`;


export const UPDATE_SEGUIMIENTO_ACTIVIDADES = `
mutation updateSeguimientoActividades($input: InputUpdateSeguimientoActividades!) {
    updateSeguimientoActividades(input: $input) {
            seguimientoID
            actividadID
            usuarioBitID
            seguimiento
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            estado
            colorEstado
            finalizarSeguimiento
            tituloTarea
            nombreUsuario
            nombreUsuarioModifico
            siniesReclAsegID
            siniesReclTercerosID
            tipo
    }
}
`;

export const DELETE_SEGUIMIENTO_ACTIVIDADES = `
mutation deleteSeguimientoActividades($input: InputDeleteSeguimientoActividades) {
    deleteSeguimientoActividades(input: $input)
}
`;


export const addSeguimientoActividades = async (variables, token) => {
    try {

        let { addSeguimientoActividades } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_SEGUIMIENTO_ACTIVIDADES
        });

        return addSeguimientoActividades;

    } catch ({ message }) { throw new Error(message); }
};

export const updateSeguimientoActividades = async (variables, token) => {
    try {

        let { updateSeguimientoActividades } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_SEGUIMIENTO_ACTIVIDADES
        });

        return updateSeguimientoActividades;

    } catch ({ message }) { throw new Error(message); }
};


export const deleteSeguimientoActividades = async (input, token) => {
    try {
        let { deleteSeguimientoActividades } = await FetchAPI.graphql({
            token,
            variables: input ,
            url: urlServidor,
            query: DELETE_SEGUIMIENTO_ACTIVIDADES
        });

        return deleteSeguimientoActividades;

    } catch ({ message }) { throw new Error(message); }
};
