import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { RiFileExcel2Fill } from "react-icons/ri";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import { getControlPago, tablaControlPagosReporteDanios } from '../../../querys/ControlPago';
import { seleccionAseguradora } from '../../../querys/Aseguradoras';
import { seleccionAgentesSeguro } from '../../../querys/AgentesSeguros';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { addControlPago, updateControlPago, deleteControlPago, generarPolizaDesdePago, controlPagoUpdateReferencia } from '../../../mutations/ControlPago';
import { useLocation, useHistory } from 'react-router-dom';
import { seleccionPolizasSeguro } from '../../../querys/PolizasSeguro';
import { getAllHistorialParidades } from '../../../querys/HistorialParidades';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric, handleGetGeneric } from '../../../_layout/helpers/handles';
import { rgxdeInt2 } from '../../../helpers/regexp';
import { tablaTrazabilidadSolicitudesSeguro } from '../../../querys/TrazabilidadSolicitudesSeguro';
import { info, danger, success, primary, rose, warning } from '../../../_layout/helpers/colores';

import { rgxLetras, rgxNumeros } from '../../../helpers/regexp';
import moment from 'moment';
import * as Permisos from '../Permisos';
import { urlServidor } from '../../../helpers';
import Documentacion from '../Documentacion';
import TrazabilidadSolicitudesSeguro from '../TrazabilidadSolicitudesSeguro';
import TrazabilidadSiniestros from '../TrazabilidadSiniestros';
import Formulario from '_layout/genericComponents/Formulario';

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const { Encrypt, Decrypt } = Encriptar;
function UpsertControlPago({ data, cerrar, referencia = { id: null, ref: null }, propsAlert, propsSetAlert }) {


    const { token } = useSelector(state => state.login);
    const formato = "YYYY-MM-DD";

    const location = useLocation();
    let history = useHistory();
    const grid = { xs: 12, sm: 12, md: 12, lg: 12 };

    let now = moment();
    let anioActual = new Date().getFullYear();

    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";

    const listEstatus = [
        {
            label: 'COMPLETADO',
            value: 'COMPLETADO'
        },
        {
            label: 'EN PROCESO',
            value: 'EN PROCESO'
        },
        {
            label: 'PENDIENTE',
            value: 'PENDIENTE'
        },
    ];

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ]

    const listOpciones = [
        { label: 'NO', value: 0 },
        { label: 'SI', value: 1 }
    ]

    const listporcentajeIVA = [
        { label: '% 0', value: 0 },
        { label: '% 16', value: 16 }
    ]

    const cleanState = {
        controlPagoID: null,
        numero: "",
        fechaTramite: now,
        empresaID: "",
        aseguradoraID: "",
        agenteSegID: "",
        descripcion: "",
        ramoSeguroID: "0",
        folioTramite: "",
        poliza: "",
        endoso: "",
        importePago: 0,
        gastosExpedicion: 0,
        porcentajeIVA: 0,
        iva: 0,
        total: 0,
        solicitado: now,
        realizado: now,
        divisaID: 69,
        folioFactura: "",
        pdf: 0,
        xml: 0,
        docOrig: 0,
        estatus: "PENDIENTE",
        observaciones: "",
        horasInvertidas: "",
        polizaID: 0,
        vigenciaIni: "",
        vigenciaHasta: "",
        devolucion: '',
        notaCredito: '',
        paridad: '',
        inciso: '',
        empresaFacturaID: ""
    };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };

    const cleanErrorState = {
        controlPagoID: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        fechaTramite: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        agenteSegID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        folioTramite: { error: false, helperText: "" },
        poliza: { error: false, helperText: "" },
        endoso: { error: false, helperText: "" },
        importePago: { error: false, helperText: "" },
        gastosExpedicion: { error: false, helperText: "" },
        porcentajeIVA: { error: false, helperText: "" },
        iva: { error: false, helperText: "" },
        total: { error: false, helperText: "" },
        solicitado: { error: false, helperText: "" },
        realizado: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        folioFactura: { error: false, helperText: "" },
        pdf: { error: false, helperText: "" },
        xml: { error: false, helperText: "" },
        docOrig: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        polizaID: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        devolucion: { error: false, helperText: "" },
        notaCredito: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        inciso: { error: false, helperText: "" },
        diasVigencia: { error: false, helperText: "" }
    }

    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
  
    let titleAccion = state.controlPagoID ? "Modificar" : "Agregar";
    let title = `${titleAccion} Control de Pago (siniestros)`;

    const numeroRef = useRef(null);
    const fechaTramiteRef = useRef(null);
    const empresaIDRef = useRef(null);
    const aseguradoraIDRef = useRef(null);
    const agenteSegIDRef = useRef(null);
    const descripcionRef = useRef(null);
    const ramoSeguroIDRef = useRef(null);
    const folioTramiteRef = useRef(null);
    const polizaRef = useRef(null);
    const endosoRef = useRef(null);
    const importePagoRef = useRef(null);
    const gastosExpedicionRef = useRef(null);
    const porcentajeIVARef = useRef(null);
    const ivaRef = useRef(null);
    const totalRef = useRef(null);
    const solicitadoRef = useRef(null);
    const realizadoRef = useRef(null);
    const divisaIDRef = useRef(null);
    const folioFacturaRef = useRef(null);
    const estatusRef = useRef(null);
    const observacionesRef = useRef(null);
    const horasInvertidasRef = useRef(null);
    const polizaIDRef = useRef(null);
    const vigenciaIniRef = useRef(null);
    const vigenciaHastaRef = useRef(null);
    const estadoRef = useRef(null);
    const devolucionRef = useRef(null);
    const notaCreditoRef = useRef(null);
    const incisoRef = useRef(null);
    const diasVigenciaRef = useRef(null);

    let accionesFormulario = [{
        accionID: state.folioInterno ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => ftGuardar()
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const inputs = [

        {
            id: "Datos del Pago",
            tipo: "fieldset",
            leyenda: "Datos del Pago",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "fechaTramite",
                    value: state.fechaTramite,
                    error: errorState.fechaTramite.error,
                    success: state.fechaTramite && !errorState.fechaTramite.error ? true : undefined,
                    helperText: errorState.fechaTramite.helperText,
                    inputRef: fechaTramiteRef,
                    placeholder: "Fecha Tramite",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 },
                    onChange: (value) => { typeof value === "object" && handleChange(value, "fechaTramite") }, /*.format(formato) */
                },
                {
                    disabled: state.solSegFianID && true,
                    id: "empresaID",
                    value: state.empresaID,
                    error: errorState.empresaID.error,
                    success: Array.isArray(state.empresaID) && state.empresaID.length > 0 && state.empresaID && !errorState.empresaID.error ? true : undefined,
                    helperText: errorState.empresaID.helperText,
                    inputRef: empresaIDRef,
                    title: "Empresa *",
                    placeholder: "Seleccionar empresa",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.empresas,
                    onChange: (value) => setState({ ...state, empresaID: value ? Number(value.value) : null, poliza: "" }),
                },
                {
                    disabled,
                    id: "aseguradoraID",
                    value: state.aseguradoraID,
                    error: errorState.aseguradoraID.error,
                    success: Array.isArray(state.aseguradoraID) && state.aseguradoraID.length > 0 && state.aseguradoraID && !errorState.aseguradoraID.error ? true : undefined,
                    helperText: errorState.aseguradoraID.helperText,
                    inputRef: aseguradoraIDRef,
                    title: "Aseguradora *",
                    placeholder: "Seleccionar Aseguradora",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.aseguradoras,
                    onChange: (value) => setState({ ...state, aseguradoraID: value ? Number(value.value) : null }),
                },
                {
                    disabled,
                    id: "agenteSegID",
                    value: state.agenteSegID,
                    error: errorState.agenteSegID.error,
                    success: Array.isArray(state.agenteSegID) && state.agenteSegID.length > 0 && state.agenteSegID && !errorState.agenteSegID.error ? true : undefined,
                    helperText: errorState.agenteSegID.helperText,
                    inputRef: agenteSegIDRef,
                    title: "Agente de Seguros *",
                    placeholder: "Seleccionar Agente",
                    grid: { md: 3, lg: 3 },
                    tipo: "autocomplete",
                    data: seleccionables.agenteSeguros,
                    onChange: (value) => setState({ ...state, agenteSegID: value ? Number(value.value) : null }),
                },
                {
                    disabled,
                    id: "descripcion",
                    value: state.descripcion,
                    error: errorState.descripcion.error,
                    success: state.descripcion && !errorState.descripcion.error ? true : undefined,
                    helperText: errorState.descripcion.helperText,
                    inputRef: descripcionRef,
                    title: "Descripción",
                    placeholder: "Capture la Descripción",
                    grid: { md: 6, lg: 6 },
                    // multiline: true,
                    onChange: ({ target: { value } }) => { handleChange(value, "descripcion") },
                    onKeyDown: () => { return; },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, descripcion: { error: state.descripcion.length > 100 && true, helperText: state.descripcion.length > 100 ? "Límite de caracteres excedido" : "" } })
                    }
                },
                {
                    disabled,
                    id: "folioTramite",
                    value: state.folioTramite,
                    error: errorState.folioTramite.error,
                    success: state.folioTramite && !errorState.folioTramite.error ? true : undefined,
                    helperText: errorState.folioTramite.helperText,
                    inputRef: folioTramiteRef,
                    title: "Folio de Tramite ",
                    placeholder: "Capture el Folio de Tramite",
                    grid: { md: 3, lg: 3 },
                    // multiline: true,
                    onChange: ({ target: { value } }) => { handleChange(value, "folioTramite") },
                    // onKeyDown: () => { return; },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, folioTramite: { error: state.folioTramite.length > 20 && true, helperText: state.folioTramite.length > 20 ? "Límite de caracteres excedido" : "" } })
                    }
                }, {
                    disabled,
                    id: "divisaID",
                    value: state.divisaID,
                    error: errorState.divisaID.error,
                    success: Array.isArray(state.divisaID) && state.divisaID.length > 0 && state.divisaID && !errorState.divisaID.error ? true : undefined,
                    helperText: errorState.divisaID.helperText,
                    inputRef: divisaIDRef,
                    title: "Divisa",
                    placeholder: "Seleccionar la Divisa",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listDivisas,
                    onChange: ({ target: { value } }) => handleChangeDivisa(value, "divisaID"),
                },
                {
                    disabled: state.divisaID == 69 && true,
                    id: "paridad",
                    value: state.paridad,
                    error: errorState.paridad.error,
                    success: state.paridad && !errorState.paridad.error ? true : undefined,
                    helperText: errorState.paridad.helperText,
                    title: "Paridad Actual ",
                    textAlign: "right",
                    placeholder: "Paridad Actual",
                    grid: { md: 2, lg: 2 },
                    onChange: ({ target: { value } }) => { handleChange(value, "paridad") }
                },
                {
                    disabled: state.solSegFianID && true,
                    id: "ramoSeguroID",
                    value: state.ramoSeguroID,
                    error: errorState.ramoSeguroID.error,
                    success: Array.isArray(state.ramoSeguroID) && state.ramoSeguroID.length > 0 && state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
                    helperText: errorState.ramoSeguroID.helperText,
                    inputRef: ramoSeguroIDRef,
                    title: "Seguro *",
                    placeholder: "Seleccionar Ramo de seguro",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.ramoSeguros,
                    onChange: (value) => setState({ ...state, ramoSeguroID: value ? Number(value.value) : null, poliza: "" }),
                },
                {
                    disabled,
                    id: "solicitado",
                    value: state.solicitado,
                    error: errorState.solicitado.error,
                    success: state.solicitado && !errorState.solicitado.error ? true : undefined,
                    helperText: errorState.solicitado.helperText,
                    inputRef: solicitadoRef,
                    placeholder: "Fecha Solicitado",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 2, lg: 2 },
                    onChange: (value) => { typeof value === "object" && handleChange(value, "solicitado") },/*.format(formato)*/
                },
                {
                    disabled,
                    id: "realizado",
                    value: state.realizado,
                    error: errorState.realizado.error,
                    success: state.realizado && !errorState.realizado.error ? true : undefined,
                    helperText: errorState.realizado.helperText,
                    inputRef: realizadoRef,
                    placeholder: "Fecha Realizado",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 2, lg: 2 },
                    onChange: (value) => { typeof value === "object" && handleChange(value, "realizado") },/*.format(formato)*/
                },
                {
                    disabled,
                    id: "importePago",
                    value: state.importePago,
                    error: errorState.importePago.error,
                    success: state.importePago && !errorState.importePago.error ? true : undefined,
                    helperText: errorState.importePago.helperText,
                    inputRef: importePagoRef,
                    title: "Prima Neta *",
                    placeholder: "Capture la Prima Neta",
                    grid: { md: 2, lg: 2 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    // multiline: true,
                    onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChangeIva(value, "importePago") },
                    onKeyDown: () => { return; },
                },
                {
                    disabled,
                    id: "gastosExpedicion",
                    value: state.gastosExpedicion,
                    error: errorState.gastosExpedicion.error,
                    success: state.gastosExpedicion && !errorState.gastosExpedicion.error ? true : undefined,
                    helperText: errorState.gastosExpedicion.helperText,
                    inputRef: gastosExpedicionRef,
                    title: "Gastos de expedición *",
                    placeholder: "Capture los Gastos de Expedición",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    // multiline: true,
                    onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChangeIva(value, "gastosExpedicion") },
                    onKeyDown: () => { return; },
                },
                {
                    disabled,
                    id: "porcentajeIVA",
                    value: state.porcentajeIVA,
                    error: errorState.porcentajeIVA.error,
                    success: Array.isArray(state.porcentajeIVA) && state.porcentajeIVA.length > 0 && state.porcentajeIVA && !errorState.porcentajeIVA.error ? true : undefined,
                    helperText: errorState.porcentajeIVA.helperText,
                    inputRef: porcentajeIVARef,
                    title: "Porcentaje IVA",
                    placeholder: "% IVA",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listporcentajeIVA,
                    onChange: ({ target: { value } }) => handleChangeIva(value, "porcentajeIVA"),
                },
                {
                    disabled: true,
                    id: "iva",
                    value: state.iva,
                    error: errorState.iva.error,
                    success: state.iva && !errorState.iva.error ? true : undefined,
                    helperText: errorState.iva.helperText,
                    inputRef: ivaRef,
                    title: "IVA *",
                    placeholder: "Capture el IVA",
                    grid: { md: 2, lg: 2 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    // multiline: true,
                    onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "iva") },
                    onKeyDown: () => { return; },
                },
                {
                    disabled: true,
                    id: "total",
                    value: state.total,
                    error: errorState.total.error,
                    success: state.total && !errorState.total.error ? true : undefined,
                    helperText: errorState.total.helperText,
                    inputRef: totalRef,
                    title: "Total *",
                    placeholder: "Capture el Total",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true,
                    // multiline: true,
                    onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "total") },
                    onKeyDown: () => { return; },
                },
                {
                    disabled,
                    id: "pdf",
                    value: state.pdf,
                    checked: state.pdf,
                    title: "Factura",
                    tipo: "switch",
                    grid: { md: 4, lg: 4 },
                    onChange: (e) => {
                        setState({ ...state, pdf: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "xml",
                    value: state.xml,
                    checked: state.xml,
                    title: "XML",
                    tipo: "switch",
                    grid: { md: 4, lg: 4 },
                    onChange: (e) => {
                        setState({ ...state, xml: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "docOrig",
                    value: state.docOrig,
                    checked: state.docOrig,
                    title: "Doc originales",
                    tipo: "switch",
                    grid: { md: 4, lg: 4 },
                    onChange: (e) => {
                        setState({ ...state, docOrig: e.target.checked })
                    }
                },
                {
                    disabled,
                    id: "folioFactura",
                    value: state.folioFactura,
                    error: errorState.folioFactura.error,
                    success: state.folioFactura && !errorState.folioFactura.error ? true : undefined,
                    helperText: errorState.folioFactura.helperText,
                    inputRef: folioFacturaRef,
                    title: "No. Factura *",
                    placeholder: "Capture el folio",
                    grid: { md: 6, lg: 6 },
                    // multiline: true,
                    onChange: ({ target: { value } }) => { handleChange(value, "folioFactura") },
                    onKeyDown: () => { return; },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, folioFactura: { error: state.folioFactura.length > 50 && true, helperText: state.folioFactura.length > 50 ? "Límite de caracteres excedido" : "" } })
                    }
                },
                {
                    disabled,
                    id: "estatus",
                    value: state.estatus,
                    error: errorState.estatus.error,
                    success: Array.isArray(state.estatus) && state.estatus.length > 0 && state.estatus && !errorState.estatus.error ? true : undefined,
                    helperText: errorState.estatus.helperText,
                    inputRef: estatusRef,
                    title: "Estatus",
                    placeholder: "Seleccionar el estatus",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listEstatus,
                    onChange: ({ target: { value } }) => handleChange(value, "estatus"),
                }, {
                    disabled,
                    id: "devolucion",
                    value: state.devolucion,
                    error: errorState.devolucion.error,
                    success: Array.isArray(state.devolucion) && state.devolucion.length > 0 && state.devolucion && !errorState.devolucion.error ? true : undefined,
                    helperText: errorState.devolucion.helperText,
                    inputRef: devolucionRef,
                    title: "¿Es una devolución / Indemnización? *",
                    placeholder: "Seleccione una Opción",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listOpciones,
                    onChange: ({ target: { value } }) => handleChange(value, "devolucion"),
                }, {
                    disabled,
                    id: "notaCredito",
                    value: state.notaCredito,
                    error: errorState.notaCredito.error,
                    success: Array.isArray(state.notaCredito) && state.notaCredito.length > 0 && state.notaCredito && !errorState.notaCredito.error ? true : undefined,
                    helperText: errorState.notaCredito.helperText,
                    inputRef: notaCreditoRef,
                    title: "¿Tiene la nota de crédito? *",
                    placeholder: "Seleccione una Opción",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listOpciones,
                    onChange: ({ target: { value } }) => handleChange(value, "notaCredito"),
                },
                /* {
                     disabled,
                     id: "horasInvertidas",
                     value: state.horasInvertidas,
                     error: errorState.horasInvertidas.error,
                     success: state.horasInvertidas && !errorState.horasInvertidas.error ? true : undefined,
                     helperText: errorState.horasInvertidas.helperText,
                     inputRef: horasInvertidasRef,
                     title: "Horas Invertidas ",
                     placeholder: "Capture las horas Invertidas",
                     grid: { md: 3, lg: 3 },
                     multiline: true,
                     onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "horasInvertidas") },
                     onKeyDown: () => { return; },
                     inputProps: {
                         onBlur: () => setErrorState({ ...cleanErrorState, horasInvertidas: { error: state.horasInvertidas.length > 100 && true, helperText: state.horasInvertidas.length > 100 ? "Límite de caracteres excedido" : "" } })
                     }
                 },*/
                {
                    disabled,
                    id: "observaciones",
                    value: state.observaciones,
                    error: errorState.observaciones.error,
                    success: state.observaciones && !errorState.observaciones.error ? true : undefined,
                    helperText: errorState.observaciones.helperText,
                    inputRef: observacionesRef,
                    title: "Observaciones ",
                    placeholder: "Capture las Observaciones",
                    grid: { md: 12, lg: 12 },
                    multiline: true,
                    onChange: ({ target: { value } }) => { handleChange(value, "observaciones") },
                    onKeyDown: () => { return; },
                    inputProps: {
                        onBlur: () => setErrorState({ ...cleanErrorState, observaciones: { error: state.observaciones.length > 3000 && true, helperText: state.observaciones.length > 100 ? "Límite de caracteres excedido (3000)" : "" } })
                    }
                },
            ]
        },
        {
            id: "Datos de Póliza",
            tipo: "fieldset",
            leyenda: "Datos de Póliza",
            grid: { lg: 12 },
            inputs: [

                {
                    disabled: state.solSegFianID && true,
                    id: "polizaID",
                    value: state.polizaID,
                    error: errorState.polizaID.error,
                    success: state.polizaID && !errorState.polizaID.error ? true : undefined,
                    helperText: errorState.polizaID.helperText,
                    inputRef: polizaRef,
                    title: "Póliza",
                    placeholder: "Seleccionar póliza *",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.polizasSeguro,
                    onChange: ({ target: { value } }) => {
                        handleChange(value, "polizaID");
                    }
                    /* addNewValue: seleccionables.polizasAutoFianza && true*/
                },
                {
                    disabled,
                    id: "poliza",
                    value: state.poliza,
                    error: errorState.poliza.error,
                    success: state.poliza && !errorState.poliza.error ? true : undefined,
                    helperText: errorState.poliza.helperText,
                    inputRef: polizaRef,
                    title: "Número de Póliza *",
                    placeholder: "Capturar Nuevo Número de Póliza",
                    grid: { md: 6, lg: 6 },
                    /*data: seleccionables.polizasAutoFianza,*/
                    onChange: ({ target: { value } }) => handleChange(value, "poliza")
                },
                {
                    disabled,
                    id: "endoso",
                    value: state.endoso,
                    error: errorState.endoso.error,
                    success: state.endoso && !errorState.endoso.error ? true : undefined,
                    helperText: errorState.endoso.helperText,
                    inputRef: endosoRef,
                    title: "Endoso ",
                    placeholder: "Capturar Nuevo Endoso",
                    grid: { md: 6, lg: 6 },
                    /*data: seleccionables.polizasAutoFianza,*/
                    onChange: ({ target: { value } }) => handleChange(value, "endoso")
                },
                {
                    disabled,
                    id: "inciso",
                    value: state.inciso,
                    error: errorState.inciso.error,
                    success: state.inciso && !errorState.inciso.error ? true : undefined,
                    helperText: errorState.inciso.helperText,
                    inputRef: incisoRef,
                    title: "Inciso",
                    placeholder: "Capturar el Inciso",
                    grid: { md: 6, lg: 6 },
                    onChange: ({ target: { value } }) => handleChange(value, "inciso")
                },
                {
                    disabled,
                    id: "vigenciaIni",
                    value: state.vigenciaIni,
                    error: errorState.vigenciaIni.error,
                    success: state.vigenciaIni && !errorState.vigenciaIni.error ? true : undefined,
                    helperText: errorState.vigenciaIni.helperText,
                    inputRef: vigenciaIniRef,
                    placeholder: "Fecha Inicio Vigencia *",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 },
                    onChange: (value) => { handleChange(value, "vigenciaIni") }, /*.format(formato) */
                },
                {
                    disabled,
                    id: "vigenciaHasta",
                    value: state.vigenciaHasta,
                    error: errorState.vigenciaHasta.error,
                    success: state.vigenciaHasta && !errorState.vigenciaHasta.error ? true : undefined,
                    helperText: errorState.vigenciaHasta.helperText,
                    inputRef: vigenciaHastaRef,
                    placeholder: "Fecha Final de Vigencia *",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 },
                    onChange: (value) => { handleChange(value, "vigenciaHasta") },/*.format(formato)*/
                },
                {
                    disabled: disabled || (parseInt(state.ramoSeguroID) !== 1 ? true : false),
                    id: "diasVigencia",
                    value: state.diasVigencia,
                    error: errorState.diasVigencia.error,
                    success: state.diasVigencia && !errorState.diasVigencia.error ? true : undefined,
                    helperText: errorState.diasVigencia.helperText,
                    inputRef: diasVigenciaRef,
                    title: "Días vigencia",
                    placeholder: "Capture los días de vigencia",
                    grid: { md: 4, lg: 4 },
                    textAlign: 'right',
                    posicionIcono: "start",
                    formatoImportes: false,
                    // multiline: true,
                    onChange: ({ target: { value } }) => { !isNaN(Number(value)) && handleChange(value, "diasVigencia") },
                    onKeyDown: () => { return; },
                }
            ]
        }

    ];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
        cerrar(true)

    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleChangeIva(value, key) {
        let iva = 0;
        let total = 0;
        if (key === "importePago") {
            total = parseFloat(value) + parseFloat(state.gastosExpedicion);
            iva = parseFloat(total) * (parseFloat(state.porcentajeIVA) / 100);
            total = total + iva;
            setState({ ...state, [key]: value, ["iva"]: parseFloat(iva).toFixed(4), ["total"]: parseFloat(total).toFixed(4) });
        }
        if (key === "gastosExpedicion") {
            total = parseFloat(value) + parseFloat(state.importePago);
            iva = parseFloat(total) * (parseFloat(state.porcentajeIVA) / 100);
            total = total + iva;
            setState({ ...state, [key]: value, ["iva"]: parseFloat(iva).toFixed(4), ["total"]: parseFloat(total).toFixed(4) });
        }
        if (key === "porcentajeIVA") {
            total = parseFloat(state.gastosExpedicion) + parseFloat(state.importePago);
            iva = parseFloat(total) * (parseFloat(value) / 100);
            total = total + iva;
            setState({ ...state, [key]: value, ["iva"]: parseFloat(iva).toFixed(4), ["total"]: parseFloat(total).toFixed(4) });
        }

    }
    async function handleChangeDivisa(value, key) {

        let historialParidades = await getAllHistorialParidades({ filtro: { divisaID: value } }, token);


        setState({ ...state, [key]: value, ["paridad"]: historialParidades[0].paridad });
    }
    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function ftGuardar() {
        async function ftGuardar() {

            try {
                let { controlPagoID, fechaTramite, empresaID, aseguradoraID, agenteSegID, descripcion, ramoSeguroID, folioTramite, poliza, endoso, importePago, gastosExpedicion, porcentajeIVA, iva, total, solicitado, realizado, divisaID, folioFactura, pdf, xml, docOrig, estatus, observaciones, horasInvertidas, polizaID, vigenciaIni, vigenciaHasta, devolucion, notaCredito, paridad, inciso, diasVigencia } = state, tipoPoliza = null, polizaAux = null;

                if (poliza.includes('@')) {
                    polizaAux = poliza.split('@')[0];
                    tipoPoliza = poliza.split('@')[1];
                }
                else {
                    polizaAux = poliza;
                    tipoPoliza = 'Definir';
                }

                let error = await handleValidaciones({ empresaID, aseguradoraID, agenteSegID, descripcion, ramoSeguroID, folioTramite, importePago, gastosExpedicion, solicitado, realizado, divisaID, folioFactura, observaciones, horasInvertidas, estatus, poliza, vigenciaIni, vigenciaHasta, devolucion, notaCredito, poliza, endoso, inciso });

                if (error) return error;

                if (controlPagoID) await updateControlPago({
                    input: {
                        controlPagoID, fechaTramite: fechaTramite.format(formato), empresaID, aseguradoraID, agenteSegID, descripcion, ramoSeguroID, folioTramite, poliza: polizaAux, tipoPoliza, endoso, importePago: parseFloat(importePago), gastosExpedicion: parseFloat(gastosExpedicion), porcentajeIVA: parseFloat(porcentajeIVA), iva: parseFloat(iva), total: parseFloat(total), solicitado: solicitado ? solicitado.format(formato) : null, realizado: realizado ? realizado.format(formato) : null, divisaID, folioFactura, pdf: Number(pdf), xml: Number(xml), docOrig: Number(docOrig), estatus, observaciones, horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0, polizaID: polizaID !== null ? Number(polizaID) : 0, vigenciaIni, vigenciaHasta, devolucion, notaCredito, paridad, inciso, diasVigencia: diasVigencia !== null ? Number(diasVigencia) : 0,
                        empresaFacturaID: state.empresaID // de momento se manda la misma que empresaID porque en siniestros no es la misma lógica de seguros donde si ocupan otro campo de empresa para identificar que otra empresa hará el pago correspondiente
                    }
                }, token);
                else {
                    let aux = await addControlPago({
                        input: {
                            fechaTramite: fechaTramite.format(formato),
                            empresaID, aseguradoraID, agenteSegID,
                            descripcion, ramoSeguroID,
                            folioTramite, poliza: polizaAux,
                            tipoPoliza, endoso, importePago: parseFloat(importePago),
                            gastosExpedicion: parseFloat(gastosExpedicion),
                            porcentajeIVA: parseFloat(porcentajeIVA), iva: parseFloat(iva),
                            total: parseFloat(total),
                            solicitado: solicitado ? solicitado.format(formato) : null,
                            realizado: realizado ? realizado.format(formato) : null,
                            divisaID, folioFactura, pdf: Number(pdf), xml: Number(xml),
                            docOrig: Number(docOrig), estatus, observaciones,
                            horasInvertidas: horasInvertidas ? parseFloat(horasInvertidas) : 0,
                            polizaID: polizaID !== null ? Number(polizaID) : 0, vigenciaIni,
                            vigenciaHasta, devolucion, notaCredito, paridad, inciso,
                            diasVigencia: diasVigencia !== null ? Number(diasVigencia) : 0,
                            reporteDaniosID: data.reporteDaniosID, empresaFacturaID: state.empresaID // de momento se manda la misma que empresaID porque en siniestros no es la misma lógica de seguros donde si ocupan otro campo de empresa para identificar que otra empresa hará el pago correspondiente
                        }
                    }, token);

                    if (referencia.ref == 'recTerID') {
                        await controlPagoUpdateReferencia({ controlPagoID: aux.controlPagoID, ref: 2, refID: parseInt(referencia.id) }, token);

                    } else if (referencia.ref == 'recAseg') {
                        await controlPagoUpdateReferencia({ controlPagoID: aux.controlPagoID, ref: 1, refID: parseInt(referencia.id) }, token);
                    }


                }
                propsSetAlert({
                    descripcion: `Se agregó correctamente el control de pago`,
                    title: "¡Correcto!",
                    tipo: success,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => propsSetAlert(null)
                });
                handleClose()
            }
            catch ({ message }) {
                setAlert({
                    descripcion: `Hubo problemas al guardar: ${message}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        return ftGuardar();
    }

    async function divisas() {

        let historialParidades = await getAllHistorialParidades({ filtro: { divisaID: state.divisaID } }, token);

        setState({ ...state, ["paridad"]: historialParidades[0].paridad });
    }

    async function handleValidaciones({ empresaID, aseguradoraID, agenteSegID, descripcion, ramoSeguroID, folioTramite, importePago, solicitado, realizado, divisaID, folioFactura, observaciones, horasInvertidas, estatus, vigenciaIni, vigenciaHasta, devolucion, notaCredito, gastosExpedicion, poliza, endoso, inciso }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(empresaID), keyError: "empresaID",
                mensajeError: "Debe seleccionar una empresa"
            },
            {
                condicion: !rgxNumeros.test(aseguradoraID), keyError: "aseguradoraID",
                mensajeError: "Debe seleccionar una aseguradora"
            },
            {
                condicion: !rgxNumeros.test(agenteSegID), keyError: "agenteSegID",
                mensajeError: "Debe seleccionar un agente de seguro"
            },
            /*  {
                  condicion: !descripcion || trim(descripcion) === "",
                  ref: descripcionRef, keyError: "descripcion",
                  mensajeError: "Debe especificar la descripcion"
              },*/
            {
                condicion: !rgxNumeros.test(ramoSeguroID), keyError: "ramoSeguroID",
                mensajeError: "Debe seleccionar un seguro"
            },
            /*  {
                  condicion: estatus==="COMPLETADO" && ( !folioTramite),
                  ref: folioTramiteRef, keyError: "folioTramite",
                  mensajeError: "Debe especificar el folio"
              },*/
            // {
            //     condicion: !poliza || trim(poliza) === "",
            //     ref: polizaRef, keyError: "poliza",
            //     mensajeError: "Debe especificar la poliza"
            // },
            // {
            //     condicion: estatus === "COMPLETADO" && (!importePago),
            //     ref: importePagoRef, keyError: "importePago",
            //     mensajeError: "Debe especificar el importe"
            // },
            // {
            //     condicion: estatus === "COMPLETADO" && (!gastosExpedicion),
            //     ref: gastosExpedicionRef, keyError: "gastosExpedicion",
            //     mensajeError: "Debe especificar el gasto"
            // },
            /* {
                 condicion: !solicitado || trim(solicitado) === "",
                 ref: solicitadoRef, keyError: "solicitado",
                 mensajeError: "Debe especificar la fecha"
             },*/
            /* {
                 condicion: !realizado || trim(realizado) === "",
                 ref: realizadoRef, keyError: "realizado",
                 mensajeError: "Debe especificar la fecha"
             },*/
            {
                condicion: devolucion === '',
                ref: devolucionRef, keyError: "devolucion",
                mensajeError: "Este campo es obligatorio"
            },
            {
                condicion: notaCredito === '',
                ref: notaCreditoRef, keyError: "notaCredito",
                mensajeError: "Este campo es obligatorio"
            },
            {
                condicion: !rgxNumeros.test(divisaID), keyError: "divisaID",
                mensajeError: "Debe seleccionar una divisa"
            },
            {
                condicion: estatus === "COMPLETADO" && (!folioFactura || trim(folioFactura) === ""),
                ref: folioFacturaRef, keyError: "folioFactura",
                mensajeError: "Debe especificar el folio"
            },
            {
                condicion: !rgxNumeros.test(divisaID), keyError: "divisaID",
                mensajeError: "Debe seleccionar una divisa"
            },

            /*{
                condicion: !observaciones || trim(observaciones) === "",
                ref: observacionesRef, keyError: "observaciones",
                mensajeError: "Debe especificar las observaciones"
            },*/
            /* horasInvertidas !== "" && {
                 condicion: !rgxdeInt2.test(horasInvertidas), ref: horasInvertidasRef, keyError: "horasInvertidas",
                 mensajeError: "El formato de la hora es inválido"
             },*/
        ];

        if (state.polizaID != 0) {
            validaciones.push(
                {
                    condicion: (!poliza || trim(poliza) === ""),
                    ref: polizaRef, keyError: "poliza",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: (!endoso || trim(endoso) === ""),
                    ref: endosoRef, keyError: "endoso",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: (!inciso || trim(inciso) === ""),
                    ref: incisoRef, keyError: "inciso",
                    mensajeError: "Dato obligatorio"
                },
                {
                    condicion: typeof vigenciaIni == 'string' || !moment(vigenciaIni).isValid(), keyError: "vigenciaIni",
                    mensajeError: "formato no válido"
                },
                {
                    condicion: typeof vigenciaHasta == 'string' || !moment(vigenciaHasta).isValid(), keyError: "vigenciaHasta",
                    mensajeError: "formato no válido"
                }
            )
        }
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }
    function AbrirRegistroDesdeParam() {
        if (location.state) {
            if (location.state.controlPagoID) {
                let controlPagoID = location.state.controlPagoID;
                window.history.replaceState({}, document.title);

                let parametros = { controlPagoID };

                location.state.controlPagoID = undefined;
                handleModificar({
                    parametros, token, setState, setOpen, setAlert,
                    asyncGet: getControlPago,
                    formatearResultado: [
                        { id: "poliza", formato: (data) => data.includes('Definir') ? data.split('@')[0] : data },
                        { id: "fechaTramite", formato: (data) => moment(data) },
                        { id: "solicitado", formato: (data) => moment(data) },
                        { id: "realizado", formato: (data) => moment(data) },
                        { id: "vigenciaIni", formato: (data) => moment(data) },
                        { id: "vigenciaHasta", formato: (data) => moment(data) },
                    ]
                });
            }
        }
    }



    useEffect(() => {
        async function ftEffect() {
            try {
                let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                let aseguradoras = await seleccionAseguradora({ filtro: {} }, token);
                let agenteSeguros = await seleccionAgentesSeguro({ filtro: {} }, token);
                let ramoSeguros = await seleccionRamoSeguro({ filtro: {} }, token);
                ramoSeguros.unshift({ label: "NO APLICA", value: "0" })
                divisas()

                let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== "" ? parseInt(state.empresaID) : -1, ramoSeguroID: state.ramoSeguroID !== "" ? parseInt(state.ramoSeguroID) : -1, tipoPoliza: "PRINCIPAL" } }, token);

                setSeleccionables({ empresas, aseguradoras, agenteSeguros, ramoSeguros, polizasSeguro });

                AbrirRegistroDesdeParam();
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar, state.empresaID, state.ramoSeguroID]);






    return (<>
        <Formulario
            title={title}
            icono={<AccountBalanceWalletIcon />}
            grid={grid}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={numeroRef}
            color={primary}
        />

        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default UpsertControlPago;