import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../../helpers';
import { ProcesosLayout } from '../../Menu/rutas.js'

export const GetPermisosUsuario = async (variables, token) => {
    let Query = `
    query getPermisosUsuario($procesoID: ID, $accionID: ID) {
        getPermisosUsuario(procesoID: $procesoID,
                            accionID: $accionID)
    }
    `;

    let getPermisosUsuario = await Ejecutar(variables, token, Query);
    let resultado = JSON.parse(getPermisosUsuario);
    if (resultado) {
        for (const [key, value] of Object.entries(resultado)) {
            if (value === null) {
                resultado[key] = "";
            }
        }
    }

    return resultado;
}

export const GetAllProcesos = async (variables, token) => {
    let Query = `
    query getAllProcesos {
        getAllProcesos
    }
    `;

    let getAllProcesos = await Ejecutar(variables, token, Query);
    let resultado = JSON.parse(getAllProcesos);

    if (resultado) {
        for (const [key, value] of Object.entries(resultado)) {
            if (value === null) {
                resultado[key] = "";
            }
        }
    }

    return resultado;
}

export const GetPermisosXPerfil = async (variables, token) => {
    let Query = `
    query getPermisosXPerfil($perfil: String) {
        getPermisosXPerfil(perfil: $perfil)
    }
    `;

    let getPermisosXPerfil = await Ejecutar(variables, token, Query);
    let resultado = JSON.parse(getPermisosXPerfil);
    if (resultado) {
        for (const [key, value] of Object.entries(resultado)) {
            if (value === null) {
                resultado[key] = "";
            }
        }
    }

    return resultado;
}

//El usuario va en el token
export const GetPermisosPersonalizadosxUsuario = async (variables, token) => {
    let Query = `
    query getPermisosPersonalizadosxUsuario($usuarioID: ID) {
        getPermisosPersonalizadosxUsuario(usuarioID: $usuarioID)
    }
    `;

    let getPermisosPersonalizadosxUsuario = await Ejecutar(variables, token, Query);
    let resultado = JSON.parse(getPermisosPersonalizadosxUsuario);
    if (resultado) {
        for (const [key, value] of Object.entries(resultado)) {
            if (value === null) {
                resultado[key] = "";
            }
        }
    }

    return resultado;
}

export const GuardarPermisosPersonalizadosxUsuario = async (variables, token) => {
    let Query = `
    mutation guardarPermisosPersonalizadosxUsuario($permisos: String, $usuarioID: ID) {
        guardarPermisosPersonalizadosxUsuario(permisos: $permisos, usuarioID: $usuarioID)
    }
    `;

    let getPermisosPersonalizadosxUsuario = await Ejecutar(variables, token, Query);
    let resultado = JSON.parse(getPermisosPersonalizadosxUsuario);
    if (resultado) {
        for (const [key, value] of Object.entries(resultado)) {
            if (value === null) {
                resultado[key] = "";
            }
        }
    }

    return resultado;
}

export const InicializarProceso = async (variables, token) => {
    let Query = `
    mutation inicializarProceso($procesoID: ID) {
        inicializarProceso(procesoID: $procesoID)
    }
    `;

    let inicializarProceso = await Ejecutar(variables, token, Query);
    let resultado = JSON.parse(inicializarProceso);
    if (resultado) {
        for (const [key, value] of Object.entries(resultado)) {
            if (value === null) {
                resultado[key] = "";
            }
        }
    }

    return resultado;
}

export const Ejecutar = async (variables, token, query) => {
    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: query
        });
        return Respuesta[Object.keys(Respuesta)[0]];

    } catch (error) {
        throw new Error(error.message);
    }
}

export async function PermisosAState(setAccionesUsr, token, procesoIDAux) {
    let procesoID = 0;
    for (const key in ProcesosLayout) {
        let keyVal = key.replace(':params', '');
        if (window.location.href.indexOf(keyVal) > 0) {
            procesoID = ProcesosLayout[key];
        }
    }

    if (procesoIDAux){
        procesoID = procesoIDAux;
    }

    let permisosUsuario = await GetPermisosUsuario({ procesoID, accionID: null }, token);
    let acciones = {};

    for (let i = 0; i < permisosUsuario.length; i++) {
        const permiso = permisosUsuario[i];
        acciones[permiso.accionID] = permiso.valor;
    }
    setAccionesUsr({ ...acciones });
}

export function ValidarAcciones(acciones, accionesUsr) {
    for (let i = acciones.length - 1; i >= 0; i--) {
        let accion = acciones[i];
        if (!accion) {
            continue;
        }
        if (!accion.accionID) {
            continue;
        }

        if (Permitido(accion.accionID, accionesUsr) === 0) {
            acciones.splice(i, 1);
            continue;
        }
    }

    return [...acciones];
}

function Permitido(accionesID, accionesUsr) {
    let Permiso = 0;
    for (let i = 0; i < accionesID.length; i++) {
        const accionID = accionesID[i];
        if (!accionesUsr[accionID]) {
            continue;
        }

        if (accionesUsr[accionID] === 1) {
            Permiso = 1;
        }
    }

    return Permiso;
}

export const reiniciarPermisosXUsuario = async (variables, token) => {
    let query = `
        query reiniciarPermisosXUsuario($params: String) {
            reiniciarPermisosXUsuario(params: $params)
    }`;

    try {
        let Respuesta = await FetchAPI.graphql({
            token,
            variables: { params: JSON.stringify(variables) },
            url: urlServidor,
            query: query
        });

        return Respuesta[Object.keys(Respuesta)[0]];
    } catch (error) { throw new Error(error.message); }
}