import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_GASTOS_MEDICOS = `
mutation add($input: InputAddSolSegFianGtosMedMay)
{
  addSolSegFianGtosMedMay(input : $input)
  {
    gtosMedMayID
    solSegFianID
    materno
    paterno
    nombres
    fechaNacimiento
    edad
    parentesco
    motivo
    divisaID
    paridad
  }
}
`;

export const UPDATE_GASTOS_MEDICOS =`
mutation update($input: InputUpdateSolSegFianGtosMedMay)
{
  updateSolSegFianGtosMedMay(input : $input)
  {
    gtosMedMayID
    solSegFianID
    materno
    paterno
    nombres
    fechaNacimiento
    edad
    parentesco
    motivo
    divisaID
    paridad
  }
}
`;

export const DELETE_GASTOS_MEDICOS = `
mutation delete ($input : InputDeleteSolSegFianGtosMedMay)
{
  deleteSolSegFianGtosMedMay(input: $input)
}
`;

export const addGastosMedicos = async (variables, token) => {
    try {
        let { addSolSegFianGtosMedMay } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_GASTOS_MEDICOS
        });
        return addSolSegFianGtosMedMay;
    } catch({message}) { throw new Error(message); }
};

export const updateGastosMedicos = async (variables, token) => {
    try {
        let { updateSolSegFianGtosMedMay } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_GASTOS_MEDICOS
        });
        return updateSolSegFianGtosMedMay;
    } catch({message}) { throw new Error(message); }
};

export const deleteGastosMedicos= async (input, token) => {
    try {
        let { deleteSolSegFianGtosMedMay } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_GASTOS_MEDICOS
        });
        return deleteSolSegFianGtosMedMay;
    } catch({message}) { throw new Error(message); }
};
