import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';


export const ADD_AFIANZADORA = `
mutation addAfianzadora($input: InputAddAfianzadora!) {
    addAfianzadora(input: $input) {
        afianzadoraID
        afianzadora
    }
}
`;


export const UPDATE_AFIANZADORA =`
mutation updateAfianzadora($input: InputUpdateAfianzadora!) {
    updateAfianzadora(input: $input) {
        afianzadoraID
        afianzadora
    }
}
`;

export const DELETE_AFIANZADORA = `
mutation deleteaAfianzadora($input: InputDeleteAfianzadora) {
    deleteAfianzadora(input: $input)
}
`;


export const addAfianzadora = async (variables, token) => {
    try {

        let { addAfianzadora } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_AFIANZADORA
        });

        return addAfianzadora;

    } catch({message}) { throw new Error(message); }
};

export const updateAfianzadora = async (variables, token) => {
    try {

        let { updateAfianzadora } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_AFIANZADORA
        });

        return updateAfianzadora;

    } catch({message}) { throw new Error(message); }
};


export const deleteAfianzadora = async (input, token) => {
    try {

        let { deleteAfianzadora } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_AFIANZADORA
        });

        return deleteAfianzadora;

    } catch({message}) { throw new Error(message); }
};
