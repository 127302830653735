import {FetchAPI} from '@renedelangel/helpers';
import {urlServidor} from '../../helpers';

export const ADD_RAMOSEGURO = `
mutation addRamoSeguro($input: InputAddRamoSeguro!){
    addRamoSeguro(input: $input){
        ramoSeguroID
        usuarioBitID
        ramoSeguro
    }
}

`;

export const UPDATE_RAMOSEGURO = `
mutation updateRamoSeguro($input: InputUpdateRamoSeguro!){
    updateRamoSeguro(input: $input){
        ramoSeguroID
        usuarioBitID
        ramoSeguro
    }
    
}
`;

export const DELETE_RAMOSEGURO = `
mutation deleteRamoSeguro($input: InputDeleteRamoSeguro){
    deleteRamoSeguro(input: $input)
}
`;


export const addRamoSeguro = async (variables, token) =>
{
    try
    {let {addRamoSeguro} =await FetchAPI.graphql({
        token,
        variables,
        url: urlServidor,
        query: ADD_RAMOSEGURO
    });

    return addRamoSeguro;
    } catch({message}) {throw new Error(message);}
};

export const updateRamoSeguro = async (variables, token) => {
    try {
        let{updateRamoSeguro} = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_RAMOSEGURO
        });
        return updateRamoSeguro;
    } catch ({message}) {throw new Error(message);}
};

export const deleteRamoSeguro = async (input, token) => {
    try {
        let {deleteRamoSeguro} = await FetchAPI.graphql({
            token,
            variables: {input},
            url: urlServidor,
            query: DELETE_RAMOSEGURO
        });
        return deleteRamoSeguro;
    } catch ({message}) {throw new Error(message);}
};