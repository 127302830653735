import { NetworkError } from "../genericComponents/Metodos";
import { danger, success, warning } from './colores';
import { errorTitle, errorBoton, modificarSinDatos, alertEliminarTitle, alertEliminarMsjConfirmacion, alertEliminarMsjCancelacion, alertEliminarDescripcion, titleEspera, genericDescripcionEspera, eliminarTitle, eliminarDescripcion, eliminarBoton,confirmacionTitle,confirmacionDescripcion,confirmacionBoton } from './handlesText';


//#region Métodos/Funciones no exportables

function VerificarTabEnter(key, keyCode) {
    return ((key === "Enter" || keyCode === 13));
}


function alertEspera(setAlert, msjEspera) {

    let { title = titleEspera, descripcion = "Aquí se necesita capturar un mensaje de espera... (¿el título y el tipo están bien?)", tipo = success } = msjEspera;

    if(setAlert) setAlert({
        title,
        descripcion: NetworkError(descripcion),
        tipo,
        onConfirm: () => setAlert(null),
        showConfirm: false,
        showCancel: false
    });

}

function setErrorGeneric({ setErrorToken, descripcion, setActualizar, actualizar, setAlert }) {

    if(setErrorToken) setErrorToken(descripcion);

    if(setActualizar) setActualizar(!actualizar);

    if(setAlert) setAlert({
        descripcion: NetworkError(descripcion),
        title: errorTitle,
        tipo: danger,
        msjConfirmacion: errorBoton,
        onConfirm: () => setAlert(null)
    });
    else console.error(descripcion);

}

//#endregion

export function enterFocusValidacion({key, keyCode}, { referencia, select = true }) {
    if(VerificarTabEnter(key, keyCode)) focusValidacion({ referencia, select });
}


export function focusValidacion({ referencia, select = true }) {
    if(referencia && referencia.current) {
        if(referencia.current.focus && typeof referencia.current.focus === "function") referencia.current.focus();
        if(select && referencia.current.select && typeof referencia.current.select === "function") referencia.current.select();
    }
}

export function handleFocus({key, keyCode}, referencia, select = true, callback) {
    handleFocusGenerico(VerificarTabEnter(key, keyCode), referencia, select, callback);
}

export async function asyncHandleFocus({key, keyCode}, referencia, select = true, callback) {
    return asyncHandleFocusGenerico(VerificarTabEnter(key, keyCode), referencia, select, callback);
}

export function handleErrorInputText({
        ref,
        keyError,
        loader,
        disabled,
        errorState,
        mensajeError,
        focus = true,
        select = true,
        condicion = false,
        cleanErrorState = {},
        callback
    }) {

    if(condicion) {

        if(ref && ref.current && ref.current.focus && focus) ref.current.focus();
        if(ref && ref.current && ref.current.select && select) ref.current.select();
        errorState(error => ({
            ...error,
            [keyError]: {
                error: true,
                helperText: mensajeError
            }
        }));
        if(loader) loader(false);
        if(disabled) disabled(false);

        if(callback) callback();

        return true;

    } else {

        if(errorState) errorState(error => ({
            ...error,
            ...cleanErrorState
        }));
        return false;
    }
}

export async function handleModificar({
    parametros,
    token,
    asyncGet,
    setState,
    setOpen,
    setAlert,
    formatearResultado = [],
    setErrorToken
}, callback) {
    return handleGetGeneric({
        parametros,
        token,
        asyncGet,
        setState,
        setOpen,
        setAlert,
        formatearResultado,
        setErrorToken
    }, callback);

}

export async function handelAlertEliminar({
    setAlert,
    onConfirm,
    onCancel,
    mensaje = "{ aquí va tu mensaje personalizado }"
}) {

    let descripcion = alertEliminarDescripcion(mensaje);

    return handleAlertGeneric({
        setAlert,
        onConfirm,
        onCancel,
        mensaje: {
            title: alertEliminarTitle,
            descripcion,
            tipo: warning,
            msjConfirmacion: alertEliminarMsjConfirmacion,
            msjCancelacion: alertEliminarMsjCancelacion
        }
    });

}

export async function handleEliminar({
    parametros,
    token,
    setAlert,
    asyncDelete,
    setActualizar,
    actualizar = true,
    setErrorToken
}, callback) {
    return handleAlertMutationGeneric({
        parametros,
        token,
        setAlert,
        setActualizar,
        actualizar,
        setErrorToken,
        asyncMutation: asyncDelete,
        mensajes: {
            msjEspera: { descripcion: genericDescripcionEspera("eliminación") },
            msjCorrecto: { title: eliminarTitle, descripcion: eliminarDescripcion, tipo: success, msjConfirmacion: eliminarBoton }
        }
    }, callback);

}


export async function handelAlertConfirmacion({
    setAlert,
    onConfirm,
    onCancel,
    descripcionMensaje = "{ aquí va tu mensaje personalizado }",
    alertTitle 
}) {
 
    return handleAlertGeneric({
        setAlert,
        onConfirm,
        onCancel,
        mensaje: {
            title: alertTitle,
            descripcion:descripcionMensaje,
            tipo: warning,
            msjConfirmacion: "Aceptar",
            msjCancelacion: "Cancelar"
        }
    });

}

export async function handleEjecutaMetodo({
    parametros,
    token,
    setAlert,
    asyncDelete,
    setActualizar,
    actualizar = true,
    setErrorToken
}, callback) {
    return handleAlertMutationGeneric({
        parametros,
        token,
        setAlert,
        setActualizar,
        actualizar,
        setErrorToken,
        asyncMutation: asyncDelete,
        mensajes: {
            msjEspera: { descripcion: genericDescripcionEspera(" actualización ") },
            msjCorrecto: { title: confirmacionTitle, descripcion: confirmacionDescripcion, tipo: success, msjConfirmacion: confirmacionBoton }
        }
    }, callback);

}


export async function handleGuardar({
    setLoader,
    setDisabled,
    setActualizar,
    setNotificaciones,
    handleClose,
    handleFocus,
    refFocus,
    mensajeCorrecto,
    asyncCallback,
    actualizar = true,
    timer = true,
    time = 500,
    setErrorToken
}, callback) {
    if(setLoader) setLoader(true);
    if(setDisabled) setDisabled(true);
    try {

        if(asyncCallback) {
            let error = await asyncCallback();
            if(error){
                if(setLoader) setLoader(false);
                if(setDisabled) setDisabled(false);
                return;
            } 
        }

        if(setNotificaciones) setNotificaciones((notificaciones) => ({
            ...notificaciones,
            mensaje: mensajeCorrecto,
            color: success,
            open: true
        }));

        if(callback) return callback();

        if(timer) setTimeout(() => {
            if(setActualizar) setActualizar(!actualizar);
            if(handleClose) handleClose();
            if(setLoader) setLoader(false);
            if(setDisabled) setDisabled(false);
        }, time);

    } catch({message:mensaje}) {
        if(setErrorToken) setErrorToken(mensaje);
        if(setLoader) setLoader(false);
        if(setDisabled) setDisabled(false);
        if(setActualizar) setActualizar(!actualizar);
        if(handleFocus && refFocus) handleFocus({ key: "Enter", keyCode: 13 }, refFocus);
        if(setNotificaciones) setNotificaciones((notificaciones) => ({
            ...notificaciones,
            mensaje,
            color: danger,
            open: true
        }));
    }
}

export async function handleMenu(rutas, permisosUsuario) {
    try {
        let routes = [];
        if(Array.isArray(rutas) && rutas.length > 0) {
            for await (const ruta of rutas) {
                const { titulo:name, ruta:path, componente:component, icono:icon, layout, hide, colapsar:collapse, vistas:views, agrupador:state,
                    nivel, id } = ruta;
                let info = { path, name, component, icon, layout, hide, collapse, state, views, nivel, id };

                if(collapse && (views && Array.isArray(views) && views.length > 0)) {
                    let vistas = await handleMenu(views, permisosUsuario);
                    info = { ...info, views: vistas ? vistas : [] };
                    if (vistas.length <= 0) {
                        continue;
                    }
                }else{
                    if (id){
                        if (!permisosUsuario.hasOwnProperty(id)) {
                            continue;
                        }
                        if (permisosUsuario[id] !== 1) {
                            continue;
                        }
                    }

                }

                routes = [...routes, info];
            }
        }
        return routes;

    } catch({message}) { throw new Error(message); }
}

//#region Handles Generico

export function handleFocusGenerico(valido, referencia, select = true, callback) {
    if(valido && referencia && referencia.current) {
        if(callback) callback();
        focusValidacion({ referencia, select });
    }
}

export async function asyncHandleFocusGenerico(valido, referencia, select = true, callback) {
    if(valido && referencia && referencia.current) {
        if(callback) await callback();
        focusValidacion({ referencia, select });
    }
}

export function handleErrorGeneric({
    condicion,
    mensaje
}) { if(condicion) { throw new Error(mensaje); }}

export async function handleGetGeneric({
    parametros,
    token,
    asyncGet,
    setState,
    setOpen,
    setAlert,
    formatearResultado = [],
    setErrorToken,
    mensajes = {}
}, callback) {
    try {

        let { msjEspera = {}, msjError = modificarSinDatos } = mensajes;
        let { title = titleEspera, descripcion = genericDescripcionEspera("consulta"), tipo = success } = msjEspera;
        let resultado;

        alertEspera(setAlert, { title, descripcion, tipo });

        if(asyncGet) resultado = await asyncGet(parametros, token);

        if(!resultado) throw new Error(msjError);

        if(Array.isArray(formatearResultado) && formatearResultado.length > 0) {
            formatearResultado.forEach(({id, formato}) => {
                let info = formato ? formato(resultado[id]) : resultado[id];
                resultado = { ...resultado, [id]: info };
            });
        }

        if(setState) setState(state => ({
            ...state,
            ...resultado
        }));

        if(setOpen) {
            if(setAlert) setAlert(null);
            setOpen(true);
        }

        if(callback) return callback(resultado);

    } catch({message:descripcion}) {

        setErrorGeneric({ setErrorToken, descripcion, setAlert }) ;

    }
}

export async function handleAlertGeneric({
    setAlert,
    onConfirm,
    onCancel,
    mensaje = {}
}) {

    let {
        title = "¡Cuidado no tengo un título claro!",
        descripcion = "Deberías de redactar una alerta clara, a mi no se ocurre nada genérico... (también revisa el título y los mensajes de los botones)",
        tipo = warning,
        msjConfirmacion = "Confirmar :)",
        msjCancelacion = "Cancelar :("
    } = mensaje;

    if(setAlert) setAlert({
        title,
        descripcion,
        tipo,
        msjConfirmacion,
        msjCancelacion,
        onConfirm,
        onCancel
    });

}

export async function handleAlertMutationGeneric({
    parametros,
    token,
    setAlert,
    asyncMutation,
    setActualizar,
    actualizar = true,
    confirmacion = true,
    setErrorToken,
    mensajes = {}
}, callback)
{
    try {
        let { msjEspera = {}, msjCorrecto = {} } = mensajes;
        let { title = titleEspera, descripcion = "Algo esta a punto de pasar, pero no se realmente que es...", tipo = success } = msjEspera;
        let {
            title:cTitle = "Todo salio bien",
            descripcion:cDescripcion = "Realmente no estoy seguro de la acción que acabas de realizar, pero al parecer todo ha salido bien...",
            tipo:cTipo = success,
            msjConfirmacion
        } = msjCorrecto;

        alertEspera(setAlert, { title, descripcion, tipo });

        if(asyncMutation) await asyncMutation(parametros, token);

        if(setActualizar) setActualizar(!actualizar);

        if(setAlert && confirmacion) setAlert({
            msjConfirmacion,
            title: cTitle,
            descripcion: NetworkError(cDescripcion),
            tipo: cTipo,
            onConfirm: () => setAlert(null)
        });

        if(setAlert && !confirmacion) setAlert(null);

        if(callback) return callback();

    } catch({message:descripcion}) {

        setErrorGeneric({ setErrorToken, descripcion, setActualizar, actualizar, setAlert }) ;

    }
}

//#endregion
