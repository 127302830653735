import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import { IoCalendarNumberOutline } from "react-icons/io5";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { SiGooglecalendar, SiR } from "react-icons/si";
import TableChart from "@material-ui/icons/TableChart";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import * as ConsultasDominios from '../../../querys/Dominios'
import { getProgramaAnual, tablaProgramaAnual } from '../../../querys/ProgramaAnual';
import { deleteProgramaAnual, addProgramaAnual, updateProgramaAnual, AutorizarProgramaAnual, DuplicadoProgramaAnual, cambioEstadoProgramaAnual } from '../../../mutations/ProgramaAnual';

import { ftObtenerEjerciciosFiltrado } from '../../../helpers/rutinas/metodos/rutinasGenerales';
import moment from 'moment';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary } from '../../../_layout/helpers/colores';

import { rgxNumeros } from '../../../helpers/regexp';
import * as Permisos from '../Permisos';

const { trim } = Validaciones;

function ProgramaAnual() {

    const { token } = useSelector(state => state.login);
    const { Encrypt, Decrypt } = Encriptar;

    let fecha = new Date();
    const anio = fecha.getFullYear();
    let history = useHistory();

    let now = moment();
    let formato = "YYYY-MM-DD";
    let formatoDelmomento = "DD/MM/YYYY";

    const cleanState = {
        programaAnualID: null,
        ejercicio: "",
        fechaGeneracion: now.format(formatoDelmomento),
        fechaAutorizada: "",
        observaciones: "",
        estado: "Activo",
        autorizado: false, 
        correoEnviar: "", 
        dominio: "@atpaltamira.com.mx",
        msjCorreo: ""
    };
    const cleanErrorState = {
        ejercicio: { error: false, helperText: "" },
        fechaGeneracion: { error: false, helperText: "" },
        fechaAutorizada: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        estado: { error: false, helperText: "" }
    }

    let listAnios = [];

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [seleccionables, setSeleccionables] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDuplicado, setOpenDuplicado] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    
    const [openEstado, setOpenEstado] = useState(false);

    let titleAccion = state.programaAnualID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Programa Anual`;

    const programaAnualIDRef = useRef(null);
    const ejercicioRef = useRef(null);
    const fechaGeneracionRef = useRef(null);
    const fechaAutorizadaRef = useRef(null);
    const observacionesRef = useRef(null);
    const estadoRef = useRef(null);

    let acciones = [{
        accionID: [1, 3],
        icono: Edit,
        color: info,
        id: "Modificar",
        descripcion: "Modificar",
        parametros: [{ campo: "programaAnualID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen, setAlert,
            asyncGet: getProgramaAnual,
            formatearResultado: [
                { id: "fechaGeneracion", formato: (data) => moment(data) },
                { id: "fechaAutorizada", formato: (data) => moment(data) }
            ]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "programaAnualID" },
        { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ programaAnualID, folioInterno }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el registro ${folioInterno}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token, setAlert, setActualizar, actualizar,
                parametros: { programaAnualID }, asyncDelete: deleteProgramaAnual
            })
        })
    },
    {
        accionID: [8],
        icono: CheckCircleOutlineIcon,
        color: primary,
        id: "Autorizar",
        descripcion: "Autorizar",
        parametros: [{ campo: "programaAnualID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ programaAnualID, folioInterno }) => ftAutorizarProgramaAnual(programaAnualID, folioInterno, token)
    },
    {
        icono: SiGooglecalendar,
        color: info,
        descripcion: "Calendario",
        parametros: [{ campo: "programaAnualID" }, { campo: "folioInterno" }, { campo: "autorizado" }],
        disabled: { multiseleccion: true },
        onClick: ({ programaAnualID, folioInterno, autorizado }) => {
            setRedireccionarFormulario({ programaAnualID, folioInterno, autorizado });
        }
    }, {
        icono: TableChart,
        color: primary,
        descripcion: "Tareas",
        disabled: { multiseleccion: true },
        parametros: [{ campo: "programaAnualID" }, { campo: "folioInterno" }, { campo: "autorizado" }],
        disabled: { multiseleccion: true },
        onClick: ({ programaAnualID, folioInterno, autorizado }) => {
            let seed = "segumex";
            const datos = `{ "programaAnualID": "${programaAnualID}", "folioInterno": "${folioInterno}","autorizado": "${autorizado}"}`;
            let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
            history.push(`/gen/TareasProgAnual/${url}`);
        }
    }, {
        accionID: [9],
        icono: SiR,
        color: success,
        descripcion: "Renovar",
        parametros: [{ campo: "programaAnualID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen: setOpenDuplicado, setAlert,
            asyncGet: getProgramaAnual,
            formatearResultado: [
                { id: "fechaGeneracion", formato: (data) => moment(data) }
            ]
        })
    },
    {
        id: "Estado",
        icono: AssignmentTurnedInIcon,
        color: info,
        descripcion: "Revisado",
        parametros: [{ campo: "programaAnualID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros, token, setState, setOpen: setOpenEstado, setAlert,
            asyncGet: getProgramaAnual

        })
    },
    ];

    let botones = [
        {
            accionID: [2],
            icono: Add,
            color: success,
            descripcion: "Agregar",
            onClick: () => setOpen(true),
            disabled: { multiseleccion: true }
        }, {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => setActualizar(!actualizar),
            disabled: { multiseleccion: true }
        }];

    const infoTabla = {
        botones,
        acciones,
        loader,
        actualizar,
        id: "programaAnualID",
        color: primary,
        title: "Programa Anual",
        iconTable: <IoCalendarNumberOutline />,
        headers: [
            { variable: "programaAnualID", descripcion: "programaAnualID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno" },
            { variable: "ejercicio", descripcion: "Ejercicio" },
            { variable: "fechaGeneracion", descripcion: "Fecha de Generacion" },
            { variable: "fechaAutorizada", descripcion: "Fecha Autorizada" },
            { variable: "observaciones", descripcion: "Observaciones" },
            { variable: "estado", descripcion: "Estado" },
            { variable: "autorizado", descripcion: "Autorizado"}
        ],
        responsiveTitle: ["FolioInterno"],
        /*filter: [
            { campo: "FolioInterno" }
        ],*/
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }],
        formato: [
            {
                columnas: ["fechaGeneracion", "fechaAutorizada"], tipo: "fecha2", onlyDate: true
            },
            { columnas: ["autorizado"], tipo: "estatus" }
        ],
        selectFilter: [
            {
                title: "Estado",
                campo: "estado", placeholder: "Filtrar por estado",
                retorna: "Int",
                data: [
                    { label: "Mostrar Todos" },
                    {
                        label: "Activo",
                        value: "Activo"
                    },
                    {
                        label: "Cerrado",
                        value: "Cerrado"
                    }
                ],
                grid: { md: 4, lg: 4 },
            }
        ],
        accionesEffect: [
            { botones: ["Estado"], ocultar: (data) => data["estado"].value === "Revisado" ? true : false },
            { botones: ["Modificar"], ocultar: (data) => data["estado"].value === "Revisado" ? true : false },
            { botones: ["Autorizar"], ocultar: (data) => data["estado"].value === "Revisado" ? false : true },
            { botones: ["Autorizar"], ocultar: (data) => data["autorizado"].value === 0 ? false : true }
        ],

    }

    for (let index = anio + 1; index > 2000; index--) {
        listAnios.push({ label: index, value: index });
    };

    const inputs = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "ejercicio",
                    value: state.ejercicio,
                    error: errorState.ejercicio.error,
                    success: state.ejercicio && !errorState.ejercicio.error ? true : undefined,
                    helperText: errorState.ejercicio.helperText,
                    inputRef: ejercicioRef,
                    title: "Ejercicio *",
                    placeholder: "Selecciona el Ejercicio",
                    grid: { md: 4, lg: 4 },
                    tipo: "select",
                    data: listAnios,
                    onChange: ({ target: { value } }) => handleChange(value, "ejercicio"),
                    onKeyDown: () => { return; }
                },
                {
                    disabled: true,
                    id: "fechaGeneracion",
                    value: state.fechaGeneracion,
                    error: errorState.fechaGeneracion.error,
                    success: state.fechaGeneracion && !errorState.fechaGeneracion.error ? true : undefined,
                    helperText: errorState.fechaGeneracion.helperText,
                    inputRef: fechaGeneracionRef,
                    placeholder: "Fecha de Generacion *",
                    tipo: "datetimepicker",
                    grid: { md: 4, lg: 4 },
                    onlyDate: true,
                    onChange: (value) => handleChange(value, "fechaGeneracion"),
                    onKeyDown: (evt) => handleFocus(evt, fechaGeneracionRef)
                },
                /* {
                     disabled,
                     id: "fechaAutorizada",
                     value: state.fechaAutorizada,
                     error: errorState.fechaAutorizada.error,
                     success: state.fechaAutorizada && !errorState.fechaAutorizada.error ? true : undefined,
                     helperText: errorState.fechaAutorizada.helperText,
                     inputRef: fechaAutorizadaRef,
                     placeholder: "Fecha Autorizada *",
                     tipo: "datetimepicker",
                     grid: { md: 4, lg: 4 },
                     onlyDate: true,
                     onChange: (value) => handleChange(value, "fechaAutorizada"),
                     onKeyDown: (evt) => handleFocus(evt, fechaAutorizadaRef)
                 },*/
                {
                    disabled,
                    id: "observaciones",
                    value: state.observaciones,
                    error: errorState.observaciones.error,
                    success: state.observaciones && !errorState.observaciones.error ? true : undefined,
                    helperText: errorState.observaciones.helperText,
                    inputRef: observacionesRef,
                    title: "Observaciones *",
                    placeholder: "Capture las Observaciones",
                    grid: { md: 8, lg: 8 },
                    onChange: ({ target: { value } }) => {
                        handleChange(value.charAt(0).toUpperCase() + value.slice(1), "observaciones")
                    },
                    onKeyDown: () => { return; }
                },
                {
                    disabled,
                    id: "estado",
                    value: state.estado,
                    error: errorState.estado.error,
                    success: state.estado && !errorState.estado.error ? true : undefined,
                    helperText: errorState.estado.helperText,
                    inputRef: estadoRef,
                    title: "Estado *",
                    tipo: "select",
                    grid: { md: 4, lg: 4 },
                    data: [
                        {
                            label: 'Activo',
                            value: 'Activo'
                        },
                        {
                            label: 'Cerrado',
                            value: 'Cerrado'
                        },
                    ],
                    onChange: ({ target: { value } }) => handleChange(value, "estado"),
                    onKeyDown: () => { return; }
                }
            ]
        }
    ];

    const inputsDuplicado = [
        {
            id: "Arriba",
            tipo: "fieldset",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled,
                    id: "ejercicio",
                    value: state.ejercicio,
                    error: errorState.ejercicio.error,
                    success: state.ejercicio && !errorState.ejercicio.error ? true : undefined,
                    helperText: errorState.ejercicio.helperText,
                    inputRef: ejercicioRef,
                    title: "Ejercicio *",
                    placeholder: "Selecciona el Ejercicio",
                    grid: { md: 12, lg: 12 },
                    tipo: "select",
                    data: listAnios,
                    onChange: ({ target: { value } }) => handleChange(value, "ejercicio"),
                    onKeyDown: () => { return; }
                },
            ]
        }
    ]



    const inputsEstado = [
        {
            disabled,
            id: "correoEnviar",
            value: state.correoEnviar,
            title: "Correo *",
            grid:{ md: 7, lg: 7 },
            placeholder: "Capture el correo",
            onChange: ({ target: { value } }) => setState({ ...state, correoEnviar: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "dominio",
            value: state.dominio,
            title: "Dominio *",
            placeholder: "Seleccione el Dominio",
            tipo: "select",
            data: seleccionables.Dominios,
            grid:{ md: 5, lg: 5 },
            onChange: ({ target: { value, name } }) => setState({ ...state, [name]: value }),
            onKeyDown: () => { return; }
        },
        {
            disabled,
            id: "msjCorreo",
            value: state.msjCorreo,
            title: "Mensaje",
            grid: { md: 12, lg: 12 },
            multiline: true,
            placeholder: "Ingrese aquí el mensaje",
            onChange: ({ target: { value } }) => setState({ ...state, msjCorreo: value }),
            onKeyDown: () => { return; }
        },
    ];


    let accionesFormulario = [{
        accionID: state.programaAnualID ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ejercicioRef,
            mensajeCorrecto: `Se ${state.programaAnualID ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const accionesFormularioDuplicado = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseDuplicado,
            handleFocus,
            refFocus: ejercicioRef,
            mensajeCorrecto: `Se ${state.folioInterno} renovo correctamente`,
            asyncCallback: ftGuardarDuplicado,
        }),
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseDuplicado
    }];


    const accionesFormularioCambioEstado = [{
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose: handleCloseEstado,
            handleFocus,
            mensajeCorrecto: `Se cambio el estado a Revisado correctamente`,
            asyncCallback: ftGuardarEstado
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseEstado
    }];



    function setRedireccionarFormulario({ programaAnualID, folioInterno, autorizado }) {

        let seed = "scrumweb";
        const datos = `{ "programaAnualID": "${programaAnualID}", "folioInterno": "${folioInterno}", "autorizado": "${autorizado}"  }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/AgendaActividades/${url}`);
    }


    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState
        });
    }

    function handleValidaciones({ ejercicio, fechaGeneracion, fechaAutorizada, observaciones, estado }) {

        let error;
        let validaciones = [
            {
                condicion: !rgxNumeros.test(ejercicio),
                ref: ejercicioRef, keyError: "ejercicio",
                mensajeError: "Seleccione un ejercicio"
            },
            /* {
                 condicion: !moment(fechaAutorizada).isValid(),
                 ref: fechaAutorizadaRef, keyError: "fechaAutorizada",
                 mensajeError: "La fecha no tiene un formato válido"
             },*/
            {
                condicion: !observaciones || trim(observaciones) === "",
                ref: observacionesRef, keyError: "observaciones",
                mensajeError: "Debe especificar las observaciones"
            }
        ];
        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if (error) return error;

    }

    function ftAutorizarProgramaAnual(programaAnualID, folioInterno, token) {

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Autorizar Programa Anual", descripcion: "¿Deseas autorizar el programa anual?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: { programaAnualID, folioInterno }, asyncMutation: () => ftAutorizar(programaAnualID),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Autorizando programa anual.. ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se autorizó con éxito", descripcion: 'Se autorizó con el éxito el programa anual ' + folioInterno, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    function ftAutorizar(programaAnualID) {
        async function ftAutorizar() {

            let resultado = await AutorizarProgramaAnual({
                input: {
                    programaAnualID: parseInt(programaAnualID)
                }
            }, token);

        }
        return ftAutorizar();
    }


    function ftGuardar() {
        async function ftGuardar() {

            let { programaAnualID,
                ejercicio,
                fechaGeneracion,
                fechaAutorizada,
                observaciones,
                estado, } = state;

            let error = await handleValidaciones({
                ejercicio,
                fechaGeneracion,
                fechaAutorizada,
                observaciones,
                estado
            });

            if (error) return error;

            if (programaAnualID) await updateProgramaAnual({
                input: {
                    programaAnualID,
                    ejercicio,
                    fechaGeneracion: fechaGeneracion.format(formato),
                    fechaAutorizada: null /* fechaAutorizada.format(formato)*/,
                    observaciones: trim(observaciones),
                    estado: trim(estado)
                }
            }, token);
            else await addProgramaAnual({
                input: {
                    ejercicio,
                    fechaGeneracion: now.format(formato),
                    fechaAutorizada: null /*fechaAutorizada.format(formato)*/,
                    observaciones: trim(observaciones),
                    estado: trim(estado)
                }
            }, token);

        }
        return ftGuardar();
    }

    

    function ftGuardarEstado() {
        async function ftGuardarEstado() {

            let {programaAnualID,
                correoEnviar,
                dominio,
                msjCorreo  } = state;


            let validacorreo = correoEnviar.includes('@');

            if (trim(correoEnviar) == "" || validacorreo) throw new Error("Debe especificar un correo valido")
            

              await cambioEstadoProgramaAnual({
                input: {
                    programaAnualID,
                    estado: "Revisado",
                    correoEnviar,
                    dominio,
                    msjCorreo
                }
            }, token);

        }
        return ftGuardarEstado();
    }

    function ftGuardarDuplicado() {
        async function ftGuardarDuplicado() {

            let { programaAnualID,
                ejercicio,
                fechaGeneracion } = state;




            let resultado = await DuplicadoProgramaAnual({
                input: {
                    programaAnualID,
                    ejercicio,
                    fechaGeneracion: fechaGeneracion.format(formato)

                }
            }, token);

        }
        return ftGuardarDuplicado();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseDuplicado() {
        setOpenDuplicado(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleCloseEstado() {
        setOpenEstado(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }


    useEffect(() => {
        async function ftEffect() {
            try {

                let Ejercicios = await ftObtenerEjerciciosFiltrado(token);
                let Dominios = await ConsultasDominios.seleccionDominios({}, token);


                setSeleccionables({ Ejercicios, Dominios });
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [actualizar]);

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
	accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    }else{
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaProgramaAnual}
            token={token}
        />
        <FormularioModal
            open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ejercicioRef}
        />
        <FormularioModal
            open={openDuplicado}
            title={"Renovacion"}
            onClose={handleCloseDuplicado}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsDuplicado}
            acciones={accionesFormularioDuplicado}
        />
        <FormularioModal
            open={openEstado}
            title={"Cambio de Estado a Revisado"}
            onClose={handleCloseEstado}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsEstado}
            acciones={accionesFormularioCambioEstado}
        />
        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);
}

export default ProgramaAnual;
