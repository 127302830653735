import React, { /*useRef,*/ useState, useEffect } from 'react';
import { Encriptar } from '@renedelangel/helpers';

import TagFacesIcon from '@material-ui/icons/TagFaces';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUsuario } from '../../redux/actions/loginAction';

// Custom hooks
import { useErrorToken } from '../../hooks/errores';

// material-ui icons
import Close from "@material-ui/icons/Close";
// import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import BusinessIcon from '@material-ui/icons/Business';
import Person from '@material-ui/icons/Person';

import MenuGeneric from '../../_layout/genericComponents/Menu';
import Preloader from '../../_layout/genericComponents/Preloader';
import SweetAlert from '../../_layout/genericComponents/ModalConfirmacion';
import Footer from '../../_layout/genericComponents/Footer';
import SyncIcon from '@material-ui/icons/Sync';

import FormularioModal from '../../_layout/masterComponents/FormularioModal';

import rutas from './rutas';
import * as Permisos from '../General/Permisos';
// import { useTipoUsuario } from '../../hooks/enums';

// import { getUsuario } from '../../querys/Usuarios/metodos';
// import { updateUsuario } from '../../mutations/Usuarios/metodos';
import { getNewToken, getAllSucursalesUsuario } from '../../querys/Login/metodos';

import { seedBackend } from '../../helpers';
import { rgxNumeros } from '../../helpers/regexp';
import { handleMenu, handleGuardar, handleModificar, handleErrorInputText } from '../../_layout/helpers/handles';
import { info, danger ,primary} from '../../_layout/helpers/colores';

// const { trim } = Validaciones;
const { DescriptarToken } = Encriptar;

function Menu() {

    const dispatch = useDispatch();
    const setTokenRedux = (token) => dispatch(setToken(token));
    const setUsuarioRedux = (usuario) => dispatch(setUsuario(usuario));

    const { usuario, token } = useSelector(state => state.login);
    const { usuario:username, nombreUsuario, empresaID, sucursalID, empresa, perfil, sucursal } = usuario;

    const cleanStateSucursal = { empresaID, sucursalID };
    const cleanErrorState = {
        empresaID: { error: false, helperText: ""  },
        sucursalID: { error: false, helperText: ""  }
    }
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const cleanSeleccionables = { empresas: [], sucursales: [] };
    const cleanDisabledEmpSuc = { empresaID: false, sucursalID: false };

    const [state, setState] = useState(null);
    const [seleccion, setSeleccion] = useState(null);
    const [seleccionables, setSeleccionables] = useState(cleanSeleccionables);
    const [stateSucursal, setStateSucursal] = useState(cleanStateSucursal);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [disabledEmpSuc, setDisabledEmpSuc] = useState(cleanDisabledEmpSuc);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);

    const [errorToken, setErrorToken] = useState(null);
    const tokenError = useErrorToken(errorToken);

    const userMenu = {
        fotoPerfil: <TagFacesIcon />,
        nombre: nombreUsuario
    };

    // const headerSearch = {
    //     placeholder: "Buscar",
    //     onClick: (evt) => console.log(evt)
    // }

    const [openUsuario, setOpenUsuario] = useState(false);

    const inputs = [{
        disabled: disabled ? disabled : disabledEmpSuc.empresaID,
        id: "empresaID",
        value: stateSucursal.empresaID,
        error: errorState.empresaID.error,
        success: stateSucursal.empresaID && !errorState.empresaID.error ? true : undefined,
        helperText: errorState.empresaID.helperText,
        title: "Empresa *",
        placeholder: "Seleccione la empresa ",
        tipo: "autocomplete",
        grid: { md: 6, lg: 6 },
        data: seleccionables.empresas,
        onChange: disabled.empresaID ? () => { return; } : (val) => {
            let value = val ? val.value : null;
            seleccion.forEach(({ empresaID, sucursales}) => {
                if(Number(value) === Number (empresaID)) {
                    sucursales = sucursales.map(({ sucursalID:value, sucursal:label }) => ({ value, label }));
                    setSeleccionables(seleccionables => ({ ...seleccionables, sucursales }));
                }
            });
            !value && setSeleccionables(seleccionables => ({ ...seleccionables, sucursales: [] }))
            setStateSucursal(stateSucursal => ({
                ...stateSucursal,
                empresaID: value, sucursalID: Number(value) === Number (empresaID) ? sucursalID : "",
                sucursalID: value ? stateSucursal.sucursalID : null
            }));
        }
    }, {
        disabled: disabled ? disabled : disabledEmpSuc.sucursalID,
        id: "sucursalID",
        value: stateSucursal.sucursalID,
        error: errorState.sucursalID.error,
        success: stateSucursal.sucursalID && !errorState.sucursalID.error ? true : undefined,
        helperText: errorState.sucursalID.helperText,
        title: "Sucursal *",
        placeholder: "Seleccionar la sucursal",
        tipo: "autocomplete",
        tipo: "autocomplete",
        grid: { md: 6, lg: 6 },
        data: seleccionables.sucursales,
        onChange: (val) => {
            let value = val ? val.value : null;
            setStateSucursal({ ...stateSucursal, sucursalID: value});
        }
    }];

    const accionesFormulario = [{
        loader,
        disabled,
        icono: ReplayIcon,
        color: info,
        descripcion: "Cambiar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            mensajeCorrecto: `El usuario se actualizó correctamente, los cambios se verán reflejados hasta que vuelva a iniciar sesión`,
            asyncCallback: ftGuardar, setErrorToken
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    const actionsHeader = [{
        tipo: "listado",
        icono: Person,
        descripcion: "Usuario",
        open: openUsuario,
        openEvento: () => setOpenUsuario(openUsuario => !openUsuario),
        listado: [/*{
            descripcion: "Editar perfil",
            onClick: () => handleModificar({
                parametros: { usuarioID }, token, setState: setStateUsuario, setOpen, setAlert, asyncGet: getUsuario, setErrorToken
            })
        },{ division: true },*/
        {
            descripcion: "Cerrar sesión",
            onClick: handleLogout
        }]
    },
    {
    
        icono: SyncIcon,
        color: primary,
        descripcion: "Actualizar Sistema",
        onClick: () => {
            localStorage.clear(); 
            window.location.reload();
        },
        disabled: { multiseleccion: true }
    }    

];

    // function handleChange(value, key) { setStateUsuario({ ...stateUsuario, [key]: value }); }

    function handleLogout() {
        setState(null);
        setTimeout(() => setTokenRedux(null), 1000);
    }

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }){
        return handleErrorInputText({ cleanErrorState, condicion, ref, keyError, mensajeError,
            loader: setLoader, disabled: setDisabled, errorState: setErrorState });
    }

    function handleValidaciones({ empresaID, sucursalID }) {

        let error;
        let validaciones = [{
            condicion: !rgxNumeros.test(empresaID), keyError: "empresaID",
            mensajeError: "Para que podamos funcionar bien necesitamos que selecciones una empresa"
        }, {
            condicion: !rgxNumeros.test(sucursalID), keyError: "sucursalID",
            mensajeError: "Para que podamos funcionar bien necesitamos que selecciones una sucursal"
        }];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if(error) return;
            error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
        });

        if(error) return error;

    }

    function ftGuardar() {
        async function ftGuardar() {

            let { empresaID, sucursalID } = stateSucursal;

            let error = await handleValidaciones({ empresaID, sucursalID });

            if(error) return error;

            let token = await getNewToken({ username, sucursalID });

            if(!token) throw new Error("No se pudo obtener el token");

            let { usuario } = await await DescriptarToken({ token, seed: seedBackend });
            setUsuarioRedux(usuario);
            setTokenRedux(token);

        }
        return ftGuardar();
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateSucursal({ ...cleanStateSucursal });
        setErrorState({ ...cleanErrorState });
    }

    function ftEffect() {
        async function effect() {

            let permisosAcceso = await Permisos.GetPermisosUsuario({procesoID: null, accionID: 1}, token);
            let permisosUsuario = {};
            for (let i = 0; i < permisosAcceso.length; i++) {
                let permiso = permisosAcceso[i];
                permisosUsuario[permiso.procesoID] = permiso.valor;
            }
            if (perfil === "ADMINISTRADOR"){
                permisosUsuario[-1] = 1;
            }

            let routes = await handleMenu(rutas.Menu(), permisosUsuario);

            setState(routes);

        } effect();
    }

    useEffect(ftEffect, []);

    const empresaSucursalUsuario = `${nombreUsuario}`;

    return(!tokenError && !state ? <Preloader /> : <>
        <MenuGeneric
            routes={state}
            logoText={username}
            redirect={{
                from: "/",
                to: "/gen/Inicio"
            }}
            userMenu={userMenu}
            logout={handleLogout}
            logoMarca={<img src={"https://www.atpaltamira.com.mx/wp-content/uploads/2016/04/Logo-Cortana-Converted-01.svg"} style={{ height: "51px", width: "100%", minWidth: "150px" }} alt="logotipo" />}
            infoHeader={<span style={{ fontSize: "10px" }}>{empresaSucursalUsuario}</span>}
            // headerSearch={headerSearch}
            actionsHeader={actionsHeader}
            footer={<Footer
                opciones={[
                    { url: "#", nombre: empresaSucursalUsuario }
                ]}
                organizacion={{
                    url: "https://www.softwone.com",
                    nombre: "Softwone International SA de CV"
                }}
            />}
        />
        { alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        /> }
    </>);

}

export default Menu;
