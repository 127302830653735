import React, { useState, useRef, useEffect } from 'react'

import ListadoBusqueda from '../../NestedList'
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Formulario from '../../../_layout/genericComponents/Formulario';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
import Notificaciones from '../../../_layout/genericComponents/Notificaciones';
import Modal from '../../../_layout/genericComponents/Modal';
import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertGeneric, handleAlertMutationGeneric } from '../../../_layout/helpers/handles';
import { urlServidor } from '../../../helpers';


import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionActividadesDeTareas } from '../../../querys/ActividadesDeTareas';
import { seleccionSeguimientoActividades } from '../../../querys/SeguimientoActividades';
import { getAllSiniestrosReclaAseg } from '../../../querys/SiniestrosReclaAseg';
import { getAllSiniestrosReclaTercero } from "../../../querys/ReclamacionesATerceros";
import { getAllArea, seleccionArea } from "../../../querys/Areas"
// Redux
import { useSelector } from 'react-redux';

import moment from 'moment';

import { seleccionRamoSeguroNA } from '../../../querys/RamoSeguro';

import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import { info, danger, success, primary } from "../../../_layout/helpers/colores";
// material-ui cons
import Close from "@material-ui/icons/Close";
import PrintIcon from '@material-ui/icons/Print';
import SearchIcon from '@material-ui/icons/Search';
import BusinessIcon from '@material-ui/icons/Business';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { RiFileExcel2Fill } from "react-icons/ri";

import { getPolizasSeguro, getAllPolizasSeguro, tablaPolizasSeguro, seleccionPolizasSeguro, seleccionableSubPolizasSeguro, getSubPolizasSeguro, getAllSubPolizasSeguro } from '../../../querys/PolizasSeguro';

function ReportesInventarios() {
    const { token, usuario } = useSelector(state => state.login);

    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    const formato = "YYYY-MM-DD";
    const curDate = moment();
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = fecha.getMonth() + 1;
    let listAnios = [];

    const clearFiltrosAccidenteLaboral = {
        empresaAccidenteID: null,
        empresaEmpleadoAccidenteID: null,
        eppCompleto: false,
        iluminacionDeficiente: false,
        condicionMeteorologicaAdversa: false,
        ordenLimpieza: false,
        espaciosReducidos: false,
        instalacionMalEstado: false,
        fallasInstruccionProceso: false,
        cortesHerramientas: false,
        objetosCaida: false,
        caidas: false,
        contactoQuimicos: false,
        atrapamientoExtremidades: false,
        lesionesErgonomicas: false,
        colision: false,
        quemaduras: false,
        cabeza: false,
        cara: false,
        cuello: false,
        espalda: false,
        pecho: false,
        brazos: false,
        manos: false,
        piernas: false,
        pies: false,

    }

    const clearFiltrosReporteDanios = {
        areaDanioID: null,
        empresaDanioID: null,
        ocasionadoTercero: null,
        ocasionadoNosotros: null,
        ocasionadoA: null,
        tipoMercancia: null,
        estatusUnidad: null,
        notificoAseguradora: false,
        acordoReparacionNosotros: false,
        inspeccionTerceros: false,
        cotizacion: false,
        anexosReportesIntExt: false,
    }

    let OpcionesDanios = [{ value: "A propiedad interna", label: "A propiedad interna" },
    { value: "A la carga", label: "A la carga" },
    { value: "A un tercero (Externo)", label: "A un tercero (Externo)" },
    { value: "N/A", label: "N/A" }
    ];

    let OpcionesDanioA = [
        { value: "null", label: "Filtrar Todos" },
        { value: "Carga General", label: "Carga General" },
    { value: "Contenedor (es)", label: "Contenedor (es)" },
    { value: "Automóvil (es)", label: "Automóvil (es)" },
    { value: "Maquinaria y/o Equipo Interno", label: "Maquinaria y/o Equipo Interno" },
    { value: "Herramientas de Trabajo", label: "Herramientas de Trabajo" },
    { value: "Instalaciones", label: "Instalaciones" },
    { value: "Propiedad Externa", label: "Propiedad Externa" }
    ];

    let OpcionesMercancia = [{ value: "Importacion", label: "Importacion" },
    { value: "Exportación", label: "Exportación" },
    { value: "Vacios", label: "Vacios" },
    { value: "Transbordo Lleno", label: "Transbordo Lleno" },
    { value: "Transbordo Vacío", label: "Transbordo Vacío" },
    { value: "NA", label: "NA" }
    ];

    const [stateFiltrosAccidenteLaboral, setStateFiltrosAccidenteLaboral] = useState(clearFiltrosAccidenteLaboral)

    const [stateFiltrosReporteDanios, setStateFiltrosReporteDanios] = useState(clearFiltrosReporteDanios);


    const cleanState = {
        fechaIni: curDate,
        serieInicial: null,
        serieFinal: null,
        folioInicial: null,
        folioFinal: null,
        cliproID: null,
        sucursalID: null,
        cCostosID: null,
        grupoID: null,
        subgrupoID: null,
        marcaID: null,
        tipoID: null,
        departamentoID: null,
        almacenID: null,
        codigoProducto: null,
        ubicacionID: null,
        loteID: null,
        folioInventarioFisicoID: null,
        divisaID: null,
        estadoID: null,
        ordenadoPor: null, //Pendiente
        tipoPrecio: null,
        cargoAdicional: 0.00,
        incluirExistenciaTeorica: false,
        noIncluirProdExistCero: false,
        producSinIva: false,
        mostrarPartidas: false,
        mostrarPreciosIva: false,
        agruparPorLote: false,
        productoID: null,
        modulo: null,
        submodulo: null,
        nombreTipo: "",
        nombreReporte: "",
        nombreEmpresa: "",
        formatoReporte: "PDF",
        fechaInicio: null,
        fechaFin: null,
        ejercicio: anio,
        mes: mes,
        estatusRecorrido: '',
        actividadID: "",
        tareaDescripcion: "",
        tipoReclamo: 1,
        seguimientoID: "null",
        seguimientoDescripcion: "",
        tareaDescripcion: "",
        ramoSeguroID: "null",
        soloVigentes: false,
        danioOcosionadoA:"",
        polizasSeguroID: null,
        subPolizaID: null
    }

    const cleanErrorState = {
        fechaIni: { error: false, helperText: "" },
        serieInicial: { error: false, helperText: "" },
        serieFinal: { error: false, helperText: "" },
        folioInicial: { error: false, helperText: "" },
        folioFinal: { error: false, helperText: "" },
        cliproID: { error: false, helperText: "" },
        sucursalID: { error: false, helperText: "" },
        cCostosID: { error: false, helperText: "" },
        grupoID: { error: false, helperText: "" },
        subgrupoID: { error: false, helperText: "" },
        marcaID: { error: false, helperText: "" },
        tipoID: { error: false, helperText: "" },
        departamentoID: { error: false, helperText: "" },
        almacenID: { error: false, helperText: "" },
        codigoProducto: { error: false, helperText: "" },
        ubicacionID: { error: false, helperText: "" },
        loteID: { error: false, helperText: "" },
        folioInventarioFisicoID: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        estadoID: { error: false, helperText: "" },
        ordenadoPor: { error: false, helperText: "" },
        tipoPrecio: { error: false, helperText: "" },
        cargoAdicional: { error: false, helperText: "" },
        incluirExistenciaTeorica: { error: false, helperText: "" },
        noIncluirProdExistCero: { error: false, helperText: "" },
        producSinIva: { error: false, helperText: "" },
        mostrarPartidas: { error: false, helperText: "" },
        mostrarPreciosIva: { error: false, helperText: "" },
        agruparPorLote: { error: false, helperText: "" },
        modulo: { error: false, helperText: "" },
        submodulo: { error: false, helperText: "" },
        nombreReporte: { error: false, helperText: "" },
        tipoReporte: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        fechaInicio: { error: false, helperText: "" },
        fechaFin: { error: false, helperText: "" },
        estatusRecorrido: { error: false, helperText: "" },
        actividadID: { error: false, helperText: "" },
        seguimientoID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        soloVigentes: { error: false, helperText: "" },
        polizasSeguroID: { error: false, helperText: "" },
        subPolizaID: { error: false, helperText: "" }
    }

    const cleanStateBloqueo = {
        fechaIni: true,
        serieInicial: true,
        serieFinal: true,
        folioInicial: true,
        folioFinal: true,
        cliproID: true,
        sucursalID: true,
        cCostosID: true,
        grupoID: true,
        subgrupoID: true,
        marcaID: true,
        tipoID: true,
        departamentoID: true,
        almacenID: true,
        codigoProducto: true,
        ubicacionID: true,
        loteID: true,
        folioInventarioFisicoID: true,
        divisaID: true,
        estadoID: true,
        ordenadoPor: true,
        tipoPrecio: true,
        cargoAdicional: true,
        incluirExistenciaTeorica: true,
        noIncluirProdExistCero: true,
        producSinIva: true,
        mostrarPartidas: true,
        mostrarPreciosIva: true,
        agruparPorLote: true,
        productoID: true,
        modulo: true,
        submodulo: true,
        nombreTipo: true,
        nombreReporte: true,
    }

    const [stateBloqueo, setStateBloqueo] = useState(cleanStateBloqueo);

    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [state, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [seleccionables, setSeleccionables] = useState({});
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [openProductos, setOpenProductos] = useState(false);

    let [datosPDF, setdatosPDf] = useState({});

    const pendientes = [{
        label: "NOTA: SE DEBE CARGAR EL LISTADO CORRESPONDIENTE AL REPORTE SELECCIONADO",
        value: 0
    }];

    const ordenListadoProducto = [{
        label: "CÓDIGO",
        value: 1
    },
    {
        label: "DESCRIPCIÓN",
        value: 2
    }
    ]

    const listFormatoReporte = [{
        label: 'PDF',
        value: 'PDF'
    },
    {
        label: 'Excel',
        value: 'Excel'
    },
    ];

    const ListReclamos = [
        { label: 'RECLAMOS A TERCEROS', value: 1 },
        { label: 'RECLAMOS A ASEGURADORAS', value: 2 }
    ]

    for (let index = anio + 1; index > 2000; index--) {
        listAnios.push({ label: index, value: index });
    };
    listAnios.unshift({ label: 'Todos', value: "null" });

    const listMeses = [
        { label: 'Todos', value: "null" },
        { label: 'Enero', value: 1 },
        { label: 'Febrero', value: 2 },
        { label: 'Marzo', value: 3 },
        { label: 'Abril', value: 4 },
        { label: 'Mayo', value: 5 },
        { label: 'Junio', value: 6 },
        { label: 'Julio', value: 7 },
        { label: 'Agosto', value: 8 },
        { label: 'Septiembre', value: 9 },
        { label: 'Octubre', value: 10 },
        { label: 'Noviembre', value: 11 },
        { label: 'Diciembre', value: 12 }
    ]

    const reportesNoAplicanFiltroFechas = [
        'reporteEmpresas',
        'reporteUsuarios',
        'reporteUbicaciones',
        'reporteAreas',
        'reporteDepartamentos',
        'reporteAgentesSeguro',
        'reporteAseguradoras',
        'reporteAfianzadoras',
        'reporteRamoSeguro',
        'reporteDivisas',
        'reporteActivosAsegurado',
        'estadisticasAccidenteLaboral',
        'estadisticasReporteDanio',
    ]

    const reportesAplicanFiltrosFechas = [
        'reporteProgramaAnual',
        'reportePresupuestoAnual',
        'reporteProgramaAnualSeguimientos',
        'reportePresupuestoAnual',
        'reporteRecorridos',
        'reporteCalendarioReclamos',
        'reporteSolSegAtendidas',
        'reporteRamoSeguroTotal',
        'reporteTotalSolTipoSeg',
        'reporteTotalSolTipoSegPorEmpresa',
        'reporteCantidadSolAtenPorEmpresa',
        'reporteSolSegFianTiempo',
        'reportePagosRealXEmpresaConcepto',
        'reportePolizaSeguroFianza',
        'reportePolizaSeg',
        'reportePolizaSegFianza',
        'reporteActivosAseguradosPolizas',
        'reporteControlPagos',
        'reporteDanios',
        'reporteDaniosServicioCliente',
        'reporteDaniosGerGeneral',
        'reporteAccidenteLaboral',
        'reporteReclamosAseguradoras',
        'reporteReclamosTerceros',
        'reporteSolSegFian',
        'reporteSolSegXRepAutos',
        'reporteSolSegXRepEquipoElectrico',
        'reporteSolSegXRepMultipleEmpreRot',
        'reporteSolSegXRepEquipoCon',
        'reporteSolSegXRepFlotilla',
        'reporteSolSegXRepFianzas',
        'reporteSolSegXRepMulEmpre',
        'reporteSolSegXRepObra',
        'reporteSolSegXRepRespCivil',
        'reporteSolSegXRepRMPT',
        'reporteSolSegXRepRBE',
        'reporteSolSegXRepTC',
        'reporteSolSegXRepTM',
        'reporteSolSegXRepVida',
        'reporteSolSegXRepGastosMed'
    ]

    const modulos = [
        { label: "Catálogos", value: 1 },
        { label: "Procedimientos", value: 2 },
        { label: "Procesos de Seguros", value: 3 },
        { label: "Procesos de Siniestros", value: 4 },
        { label: "Solicitudes de Seguro", value: 5 },
        { label: "Estadisticas", value: 6 }
    ];

    const subModulos = [
        { label: "Empresas", value: "reporteEmpresas", tipo: 1 },
        { label: "Usuarios", value: "reporteUsuarios", tipo: 1 },
        { label: "Ubicaciones", value: "reporteUbicaciones", tipo: 1 },
        { label: "Areas", value: "reporteAreas", tipo: 1 },
        { label: "Departamentos", value: "reporteDepartamentos", tipo: 1 },
        { label: "Agentes de seguro", value: "reporteAgentesSeguro", tipo: 1 },
        { label: "Aseguradoras", value: "reporteAseguradoras", tipo: 1 },
        { label: "Afianzadoras", value: "reporteAfianzadoras", tipo: 1 },
        { label: "Ramos de seguro", value: "reporteRamoSeguro", tipo: 1 },
        { label: "Divisas", value: "reporteDivisas", tipo: 1 },
        { label: "Activos Asegurar", value: "reporteActivosAsegurado", tipo: 1 },
        { label: "Programa Anual", value: "reporteProgramaAnual", tipo: 1 },
        { label: "Programa Anual (Seguimientos)", value: "reporteProgramaAnualSeguimientos", tipo: 1 },
        { label: "Reclamos (Seguimientos)", value: "reporteCalendarioReclamos", tipo: 1 },
        { label: "Recorridos de instalaciones", value: "reporteRecorridos", tipo: 1 },


        { label: "Presupuesto Anual", value: "reportePresupuestoAnual", tipo: 2 },
        { label: "Total de Solicitudes Atendidas por Tipo de Solicitud", value: "reporteSolSegAtendidas", tipo: 2 },
        { label: "Total de Solicitudes por Tipo de Seguro", value: "reporteRamoSeguroTotal", tipo: 2 },
        { label: "Total de Solicitudes Atendidas de Empresa por Tipo de Seguro de Manera Global", value: "reporteTotalSolTipoSeg", tipo: 2 },
        { label: "Total de Solicitudes Atendidas por Tipo de Seguro por Empresa", value: "reporteTotalSolTipoSegPorEmpresa", tipo: 2 },
        { label: "Cantidad de Sol. Atendidas por Empresa", value: "reporteCantidadSolAtenPorEmpresa", tipo: 2 },
        { label: "Promedio de Tiempo Resolucion de Tramites", value: "reporteSolSegFianTiempo", tipo: 2 },
        { label: "Pagos Realizados por un Periodo en Especifico por Empresa", value: "reportePagosRealXEmpresaConcepto", tipo: 2 },



/*         { label: "Poliza de Seguro", value: "reportePolizaSeg", tipo: 3 },
        { label: "Poliza de Seg. Fianzas", value: "reportePolizaSegFianza", tipo: 3 }, */
        { label: "Poliza de Seguro / Fianzas", value: "reportePolizaSeguroFianza", tipo: 3 },
        // { label: "Activos Asegurados en las Polizas", value: "reporteActivosAseguradosPolizas", tipo: 3 },
        { label: "Control de Pagos", value: "reporteControlPagos", tipo: 3 },



        { label: "Reporte de Daños", value: "reporteDanios", tipo: 4 },
        { label: "Reporte de Daños Servicio a Clientes", value: "reporteDaniosServicioCliente", tipo: 4 },
        { label: "Reporte de Daños Gerencia General", value: "reporteDaniosGerGeneral", tipo: 4 },
        { label: "Reporte de Accidente Laboral", value: "reporteAccidenteLaboral", tipo: 4 },
        { label: "Reclamos a Aseguradoras", value: "reporteReclamosAseguradoras", tipo: 4 },
        { label: "Reclamos a Terceros", value: "reporteReclamosTerceros", tipo: 4 },
        { label: "Control de pagos (siniestros)", value: "reporteControlPagosSiniestros", tipo: 4 },

        { label: "Solicitudes de Seguro", value: "reporteSolSegFian", tipo: 5 },
        { label: "Solicitudes de Seguro Automóviles Residentes", value: "reporteSolSegXRepAutos", tipo: 5 },
        { label: "Solicitudes de Seguro Equipo Electrónico", value: "reporteSolSegXRepEquipoElectrico", tipo: 5 },
        { label: "Solicitudes de Seguro Múltiple Empresarial- Rotura", value: "reporteSolSegXRepMultipleEmpreRot", tipo: 5 },
        { label: "Solicitudes de Seguro Equipo de Contratistas y Maq. Pesada", value: "reporteSolSegXRepEquipoCon", tipo: 5 },
        { label: "Solicitudes de Seguro Flotilla de Automóviles(Camiones Carga General)", value: "reporteSolSegXRepFlotilla", tipo: 5 },
        { label: "Solicitudes de Seguro de Fianzas", value: "reporteSolSegXRepFianzas", tipo: 5 },
        { label: "Solicitudes de Seguro Múltiple Empresarial", value: "reporteSolSegXRepMulEmpre", tipo: 5 },
        { label: "Solicitudes de Seguro Obra Civil", value: "reporteSolSegXRepObra", tipo: 5 },
        { label: "Solicitudes de Seguro Responsabilidad Civil, Arrendamiento", value: "reporteSolSegXRepRespCivil", tipo: 5 },
        { label: "Solicitudes de Seguro Montaje", value: "reporteSolSegXRepMontaje", tipo: 5 },
        { label: "Solicitudes de Seguro Riesgos Múltiples para Puertos y Terminales (RC Marinas)", value: "reporteSolSegXRepRMPT", tipo: 5 },
        { label: "Solicitudes de Seguro Riesgos Múltiples para Puertos y Terminales (Ro-Ro)", value: "reporteSolSegXRepRMPTRo", tipo: 5 },
        { label: "Solicitudes de Seguro Robo a Bienes Empresariales", value: "reporteSolSegXRepRBE", tipo: 5 },
        { label: "Solicitudes de Seguro Transporte de Contenedores", value: "reporteSolSegXRepTC", tipo: 5 },
        { label: "Solicitudes de Seguro Transportes de Mercancía", value: "reporteSolSegXRepTM", tipo: 5 },
        { label: "Solicitudes de Seguro de Vida", value: "reporteSolSegXRepVida", tipo: 5 },
        { label: "Solicitudes de Seguro Gastos Médicos Mayores", value: "reporteSolSegXRepGastosMed", tipo: 5 },

        {
            label: "Estadísticas de Accidentes Laborales", value: "estadisticasAccidenteLaboral", tipo: 6
        },
        {
            label: "Estadísticas de Reportes de daño", value: "estadisticasReporteDanios", tipo: 6
        }
    ];

    const inputs = [{
        id: "IDTRF",
        tipo: "fieldset",
        leyenda: `Criterios de Selección de Reporte: ${state.modulo !== 0 ? state.nombreTipo : ""} - ${state.submodulo ? state.submodulo : ""} `,
        inputs: [{
            disabled,
            id: "modulo",
            value: state.modulo,
            grid: { md: 6, lg: 6 },
            error: errorState.modulo.error,
            success: state.modulo && !errorState.modulo.error ? true : undefined,
            helperText: errorState.modulo.helperText,
            title: "Tipo Reporte",
            placeholder: "Seleccionar tipo de reporte",
            tipo: "autocomplete",
            data: modulos,
            onChange: (target) => {
                target && setState({ ...state, modulo: Number(target.value), nombreTipo: target.label, submodulo: null, nombreReporte: "", tipoReporte: null, actividadID: null });
                setStateBloqueo(cleanStateBloqueo);
            }
        },
        {
            disabled: state.modulo ? false : true,
            id: "tipoReporte",
            value: state.tipoReporte,
            grid: { md: 6, lg: 6 },
            error: errorState.tipoReporte.error,
            success: state.tipoReporte && !errorState.tipoReporte.error ? true : undefined,
            helperText: errorState.tipoReporte.helperText,
            title: "Reporte",
            placeholder: "Seleccionar reporte",
            tipo: "autocomplete",
            data: seleccionables.resultSubmodulos,
            onChange: (target) => { target && ftOnchageInputsBloqueo(target.value, target.label, "tipoReporte"); clearFilterExtras(state.tipoReporte) }
        },
        {
            disabled: (state.tipoReporte && state.tipoReporte !== 'estadisticasAccidenteLaboral' && state.tipoReporte !== 'estadisticasReporteDanios') ? false : true,
            id: "empresaID",
            value: state.empresaID,
            grid: { md: 3, lg: 3 },
            error: errorState.empresaID.error,
            success: state.empresaID && !errorState.empresaID.error ? true : undefined,
            helperText: errorState.empresaID.helperText,
            title: "Empresa",
            placeholder: "Seleccionar Empresa",
            tipo: "autocomplete",
            data: seleccionables.Empresa,
            onChange: (target) => { 
                target && ftOnchageInputsBloqueo(target.value, target.label, "empresaID") 

            }
        },
        {
            disabled: (state.tipoReporte === 'reporteCalendarioReclamos') ? false : true,
            hidden: state.tipoReporte !== 'reporteCalendarioReclamos' ? true : false,
            id: "tipoReclamo",
            value: state.tipoReclamo,
            grid: { md: 3, lg: 3 },
            title: "Reclamos",
            tipo: "select",
            data: ListReclamos,
            onChange: ({ target: { value } }) => setState({ ...state, tipoReclamo: value, seguimientoID: "null", seguimientoDescripcion: "" }),
        },
        {
            disabled: (state.tipoReporte === 'reporteProgramaAnualSeguimientos') ? false : true,
            hidden: state.tipoReporte === 'reporteCalendarioReclamos' || state.tipoReporte == 'reportePolizaSeguroFianza' ? true : false,
            id: "actividadID",
            value: state.actividadID,
            grid: { md: 4, lg: 4 },
            error: errorState.actividadID.error,
            success: state.actividadID && !errorState.actividadID.error ? true : undefined,
            helperText: errorState.actividadID.helperText,
            title: "Actividad Tarea",
            placeholder: "Seleccionar Actividad Tarea",
            tipo: "autocomplete",
            data: seleccionables.actividadesTareas,
            onChange: (target) => { target && setState({ ...state, actividadID: target.value, tareaDescripcion: target.label }) }
        },
        {
            disabled,
            hidden: state.tipoReporte !== 'reporteDanios',
            id: "danioOcosionadoA",
            value: state.danioOcosionadoA,
            grid: { md: 4, lg: 4 },
            title: "Daño ocasionado a",
            placeholder: "Daño ocasionado a",
            tipo: "autocomplete",
            data: OpcionesDanioA,
            onChange: (target) => { target && setState({ ...state, danioOcosionadoA: target.value }) }
        },
        {
            hidden: state.tipoReporte === 'reporteCalendarioReclamos' ? false : true,
            id: "seguimientoID",
            value: state.seguimientoID,
            grid: { md: 4, lg: 4 },
            error: errorState.seguimientoID.error,
            success: state.seguimientoID && !errorState.seguimientoID.error ? true : undefined,
            helperText: errorState.seguimientoID.helperText,
            title: "Seguimientos",
            placeholder: "Seleccionar Seguimiento",
            tipo: "autocomplete",
            data: seleccionables.eventos,
            onChange: (target) => { target && setState({ ...state, seguimientoID: target.value, seguimientoDescripcion: target.label }) }
        },
        {
            hidden: state.tipoReporte === 'reporteProgramaAnual' || state.tipoReporte === 'reporteProgramaAnualSeguimientos' || state.tipoReporte == 'reportePolizaSeguroFianza' ? false : true,
            id: "ramoSeguroID",
            value: state.ramoSeguroID,
            grid: { md: 3, lg: 3 },
            error: errorState.ramoSeguroID.error,
            success: state.ramoSeguroID && !errorState.ramoSeguroID.error ? true : undefined,
            helperText: errorState.ramoSeguroID.helperText,
            title: "Ramo de Seguro",
            placeholder: "Seleccionar Ramo de Seguro",
            tipo: "autocomplete",
            data: seleccionables.RamoSeguro,
            onChange: (target) => { target && setState({ ...state, ramoSeguroID: target.value, actividadID: "null" }) }
        },
        {
            disabled: state.tipoReporte ? false : true,
            hidden: state.submodulo !== 'Recorridos de instalaciones' ? true : false,
            id: "estatusRecorrido",
            value: state.estatusRecorrido,
            grid: { md: 3, lg: 30 },
            error: errorState.estatusRecorrido.error,
            success: state.estatusRecorrido && !errorState.estatusRecorrido.error ? true : undefined,
            helperText: errorState.estatusRecorrido.helperText,
            title: "Estatus recorrido",
            placeholder: "Seleccionar estatus",
            tipo: "autocomplete",
            data: [{ label: "EN PROCESO", value: "EN PROCESO" },
            { label: "TERMINADO", value: "TERMINADO" }],
            onChange: (target) => { target && handleChange(target.value, "estatusRecorrido") }
        },
        {
            disabled: (state.tipoReporte !== 'estadisticasAccidenteLaboral' && state.tipoReporte !== 'estadisticasReporteDanios') ? false : true,
            id: "formatoReporte",
            value: state.formatoReporte,
            grid: { md: 2, lg: 2 },
            title: "Formato de Reporte",
            tipo: "select",
            data: listFormatoReporte,
            onChange: ({ target: { value } }) => setState({ ...state, formatoReporte: value }),
        },{
            hidden: (state.tipoReporte !== 'reportePolizaSeguroFianza'),
            id: "soloVigentes",
            value: state.soloVigentes,
            grid: { md: 2, lg: 2 },
            title: "Solo Vigentes",
            tipo: "select",
            data: [{label: 'NO', value: false}, {label: 'SI', value: true}],
            onChange: ({ target: { value } }) => setState({ ...state, soloVigentes: value }),
        },
        ]
    }, {

        hidden: !(state.tipoReporte) || !reportesAplicanFiltrosFechas.includes(state.tipoReporte) || state.soloVigentes,
        tipo: "fieldset",
        leyenda: "Fechas",
        disabled: state.tipoReporte ? false : true,
        inputs: [{
            hidden: state.tipoReporte == 'reporteProgramaAnualSeguimientos' || state.tipoReporte == 'reportePolizaSeguroFianza' || state.tipoReporte == 'reportePolizaSegFianza',
            disabled,
            id: "ejercicio",
            value: state.ejercicio,
            title: "Ejercicio",
            placeholder: "Seleccionar Ejercicio",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: listAnios,
            onChange: (value) => value && setState({ ...state, ejercicio: value.value, actividadID: null, tareaDescripcion: "" }),
        },
        {
            hidden: state.tipoReporte == 'reporteProgramaAnualSeguimientos' || state.tipoReporte == 'reportePresupuestoAnual' || state.tipoReporte == 'reportePolizaSeguroFianza' || state.tipoReporte == 'reportePolizaSegFianza' || state.tipoReporte == 'reporteActivosAseguradosPolizas',
            disabled,
            id: "mes",
            value: state.mes,
            title: "Mes",
            placeholder: "Seleccionar Mes",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: listMeses,
            onChange: (value) => value && setState({ ...state, mes: value.value }),
        },
        {
            hidden: state.tipoReporte == 'reporteProgramaAnualSeguimientos' || state.tipoReporte == 'reportePresupuestoAnual' || state.tipoReporte == 'reporteControlPagos', // EMRR 08May2024
            disabled,
            id: "fechaInicio",
            value: state.fechaInicio,
            grid: { md: 6, lg: 6 },
            error: errorState.fechaInicio.error,
            success: state.fechaInicio && !errorState.fechaInicio.error ? true : undefined,
            helperText: errorState.fechaInicio.helperText,
            title: "Fecha Inicial",
            placeholder: "Fecha Inicial",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => typeof (value) === 'object' ? handleChange(value.format(formato), "fechaInicio") : handleChange(null, "fechaInicio")
        },
        {
            hidden: state.tipoReporte == 'reporteProgramaAnualSeguimientos' || state.tipoReporte == 'reportePresupuestoAnual' || state.tipoReporte == 'reporteControlPagos',
            disabled,
            id: "fechaFin",
            value: state.fechaFin,
            grid: { md: 6, lg: 6 },
            error: errorState.fechaFin.error,
            success: state.fechaFin && !errorState.fechaFin.error ? true : undefined,
            helperText: errorState.fechaFin.helperText,
            title: "Fecha Final",
            placeholder: "Fecha Final",
            tipo: "datetimepicker",
            onlyDate: true,
            onChange: (value) => typeof (value) === 'object' ? handleChange(value.format(formato), "fechaFin") : handleChange(null, "fechaFin")
        },
        ]
    },
    {
        hidden: (state.tipoReporte !== 'estadisticasAccidenteLaboral' && state.tipoReporte !== 'estadisticasReporteDanios'),
        id: "inputsAccidenteLaboral",
        tipo: "fieldset",
        leyenda: "",
        inputs: filtrosOpcionales()
    },
    {
        id: "seleccionablesPolizas",
        tipo: "fieldset",
        leyenda: `Pólizas y Sub Pólizas de seguro`,
        hidden: state.submodulo == 'Activos Asegurados en las Polizas'? false : true,
        inputs: [{
            disabled,
            id: "polizasSeguroID",
            value: state.polizasSeguroID,
            grid: { md: 6, lg: 6 },
            error: errorState.polizasSeguroID.error,
            success: state.polizasSeguroID && !errorState.polizasSeguroID.error ? true : undefined,
            helperText: errorState.polizasSeguroID.helperText,
            title: "Póliza de seguro",
            placeholder: "Selecciona la póliza",
            tipo: "autocomplete",
            data: seleccionables.polizasSeguro,
            onChange: (target) => {
                target && setState({ ...state, polizasSeguroID: Number(target.value), subPolizaID: null});
            }
        },
        {
            disabled: state.polizasSeguroID == null? true : false,
            id: "subPolizaID",
            value: state.subPolizaID,
            grid: { md: 6, lg: 6 },
            error: errorState.subPolizaID.error,
            success: state.subPolizaID && !errorState.subPolizaID.error ? true : undefined,
            helperText: errorState.subPolizaID.helperText,
            title: "Sub póliza",
            placeholder: "Seleccione la sub póliza",
            tipo: "autocomplete",
            data: seleccionables.subPolizasSeguro,
            onChange: (target) => {
                target && setState({ ...state, subPolizaID: Number(target.value)});
            }
        }
    ]
    }
    ];


    useEffect(() => {
         function cleanVigentes(){
            setState({...state, soloVigentes: false,
                fechaInicio: null,
                fechaFin: null,
                ejercicio: anio,
                mes: mes
            })
        }
        cleanVigentes();
    }, [state.tipoReporte])

    function filtrosOpcionales() {


        switch (state.tipoReporte) {
            case 'estadisticasAccidenteLaboral':
                return ([
                    {
                        id: "filtrosAccidenteLaboral",
                        tipo: "fieldset",
                        leyenda: "Filtros Accidente Laboral",
                        grid: { md: 12, lg: 12, sm: 12 },
                        inputs: [
                            {
                                id: "generalesAccidenteLaboral",
                                tipo: "fieldset",
                                leyenda: "Generales",
                                grid: { md: 12, lg: 12, sm: 12 },
                                inputs: [
                                    {
                                        disabled: false,
                                        id: "empresaAccidenteID",
                                        value: stateFiltrosAccidenteLaboral.empresaAccidenteID,
                                        grid: { md: 6, lg: 6 },
                                        title: "Empresa en la que ocurrio el accidente",
                                        placeholder: "Seleccionar Empresa",
                                        tipo: "autocomplete",
                                        data: seleccionables.dataEmpresa,
                                        onChange: (target) => { setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, empresaAccidenteID: target.value }) }
                                    }, {
                                        disabled: false,
                                        id: "empresaEmpleadoAccidenteID",
                                        value: stateFiltrosAccidenteLaboral.empresaEmpleadoAccidenteID,
                                        grid: { md: 6, lg: 6 },
                                        title: "Empresa a la que pertenece el empleado accidentado",
                                        placeholder: "Seleccionar Empresa",
                                        tipo: "autocomplete",
                                        data: seleccionables.dataEmpresa,
                                        onChange: (target) => { setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, empresaEmpleadoAccidenteID: target.value }) }
                                    }]
                            },
                            {
                                id: "factoresTrabajo",
                                tipo: "fieldset",
                                leyenda: "Factores de Trabajo",
                                grid: { md: 4, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled,
                                        id: "iluminacionDeficiente",
                                        value: stateFiltrosAccidenteLaboral.iluminacionDeficiente,
                                        checked: stateFiltrosAccidenteLaboral.iluminacionDeficiente,
                                        title: "Iluminación Deficiente",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, iluminacionDeficiente: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "condicionMeteorologicaAdversa",
                                        value: stateFiltrosAccidenteLaboral.condicionMeteorologicaAdversa,
                                        checked: stateFiltrosAccidenteLaboral.condicionMeteorologicaAdversa,
                                        title: "Condición meteorológica adversas",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, condicionMeteorologicaAdversa: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "ordenLimpieza",
                                        value: stateFiltrosAccidenteLaboral.ordenLimpieza,
                                        checked: stateFiltrosAccidenteLaboral.ordenLimpieza,
                                        title: "Falta de orden y limpieza",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, ordenLimpieza: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "espaciosReducidos",
                                        value: stateFiltrosAccidenteLaboral.espaciosReducidos,
                                        checked: stateFiltrosAccidenteLaboral.espaciosReducidos,
                                        title: "Espacios Reducidos",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, espaciosReducidos: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "instalacionMalEstado",
                                        value: stateFiltrosAccidenteLaboral.instalacionMalEstado,
                                        checked: stateFiltrosAccidenteLaboral.instalacionMalEstado,
                                        title: "Instalación en mal estado",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, instalacionMalEstado: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "fallasInstruccionProceso",
                                        value: stateFiltrosAccidenteLaboral.fallasInstruccionProceso,
                                        checked: stateFiltrosAccidenteLaboral.fallasInstruccionProceso,
                                        title: "Fallas en la instrucción o Proceso",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, fallasInstruccionProceso: e.target.checked })
                                        }
                                    },
                                ]
                            },
                            {
                                id: "agentesDeRiesgo",
                                tipo: "fieldset",
                                leyenda: "Agentes de Riesgo",
                                grid: { md: 4, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled,
                                        id: "cortesHerramientas",
                                        value: stateFiltrosAccidenteLaboral.cortesHerramientas,
                                        checked: stateFiltrosAccidenteLaboral.cortesHerramientas,
                                        title: "Cortes por Herramientas",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, cortesHerramientas: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "objetosCaida",
                                        value: stateFiltrosAccidenteLaboral.objetosCaida,
                                        checked: stateFiltrosAccidenteLaboral.objetosCaida,
                                        title: "Golpes por objetos en caída",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, objetosCaida: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "caidas",
                                        value: stateFiltrosAccidenteLaboral.caidas,
                                        checked: stateFiltrosAccidenteLaboral.caidas,
                                        title: "Caídas al mismo y diferente nivel",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, caidas: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "contactoQuimicos",
                                        value: stateFiltrosAccidenteLaboral.contactoQuimicos,
                                        checked: stateFiltrosAccidenteLaboral.contactoQuimicos,
                                        title: "Contacto con sustancias químicas",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, contactoQuimicos: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "atrapamientoExtremidades",
                                        value: stateFiltrosAccidenteLaboral.atrapamientoExtremidades,
                                        checked: stateFiltrosAccidenteLaboral.atrapamientoExtremidades,
                                        title: "Atrapamiento de Extremidades (superior e Inferior)",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, atrapamientoExtremidades: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "lesionesErgonomicas",
                                        value: stateFiltrosAccidenteLaboral.lesionesErgonomicas,
                                        checked: stateFiltrosAccidenteLaboral.lesionesErgonomicas,
                                        title: "Lesiones Ergonómicas (espalda, cuello, hombros )",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, lesionesErgonomicas: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "colision",
                                        value: stateFiltrosAccidenteLaboral.colision,
                                        checked: stateFiltrosAccidenteLaboral.colision,
                                        title: "Colisión /choque o volcadura",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, colision: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "quemaduras",
                                        value: stateFiltrosAccidenteLaboral.quemaduras,
                                        checked: stateFiltrosAccidenteLaboral.quemaduras,
                                        title: "Quemaduras",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, quemaduras: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "eppCompleto",
                                        value: stateFiltrosAccidenteLaboral.eppCompleto,
                                        checked: stateFiltrosAccidenteLaboral.eppCompleto,
                                        title: "EPP completo",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, eppCompleto: e.target.checked })
                                        }
                                    }
                                ]
                            },
                            {
                                id: "partesAfectadas",
                                tipo: "fieldset",
                                leyenda: "Partes Afectadas",
                                grid: { md: 4, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled,
                                        id: "cabeza",
                                        value: stateFiltrosAccidenteLaboral.cabeza,
                                        checked: stateFiltrosAccidenteLaboral.cabeza,
                                        title: "Cabeza",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, cabeza: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "cara",
                                        value: stateFiltrosAccidenteLaboral.cara,
                                        checked: stateFiltrosAccidenteLaboral.cara,
                                        title: "Cara",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, cara: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "cuello",
                                        value: stateFiltrosAccidenteLaboral.cuello,
                                        checked: stateFiltrosAccidenteLaboral.cuello,
                                        title: "Cuello",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, cuello: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "espalda",
                                        value: stateFiltrosAccidenteLaboral.espalda,
                                        checked: stateFiltrosAccidenteLaboral.espalda,
                                        title: "Espalda",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, espalda: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "pecho",
                                        value: stateFiltrosAccidenteLaboral.pecho,
                                        checked: stateFiltrosAccidenteLaboral.pecho,
                                        title: "Pecho",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, pecho: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "brazos",
                                        value: stateFiltrosAccidenteLaboral.brazos,
                                        checked: stateFiltrosAccidenteLaboral.brazos,
                                        title: "Brazos",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, brazos: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "manos",
                                        value: stateFiltrosAccidenteLaboral.manos,
                                        checked: stateFiltrosAccidenteLaboral.manos,
                                        title: "Manos",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, manos: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "piernas",
                                        value: stateFiltrosAccidenteLaboral.piernas,
                                        checked: stateFiltrosAccidenteLaboral.piernas,
                                        title: "Piernas",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, piernas: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "pies",
                                        value: stateFiltrosAccidenteLaboral.pies,
                                        checked: stateFiltrosAccidenteLaboral.pies,
                                        title: "Pies",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosAccidenteLaboral({ ...stateFiltrosAccidenteLaboral, pies: e.target.checked })
                                        }
                                    },
                                ]
                            }]
                    }
                ])

            case 'estadisticasReporteDanios':
                return ([
                    {
                        id: "filtrosRepDanios",
                        tipo: "fieldset",
                        leyenda: "Filtros Reportes de Daños",
                        grid: { md: 12, lg: 12, sm: 12 },
                        inputs: [
                            {
                                id: "generalesRepDanios",
                                tipo: "fieldset",
                                leyenda: "Generales",
                                grid: { md: 12, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled: false,
                                        id: "areaDanioID",
                                        value: stateFiltrosReporteDanios.areaDanioID,
                                        grid: { md: 12, lg: 12 },
                                        title: "Área en la que ocurrio el accidente",
                                        placeholder: "Seleccionar el área",
                                        tipo: "autocomplete",
                                        data: seleccionables.areas,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, areaDanioID: target.value }) }
                                    },
                                    {
                                        disabled: false,
                                        id: "empresaDanioID",
                                        value: stateFiltrosReporteDanios.empresaDanioID,
                                        grid: { md: 12, lg: 12 },
                                        title: "Empresa en la que ocurrio el accidente",
                                        placeholder: "Seleccionar la empresa",
                                        tipo: "autocomplete",
                                        data: seleccionables.dataEmpresa,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, empresaDanioID: target.value }) }
                                    },
                                    {
                                        disabled: false,
                                        id: "ocasionadoTercero",
                                        value: stateFiltrosReporteDanios.ocasionadoTercero,
                                        grid: { md: 12, lg: 12 },
                                        title: "Daño ocasionado por tercero",
                                        placeholder: "Daño por terceros",
                                        tipo: "autocomplete",
                                        data: OpcionesDanios,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, ocasionadoTercero: target.value }) }
                                    },
                                    {
                                        disabled: false,
                                        id: "ocasionadoNosotros",
                                        value: stateFiltrosReporteDanios.ocasionadoNosotros,
                                        grid: { md: 12, lg: 12 },
                                        title: "Daño ocasionado por nosotros",
                                        placeholder: "Daño por nosotros",
                                        tipo: "autocomplete",
                                        data: OpcionesDanios,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, ocasionadoNosotros: target.value }) }
                                    }]
                            },
                            {
                                id: "informacionEventDanio",
                                tipo: "fieldset",
                                leyenda: "Información del evento",
                                grid: { md: 12, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled: false,
                                        id: "ocasionadoA",
                                        value: stateFiltrosReporteDanios.ocasionadoA,
                                        grid: { md: 12, lg: 12 },
                                        title: "Daño ocasionado a",
                                        placeholder: "Seleccione sobre que se realizo el daño",
                                        tipo: "autocomplete",
                                        data: OpcionesDanioA,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, ocasionadoA: target.value }) }
                                    }]
                            },
                            {
                                id: "infComplementaria",
                                tipo: "fieldset",
                                leyenda: "Información complementaria",
                                grid: { md: 12, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled: false,
                                        id: "tipoMercancia",
                                        value: stateFiltrosReporteDanios.tipoMercancia,
                                        grid: { md: 12, lg: 12 },
                                        title: "Tipo de mercancia",
                                        placeholder: "Seleccione el tipo de mercancia",
                                        tipo: "autocomplete",
                                        data: OpcionesMercancia,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, tipoMercancia: target.value }) }
                                    },
                                    {
                                        disabled: false,
                                        id: "estatusUnidad",
                                        value: stateFiltrosReporteDanios.estatusUnidad,
                                        grid: { md: 12, lg: 12 },
                                        title: "Estatus de la unidad",
                                        placeholder: "Seleccione el estatus de la unidad",
                                        tipo: "autocomplete",
                                        data: OpcionesMercancia,
                                        onChange: (target) => { setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, estatusUnidad: target.value }) }
                                    }]
                            }
                            , {
                                id: "extras",
                                tipo: "fieldset",
                                leyenda: "Filtros Extras",
                                grid: { md: 12, lg: 4, sm: 12 },
                                inputs: [
                                    {
                                        disabled,
                                        id: "notificoAseguradora",
                                        value: stateFiltrosReporteDanios.notificoAseguradora,
                                        checked: stateFiltrosReporteDanios.notificoAseguradora,
                                        title: "¿FUE NECESARIO NOTIFICAR A NUESTRA ASEGURADORA?",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, notificoAseguradora: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "acordoReparacionNosotros",
                                        value: stateFiltrosReporteDanios.acordoReparacionNosotros,
                                        checked: stateFiltrosReporteDanios.acordoReparacionNosotros,
                                        title: "¿SE ACORDÓ REPARACIÓN DEL DAÑO POR PARTE DE NOSOTROS?",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, acordoReparacionNosotros: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "inspeccionTerceros",
                                        value: stateFiltrosReporteDanios.inspeccionTerceros,
                                        checked: stateFiltrosReporteDanios.inspeccionTerceros,
                                        title: "¿SE SOLICITÓ LA INSPECCIÓN DE UNA TERCERA PARTE?",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, inspeccionTerceros: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "cotizacion",
                                        value: stateFiltrosReporteDanios.cotizacion,
                                        checked: stateFiltrosReporteDanios.cotizacion,
                                        title: "¿SE CUENTA CON COTIZACIÓN DEL DAÑO?",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, cotizacion: e.target.checked })
                                        }
                                    },
                                    {
                                        disabled,
                                        id: "anexosReportesIntExt",
                                        value: stateFiltrosReporteDanios.anexosReportesIntExt,
                                        checked: stateFiltrosReporteDanios.anexosReportesIntExt,
                                        title: "¿SE ANEXAN REPORTES INTERNOS/EXTERNOS?",
                                        tipo: "switch",
                                        grid: { md: 12, lg: 12, sm: 12 },
                                        onChange: (e) => {
                                            setStateFiltrosReporteDanios({ ...stateFiltrosReporteDanios, anexosReportesIntExt: e.target.checked })
                                        }
                                    }]
                            }]
                    }

                ])

            default:
                return ([])
        }

    }

    const accionesFormulario = [{
        loader,
        disabled,
        icono: PrintIcon,
        color: primary,
        descripcion: "Generar Reporte",
        onClick: () => state.submodulo ?
            handleAlertGeneric({
                setAlert,
                onCancel: () => setAlert(null),
                mensaje: {
                    title: `Generar Reporte`,
                    descripcion: `¿Deseas generar y descargar el reporte de ${state.nombreReporte}?`,
                    tipo: "warning",
                    msjConfirmacion: "Aceptar",
                    msjCancelacion: "Cancelar"
                },

                onConfirm: () => handleAlertMutationGeneric({
                    token,
                    setAlert,
                    setActualizar,
                    actualizar,
                    parametros: { empresaID: state.empresaID },
                    asyncMutation: GenerarReporte,
                    mensajes: {
                        msjEspera: {
                            title: "Espere...",
                            descripcion: `Generando el reporte de ${state.nombreReporte} `,
                            tipo: success
                        },
                        msjCorrecto: {
                            title: "Se generó con éxito",
                            descripcion: ` Se descargó el reporte de ${state.nombreReporte}`,
                            msjConfirmacion: "Aceptar"
                        }
                    }
                })
            }) : setAlert({
                descripcion: "Debe seleccionar un reporte",
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            })
    },
        /* {
            disabled,
            icono: Close,
            color: danger,
            descripcion: "Cancelar",
            onClick: handleClose
        } */
    ];

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setErrorState({ ...cleanErrorState });
    }

    function handldeCloseProductos() {
        setOpenProductos(false);
    }

    function handleChange(value, key) { setState({ ...state, [key]: value }); }

    function handleBuscarPorCodigoChange(datoBuscar) {

        seleccionables.productos.forEach(({ label, value }) => {
            if (label === datoBuscar) {
                //obtenerDatosProductoPorCodigo({productoID:value}) ;
            }
        })
    }



    //Función para bloquear los inputs dependiendo del reporte seleccionado
    function ftOnchageInputsBloqueo(value, label, id) {
        setState({ ...state, submodulo: label, nombreReporte: label, tipoReporte: value, actividadID: null });
        if (id === "empresaID") {
            setState({ ...state, empresaID: value, nombreEmpresa: label, actividadID: null, tareaDescripcion: "", polizasSeguroID: null, subPolizaID: null });
        }
        switch (value) {
            case "reporteInvListadoPrecios":
                setState(state => ({ ...state, ordenadoPor: 1 }))
                setStateBloqueo({ ...stateBloqueo, cliproID: false, grupoID: false, subgrupoID: false, marcaID: false, tipoID: false, departamentoID: false, almacenID: false, productoID: false, ubicacionID: false, estadoID: false, ordenadoPor: false, cargoAdicional: false, mostrarPreciosIva: false })
                break;
            default:
                setStateBloqueo(cleanStateBloqueo);
        }
    }
    //funcion para generar el PDF con sus parámetros correspondientes
    async function GenerarPDF({ ramoSeguroID, nombreReporte, tipoReporte, reporte, cliproID, grupoID, subgrupoID, marcaID, tipoID, departamentoID, almacenID, productoID, ubicacionID, estadoID, ordenadoPor, cargoAdicional, mostrarPreciosIva, empresaID, nombreEmpresa, formatoReporte, fechaInicio, fechaFin, ejercicio, mes, tipoReclamo, seguimientoID, seguimientoDescripcion, soloVigentes, polizasSeguroID, subPolizaID }) {
        let myHeaders = new Headers();
        empresaID = empresaID ? empresaID : null;

        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");
        let formdata = new FormData();
        formdata.append("nombreReporte", `${reporte}`);
        formdata.append("empresaID", empresaID);
        //formdata.append("sucursalID", sucursalID);
        formdata.append("tipoReporte", tipoReporte);
        //parámetros para listado precios
        formdata.append("ramoSeguroID", ramoSeguroID);
        formdata.append("cliproID", cliproID);
        formdata.append("grupoID", grupoID);
        formdata.append("subgrupoID", subgrupoID);
        formdata.append("marcaID", marcaID);
        formdata.append("tipoID", tipoID);
        formdata.append("departamentoID", departamentoID);
        formdata.append("almacenID", almacenID);
        formdata.append("productoID", productoID);
        formdata.append("ubicacion", ubicacionID);
        formdata.append("estado", estadoID);
        formdata.append("orden", ordenadoPor);
        formdata.append("cargoAdicional", cargoAdicional);
        formdata.append("checkPreciosIva", mostrarPreciosIva);
        formdata.append("nombreEmpresa", nombreEmpresa);
        formdata.append("formatoReporte", formatoReporte);
        formdata.append("fechaInicio", fechaInicio);
        formdata.append("fechaFin", fechaFin);
        formdata.append("ejercicio", ejercicio);
        formdata.append("mes", mes);
        formdata.append("estatusRecorrido", state.estatusRecorrido);
        formdata.append("actividadID", state.actividadID);
        formdata.append("tareaDescripcion", state.tareaDescripcion);
        formdata.append("tipoReclamo", tipoReclamo);
        formdata.append("seguimientoID", seguimientoID);
        formdata.append("seguimientoDescripcion", seguimientoDescripcion);
        formdata.append("soloVigentes", soloVigentes);
        formdata.append("danioOcasioA", state.danioOcosionadoA);
        formdata.append("polizasSeguroID", polizasSeguroID);
        formdata.append("subPolizaID", subPolizaID);
        
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/service/reportes/reportesGenCatalogos`, requestOptions)
            .then(response => {
                
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${state.formatoReporte === "PDF" ? ".pdf" : ".xlsx"}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });

    }

    async function estadisticasPDF(tipoReporte) {
        let datosCompletos;
        switch (tipoReporte) {
            case 'estadisticasAccidenteLaboral':
                 datosCompletos = [state, stateFiltrosAccidenteLaboral, 'estadisticasAccidenteLaboral', clearFiltrosAccidenteLaboral];
                break;
            case 'estadisticasReporteDanios':
                datosCompletos = [state, stateFiltrosReporteDanios, 'estadisticasReporteDanios', clearFiltrosReporteDanios];
                break;
            default:
                break;
        }

        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");
        let formdata = new FormData();
        formdata.append('data', JSON.stringify(datosCompletos));
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        await fetch(`${urlServidor}/service/reportes/RepEstadisticos`, requestOptions)
            .then(response => {
                
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${tipoReporte == 'estadisticasAccidenteLaboral'? `Reporte estadistico de accidentes laborales ${moment().format('L')}` : `Reporte estadistico de Reportes de daños ${moment().format('L')}`}.pdf`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    async function GenerarReporte() {
        let { tipoReporte, nombreReporte, cliproID, grupoID, subgrupoID, marcaID, tipoID, departamentoID, almacenID, productoID, ubicacionID, estadoID, ordenadoPor, cargoAdicional, mostrarPreciosIva, empresaID, nombreEmpresa, formatoReporte, fechaInicio, fechaFin, ejercicio, mes
            , tipoReclamo, seguimientoID, seguimientoDescripcion, ramoSeguroID, soloVigentes, polizasSeguroID, subPolizaID } = state;
        let reporte = "reporteGenCatalogos";

        switch (state.tipoReporte) {
            case 'estadisticasAccidenteLaboral':
                await estadisticasPDF('estadisticasAccidenteLaboral');
                break;
            case 'estadisticasReporteDanios':
                await estadisticasPDF('estadisticasReporteDanios');
                break;

            default:
                await GenerarPDF({ ramoSeguroID, tipoReporte, nombreReporte, reporte, cliproID, grupoID, subgrupoID, marcaID, tipoID, departamentoID, almacenID, productoID, ubicacionID, estadoID, ordenadoPor, cargoAdicional, mostrarPreciosIva, empresaID, nombreEmpresa, formatoReporte, fechaInicio, fechaFin, ejercicio, mes, tipoReclamo, seguimientoID, seguimientoDescripcion, soloVigentes, polizasSeguroID, subPolizaID });
                break;
        }


        // break;
        // }
    }


    function clearFilterExtras(parametro) {
        if (parametro == 'estadisticasAccidenteLaboral') {
            setStateFiltrosReporteDanios(clearFiltrosReporteDanios);
        }
        else if (parametro == 'estadisticasReporteDanios') {
            setStateFiltrosAccidenteLaboral(clearFiltrosAccidenteLaboral);
        }
        else {
            setStateFiltrosReporteDanios(clearFiltrosReporteDanios);
            setStateFiltrosAccidenteLaboral(clearFiltrosAccidenteLaboral);
        }
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                let resultado = subModulos.filter(({ tipo }) => tipo === Number(state.modulo));
                let Empresa = await seleccionEmpresa({ filtro: { activa: 1 } }, token);
                Empresa.unshift({ label: 'Todas las Empresas', value: "null" })
                let actividadesTareas = await seleccionActividadesDeTareas({ filtro: { ramoSeguroID: state.ramoSeguroID === 'null' ? null : state.ramoSeguroID, ejercicio: state.ejercicio === 'null' ? null : state.ejercicio, empresaID: state.empresaID === 'null' ? null : state.empresaID } }, token);
                actividadesTareas.length > 0 && actividadesTareas.unshift({ label: 'Todas las Actividades', value: "null" })
                let areas = await seleccionArea({ filtro: {} }, token);
                let RamoSeguro = await seleccionRamoSeguroNA({ filtro: {} }, token);
                let aux = Empresa;
                let dataEmpresa = /* aux? aux.splice(1) : */ aux;

                let polizasSeguro = await seleccionPolizasSeguro({ filtro: { empresaID: state.empresaID !== null ? parseInt(state.empresaID) : -1, tipoPoliza: "PRINCIPAL", fechaInicio: state.fechaInicio, fechaFinReporte: state.fechaFin, ejercicioReporte: state.ejercicio !== 'null' ? state.ejercicio : null/*, mes */ } }, token);

                let subPolizasSeguro = await seleccionableSubPolizasSeguro({ filtro: { polizasSeguroID: state.polizasSeguroID === null ? null : Number(state.polizasSeguroID) } }, token);
                subPolizasSeguro.unshift({label:"No Aplica", value:"0"})


                setSeleccionables(seleccionables => ({
                    ...seleccionables,
                    resultSubmodulos: resultado,
                    Empresa,
                    actividadesTareas,
                    RamoSeguro,
                    dataEmpresa,
                    areas,
                    polizasSeguro,
                    subPolizasSeguro
                }))
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(
                        message
                    )}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, [state.modulo, state.ejercicio, state.empresaID, state.ramoSeguroID, state.polizasSeguroID]);


    useEffect(() => {
        async function ftEffect() {
            try {

                if (state.tipoReclamo === 1) {
                    let aux = await getAllSiniestrosReclaTercero({ filtro: { ejercicio: state.ejercicio === "null" ? null : state.ejercicio } }, token);

                    if (!Array.isArray(aux)) throw new Error("No se encontraron registros");

                    let eventos = aux.map(({ siniesReclTercerosID: value, title: label }) => ({ value, label }));
                    eventos.length > 0 && eventos.unshift({ label: 'Todas los Seguimientos', value: "null" })


                    setSeleccionables((seleccionables) => ({ ...seleccionables, eventos }));
                } else {
                    let aux = await getAllSiniestrosReclaAseg({ filtro: { ejercicio: state.ejercicio === "null" ? null : state.ejercicio } }, token);

                    if (!Array.isArray(aux)) throw new Error("No se encontraron registros");

                    let eventos = aux.map(({ siniesReclAsegID: value, title: label }) => ({ value, label }));
                    eventos.length > 0 && eventos.unshift({ label: 'Todas los Seguimientos', value: "null" })

                    setSeleccionables((seleccionables) => ({ ...seleccionables, eventos }));
                }

            } catch ({ message }) {
                setAlert({
                    descripcion: `Error: ${message}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [state.tipoReclamo, state.ejercicio]);

    let acciones = [
        {
            icono: PictureAsPdfIcon,
            color: primary,
            descripcion: "Generar PDF",
            parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
            disabled: { multiseleccion: true },
            onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero)
        },
        {
            icono: RiFileExcel2Fill,
            color: success,
            descripcion: "Generar Excel",
            parametros: [{ campo: "polizasSeguroID" }, { campo: "numero" }],
            disabled: { multiseleccion: true },
            onClick: ({ polizasSeguroID, numero }) => AuxDescargar(polizasSeguroID, numero, 'excel')
        }
        ];

    const infoTabla = {
        botones: [],
        acciones,
        actualizar,
        id: "polizasSeguroID",
        color: primary,
        title: "Pólizas de Seguro",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "numero", descripcion: "Número" },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "polizaOrigenID", descripcion: "polizaOrigenID", hide: true },
            { variable: "nombre", descripcion: "Empresa" },
            { variable: "ramoSeguroID", descripcion: "ramoSeguroID", hide: true },
            { variable: "ramoSeguro", descripcion: "Ramo seguro" },
            { variable: "aseguradoraID", descripcion: "aseguradoraID", hide: true },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "numpolEndoso", descripcion: "Póliza Endoso" },
            { variable: "divisa", descripcion: "Divisa" },
            { variable: "sumaAseg", descripcion: "Suma Asegurada" },
            { variable: "primaNeta", descripcion: "Prima Neta" },
            { variable: "fpPromedio", descripcion: "F.P. Promedio" },
            { variable: "exclusiones", descripcion: "Exclusiones", hide: true },
            { variable: "fechaSolCot", descripcion: "Fecha Sol. Cot." },
            { variable: "vigenciaIni", descripcion: "Vigencia Inicial" },
            { variable: "vigenciaHasta", descripcion: "Vigencia Hasta" },
            { variable: "renovacion", descripcion: "Renovar al Termino" },
            { variable: "divisaID", descripcion: "divisaID", hide: true },
            { variable: "folioInternoControlPago", descripcion: "Control de Pago" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación", hide: false },
            { variable: "beneficiarioPreferente", descripcion: " Beneficiario Preferente", hide: false },
            { variable: "bienesAmparadosEncabezado", descripcion: "Bienes Amparados", hide: false },
            { variable: "polizaRenovada", descripcion: "Póliza Renovada", hide: false },
            { variable: "folioPolizaRenovada", descripcion: "Folio Póliza Renovada", hide: false },
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true }




        ],
        responsiveTitle: ["folioInterno", "numpolEndoso", 'ramoSeguro'],
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }, { columnas: [0, 4, 6, 7, 8], alineacion: "right" }],
        formato: [

            {
                columnas: ["fechaSolCot", "vigenciaIni", "vigenciaHasta", "fechaModificacion"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["sumaAseg", "primaNeta"], tipo: "moneda",
            },
            {
                columnas: ["fpPromedio"], tipo: "porcentaje", simbolo: "%"

            },
            {
                columnas: ["renovacion", "polizaRenovada"], tipo: "estatus"

            }
        ]
    }

    

    function AuxDescargar(polizasSeguroID, stateFolioInterno, formatoReporte = 'PDF', empresaID, ramoSeguro) {

        setdatosPDf({ "polizasSeguroID": polizasSeguroID, "stateFolioInterno": stateFolioInterno, formatoReporte, empresaID, ramoSeguro });
        datosPDF = { "polizasSeguroID": polizasSeguroID, "stateFolioInterno": stateFolioInterno, formatoReporte, empresaID, ramoSeguro };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte", descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de poliza de seguro?", tipo: "warning", msjConfirmacion: "Aceptar", msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token, setAlert, setActualizar, actualizar,
                parametros: {}, asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...", descripcion: "Generando el formato de impresión del reporte de poliza de seguro ", tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito", descripcion: 'Se descargó el formato de impresión del reporte de poliza de seguro ' + stateFolioInterno, msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("polizasSeguroID", datosPDF.polizasSeguroID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);
        formdata.append("empresaID", datosPDF.empresaID);
        formdata.append("ramoSeguro", datosPDF.ramoSeguro);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };
        let nombreReporte = 'ReportePolizasSeguro' + datosPDF.stateFolioInterno

        await fetch(`${urlServidor}/service/reportes/ReportePolizasSeguro`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`);// mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    useEffect(()=>{
        setActualizar(!actualizar)
    }, [state.empresaID, state.fechaInicio, state.fechaFin, state.ejercicio])

    return (<>

        <Formulario header={true}
            inputs={inputs}
            acciones={accionesFormulario}
        />

        {/* {
            state.submodulo == 'Activos Asegurados en las Polizas'?

        <Tabla
        infoTabla={infoTabla}
        asyncData={tablaPolizasSeguro}
        token={token}
        parametrosFijos={{ tipoPoliza: 'PRINCIPAL', fianza: false, empresaID: state.empresaID !== 'null' ? state.empresaID : null, fechaIni: state.fechaInicio,
        fechaFin: state.fechaFin, usarFechas: true, ejercicio: state.ejercicio !== 'null' ? state.ejercicio : null  }}
        />

        :
        <>
        </>
        } */}
         {
            alert && < SweetAlert
                title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={
                    () => setAlert(null)
                }
            />} </>
    );
}

export default ReportesInventarios;
