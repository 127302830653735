import { FetchAPI } from '@renedelangel/helpers';
import { urlServidor } from '../../helpers';

export const GET_SOLICITUDSEGURO = `
    query get($filtro: InputGetSolSegFian){
        getSolSegFian(filtro: $filtro)
        {
            solSegFianID
            empresaID
            usuarioID
            correo
            nombreUsuario
            solicitud
            ramoSeguro
            ramoSeguroID
            fechaSolicitud
            solicitante
            puesto
            departamentoID
            observaciones
            nomAutoriza
            estatus
            folio
            folioInterno
            departamento
            horasInvertidas
            polizasSeguroID
            activosProcesados
            folioControlPago
            controlPagoID
            numPolEndoso
            ejercicio
            mes
            fechaInicio
            fechaFin
            subRamoID
            subPolizaID
            subRamo
            seleccionTipoPoliza
            codigoMoneda
        }
    }
`;

export const GET_ALL_SOLICITUDSEGURO = `
    query filtroGetAll($filtro: InputGetSolSegFian){
        getAllSolSegFian(filtro: $filtro)
        {
            solSegFianID
            empresaID
            usuarioID
            correo
            nombreUsuario
            solicitud
            ramoSeguroID
            fechaSolicitud
            solicitante
            puesto
            departamentoID
            observaciones
            nomAutoriza
            estatus
            folio
            folioInterno
            departamento
            horasInvertidas
            polizasSeguroID
            activosProcesados
            folioControlPago
            controlPagoID
            numPolEndoso
            ejercicio
            mes
            fechaInicio
            fechaFin
            subRamoID
            subPolizaID
            subRamo
            seleccionTipoPoliza
            codigoMoneda
        }
    }
`;

export const GET_REPORTE_TIPO_SOLSEGFIAN = `
    query getReporteSolSeGFianTipoSoli($filtro: InputReporteSolSegFianTipo){
        getReporteSolSegFian(filtro: $filtro)
        {
            solSegFianID
            tipo
            autorizadaCantidad
            enProcesoCantidad
            revisadaCantidad
            total
        }
    }
`;

export const GET_REPORTE_SOLSEGFIAN_TIEMPO = `
    query getReporteSolSegFianTiempo($filtro: InputReporteSolSegFianTiempo) {
        getReporteSolSegFianTiempo(filtro: $filtro){
            total
            ramoSeguro
            color
            totalHoras
            diasInvertidos
        }
    }
`;

export const TABLA_SOLICITUDSEGURO = `
    query filtro($filtro: InputFiltroSolSegFian){
        filterSolSegFian(filtro: $filtro){
            listado{
                solSegFianID
                empresaID
                usuarioID
                correo
                nombreUsuario
                solicitud
                ramoSeguroID
                fechaSolicitud
                solicitante
                puesto
                departamentoID
                observaciones
                nomAutoriza
                empresa
                ramoSeguro
                estatus
                folio
                folioInterno
                departamento
                horasInvertidas,
                polizasSeguroID
                fechaModificacion
                activosProcesados
                folioControlPago
                estatusControlPago
                controlPagoID
                numPolEndoso
                numeroPoliza
                estatusInvitado
                ramoSeguroID
                subRamoID
                subPolizaID
                subRamo
                seleccionTipoPoliza
                codigoMoneda
            }
            registros
            paginas
        }
    }
`;

export const TABLA_SOLICITUDSEGURO_DETALLE = `
    query filtro($filtro: InputFiltroSolSegFianDetalle){
        filterSolSegFianDetalle(filtro: $filtro){
            listado{
                empresaID
                solicitud
                ramoSeguroID
                ramoSeguro
                departamentoID
                departamento
                partidaID
                solSegFianID
                activoAsegID
                descripcion
                tipo
                folio
                folioInterno

            }
            registros
            paginas
        }
    }
`;

export const getSolicitudSeguro = async (filtro, token) => {
    try {
        let { getSolSegFian } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_SOLICITUDSEGURO
        });

        return getSolSegFian;
    } catch ({ message }) { throw new Error(message); }
}

export const getAllSolicitudSeguro = async (variables, token) => {
    try {
        let { getAllSolSegFian } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_SOLICITUDSEGURO
        });
        return getAllSolSegFian;
    } catch ({ message }) { throw new Error(message); }
}

export const getReporteSolSegFian = async (variables, token) => {
    try {
        let { getReporteSolSegFian } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_REPORTE_TIPO_SOLSEGFIAN
        });
        return getReporteSolSegFian;
    } catch ({ message }) { throw new Error(message); }
}

export const getReporteSolSegFianTiempo = async (variables, token) => {
    try {
        let { getReporteSolSegFianTiempo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_REPORTE_SOLSEGFIAN_TIEMPO
        });
        return getReporteSolSegFianTiempo;
    } catch ({ message }) { throw new Error(message); }
}

export const seleccionSolicitudSeguro = async (variables, token) => {
    try {
        let allSolicitudSeguro = await getAllSolicitudSeguro(variables, token);
        if (!Array.isArray(allSolicitudSeguro)) throw new Error("No se encontraron registros");

        return allSolicitudSeguro.map(({
            solSegFianID: value, solicitud: label
        }) => ({ value, label: label.toUpperCase() }));
    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaSolicitudSeguro = async (variable, token) => {
    try {
        let { solSegFianID, empresaID, ramoSeguroID, solicitud, fechaIni, fechaFin, estatus, estatusInvitado, pagina, limite, subRamoID } = variable.filtro;
        let filtro = {
            solSegFianID: solSegFianID === null ? null : Number(solSegFianID),
            empresaID: empresaID === null ? null : Number(empresaID),
            ramoSeguroID: ramoSeguroID === null ? null : Number(ramoSeguroID),
            solicitud, fechaIni, fechaFin, estatus, estatusInvitado, pagina, limite,
            subRamoID : subRamoID === null ? null : Number(subRamoID)
        };
        let variables = { filtro };
        let { filterSolSegFian } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SOLICITUDSEGURO
        });

        return filterSolSegFian;

    } catch ({ message }) { throw new Error(message); }
}


export const tablaSolicitudSeguroDetalle = async (variable, token) => {
    try {
        let { solSegFianID, empresaID, ramoSeguroID, solicitud, pagina, limite } = variable.filtro;
        let filtro = {
            solSegFianID: solSegFianID === null ? null : Number(solSegFianID),
            empresaID: empresaID === null ? null : Number(empresaID),
            ramoSeguroID: ramoSeguroID === null ? null : Number(ramoSeguroID),
            solicitud, pagina, limite
        };
        let variables = { filtro };
        let { filterSolSegFianDetalle } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_SOLICITUDSEGURO_DETALLE
        });
        return filterSolSegFianDetalle;

    } catch ({ message }) { throw new Error(message); }
}
