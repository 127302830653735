import React, { useState, useRef, useEffect } from 'react';
import { Validaciones, Encriptar } from '@renedelangel/helpers';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
// Redux
import { useSelector } from 'react-redux';
// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';
import Modal from '../../../_layout/genericComponents/Modal';
// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';
//storage
import { actualizaArchivo, eliminaArchivo } from "../../../helpers/serverStorage";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { RiFileExcel2Fill } from "react-icons/ri";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { NetworkError } from "../../../_layout/genericComponents/Metodos";

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, handleAlertMutationGeneric, handleAlertGeneric } from '../../../_layout/helpers/handles';
import { info, danger, success, primary, black } from '../../../_layout/helpers/colores';
import * as Consultas from './Consultas.js';
import * as ConsultasEmpresas from '../Empresas/Consultas.js';
import * as ConsultasReporteDanios from '../ReporteDanios/Consultas.js';
import moment from 'moment';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import { urlServidor } from '../../../helpers';
import { rgxdeInt2 } from 'helpers/regexp';
import { seleccionArea } from '../../../querys/Areas';
import { seleccionUbicaciones } from '../../../querys/Ubicaciones'
import * as Permisos from '../Permisos';
import Documentacion from '../Documentacion';

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;

function ReporteDaniosGerencia() {
    const { token, usuario } = useSelector(stDatos => stDatos.login);
    const { Encrypt } = Encriptar;
    let anioActual = new Date().getFullYear();
    let cFechaFiltroInicial = anioActual + "0101";
    let cFechaFiltroFinal = anioActual + "1231";
    let Empresas = [];
    let remover = false;
    const cleanState = {};
    const cleanErrorState = {}
    const cleanNotificaciones = { mensaje: "", color: null, open: false };
    const [stEmpresas, setEmpresas] = useState(Empresas)
    const [stReporteDanios, setReporteDanios] = useState(Empresas)

    let [datosPDF, setdatosPDf] = useState({});
    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});
    const [stTituloTipoDanio, setTituloTipoDanio] = useState("")
    let [stDatos, setState] = useState(cleanState);
    const [errorState, setErrorState] = useState(cleanErrorState);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [stFotografia1, setFotografia1] = useState({ valor: "", file: "", eliminar: remover });
    const [stFotografia2, setFotografia2] = useState({ valor: "", file: "", eliminar: remover });

    const [abrirSeguimiento, setAbrirSeguimiento] = useState(false);
    const cleanNotificacionesSeguimiento = { mensaje: "", color: null, open: false };
    const [notificacionesSeguimiento, setnotificacionesSeguimiento] = useState(cleanNotificacionesSeguimiento);
    const [seguimientoSelect, setseguimientoSelect] = useState("SIN RECLAMACIÓN");
    const [stCerrarReporte, setCerrarReporte] = useState({});

    const [datosDetalleAdjuntos, setDatosDetalleAdjuntos] = useState({})
    const [openDocumentacion, setOpenDocumentacion] = useState(false);

    let titleAccion = stDatos.reporteDaniosGerenciaID ? "Modificar" : "Agregar";
    let titleModal = `${titleAccion} Reporte de Daños de Gerencia`;
    let ArrayRef = [];

    const location = useLocation();
    const history = useHistory();

    const IrARuta = (pReclamo, RegistroID) => {
        let path = '/';
        if (pReclamo === 'TERCERO') {
            path = `/gen/ReclamacionATerceros`;
        } else {
            path = `/gen/SiniestrosReclamados`;
        }
        setAlert(null);
        history.push(path, { RegistroID });
    }

    let acciones = [{
        accionID: [9],
        icono: AssignmentTurnedInIcon,
        color: success,
        descripcion: "Finalizar Reporte",
        parametros: [{ campo: "reporteDaniosGerenciaID" }, { campo: "finalizado" }, { campo: "numIncidencia" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => FinalizarReporteDaniosGerencia(parametros)
    }, {
        accionID: [1, 3],
        icono: Edit,
        color: info,
        descripcion: "Modificar",
        parametros: [{ campo: "reporteDaniosGerenciaID" }],
        disabled: { multiseleccion: true },
        onClick: (parametros) => handleModificar({
            parametros,
            token,
            setState,
            setOpen,
            setAlert,
            asyncGet: Consultas.getReporteDaniosGerencia,
            formatearResultado: [{ id: "fechaElaboracion", formato: (data) => moment(data) }, { id: "fechaEvento", formato: (data) => moment(data) }, { id: "fechaIngreso", formato: (data) => moment(data) }, { id: "fechaHabilidad", formato: (data) => moment(data) }, { id: "semanaDanio", formato: (data) => moment(data) }]
        })
    }, {
        accionID: [4],
        icono: Close,
        color: danger,
        descripcion: "Eliminar",
        parametros: [{ campo: "reporteDaniosGerenciaID" },
        { campo: "folioInterno", variable: "folioInterno" }
        ],
        disabled: { multiseleccion: true },
        onClick: ({ reporteDaniosGerenciaID, folioInterno }) => handelAlertEliminar({
            setAlert,
            mensaje: ` el reporte de daño ${folioInterno}`,
            onCancel: () => setAlert(null),
            onConfirm: () => handleEliminar({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: { reporteDaniosGerenciaID, folioInterno },
                asyncDelete: Consultas.deleteReporteDaniosGerencia
            })
        })
    }, {
        accionID: [5],
        icono: PictureAsPdfIcon,
        color: primary,
        descripcion: "Descargar PDF",
        parametros: [{ campo: "reporteDaniosGerenciaID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteDaniosGerenciaID, folioInterno }) => AuxDescargar(reporteDaniosGerenciaID, folioInterno)
    },
    {
        accionID: [6],
        icono: RiFileExcel2Fill,
        color: success,
        descripcion: "Generar Excel(Largo)",
        parametros: [{ campo: "reporteDaniosGerenciaID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteDaniosGerenciaID, folioInterno }) => AuxDescargar(reporteDaniosGerenciaID, folioInterno, 'excel')
    },
    /* {
        accionID: [8],
        icono: RiFileExcel2Fill,
        color: success,
        descripcion: "Generar Excel(Corto)",
        parametros: [{ campo: "reporteDaniosGerenciaID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteDaniosGerenciaID, folioInterno }) => AuxDescargar(reporteDaniosGerenciaID, folioInterno, 'excelCorto')
    }, */
    {
        accionID: [7],
        icono: UnarchiveIcon,
        color: info,
        descripcion: "Adjuntar",
        parametros: [{ campo: "reporteDaniosGerenciaID" }, { campo: "folioInterno" }],
        disabled: { multiseleccion: true },
        onClick: ({ reporteDaniosGerenciaID, folioInterno }) => {
           // setRedireccionarFormulario({ reporteDaniosGerenciaID, folioInterno });
           setDatosDetalleAdjuntos({
            pURLDoctoOrigenID:reporteDaniosGerenciaID ,
            pTipo:folioInterno ,
            pClasificacion: "",
            pTabla: "Reporte de daños gerencia",
            pTitulo2: `Reporte de daños de gerencia folio: ${folioInterno}`,
            plUtilizaProps: true
        });
        setOpenDocumentacion(true);
        }
    }
    ];

    function setRedireccionarFormulario({ reporteDaniosGerenciaID, folioInterno }) {

        let seed = "scrumweb";
        const datos = `{ "pURLDoctoOrigenID": "${reporteDaniosGerenciaID}", "pTipo": "${folioInterno}", "pTabla": "Reporte de daños gerencia", "pTitulo2": "Reporte de daños de gerencia folio: ${folioInterno}" }`;
        let url = Encrypt({ data: datos, seed }).replace(/\//g, "&");
        history.push(`/gen/Documentos/${url}`);
    }

    function AuxDescargar(reporteDaniosGerenciaID, folioInterno, formatoReporte = "PDF") {
        setdatosPDf({ "reporteDaniosGerenciaID": reporteDaniosGerenciaID, "folioInterno": folioInterno, formatoReporte });
        datosPDF = { "reporteDaniosGerenciaID": reporteDaniosGerenciaID, "folioInterno": folioInterno, formatoReporte };

        handleAlertGeneric({
            setAlert,
            onCancel: () => setAlert(null),
            mensaje: {
                title: "Generar Reporte",
                descripcion: "¿Deseas generar y descargar el formato de impresión del reporte de daños de gerencia?",
                tipo: "warning",
                msjConfirmacion: "Aceptar",
                msjCancelacion: "Cancelar"
            },

            onConfirm: () => handleAlertMutationGeneric({
                token,
                setAlert,
                setActualizar,
                actualizar,
                parametros: {},
                asyncMutation: () => DescargarPdf(),
                mensajes: {
                    msjEspera: {
                        title: "Espere...",
                        descripcion: "Generando el formato de impresión del reporte de daños de gerencia ",
                        tipo: success
                    },
                    msjCorrecto: {
                        title: "Se generó con éxito",
                        descripcion: 'Se descargó el formato de impresión del reporte de daños de gerencia ' + folioInterno,
                        msjConfirmacion: "Aceptar"
                    }
                }
            })
        })
    }
    const showFile = (archivo, name = 'file.pdf',) => {
        let url = window.URL.createObjectURL(archivo);
        let a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();
    };

    async function DescargarPdf() {
        let myHeaders = new Headers();
        myHeaders.append("token", token);
        myHeaders.append("mimeType", "multipart/form-data");

        let formdata = new FormData();
        formdata.append("reporteDaniosGerenciaID", datosPDF.reporteDaniosGerenciaID);
        formdata.append("formatoReporte", datosPDF.formatoReporte);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        };

        let nombreReporte = 'ReporteDeDañosGerencia' + datosPDF.folioInterno
        await fetch(`${urlServidor}/service/reportes/ReporteDeDaniosGerencia`, requestOptions)
            .then(response => {
                if (!response.ok) return response.json();
                return response.blob()
            })
            .then(result => {
                let { error, mensaje } = result;
                if (error) throw new Error(mensaje);
                // showFile(result, `${nombreReporte}.pdf`);
                showFile(result, `${nombreReporte}${datosPDF.formatoReporte === 'PDF' ? '.pdf' : '.xlsx'}`); // mandar como variable dependiendo del if/case
                setAlert(null)
            })
            .catch(({ message }) => { throw new Error(message) });
    }

    function FinalizarReporteDaniosGerencia(parametros) {
        if (parametros.finalizado === 1) {
            setAlert({
                descripcion: 'Este registro ya se encuentra finalizado',
                title: "Finalizar registro",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
            return;
        }

        setAlert({
            descripcion: 'El reporte de daños de gerencia con folio ' + parametros.folioInterno + ' pasará a finalizado',
            title: "Finalizar registro",
            tipo: "success-two",
            msjConfirmacion: "Aceptar",
            onConfirm: () => ftFinalizarReporte(parametros.reporteDaniosGerenciaID),
            msjCancelacion: "Cancelar",
            onCancel: () => setAlert(null)
        });
    }

    async function ftFinalizarReporte(reporteDaniosGerenciaID) {
        try {
            await Consultas.FinalizarReporteDaniosGerencia({ reporteDaniosGerenciaID }, token);
            setActualizar(!actualizar);
            setAlert(null);

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    let botones = [{
        accionID: [2],
        icono: Add,
        color: success,
        descripcion: "Agregar",
        onClick: () => setOpen(true),
        disabled: { multiseleccion: true }
    }, {
        icono: SyncIcon,
        color: info,
        descripcion: "Actualizar",
        onClick: () => setActualizar(!actualizar),
        disabled: { multiseleccion: true }
    }];

    let infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "reporteDaniosGerenciaID",
        color: primary,
        title: "Reporte de Daños de Gerencia",
        iconTable: < SupervisorAccountIcon />,
        headers: [
            { variable: "reporteDaniosGerenciaID", descripcion: "ID", hide: true },
            { variable: "folioInterno", descripcion: "Folio", hide: false },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "finalizado", descripcion: "Finalizado", hide: true },
            { variable: "finalizadoNombre", descripcion: "Estatus" },
            { variable: "numIncidencia", descripcion: "No. de Acción" },
            { variable: "numNC", descripcion: "No. De NC" },
            { variable: "fechaEvento", descripcion: "Fecha del Evento" },
            { variable: "lugarEvento", descripcion: "Lugar del Evento" },
            { variable: "supervCargo", descripcion: "Supervisor(es) a Cargo" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
        ],
        responsiveTitle: ["folioInterno", "numIncidencia", "lugarEvento"],
        rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        filter: [
            { campo: "numIncidencia", variable: "numIncidencia", placeholder: "Capture el no. de daños o incidencia a buscar" },
            { campo: "lugarEvento", variable: "lugarEvento", placeholder: "Capture el lugar del evento a buscar" }
        ],
        alineacion: [{ columnas: [0, 4, 5], alineacion: "left" }, { columnas: [1, 2], alineacion: "right" },
        { columnas: [3], alineacion: "center" }
        ],
        selectFilter: [{
            campo: "empresaID",
            title: "Empresa",
            retorna: "number",
            limpiarFiltro: "Todos",
            data: stEmpresas ? stEmpresas : [],
            grid: { md: 3, lg: 3 }
        }],
        formato: [
            {
                columnas: ["fechaEvento", "fechaModificacion"], tipo: "fecha", onlyDate: true
            }]
    };

    const seguimiento = [{ value: "PENDIENTE", label: "PENDIENTE" },
    { value: "SIN RECLAMACIÓN", label: "SIN RECLAMACIÓN" },
    { value: "REPARADO", label: "REPARADO" },
    { value: "PAGADO", label: "PAGADO" },
    { value: "RECLAMADO A ASEGURADORA (RGR-002)", label: "RECLAMADO A ASEGURADORA (RGR-002)" },
    { value: "RECLAMADO A TERCERO (RGR-007)", label: "RECLAMADO A TERCERO (RGR-007)" }
    ];

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorState["reporteDaniosGerenciaID"] = { error: false, helperText: "" };
    cleanState["reporteDaniosGerenciaID"] = 0;
    cleanState["empresaID"] = 0;
    cleanState["estatus"] = 'EN PROCESO';
    cleanState["seguimiento"] = 'PENDIENTE';
    cleanState["finalizado"] = 0;
    cleanState["areaRespDanioID"] = 0;
    cleanState["areaRespRepaID"] = 0;

    InicializarInputs("reporteDaniosID", "Reporte de daños", "", 0, "Datos Generales", 0, "center");
    InicializarInputs("fechaElaboracion", "Fecha de Elaboración*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputs("numIncidencia", "Número de acción*", "", "", "Datos Generales", 30);
    InicializarInputs("numNC", "Tipo de reclamación (Servicio no conforme, Daño a la carga, etc.)*", "", "", "Datos Generales", 30);
    InicializarInputs("codigoClasifDanio", "Codigo Clasificacion Daño*", "", "", "Datos Generales", 30);
    InicializarInputs("descripBreve", "Descripción Breve del Evento", "", "", "Datos Generales", 0);
    InicializarInputs("lugarEvento", "Lugar del Evento*", "", "", "Datos Generales", 0);
    InicializarInputs("semanaDanio", "Semana de Daño*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputs("fechaEvento", "Fecha del Evento*", "", new Date(), "Datos Generales", 0, "center");
    InicializarInputs("horaEvento", "Hora del Evento*", "", new Date(), "Datos Generales", 0, "center");
   // InicializarInputs("horasInvertidas", "Horas Invertidas", "", "0", "Datos Generales", 0, "center");
    InicializarInputs("supervCargo", "Supervisor(es) a Cargo Durante el Evento", "", "", "Datos Generales", 0);
    InicializarInputs("areaID", "Área", "", 0, "Datos Generales", 150);
    InicializarInputs("empresaID", "Empresa*", "", usuario["empresaID"], "Datos Generales", 0);
    InicializarInputs("danioOcasioTer", "Daño Ocasionado por un Tercero", "", "", "Datos Generales", 150);
    InicializarInputs("danioOcasioNos", "Daño Ocasionado por Nosotros", "", "", "Datos Generales", 150);
    InicializarInputs("montos", "Montos", "", "0", "Datos Generales", 0, "center");
    InicializarInputs("descripMedia", "Descripción del evento: (Incluir solo lo que pasó, cómo, cuándo, quién, testigos, maquinaria involucrada, etc.)", "", "", "Información del Evento", 0);
    InicializarInputs("danioOcasioA", "Daño Ocasionado a*", "", "", "Información del Evento", 150);
    InicializarInputs("tipoDanioOcasio", "Tipo de daño", "", "", "Información del Evento", 150);

    InicializarInputs("buqueViaje", "Buque viaje", "", "", "Información del Evento", 150);

    InicializarInputs("descripDetallada", "Descripción Detallada del Daño:", "Incluir descripción de los daños ocasionados que se presentan, partes o propiedad, ubicaciones, dimensiones, contenido, estatus, cantidad", "", "Información del Evento", 0);
    InicializarInputs("folioServicio", "Folio de servicio", "", "", "Información complementaria (en caso de aplicar)", 0);
    InicializarInputs("tipoServicio", "Tipo de servicio", "", "", "Información complementaria (en caso de aplicar)", 50);
    InicializarInputs("tipoMercancia", "¿Qué tipo de mercancia es?", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputs("propiedadDe", "Propiedad de", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputs("repreLocal", "Representante local", "", "", "Información complementaria (en caso de aplicar)", 250);
    InicializarInputs("fotografia1", "Foto (1)", "", "", "Incluir Fotografía(s) Ilustrativa(s) del Evento", 250);
    InicializarInputs("fotografia2", "Foto (2)", "", "", "Incluir Fotografía(s) Ilustrativa(s) del Evento", 250);
    InicializarInputs("nombresPersonal", "Nombres", "", "", "Información del Personal Involucrado", 0);
    InicializarInputs("numControlPersonal", "No. De control", "", "", "Información del Personal Involucrado", 0);
    InicializarInputs("habilidadBase", "Puesto o habilidad base", "", "", "Información del Personal Involucrado", 30);
    InicializarInputs("habilidadActiv", "Puesto o habilidad de la actividad que realizaba", "", "", "Información del Personal Involucrado", 150);
    InicializarInputs("fechaIngreso", "Fecha de Ingreso*", "", new Date(), "Información del Personal Involucrado", 0, "center");
    InicializarInputs("fechaHabilidad", "Fecha de habilidad*", "", new Date(), "Información del Personal Involucrado", 0, "center");
    InicializarInputs("otrosDaniosHabilidades", "Otros Daños o Habilidades", "", "", "Información del Personal Involucrado", 0);

    InicializarInputs("nombresTestigos", "Nombres", "", "", "Información de Testigo 1", 150);
    InicializarInputs("numControlTestigos", "No. De control", "", "", "Información de Testigo 1", 150);
    InicializarInputs("habilidadTestigos", "Puesto o habilidad", "", "", "Información de Testigo 1", 150);
    InicializarInputs("nombresTestigos2", "Nombres", "", "", "Información de Testigo 2", 150);
    InicializarInputs("numControlTestigos2", "No. De control", "", "", "Información de Testigo 2", 150);
    InicializarInputs("habilidadTestigos2", "Puesto o habilidad", "", "", "Información de Testigo 2", 150);
    InicializarInputs("notifAseg", "¿Fue necesaro notificar a nuestra aseguradora?", "", "", "", 5);
    InicializarInputs("reparaDanio", "¿Se acordó reparación del daño por parte de nosotros?", "", "", "", 5);
    InicializarInputs("inspecTerceros", "¿Se solicitó la inspección de una tercera parte?", "", "", "", 5);
    InicializarInputs("cotizacionDanio", "¿Se cuenta con Cotización del daño?", "", "", "", 5);
    InicializarInputs("anexRep", "¿Se anexan reportes internos / externos?", "", "", "", 5);
    InicializarInputs("reportesAnex", "¿Cuáles reportes se anexan?", "", "", "", 0);
    InicializarInputs("obserGenerales", "Observaciones Generales", "", "", "", 0);
    InicializarInputs("responAnalisis", "Responsable del Análisis de Causa Raíz", "", "", "", 0);
    //-----------------------------------------------------
    InicializarInputs("elaboro", "Elaboró", "", "", "", 250);
    InicializarInputs("firma", "Firma", "", "", "", 250);
    InicializarInputs("cargo", "Cargo", "", "", "", 0);
    //-----------------------------------------------------
    InicializarInputs("codigo", "Código*", "", "", "Datos de gerencia general", 15, "left");
    InicializarInputs("clasificacion", "Clasificación*", "", "", "Datos de gerencia general", 250, "left");
    InicializarInputs("nombreAccidentado", "Nombre del accidentado", "", "", "Datos de gerencia general", 250, "left");
    InicializarInputs("diasIncapacidad", "Días de incapacidad", "", 0, "Datos de gerencia general", 0, "right");
    InicializarInputs("cliente", "Cliente", "", "", "Datos de gerencia general", 250, "left");
    InicializarInputs("costosInvolucrados", "Costos involucrados", "", "0.00", "Datos de gerencia general", 250, "left");
    InicializarInputs("observaciones", "Observaciones", "", "", "Datos de gerencia general", 0, "left");

    //-----------------------------------------------------
    InputsConID["reporteDaniosID"].grid = { md: 4, lg: 4 };
    InputsConID["fechaElaboracion"].grid = { md: 4, lg: 4 };
    InputsConID["numIncidencia"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["numNC"].grid = { md: 4, lg: 4 };
    InputsConID["codigoClasifDanio"].grid = { md: 4, lg: 4 };
    InputsConID["lugarEvento"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["descripBreve"].grid = { md: 6, lg: 6 };
    InputsConID["semanaDanio"].grid = { md: 3, lg: 3 };
    InputsConID["fechaEvento"].grid = { md: 3, lg: 3 };
    //-----------------------------------------------------
    InputsConID["horaEvento"].grid = { md: 3, lg: 3 };
   // InputsConID["horasInvertidas"].grid = { md: 3, lg: 3 };
    //-----------------------------------------------------
    InputsConID["supervCargo"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["areaID"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["empresaID"].grid = { md: 6, lg: 6 };
    InputsConID["danioOcasioTer"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["danioOcasioNos"].grid = { md: 7, lg: 7 };
    InputsConID["montos"].grid = { md: 5, lg: 5 };
    //-----------------------------------------------------
    InputsConID["descripMedia"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["danioOcasioA"].grid = { md: 4, lg: 4 };
    InputsConID["tipoDanioOcasio"].grid = { md: 4, lg: 4 };
    InputsConID["buqueViaje"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["descripDetallada"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["folioServicio"].grid = { md: 4, lg: 4 };
    InputsConID["tipoServicio"].grid = { md: 4, lg: 4 };
    InputsConID["tipoMercancia"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["propiedadDe"].grid = { md: 6, lg: 6 };
    InputsConID["repreLocal"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["fotografia1"].grid = { md: 6, lg: 6 };
    InputsConID["fotografia2"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["nombresPersonal"].grid = { md: 8, lg: 8 };
    InputsConID["numControlPersonal"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["habilidadBase"].grid = { md: 6, lg: 6 };
    InputsConID["habilidadActiv"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["fechaIngreso"].grid = { md: 6, lg: 6 };
    InputsConID["fechaHabilidad"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["otrosDaniosHabilidades"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["nombresTestigos"].grid = { md: 4, lg: 4 };
    InputsConID["numControlTestigos"].grid = { md: 4, lg: 4 };
    InputsConID["habilidadTestigos"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["nombresTestigos2"].grid = { md: 4, lg: 4 };
    InputsConID["numControlTestigos2"].grid = { md: 4, lg: 4 };
    InputsConID["habilidadTestigos2"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["notifAseg"].grid = { md: 6, lg: 6 };
    InputsConID["reparaDanio"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["inspecTerceros"].grid = { md: 6, lg: 6 };
    InputsConID["cotizacionDanio"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["anexRep"].grid = { md: 6, lg: 6 };
    InputsConID["reportesAnex"].grid = { md: 6, lg: 6 };
    //-----------------------------------------------------
    InputsConID["obserGenerales"].grid = { md: 12, lg: 12 };
    InputsConID["responAnalisis"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------
    InputsConID["elaboro"].grid = { md: 4, lg: 4 };
    InputsConID["firma"].grid = { md: 4, lg: 4 };
    InputsConID["cargo"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["codigo"].grid = { md: 4, lg: 4 };
    InputsConID["clasificacion"].grid = { md: 4, lg: 4 };
    InputsConID["nombreAccidentado"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["diasIncapacidad"].grid = { md: 4, lg: 4 };
    InputsConID["cliente"].grid = { md: 4, lg: 4 };
    InputsConID["costosInvolucrados"].grid = { md: 4, lg: 4 };
    //-----------------------------------------------------
    InputsConID["observaciones"].grid = { md: 12, lg: 12 };
    //-----------------------------------------------------

    InputsConID["reporteDaniosID"].tipo = "select";
    InputsConID["reporteDaniosID"].data = stReporteDanios;

    InputsConID["empresaID"].tipo = "select";
    InputsConID["empresaID"].data = stEmpresas;

    InputsConID["descripBreve"].multiline = true;
    InputsConID["descripMedia"].multiline = true;
    InputsConID["descripDetallada"].multiline = true;

    let OpcionesDanios = [{ value: "A propiedad interna", label: "A propiedad interna" },
    { value: "A la carga", label: "A la carga" },
    { value: "A un tercero (Externo)", label: "A un tercero (Externo)" }
    ];

    InputsConID["danioOcasioTer"].tipo = "select";
    InputsConID["danioOcasioTer"].data = OpcionesDanios;

    InputsConID["danioOcasioNos"].tipo = "select";
    InputsConID["danioOcasioNos"].data = OpcionesDanios;

    let OpcionesDanioA = [{ value: "Carga General", label: "Carga General" },
    { value: "Contenedor (es)", label: "Contenedor (es)" },
    { value: "Automóvil (es)", label: "Automóvil (es)" },
    { value: "Maquinaria y/o Equipo Interno", label: "Maquinaria y/o Equipo Interno" },
    { value: "Herramientas de Trabajo", label: "Herramientas de Trabajo" },
    { value: "Instalaciones", label: "Instalaciones" },
    { value: "Propiedad Externa", label: "Propiedad Externa" }
    ];

    InputsConID["danioOcasioA"].tipo = "select";
    InputsConID["danioOcasioA"].data = OpcionesDanioA;
    InputsConID["danioOcasioA"].value = stDatos["danioOcasioA"];

    InputsConID["tipoDanioOcasio"].title = stTituloTipoDanio;
    InputsConID["tipoDanioOcasio"].placeholder = stTituloTipoDanio;

    let OpcionesMercancia = [{ value: "Importacion", label: "Importacion" },
    { value: "Exportación", label: "Exportación" },
    { value: "Vacios", label: "Vacios" },
    { value: "Transbordo Lleno", label: "Transbordo Lleno" },
    { value: "Transbordo Vacío", label: "Transbordo Vacío" },
    { value: "NA", label: "NA" }
    ];

    InputsConID["tipoMercancia"].tipo = "select";
    InputsConID["tipoMercancia"].data = OpcionesMercancia;

    let Opciones = [{ value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
    { value: "NA", label: "NA" }
    ];

    InputsConID["notifAseg"].tipo = "select";
    InputsConID["notifAseg"].data = Opciones;

    InputsConID["reparaDanio"].tipo = "select";
    InputsConID["reparaDanio"].data = Opciones;

    InputsConID["inspecTerceros"].tipo = "select";
    InputsConID["inspecTerceros"].data = Opciones;

    InputsConID["cotizacionDanio"].tipo = "select";
    InputsConID["cotizacionDanio"].data = Opciones;

    InputsConID["anexRep"].tipo = "select";
    InputsConID["anexRep"].data = Opciones;

    InputsConID["fotografia1"].tipo = "imageupload";
    InputsConID["fotografia1"]["removeImage"] = stDatos["fotografia1"] === "" ? true : false;
    InputsConID["fotografia1"].onChange = (file, base) => {
        if (file === null) {
            remover = true;
        }
        setState(stDatos => ({
            ...stDatos,
            'fotografia1': base
        }));
        if (open) {
            setFotografia1({ valor: base, file: file, eliminar: remover });
        }
        return;
    };
    InputsConID["fotografia2"].tipo = "imageupload";
    InputsConID["fotografia2"]["removeImage"] = stDatos["fotografia2"] === "" ? true : false;
    InputsConID["fotografia2"].onChange = (file, base) => {
        if (file === null) {
            remover = true;
        }
        setState(stDatos => ({
            ...stDatos,
            'fotografia2': base
        }));
        if (open) {
            setFotografia2({ valor: base, file: file, eliminar: remover });
        }
        return;
    };

    InputsConID["fechaElaboracion"].tipo = "datetimepicker";
    InputsConID["fechaElaboracion"].timeFormat = false;
    InputsConID["fechaElaboracion"].onlyDate = true;
    InputsConID["fechaElaboracion"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaElaboracion");
    };

    InputsConID["semanaDanio"].tipo = "datetimepicker";
    InputsConID["semanaDanio"].timeFormat = false;
    InputsConID["semanaDanio"].onlyDate = true;
    InputsConID["semanaDanio"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "semanaDanio");
    };

    InputsConID["fechaIngreso"].tipo = "datetimepicker";
    InputsConID["fechaIngreso"].timeFormat = false;
    InputsConID["fechaIngreso"].onlyDate = true;
    InputsConID["fechaIngreso"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaIngreso");
    };

    InputsConID["fechaHabilidad"].tipo = "datetimepicker";
    InputsConID["fechaHabilidad"].timeFormat = false;
    InputsConID["fechaHabilidad"].onlyDate = true;
    InputsConID["fechaHabilidad"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaHabilidad");
    };

    InputsConID["fechaEvento"].tipo = "datetimepicker";
    InputsConID["fechaEvento"].timeFormat = false;
    InputsConID["fechaEvento"].onlyDate = true;
    InputsConID["fechaEvento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "fechaEvento");
    };

    InputsConID["horaEvento"].tipo = "datetimepicker";
    InputsConID["horaEvento"].timeFormat = false;
    InputsConID["horaEvento"].onlyTime = true;
    InputsConID["horaEvento"].onlyDate = false;
    InputsConID["horaEvento"].onChange = (value) => {
        if (typeof value !== "object") return;
        handleChange(value, "horaEvento");
    };

   // InputsConID["horasInvertidas"].onChange = ({ target: { value } }) => !isNaN(Number(value)) && handleChange(value, "horasInvertidas");
    InputsConID["montos"].onChange = ({ target: { value } }) => !isNaN(Number(value)) && handleChange(value, "montos");
    InputsConID["costosInvolucrados"].onChange = ({ target: { value } }) => !isNaN(Number(value)) && handleChange(value, "costosInvolucrados");

    /*let OpcionesFormaRecibido = [
        { value: "Correo", label: "Correo" },
        { value: "Teléfono", label: "Teléfono" }
    ];
    InputsConID["formaRecibido"].tipo = "select";
    InputsConID["formaRecibido"].data = OpcionesFormaRecibido;*/

    InputsConID["costosInvolucrados"].textAlign = "right";
    InputsConID["costosInvolucrados"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["costosInvolucrados"].posicionIcono = "start";
    InputsConID["costosInvolucrados"].formatoImportes = true;

    InputsConID["montos"].textAlign = "right";
    InputsConID["montos"].icono = <IconoSimbolo simbolo="$" />;
    InputsConID["montos"].posicionIcono = "start";
    InputsConID["montos"].formatoImportes = true;

    DisabledAllInputs();
    function DisabledAllInputs() {
        for (const key in InputsConID) {
            InputsConID[key].disabled = stDatos["finalizado"] === 1 ? true : false;
        }
    }

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0, textAlign = 'left') {

        cleanErrorState[Identificador] = { error: false, helperText: "" };
        cleanState[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        let tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            textAlign: textAlign,
            onChange: ({ target: { value } }) => handleChange(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        let tInputsDeSeccion = [];
        let InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.5em",
                widthBorderFieldset:  Seccion === "Datos de gerencia general" ? "10px" : "1px",
                fontWeight: Seccion === "Datos de gerencia general" ? 700 : 300
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }

    let accionesFormulario = [{
        accionID: stDatos["reporteDaniosGerenciaID"] ? [3] : [2],
        loader,
        disabled,
        icono: SaveIcon,
        color: info,
        descripcion: "Guardar",
        onClick: () => handleGuardar({
            setLoader,
            setDisabled,
            setActualizar,
            actualizar,
            setNotificaciones,
            handleClose,
            handleFocus,
            refFocus: ArrayRef["fechaElaboracion"],
            mensajeCorrecto: `El reporte de servicio a clientes se ${stDatos["reporteDaniosGerenciaID"] ? "actualizó" : "agregó"} correctamente`,
            asyncCallback: ftGuardar,
        }),
        // inputRef: accederRef,
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleClose
    }];

    function ftErrorInputText({ condicion, ref, keyError, mensajeError }) {
        return handleErrorInputText({
            cleanErrorState,
            condicion,
            ref,
            keyError,
            mensajeError,
            loader: setLoader,
            disabled: setDisabled,
            errorState: setErrorState
        });
    }

    function handleValidaciones() {

        let error;
        let validaciones = [{
            condicion: !stDatos["numIncidencia"] || trim(stDatos["numIncidencia"]) === "",
            ref: ArrayRef["numIncidencia"],
            keyError: "numIncidencia",
            mensajeError: "Debe especificar el No. de daños o incidencia"
        },
        {
            condicion: !stDatos["numNC"] || trim(stDatos["numNC"]) === "",
            ref: ArrayRef["numNC"],
            keyError: "numNC",
            mensajeError: "Debe especificar el No. De NC"
        },
        {
            condicion: !stDatos["empresaID"] || ["empresaID"] === 0,
            ref: ArrayRef["empresaID"],
            keyError: "empresaID",
            mensajeError: "Debe especificar la empresa"
        },
        {
            condicion: !stDatos["fechaElaboracion"] || trim(stDatos["fechaElaboracion"]) === "",
            ref: ArrayRef["fechaElaboracion"],
            keyError: "fechaElaboracion",
            mensajeError: "Debe especificar la fecha de elaboración"
        },
        {
            condicion: !stDatos["lugarEvento"] || trim(stDatos["lugarEvento"]) === "",
            ref: ArrayRef["lugarEvento"],
            keyError: "lugarEvento",
            mensajeError: "Debe especificar el lugar del evento"
        },
        {
            condicion: !stDatos["semanaDanio"] || trim(stDatos["semanaDanio"]) === "",
            ref: ArrayRef["semanaDanio"],
            keyError: "semanaDanio",
            mensajeError: "Debe especificar la semana del daño"
        },
        {
            condicion: !stDatos["fechaIngreso"] || trim(stDatos["fechaIngreso"]) === "",
            ref: ArrayRef["fechaIngreso"],
            keyError: "fechaIngreso",
            mensajeError: "Debe especificar la fecha de ingreso"
        },
        {
            condicion: !stDatos["fechaHabilidad"] || trim(stDatos["fechaHabilidad"]) === "",
            ref: ArrayRef["fechaHabilidad"],
            keyError: "fechaHabilidad",
            mensajeError: "Debe especificar la fecha de habilidad"
        },
        {
            condicion: !stDatos["fechaEvento"] || trim(stDatos["fechaEvento"]) === "",
            ref: ArrayRef["fechaEvento"],
            keyError: "fechaEvento",
            mensajeError: "Debe especificar la fecha del evento"
        },
        {
            condicion: !stDatos["horaEvento"] || trim(stDatos["horaEvento"]) === "",
            ref: ArrayRef["horaEvento"],
            keyError: "horaEvento",
            mensajeError: "Debe especificar la hora del evento"
        },
       /* stDatos["horasInvertidas"] !== "" && {
            condicion: !rgxdeInt2.test(stDatos["horasInvertidas"]),
            ref: ArrayRef["horasInvertidasRef"],
            keyError: "horasInvertidas",
            mensajeError: "El formato de la hora es inválido"
        },*/
        {
            condicion: !stDatos["danioOcasioA"] || trim(stDatos["danioOcasioA"]) === "",
            ref: ArrayRef["danioOcasioA"],
            keyError: "danioOcasioA",
            mensajeError: "Debe especificar el daño Ocasionado a"
        },
        {
            condicion: !stDatos["codigo"] || trim(stDatos["codigo"]) === "",
            ref: ArrayRef["codigo"],
            keyError: "codigo",
            mensajeError: "Debe especificar el código"
        },
        {
            condicion: !stDatos["clasificacion"] || trim(stDatos["clasificacion"]) === "",
            ref: ArrayRef["clasificacion"],
            keyError: "clasificacion",
            mensajeError: "Debe especificar la clasificación"
        }
        ];

        validaciones.forEach(({ condicion, ref, keyError, mensajeError }) => {
            if (stDatos["reporteDaniosGerenciaID"] === 0) {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            } else {
                if (error) return;
                error = ftErrorInputText({ condicion, ref, keyError, mensajeError });
            }
        });

        if (error) return error;
    }

    function ftGuardar() {
        async function ftGuardar() {
            let valor;
            let error = await handleValidaciones();
            //try{
            let DatosGuardar = { ...stDatos };

            DatosGuardar["fechaElaboracion"] = formatearFecha(DatosGuardar["fechaElaboracion"]);
            DatosGuardar["semanaDanio"] = formatearFecha(DatosGuardar["semanaDanio"]);
            DatosGuardar["fechaIngreso"] = formatearFecha(DatosGuardar["fechaIngreso"]);
            DatosGuardar["fechaHabilidad"] = formatearFecha(DatosGuardar["fechaHabilidad"]);
            DatosGuardar["fechaEvento"] = formatearFecha(DatosGuardar["fechaEvento"]);
            DatosGuardar["horaEvento"] = formatearHora(DatosGuardar["horaEvento"]);
           // DatosGuardar["horasInvertidas"] = DatosGuardar["horasInvertidas"] ? parseFloat(DatosGuardar["horasInvertidas"]) : 0
            DatosGuardar["montos"] = DatosGuardar["montos"] ? parseFloat(DatosGuardar["montos"]) : 0

            DatosGuardar["costoDanio"] = parseFloat(DatosGuardar["costoDanio"]);
            DatosGuardar["reporteDaniosGerenciaID"] = parseInt(DatosGuardar["reporteDaniosGerenciaID"]);
            DatosGuardar["finalizado"] = parseInt(DatosGuardar["finalizado"])

            DatosGuardar["areaID"] = parseInt(DatosGuardar["areaID"]);
            DatosGuardar["costosInvolucrados"] = parseFloat(DatosGuardar["costosInvolucrados"]);
            DatosGuardar["diasIncapacidad"] = parseInt(DatosGuardar["diasIncapacidad"]);

            if (DatosGuardar["areaRespDanioID"] === "") {
                DatosGuardar["areaRespDanioID"] = 0;
            }

            if (DatosGuardar["areaRespRepaID"] === "") {
                DatosGuardar["areaRespRepaID"] = 0;
            }

            if (error) return error;

            let datos = { serverStorageID: stDatos.storageID1, origenID: Number(DatosGuardar.reporteDaniosGerenciaID), tabla: "REPORTEDANIOSGERENCIA", tipo: "REPORTEDANIOSGERENCIA", clasificacion: "fotoDanio", nombreArchivo: stFotografia1.eliminar ? "" : stFotografia1.file.name, ruta: stDatos["rutafotografia1"] }

            let datos2 = { serverStorageID: stDatos.storageID2, origenID: Number(DatosGuardar.reporteDaniosGerenciaID), tabla: "REPORTEDANIOSGERENCIA", tipo: "REPORTEDANIOSGERENCIA", clasificacion: "fotoDanio", nombreArchivo: stFotografia2.eliminar ? "" : stFotografia2.file.name, ruta: stDatos["rutafotografia2"] }

            delete DatosGuardar["fotografia1"];
            delete DatosGuardar["rutafotografia1"];
            delete DatosGuardar["storageID1"];
            delete DatosGuardar["nombrefoto1"];

            delete DatosGuardar["fotografia2"];
            delete DatosGuardar["rutafotografia2"];
            delete DatosGuardar["storageID2"];
            delete DatosGuardar["nombrefoto2"];

            delete DatosGuardar["folioInterno"];
            delete DatosGuardar["fechaModificacion"];
            delete DatosGuardar["areaNombre"];
            delete DatosGuardar["areaRespRepaNombre"];
            delete DatosGuardar["areaRespDanioNombre"];
            delete DatosGuardar["horasInvertidas"];

            delete DatosGuardar["ubicacionID"];
            delete DatosGuardar["modeloUnidad"];
            delete DatosGuardar["numVin"];
            delete DatosGuardar["numControl"];
            delete DatosGuardar["nomChofer"];
            delete DatosGuardar["genero"];
            delete DatosGuardar["antiguedad"];
            delete DatosGuardar["fechaNacimiento"];
            delete DatosGuardar["horaNombramiento"];
            delete DatosGuardar["turnoAnterior"];
            delete DatosGuardar["horaAlimentos"];
            delete DatosGuardar["calistenia"];
            delete DatosGuardar["ultimaPlatica"];
            delete DatosGuardar["fechaUltimaPlatica"];
            delete DatosGuardar["fechaRevProc"];
            delete DatosGuardar["ejercicio"];
            delete DatosGuardar["mes"];
            delete DatosGuardar["fechaInicio"];
            delete DatosGuardar["fechaFin"];
            delete DatosGuardar["fechaFinReporte"];
            
            if (stDatos["reporteDaniosGerenciaID"]) {
                await Consultas.updateReporteDaniosGerencia(DatosGuardar, token);
            } else {
                delete DatosGuardar["reporteDaniosGerenciaID"];
                valor = await Consultas.addReporteDaniosGerencia(DatosGuardar, token);
                datos = { ...datos, serverStorageID: null, origenID: valor.reporteDaniosGerenciaID };
                datos2 = { ...datos2, serverStorageID: null, origenID: valor.reporteDaniosGerenciaID };
            }
            try {
                if (stFotografia1.file || stFotografia1.eliminar == true) {
                    await actualizaArchivo('ReporteDaniosGerencia/fotoPerfil/', stFotografia1.file, 1, datos, token, stFotografia1.eliminar);
                }
                if (stFotografia2.file || stFotografia2.eliminar == true) {
                    await actualizaArchivo('ReporteDaniosGerencia/fotoPerfil/', stFotografia2.file, 1, datos2, token, stFotografia2.eliminar);
                }
            } catch (error) {
                setNotificaciones({ mensaje: error, color: danger, open: true });
            }
        }

        return ftGuardar();
    }

    function formatearFecha(pFecha) {
        if (moment(pFecha).isValid() === false) {
            return null;
        }

        let formatoFecha = "YYYY-MM-DD";
        return moment(pFecha).format(formatoFecha);
    }

    function formatearHora(pHora) {
        if (moment(pHora).isValid() === false) {
            let tHora = moment(pHora, 'HH:mm').format('HH:mm');
            if (moment(tHora).isValid() === false) {
                return tHora;
            }
            return null;
        }

        return moment(pHora).format("HH:mm");
    }

    function handleClose() {
        setOpen(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setState({ ...cleanState });
        setFotografia1({ valor: "", file: "", remover: false });
        setFotografia2({ valor: "", file: "", remover: false });
        setErrorState({ ...cleanErrorState });
    }

    function handleChange(value, key, MaxLength = 0) {
        if (key === "fotoUsuario") {
            return;
        }

        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }

        if (key === "costoDanio"
            || key === "costosInvolucrados"
            || key === "diasIncapacidad") {
            if (isNaN(Number(value))) {
                return;
            }
        }

        if (key === "reporteDaniosID") {
            AsignarValoresDesdeReporteDanios(value);
        }

        setState(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

        if (key === "danioOcasioA") {
            switch (value) {
                case "Carga General":
                    setTituloTipoDanio("Tipo de Carga y/o No. de Serie");
                    break;
                case "Contenedor (es)":
                    setTituloTipoDanio("Tipo y No de Cont.");
                    break;
                case "Automóvil (es)":
                    setTituloTipoDanio("No. Serie");
                    break;
                case "Maquinaria y/o Equipo Interno":
                    setTituloTipoDanio("No. de Serie y/o Económico");
                    break;
                case "Herramientas de Trabajo":
                    setTituloTipoDanio("Tipo de Herramienta");
                    break;
                case "Instalaciones":
                    setTituloTipoDanio("¿Cuál (es)?");
                    break;
                case "Propiedad Externa":
                    setTituloTipoDanio("Tipo y No de Serie o Económico");
                    break;
                default:
                    setTituloTipoDanio("")
                    break;
            }
        }
    }

    async function ObtenerEmpresas() {
        let Respuesta = await ConsultasEmpresas.seleccionEmpresa({ filtro: { activa: 1 } }, token);
        setEmpresas(Respuesta);
    }

    async function ObtenerReporteDanios() {
        let Respuesta = await ConsultasReporteDanios.seleccionReporteDanios({ filtro: {} }, token);
        setReporteDanios(Respuesta);
    }

    async function AsignarValoresDesdeReporteDanios(pReporteDaniosID) {
        for (let i = 0; i < stReporteDanios.length; i++) {
            let tElement = stReporteDanios[i];
            if (parseInt(pReporteDaniosID) === parseInt(tElement.value)) {

                let Registro = await ConsultasReporteDanios.getReporteDanios({ reporteDaniosID: pReporteDaniosID }, token);

                if (Registro.fotografia1 !== "") {
                    let file1 = await dataUrlToFile(Registro.fotografia1, Registro.reporteDaniosID + "foto1")
                    setFotografia1({ valor: Registro.fotografia1, file: file1, remover: true });
                }

                if (Registro.fotografia2 !== "") {
                    let file2 = await dataUrlToFile(Registro.fotografia2, Registro.reporteDaniosID + "foto2")
                    setFotografia2({ valor: Registro.fotografia2, file: file2, remover: true });
                }

                setState(stDatos => ({
                    ...stDatos,
                    ...Registro,
                    storageID1: null,
                    storageID2: null,
                    finalizado: 0
                }));
                return;
            }
        }
    }

    async function dataUrlToFile(dataUrl, fileName) {
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                ObtenerEmpresas();
                ObtenerReporteDanios();

                let Areas = await seleccionArea({ filtro: {} }, token);
                let Ubicaciones = await seleccionUbicaciones({ filtro: {} }, token);
                setSeleccionables({ Areas, Ubicaciones });

            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        }
        ftEffect();
    }, []);

    function handleCloseSeguimiento() {
        setAbrirSeguimiento(false);
        setnotificacionesSeguimiento({ ...cleanNotificaciones });
        setseguimientoSelect("");
    }

    ArrayRef["seguimientoSelect"] = useRef(null);
    cleanErrorState["seguimientoSelect"] = { error: false, helperText: "" };

    const inputsSeguimiento = [{
        value: seguimientoSelect,
        id: "seguimientoSelect",
        error: errorState["seguimientoSelect"].error,
        success: seguimientoSelect && !errorState["seguimientoSelect"].error ? true : undefined,
        helperText: errorState["seguimientoSelect"].helperText,
        inputRef: ArrayRef["seguimientoSelect"],
        title: "Seguimiento",
        placeholder: "Seguimiento",
        textAlign: "left",
        onChange: ({ target: { value } }) => setseguimientoSelect(value),
        onKeyDown: () => { return; },
        grid: { md: 12, lg: 12 },
        tipo: "select",
        data: [{ value: "SIN RECLAMACIÓN", label: "SIN RECLAMACIÓN" },
        { value: "REPARADO", label: "REPARADO" },
        { value: "PAGADO", label: "PAGADO" },
        { value: "RECLAMADO A ASEGURADORA (RGR-002)", label: "RECLAMADO A ASEGURADORA (RGR-002)" },
        { value: "RECLAMADO A TERCERO (RGR-007)", label: "RECLAMADO A TERCERO (RGR-007)" }
        ]
    }];

    const accionesSeguimiento = [{
        loader,
        disabled,
        icono: AssignmentTurnedInIcon,
        color: info,
        descripcion: "Cerrar reporte",
        onClick: () => CerrarReporte()
    }, {
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cancelar",
        onClick: handleCloseSeguimiento
    }];

    function AbrirFormCerrarReporte(parametros) {
        setCerrarReporte(parametros)
        if (parametros.estatus === "CERRADO") {
            setAlert({
                descripcion: 'El reporte de servicio a clientes ya se encuentra cerrado',
                title: "Cerrar reporte",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null),
                msjCancelacion: "Cancelar",
                onCancel: () => setAlert(null)
            });
            return;
        }

        setAbrirSeguimiento(true);
    }

    async function CerrarReporte() {
        try {
            let result = await Consultas.CerrarReporteDaniosGerencia({ reporteDaniosGerenciaID: stCerrarReporte['reporteDaniosGerenciaID'], seguimiento: seguimientoSelect }, token);

            switch (seguimientoSelect) {
                case "RECLAMADO A ASEGURADORA (RGR-002)":
                    setAlert({
                        descripcion: 'El reporte de servicio a clientes generó la reclamación a aseguradora con No. Siniestro ' + stCerrarReporte.folio,
                        title: "",
                        tipo: "success-two",
                        msjConfirmacion: "Abrir reclamo",
                        onConfirm: () => IrARuta('ASEGURADORA', result["RegistroID"]),
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });
                    break;
                case "RECLAMADO A TERCERO (RGR-007)":
                    setAlert({
                        descripcion: 'El reporte de servicio a clientes generó la reclamación a tercero con No. Siniestro ' + stCerrarReporte.folio,
                        title: "",
                        tipo: "success-two",
                        msjConfirmacion: "Abrir reclamo",
                        onConfirm: () => IrARuta('TERCERO', result["RegistroID"]),
                        msjCancelacion: "Cancelar",
                        onCancel: () => setAlert(null)
                    });
                    break;
                default:
                    break;
            }

            setActualizar(!actualizar);
            setseguimientoSelect("");
            setCerrarReporte({})
            setAbrirSeguimiento(false);

        } catch ({ message: descripcion }) {
            setAlert({
                descripcion: descripcion,
                title: "¡Una disculpa!",
                tipo: danger,
                msjConfirmacion: "Aceptar",
                onConfirm: () => setAlert(null)
            });
        }
    }

    const [accionesUsr, setAccionesUsr] = useState();
    if (accionesUsr) {
        acciones = Permisos.ValidarAcciones(acciones, accionesUsr);
        botones = Permisos.ValidarAcciones(botones, accionesUsr);
	    accionesFormulario = Permisos.ValidarAcciones(accionesFormulario, accionesUsr);
    }else{
        Permisos.PermisosAState(setAccionesUsr, token);
    }

    return (<>< Tabla infoTabla={infoTabla}
        asyncData={Consultas.tablaReporteDaniosGerencia}
        token={token} />

        <FormularioModal open={open}
            title={titleModal}
            onClose={handleClose}
            notificaciones={notificaciones}
            closeNotification={
                () => setNotificaciones({ ...cleanNotificaciones })
            }
            inputs={inputs}
            acciones={accionesFormulario}
            focus={ArrayRef["UsuarioNombre"]} /> <FormularioModal open={abrirSeguimiento}
                title={"Cerrar reporte de servicio a clientes"}
                onClose={handleCloseSeguimiento}
                notificaciones={notificacionesSeguimiento}
                //closeNotification={() => setNotificacionesSeguimiento({ ...cleanNotificacionesSeguimiento })}
                inputs={inputsSeguimiento}
                acciones={accionesSeguimiento}
                focus={ArrayRef["seguimientoSelect"]} />
             {
             openDocumentacion && <Modal maxWidth={true} onClose={() => {setOpenDocumentacion(false)}}>

                <Documentacion datosDetalleAdjuntos={datosDetalleAdjuntos}   setOpenDocumentacion = { () => setOpenDocumentacion(false)
                } />

            </Modal>
          }
        {
            alert && < SweetAlert title={alert.title}
                descripcion={alert.descripcion}
                tipo={alert.tipo}
                msjConfirmacion={alert.msjConfirmacion}
                msjCancelacion={alert.msjCancelacion}
                onConfirm={alert.onConfirm}
                showConfirm={alert.showConfirm}
                showCancel={alert.showCancel}
                onCancel={
                    () => setAlert(null)
                }
            />} </>
    );
}

export default ReporteDaniosGerencia;
