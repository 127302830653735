import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_ACTIVIDADES_TAREAS = `
        query get($filtro: InputGetActividadesDeTareas){
            getActividadesDeTareas(filtro: $filtro){
            actividadID
            tareaID
            usuarioID
            usuarioBitID
            descripcion
            responsable
            porcentaje
            estado
            partida
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            responsableID
            usuario
            colorEstado
            titulo
            programaAnualID
            folioTarea
            folioInternoProgama
            ramoSeguro
            actividad
            }
        }`;

export const GET_ALL_ACTIVIDADES_TAREAS = `
        query filtroGetAll($filtro: InputGetActividadesDeTareas){
            getAllActividadesDeTareas(filtro: $filtro){
            actividadID
            tareaID
            usuarioID
            usuarioBitID
            descripcion
            responsable
            porcentaje
            estado
            partida
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            responsableID
            usuario
            colorEstado
            titulo
            programaAnualID
            folioTarea
            folioInternoProgama
            ramoSeguro
            siglasEmpresa
            actividad
            }
        }`;

export const TABLA_ACTIVIDADES_TAREAS = `
        query filtro($filtro: InputFiltroActividadesDeTareas){
            filterActividadesDeTareas(filtro: $filtro){
            listado{
            actividadID
            tareaID
            usuarioID
            usuarioBitID
            descripcion
            responsable
            porcentaje
            estado
            partida
            avance
            fechaInicio
            fechaFinal
            fechaInicioReal
            fechaFinalReal
            responsableID
            usuario
            colorEstado
            titulo
            programaAnualID
            folioTarea
            folioInternoProgama
            ramoSeguro
            }
            registros
            paginas
            }
        }`;

export const getActividadesDeTareas = async (filtro, token) => {
    try {
        let { getActividadesDeTareas } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_ACTIVIDADES_TAREAS
        });

        return getActividadesDeTareas;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllActividadesDeTareas = async (variables, token) => {
    try {

        let { getAllActividadesDeTareas } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_ACTIVIDADES_TAREAS
        });

        let auxData = getAllActividadesDeTareas.map(({ actividadID, tareaID, usuarioID, usuarioBitID, descripcion, responsable, porcentaje, estado, partida, avance, fechaInicio, fechaFinal, fechaInicioReal, fechaFinalReal, colorEstado, titulo, programaAnualID, folioTarea, folioInternoProgama, ramoSeguro, siglasEmpresa: siglas, actividad }) => {
            return {
                title: titulo,
                start: new Date(fechaInicio),
                end: new Date(fechaInicio),
                fechaInicio: fechaInicio,
                fechaFinal: fechaFinal,
                fechaInicioReal: fechaInicioReal,
                fechaFinalReal: fechaFinalReal,
                actividadID,
                tareaID,
                usuarioID,
                usuarioBitID,
                responsable,
                porcentaje,
                estado,
                partida,
                avance,
                color: colorEstado,
                descripcion,
                programaAnualID,
                folioTarea,
                folioInternoProgama,
                titulo,
                colorEstado,
                ramoSeguro,
                siglas,
                actividad
            }
        })

        return auxData;

    } catch ({ message }) { throw new Error(message); }
}

export const seleccionActividadesDeTareas = async (variables, token) => {
    try {

        let allActividadesDeTareas = await getAllActividadesDeTareas(variables, token);

        if (!Array.isArray(allActividadesDeTareas)) throw new Error("No se encontraron registros");

        return allActividadesDeTareas.map(({ actividadID: value, descripcion: label }) => ({ value, label: label.toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaActividadesDeTareas = async (variables, token) => {
    try {
        let { filterActividadesDeTareas } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_ACTIVIDADES_TAREAS
        });
        return filterActividadesDeTareas;
    } catch ({ message }) { throw new Error(message); }
}
