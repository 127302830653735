import React, { useState, useRef, useEffect } from 'react'
import { Validaciones, Encriptar } from '@renedelangel/helpers';

// Redux
import { useSelector } from 'react-redux';

// master components
import Tabla from '../../../_layout/masterComponents/Tabla';
import FormularioModal from '../../../_layout/masterComponents/FormularioModal';

// generic components
import SweetAlert from '../../../_layout/genericComponents/ModalConfirmacion';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import SyncIcon from '@material-ui/icons/Sync';
import SaveIcon from '@material-ui/icons/Save';
import BusinessIcon from '@material-ui/icons/Business';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { RiFileExcel2Fill } from "react-icons/ri";
import { NetworkError } from "../../../_layout/genericComponents/Metodos";
import BackspaceOutlined from "@material-ui/icons/BackspaceOutlined";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import ExplicitIcon from '@material-ui/icons/Explicit';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { getControlPago, tablaControlPagos } from '../../../querys/ControlPago';
import { seleccionAseguradora } from '../../../querys/Aseguradoras';
import { seleccionAgentesSeguro } from '../../../querys/AgentesSeguros';
import { seleccionRamoSeguro } from '../../../querys/RamoSeguro';
import { seleccionEmpresa } from '../../../querys/Empresas';
import { seleccionDepartamento } from 'querys/Departamento';

import { addControlPago, updateControlPago, deleteControlPago, generarPolizaDesdePago } from '../../../mutations/ControlPago';
import { useLocation } from 'react-router-dom';
import { seleccionPolizasSeguro, seleccionableSubPolizasSeguro, getPolizasSeguro,getAllSubPolizasSeguro} from '../../../querys/PolizasSeguro';
import { upsertFile, deleteFile, tablaServerStorage, getFile } from '../../../mutations/ServerStorage'
//storage
import { actualizaArchivo, eliminaArchivo, getArchivo } from "../../../helpers/serverStorage";

import { showFile } from '../../../helpers';

import { handleFocus, handleErrorInputText, handleModificar, handelAlertEliminar, handleEliminar, handleGuardar, ftPrecarga, handleAlertMutationGeneric, handleAlertGeneric, handleGetGeneric } from '../../../_layout/helpers/handles';
import { rgxdeInt2 } from '../../../helpers/regexp';
import { tablaTrazabilidadSolicitudesSeguro, tablaDocumentosAdjuntos } from '../../../querys/TrazabilidadSolicitudesSeguro';
import { info, danger, success, primary, rose, warning } from '../../../_layout/helpers/colores';
import { getSolicitudSeguro } from '../../../querys/SolicitudesSeguros/';
import { rgxLetras, rgxNumeros } from '../../../helpers/regexp';
import moment from 'moment';
import { BrowserRouter as Router, Switch, Route, useParams, useHistory } from "react-router-dom";

import { tipoSolicitudSeguro } from '../../../helpers/datosFijos/tipoSolicitudSeguro';

import { estadoSolicitudSeguro } from 'helpers/datosFijos/estadoSolicitudSeguro';

import { urlServidor } from '../../../helpers';
import * as Permisos from '../Permisos';
import MultiPartidas from '../../../_layout/NuevosComponentes/MultiPartidas';
import Modal from '_layout/genericComponents/Modal';

function IconoSimbolo({ simbolo = "" }) { return (<span>{simbolo}</span>); }

const { trim } = Validaciones;
const { Decrypt } = Encriptar;

function TrazabilidadSolicitudesSeguro({ datosDetalle, setOpenTrazabilidad }) {
    const { Encrypt, Decrypt } = Encriptar;
    const { token } = useSelector(state => state.login);
    const formato = "YYYY-MM-DD";
    const location = useLocation();
    let history = useHistory();
    let formatoDelmomento = "DD/MM/YYYY";
    let nsolSegFianID = null;
    let ncontrolPagoID = null;
    let nPolizasSeguroID = null;
    let nOrigen = null;
    let now = moment();
    let titleAccion = "Consultar";
    let titleModalSol = `${titleAccion} Datos de Solicitud`;
    let titleModalPago = `${titleAccion} Datos de Control de Pago`;
    let titleModalPolizaPrincipal = `${titleAccion} Datos de Póliza Principal`;
    let lUtilizaProps = true;

    const listEstatus = [
        {
            label: 'COMPLETADO',
            value: 'COMPLETADO'
        },
        {
            label: 'EN PROCESO',
            value: 'EN PROCESO'
        },
        {
            label: 'PENDIENTE',
            value: 'PENDIENTE'
        },
    ];

    const listOpciones = [
        { label: 'NO', value: 0 },
        { label: 'SI', value: 1 }
    ]

    const listporcentajeIVA = [
        { label: '% 0', value: 0 },
        { label: '% 16', value: 16 }
    ]

    const listDivisas = [
        {
            label: 'MXN',
            value: 69
        },
        {
            label: 'USD',
            value: 100
        },
    ];
    const cleanaTiposPoliza = [
        {
            label: 'PRINCIPAL',
            value: 'PRINCIPAL'
        },
        {
            label: 'POLIZA',
            value: 'POLIZA'
        },
        {
            label: 'ENDOSO',
            value: 'ENDOSO'
        }
    ];

    let { params } = useParams();
    if (params != undefined && params !== ":params") {
        let url = params.replace(/&/g, "/");
        let seed = "scrumweb";
        let { pSolSegFianID, pControlPagoID, pPolizasSeguroID, pOrigen } = Decrypt({ data: url, seed }) !== "" ? JSON.parse(Decrypt({ data: url, seed })) : null;
        nsolSegFianID = pSolSegFianID;
        nPolizasSeguroID = pPolizasSeguroID;
        ncontrolPagoID = pControlPagoID;
        nOrigen = pOrigen;

    }


    if (nsolSegFianID != undefined && nsolSegFianID.length > 0) {
        lUtilizaProps = false;
    }

    if (lUtilizaProps) {
        let { pSolSegFianID, pControlPagoID, pPolizasSeguroID, pOrigen } = datosDetalle;
        nsolSegFianID = pSolSegFianID;
        nPolizasSeguroID = pPolizasSeguroID;
        ncontrolPagoID = pControlPagoID;
        nOrigen = pOrigen;
    }

    const tipoValorSumaAseg = [
        {
            label: 'VALOR CONVENIDO',
            value: 'VALOR CONVENIDO'
        },
        {
            label: 'VALOR COMERCIAL + 10%',
            value: 'VALOR COMERCIAL + 10%'
        },
        {
            label: 'VALOR DE REPOSICIÓN',
            value: 'VALOR DE REPOSICIÓN'
        },
        {
            label: 'AVALUO',
            value: 'AVALUO'
        },
        {
            label: 'VALOR FACTURA',
            value: 'VALOR FACTURA'
        },
    ]

    const cleanStateSol = {
        solSegFianID: null, departamentoID: null, empresaID: null, ramoSeguroID: null, subRamoID: null,solicitud: "Alta de Seguro", solicitante: "", puesto: "",
        observaciones: "", nomAutoriza: "", fechaSolicitud: new Date(), ramoSeguro: "", estatus: "EN PROCESO", folioInterno: "", horasInvertidas: "",
        polizasSeguroID: 0,subPolizaID: "0",
    };

    const cleanErrorStateSol = {
        solicitud: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        nomAutoriza: { error: false, helperText: "" },
        solicitante: { error: false, helperText: "" },
        puesto: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        subRamoID: { error: false, helperText: "" },
        fechaSolicitud: { error: false, helperText: "" },
        departamentoID: { error: false, helperText: "" },
        ramoSeguro: { error: false, helperText: "" },
        departamento: { error: false, helperText: "" },
        empresa: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        folioInterno: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        polizasSeguroID: { error: false, helperText: "" },
        subPolizaID: { error: false, helperText: "" },
    }

    const SolempresaIDRef = useRef(null);
    const SolsolicitudRef = useRef(null);
    const SolramoSeguroIDRef = useRef(null);
    const SolsubRamoSeguroIDRef = useRef(null);
    const SolfechaSolicitudRef = useRef(null);
    const SolsolicitanteRef = useRef(null);
    const SolpuestoRef = useRef(null);
    const SoldepartamentoIDRef = useRef(null);
    const SolobservacionesRef = useRef(null);
    const SolnomAutorizaRef = useRef(null);
    const SolestatusRef = useRef(null);
    const SolsolSegFianIDRef = useRef(null);
    const SolfolioFormateadoRef = useRef(null);
    const SolhorasInvertidasRef = useRef(null);
    const SolpolizasSeguroIDRef = useRef(null);
    const SolsubpolizasSeguroIDRef = useRef(null);

    // Seleccionables
    const [seleccionables, setSeleccionables] = useState({});

    const [stateSol, setStateSol] = useState(cleanStateSol);
    const cleanNotificaciones = { mensaje: "", color: null, open: false };

    const [errorstateSol, setErrorStateSol] = useState(cleanErrorStateSol);
    const [notificaciones, setNotificaciones] = useState(cleanNotificaciones);

    const [disabled, setDisabled] = useState(false);
    const [alert, setAlert] = useState(null);
    const [loader, setLoader] = useState(false);
    const [actualizar, setActualizar] = useState(false);
    const [openVerSolicitud, setOpenVerSolicitud] = useState(false);
    const [openTablControlPagos, setopenTablControlPagos] = useState(false);
    const [datosPartidas, setDatosPartidas] = useState(null);
    const [datosPartidasGet, setDatosPartidasGet] = useState(null);

    /* Control de pago */

    const cleanStatePago = {
        controlPagoID: null,
        numero: "",
        fechaTramite: now,
        empresaID: "",
        aseguradoraID: "",
        agenteSegID: "",
        descripcion: "",
        ramoSeguroID: "",
        folioTramite: "",
        poliza: "",
        endoso: "",
        importePago: "",
        solicitado: now,
        realizado: now,
        divisaID: 69,
        folioFactura: "",
        pdf: 0,
        xml: 0,
        docOrig: 0,
        estatus: "PENDIENTE",
        observaciones: "",
        horasInvertidas: "",
        polizaID: 0,
        vigenciaIni: now,
        vigenciaHasta: now,
        paridad: 0,
        subRamoID: 0,
        importePago:0,
        gastosExpedicion:0,
        porcentajeIVA:0,
        iva:0,
        total:0,
        devolucion:0,
        notaCredito:0,
        subPolizaID:0,
        inciso:"",
        diasVigencia:0

    };
    const cleanStateMultiple = { usuarioID: null, empresasIDs: [] };

    const cleanErrorStatePago = {
        controlPagoID: { error: false, helperText: "" },
        numero: { error: false, helperText: "" },
        fechaTramite: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        agenteSegID: { error: false, helperText: "" },
        descripcion: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        folioTramite: { error: false, helperText: "" },
        poliza: { error: false, helperText: "" },
        endoso: { error: false, helperText: "" },
        importePago: { error: false, helperText: "" },
        solicitado: { error: false, helperText: "" },
        realizado: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        folioFactura: { error: false, helperText: "" },
        pdf: { error: false, helperText: "" },
        xml: { error: false, helperText: "" },
        docOrig: { error: false, helperText: "" },
        estatus: { error: false, helperText: "" },
        observaciones: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        polizaID: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        subRamoID: { error: false, helperText: "" },
        importePago: { error: false, helperText: "" },
        gastosExpedicion: { error: false, helperText: "" },
        porcentajeIVA: { error: false, helperText: "" },
        iva: { error: false, helperText: "" },
        total: { error: false, helperText: "" },
        devolucion: { error: false, helperText: "" },
        notaCredito: { error: false, helperText: "" },
        subPolizaID: { error: false, helperText: "" },
        inciso: { error: false, helperText: "" },
        diasVigencia: { error: false, helperText: "" },

    }

    const [statePago, setStatePago] = useState(cleanStatePago);
    const [errorStatePago, setErrorStatePago] = useState(cleanErrorStatePago);
    const [openVerPago, setOpenVerPago] = useState(false);

    const PagocontrolPagoIDRef = useRef(null);
    const PagonumeroRef = useRef(null);
    const PagofechaTramiteRef = useRef(null);
    const PagoempresaIDRef = useRef(null);
    const PagoaseguradoraIDRef = useRef(null);
    const PagoagenteSegIDRef = useRef(null);
    const PagodescripcionRef = useRef(null);
    const PagoramoSeguroIDRef = useRef(null);
    const PagofolioTramiteRef = useRef(null);
    const PagopolizaRef = useRef(null);
    const PagoendosoRef = useRef(null);
    const PagoimportePagoRef = useRef(null);
    const PagosolicitadoRef = useRef(null);
    const PagorealizadoRef = useRef(null);
    const PagodivisaIDRef = useRef(null);
    const PagofolioFacturaRef = useRef(null);
    const PagoestatusRef = useRef(null);
    const PagoobservacionesRef = useRef(null);
    const PagohorasInvertidasRef = useRef(null);
    const PagopolizaIDRef = useRef(null);
    const PagovigenciaIniRef = useRef(null);
    const PagovigenciaHastaRef = useRef(null);
    const PagoestadoRef = useRef(null);

    /* Poliza Principal */


    const cleanStatePolPrincipal = {
        polizasSeguroID: null,
        numero: "",
        fechaSolCot: "",
        empresaID: "",
        ramoSeguroID: "",
        aseguradoraID: "",
        numpolEndoso: "",
        divisaID: 69,
        sumaAseg: "",
        primaNeta: "",
        fpPromedio: "",
        vigenciaIni: now.format(formatoDelmomento),
        vigenciaHasta: "",
        exclusiones: "",
        renovacion: false,
        horasInvertidas: "",
        tipoValorAseg: "",
        tipoPoliza: "PRINCIPAL",
        polizaOrigenID: null,
        seleccionTipoPoliza:"1",
        derechoPoliza:"",
        ramoSeguro:"",
        paridad:"",
        inciso:"",
        beneficiarioPreferente:"",
        aseguradoAdicional:""
    };
    const cleanErrorStatePolPrincipal = {
        numero: { error: false, helperText: "" },
        fechaSolCot: { error: false, helperText: "" },
        empresaID: { error: false, helperText: "" },
        ramoSeguroID: { error: false, helperText: "" },
        aseguradoraID: { error: false, helperText: "" },
        numpolEndoso: { error: false, helperText: "" },
        divisaID: { error: false, helperText: "" },
        sumaAseg: { error: false, helperText: "" },
        primaNeta: { error: false, helperText: "" },
        fpPromedio: { error: false, helperText: "" },
        vigenciaIni: { error: false, helperText: "" },
        vigenciaHasta: { error: false, helperText: "" },
        exclusiones: { error: false, helperText: "" },
        renovacion: { error: false, helperText: "" },
        horasInvertidas: { error: false, helperText: "" },
        tipoValorAseg: { error: false, helperText: "" },
        tipoPoliza: { error: false, helperText: "" },
        polizaOrigenID: { error: false, helperText: "" },
        seleccionTipoPoliza: { error: false, helperText: "" },
        derechoPoliza: { error: false, helperText: "" },
        ramoSeguro: { error: false, helperText: "" },
        paridad: { error: false, helperText: "" },
        inciso: { error: false, helperText: "" },
        beneficiarioPreferente: { error: false, helperText: "" },
        aseguradoAdicional: { error: false, helperText: "" }
    }
    const PolPrincipalnumeroRef = useRef(null);
    const PolPrincipaltipoValorAsegRef = useRef(null);
    const PolPrincipalfechaSolCotRef = useRef(null);
    const PolPrincipalempresaIDRef = useRef(null);
    const PolPrincipalramoSeguroIDRef = useRef(null);
    const PolPrincipalaseguradoraIDRef = useRef(null);
    const PolPrincipalnumpolEndosoRef = useRef(null);
    const PolPrincipaldivisaIDRef = useRef(null);
    const PolPrincipalsumaAsegRef = useRef(null);
    const PolPrincipalprimaNetaRef = useRef(null);
    const PolPrincipalfpPromedioRef = useRef(null);
    const PolPrincipalvigenciaIniRef = useRef(null);
    const PolPrincipalvigenciaHastaRef = useRef(null);
    const PolPrincipalexclusionesRef = useRef(null);
    const PolPrincipalhorasInvertidasRef = useRef(null);
    const PolPrincipaltipoPolizaRef = useRef(null);
    const PolPrincipalpolizaOrigenIDRef = useRef(null);
    const PolPrincipalseleccionTipoPolizaRef = useRef(null);
    const PolPrincipalDerechoPolizaRef = useRef(null);
    const PolPrincipalIncisoRef = useRef(null);
    const PolPrincipalbeneficiarioPreferenteRef = useRef(null);
    const PolPrincipalbaseguradoAdicionalRef = useRef(null);

    const [statePolPrincipal, setStatePolPrincipal] = useState(cleanStatePolPrincipal);
    const [errorStatePolPrincipal, setErrorStatePolPrincipal] = useState(cleanErrorStatePolPrincipal);
    const [openVerPolPrincipal, setOpenVerPolPrincipal] = useState(false);
    const [aTiposPoliza, setActualizaraTiposPoliza] = useState(cleanaTiposPoliza);

    /* adjuntos */
    let titleModal = `${titleAccion} documento`;
    let ArrayRef = [];
    var Tipos = [];
    var Clasificaciones = [];
    let remover = false;
    const cleanStateAdjuntos = { serverStorageID: null };
    const cleanErrorStateAdjuntos = {}

    let [stDatos, setStateAdjuntos] = useState(cleanStateAdjuntos);
    const [errorState, setErrorState] = useState(cleanErrorStateAdjuntos);
    const [documento, setDocumento] = useState({ valor: "", file: "", eliminar: remover });
    

    const botones = [
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {

                if (lUtilizaProps) {
                    setOpenTrazabilidad();
                } else {
                    history.goBack();
                }

            }
        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => {
                setActualizar(!actualizar);
                //  setActualizarDetalle(!actualizarDetalle);
                // setlConPartidas(true);
            },
            disabled: { multiseleccion: true }
        }];

    const botonesControlPago = [
        {
            icono: BackspaceOutlined,
            color: danger,
            descripcion: "Regresar",
            disabled: { multiseleccion: true },
            onClick: () => {
                setopenTablControlPagos(false);
            }
        },
        {
            icono: SyncIcon,
            color: info,
            descripcion: "Actualizar",
            onClick: () => {
                setActualizar(!actualizar);
            },
            disabled: { multiseleccion: true }
        }];


    let tAcciones = [ ];

        if(ncontrolPagoID !== 0 && ncontrolPagoID !== undefined && ncontrolPagoID !== null){
            tAcciones = [...tAcciones, {
                accionID: [3],
                icono: PaymentIcon,
                color: info,
                descripcion: "Ver Control de Pago",
                parametros: [{ campo: "controlPagoID" }],
                disabled: { multiseleccion: true },
                onClick: (parametros) => {
                    if (nOrigen == "Poliza"){
                        setopenTablControlPagos(true)
                    }
                    else{
                        if (parametros.controlPagoID !== -1) {
                            actualizarSeleccionables({})
                            handleModificar({
                                parametros, token, setState: setStatePago, setOpen: setOpenVerPago, setAlert,
                                asyncGet: getControlPago,
        
                            })
        
                        }
                    }
                }
            },]
        }
    const accionesControlPago = [
        {
            accionID: [3],
            icono: VisibilityIcon,
            color: info,
            descripcion: "Ver Control de Pago",
            parametros: [{ campo: "controlPagoID" }],
            disabled: { multiseleccion: true },
            onClick: (parametros) => {
                if (parametros.controlPagoID !== -1) {
                    actualizarSeleccionables({})
                    handleModificar({
                        parametros, token, setState: setStatePago, setOpen: setOpenVerPago, setAlert,
                        asyncGet: getControlPago,
                    })
                }
            }
        }
    ]

        if(nPolizasSeguroID !== 0 && nPolizasSeguroID !== undefined && nPolizasSeguroID !== null){
            tAcciones = [...tAcciones, {
                accionID: [4],
                icono: LocalParkingIcon,
                color: info,
                descripcion: "Ver Póliza Principal",
                parametros: [{ campo: "polizaPrincipalID" }],
                disabled: { multiseleccion: true },
                onClick: (parametros) => {
                    if (parametros.polizaPrincipalID !== -1) {
                        actualizarSeleccionables({});
                        obtenerValoresMultipartidas(parametros);
                        let parametros2 = { polizasSeguroID: parametros.polizaPrincipalID }
                        handleModificar({
                            parametros: parametros2, token, setState: setStatePolPrincipal, setOpen: setOpenVerPolPrincipal, setAlert,
                            asyncGet: getPolizasSeguro,
    
                        })
    
                    }
                }
            }]
        }


        if(nsolSegFianID !== 0 && nsolSegFianID !== undefined && nsolSegFianID !== null){
            tAcciones = [...tAcciones,  {
                accionID: [2],
                icono: DesktopMacIcon,
                color: rose,
                descripcion: "Ver Solicitud",
                parametros: [{ campo: "solSegFianID" }, { campo: "estatus" }],
                disabled: { multiseleccion: true },
                onClick: (parametros) => {
                    if (parametros.solSegFianID !== -1) {
                        actualizarSeleccionables({polizasSeguroID: nPolizasSeguroID !== 0 && nPolizasSeguroID !== undefined && nPolizasSeguroID !== null ? Number(nPolizasSeguroID) : null});
                        handleModificar({
                            parametros, token, setState: setStateSol, setOpen: setOpenVerSolicitud, setAlert,
                            asyncGet: getSolicitudSeguro,
                            formatearResultado: [{ id: "fechaSolicitud", formato: (data) => moment(data) }]
    
                        })
                    }
                }
    
            }]
        }
        if (nOrigen === "Solicitud" || nOrigen === 'Control'){
            tAcciones = [...tAcciones, {
                accionID: [5],
                icono: ExplicitIcon,
                color: info,
                descripcion: "Ver Póliza / Endoso",
                parametros: [{ campo: "polizasSeguroID" }],
                disabled: { multiseleccion: true },
                onClick: (parametros) => {
                    if (parametros.polizasSeguroID !== -1) {
                        actualizarSeleccionables({});
                        handleModificar({
                            parametros, token, setState: setStatePolPrincipal, setOpen: setOpenVerPolPrincipal, setAlert,
                            asyncGet: getPolizasSeguro,
    
                        })
    
                    }
                }
            },
               ]
        }

    const [acciones, setAcciones] = useState(tAcciones);
    const [accionesUsr, setAccionesUsr] = useState();
    useEffect(() => {
        async function ftEffect() {
            try {
                Permisos.PermisosAState(setAccionesUsr, token, 42);
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            try {
                if (!accionesUsr) {
                    return;
                }
                let tAcciones = Permisos.ValidarAcciones([...acciones], accionesUsr);
                
                setAcciones(tAcciones);
            } catch ({ message }) {
                setAlert({
                    descripcion: `Algunos datos necesarios para funcionar no se cargaron correctamente, intenta actualizar la página o verifica tu conexión a internet; a continuación se muestrán más detalles del error: ${NetworkError(message)}`,
                    title: "¡Una disculpa!",
                    tipo: danger,
                    msjConfirmacion: "Aceptar",
                    onConfirm: () => setAlert(null)
                });
            }
        } ftEffect();
    }, [accionesUsr]);

    const infoTabla = {
        botones,
        acciones,
        actualizar,
        id: "folio",
        color: primary,
        title: (nOrigen === "Solicitud" ? "Trazabilidad de Solicitudes de Seguro" : (nOrigen === "Poliza" ? "Trazabilidad de Pólizas de Seguro" : "Trazabilidad de Control de Pago")),
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "solSegFianID", descripcion: "solSegFianID", hide: true },
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true },
            { variable: "polizasSeguroID", descripcion: "polizasSeguroID", hide: true },
            { variable: "polizaPrincipalID", descripcion: "polizaPrincipalID", hide: true },
            { variable: "siglas", descripcion: "Empresa" },
            { variable: "ramoSeguro", descripcion: "Ramo Seguro", hide: false },
            { variable: "folioSolicitud", descripcion: "Folio", hide: false },
            { variable: "fechaSolicitud", descripcion: "Fecha Solicitud", hide: false },
            { variable: "folioControlPago", descripcion: "Folio Control Pago", hide: false },
            { variable: "fechaTramite", descripcion: "Fecha Tramite" },
            { variable: "fechaSolCotPrincipal", descripcion: "Fecha Sol Cot Principal" },
            { variable: "folioPolizaPrincipal", descripcion: "Folio Poliza Principal" },
            { variable: "numPolEndosoPrincipal", descripcion: "Num. Pol Endoso Principal" },
            { variable: "numeroPrincipal", descripcion: "Numero Principal" },
            { variable: "fechaSolCot", descripcion: "Fecha Sol Cot" },
            { variable: "folioPoliza", descripcion: "Folio Poliza" },
            { variable: "numPolEndoso", descripcion: "Num. Pol Endoso" },
            { variable: "numero", descripcion: "Numero" }
        ],
        responsiveTitle: ["folioSolicitud", "folioControlPago", "folioPoliza"],
        formato: [{ columnas: ["fechaSolicitud", "fechaTramite", "fechaSolCot", "fechaSolCotPrincipal"], tipo: "fecha", onlyDate: true },]
    }

    const infoTablaControlPagos = {
        botones: botonesControlPago,
        acciones: accionesControlPago,
        actualizar,
        id: "controlPagoID",
        color: primary,
        title: "Control de Pagos",
        iconTable: <BusinessIcon />,
        headers: [
            { variable: "controlPagoID", descripcion: "controlPagoID", hide: true },
            { variable: "polizaID", descripcion: "polizaID", hide: true },
            { variable: "solSegFianID", descripcion: "solSegFianID", hide: true },
            { variable: "folioInterno", descripcion: "Folio Interno", hide: false },
            { variable: "empresaID", descripcion: "empresaID", hide: true },
            { variable: "descripcion", descripcion: "Descripción" },
            { variable: "numero", descripcion: "No.", hide: true  },
            { variable: "fechaTramite", descripcion: "Fecha Tramite" },
            { variable: "fechaModificacion", descripcion: "Fecha Modificación" },
            { variable: "empresa", descripcion: "Empresa" },
            { variable: "aseguradora", descripcion: "Aseguradora" },
            { variable: "agenteSeguro", descripcion: "Agente Seguros" },
            { variable: "folioTramite", descripcion: "Folio Tramite" },
            { variable: "importePago", descripcion: "Prima neta" },
            { variable: "polizaPrincipal", descripcion: "Póliza Principal" },
            { variable: "endoso", descripcion: "Endoso" },
            { variable: "folioInternoSolSeguro", descripcion: "Folio Sol. Seguro" },
            { variable: "estatus", descripcion: "Estado" },
            { variable: "folioReclamacion", descripcion: "Folio RGR-002 " },
            { variable: "siniesReclAsegID", descripcion: "siniesReclAsegID", hide: true },
            { variable: "reporteDaniosID", descripcion: "reporteDaniosID", hide: true },
            { variable: "tipoPoliza", descripcion: "tipoPoliza", hide: true },
        ],
        formato: [
            {
                columnas: ["importePago"], tipo: "moneda"
            },
            {
                columnas: ["fechaTramite", "fechaModificacion"], tipo: "fecha", onlyDate: true
            },
            {
                columnas: ["folioTramite"], tipo: "number"
            },
        ],
        responsiveTitle: ["folioInterno", "fechaTramite", "empresa", "aseguradora", "agenteSeguro"],
        filter: [
            { campo: "poliza", placeholder: "Póliza" },
            { campo: "folioTramite", placeholder: "Folio Tramite" },
        ],
        // rangoFechas: { ini: "fechaIni", fin: "fechaFin", fechaInicial: moment(cFechaFiltroInicial, "YYYYMMDD"), fechaFinal: moment(cFechaFiltroFinal, "YYYYMMDD") },
        alineacion: [{ columnas: [0, 1, 2], alineacion: "left" }, { columnas: [5, 6], alineacion: "right" }],
        selectFilter: [
            {
                campo: "empresaID", title: "Empresa",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.empresas ? seleccionables.empresas : [], grid: { md: 3, lg: 3 }
            }, {
                campo: "ramoSeguroID", title: "Ramo de Seguro",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.ramoSeguros ? seleccionables.ramoSeguros : [], grid: { md: 3, lg: 3 }
            },

            {
                campo: "aseguradoraID", title: "Aseguradora",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.aseguradoras ? seleccionables.aseguradoras : [], grid: { md: 3, lg: 3 }
            },
            {
                campo: "agenteSegID", title: "Agente de Seguro",
                retorna: "number", limpiarFiltro: "Todos",
                data: seleccionables.agenteSeguros ? seleccionables.agenteSeguros : [], grid: { md: 3, lg: 3 }
            },

            // {
            //     campo: "polizaID", title: "Poliza Principal",
            //     retorna: "number", limpiarFiltro: "Todos",
            //     data: seleccionables.polizasSeguro ? seleccionables.polizasSeguro : [], grid: { md: 3, lg: 3 }
            // },
            {
                campo: "estatus", title: "Estado", limpiarFiltro: "Todos",
                data: listEstatus, grid: { md: 3, lg: 3 }
            },
        ]
    }


    const accionesFormularioVerSol = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerSol
    }];

    const accionesFormularioVerPago = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerPago
    }];

    const accionesFormularioVerPolizaPrincipal = [{
        disabled,
        icono: Close,
        color: danger,
        descripcion: "Cerrar",
        onClick: handleCloseVerPolizaPrincipal
    }];


    const inputsSolicitud = [

        {
            disabled: true,
            id: "empresaID",
            value: stateSol.empresaID,
            error: errorstateSol.empresaID.error,
            success: stateSol.empresaID && !errorstateSol.empresaID.error ? true : undefined,
            helperText: errorstateSol.empresaID.helperText,
            inputRef: SolempresaIDRef,
            title: "Empresa *",
            placeholder: "Seleccione empresa",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.empresas
        },
        {
            disabled: true,
            id: "solicitud",
            value: stateSol.solicitud,
            error: errorstateSol.solicitud.error,
            success: stateSol.solicitud && !errorstateSol.solicitud.error ? true :
                undefined,
            helperText: errorstateSol.solicitud.helperText,
            inputRef: SolsolicitudRef,
            title: "Tipo Solicitud",
            placeholder: "Seleccione el tipo de solicitud",
            tipo: "select",
            data: tipoSolicitudSeguro,
            grid: { md: 3, lg: 3 }
        }, {
            disabled: true,
            id: "fechaSolicitud",
            value: stateSol.fechaSolicitud,
            error: errorstateSol.fechaSolicitud.error,
            success: stateSol.fechaSolicitud && !errorstateSol.fechaSolicitud.error ? true : undefined,
            helperText: errorstateSol.fechaSolicitud.helperText,
            inputRef: SolfechaSolicitudRef,
            title: "Fecha Solicitud *",
            grid: { md: 3, lg: 3 },
            placeholder: "Fecha Solicitud",
            tipo: "datetimepicker",
            onlyDate: true
        },
        {
            disabled: true,
            id: "ramoSeguroID",
            value: stateSol.ramoSeguroID,
            error: errorstateSol.ramoSeguroID.error,
            success: stateSol.ramoSeguroID && !errorstateSol.ramoSeguroID.error ? true : undefined,
            helperText: errorstateSol.ramoSeguroID.helperText,
            inputRef: SolramoSeguroIDRef,
            title: "Ramo de Seguro *",
            placeholder: "Seleccione el Ramo de Seguro",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.ramoSeguros
        },
        {
            disabled:true,
            id: "subRamoID",
            value: stateSol.subRamoID,
            error: errorstateSol.subRamoID.error,
            success: stateSol.subRamoID && !errorstateSol.subRamoID.error ? true : undefined,
            helperText: errorstateSol.subRamoID.helperText,
            inputRef: SolsubRamoSeguroIDRef,
            title: "Cobertura *",
            placeholder: "Seleccione la Cobertura",
            grid: { md: 6, lg: 6 },
            tipo: "autocomplete",
            data: seleccionables.ramoSeguros
        },
        {
            disabled: true,
            id: "solicitante",
            value: stateSol.solicitante,
            error: errorstateSol.solicitante.error,
            success: stateSol.solicitante && !errorstateSol.solicitante.error ? true :
                undefined,
            helperText: errorstateSol.solicitante.helperText,
            inputRef: SolsolicitanteRef,
            title: "Solicitante *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el solicitante"
        },
        {
            disabled: true,
            id: "puesto",
            value: stateSol.puesto,
            error: errorstateSol.puesto.error,
            success: stateSol.puesto && !errorstateSol.puesto.error ? true :
                undefined,
            helperText: errorstateSol.puesto.helperText,
            inputRef: SolpuestoRef,
            title: "Puesto *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el puesto"
        }
        ,
        {
            disabled: true,
            id: "departamentoID",
            value: stateSol.departamentoID,
            error: errorstateSol.departamentoID.error,
            success: stateSol.departamentoID && !errorstateSol.departamentoID.error ? true : undefined,
            helperText: errorstateSol.departamentoID.helperText,
            inputRef: SoldepartamentoIDRef,
            title: "Departamento *",
            placeholder: "Seleccione el departamento",
            grid: { md: 4, lg: 4 },
            tipo: "autocomplete",
            data: seleccionables.departamentos
        },
        {
            disabled: true,
            id: "observaciones",
            value: stateSol.observaciones,
            error: errorstateSol.observaciones.error,
            success: stateSol.observaciones && !errorstateSol.observaciones.error ? true :
                undefined,
            helperText: errorstateSol.observaciones.helperText,
            inputRef: SolobservacionesRef,
            title: "Observaciones *",
            grid: { md: 8, lg: 8 },
            placeholder: "Capture las observaciones"
        },
        {
            disabled: true,
            id: "nomAutoriza",
            value: stateSol.nomAutoriza,
            error: errorstateSol.nomAutoriza.error,
            success: stateSol.nomAutoriza && !errorstateSol.nomAutoriza.error ? true :
                undefined,
            helperText: errorstateSol.nomAutoriza.helperText,
            inputRef: SolnomAutorizaRef,
            title: "Nombre de Autorizador *",
            grid: { md: 6, lg: 6 },
            placeholder: "Capture el nombre del autorizador"
        }, {
            disabled: true,
            id: "estatus",
            value: stateSol.estatus,
            error: errorstateSol.estatus.error,
            success: stateSol.estatus && !errorstateSol.estatus.error ? true :
                undefined,
            helperText: errorstateSol.estatus.helperText,
            inputRef: SolestatusRef,
            title: "Estatus*",
            placeholder: "Especifique el estatus",
            tipo: "select",
            data: estadoSolicitudSeguro,
            grid: { md: 3, lg: 3 }
        },
        {
            disabled: true,
            id: "horasInvertidas",
            value: stateSol.horasInvertidas,
            error: errorstateSol.horasInvertidas.error,
            success: stateSol.horasInvertidas && !errorstateSol.horasInvertidas.error ? true :
                undefined,
            helperText: errorstateSol.horasInvertidas.helperText,
            inputRef: SolhorasInvertidasRef,
            title: "Horas Invertidas",
            grid: { md: 3, lg: 3 },
            placeholder: "Capture las horas invertidas"
        },
        {
            disabled: true,
            id: "polizasSeguroID",
            value: stateSol.polizasSeguroID,
            error: errorstateSol.polizasSeguroID.error,
            success: stateSol.polizasSeguroID && !errorstateSol.polizasSeguroID.error ? true : undefined,
            helperText: errorstateSol.polizasSeguroID.helperText,
            inputRef: SolpolizasSeguroIDRef,
            title: "Póliza de seguro",
            placeholder: "Seleccione la póliza de seguro",
            grid: { md: 6, lg: 6 },
            tipo: "select",
            data: seleccionables.polizasSeguro
        },
        {
            disabled:true,
            id: "subPolizaID",
            value: stateSol.subPolizaID,
            error: errorstateSol.subPolizaID.error,
            success: stateSol.subPolizaID && !errorstateSol.subPolizaID.error ? true : undefined,
            helperText: errorstateSol.subPolizaID.helperText,
            inputRef: SolsubpolizasSeguroIDRef,
            title: "Folio de Cobertura",
            placeholder: "Seleccione el Folio de Cobertura",
            grid: { md: 6, lg: 6 },
            tipo: "select",
            data: seleccionables.subPolizasSeguro
        }
    ];

    const inputsPago = [

        {
            id: "Datos del Pago",
            tipo: "fieldset",
            leyenda: "Datos del Pago",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "fechaTramite",
                    value: statePago.fechaTramite,
                    error: errorStatePago.fechaTramite.error,
                    success: statePago.fechaTramite && !errorStatePago.fechaTramite.error ? true : undefined,
                    helperText: errorStatePago.fechaTramite.helperText,
                    inputRef: PagofechaTramiteRef,
                    placeholder: "Fecha Tramite",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled: true,
                    id: "empresaID",
                    value: statePago.empresaID,
                    error: errorStatePago.empresaID.error,
                    success: Array.isArray(statePago.empresaID) && statePago.empresaID.length > 0 && statePago.empresaID && !errorStatePago.empresaID.error ? true : undefined,
                    helperText: errorStatePago.empresaID.helperText,
                    inputRef: PagoempresaIDRef,
                    title: "Empresa *",
                    placeholder: "Seleccionar empresa",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.empresas
                },
                {
                    disabled: true,
                    id: "aseguradoraID",
                    value: statePago.aseguradoraID,
                    error: errorStatePago.aseguradoraID.error,
                    success: Array.isArray(statePago.aseguradoraID) && statePago.aseguradoraID.length > 0 && statePago.aseguradoraID && !errorStatePago.aseguradoraID.error ? true : undefined,
                    helperText: errorStatePago.aseguradoraID.helperText,
                    inputRef: PagoaseguradoraIDRef,
                    title: "Aseguradora *",
                    placeholder: "Seleccionar Aseguradora",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.aseguradoras
                },
                {
                    disabled: true,
                    id: "agenteSegID",
                    value: statePago.agenteSegID,
                    error: errorStatePago.agenteSegID.error,
                    success: Array.isArray(statePago.agenteSegID) && statePago.agenteSegID.length > 0 && statePago.agenteSegID && !errorStatePago.agenteSegID.error ? true : undefined,
                    helperText: errorStatePago.agenteSegID.helperText,
                    inputRef: PagoagenteSegIDRef,
                    title: "Agente de Seguros *",
                    placeholder: "Seleccionar Agente",
                    grid: { md: 3, lg: 3 },
                    tipo: "autocomplete",
                    data: seleccionables.agenteSeguros
                },
                {
                    disabled: true,
                    id: "descripcion",
                    value: statePago.descripcion,
                    error: errorStatePago.descripcion.error,
                    success: statePago.descripcion && !errorStatePago.descripcion.error ? true : undefined,
                    helperText: errorStatePago.descripcion.helperText,
                    inputRef: PagodescripcionRef,
                    title: "Descripción *",
                    placeholder: "Capture la descripción",
                    grid: { md: 7, lg: 7 }
                },
                {
                    disabled: true,
                    id: "folioTramite",
                    value: statePago.folioTramite,
                    error: errorStatePago.folioTramite.error,
                    success: statePago.folioTramite && !errorStatePago.folioTramite.error ? true : undefined,
                    helperText: errorStatePago.folioTramite.helperText,
                    inputRef: PagofolioTramiteRef,
                    title: "Folio de Tramite ",
                    placeholder: "Capture el folio de tramite",
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled: true,
                    id: "divisaID",
                    value: statePago.divisaID,
                    error: errorStatePago.divisaID.error,
                    success: Array.isArray(statePago.divisaID) && statePago.divisaID.length > 0 && statePago.divisaID && !errorStatePago.divisaID.error ? true : undefined,
                    helperText: errorStatePago.divisaID.helperText,
                    inputRef: PagodivisaIDRef,
                    title: "Divisa",
                    placeholder: "Seleccionar la divisa",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listDivisas
                },
                {
                    disabled: true,
                    id: "paridad",
                    value: statePago.paridad,
                    error: errorStatePago.paridad.error,
                    success: statePago.paridad && !errorStatePago.paridad.error ? true : undefined,
                    helperText: errorStatePago.paridad.helperText,
                    title: "Paridad Actual ",
                    textAlign: "right",
                    placeholder: "Paridad Actual",
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled: true,
                    id: "ramoSeguroID",
                    value: statePago.ramoSeguroID,
                    error: errorStatePago.ramoSeguroID.error,
                    success: Array.isArray(statePago.ramoSeguroID) && statePago.ramoSeguroID.length > 0 && statePago.ramoSeguroID && !errorStatePago.ramoSeguroID.error ? true : undefined,
                    helperText: errorStatePago.ramoSeguroID.helperText,
                    inputRef: PagoramoSeguroIDRef,
                    title: "Ramo de Seguro *",
                    placeholder: "Seleccionar Ramo de seguro",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.ramoSeguros
                },
                {
                    disabled: true,
                    id: "subRamoID",
                    value: statePago.subRamoID,
                    error: errorStatePago.subRamoID.error,
                    success: Array.isArray(statePago.subRamoID) && statePago.subRamoID.length > 0 && statePago.subRamoID && !errorStatePago.subRamoID.error ? true : undefined,
                    helperText: errorStatePago.subRamoID.helperText,
                    title: "Cobertura *",
                    placeholder: "Seleccione la Cobertura",
                    grid: { md: 4, lg: 4 },
                    tipo: "autocomplete",
                    data: seleccionables.ramoSeguros
                },
                {
                    disabled: true,
                    id: "solicitado",
                    value: statePago.solicitado,
                    error: errorStatePago.solicitado.error,
                    success: statePago.solicitado && !errorStatePago.solicitado.error ? true : undefined,
                    helperText: errorStatePago.solicitado.helperText,
                    inputRef: PagosolicitadoRef,
                    placeholder: "Fecha Solicitado",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled: true,
                    id: "realizado",
                    value: statePago.realizado,
                    error: errorStatePago.realizado.error,
                    success: statePago.realizado && !errorStatePago.realizado.error ? true : undefined,
                    helperText: errorStatePago.realizado.helperText,
                    inputRef: PagorealizadoRef,
                    placeholder: "Fecha Realizado",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled:true,
                    id: "importePago",
                    value: statePago.importePago,
                    error: errorStatePago.importePago.error,
                    success: statePago.importePago && !errorStatePago.importePago.error ? true : undefined,
                    helperText: errorStatePago.importePago.helperText,
                    title: "Prima Neta *",
                    placeholder: "Capture la Prima Neta",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true
                },
                {
                    disabled,
                    id: "gastosExpedicion",
                    value: statePago.gastosExpedicion,
                    error: errorStatePago.gastosExpedicion.error,
                    success: statePago.gastosExpedicion && !errorStatePago.gastosExpedicion.error ? true : undefined,
                    helperText: errorStatePago.gastosExpedicion.helperText,
                    title: "Gastos de expedición *",
                    placeholder: "Capture los Gastos de Expedición",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true
                },
                {
                    disabled,
                    id: "porcentajeIVA",
                    value: statePago.porcentajeIVA,
                    error: errorStatePago.porcentajeIVA.error,
                    success: Array.isArray(statePago.porcentajeIVA) && statePago.porcentajeIVA.length > 0 && statePago.porcentajeIVA && !errorStatePago.porcentajeIVA.error ? true : undefined,
                    helperText: errorStatePago.porcentajeIVA.helperText,
                    title: "Porcentaje IVA",
                    placeholder: "% IVA",
                    grid: { md: 2, lg: 2 },
                    tipo: "select",
                    data: listporcentajeIVA
                },
                {
                    disabled: true,
                    id: "iva",
                    value: statePago.iva,
                    error: errorStatePago.iva.error,
                    success: statePago.iva && !errorStatePago.iva.error ? true : undefined,
                    helperText: errorStatePago.iva.helperText,
                    title: "IVA *",
                    placeholder: "Capture el IVA",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true
                },
                {
                    disabled: true,
                    id: "total",
                    value: statePago.total,
                    error: errorStatePago.total.error,
                    success: statePago.total && !errorStatePago.total.error ? true : undefined,
                    helperText: errorStatePago.total.helperText,
                    title: "Total *",
                    placeholder: "Capture el Total",
                    grid: { md: 3, lg: 3 },
                    textAlign: 'right',
                    icono: <IconoSimbolo simbolo="$" />,
                    posicionIcono: "start",
                    formatoImportes: true
                },
                {
                    disabled: true,
                    id: "pdf",
                    value: statePago.pdf,
                    checked: statePago.pdf,
                    title: "Factura",
                    tipo: "switch",
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled: true,
                    id: "xml",
                    value: statePago.xml,
                    checked: statePago.xml,
                    title: "XML",
                    tipo: "switch",
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled: true,
                    id: "docOrig",
                    value: statePago.docOrig,
                    checked: statePago.docOrig,
                    title: "Doc originales",
                    tipo: "switch",
                    grid: { md: 2, lg: 2 }
                },
                {
                    disabled: true,
                    id: "folioFactura",
                    value: statePago.folioFactura,
                    error: errorStatePago.folioFactura.error,
                    success: statePago.folioFactura && !errorStatePago.folioFactura.error ? true : undefined,
                    helperText: errorStatePago.folioFactura.helperText,
                    inputRef: PagofolioFacturaRef,
                    title: "No. Factura*",
                    placeholder: "Capture el folio",
                    grid: { md: 6, lg: 6 }
                },
                {
                    disabled: true,
                    id: "estatus",
                    value: statePago.estatus,
                    error: errorStatePago.estatus.error,
                    success: Array.isArray(statePago.estatus) && statePago.estatus.length > 0 && statePago.estatus && !errorStatePago.estatus.error ? true : undefined,
                    helperText: errorStatePago.estatus.helperText,
                    inputRef: PagoestatusRef,
                    title: "Estatus",
                    placeholder: "Seleccionar el estatus",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listEstatus
                },
                {
                    disabled,
                    id: "devolucion",
                    value: statePago.devolucion,
                    error: errorStatePago.devolucion.error,
                    success: Array.isArray(statePago.devolucion) && statePago.devolucion.length > 0 && statePago.devolucion && !errorStatePago.devolucion.error ? true : undefined,
                    helperText: errorStatePago.devolucion.helperText,
                    title: "¿Es una devolución / Indemnización? *",
                    placeholder: "Seleccione una Opción",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listOpciones
                }, {
                    disabled,
                    id: "notaCredito",
                    value: statePago.notaCredito,
                    error: errorStatePago.notaCredito.error,
                    success: Array.isArray(statePago.notaCredito) && statePago.notaCredito.length > 0 && statePago.notaCredito && !errorStatePago.notaCredito.error ? true : undefined,
                    helperText: errorStatePago.notaCredito.helperText,
                    title: "¿Tiene la nota de crédito? *",
                    placeholder: "Seleccione una Opción",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: listOpciones
                },
                // {
                //     disabled: true,
                //     id: "horasInvertidas",
                //     value: statePago.horasInvertidas,
                //     error: errorStatePago.horasInvertidas.error,
                //     success: statePago.horasInvertidas && !errorStatePago.horasInvertidas.error ? true : undefined,
                //     helperText: errorStatePago.horasInvertidas.helperText,
                //     inputRef: PagohorasInvertidasRef,
                //     title: "Horas Invertidas *",
                //     placeholder: "Capture las horas Invertidas",
                //     grid: { md: 3, lg: 3 },
                //     multiline: true
                // },
                {
                    disabled: true,
                    id: "observaciones",
                    value: statePago.observaciones,
                    error: errorStatePago.observaciones.error,
                    success: statePago.observaciones && !errorStatePago.observaciones.error ? true : undefined,
                    helperText: errorStatePago.observaciones.helperText,
                    inputRef: PagoobservacionesRef,
                    title: "Observaciones *",
                    placeholder: "Capture las observaciones",
                    grid: { md: 12, lg: 12 },
                    multiline: true
                },
            ]
        },
        {
            id: "Datos de Póliza",
            tipo: "fieldset",
            leyenda: "Datos de Póliza",
            grid: { lg: 12 },
            inputs: [

                {
                    disabled: true,
                    id: "polizaID",
                    value: statePago.polizaID,
                    error: errorStatePago.polizaID.error,
                    success: statePago.polizaID && !errorStatePago.polizaID.error ? true : undefined,
                    helperText: errorStatePago.polizaID.helperText,
                    inputRef: PagopolizaRef,
                    title: "Póliza",
                    placeholder: "Seleccionar póliza",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.polizasSeguro
                },
                {
                    disabled:true,
                    id: "subPolizaID",
                    value: statePago.subPolizaID,
                    error: errorStatePago.subPolizaID.error,
                    success: statePago.subPolizaID && !errorStatePago.subPolizaID.error ? true : undefined,
                    helperText: errorStatePago.subPolizaID.helperText,
                    title: "Folio de Cobertura",
                    placeholder: "Seleccione el Folio de Cobertura",
                    grid: { md: 6, lg: 6 },
                    tipo: "select",
                    data: seleccionables.subPolizasSeguro
                },
                {
                    disabled: true,
                    id: "poliza",
                    value: statePago.poliza,
                    error: errorStatePago.poliza.error,
                    success: statePago.poliza && !errorStatePago.poliza.error ? true : undefined,
                    helperText: errorStatePago.poliza.helperText,
                    inputRef: PagopolizaRef,
                    title: "Número de Póliza ",
                    placeholder: "Capturar Nuevo Número de Póliza",
                    grid: { md:4, lg:4 }
                },
                {
                    disabled: true,
                    id: "endoso",
                    value: statePago.endoso,
                    error: errorStatePago.endoso.error,
                    success: statePago.endoso && !errorStatePago.endoso.error ? true : undefined,
                    helperText: errorStatePago.endoso.helperText,
                    inputRef: PagoendosoRef,
                    title: "Endoso",
                    placeholder: "Capturar nuevo endoso",
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled:true,
                    id: "inciso",
                    value: statePago.inciso,
                    error: errorStatePago.inciso.error,
                    success: statePago.inciso && !errorStatePago.inciso.error ? true : undefined,
                    helperText: errorStatePago.inciso.helperText,
                    title: "Inciso",
                    placeholder: "Capturar el Inciso",
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled: true,
                    id: "vigenciaIni",
                    value: statePago.vigenciaIni,
                    error: errorStatePago.vigenciaIni.error,
                    success: statePago.vigenciaIni && !errorStatePago.vigenciaIni.error ? true : undefined,
                    helperText: errorStatePago.vigenciaIni.helperText,
                    inputRef: PagovigenciaIniRef,
                    placeholder: "Fecha Inicio Vigencia",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled: true,
                    id: "vigenciaHasta",
                    value: statePago.vigenciaHasta,
                    error: errorStatePago.vigenciaHasta.error,
                    success: statePago.vigenciaHasta && !errorStatePago.vigenciaHasta.error ? true : undefined,
                    helperText: errorStatePago.vigenciaHasta.helperText,
                    inputRef: PagovigenciaHastaRef,
                    placeholder: "Fecha Final de Vigencia",
                    tipo: "datetimepicker",
                    onlyDate: true,
                    grid: { md: 4, lg: 4 }
                },
                {
                    disabled:  true,
                    id: "diasVigencia",
                    value: statePago.diasVigencia,
                    error: errorStatePago.diasVigencia.error,
                    success: statePago.diasVigencia && !errorStatePago.diasVigencia.error ? true : undefined,
                    helperText: errorStatePago.diasVigencia.helperText,
                    title: "Días vigencia",
                    placeholder: "Capture los días de vigencia",
                    grid: { md: 4, lg: 4 },
                    textAlign: 'right',
                    posicionIcono: "start",
                    formatoImportes: false
                }
            ]
        }

    ];

    let inputsPolizaPrincipal = [];
    if (statePolPrincipal.seleccionTipoPoliza == 1) {
        inputsPolizaPrincipal.push(
            {
                id: "cTopAPDS",
                tipo: "fieldset",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "seleccionTipoPoliza",
                        value: statePolPrincipal.seleccionTipoPoliza,
                        error: errorStatePolPrincipal.seleccionTipoPoliza.error,
                        success: statePolPrincipal.seleccionTipoPoliza && !errorStatePolPrincipal.seleccionTipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.seleccionTipoPoliza.helperText,
                        inputRef: PolPrincipalseleccionTipoPolizaRef,
                        title: "Sencilla / Múltiple",
                        placeholder: "Sencilla / Múltiple",
                        tipo: "autocomplete",
                        data: [{ label: 'Sencilla', value: 1 }, { label: 'Multiple', value: 2 }],
                        grid: { md: 12, lg: 12 }
                    }
                ]
            },
            // :
            {
                id: "Arriba izquierdo",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "empresaID",
                        value: statePolPrincipal.empresaID,
                        error: errorStatePolPrincipal.empresaID.error,
                        success: statePolPrincipal.empresaID && !errorStatePolPrincipal.empresaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.empresaID.helperText,
                        inputRef: PolPrincipalempresaIDRef,
                        title: "Empresa *",
                        placeholder: "Seleccione una Empresa",
                        tipo: "autocomplete",
                        data: seleccionables.empresas,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "ramoSeguroID",
                        value: statePolPrincipal.ramoSeguroID,
                        error: errorStatePolPrincipal.ramoSeguroID.error,
                        success: statePolPrincipal.ramoSeguroID && !errorStatePolPrincipal.ramoSeguroID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.ramoSeguroID.helperText,
                        inputRef: PolPrincipalramoSeguroIDRef,
                        title: "Ramo de Seguro *",
                        placeholder: "Seleccione una Ramo",
                        tipo: "autocomplete",
                        data: seleccionables.ramoSeguros,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "aseguradoraID",
                        value: statePolPrincipal.aseguradoraID,
                        error: errorStatePolPrincipal.aseguradoraID.error,
                        success: statePolPrincipal.aseguradoraID && !errorStatePolPrincipal.aseguradoraID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.aseguradoraID.helperText,
                        inputRef: PolPrincipalaseguradoraIDRef,
                        title: "Aseguradora *",
                        placeholder: "Seleccione una Aseguradora",
                        tipo: "autocomplete",
                        data: seleccionables.aseguradoras,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "tipoPoliza",
                        value: statePolPrincipal.tipoPoliza,
                        error: errorStatePolPrincipal.tipoPoliza.error,
                        success: statePolPrincipal.tipoPoliza && !errorStatePolPrincipal.tipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoPoliza.helperText,
                        inputRef: PolPrincipaltipoPolizaRef,
                        title: "Tipo de Póliza *",
                        placeholder: "Seleccione un tipo",
                        tipo: "select",
                        data: aTiposPoliza,
                        grid: { md: 5, lg: 5 }
                    },
                    {
                        hidden: statePolPrincipal.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                        disabled: true,
                        id: "derechoPoliza",
                        value: statePolPrincipal.derechoPoliza,
                        // error: errorStatePolPrincipal.derechoPoliza.error,
                        //success: statePolPrincipal.derechoPoliza && !errorStatePolPrincipal.derechoPoliza.error ? true : undefined,
                        // helperText: errorStatePolPrincipal.derechoPoliza.helperText,
                        inputRef: PolPrincipalDerechoPolizaRef,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        title: "Derecho de Póliza *",
                        placeholder: "Capture el Derecho de Póliza",
                        grid: { md: 7, lg: 7 }
                    },
                    {
                        disabled: true,
                        id: "polizaOrigenID ",
                        value: statePolPrincipal.polizaOrigenID == null ? "0" : statePolPrincipal.polizaOrigenID,
                        error: errorStatePolPrincipal.polizaOrigenID.error,
                        success: statePolPrincipal.polizaOrigenID && !errorStatePolPrincipal.polizaOrigenID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.polizaOrigenID.helperText,
                        inputRef: PolPrincipalpolizaOrigenIDRef,
                        title: "Póliza Principal *",
                        placeholder: "Seleccione la poliza Principal",
                        tipo: "autocomplete",
                        data: seleccionables.polizasSeguro,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "divisaID",
                        value: statePolPrincipal.divisaID,
                        error: errorStatePolPrincipal.divisaID.error,
                        success: statePolPrincipal.divisaID && !errorStatePolPrincipal.divisaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.divisaID.helperText,
                        inputRef: PolPrincipaldivisaIDRef,
                        title: "Divisa",
                        placeholder: "Seleccionar la Divisa",
                        grid: { md: 6, lg: 6 },
                        tipo: "autocomplete",
                        data: listDivisas
                    },
                    {
                        disabled: true,
                        id: "paridad",
                        value: statePolPrincipal.paridad,
                        error: errorStatePolPrincipal.paridad.error,
                        //success: statePolPrincipal.paridad && !errorStatePolPrincipal.paridad.error ? true : undefined,
                        helperText: errorStatePolPrincipal.paridad.helperText,
                        title: "Paridad Actual ",
                        textAlign: "right",
                        placeholder: "Paridad Actual",
                        grid: { md: 6, lg: 6 }
                    }
                ]
            },
            {
                id: "Arriba derecho",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "numero",
                        value: statePolPrincipal.numero,
                        error: errorStatePolPrincipal.numero.error,
                        success: statePolPrincipal.numero && !errorStatePolPrincipal.numero.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numero.helperText,
                        inputRef: PolPrincipalnumeroRef,
                        title: "No. de Póliza *",
                        placeholder: "Capture el No. de Póliza",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "numpolEndoso",
                        value: statePolPrincipal.numpolEndoso,
                        error: errorStatePolPrincipal.numpolEndoso.error,
                        success: statePolPrincipal.numpolEndoso && !errorStatePolPrincipal.numpolEndoso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numpolEndoso.helperText,
                        inputRef: PolPrincipalnumpolEndosoRef,
                        title: "Endoso",
                        placeholder: "Capture el endoso",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "inciso",
                        value: statePolPrincipal.inciso,
                        error: errorStatePolPrincipal.inciso.error,
                        //success: statePolPrincipal.inciso && !errorStatePolPrincipal.inciso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.inciso.helperText,
                        inputRef: PolPrincipalIncisoRef,
                        title: "Inciso",
                        placeholder: "Capture el inciso",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "tipoValorAseg",
                        value: statePolPrincipal.tipoValorAseg,
                        error: errorStatePolPrincipal.tipoValorAseg.error,
                        success: statePolPrincipal.tipoValorAseg && !errorStatePolPrincipal.tipoValorAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoValorAseg.helperText,
                        inputRef: PolPrincipaltipoValorAsegRef,
                        title: "Tipo de Valor Suma Asegurada",
                        tipo: "select",
                        data: tipoValorSumaAseg,
                        placeholder: "Capture el tipo de valor asegurado",
                        grid: { md: 8, lg: 8 }
                    },
                    {
                        disabled,
                        id: "sumaAseg",
                        value: statePolPrincipal.sumaAseg,
                        error: errorStatePolPrincipal.sumaAseg.error,
                        success: statePolPrincipal.sumaAseg && !errorStatePolPrincipal.sumaAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.sumaAseg.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        inputRef: PolPrincipalsumaAsegRef,
                        title: "Suma Asegurada",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "primaNeta",
                        value: statePolPrincipal.primaNeta,
                        error: errorStatePolPrincipal.primaNeta.error,
                        success: statePolPrincipal.primaNeta && !errorStatePolPrincipal.primaNeta.error ? true : undefined,
                        helperText: errorStatePolPrincipal.primaNeta.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        inputRef: PolPrincipalprimaNetaRef,
                        title: "Prima Neta",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "fpPromedio",
                        value: statePolPrincipal.fpPromedio,
                        error: errorStatePolPrincipal.fpPromedio.error,
                        success: statePolPrincipal.fpPromedio && !errorStatePolPrincipal.fpPromedio.error ? true : undefined,
                        helperText: errorStatePolPrincipal.fpPromedio.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="%" />,
                        posicionIcono: "end",
                        inputRef: PolPrincipalfpPromedioRef,
                        title: "Promedio",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "horasInvertidas",
                        value: statePolPrincipal.horasInvertidas,
                        error: errorStatePolPrincipal.horasInvertidas.error,
                        success: statePolPrincipal.horasInvertidas && !errorStatePolPrincipal.horasInvertidas.error ? true : undefined,
                        helperText: errorStatePolPrincipal.horasInvertidas.helperText,
                        textAlign: "right",
                        inputRef: PolPrincipalhorasInvertidasRef,
                        title: "Horas Invertidas",
                        placeholder: "Capture las horas invertidas",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "Beneficiario",
                        value: statePolPrincipal.beneficiarioPreferente,
                        error: errorStatePolPrincipal.beneficiarioPreferente.error,
                        //success: statePolPrincipal.beneficiarioPreferente && !errorStatePolPrincipal.beneficiarioPreferente.error ? true : undefined,
                        helperText: errorStatePolPrincipal.beneficiarioPreferente.helperText,
                        inputRef: PolPrincipalbeneficiarioPreferenteRef,
                        title: "Beneficiario Preferente",
                        placeholder: "Capture el nombre del beneficiario",
                        grid: { md: 12, lg: 12 }
                    }
                ]
            },
            {
                id: "fechas",
                tipo: "fieldset",
                leyenda: "▀",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "fechaSolCot",
                        value: statePolPrincipal.fechaSolCot,
                        error: errorStatePolPrincipal.fechaSolCot.error,
                        success: statePolPrincipal.fechaSolCot && !errorStatePolPrincipal.fechaSolCot.error ? true : undefined,
                        helperText: errorStatePolPrincipal.fechaSolCot.helperText,
                        inputRef: PolPrincipalfechaSolCotRef,
                        title: "Fecha Sol. Cotizacion.",
                        placeholder: "Fecha de Solicitud",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaIni",
                        value: statePolPrincipal.vigenciaIni,
                        error: errorStatePolPrincipal.vigenciaIni.error,
                        success: statePolPrincipal.vigenciaIni && !errorStatePolPrincipal.vigenciaIni.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaIni.helperText,
                        inputRef: PolPrincipalvigenciaIniRef,
                        placeholder: "Fecha Vigencia de Inicio",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaHasta",
                        value: statePolPrincipal.vigenciaHasta,
                        error: errorStatePolPrincipal.vigenciaHasta.error,
                        success: statePolPrincipal.vigenciaHasta && !errorStatePolPrincipal.vigenciaHasta.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaHasta.helperText,
                        inputRef: PolPrincipalvigenciaHastaRef,
                        title: "Vigencia hasta",
                        placeholder: "Fecha de Vencimiento",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    }, {
                        disabled: true,
                        id: "renovacion",
                        value: statePolPrincipal.renovacion,
                        checked: statePolPrincipal.renovacion,
                        title: "Renovar al Termino de Vigencia",
                        tipo: "switch",
                        grid: { md: 3, lg: 3 },
                    }]
            }, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "exclusiones",
                    value: statePolPrincipal.exclusiones,
                    error: errorStatePolPrincipal.exclusiones.error,
                    success: statePolPrincipal.exclusiones && !errorStatePolPrincipal.exclusiones.error ? true : undefined,
                    helperText: errorStatePolPrincipal.exclusiones.helperText,
                    inputRef: PolPrincipalexclusionesRef,
                    multiline: true,
                    title: "Exclusiones *",
                    placeholder: "Capture las exclusiones",
                    grid: { md: 12, lg: 12 }
                }]
        }
        )
    }
    else {
        inputsPolizaPrincipal.push(
            {
                id: "cTopAPDS",
                tipo: "fieldset",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "seleccionTipoPoliza",
                        value: statePolPrincipal.seleccionTipoPoliza,
                        error: errorStatePolPrincipal.seleccionTipoPoliza.error,
                        success: statePolPrincipal.seleccionTipoPoliza && !errorStatePolPrincipal.seleccionTipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.seleccionTipoPoliza.helperText,
                        inputRef: PolPrincipalseleccionTipoPolizaRef,
                        title: "Sencilla / Múltiple",
                        placeholder: "Sencilla / Múltiple",
                        tipo: "autocomplete",
                        data: [{ label: 'Sencilla', value: 1 }, { label: 'Multiple', value: 2 }],
                        grid: { md: 12, lg: 12 }
                    }
                ]
            },
            // :
            {
                id: "Arriba izquierdo",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "empresaID",
                        value: statePolPrincipal.empresaID,
                        error: errorStatePolPrincipal.empresaID.error,
                        success: statePolPrincipal.empresaID && !errorStatePolPrincipal.empresaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.empresaID.helperText,
                        inputRef: PolPrincipalempresaIDRef,
                        title: "Empresa *",
                        placeholder: "Seleccione una Empresa",
                        tipo: "autocomplete",
                        data: seleccionables.empresas,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "ramoSeguroID",
                        value: statePolPrincipal.ramoSeguroID,
                        error: errorStatePolPrincipal.ramoSeguroID.error,
                        success: statePolPrincipal.ramoSeguroID && !errorStatePolPrincipal.ramoSeguroID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.ramoSeguroID.helperText,
                        inputRef: PolPrincipalramoSeguroIDRef,
                        title: "Ramo de Seguro *",
                        placeholder: "Seleccione una Ramo",
                        tipo: "autocomplete",
                        data: seleccionables.ramoSeguros,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "aseguradoraID",
                        value: statePolPrincipal.aseguradoraID,
                        error: errorStatePolPrincipal.aseguradoraID.error,
                        success: statePolPrincipal.aseguradoraID && !errorStatePolPrincipal.aseguradoraID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.aseguradoraID.helperText,
                        inputRef: PolPrincipalaseguradoraIDRef,
                        title: "Aseguradora *",
                        placeholder: "Seleccione una Aseguradora",
                        tipo: "autocomplete",
                        data: seleccionables.aseguradoras,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "tipoPoliza",
                        value: statePolPrincipal.tipoPoliza,
                        error: errorStatePolPrincipal.tipoPoliza.error,
                        success: statePolPrincipal.tipoPoliza && !errorStatePolPrincipal.tipoPoliza.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoPoliza.helperText,
                        inputRef: PolPrincipaltipoPolizaRef,
                        title: "Tipo de Póliza *",
                        placeholder: "Seleccione un tipo",
                        tipo: "select",
                        data: aTiposPoliza,
                        grid: { md: 5, lg: 5 }
                    },
                    {
                        hidden: statePolPrincipal.ramoSeguro.toUpperCase() === "AUTOMÓVILES RESIDENTES" ? false : true,
                        disabled: true,
                        id: "derechoPoliza",
                        value: statePolPrincipal.derechoPoliza,
                        // error: errorStatePolPrincipal.derechoPoliza.error,
                        //success: statePolPrincipal.derechoPoliza && !errorStatePolPrincipal.derechoPoliza.error ? true : undefined,
                        // helperText: errorStatePolPrincipal.derechoPoliza.helperText,
                        inputRef: PolPrincipalDerechoPolizaRef,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        title: "Derecho de Póliza *",
                        placeholder: "Capture el Derecho de Póliza",
                        grid: { md: 7, lg: 7 }
                    },
                    {
                        disabled: true,
                        id: "polizaOrigenID ",
                        value: statePolPrincipal.polizaOrigenID == null ? "0" : statePolPrincipal.polizaOrigenID,
                        error: errorStatePolPrincipal.polizaOrigenID.error,
                        success: statePolPrincipal.polizaOrigenID && !errorStatePolPrincipal.polizaOrigenID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.polizaOrigenID.helperText,
                        inputRef: PolPrincipalpolizaOrigenIDRef,
                        title: "Póliza Principal *",
                        placeholder: "Seleccione la poliza Principal",
                        tipo: "autocomplete",
                        data: seleccionables.polizasSeguro,
                        grid: { md: 12, lg: 12 }
                    },
                    {
                        disabled: true,
                        id: "divisaID",
                        value: statePolPrincipal.divisaID,
                        error: errorStatePolPrincipal.divisaID.error,
                        success: statePolPrincipal.divisaID && !errorStatePolPrincipal.divisaID.error ? true : undefined,
                        helperText: errorStatePolPrincipal.divisaID.helperText,
                        inputRef: PolPrincipaldivisaIDRef,
                        title: "Divisa",
                        placeholder: "Seleccionar la Divisa",
                        grid: { md: 6, lg: 6 },
                        tipo: "autocomplete",
                        data: listDivisas
                    },
                    {
                        disabled: true,
                        id: "paridad",
                        value: statePolPrincipal.paridad,
                        error: errorStatePolPrincipal.paridad.error,
                        //success: statePolPrincipal.paridad && !errorStatePolPrincipal.paridad.error ? true : undefined,
                        helperText: errorStatePolPrincipal.paridad.helperText,
                        title: "Paridad Actual ",
                        textAlign: "right",
                        placeholder: "Paridad Actual",
                        grid: { md: 6, lg: 6 }
                    }
                ]
            },
            {
                id: "Arriba derecho",
                tipo: "fieldset",
                grid: { md: 6, lg: 6 },
                inputs: [
                    {
                        disabled: true,
                        id: "numero",
                        value: statePolPrincipal.numero,
                        error: errorStatePolPrincipal.numero.error,
                        success: statePolPrincipal.numero && !errorStatePolPrincipal.numero.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numero.helperText,
                        inputRef: PolPrincipalnumeroRef,
                        title: "No. de Póliza *",
                        placeholder: "Capture el No. de Póliza",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "numpolEndoso",
                        value: statePolPrincipal.numpolEndoso,
                        error: errorStatePolPrincipal.numpolEndoso.error,
                        success: statePolPrincipal.numpolEndoso && !errorStatePolPrincipal.numpolEndoso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.numpolEndoso.helperText,
                        inputRef: PolPrincipalnumpolEndosoRef,
                        title: "Endoso",
                        placeholder: "Capture el endoso",
                        grid: { md: 6, lg: 6 }
                    },
                    {
                        disabled: true,
                        id: "inciso",
                        value: statePolPrincipal.inciso,
                        error: errorStatePolPrincipal.inciso.error,
                        //success: statePolPrincipal.inciso && !errorStatePolPrincipal.inciso.error ? true : undefined,
                        helperText: errorStatePolPrincipal.inciso.helperText,
                        inputRef: PolPrincipalIncisoRef,
                        title: "Inciso",
                        placeholder: "Capture el inciso",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "tipoValorAseg",
                        value: statePolPrincipal.tipoValorAseg,
                        error: errorStatePolPrincipal.tipoValorAseg.error,
                        success: statePolPrincipal.tipoValorAseg && !errorStatePolPrincipal.tipoValorAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.tipoValorAseg.helperText,
                        inputRef: PolPrincipaltipoValorAsegRef,
                        title: "Tipo de Valor Suma Asegurada",
                        tipo: "select",
                        data: tipoValorSumaAseg,
                        placeholder: "Capture el tipo de valor asegurado",
                        grid: { md: 8, lg: 8 }
                    },
                    {
                        disabled: true,
                        id: "Beneficiario",
                        value: statePolPrincipal.beneficiarioPreferente,
                        error: errorStatePolPrincipal.beneficiarioPreferente.error,
                        //success: statePolPrincipal.beneficiarioPreferente && !errorStatePolPrincipal.beneficiarioPreferente.error ? true : undefined,
                        helperText: errorStatePolPrincipal.beneficiarioPreferente.helperText,
                        inputRef: PolPrincipalbeneficiarioPreferenteRef,
                        title: "Beneficiario Preferente",
                        placeholder: "Capture el nombre del beneficiario",
                        grid: { md: 12, lg: 12 }
                    },
                     {
                        disabled,
                        id: "sumaAseg",
                        value: statePolPrincipal.sumaAseg,
                        error: errorStatePolPrincipal.sumaAseg.error,
                        success: statePolPrincipal.sumaAseg && !errorStatePolPrincipal.sumaAseg.error ? true : undefined,
                        helperText: errorStatePolPrincipal.sumaAseg.helperText,
                        textAlign: "right",
                        icono: <IconoSimbolo simbolo="$" />,
                        formatoImportes: true,
                        posicionIcono: "start",
                        inputRef: PolPrincipalsumaAsegRef,
                        title: "Suma Asegurada",
                        placeholder: "0.0",
                        grid: { md: 6, lg: 6 }
                    }
                ]
            },
            {
                id: "fechas",
                tipo: "fieldset",
                leyenda: "▀",
                grid: { lg: 12 },
                inputs: [
                    {
                        disabled: true,
                        id: "fechaSolCot",
                        value: statePolPrincipal.fechaSolCot,
                        error: errorStatePolPrincipal.fechaSolCot.error,
                        success: statePolPrincipal.fechaSolCot && !errorStatePolPrincipal.fechaSolCot.error ? true : undefined,
                        helperText: errorStatePolPrincipal.fechaSolCot.helperText,
                        inputRef: PolPrincipalfechaSolCotRef,
                        title: "Fecha Sol. Cotizacion.",
                        placeholder: "Fecha de Solicitud",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaIni",
                        value: statePolPrincipal.vigenciaIni,
                        error: errorStatePolPrincipal.vigenciaIni.error,
                        success: statePolPrincipal.vigenciaIni && !errorStatePolPrincipal.vigenciaIni.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaIni.helperText,
                        inputRef: PolPrincipalvigenciaIniRef,
                        placeholder: "Fecha Vigencia de Inicio",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    },
                    {
                        disabled: true,
                        id: "vigenciaHasta",
                        value: statePolPrincipal.vigenciaHasta,
                        error: errorStatePolPrincipal.vigenciaHasta.error,
                        success: statePolPrincipal.vigenciaHasta && !errorStatePolPrincipal.vigenciaHasta.error ? true : undefined,
                        helperText: errorStatePolPrincipal.vigenciaHasta.helperText,
                        inputRef: PolPrincipalvigenciaHastaRef,
                        title: "Vigencia hasta",
                        placeholder: "Fecha de Vencimiento",
                        onlyDate: true,
                        tipo: "datetimepicker",
                        grid: { md: 4, lg: 4 }
                    }, {
                        disabled: true,
                        id: "renovacion",
                        value: statePolPrincipal.renovacion,
                        checked: statePolPrincipal.renovacion,
                        title: "Renovar al Termino de Vigencia",
                        tipo: "switch",
                        grid: { md: 3, lg: 3 },
                    }]
            }, {
            id: "Abajo",
            tipo: "fieldset",
            leyenda: "▀",
            grid: { lg: 12 },
            inputs: [
                {
                    disabled: true,
                    id: "exclusiones",
                    value: statePolPrincipal.exclusiones,
                    error: errorStatePolPrincipal.exclusiones.error,
                    success: statePolPrincipal.exclusiones && !errorStatePolPrincipal.exclusiones.error ? true : undefined,
                    helperText: errorStatePolPrincipal.exclusiones.helperText,
                    inputRef: PolPrincipalexclusionesRef,
                    multiline: true,
                    title: "Exclusiones *",
                    placeholder: "Capture las exclusiones",
                    grid: { md: 12, lg: 12 }
                },
                {
                    disabled:true,
                    id: "aseguradoAdicional",
                    value: statePolPrincipal.aseguradoAdicional,
                    error: errorStatePolPrincipal.aseguradoAdicional.error,
                    success: statePolPrincipal.aseguradoAdicional && !errorStatePolPrincipal.aseguradoAdicional.error ? true : undefined,
                    helperText: errorStatePolPrincipal.aseguradoAdicional.helperText,
                    inputRef: PolPrincipalbaseguradoAdicionalRef,
                    multiline: true,
                    title: "Asegurado Adicional",
                    placeholder: "Capturar asegurado adicional",
                    grid: { md: 12, lg: 12 }
                },
            ]
        },
        {
            id: "cMultipartidas",
            tipo: "custom",
            leyenda: "▀",
            grid: { lg: 12 },
            custom: <MultiPartidas
                datosGet={datosPartidasGet}
                setDataToSave={setDatosPartidas}
                ramoSeguroSelect={seleccionables.ramoSeguros}
                datosEncabezado={statePolPrincipal}
                trazabilidad={1}
            />
        }
        )
    }

    const accionesAdjuntos = [];
    const botonesAdjuntos = [];

    const infoTablaAdjuntos = {
        botones: botonesAdjuntos,
        acciones: accionesAdjuntos,
        actualizar,
        id: "serverStorageID",
        color: primary,
        title: "Documentación Adjunta",
        title2: "Documentación",
        iconTable: <AccountCircle />,
        headers: [
            // { variable: "serverStorageID", descripcion: "ID" , hide: true },
            { variable: "tabla", descripcion: "Categoría" },
            { variable: "tipo", descripcion: "Carpeta" },
            { variable: "clasificacion", descripcion: "Subcarpeta" },
            { variable: "nombreArchivo", descripcion: "Nombre" },
            { variable: "serverStorageID", descripcion: "Archivo" },
            { variable: "ruta", descripcion: "Ruta", hide: true },
        ],
        responsiveTitle: ["tipo", "clasificacion", "nombreArchivo"],
        filter: [
            { campo: "tabla", variable: "tabla", placeholder: "Capture la categoria" },
            { campo: "tipo", variable: "tipo", placeholder: "Capture el tipo" },
            { campo: "clasificacion", variable: "clasificacion", placeholder: "Capture la clasificacion" }
        ],
        alineacion: [{ columnas: [0], alineacion: "left" }, { columnas: [0, 4, 5, 6, 7], alineacion: "center" }],
        formato: [
            {
                columnas: ["serverStorageID"], tipo: "custom", callback: (data) =>
                    <Button size="small" variant="outlined" color="primary" onClick={() => descargar(data)}>Descargar</Button>

            }
        ],
    }

    const inputs = [];
    const InputsConID = [];
    const Secciones = [];

    cleanErrorStateAdjuntos["serverStorageID"] = { error: false, helperText: "" };
    cleanStateAdjuntos["serverStorageID"] = 0;


    InicializarInputs("clasificacion", "Clasificación", "Capture la clasificación", "", "", 100);
    InicializarInputs("archivo", "Nombre del archivo", "nombre del archivo", "", "", 100);
    InicializarInputs("nombreDocumento", "Nombre del archivo", "nombre del archivo", "", "", 100);
    InicializarInputs("extension", "Extensión", "Extensión", "", "", 10);


    //-------------------------------------------
    InputsConID["clasificacion"].grid = { md: 12, lg: 12 };
    InputsConID["archivo"].grid = { md: 12, lg: 12 };
    InputsConID["nombreDocumento"].grid = { md: 8, lg: 8 };
    InputsConID["extension"].grid = { md: 4, lg: 4 };
    //-------------------------------------------

    InputsConID["archivo"].inputProps = { type: "file" }
    InputsConID["archivo"].onChange = ({ target: { value, files } }) => handleChangeFiles({ value, files }, "archivo");
    InputsConID["extension"].inputProps = { readOnly: true }

    function InicializarInputs(Identificador, pTitulo = "", pPlaceholder = "", ValorInicial = "", Seccion = "", MaxLength = 0) {

        cleanErrorStateAdjuntos[Identificador] = { error: false, helperText: "" };
        cleanStateAdjuntos[Identificador] = ValorInicial;
        ArrayRef[Identificador] = useRef(null);

        if (pTitulo === "") {
            pTitulo = Identificador;
        }

        if (pPlaceholder === "") {
            pPlaceholder = pTitulo;
        }

        var tInput = {
            value: stDatos[Identificador],
            id: Identificador,
            error: errorState[Identificador].error,
            success: stDatos[Identificador] && !errorState[Identificador].error ? true : undefined,
            helperText: errorState[Identificador].helperText,
            inputRef: ArrayRef[Identificador],
            title: pTitulo,
            placeholder: pPlaceholder,
            onChange: ({ target: { value } }) => handleChangeAdjuntos(value, Identificador, MaxLength),
            onKeyDown: () => { return; }
        };

        InputsConID[Identificador] = tInput;
        if (Seccion === "") {
            inputs.push(tInput);
            return;
        }

        var tInputsDeSeccion = [];
        var InputFieldSet;
        if (Secciones[Seccion]) {
            InputFieldSet = Secciones[Seccion];
            tInputsDeSeccion = InputFieldSet.inputs;
        } else {
            InputFieldSet = {
                id: Seccion,
                leyenda: Seccion,
                placeholder: Seccion,
                tipo: "fieldset",
                sizeLetra: "1.05em"
            };

            inputs.push(InputFieldSet);
        }

        tInputsDeSeccion.push(tInput);
        InputFieldSet.inputs = tInputsDeSeccion;
        Secciones[Seccion] = InputFieldSet;
    }
    const descargar = async function (idDoc) { //get storagen

        let archivo = await getFile({ serverStorageID: idDoc }, token);
        let documento = await getArchivo(archivo.ruta, token);

        let inicio = documento.length;
        let cadena = documento.indexOf(',')
        let b64 = documento.substring(cadena + 1, inicio);

        let iniciofor = documento.indexOf(':');
        let finfor = documento.indexOf(';');

        let ext = documento.substring(iniciofor + 1, finfor);

        showFile(b64, archivo.nombreArchivo, ext)

    };
    function handleChangeFiles({ files, value }, key) {
        if (files === null) {
            remover = true;
        }
        setDocumento({ valor: value, file: files[0], eliminar: remover });
        let nom = files[0].name;
        let punto = nom.indexOf('.');
        let ext = nom.substring(punto, nom.length);
        let nomDocumento = nom.substring(0, punto);

        setStateAdjuntos({
            ...stDatos,
            [key]: value,
            [`${key}File`]: files[0],
            [`${key}Name`]: files[0].name,
            ["nombreDocumento"]: nomDocumento,
            ["extension"]: ext
        });
        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));
    }
    function handleChangeAdjuntos(value, key, MaxLength = 0) {
        if (key === "archivo") {
            return;
        }
        if (value.length > MaxLength && MaxLength !== 0) {
            return;
        }
        setStateAdjuntos(stDatos => ({
            ...stDatos,
            [key]: value
        }));

        setErrorState(errorState => ({
            ...errorState,
            [key]: { helperText: "", error: false }
        }));

    }

    async function obtenerValoresMultipartidas(polizasSeguroID) {
        let auxDatos = await getAllSubPolizasSeguro({ filtro: { polizasSeguroID: parseInt(polizasSeguroID.polizaPrincipalID) } }, token)
        if (auxDatos.length > 0) {
            setDatosPartidasGet(auxDatos);
        }
        return;
    }

    function handleCloseVerSol() {
        setOpenVerSolicitud(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStateSol({ ...cleanStateSol });
        setErrorStateSol({ ...cleanErrorStateSol });

    }

    function handleCloseVerPago() {
        setOpenVerPago(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStatePago({ ...cleanStatePago });
        setErrorStatePago({ ...cleanErrorStatePago });

    }


    function handleCloseVerPolizaPrincipal() {
        setOpenVerPolPrincipal(false);
        //setOpenDetalle(false);
        setDisabled(false);
        setLoader(false);
        setNotificaciones({ ...cleanNotificaciones });
        setStatePolPrincipal({ ...cleanStatePolPrincipal });
        setErrorStatePolPrincipal({ ...cleanErrorStatePolPrincipal });
    }

    async function actualizarSeleccionables({polizasSeguroID = null}) {
        let ramoSeguros = await seleccionRamoSeguro({ filtro: { fijo: true } }, token);
        let empresas = await seleccionEmpresa({ filtro: { activa: 1 } }, token);

        let departamentos = await seleccionDepartamento({ filtro: {} }, token);
        let polizasSeguro = await seleccionPolizasSeguro({ filtro: { tipoPoliza: "PRINCIPAL" } }, token);
        polizasSeguro.unshift( {label:'No Aplica', value:"0"})
        let aseguradoras = await seleccionAseguradora({ filtro: {} }, token);
        let agenteSeguros = await seleccionAgentesSeguro({ filtro: {} }, token);
        let subPolizasSeguro = await seleccionableSubPolizasSeguro({ filtro: { polizasSeguroID } }, token);
        subPolizasSeguro.unshift({label:"No Aplica", value:"0"})
        setSeleccionables({ ramoSeguros, empresas, departamentos, polizasSeguro, aseguradoras, agenteSeguros,subPolizasSeguro });
    }

    return (<>
        <Tabla
            infoTabla={infoTabla}
            asyncData={tablaTrazabilidadSolicitudesSeguro}
            token={token}
            parametrosFijos={{ solSegFianID: nsolSegFianID !== 0 && nsolSegFianID !== undefined && nsolSegFianID !== null ? Number(nsolSegFianID) : null, polizasSeguroID: nPolizasSeguroID !== 0 && nPolizasSeguroID !== undefined && nPolizasSeguroID !== null ? Number(nPolizasSeguroID) : null, controlPagoID: ncontrolPagoID !== 0 && ncontrolPagoID !== undefined && ncontrolPagoID !== null ? Number(ncontrolPagoID) : null }}
        />

        <Tabla
            infoTabla={infoTablaAdjuntos}
            asyncData={tablaDocumentosAdjuntos}
            token={token}
            parametrosFijos={{ solSegFianID: nsolSegFianID !== 0 && nsolSegFianID !== undefined && nsolSegFianID !== null ? Number(nsolSegFianID) : null, polizasSeguroID: ( (nPolizasSeguroID !== 0) && (nPolizasSeguroID !== undefined) && (nPolizasSeguroID !== null) && (window.location.pathname.includes('SolicitudesSeguros') === false) && (window.location.pathname.includes('ControlPagos') === false) ) ? Number(nPolizasSeguroID) : null, controlPagoID: ncontrolPagoID !== 0 && ncontrolPagoID !== undefined && ncontrolPagoID !== null ? Number(ncontrolPagoID) : null }}
        />
        {
        openTablControlPagos &&    <Modal maxWidth={true} onClose={()=> setopenTablControlPagos(false)} >
                <Tabla
                    infoTabla={infoTablaControlPagos}
                    asyncData={tablaControlPagos}
                    token={token}
                    parametrosFijos={{polizaPago:1,polizaID:Number(nPolizasSeguroID)}}
                />
            </Modal>
        }
        {openVerSolicitud && <FormularioModal
            open={openVerSolicitud}
            title={titleModalSol}
            onClose={handleCloseVerSol}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsSolicitud}
            acciones={accionesFormularioVerSol}
            focus={SolfechaSolicitudRef}
        />}

        {openVerPago && <FormularioModal
            open={openVerPago}
            title={titleModalPago}
            onClose={handleCloseVerPago}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsPago}
            acciones={accionesFormularioVerPago}
            focus={PagofechaTramiteRef}
        />}

        {openVerPolPrincipal && <FormularioModal
            open={openVerPolPrincipal}
            title={titleModalPolizaPrincipal}
            onClose={handleCloseVerPolizaPrincipal}
            notificaciones={notificaciones}
            closeNotification={() => setNotificaciones({ ...cleanNotificaciones })}
            inputs={inputsPolizaPrincipal}
            acciones={accionesFormularioVerPolizaPrincipal}
            focus={PolPrincipalnumeroRef}
        />}

        {alert && <SweetAlert
            title={alert.title}
            descripcion={alert.descripcion}
            tipo={alert.tipo}
            msjConfirmacion={alert.msjConfirmacion}
            msjCancelacion={alert.msjCancelacion}
            onConfirm={alert.onConfirm}
            showConfirm={alert.showConfirm}
            showCancel={alert.showCancel}
            onCancel={() => setAlert(null)}
        />}
    </>);

}

export default TrazabilidadSolicitudesSeguro;
