import { FetchAPI } from "@renedelangel/helpers";
import { urlServidor } from '../../helpers';

export const ADD_GRUPO = `
mutation add($input: InputAddGrupo)
{
    addGrupo(input : $input)
  {
    grupoID
    usuarioBitID
    grupo
  }
}
`;

export const UPDATE_GRUPO =`
mutation update($input: InputUpdateGrupo)
{
    updateGrupo(input : $input)
  {
    grupoID
    usuarioBitID
    grupo
  }
}
`;

export const DELETE_GRUPO = `
mutation delete ($input : InputDeleteGrupo)
{
    deleteGrupo(input: $input)
}
`;

export const addGrupo = async (variables, token) => {
    try {
        let { addGrupo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: ADD_GRUPO
        });
        return addGrupo;
    } catch({message}) { throw new Error(message); }
};

export const updateGrupo = async (variables, token) => {
    try {
        let { updateGrupo } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: UPDATE_GRUPO
        });
        return updateGrupo;
    } catch({message}) { throw new Error(message); }
};

export const deleteGrupo= async (input, token) => {
    try {
        let { deleteGrupo } = await FetchAPI.graphql({
            token,
            variables:{input},
            url: urlServidor,
            query: DELETE_GRUPO
        });
        return deleteGrupo;
    } catch({message}) { throw new Error(message); }
};
