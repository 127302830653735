import { FetchAPI } from "@renedelangel/helpers";

import { urlServidor } from '../../helpers';

export const GET_PROGRAMA_ANUAL = `
        query get($filtro: InputGetProgramaAnual){
            getProgramaAnual(filtro: $filtro){
            programaAnualID
            ejercicio
            fechaGeneracion
            fechaAutorizada
            observaciones
            estado
            folioInterno
            autorizado
            }
        }`;

export const GET_ALL_PROGRAMA_ANUAL = `
        query filtroGetAll($filtro: InputGetProgramaAnual){
            getAllProgramaAnual(filtro: $filtro){
            programaAnualID
            ejercicio
            fechaGeneracion
            fechaAutorizada
            observaciones
            estado
            folioInterno
            autorizado
            }
        }`;

export const TABLA_PROGRAMA_ANUAL = `
        query filtro($filtro: InputFiltroProgramaAnual){
            filterProgramaAnual(filtro: $filtro){
            listado{
            programaAnualID
            ejercicio
            fechaGeneracion
            fechaAutorizada
            observaciones
            estado
            folioInterno
            autorizado
            }
            registros
            paginas
            }
        }`;

export const getProgramaAnual = async (filtro, token) => {
    try {
        let { getProgramaAnual } = await FetchAPI.graphql({
            token,
            variables: { filtro },
            url: urlServidor,
            query: GET_PROGRAMA_ANUAL
        });

        return getProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
}

export const getAllProgramaAnual = async (variables, token) => {
    try {

        let { getAllProgramaAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: GET_ALL_PROGRAMA_ANUAL
        });

        return getAllProgramaAnual;

    } catch ({ message }) { throw new Error(message); }
}

export const seleccionProgramaAnual = async (variables, token) => {
    try {

        let allProgramaAnual = await getAllProgramaAnual
            (variables, token);

        if (!Array.isArray(allProgramaAnual)) throw new Error("No se encontraron registros");
        
        return allProgramaAnual.map(({ programaAnualID: value, ejercicio: label }) => ({ value, label: label.toString().toUpperCase() }));

    } catch ({ message: label }) {
        return [{
            label,
            value: null,
            disabled: true
        }];
    }
}

export const tablaProgramaAnual = async (variables, token) => {
    try {
        let { filterProgramaAnual } = await FetchAPI.graphql({
            token,
            variables,
            url: urlServidor,
            query: TABLA_PROGRAMA_ANUAL
        });
        return filterProgramaAnual;
    } catch ({ message }) { throw new Error(message); }
}
