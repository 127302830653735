import { SET_TOKEN, SET_USUARIO, SET_CONFIGURACION, SET_FECHASFILTRO } from '../../types';

export default function(state = null, action) {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case SET_USUARIO:
            return { ...state, usuario: action.payload };
        case SET_CONFIGURACION:
            return { ...state, configuracion: action.payload };
        case SET_FECHASFILTRO:
                return { ...state, fechasFiltro: action.payload };
        default:
            return state;
    }
}
